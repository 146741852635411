<h3 *ngIf="(config.loading | async) && (config.loadingText | async)" class="sending-text" [innerHTML]="(config.loadingText | async) + '...'"></h3>
<div class="spinner-container" *ngIf="config.loading | async">
  <mat-spinner class="spinner" [diameter]="50"></mat-spinner>
</div>
<div
  *ngIf="!(config.loading | async) && config.response | async; let response"
  fxLayout="column"
  [fxLayoutAlign]="config.centerMessage ? 'initial center' : 'start'"
  fxLayoutGap="5px"
  class="end-message ice-mt-5 ice-mb-5"
>
  <div class="response-error-icon-container">
    <mat-icon class="response-error-icon" *ngIf="config.messageIcon | async">{{ config.messageIcon | async }}</mat-icon>
    <div>
      <span *ngIf="response.text" [innerHTML]="response.text" class="end-message-text"></span>
      <a *ngIf="response.link" [className]="'mat-tab-link response-link-action'" style="cursor: pointer" (mouseup)="response.onClickLink($event)">
        {{ response.link }}
      </a>
      <span *ngIf="response.textAfterLink" [innerHTML]="response.textAfterLink" class="end-message-text"></span>
    </div>
  </div>
  <div fxLayout="column">
    <span *ngIf="response.secondaryText" [innerHTML]="response.secondaryText" style="margin-top: 20px" class="ice-error-msg"></span>
    <div fxLayout="column">
      <span
        fxLayout="row"
        fxLayoutGap="5px"
        style="line-height: initial; margin-top: 4px"
        *ngFor="let secondaryLink of response.secondaryLinks"
        (mouseup)="response.onClickSecondaryLink($event, secondaryLink)"
        ><a class="response-link-action ice-error-msg">
          {{ secondaryLink }}
        </a>
        <mat-icon class="open-new-tab-icon"> open_in_new </mat-icon></span
      >
    </div>
  </div>
</div>
<div class="end-message break-word" *ngIf="config.errors | async">
  <p class="error-title" *ngIf="config.errorTitle">{{ config.errorTitle }}</p>
  <ul class="error-messages" *ngIf="config.errors | async; let dataError">
    <ng-container *ngIf="isArray(dataError)">
      <li class="ice-black-error-msg" *ngFor="let error of dataError; let first = first" [ngClass]="{ first: first && !config.errorTitle }">{{ error }}</li>
    </ng-container>
    <ng-container *ngIf="!isArray(dataError)">
      <li class="ice-black-error-msg">{{ dataError }}</li>
    </ng-container>
  </ul>
</div>
<mat-dialog-actions
  class="basic-buttons result-buttons"
  *ngIf="
    config && (config.response | async) && !(config.loading | async) && !(config.errors | async) ? (config.responseButtons | async) : (config.errorButtons | async);
    let buttons
  "
>
  <div [fxLayout]="'row'" *ngIf="isArray(buttons)">
    <div class="end-button" *ngFor="let button of buttons; index as endButtonsIndex">
      <button mat-button (click)="button.action()" [ngClass]="button.class" attr.data-testid="{{button.attributes?.['data-testid'] || 'end-button-' + endButtonsIndex }}">
        {{ button.text | async }}
      </button>
    </div>
  </div>
</mat-dialog-actions>
