<div [ngClass]="config?.customClass">
  <!-- mat fab btn -->
  <div *ngIf="config && config.buttons && (config.type === 'matFab' || config.type === 'matFabToggle')" [fxLayout]="config.layout" fxLayoutGap="10px">
    <button
      *ngFor="let button of config.buttons"
      mat-fab
      attr.data-testid="{{ button.name }}"
      [color]="button.color"
      (click)="onClick($event, button)"
      [disabled]="isObservable(button.disabled) ? (button.disabled | async) : button.disabled"
      [ngClass]="(button.isHidden && (button.isHidden | async) && 'ice-display-none ' + button.className) || button.className"
      (contextmenu)="onContextMenu($event, button)"
    >
      <mat-icon
        class="white-icon"
        *ngIf="button.icon"
        [matTooltipClass]="'ice-tooltip ice-tooltip-floating-button force-merge-tooltip ' + (button.tooltipClass || '')"
        [matTooltip]="(button.tooltip | async) || (button.label | translate)"
        [matTooltipPosition]="'above'"
        [attr.aria-label]="(button.tooltip | async) || (button.label | translate)"
        >{{ button.icon }}
      </mat-icon>
      <span *ngIf="!button.icon">{{ button.label | translate }}</span>
      <div
        *ngIf="button.multipleActions"
        style="visibility: hidden; position: fixed"
        [style.left]="contextMenuPosition.x"
        [style.top]="contextMenuPosition.y"
        [matMenuTriggerFor]="contextMenu"
      ></div>
      <mat-menu #contextMenu="matMenu">
        <ng-template matMenuContent let-item="button">
          <button
            mat-menu-item
            attr.data-testid="{{ button.name }}"
            *ngFor="let contextButton of button.multipleActions"
            (click)="onContextMenuAction(contextButton)"
            [disabled]="contextButton.disabled"
            ngClass="{{ contextButton.className }}"
          >
            <mat-icon *ngIf="contextButton.icon">{{ contextButton.icon }}</mat-icon>
            <span>{{ contextButton.label | translate }}</span>
          </button>
        </ng-template>
      </mat-menu>
    </button>
  </div>

  <!-- mat toggle btns -->
  <div *ngIf="config.type === 'matToggle'" class="flex justify-content-center flex-align-items-center flex-1">
    <mat-button-toggle-group appearance="standard" [value]="config.defaultValue" (change)="onToggle($event)">
      <ng-container *ngFor="let toggleButton of config.buttons">
        <mat-button-toggle [value]="toggleButton.value">
          <ng-container *ngIf="toggleButton.label">{{ toggleButton.label | translate }}</ng-container>
          <mat-icon *ngIf="toggleButton.icon">{{ toggleButton.icon }}</mat-icon>
        </mat-button-toggle>
      </ng-container>
    </mat-button-toggle-group>
  </div>
  <!-- mat btn // not defined type -->
  <div *ngIf="!config.type || config.type === 'matBtn'" [fxLayout]="config && config.buttons && config.layout" fxLayoutGap="10px">
    <button
      *ngFor="let button of config && config.buttons"
      mat-raised-button
      attr.data-testid="{{ button.name }}"
      [color]="button.color"
      (click)="onClick($event, button)"
      [ngClass]="(button.isHidden && (button.isHidden | async) && 'ice-display-none') || button.className"
      [disabled]="isObservable(button.disabled) ? (button.disabled | async) : button.disabled"
    >
      {{ button.label | translate
      }}<mat-icon *ngIf="button.icon" class="white-icon" [ngClass]="button.iconClassName" [attr.aria-label]="button.label | translate">{{ button.icon }} </mat-icon>
    </button>
  </div>
  <!-- mat-stroked-button -->
  <div *ngIf="!config.type || config.type === 'matStrBtn'" [fxLayout]="config && config.buttons && config.layout" fxLayoutGap="10px">
    <button
      *ngFor="let button of config && config.buttons"
      mat-stroked-button
      attr.data-testid="{{ button.name }}"
      [color]="button.color"
      (click)="onClick($event, button)"
      [ngClass]="(button.isHidden && (button.isHidden | async) && 'ice-display-none') || button.className"
      [disabled]="isObservable(button.disabled) ? (button.disabled | async) : button.disabled"
    >
      <span class="ice-ml-5"></span>{{ button?.templateOptions?.text }}{{ button.label | translate }}
      <mat-icon *ngIf="button.icon" class="white-icon" [ngClass]="button.iconClassName" [attr.aria-label]="button.label | translate">{{ button.icon }} </mat-icon>
    </button>
  </div>

  <div *ngIf="config.message" class="center-message">
    <span>{{ config.message | async }}</span>
  </div>
</div>
