import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DataTableRow, SortInfo } from '@ice/components/data-table/data-table';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import { REPORT_CLONE, REPORT_DOWNLOAD, REPORT_STATUS } from 'config/constants/reports.constants';
import { RootDatatable } from 'config/data-table-builders/root-datatable';
import { of } from 'rxjs';
import * as fromActions from 'store/root/actions';
import { DataTableBuilder } from './data-table-builder';

export class CopyrightReportsDataTable extends RootDatatable implements DataTableBuilder {
  constructor(protected translate: TranslateService, protected fuseTranslationLoader: FuseTranslationLoaderService) {
    super(translate);
    this.fuseTranslationLoader.loadTranslations(english);
  }

  getDefaultSorting(): SortInfo[] {
    return [{ prop: 'date', dir: 'desc' }];
  }

  formatSort(sort: SortInfo) {
    switch (sort.prop) {
      case 'id':
        return { prop: `id`, dir: sort.dir };
      case 'date':
        return { prop: `attributes.requestDate`, dir: sort.dir };
    }
  }

  getDataTable(headerButtons, store): DataTableRow[] {
    return [
      { name: this.translate.instant('REPORTS.NAME'), prop: 'name', flexGrow: 1 },
      { name: this.translate.instant('REPORTS.REPORT_ID'), prop: 'id', flexGrow: 1, comparator: () => this.serverSideSorting() },
      { name: this.translate.instant('REPORTS.DATE_REQUESTED'), prop: 'date', flexGrow: 0.5, comparator: () => this.serverSideSorting() },
      { name: this.translate.instant('REPORTS.STATUS'), prop: 'status', flexGrow: 0.4 },
      { name: this.translate.instant('REPORTS.USERNAME'), prop: 'username', flexGrow: 0.5 },
      {
        prop: 'download',
        name: '',
        hideActionButton: row => of(row.status !== REPORT_STATUS.FINISHED),
        actionButtonIcon: 'save_alt',
        actionButtonTooltip: this.translate.instant('REPORTS.DOWNLOAD_REPORT'),
        flexGrow: 0.15,
        action: (row: any) => store.dispatch(new fromActions.GetReport({ ...row, type: REPORT_DOWNLOAD })),
      },
      {
        prop: 'clone',
        name: '',
        actionButtonIcon: 'filter_none',
        actionButtonTooltip: this.translate.instant('REPORTS.CLONE_REPORT'),
        flexGrow: 0.15,
        action: (row: any) => store.dispatch(new fromActions.GetReport({ ...row, type: REPORT_CLONE })),
      },
    ];
  }

  getColumnMode(): ColumnMode {
    return ColumnMode.flex;
  }

  displayCheck(row: any, firstSelectedItem: any, targetItem: any, column?: any, value?: any): boolean {
    return true;
  }
}
