export const locale = {
  lang: 'en',
  data: {
    ERROR: {
      EDIT_CLAIM_SUCCESS: 'Claim updated correctly',
      SERVER: {
        COPYRIGHT: 'Server Error fetching data',
        AUTH: 'The user is not currently authenticated, please login again',
        GET_USER_DETAILS: 'Cube is temporarily not accessible. Please try again later. If the issue persists, please contact your helpdesk.',
        ITEM_NOT_FOUND: 'Item not found',
        COPYRIGHT_EXTRA: 'Error fetching extended data',
        COPYRIGHT_EXTENDED: 'Error fetching extended data',
        SEARCH: 'Server Error running the search',
        SHARE: 'Server Error fetching shares data',
        UNHANDLED: "An error has occurred and we've notified support, if the app becomes non-responsive please refresh using F5 or Cmd+R",
        NEW_SECTION_ITEM_FAIL: 'Error on saving new item',
        UPDATE_FAIL: 'Server Error running the update',
        SAVE_SECTION_ITEM_FAIL: 'Server Error running the update',
        ICE_ERROR: 'Server Error',
        DELETE_FAIL: 'An error has occurred running the delete action',
        SAVING_ITEM_ERROR: 'There has been an error while saving the new item. Please review the submitted information',
        ACCESS_FAIL: "Sorry, you don't have sufficient access rights to view this page",
        ROLE_ERROR: 'Defined user role for this account is incorrect. Please contact your support for help.',
        ALL_TERRITORIES: 'Server Error fetching all territories data',
        AGREEMENT_CYCLE: 'Cyclic hierarchy found, processing have stopped.',
      },
      UI: {
        SELECTED_ITEM_MISSING_KEY: 'The selected item is missing the key',
        WORKS_CONFLICT_OUTDATED: 'The works in the conflict have been changed outside of this window. Please re-load the conflict.',
      },
      AUTH: {
        USER_NOT_AUTH: 'User not authenticated',
        PASSWORD_LINK_EXPIRED: 'The user account activation link is expired. Please contact the system support.',
      },
      AGREEMENTS: {
        CREATE_ERROR: 'There was a server error while creating the Agreement',
        MALFORMED_AGREEMENT: 'Cannot save malformed Agreement',
      },
    },
  },
};
