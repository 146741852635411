import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { concatMap, map, share, take } from 'rxjs/operators';

const CHECK_INTERVAL = 1000 * 60 * 30;

@Injectable()
export class CheckVersionService {
  private initialVersion;
  private version$ = this.httpClient.get('/assets/version/version.json').pipe(
    map(versionInfo => this.getVersionAsString(versionInfo)),
    share(),
  );

  constructor(private httpClient: HttpClient) {
    this.version$.pipe(take(1)).subscribe(version => {
      this.initialVersion = version;
    });
  }

  get currentVersion$(): Observable<string> {
    return timer(0, CHECK_INTERVAL).pipe(concatMap(() => this.version$));
  }

  get needsUpdate$(): Observable<boolean> {
    return this.currentVersion$.pipe(map(currentVersion => currentVersion && this.initialVersion && this.initialVersion !== currentVersion));
  }

  private getVersionAsString(versionInfo) {
    const { major, minor, patch } = versionInfo;
    return `${major}.${minor}.${patch}`;
  }
}
