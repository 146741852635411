import { AGREEMENTS_CONSTANTS } from 'config/constants/agreements.constants';
import { IP_CONSTANTS } from 'config/constants/ips.constants';

export const AgreementInitModel = {
  type_of_agreement: AGREEMENTS_CONSTANTS.GENERAL,
  prior_royalties: 'Y',
  sales_manufacture: AGREEMENTS_CONSTANTS.SALES,
  transfer_works: true,
  share_in_op_assignor_only: false,
  assignor: {
    assignor_type: IP_CONSTANTS.LEGAL_ENTITY,
  },
  gema_remuneration_claim_indicator: false,
  library_music: 'n',
  identifiers: [{}],
  related_agreements: [{ related_agreement_type: '' }],
  shares: [
    { type: 'Mechanical', creator: '0.00', publisher: '0.00', assignee: '0.00', territory: '2WL', inclusion: 'ALL' },
    { type: 'Performing', creator: '0.00', publisher: '0.00', assignee: '0.00', territory: '2WL', inclusion: 'ALL' },
  ],
};
