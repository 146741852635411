import { UserCleaned } from './users/users.model';

export class User {
  userName: string;
  name: string;
  surname: string;
  email: string;
  roles?: string[];
  typedRoles?: string[];
  detail?: UserCleaned;
  ns?: string;
  organizations?: any;
  currentOrganization?: CurrentOrganization;
}

export interface Credentials {
  username: string;
  password: string;
}

export interface PasswordResetCredentials {
  email: string;
  code: string;
  newPassword: string;
}

export interface NewPasswordCredentials {
  userName: string;
  password: string;
  newPassword: string;
}

export enum CognitoStatus {
  onSuccess = 1,
  newPasswordRequired = 2,
  onFailure = 3,
  mfaRequired = 4,
}

export interface CognitoResult {
  type: CognitoStatus;
  user: User;
}

export interface CurrentOrganization {
  id: string;
  name: string;
  type: string;
  publisherNS?: string;
  extraSocPermissions?: String[];
  accessPartyNames?: string[];
  societyCode?: string;
  isIce?: boolean;
}
