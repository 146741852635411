<div class="input-box">
  <mat-icon matPrefix>{{ to.icon }}</mat-icon>
  <input matInput
    title="{{ to.tooltip }}"
    [id]="id"
    [required]="to.required"
    [type]="to.type || 'text'"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [step]="to.step || '1'"
    [placeholder]="to.placeholder"
    [maxLength]="field.templateOptions.maxLength || 1000"/>
</div>
