import { environment } from 'config/env';
import { SectionsConfig } from 'config/sections-config';
import { RequestType } from 'config/sections-config/api-call';
import {
  allowForbiddenResponse,
  avoidFirstTime,
  createApiCall,
  createApiCallFromBase,
  createPermissionResolver,
  isCounterApiCall,
  isPageable,
  isResponseHandler,
  setBody,
  setCheckStatus,
  setInclude,
  setLabelResolver,
  setPageable,
  setQueryParams,
  setResultCleaner,
  validateLabels,
} from '../api-call.factory';
import { getAuditHistory, getAuditHistoryFilterEndDate, getAuditHistoryFilterStartDate, getClaimGraphBase } from '../common/common.api-calls';
import * as fromCommonResultCleaners from '../common/common.result-cleaners';
import * as fromLabelResolvers from './works.label-resolvers';
import * as fromResultCleaners from './works.result-cleaners';

const workSection = SectionsConfig.WORKS.name;

export const getAgreementApplications = createApiCall(
  `${environment.apiUrlCubeData}/agreementapplications/CUBE/search`,
  RequestType.POST,
  setInclude(`attributes{agreementId,workId,claimantId,shares,include},
      agreement.attributes{id, administrator, agreementType, searchTerm, shares, retention, agreementDispute, sourceOfDoc, termTerritory},
      agreement.relations[XREF]{relation, otherId},
      agreement.assignor{partyNameId,party,partyName}.partyName.attributes{id, name, firstName},
      agreement.assignor.partyName.relations[XREF],
      agreement.assignor.party.attributes{typeOf},
      agreement.assignor.party.societies{societyId,society,memberships},
      agreement.assignee{partyNameId,party,partyName}.partyName.attributes{id, name, firstName},
      agreement.assignee.partyName.relations[XREF]{relation, otherId},
      agreement.assignee.party.societies{societyId,society,memberships},
      agreement.assignee.party.attributes{typeOf},
      agreement.agg.attributes.workCount,
      agreement.counterclaims.counterclaim.attributes
`),
  setBody(`{"and":[{"equals": {"attributes.workId": "<<id>>"}},{"equals": {"attributes.include": true}}]}`),
  setLabelResolver(workSection, fromLabelResolvers.getAttributesId),
  setResultCleaner(['agreements', fromResultCleaners.getAgreementApplicationsResultCleaner]),
  isPageable,
);

export const getAllClaimsWork = createApiCallFromBase(
  getClaimGraphBase,
  { id: 'getAllClaimsWork' },
  setInclude(
    `claims.attributes{share, parentId, role, territories, usageStartDate, usageEndDate, distributionStartDate, distributionEndDate, inDispute, id, rightTypes, status, tags,
      claimantPartyNameId},
    claims.claimant.party.societies.society.attributes, attributes,
    claims.claimant.partyName.attributes{firstName, name}, claims.claimant.partyName.relations[XREF], <<filter>>, claims.claimant.party.societies, claims.claimant.party.relations,
    claims.claimant.party.attributes,
    claims.agreement`,
  ),
  setLabelResolver(workSection, fromLabelResolvers.getAttributesId, fromLabelResolvers.getAllClaimsLabelResolver),
  setResultCleaner(['allClaims', fromCommonResultCleaners.getClaimGraphResultCleaner]),
  createPermissionResolver('works_detail_all_claims_tab'),
  validateLabels,
);

export const getTerritoriesCountries = createApiCall(
  environment.territoriesApiUrl,
  RequestType.POST,
  { id: 'getTerritoriesCountries' },
  setBody(`<<codes>>`),
  setLabelResolver(workSection, fromLabelResolvers.getTerritoryCodesLabelResolver),
  createPermissionResolver('works_detail_all_claims_tab'),
  isResponseHandler,
  validateLabels,
);

export const getWorkConflicts = createApiCall(
  `${environment.apiUrlCubeData}/${workSection}/<<ns>>/<<id>>`,
  RequestType.GET,
  setInclude(`conflicts.conflict.claimCases.claimCase.attributes,
      conflicts.conflict.works.work.activityTriggers.attributes,
      conflicts.conflict.works.work.relations,
      conflicts.conflict.attributes,
      conflicts.conflict.case,
      conflicts.conflict.claimConflictPoints,
      conflicts.conflict.claimCases.claimCase.claimants,
      activityTriggers.attributes`),
  setLabelResolver(workSection, fromLabelResolvers.getAttributesKey),
  setResultCleaner(['conflicts', fromResultCleaners.getWorkConflictsResultCleaner]),
  avoidFirstTime,
  validateLabels,
);

export const getWorkRepertoires = createApiCall(
  `${environment.apiUrlCubeData}/repertoires/<<ns>>/search`,
  RequestType.POST,
  setInclude(`attributes{id, name, type, territories, startDate, endDate}, relations[XREF]{relation, otherId}`),
  setBody(`{"and":[{"equals":{"works.work.relations[XREF].otherId":"<<id>>"}},{"wildcard":{"relations[XREF].otherId":"ICE:*"}}]}`),
  isPageable,
  setLabelResolver(workSection, fromLabelResolvers.getAttributesKey),
  setResultCleaner(['repertoires', fromResultCleaners.getWorkRepertoiresResultCleaner]),
);

export const getWorkAuditHistoryFilterStartDate = createApiCallFromBase(getAuditHistory, getAuditHistoryFilterStartDate);

export const getWorkAuditHistoryFilterEndDate = createApiCallFromBase(getAuditHistory, getAuditHistoryFilterEndDate);

export const getSourceWorks = createApiCall(
  `${environment.apiUrlCubeData}/works/<<ns>>/search`,
  RequestType.POST,
  { id: 'getSourceWorks' },
  setInclude(`attributes,relations[relation=("MTCH")],contributions.attributes,contributions.relations,
  contributions{contributor, role}.contributor{partyName}.partyName{attributes}.attributes{name, firstName},claims, counterclaims,
  contributions.contributor.party.attributes{typeOf}, tags`),
  setPageable(0, 100),
  setBody(`{"or":[<<pattern>>]}`),
  setLabelResolver(workSection, fromLabelResolvers.getRelationsMatchPatternLabelResolver, fromLabelResolvers.getMatchRelationsLabelResolver),
  setResultCleaner(['sourceWorks', fromResultCleaners.getSourceWorksResultCleaner]),
);

export const getWorkCounterclaimsAttributes = createApiCall(
  `${environment.apiUrlCubeData}/${SectionsConfig.WORKS.name}/<<ns>>/<<id>>`,
  RequestType.GET,
  setInclude(`counterclaims.attributes`),
  setLabelResolver(workSection, fromLabelResolvers.getAttributesKey),
  setResultCleaner(['counterclaims', fromResultCleaners.getCounterclaimsAttributesCleaner]),
  createPermissionResolver('works_detail_conflicts-cc_tab'),
  validateLabels,
  allowForbiddenResponse,
);

export const getWorkInternalRelations = createApiCall(
  `${environment.apiUrlCubeData}/works/<<ns>>/search`,
  RequestType.POST,
  setInclude(`attributes,relations,contributions.attributes,
  contributions.relations,contributions{contributor, role}.contributor{partyName}.partyName{attributes}.attributes{name, firstName},claims, counterclaims,
  contributions.contributor.party.attributes{typeOf}`),
  setBody(`{"or":[<<pattern>>]}`),
  setPageable(0, 100),
  validateLabels,
  setLabelResolver(workSection, fromLabelResolvers.getRelationPatternLabelResolver, fromLabelResolvers.getSMPLEXCPMODRelationsLabelResolver),
  setResultCleaner(['workInternalRelations', fromResultCleaners.getWorkInternalRelationsResultCleaner]),
);

export const getWorkExternalRelations = createApiCall(
  `${environment.apiUrlCubeData}/works/<<ns>>/search`,
  RequestType.POST,
  setInclude(`attributes,relations[relation=("EXCP","MOD","SMPL")],contributions.attributes,contributions.relations,
  contributions{contributor, role}.contributor{partyName}.partyName{attributes}.attributes{name, firstName},claims, counterclaims,
  contributions.contributor.party.attributes{typeOf}`),
  setPageable(0, 100),
  setBody(`{"or":[<<pattern>>]}`),
  setLabelResolver(workSection, fromLabelResolvers.getRelationsPatternLabelResolver, fromLabelResolvers.getMatchRelationsLabelResolver),
  setResultCleaner(['workExternalRelations', fromResultCleaners.getWorkExternalRelationsResultCleaner]),
  validateLabels,
);

export const getAgreementCount = createApiCall(
  `${environment.apiUrlCubeData}/agreementapplications/CUBE/search`,
  RequestType.POST,
  setBody(`{"and":[{"equals": {"attributes.workId": "<<id>>"}},{"equals": {"attributes.include": true}}]}`),
  setLabelResolver(workSection, fromLabelResolvers.getAttributesId),
  isCounterApiCall,
  setResultCleaner(['agreementsCount', (value, oldValue, data, translate) => value && value.total]),
);

export const cloneWork = createApiCall(
  `${environment.apiUrlCubeData}/${workSection}/copy/<<id>>/<<onBehalfOf>>`,
  RequestType.POST,
  isResponseHandler,
  setCheckStatus(workSection, null, true),
);

export const searchPartyName = createApiCall(
  `${environment.apiUrlCubeData}/party-names/<<ns>>/search`,
  RequestType.POST,
  setInclude(`attributes.publisher`),
  setQueryParams({ followRedirects: false }),
  setPageable(0, 100),
);
