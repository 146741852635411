import { ChangeDetectionStrategy, Component } from '@angular/core';
import { isObservable } from 'rxjs';
import { DynamicComponent } from '../dynamic-component';
import { FormsAndTables } from './forms-and-tables';

@Component({
  selector: 'ice-forms-and-tables',
  templateUrl: './forms-and-tables.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormsAndTablesComponent implements DynamicComponent {
  isObservable = isObservable;
  components: FormsAndTables;
}
