import { AfterViewChecked, ChangeDetectionStrategy, Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { TabInfo } from 'config/tabs-data-builders/tabs-data';
import { findIndex, forEach } from 'lodash';

@Component({
  selector: 'ice-tabs-container',
  templateUrl: './tabs-container.component.html',
  styleUrls: ['./tabs-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TabsContainerComponent implements AfterViewChecked {
  @ViewChild('matTabGroup', { static: true }) matTabGroup: MatTabGroup;
  @Input() tabInfo: TabInfo[];
  @Input() tabSelected: string;

  ngAfterViewChecked(): void {
    this.matTabGroup.realignInkBar();
  }

  ceil(i) {
    return Math.ceil(i);
  }

  constructor(private router: Router) {}

  openNewTab(tab) {
    const splitUrl = this.router.url.split('/');
    const parentUrl = splitUrl[splitUrl.length - 1] === 'edit' ? splitUrl.slice(0, splitUrl.length - 2).join('/') : splitUrl.slice(0, splitUrl.length - 1).join('/');
    window.open(`${parentUrl}/${tab}?force=true`);
  }

  getSelectedIndex() {
    const visibleTabs = [];
    forEach(this.tabInfo, tab => {
      tab.attributes
        .subscribe(attributes => {
          if (!attributes.isHidden) {
            visibleTabs.push(tab);
          }
        })
        .unsubscribe();
    });
    return findIndex(visibleTabs, tab => tab.link === this.tabSelected);
  }
}
