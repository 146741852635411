<ng-container *ngIf="!(config.isHidden | async) && config; let c">
  <mat-expansion-panel
    [expanded]="c.expanded | async"
    (opened)="c.onOpen && c.onOpen()"
    (closed)="c.onClosed && c.onClosed()"
    [hideToggle]="c.hideToggle"
    id="{{ c.listIdentifier ? 'expansion-list-' + c.listIdentifier : '' }}"
  >
    <mat-expansion-panel-header class="mat-accent-bg" [ngClass]="config.blockExpansion && 'disabled-pointer'" [ngClass]="config.headerClass">
      <mat-panel-title>
        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
          <h3 class="ice-txt-white" [ngClass]="config.blockExpansion && 'authorize-click'" (click)="headerTitleOnClick($event)">
            {{ c.title }}
            {{ getCounter() | async }}
          </h3>
          <button
            *ngIf="config.actionButton | async; let actionButton"
            [ngClass]="config.blockExpansion && 'authorize-click'"
            mat-icon-button
            [matTooltipClass]="'ice-tooltip'"
            [matTooltipPosition]="'above'"
            [matTooltip]="actionButton.tooltip"
            (click)="headerActionButtonClick($event, actionButton)"
          >
            <mat-icon class="mat-icon material-icons" [ngClass]="actionButton.class">{{ actionButton.icon }} </mat-icon>
          </button>
        </div>
      </mat-panel-title>
      <mat-panel-description> </mat-panel-description>
    </mat-expansion-panel-header>

    <ng-container *ngIf="c.model | async; let model">
      <div [ngStyle]="{ 'max-height.px': c.maxHeight ? c.maxHeight : 'auto', 'min-height.px': c.minHeight ? c.minHeight : 'auto', 'padding-top.px': 20 }">
        <mat-form-field #scrollParent class="ice-mt-5" *ngIf="model.length > c.filterItemsOn">
          <input matInput type="text" placeholder=" {{ 'FORM_FILTER' | translate }}" [(ngModel)]="filterValue" />
          <button mat-button *ngIf="filterValue" matSuffix mat-icon-button aria-label="Clear" (click)="clear($event)"><mat-icon>close</mat-icon></button>
        </mat-form-field>

        <ng-container [ngSwitch]="c.type">
          <ng-container *ngSwitchCase="'labeledList'">
            <cdk-virtual-scroll-viewport itemSize="75" [ngStyle]="{ 'height.px': c.customContentHeight || c.maxHeight - 85 }">
              <div *cdkVirtualFor="let item of (model | filter: filterValue:'value').slice(0, sliceLimit || model.length); let elemIndex = index" class="row">
                <div *ngIf="item.label" class="title">
                  <label matTooltip="{{ item.labelTooltip | translate }}" [matTooltipClass]="'ice-tooltip ice-tooltip-list'" [matTooltipPosition]="'above'">{{ item.label }}</label>
                </div>

                <div
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  class="ice-p-8 expansion-list-item-value ice-cell-bold fs-14"
                  [class.cursor-pointer]="c.selectable"
                  [attr.data-elem-index]="elemIndex"
                  (click)="c.onSelect && c.onSelect(item)"
                  (mouseup)="c.onMouseWheelClick && c.onMouseWheelClick($event, item)"
                >
                  <div *ngIf="item.labelInLine">{{ item.labelInLine }}</div>
                  <div
                    class="expansion-list-item-value-text"
                    [matTooltip]="tempTooltip"
                    [matTooltipClass]="'ice-tooltip'"
                    [matTooltipPosition]="'above'"
                    (mouseenter)="enterField($event, item.value)"
                    (mouseleave)="leaveField()"
                  >
                    {{ item.value }}
                  </div>
                  <div class="expansion-list-action-buttons" *ngIf="c.rowButtons | async">
                    <button
                      *ngFor="let button of c.rowButtons | async"
                      mat-icon-button
                      [matTooltip]="button.tooltip"
                      [matTooltipClass]="'ice-tooltip'"
                      [matTooltipPosition]="'above'"
                      (click)="button.onClick(item)"
                      [ngStyle]="{ visibility: button.getVisibility && button.getVisibility(item) }"
                    >
                      <mat-icon class="mat-icon material-icons" [ngClass]="button.class">{{ button.icon }}</mat-icon>
                    </button>
                  </div>
                </div>
                <div class="underline"></div>
              </div>
            </cdk-virtual-scroll-viewport>
          </ng-container>
          <ng-container *ngSwitchCase="'select'">
            <div class="title wsm-title">{{ c.selectTitle }}</div>
            <div *ngFor="let item of model">
              <div class="wsm-content">
                <div class="wsm-society ice-cell-bold fs-14">
                  <span [ngClass]="{ mark: item.highlight }"> {{ item.label }} </span>
                </div>
                <div class="mat-slide-content" *ngIf="c.actionEdit | async as actionEdit; else detail">
                  <mat-slide-toggle
                    [matTooltip]="actionEdit.tooltip + ':' + item.tooltip"
                    [matTooltipClass]="'ice-tooltip ice-tooltip-slide'"
                    [matTooltipPosition]="'above'"
                    (click)="actionEdit.onClick(item)"
                    [checked]="item.key"
                  >
                  </mat-slide-toggle>
                </div>
                <ng-template #detail>
                  <mat-slide-toggle
                    [matTooltip]="item.tooltip"
                    [matTooltipClass]="'ice-tooltip ice-tooltip-slide'"
                    [matTooltipPosition]="'above'"
                    [disabled]="true"
                    [checked]="item.key"
                  >
                  </mat-slide-toggle>
                </ng-template>
              </div>
              <div class="underline"></div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'form'">
            <ice-form
              [resetAvailable]="c.resetAvailable"
              [submitAvailable]="c.submitAvailable"
              [button1Available]="c.button1Available"
              [formBuilder]="c.formBuilder"
              [model]="model"
              [submitLabel]="c.submitLabel"
              [button1Label]="c.button1Label"
              [submitShortcutEnable]="config.submitShortcutEnable"
              (submit)="c.onSubmit($event)"
              (button1Clicked)="c.onButton1($event)"
            ></ice-form>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </mat-expansion-panel>
</ng-container>
