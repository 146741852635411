import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '@ice/i18n/en/expansion-list';
import { ActionButton } from 'models/action-button';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'ice-expansion-list',
  templateUrl: './expansion-list.component.html',
  styleUrls: ['./expansion-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpansionListComponent {
  @Input() title: string;
  @Input() className: string;
  @Input() model: any[];
  @Input() extraData: any;
  @Input() buttonTitle: string;
  @Input() iconConflict: Observable<string>;
  @Input() tooltipIconConflict: Observable<string>;
  @Input() expanded: Observable<Boolean>;
  @Input() selectable = false;
  @Input() filterItemsOn = 10; // Number of items to show the filter On.
  @Input() height;
  @Input() maxHeight = 300;
  @Input() hideExpansionIcon = false;
  @Input() actionButton: ActionButton;
  @Input() listIdentifier: string;
  @Input() cardClass: string;
  @Output() newTab: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedItemEmit = new EventEmitter<any>();
  @Output() mouseWheelClickRowEmit = new EventEmitter<any>();
  @Output() open: EventEmitter<any> = new EventEmitter<any>();
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  @Output() filteringBy: EventEmitter<string> = new EventEmitter<string>();

  filterValue = '';

  constructor(private fuseTranslationLoader: FuseTranslationLoaderService) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  clear(event) {
    event.stopPropagation();
    event.preventDefault();
    this.filterValue = '';
  }

  clickHeaderButton(ev: any, data: any): void {
    ev.stopPropagation();
    ev.preventDefault();
    if (data && data.workId) {
      data.workId.pipe(take(1)).subscribe((workId: string) => this.newTab.emit(workId));
    }
  }

  onSelect(item) {
    if (this.selectable) {
      this.selectedItemEmit.emit(item.key);
    }
  }

  onMouseWheelClickSelect(event, item) {
    if (event && this.selectable && event.which === 2 && item) {
      this.mouseWheelClickRowEmit.emit(item);
    }
  }

  onOpened() {
    if (this.open) {
      this.open.emit();
    }
  }

  onClosed() {
    if (this.close) {
      this.close.emit();
    }
  }

  filterChanged(value: string): void {
    this.filteringBy.emit(value);
  }
}
