export enum TerritoryDataType {
  TISN = 'tisn',
  TISA = 'tisa',
  NAME = 'name',
  TISA_EXT = 'tisaExt',
  OFFICIAL_NAME = 'officialName',
  UNOFFICIAL_NAME = 'unofficialName',
}

export const TERRITORIES = 'territories';

export const ATTRIBUTES = 'attributes';

export enum TerritoryType {
  COUNTRY = 'COUNTRY',
  ECONOMIC_GROUP = 'ECONOMIC_GROUP',
  POLITICAL_GROUP = 'POLITICAL_GROUP',
  GEOGRAPHIC_GROUP = 'GEOGRAPHIC_GROUP',
}

export const IceTerritoriesTisnList = [56, 196, 208, 233, 246, 276, 352, 372, 428, 440, 442, 470, 528, 578, 752, 826, 2109, 2110, 2111, 2127, 2131];

export enum TerritoryGroup {
  ICE_TERRITORIES = 'ICE Territories',
  OTHER_COUNTRIES = 'Other Countries',
  GEOGRAPHIC_GROUPS = 'Geographic Groups',
  ECONOMIC_GROUPS = 'Economic Groups',
  POLITICAL_GROUPS = 'Political Groups',
}

export const INCLUDE_WORLD_IN_EX_TISN = '+2136';
export const WORLD_TISN = '2136';
