import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TypeaheadContainerComponent } from './typeahead-container.component';
import { TypeaheadHighlight } from './typeahead-highlight.pipe';
import { TypeaheadMenuComponent } from './typeahead-menu.component';
import { TypeaheadDirective } from './typeahead.directive';

@NgModule({
  declarations: [TypeaheadContainerComponent, TypeaheadMenuComponent, TypeaheadDirective, TypeaheadHighlight],
  imports: [CommonModule],
  exports: [TypeaheadDirective, TypeaheadContainerComponent, TypeaheadMenuComponent, TypeaheadHighlight],
})
export class TypeaheadModule {}
