import { Injectable } from '@angular/core';
import { AgreementUtils } from '@ice';
import moment from 'moment';
import { ExpertSearchCommonFunctions } from './expert-search-common-functions';
import { ExpertSearchParseService } from './expert-search-parse.service';
import { SearchService } from './search.service';

export enum AgreementType {
  G = 'G',
  S = 'S',
}

@Injectable()
export class ExpertSearchParseAgreementsService extends ExpertSearchParseService {
  constructor(private searchService: SearchService) {
    super(
      {
        agreementType: Object.keys(AgreementType),
      },
      {
        agreementName: 'attributes.searchTerm',
        agreementReference: 'relations[XREF].otherId',
        assignorNameOrIp: 'assignor.partyName.attributes.name',
        assigneNameOrIp: 'assignee.partyName.attributes.name',
        startDate: 'attributes.shares.startDate',
        endDate: 'attributes.shares.endDate',
        agreementType: 'attributes.agreementType',
        sourceOfDocumentation: 'attributes.sourceOfDoc',
        agreementTerminated: async (comparator: string, value: string, _deep = false) => this.getTerminatedAgreements(value),
        retention: 'attributes.retention',
        groupIdByGroup: async (comparator: string, value: string, _deep = false) => AgreementUtils.getAgreementGroupQueryByRelationType('GROUP', value, comparator),
        groupIdByRecipient: async (comparator: string, value: string, _deep = false) => AgreementUtils.getAgreementGroupQueryByRelationType('RECIPIENT', value, comparator),
        groupIdByGroupOrRecipient: async (comparator: string, value: string, _deep = false) => ({
          or: ['GROUP', 'RECIPIENT'].map(type => AgreementUtils.getAgreementGroupQueryByRelationType(type, value, comparator)),
        }),
        territory: async (comparator: string, value: string, deep: boolean = false) => {
          if (!deep) {
            return {
              [comparator]: { 'attributes.territories': value },
            };
          } else {
            const codes = await this.searchService.getTerritoriesCountries(value.split('*').join('')).toPromise();
            const tisns = codes.tisns;
            return {
              and: (tisns || []).map(tisn => ({ equals: { 'attributes.shares.countries': tisn } })),
            };
          }
        },
        agreementRef: async (comparator: string, value: string, deep = false) => ExpertSearchCommonFunctions.agreementRefFunction(searchService, comparator, value, deep),
      },
    );
  }

  getTerminatedAgreements(value: string) {
    const regEx = /[*]/g;
    const formattedValue = value.replace(regEx, '');
    if (formattedValue === 'N') {
      return {
        and: [{ range: { 'attributes.shares.endDate': { gte: moment(new Date(), 'YYYYMMDD').format('YYYY-MM-DD') } } }],
      };
    }
    if (formattedValue === 'Y') {
      return {
        and: [{ range: { 'attributes.shares.endDate': { lt: moment(new Date(), 'YYYYMMDD').format('YYYY-MM-DD') } } }],
      };
    }
  }
}
