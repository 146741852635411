import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DataTableRow, SortInfo } from '@ice/components/data-table/data-table';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import { RootDatatable } from 'config/data-table-builders/root-datatable';
import { DataTableBuilder } from './data-table-builder';

export class ConflictsAgreementConflictDataTable extends RootDatatable implements DataTableBuilder {
  constructor(protected translate: TranslateService, protected fuseTranslationLoader: FuseTranslationLoaderService) {
    super(translate);
    this.fuseTranslationLoader.loadTranslations(english);
  }

  getDefaultSorting(): SortInfo[] {
    return [{ prop: 'id', dir: 'asc' }];
  }

  formatSort(sort: SortInfo) {
    switch (sort.prop) {
      case 'caseId':
        return { prop: `case.caseId`, dir: sort.dir };
      case 'caseStatus':
        return { prop: `case.status`, dir: sort.dir };
      case 'conflictData':
        return { prop: `attributes.entityType`, dir: sort.dir };
      case 'conflictType':
        return { prop: `attributes.conflictType`, dir: sort.dir };
      case 'deadline':
        return null;
      // No possible to server side sort, tested with:
      // return { prop: `works.work.activityTriggers.attributes.deadline`, dir: sort.dir };
      // return { prop: `works.work.minActivityTriggerDeadline`, dir: sort.dir };
      case 'distributionCategory':
        return { prop: `works.work.minActivityTriggerUploadCat`, dir: sort.dir };
      case 'customer':
        return null;
      // No possible to server side sort, tested with:
      // return { prop: `works.work.activityTriggers.attributes.soc`, dir: sort.dir };
      //  return { prop: `works.work.minActivityTriggerSoc`, dir: sort.dir };
      case 'userId':
        return { prop: `case.userId`, dir: sort.dir };
      case 'value':
        return { prop: `works.work.sumActivityTriggers`, dir: sort.dir };
    }
  }

  getDataTable(): DataTableRow[] {
    return [
      { name: this.translate.instant('AGREEMENT_CONFLICT.CASE_ID'), prop: 'id', flexGrow: 2 },
      { name: this.translate.instant('AGREEMENT_CONFLICT.CASE_STATUS'), prop: 'status', flexGrow: 1 },
      { name: this.translate.instant('AGREEMENT_CONFLICT.AGREEMENT_REF'), prop: 'agreementRefs', flexGrow: 1 },
      { name: this.translate.instant('AGREEMENT_CONFLICT.ASSIGNED_USER'), prop: 'assignedUser', flexGrow: 1.6 },
      { name: this.translate.instant('AGREEMENT_CONFLICT.CREATED_DATE'), prop: 'createdDate', flexGrow: 1.6 },
      { name: this.translate.instant('AGREEMENT_CONFLICT.DEADLINE'), prop: 'deadline', flexGrow: 0.8 },
      {
        name: '',
        prop: 'deadlineIconReached',
        icons: 'deadlineReached',
        cellClass: 'ice-search-results-font-16',
        flexGrow: 1,
        comparator: (valueA, valueB, modelA, modelB) => {
          const curr = modelA[`deadline`];
          const next = modelB[`deadline`];
          return curr > next ? 1 : -1;
        },
      },
    ];
  }

  getColumnMode(): ColumnMode {
    return ColumnMode.flex;
  }

  displayCheck(row: any, firstSelectedItem: any, targetItem: any, column?: any, value?: any): boolean {
    return true;
  }
}
