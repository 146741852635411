import { DEFAULT_SEARCH_FROM, DEFAULT_SEARCH_SIZE } from 'config/constants/search.constants';
import { ApiCall, ApiCallFactoryResolver, CustomApiCall, LabelResolverFunction, PropertyCleaner, RequestType, ResultCleaner } from 'config/sections-config/api-call';

/**
 * Creates an ApiCall object with the given parameters.
 * This object will travel all the way through the effects, reducers, and selectors to make an API call,
 * and perform different actions based on the properties of the object.
 *
 * The effect handling the API call is `FetchApiCall$`
 */
export const createApiCall = (url: string, requestType: RequestType, ...apiCallConfig: Partial<ApiCall>[]): ApiCall =>
  createApiCallFromBase({ ...apiCallConfig[0], requestType, url }, ...apiCallConfig);

export const isResponseHandler: Partial<ApiCall> = { responseHandler: true };

export const setCheckStatus: ApiCallFactoryResolver<any> = (sectionOrObject = '', customStatusPath?, isClone = false) => ({
  checkStatus: { sectionOrObject, customStatusPath, isClone },
});

export const setInclude: ApiCallFactoryResolver<string> = include => ({ queryParams: { include } });

/**
 * Sets the body of the API call configuration object.
 *
 * It does no other transformations to the body, so it should be a valid JSON string.
 */
export const setBody: ApiCallFactoryResolver<string> = body => ({ body });

export const setInitialBody: ApiCallFactoryResolver<string> = initialBody => ({ initialBody });

export const setInitialBodyArrayParameter: ApiCallFactoryResolver<string> = (bodyArrayParameter, bodyArrayParameterModel) => ({
  bodyArrayParameter,
  bodyArrayParameterModel,
});

export const setPageable: ApiCallFactoryResolver<number> = (from = DEFAULT_SEARCH_FROM, size = DEFAULT_SEARCH_SIZE) => ({ queryParams: { from, size }, pageable: true });

export const isPageable = setPageable();

export const validateLabels: Partial<ApiCall> = { validateLabels: true };

export const copyrightGlobal: Partial<ApiCall> = { copyrightGlobal: true };

export const isNOTPageable: Partial<ApiCall> = { pageable: false };

export const avoidFirstTime: Partial<ApiCall> = { avoidFirstTime: true };

export const allowForbiddenResponse: Partial<ApiCall> = { allowForbiddenResponse: true };

export const setQueryParams: ApiCallFactoryResolver<any> = queryParams => ({ queryParams });

export const createApiCallFromBase = (baseApiCall: ApiCall, ...apiCallConfig: Partial<ApiCall>[]) => {
  let apiCallOutput: ApiCall = baseApiCall;
  for (const apiCall of apiCallConfig) {
    const { queryParams, ...apicallRest } = apiCall;
    apiCallOutput = { ...apiCallOutput, ...apicallRest, ...(queryParams && { queryParams: { ...(apiCallOutput.queryParams || {}), ...queryParams } }) };
  }
  return apiCallOutput;
};
/**
 * Allows for the transformation of the result of the API call before it is stored in the redux state.
 *
 * @param0 response of the API call
 * @param1 slice of the current store state
 * @param2 cleanerData of the API call stored in the `cleanerData` property of the customApiCall
 * @param3 translate function
 * @returns object that will be used to be stored in the redux state
 */
export const createResultCleaner = <T = any, R = any>(resultCleaner: ResultCleaner<T, R>) => resultCleaner;

export const createPropertyCleaner = (propertyCleaner: PropertyCleaner) => propertyCleaner;

export const createLabelResolver = (labelResolver: Record<string, string | LabelResolverFunction>) => labelResolver;

export const setLabelResolver = (section: string, ...labelResolvers: Record<string, string | LabelResolverFunction>[]): Partial<ApiCall> => {
  let apiCallLabelResolver: Partial<ApiCall> = { labelSection: section, responseSection: section };
  for (const labelResolver of labelResolvers) {
    apiCallLabelResolver = { ...apiCallLabelResolver, labelResolver: { ...apiCallLabelResolver.labelResolver, ...labelResolver } };
  }
  return apiCallLabelResolver;
};

export const createPermissionResolver = (permission: string) => ({ validatePermission: permission });
/**
 * This method sets the result cleaner of the API call configuration object.
 * When the first argument is a string, it will be stored in the redux state with that property name.
 *
 * E.g. `setResultCleaner('agreementChains', getAgreementChainsResultCleaner)`
 * will set the item in the redux state as State.copyright[section].agreementChains
 */
export const setResultCleaner = (...args: [string | PropertyCleaner, ResultCleaner<any>][]): Partial<ApiCall> => {
  return {
    resultCleaner: args.map(resultCleanerPair => ({ property: resultCleanerPair[0], resultCleaner: resultCleanerPair[1] })),
  };
};

export const isCounterApiCall: Partial<ApiCall> = { queryParams: { includes: ``, from: 0, size: 0 }, pageable: false };

export const createCustomApiCall = (apiCall, apiCallData): CustomApiCall => ({ apiCall, apiCallData });

export const isAthena: Partial<ApiCall> = { isAthena: true };

export const fetchAllData: Partial<ApiCall> = { fetchAllData: true };
