import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseSharedModule } from '@fuse/shared.module';
import { IceMaterialModule } from '@ice/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { DataTableModule } from '../data-table/data-table.module';
import { FormContainerModule } from '../form-container/form-container.module';
import { CardComponent } from './card.component';

@NgModule({
  imports: [CommonModule, TranslateModule, IceMaterialModule, DataTableModule, FormContainerModule, FuseSharedModule, FlexLayoutModule],
  declarations: [CardComponent],
  exports: [CardComponent],
})
export class CardModule {}
