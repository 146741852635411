import { CC_STATUS_RESOLVED } from '@ice';
import { SectionsConfig } from 'config/sections-config';
import { RequestType } from 'config/sections-config/api-call';
import { environment } from 'config/env';
import { counterClaimActionsForDashboardQuery } from 'config/constants/counter-claims.constants';
import { SearchUtilsFactory } from 'store/root/utils';
import { CUBE } from 'config/constants/global.constants';
import { createApiCall, createApiCallFromBase, isCounterApiCall, isPageable, setBody, setInclude, setLabelResolver, setResultCleaner } from '../api-call.factory';
import { getAuditHistory, getAuditHistoryFilterEndDate, getAuditHistoryFilterStartDate } from '../common/common.api-calls';
import * as fromLabelResolvers from './counter-claims.label-resolvers';
import * as fromResultCleaners from './counter-claims.result-cleaners';

export const getCounterClaimsAuditHistoryFilterStartDate = createApiCallFromBase(getAuditHistory, getAuditHistoryFilterStartDate);

export const getCounterClaimsAuditHistoryFilterEndDate = createApiCallFromBase(getAuditHistory, getAuditHistoryFilterEndDate);

export const getOtherCounterClaims = createApiCall(
  `${environment.apiUrlCubeData}/counterclaims/CUBE/search`,
  RequestType.POST,
  setInclude(`attributes,tags,actions.attributes,participants,work,work.attributes`),
  isPageable,
  setBody(`{"and":[{"wildcard":{"attributes.entityType":"*work*"}},{"equals":{"attributes.workId":"<<workId>>"}},
  {"not":{"equals":{"attributes.status": "${CC_STATUS_RESOLVED}"}}},{"not":{"equals":{"id": "<<id>>"}}}]}`),
  setLabelResolver(SectionsConfig.COUNTERCLAIMS.name, fromLabelResolvers.getAttributesId, fromLabelResolvers.getWorkId),
  setResultCleaner(['otherCounterClaims', fromResultCleaners.getOtherCounterClaimsResultCleaner]),
);

export const getOtherCounterClaimsCount = createApiCallFromBase(
  getOtherCounterClaims,
  isCounterApiCall,
  setResultCleaner(['otherCounterClaimsCount', value => value && value.total]),
);

export const getCounterclaimActionsForDashboard = createApiCall(
  // This apiCall is used in lieu of the typical Dashboard `NewItemDoSearch` because
  // those `NewItemDoSearch` calls can't be integrated into our Export functionality
  SearchUtilsFactory.getSearchUrl(SectionsConfig.COUNTERCLAIMS_ACTIONS, CUBE),
  RequestType.POST,
  setInclude(`counterclaim.attributes, ownerName.attributes{name,firstName}, ownerName.relations{otherId,relation}, counterclaim.tags, tags,
  attributes, counterclaim.participants, counterclaim.work, counterclaim.work.attributes, counterclaim.work.relations,counterclaimParticipant`),
  isPageable,
  setLabelResolver(SectionsConfig.COUNTERCLAIMS.name, fromLabelResolvers.getActionsForDashboard),
  setBody(counterClaimActionsForDashboardQuery),
  setResultCleaner(['dashboardSearch.result', fromResultCleaners.getCounterclaimActionsResultCleaner]),
);
