<div fxLayout="row" style="height: 15.75px" fxLayoutAlign=" center" class="ice-form-datepicker-type">
  <input
    matInput
    [errorStateMatcher]="errorStateMatcher"
    [formControl]="formControl"
    [matDatepicker]="picker"
    [matDatepickerFilter]="onFilter"
    [formlyAttributes]="field"
    (dateInput)="onChange($event)"
    (change)="onChangeInput($event)"
    (focus)="onFocus($event)"
    (blur)="onBlur($event)"
    (paste)="onPaste($event)"
    attr.data-testid="date-{{ field.key }}"
    style="min-width: calc(100% - 22px); max-width: calc(100% - 22px); width: calc(100% - 22px)"
  />
  <div fxLayout="column" (focusout)="onPickerPanelFocusOut($event)" class="z-index-10" (blur)="onBlur($event)">
    <mat-datepicker-toggle
      attr.data-testid="toggle-calendar-{{ field.key }}"
      matSuffix
      fxFlexOrder="2"
      [for]="picker"
      fxLayoutAlign=" center"
      [disabled]="checkDisbaledCalendarIcon()"
    >
      <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker (opened)="onOpenPopup()"></mat-datepicker>
  </div>
</div>

<mat-icon
  *ngIf="field.templateOptions.infoText"
  fxLayout="column"
  fxFlex="10"
  fxFlexOrder="1"
  class="datepicker-info formFieldInfo-type z-index-20"
  color="accent"
  [matTooltipPosition]="'above'"
  matTooltip="{{ field.templateOptions.infoText }}"
  [matTooltipClass]="'ice-tooltip ice-tooltip-icon ice-txt-size-12'"
>
  info
</mat-icon>
