import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { IpUtils, TabsUtils } from '@ice';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import * as fromApiCalls from 'config/api-calls';
import { CopyrightWorksDataTable } from 'config/data-table-builders/copyright.works';
import { RootDatatable } from 'config/data-table-builders/root-datatable';
import { SelectionDatatableBuilder } from 'config/data-table-builders/selection-datatable-builder';
import { SectionsConfig } from 'config/sections-config';
import type { SectionTabConfig } from 'config/tabs-data-builders/tabs-data';
import { map } from 'rxjs/operators';
import * as fromRoot from 'store/root';

export class TabIpWorks extends RootDatatable implements SectionTabConfig {
  private worksDatatable;
  private selectionDatatable: SelectionDatatableBuilder;
  constructor(protected translate: TranslateService, protected fuseTranslationLoader: FuseTranslationLoaderService, protected store: Store<fromRoot.RootState>) {
    super(translate);
    this.fuseTranslationLoader.loadTranslations(english);
    this.worksDatatable = new CopyrightWorksDataTable(this.translate, this.fuseTranslationLoader);
    const onSelect = (event: any[]) => {
      // Force tab to avoid error if source work has the same ICE Key
      const defaultTabName = TabsUtils.getDefaultTabName(SectionsConfig.WORKS.name);
      this.store.dispatch(
        new fromRoot.Go({
          path: [`copyright/${SectionsConfig.WORKS.name}/${event[0].key || event[0].id}/${defaultTabName}`],
        }),
      );
    };
    this.selectionDatatable = new SelectionDatatableBuilder(
      this.store,
      this.worksDatatable.getDataTable(false),
      onSelect,
      fromApiCalls.getIpsWorks,
      null,
      null,
      this.store.pipe(select(fromRoot.getIpDetailWorks)),
    );
  }

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              titleWithCount: this.getTitle(),
              model: this.store.pipe(select(fromRoot.getIpDetailWorks)),
              sorts: [],
              ...this.selectionDatatable.getSelectionDatatableConfig(),
              loadingIndicator: false,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: true,
              shadowed: false,
              columnMode: 'flex',
              getRowClass: (row: any): any => ({ 'ice-search-results-table-row': true }),
              sortConfig: { format: this.worksDatatable.formatSort, sortPrefix: 'work.' },
              onMouseSelect: event => {
                this.store.dispatch(
                  new fromRoot.OpenNewTab({
                    path: [`copyright/${SectionsConfig.WORKS.name}/${event.key || event.id}`],
                  }),
                );
              },
            },
          },
        ],
      },
    ];
  }

  getTitle() {
    return this.store.pipe(
      select(fromRoot.getWorksCount),
      map((workscount: number) => {
        return IpUtils.getTabTableTitle(workscount, this.translate.instant('IPS.WORKS.TAB_TITLE_SINGULAR'), this.translate.instant('IPS.WORKS.TAB_TITLE'));
      }),
    );
  }
}
