import { createLabelResolver } from '../api-call.factory';

export const getAgreementWorksLabelResolver = createLabelResolver({
  extraParam: (detail, user, tabName) => (tabName === 'included-works' ? 'true' : 'false'),
});

export const getAttributesId = createLabelResolver({ id: `attributes.id` });

export const getAttributesKey = createLabelResolver({ id: `attributes.key` });

export const getSubmitterPartyCondition = createLabelResolver({
  submitterPartyId: detail => detail?.attributes?.submitterPartyNameIds,
});

export const getSubmitterPartyRelationPatternLabelResolver = createLabelResolver({
  pattern: () => `{"equals":{"relations[XREF].otherId":"<<submitterPartyId>>"}}`,
});
