import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { IpUtils } from '@ice/utils/ip/ip.utils';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import * as fromApiCalls from 'config/api-calls';
import { LinkedByType, LinkedTabsName } from 'config/constants/ips.constants';
import { CopyrightLinkedDataTable } from 'config/data-table-builders/copyright.linked';
import { SelectionDatatableBuilder } from 'config/data-table-builders/selection-datatable-builder';
import { SectionsConfig } from 'config/sections-config';
import { SectionTabConfig } from 'config/tabs-data-builders/tabs-data';
import { get } from 'lodash';
import { map } from 'rxjs/operators';
import { NamespaceService } from 'services/namespace/namespace.service';
import { SearchService } from 'services/search/search.service';
import * as fromNewSectionItem from 'store/new-section-item';
import * as fromRoot from 'store/root';

export class TabIpLinked implements SectionTabConfig {
  private linkedDatatable: CopyrightLinkedDataTable;
  private linkedByType: LinkedByType;
  private tabNameLabel: string;
  private countSelector: any;

  private selectionDatatable: SelectionDatatableBuilder;

  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private dialog: null,
    private commonApiService: null,
    private detailService: null,
    protected nsService: NamespaceService,
    private iceFacade: null,
    private fieldValidatorService: null,
    private permissionsService: null,
    private searchService: SearchService,
    private newStore: Store<fromNewSectionItem.NewSectionItemState>,
    tabName,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.linkedDatatable = new CopyrightLinkedDataTable(this.translate, this.fuseTranslationLoader, store);
    this.linkedByType = tabName === LinkedTabsName.Publishers ? LinkedByType.Publishers : LinkedByType.Writers;
    this.tabNameLabel = this.linkedByType.replace(/-/g, '_').toUpperCase();
    this.countSelector = this.linkedByType === LinkedByType.Publishers ? fromRoot.getLinkedPublishersCount : fromRoot.getLinkedWritersCount;

    const onSelect = (event: any[]) => {
      this.store.dispatch(
        new fromRoot.Go({
          path: [`copyright/${SectionsConfig.IPS.name}/${event[0].ipNameKeyRaw}/details`],
        }),
      );
    };
    this.selectionDatatable = new SelectionDatatableBuilder(
      this.store,
      this.linkedDatatable.getDataTable(this.linkedByType, row => {
        this.store.dispatch(
          new fromRoot.StartApiCall({
            apiCall: this.linkedByType === LinkedByType.Publishers ? fromApiCalls.getWriterPublisherDetails : fromApiCalls.getPublisherWriterDetails,
            apiCallData: {
              labels: { writerId: row.writerId, publisherId: row.publisherId },
            },
            callBack: response => this.afterGetWritersAndPublishers(response),
          }),
        );
      }),
      onSelect,
      null,
    );
  }

  afterGetWritersAndPublishers(response) {
    if (!response.error) {
      const xref = response && (get(response, 'items[0].publisher.workIds') || get(response, 'items[0].writer.workIds'));
      if (Array.isArray(xref)) {
        this.store.dispatch(new fromRoot.SetSearchXrefList(xref.slice(0, 1000)));
        this.store.dispatch(
          new fromRoot.Go({
            path: [SectionsConfig.WORKS.domainName, SectionsConfig.WORKS.name, 'search'],
            extras: { queryParams: { xrefList: true } },
          }),
        );
      }
    }
  }

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              titleWithCount: this.getTitle(),
              model: this.linkedByType === LinkedByType.Publishers ? this.store.pipe(select(fromRoot.getIpLinkedPublishers)) : this.store.pipe(select(fromRoot.getIpLinkedWriters)),
              sorts: [],
              ...this.selectionDatatable.getSelectionDatatableConfig(),
              loadingIndicator: false,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: true,
              shadowed: false,
              columnMode: 'flex',
              onMouseSelect: event => {
                this.store.dispatch(
                  new fromRoot.OpenNewTab({
                    path: [`copyright/${SectionsConfig.IPS.name}/${event.ipNameKeyRaw}/details`],
                  }),
                );
              },
            },
          },
        ],
      },
    ];
  }

  getTitle() {
    return this.store.pipe(
      select(this.countSelector),
      map((count: number) => {
        return IpUtils.getTabTableTitle(
          count,
          this.translate.instant(`IPS.LINKED_${this.tabNameLabel}.TAB_TITLE_SINGULAR`),
          this.translate.instant(`IPS.LINKED_${this.tabNameLabel}.TAB_TITLE`),
        );
      }),
    );
  }
}
