import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/material';

@Component({
  selector: 'ice-formly-label',
  templateUrl: './label-type.component.html',
  styleUrls: ['./label-type.component.scss'],
})
export class FormlyFieldLabelComponent extends FieldType {
  onClick(event, model) {
    event.preventDefault();
    if (this.to.onClick) {
      this.to.onClick(model, this.formControl);
    }
  }

  getCurrentError() {
    if (this.to.errorMessages) {
      const errors = this.formControl.errors;
      const currentError = Object.keys(errors).find(key => errors[key]);
      return this.to.errorMessages[currentError];
    }
    return '';
  }
}
