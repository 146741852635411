import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IceMaterialModule } from '@ice/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { ExtendedWorkSelectorComponent } from './extended-work-selector.component';

@NgModule({
  declarations: [ExtendedWorkSelectorComponent],
  imports: [CommonModule, TranslateModule, IceMaterialModule, FlexLayoutModule],
  exports: [ExtendedWorkSelectorComponent],
})
export class ExtendedWorkSelectorComponentModule {}
