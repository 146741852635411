import { Clipboard } from '@angular/cdk/clipboard';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SecurityContext,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DateTimeUtils, FetchApiCallUtils, RouterUtils, StringUtils } from '@ice';
import { locale as english } from '@ice/i18n/en/data-table';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { ApiCallConfig, ApiCallSortConfig, isApiCallConfig, isCustomApiCall } from 'config/sections-config/api-call';
import { ExpandableData } from 'config/tabs-data-builders/shared/audit-history.model';
import { cloneDeep, concat, differenceWith, findIndex, get, intersectionBy, isEqual, last, find as lodashFind, takeRight } from 'lodash';
import { ActionButton } from 'models/action-button';
import { Observable, Subject, isObservable, of } from 'rxjs';
import { debounceTime, delay, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { DatatableService } from 'services/datatable/datatable.service';
import * as fromActions from 'store/root/actions';
import { SCROLL_TRIGGER_RATIO } from 'config/constants/global.constants';
import { DataTableTotals } from '../data-table-totals/data-table-totals';
import { DataTable, DataTableMessages, DataTableRow, FooterOptions, SortInfo } from './data-table';

@Component({
  selector: 'ice-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTableComponent implements DataTable, OnChanges, OnInit, AfterViewInit, OnDestroy {
  originalData;
  @Input() set data(data: any[]) {
    const section = RouterUtils.getSectionFromUrl(this.router);
    data = DateTimeUtils.cleanObjectsIndefiniteDate(data, section);
    this._data = data;
    this._allData = cloneDeep(data);
    if (data && data[0] && data[0].groupBy && data[0].groupBy !== '') {
      this.groupRowsBy = 'groupBy';
      this.groupExpansionDefault = 'true';
    } else {
      this.groupRowsBy = null;
      this.groupExpansionDefault = null;
    }
    this.autoSelectData(data);
  }
  get data() {
    return this._data;
  }
  private section = RouterUtils.getSectionFromUrl(this.router);
  private iconDown = 'datatable-icon-down';
  private iconUp = 'datatable-icon-up';

  constructor(
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private detCh: ChangeDetectorRef,
    private store: Store<any>,
    private datatableService: DatatableService,
    private router: Router,
    public translate: TranslateService,
    private clipboard: Clipboard,
    private sanitizer: DomSanitizer,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  @Output() sortRowEmit = new EventEmitter<any>();
  @Output() selectedRowEmit = new EventEmitter<any>();
  @Output() selectedExpandableRowEmit = new EventEmitter<any>();
  @Output() selectedCheckboxEmit = new EventEmitter<any>();
  @Output() mouseWheelClickRowEmit = new EventEmitter<any>();
  @Output() allSelected = new EventEmitter<any>();
  @Output() reorderColumn = new EventEmitter<any>();
  @Output() resized = new EventEmitter<any>();
  @Input() sorts;
  @Input() detailSorts;
  @Input() virtualization = true;
  @Input() schema: DataTableRow[] | Observable<DataTableRow[]>;
  @Input() expandableSchema: Record<string, DataTableRow[]>;
  @Input() displayCheck: (row: any, column?: any, value?: any) => boolean;
  @Input() displayRadio: (row?: any, column?: any, value?: any) => boolean;
  @Input() loadingIndicator = false;
  @Input() showLoader = false;
  @Input() reorderable = true;
  @Input() shadowed = false;
  @Input() columnMode = ColumnMode.flex;
  @Input() hideEmpty = false;
  @Input() height;
  @Input() fixedHeight;
  @Input() maxHeight;
  @Input() footerHeight = 0;
  @Input() footerOptions: FooterOptions;
  @Input() rowHeight = 0;
  @Input() scrollbarV = false;
  @Input() scrollbarH = false;
  @Input() selectionType = SelectionType.single;
  @Input() isSelectableMode: Observable<boolean>;
  @Input() selectableClass = true;
  @Input() isLoadingData = false;
  @Input() messages: DataTableMessages;
  @Input() isSelectable = false;
  @Input() visibleColumns: Observable<string[]>;
  @Input() detailVisibleColumns: Observable<string[]>;
  @Input() requestStatus: Observable<any[]>;
  @Input() className: string;
  @Input() selectedFilter: string;
  @Input() sliceLimit?: number;
  @Input() cssClass: string;
  @Input() counterClaimType: Observable<string>;
  /** @prop {boolean}
   * Allows the property `selected` in ice-data-table to be set to empty array.
   * ```html [selected]="[]" ```
   *
   * Allows in ice-data-table set selected to ignore the default behaviour
   * of select similar items in the data
   * */
  @Input() singleSelection = false;
  @Input() set selected(selected) {
    this._selected = selected?.length > 0 ? selected : this._selected || [];
    if (this.singleSelection) {
      this._selected = selected || [];
    }
  }
  get selected() {
    return this._selected;
  }
  /**
   * An input property that defines a custom function for uniquely identifying rows within the `ice-data-table`.
   * This function is used to determine the identity of each row, which is crucial for operations such as selection,
   * sorting, or any other manipulation where distinguishing between rows is necessary.
   *
   * The `rowIdentity` function should return a unique identifier for a given row. This identifier is typically derived
   * from the row's data itself, such as a unique ID field. The function is called with the row's data object as its argument.
   *
   * Setting this input allows for custom logic in identifying rows, which can be useful in cases where the default
   * identification mechanism may not suit the specific needs of the data or the desired behavior of the table.
   *
   * @Input() rowIdentity - A function that takes a row's data object as an argument and returns a unique identifier for that row.
   *                        The unique identifier can be of any type that supports equality checks (e.g., string, number).
   *
   * @example
   * // Define a row identity function that uses an 'id' property of each row's data as the unique identifier.
   * rowIdentityFunction = (row) => row.id;
   *
   * // In your HTML template, bind the `rowIdentity` input to your custom function.
   * <ice-data-table [rowIdentity]="rowIdentityFunction"></ice-data-table>
   */
  @Input() rowIdentity?: (item: any) => number | string;
  @Input() selectedItemsInDialog: any[];
  @Input() expandableProperty: Observable<string>;
  @Input() selectedKey: string;
  @Input() dontStrip = false;
  @Input() customClass = '';
  @Input() expandRowsEnabled = false;
  @Input() sortConfig: ApiCallSortConfig;
  @Input() apiCall: ApiCallConfig | (() => ApiCallConfig);
  @Input() selectionMode: boolean;
  @Input() totals: DataTableTotals;
  @Input() expandableNoHeader?: boolean;
  @Input() addDetailsOnMainDataTable = false;
  @Input() shouldAddParentRowClass = false;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('noTooltipCellTemplate', { static: true }) noTooltipCellTemplate: TemplateRef<any>;
  @ViewChild('customButtonCellTemplate', { static: true }) customButtonCellTemplate: TemplateRef<any>;
  @ViewChild('iconRightOfTextCellTemplate', { static: true }) iconRightOfTextCellTemplate: TemplateRef<any>;

  @Input() set headerHeight(height) {
    if (height !== undefined) {
      this._headerHeight = height;
    } else {
      this._headerHeight = 60;
    }
  }
  get headerHeight() {
    return this._headerHeight;
  }
  @Input() sortReset = '';
  @Input() headerTitle: Observable<string>;
  @Input() headerClass: string;
  @Input() disabledSort: Observable<boolean>;
  @Input() doPagination: any;
  @Input() rowFixedDetails: { property: string; class: string };
  @Input() tableActionButton: ActionButton;
  @Input() expandAllRows$: Observable<boolean>;
  _headerHeight = 50;
  _data: any[];
  filterSubject = new Subject();
  _allData: any[];
  originalSchema: any;
  _columnMode: ColumnMode;
  _selected: any[] = [];
  selectedItems: Observable<any[]>;
  public _schema: DataTableRow[];
  public schemaIsResolved: boolean;
  public groupRowsBy: string = null;
  public groupExpansionDefault: string = null;
  public filter = {};
  private unsubscribeAll = new Subject();
  public _expandableSchema: DataTableRow[];
  private autoSelectAll = 0;
  headerTotals: DataTableTotals;
  public lastSelectableValue: boolean;
  private customButtonsHover = {};
  private customButtonsConfig = {};

  ngOnInit() {
    this.filterSubject.pipe(debounceTime(250), takeUntil(this.unsubscribeAll)).subscribe((field: string) => {
      this._data = [
        ...this._allData.filter(row => {
          return (row[field] || '').toLowerCase().includes((this.filter[field] || '').toLowerCase());
        }),
      ];
      if (!this.detCh['destroyed']) {
        this.detCh.detectChanges();
      }
    });
    this.datatableService.scrollApiCall = this.apiCall;
    this.datatableService.forceSort.pipe(takeUntil(this.unsubscribeAll)).subscribe(_ => {
      this.table.onColumnSort({ sorts: this.sorts });
    });
    if (this.totals) {
      this.headerTotals = {
        class: this.totals.class,
        totalsTiles: this.totals.totalsTiles.pipe(map(tiles => takeRight(tiles, 3))),
      };
    }
    if (this.isSelectableMode) {
      this.isSelectableMode.pipe(takeUntil(this.unsubscribeAll)).subscribe(selectable => {
        if (selectable !== this.lastSelectableValue) {
          this.lastSelectableValue = selectable;
          this.selected = [];
          this._selected = [];
          this.autoSelectAll = 0;
        }
      });
    }

    this.expandAllRows$?.pipe(takeUntil(this.unsubscribeAll)).subscribe(expand => {
      if (expand) {
        this.expandAllRows();
      } else {
        this.collapseAllRows();
      }
    });

    this.rowIdentity = this.rowIdentity || (row => row?.id);
  }

  ngAfterViewInit() {
    this.resolveSchemaAndInit();
  }

  onScroll(event) {
    if (event?.target) {
      const { scrollTop, offsetHeight, scrollHeight } = event.target;
      if (!!this.doPagination && Math.round(scrollTop + SCROLL_TRIGGER_RATIO * offsetHeight) >= scrollHeight) {
        this.doPagination();
      }
      const dataTableElem = document.body.querySelector('ngx-datatable');
      if (dataTableElem && Math.round(scrollTop + SCROLL_TRIGGER_RATIO * offsetHeight) >= scrollHeight) {
        const scrollApiCallConfigPayload = this.datatableService.scrollApiCall;
        const scrollApiCallConfig = scrollApiCallConfigPayload && (isApiCallConfig(scrollApiCallConfigPayload) ? scrollApiCallConfigPayload : scrollApiCallConfigPayload());
        if (!!scrollApiCallConfig) {
          this.store.dispatch(new fromActions.PaginateApiCall(scrollApiCallConfig));
        }
      }
    }
  }

  onResized(event) {
    this.resized.emit(true);
  }

  onFilter(event) {
    this.filter[event.column] = event.value;
    this.filterSubject.next(event.column);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.addSelectedItems();
  }

  getDatatableClasses() {
    const datatableClasses = [];
    if (this.cssClass) {
      datatableClasses.push('hierarchy-colo-table datatable-padding');
    } else {
      datatableClasses.push('material ice-table');
    }
    if (this.isSelectable) {
      datatableClasses.push('is-selectable');
    }
    if (!this.dontStrip) {
      datatableClasses.push('striped');
    }
    if (this.customClass) {
      datatableClasses.push(this.customClass);
    }
    if (this.shadowed) {
      datatableClasses.push('table-shadow');
    }
    return datatableClasses.join(' ');
  }

  addSelectedItems() {
    if (this.selectedItemsInDialog) {
      this._selected = this.getSelectedFromStepperData(this.selectedItemsInDialog, this.data);
    }
  }

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    this.datatableService.scrollApiCall = null;
  }

  onSelect(event) {
    const selection = window.getSelection();
    // Click disabled when selecting text
    if (selection.toString().length === 0) {
      this.selectedRowEmit.emit(event.selected);
      this.setAutoSelect(this.data, event.selected);
    }
    if (this.displayCheck && !this.shouldAddParentRowClass) {
      this.data = cloneDeep(this.data);
    }
  }

  onActivate(event) {
    if (event.type === 'checkbox') {
      this.selectedCheckboxEmit.emit(event);
    } else if (event.type === 'middleclick') {
      this.mouseWheelClickRowEmit.emit({
        ...event.row,
        activatedColumn: event.column && event.column.prop,
        redirectPath: event.column && event.column.redirectPath,
      });
    }
  }

  reorder(event) {
    const columns = cloneDeep(this._schema);
    const previous = columns[event.prevValue];
    columns[event.prevValue] = columns[event.newValue];
    columns[event.newValue] = previous;
    this.reorderColumn.emit(columns);
  }

  getMessage() {
    if (!this.messages) {
      this.messages = this.getDataTableDefaultMessages(this.translate);
    }
    return this.messages && this.messages.loadingMessage && this.messages.noResultsMessage
      ? this.isLoadingData
        ? this.messages.loadingMessage
        : this.messages.noResultsMessage
      : '';
  }

  private autoSelectData(data: any[] = []) {
    if (Array.isArray(data) && data.length && this.autoSelectAll && this.autoSelectAll < data.length) {
      this.selected = [...data];
      this._selected = this.selected;
      this.autoSelectAll = data.length;
      this.selectedRowEmit.emit(this._selected);
    }
  }

  private setAutoSelect(data: any[] = [], selected?) {
    this.autoSelectAll = 0;
    if (this.selectionType === SelectionType.checkbox) {
      if (Array.isArray(selected) && Array.isArray(data) && data.length && selected.length) {
        if (selected.length === data.length) {
          this.autoSelectAll = data.length;
          this.allSelected.emit(true);
        } else {
          this.autoSelectAll = 0;
          this.allSelected.emit(false);
        }
      }
      this._selected = selected;
    }
  }

  getExpandableData(row) {
    return this.expandableProperty
      ? this.expandableProperty.pipe(
          take(1),
          map(property => row[property]),
        )
      : of(row.detail);
  }

  getExpandableDataHeight(datatable, row, returnObservable) {
    return datatable.length > 8
      ? returnObservable
        ? of(320)
        : '320px'
      : this.expandableProperty
      ? this.expandableProperty.pipe(
          take(1),
          map(property => row[property]?.length * 41 + 1 || 0),
        )
      : returnObservable
      ? of(0)
      : '0';
  }

  getExpandableHeaderHeight() {
    return this.expandableProperty
      ? this.expandableProperty?.pipe(
          take(1),
          map(property => (property && this.expandableSchema && this.expandableSchema[property] ? this.headerHeight : 0)),
        )
      : of(this.headerHeight);
  }

  private getSelectedFromStepperData(selected: any[] = [], data: any[] = []): any[] {
    const selectedElemsFromData = [];
    if (this.selectedKey) {
      selected.forEach(selectedElem => {
        data.forEach(dataElem => {
          if (dataElem[this.selectedKey] === selectedElem[this.selectedKey]) {
            selectedElemsFromData.push(dataElem);
          }
        });
      });
    }
    return selectedElemsFromData;
  }

  onSort(event) {
    if (event && event.sorts) {
      this.sortRowEmit.emit(event.sorts);
      const apiCallConfig = this.apiCall && (isApiCallConfig(this.apiCall) ? this.apiCall : this.apiCall());
      const apiCall = FetchApiCallUtils.getApiCall(apiCallConfig);
      const apiCallData = isCustomApiCall(apiCallConfig) && apiCallConfig.apiCallData;
      if (this.sortConfig && apiCall) {
        const firstSort: SortInfo = event.sorts[0];
        if (firstSort && firstSort.prop !== '') {
          const sortInfo: SortInfo = firstSort && this.sortConfig.format(firstSort);
          const sort: string = sortInfo ? `${this.sortConfig.sortPrefix || ''}${sortInfo.prop}:${sortInfo.dir}` : '';
          if (sort) {
            this.store.dispatch(new fromActions.StartApiCall({ apiCall, apiCallData: { ...apiCallData, queryParams: { ...apiCallData.queryParams, sort } } }));
            this.datatableService.scrollApiCall = () => {
              const apiCallConfigInternal = this.apiCall && (isApiCallConfig(this.apiCall) ? this.apiCall : this.apiCall());
              const apiCallInternal = FetchApiCallUtils.getApiCall(apiCallConfigInternal);
              const apiCallDataInternal = isCustomApiCall(apiCallConfigInternal) && apiCallConfigInternal.apiCallData;
              return { apiCall: apiCallInternal, apiCallData: { ...apiCallDataInternal, queryParams: { ...apiCallDataInternal.queryParams, sort } } };
            };
          }
        }
      }
    }
  }

  private resolveSchemaAndInit(): void {
    if (Array.isArray(this.schema)) {
      this._schema = this.schema;
      this.setCustomCellTemplates();
      this.init();
    } else {
      this.schema?.pipe(takeUntil(this.unsubscribeAll)).subscribe(schemaData => {
        this._schema = schemaData;
        this.setCustomCellTemplates();
        if (!this.schemaIsResolved) {
          this.init();
        }
      });
    }
    this.expandableProperty
      ?.pipe(
        map(property => (property && this.expandableSchema && this.expandableSchema[property]) || this._schema),
        switchMap(schema => {
          if (!isEqual(schema, this._expandableSchema)) {
            this.expandRowsEnabled = false;
            return of(schema).pipe(delay(200));
          } else {
            return of(schema);
          }
        }),
        takeUntil(this.unsubscribeAll),
      )
      .subscribe(schema => {
        this.expandRowsEnabled = true;
        this._expandableSchema = schema;
        if (!this.detCh['destroyed']) {
          this.detCh.detectChanges();
        }
      });
  }

  // BUG FIX
  private forceResize() {
    this.schemaIsResolved = true;
    if (!this.detCh['destroyed']) {
      this.detCh.detectChanges();
    }
    setTimeout(() => {
      // Hack to return to force (column resizing enabled) after paint columns correctly with other columnMode
      this._columnMode = ColumnMode.force;
      if (!this.detCh['destroyed']) {
        this.detCh.detectChanges();
      }
    }, 400);
  }

  private init(): void {
    if (this._schema && !this.originalSchema) {
      this.originalSchema = cloneDeep(this._schema);
    }
    if (this.visibleColumns) {
      this.visibleColumns.pipe(takeUntil(this.unsubscribeAll)).subscribe(cols => {
        if (cols) {
          this._schema = this.originalSchema
            .filter(field => {
              return field.prop === '' || lodashFind(cols, o => o === field.prop); // to show cols with icons and no property associated
            })
            .sort((a, b) => findIndex(cols, col => a.prop === col) - findIndex(cols, col => b.prop === col));
          this._columnMode = this.columnMode;
          this.forceResize();
          this.disableMouseWheelEvent();
        }
      });
    } else {
      this._columnMode = this.columnMode;
      this.forceResize();
      this.disableMouseWheelEvent();
    }
  }

  setDatatableOffset(offset) {
    if (this.table && !this.detCh['destroyed'] && offset >= 0) {
      this.table.bodyComponent.scroller.setOffset(offset);
      this.detCh.detectChanges();
    }
  }

  expandRows(): void {
    // Hack that allows us to expend RowDetails, before ngx-datatable is redrown
    // fixes blinking of RowDetails on this.rows changes
    // uses advantage of js microtask
    Promise.resolve().then(() => {
      this.data.forEach(x => this.table.rowDetail.toggleExpandRow(x));
      if (!this.detCh['destroyed']) {
        this.detCh.detectChanges();
      }
    });
  }

  expandAllRows(): void {
    Promise.resolve().then(() => {
      this.table.rowDetail.expandAllRows();
    });
  }

  collapseAllRows(): void {
    Promise.resolve().then(() => {
      this.table.rowDetail.collapseAllRows();
    });
  }

  getRowClass(row) {
    const baseClass = { 'indent-row': true };
    const indentClass = `indent-${row.level}`;
    const { rowClass } = row;
    if (rowClass) {
      baseClass[rowClass] = true;
    }
    baseClass[indentClass] = true;
    if (row.markAsClaimantClass) {
      baseClass[row.markAsClaimantClass] = true;
    }
    if (row.conflict === 'Y') {
      baseClass['datatable-row-bold'] = true;
    }
    return baseClass;
  }

  mouseWheelHandler = (_element: any) => {
    const element: any = window.event || _element; // old IE support
    if (element.button !== 1) {
      return;
    }
    element.preventDefault();
    element.stopPropagation();
    return false;
  };

  private disableMouseWheelEvent() {
    const tables: NodeListOf<any> = document.querySelectorAll('ngx-datatable');
    tables?.forEach(table => {
      if (table?.addEventListener) {
        table?.addEventListener('mousedown', this.mouseWheelHandler, false);
      } else {
        table?.attachEvent('mousedown', this.mouseWheelHandler);
      }
    });
  }

  private setCustomCellTemplates() {
    if (this._schema) {
      const schemaMod = this._schema.map(column => {
        if (column.isNoTooltipCell) {
          column['cellTemplate'] = this.noTooltipCellTemplate;
        }
        if (column.customButton) {
          column['cellTemplate'] = this.customButtonCellTemplate;
        }
        if (column.iconRightOfText) {
          column['cellTemplate'] = this.iconRightOfTextCellTemplate;
        }
        return column;
      });
    }
  }

  onResetSort() {
    this.datatableService.forceSort.next();
    this.detCh.detectChanges();
  }

  convertSpacesToHtml(schema: DataTableRow[], data: any): any {
    // Related to this type of bugs: https://ice-cube.atlassian.net/browse/CUBE-13012
    let newData = data;
    const propsToTransform = schema?.filter((fieldConfig: DataTableRow) => fieldConfig.spacesToHtmlCode).map(fieldConfig => fieldConfig.prop);
    if (propsToTransform) {
      newData = data?.map(row => {
        const formattedRow = row;
        if (formattedRow) propsToTransform.forEach(fieldProp => (formattedRow[fieldProp] = StringUtils.visualizeAllSpaces(formattedRow[fieldProp].toString())));
        return formattedRow;
      });
    }
    return newData;
  }

  getRows(): Observable<any> {
    const slicedRows = this.sliceLimit ? this.data.slice(0, this.sliceLimit) : this.data;
    if (isObservable(this.schema)) {
      return this.schema.pipe(
        map(schema => this.getRowsData(schema, slicedRows)),
        takeUntil(this.unsubscribeAll),
      );
    } else {
      return of(this.getRowsData(this.schema, slicedRows));
    }
  }

  private getRowsData(schema: DataTableRow[], slicedRows: any[]): any {
    if (!schema) {
      return [];
    }

    const newData = this.convertSpacesToHtml(schema, slicedRows);
    if (this.autoSelectAll) {
      this._selected = newData;
    } else {
      if (!this.singleSelection) {
        this._selected = intersectionBy(newData, this._selected, item => get(item, 'id', get(item, 'ipiNameNumber', get(item, 'ClaimantIPNameNumber', ''))));
      }
    }
    return newData;
  }

  onSelectExpandable(expandableData: ExpandableData) {
    const selection = window.getSelection();
    // Click disabled when selecting text
    if (selection.toString().length === 0) {
      this.selectedExpandableRowEmit.emit(expandableData);
    }
  }

  onDetailToggle(event) {
    if (this.shouldAddParentRowClass) {
      this.manageAllParentsClass(event);
    } else if (this.addDetailsOnMainDataTable) {
      this.manageDetailData(event.value);
    } else {
      this.manageParentClass(event.value);
    }
  }

  manageAllParentsClass(event): void {
    if (event.type === 'all') {
      this.table.rows.forEach(row => {
        if (event.value) {
          row.rowClass += !row.rowClass?.includes('row-parent') ? ' row-parent' : '';
        } else {
          row.rowClass = row.rowClass?.replace('row-parent', '');
        }
      });
    } else {
      const { index } = event.value;
      const targetRow = this.table.rows.find(row => row.index === index);
      const targetRowGroup = this.getClaimClassGroup(targetRow?.rowClass);

      this.toggleParentClass(targetRow);

      const previousRow = this.table.rows.find(row => row.index === index - 1);
      const topIpRow = this.table.rows.find(row => row.index === index - 2);
      const topIpRowGroup = this.getClaimClassGroup(topIpRow?.rowClass);

      if (previousRow?.rowClass?.includes('claimant-row')) {
        this.toggleParentClass(previousRow);
      }
      // only togle if topIpRow and target row are from same gruop
      if (!topIpRow?.relation && targetRowGroup === topIpRowGroup) {
        this.toggleParentClass(topIpRow);
      }
    }
  }
  toggleParentClass(row: any): void {
    if (row) {
      if (!row.rowClass?.includes('row-parent')) {
        row.rowClass = (row.rowClass || '') + ' row-parent';
      } else {
        row.rowClass = row.rowClass?.replace('row-parent', '');
      }
    }
  }

  getClaimClassGroup(rowClass: string): string {
    const pattern = /(first-group|second-group)/;
    const match = pattern.exec(rowClass);
    return match?.[0];
  }

  manageDetailData(row: any) {
    if (row.detail?.length > 1) {
      const element = document.activeElement;
      const icons = Array.from(document.querySelectorAll('a[class*="datatable-icon"]'));
      const iconIndex = findIndex(icons, icon => icon === element);
      const match = icons[iconIndex];
      const detailData = row.detail.map(detail => ({ ...detail, date: row.date, rowClass: 'row-child' }));
      const lastDetails = last(detailData);
      lastDetails['rowClass'] += ' last-child';

      if (findIndex(this.data, detailData[0] || {}) === -1) {
        row['rowClass'] = 'row-parent';
        match.classList.remove(this.iconDown);
        match.classList.add(this.iconUp);
        this.insertDetailsRows(row, detailData);
      } else {
        row['rowClass'] = '';
        match.classList.remove(this.iconUp);
        match.classList.add(this.iconDown);
        this.removeDetailsRows(detailData);
      }
      this.fixNextIcon(icons, iconIndex);
    }
  }

  insertDetailsRows(row: any, detailData: any[]) {
    const index = findIndex(this.data, row) + 1;
    this.data = concat(this.data.slice(0, index), detailData, this.data.slice(index));
  }

  removeDetailsRows(detailData: any[]) {
    this.data = differenceWith(this.data, detailData, isEqual);
  }

  manageParentClass(row: any) {
    const element = document.activeElement;
    if (element && typeof row === 'object' && row !== null) {
      const hasIconDown = element.classList.contains(this.iconDown);
      if (hasIconDown) {
        row['rowClass'] += ' row-parent';
      } else {
        row['rowClass'] = row['rowClass'].replace(' row-parent', '');
      }
    }
  }

  fixNextIcon(icons: Element[], index: number) {
    if (icons.length > index + 1) {
      for (let i = index + 1; i < icons.length; i++) {
        const classes = Array.from(icons[i].classList);
        this.detCh.detectChanges();
        const updatedIcons = Array.from(document.querySelectorAll('a[class*="datatable-icon"]'));
        const hasIconDown = classes.includes(this.iconDown);
        updatedIcons[i].classList.remove(hasIconDown ? this.iconUp : this.iconDown);
        updatedIcons[i].classList.add(hasIconDown ? this.iconDown : this.iconUp);
      }
    }
  }

  showCustomButton(row, column, value) {
    this.customButtonsConfig[value] = column?.customButton;
    return (this.customButtonsConfig[value]?.hideWhenNoValue && value) || !this.customButtonsConfig[value]?.hideWhenNoValue;
  }

  copyToClipboard(event, value) {
    event.stopPropagation();
    this.clipboard.copy(value);
  }

  getDataTableDefaultMessages(translate) {
    const NO_RESULTS_MESSAGE_TAB = translate.instant('ROOT.NO_RESULTS_MESSAGE_TAB');
    const STYLED_NO_RESULTS_MESSAGE_TAB = this.sanitizer.sanitize(
      SecurityContext.HTML,
      this.sanitizer.bypassSecurityTrustHtml(`<div class="highlight">${NO_RESULTS_MESSAGE_TAB}</div>`),
    );
    return {
      loadingMessage: { emptyMessage: translate.instant('ROOT.LOADING_MESSAGE') },
      noResultsMessage: {
        emptyMessage: this.highlightNoResultsMessage() ? String(STYLED_NO_RESULTS_MESSAGE_TAB) : NO_RESULTS_MESSAGE_TAB,
      },
    };
  }

  highlightNoResultsMessage = () => {
    return this.section === 'activity' ? this._schema.some(obj => obj.highlightsEmptyResultsMessage === true) : false;
  };
}
