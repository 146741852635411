import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/search-form-builders';
import { sortBy } from 'lodash';
import { filter, map } from 'rxjs/operators';
import type { SearchService } from 'services/search/search.service';
import type { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';
import { RootState } from 'store/root';
import { FormConfig, SearchExpressions } from './form-config';

export const DEFAULT_SOCIETY = 'ICE:ICE';
export const ALL_REPORTS_NAMES = 'ALL';

export class ReportsSearchExpressions extends SearchExpressions {
  getSearchExpressionConfig(key: string, value: string, prefix: string, allUsers: any) {
    switch (key) {
      case 'requestedBy':
        return `{"wildcard":{"${'attributes.' + key}":"*${value}*"}}`;
      case 'reportName':
        if (value === ALL_REPORTS_NAMES) {
          return null;
        }
        return `{"equals":{"${'attributes.' + key}":"${value}"}}`;
      default:
        return `{"wildcard":{"${'attributes.' + key}":"*${value}*"}}`;
    }
  }

  getExtraSearchExpression() {
    return '';
  }
}

export class SearchReportsForm implements FormConfig {
  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private searchService: SearchService,
    private store: Store<RootState>,
    private fieldValidatorService: FieldValidatorService,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  getForm(): FormlyFieldConfig[] {
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'id',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('REPORTS.ID'), required: false },
          },
          {
            className: 'flex-1',
            key: 'requestedBy',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('REPORTS.REQUESTED_BY'), required: false },
          },
          {
            className: 'flex-1',
            key: 'reportName',
            type: 'select',
            defaultValue: ALL_REPORTS_NAMES,
            templateOptions: {
              label: this.translate.instant('REPORTS.REPORT_NAME'),
              options: this.store.select(fromRoot.getCopyrightReports).pipe(
                filter(reportTypes => !!reportTypes && reportTypes.length > 0),
                map(reportTypes => [
                  { label: this.translate.instant('REPORTS.REPORT_NAME_OPTIONS_ALL'), value: ALL_REPORTS_NAMES },
                  ...sortBy(reportTypes, ['name']).map(reportType => ({ label: reportType.name, value: reportType.name })),
                ]),
              ),
              required: false,
            },
          },
        ],
      },
    ];
  }
}
