import { Component, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/app/cookies';
import Cookies from 'js-cookie';

@Component({
  selector: 'ice-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss'],
})
export class CookiesComponent implements OnInit {
  public showCookies = true;
  public currentCookies = [];
  public showCookiesDetails = false;
  private acceptedICECookiesName = 'ice-cookies-accepted-v1.0';
  public firstTabActive = true;
  public secondTabActive = false;

  constructor(private fuseTranslationLoader: FuseTranslationLoaderService, private translate: TranslateService) {
    this.fuseTranslationLoader.loadTranslations(english);
  }
  ngOnInit(): void {
    this.checkSiteCookies();
  }

  private checkSiteCookies() {
    const cookies = Cookies.get();
    const ddsCookieDetail = `${this.translate.instant('COOKIE_DATADOG_DETAIL')}${this.translate.instant('COOKIE_DATADOG_DETAIL_1')}${this.translate.instant(
      'COOKIE_DATADOG_DETAIL_2',
    )}`;
    Object.keys(cookies).forEach(cookie => {
      this.currentCookies.push({
        cookieName: cookie,
        provider: cookie === '_dd_s' ? 'Datadog' : '',
        purpose: cookie === '_dd_s' ? ddsCookieDetail : '',
        expireTime: cookie === '_dd_s' ? '4 hours' : '',
        type: cookie === '_dd_s' ? 'Other' : '',
      });
    });
    this.showCookies = !this.currentCookies.some(cookie => cookie.cookieName === this.acceptedICECookiesName);
  }

  public setAcceptCookie() {
    const isSecure = window.location.protocol === 'https:';
    const options = {
      expires: 365,
      path: '/',
      sameSite: isSecure ? 'None' : ('Lax' as 'None' | 'Lax' | 'Strict'),
      secure: isSecure,
    };
    Cookies.set(this.acceptedICECookiesName, 1, options);
    this.showCookies = false;
  }

  public showTab() {
    this.firstTabActive = !this.firstTabActive;
    this.secondTabActive = !this.secondTabActive;
  }

  public changeCookiesDetailsVisibility() {
    this.showCookiesDetails = !this.showCookiesDetails;
  }

  public get expandIcon() {
    return this.showCookiesDetails ? 'expand_less' : 'expand_more';
  }
}
