import { CopyrightUtils } from '@ice';
import { createSelector } from '@ngrx/store';
import { getRouterSection, getRouterView } from 'store/root/selectors';
import { NavigationStates } from 'store/root/state';
import * as fromFeature from '../../reducers';
import * as fromGlobal from '../../reducers/global/global.reducer';

export const getGlobalNavigation = createSelector(fromFeature.getGlobalFeatureState, fromGlobal.getGlobalNavigationState);

export const getGlobalNavigationState = createSelector(getGlobalNavigation, navigation => navigation.state);

export const getGlobalNavigationLastSection = createSelector(getGlobalNavigation, navigation => navigation.lastSection);

export const getGlobalApiNamespace = createSelector(fromFeature.getGlobalFeatureState, fromGlobal.getGlobalApiNamespaceState);

export const getForcedNS = createSelector(fromFeature.getGlobalFeatureState, global => global.forcedNS);

export const getForcedNSBackLink = createSelector(fromFeature.getGlobalFeatureState, global => global.forcedNsBackLink);

export const getForcedNSBackLinkVisible = createSelector(fromFeature.getGlobalFeatureState, global => global.forcedNsBackLink !== '');

export const getApiNamespace = createSelector(fromFeature.getGlobalFeatureState, global => global.apiNamespace);

export const getNewBtnVisibilityBySectionAndComponent = createSelector(getRouterSection, getRouterView, getGlobalNavigationState, (section, view, navigation) => {
  const sectionConfig = CopyrightUtils.getSectionData(section);
  const res = sectionConfig && sectionConfig.newItemBtn && sectionConfig.newItemBtn.some(item => item === view) && navigation === NavigationStates.routerNavigated;
  return res;
});
