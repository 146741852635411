<div fxLayout="column">
  <mat-form-field>
    <input matInput 
    [id]="id"
    [type]="to.type || 'text'"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [placeholder]="to.placeholder">
    <mat-error [id]="null">
        <formly-validation-message [field]="field"></formly-validation-message>
    </mat-error>       
  </mat-form-field>  
</div>
