import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iceTypeaheadHighlight',
})
export class TypeaheadHighlight implements PipeTransform {
  transform(value: any, args: any): any {
    const re = new RegExp('^' + args, 'g');
    return value.replace(re, '<strong>' + args + '</strong>');
  }
}
