import {
  CopyrightOwnershipTableItem,
  CopyrightUtils,
  DateTimeUtils,
  Ip,
  IpUtils,
  RelationsUtils,
  SharePictures,
  SharePicturesSharePicturesNode,
  StringUtils,
  TerritoryUtils,
} from '@ice';
import { TranslateService } from '@ngx-translate/core';
import { NOT_AVAILABLE } from 'config/constants/global.constants';
import { mrRights, prRights } from 'config/constants/ips.constants';
import { MARGIN_ROLE, RIGHT_MR, RIGHT_PR, ROLE_UNDEFINED } from 'config/constants/shares.constants';
import { INCLUDE_WORLD_IN_EX_TISN, TerritoryDataType, WORLD_TISN } from 'config/constants/territories.constants';
import { DEFAULT_FILTER_MODEL } from 'config/constants/works.constants';
import { concat, difference, find, get, has, includes, isEqual, mapValues, pickBy, toNumber } from 'lodash';
import { UserCleaned } from 'models/users/users.model';
import moment from 'moment';
import { Observable, of, timer } from 'rxjs';
import { concatMap, map, mapTo } from 'rxjs/operators';
import { LocalStorageUtils } from '../local-storage/localstorage.utils';
import { getPercent2Decimals } from '../mathUtils';
import { SocietiesUtils } from '../societies/societies.utils';
import { ClaimsUtils } from './claims.utils';

export class ClaimGraphUtils {
  static needsClaimGraphApiCall(newFilter, oldFilter) {
    const cleanFilter = mapValues(newFilter, val => (val instanceof Date ? DateTimeUtils.formatDate(moment(val)) : val));
    return !isEqual(this.getApiFilterProperties(cleanFilter), this.getApiFilterProperties(oldFilter));
  }

  static getClaimFilter(territory: any, usageDate: string, distributionDate: string, mrRight: string, prRight: string, status: Array<string>) {
    const filterAttributesContent = [
      mrRight || prRight ? `attributes.rightTypes=(${[mrRight && `'${mrRight}'`, prRight && `'${prRight}'`].filter(right => !!right).join(',')})` : null,
      usageDate ? `attributes.usageStartDate<='${moment(usageDate).format('YYYY-MM-DD')}'` : null,
      usageDate ? `attributes.usageEndDate>='${moment(usageDate).format('YYYY-MM-DD')}'` : null,
      distributionDate ? `attributes.distributionStartDate<='${moment(distributionDate).format('YYYY-MM-DD')}'` : null,
      distributionDate ? `attributes.distributionEndDate>='${moment(distributionDate).format('YYYY-MM-DD')}'` : null,
      status ? `attributes.status=(${status.map(stat => `'${stat}'`)})` : null,
    ]
      .filter(param => !!param)
      .join(', ');
    const filterAttributes = (filterAttributesContent && `claims[${filterAttributesContent}].attributes,`) || 'claims.attributes,';

    const territories = Array.isArray(territory) ? territory.join() : territory;
    const isTerritoryFilterIncluded = territories && ![WORLD_TISN, +WORLD_TISN, INCLUDE_WORLD_IN_EX_TISN].includes(territories);
    const filterCountries = isTerritoryFilterIncluded ? `claims[countries.value=(${territories})].countries,` : '';

    return `${filterAttributes}${filterCountries}`.slice(0, -1);
  }

  static getApiFilterProperties(filter) {
    return pickBy(filter, (value, key) => !['onlyInConflict', 'removeE', 'removeSE'].includes(key));
  }

  static getOwnershipTableVisibleColumns(manuscriptTableSelected, claimTableSelected, hasUnauthorisedClaims) {
    let fields = ['role', 'name', 'prSociety', 'mrSociety', 'pr', 'mr'];

    const hasToShowAuthorised = !manuscriptTableSelected && hasUnauthorisedClaims;
    if (manuscriptTableSelected) {
      fields = concat(fields, ['ipiNameNumber', 'ipiNameKey', 'ipiBaseNumber', 'ipiBaseKey']);
    }
    if (claimTableSelected) {
      fields = concat(fields, ['refLabel']);
    }
    if (hasToShowAuthorised) {
      fields.push('unauthorised');
    }
    return fields;
  }

  static formatSociety(society) {
    return (society && `${society}:${SocietiesUtils.searchSocietyNameById(society.trim())}`) || '';
  }

  static getManuscriptShare(shares, type): { share: string; tooltip: string } {
    const share = find(shares, item => find(item.rightTypes, rightType => rightType === type));
    if (share && share.share !== undefined) {
      const share2Decimals = getPercent2Decimals(share.share);
      const sharePercent = share.share / 100;
      return { share: `${share2Decimals} %`, tooltip: share2Decimals !== sharePercent ? `${sharePercent} %` : '' };
    }
    return { share: '', tooltip: '' };
  }

  static getOwnershipTableItemsFromSharePictures(sp: SharePictures): CopyrightOwnershipTableItem[] {
    if (sp && sp['party-names'] && sp['party-names'].length > 0) {
      const mapPartyNames = new Map();
      for (const partyName of sp['party-names']) {
        mapPartyNames.set(partyName.id, partyName);
      }
      if (sp && sp.share_pictures) {
        return this.addItemsFromSharePictureNode(mapPartyNames, [], sp.share_pictures[0].ownership_share_picture.contributor_share_pictures);
      }
    }
    return [];
  }

  static addItemsFromSharePictureNode(partyNames: Map<string, Ip[]>, tableItems: any[], sharePicturesNodes: SharePicturesSharePicturesNode[]): CopyrightOwnershipTableItem[] {
    for (const node of sharePicturesNodes) {
      const label = node.contributor_id ? node.contributor_id : node.publisher_id;
      const prval = node && node.shares && node.shares.PR && node.shares.PR.share ? node.shares.PR.share / 100 : 0;
      const mrval = node && node.shares && node.shares.MR && node.shares.MR.share ? node.shares.MR.share / 100 : 0;
      const prSociety = node && node.shares && node.shares.PR && node.shares.PR.share && node.shares.PR.society_id ? node.shares.PR.society_id : '';
      const mrSociety = node && node.shares && node.shares.MR && node.shares.MR.share && node.shares.MR.society_id ? node.shares.MR.society_id : '';
      tableItems.push({
        role: node.contributor_id ? node.role : `&nbsp;&nbsp;&nbsp;${node.role}`,
        name: CopyrightUtils.extractPartyNameFirstname(partyNames.get(label)),
        ref: label,
        ip: '',
        society: `${prSociety} / ${mrSociety}`,
        pr: `${prval}%`,
        mr: `${mrval}%`,
      });
      if (node.publisher_share_pictures && node.publisher_share_pictures.length > 0) {
        tableItems.concat(this.addItemsFromSharePictureNode(partyNames, tableItems, node.publisher_share_pictures));
      } else if (node.sub_publisher_share_pictures && node.sub_publisher_share_pictures.length > 0) {
        tableItems.concat(this.addItemsFromSharePictureNode(partyNames, tableItems, node.sub_publisher_share_pictures));
      }
    }
    return tableItems;
  }

  static getCopyrightOwnershipTotals(ownershipTableItems: CopyrightOwnershipTableItem[]): CopyrightOwnershipTableItem[] {
    if (ownershipTableItems && ownershipTableItems.length) {
      const prParser = item => (item.prTooltip && item.prTooltip.length > 0 ? parseFloat(item.prTooltip.slice(0, -1)) : item.pr ? parseFloat(item.pr.slice(0, -1)) : '-');
      const mrParser = item => (item.mrTooltip && item.mrTooltip.length > 0 ? parseFloat(item.mrTooltip.slice(0, -1)) : item.mr ? parseFloat(item.mr.slice(0, -1)) : '-');
      const { totalMR, totalPR, publisherSubTotalPR, publisherSubTotalMR, writerSubTotalMR, writerSubTotalPR, specialSubTotalMR, specialSubTotalPR } =
        ClaimsUtils.calculateCopyrightOwnershipTotals(ownershipTableItems, prParser, mrParser);
      return [
        { role: '', name: '', ref: '', ip: '', society: '', pr: `${writerSubTotalPR}%`, mr: `${writerSubTotalMR}%`, unauthorised: '' },
        { role: '', name: '', ref: '', ip: '', society: '', pr: `${publisherSubTotalPR}%`, mr: `${publisherSubTotalMR}%`, unauthorised: '' },
        { role: '', name: '', ref: '', ip: '', society: '', pr: `${specialSubTotalPR}%`, mr: `${specialSubTotalMR}%`, unauthorised: '' },
        { role: '', name: '', ref: '', ip: '', society: '', pr: `${totalPR}%`, mr: `${totalMR}%`, unauthorised: '' },
      ];
    }
    return [];
  }

  static hasUnauthorisedClaims(claims) {
    return !!claims
      .map(claim => {
        const { attributes } = claim;
        const unauthorised = get(attributes, 'tags.unauthorised[0]');
        return unauthorised === 'true';
      })
      .find(unauthorised => !!unauthorised);
  }

  static getAllClaimsFilterModel(user) {
    const store = LocalStorageUtils.getAllClaimsFilter(user?.detail?.cognitoUserName);
    if (store && !has(store, 'territory')) {
      store.territory = DEFAULT_FILTER_MODEL.territory;
    }
    return store || DEFAULT_FILTER_MODEL;
  }

  static getClaimView(claims: any[], translate?: TranslateService, territoriesFilter?: any, user?: UserCleaned): CopyrightOwnershipTableItem[] {
    if (claims) {
      const claimIdMap = new Map<string, any>();
      const claimHashMap = new Map<string, any>();
      const cleanedClaims = claims.map(claim => {
        const { attributes, claimant } = claim;
        const claimId = get(attributes, 'id', '');
        const hasChildren = claims.some(c => {
          return get(c, 'attributes.parentId', null) === claimId;
        });
        const partyName = claim.claimant && claim.claimant.partyName;
        let name: string;
        let ref: string;
        let refLabel: string;
        let ipiBaseNumberRelation: any;
        let key: string;
        const partyNameId = get(claim, 'claimant.partyNameId', '');
        const partyId = get(claim, 'claimant.partyId', '');
        const ipiNumberFallback = (partyNameId.includes('IPI') && partyNameId) || '';
        const ipiBaseNumberFallback = (partyId.includes('IPI') && partyId) || '';
        const partyRelations = get(claim, 'claimant.party.relations');
        if (partyName) {
          key = IpUtils.selectIpsKey(get(claim, 'claimant.partyName.relations'), partyName.attributes.id);
          ref = IpUtils.getClaimantRef(partyName);
          refLabel = ref.replace('IPI:', '');
          name = partyName.attributes && IpUtils.getIpFullName(partyName.attributes);
        }
        if (partyRelations) {
          ipiBaseNumberRelation = partyRelations.find(elem => elem.otherId.indexOf('IPI') === 0);
        }

        const attShare = get(attributes, 'share', 0);
        const share2Decimals = getPercent2Decimals(attShare);
        const societies = get(claim, 'claimant.party.societies');
        const societyCodes = ((Array.isArray(societies) && societies) || []).map((society: any) => ({
          code: SocietiesUtils.getSocietyCode(society.societyId),
          rights: society.memberships.map(member => member.rights || []).reduce((acc, it) => [...acc, ...it], []),
          territories: society.memberships.map(member => member.territories || []).reduce((acc, it) => [...acc, ...it], []),
        }));
        const filter = user?.cognitoUserName ? LocalStorageUtils.getAllClaimsFilter(user.cognitoUserName) : {};
        const societyCodePr = (
          societyCodes.find(
            society =>
              society.rights.includes(RIGHT_PR) && (!filter?.prRight || society.rights.includes(filter.prRight)) && TerritoryUtils.filterSociety(society, territoriesFilter),
          ) || ({} as any)
        ).code;
        const societyCodeMr = (
          societyCodes.find(
            society =>
              society.rights.includes(RIGHT_MR) && (!filter?.mrRight || society.rights.includes(filter.mrRight)) && TerritoryUtils.filterSociety(society, territoriesFilter),
          ) || ({} as any)
        ).code;
        const territories = get(attributes, 'territories', []);
        const prMrSeparator = societyCodePr && societyCodeMr ? ' / ' : '';
        const prMr = `${societyCodePr || ''}${prMrSeparator}${societyCodeMr || ''}`;
        const prMrTooltip = `${SocietiesUtils.searchSocietyNameById(societyCodePr) || ''}${prMrSeparator}${SocietiesUtils.searchSocietyNameById(societyCodeMr) || ''}`;
        const parentId = get(attributes, 'parentId', null);
        const parent = claims.find(parentClaim => parentClaim.id === parentId);
        const territoriesConversionData = TerritoryUtils.convertTerritoryArrayElementsPrefix(territories);
        const typeOf = get(claim, 'claimant.party.attributes.typeOf');
        const hasNotResolvedCounterclaim = get(claim, 'attributes.hasNotResolvedCounterclaim');
        const cleanClaim = {
          roleRaw: (attributes && attributes.role) || ROLE_UNDEFINED,
          rawCountries: TerritoryUtils.flatTerritories(territories).sort().join(),
          ref,
          id: get(partyName, 'id', ''),
          territories,
          territoriesTisa: territoriesConversionData[TerritoryDataType.TISA].join(' '),
          territoriesTooltip: TerritoryUtils.getTerritoriesNamesTooltipText(territoriesConversionData[TerritoryDataType.NAME]),
          startDate: get(attributes, 'distributionStartDate', ''),
          endDate: get(attributes, 'usageEndDate', ''),
          postTermCollectionDate: get(attributes, 'distributionEndDate', ''),
          priorRoyaltyDate: get(attributes, 'usageStartDate', ''),
          refLabel: refLabel || CopyrightUtils.getKeySuffix(ipiNumberFallback),
          ipiBaseNumber: RelationsUtils.getKeySuffixFromRelation(ipiBaseNumberRelation) || CopyrightUtils.getKeySuffix(ipiBaseNumberFallback),
          key,
          inDispute: get(attributes, 'inDispute', false) ? 'Y' : 'N',
          partialAgreementId: StringUtils.trimLeadingZerosFromString(get(claim, 'agreement.id', '').split(':')[1]),
          agreementId: [],
          name,
          share: share2Decimals,
          prMr,
          prMrTooltip,
          partyNameId: refLabel || CopyrightUtils.getKeySuffix(partyNameId),
          mr: (translate && NOT_AVAILABLE) || 0,
          pr: (translate && NOT_AVAILABLE) || 0,
          claimId,
          partialRights: get(attributes, 'rightTypes') || [],
          parentId,
          parent,
          rawStatus: [get(attributes, 'status', '')],
          partialPrSoc: societyCodePr,
          partialMrSoc: societyCodeMr,
          prSocTooltip: [],
          mrSocTooltip: [],
          typeOf,
          hasChildren,
          hasNotResolvedCounterclaim,
        };
        cleanClaim['claimantPartyNameId'] = get(claimant, 'partyNameId');
        cleanClaim['claimantPartyId'] = get(claimant, 'partyId');
        const claimHash = window.btoa(
          `${cleanClaim.refLabel || get(attributes, 'claimantPartyNameId')}|${cleanClaim.startDate}|${cleanClaim.endDate}|${cleanClaim.rawCountries}|${cleanClaim.roleRaw}`,
        );
        cleanClaim['claimHash'] = claimHash;
        const prSocietyObject = SocietiesUtils.getPRorMRSociety(societies, 'PR');
        cleanClaim['prSociety'] = prSocietyObject.multipleSocieties ? '' : this.formatSociety(prSocietyObject.societyCode) || this.formatSociety('099');
        cleanClaim['prSocietyIcons'] = prSocietyObject.multipleSocieties
          ? SocietiesUtils.generateIsICESocietyIcons({ isICE: prSocietyObject.hasICESociety, societies: prSocietyObject.societyCodes, excludeMargin: true })
          : [];

        const mrSocietyObject = SocietiesUtils.getPRorMRSociety(societies, 'MR');
        cleanClaim['mrSociety'] = mrSocietyObject.multipleSocieties ? '' : this.formatSociety(mrSocietyObject.societyCode) || this.formatSociety('099');
        cleanClaim['mrSocietyIcons'] = mrSocietyObject.multipleSocieties
          ? SocietiesUtils.generateIsICESocietyIcons({ isICE: mrSocietyObject.hasICESociety, societies: mrSocietyObject.societyCodes, excludeMargin: true })
          : [];

        const unauthorized = get(attributes, 'tags.unauthorised[0]');
        const unauthorizedIcon = unauthorized === 'true' ? CopyrightUtils.getUnauthorizedIconWithTooltip(translate) : undefined;
        cleanClaim['alert'] = unauthorized;
        cleanClaim['alertIcon'] = unauthorizedIcon;
        claimIdMap.set(get(attributes, `id`), claimHash);
        claimHashMap.set(claimHash, cleanClaim);
        return cleanClaim;
      });
      const orderHashMap = new Map();
      const claimsPathGroupList = ClaimsUtils.getClaimsByPath(cleanedClaims, claimIdMap)
        .map(claimsByPath => {
          const shares = claimsByPath[0];
          shares.role = `${MARGIN_ROLE.repeat(shares.path.length - 1)}${shares.roleRaw}`;
          shares.claimId = claimsByPath.map(claim => claim.claimId);
          shares.level = shares.path.length;
          shares.topLevelNameId = get(claimHashMap.get(shares.path[0]), 'id');
          claimsByPath.map(claim => {
            if (claim['partialAgreementId'] && !shares['agreementId'].includes(claim['partialAgreementId'])) {
              shares['agreementId'].push(claim['partialAgreementId']);
            }
            if (includes(claim.partialRights, RIGHT_PR)) {
              shares['pr'] = ((!isNaN(shares['pr']) && shares['pr']) || 0) + claim.share;
              const splitShares = shares['allRights'] && shares['allRights'].split('/');
              const allPr = !difference(prRights, claim.partialRights).length;
              shares['allRights'] = `${(allPr && translate && translate.instant('WORKS.ALL_CLAIMS.ALL_PR')) || claim.partialRights} / ${
                (splitShares && splitShares.length && splitShares[1].trim()) || ''
              }`;
            }
            if (includes(claim.partialRights, RIGHT_MR)) {
              shares['mr'] = ((!isNaN(shares['mr']) && shares['mr']) || 0) + claim.share;
              const splitShares = shares['allRights'] && shares['allRights'].split('/');
              const allMr = !difference(mrRights, claim.partialRights).length;
              shares['allRights'] = `${(splitShares && splitShares[0].trim()) || ''} / ${
                (allMr && translate && translate.instant('WORKS.ALL_CLAIMS.ALL_MR')) || claim.partialRights
              }`;
            }
            if (shares.partialPrSoc && !shares.prSocTooltip.includes(shares.partialPrSoc)) {
              shares.prSocTooltip.push(shares.partialPrSoc);
            }
            if (shares.partialMrSoc && !shares.mrSocTooltip.includes(shares.partialMrSoc)) {
              shares.mrSocTooltip.push(shares.partialMrSoc);
            }
            shares.rawStatus = [...shares.rawStatus, ...((!shares.rawStatus.includes(claim.rawStatus[0]) && claim.rawStatus) || [])];
            shares['allRightsArray'] = [...(shares['allRightsArray'] || []), ...claim.partialRights];
          });

          return { ...shares, ...ClaimsUtils.getClaimStatus(shares.rawStatus, translate) };
        })
        .map(shares => {
          orderHashMap.set(shares.claimHash, `${shares.rawCountries}/${shares.allRightsArray.length}/${shares.name}/${shares.roleRaw}/${shares.startDate}/${shares.endDate}`);
          return shares;
        });
      return ClaimsUtils.sortClaimsByPath(
        claimsPathGroupList.map(shares => {
          shares.path = shares.path.map(hash => {
            const orderHash = orderHashMap.get(hash);
            return `${orderHash}/${hash}`;
          });
          shares.mr = `${shares.mr}${(shares.mr !== NOT_AVAILABLE && ' %') || ''}`;
          shares.pr = `${shares.pr}${(shares.pr !== NOT_AVAILABLE && ' %') || ''}`;
          shares['mrTooltip'] = shares['mr'];
          shares['prTooltip'] = shares['pr'];
          shares['agreementId'] = shares['agreementId'].join('<br>');
          shares.prSoc = (shares?.prSocTooltip?.sort((socA, socB) => toNumber(socA.slice(1, 1)) - toNumber(socB.slice(1, 1))) || [])[0];
          shares.mrSoc = (shares?.mrSocTooltip?.sort((socA, socB) => toNumber(socA.slice(1, 1)) - toNumber(socB.slice(1, 1))) || [])[0];
          shares.prSocTooltip = shares.prSocTooltip.join('<br>');
          shares.mrSocTooltip = shares.mrSocTooltip.join('<br>');
          return [shares];
        }),
      );
    }
    return null;
  }

  static getTotalTitles(data: Observable<any[]>, totalParser, writerSubTotalLabel, publisherSubTotalLabel, specialSubTotalLabel, totalClaimsLabel, onlyTotals = false) {
    return data.pipe(
      map(model => totalParser(model)),
      concatMap((model, index) => {
        if (model) {
          const { totalMR, totalPR, publisherSubTotalPR, publisherSubTotalMR, writerSubTotalMR, writerSubTotalPR, specialSubTotalPR, specialSubTotalMR } = model;
          const totals = onlyTotals
            ? []
            : [
                { text: writerSubTotalLabel, span: 2 },
                { text: `${writerSubTotalPR}`, span: 1 },
                { text: `${writerSubTotalMR}`, span: 1 },
                { text: publisherSubTotalLabel, span: 2 },
                { text: `${publisherSubTotalPR}`, span: 1 },
                { text: `${publisherSubTotalMR}`, span: 1 },
              ];

          if (specialSubTotalPR !== '0.00' && specialSubTotalMR !== '0.00' && !onlyTotals) {
            totals.push({ text: specialSubTotalLabel, span: 2 }, { text: `${specialSubTotalPR}`, span: 1 }, { text: `${specialSubTotalMR}`, span: 1 });
          }
          totals.push({ text: totalClaimsLabel, span: 2 }, { text: `${totalPR}`, span: 1 }, { text: `${totalMR}`, span: 1 });
          if (index === 0) {
            return timer(250).pipe(mapTo(totals));
          } else {
            return of(totals);
          }
        } else {
          return of([]);
        }
      }),
    );
  }

  static filterClaims(claims: any[], filterParams: any) {
    const { onlyInConflict, removeE, removeSE } = filterParams;
    const filteredClaims = claims
      .filter(
        claim =>
          (!onlyInConflict || claim.inDispute === 'Y') && (!removeE || claim?.role.replace(/&nbsp;/g, '') !== 'E') && (!removeSE || claim?.role.replace(/&nbsp;/g, '') !== 'SE'),
      )
      .map(claimsList => this.setCounterclaimRows(claimsList));
    return filteredClaims;
  }

  static setCounterclaimRows(claim) {
    const { hasNotResolvedCounterclaim, alertIcon } = claim;
    const counterClaimIcon = hasNotResolvedCounterclaim ? CopyrightUtils.getCounterclaimIconWithTooltip() : [];
    const alertIcons = !!alertIcon ? alertIcon : [];
    return { ...claim, rowClass: hasNotResolvedCounterclaim ? 'unresolved-cc-row' : '', alertIcon: [...alertIcons, ...counterClaimIcon] };
  }
}
