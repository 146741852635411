import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { QueryEditorModule } from '../query-editor/query-editor.module';
import { QueryEditorExpertSearchComponent } from './query-editor-expert-search.component';

@NgModule({
  declarations: [QueryEditorExpertSearchComponent],
  imports: [CommonModule, QueryEditorModule, FormsModule],
  exports: [QueryEditorExpertSearchComponent],
})
export class QueryEditorexpertSearchModule {}
