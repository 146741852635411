export const INSTRUMENTS = [
  { code: 'ACC', description: 'Accordion', family: 'Keyboard' },
  { code: 'ACL', description: 'Alto Clarinet', family: 'Woodwind' },
  { code: 'AFL', description: 'Alto Flute', family: 'Woodwind' },
  { code: 'AHN', description: 'Alto Horn', family: 'Brass' },
  { code: 'ALP', description: 'Alp Horn', family: 'National/Folk' },
  { code: 'ALT', description: 'Alto Voice', family: 'Voice' },
  { code: 'AMP', description: 'Amplifier', family: 'Electronic' },
  { code: 'ARC', description: 'Alto Recorder', family: 'Early Music' },
  { code: 'ASX', description: 'Alto Saxophone', family: 'Woodwind' },
  { code: 'BAG', description: 'Bagpipes', family: 'National/Folk' },
  { code: 'BAR', description: 'Baritone Horn', family: 'Brass' },
  { code: 'BAS', description: 'Double Bass', family: 'String' },
  { code: 'BBF', description: 'Bamboo Flute', family: 'National/Folk' },
  { code: 'BBT', description: 'Bass Baritone', family: 'Voice' },
  { code: 'BCL', description: 'Bass Clarinet', family: 'Woodwind' },
  { code: 'BDN', description: 'Bandoneon', family: 'Keyboard' },
  { code: 'BDR', description: 'Bass Drum', family: 'Percussion' },
  { code: 'BEL', description: 'Bells', family: 'Percussion' },
  { code: 'BFT', description: 'Bass Flute', family: 'Woodwind' },
  { code: 'BGL', description: 'Bugle', family: 'Brass' },
  { code: 'BGT', description: 'Bass Guitar', family: 'String' },
  { code: 'BHN', description: 'Basset Horn', family: 'Woodwind' },
  { code: 'BKA', description: 'Balalaika', family: 'National/Folk' },
  { code: 'BNG', description: 'Bongos', family: 'Percussion' },
  { code: 'BNJ', description: 'Banjo', family: 'String' },
  { code: 'BOB', description: 'Bass Oboe', family: 'Woodwind' },
  { code: 'BOY', description: 'Boy Soprano', family: 'Voice' },
  { code: 'BQF', description: 'Baroque Flute', family: 'Early Music' },
  { code: 'BRC', description: 'Bass Recorder', family: 'Early Music' },
  { code: 'BRT', description: 'Bass Trombone', family: 'Brass' },
  { code: 'BSN', description: 'Bassoon', family: 'Woodwind' },
  { code: 'BSP', description: 'Bass Saxophone', family: 'Woodwind' },
  { code: 'BSS', description: 'Bass Voice', family: 'Voice' },
  { code: 'BSX', description: 'Baritone Saxophone', family: 'Woodwind' },
  { code: 'BTN', description: 'Baritone Voice', family: 'Voice' },
  { code: 'CAL', description: 'Contralto Voice', family: 'Voice' },
  { code: 'CAR', description: 'Carillon', family: 'Percussion' },
  { code: 'CBC', description: 'Contrabass Clarinet', family: 'Woodwind' },
  { code: 'CBN', description: 'Contra Bassoon', family: 'Woodwind' },
  { code: 'CCL', description: 'Contralto Clarinet', family: 'Woodwind' },
  { code: 'CEL', description: 'Celesta', family: 'Keyboard' },
  { code: 'CHM', description: 'Chimes', family: 'Percussion' },
  { code: 'CIM', description: 'Cimbalom', family: 'National/Folk' },
  { code: 'CLR', description: 'Clarinet', family: 'Woodwind' },
  { code: 'CNB', description: 'Concertina', family: 'Misc.' },
  { code: 'CNG', description: 'Congas', family: 'Percussion' },
  { code: 'CNT', description: 'Cornet', family: 'Brass' },
  { code: 'COM', description: 'Computer', family: 'Electronic' },
  { code: 'CST', description: 'Castanets', family: 'Percussion' },
  { code: 'CTN', description: 'Countertenor Voice', family: 'Voice' },
  { code: 'CVD', description: 'Clavichord', family: 'Keyboard' },
  { code: 'CYM', description: 'Cymbals', family: 'Percussion' },
  { code: 'DIJ', description: 'Didjeridu', family: 'National/Folk' },
  { code: 'DIZ', description: "Dizi/D'Tzu", family: 'National/Folk' },
  { code: 'DJM', description: 'Djembe', family: 'National/Folk' },
  { code: 'DRK', description: 'Drum Kit/Drum Set', family: 'Percussion' },
  { code: 'DRM', description: 'Drum', family: 'Percussion' },
  { code: 'DUL', description: 'Dulcimer', family: 'National/Folk' },
  { code: 'EBG', description: 'Electric Bass Guitar', family: 'String' },
  { code: 'EFC', description: 'E-Flat Clarinet', family: 'Woodwind' },
  { code: 'EGT', description: 'Electric Guitar', family: 'String' },
  { code: 'EHN', description: 'English Horn', family: 'Woodwind' },
  { code: 'ELL', description: 'Electronics, Live', family: 'Electronic' },
  { code: 'ELP', description: 'Electronics, Pre-recorded', family: 'Electronic' },
  { code: 'EOG', description: 'Electronic Organ', family: 'Keyboard' },
  { code: 'ERH', description: 'Erhu', family: 'National/Folk' },
  { code: 'EUP', description: 'Euphonium', family: 'Brass' },
  { code: 'FLG', description: 'Flugelhorn', family: 'Brass' },
  { code: 'FLT', description: 'Flute', family: 'Woodwind' },
  { code: 'FRN', description: 'French Horn', family: 'Brass' },
  { code: 'GHM', description: 'Glass Harmonica', family: 'Misc.' },
  { code: 'GHP', description: 'Glass Harp', family: 'Misc.' },
  { code: 'GLS', description: 'Glockenspiel', family: 'Percussion' },
  { code: 'GNG', description: 'Gong', family: 'Percussion' },
  { code: 'GTR', description: 'Guitar', family: 'String' },
  { code: 'HAR', description: 'Harmonica', family: 'Misc.' },
  { code: 'HBL', description: 'Handbells', family: 'Percussion' },
  { code: 'HCK', description: 'Heckelphone', family: 'Woodwind' },
  { code: 'HPS', description: 'Harpsichord', family: 'Early Music' },
  { code: 'HRM', description: 'Harmonium', family: 'Keyboard' },
  { code: 'HRN', description: 'Horn', family: 'Brass' },
  { code: 'HRP', description: 'Harp', family: 'String' },
  { code: 'HUR', description: 'Hurdy-Gurdy', family: 'Early Music' },
  { code: 'KAZ', description: 'Kazoo', family: 'National/Folk' },
  { code: 'KEY', description: 'Keyboard', family: 'Keyboard' },
  { code: 'KLV', description: 'Klavier', family: 'Keyboard' },
  { code: 'KOT', description: 'Koto', family: 'National/Folk' },
  { code: 'LUT', description: 'Lute', family: 'Early Music' },
  { code: 'LYR', description: 'Lyre', family: 'Early Music' },
  { code: 'MAN', description: 'Mandolin', family: 'String' },
  { code: 'MAR', description: 'Marimba', family: 'Percussion' },
  { code: 'MBR', description: 'Mbira', family: 'National/Folk' },
  { code: 'MCS', description: 'Maracas', family: 'National/Folk' },
  { code: 'MEL', description: 'Melodica', family: 'Misc.' },
  { code: 'MEZ', description: 'Mezzo Soprano Voice', family: 'Voice' },
  { code: 'MID', description: 'Midi', family: 'Electronic' },
  { code: 'MSB', description: 'Music Box', family: 'Misc.' },
  { code: 'NAF', description: 'Native American Flute', family: 'National/Folk' },
  { code: 'NAR', description: 'Narrator/Speaker', family: 'Voice' },
  { code: 'NHN', description: 'Natural Horn', family: 'Early Music' },
  { code: 'OBD', description: "Oboe d'Amore", family: 'Woodwind' },
  { code: 'OBO', description: 'Oboe', family: 'Woodwind' },
  { code: 'OND', description: 'Ondes Martinot', family: 'Electronic' },
  { code: 'ORG', description: 'Organ', family: 'Keyboard' },
  { code: 'PER', description: 'Percussion', family: 'Percussion' },
  { code: 'PIA', description: 'Piano', family: 'Keyboard' },
  { code: 'PIC', description: 'Piccolo', family: 'Woodwind' },
  { code: 'PPA', description: 'Pipa', family: 'National/Folk' },
  { code: 'PRO', description: 'Processor', family: 'Electronic' },
  { code: 'PRP', description: 'Prepared Piano', family: 'Keyboard' },
  { code: 'PWH', description: 'Pennywhistle', family: 'National/Folk' },
  { code: 'REC', description: 'Recorder', family: 'Early Music' },
  { code: 'RUA', description: 'Ruan', family: 'National/Folk' },
  { code: 'SAM', description: 'Sampler', family: 'Electronic' },
  { code: 'SAX', description: 'Saxophone', family: 'Woodwind' },
  { code: 'SDM', description: 'Snare drum', family: 'Percussion' },
  { code: 'SEQ', description: 'Sequencer', family: 'Electronic' },
  { code: 'SHK', description: 'Shakuhachi', family: 'National/Folk' },
  { code: 'SHM', description: 'Shamisen', family: 'National/Folk' },
  { code: 'SHO', description: 'Sho', family: 'National/Folk' },
  { code: 'SHW', description: 'Shawm', family: 'Early Music' },
  { code: 'SIT', description: 'Sitar', family: 'National/Folk' },
  { code: 'SNR', description: 'Sopranino Recorder', family: 'Early Music' },
  { code: 'SNS', description: 'Sopranino Saxophone', family: 'Woodwind' },
  { code: 'SOP', description: 'Soprano Voice', family: 'Voice' },
  { code: 'SOU', description: 'Sousaphone', family: 'Brass' },
  { code: 'SPO', description: 'Spoons', family: 'National/Folk' },
  { code: 'SRC', description: 'Soprano Recorder', family: 'Early Music' },
  { code: 'SSX', description: 'Soprano Saxophone', family: 'Woodwind' },
  { code: 'STD', description: 'Steel drums', family: 'Percussion' },
  { code: 'SYN', description: 'Synthesizer', family: 'Electronic' },
  { code: 'TAB', description: 'Tabla', family: 'National/Folk' },
  { code: 'TAM', description: 'Tambour', family: 'Percussion' },
  { code: 'TAP', description: 'Tape', family: 'Electronic' },
  { code: 'TBA', description: 'Tuba', family: 'Brass' },
  { code: 'TEN', description: 'Tenor Voice', family: 'Voice' },
  { code: 'THE', description: 'Theremin', family: 'Electronic' },
  { code: 'THN', description: 'Tenor Horn', family: 'Brass' },
  { code: 'TIM', description: 'Timpani', family: 'Percussion' },
  { code: 'TMB', description: 'Trombone', family: 'Brass' },
  { code: 'TMN', description: 'Tambourine', family: 'Percussion' },
  { code: 'TRC', description: 'Tenor Recorder', family: 'Early Music' },
  { code: 'TRI', description: 'Triangle', family: 'Percussion' },
  { code: 'TRM', description: 'Trumpet', family: 'Brass' },
  { code: 'TSX', description: 'Tenor Saxophone', family: 'Woodwind' },
  { code: 'TTM', description: 'Tamtam', family: 'Percussion' },
  { code: 'TYP', description: 'Toy Piano', family: 'Keyboard' },
  { code: 'UKE', description: 'Ukulele', family: 'String' },
  { code: 'VCL', description: 'Violoncello', family: 'String' },
  { code: 'VDG', description: 'Viola Da Gamba', family: 'Early Music' },
  { code: 'VIB', description: 'Vibraphone', family: 'Percussion' },
  { code: 'VID', description: 'Video', family: 'Electronic' },
  { code: 'VLA', description: 'Viola', family: 'String' },
  { code: 'VLN', description: 'Violin', family: 'String' },
  { code: 'VOC', description: 'Voice', family: 'Voice' },
  { code: 'WBK', description: 'Wood block', family: 'Percussion' },
  { code: 'WHS', description: 'Whistle', family: 'Misc.' },
  { code: 'WTB', description: 'Wagner Tuba', family: 'Brass' },
  { code: 'XYL', description: 'Xylophone', family: 'Percussion' },
  { code: 'YQN', description: 'Yang Qin', family: 'National/Folk' },
  { code: 'ZHG', description: 'Zheng', family: 'National/Folk' },
  { code: 'ZIT', description: 'Zither', family: 'National/Folk' },
];

export const INSTRUMENTATIONS = [
  { code: 'AUD', description: 'Audience', text: '' },
  { code: 'BBA', description: 'Brass Band', text: 'Large group of brasses (approximately 25 players) with optional percussion' },
  { code: 'BCH', description: 'Chorus, Boys', text: "Multiple boy's voices on a part" },
  { code: 'BND', description: 'Band', text: 'Large group of woodwinds and brass with optional percussion, keyboard, and double bass' },
  { code: 'BQN', description: 'Brass Quintet', text: '2 trumpets, horn, trombone, tuba' },
  { code: 'BQU', description: 'Brass Quartet', text: '2 trumpets, horn, trombone or tuba' },
  { code: 'BRC', description: 'Brass Choir', text: 'Group of brasses with optional percussion (usually 10-20 players)' },
  { code: 'BTR', description: 'Brass Trio', text: 'Trumpet, horn, trombone' },
  { code: 'BXT', description: 'Brass Sextet', text: '2 trumpets, 2 horns, trombone, tuba' },
  { code: 'CAO', description: 'Chorus and Orchestra', text: 'Multiple voices with orchestra' },
  { code: 'CBA', description: 'Concert Band', text: 'Large group of woodwinds and brass with optional percussion, keyboard, and double bass' },
  { code: 'CCH', description: 'Chorus, Childrens', text: 'Multiple treble voices on a part' },
  { code: 'CEN', description: 'Chamber Ensemble', text: 'Mixed group of single players on a part; does not denote size of group' },
  { code: 'CHO', description: 'Chorus', text: 'Multiple voices on a part' },
  { code: 'CLC', description: 'Clarinet Choir', text: 'Multiple clarinets (usually 10-20)' },
  { code: 'COR', description: 'Orchestra, Chamber', text: 'Orchestra with fewer winds and strings than full orchestra (usually 20-25 players)' },
  { code: 'CQN', description: 'Clarinet Quintet', text: 'Clarinet and string quartet' },
  { code: 'DCH', description: 'Chorus, Double', text: 'Two choruses' },
  { code: 'FLC', description: 'Flute Choir', text: 'Multiple flutes (usually 10-20)' },
  { code: 'GML', description: 'Gamelan', text: 'Indonesian percussion ensemble, usually including gongs, chimes, mallet percussion, and drums' },
  { code: 'GQT', description: 'Guitar Quartet', text: '4 guitars' },
  { code: 'HNC', description: 'Horn Choir', text: 'Multiple horns (10-20 players)' },
  { code: 'JZE', description: 'Jazz Ensemble', text: 'Multiple saxophones, trumpets, trombones with rhythm section of piano, double bass, drum set, guitar' },
  { code: 'MCH', description: 'Chorus, Mens', text: "Multiple men's voices on a part" },
  { code: 'MXC', description: 'Chorus, Mixed', text: 'Multiple voices on a part (usually SATB)' },
  { code: 'OQU', description: 'Oboe Quartet', text: 'Oboe and string trio' },
  { code: 'ORC', description: 'Orchestra, Full', text: 'Large group of multiple strings with woodwinds, brass and percussion' },
  { code: 'PCE', description: 'Percussion Ensemble', text: 'Multiple players on various instruments' },
  { code: 'PDU', description: 'Piano Duo', text: '2 pianists on two pianos' },
  { code: 'PFH', description: 'Piano Four Hands', text: '2 pianists on one piano' },
  { code: 'PQN', description: 'Piano Quintet', text: 'Piano and string quartet' },
  { code: 'PQU', description: 'Piano Quartet', text: 'Piano and string trio' },
  { code: 'PTR', description: 'Piano Trio', text: 'Violin, cello, piano' },
  { code: 'SBA', description: 'Symphonic Band', text: 'Large group of woodwinds and brass with optional percussion, keyboard, and double bass' },
  { code: 'SGT', description: 'String Trio', text: 'Violin, viola, cello' },
  { code: 'SOC', description: 'String Octet', text: 'Double string quartet' },
  { code: 'SOR', description: 'Orchestra, String', text: 'Multiple strings on a part (usually 16 or more)' },
  { code: 'SQN', description: 'String Quintet', text: '2 violins, viola, cello and one other stringed instrument' },
  { code: 'SQT', description: 'Saxophone Quartet', text: '4 saxophones' },
  { code: 'SQU', description: 'String Quartet', text: '2 violins, viola, cello' },
  { code: 'TBC', description: 'Trombone  Choir', text: 'Multiple trombones (usually 10-20)' },
  { code: 'TCH', description: 'Chorus, Treble', text: 'Multiple childrens voices on a part' },
  { code: 'TPC', description: 'Trumpet Choir', text: 'Multiple trumpets (usually 10-20)' },
  { code: 'TUC', description: 'Tuba Choir', text: 'Multiple tubas (usually 10-20)' },
  { code: 'UCH', description: 'Chorus, Unison', text: 'Multiple voices on a single part' },
  { code: 'WCH', description: 'Chorus, Womens', text: 'Multiple womens voices on a part' },
  { code: 'WEN', description: 'Wind Ensemble', text: 'Large group of woodwinds and brass with optional percussion, keyboard, and double bass' },
  { code: 'WQN', description: 'Woodwind Quintet', text: 'Flute, oboe, clarinet, bassoon, horn' },
  { code: 'WQR', description: 'Woodwind Quartet', text: 'Flute, oboe, clarinet, bassoon' },
  { code: 'YCH', description: 'Chorus, Youth', text: 'Multiple young voices on a part' },
];
