import { BulkUpdateSegment, BulkUpdateResponseType, BulkUpdateDetail, BulkUpdateResultStatus, BulkUpdateItemStatus } from 'models/copyright/bulk-updates/jobs.model';
import { COUNTRIES_DEFAULT_DATE, DATE_FORMAT, END_DATE_NO_DISPLAY, ICE, ICEADMIN_ORIGIN } from 'config/constants/global.constants';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { CopyrightUtils } from '../copyright/copyright.utils';

/**
 * Utility class for handling bulk updates.
 */
export class BulkUpdatesUtils {
  static formatBulkUpdateRequestData(item, ns) {
    const {
      baseIpiNumberReplacement,
      rightTypes: rightTypesString,
      btnEditRightTypes,
      ipiNameNumber,
      ipiNameNumberReplacement,
      postTermCollectionValue,
      priorRoyalties,
      terminationDate,
      territory: { value: territoryValue },
      workIds,
      bulkUpdateType: path,
      comment,
      mode,
      newDuration,
      newTitle,
      newTitleType,
      note,
      partyNameId,
      oldTitle,
      oldTitleType,
      role,
      purpose,
      xrefType,
      broadcastInfo,
      xrefValue,
    } = item;
    const onBehalfNamespace = ns === ICE ? ICEADMIN_ORIGIN : ns;
    switch (path) {
      case BulkUpdateSegment.WORK_MATCHES:
        return {
          body: { workIds, onBehalfNamespace, note, comment },
          path,
        };
      case BulkUpdateSegment.WORK_XREFS:
        return {
          body: { workIds, onBehalfNamespace, xrefType, xrefValue, mode, note, comment },
          path,
        };
      case BulkUpdateSegment.OTHER_PARTIES:
        return {
          body: { workIds, onBehalfNamespace, partyNameId, mode, role, comment, note },
          path,
        };
      case BulkUpdateSegment.PURPOSE:
        return {
          body: { workIds, purpose, onBehalfNamespace, note, comment },
          path,
        };
      case BulkUpdateSegment.TITLES:
        return {
          body: { workIds, onBehalfNamespace, newTitle, newTitleType, oldTitle, oldTitleType, mode, newDuration, note, comment },
          path,
        };
      case BulkUpdateSegment.WORK_BROADCAST_INFO:
        return {
          body: { workIds, onBehalfNamespace, broadcastInfo, note, comment },
          path,
        };
      default:
        break;
    }
    const claimantPartyNameIdTarget = `IPI:${CopyrightUtils.getKeySuffix(ipiNameNumber)}`;
    const claimantPartyNameIdReplacement = `IPI:${CopyrightUtils.getKeySuffix(ipiNameNumberReplacement)}`;
    const claimantPartyIdReplacement = `IPI:${CopyrightUtils.getKeySuffix(baseIpiNumberReplacement)}`;
    let { postTermCollectionDate } = item;
    if (postTermCollectionValue === 'none') {
      postTermCollectionDate = terminationDate;
    }
    if (postTermCollectionValue === 'indefinite') {
      postTermCollectionDate = moment(END_DATE_NO_DISPLAY).format(DATE_FORMAT);
    }
    const rightTypes = rightTypesString === 'ALL' ? null : btnEditRightTypes.PRMR;

    switch (path) {
      case BulkUpdateSegment.SWAP_IP:
        return {
          body: { workIds, onBehalfNamespace, claimantPartyNameIdTarget, claimantPartyNameIdReplacement, claimantPartyIdReplacement, note, comment },
          path,
        };
      case BulkUpdateSegment.TRANSFER_IP: {
        const territories = territoryValue?.match(/([+\-])\w+/g); // creates array of territories like ['+US', '-CA']
        const priorRoyaltiesDate = (priorRoyalties ? moment(COUNTRIES_DEFAULT_DATE) : moment(terminationDate).add(1, 'days')).format(DATE_FORMAT);

        const body = {
          claimantPartyIdReplacement,
          claimantPartyNameIdReplacement,
          claimantPartyNameIdTarget,
          comment,
          note,
          onBehalfNamespace,
          postTermCollectionDate,
          priorRoyaltiesDate,
          rightTypes,
          terminationDate,
          territories,
          workIds,
        };

        return {
          body,
          path,
        };
      }
      case BulkUpdateSegment.TERMINATE_IP: {
        const territories = territoryValue?.match(/([+\-])\w+/g); // creates array of territories like ['+US', '-CA']
        const priorRoyaltiesDate = (priorRoyalties ? moment(COUNTRIES_DEFAULT_DATE) : moment(terminationDate).add(1, 'days')).format(DATE_FORMAT);

        const body = {
          claimantPartyNameIdTarget,
          comment,
          note,
          onBehalfNamespace,
          postTermCollectionDate,
          priorRoyaltiesDate,
          rightTypes,
          terminationDate,
          territories,
          workIds,
        };

        return {
          body,
          path,
        };
      }
    }
    return null;
  }

  static translateBulkUpdateTypeValue(translate: TranslateService, bulkUpdateTypeValue: BulkUpdateResponseType) {
    switch (bulkUpdateTypeValue) {
      case BulkUpdateResponseType.SWAP_IP:
        return translate.instant('BULK_UPDATES.FORM.SWAP_IP');
      case BulkUpdateResponseType.TRANSFER_IP:
        return translate.instant('BULK_UPDATES.FORM.TRANSFER_IP');
      case BulkUpdateResponseType.TERMINATE_IP:
        return translate.instant('BULK_UPDATES.FORM.TERMINATE_IP');
      case BulkUpdateResponseType.PURPOSE:
        return translate.instant('BULK_UPDATES.FORM.PURPOSE');
      case BulkUpdateResponseType.TITLES:
        return translate.instant('BULK_UPDATES.FORM.TITLES');
      case BulkUpdateResponseType.OTHER_PARTIES:
        return translate.instant('BULK_UPDATES.FORM.OTHER_PARTIES');
      case BulkUpdateResponseType.WORK_XREFS:
        return translate.instant('BULK_UPDATES.FORM.WORK_XREFS');
      case BulkUpdateResponseType.WORK_MATCHES:
        return translate.instant('BULK_UPDATES.FORM.WORK_MATCHES');
      case BulkUpdateResponseType.WORK_BROADCAST_INFO:
        return translate.instant('BULK_UPDATES.FORM.WORK_BROADCAST_INFO');
      default:
        return bulkUpdateTypeValue;
    }
  }

  /**
   * Returns the translation key based on the provided BulkUpdateResultStatus.
   *
   * @param status - The BulkUpdateResultStatus value.
   * @returns The translation key corresponding to the provided status.
   */
  static getBulkUpdateTranslationKey(status: BulkUpdateResultStatus | BulkUpdateItemStatus) {
    if (status === BulkUpdateResultStatus.FAILED) {
      return 'BULK_UPDATES.FEEDBACK.FAILED';
    }
    if (status === BulkUpdateResultStatus.RUNNING) {
      return 'BULK_UPDATES.FEEDBACK.IN_PROGRESS';
    }
    if (status === BulkUpdateItemStatus.MASTERING) {
      return 'BULK_UPDATES.FEEDBACK.MASTERING';
    }
    if (status === BulkUpdateResultStatus.DELIVERED_WITH_ERRORS) {
      return 'BULK_UPDATES.FEEDBACK.HAS_ERRORS';
    }
    if ([BulkUpdateResultStatus.DELIVERED_SUCCESSFULLY, BulkUpdateItemStatus.SUCCEEDED].includes(status)) {
      return 'BULK_UPDATES.FEEDBACK.SUCCESS';
    }
    if (status === BulkUpdateItemStatus.SKIPPED) {
      return 'BULK_UPDATES.FEEDBACK.SKIPPED';
    }
    if (status === BulkUpdateItemStatus.NOT_STARTED) {
      return 'BULK_UPDATES.FEEDBACK.NOT_STARTED';
    }
    return '';
  }

  /**
   * Prepares the data for bulk update export.
   *
   * @param options - The options for preparing the data.
   * @param options.items - The items to be processed for bulk update export.
   * @param options.translate - The translation service used for translating status.
   * @returns The prepared data for bulk update export.
   */
  static prepareBulkUpdateExport({ items, translate }: { items: BulkUpdateDetail; translate: TranslateService }) {
    const data = items.map(item => {
      return {
        ...item,
        status: translate.instant(this.getBulkUpdateTranslationKey(item.status)),
      };
    });

    return data;
  }
}
