import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { FormlyValidatorUtils } from '@ice/utils/formly/formly-validators.utils';
import { fieldRepeaterValidator } from '@ice/utils/formly/formly.utils';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, of } from 'rxjs';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CopyrightUtils } from '@ice/utils/copyright/copyright.utils';
import { DialogInfo } from './dialog-info';

export class DialogAddUploadIp {
  private dialogRef: MatDialogRef<DialogMultiLayoutComponent>;
  private ids: Map<string, string>;
  private showInformation: BehaviorSubject<Boolean> = new BehaviorSubject(false);
  private informationMessage: BehaviorSubject<String> = new BehaviorSubject('');
  private showWarning: BehaviorSubject<Boolean> = new BehaviorSubject(false);
  private warningMessage: BehaviorSubject<String> = new BehaviorSubject('');
  private partyKeysLength: number;
  private manualAddDisabled: BehaviorSubject<Boolean> = new BehaviorSubject(false);
  private uploadDisabled: BehaviorSubject<Boolean> = new BehaviorSubject(false);
  private uploadFileField: FormlyFieldConfig;
  private partiesField: FormlyFieldConfig;

  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private store: Store<fromRoot.RootState>,
    private fieldValidatorService: FieldValidatorService,
    private labels: Map<string, string>,
  ) {}

  openDialog(onSubmit: (id) => void, initialValue = new BehaviorSubject({ parties: [] })) {
    this.ids = new Map();
    this.showInformation.next(false);
    this.showWarning.next(false);
    this.manualAddDisabled.next(false);
    this.uploadDisabled.next(false);
    this.dialogRef = this.dialog.open(DialogMultiLayoutComponent, {
      panelClass: 'overflow-hidden',
      data: {
        loading: this.store.pipe(select(fromRoot.getCopyrightLoading)),
        layouts: [this.getAddByIdLayout(onSubmit, initialValue)],
      },
    });
    return this.dialogRef;
  }

  getDialogConfigByModel() {
    return {
      title: of(this.translate.instant(this.labels.get('dialogTitle'))),
      placeholder: `${this.translate.instant('ADD_BY_ID_DIALOG.COMMON.ADD')} ${this.translate.instant('ADD_BY_ID_DIALOG.IPS.TITLE')}`,
      validator: this.getIpsLegalEntityValidator(),
    };
  }

  getAddByIdLayout(onSubmit: (id) => void, initialValue) {
    const isFormValid = new BehaviorSubject(false);
    const dialogConfigBySection = this.getDialogConfigByModel();
    return {
      title: dialogConfigBySection.title,
      className: 'dialog-wrapper-width-50vw',
      forceSubmitDisabled: true,
      layout: [
        {
          group: [
            {
              type: 'formly',
              config: {
                setValidForm: valid => isFormValid.next(!valid),
                formBuilder: of([
                  {
                    className: 'flex-1 ice-no-underline',
                    type: 'label',
                    defaultValue: this.translate.instant(this.labels.get('addManuallyLabel')),
                    key: 'type',
                    templateOptions: { class: 'ice-bold' },
                    hideExpression: () => {
                      return this.manualAddDisabled.getValue();
                    },
                  },
                  {
                    key: 'parties',
                    type: 'repeat',
                    modelOptions: {
                      updateOn: 'blur',
                    },
                    hooks: {
                      onInit: field => {
                        this.partiesField = field;
                      },
                    },
                    templateOptions: {
                      max: 10,
                      noScroll: true,
                      maxItemsText: this.translate.instant(this.labels.get('maxIpMessage')),
                      onRemove: async value => {
                        this.ids.delete(value.fieldGroup[0].id);
                        if (Array.from(this.ids.values()).length === 0) {
                          this.uploadDisabled.next(false);
                          this.uploadFileField.form.controls.files.updateValueAndValidity();
                          this.uploadFileField.form.controls.parties.updateValueAndValidity();
                          this.informationMessage.next(this.translate.instant(this.labels.get('uploadedIpsMessage')).replace(/<<\w+>>/g, (this.partyKeysLength -= 1)));
                        }
                      },
                      onAdd: () => {
                        this.uploadDisabled.next(true);
                        this.informationMessage.next(this.translate.instant(this.labels.get('uploadedIpsMessage')).replace(/<<\w+>>/g, (this.partyKeysLength += 1)));
                      },
                    },
                    expressionProperties: {
                      'templateOptions.disabled': () => {
                        return this.manualAddDisabled.getValue();
                      },
                    },
                    fieldArray: {
                      fieldGroupClassName: 'display-flex',
                      fieldGroup: [
                        {
                          className: 'ice-w-50 ice-mr-10',
                          key: 'IpiNumber',
                          type: 'input',
                          wrappers: ['form-field', 'wrapper-input-text'],
                          modelOptions: {
                            updateOn: 'blur',
                          },
                          templateOptions: {
                            type: 'text',
                            placeholder: dialogConfigBySection.placeholder,
                            required: true,
                            change: (field, $event) => {
                              FormlyValidatorUtils.checkRepeaterDuplicates(field.formControl, 'partiesErrorValidator', 'IpiNumber');
                              FormlyValidatorUtils.checkRepeaterMinimum1(field.formControl, 'partiesErrorValidator');
                            },
                          },
                          hooks: {
                            onDestroy: () => FormlyValidatorUtils.checkRepeaterDuplicatesOnDestroy(this.partiesField.formControl, 'partiesErrorValidator', 'IpiNumber'),
                          },
                          asyncValidators: this.getIpsLegalEntityValidator(),
                        },
                      ],
                    },
                  },
                  {
                    className: 'flex-2',
                    key: 'files',
                    type: 'upload-file',
                    templateOptions: {
                      showInformation: this.showInformation,
                      information: this.informationMessage,
                      showWarning: this.showWarning,
                      warningMessage: this.warningMessage,
                      required: true,
                      disabled: false,
                      label: this.labels.get('dragAndDropLabel'),
                      customAddFile: file => {
                        const fileName = file.name;
                        if (!CopyrightUtils.isValidCSVFile(file)) {
                          this.showWarningMessage(this.translate.instant(this.labels.get('invalidFileMessage')).replace(/<<\w+>>/g, fileName));
                          return;
                        }
                        let invalidPartyIds = 0;
                        this.manualAddDisabled.next(true);
                        this.uploadDisabled.next(true);
                        const reader = new FileReader();
                        reader.onload = async e => {
                          const csvData = reader.result as string;
                          if (csvData?.length) {
                            const parsedCsvData: string[][] = CopyrightUtils.parseCsvData(csvData);
                            if (parsedCsvData.length > 100) {
                              this.showWarningMessage(this.translate.instant(this.labels.get('bulkUploadToMuchEntriesMessage')).replace(/<<\w+>>/g, fileName));
                              return;
                            }
                            let partyKeys = parsedCsvData.map(cols => cols[0].replace('IPI:', ''));
                            partyKeys = partyKeys.filter(row => row); // Filter out empty partyKeys
                            await Promise.all(
                              partyKeys.map(partyKey => {
                                return this.fieldValidatorService.validIPLegalEntityKey(
                                  <FormControl>{ value: partyKey },
                                  partyId => {
                                    if (partyId.trim().length !== 0) {
                                      this.ids.set(partyKey, partyId);
                                    }
                                  },
                                  () => {
                                    invalidPartyIds++;
                                  },
                                );
                              }),
                            );
                            if (this.ids.size === 0) {
                              this.showWarningMessage('No Legal Entity has been imported');
                              this.uploadDisabled.next(false);
                              this.manualAddDisabled.next(false);
                              return;
                            }
                            if (invalidPartyIds !== 0) {
                              this.showInformationMessage(
                                this.translate.instant(this.labels.get('bulkUploadSuccessMessage')).replace(/<<\w+>>/g, partyKeys.length) +
                                  this.translate.instant(this.labels.get('bulkUploadSomeValid')).replace(/<<\w+>>/g, partyKeys.length - invalidPartyIds) +
                                  this.translate.instant(this.labels.get('bulkUploadSomeInvalid')).replace(/<<\w+>>/g, invalidPartyIds),
                              );
                            } else {
                              this.showInformationMessage(
                                this.translate.instant(this.labels.get('bulkUploadSuccessMessage')).replace(/<<\w+>>/g, partyKeys.length) +
                                  this.translate.instant(this.labels.get('bulkUploadAllValid')),
                              );
                            }

                            this.partyKeysLength = partyKeys.length;
                            this.uploadFileField.form.controls.files.setValue([file]);
                            this.uploadFileField.form.controls.files.updateValueAndValidity();
                          }
                        };
                        reader.readAsText(file);
                      },
                      onClose: () => {
                        this.showWarning.next(false);
                        this.showInformation.next(false);
                        this.informationMessage.next('');
                      },
                    },
                    expressionProperties: {
                      'templateOptions.disabled': () => {
                        return this.uploadDisabled.getValue();
                      },
                    },
                    hooks: {
                      onInit: field => {
                        this.uploadFileField = field;
                      },
                    },
                  },
                  fieldRepeaterValidator('partiesErrorValidator', this.translate),
                ]),
                model: initialValue,
                submitEnabled: true,
                submitAvailable: 'true',
                submitLabel: this.translate.instant('POPUP.CONFIRM_BULK'),
                button1Enabled: true,
                button1Available: 'true',
                button1Label: this.translate.instant('POPUP.CANCEL'),
                submit: () => {
                  onSubmit(Array.from(this.ids.values()));
                  this.dialogRef.close();
                },
                onButton1: () => this.showDialogCancel(),
              },
            },
          ],
        },
      ],
    };
  }

  showInformationMessage(message) {
    this.showWarning.next(false);
    this.showInformation.next(true);
    this.informationMessage.next(message);
  }

  showWarningMessage(message) {
    this.showWarning.next(true);
    this.showInformation.next(false);
    this.warningMessage.next(message);
  }

  showDialogCancel() {
    const confirmPopupTitle = this.translate.instant(this.labels.get('cancelUploadingTitle'));
    const confirmPopupMsg = this.translate.instant(this.labels.get('cancelUploadingMessage'));

    const dialogInfo = new DialogInfo(this.translate, this.dialog, this.store);
    const dialogRefInfo = dialogInfo.openDialog(
      confirmPopupTitle,
      confirmPopupMsg,
      () => {
        dialogRefInfo.close();
        this.dialogRef.close();
      },
      () => {
        dialogRefInfo.close();
      },
    );
  }

  getIpsLegalEntityValidator() {
    return {
      parties_validation: {
        expression: async (control: FormControl, field) =>
          await this.fieldValidatorService.validIPLegalEntityKey(
            <FormControl>{ value: control.value.replace('IPI:', '') },
            partyId => {
              this.ids.set(field.id, partyId);
            },
            () => {},
          ),
        message: this.translate.instant('ADD_BY_ID_DIALOG.IPS.ERROR'),
      },
    };
  }
}
