import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DataTableRow, SortInfo } from '@ice/components/data-table/data-table';
import { TabsUtils, LocalStorageUtils } from '@ice';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import * as fromApiCalls from 'config/api-calls';
import { DataTableBuilder } from 'config/data-table-builders/data-table-builder';
import { RootDatatable } from 'config/data-table-builders/root-datatable';
import { SectionsConfig } from 'config/sections-config';
import { flatten, get, uniq } from 'lodash';
import * as fromRoot from 'store/root';

export class CopyrightAgreementsWritersDataTable extends RootDatatable implements DataTableBuilder {
  constructor(protected translate: TranslateService, protected fuseTranslationLoader: FuseTranslationLoaderService, protected store) {
    super(translate);
    this.fuseTranslationLoader.loadTranslations(english);
  }

  getDefaultSorting(): SortInfo[] {
    return [{ prop: 'creatorName', dir: 'asc' }];
  }

  formatSort(sort: SortInfo) {
    switch (sort.prop) {
      case 'cleanCreatorIpi':
        return { prop: 'creatorIpi', dir: sort.dir };
      case 'cleanPublisherIpi':
        return { prop: 'publisherIpi', dir: sort.dir };
    }
    return { prop: sort.prop, dir: sort.dir };
  }

  getDataTable(): DataTableRow[] {
    return [
      {
        name: this.translate.instant('AGREEMENTS.WRITERS.FIELDS.WRITER_NAME'),
        prop: 'creatorName',
        flexGrow: 1.5,
        onClickAction: row => this.goToIP(row.creatorIpi),
        cellClass: 'cursor-pointer',
      },
      {
        name: this.translate.instant('AGREEMENTS.WRITERS.FIELDS.WRITER_IPI_NAME_NUMBER'),
        prop: 'cleanCreatorIpi',
        flexGrow: 1,
        onClickAction: row => this.goToIP(row.creatorIpi),
        cellClass: 'cursor-pointer',
      },
      {
        name: this.translate.instant('AGREEMENTS.WRITERS.FIELDS.OP_NAME'),
        prop: 'publisherName',
        flexGrow: 1.5,
        onClickAction: row => this.goToIP(row.publisherIpi),
        cellClass: 'cursor-pointer',
      },
      {
        name: this.translate.instant('AGREEMENTS.WRITERS.FIELDS.OP_IPI_NAME_NUMBER'),
        prop: 'cleanPublisherIpi',
        flexGrow: 1,
        onClickAction: row => this.goToIP(row.publisherIpi),
        cellClass: 'cursor-pointer',
      },
      {
        name: this.translate.instant('AGREEMENTS.WRITERS.FIELDS.WORK_NUMBERS'),
        prop: 'workCount',
        flexGrow: 1,
        onClickAction: row => this.getAgreementWritersDetails(row),
      },
    ];
  }

  getColumnMode(): ColumnMode {
    return ColumnMode.flex;
  }

  private goToIP(ipId: string): void {
    return this.store.dispatch(
      new fromRoot.Go({
        path: [`${SectionsConfig.IPS.domainName}/${SectionsConfig.IPS.name}/${ipId}/${TabsUtils.getDefaultTabName(SectionsConfig.IPS.name)}`],
      }),
    );
  }

  private goToIPNewTab(ipId: string): void {
    return this.store.dispatch(
      new fromRoot.OpenNewTab({
        path: [`${SectionsConfig.IPS.domainName}/${SectionsConfig.IPS.name}/${ipId}/${TabsUtils.getDefaultTabName(SectionsConfig.IPS.name)}`],
      }),
    );
  }

  private getAgreementWritersDetails(event: any, newTabTarget?: boolean) {
    this.store.dispatch(
      new fromRoot.StartApiCall({
        apiCall: fromApiCalls.getAgreementWritersDetails,
        apiCallData: {
          labels: { agreementId: event.agreementId, creatorIpi: event.creatorIpi, publisherIpi: event.publisherIpi },
        },
        callBack: response => (newTabTarget ? this.afterGetWritersDetailsNewTab(response) : this.afterGetWritersDetails(response)),
      }),
    );
  }

  private afterGetWritersDetails(response) {
    if (!response?.error) {
      const xref = uniq(flatten((response?.items || []).map(item => get(item, 'workIds'))));
      if (Array.isArray(xref)) {
        this.store.dispatch(new fromRoot.SaveXrefAndNavigate({ xref: xref.slice(0, 1000) }));
      }
    }
  }

  private afterGetWritersDetailsNewTab(response) {
    if (!response?.error) {
      const xref = uniq(flatten((response?.items || []).map(item => get(item, 'workIds'))));
      if (Array.isArray(xref)) {
        LocalStorageUtils.setXrefList(xref.slice(0, 1000));
        this.store.dispatch(
          new fromRoot.OpenNewTab({
            path: [`${SectionsConfig.WORKS.domainName}/${SectionsConfig.WORKS.name}/search`],
          }),
        );
      }
    }
  }

  onMouseSelect(event: any): void {
    const { activatedColumn } = event;
    switch (activatedColumn) {
      case 'creatorName':
      case 'cleanCreatorIpi':
        this.goToIPNewTab(event.creatorIpi);
        break;
      case 'publisherName':
      case 'cleanPublisherIpi':
        this.goToIPNewTab(event.publisherIpi);
        break;
      case 'workCount':
        this.getAgreementWritersDetails(event, true);
        break;
    }
  }
}
