import { Pipe, PipeTransform } from '@angular/core';
import { StringUtils } from '@ice';

@Pipe({ name: 'addPercentToNumber' })
export class AddPercentToNumberPipe implements PipeTransform {
  transform(value: string, args: any[] = []) {
    if (value && StringUtils.isContainsNumbers(value)) {
      return `${value} %`;
    }
    return value && value.length > 0 ? value : '-';
  }
}
