import { FormControl } from '@angular/forms';
import { AgreementDetail, fieldConfig, StringUtils } from '@ice';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { ErrorsUtils } from '@ice/utils/api-responses/errors.utils';
import { select } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ipsSocietiesCodes } from 'assets/ts/ips-societies';
import * as fromApiCalls from 'config/api-calls';
import { RELATIONTYPES } from 'config/constants/relation.constants';
import { get, toNumber } from 'lodash';
import { BehaviorSubject, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { IceMaskedIdsConfig } from 'services/masked-ids/masked-ids.model';
import * as fromRoot from 'store/root';

export enum XREFTYPES {
  agreementGroup = 'agreementGroup',
  society = 'society',
  publisher = 'publisher',
}

export class DialogEditAgreementCrossReferences {
  static openDialog(dialog, store, translate, fuseTranslationLoader, fieldValidatorService) {
    let fieldsToRetry: any;
    store.dispatch(new fromRoot.CleanLoading());
    const errors$ = new BehaviorSubject(null);

    const dialogRef = dialog.open(DialogMultiLayoutComponent, {
      data: {
        className: 'dialog-wrapper-width-420-h',
        layouts: [
          {
            title: of(`${translate.instant('POPUP_COMMON.ADD')} ${translate.instant('AGREEMENTS.CROSS_REFERENCES.TITLE')}`),
            layout: [
              {
                group: [
                  {
                    type: 'formly',
                    config: {
                      formBuilder: of(this.getPopupXrefsForm(translate, store, fieldValidatorService)),
                      model: of({}),
                      submitEnabled: true,
                      submitAvailable: 'true',
                      submitLabel: translate.instant('POPUP_COMMON.CONFIRM'),
                      button1Enabled: true,
                      button1Available: 'true',
                      button1Label: translate.instant('POPUP_COMMON.CANCEL'),
                      submit: fields => {
                        fieldsToRetry = fields;
                        dialogRef.componentInstance.setLayout(1);
                        this.saveCrossReference(fields, store, dialogRef, errors$);
                      },
                      onButton1: () => {
                        dialogRef.close();
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            title: of(`${translate.instant('POPUP_COMMON.ADD')} ${translate.instant('AGREEMENTS.CROSS_REFERENCES.TITLE')}`),
            layout: [
              {
                group: [
                  {
                    type: 'response-error',
                    className: 'dialog-wrapper-auto',
                    config: {
                      loading: store.pipe(select(fromRoot.getCopyrightLoading)),
                      response: of(null),
                      errors: errors$,
                      messageIcon: of('info'),
                      errorButtons: of([
                        {
                          text: of(translate.instant('POPUP.CLOSE')),
                          visibleOnError: true,
                          action: () => {
                            store.dispatch(new fromRoot.CleanResponse());
                            dialogRef.close();
                          },
                        },
                        {
                          text: of(translate.instant('POPUP.RETRY')),
                          visibleOnError: true,
                          action: () => fieldsToRetry && this.saveCrossReference(fieldsToRetry, store, dialogRef, errors$),
                        },
                      ]),
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    });
  }

  static openDeleteDialog(dialog, store, translate, fuseTranslationLoader, xrefToDelete) {
    store.dispatch(new fromRoot.CleanLoading());
    const errors$ = new BehaviorSubject(null);
    const dialogRef = dialog.open(DialogMultiLayoutComponent, {
      data: {
        className: 'dialog-wrapper-width-420-h',
        layouts: [
          {
            title: of(`${translate.instant('POPUP_COMMON.DELETE')} ${translate.instant('AGREEMENTS.CROSS_REFERENCES.TITLE')}`),
            layout: [
              {
                group: [
                  {
                    type: 'response-error',
                    className: 'dialog-wrapper-auto',
                    config: {
                      response: of({ text: `${translate.instant('AGREEMENTS.CROSS_REFERENCES.ARE_YOU_SURE')} ${xrefToDelete.otherId || xrefToDelete.groupId}` }),
                      messageIcon: of('info'),
                      responseButtons: of([
                        {
                          text: of(translate.instant('POPUP_COMMON.CANCEL')),
                          action: () => dialogRef.close(),
                        },
                        {
                          text: of(translate.instant('POPUP_COMMON.CONFIRM')),
                          class: 'ok-button',
                          action: () => {
                            dialogRef.componentInstance.setLayout(1);
                            this.deleteCrossReference(xrefToDelete, store, dialogRef, errors$);
                          },
                        },
                      ]),
                    },
                  },
                ],
              },
            ],
          },
          {
            title: of(`${translate.instant('POPUP_COMMON.DELETE')} ${translate.instant('AGREEMENTS.CROSS_REFERENCES.TITLE')}`),
            layout: [
              {
                group: [
                  {
                    type: 'response-error',
                    className: 'dialog-wrapper-auto',
                    config: {
                      loading: store.pipe(select(fromRoot.getCopyrightLoading)),
                      response: of(null),
                      errors: errors$,
                      messageIcon: of('info'),
                      errorButtons: of([
                        {
                          text: of(translate.instant('POPUP.CANCEL')),
                          visibleOnError: true,
                          action: () => {
                            store.dispatch(new fromRoot.CleanResponse());
                            dialogRef.close();
                          },
                        },
                        {
                          text: of(translate.instant('POPUP.RETRY')),
                          visibleOnError: true,
                          action: () => {
                            this.deleteCrossReference(xrefToDelete, store, dialogRef, errors$);
                          },
                        },
                      ]),
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    });
  }

  static getPopupXrefsForm(translate, store, fieldValidatorService) {
    return [
      fieldConfig([
        {
          className: 'flex-1',
          fieldGroup: [
            {
              key: 'type',
              type: 'select',
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                required: true,
                label: translate.instant('AGREEMENTS.CROSS_REFERENCES.REFERENCE_TYPE'),
                options: [
                  { label: translate.instant('AGREEMENTS.CROSS_REFERENCES.REFERENCE_TYPES.AGREEMENT_GROUP_REFERENCE'), value: XREFTYPES.agreementGroup },
                  { label: translate.instant('AGREEMENTS.CROSS_REFERENCES.REFERENCE_TYPES.SOCIETY_REFERENCE_NUMBER'), value: XREFTYPES.society },
                  { label: translate.instant('AGREEMENTS.CROSS_REFERENCES.REFERENCE_TYPES.PUBLISHER_REFERENCE_NUMBER'), value: XREFTYPES.publisher },
                ],
              },
            },
          ],
        },
      ]),
      fieldConfig([
        {
          className: 'flex-1',
          fieldGroup: [
            {
              key: 'groupType',
              type: 'select',
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                required: true,
                label: translate.instant('AGREEMENTS.CROSS_REFERENCES.AGREEMENT_GROUP_TYPE'),
                options: [
                  { label: translate.instant('AGREEMENTS.CROSS_REFERENCES.AGREEMENT_GROUP_TYPES.INCLUDE'), value: RELATIONTYPES.GROUP },
                  { label: translate.instant('AGREEMENTS.CROSS_REFERENCES.AGREEMENT_GROUP_TYPES.RECIPIENT'), value: RELATIONTYPES.RECIPIENT },
                ],
              },
              hideExpression: model => model.type !== XREFTYPES.agreementGroup,
            },
          ],
        },
      ]),
      fieldConfig([
        {
          className: 'flex-1 ice-max-width-100',
          fieldGroup: [
            {
              key: 'society',
              type: 'ice-autocomplete',
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                required: true,
                placeholder: translate.instant('AGREEMENTS.CROSS_REFERENCES.SOCIETY_CODE'),
                options: ipsSocietiesCodes.map(society => ({ label: society.name, value: society.code })),
              },
              hideExpression: model => model.type !== XREFTYPES.society,
              validators: {
                socityCode: {
                  expression: c => {
                    let isCorrect = true;
                    if (c.value) {
                      let selectedValue: string;
                      if (typeof c.value === 'string') {
                        selectedValue = c.value;
                      } else {
                        // It's an option
                        selectedValue = c.value.value;
                      }
                      selectedValue.split(',').forEach(value => {
                        if (!ipsSocietiesCodes.find(society => society.code === value.trim())) {
                          isCorrect = false;
                        }
                      });
                    }
                    return isCorrect;
                  },
                  message: (error, field: FormlyFieldConfig) => translate.instant('REPERTOIRES.STEPS.DETAILS.FIELDS.CREATOR_AFFILIATIONS.FIELDS.ERROR_SOCIETY_NOT_EXISTS'),
                },
              },
            },
          ],
        },
      ]),
      fieldConfig([
        {
          className: 'flex-1',
          fieldGroup: [
            {
              key: 'publisher',
              wrappers: ['form-field', 'wrapper-input-text'],
              type: 'input',
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                required: true,
                label: translate.instant('AGREEMENTS.CROSS_REFERENCES.PUBLISHER'),
              },
              hideExpression: model => model.type !== XREFTYPES.publisher,
              asyncValidators: {
                ipiNumberValid: {
                  expression: (control, field) =>
                    fieldValidatorService.searchPartyFieldValidator(
                      (partyId, partyNameId, name, partyName) => {
                        if (get(partyName, 'attributes.pubPrefix', null)) {
                          control.parent.get('pubPrefix').setValue(partyName.attributes.pubPrefix);
                        }
                      },
                      control,
                      field,
                      {
                        key: 'xrefs',
                        value: control.value && control.value.split(':').length > 1 ? `${control.value}` : ['ICE', 'IPI'].map(prefix => `${prefix}:${control.value}`).join(','),
                      },
                    ),
                  message: translate.instant('AGREEMENT_GROUP.ERRORS.IP_NAME_KEY_ERROR'),
                },
              },
            },
            { key: 'pubPrefix' },
          ],
        },
      ]),
      fieldConfig([
        {
          className: 'flex-1',
          fieldGroup: [
            {
              key: 'xref',
              wrappers: ['form-field', 'wrapper-input-text'],
              type: 'input',
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                required: true,
                needValidate: false,
                label: translate.instant('AGREEMENTS.CROSS_REFERENCES.REFERENCE_NUMBER'),
                focus: (field: FormlyFieldConfig) => (field.templateOptions.needValidate = true),
              },
              hooks: {
                onInit: field => field.formControl.markAsTouched(),
              },
              asyncValidators: {
                agreementGroupValidator: {
                  expression: (control: FormControl, field) =>
                    new Promise((resolve, reject) => {
                      if (field.model.type !== XREFTYPES.agreementGroup) {
                        resolve(true);
                      } else {
                        if (field && field.templateOptions.needValidate && control.value) {
                          store.dispatch(new fromRoot.ShowDataLoadingVisibility(true));
                          return fieldValidatorService
                            .existAgreementGroupReference(control.value)
                            .then((res: boolean) => resolve(res), store.dispatch(new fromRoot.ShowDataLoadingVisibility(false)));
                        } else {
                          resolve(true);
                        }
                      }
                    }),
                  message: translate.instant('AGREEMENTS.CROSS_REFERENCES.ERROR_INVALID_AGREEMENT_GROUP'),
                },
              },
            },
          ],
        },
      ]),
    ];
  }

  static saveCrossReference(fields, store, dialog, errors$) {
    store
      .pipe(
        select(fromRoot.getCopyrightDetailBySection),
        filter(detail => !!detail),
        take(1),
        map((detail: AgreementDetail) => {
          let prefix: string;
          let maskedIdsList: IceMaskedIdsConfig[] = [];
          store.dispatch(new fromRoot.CleanResponse());
          const xref = fields.xref.trim();
          const nameSpace = !StringUtils.isContainsLetters(xref) && 'CUBE:';
          const groupId = nameSpace ? `${nameSpace}${xref}` : xref;
          const groups =
            fields.type === XREFTYPES.agreementGroup
              ? [
                  ...detail.groups,
                  {
                    ns: 'CUBE',
                    groupId,
                    relation: fields.groupType,
                  },
                ]
              : [...detail.groups];
          switch (fields.type) {
            case 'society':
              prefix = `SAREF:SO${toNumber(fields.society.value)}`;
              maskedIdsList = [{ source: `$['relations'][?(@.otherId == '${prefix}:${fields.xref.trim()}')]['otherId']`, type: 'society' }];
              break;
            case 'publisher':
              prefix = `PAREF:${fields.pubPrefix || fields.publisher}`;
              maskedIdsList = [{ source: `$['relations'][?(@.otherId == '${prefix}:${fields.xref.trim()}')]['otherId']`, type: 'party-name' }];
          }
          const relations =
            fields.type !== XREFTYPES.agreementGroup
              ? [
                  ...detail.relations,
                  {
                    otherId: `${prefix}:${fields.xref.trim()}`,
                    relation: 'XREF',
                  },
                ]
              : [...detail.relations];
          store.dispatch(
            new fromRoot.StartApiCall({
              apiCall: fromApiCalls.updateAgreementCrossReference,
              apiCallData: { body: JSON.stringify({ id: detail.attributes.id, groups, relations }), labels: { id: detail.attributes.key } },
              callBack: (response, errors) => this.afterResponse(response, store, dialog, errors, errors$),
            }),
          );
        }),
      )
      .subscribe();
  }

  static deleteCrossReference(xrefToDelete, store, dialog, errors$) {
    store
      .pipe(
        select(fromRoot.getCopyrightDetailBySection),
        filter(detail => !!detail),
        take(1),
        map((detail: AgreementDetail) => {
          const groups =
            xrefToDelete.type === 'group'
              ? detail.groups.filter(group => !(group.groupId === xrefToDelete.groupId && group.relation === xrefToDelete.relation))
              : [...detail.groups];
          const relations =
            xrefToDelete.type === 'relation'
              ? detail.relations.filter(relation => !(relation.otherId === xrefToDelete.otherId && relation.relation === xrefToDelete.relation))
              : [...detail.relations];
          store.dispatch(
            new fromRoot.StartApiCall({
              apiCall: fromApiCalls.updateAgreementCrossReference,
              apiCallData: { body: JSON.stringify({ id: detail.attributes.id, groups, relations }), labels: { id: detail.attributes.key } },
              callBack: (response, errors) => this.afterResponse(response, store, dialog, errors, errors$),
            }),
          );
        }),
      )
      .subscribe();
  }

  static afterResponse(response, store, dialog, errors, errors$) {
    const requestErrors = ErrorsUtils.getResponseErrors(errors) || ErrorsUtils.getResponseErrors(response);
    if (!requestErrors) {
      dialog.close();
      store.dispatch(new fromRoot.RefreshDetail());
    } else {
      errors$.next(requestErrors);
    }
  }
}
