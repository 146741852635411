import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { QueryEditorexpertSearchModule } from '../query-editor-expert-search/query-editor-expert-search.module';
import { IceMaterialModule } from './../../material.module';
import { ExpertSearchComponent } from './expert-search.component';

@NgModule({
  declarations: [ExpertSearchComponent],
  imports: [CommonModule, QueryEditorexpertSearchModule, TranslateModule, FlexLayoutModule, ReactiveFormsModule, IceMaterialModule],
  exports: [ExpertSearchComponent],
})
export class ExpertSearchModule {}
