import { FormlyFieldConfig } from '@ngx-formly/core';
import { of } from 'rxjs';

export class SummaryShares {
  static getFormField(translate): FormlyFieldConfig[] {
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [{ className: 'flex-1', template: `<h2 class="ice-text-color-blue">${translate.instant('AGREEMENTS.SHARES.TITLE')}</h2>` }],
      },
      this.getRepeatSectionFormConfig(translate),
    ];
  }

  static getRepeatSectionFormConfig(translate): FormlyFieldConfig {
    return {
      key: 'shares',
      type: 'shares-repeat',
      templateOptions: {
        wrapperClass: 'ice-share-col-w',
        rowLabels: [
          {
            label: translate.instant('AGREEMENTS.SHARES.TABLE.COL_TITLE_RIGHT_TYPE'),
            info: translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.RIGHT_TYPE'),
          },
          {
            label: translate.instant('AGREEMENTS.SHARES.TABLE.RIGHT_TYPE_CREATOR'),
            info: translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.CREATOR'),
          },
          {
            label: translate.instant('AGREEMENTS.SHARES.TABLE.RIGHT_TYPE_PUBLISHER'),
            info: translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.PUBLISHER'),
          },
          {
            label: translate.instant('AGREEMENTS.SHARES.TABLE.RIGHT_TYPE_ASSIGNEE'),
            info: translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.ASSIGNEE'),
          },
          {
            label: translate.instant('AGREEMENTS.SHARES.TABLE.SHARES_TERRITORY'),
            info: translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.SHARES'),
          },
          {
            label: translate.instant('AGREEMENTS.SHARES.TABLE.RIGHT_TYPE_INCLUSION'),
            info: translate.instant('AGREEMENTS.SHARES.TABLE.TOOLTIPS.INCLUSION'),
          },
        ],
        showButtons: of(false),
      },
      fieldArray: {
        fieldGroupClassName: 'ice-share-col-w',
        fieldGroup: [
          {
            className: 'display-flex-row-justify-left',
            type: 'label',
            defaultValue: 'Mechanical',
            key: 'type',
            modelOptions: { updateOn: 'submit' },
            templateOptions: { class: 'ice-bold', isSummary: true },
          },
          {
            fieldGroupClassName: 'display-flex-row-justify-left',
            fieldGroup: [
              {
                className: 'ice-form-field-infix',
                type: 'input',
                key: 'creator',
                defaultValue: 0,
                modelOptions: { updateOn: 'blur' },
                templateOptions: {
                  type: 'number',
                  min: 0,
                  max: 100,
                  required: true,
                  disabled: true,
                },
              },
              {
                template: '%',
              },
            ],
          },
          {
            fieldGroupClassName: 'display-flex-row-justify-left',
            fieldGroup: [
              {
                className: 'ice-form-field-infix',
                type: 'input',
                key: 'publisher',
                defaultValue: 0,
                modelOptions: { updateOn: 'blur' },
                templateOptions: {
                  type: 'number',
                  min: 0,
                  max: 100,
                  required: true,
                  disabled: true,
                },
              },
              {
                template: '%',
              },
            ],
          },
          {
            fieldGroupClassName: 'display-flex-row-justify-left',
            fieldGroup: [
              {
                className: 'ice-form-field-infix',
                type: 'input',
                key: 'assignee',
                defaultValue: 0,
                modelOptions: { updateOn: 'blur' },
                templateOptions: {
                  type: 'number',
                  min: 0,
                  max: 100,
                  required: true,
                  disabled: true,
                },
              },
              {
                template: '%',
              },
            ],
          },
          {
            className: 'ice-form-field-infix-no-percentage w-50 ice-overflow-x-hidden-span',
            type: 'ice-input',
            key: 'territory',
            defaultValue: '',
            modelOptions: { updateOn: 'blur' },
            templateOptions: {
              required: false,
              disabled: true,
            },
          },
          {
            className: 'ice-form-field-infix-no-percentage w-50 ice-overflow-x-hidden-span',
            type: 'ice-input',
            key: 'inclusion',
            defaultValue: 'ALL',
            modelOptions: { updateOn: 'blur' },
            templateOptions: {
              required: false,
              disabled: true,
            },
          },
        ],
      },
    };
  }
}
