export const locale = {
  lang: 'en',
  data: {
    SAMPLE: {
      HELLO: 'Hello, World!',
    },
    COOKIE_TITLE: 'This website uses necessary cookies only',
    COOKIE_FED_TITLE: `This is an ICE platform, developed to support the copyright services for ICE's CMOs copyright customers. ICE uses necessary cookies to ensure the 
platform's functionality, by enabling basic features such as page navigation and access to secure areas. Without these cookies, this platform cannot operate effectively.
 For more detailed information, see <a href="https://www.iceservices.com/cookie-policy/?_gl=1*1q9lnd6*_up*MQ..*_ga*MTE2MzM3NjMyNS4xNzEyODQzNjU1*_ga_QSZEMWD5QD*MTcxMjg0Mz
 Y1NC4xLjAuMTcxMjg0MzY1NC4wLjAuMA.." target="_blank">ICE's Cookie Policy.</a>`,
    COOKIE_KIND_USE: 'Use necessary cookies only',
    COOKIE_KIND: 'Necessary',
    SHOW_COOKIE_DETAILS: 'Show details',
    COOKIE_DECLARATION: 'Cookie Declaration',
    COOKIE_ABOUT: 'About',
    COOKIE_ABOUT_FEED: 'Necesary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website.',
    COOKIE_NAME: 'Name',
    COOKIE_PROVIDER: 'Provider',
    COOKIE_PURPOSE: 'Purpose',
    COOKIE_EXPIRE_TIME: 'Expire',
    COOKIE_TYPE: 'Type',
    COOKIE_DATADOG_DETAIL: 'This cookie is set by Datadog to group all events generated from a unique user session across multiple pages. ',
    COOKIE_DATADOG_DETAIL_1: 'It contains the current session ID, whether the session is excluded due to sampling, and the expiration date of the session.',
    COOKIE_DATADOG_DETAIL_2: ' The cookie is extended for an extra 15 minutes every time the user interacts with the website, up to the maximum user session duration (4 hours).',
  },
};
