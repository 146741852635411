import { XREF_LIST } from 'config/constants/search.constants';
import { PayloadSharePicture } from 'models';

export class LocalStorageUtils {
  static get(key) {
    return localStorage.getItem(key);
  }

  static remove(key) {
    localStorage.removeItem(key);
  }

  static getObject(key) {
    return JSON.parse(localStorage.getItem(key));
  }

  static set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  private static saveFilter(userName, filter, storageKey) {
    this.set(`${userName}:${storageKey}`, filter);
  }

  private static getFilter(userName, storageKey) {
    return this.getObject(`${userName}:${storageKey}`);
  }

  static getSharePictureFilter(userName): PayloadSharePicture & Record<string, unknown> {
    return this.getFilter(userName, 'filterShares');
  }

  static getDefaultSharePictureFilter(userName): PayloadSharePicture & Record<string, unknown> {
    return this.getFilter(userName, 'filterDefaultShares');
  }

  static getAllClaimsFilter(userName): any {
    return this.getFilter(userName, 'filterAllClaims');
  }

  static getWorkClaimsFilter(userName): any {
    return this.getFilter(userName, 'filterWorkClaims');
  }

  static setFilterSharePicture(userName, filter: PayloadSharePicture & Record<string, unknown>) {
    this.saveFilter(userName, filter, 'filterShares');
  }
  static setDefaultFilterSharePicture(userName, filter: PayloadSharePicture & Record<string, unknown>) {
    this.saveFilter(userName, filter, 'filterDefaultShares');
  }

  static setFilterAllClaims(userName, filter) {
    // Dates are not stored in localStorage, as it is confusing when time goes by and those dates are pointing to the past
    const { distributionDate, usageDate, ...reducedFilter } = filter;
    this.saveFilter(userName, reducedFilter, 'filterAllClaims');
  }

  static setFilterWorkClaims(userName, filter) {
    this.saveFilter(userName, filter, 'filterWorkClaims');
  }

  static saveRedirectURL(url) {
    this.set('redirectURL', url);
  }

  static getRedirectURL() {
    const redirectURL = this.getObject('redirectURL');
    this.remove('redirectURL');
    return redirectURL;
  }

  static setXrefList(xrefListValue: any) {
    this.set(XREF_LIST, xrefListValue);
  }

  static removeXrefList() {
    this.remove(XREF_LIST);
  }
}
