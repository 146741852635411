import { DataTableRow } from '@ice/components/data-table/data-table';
import { DialogInfo } from 'config/dialog-builders/dialog-info';
import { of } from 'rxjs';

export class PartiesSharedSchemas {
  static getSchema(translate, dialog, store, tabTitle, deleteInfo, onDelete: (row, dialogRef) => void, canEdit = false): DataTableRow[] {
    return [
      { name: this.getTranslate(translate, 'NAME'), prop: 'name', flexGrow: 1 },
      { name: this.getTranslate(translate, 'IPI_NAME_NUMBER'), prop: 'ipiNameNumber', flexGrow: 1, cellClass: 'ipi-name-number' },
      {
        name: '',
        prop: 'delete',
        actionButtonIcon: 'delete',
        flexGrow: 0.001,
        maxWidth: 50,
        minWidth: 50,
        resizeable: false,
        hideActionButton: row => of(canEdit),
        action: row => {
          const dialogInfo = new DialogInfo(translate, dialog, store);
          const dialogRef = dialogInfo.openDialog(
            `${translate.instant('REPERTOIRES.COMMON.DELETE')} ${tabTitle}`,
            deleteInfo,
            () => onDelete(row, dialogRef),
            () => dialogRef.close(),
          );
        },
        actionButtonTooltip: `${translate.instant('REPERTOIRES.COMMON.DELETE')} ${tabTitle}`,
      },
    ];
  }

  static getTranslate(translate, fieldTranslatePath) {
    return translate.instant(`REPERTOIRES.COMMON.${fieldTranslatePath}`);
  }
}
