import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ResponseError } from './response-error';

@Component({
  selector: 'ice-response-error-component',
  templateUrl: './response-error.component.html',
  styleUrls: ['./response-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResponseErrorComponent {
  @Input() config: ResponseError;
  isArray = Array.isArray;
}
