import { Inject, Optional } from '@angular/core';
import { MatMomentDateAdapterOptions, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { isNaN } from 'lodash';
import moment from 'moment';

export class IceDateAdapter extends MomentDateAdapter {
  constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string, @Optional() @Inject(MAT_MOMENT_DATE_ADAPTER_OPTIONS) options?: MatMomentDateAdapterOptions) {
    super(dateLocale, options);
  }

  getValidDateOrNull(obj) {
    return obj;
  }

  format(date, displayFormat) {
    const da = this.clone(date).format(displayFormat);
    return da === 'Invalid date' ? (isNaN(date?._i) ? moment().format(displayFormat) : date?._i) : da;
  }

  sameDate(first, second) {
    if (first && second) {
      if (typeof first['_i']?.split !== 'undefined') {
        const month = first['_i']?.split('-')?.[1];
        const day = first['_i']?.split('-')?.[2];
        const exceptionValues = ['0', '00'];
        const firstValid = this.isValid(first);
        const secondValid = this.isValid(second);
        if (exceptionValues.includes(month) || exceptionValues.includes(day)) {
          return false;
        }
        if (firstValid && secondValid) {
          return !this.compareDate(first, second);
        }
        return firstValid === secondValid;
      }
    }
    return first === second;
  }
}
