import { CopyrightUtils, SocietySearchItem, SocietySearchItemCleaned, TerritoryUtils } from '@ice';
import { SocietiesUtils } from '@ice/utils/societies/societies.utils';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { societiesICE } from 'config/constants/ips.constants';
import { flatten, intersection, padStart, uniq } from 'lodash';

const TOOLTIP_IS_ICE_SOCIETY = 'Is ICE Society';
const TOOLTIP_NO_ICE_SOCIETY = 'Is Not ICE Society';

export class SearchSocietiesParser {
  static parse(items: any[], translate: TranslateService, extraParams?: Object, extraItems?: string[], store?: Store<any>) {
    return items.map((item: SocietySearchItem) => {
      const { attributes } = item;
      const socCode = (attributes && attributes.id && padStart(attributes.id.split(':')[1], 3, '0')) || '';
      const socName = (socCode && SocietiesUtils.searchSocietyNameById(socCode)) || '';
      const memberships = attributes && attributes.memberships;
      const territories = memberships && uniq(flatten(memberships.map(membership => membership.territories)));
      const countryCodes = territories && TerritoryUtils.convertTerritoryArrayElements(territories, 'name').join(', ');
      const isIce = socCode && intersection(societiesICE, [socCode]).length > 0;
      const isIceIcon =
        (isIce && CopyrightUtils.generateIconWithTooltip(TOOLTIP_IS_ICE_SOCIETY, 'check')) || CopyrightUtils.generateIconWithTooltip(TOOLTIP_NO_ICE_SOCIETY, 'clear');

      return <SocietySearchItemCleaned>{ ...attributes, dataType: 'society', socName, socCode, countryCodes, isIce, isIceIcon };
    });
  }
}
