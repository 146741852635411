import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CounterClaimConflictStatus } from '@ice';
import { DatepickerUtils } from '@ice/utils/datepicker/datepicker.utils';
import { select, Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { CopyrightAgreementsDataTable } from 'config/data-table-builders/copyright.agreements';
import { DialogSearchAgreement } from 'config/dialog-builders/dialog-search-agreement';
import { StepType } from 'config/stepper-builders/stepper-config';
import { cloneDeep, get } from 'lodash';
import moment from 'moment';
import { filter, map, take } from 'rxjs/operators';
import { SearchService } from 'services/search/search.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromNewSectionItem from 'store/new-section-item';
import * as fromRoot from 'store/root';

export class AgreementConflictStep {
  searchTableBuilder: CopyrightAgreementsDataTable;
  fieldAgreements: any;
  fieldDeadline: any;

  constructor(
    private translate: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private dialog: MatDialog,
    private store: Store<fromRoot.RootState>,
    private storeNewItem: Store<fromNewSectionItem.NewSectionItemState>,
    private searchService: SearchService,
    private fieldValidatorService: FieldValidatorService,
  ) {
    this.searchTableBuilder = new CopyrightAgreementsDataTable(translate, translationLoader, store);
  }

  getStep(): StepType {
    return {
      label: this.translate.instant('AGREEMENT-CONFLICT.TITLE_CREATION'),
      formBuilder: [this.getStatus(), this.getButton(), this.getDataTable()],
    };
  }

  private getStatus(): FormlyFieldConfig {
    const name = 'deadline';
    const label = this.translate.instant('AGREEMENT-CONFLICT.DEADLINE');
    const controlFieldToAssign = this.fieldDeadline;
    const required = true;
    const translate = this.translate;
    const extraClass = 'flex-2';
    const hooks = {
      onInit: field => (this.fieldDeadline = field),
    };
    return {
      fieldGroupClassName: 'agreement-conflict-status display-flex',
      fieldGroup: [
        {
          className: 'flex-2 ice-display-none',
          key: 'id',
          type: 'input',
        },
        {
          className: 'flex-2 ice-display-none',
          key: 'cases',
          type: 'input',
        },
        {
          className: 'flex-2',
          key: 'status',
          type: 'select',
          modelOptions: {
            updateOn: 'change',
          },
          templateOptions: {
            required: true,
            label: 'Status',
            change: field => {
              if (field.formControl.value === CounterClaimConflictStatus.OPEN || field.formControl.value === CounterClaimConflictStatus.POTENTIAL_CONFLICT) {
                this.fieldDeadline.formControl.setValue(moment().add(21, 'days').format('YYYY-MM-DD'));
              }
            },
            options: this.store
              .pipe(
                select(fromNewSectionItem.getNewSectionState),
                filter(state => !!state),
                take(1),
              )
              .pipe(
                map((state: any) => {
                  const { fields } = state;
                  const { status, tags } = fields;
                  const conflictTypeAutomatic = tags && tags.isAutomatic ? get(tags, 'isAutomatic')[0] : false;
                  const overlap = { label: this.translate.instant('AGREEMENT-CONFLICT.STATUS_OVERLAP'), value: CounterClaimConflictStatus.OPEN };
                  const conflict = { label: this.translate.instant('AGREEMENT-CONFLICT.STATUS_CONFLICT'), value: CounterClaimConflictStatus.POTENTIAL_CONFLICT };
                  const dispute = { label: this.translate.instant('AGREEMENT-CONFLICT.STATUS_DISPUTE'), value: CounterClaimConflictStatus.CONFIRMED };
                  const resolved = { label: this.translate.instant('AGREEMENT-CONFLICT.STATUS_RESOLVED'), value: CounterClaimConflictStatus.RESOLVED };
                  const closed = { label: this.translate.instant('AGREEMENT-CONFLICT.STATUS_CLOSED'), value: CounterClaimConflictStatus.CLOSED };
                  switch (status) {
                    case CounterClaimConflictStatus.OPEN:
                      return [overlap, conflict];
                    case CounterClaimConflictStatus.POTENTIAL_CONFLICT:
                      if (conflictTypeAutomatic === 'true') {
                        return [conflict, dispute];
                      }
                      return [conflict, dispute, resolved];
                    case CounterClaimConflictStatus.CONFIRMED:
                      if (conflictTypeAutomatic === 'true') {
                        return [dispute];
                      }
                      return [dispute, resolved];
                    case CounterClaimConflictStatus.RESOLVED:
                      return [resolved, closed];
                    default:
                      return [conflict];
                  }
                }),
              ),
          },
        },
        DatepickerUtils.getDatepickerField({
          name,
          label,
          controlFieldToAssign,
          required,
          translate,
          extraClass,
          hooks,
        }),
        {
          className: 'flex-2 ice-display-none',
          key: 'oldStatus',
          type: 'input',
          templateOptions: {
            type: 'text',
            required: false,
            disabled: false,
          },
        },
      ],
    };
  }

  private getButton() {
    return {
      type: 'button',
      key: 'usageTypes',
      templateOptions: {
        text: 'Add agreement',
        btnType: 'info',
        onClick: event => {
          DialogSearchAgreement.openDialog(this.dialog, this.store, this.translate, this.translationLoader, this.searchService, this.fieldValidatorService, selected => {
            const list = cloneDeep(this.fieldAgreements.formControl.value);
            const newAgreementDuplicate = list.find(agreement => agreement.key === selected.key);
            if (newAgreementDuplicate) {
              this.store.dispatch(
                new fromRoot.ShowSnackBar({
                  duration: 5000,
                  message: this.translate.instant('AGREEMENT-CONFLICT.ERROR.DUPLICATE_AGREEMENT'),
                }),
              );
            } else {
              this.fieldAgreements.formControl.markAsTouched();
              this.fieldAgreements.formControl.setValue([...list, selected]);
              this.dialog.closeAll();
            }
          });
        },
      },
    };
  }

  private getDataTable(): FormlyFieldConfig {
    return {
      fieldGroupClassName: 'agreement-conflict-datatable display-flex',
      fieldGroup: [
        {
          className: 'flex-1',
          key: 'agreements',
          type: 'datatable-editable',
          templateOptions: {
            config: {
              data: this.store.pipe(select(fromNewSectionItem.getNewSectionItemFieldsWithSection)).pipe(
                map(fields => {
                  return (fields && fields.fields && fields.fields['agreements'] && fields.fields['agreements'].length && fields.fields['agreements']) || [];
                }),
              ),
              schema: this.searchTableBuilder.getDataTable(),
              columnMode: ColumnMode.flex,
            },
          },
          hooks: {
            onInit: field => {
              this.fieldAgreements = field;
            },
          },
          validators: {
            min_required_validation: {
              expression: c => c.value && c.value.length && c.value.length > 1,
            },
          },
        },
      ],
    };
  }
}
