import { concat } from 'lodash';

export const prRightsOnLine = ['OB', 'OD'];
export const prRightsOnLineSelectOptions = [
  { value: 'OB', label: '(OB) Making Available Right - non-interactive' },
  { value: 'OD', label: '(OD) Making Available Right - interactive' },
];
export const prRightsNonOnLine = ['MP', 'PC', 'PR', 'PT', 'RB', 'RT', 'TB', 'TO', 'TP', 'TV', 'ER'];
export const prRightsNonOnLineSelectOptions = [
  { value: 'MP', label: '(MP) Motion Picture Exhibition Right' },
  { value: 'PC', label: '(PC) Right of Public Performance of a Recording' },
  { value: 'PR', label: '(PR) Performing Right' },
  { value: 'PT', label: '(PT) Theatrical Performing Right' },
  { value: 'RB', label: '(RB) Radio Broadcast Right' },
  { value: 'RT', label: '(RT) Retransmission Right' },
  { value: 'TB', label: '(TB) Television Broadcast Right ' },
  { value: 'TO', label: '(TO) Right of Public Performance of Radio Broadcast' },
  { value: 'TP', label: '(TP) Right of Retransmission of Performance' },
  { value: 'TV', label: '(TV) Right of Public Performance of TV Broadcast' },
  { value: 'ER', label: '(ER) Educational Right' },
];
export const prRights = concat(prRightsOnLine, prRightsNonOnLine);
export const prRightsSet = new Set(prRights);
export const prRightsSelectOptions = concat(prRightsOnLineSelectOptions, prRightsNonOnLineSelectOptions);
export const mrRightsOnline = ['MB', 'MD'];
export const mrRightsOnlineSelectOptions = [
  { value: 'MB', label: '(MB) Mechanical Right - non-interactive' },
  { value: 'MD', label: '(MD) Mechanical Right - interactive' },
];
export const mrRightsNonOnline = ['BT', 'MA', 'MR', 'MT', 'MV', 'RL', 'SY'];
export const mrRightsNonOnlineSelectOptions = [
  { value: 'BT', label: '(BT) Blank Tape Remuneration' },
  { value: 'MA', label: '(MA) Mechanical Right Radio' },
  { value: 'MR', label: '(MR) Mechanical Right Sound Carrier' },
  { value: 'MT', label: '(MT) Mechanical Right TV' },
  { value: 'MV', label: '(MV) Mechanical Right Video' },
  { value: 'RL', label: '(RL) Rental and Lending Right' },
  { value: 'SY', label: '(SY) Synchronisation Right' },
];
export const mrRights = concat(mrRightsOnline, mrRightsNonOnline);
export const mrRightsSet = new Set(mrRights);
export const mrRightsSelectOptions = concat(mrRightsOnlineSelectOptions, mrRightsNonOnlineSelectOptions);
export const syRights = ['SY'];
export const rpRights = ['RP'];
export const allRights = concat(prRights, mrRights, syRights, rpRights);
export const allRightsSet = new Set(allRights);
export const societiesICE = ['023', '035', '040', '044', '048', '052', '055', '078', '079', '089', '090'];
export const IPI = 'IPI';
export const ICE = 'ICE';
export const iceCustomerSocieties = ['023', '052', '055', '040', '079', '090', '089'];
export const syRight = 'SY';

export const IP_CONSTANTS = {
  LEGAL_ENTITY: 'L',
  NATURAL_PERSON: 'N',
  MALE: 'M',
  FEMALE: 'F',
  TYPE_PARENT: 'parent',
  TYPE_CHILDREN: 'children',
  TYPE_MW: 'MW',
};

export const IP_TYPES = [
  { value: 'PA', label: 'Performing Artist' },
  { value: 'PG', label: 'Performing Group' },
  { value: 'PU', label: 'Publisher' },
  { value: 'PX', label: 'Publisher information migrated without shares' },
  { value: 'RS', label: 'Repertoire Source' },
];

export const IP_TYPES_PERSON: string[] = ['PA', 'PU'];

export const NEW_IP_ASSIGNOR = 0;
export const NEW_IP_ASSIGNEE = 1;
export const NEW_IP_CLAIMANT = 2;
export const NEW_IP_PUBLISHER = 3;
export type NewIpType = -1 | 0 | 1 | 2 | 3;

export const IP_CLAUSES = ['LP', 'REVRG', 'SO', 'SY', 'ULA', 'ULB', 'ULS', 'NM', 'CC'];

export enum LinkedByType {
  Publishers = 'PUBLISHERS',
  Writers = 'WRITERS',
}

export enum LinkedTabsName {
  Publishers = 'linked-publishers',
  Writers = 'linked-writers',
}

export const CREATION_CLASS = 'MW'; // Hardcoded until values are defined //
export const IP_TYPE_LEGAL_ENTITY = 'L';
export const IP_TYPE_NATURAL_PERSON = 'N';
export const MECHANICAL_RIGHTS = 'MR';

export enum IP_STATUS {
  ACTIVE = '1',
  PURCHASED = '2',
  MERGED = '3',
  DELETED = '4',
}

export enum PartyRelationType {
  MERGE = 'MERG',
  DISPLAY_REFERENCE = 'DISP',
  CROSS_REFERENCE = 'XREF',
  OLD = 'OLD',
  MATCH = 'MTCH',
  POSSIBLE_MATCH = 'POSS',
  PURCHASE = 'PURCHASE',
  DELETION = 'DELETION',
}
