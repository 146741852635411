import { AfterViewInit, Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormContainerComponent } from '@ice';
import { DynamicComponent } from '../dynamic-component';
import { AnalysisAndComparisons } from './analysis-and-comparisons';
import { AnalysisAndComparisonsUtils } from './analysis-and-comparisons.utils';

@Component({
  selector: 'ice-analysis-and-comparisons',
  templateUrl: './analysis-and-comparisons.component.html',
  styleUrls: ['./analysis-and-comparisons.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AnalysisAndComparisonsComponent implements DynamicComponent, AfterViewInit {
  @Input() components: AnalysisAndComparisons;
  public fromRightClass: boolean;
  public fromLeftClass: boolean;
  private analysisAndComparisonsUtils: AnalysisAndComparisonsUtils;
  @ViewChild('filterForm') filterForm: FormContainerComponent;

  constructor() {
    this.analysisAndComparisonsUtils = new AnalysisAndComparisonsUtils();
    this.fromRightClass = true;
    this.fromLeftClass = true;
  }

  ngAfterViewInit() {
    requestAnimationFrame(() => {
      this.fromRightClass = false;
      this.fromLeftClass = false;
    });

    if (!!this.filterForm?.form && this.components?.comparisonFilter?.onSubmit) {
      this.components?.comparisonFilter?.onSubmit(this.filterForm.form.value);
    }
  }

  public filterChanged(): void {
    this.analysisAndComparisonsUtils.highlightExpansionLists(['#expansion-list-']);
  }
}
