import { DateTimeUtils } from '@ice/utils/date-time/date-time.utils';
import { REPORT_REF_TYPE_AGREEMENT, REPORT_REF_TYPE_IPI, REPORT_REF_TYPE_WORK, REPORT_TYPES } from 'config/constants/reports.constants';
import { get, isEmpty } from 'lodash';

export const ReportWorkDetailsIPSharesFormatter = {
  formatter: item => {
    let works = [];
    let partyIdList = [];
    const partyNameId = [];
    let agreementId = [];

    const {
      refNumberType,
      workeys,
      refNumber,
      usageDate,
      distributionDate,
      reportingSociety,
      mrRightType,
      prRightType,
      mrDistributionSociety,
      prDistributionSociety,
      refNumberTypeWork,
      ipBaseWorksList,
      partyId,
      payment,
      fiche,
      includeRepertoireDetails,
      includePerformerInformation,
    } = item;

    switch (refNumberType) {
      case REPORT_REF_TYPE_WORK:
        if (workeys && workeys.length) {
          works = workeys.map(key => `${refNumberTypeWork}:${key}`);
        } else {
          works = [`${refNumberTypeWork}:${refNumber}`];
        }
        break;
      case REPORT_REF_TYPE_AGREEMENT:
        agreementId = [!(refNumber || '').includes('ICE') ? `ICE:${refNumber}` : refNumber];
        break;
      case REPORT_REF_TYPE_IPI:
        if (ipBaseWorksList) {
          partyIdList = partyId;
        } else {
          partyNameId.push(`IPI:${refNumber}`);
        }
        break;
    }

    const countryValue = get(item, 'country.value') || get(item, 'country', '');
    const output: any = {
      reportType: REPORT_TYPES.IPS_SHARES,
      paymentOrOwnership: payment,
      country: parseInt(countryValue, 10),
      usageDate,
      distributionDate,
      currentDate: DateTimeUtils.getTodayFormatted(),
      mrDistributionSociety,
      prDistributionSociety,
      works,
      returnWorkRefs: get(item, 'include'),
      agreementId,
      partyNameId,
      partyId: partyIdList,
      mrRightType,
      prRightType,
      mrOverrideRightType: mrRightType,
      prOverrideRightType: prRightType,
      includePerformerInformation,
      includeRepertoireDetails,
      fiche,
    };
    output.reportingSociety = reportingSociety ? [reportingSociety] : [];
    return output;
  },
  parseClone: item => {
    const {
      works,
      agreementId,
      partyId,
      partyNameId,
      country,
      usageDate,
      distributionDate,
      reportingSociety,
      mrRightType,
      prRightType,
      mrOverrideRightType,
      prOverrideRightType,
      mrDistributionSociety,
      prDistributionSociety,
      paymentOrOwnership,
      includeRepertoireDetails,
      fiche,
      includePerformerInformation,
      returnWorkRefs,
    } = item;
    const refNumberType = !isEmpty(works) ? REPORT_REF_TYPE_WORK : !isEmpty(agreementId) ? REPORT_REF_TYPE_AGREEMENT : REPORT_REF_TYPE_IPI;
    let workeys = [];
    let refNumber = '';
    let refNumberTypeWork = null;
    let ipBaseWorksList = null;

    switch (refNumberType) {
      case REPORT_REF_TYPE_WORK:
        if (works.length > 1) {
          workeys = works.map(key => {
            if (key.includes(':')) {
              const splittedKey = key.split(':');
              refNumberTypeWork = splittedKey[0];
              return splittedKey[1];
            }
            return key;
          });
        } else {
          const key = works[0];
          if (key.includes(':')) {
            const splittedKey = key.split(':');
            refNumberTypeWork = splittedKey[0];
            refNumber = splittedKey[1];
          } else {
            refNumber = key;
          }
        }
        break;
      case REPORT_REF_TYPE_AGREEMENT:
        refNumber = (agreementId[0] || '').includes('ICE:') ? agreementId[0].split(':')[1] : agreementId[0];
        break;
      case REPORT_REF_TYPE_IPI:
        if (!isEmpty(partyNameId)) {
          // Select all works to IP base -> NO
          ipBaseWorksList = false;
          const pNameId = partyNameId[0];
          refNumber = pNameId.includes(':') ? pNameId.split(':')[1] : pNameId;
        } else {
          // Select all works to IP base -> YES
          ipBaseWorksList = true;
        }
    }

    const output = {
      refNumberType,
      refNumber,
      workeys,
      partyNameId,
      country: country ? (typeof country === 'string' ? country : country.toString()) : null,
      usageDate,
      distributionDate,
      reportingSociety: reportingSociety && reportingSociety.length ? reportingSociety[0] : null,
      mrRightType,
      prRightType,
      mrOverrideRightType,
      prOverrideRightType,
      mrDistributionSociety,
      prDistributionSociety,
      payment: paymentOrOwnership,
      include: returnWorkRefs,
      partyId,
      ipBaseWorksList,
      includeRepertoireDetails,
      includePerformerInformation,
      fiche,
    };
    if (refNumberTypeWork) {
      output['refNumberTypeWork'] = refNumberTypeWork;
    }
    return output;
  },
};
