import { Credentials, NewPasswordCredentials, PasswordResetCredentials, User } from '@ice';
import { Action } from '@ngrx/store';
import { UserCleaned } from 'models/users/users.model';
import { IceErrorPayload } from 'models/action-payloads/ice-error-payload';
import { IceRedirectPayload } from '../../../../models/action-payloads/ice-redirect.payload';

export const DO_UPDATE_USER = '[Auth] Update user info';

export const DO_LOGIN = '[Auth] Do a Login';
export const DO_FED_LOGIN = '[Auth] Do a Federated Login';
export const DO_LOGIN_FAIL = '[Auth] Login Fail';
export const DO_LOGIN_SUCCESS = '[Auth] Login Success';
export const RESET_DO_LOGIN_FAIL = '[Auth] Reset Login Fail';

export const GOTO_PASSWORD_STEP1 = '[Auth] GoTo Forgot Password  Step1 ';
export const DO_PASSWORD_STEP1_SEND = '[Auth] Password Reset Step1 Send';
export const DO_PASSWORD_STEP1_FAIL = '[Auth] Password Reset  Step1 Fail';
export const DO_PASSWORD_STEP1_SUCCESS = '[Auth] Password Reset Step1 Success';

export const GOTO_PASSWORD_STEP2 = '[Auth] GoTo Password Reset Step2';
export const DO_PASSWORD_STEP2_FAIL = '[Auth] Password Reset Step2 Fail';
export const DO_PASSWORD_STEP2_SEND = '[Auth] Password Reset Step2 Send';
export const DO_PASSWORD_STEP2_SUCCESS = '[Auth] Password Reset Step2 Success';

export const GOTO_LOGIN = '[Auth] GoTo Login';

export const GOTO_NEW_PASSWORD = '[Auth] GoTo New Password';
export const DO_NEW_PASSWORD = '[Auth] Do New Password';
export const DO_NEW_PASSWORD_SUCCESS = '[Auth] Do New Password Success';
export const DO_NEW_PASSWORD_FAIL = '[Auth] Do New Password Fail';

export const SET_NEW_USER_ACTIVE = '[Auth] Set New User Active';
export const SET_NEW_USER_ACTIVE_SUCCESS = '[Auth] Set New User Active Success';
export const SET_NEW_USER_ACTIVE_FAIL = '[Auth] Set New User Active Fail';

export const LOGOUT = '[Auth] Logout';

export const SAVE_REDIRECT_URL_ON_LOGIN = '[Router] Save Redirect Url On Login';

export const GET_USER_DETAILS = '[Auth] Get User Details';
export const GET_USER_DETAILS_SUCCESS = '[Auth] Get User Details Success';
export const GET_USER_DETAILS_FAIL = '[Auth] Get User Details Fail';

export const GET_USER_ORGANIZATIONS = '[Auth] Get User Organizations';
export const GET_USER_ORGANIZATIONS_SUCCESS = '[Auth] Get User Organizations Success';
export const GET_USER_ORGANIZATIONS_FAIL = '[Auth] Get User Organizations Fail';

export class DoLogin implements Action {
  readonly type = DO_LOGIN;

  constructor(public payload: Credentials) {}
}

export class DoFederatedLogin implements Action {
  readonly type = DO_FED_LOGIN;
  constructor() {}
}

export class DoLoginFail implements Action {
  readonly type = DO_LOGIN_FAIL;

  constructor(public payload: Error | any) {}
}

export class ResetDoLoginFail implements Action {
  readonly type = RESET_DO_LOGIN_FAIL;

  constructor(public payload: Error | any) {}
}

export class DoLoginSuccess implements Action {
  readonly type = DO_LOGIN_SUCCESS;

  constructor(public payload: User) {}
}

export class GoToForgotPassword implements Action {
  readonly type = GOTO_PASSWORD_STEP1;
}

export class DoPasswordReset implements Action {
  readonly type = DO_PASSWORD_STEP1_SEND;

  constructor(public payload: string) {}
}

export class DoGoToLogin implements Action {
  readonly type = GOTO_LOGIN;
}

export class DoPasswordResetSuccess implements Action {
  readonly type = DO_PASSWORD_STEP1_SUCCESS;
}

export class DoPasswordResetFail implements Action {
  readonly type = DO_PASSWORD_STEP1_FAIL;

  constructor(public payload: string) {}
}

export class GoToPasswordResetStep2 implements Action {
  readonly type = GOTO_PASSWORD_STEP2;

  constructor(public payload: string) {}
}

export class DoPasswordResetStep2Send implements Action {
  readonly type = DO_PASSWORD_STEP2_SEND;

  constructor(public payload: PasswordResetCredentials) {}
}

export class DoPasswordResetStep2Fail implements Action {
  readonly type = DO_PASSWORD_STEP2_FAIL;

  constructor(public payload: string) {}
}

export class DoPasswordResetStep2Success implements Action {
  readonly type = DO_PASSWORD_STEP2_SUCCESS;

  constructor(public payload: Credentials) {}
}

export class DoUpdateUser implements Action {
  readonly type = DO_UPDATE_USER;

  constructor(public payload: User) {}
}

export class GotoNewPassword implements Action {
  readonly type = GOTO_NEW_PASSWORD;
  constructor(public payload: any) {}
}

export class DoNewPassword implements Action {
  readonly type = DO_NEW_PASSWORD;

  constructor(public payload: NewPasswordCredentials) {}
}

export class DoNewPasswordFail implements Action {
  readonly type = DO_NEW_PASSWORD_FAIL;

  constructor(public payload: string) {}
}

export class DoNewPasswordSuccess implements Action {
  readonly type = DO_NEW_PASSWORD_SUCCESS;
  constructor(public payload: User) {}
}

export class SetNewuserActive implements Action {
  readonly type = SET_NEW_USER_ACTIVE;

  constructor() {}
}

export class SetNewuserActiveFail implements Action {
  readonly type = SET_NEW_USER_ACTIVE_FAIL;

  constructor(public payload: string) {}
}

export class SetNewuserActiveSuccess implements Action {
  readonly type = SET_NEW_USER_ACTIVE_SUCCESS;
}

export class Logout implements Action {
  readonly type = LOGOUT;

  constructor(public payload: boolean = false) {}
}

export class SaveRedirectOnLogin implements Action {
  readonly type = SAVE_REDIRECT_URL_ON_LOGIN;

  constructor(public payload: IceRedirectPayload) {}
}

export class GetUserDetails implements Action {
  readonly type = GET_USER_DETAILS;

  constructor(public payload: { userName: string }) {}
}

export class GetUserDetailsSuccess implements Action {
  readonly type = GET_USER_DETAILS_SUCCESS;

  constructor(public payload: UserCleaned) {}
}

export class GetUserDetailsFail implements Action {
  readonly type = GET_USER_DETAILS_FAIL;

  constructor(public payload?: IceErrorPayload) {}
}

export class GetUserOrganizations implements Action {
  readonly type = GET_USER_ORGANIZATIONS;

  constructor() {}
}

export class GetUserOrganizationsSuccess implements Action {
  readonly type = GET_USER_ORGANIZATIONS_SUCCESS;

  constructor(public payload: any[]) {}
}

export class GetUserOrganizationsFail implements Action {
  readonly type = GET_USER_ORGANIZATIONS_FAIL;

  constructor(public payload: any) {}
}

export type LoginActions =
  | DoLogin
  | DoFederatedLogin
  | DoUpdateUser
  | DoLoginFail
  | ResetDoLoginFail
  | DoLoginSuccess
  | GoToForgotPassword
  | DoPasswordReset
  | DoPasswordResetFail
  | GoToPasswordResetStep2
  | DoPasswordResetStep2Send
  | DoPasswordResetSuccess
  | DoPasswordResetStep2Fail
  | DoPasswordResetStep2Success
  | GotoNewPassword
  | DoNewPassword
  | DoNewPasswordFail
  | DoGoToLogin
  | DoNewPasswordSuccess
  | Logout
  | SaveRedirectOnLogin
  | GetUserDetails
  | GetUserDetailsSuccess
  | GetUserDetailsFail
  | GetUserOrganizations
  | GetUserOrganizationsSuccess
  | GetUserOrganizationsFail;
