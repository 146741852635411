export const WorkClaimsInitModel = [
  {
    label: 'root',
    name: '',
    parent: null,
    color: '#17becf',
    level: 0,
    contextMenu: {
      items: [
        {
          label: 'ADD_CREATOR',
          action: (nodeData, removeContextMenu) => {},
        },
      ],
    },
  },
];
export const WorkInitModel = { additionalClaims: WorkClaimsInitModel };
