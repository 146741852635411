import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DataTableRow, SortInfo } from '@ice/components/data-table/data-table';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import { DataTableBuilder } from 'config/data-table-builders/data-table-builder';
import { RootDatatable } from 'config/data-table-builders/root-datatable';
import { SectionsConfig } from 'config/sections-config';
import * as fromRoot from 'store/root';

export class CopyrightAgreementChainsDataTable extends RootDatatable implements DataTableBuilder {
  constructor(protected translate: TranslateService, protected fuseTranslationLoader: FuseTranslationLoaderService, protected store) {
    super(translate);
    this.fuseTranslationLoader.loadTranslations(english);
  }

  getDefaultSorting(): SortInfo[] {
    return [{ prop: 'owner', dir: 'asc' }];
  }

  getDataTable(): DataTableRow[] {
    return [
      {
        name: this.translate.instant('IPS.AGREEMENT_CHAINS.OWNER'),
        prop: 'ownerTemplate',
        tooltip: 'ownerTooltip',
        cellClass: 'ice-txt-size-14 hierarchy-cell',
        badge: { text: 'badgeText', tooltip: 'badgeTooltip', backgroundColor: '#0A9BE5', textColor: '#fff' },
        flexGrow: 8,
        sortable: false,
      },
      {
        name: this.translate.instant('IPS.AGREEMENT_CHAINS.REFERENCE'),
        prop: 'key',
        cellClass: 'ice-txt-size-14',
        flexGrow: 2,
        sortable: false,
        onClickAction: row => {
          this.store.dispatch(
            new fromRoot.Go({
              path: [`copyright/${SectionsConfig.AGREEMENTS.name}/${row.key}`],
            }),
          );
        },
      },
      {
        name: this.translate.instant('IPS.AGREEMENT_CHAINS.START_DATE'),
        prop: 'startDate',
        cellClass: 'ice-txt-size-14',
        flexGrow: 2,
        sortable: false,
      },
      {
        name: this.translate.instant('AGREEMENTS.PR'),
        prop: 'pr',
        icons: 'prIcons',
        cellClass: 'ice-txt-size-14 agreements-pr',
        flexGrow: 1.4,
        exportProp: 'prToExport',
        headerTooltip: this.translate.instant('AGREEMENTS.PR_TOOLTIP'),
        sortable: false,
      },
      {
        name: this.translate.instant('AGREEMENTS.MR'),
        prop: 'mr',
        icons: 'mrIcons',
        cellClass: 'ice-txt-size-14 agreements-mr',
        flexGrow: 1.4,
        exportProp: 'mrToExport',
        headerTooltip: this.translate.instant('AGREEMENTS.MR_TOOLTIP'),
        sortable: false,
      },
      {
        name: this.translate.instant('IPS.AGREEMENT_CHAINS.PRIOR_ROYALTIES'),
        prop: 'priorRoyaltiesStartDate',
        cellClass: 'ice-txt-size-14',
        flexGrow: 1,
        sortable: false,
      },
      {
        name: this.translate.instant('IPS.AGREEMENT_CHAINS.TERRITORY'),
        prop: 'territoriesText',
        tooltip: 'territoriesTooltip',
        cellClass: 'ice-txt-size-14',
        flexGrow: 3,
        sortable: false,
      },
      {
        name: this.translate.instant('IPS.AGREEMENT_CHAINS.TYPE'),
        prop: 'agreementType',
        cellClass: 'ice-txt-size-14',
        flexGrow: 1,
        sortable: false,
      },
    ];
  }

  getColumnMode(): ColumnMode {
    return ColumnMode.flex;
  }
  displayCheck(row: any, firstSelectedItem: any, targetItem: any, column?: any, value?: any): boolean {
    return true;
  }
}
