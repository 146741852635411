<div fxflex="1 1 100" fxLayout="column" fxLayoutAlign="center center">
  <h4 [style.color]="(hasError && hasError | async) ? 'red' : 'black'" style="text-align: center">
    <span *ngIf="hasError && hasError | async">{{ error }}</span
    ><br /><span>{{ message }}</span>
  </h4>
  <mat-form-field appearance="outline">
    <mat-label>{{ title }}</mat-label>
    <ngx-mat-file-input formControlName="outline" (change)="importFile($event)" [placeholder]="placeholder" [accept]="fileType"></ngx-mat-file-input>
    <mat-icon matSuffix>folder</mat-icon>
  </mat-form-field>
  <table
    *ngIf="(customPreview && (customPreview | async)) || (!customPreview && fileType === '.csv' && output); let table"
    class="csv-table {{ csvMaxHeightStyle && 'csv-max-height' }}"
  >
    <tr *ngFor="let row of table">
      <td [style.color]="(hasError && hasError | async) || val === 'NOT_FOUND' ? 'red' : 'black'" *ngFor="let val of row">
        {{ val === 'NOT_FOUND' ? ('ERROR.' + val | translate) : val }}
      </td>
    </tr>
  </table>
</div>
