import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '@ice/i18n/en/expansion-list';
import { flatMap, get } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of, timer } from 'rxjs';
import { concatMap, map, mapTo, switchMap } from 'rxjs/operators';
import { DataTableComponent, WidgetSwitchComponent } from '..';
import { SharePictureTypes } from './../../../models/copyright/share-picture/share-picture';
import { CardWithSharesRepertoire, SharePictureRepertoireTypes } from './card-with-shares-repertoire';

@Component({
  selector: 'ice-card-with-shares-repertoire',
  templateUrl: './card-with-shares-repertoire.component.html',
  styleUrls: ['./card-with-shares-repertoire.component.scss'],
})
export class CardWithSharesRepertoireComponent implements OnInit {
  @Input() config: CardWithSharesRepertoire;
  selectedType: SharePictureRepertoireTypes;
  selectedTypeSubject: BehaviorSubject<SharePictureRepertoireTypes> = new BehaviorSubject(SharePictureRepertoireTypes.REPERTOIRE);
  tableVisibleColumns: Observable<string[]>;
  tableData: Observable<any[]>;
  treeLevels: Observable<any>;
  treeData: Observable<any[]>;
  treeVisibility: Observable<boolean>;
  disableLog: Observable<boolean>;
  fixedFace: Observable<string>;
  totalsTiles: Observable<any[]>;
  claimTotals: any[];
  totalsClass: Observable<string>;
  isFront: boolean;
  requestStatus: Observable<string>;
  canShow = true;
  @ViewChild('iceWidgetSwitch') iceWidgetSwitch: WidgetSwitchComponent;
  @ViewChild(DataTableComponent) dataTable: DataTableComponent;

  constructor(private fuseTranslationLoader: FuseTranslationLoaderService) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  ngOnInit() {
    this.selectedType =
      (this.config &&
        get(
          this.config.shareTypes.find(shareType => shareType.checked),
          'type',
        )) ||
      SharePictureRepertoireTypes.REPERTOIRE;
    if (this.config && this.config.table) {
      this.tableVisibleColumns = this.getObservableBySelectType(this.config.table.visibleColumns);
      this.tableData = this.getObservableBySelectType(this.config.table.model);
      this.requestStatus = this.config.table.requestStatus;
    }
    if (this.config && this.config.totals) {
      this.totalsClass = this.getObservableBySelectType(this.config.totals.class);
    }
    this.getTotalTiles();
  }

  setIsFront(isFront) {
    this.isFront = isFront;
  }

  onResetSort() {
    this.dataTable.onResetSort();
  }

  onSelectType(type) {
    this.selectedTypeSubject.next(type);
    this.checkHideTree(type);
  }

  onToggleChange(event) {
    this.selectedType = event.checked ? this.config.shareTypes[0].type : this.config.shareTypes[1].type;
    this.selectedTypeSubject.next(this.selectedType);
  }

  getObservableBySelectType(property: any): Observable<any> {
    if (property) {
      return combineLatest([...flatMap(property), this.selectedTypeSubject] as any[]).pipe(switchMap(_ => property[this.selectedType] || property['default']));
    } else {
      return null;
    }
  }

  getTotalTiles() {
    if (this.tableData && this.config.totals) {
      this.totalsTiles = this.tableData.pipe(
        map(model => this.config.totals.totalParser(model)),
        concatMap((model, index) => {
          if (model) {
            const { totalMR, totalPR, publisherSubTotalPR, publisherSubTotalMR, writerSubTotalMR, writerSubTotalPR, specialSubTotalPR, specialSubTotalMR } = model;
            const totals = [
              { text: this.config.totals.writerSubTotalLabel, span: 2 },
              { text: `${writerSubTotalPR}`, span: 1 },
              { text: `${writerSubTotalMR}`, span: 1 },
              { text: this.config.totals.publisherSubTotalLabel, span: 2 },
              { text: `${publisherSubTotalPR}`, span: 1 },
              { text: `${publisherSubTotalMR}`, span: 1 },
            ];

            if (specialSubTotalPR !== '0.00' && specialSubTotalMR !== '0.00') {
              totals.push({ text: this.config.totals.specialSubTotalLabel, span: 2 }, { text: `${specialSubTotalPR}`, span: 1 }, { text: `${specialSubTotalMR}`, span: 1 });
            }
            totals.push({ text: this.config.totals.totalClaimsLabel, span: 2 }, { text: `${totalPR}`, span: 1 }, { text: `${totalMR}`, span: 1 });
            this.claimTotals = [
              { text: this.config.totals.totalClaimsLabel, span: 2 },
              { text: `${totalPR}`, span: 1 },
              { text: `${totalMR}`, span: 1 },
            ];
            if (index === 0) {
              return timer(250).pipe(mapTo(totals));
            } else {
              return of(totals);
            }
          } else {
            return of([]);
          }
        }),
      );
    }
  }

  checkHideTree(type: any) {
    if (this.isFront && type === SharePictureTypes.MANUSCRIPT) {
      this.iceWidgetSwitch.switchFace(!this.isFront);
    }
  }
}
