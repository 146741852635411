export class StringUtils {
  static emailRegExp = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

  static joinStrings(separator: string, ...fields: string[]): string {
    return fields.map(field => field.trim()).join(separator) || '';
  }

  static removeExtraSpaces(sentence: string): string {
    return sentence.toString().trim();
  }

  static removeAccents(term: string): string {
    return term.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  static isNumberLike(term: string): boolean {
    return !isNaN(Number(term));
  }

  static isContainsNumbers(term: string = ''): boolean {
    return /[0-9]/gi.test(term);
  }

  static isContainsLetters(term: string = ''): boolean {
    return /[A-Z]/gi.test(term);
  }

  static isId(term: string = ''): boolean {
    return /^[A-Z]+:[0-9]+$|^[0-9]+$/g.test(term);
  }

  static trimLeadingZerosFromArray(values: string[]): string[] {
    return (values && values.map(value => this.trimLeadingZerosFromString(value))) || [''];
  }

  static trimLeadingZerosFromString(value: string): string {
    return StringUtils.isNumberLike(value) ? Number(value).toString() : value;
  }

  static isEmail(value: string): boolean {
    return StringUtils.emailRegExp.test(value);
  }

  static padZeros(num, size) {
    let s = num + '';
    while (s.length < size) {
      s = '0' + s;
    }
    return s;
  }

  static compareFloatStrings(a, b) {
    return parseFloat(a) > parseFloat(b) ? 1 : -1;
  }

  static hash(txt: string) {
    let hash = 0;
    if (!txt.length) {
      return hash;
    }
    for (let i = 0; i < txt.length; i++) {
      // eslint-disable-next-line no-bitwise
      hash = (hash << 5) - hash + txt.charCodeAt(i);
      // eslint-disable-next-line no-bitwise
      hash = hash & hash;
    }
    return hash;
  }

  static getArrayFromStringList(field: string) {
    return field && field.split('<br>');
  }

  static visualizeAllSpaces(text: string) {
    return text?.split(' ').join('&nbsp;');
  }

  static replaceNbsp(text: string) {
    return text?.replace(/&nbsp;/g, ' ');
  }
}
