import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ExtendedWorkSelectorComponentModule } from '@ice';
import { ButtonsGroupModule } from '@ice/components/buttons-group/buttons-group.module';
import { CardWithDataTableModule } from '@ice/components/card-with-data-table/card-with-data-table.module';
import { CardWithExpansionListModule } from '@ice/components/card-with-expansion-list/card-with-expansion-list.module';
import { CardWithFormDropdownModule } from '@ice/components/card-with-form-dropdown/card-with-form-dropdown.module';
import { CardWithFormModule } from '@ice/components/card-with-form/card-with-form.module';
import { ExpansionListDataTableModule } from '@ice/components/expansion-list-data-table/expansion-list-data-table.module';
import { ExpansionListModule } from '@ice/components/expansion-list/expansion-list.module';
import { FormContainerModule } from '@ice/components/form-container/form-container.module';
import { IceMaterialModule } from '@ice/material.module';
import { AnalysisAndComparisonsComponent } from './analysis-and-comparisons.component';

@NgModule({
  declarations: [AnalysisAndComparisonsComponent],
  imports: [
    CommonModule,
    IceMaterialModule,
    FlexLayoutModule,
    CardWithFormModule,
    CardWithFormDropdownModule,
    ExpansionListModule,
    ExpansionListDataTableModule,
    FormContainerModule,
    CardWithExpansionListModule,
    CardWithDataTableModule,
    ButtonsGroupModule,
    ExtendedWorkSelectorComponentModule,
  ],
  exports: [AnalysisAndComparisonsComponent],
})
export class AnalysisAndComparisonsModule {}
