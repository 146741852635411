export enum OrganizationType {
  society = 'society',
  publisher = 'publisher',
  ice = 'ice',
  stage = 'stage',
  creator = 'creator',
}

export const SEARCH_TYPE_ORGANIZATION = 'organization';

export const ORGANIZATION_POLICIES = {
  [OrganizationType.publisher]: {
    admin: ['CUBE:admin', 'CUBE:restrictedRead', 'CUBE:restrictedWrite', 'ICE:publisherAdmin'],
    editor: ['CUBE:editor', 'CUBE:restrictedRead', 'CUBE:restrictedWrite', 'ICE:publisherEditor'],
    viewer: ['CUBE:viewer', 'CUBE:restrictedRead', 'CUBE:restrictedWrite', 'ICE:publisherViewer'],
    counterclaim: ['CUBE:counterclaim', 'CUBE:restrictedRead', 'CUBE:restrictedWrite', 'ICE:publisherCounterclaim'],
    'publisher-extended-viewer': ['CUBE:restrictedRead', 'CUBE:restrictedWrite', 'CUBE:publisherExtendedViewer'],
  },
  [OrganizationType.society]: {
    admin: ['CUBE:admin', 'CUBE:restrictedWrite', 'ICE:societyAdmin'],
    editor: ['CUBE:editor', 'CUBE:restrictedWrite', 'ICE:societyEditor'],
    viewer: ['CUBE:viewer', 'CUBE:restrictedWrite', 'ICE:societyViewer'],
    'restricted-viewer': ['CUBE:viewerRestricted', 'CUBE:restrictedWriteNoRepertoire', 'ICE:societyViewer'],
    'add-match': ['CUBE:addMatch'],
    'ip-merge': ['CUBE:ipMerge'],
  },
  [OrganizationType.ice]: {
    admin: ['CUBE:admin', 'CUBE:restrictedRead', 'CUBE:restrictedWrite', 'ICE:iceAdmin'],
    editor: ['CUBE:editor', 'CUBE:restrictedRead', 'CUBE:restrictedWrite', 'ICE:iceEditor'],
    viewer: ['CUBE:viewer', 'CUBE:restrictedRead', 'CUBE:restrictedWrite', 'ICE:iceViewer'],
    super: ['CUBE:super', 'CUBE:restrictedRead', 'CUBE:restrictedWrite', 'ICE:iceSuper'],
  },
};
