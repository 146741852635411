<mat-card>
  <mat-card-header class="mat-accent-bg card-expanded-header">
    <mat-card-title>{{ title }}</mat-card-title>
  </mat-card-header>
  <mat-card-content class="ice-p-12" [ngStyle]="{ height: cardHeight }">
    <ice-form
      [resetAvailable]="resetAvailable"
      [submitAvailable]="submitAvailable"
      [button1Available]="button1Available"
      [formBuilder]="formBuilder"
      [model]="model"
      [submitLabel]="submitLabel"
      [button1Label]="button1Label"
      [submitShortcutEnable]="submitShortcutEnable"
      (submit)="onSubmit($event)"
      (button1Clicked)="onButton1($event)"
    >
    </ice-form>
  </mat-card-content>
</mat-card>
