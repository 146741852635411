import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { isObservable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromRoot from 'store/root';

export class DialogInfo {
  constructor(private translate: TranslateService, private dialog: MatDialog, private store: Store<fromRoot.RootState>) {}

  openDialog(title, msg, actionOk, actionKo?, labelOk?, labelKo?, okButtonExtraClass?): MatDialogRef<DialogMultiLayoutComponent, any> {
    const responseButtons: any[] = [
      {
        text: of(labelOk || this.translate.instant('POPUP.OK')),
        class: `ok-button ${okButtonExtraClass}`,
        action: actionOk,
      },
    ];
    if (actionKo) {
      responseButtons.push({
        text: of(labelKo || this.translate.instant('POPUP.CANCEL')),
        action: actionKo,
      });
    }

    return this.dialog.open(DialogMultiLayoutComponent, {
      data: {
        className: 'dialog-wrapper-width-420-h',
        layouts: [
          {
            title: isObservable(title) ? title : of(title),
            layout: [
              {
                group: [
                  {
                    type: 'response-error',
                    className: 'dialog-wrapper-auto',
                    config: {
                      response: isObservable(msg)
                        ? msg.pipe(map(text => ({ text })))
                        : of({
                            text: msg,
                          }),
                      messageIcon: of('info'),
                      responseButtons: of(responseButtons),
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    });
  }

  openDialogResponseError(title, response, actionOk, actionKo, labelOk, labelKo, errors, errorButtons, loading, loadingText): MatDialogRef<DialogMultiLayoutComponent, any> {
    return this.dialog.open(DialogMultiLayoutComponent, {
      data: {
        className: 'dialogInfo',
        layouts: [
          {
            title: of(title),
            layout: [
              {
                group: [
                  {
                    type: 'response-error',
                    className: 'dialog-wrapper-auto',
                    config: {
                      response,
                      messageIcon: of('info'),
                      responseButtons: of([
                        {
                          text: of(labelOk || this.translate.instant('POPUP_COMMON.OK')),
                          class: 'ok-button',
                          action: actionOk,
                        },
                        {
                          text: of(labelKo || this.translate.instant('POPUP_COMMON.CANCEL')),
                          action: actionKo,
                        },
                      ]),
                      errors,
                      errorButtons,
                      loading,
                      loadingText,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    });
  }
}
