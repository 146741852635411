import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/search-form-builders';
import { TerritoryDataType } from 'config/constants/territories.constants';
import { FormConfig, SearchExpressions } from './form-config';

export class TerritoriesSearchExpressions extends SearchExpressions {
  alternativeNS = 'TISN';

  getSearchExpressionConfig(key: string, value: any, prefix: string, allUsers: any) {
    switch (key) {
      case TerritoryDataType.TISN:
        return `{"equals":{"attributes.tisn":"${value}"}}`;

      case TerritoryDataType.TISA:
        return `{"equals":{"attributes.codes.tisa":"${value}"}}`;

      case TerritoryDataType.TISA_EXT:
        return `{"equals":{"attributes.codes.tisaExt":"${value}"}}`;

      case TerritoryDataType.NAME:
        return `{"wildcard":{"attributes.codes.names.name":"*${value}*"}}`;

      case TerritoryDataType.OFFICIAL_NAME:
        return `{"wildcard":{"attributes.codes.names.officialName":"*${value}*"}}`;

      case TerritoryDataType.UNOFFICIAL_NAME:
        return `{"wildcard":{"attributes.codes.names.abbreviatedName":"*${value}*"}}`;
    }
    return `{"wildcard":{"${'attributes.' + key}":"*${value}*"}}`;
  }

  getExtraSearchExpression() {
    return '';
  }
}
export class SearchCopyrightTerritoriesForm implements FormConfig {
  constructor(private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  getForm(): FormlyFieldConfig[] {
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'tisn',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('TERRITORIES.TISN'), required: false },
          },
          {
            className: 'flex-1',
            key: 'tisa',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('TERRITORIES.TISA'), required: false },
          },
          {
            className: 'flex-1',
            key: 'tisaExt',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('TERRITORIES.TISA_EXT'), required: false },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'name',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('TERRITORIES.NAME'), required: false },
          },
          {
            className: 'flex-1',
            key: 'officialName',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('TERRITORIES.OFFICIAL_NAME'), required: false },
          },
          {
            className: 'flex-1',
            key: 'unofficialName',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('TERRITORIES.UNOFFICIAL_NAME'), required: false },
          },
        ],
      },
    ];
  }
}
