import { ButtonsGroupConfig } from '@ice/components/buttons-group/buttons-group';
import { CardBasic } from '@ice/components/card-basic/card-basic';
import { CardWithDataTable } from '@ice/components/card-with-data-table/card-with-data-table';
import { CardWithExpansionList } from '@ice/components/card-with-expansion-list/card-with-expansion-list';
import { CardWithFormly } from '@ice/components/card-with-formly/card-with-formly';
import { CardWithSharesRepertoire } from '@ice/components/card-with-shares-repertoire/card-with-shares-repertoire';
import { CardWithShares } from '@ice/components/card-with-shares/card-with-shares';
import type { Card } from '@ice/components/card/card';
import { DataTableTotals } from '@ice/components/data-table-totals/data-table-totals';
import { DataTable } from '@ice/components/data-table/data-table';
import { HtmlTemplate } from '@ice/components/html-template/html-template.model';
import { ProgressBar } from '@ice/components/progress-bar/progress-bar';
import { BasicButton, ResponseError } from '@ice/components/response-error/response-error';
import { Stepper } from '@ice/components/stepper/stepper';
import { TableConfig } from '@ice/components/table/table.model';
import { TreeChartConfig } from '@ice/components/work-tree-chart/tree-chart-models';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import { InputUploadFileModel } from '@ice/components/input-upload-file/input-upload-file.model';
import type { AnalysisAndComparisons } from '../analysis-and-comparisons/analysis-and-comparisons';

export interface IceDialogMultiLayout {
  loading?: Observable<boolean>;
  doPagination?: () => void;
  loadingButtons?: Observable<BasicButton[]>;
  loadingText?: Observable<string>;
  layouts: IceLayout[];
  className?: string;
}

export interface IceLayout {
  className?: string;
  title: Observable<string>;
  verticalFloatingButtons?: boolean;
  layout: IceGroupComponent[];
  actions?: any[];
}

export interface IceGroupComponent {
  onDestroy?: () => void;
  class?: string;
  group: IceComponent[];
}

export interface ButtonsGroupComponent {
  config: ButtonsGroupConfig;
  class?: string;
  emitEvent: (action) => void;
}

export type IceComponentType =
  | {
      type: 'formly';
      config: IceForm;
    }
  | {
      type: 'buttonsGroup';
      config: ButtonsGroupComponent;
    }
  | {
      type: 'table';
      config: Observable<TableConfig>;
    }
  | {
      type: 'dataTable';
      config: DataTable;
    }
  | {
      type: 'dataTableTotals';
      config: DataTableTotals;
    }
  | {
      type: 'stepper';
      config: Stepper;
    }
  | {
      type: 'card';
      config: Card;
    }
  | {
      type: 'cardWithForm';
      config: CardWithFormly;
    }
  | {
      type: 'cardWithExpansionList';
      config: CardWithExpansionList;
    }
  | {
      type: 'response-error';
      config: ResponseError;
    }
  | {
      type: 'progressBar';
      config: ProgressBar;
    }
  | {
      type: 'cardWithDataTable';
      config: CardWithDataTable;
    }
  | {
      type: 'cardWithShares';
      config: CardWithShares;
    }
  | {
      type: 'cardWithSharesRepertoire';
      config: CardWithSharesRepertoire;
    }
  | {
      type: 'treeView';
      config: Observable<TreeChartConfig>;
    }
  | {
      type: 'htmlTemplate';
      config: HtmlTemplate;
    }
  | {
      type: 'card-basic';
      config: CardBasic;
    }
  | {
      type: 'analysisAndComparisons';
      config: AnalysisAndComparisons;
    }
  | {
      type: 'input-upload-file';
      config: InputUploadFileModel;
    };

export type IceComponent = IceComponentType & {
  isHidden?: Observable<boolean>;
  flex?: number;
  fxFlex?: string;
};

export interface IceForm {
  resetAvailable?: boolean;
  submitAvailable?: boolean;
  submitEnabled?: boolean;
  button1Available?: boolean;
  button1Enabled?: boolean;
  formInline?: boolean;
  formBuilder?: Observable<FormlyFieldConfig[]>;
  model: Observable<any>;
  extraFormBuilder?: FormlyFieldConfig[];
  submitLabel?: string;
  button1Label?: string;
  extraActionAvailable?: boolean;
  extraActionColor?: string;
  extraActionLabel?: string;
  submitShortcutEnable?: boolean;
  className?: string;
  formClass?: string;
  submitClass?: string;
  submitButtonIcon?: string;
  button1ButtonIcon?: string;
  change?: (event) => void;
  submit?: (event) => void;
  onButton1?: (event) => void;
  setValidForm?: (event) => void;
  button1Clicked?: (event) => void;
  extraActionClick?: (event) => void;
  onReset?: (data: any) => void;
}

export enum ColorType {
  accent = 'accent',
  primary = 'primary',
  warn = 'warn',
  link = 'link',
  basic = 'basic',
  disabled = 'disabled',
}
