import { Injectable } from '@angular/core';
import { DetectedToken, HighlightTag } from '@ice/components/query-editor/highlight.component';
import { ParsedInput, QueryLanguageParser } from '@ice/components/query-editor/query-editor.component';
import type { TypeaheadState } from '@ice/components/query-editor/typeahead.model';
import { cloneDeep } from 'lodash';

export enum IceQueryLanguageTokenStates {
  EXPECT_STATEMENT,
  EXPECT_STATEMENT_OR_NEGATOR,
  FIELD_OR_NEGATOR,
  FIELD,
  NEGATOR,
  EXPECT_COMPARISON,
  COMPARISON,
  EXPECT_VALUE,
  VALUE,
  VALUE_DOUBLE_QUOTED_START,
  VALUE_DOUBLE_QUOTED,
  VALUE_DOUBLE_QUOTED_END,
  EXPECT_OPERATOR,
  OPERATOR,
}

@Injectable()
export class ExpertSearchParseService implements QueryLanguageParser<IceQueryLanguageTokenStates> {
  public tokenTypeMap: object;
  public AttributesMap: object;
  public customKeysMap: object;

  constructor(attributesEnumMap: object, AttributesMap: object, customKeysMap?: object) {
    this.AttributesMap = AttributesMap;
    this.customKeysMap = customKeysMap;

    const compareOperatorsMap = Object.entries(AttributesMap).reduce((temp, [key, resolver]) => {
      if (customKeysMap && customKeysMap[key] && !customKeysMap[key]?.includes('concat-fields')) {
        return { ...temp, [key]: customKeysMap[key] };
      }
      if (typeof resolver === 'function') {
        return { ...temp, [key]: ['==', '=', '=*'] };
      } else {
        return { ...temp, [key]: ['==', '=', '=*', '<=', '<', '>=', '>'] };
      }
    }, {});
    this.tokenTypeMap = {
      [IceQueryLanguageTokenStates.EXPECT_STATEMENT]: {
        default: Object.keys(AttributesMap),
      },
      [IceQueryLanguageTokenStates.EXPECT_STATEMENT_OR_NEGATOR]: {
        default: Object.keys(AttributesMap),
      },
      [IceQueryLanguageTokenStates.FIELD]: {
        default: Object.keys(AttributesMap),
      },
      [IceQueryLanguageTokenStates.NEGATOR]: {
        default: ['not'],
      },
      [IceQueryLanguageTokenStates.FIELD_OR_NEGATOR]: {
        default: ['not', ...Object.keys(AttributesMap)],
      },
      [IceQueryLanguageTokenStates.EXPECT_COMPARISON]: {
        ...compareOperatorsMap,
        default: ['==', '=', '=*'],
      },
      [IceQueryLanguageTokenStates.COMPARISON]: {
        ...compareOperatorsMap,
        default: ['==', '=', '=*'],
      },
      [IceQueryLanguageTokenStates.OPERATOR]: {
        default: ['and', 'or', 'with'],
      },
      [IceQueryLanguageTokenStates.EXPECT_OPERATOR]: {
        default: ['and', 'or', 'with'],
      },
      [IceQueryLanguageTokenStates.VALUE_DOUBLE_QUOTED_START]: {
        default: [],
      },
      [IceQueryLanguageTokenStates.VALUE_DOUBLE_QUOTED_END]: {
        default: [],
      },
      [IceQueryLanguageTokenStates.EXPECT_VALUE]: {
        ...attributesEnumMap,
        default: [],
      },
      [IceQueryLanguageTokenStates.VALUE]: {
        ...attributesEnumMap,
        default: [],
      },
      [IceQueryLanguageTokenStates.VALUE_DOUBLE_QUOTED]: {
        ...attributesEnumMap,
        default: [],
      },
    };
  }

  findTokenType(editorState: TypeaheadState<IceQueryLanguageTokenStates>, searchText: string) {
    return editorState.tokenType.filter(item => item.toLowerCase().startsWith(searchText.toLowerCase()));
  }

  calculateTypeaheadState(analyzedInput: ParsedInput<IceQueryLanguageTokenStates>, selectionStart: number): TypeaheadState<IceQueryLanguageTokenStates> {
    let tokenType = [];
    const cursorPosition = selectionStart;
    const tokenTypeTypeCand: IceQueryLanguageTokenStates = analyzedInput.typeMask[cursorPosition];
    const prevCharTokenTypeType: IceQueryLanguageTokenStates = cursorPosition > 0 ? analyzedInput.typeMask[cursorPosition - 1] : null;
    let tokenTypeType: IceQueryLanguageTokenStates;
    if (tokenTypeTypeCand == null) {
      if (prevCharTokenTypeType != null) {
        if (prevCharTokenTypeType === IceQueryLanguageTokenStates.FIELD_OR_NEGATOR) {
          tokenTypeType = IceQueryLanguageTokenStates.EXPECT_COMPARISON;
        } else if (prevCharTokenTypeType === IceQueryLanguageTokenStates.COMPARISON) {
          tokenTypeType = IceQueryLanguageTokenStates.VALUE;
        } else if ([IceQueryLanguageTokenStates.VALUE, IceQueryLanguageTokenStates.VALUE_DOUBLE_QUOTED_END].indexOf(prevCharTokenTypeType) !== -1) {
          tokenTypeType = IceQueryLanguageTokenStates.EXPECT_OPERATOR;
        } else if ([IceQueryLanguageTokenStates.OPERATOR].indexOf(prevCharTokenTypeType) !== -1) {
          tokenTypeType = IceQueryLanguageTokenStates.FIELD_OR_NEGATOR;
        } else {
          tokenTypeType = prevCharTokenTypeType;
        }
      } else {
        tokenTypeType = IceQueryLanguageTokenStates.FIELD_OR_NEGATOR;
      }
    } else {
      tokenTypeType = tokenTypeTypeCand;
    }
    const tokenTypeForField = this.tokenTypeMap[tokenTypeType];

    if (tokenTypeForField) {
      const tokenTypeForFieldKeysCount = Object.keys(tokenTypeForField).length;
      const noMatch = tokenTypeForFieldKeysCount == null;
      if (!noMatch) {
        const onlyDefault = tokenTypeForFieldKeysCount === 1;
        if (onlyDefault) {
          tokenType = this.tokenTypeMap[tokenTypeType].default;
        } else {
          let start: number;
          let end: number;
          for (let i = cursorPosition; i >= 0; i--) {
            if (!end && analyzedInput.typeMask[i] === IceQueryLanguageTokenStates.FIELD) {
              end = i + 1;
            }
            if (!start && end) {
              if (analyzedInput.typeMask[i] !== IceQueryLanguageTokenStates.FIELD) {
                start = i + 1;
              } else if (i === 0) {
                start = 0;
              }
            }
          }
          const fieldName = start != null && end != null ? analyzedInput.text.substr(start, end - start) : 'default';
          tokenType = this.tokenTypeMap[tokenTypeType][fieldName] || [];
        }
      }
    }
    let triggerCharacterPosition: number;
    for (
      triggerCharacterPosition = cursorPosition;
      triggerCharacterPosition > 0 && analyzedInput.typeMask[triggerCharacterPosition] === analyzedInput.typeMask[triggerCharacterPosition - 1];
      triggerCharacterPosition--
    ) {}
    let triggerEndPosition: number;
    for (
      triggerEndPosition = triggerCharacterPosition + 1;
      triggerEndPosition < analyzedInput.typeMask.length && analyzedInput.typeMask[triggerEndPosition] === analyzedInput.typeMask[triggerEndPosition - 1];
      triggerEndPosition++
    ) {}
    return {
      ...analyzedInput,
      triggerCharacterPosition,
      triggerEndPosition,
      selectionStart,
      tokenTypeType,
      tokenType: triggerCharacterPosition === selectionStart ? tokenType : [],
    };
  }

  processNextCharacter(tokenized: any, char: string) {
    const textReverse = [...tokenized.text.split('')].slice().reverse();
    const typeMaskRevers = [...tokenized.typeMask].reverse();
    const start = typeMaskRevers.findIndex(t => [IceQueryLanguageTokenStates.FIELD, IceQueryLanguageTokenStates.FIELD_OR_NEGATOR].indexOf(t) !== -1);
    const end = typeMaskRevers.slice(start + 1).findIndex(t => [IceQueryLanguageTokenStates.FIELD, IceQueryLanguageTokenStates.FIELD_OR_NEGATOR].indexOf(t) === -1);
    const potentialFieldName = [...textReverse.slice(start, end === -1 ? undefined : start + end + 2)].reverse().join('').trim();
    const currentFieldName = Object.entries(this.tokenTypeMap[IceQueryLanguageTokenStates.COMPARISON]).some(([key]) => key === potentialFieldName) ? potentialFieldName : 'default';

    let typeMask = tokenized.typeMask;
    let levelMask = tokenized.levelMask;
    const prevTokenType =
      (tokenized.typeMask.length > 0 && tokenized.typeMask[tokenized.typeMask.length - 1]) ||
      IceQueryLanguageTokenStates.EXPECT_STATEMENT_OR_NEGATOR ||
      IceQueryLanguageTokenStates.EXPECT_STATEMENT;
    let nextTokenType = prevTokenType;
    const prevLevel = (tokenized.levelMask.length > 0 && tokenized.levelMask[tokenized.levelMask.length - 1]) || 0;
    let j;
    for (j = tokenized.text.length - 1; j >= 0 && tokenized.typeMask[j] === tokenized.typeMask[tokenized.text.length - 1]; j--) {}
    const currentToken = tokenized.text.substr(j + 1, tokenized.text.length - 1 - j);
    let level = prevLevel;
    if ((prevTokenType === IceQueryLanguageTokenStates.EXPECT_STATEMENT || prevTokenType === IceQueryLanguageTokenStates.EXPECT_STATEMENT_OR_NEGATOR) && char === '(') {
      level++;
    } else if ((prevTokenType === IceQueryLanguageTokenStates.EXPECT_STATEMENT || prevTokenType === IceQueryLanguageTokenStates.EXPECT_STATEMENT_OR_NEGATOR) && char !== ' ') {
      nextTokenType = IceQueryLanguageTokenStates.FIELD_OR_NEGATOR;
    } else if (
      (prevTokenType === IceQueryLanguageTokenStates.FIELD ||
        prevTokenType === IceQueryLanguageTokenStates.NEGATOR ||
        prevTokenType === IceQueryLanguageTokenStates.FIELD_OR_NEGATOR) &&
      char === ' '
    ) {
      if (currentToken.toLowerCase() === 'not') {
        typeMask.fill(IceQueryLanguageTokenStates.NEGATOR, typeMask.length - currentToken.length);
        nextTokenType = IceQueryLanguageTokenStates.EXPECT_STATEMENT;
      } else {
        typeMask.fill(IceQueryLanguageTokenStates.FIELD, typeMask.length - currentToken.length);
        nextTokenType = IceQueryLanguageTokenStates.EXPECT_COMPARISON;
      }
    } else if (prevTokenType === IceQueryLanguageTokenStates.EXPECT_COMPARISON && char !== ' ') {
      nextTokenType = IceQueryLanguageTokenStates.COMPARISON;
    } else if (
      (prevTokenType === IceQueryLanguageTokenStates.FIELD ||
        prevTokenType === IceQueryLanguageTokenStates.NEGATOR ||
        prevTokenType === IceQueryLanguageTokenStates.FIELD_OR_NEGATOR) &&
      this.tokenTypeMap[IceQueryLanguageTokenStates.COMPARISON][currentFieldName].find(compFuncName => compFuncName[0] === char) != null
    ) {
      if (currentToken.toLowerCase() === 'not') {
        typeMask.fill(IceQueryLanguageTokenStates.NEGATOR, typeMask.length - currentToken.length);
        nextTokenType = IceQueryLanguageTokenStates.EXPECT_STATEMENT;
      } else {
        typeMask.fill(IceQueryLanguageTokenStates.FIELD, typeMask.length - currentToken.length);
        nextTokenType = IceQueryLanguageTokenStates.COMPARISON;
      }
    } else if (prevTokenType === IceQueryLanguageTokenStates.COMPARISON && char === ' ') {
      nextTokenType = IceQueryLanguageTokenStates.EXPECT_VALUE;
    } else if (prevTokenType === IceQueryLanguageTokenStates.COMPARISON && char === '"') {
      nextTokenType = IceQueryLanguageTokenStates.VALUE_DOUBLE_QUOTED_START;
    } else if (prevTokenType === IceQueryLanguageTokenStates.VALUE_DOUBLE_QUOTED_START) {
      nextTokenType = IceQueryLanguageTokenStates.VALUE_DOUBLE_QUOTED;
    } else if (
      prevTokenType === IceQueryLanguageTokenStates.COMPARISON &&
      this.tokenTypeMap[IceQueryLanguageTokenStates.COMPARISON][currentFieldName].find(compFuncName => compFuncName === currentToken) != null &&
      this.tokenTypeMap[IceQueryLanguageTokenStates.COMPARISON][currentFieldName].find(compFuncName => compFuncName === currentToken + char) == null
    ) {
      nextTokenType = IceQueryLanguageTokenStates.VALUE;
    } else if (prevTokenType === IceQueryLanguageTokenStates.EXPECT_VALUE && char !== '"' && char !== ' ') {
      nextTokenType = IceQueryLanguageTokenStates.VALUE;
    } else if (prevTokenType === IceQueryLanguageTokenStates.EXPECT_VALUE && char === '"') {
      nextTokenType = IceQueryLanguageTokenStates.VALUE_DOUBLE_QUOTED_START;
    } else if (prevTokenType === IceQueryLanguageTokenStates.VALUE_DOUBLE_QUOTED && char === '"' && currentToken && currentToken.length > 0 && currentToken[0] !== '\\') {
      nextTokenType = IceQueryLanguageTokenStates.VALUE_DOUBLE_QUOTED_END;
    } else if (prevTokenType === IceQueryLanguageTokenStates.VALUE_DOUBLE_QUOTED_END) {
      nextTokenType = IceQueryLanguageTokenStates.EXPECT_OPERATOR;
    } else if (prevTokenType === IceQueryLanguageTokenStates.VALUE && [' ', ')'].indexOf(char) !== -1) {
      nextTokenType = IceQueryLanguageTokenStates.EXPECT_OPERATOR;
      if (char === ')') {
        level--;
      }
    } else if ([IceQueryLanguageTokenStates.VALUE, IceQueryLanguageTokenStates.EXPECT_OPERATOR].indexOf(prevTokenType) !== -1 && char === ')') {
      level--;
    } else if (prevTokenType === IceQueryLanguageTokenStates.EXPECT_OPERATOR && char !== ' ') {
      nextTokenType = IceQueryLanguageTokenStates.OPERATOR;
    } else if (prevTokenType === IceQueryLanguageTokenStates.OPERATOR && char === ' ') {
      nextTokenType = IceQueryLanguageTokenStates.EXPECT_STATEMENT_OR_NEGATOR;
    }
    typeMask = [...typeMask, nextTokenType];
    levelMask = [...levelMask, level];
    return {
      typeMask,
      levelMask,
      text: `${tokenized.text}${char}`,
    };
  }

  tokenize(key: string, text = '', selection: number): ParsedInput<IceQueryLanguageTokenStates> {
    const tags: HighlightTag[] = [];
    const tokens: DetectedToken<IceQueryLanguageTokenStates>[] = [];
    let tokenized = {
      typeMask: [],
      levelMask: [],
      text: '',
    };
    let prevTagType = null;
    let textWithNewChar;
    if (key.length === 1) {
      textWithNewChar = text.substr(0, selection) + key + text.substr(selection);
    } else if (text && key === 'Backspace') {
      textWithNewChar = text.substr(0, selection - 1) + text.substr(selection);
    } else if (text && key === 'Delete') {
      textWithNewChar = text.substr(0, selection) + text.substr(selection + 1);
    } else {
      textWithNewChar = text;
    }
    if (textWithNewChar) {
      let lastFieldName;
      tokenized.typeMask[tokenized.typeMask.length - 1] = IceQueryLanguageTokenStates.EXPECT_STATEMENT_OR_NEGATOR;
      tokenized.levelMask[tokenized.levelMask.length - 1] = 0;
      for (const char of textWithNewChar) {
        tokenized = this.processNextCharacter(tokenized, char);
      }
      for (let i = 0; i < tokenized.typeMask.length; i++) {
        const tagType = tokenized.typeMask[i];
        const levelMask = tokenized.levelMask[i];
        if (
          prevTagType !== tagType &&
          [
            IceQueryLanguageTokenStates.FIELD,
            IceQueryLanguageTokenStates.NEGATOR,
            IceQueryLanguageTokenStates.COMPARISON,
            IceQueryLanguageTokenStates.OPERATOR,
            IceQueryLanguageTokenStates.VALUE,
            IceQueryLanguageTokenStates.VALUE_DOUBLE_QUOTED,
          ].indexOf(tagType) !== -1
        ) {
          let cssClass = '';
          if (tagType === IceQueryLanguageTokenStates.FIELD) {
            cssClass = 'bg-tag-field';
          } else if (tagType === IceQueryLanguageTokenStates.COMPARISON) {
            cssClass = 'bg-tag-comparison';
          } else if (tagType === IceQueryLanguageTokenStates.NEGATOR) {
            cssClass = 'bg-tag-operator';
          } else if (tagType === IceQueryLanguageTokenStates.OPERATOR) {
            cssClass = 'bg-tag-operator';
          } else if (tagType === IceQueryLanguageTokenStates.VALUE) {
            cssClass = 'bg-tag-value';
          } else if (tagType === IceQueryLanguageTokenStates.VALUE_DOUBLE_QUOTED) {
            cssClass = 'bg-tag-value-quoted';
          }
          let j;
          for (j = i; j < tokenized.typeMask.length && tokenized.typeMask[j] === tagType; j++) {}
          const currentToken = textWithNewChar.substr(i, j - i);
          if (tagType === IceQueryLanguageTokenStates.FIELD) {
            lastFieldName = currentToken;
          }
          let valid = Object.keys(this.tokenTypeMap[tagType]).length === 1 && this.tokenTypeMap[tagType].default.length === 0;

          // check if reserved term exists in type map
          if (!valid) {
            if (
              [
                IceQueryLanguageTokenStates.VALUE,
                IceQueryLanguageTokenStates.VALUE_DOUBLE_QUOTED,
                IceQueryLanguageTokenStates.COMPARISON,
                IceQueryLanguageTokenStates.VALUE,
                IceQueryLanguageTokenStates.VALUE_DOUBLE_QUOTED,
              ].indexOf(tagType) !== -1 &&
              lastFieldName
            ) {
              if (
                (this.tokenTypeMap[tagType][lastFieldName] == null &&
                  (this.tokenTypeMap[tagType].default.length === 0 || this.tokenTypeMap[tagType].default.indexOf(currentToken) !== -1)) ||
                (this.tokenTypeMap[tagType][lastFieldName] && this.tokenTypeMap[tagType][lastFieldName].indexOf(currentToken) !== -1)
              ) {
                valid = true;
              }
            } else {
              if (
                // operators are case in-sensitive
                (tagType === IceQueryLanguageTokenStates.OPERATOR && this.tokenTypeMap[tagType].default.find(item => item.toLowerCase() === currentToken.toLowerCase()) != null) ||
                (tagType === IceQueryLanguageTokenStates.NEGATOR && currentToken.toLowerCase() === 'not') ||
                this.tokenTypeMap[tagType].default.indexOf(currentToken) !== -1
              ) {
                valid = true;
              }
            }
          }
          // only highlight valid terms
          if (
            (this.tokenTypeMap[tagType][lastFieldName] == null && this.tokenTypeMap[tagType].default.length > 0) ||
            (this.tokenTypeMap[tagType][lastFieldName] != null && this.tokenTypeMap[tagType][lastFieldName].length > 0)
          ) {
            if (valid) {
              // only highlight if min 1 options to choose from
              tags.push({ indices: { start: i, end: j }, cssClass, currentToken });
            } else {
              tags.push({ indices: { start: i, end: j }, cssClass: 'bg-tag-error', currentToken });
            }
          }
          tokens.push({ tokenType: tagType, token: currentToken, level: levelMask, start: i });
        }
        prevTagType = tagType;
      }
    }

    return {
      key,
      text: textWithNewChar,
      typeMask: tokenized.typeMask,
      levelMask: tokenized.levelMask,
      tags,
      tokens,
    };
  }

  async parseQuery(tokens: DetectedToken<IceQueryLanguageTokenStates>[], deep: boolean = false) {
    const createLevelTree = (tokenSlices, currentRootLevel = 0) => {
      const sliceLevel = slice => slice.length > 0 && slice[0].level;
      const tree = [];
      let currentSubTree = null;
      for (const slice of tokenSlices) {
        const isRootLevel = sliceLevel(slice) === currentRootLevel;
        if (isRootLevel) {
          if (currentSubTree) {
            const subTreeParsed = createLevelTree(currentSubTree, currentRootLevel + 1);
            tree.push(subTreeParsed);
            currentSubTree = null;
          }
          slice.forEach(token => tree.push(token));
        } else {
          if (!currentSubTree) {
            currentSubTree = [];
          }
          currentSubTree.push(slice);
        }
      }
      if (currentSubTree) {
        const subTreeParsed = createLevelTree(currentSubTree, currentRootLevel + 1);
        tree.push(subTreeParsed);
        currentSubTree = null;
      }
      return tree;
    };

    const parseOperatorOrNegator = (levelTree, opType, opName) => {
      if (levelTree.tokenType) {
        return levelTree;
      } else if (!Array.isArray(levelTree)) {
        return Object.entries(levelTree).reduce((temp, [field, value]) => {
          const parsedValue = parseOperatorOrNegator(value, opType, opName);
          const parsedValueClean = parsedValue.length === 1 ? parsedValue[0] : parsedValue;
          return { ...temp, [field]: parsedValueClean };
        }, {});
      } else {
        const hasOperator = levelTree.some(item => item.tokenType === opType && item.token.toLowerCase() === opName.toLowerCase());
        const splitByOperator = hasOperator
          ? levelTree.reduce((temp, item) => {
              const lastIndex = Math.max(0, temp.length - 1);
              const prevSlice = (temp.length > 0 && temp[temp.length - 1]) || [];
              if (!item.token || item.token.toLowerCase() !== opName.toLowerCase()) {
                return [...temp.slice(0, lastIndex), [...prevSlice, item]];
              }
              return [...temp, []];
            }, [])
          : levelTree;
        const paresItems = splitByOperator.map(subTree => {
          const collapsedNode = subTree.length === 1 ? subTree[0] : subTree;
          return parseOperatorOrNegator(collapsedNode, opType, opName);
        });
        const paresItemsCollpased = paresItems.length === 1 ? paresItems[0] : paresItems;
        return hasOperator ? { [opName]: paresItemsCollpased } : paresItemsCollpased;
      }
    };

    const parseStatements = async levelTree => {
      if (levelTree.tokenType) {
        return levelTree;
      } else if (!Array.isArray(levelTree)) {
        const resolvedValues = await Promise.all(Object.values(levelTree).map(async value => await parseStatements(value)));
        return Object.keys(levelTree).reduce((temp, field, index) => {
          const parsedValue = resolvedValues[index];
          const parsedValueClean = parsedValue.length === 1 ? parsedValue[0] : parsedValue;
          return { ...temp, [field]: parsedValueClean };
        }, {});
      } else {
        const isExpression = !levelTree.some(token => !token.tokenType);
        if (isExpression) {
          if (levelTree.length === 3) {
            const field = levelTree.find(({ tokenType }) => tokenType === IceQueryLanguageTokenStates.FIELD);
            const comparator = levelTree.find(({ tokenType }) => tokenType === IceQueryLanguageTokenStates.COMPARISON);
            const value = levelTree.find(({ tokenType }) => [IceQueryLanguageTokenStates.VALUE, IceQueryLanguageTokenStates.VALUE_DOUBLE_QUOTED].indexOf(tokenType) !== -1);
            if (!Object.keys(this.AttributesMap).find(item => item === field.token)) {
              throw new SyntaxError(`Unknown field "${field.token}"`);
            }
            // parse booleans (user can still skip this auto-parsing by using quotes since token type becomes VALUE_DOUBLE_QUOTED then)
            const resolvedValue = value.tokenType === IceQueryLanguageTokenStates.VALUE && ['true', 'false'].indexOf(value.token) !== -1 ? JSON.parse(value.token) : value.token;

            if (typeof this.AttributesMap[field.token] === 'function') {
              // field does not map to another field but to an expression
              if (comparator.token === '=') {
                return await this.AttributesMap[field.token]('wildcard', `*${resolvedValue}*`, deep);
              } else if (comparator.token === '==') {
                return await this.AttributesMap[field.token]('equals', resolvedValue, deep);
              } else if (comparator.token === '=*') {
                return await this.AttributesMap[field.token]('wildcard', resolvedValue, deep);
              }
              throw new SyntaxError(`Operator ${comparator.token} is not supported for ${field.token}.`);
            } else if (comparator.token === '=') {
              if (typeof resolvedValue === 'string') {
                return {
                  wildcard: { [this.AttributesMap[field.token]]: `*${resolvedValue}*` },
                };
              } else {
                // for better ux: resolve wildcard boolean searches to exact boolean searches
                return {
                  equals: { [this.AttributesMap[field.token]]: resolvedValue },
                };
              }
            } else if (comparator.token === '==') {
              if (field.token === 'notes') {
                const noteParam = { exists: { field: this.AttributesMap[field.token] } };
                return value.token === 'true' ? noteParam : { not: noteParam };
              } else {
                return {
                  equals: { [this.AttributesMap[field.token]]: resolvedValue },
                };
              }
            } else if (comparator.token === '=*') {
              return {
                wildcard: { [this.AttributesMap[field.token]]: resolvedValue },
              };
            } else if (comparator.token === '>') {
              return {
                range: { [this.AttributesMap[field.token]]: { gt: resolvedValue } },
              };
            } else if (comparator.token === '>=') {
              return {
                range: { [this.AttributesMap[field.token]]: { gte: resolvedValue } },
              };
            } else if (comparator.token === '<') {
              return {
                range: { [this.AttributesMap[field.token]]: { lt: resolvedValue } },
              };
            } else if (comparator.token === '<=') {
              return {
                range: { [this.AttributesMap[field.token]]: { lte: resolvedValue } },
              };
            } else {
              throw new SyntaxError(`Unknown comparator "${comparator.token}" for ${field.token} at position ${levelTree[0].start}`);
            }
          } else if (levelTree.length > 0) {
            const fields = cloneDeep(levelTree);
            const doConcatFields = fields.some(item => item?.token.toLowerCase() === 'with');
            if (doConcatFields) {
              const relatedFields = fields.reduce((temp, item) => {
                const lastIndex = Math.max(0, temp.length - 1);
                const prevSlice = (temp.length > 0 && temp[temp.length - 1]) || [];
                if (item.token?.toLowerCase() !== 'with') {
                  return [...temp.slice(0, lastIndex), [...prevSlice, item]];
                }
                return [...temp, []];
              }, []);
              const concatFieldIndex = relatedFields?.findIndex(item => item?.findIndex(node => this.customKeysMap[node?.token]?.includes('concat-fields')) > -1);
              if (concatFieldIndex > -1) {
                const concatField = relatedFields.splice(concatFieldIndex, 1)[0];
                const concatFieldToken = concatField.find(({ tokenType }) => tokenType === IceQueryLanguageTokenStates.FIELD)?.token;
                const concatFieldValue = concatField.find(
                  ({ tokenType }) => [IceQueryLanguageTokenStates.VALUE, IceQueryLanguageTokenStates.VALUE_DOUBLE_QUOTED].indexOf(tokenType) !== -1,
                )?.token;
                if (concatFieldValue) {
                  if (typeof this.AttributesMap[concatFieldToken] === 'function') {
                    const concatFieldComparatorToken = concatField.find(({ tokenType }) => tokenType === IceQueryLanguageTokenStates.COMPARISON)?.token;
                    if (['=='].includes(concatFieldComparatorToken)) {
                      return await this.AttributesMap[concatFieldToken]('equals', concatFieldValue, deep, relatedFields);
                    }
                    throw new SyntaxError(`Operator ${concatFieldComparatorToken} is not supported for ${concatFieldToken}.`);
                  }
                  throw new SyntaxError(`Unknown "${concatFieldToken}" in the statement.`);
                }
                throw new SyntaxError(`Missing value for "${concatFieldToken}" in the statement.`);
              }
              throw new SyntaxError(`Unknown fields in the statement.`);
            }

            const comparator = fields.find(({ tokenType }) => tokenType === IceQueryLanguageTokenStates.COMPARISON);
            if (comparator) {
              throw new SyntaxError(`Missing value after "${comparator.token}". Expected value for ${levelTree[0].token}`);
            }
            throw new SyntaxError(`Missing operator after ${levelTree[0].token}.`);
          }
        } else {
          return await Promise.all(
            levelTree.map(async (item, index) => {
              const parsed = await parseStatements(item);
              return parsed;
            }),
          );
        }
      }
    };

    const levelSlices = tokens.reduce((temp, token) => {
      const lastIndex = Math.max(0, temp.length - 1);
      const prevSlice = (temp.length > 0 && temp[temp.length - 1]) || [];
      const prevToken = (prevSlice && prevSlice.length > 0 && prevSlice[prevSlice.length - 1]) || null;
      const prevLevel = (prevToken && prevToken.level) || 0;
      return prevLevel !== token.level ? [...temp, [token]] : [...temp.slice(0, lastIndex), [...prevSlice, token]];
    }, []);
    const lTree = createLevelTree(levelSlices);
    const orParse = parseOperatorOrNegator(lTree, IceQueryLanguageTokenStates.OPERATOR, 'or');
    const andParse = parseOperatorOrNegator(orParse, IceQueryLanguageTokenStates.OPERATOR, 'and');
    const notParse = parseOperatorOrNegator(andParse, IceQueryLanguageTokenStates.NEGATOR, 'not');
    return await parseStatements(notParse);
  }

  getChoiceLabel(choice: string) {
    return `${choice}`;
  }

  async parseSearchQuery(text: string, deep: boolean = false) {
    const expression = await this.parseQuery(this.tokenize('', text || '', (text && text.length - 1) || 0).tokens, deep);
    return {
      expression,
    };
  }
}
