import { createSelector } from '@ngrx/store';
import { NEW_IP_ASSIGNOR } from 'config/constants/ips.constants';
import { clone, isEqual } from 'lodash';
import * as fromFeature from 'store/new-section-item/reducers';
import * as fromNewSectionItem from 'store/new-section-item/reducers/new-section-item.reducer';
import * as fromAuth from 'store/root/selectors/auth/auth.selectors';
import * as fromRouterSelectors from 'store/root/selectors/router/router.selectors';

export const getNewSectionItemFields = createSelector(fromFeature.getNewItem, fromNewSectionItem.getNewSectionFieldsState);

export const getNewSectionState = createSelector(fromFeature.getNewItem, state => state);

export const getNewSectionValidOrChangedForm = createSelector(fromFeature.getNewItem, fromNewSectionItem.getNewSectionValidOrChangedFormState, state => {
  const { fields, initialFields, isValid, isCloning, isChanged } = state;
  if (isCloning) {
    return !isChanged || (isChanged && isValid);
  }
  if (isValid && initialFields) {
    // Needs refactor to find a better solution to activate button in users edit
    const organizationId = fields && fields['organizationId'];
    if (organizationId && organizationId.value && organizationId.label) {
      const formatFields = { ...fields, organizationId: organizationId.value };
      return !isEqual(formatFields, initialFields);
    }
    return !isEqual(fields, initialFields);
  }
  return isValid;
});

// To be refactored, Use instead getUserDetail, getFields, getSection in effects
export const getNewSectionItemFieldsWithSection = createSelector(
  getNewSectionItemFields,
  fromRouterSelectors.getRouterSection,
  fromAuth.getUserDetail,
  (fields, section, userDetail) => ({ fields, section, userDetail }),
);

export const getNewSectionSearch = createSelector(fromFeature.getNewItem, fromNewSectionItem.getNewSectionSearch);

export const getNewSectionInitialFields = createSelector(fromFeature.getNewItem, fromNewSectionItem.getNewSectionInitialFields);

export const getNewSectionSearchResult = createSelector(fromFeature.getNewItem, fromNewSectionItem.getNewSectionSearchResult);

export const getNewSectionLoading = createSelector(fromFeature.getNewItem, fromNewSectionItem.getNewSectionLoading);

export const getNewSectionResponse = createSelector(fromFeature.getNewItem, fromNewSectionItem.getNewSectionResponse);

export const getNewSectionIsLastActionClose = createSelector(fromFeature.getNewItem, fromNewSectionItem.getNewSectionIsLastActionClose);

export const getNewSectionIsPopupOkButtonMarked = createSelector(fromFeature.getNewItem, fromNewSectionItem.getNewSectionIsPopupOkButtonMarked);

export const getNewSectionNewIp = createSelector(fromFeature.getNewItem, fromNewSectionItem.getNewSectionNewIp);

export const getNewSectionNewIpFields = createSelector(getNewSectionNewIp, newIp => {
  if (newIp) {
    const { current, assignorFields, assigneeFields } = newIp;
    if (assignorFields && assigneeFields) {
      return current === NEW_IP_ASSIGNOR ? clone(assignorFields) : clone(assigneeFields);
    }
  }
  return {};
});

export const getNewSectionNewIpIsLoading = createSelector(getNewSectionNewIp, newIp => newIp.isLoading);

export const getNewSectionNewIpError = createSelector(getNewSectionNewIp, newIp => (newIp && newIp.error ? { text: newIp.error } : null));

export const getNewSectionSelectedIps = createSelector(fromFeature.getNewItem, newItem => newItem.counterClaimIps);

export const getNewSectionCounterClaimUsageTypes = createSelector(getNewSectionItemFields, fields => fields && fields['usageTypes']);

export const getNewSectionCounterClaimType = createSelector(getNewSectionItemFields, fields => fields && fields['type']);

export const getCounterClaimDialogSelectedIps = createSelector(fromFeature.getNewItem, newItem => newItem.dialogSelectedcounterClaimIps);

export const getClaimantGroups = createSelector(fromFeature.getNewItem, newItem => newItem.claimantGroups);

export const getIsCloning = createSelector(fromFeature.getNewItem, newItem => newItem.isCloning);

export const getNewSectionDocuments = createSelector(getNewSectionItemFields, fields => fields && fields['documents']);

export const getNewSectionSelectedParties = createSelector(getNewSectionItemFields, fields => fields && fields['selectedParties']);
