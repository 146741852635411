import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormContainerModule } from '@ice/components/form-container/form-container.module';
import { IceMaterialModule } from '@ice/material.module';
import { CardWithFormComponent } from './card-with-form.component';

@NgModule({
  imports: [CommonModule, IceMaterialModule, FlexLayoutModule, FormContainerModule],
  declarations: [CardWithFormComponent],
  exports: [CardWithFormComponent],
})
export class CardWithFormModule {}
