import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { FuseWidgetModule } from '@fuse/components';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { IceMaterialModule } from '@ice/material.module';
import { IcePipesModule } from '@ice/pipes/ice.pipes.module';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { DatepickerTypeComponent } from '..';
import { DataTableModule } from '../data-table/data-table.module';
import { DatepickerTypeModule } from '../datepicker-type/datepicker-type.module';
import { FormContainerModule } from '../form-container/form-container.module';
import { WidgetSwitchModule } from '../widget-switch/widget-switch.module';
import { TreeChartModule } from '../work-tree-chart/tree-chart.module';
import { CardWithSharesRepertoireComponent } from './card-with-shares-repertoire.component';

@NgModule({
  declarations: [CardWithSharesRepertoireComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FuseSharedModule,
    FlexLayoutModule,
    FuseWidgetModule,
    TreeChartModule,
    IceMaterialModule,
    DatepickerTypeModule,
    ReactiveFormsModule,
    FormlyMaterialModule,
    NgxJsonViewerModule,
    FormlyModule.forRoot({
      extras: { checkExpressionOn: 'modelChange' },
      types: [
        {
          name: 'datepicker',
          component: DatepickerTypeComponent,
          wrappers: ['form-field'],
          defaultOptions: {
            defaultValue: new Date(),
            templateOptions: {
              datepickerOptions: {},
            },
          },
        },
      ],
    }),
    NgxDatatableModule,
    WidgetSwitchModule,
    DataTableModule,
    FormContainerModule,
    FusePipesModule,
    IcePipesModule,
  ],
  exports: [CardWithSharesRepertoireComponent],
})
export class CardWithSharesRepertoireModule {}
