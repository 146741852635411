import { createSelector } from '@ngrx/store';
import { Share, Shares } from 'models/copyright/shares/shares';
import * as fromFeature from 'store/root/reducers';
import * as fromReducer from 'store/root/reducers/copyright/copyright.reducer';

export const getCopyrightShares = createSelector(fromFeature.getCopyrightFeatureState, fromReducer.getCopyrightSharesState);

export const getAuditInfoSharePicture = createSelector(
  getCopyrightShares,
  (shares: Shares) => shares && shares.sharePictures && shares.sharePictures.auditInfo && shares.sharePictures.auditInfo.triggeredRules,
);

export const getCurrentShare = createSelector(getCopyrightShares, (shares: Shares) => shares && shares.share);

export const getSharesRequestStatus = createSelector(getCurrentShare, (share: Share) => {
  return share && share.status;
});

export const getDebugSharePicture = createSelector(getCurrentShare, (share: Share) => share && share.debugSharePicture);

export const getOwnershipRowsSharePicture = createSelector(getCurrentShare, (share: Share) => share && share.ownership && share.ownership.rows);

export const getOwnershipTreeSharePicture = createSelector(getCurrentShare, (share: Share) => share && share.ownership && share.ownership.tree);

export const getOwnershipLevelsSharePicture = createSelector(getCurrentShare, (share: Share) => share && share.ownership && share.ownership.levels);

export const getDataLevelsSharePicture = createSelector(getCurrentShare, (share: Share) => share && share.data && share.data.levels);

export const getRepertoireLevelsSharePicture = createSelector(getCurrentShare, (share: Share) => share && share.repertoire && share.repertoire.levels);

export const getPaymentLevelsSharePicture = createSelector(getCurrentShare, (share: Share) => share && share.payment && share.payment.levels);

export const getPaymentRowsSharePicture = createSelector(getCurrentShare, (share: Share) => share && share.payment && share.payment.rows);

export const getPaymentTreeSharePicture = createSelector(getCurrentShare, (share: Share) => share && share.payment && share.payment.tree);

export const getDataRowsSharePicture = createSelector(getCurrentShare, (share: Share) => share && share.data && share.data.rows);

export const getDataTreeSharePicture = createSelector(getCurrentShare, (share: Share) => share && share.data && share.data.tree);

export const getCounterClaimTreeSharePicture = createSelector(getCurrentShare, (share: Share) => share && share.counter_claim && share.counter_claim.tree);

export const getRepertoireRowsSharePicture = createSelector(getCurrentShare, (share: Share) => share && share.repertoire && share.repertoire.rows);

export const getRepertoireTreeSharePicture = createSelector(getCurrentShare, (share: Share) => share && share.repertoire && share.repertoire.tree);

export const getSharePictures = createSelector(getCopyrightShares, (shares: Shares) => (shares && shares.sharePictures ? shares.sharePictures : null));

export const getSharePicturesParameters = createSelector(getSharePictures, (sharePictures: any) => (sharePictures && sharePictures.parameters) || null);

export const getLoadingShares = createSelector(getCopyrightShares, (shares: Shares) => shares && shares.loading);

export const getSalientSessionId = createSelector(getCopyrightShares, (shares: Shares) => shares && shares.salientSessionId);
