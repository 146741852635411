import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FuseSharedModule } from '@fuse/shared.module';
import { DataTableModule } from '@ice/components/data-table/data-table.module';
import { IceMaterialModule } from '@ice/material.module';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { TranslateModule } from '@ngx-translate/core';
import { DatatableEditableTypeComponent } from './datatable-editable-type.component';

@NgModule({
  declarations: [DatatableEditableTypeComponent],
  imports: [TranslateModule, FuseSharedModule, IceMaterialModule, FormlyMaterialModule, MatAutocompleteModule, DataTableModule, FormlyModule.forRoot()],
  exports: [DatatableEditableTypeComponent],
})
export class DatatableEditableTypeModule {}
