<mat-tab-group #matTabGroup [selectedIndex]="getSelectedIndex()">
  <div *ngFor="let tab of tabInfo; attributes as attributes">
    <ng-container *ngIf="tab.attributes | async; let attributes">
      <mat-tab *ngIf="!attributes.isHidden" disabled="true">
        <ng-template mat-tab-label>
          <ng-container *ngIf="attributes.openNewTab">
            <div [className]="'mat-tab-link'" (click)="openNewTab(tab.link)">{{ tab.title | translate }}</div>
          </ng-container>
          <ng-container *ngIf="!attributes.openNewTab">
            <a
              [routerLink]="tab.link"
              [className]="'mat-tab-link tab-' + tab.link + (((attributes.count > 0 || attributes.bold) && ' tab-bold') || '')"
              [matTooltipClass]="'ice-tooltip ice-tooltip-tabs-container'"
              [matTooltipPosition]="'above'"
              matTooltip="{{ tab.titleTooltip | translate }}"
            >
              {{ tab.title | translate }}
            </a></ng-container
          >&nbsp;
          <span *ngIf="attributes.count >= 0 && attributes.count < 100" [className]="'mat-tab-link' + (((attributes.count > 0 || attributes.bold) && ' tab-bold') || '')"
            >({{ attributes.count }})</span
          >
          <span *ngIf="attributes.count > 99 && attributes.count <= 1000" [className]="'mat-tab-link' + (((attributes.count > 0 || attributes.bold) && ' tab-bold') || '')"
            >(99+)</span
          >
          <span *ngIf="attributes.count > 1000 && attributes.count <= 10000" [className]="'mat-tab-link' + (((attributes.count > 0 || attributes.bold) && ' tab-bold') || '')"
            >(1k+)</span
          >
          <span
            *ngIf="attributes.count > 10000 && attributes.count <= 100000"
            matBadge="{{ ceil(attributes.count / 1000) }}k"
            matBadgeOverlap="false"
            matBadgeColor="accent"
            [className]="'mat-tab-link' + (((attributes.count > 0 || attributes.bold) && ' tab-bold') || '')"
          ></span>
          <span
            *ngIf="attributes.count > 100000 && attributes.count <= 100000"
            matBadge="99k"
            matBadgeOverlap="false"
            matBadgeColor="accent"
            [className]="'mat-tab-link' + (((attributes.count > 0 || attributes.bold) && ' tab-bold') || '')"
          ></span>
          <span
            *ngIf="attributes.count > 1000000"
            matBadge="1m+"
            matBadgeOverlap="false"
            matBadgeColor="accent"
            [className]="'mat-tab-link' + (((attributes.count > 0 || attributes.bold) && ' tab-bold') || '')"
          ></span>
        </ng-template>
      </mat-tab>
    </ng-container>
  </div>
</mat-tab-group>
<router-outlet></router-outlet>
