export const ClaimInitModel = {
  ipiNumber: '',
  role: '',
  startDate: '',
  endDate: '',
  ClaimantType: '',
  ClaimantisDummyIP: false,
  ClaimantIPNameNumber: '',
  PublisherType: '',
  shares: [
    { type: 'Mechanical', share: '0.00', territory: '2WL', inclusion: 'ALL' },
    { type: 'Performing', share: '0.00', territory: '2WL', inclusion: 'ALL' },
  ],
};

export const UnKnowPublisherIPIKey = '288936892';

export const ClaimInitModelUnknownPublisher = {
  ClaimantIPI: UnKnowPublisherIPIKey,
  role: 'E',
  startDate: '',
  endDate: '',
  ClaimantType: '',
  ClaimantisDummyIP: false,
  ClaimantIPNameNumber: '',
  PublisherType: '',
  shares: [
    { type: 'Mechanical', share: '0.00', territory: '2WL', inclusion: 'ALL' },
    { type: 'Performing', share: '0.00', territory: '2WL', inclusion: 'ALL' },
  ],
};

export const UnknownAuthorIPIKey = '288936400';

export const ClaimInitModelUnknownAuthor = {
  ClaimantIPI: UnknownAuthorIPIKey,
  role: 'CA',
  startDate: '',
  endDate: '',
  ClaimantType: '',
  ClaimantisDummyIP: false,
  ClaimantIPNameNumber: '',
  PublisherType: '',
  shares: [
    { type: 'Mechanical', share: '0.00', territory: '2WL', inclusion: 'ALL' },
    { type: 'Performing', share: '0.00', territory: '2WL', inclusion: 'ALL' },
  ],
};
