import { MatDialog } from '@angular/material/dialog';
import { DialogJsonViewerComponent } from '@ice/components/dialog-json-viewer/dialog-json-viewer.component';
import { Observable, of } from 'rxjs';

export class DialogUtils {
  static openDialog(dialog: MatDialog, dialogComponentClass: any, title: string, formBuilder: any, model: any, submitLabel: string, cancelLabel: string, onSubmit, onCancel?) {
    dialog.open(dialogComponentClass, {
      data: {
        title: of(title),
        formBuilder: of(formBuilder),
        model,
        submitEnabled: true,
        submitAvailable: 'true',
        submitLabel,
        button1Enabled: true,
        button1Available: 'true',
        button1Label: cancelLabel,
        onSubmit: $event => {
          onSubmit($event);
          dialog.closeAll();
        },
        onButton1: () => {
          if (onCancel) {
            onCancel();
          }
          dialog.closeAll();
        },
      },
    });
  }

  static openDialogJsonViewer(dialog: MatDialog, title: string, json: Observable<any>, expanded: boolean, tooltip: string) {
    dialog.open(DialogJsonViewerComponent, {
      data: {
        title: of(title),
        json,
        expanded: of(expanded),
        tooltip,
      },
    });
  }
}
