import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FEATURES, NAV_PERMISSIONS } from 'config/constants/global.constants';
import * as fromRoot from 'store/root';

/****   HOW TO USE  ****

can( 'agreements_bt_create' )
  
'agreements_bt_create' is a property of FEATURES constant that specifies the allowed roles to this feature.

*/
@Injectable()
export class PermissionsService {
  private userRoles = [];
  private currentTempRoles: string[] = null;

  constructor(private store: Store<fromRoot.RootState>) {}

  getUserRoles(): string[] {
    return this.userRoles;
  }

  setUserRoles(newRoles) {
    this.userRoles = newRoles;
  }

  getUserTempRoles(): string[] {
    return this.currentTempRoles;
  }

  setUserTempRoles(tempRoles) {
    this.currentTempRoles = tempRoles;
  }

  unsetUserTempRoles() {
    this.currentTempRoles = null;
  }

  userHasRole(role): boolean {
    const rolesToValidate = this.currentTempRoles || this.userRoles;
    return rolesToValidate.includes(role);
  }

  can(feature): boolean {
    const rolesToValidate = this.currentTempRoles || this.userRoles;
    if (rolesToValidate.length > 0) {
      if (feature in FEATURES) {
        return FEATURES[feature].some(featureRole => {
          return rolesToValidate.indexOf(featureRole) >= 0;
        });
      }
    }
    return true;
  }

  checkPermissionToNavigate(section: string) {
    return this.can(NAV_PERMISSIONS[section]);
  }
}
