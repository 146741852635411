import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { IpAttributesName, StringUtils, TabsUtils } from '@ice';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import { DIALOG_NORMAL_BT, DialogForm } from 'config/dialog-builders/dialog-form';
import { DialogInfo } from 'config/dialog-builders/dialog-info';
import { IceFacade } from 'facades/ice.facade';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonApiService } from 'services/common-api.service';
import { FeatureFlagService } from 'services/feature-flags/feature-flags-flagsmith.service';
import { NamespaceService } from 'services/namespace/namespace.service';
import { PermissionsService } from 'services/permissions/permissions.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';
import { SectionTabConfig } from '../../tabs-data';

export class TabIpAlternativeNames implements SectionTabConfig {
  flagDisableIpsAlternativeNamesUpdate: Observable<boolean>;

  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private dialog: MatDialog,
    private commonApiService: CommonApiService,
    private detailService: null,
    private nsService: NamespaceService,
    private iceFacade: IceFacade,
    private fieldValidatorService: FieldValidatorService,
    private permissionsService: PermissionsService,
    private searchService,
    storeNewItem: null,
    params: null,
    private datatableService: null,
    private featureFlagService: FeatureFlagService,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);

    this.flagDisableIpsAlternativeNamesUpdate = this.featureFlagService.disableIpsAlternativeNamesUpdate.asObservable();
  }

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('IPS.ALTERNATIVE_NAMES.TAB_TITLE'),
              model: this.store.pipe(select(fromRoot.getIpAlternativeNames)),
              columnMode: 'flex',
              schema: this.getSchema(),
              sorts: [],
              loadingIndicator: false,
              reorderable: true,
              shadowed: false,
              getRowClass: (): any => ({ 'ice-search-results-table-row': true }),
              tableWidth: '100',
              height: 'auto',
              actionButtons: combineLatest([this.store.pipe(select(fromRoot.getEditMode)), this.flagDisableIpsAlternativeNamesUpdate]).pipe(
                map(([editMode, flagDisableIpsAlternativeNamesUpdate]) => {
                  if (editMode === true && !flagDisableIpsAlternativeNamesUpdate) {
                    return [
                      {
                        icon: 'add',
                        tooltip: this.translate.instant('IPS.ALTERNATIVE_NAMES.ADD'),
                        class: 'mat-white-icon',
                        onClick: () => this.newAlternativeNamePopup(),
                      },
                    ];
                  }
                  return null;
                }),
              ),
              visibleColumns: this.getVisibleColumns(this.getSchema()),
            },
          },
        ],
      },
    ];
  }

  newAlternativeNamePopup() {
    this.editPopup({ name: '', firstName: '', language: 'AN', nameId: 0 }, 'new', this.translate.instant('POPUP_COMMON.ADD'));
  }

  editAlternativeNamePopup(row) {
    this.editPopup(row, 'edit', this.translate.instant('POPUP_COMMON.EDIT'));
  }

  editPopup(row: IpAttributesName, type: 'edit' | 'new', titlePrefix: string) {
    const dialogForm = new DialogForm(this.translate, this.dialog, DIALOG_NORMAL_BT);
    const dialogRef = dialogForm.openDialog(
      `${titlePrefix} ${this.translate.instant('IPS.ALTERNATIVE_NAMES.ALTERNATIVE_NAME')}`,
      { name: StringUtils.replaceNbsp(row.name), firstName: StringUtils.replaceNbsp(row.firstName), language: StringUtils.replaceNbsp(row.language) },
      this.getPopupAlternativeNameForm(),
      newValue => {
        this.store.dispatch(new fromRoot.UpdateField({ object: 'alternativeName', newValue, type }));
        dialogRef.close();
      },
      () => dialogRef.close(),
      this.translate.instant('POPUP_COMMON.CONFIRM'),
      this.translate.instant('POPUP_COMMON.CANCEL'),
      'dialog-wrapper-width-420-h',
    );
  }

  private getPopupAlternativeNameForm() {
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'name',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('IPS.ALTERNATIVE_NAMES.EDIT_MODE.NAME'), required: true },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'firstName',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('IPS.ALTERNATIVE_NAMES.EDIT_MODE.FIRST_NAME'), required: true },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'language',
            type: 'select',
            defaultValue: 'AN',
            templateOptions: {
              type: 'text',
              label: this.translate.instant('IPS.ALTERNATIVE_NAMES.LANGUAGE.LABEL'),
              required: true,
              options: [{ label: this.translate.instant('IPS.ALTERNATIVE_NAMES.LANGUAGE.OPTIONS.AN'), value: 'AN' }],
            },
            hideExpression: model => true, // hide while only 1 value is possible
          },
        ],
      },
    ];
  }

  deletePopup(row) {
    const dialogInfo = new DialogInfo(this.translate, this.dialog, this.store);
    const dialogRefInfo = dialogInfo.openDialog(
      this.translate.instant('IPS.ALTERNATIVE_NAMES.REMOVE'),
      this.translate.instant('IPS.ALTERNATIVE_NAMES.CHECK_DELETE'),
      () => {
        this.store.dispatch(new fromRoot.UpdateField({ object: 'alternativeName', newValue: row, type: 'delete' }));
        this.dialog.closeAll();
      },
      () => this.dialog.closeAll(),
      this.translate.instant('NOTES.BUTTONS.REMOVE'),
      this.translate.instant('NOTES.BUTTONS.CANCEL'),
    );
  }

  private getSchema(): any {
    return [
      {
        name: this.translate.instant('IPS.ALTERNATIVE_NAMES.NAME'),
        prop: 'name',
        flexGrow: 3,
        class: 'ice-txt-size-14',
        spacesToHtmlCode: true,
      },
      {
        name: this.translate.instant('IPS.ALTERNATIVE_NAMES.FIRST_NAME'),
        prop: 'firstName',
        flexGrow: 3,
        class: 'ice-txt-size-14',
        spacesToHtmlCode: true,
      },
      {
        name: null,
        prop: 'edit',
        actionButtonIcon: 'edit',
        flexGrow: 0.001,
        maxWidth: 40,
        minWidth: 40,
        resizeable: false,
        action: row => this.editAlternativeNamePopup(row),
        hideActionButton: row =>
          combineLatest([this.store.pipe(select(fromRoot.getEditMode)), this.flagDisableIpsAlternativeNamesUpdate]).pipe(
            map(([editMode, flagDisableIpsAlternativeNamesUpdate]) => !editMode || flagDisableIpsAlternativeNamesUpdate),
          ),
        actionButtonTooltip: this.translate.instant('IPS.ALTERNATIVE_NAMES.EDIT'),
      },
      {
        name: null,
        prop: 'delete',
        actionButtonIcon: 'delete',
        flexGrow: 0.001,
        maxWidth: 50,
        minWidth: 50,
        resizeable: false,
        action: row => this.deletePopup(row),
        hideActionButton: row =>
          combineLatest([this.store.pipe(select(fromRoot.getEditMode)), this.flagDisableIpsAlternativeNamesUpdate]).pipe(
            map(([editMode, flagDisableIpsAlternativeNamesUpdate]) => !editMode || flagDisableIpsAlternativeNamesUpdate),
          ),
        actionButtonTooltip: this.translate.instant('IPS.ALTERNATIVE_NAMES.REMOVE'),
      },
    ];
  }

  private getVisibleColumns(schema: any): Observable<string[]> {
    const schemaDatatable = TabsUtils.getSChemaPropsToArray(schema);
    return this.store.pipe(
      select(fromRoot.getEditMode),
      map(editMode => {
        if (editMode) {
          return schemaDatatable;
        } else {
          return TabsUtils.getSchemaFiltered(schemaDatatable, ['edit', 'delete']);
        }
      }),
    );
  }
}
