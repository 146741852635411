import { CDK_PANE_SELECTOR } from '@ice';
import { each, isArray } from 'lodash';

export function addClassesToElement(selector: string, classes: string[] | string) {
  const element = document.querySelector(selector);
  if (element) {
    if (!isArray(classes)) {
      classes = [classes];
    }
    each(classes, className => element.classList.add(className));
  }
}

export function addClassesToCdkPaneElement(classes: string[] | string) {
  addClassesToElement(CDK_PANE_SELECTOR, classes);
}
