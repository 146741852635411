import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ConflictUtils } from '@ice';
import { DatepickerUtils } from '@ice/utils/datepicker/datepicker.utils';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/search-form-builders';
import moment from 'moment';
import { FormConfig, SearchExpressions } from './form-config';

export class ConflictsAgreementConflictSearchExpressions extends SearchExpressions {
  getSearchExpressionConfig(key: string, value: string, prefix: string, allUsers: any) {
    switch (key) {
      case 'id':
        return `{"or":[
          {"wildcard":{"id":"*${value}*"}},
          {"wildcard":{"agreements.agreementId":"*${value}*"}}
        ]}`;
      case 'caseId': {
        return `{"wildcard":{"id":"*${value}*"}}`;
      }
      case 'status':
        return `{"equals":{"attributes.status":"${value}"}}`;
      case 'agreementId':
        return `{"wildcard":{"agreements.agreementId":"*${value}*"}}`;
      case 'user':
        return `{"wildcard":{"actions.attributes.assignor":"*${value}*"}}`;
      case 'publisher':
        return `{"or":[
          {"wildcard":{"agreements.agreement.assignor.partyName.attributes.name":"*${value}*"}},
          {"wildcard":{"agreements.agreement.assignor.partyName.relations[XREF].otherId":"*${value}*"}}
        ]}`;
      case 'deadline':
        return `{"equals":{"actions.attributes.deadline":"${moment(value).format('YYYY-MM-DD')}"}}`;
      case 'fromDate':
        return `{"range":{"attributes.createdDate":{"gte": "${moment(value).format('YYYY-MM-DD')}"}}}`;
      case 'toDate':
        return `{"range":{"attributes.createdDate":{"lte": "${moment(value).format('YYYY-MM-DD')}"}}}`;
      case 'xref':
        return `{"equals":{"agreements.agreement.relations[XREF].otherId":"${value}"}}`;
    }
    return `{"wildcard":{"attributes.${key}":"*${value}*"}}`;
  }

  getExtraSearchExpression() {
    return '';
  }
}

export class SearchConflictsAgreementConflictForm implements FormConfig {
  constructor(private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  getForm(): FormlyFieldConfig[] {
    const translate = this.translate;
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'caseId',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('AGREEMENT_CONFLICT.CASE_ID'),
            },
          },
          {
            className: 'flex-1',
            key: 'status',
            type: 'select',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('AGREEMENT_CONFLICT.CASE_STATUS'),
              options: ConflictUtils.getAgreementConflictStateOptions(this.translate),
            },
          },
          {
            className: 'flex-1',
            key: 'agreementId',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('AGREEMENT_CONFLICT.AGREEMENT_REF'),
            },
          },
          {
            className: 'flex-1',
            key: 'user',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('AGREEMENT_CONFLICT.ASSIGNED_USER'),
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'publisher',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('AGREEMENT_CONFLICT.PUBLISHER'),
            },
          },
          DatepickerUtils.getDatepickerField({
            name: 'deadline',
            label: this.translate.instant('AGREEMENT_CONFLICT.DEADLINE_DATE'),
            translate,
          }),
          DatepickerUtils.getDatepickerField({
            name: 'fromDate',
            label: this.translate.instant('AGREEMENT_CONFLICT.CREATED_FROM'),
            translate,
          }),
          DatepickerUtils.getDatepickerField({
            name: 'toDate',
            label: this.translate.instant('AGREEMENT_CONFLICT.CREATED_TO'),
            translate,
          }),
        ],
      },
    ];
  }
}
