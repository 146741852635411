import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { SharePictureType } from '@ice';
import { locale as english } from '@ice/i18n/en/expansion-list';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { each, flatMap, get, includes } from 'lodash';
import { BehaviorSubject, Observable, combineLatest, of, timer } from 'rxjs';
import { concatMap, delay, filter, map, mapTo, switchMap, take, tap } from 'rxjs/operators';
import { DataTableComponent, WidgetSwitchComponent } from '..';
import { SharePictureTypes } from './../../../models/copyright/share-picture/share-picture';
import { CardWithShares } from './card-with-shares';

@Component({
  selector: 'ice-card-with-shares',
  templateUrl: './card-with-shares.component.html',
  styleUrls: ['./card-with-shares.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardWithSharesComponent implements OnInit {
  @Input() config: CardWithShares;
  selectedType: SharePictureType;
  selectedTypeSubject: BehaviorSubject<SharePictureType> = new BehaviorSubject(SharePictureTypes.MANUSCRIPT);
  tableVisibleColumns: Observable<string[]>;
  tableData: Observable<any[]>;
  treeLevels: Observable<any>;
  treeData: Observable<any[]>;
  treeVisibility: Observable<boolean>;
  disableLog: Observable<boolean>;
  fixedFace: Observable<string>;
  totalsTiles: Observable<any[]>;
  claimTotals: any[];
  totalsClass: Observable<string>;
  isFront: boolean;
  requestStatus: Observable<string>;
  canShow = true;
  @ViewChild('iceWidgetSwitch') iceWidgetSwitch: WidgetSwitchComponent;
  @ViewChild(DataTableComponent) dataTable: DataTableComponent;

  constructor(private fuseTranslationLoader: FuseTranslationLoaderService) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  ngOnInit() {
    this.selectedType =
      (this.config &&
        get(
          this.config.shareTypes.find(shareType => shareType.checked),
          'type',
        )) ||
      SharePictureTypes.MANUSCRIPT;
    if (this.config?.table) {
      this.tableVisibleColumns = this.getObservableBySelectType(this.config.table.visibleColumns);
      this.tableData = this.getObservableBySelectType(this.config.table.model);
      this.requestStatus = this.config.table.requestStatus;
    }
    if (this.config && this.config.tree) {
      this.treeData = this.getObservableBySelectType(this.config.tree.model);
      this.treeLevels = this.getObservableBySelectType(this.config.tree.levels);
      this.treeVisibility = this.getObservableBySelectType(this.config.tree.visibility);
    }
    if (this.config && this.config.totals) {
      this.totalsClass = this.getObservableBySelectType(this.config.totals.class);
    }
    this.getFixedFace();
    this.getTotalTiles();
  }

  setIsFront(isFront) {
    this.isFront = isFront;
  }

  onResetSort() {
    this.dataTable.onResetSort();
  }

  onSelectType(type) {
    this.selectedTypeSubject.next(type);
    this.checkHideTree(type);
    this.checkHideFilterInputs(type);
  }

  onToggleChange(event) {
    this.selectedType = event.checked ? this.config.shareTypes[0].type : this.config.shareTypes[1].type;
    this.selectedTypeSubject.next(this.selectedType);
  }

  getObservableBySelectType(property: any): Observable<any> {
    if (property) {
      return combineLatest([...flatMap(property), this.selectedTypeSubject] as any[]).pipe(switchMap(_ => property[this.selectedType] || property['default']));
    } else {
      return null;
    }
  }

  getFixedFace() {
    if (this.treeVisibility && this.config.loading) {
      this.fixedFace = combineLatest([
        this.treeVisibility,
        this.config.loading.pipe(
          filter(loading => loading === false),
          tap(_ => (this.canShow = false)),
          delay(300),
          take(1),
        ),
      ]).pipe(
        switchMap(([treeVisible, loaded]) => {
          this.canShow = true;
          if (!(this.config.tree && treeVisible) && this.config.table) {
            return of('front');
          }
          if (this.config.tree && treeVisible && !this.config.table) {
            return of('back');
          }
          return of('default');
        }),
      );
    }
  }

  getTotalTiles() {
    if (this.tableData && this.config.totals) {
      this.totalsTiles = this.tableData.pipe(
        map(model => this.config.totals.totalParser(model)),
        concatMap((model, index) => {
          if (model) {
            const { totalMR, totalPR, publisherSubTotalPR, publisherSubTotalMR, writerSubTotalMR, writerSubTotalPR, specialSubTotalPR, specialSubTotalMR } = model;
            const totals = [
              { text: this.config.totals.writerSubTotalLabel, span: 2 },
              { text: `${writerSubTotalPR}`, span: 1 },
              { text: `${writerSubTotalMR}`, span: 1 },
              { text: this.config.totals.publisherSubTotalLabel, span: 2 },
              { text: `${publisherSubTotalPR}`, span: 1 },
              { text: `${publisherSubTotalMR}`, span: 1 },
            ];

            if (specialSubTotalPR !== '0.00' && specialSubTotalMR !== '0.00') {
              totals.push({ text: this.config.totals.specialSubTotalLabel, span: 2 }, { text: `${specialSubTotalPR}`, span: 1 }, { text: `${specialSubTotalMR}`, span: 1 });
            }
            totals.push({ text: this.config.totals.totalClaimsLabel, span: 2 }, { text: `${totalPR}`, span: 1 }, { text: `${totalMR}`, span: 1 });
            this.claimTotals = [
              { text: this.config.totals.totalClaimsLabel, span: 2 },
              { text: `${totalPR}`, span: 1 },
              { text: `${totalMR}`, span: 1 },
            ];
            if (index === 0) {
              return timer(250).pipe(mapTo(totals));
            } else {
              return of(totals);
            }
          } else {
            return of([]);
          }
        }),
      );
    }
  }

  checkHideTree(type: any) {
    if (this.isFront && type === SharePictureTypes.MANUSCRIPT) {
      this.iceWidgetSwitch.switchFace(!this.isFront);
    }
  }

  checkHideFilterInputs(type: any) {
    const fields: FormlyFieldConfig[] = this.getFormFields(['removeE', 'removeSE']);
    if (type === SharePictureTypes.MANUSCRIPT) {
      this.hideFields(fields);
    } else {
      this.displayFields(fields);
    }
  }

  getFormFields(fieldKeys: string[], prop: string = 'key'): FormlyFieldConfig[] {
    const fields: FormlyFieldConfig[] = [];
    each(this.config.filter.formBuilder, group => {
      const fieldGroup = get(group, 'fieldGroup', []);
      return each(fieldGroup, field => {
        const value = get(field, prop, '');
        if (includes(fieldKeys, value)) {
          fields.push(field);
        }
      });
    });
    return fields;
  }

  hideFields(fields: FormlyFieldConfig[]) {
    this.updateFieldProp(fields, 'hide', true);
  }

  displayFields(fields: FormlyFieldConfig[]) {
    this.updateFieldProp(fields, 'hide', false);
  }

  updateFieldProp(fields: FormlyFieldConfig[], prop: string, value: any) {
    each(fields, field => {
      field[prop] = value;
    });
  }
}
