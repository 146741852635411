import { ActivitySearch, ActivitySearchItemCleaned, ActivityTriggerAttributes, ConflictUtils, CopyrightUtils, DateTimeUtils, TerritoryUtils, WorkUtils } from '@ice';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ConflictSubType } from 'config/constants/activity.constants';
import { TerritoryDataType } from 'config/constants/territories.constants';
import { find, get, isArray, pick, reverse, sortBy, split, sum } from 'lodash';
import { organizationCaseStatusOptions, OrganizationCaseStatusType } from 'models/copyright/search/user-management-organization-search';

export class SearchActivityParser {
  static parse(items: any[], translate: TranslateService, extraParams?: Object, extraItems?: string[], store?: Store<any>) {
    const allUsers = get(extraParams, 'allUsers');
    const cleanedItems = items
      .filter(item => !!item)
      .map((item: any) => {
        const {
          activityTriggers,
          caseArea,
          conflictId,
          conflictSubtype,
          conflictType,
          deadline,
          domesticSocieties,
          firstCreator,
          title,
          value,
          workId,
          assignee,
          caseStatus,
          uploadCategory,
          ids,
          id,
        } = item;
        const orderedActivityTriggers = (activityTriggers && reverse(sortBy(activityTriggers, ['value']))) || [];
        const fullName = allUsers?.length ? get(find(allUsers, { id: assignee }), 'fullName', assignee) : assignee;

        return <ActivitySearchItemCleaned>{
          id,
          conflictId,
          deadline: DateTimeUtils.formatDate(deadline),
          workId,
          formattedWorkId: WorkUtils.removeLeadingZeros(workId),
          conflictType,
          conflictSubType: conflictSubtype,
          conflictStatus: this.createConflictStatus(caseStatus ?? 'NEW'),
          assignee: fullName,
          workTitle: title,
          firstCreator,
          value: (value && value.toFixed(2)) || '',
          uploadCategory,
          uploadCategoryTooltip: CopyrightUtils.getTranslation(translate, 'ACTIVITY.UPLOAD_CATEGORY_TYPE.' + uploadCategory),
          domesticSociety: ConflictUtils.getDomesticSocietyTooltip(domesticSocieties, translate),
          triggers: (activityTriggers && activityTriggers.length) || 0,
          conflictArea: caseArea,
          conflictAreaTooltip: translate.instant('ACTIVITY.CONFLICT_AREA_TYPE.' + caseArea),
          detail: orderedActivityTriggers,
          ids,
        };
      });
    return cleanedItems;
  }

  static createConflictStatus = (caseObject: any, ccConflit = false) => {
    let result = [caseObject];
    if (ccConflit) {
      result = caseObject?.status ?? ['NEW'];
      if (!isArray(result)) result = [result];
    }
    if (!result.includes(OrganizationCaseStatusType.AR)) {
      result.push(OrganizationCaseStatusType.AR);
    }
    if (!result.includes(OrganizationCaseStatusType.RSCH)) {
      result.push(OrganizationCaseStatusType.RSCH);
    }
    return result.map(status => {
      return {
        value: status,
        label: status,
      };
    });
  };

  static workConflictParse(items: any[], translate: TranslateService) {
    const cleanedItems = items
      .filter(item => item !== undefined)
      .map((item: ActivitySearch) => {
        let workTitle: string;
        let firstCreator: string;
        let soc: string;
        let value: string;
        let deadline: string;
        let uploadCat: string;
        let workId: string;
        let triggers: ActivityTriggerAttributes[];
        let conflictData: string;
        let conflictType: string;
        let conflictSubType: ConflictSubType;
        let conflictArea: string;
        let claimsWithMissingLinks: [];

        const { attributes, claimConflictPoints } = item;
        if (attributes) {
          conflictData = attributes.entityType || '';
          conflictType = attributes.conflictType || '';
          conflictSubType = attributes.conflictSubType;
          claimsWithMissingLinks = get(attributes, 'data.claimsWithMissingLinks');
        }

        const firstWork = get(item, 'works[0].work', null);
        if (firstWork) {
          ({ workId, workTitle, firstCreator, soc, value, deadline, uploadCat, triggers, conflictArea } = ConflictUtils.cleanSearchConflictWork(firstWork));
        }
        const caseObject = item.case || {};
        switch (caseObject && caseObject.status && caseObject.status.toUpperCase()) {
          case OrganizationCaseStatusType.IP:
          case OrganizationCaseStatusType.IPNEW:
            caseObject.status = organizationCaseStatusOptions.in_progress;
            break;
        }
        const valueTotal = triggers && sum(triggers.map(trigger => trigger.value));

        return <ActivitySearchItemCleaned>{
          id: item.id,
          attributesData: attributes?.data,
          attributesDetails: attributes?.details,
          conflictId: item.id,
          customer: soc,
          conflictData,
          deadline: '',
          workId,
          conflictType,
          conflictSubType,
          conflictStatus: this.createConflictStatus(caseObject, true),
          assignee: caseObject && caseObject.userId && split(caseObject.userId, ':').length > 1 ? split(caseObject.userId, ':')[1] : caseObject.userId,
          claimsWithMissingLinks,
          caseId: caseObject && caseObject.caseId,
          workTitle,
          firstCreator,
          scope: claimConflictPoints?.map(scope => ({
            ...scope,
            territoriesTisa: TerritoryUtils.convertTerritoryArrayElements(scope.territories.inExTisns, TerritoryDataType.TISA),
            territoriesTooltip: TerritoryUtils.getTerritoriesNamesTooltipText(TerritoryUtils.convertTerritoryArrayElements(scope.territories.inExTisns, TerritoryDataType.NAME)),
          })),
          value: (valueTotal && valueTotal.toFixed(2)) || '',
          distributionCategory: uploadCat,
          triggers: (triggers && triggers.length) || 0,
          conflictArea,
          detail: triggers && triggers.map(trigger => pick(trigger, ['value', 'deadline', 'uploadCat'])),
        };
      });
    return cleanedItems;
  }
}
