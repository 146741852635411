import { environment } from 'config/env';
import type { SectionConfig } from './sections-config';

export const SectionCopyrightSocieties: SectionConfig = {
  name: 'societies',
  url: '/copyright/societies',
  domainName: 'copyright',
  baseUrl: environment.apiUrl,
  baseUrlCubeData: environment.apiUrlCubeData,
  apiSegment: 'societies',
  detailSegment: `societies/<<ns>>/<<id>>`,
  minCharsToSearch: 3,
  apiIncludes: {
    detail: 'attributes',
    search: 'attributes',
  },
  searchServerSideSortDefault: '',
  allowedActions: {
    newItem: false,
    editItem: null,
    newNote: false,
    removeItem: false,
  },
};
