import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DataTableRow, SortInfo } from '@ice/components/data-table/data-table';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import { DataTableBuilder } from 'config/data-table-builders/data-table-builder';
import { RootDatatable } from 'config/data-table-builders/root-datatable';
import { DialogInfo } from 'config/dialog-builders/dialog-info';
import { SectionsConfig } from 'config/sections-config';
import { of } from 'rxjs';
import * as fromRoot from 'store/root';

export class CopyrightAgreementsDataTable extends RootDatatable implements DataTableBuilder {
  constructor(protected translate: TranslateService, protected fuseTranslationLoader: FuseTranslationLoaderService, protected store) {
    super(translate);
    this.fuseTranslationLoader.loadTranslations(english);
  }

  getDefaultSorting(): SortInfo[] {
    return [{ prop: 'assignor.name', dir: 'asc' }];
  }

  formatSort(sort: SortInfo) {
    switch (sort.prop) {
      case 'assignor.name':
        return { prop: `assignor.partyName.attributes.name`, dir: sort.dir };
      case 'assignee.name':
        return { prop: `assignee.partyName.attributes.name`, dir: sort.dir };
      case 'admin':
        return { prop: `attributes.administrator`, dir: sort.dir };
      case 'key':
      case 'keyWithoutPrefix':
      case 'territoriesText':
      case 'pr':
      case 'mr':
      case 'dispute':
      case 'priorRoyaltiesStartDate':
      case 'workCount':
        return { prop: `agg.attributes.workCount`, dir: sort.dir };
      case 'startDate':
        return { prop: `attributes.shares.priorRoyaltiesDate`, dir: sort.dir };
      case 'endDate':
      case 'postTermCollectionDate':
        return { prop: `attributes.shares.${sort.prop}`, dir: sort.dir };
      case 'retentionValue':
        return { prop: 'attributes.retention', dir: sort.dir };
    }
    return { prop: `attributes.${sort.prop}`, dir: sort.dir };
  }

  getDataTable(): DataTableRow[] {
    return [
      {
        name: this.translate.instant('AGREEMENTS.ASSIGNOR'),
        prop: 'assignor.name',
        icons: 'assignor.icons',
        cellClass: 'ice-txt-size-14 agreements-assignor-name',
        flexGrow: 2.8,
        comparator: (valueA, valueB, modelA, modelB) => this.assignorAssigneeComparator('assignor', modelA, modelB),
      },
      {
        name: this.translate.instant('AGREEMENTS.ASSIGNEE'),
        prop: 'assignee.name',
        cellClass: 'ice-txt-size-14 agreements-assignee-name',
        flexGrow: 2.8,
        comparator: (valueA, valueB, modelA, modelB) => this.assignorAssigneeComparator('assignee', modelA, modelB),
      },
      {
        name: this.translate.instant('AGREEMENTS.ADMIN'),
        prop: 'admin', // Dummy property for sorting
        icons: 'administratorIcon',
        hideTextProperty: true,
        cellClass: 'ice-txt-size-14 agreements-admin',
        flexGrow: 1.2,
        comparator: (valueA, valueB, modelA, modelB) => this.adminComparator(modelA, modelB),
      },
      { name: this.translate.instant('AGREEMENTS.REFERENCE'), prop: 'key', cellClass: 'ice-txt-size-14 agreements-reference', flexGrow: 2 },
      { name: this.translate.instant('AGREEMENTS.NAME'), prop: 'searchTerm', cellClass: 'ice-txt-size-14 agreements-name', flexGrow: 2 },
      {
        name: this.translate.instant('AGREEMENTS.WORKCOUNT'),
        prop: 'workCount',
        cellClass: 'ice-txt-size-14 ice-cursor-pointer',
        flexGrow: 1.2,
        onClickAction: row =>
          this.store.dispatch(
            new fromRoot.Go({
              path: [`${SectionsConfig.AGREEMENTS.domainName}/${SectionsConfig.AGREEMENTS.apiSegment}/${row.key}/included-works`],
            }),
          ),
      },
      { name: this.translate.instant('AGREEMENTS.TYPE'), prop: 'agreementType', cellClass: 'ice-txt-size-14 agreements-type', flexGrow: 1, tooltip: 'agreementTypeTooltip' },
      {
        name: this.translate.instant('AGREEMENTS.TERRITORIES'),
        prop: 'territoriesText',
        icons: 'territoriesIcon',
        tooltip: 'territoriesTooltip',
        tooltipDuration: 250,
        cellClass: 'ice-txt-size-14 agreements-territories',
        flexGrow: 1.5,
      },
      {
        name: this.translate.instant('AGREEMENTS.PR'),
        prop: 'pr',
        icons: 'prIcons',
        cellClass: 'ice-txt-size-14 agreements-pr',
        flexGrow: 1.4,
        exportProp: 'prToExport',
        headerTooltip: this.translate.instant('AGREEMENTS.PR_TOOLTIP'),
      },
      {
        name: this.translate.instant('AGREEMENTS.MR'),
        prop: 'mr',
        icons: 'mrIcons',
        cellClass: 'ice-txt-size-14 agreements-mr',
        flexGrow: 1.4,
        exportProp: 'mrToExport',
        headerTooltip: this.translate.instant('AGREEMENTS.MR_TOOLTIP'),
      },
      {
        name: this.translate.instant('AGREEMENTS.DISPUTE'),
        prop: 'dispute',
        icons: 'disputeIcon',
        hideTextProperty: true,
        cellClass: 'ice-txt-size-14 agreements-dispute',
        flexGrow: 1.4,
      },
      { name: this.translate.instant('AGREEMENTS.START_DATE'), prop: 'startDate', icons: 'sharesIcons', cellClass: 'ice-txt-size-14 agreements-start-date', flexGrow: 1.5 },
      {
        name: this.translate.instant('AGREEMENTS.PRIOR_ROYALTIES'),
        prop: 'priorRoyaltiesStartDate',
        icons: 'sharesIcons',
        cellClass: 'ice-txt-size-14 agreements-prior-royalties',
        flexGrow: 1.5,
      },
      {
        name: this.translate.instant('AGREEMENTS.TERMINATION_DATE'),
        prop: 'endDate',
        icons: 'partiallyTerminatedIcons',
        cellClass: 'ice-txt-size-14 agreements-termination-date',
        flexGrow: 1.8,
        exportProp: 'endDateToExport',
      },
      {
        name: this.translate.instant('AGREEMENTS.POST_TERM_COLLECTION_END_DATE'),
        prop: 'postTermCollectionDate',
        icons: 'partiallyTerminatedIcons',
        cellClass: 'ice-txt-size-14 agreements-post-term-collection-date',
        flexGrow: 1.8,
        exportProp: 'postTermCollectionDateToExport',
      },
      {
        name: this.translate.instant('AGREEMENTS.RETENTION_COL'),
        prop: 'retentionValue',
        hideTextProperty: true,
        icons: 'retentionIcons',
        cellClass: 'ice-txt-size-14 agreements-pr',
        flexGrow: 1,
        headerTooltip: this.translate.instant('AGREEMENTS.RETENTION_COL_TOOLTIP'),
      },
    ];
  }

  getRepertoireAgreementsField(dialog, store, tabTitle, deleteInfo, onDelete: (row, dialogRef) => void, canEdit = false) {
    return [
      {
        name: '',
        prop: 'delete',
        actionButtonIcon: 'delete',
        flexGrow: 0.001,
        maxWidth: 50,
        minWidth: 50,
        resizeable: false,
        hideActionButton: row => of(canEdit),
        action: row => {
          const dialogInfo = new DialogInfo(this.translate, dialog, store);
          const dialogRef = dialogInfo.openDialog(
            `${this.translate.instant('REPERTOIRES.COMMON.DELETE')} ${tabTitle}`,
            deleteInfo,
            () => onDelete(row, dialogRef),
            () => dialogRef.close(),
          );
        },
        actionButtonTooltip: `${this.translate.instant('REPERTOIRES.COMMON.DELETE')} ${tabTitle}`,
      },
    ];
  }

  getColumnMode(): ColumnMode {
    return ColumnMode.flex;
  }

  adminComparator(modelA, modelB) {
    const valueA: number = modelA[`administrator`] || 0;
    const valueB: number = modelB[`administrator`] || 0;
    return valueA > valueB ? 1 : -1;
  }

  assignorAssigneeComparator(type: string, modelA: any, modelB: any): number {
    if (modelA && modelB && modelA[type] && modelB[type]) {
      return (modelA[type].name || 0) > (modelB[type].name || 0) ? 1 : -1;
    }
    return 0;
  }

  displayCheck(row: any, firstSelectedItem: any, targetItem: any, column?: any, value?: any): boolean {
    return true;
  }
}
