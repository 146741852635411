import { TreeNodeData } from '@ice/components/work-tree-chart/tree-chart-models';
import { groupBy, map, sum } from 'lodash';

export class TreeChartUtils {
  static calculateLevels(value: TreeNodeData[]) {
    const additionalHLevel = 1;
    const minhLevel = 7;
    const minvLevel = 6;
    const maxHLevel = ((value?.length > 0 && value?.sort((a, b) => (a.level > b.level ? -1 : 1))[0].level + 1) || minhLevel) + additionalHLevel;
    const totalNodes = sum(map(groupBy(value, 'level'), group => group.length));
    const vLevel = totalNodes + (totalNodes >= minvLevel ? minvLevel : 0);
    return { maxHLevel: minhLevel > maxHLevel ? minhLevel : maxHLevel, vLevel: minvLevel > vLevel ? minvLevel : vLevel };
  }
}
