import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DataTableRow, SortInfo } from '@ice/components/data-table/data-table';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import { RootDatatable } from 'config/data-table-builders/root-datatable';

import { ColumnMode } from '@swimlane/ngx-datatable';
import { DataTableBuilder } from './data-table-builder';

export class CopyrightAgreementGroupDataTable extends RootDatatable implements DataTableBuilder {
  constructor(protected translate: TranslateService, protected fuseTranslationLoader: FuseTranslationLoaderService) {
    super(translate);
    this.fuseTranslationLoader.loadTranslations(english);
  }

  getDefaultSorting(): SortInfo[] {
    return [{ prop: 'groupId', dir: 'asc' }];
  }

  formatSort(sort: SortInfo) {
    switch (sort.prop) {
      case 'groupId':
        return { prop: `attributes.id`, dir: sort.dir };
      case 'ipName':
        return { prop: `partyName.attributes.name`, dir: sort.dir };
      case 'ipNameKey':
        return { prop: `partyName.relations[DISP].otherId`, dir: sort.dir };
      default:
        return { prop: `attributes.${sort.prop}`, dir: sort.dir };
    }
  }

  getDataTable(): DataTableRow[] {
    return [
      { name: this.translate.instant('AGREEMENT_GROUP.GROUP_ID'), prop: 'groupId', flexGrow: 1 },
      { name: this.translate.instant('AGREEMENT_GROUP.OWNER'), prop: 'ipName', flexGrow: 3 },
      { name: this.translate.instant('AGREEMENT_GROUP.DESCRIPTION'), prop: 'description', flexGrow: 3 },
      { name: this.translate.instant('AGREEMENT_GROUP.IP_NAME_KEY'), prop: 'ipNameKey', flexGrow: 1 },
    ];
  }

  getColumnMode(): ColumnMode {
    return ColumnMode.flex;
  }

  displayCheck(row: any, firstSelectedItem: any, targetItem: any, column?: any, value?: any): boolean {
    return true;
  }
}
