import { CaseClaimant, ClaimCase, ClaimGraphUtils, CopyrightOwnershipTableItem, CounterClaimUtils, SharePictures, WorkDetail } from '@ice';
import { ClaimsUtils } from '@ice/utils/claim/claims.utils';
import { WorkUtils } from '@ice/utils/work/work.utils';
import { createSelector } from '@ngrx/store';
import { compact } from 'lodash';
import { SharePicture } from 'models/copyright/shares/shares';
import * as fromFeature from 'store/root/reducers';
import * as fromReducer from 'store/root/reducers/copyright/copyright.reducer';
import * as fromSharesSelector from 'store/root/selectors/copyright/shares/shares.selectors';

export const getCopyrightWork = createSelector(fromFeature.getCopyrightFeatureState, fromReducer.getCopyrightWorkState);

export const getCopyrightWorkEditClaim = createSelector(fromFeature.getCopyrightFeatureState, fromReducer.getCopyrightWorksEditClaimState);

export const getManuscriptTableSelected = createSelector(fromFeature.getCopyrightFeatureState, fromReducer.getManuscriptTableSelectedState);

export const getCopyrightOwnership = createSelector(fromFeature.getCopyrightFeatureState, fromReducer.getCopyrightOwnershipTableItemsState);

export const getCopyrightSharePicture = createSelector(fromFeature.getCopyrightFeatureState, fromReducer.getCopyrightSharePictureState);

export const getAllClaims = createSelector(getCopyrightWork, (detail: WorkDetail) => (detail && detail.allClaims) || []);

export const getWorkClaims = createSelector(getCopyrightWork, (detail: WorkDetail) => (detail && detail?.claims) || []);

export const getWorkIpClauses = createSelector(getCopyrightWork, (detail: WorkDetail) => detail && detail.clauses);

export const getManuscriptTableItems = createSelector(getCopyrightWork, (workDetail: WorkDetail) => (workDetail && workDetail.manuscriptData) || []);

export const getOwnershipClaimsTableItems = createSelector(getCopyrightSharePicture, (sharePictures: SharePictures) =>
  ClaimGraphUtils.getOwnershipTableItemsFromSharePictures(sharePictures),
);

export const getManuscriptViewVisibleColumns = createSelector(getManuscriptTableItems, manuscriptTableSelected =>
  ClaimGraphUtils.getOwnershipTableVisibleColumns(manuscriptTableSelected, null, false),
);

export const getClaimViewVisibleColumns = createSelector(getOwnershipClaimsTableItems, claimsTableSelected =>
  ClaimGraphUtils.getOwnershipTableVisibleColumns(null, claimsTableSelected, false),
);

export const getCopyrightOwnershipTableTotals = createSelector(getCopyrightOwnership, (ownershipTableItems: CopyrightOwnershipTableItem[]) =>
  ClaimGraphUtils.getCopyrightOwnershipTotals(ownershipTableItems),
);

export const getCopyrightManuscriptTableTotals = createSelector(getManuscriptTableItems, (items: CopyrightOwnershipTableItem[]) =>
  ClaimGraphUtils.getCopyrightOwnershipTotals(items),
);

export const getWorkPossibleMatches = createSelector(getCopyrightWork, (detail: WorkDetail) => (detail && detail.relations ? detail.relations : []));

export const getWorkMatchRelations = createSelector(getWorkPossibleMatches, relations => relations.filter(relation => relation.relation === 'MTCH'));

export const getWorkDetailWork = createSelector(getCopyrightWork, (detail: WorkDetail) => (detail && detail.attributes ? WorkUtils.getWorkWithIswcAndDuration(detail) : {}));

export const getWorkSocietyMarkers = createSelector(getCopyrightWork, (detail: WorkDetail) => (detail && detail.attributes && detail.attributes.societyMarkers) || []);

export const getWSM = createSelector(getCopyrightWork, (detail: WorkDetail) => {
  return WorkUtils.getWSM(detail);
});

export const getWorkDetailXrefsIds = createSelector(getCopyrightWork, (detail: WorkDetail) =>
  detail && detail.relations ? WorkUtils.getWorkToCompareXrefsIdsOrdered(detail.relations) : [],
);

export const getWorkDetailAgreements = createSelector(getCopyrightWork, (detail: WorkDetail) => (detail && detail.agreements ? detail.agreements.items : []));

export const getWorkDetailAlternativeTitles = createSelector(getCopyrightWork, (detail: WorkDetail) =>
  detail && detail.attributes && detail.attributes.titles ? WorkUtils.getWorkAlternativeTitles(detail.attributes.titles) : [],
);

export const getWorkDetailPartyNames = createSelector(getCopyrightWork, (detail: WorkDetail) =>
  detail && detail.partyNames ? WorkUtils.getWorkDetailPartyNamesOrdered(detail.partyNames) : [],
);

export const getWorkDetailContributors = createSelector(getCopyrightWork, (detail: WorkDetail) =>
  detail && detail.partyNames && detail.contributions ? WorkUtils.getWorkDetailContributorsOrdered(detail) : [],
);

export const getWorkPartyNames = createSelector(getCopyrightWork, (detail: WorkDetail) => (detail && detail.partyNames ? WorkUtils.getWorkPartyNames(detail.partyNames) : []));

export const getSourceWorks = createSelector(getCopyrightWork, (detail: WorkDetail) => (detail && detail.attributes && detail.sourceWorks && detail.sourceWorks.items) || []);

export const getWorkInstruments = createSelector(getCopyrightWork, (detail: WorkDetail) =>
  detail && detail.attributes && detail.attributes.instruments ? detail.attributes.instruments : [],
);

export const getWorkInstrumentations = createSelector(getCopyrightWork, (detail: WorkDetail) =>
  detail && detail.attributes && detail.attributes.standardInstrumentation ? detail.attributes.standardInstrumentation : [],
);

export const getWorkSocieties = createSelector(getCopyrightWork, (detail: WorkDetail) => (detail && detail.societies ? detail.societies : []));

export const getWorkSharePicture = createSelector(getCopyrightWork, (detail: WorkDetail) => (detail && detail.sharePicture ? detail.sharePicture : null));

export const getWorkSharePictureId = createSelector(getWorkSharePicture, (sharePicture: SharePicture) => sharePicture && sharePicture.id);

export const getWorkRepertoires = createSelector(getCopyrightWork, (detail: WorkDetail) => (detail && detail.repertoires ? detail.repertoires : []));

export const getWorkAuditHistory = createSelector(getCopyrightWork, (detail: WorkDetail) => (detail && detail.auditHistory ? detail.auditHistory.items : []));

export const getWorkCreators = createSelector(getCopyrightWork, WorkUtils.getCreatorsFromWorkDetail);

export const getWorkPublishers = createSelector(getCopyrightWork, WorkUtils.getPublishersFromWorkDetail);

export const getWorkPublishersICE = createSelector(getCopyrightWork, WorkUtils.getPublishersICEFromWorkDetail);

export const getWorkPublishersAndCreatorsICE = createSelector(getCopyrightWork, WorkUtils.getPublishersAndCreatorICEFromWorkDetail);

export const getOptionsWorkCreators = createSelector(getCopyrightWork, WorkUtils.getCreatorsOptionsFromWorkDetail);

export const getWorkId = createSelector(getCopyrightWork, (detail: WorkDetail) => (detail && detail.attributes ? detail.attributes.id : ''));

export const getWorkKey = createSelector(getCopyrightWork, (detail: WorkDetail) => (detail && detail.attributes ? detail.attributes.key : ''));

export const getWorkTitle = createSelector(getCopyrightWork, (detail: WorkDetail) => detail?.attributes?.title || '');

export const getManuscriptData = createSelector(getCopyrightWork, (detail: WorkDetail) => detail && detail.manuscriptData);

export const getCounterClaims = createSelector(getCopyrightWork, (detail: WorkDetail) => (detail && detail.claimCases && compact(detail.claimCases)) || []);

export const getCounterClaimsClaimants = createSelector(getCounterClaims, (counterClaims: ClaimCase[]) => CounterClaimUtils.getClaimantsFormCounterClaims(compact(counterClaims)));

export const getClaims = createSelector(getCopyrightWork, (detail: WorkDetail) => (detail && detail.claims) || []);

export const getOwnershipRowsSharePictureWithClaimants = createSelector(
  getCounterClaimsClaimants,
  fromSharesSelector.getOwnershipRowsSharePicture,
  getClaims,
  (claimants: CaseClaimant[], ownershipRows: any[], claims: any[]) =>
    claimants && ownershipRows && claims && ClaimsUtils.joinClaimsToOwnership(CounterClaimUtils.joinSharesWithClaimants(ownershipRows, claimants, false), claims),
);

export const hasOwnershipIncomeParticipant = createSelector(
  fromSharesSelector.getOwnershipRowsSharePicture,
  getClaims,
  (ownershipRows: any[], claims: any[]) => ownershipRows && claims && ClaimsUtils.hasOwnershipIncomeParticipant(ownershipRows, claims),
);

export const getPaymentRowsSharePictureWithClaimants = createSelector(
  getCounterClaimsClaimants,
  fromSharesSelector.getPaymentRowsSharePicture,
  (claimants: CaseClaimant[], ownershipRows: any[]) => claimants && ownershipRows && CounterClaimUtils.joinSharesWithClaimants(ownershipRows, claimants, false),
);

export const getDataRowsSharePictureWithClaimants = createSelector(
  getCounterClaimsClaimants,
  fromSharesSelector.getDataRowsSharePicture,
  (claimants: CaseClaimant[], ownershipRows: any[]) => claimants && ownershipRows && CounterClaimUtils.joinSharesWithClaimants(ownershipRows, claimants, false),
);

export const getRepertoireRowsSharePictureWithClaimants = createSelector(
  getCounterClaimsClaimants,
  fromSharesSelector.getRepertoireRowsSharePicture,
  (claimants: CaseClaimant[], ownershipRows: any[]) => claimants && ownershipRows && CounterClaimUtils.joinSharesWithClaimants(ownershipRows, claimants, false),
);

export const getExtendedToP = createSelector(getCopyrightWork, (detail: WorkDetail) => (detail && WorkUtils.getExtendedToP(detail)) || []);

export const getClaimsActiveClaimants = createSelector(getClaims, (claims: any[]) => claims && ClaimsUtils.getClaimsActiveClaimants(claims));

export const getConflicts = createSelector(getCopyrightWork, (detail: WorkDetail) => detail && detail.conflicts);

export const getEditClaimId = createSelector(getCopyrightWork, (detail: WorkDetail) => detail && detail.editClaim);

export const getEditConflictId = createSelector(getCopyrightWork, (detail: WorkDetail) => detail && detail.editConflictId);

export const getFilterSharePicture = createSelector(getCopyrightWork, (detail: WorkDetail) => detail?.filterSharePicture);

export const getCounterClaimList = createSelector(getCopyrightWork, (detail: WorkDetail) => detail && detail.counterclaims);

export const getCounterclaimsCount = createSelector(getCopyrightWork, (detail: WorkDetail) => detail?.counterclaimsCount);

export const getUnresolvedCounterclaimsCount = createSelector(getCopyrightWork, (detail: WorkDetail) => detail?.unresolvedCounterclaimsCount);

export const getWorkHasCounterclaims = createSelector(getUnresolvedCounterclaimsCount, count => !!count);

export const getWorkActivity = createSelector(getCopyrightWork, (detail: WorkDetail) => detail && detail.activity);

export const getAllClaimsFilter = createSelector(getCopyrightWork, (detail: WorkDetail) => detail.allClaimsFilter);
