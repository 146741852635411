export const locale = {
  lang: 'en',
  data: {
    ERROR: {
      IS_REQUIRED_OR_IS_NOT_VALID: 'The field cannot be empty or the entry is incorrect',
      ALPHANUMERIC: 'This field should be alphanumeric',
      ONLYNUMBERS: 'This field should be numeric',
      INCORRECT: 'The field contains an incorrect value',
      DATATABLE: {
        NO_EMPTY_ROWS: 'There cannot be empty rows',
        NO_INVALID_VALUES: 'There cannot be invalid values',
      },
      HAS_DUPLICATED_VALUE: 'Duplicated values not accepted',
      COMBINATION_ALL_READY_EXISTS: 'The combination society/territory allready exists',
      HAS_MULTIPLE_SPACES: 'Multiple spaces not accepted',
      NOT_EXISTS: `The <<placeholder>> doesn't exist`,
      MAX_ALLOWED_EXCEDED: 'Max number allowed is exceded',
      MINIMUM_1_ITEM: 'You must select 1 item at least',
      ICE_KEY_CHARACTERS_ERROR: 'Pattern error: the valid character are alphanumeric and :',
    },
  },
};
