import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { buildOptions, DateTimeUtils } from '@ice/utils';
import { DatepickerUtils } from '@ice/utils/datepicker/datepicker.utils';
import { SocietiesUtils } from '@ice/utils/societies/societies.utils';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { countryGroups } from 'assets/ts/countries';
import { mrRights, prRights } from 'config/constants/ips.constants';
import { REPORTS_PAYMENT_OWNERSHIP, REPORTS_PAYMENT_PAYMENT, REPORTS_REPORTING_SOC } from 'config/constants/reports.constants';
import { DEFAULT_MR_RIGHT_TYPE, DEFAULT_PR_RIGHT_TYPE } from 'config/constants/shares.constants';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import { StepType } from '../../stepper-config';

const DEFAULT_FILTER_TERRITORY = '826';
export class ReportWorkDetailsScopeStep {
  private fieldDistributionDate: any;
  private usageDateSubscription: Subscription;

  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<any>,
    private fieldValidatorService: FieldValidatorService,
  ) {}

  onInitForm(field) {}
  onDestroyForm() {}

  getStep(translate: TranslateService): StepType {
    const today = DateTimeUtils.formatDate(moment());

    const usageName = 'usageDate';
    const usageLabel = this.translate.instant('REPORTS.IPS_SHARES.USAGE_DATE');
    const usageExtraValidators = {
      correctStartDate: {
        expression: c => {
          const controlValue = DateTimeUtils.getIndefiniteDate(c.value);
          return moment(controlValue ? controlValue : '2020-01-01', 'YYYY-MM-DD', true).isValid() || moment(controlValue ? controlValue : '20200101', 'YYYYMMDD', true).isValid();
        },
        message: translate.instant('AGREEMENTS.ERRORS.INVALID_DATE'),
      },
      dateBefore: {
        expression: control => {
          return !DateTimeUtils.checkDate({
            checkDate: DateTimeUtils.getIndefiniteDate(control.value),
            filter: {
              isBefore: ['2000-01-01'],
            },
          });
        },
        message: translate.instant('REPORTS.IPS_SHARES.ERROR_DATE_BEFORE_2000'),
      },
    };
    const usageRequired = true;
    const usageHooks = {
      onInit: field => {
        this.usageDateSubscription = field.formControl.valueChanges.subscribe(value => {
          if (this.fieldDistributionDate) {
            this.fieldDistributionDate.formControl.updateValueAndValidity();
          }
        });
      },
      onDestroy: () => {
        this.usageDateSubscription.unsubscribe();
      },
    };

    const distributionName = 'distributionDate';
    const distributionLabel = this.translate.instant('REPORTS.IPS_SHARES.DISTRIBUTION_DATE');
    const distributionExtraValidators = {
      correctStartDate: {
        expression: c => {
          const controlValue = DateTimeUtils.getIndefiniteDate(c.value);
          return moment(controlValue ? controlValue : '2020-01-01', 'YYYY-MM-DD', true).isValid() || moment(controlValue ? controlValue : '20200101', 'YYYYMMDD', true).isValid();
        },
        message: translate.instant('AGREEMENTS.ERRORS.INVALID_DATE'),
      },
      dateBefore2000: {
        expression: control => {
          return !DateTimeUtils.checkDate({
            checkDate: DateTimeUtils.getIndefiniteDate(control.value),
            filter: {
              isBefore: ['2000-01-01'],
            },
          });
        },
        message: translate.instant('REPORTS.IPS_SHARES.ERROR_DATE_BEFORE_2000'),
      },
      dateBefore: {
        expression: control => {
          return !DateTimeUtils.checkDate({
            checkDate: DateTimeUtils.getIndefiniteDate(control.value),
            filter: {
              isBefore: [control.parent.controls['usageDate'].value],
            },
          });
        },
        message: translate.instant('REPORTS.IPS_SHARES.ERROR_DISTRIBUTION_BEFORE_USAGE'),
      },
    };
    const distributionRequired = true;
    const distributionHooks = {
      onInit: field => {
        this.fieldDistributionDate = field;
      },
    };

    return {
      label: translate.instant('REPORTS.IPS_SHARES.TITLE_STEP_2'),
      formBuilder: [
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-1',
              key: 'country',
              type: 'ice-autocomplete-grouped',
              modelOptions: {
                updateOn: 'submit',
              },
              defaultValue: DEFAULT_FILTER_TERRITORY,
              templateOptions: {
                placeholder: this.translate.instant('REPORTS.IPS_SHARES.COUNTRY'),
                options: countryGroups,
                required: true,
                useCountryShortcuts: true,
                panelWidth: 'auto',
              },
            },
            DatepickerUtils.getDatepickerField({
              name: usageName,
              label: usageLabel,
              extraValidators: usageExtraValidators,
              required: usageRequired,
              translate,
              hooks: usageHooks,
              extraTemplateOptions: { popupForcePt: 5 },
            }),
            DatepickerUtils.getDatepickerField({
              name: distributionName,
              label: distributionLabel,
              extraValidators: distributionExtraValidators,
              required: distributionRequired,
              translate,
              hooks: distributionHooks,
              extraTemplateOptions: { popupForcePt: 5 },
            }),
            {
              key: 'reportingSociety',
              type: 'select',
              className: 'flex-1',
              templateOptions: {
                required: false,
                label: translate.instant('REPORTS.IPS_SHARES.REPORTING_SOCIETY'),
                options: REPORTS_REPORTING_SOC,
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-1',
              key: 'mrRightType',
              type: 'select',
              defaultValue: DEFAULT_MR_RIGHT_TYPE,
              templateOptions: {
                label: this.translate.instant('REPORTS.IPS_SHARES.MR_IPI'),
                options: buildOptions(mrRights),
                required: true,
              },
            },
            {
              className: 'flex-1',
              key: 'prRightType',
              type: 'select',
              defaultValue: DEFAULT_PR_RIGHT_TYPE,
              templateOptions: {
                label: this.translate.instant('REPORTS.IPS_SHARES.PR_IPI'),
                options: buildOptions(prRights),
                required: true,
              },
            },
            {
              className: 'flex-1',
              key: 'mrDistributionSociety',
              type: 'select',
              templateOptions: {
                label: this.translate.instant('REPORTS.IPS_SHARES.MR_DISTRIBUTING_SOCIETY'),
                options: [...SocietiesUtils.getFormattedSocietiesListCISAC(), { label: 'ALL Societies', value: 'ALL' }],
                required: true,
              },
            },
            {
              className: 'flex-1',
              key: 'prDistributionSociety',
              type: 'select',
              templateOptions: {
                label: this.translate.instant('REPORTS.IPS_SHARES.PR_DISTRIBUTING_SOCIETY'),
                options: [...SocietiesUtils.getFormattedSocietiesListCISAC(), { label: 'ALL Societies', value: 'ALL' }],
                required: true,
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-1',
              key: 'payment',
              type: 'select',
              defaultValue: REPORTS_PAYMENT_OWNERSHIP,
              templateOptions: {
                label: this.translate.instant('REPORTS.IPS_SHARES.PAYMENT'),
                options: [
                  { value: REPORTS_PAYMENT_OWNERSHIP, label: this.translate.instant('REPORTS.IPS_SHARES.OWNERSHIP_VALUE') },
                  { value: REPORTS_PAYMENT_PAYMENT, label: this.translate.instant('REPORTS.IPS_SHARES.PAYMENT_VALUE') },
                ],
                required: false,
              },
            },
            {
              className: 'flex-1',
              key: 'include',
              type: 'select',
              defaultValue: false,
              templateOptions: {
                label: this.translate.instant('REPORTS.IPS_SHARES.INCLUDE_ADDITIONAL'),
                options: [
                  { value: false, label: this.translate.instant('REPORTS.IPS_SHARES.NO_MIN') },
                  { value: true, label: this.translate.instant('REPORTS.IPS_SHARES.YES_MIN') },
                ],
                required: false,
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-5',
              key: 'includeRepertoireDetails',
              type: 'checkbox',
              defaultValue: false,
              templateOptions: {
                label: translate.instant('REPORTS.IPS_SHARES.INCLUDE_REPERTOIRE'),
                required: false,
              },
              hideExpression: model => model.payment !== REPORTS_PAYMENT_PAYMENT,
            },
            {
              className: 'flex-5',
              key: 'fiche',
              type: 'checkbox',
              defaultValue: false,
              templateOptions: {
                label: translate.instant('REPORTS.IPS_SHARES.INCLUDE_FICHE'),
                required: false,
              },
            },
            {
              className: 'flex-5',
              key: 'includePerformerInformation',
              type: 'checkbox',
              defaultValue: true,
              templateOptions: {
                label: translate.instant('REPORTS.IPS_SHARES.INCLUDE_PERFORMER_INFORMATION'),
                required: true,
              },
            },
          ],
        },
      ],
    };
  }
}
