import { HttpStatusCode } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ClaimantUtils } from '@ice';
import { DataTableRow } from '@ice/components/data-table/data-table';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { AnalysisAndComparisons } from '@ice/dynamic-components/analysis-and-comparisons/analysis-and-comparisons';
import { FormsAndTables } from '@ice/dynamic-components/forms-and-tables/forms-and-tables';
import { IceGroupComponent, IceLayout } from '@ice/dynamic-components/group-component/group-component';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import * as fromApiCalls from 'config/api-calls';
import { ActionStatus, ReducedActionResponses, WithDrawActionTypes } from 'config/constants/activity.constants';
import { CounterClaimActionDialog } from 'config/constants/counter-claims.constants';
import { CopyrightClaimsDataTable } from 'config/data-table-builders/copyright.claims';
import { DialogCounterClaimActions } from 'config/dialog-builders/dialog-counter-claim-actions';
import { SectionTabConfig } from 'config/tabs-data-builders/tabs-data';
import { IceFacade } from 'facades/ice.facade';
import { get } from 'lodash';
import { BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonApiService } from 'services/common-api.service';
import { FeatureFlagService } from 'services/feature-flags/feature-flags-flagsmith.service';
import { NamespaceService } from 'services/namespace/namespace.service';
import { PermissionsService } from 'services/permissions/permissions.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';
import { errorMessageShowtime } from 'store/root/effects/ui/show-error';

export class TabCounterClaimsActions implements SectionTabConfig {
  private dialogCounterClaimActionsBuilder: DialogCounterClaimActions;
  private schema: CopyrightClaimsDataTable;
  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private dialog: MatDialog,
    private commonApiService: CommonApiService,
    private featureFlagService: FeatureFlagService,
    private namespaceService: NamespaceService,
    private iceFacade: IceFacade,
    private fieldValidatorService: FieldValidatorService,
    private permissionsService: PermissionsService,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.schema = new CopyrightClaimsDataTable(this.translate, this.fuseTranslationLoader, this.store, this.permissionsService);
    this.dialogCounterClaimActionsBuilder = new DialogCounterClaimActions(translate, store, dialog, this.schema, commonApiService, this.featureFlagService);
  }
  private dialogRef: MatDialogRef<DialogMultiLayoutComponent, any>;

  getConf(): IceGroupComponent[] | AnalysisAndComparisons | FormsAndTables {
    return [
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('COUNTER.ACTION_TABLE.TITLE'),
              model: this.store
                .select(fromRoot.getCounterClaimWork)
                .pipe(map(counterClaimWork => ClaimantUtils.translateActionsList(get(counterClaimWork, 'actionsList', []), this.translate))),
              sorts: [],
              schema: this.getActionsSchema(),
              loadingIndicator: false,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: true,
              shadowed: false,
              columnMode: 'flex',
            },
          },
        ],
      },
    ];
  }

  getActionsSchema(): DataTableRow[] {
    return [
      {
        name: this.translate.instant('COUNTER.ACTION_TABLE.ACTION'),
        prop: 'action',
        flexGrow: 1,
        headerTooltip: ' ',
      },
      {
        name: this.translate.instant('COUNTER.ACTION_TABLE.ASSIGNED_USER'),
        prop: 'user',
        flexGrow: 1,
        headerTooltip: ' ',
      },
      {
        name: this.translate.instant('COUNTER.ACTION_TABLE.RESOLVER_NAME'),
        prop: 'resolverName',
        flexGrow: 1,
        headerTooltip: ' ',
      },
      {
        name: this.translate.instant('COUNTER.ACTION_TABLE.IPI_NAME_NUMBER'),
        prop: 'ipiNameNumber',
        flexGrow: 1,
        headerTooltip: ' ',
      },
      {
        name: this.translate.instant('COUNTER.ACTION_TABLE.STATUS'),
        prop: 'status',
        flexGrow: 1,
        headerTooltip: ' ',
      },
      {
        name: this.translate.instant('COUNTER.ACTION_TABLE.DEADLINE'),
        prop: 'deadline',
        flexGrow: 1,
        headerTooltip: ' ',
      },
      {
        name: this.translate.instant('CLAIMS.INFO'),
        prop: 'viewInfo',
        flexGrow: 2,
        hideActionButton: row => of(!row.id || (!ReducedActionResponses.includes(get(row, 'response', '')) && !WithDrawActionTypes.includes(get(row, 'type', '')))),
        actionButtonIcon: 'remove_red_eye',
        tooltip: 'actionButtonTooltip',
        canHideTooltip: true,
        action: row => this.dialogCounterClaimActionsBuilder.openDialog(CounterClaimActionDialog.View, row),
        headerTooltip: ' ',
      },
      {
        name: this.translate.instant('COUNTER.ACTION_TABLE.CLOSE'),
        actionButtonIcon: 'close',
        sortable: false,
        prop: 'close',
        flexGrow: 0.5,
        hideActionButton: row => of(!ClaimantUtils.isIceAction(row) || row.status.toUpperCase() === ActionStatus.CLOSED),
        action: row => {
          this.dialogRef = this.dialog.open(DialogMultiLayoutComponent, {
            data: {
              loading: this.store.pipe(select(fromRoot.getCopyrightLoading)),
              layouts: [this.closeActionDialogLayout(row)],
            },
          });
        },
        headerTooltip: ' ',
      },
    ];
  }

  closeActionDialogLayout(action): IceLayout {
    const responseStatus$ = new BehaviorSubject(null);
    return {
      title: of(this.translate.instant('COUNTER.CLOSE_ACTION_DIALOG.TITLE')),
      actions: [],
      className: 'dialog-wrapper-auto',
      layout: [
        {
          group: [
            {
              type: 'response-error',
              config: {
                response: responseStatus$.pipe(
                  map(responseStatus => {
                    return { text: responseStatus.text };
                  }),
                ),
                errorButtons: of([]),
                responseButtons: this.getResponseButtons(action, responseStatus$),
                errors: null,
              },
            },
          ],
        },
      ],
    };
  }

  private getResponseButtons(action, responseStatus$: BehaviorSubject<any>) {
    responseStatus$.next({ text: this.translate.instant('COUNTER.CLOSE_ACTION_DIALOG.MESSAGE'), error: false });
    return responseStatus$.pipe(
      map(responseStatus => {
        if (responseStatus.error) {
          return [
            {
              text: of(this.translate.instant('POPUP.CLOSE')),
              action: () => {
                this.dialogRef.close();
              },
            },
          ];
        } else {
          return [
            {
              text: of(this.translate.instant('POPUP.YES')),
              class: 'ok-button',
              action: () => {
                const response = ClaimantUtils.getResponseToCloseAction(action.type);
                const body = JSON.stringify({
                  response,
                  actions: [
                    {
                      id: action.id,
                    },
                  ],
                });
                this.store.dispatch(
                  new fromRoot.StartApiCall({
                    apiCall: fromApiCalls.updateCounterclaim,
                    apiCallData: { body },
                    callBack: res => this.afterUpdateCounterclaim(res, responseStatus$),
                  }),
                );
              },
            },
            {
              text: of(this.translate.instant('POPUP.NO')),
              action: () => this.dialogRef.close(),
            },
          ];
        }
      }),
    );
  }

  afterUpdateCounterclaim(response, responseStatus$) {
    if ([HttpStatusCode.BadRequest, HttpStatusCode.InternalServerError].includes(response?.status) || response?.error) {
      if (response?.error?.message) {
        responseStatus$.next({ text: response.error.message, error: true });
      } else {
        responseStatus$.next({ text: this.translate.instant('COUNTER.CLOSE_ACTION_DIALOG.ERROR'), error: true });
      }
    } else {
      this.store.dispatch(
        new fromRoot.ShowSnackBar({
          icon: 'done',
          message: this.translate.instant('COUNTER.CLOSE_ACTION_DIALOG.SUCCESS'),
          duration: errorMessageShowtime.normal,
          position: {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          },
        }),
      );
      this.dialogRef.close();
      this.store.dispatch(new fromRoot.RefreshDetail());
    }
  }
}
