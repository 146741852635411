import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CopyrightUtils, FieldPermissions, TabsUtils } from '@ice';
import { DataTableRow } from '@ice/components/data-table/data-table';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DialogInfo } from 'config/dialog-builders/dialog-info';
import { IceFacade } from 'facades/ice.facade';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';
import { CommonApiService } from 'services/common-api.service';
import { DetailService } from 'services/detail/detail.service';
import { NamespaceService } from 'services/namespace/namespace.service';
import { PermissionsService } from 'services/permissions/permissions.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';
import { TabExtProtection } from './shared/tab-ext-protection';

export class TabExtendedToP extends TabExtProtection {
  // permissions
  private canEditTermsOfProtection = false;

  constructor(
    protected translate: TranslateService,
    protected fuseTranslationLoader: FuseTranslationLoaderService,
    protected store: Store<fromRoot.RootState>,
    protected dialog: MatDialog,
    protected commonApiService: CommonApiService,
    protected detailService: DetailService,
    protected nsService: NamespaceService,
    protected iceFacade: IceFacade,
    protected fieldValidatorService: FieldValidatorService,
    protected permissionsService: PermissionsService,
  ) {
    super(translate, fuseTranslationLoader, store, dialog, commonApiService, detailService, nsService, iceFacade, fieldValidatorService, permissionsService);
    this.subscriptionEditMode = this.store.pipe(select(fromRoot.getEditMode)).subscribe((editMode: boolean) => {
      if (editMode) {
        this.canEditTermsOfProtection = this.permissionsService.can('works_terms_of_protection_works') || this.permissionsService.can('works_society_attributes');
      }
    });
  }

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('WORKS.EXTENDED_TOP.TABLE_TITLE'),
              model: this.store.pipe(select(fromRoot.getExtendedToP)),
              columnMode: 'flex',
              schema: this.getDataTable(),
              tableWidth: '100',
              sorts: [],
              actionButtons: this.store.pipe(
                select(fromRoot.getEditMode),
                distinctUntilChanged(),
                withLatestFrom(this.store.select(fromRoot.getCopyrightPermissionsBySection)),
                map(([editMode, permissions]: [boolean, FieldPermissions]) => {
                  if (editMode && this.canEditTermsOfProtection && permissions && CopyrightUtils.getGranularPermission(permissions, 'copyrightOverrides')) {
                    return [
                      {
                        icon: 'add',
                        tooltip: this.translate.instant('WORKS.EXTENDED_TOP.ADD_EXTENDED_ToP'),
                        class: 'mat-white-icon',
                        onClick: () => this.openTermsOfProtectionDialog(),
                      },
                    ];
                  } else {
                    return null;
                  }
                }),
              ),
              visibleColumns: this.getVisibleColumnsExtTop(this.getDataTable()),
            },
          },
        ],
      },
    ];
  }

  getDataTable(): DataTableRow[] {
    return [
      {
        name: this.translate.instant('WORKS.EXTENDED_TOP.SOCIETY'),
        prop: 'societyIds',
        flexGrow: 1,
        cellClass: 'ice-txt-size-14',
      },
      {
        name: this.translate.instant('WORKS.EXTENDED_TOP.COUNTRY'),
        prop: 'territories.inExTisns',
        flexGrow: 3,
        cellClass: 'ice-txt-size-14',
      },
      {
        name: this.translate.instant('WORKS.EXTENDED_TOP.DATE'),
        prop: 'protectionDate',
        flexGrow: 1,
        cellClass: 'ice-txt-size-14',
      },
      {
        name: this.translate.instant('WORKS.EXTENDED_TOP.IP_ROLE'),
        prop: 'role',
        flexGrow: 1,
        cellClass: 'ice-txt-size-14',
      },
      {
        name: this.translate.instant('WORKS.EXTENDED_TOP.NAME'),
        prop: 'name',
        flexGrow: 3,
        cellClass: 'ice-txt-size-14',
      },
      {
        name: this.translate.instant('WORKS.EXTENDED_TOP.IPI_NAME_NUMBER'),
        prop: 'ipiNameNumber',
        flexGrow: 1,
        cellClass: 'ice-txt-size-14',
      },
      {
        name: '',
        prop: 'deleteBtn',
        actionButtonIcon: 'delete',
        flexGrow: 0.001,
        maxWidth: 50,
        minWidth: 50,
        resizeable: false,
        hideActionButton: row =>
          this.store.pipe(
            select(fromRoot.getEditMode),
            distinctUntilChanged(),
            withLatestFrom(this.store.select(fromRoot.getCopyrightPermissionsBySection)),
            map(([editMode, permissions]: [boolean, FieldPermissions]) => {
              if (editMode && this.canEditTermsOfProtection && permissions) {
                return !CopyrightUtils.getGranularPermission(permissions, 'copyrightOverrides');
              }
              return true;
            }),
          ),
        action: row => {
          const dialogInfo = new DialogInfo(this.translate, this.dialog, this.store);
          const dialogRefInfo = dialogInfo.openDialog(
            this.translate.instant('WORKS.EXTENDED_TOP.REMOVE_PROTECTION'),
            this.translate.instant('WORKS.EXTENDED_TOP.CHECK_DELETE'),
            () => {
              this.store.dispatch(new fromRoot.UpdateField({ object: 'ExtProtection', newValue: row, type: 'delete' }));
              this.dialog.closeAll();
            },
            () => dialogRefInfo.close(),
            this.translate.instant('NOTES.BUTTONS.REMOVE'),
            this.translate.instant('NOTES.BUTTONS.CANCEL'),
          );
        },
      },
    ];
  }

  private getVisibleColumnsExtTop(schema: any): Observable<string[]> {
    const schemaDatatable = TabsUtils.getSChemaPropsToArray(schema);

    return this.store.pipe(
      select(fromRoot.getEditMode),
      map(editMode => {
        if (editMode) {
          return schemaDatatable;
        } else {
          return TabsUtils.getSchemaFiltered(schemaDatatable, ['deleteBtn']);
        }
      }),
    );
  }
}
