import { SearchUtils } from '@ice/utils/search/search.utils';
import { ActionsSearchExpressions } from 'config/search-form-builders/search-conflicts-actions';
import { createLabelResolver } from '../api-call.factory';

export const getAttributesId = createLabelResolver({ id: `id` });

export const getWorkId = createLabelResolver({ workId: 'workId' });

export const getActionsForDashboard = createLabelResolver({
  dashboardFilterExpression: detail => {
    if (detail.dashboardSearch?.filter) {
      return JSON.stringify(SearchUtils.generateExpressionCubeData(detail.dashboardSearch.filter, new ActionsSearchExpressions()));
    }
    return ' ';
  },
});
