import { RightTypes } from 'config/constants/shares.constants';
import Decimal from 'decimal.js';
import { find, some } from 'lodash';

export class AdditionalFieldsValidator {
  static writerMechanicalReasonHideExpression(model, field?) {
    let isHidden = true;
    const codesToValidate = model?.assignor?.assignor_society_code ? model.assignor.assignor_society_code.replace(/[()]/g, '').split(', ') : null;
    if (codesToValidate) {
      const shares = model.shares;
      const isMoreThan75 = some(shares, share => share.type === RightTypes.MR && new Decimal(share?.publisher || 0).plus(new Decimal(share?.assignee || 0)).comparedTo(75) > -1);
      const codes = [
        '001',
        '003',
        '009',
        '116',
        '035',
        '040',
        '048',
        '110',
        '050',
        '055',
        '058',
        '079',
        '080',
        '089',
        '090',
        '097',
        '005',
        '011',
        '122',
        '023',
        '078',
        '094',
        '072',
        '074',
        '115',
      ];
      const codeValidated = find(codes, code => find(codesToValidate, codeToValidate => code === codeToValidate));
      isHidden = !(codeValidated && isMoreThan75);
    }
    if (field) {
      if (isHidden) {
        field.formControl.setValue(null);
      }
      field.formControl.updateValueAndValidity();
    }
    return isHidden;
  }
}
