import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '@ice/i18n/en/search-bar';

@Component({
  selector: 'ice-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBarComponent {
  @Input() collapsedAdvanced = true;
  @Input() collapsedExpert = true;
  @Input() searchTerm = '';
  @Input() section;
  @Input() disableNormalSearch = false;
  @Output() searchEmitter: EventEmitter<any> = new EventEmitter();
  @Output() resetEmitter: EventEmitter<void> = new EventEmitter();
  @Output() collapsedEmitter: EventEmitter<boolean> = new EventEmitter();
  @Output() advancedCollapsedEmitter: EventEmitter<void> = new EventEmitter();
  @Output() expertCollapsedEmitter: EventEmitter<void> = new EventEmitter();
  @ViewChild('term', { static: true })
  termEl;

  constructor(private fuseTranslationLoader: FuseTranslationLoaderService) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  focus() {
    if (!!this.termEl?.value) {
      this.termEl.nativeElement.focus();
    }
  }

  search(term: string) {
    this.searchEmitter.emit(term);
  }

  collapse(value: boolean) {
    this.collapsedEmitter.emit(value);
  }

  collapseAdvanced() {
    this.advancedCollapsedEmitter.emit();
  }

  collapseExpert() {
    this.expertCollapsedEmitter.emit();
  }

  resetSearch() {
    this.resetEmitter.emit();
  }
}
