import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DataTableRow } from '@ice/components/data-table/data-table';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import { SectionsConfig } from 'config/sections-config';
import { IceFacade } from 'facades/ice.facade';
import { CommonApiService } from 'services/common-api.service';
import { DetailService } from 'services/detail/detail.service';
import { NamespaceService } from 'services/namespace/namespace.service';
import * as fromRoot from 'store/root';
import { SectionTabConfig } from '../../tabs-data';

export class TabTerritoryDetail implements SectionTabConfig {
  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private dialog: MatDialog,
    private commonApiService: CommonApiService,
    private detailService: DetailService,
    private nsService: NamespaceService,
    private iceFacade: IceFacade,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'cardWithForm',
            config: {
              title: this.translate.instant('TERRITORIES.DETAILS.CARD_WITH_FORM.CARD_TITLE'),
              model: this.iceFacade.territories.getSelectedTerritoryDetail(),
              resetAvailable: false,
              submitAvailable: false,
              formBuilder: this.getFormly(),
            },
          },
        ],
      },
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('TERRITORIES.DETAILS.CARD_LANGUAGE_NAMES.TAB_TITLE'),
              model: this.iceFacade.territories.getTerritoryLanguageNames(),
              schema: this.getLanguageNamesSchema(),
            },
          },
        ],
      },
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('TERRITORIES.DETAILS.CARD_TITLES.INC_COUNTRIES'),
              contentClass: 'included-territories',
              model: this.iceFacade.territories.getTerritoryIncludedCountriesFilteredByDate(),
              schema: this.getTableSchema(),
              onSelect: (event: any[]) => {
                this.iceFacade.dispatch(
                  new fromRoot.Go({
                    path: this.GetTerritoriesTisnPath(event[0]),
                  }),
                );
              },
              onMouseSelect: event => {
                this.iceFacade.dispatch(
                  new fromRoot.OpenNewTab({
                    path: this.GetTerritoriesTisnPath(event),
                  }),
                );
              },
            },
          },
        ],
      },
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('TERRITORIES.DETAILS.CARD_TITLES.PRED_TERRITORIES'),
              cardHeight: '180px',
              model: this.iceFacade.territories.getTerritoryPredecessors(),
              schema: this.getTableSchema(true),
              onSelect: (event: any) => {
                this.iceFacade.dispatch(
                  new fromRoot.Go({
                    path: this.GetTerritoriesTisnPath(event[0]),
                  }),
                );
              },
              onMouseSelect: event => {
                this.iceFacade.dispatch(
                  new fromRoot.OpenNewTab({
                    path: this.GetTerritoriesTisnPath(event),
                  }),
                );
              },
            },
          },
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('TERRITORIES.DETAILS.CARD_TITLES.SUC_TERRITORIES'),
              cardHeight: '180px',
              model: this.iceFacade.territories.getTerritorySuccessors(),
              schema: this.getTableSchema(true),
              onSelect: (event: any[]) => {
                this.iceFacade.dispatch(
                  new fromRoot.Go({
                    path: this.GetTerritoriesTisnPath(event[0]),
                  }),
                );
              },
              onMouseSelect: event => {
                this.iceFacade.dispatch(
                  new fromRoot.OpenNewTab({
                    path: this.GetTerritoriesTisnPath(event),
                  }),
                );
              },
            },
          },
        ],
      },
    ];
  }

  private getTableSchema(terminationDate?) {
    const tableSchema = [
      {
        name: this.translate.instant('TERRITORIES.DETAILS.CARD_WITH_FORM.FORM.NAME'),
        prop: 'name',
        colWidth: 18,
        cellClass: 'territory-name',
      },
      {
        name: this.translate.instant('TERRITORIES.DETAILS.CARD_WITH_FORM.FORM.TISA'),
        prop: 'tisa',
        colWidth: 18,
        cellClass: 'territory-tisa',
      },
      {
        name: this.translate.instant('TERRITORIES.DETAILS.CARD_WITH_FORM.FORM.TISN'),
        prop: 'tisn',
        colWidth: 18,
        cellClass: 'territory-tisn',
      },
      {
        name: this.translate.instant('TERRITORIES.DETAILS.CARD_WITH_FORM.FORM.CREATION_DATE'),
        prop: 'start',
        colWidth: 18,
        cellClass: 'territory-creation-date',
      },
    ];
    if (terminationDate) {
      tableSchema.push({
        name: this.translate.instant('TERRITORIES.DETAILS.CARD_WITH_FORM.FORM.TERMINATION_DATE'),
        prop: 'end',
        colWidth: 18,
        cellClass: 'territory-creation-date',
      });
    }
    return tableSchema;
  }

  private getFormly() {
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-2',
            key: 'name',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('TERRITORIES.DETAILS.CARD_WITH_FORM.FORM.NAME'), required: false, disabled: true },
          },
          {
            className: 'flex-2',
            key: 'tisaExt',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('TERRITORIES.DETAILS.CARD_WITH_FORM.FORM.TISAEXT'), required: false, disabled: true },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-2',
            key: 'tisa',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('TERRITORIES.DETAILS.CARD_WITH_FORM.FORM.TISA'), required: false, disabled: true },
          },
          {
            className: 'flex-2',
            key: 'tisn',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('TERRITORIES.DETAILS.CARD_WITH_FORM.FORM.TISN'), required: false, disabled: true },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-2',
            key: 'start',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('TERRITORIES.DETAILS.CARD_WITH_FORM.FORM.CREATION_DATE'), required: false, disabled: true },
          },
          {
            className: 'flex-2',
            key: 'end',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('TERRITORIES.DETAILS.CARD_WITH_FORM.FORM.TERMINATION_DATE'), required: false, disabled: true },
          },
        ],
      },
    ];
  }

  private GetTerritoriesTisnPath(event): string[] {
    return [`copyright/${SectionsConfig.TERRITORIES.name}/${event.tisn}/details`];
  }

  private getLanguageNamesSchema(): DataTableRow[] {
    const cardName = 'CARD_LANGUAGE_NAMES';
    return [
      { name: this.getTranslate(cardName, 'LANGUAGE'), prop: 'language', flexGrow: 1 },
      { name: this.getTranslate(cardName, 'NAME'), prop: 'name', flexGrow: 2 },
      { name: this.getTranslate(cardName, 'ABBREVIATED_NAME'), prop: 'abbreviatedName', flexGrow: 2 },
      { name: this.getTranslate(cardName, 'OFFICIAL_NAME'), prop: 'officialName', flexGrow: 3 },
      { name: this.getTranslate(cardName, 'UNOFFICIAL_NAME'), prop: 'unofficialName', flexGrow: 2 },
      { name: this.getTranslate(cardName, 'START'), prop: 'start', flexGrow: 1 },
      { name: this.getTranslate(cardName, 'END'), prop: 'end', flexGrow: 1 },
    ];
  }

  private getTranslate(cardTranslatePath, fieldTranslatePath) {
    return this.translate.instant(`TERRITORIES.DETAILS.${cardTranslatePath}.${fieldTranslatePath}`);
  }
}
