import { CounterClaimUtils } from '@ice';
import { createSelector } from '@ngrx/store';
import { CounterClaimDetail } from 'models/copyright/detail/counterclaim';
import * as fromFeature from 'store/root/reducers';
import * as fromReducer from 'store/root/reducers/copyright/copyright.reducer';

export const getCounterClaimWork = createSelector(fromFeature.getCopyrightFeatureState, fromReducer.getCounterClaimsState);

export const getCounterClaimDashboardSearch = createSelector(getCounterClaimWork, (detail: CounterClaimDetail) => detail?.dashboardSearch || {});

export const getCounterClaimAuditHistory = createSelector(getCounterClaimWork, (detail: CounterClaimDetail) => detail?.auditHistory || []);

export const getCounterClaimOtherCounterClaims = createSelector(getCounterClaimWork, (detail: CounterClaimDetail) => detail?.otherCounterClaims?.items || []);

export const getCounterClaimDocuments = createSelector(getCounterClaimWork, (detail: CounterClaimDetail) => (detail && CounterClaimUtils.getDocuments(detail)) || []);

export const getCounterClaimDownloadDocumentMode = createSelector(getCounterClaimWork, (detail: CounterClaimDetail) => (detail && detail.downloadDocumentMode) || false);

export const getCounterClaimDocumentsToDownload = createSelector(getCounterClaimWork, (detail: CounterClaimDetail) => (detail && detail.documentsToDownload) || []);

export const getCounterClaimXREF = createSelector(getCounterClaimWork, (detail: CounterClaimDetail) => (detail && detail.workXREF) || []);

export const getCounterClaimWorkTitles = createSelector(getCounterClaimWork, (detail: CounterClaimDetail) => (detail && detail.workTitles) || []);

export const getCounterClaimRejectDocumentMode = createSelector(getCounterClaimWork, (detail: CounterClaimDetail) => (detail && detail.rejectDocumentMode) || false);

export const getCounterClaimDocumentsToReject = createSelector(getCounterClaimWork, (detail: CounterClaimDetail) => (detail && detail.documentsToReject) || []);
