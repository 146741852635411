import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ice-extended-work-selector',
  templateUrl: './extended-work-selector.component.html',
  styleUrls: ['./extended-work-selector.component.scss'],
})
export class ExtendedWorkSelectorComponent {
  @Input() options: any[];
  @Input() extendedWorkSelectorTitle: BehaviorSubject<string>;
  @Input() extendedWorkSelectorPlaceHolder: BehaviorSubject<string>;
  @Input() selected: string;
  @Output() select: EventEmitter<any> = new EventEmitter<any>();

  constructor(private translate: TranslateService) {}

  onSelectOption(data: any) {
    this.select.emit({ value: data.value });
  }
}
