import { MOCK_SHAREPIC } from 'config/constants/shares.constants';

export const ReportShareFormatter = {
  formatter: item => {
    const { reportType } = item;
    return { ...MOCK_SHAREPIC, reportType };
  },
  parseClone: item => {
    return item;
  },
};
