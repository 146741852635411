import { environment } from 'config/env';
import { SectionRouterViews } from './section-router-views';
import type { SectionConfig } from './sections-config';

export const SectionUserManagementOrganizations: SectionConfig = {
  name: 'organizations',
  url: '/user-management/organizations',
  domainName: 'user-management',
  baseUrl: environment.apiUrl,
  baseUrlCubeData: environment.apiUrlCubeData,
  apiSegment: 'organizations',
  detailSegment: `organizations/<<ns>>/<<id>>`,
  minCharsToSearch: 3,
  apiIncludes: {
    detail: 'attributes,roles, tags',
    search: 'attributes.name,roles',
  },
  searchServerSideSortDefault: 'attributes.name',
  newItemBtn: [SectionRouterViews.home, SectionRouterViews.search],
  allowedActions: {
    newItem: true,
    editItem: 'stepper',
    newNote: false,
    removeItem: true,
  },
  hideHomeEditButton: true,
};
