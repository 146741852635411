export const locale = {
  lang: 'en',
  data: {
    INSTRUMENT: {
      ACC: { DESCRIPTION: 'Accordion', INSTRUMENT_FAMILY: 'Keyboard' },

      ACL: { DESCRIPTION: 'Alto Clarinet', INSTRUMENT_FAMILY: 'Woodwind' },

      AFL: { DESCRIPTION: 'Alto Flute', INSTRUMENT_FAMILY: 'Woodwind' },

      AHN: { DESCRIPTION: 'Alto Horn', INSTRUMENT_FAMILY: 'Brass' },

      ALP: { DESCRIPTION: 'Alp Horn', INSTRUMENT_FAMILY: 'National/Folk' },

      ALT: { DESCRIPTION: 'Alto Voice', INSTRUMENT_FAMILY: 'Voice' },

      AMP: { DESCRIPTION: 'Amplifier', INSTRUMENT_FAMILY: 'Electronic' },

      ARC: { DESCRIPTION: 'Alto Recorder', INSTRUMENT_FAMILY: 'Early Music' },

      ASX: { DESCRIPTION: 'Alto Saxophone', INSTRUMENT_FAMILY: 'Woodwind' },

      BAG: { DESCRIPTION: 'Bagpipes', INSTRUMENT_FAMILY: 'National/Folk' },

      BAR: { DESCRIPTION: 'Baritone Horn', INSTRUMENT_FAMILY: 'Brass' },

      BAS: { DESCRIPTION: 'Double Bass', INSTRUMENT_FAMILY: 'String' },

      BBF: { DESCRIPTION: 'Bamboo Flute', INSTRUMENT_FAMILY: 'National/Folk' },

      BBT: { DESCRIPTION: 'Bass Baritone', INSTRUMENT_FAMILY: 'Voice' },

      BCL: { DESCRIPTION: 'Bass Clarinet', INSTRUMENT_FAMILY: 'Woodwind' },

      BDN: { DESCRIPTION: 'Bandoneon', INSTRUMENT_FAMILY: 'Keyboard' },

      BDR: { DESCRIPTION: 'Bass Drum', INSTRUMENT_FAMILY: 'Percussion' },

      BEL: { DESCRIPTION: 'Bells', INSTRUMENT_FAMILY: 'Percussion' },

      BFT: { DESCRIPTION: 'Bass Flute', INSTRUMENT_FAMILY: 'Woodwind' },

      BGL: { DESCRIPTION: 'Bugle', INSTRUMENT_FAMILY: 'Brass' },

      BGT: { DESCRIPTION: 'Bass Guitar', INSTRUMENT_FAMILY: 'String' },

      BHN: { DESCRIPTION: 'Basset Horn', INSTRUMENT_FAMILY: 'Woodwind' },

      BKA: { DESCRIPTION: 'Balalaika', INSTRUMENT_FAMILY: 'National/Folk' },

      BNG: { DESCRIPTION: 'Bongos', INSTRUMENT_FAMILY: 'Percussion' },

      BNJ: { DESCRIPTION: 'Banjo', INSTRUMENT_FAMILY: 'String' },

      BOB: { DESCRIPTION: 'Bass Oboe', INSTRUMENT_FAMILY: 'Woodwind' },

      BOY: { DESCRIPTION: 'Boy Soprano', INSTRUMENT_FAMILY: 'Voice' },

      BQF: { DESCRIPTION: 'Baroque Flute', INSTRUMENT_FAMILY: 'Early Music' },

      BRC: { DESCRIPTION: 'Bass Recorder', INSTRUMENT_FAMILY: 'Early Music' },

      BRT: { DESCRIPTION: 'Bass Trombone', INSTRUMENT_FAMILY: 'Brass' },

      BSN: { DESCRIPTION: 'Bassoon', INSTRUMENT_FAMILY: 'Woodwind' },

      BSP: { DESCRIPTION: 'Bass Saxophone', INSTRUMENT_FAMILY: 'Woodwind' },

      BSS: { DESCRIPTION: 'Bass Voice', INSTRUMENT_FAMILY: 'Voice' },

      BSX: { DESCRIPTION: 'Baritone Saxophone', INSTRUMENT_FAMILY: 'Woodwind' },

      BTN: { DESCRIPTION: 'Baritone Voice', INSTRUMENT_FAMILY: 'Voice' },

      CAL: { DESCRIPTION: 'Contralto Voice', INSTRUMENT_FAMILY: 'Voice' },

      CAR: { DESCRIPTION: 'Carillon', INSTRUMENT_FAMILY: 'Percussion' },

      CBC: { DESCRIPTION: 'Contrabass Clarinet', INSTRUMENT_FAMILY: 'Woodwind' },

      CBN: { DESCRIPTION: 'Contra Bassoon', INSTRUMENT_FAMILY: 'Woodwind' },

      CCL: { DESCRIPTION: 'Contralto Clarinet', INSTRUMENT_FAMILY: 'Woodwind' },

      CEL: { DESCRIPTION: 'Celesta', INSTRUMENT_FAMILY: 'Keyboard' },

      CHM: { DESCRIPTION: 'Chimes', INSTRUMENT_FAMILY: 'Percussion' },

      CIM: { DESCRIPTION: 'Cimbalom', INSTRUMENT_FAMILY: 'National/Folk' },

      CLR: { DESCRIPTION: 'Clarinet', INSTRUMENT_FAMILY: 'Woodwind' },

      CNB: { DESCRIPTION: 'Concertina', INSTRUMENT_FAMILY: 'Misc.' },

      CNG: { DESCRIPTION: 'Congas', INSTRUMENT_FAMILY: 'Percussion' },

      CNT: { DESCRIPTION: 'Cornet', INSTRUMENT_FAMILY: 'Brass' },

      COM: { DESCRIPTION: 'Computer', INSTRUMENT_FAMILY: 'Electronic' },

      CST: { DESCRIPTION: 'Castanets', INSTRUMENT_FAMILY: 'Percussion' },

      CTN: { DESCRIPTION: 'Countertenor Voice', INSTRUMENT_FAMILY: 'Voice' },

      CVD: { DESCRIPTION: 'Clavichord', INSTRUMENT_FAMILY: 'Keyboard' },

      CYM: { DESCRIPTION: 'Cymbals', INSTRUMENT_FAMILY: 'Percussion' },

      DIJ: { DESCRIPTION: 'Didjeridu', INSTRUMENT_FAMILY: 'National/Folk' },

      DIZ: { DESCRIPTION: 'Dizi/D\\çTzu', INSTRUMENT_FAMILY: 'National/Folk' },

      DJM: { DESCRIPTION: 'Djembe', INSTRUMENT_FAMILY: 'National/Folk' },

      DRK: { DESCRIPTION: 'Drum Kit/Drum Set', INSTRUMENT_FAMILY: 'Percussion' },

      DRM: { DESCRIPTION: 'Drum', INSTRUMENT_FAMILY: 'Percussion' },

      DUL: { DESCRIPTION: 'Dulcimer', INSTRUMENT_FAMILY: 'National/Folk' },

      EBG: { DESCRIPTION: 'Electric Bass Guitar', INSTRUMENT_FAMILY: 'String' },

      EFC: { DESCRIPTION: 'E-Flat Clarinet', INSTRUMENT_FAMILY: 'Woodwind' },

      EGT: { DESCRIPTION: 'Electric Guitar', INSTRUMENT_FAMILY: 'String' },

      EHN: { DESCRIPTION: 'English Horn', INSTRUMENT_FAMILY: 'Woodwind' },

      ELL: { DESCRIPTION: 'Electronics, Live', INSTRUMENT_FAMILY: 'Electronic' },

      ELP: { DESCRIPTION: 'Electronics, Pre-recorded', INSTRUMENT_FAMILY: 'Electronic' },

      EOG: { DESCRIPTION: 'Electronic Organ', INSTRUMENT_FAMILY: 'Keyboard' },

      ERH: { DESCRIPTION: 'Erhu', INSTRUMENT_FAMILY: 'National/Folk' },

      EUP: { DESCRIPTION: 'Euphonium', INSTRUMENT_FAMILY: 'Brass' },

      FLG: { DESCRIPTION: 'Flugelhorn', INSTRUMENT_FAMILY: 'Brass' },

      FLT: { DESCRIPTION: 'Flute', INSTRUMENT_FAMILY: 'Woodwind' },

      FRN: { DESCRIPTION: 'French Horn', INSTRUMENT_FAMILY: 'Brass' },

      GHM: { DESCRIPTION: 'Glass Harmonica', INSTRUMENT_FAMILY: 'Misc.' },

      GHP: { DESCRIPTION: 'Glass Harp', INSTRUMENT_FAMILY: 'Misc.' },

      GLS: { DESCRIPTION: 'Glockenspiel', INSTRUMENT_FAMILY: 'Percussion' },

      GNG: { DESCRIPTION: 'Gong', INSTRUMENT_FAMILY: 'Percussion' },

      GTR: { DESCRIPTION: 'Guitar', INSTRUMENT_FAMILY: 'String' },

      HAR: { DESCRIPTION: 'Harmonica', INSTRUMENT_FAMILY: 'Misc.' },

      HBL: { DESCRIPTION: 'Handbells', INSTRUMENT_FAMILY: 'Percussion' },

      HCK: { DESCRIPTION: 'Heckelphone', INSTRUMENT_FAMILY: 'Woodwind' },

      HPS: { DESCRIPTION: 'Harpsichord', INSTRUMENT_FAMILY: 'Early Music' },

      HRM: { DESCRIPTION: 'Harmonium', INSTRUMENT_FAMILY: 'Keyboard' },

      HRN: { DESCRIPTION: 'Horn', INSTRUMENT_FAMILY: 'Brass' },

      HRP: { DESCRIPTION: 'Harp', INSTRUMENT_FAMILY: 'String' },

      HUR: { DESCRIPTION: 'Hurdy-Gurdy', INSTRUMENT_FAMILY: 'Early Music' },

      KAZ: { DESCRIPTION: 'Kazoo', INSTRUMENT_FAMILY: 'National/Folk' },

      KEY: { DESCRIPTION: 'Keyboard', INSTRUMENT_FAMILY: 'Keyboard' },

      KLV: { DESCRIPTION: 'Klavier', INSTRUMENT_FAMILY: 'Keyboard' },

      KOT: { DESCRIPTION: 'Koto', INSTRUMENT_FAMILY: 'National/Folk' },

      LUT: { DESCRIPTION: 'Lute', INSTRUMENT_FAMILY: 'Early Music' },

      LYR: { DESCRIPTION: 'Lyre', INSTRUMENT_FAMILY: 'Early Music' },

      MAN: { DESCRIPTION: 'Mandolin', INSTRUMENT_FAMILY: 'String' },

      MAR: { DESCRIPTION: 'Marimba', INSTRUMENT_FAMILY: 'Percussion' },

      MBR: { DESCRIPTION: 'Mbira', INSTRUMENT_FAMILY: 'National/Folk' },

      MCS: { DESCRIPTION: 'Maracas', INSTRUMENT_FAMILY: 'National/Folk' },

      MEL: { DESCRIPTION: 'Melodica', INSTRUMENT_FAMILY: 'Misc.' },

      MEZ: { DESCRIPTION: 'Mezzo Soprano Voice', INSTRUMENT_FAMILY: 'Voice' },

      MID: { DESCRIPTION: 'Midi', INSTRUMENT_FAMILY: 'Electronic' },

      MSB: { DESCRIPTION: 'Music Box', INSTRUMENT_FAMILY: 'Misc.' },

      NAF: { DESCRIPTION: 'Native American Flute', INSTRUMENT_FAMILY: 'National/Folk' },

      NAR: { DESCRIPTION: 'Narrator/Speaker', INSTRUMENT_FAMILY: 'Voice' },

      NHN: { DESCRIPTION: 'Natural Horn', INSTRUMENT_FAMILY: 'Early Music' },

      OBD: { DESCRIPTION: 'Oboe d\\çAmore', INSTRUMENT_FAMILY: 'Woodwind' },

      OBO: { DESCRIPTION: 'Oboe', INSTRUMENT_FAMILY: 'Woodwind' },

      OND: { DESCRIPTION: 'Ondes Martinot', INSTRUMENT_FAMILY: 'Electronic' },

      ORG: { DESCRIPTION: 'Organ', INSTRUMENT_FAMILY: 'Keyboard' },

      PER: { DESCRIPTION: 'Percussion', INSTRUMENT_FAMILY: 'Percussion' },

      PIA: { DESCRIPTION: 'Piano', INSTRUMENT_FAMILY: 'Keyboard' },

      PIC: { DESCRIPTION: 'Piccolo', INSTRUMENT_FAMILY: 'Woodwind' },

      PPA: { DESCRIPTION: 'Pipa', INSTRUMENT_FAMILY: 'National/Folk' },

      PRO: { DESCRIPTION: 'Processor', INSTRUMENT_FAMILY: 'Electronic' },

      PRP: { DESCRIPTION: 'Prepared Piano', INSTRUMENT_FAMILY: 'Keyboard' },

      PWH: { DESCRIPTION: 'Pennywhistle', INSTRUMENT_FAMILY: 'National/Folk' },

      REC: { DESCRIPTION: 'Recorder', INSTRUMENT_FAMILY: 'Early Music' },

      RUA: { DESCRIPTION: 'Ruan', INSTRUMENT_FAMILY: 'National/Folk' },

      SAM: { DESCRIPTION: 'Sampler', INSTRUMENT_FAMILY: 'Electronic' },

      SAX: { DESCRIPTION: 'Saxophone', INSTRUMENT_FAMILY: 'Woodwind' },

      SDM: { DESCRIPTION: 'Snare drum', INSTRUMENT_FAMILY: 'Percussion' },

      SEQ: { DESCRIPTION: 'Sequencer', INSTRUMENT_FAMILY: 'Electronic' },

      SHK: { DESCRIPTION: 'Shakuhachi', INSTRUMENT_FAMILY: 'National/Folk' },

      SHM: { DESCRIPTION: 'Shamisen', INSTRUMENT_FAMILY: 'National/Folk' },

      SHO: { DESCRIPTION: 'Sho', INSTRUMENT_FAMILY: 'National/Folk' },

      SHW: { DESCRIPTION: 'Shawm', INSTRUMENT_FAMILY: 'Early Music' },

      SIT: { DESCRIPTION: 'Sitar', INSTRUMENT_FAMILY: 'National/Folk' },

      SNR: { DESCRIPTION: 'Sopranino Recorder', INSTRUMENT_FAMILY: 'Early Music' },

      SNS: { DESCRIPTION: 'Sopranino Saxophone', INSTRUMENT_FAMILY: 'Woodwind' },

      SOP: { DESCRIPTION: 'Soprano Voice', INSTRUMENT_FAMILY: 'Voice' },

      SOU: { DESCRIPTION: 'Sousaphone', INSTRUMENT_FAMILY: 'Brass' },

      SPO: { DESCRIPTION: 'Spoons', INSTRUMENT_FAMILY: 'National/Folk' },

      SRC: { DESCRIPTION: 'Soprano Recorder', INSTRUMENT_FAMILY: 'Early Music' },

      SSX: { DESCRIPTION: 'Soprano Saxophone', INSTRUMENT_FAMILY: 'Woodwind' },

      STD: { DESCRIPTION: 'Steel drums', INSTRUMENT_FAMILY: 'Percussion' },

      SYN: { DESCRIPTION: 'Synthesizer', INSTRUMENT_FAMILY: 'Electronic' },

      TAB: { DESCRIPTION: 'Tabla', INSTRUMENT_FAMILY: 'National/Folk' },

      TAM: { DESCRIPTION: 'Tambour', INSTRUMENT_FAMILY: 'Percussion' },

      TAP: { DESCRIPTION: 'Tape', INSTRUMENT_FAMILY: 'Electronic' },

      TBA: { DESCRIPTION: 'Tuba', INSTRUMENT_FAMILY: 'Brass' },

      TEN: { DESCRIPTION: 'Tenor Voice', INSTRUMENT_FAMILY: 'Voice' },

      THE: { DESCRIPTION: 'Theremin', INSTRUMENT_FAMILY: 'Electronic' },

      THN: { DESCRIPTION: 'Tenor Horn', INSTRUMENT_FAMILY: 'Brass' },

      TIM: { DESCRIPTION: 'Timpani', INSTRUMENT_FAMILY: 'Percussion' },

      TMB: { DESCRIPTION: 'Trombone', INSTRUMENT_FAMILY: 'Brass' },

      TMN: { DESCRIPTION: 'Tambourine', INSTRUMENT_FAMILY: 'Percussion' },

      TRC: { DESCRIPTION: 'Tenor Recorder', INSTRUMENT_FAMILY: 'Early Music' },

      TRI: { DESCRIPTION: 'Triangle', INSTRUMENT_FAMILY: 'Percussion' },

      TRM: { DESCRIPTION: 'Trumpet', INSTRUMENT_FAMILY: 'Brass' },

      TSX: { DESCRIPTION: 'Tenor Saxophone', INSTRUMENT_FAMILY: 'Woodwind' },

      TTM: { DESCRIPTION: 'Tamtam', INSTRUMENT_FAMILY: 'Percussion' },

      TYP: { DESCRIPTION: 'Toy Piano', INSTRUMENT_FAMILY: 'Keyboard' },

      UKE: { DESCRIPTION: 'Ukulele', INSTRUMENT_FAMILY: 'String' },

      VCL: { DESCRIPTION: 'Violoncello', INSTRUMENT_FAMILY: 'String' },

      VDG: { DESCRIPTION: 'Viola Da Gamba', INSTRUMENT_FAMILY: 'Early Music' },

      VIB: { DESCRIPTION: 'Vibraphone', INSTRUMENT_FAMILY: 'Percussion' },

      VID: { DESCRIPTION: 'Video', INSTRUMENT_FAMILY: 'Electronic' },

      VLA: { DESCRIPTION: 'Viola', INSTRUMENT_FAMILY: 'String' },

      VLN: { DESCRIPTION: 'Violin', INSTRUMENT_FAMILY: 'String' },

      VOC: { DESCRIPTION: 'Voice', INSTRUMENT_FAMILY: 'Voice' },

      WBK: { DESCRIPTION: 'Wood block', INSTRUMENT_FAMILY: 'Percussion' },

      WHS: { DESCRIPTION: 'Whistle', INSTRUMENT_FAMILY: 'Misc.' },

      WTB: { DESCRIPTION: 'Wagner Tuba', INSTRUMENT_FAMILY: 'Brass' },

      XYL: { DESCRIPTION: 'Xylophone', INSTRUMENT_FAMILY: 'Percussion' },

      YQN: { DESCRIPTION: 'Yang Qin', INSTRUMENT_FAMILY: 'National/Folk' },

      ZHG: { DESCRIPTION: 'Zheng', INSTRUMENT_FAMILY: 'National/Folk' },

      ZIT: { DESCRIPTION: 'Zither', INSTRUMENT_FAMILY: 'National/Folk' },
    },
  },
};
