import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromState from '../state';
import * as fromNewSectionItem from './new-section-item.reducer';

export interface NewSectionItemState {
  newItem: fromState.NewSectionItemState;
}

export const reducers: ActionReducerMap<NewSectionItemState> = {
  newItem: fromNewSectionItem.reducer,
};

// Feature selectors
export const getNewSectionItemFeatureState = createFeatureSelector<NewSectionItemState>('newSectionItem');

export const getNewItem = createSelector(getNewSectionItemFeatureState, (state: NewSectionItemState) => state.newItem);
