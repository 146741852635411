import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ApiNamespace, CopyrightUtils, RelationsUtils, StringUtils, TabsUtils, SocietiesUtils, MergeIpUtils } from '@ice';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { FormsAndTables } from '@ice/dynamic-components/forms-and-tables/forms-and-tables';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import * as fromApiCalls from 'config/api-calls';
import { IPI_PREFIX } from 'config/constants/global.constants';
import { IP_CONSTANTS, IP_TYPE_NATURAL_PERSON } from 'config/constants/ips.constants';
import { SectionsConfig } from 'config/sections-config';
import { ApiCallData } from 'config/sections-config/api-call';
import { SectionCopyrightIps } from 'config/sections-config/section-copyright-ips';
import { DialogTransferIpWorksSteps } from 'config/stepper-builders/ips/dialog-transfer-ip-works-steps/dialog-transfer-ip-works-steps';
import { cloneDeep, find, get, last, uniq } from 'lodash';
import { SearchIpsParser } from 'models/search-parsers/search-ips-parser';
import { Observable, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, take, withLatestFrom } from 'rxjs/operators';
import { NamespaceService } from 'services/namespace/namespace.service';
import { SearchService } from 'services/search/search.service';
import * as fromNewSectionItem from 'store/new-section-item';
import * as fromRoot from 'store/root';
import * as fromUtils from 'store/root/utils';
import { SectionTabConfig } from '../../tabs-data';
import { DataTableRow } from './../../../../@ice/components/data-table/data-table';

const { NATURAL_PERSON, LEGAL_ENTITY, MALE, FEMALE } = IP_CONSTANTS;
const NATURAL_PERSON_VISIBLE_COLUMNS = [
  'baseIPINumber',
  'baseId',
  'typeName',
  'sexName',
  'dateOfBirth',
  'dateOfDeath',
  'countryOfBirth',
  'placeOfBirth',
  'status',
  'agreements',
  'mergeIdLabel',
];
const LEGAL_ENTITY_VISIBLE_COLUMNS = ['baseIPINumber', 'baseId', 'typeName', 'dateOfFoundation', 'dateOfDissolution', 'status', 'agreements', 'mergeIdLabel'];

// TODO: Add Angular decorator.
export class TabIpDetail implements SectionTabConfig {
  private key: string;
  private ns: ApiNamespace;
  private openChildTab = null;
  private tabWorks = 'works';
  private tabAgreements = 'agreements';
  private dialogRef: MatDialogRef<DialogMultiLayoutComponent, any>;

  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private dialog: MatDialog,
    private commonApiService: null,
    private detailService: null,
    protected nsService: NamespaceService,
    private iceFacade: null,
    private fieldValidatorService: null,
    private permissionsService: null,
    private searchService: SearchService,
    private storeNewItem: Store<fromNewSectionItem.NewSectionItemState>,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.store.pipe(select(fromRoot.getRouterParams)).subscribe(params => (params && params.key ? (this.key = params.key) : null));

    store
      .pipe(select(fromRoot.getGlobalApiNamespace))
      .subscribe(ns => (this.ns = ns))
      .unsubscribe();
  }

  static type() {
    return TabIpDetail;
  }

  getConf(): FormsAndTables {
    return {
      tablesAlign: 'column',
      tablesGap: '10px',
      cardWithDataTableOne: {
        title: this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.CARD_TITLE'),
        visibleColumns: this.getVisibleColumns(),
        model: this.store.pipe(
          select(fromRoot.getIpBaseInformation),
          map((baseInformation: any) => {
            if (baseInformation) {
              return baseInformation.map(bInfo => {
                const typeName =
                  bInfo.typeOf && bInfo.typeOf === 'N'
                    ? this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.IP_TYPE_NATURAL_PERSON')
                    : this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.IP_TYPE_LEGAL_ENTITY');
                const sexName =
                  bInfo.sex && bInfo.sex === MALE
                    ? this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.MALE')
                    : bInfo.sex === FEMALE
                    ? this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.FEMALE')
                    : '';
                return { ...bInfo, typeName, sexName };
              });
            }
            return [];
          }),
        ),
        columnMode: 'flex',
        schema: [
          { name: this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.BASE_ID'), prop: 'baseIPINumber', flexGrow: 1 },
          { name: this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.IP_BASE_KEY'), prop: 'baseId', flexGrow: 1 },
          { name: this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.BASE_TYPE_NAME'), prop: 'typeName', flexGrow: 1 },
          { name: this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.SEX'), prop: 'sexName', flexGrow: 1 },
          { name: this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.DATE_OF_BIRTH'), prop: 'dateOfBirth', flexGrow: 1 },
          { name: this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.DATE_OF_FOUNDATION'), prop: 'dateOfFoundation', flexGrow: 1 },
          { name: this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.DATE_OF_DEATH'), prop: 'dateOfDeath', flexGrow: 1 },
          { name: this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.DATE_OF_DISSOLUTION'), prop: 'dateOfDissolution', flexGrow: 1 },
          { name: this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.COUNTRY_OF_BIRTH'), prop: 'countryOfBirth', flexGrow: 1 },
          { name: this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.PLACE_OF_BIRTH'), prop: 'placeOfBirth', flexGrow: 1 },
          {
            name: this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.STATUS'),
            prop: 'status',
            cellClass: 'display-flex ice-justify-right ice-table-cell-reverse ice-pr-0 fit-icons',
            headerClass: 'display-flex ice-justify-right',
            flexGrow: 1,
            tooltip: 'statusTooltip',
            tooltipDuration: 250,
            icons: 'statusIcons',
          },
          {
            name: '',
            prop: 'mergeIdLabel',
            cellClass: 'ice-table-link ice-pl-0 fit-icons',
            flexGrow: 1,
            sortable: false,
            tooltip: 'mergeIdLabelTooltip',
            tooltipDuration: 250,
            onClickAction: row => this.goToIP(row.mergeId, this.store),
            icons: 'mergeIdIcons',
          },
        ],
        tableWidth: '100',
        sorts: [{ prop: 'fullName', dir: 'asc' }],
        onMouseSelect: row => {
          if (row?.activatedColumn === 'mergeIdLabel') {
            this.goToIPNewTab(row.mergeId, this.store);
          }
        },
      },
      cardWithDataTableTwo: {
        class: 'ip-details-names',
        title: this.translate.instant('IPS.DETAILS.CARD_WITH_DATA_TABLE_ONE.CARD_TITLE'),
        model: combineLatest([
          this.store.select(fromRoot.getTransferIpWorksMode),
          this.store.select(fromRoot.getIpDetailNames),
          this.store.select(fromRoot.getIpDetailWorksCount),
          this.store.select(fromRoot.getIpDetailAgreementsCount),
        ]).pipe(
          map(([transferIpWorksMode, detailNames, worksCount, agreementsCount]) => {
            const names = cloneDeep(detailNames);
            let type;
            let key;
            if (this.key) {
              type = CopyrightUtils.getKeySuffix(this.key);
              key = CopyrightUtils.getKeyPrefix(this.key);
            }

            return names.map(name => {
              name.worksTooltipText = this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.TOOLTIP_WORKS_COUNT');
              name.agreementsTooltipText = this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.TOOLTIP_AGREEMENTS_COUNT');
              if (this.key && ((type === IPI_PREFIX && name.ipiNameNumber === key) || (type !== 'IPI' && this.key === name.key))) {
                name['rowClass'] = 'ice-highlighted-row';
                name.works = worksCount;
                name.agreements = agreementsCount;
                name.worksTooltipText = this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.TOOLTIP_WORKS_COUNT_UPDATED');
                name.agreementsTooltipText = this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.TOOLTIP_AGREEMENTS_COUNT_UPDATED');
              }
              name['transferIpWorksIcon'] =
                name.works > 0 && transferIpWorksMode
                  ? [
                      {
                        icon: 'arrow_forward',
                        text: this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.MOVE_THESE_WORKS'),
                        class: 'white-icon ice-transfer-ip-works-icon',
                        action: row => this.openTransferIpWorksDialog(row),
                      },
                    ]
                  : [];

              return name;
            });
          }),
        ),
        sorts: [{ prop: 'fullName', dir: 'asc' }],
        columnMode: 'flex',
        selectionType: of('multi'),
        schema: [
          { cellClass: 'cursor-pointer', name: this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.NAME'), prop: 'fullName', flexGrow: 6 },
          {
            cellClass: 'cursor-pointer ip-detail-editable-ipi',
            name: this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.IPI_NAME_NUMBER'),
            prop: 'ipiNameNumber',
            errorMessageField: 'errorMessage',
            flexGrow: 2,
            editable: row =>
              this.store.pipe(
                select(fromRoot.getMergingIP),
                distinctUntilChanged(),
                withLatestFrom<boolean, any>(this.store.select(fromRoot.getIpDetailNames)),
                map(([mergingIP, names]: [boolean, Array<any>]) => {
                  if (names && names.length) {
                    const name = names.find(n => n.id === row.id);
                    return mergingIP && (!name.ipiNameNumber || name.ipiNameNumber === '');
                  }
                  return false;
                }),
              ),
            addEditableCheckButton: true,
            onEdit: (newValue: DataTableRow) => {
              if (newValue['ipiNameNumber']) {
                this.store.pipe(select(fromRoot.getIpBaseParty), take(1)).subscribe(baseParty => {
                  const typeOf = baseParty?.attributes?.typeOf || IP_TYPE_NATURAL_PERSON;
                  const IPINameNumber = newValue['ipiNameNumber'];
                  const ipSearch = new fromUtils.SearchUtilsFactory(SectionsConfig.IPS.name).getFormatted(`#${IPINameNumber}`);
                  this.searchService
                    .getSearchResults(SectionCopyrightIps, { ...ipSearch, baseIdPrefix: 'IPI', xrefPrefix: 'IPI', typeOf, includeInactive: false, ns: this.ns })
                    .subscribe(result => {
                      const ips = SearchIpsParser.IpSearchCleaner(
                        (result?.items || []).filter(item => item.attributes.typeOfName !== 'PG'),
                        this.translate,
                      );
                      if (ips.length > 0) {
                        newValue.errorMessage = '';
                        if (!newValue['isOnChange']) {
                          const items = [ips[0], { ...newValue, ipiNameNumber: '', baseParty }];
                          this.store.dispatch(new fromRoot.SetMergeIpItems(items));
                          MergeIpUtils.getMergeIpOpenDialog({
                            dialog: this.dialog,
                            translate: this.translate,
                            store: this.store,
                          });
                        }
                      } else {
                        newValue.errorMessage = this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.INVALID_IPI');
                      }
                      this.store.dispatch(new fromRoot.UpdateField({ object: 'partyName', newValue, type: 'edit' }));
                    });
                });
              } else {
                this.store.dispatch(new fromRoot.UpdateField({ object: 'partyName', newValue, type: 'edit' }));
              }
            },
          },
          { cellClass: 'cursor-text ip-name-key', name: this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.REFERENCE'), prop: 'nameKey', flexGrow: 2 },
          { cellClass: 'cursor-text', name: this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.TYPE_OF_NAME'), prop: 'typeOfName', flexGrow: 2, tooltip: 'typeOfNameTooltip' },
          {
            name: this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.WORKS'),
            prop: 'works',
            flexGrow: 1,
            tooltip: 'worksTooltipText',
            onClickAction: row => this.gotoTab(row, this.tabWorks),
            onWheelClickAction: row => (this.openChildTab = this.tabWorks),
            icons: 'transferIpWorksIcon',
            cellClass: 'cursor-pointer ice-transfer-ip-works-icon-wrapper',
            minWidth: 100,
          },
          {
            name: this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.AGREEMENTS'),
            prop: 'agreements',
            tooltip: 'agreementsTooltipText',
            flexGrow: 1,
            onClickAction: row => this.gotoTab(row, this.tabAgreements),
            onWheelClickAction: row => (this.openChildTab = this.tabAgreements),
            cellClass: 'cursor-pointer',
          },
        ],
        tableWidth: '100',
        onSelect: (event: any[]) => {
          this.store.pipe(select(fromRoot.getEditMode), take(1)).subscribe((editMode: boolean) => {
            if (!editMode && event && event[0] && event[0].key && event[0].key.length > 0) {
              this.store.dispatch(
                new fromRoot.Go({
                  path: [`copyright/${SectionsConfig.IPS.name}/${event[0].key}/details`],
                }),
              );
            }
          });
        },
        onMouseSelect: event => {
          setTimeout(() => {
            // Time to wait the cells onWheelClickAction to get the correct tab
            this.store.pipe(select(fromRoot.getEditMode), take(1)).subscribe((editMode: boolean) => {
              if (!editMode && event.key.length > 0) {
                this.store.dispatch(
                  new fromRoot.OpenNewTab({
                    path: [`copyright/${SectionsConfig.IPS.name}/${event.key}/${this.openChildTab || ''}`],
                  }),
                );
              }
              this.openChildTab = null;
            });
          }, 500);
        },
      },
      cardWithDataTableThree: {
        title: this.translate.instant('IPS.DETAILS.CARD_WITH_DATA_TABLE_TWO.CARD_TITLE'),
        model: this.store.pipe(
          select(fromRoot.getIpSocieties),
          map(societies => SocietiesUtils.cleanSocietiesRelations(societies, this.translate)),
        ),
        columnMode: 'flex',
        onSelect: (event: any[]) => {
          this.store.dispatch(
            new fromRoot.Go({
              path: [`copyright/${SectionsConfig.SOCIETIES.name}/CISAC:${StringUtils.trimLeadingZerosFromString(event[0].societyNumber)}`],
            }),
          );
        },
        onMouseSelect: event => {
          if (event?.societyNumber) {
            this.store.dispatch(
              new fromRoot.OpenNewTab({
                path: [`copyright/${SectionsConfig.SOCIETIES.name}/CISAC:${StringUtils.trimLeadingZerosFromString(event.societyNumber)}`],
              }),
            );
          }
        },
        schema: [
          { name: this.translate.instant('IPS.DETAILS.CARD_WITH_DATA_TABLE_TWO.TABLE_SCHEMA.SOCIETY_CODE'), prop: 'societyNumber', flexGrow: 0.7 },
          {
            name: this.translate.instant('IPS.DETAILS.CARD_WITH_DATA_TABLE_TWO.TABLE_SCHEMA.CREATION_CLASS'),
            prop: 'creationClass',
            flexGrow: 0.7,
            tooltip: 'creationClassTooltip',
          },
          { name: this.translate.instant('IPS.DETAILS.CARD_WITH_DATA_TABLE_TWO.TABLE_SCHEMA.SOCIETY'), prop: 'societyName', flexGrow: 2.5 },
          { name: this.translate.instant('IPS.DETAILS.CARD_WITH_DATA_TABLE_TWO.TABLE_SCHEMA.SHARE'), prop: 'share', flexGrow: 0.7 },
          {
            name: this.translate.instant('IPS.DETAILS.CARD_WITH_DATA_TABLE_TWO.TABLE_SCHEMA.TERRITORY'),
            prop: 'territoriesText',
            icons: 'territoriesIcon',
            class: 'ice-txt-size-14 agreements-territories',
            flexGrow: 4,
            tooltip: 'territoriesTooltip',
            tooltipDuration: 250,
          },
          { name: this.translate.instant('IPS.DETAILS.CARD_WITH_DATA_TABLE_TWO.TABLE_SCHEMA.VALID_FROM'), prop: 'validFrom', flexGrow: 1 },
          { name: this.translate.instant('IPS.DETAILS.CARD_WITH_DATA_TABLE_TWO.TABLE_SCHEMA.VALID_TO'), prop: 'validTo', flexGrow: 1 },
          { name: this.translate.instant('IPS.DETAILS.CARD_WITH_DATA_TABLE_TWO.TABLE_SCHEMA.ROLE_CODE'), prop: 'roleCodes', flexGrow: 0.7, tooltip: 'roleCodesTooltip' },
          { name: this.translate.instant('IPS.DETAILS.CARD_WITH_DATA_TABLE_TWO.TABLE_SCHEMA.RIGHTS_CODE'), prop: 'rightsCodes', flexGrow: 4, tooltip: 'rightsCodesTooltip' },
        ],
        tableWidth: '100',
        sorts: [{ prop: 'societyNumber', dir: 'asc' }],
      },
    };
  }

  gotoTab(row, tab, openInNewTab = false) {
    if (row && row.key && row.key.length > 0 && ((row.agreements && row.agreements > 0) || (row.works && row.works > 0))) {
      const params = {
        path: [`copyright/${SectionsConfig.IPS.name}/${row.key}/${tab}`],
      };
      if (openInNewTab) {
        this.store.dispatch(new fromRoot.OpenNewTab(params));
      } else {
        this.store.dispatch(new fromRoot.Go(params));
      }
    }
  }

  getVisibleColumns(): Observable<string[]> {
    return this.store.pipe(
      select(fromRoot.getIpBaseInformation),
      map((baseInformation: any) => {
        const baseTypesReduced = uniq(
          baseInformation.map(bInfo => {
            return bInfo.typeOf && bInfo.typeOf === NATURAL_PERSON ? NATURAL_PERSON : LEGAL_ENTITY;
          }),
        );

        const hasLegalEntity = find(baseTypesReduced, itemType => itemType === LEGAL_ENTITY);
        const hasNaturalPerson = find(baseTypesReduced, itemType => itemType === NATURAL_PERSON);
        if (hasLegalEntity && !hasNaturalPerson) {
          return LEGAL_ENTITY_VISIBLE_COLUMNS;
        } else if (hasNaturalPerson && !hasLegalEntity) {
          return NATURAL_PERSON_VISIBLE_COLUMNS;
        } else {
          return [];
        }
      }),
    );
  }

  private goToIP(ipId: string, store: Store<fromRoot.RootState>) {
    const actionFn = (id: string) =>
      store.dispatch(
        new fromRoot.Go({
          path: [`${SectionsConfig.IPS.domainName}/${SectionsConfig.IPS.name}/${id}/${TabsUtils.getDefaultTabName(SectionsConfig.IPS.name)}`],
        }),
      );
    this.getIpNameAndGoToIP(ipId, store, actionFn);
  }

  private goToIPNewTab(ipId: string, store: Store<fromRoot.RootState>) {
    const actionFn = (id: string) =>
      store.dispatch(
        new fromRoot.OpenNewTab({
          path: [`${SectionsConfig.IPS.domainName}/${SectionsConfig.IPS.name}/${id}/${TabsUtils.getDefaultTabName(SectionsConfig.IPS.name)}`],
        }),
      );
    this.getIpNameAndGoToIP(ipId, store, actionFn);
  }

  private getIpNameAndGoToIP(ipId: string, store: Store<fromRoot.RootState>, actionFn: (id: string) => void) {
    const apiCallData: ApiCallData = { labels: { id: ipId } };
    this.store.dispatch(
      new fromRoot.StartApiCall({
        apiCall: fromApiCalls.getIpsPartyNames,
        apiCallData,
        callBack: response => {
          const partyNames = get(response, 'partyNames');
          actionFn(RelationsUtils.getWorkIdFromRelations(get(find(partyNames, party => party.type === 'PA') || last(partyNames), 'partyName.relations', [])));
        },
      }),
    );
  }

  openTransferIpWorksDialog(sourceIp) {
    this.dialogRef = this.dialog.open(DialogMultiLayoutComponent, {
      panelClass: '',
      data: {
        className: 'dialog-wrapper-85vw-90vh dialog-horizontal-stepper',
        layouts: [
          {
            contentClose: true,
            layout: [
              {
                group: [
                  {
                    type: 'stepper',
                    config: {
                      stepperConfig: {
                        horizontal: true,
                        model: of({}),
                        steps: DialogTransferIpWorksSteps.getSteps(
                          this.translate,
                          this.fuseTranslationLoader,
                          this.store,
                          this.storeNewItem,
                          this.fieldValidatorService,
                          sourceIp,
                          this.closeTransferIpWorksDialog.bind(this),
                        ),
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    });

    this.dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.dialogRef = null;
        this.store.dispatch(new fromRoot.SetTransferIpWorksInfo(null));
      });
  }

  closeTransferIpWorksDialog() {
    this.dialogRef?.close();
  }
}
