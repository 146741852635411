<div fxLayout="row" attr.data-testid="wrapper-{{ field.key }}">
  <mat-form-field fxLayout="column" fxFlex="90">
    <mat-select [id]="id" [formControl]="formControl" [formlyAttributes]="field" [placeholder]="to.placeholder" attr.data-testid="select-{{ to.key }}" [multiple]="to.multiple">
      <ng-container *ngFor="let item of to.options">
        <mat-optgroup *ngIf="item.group" label="{{ item.label }}">
          <mat-option *ngFor="let child of item.group" [value]="child.id" [disabled]="child.disabled">
            {{ child.label }}
          </mat-option>
        </mat-optgroup>
        <mat-option *ngIf="!item.group" [value]="item.id" [disabled]="item.disabled">{{ item.label }}</mat-option>
      </ng-container>
    </mat-select>
    <mat-error [id]="null">
      <formly-validation-message [field]="field"></formly-validation-message>
    </mat-error>
  </mat-form-field>

  <mat-icon
    fxLayout="column"
    fxFlex="10"
    class="formFieldInfo"
    color="accent"
    [matTooltipPosition]="'above'"
    matTooltip="{{ field.templateOptions.infoText }}"
    [matTooltipClass]="'ice-txt-size-12'"
    attr.data-testid="icon-{{ field.key }}"
    >info</mat-icon
  >
</div>
