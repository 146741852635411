import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Injector,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ExpertSearchParseService } from '../../../services/search/expert-search-parse.service';

@Component({
  selector: 'ice-query-editor-expert-search',
  templateUrl: './query-editor-expert-search.component.html',
  styleUrls: ['./query-editor-expert-search.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => QueryEditorExpertSearchComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class QueryEditorExpertSearchComponent implements ControlValueAccessor, OnChanges {
  value: string;
  @Input() queryLanguageParser: ExpertSearchParseService;

  @ViewChild('inputElement', { read: ElementRef, static: true }) inputElement: ElementRef;

  @Input() prevVal: string;
  @Output() ngModelChange = new EventEmitter<any>();

  constructor(private renderer2: Renderer2, private injector: Injector, private changeDetectorRef: ChangeDetectorRef) {}

  onChange = (_: any) => {};

  @HostListener('blur') onTouched = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer2.setProperty(this.inputElement.nativeElement, 'disabled', isDisabled);
  }

  setValue(value: any): void {
    this.value = value;
    this.changeDetectorRef.detectChanges();
  }

  @HostListener('input', ['$event.target.value']) handleInput(value: any): void {
    if (value === this.prevVal) {
      const model = this.injector.get<NgControl>(NgControl as any); // get NgModel
      model.control.setValue(model.value); // set previous value
      return;
    }
    this.onChange(value);
  }

  addDarkClass(elm: HTMLElement) {
    if (elm.classList.contains('bg-tag-field')) {
      elm.classList.add('bg-tag-field-dark');
    } else if (elm.classList.contains('bg-tag-comparison')) {
      elm.classList.add('bg-tag-comparison-dark');
    } else if (elm.classList.contains('bg-tag-value')) {
      elm.classList.add('bg-tag-value-dark');
    } else if (elm.classList.contains('bg-tag-value-quoted')) {
      elm.classList.add('bg-tag-value-quoted-dark');
    } else if (elm.classList.contains('bg-tag-operator')) {
      elm.classList.add('bg-tag-operator-dark');
    }
  }

  removeDarkClass(elm: HTMLElement) {
    elm.classList.remove('bg-tag-field-dark');
    elm.classList.remove('bg-tag-comparison-dark');
    elm.classList.remove('bg-tag-value-dark');
    elm.classList.remove('bg-tag-value-quoted-dark');
    elm.classList.remove('bg-tag-operator-dark');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.queryLanguageParser) {
      this.queryLanguageParser = changes.queryLanguageParser.currentValue;
    }
  }
}
