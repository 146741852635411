import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouterUtils } from '@ice';
import { ErrorsUtils } from '@ice/utils/api-responses/errors.utils';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RESPONSE_STATUS } from 'config/constants/response-errors-constants';
import { SectionsConfig } from 'config/sections-config';
import { environment } from 'config/env';
import { dropRight, first, get } from 'lodash';
import { EMPTY, Observable, from, of, pipe, throwError, timer } from 'rxjs';
import { catchError, delay, expand, filter, flatMap, map, mergeMap, reduce, retryWhen, switchMap, takeUntil, tap } from 'rxjs/operators';
import * as fromRoot from 'store/root';
import { RootState } from 'store/root';
import { GetRequestHeadersProps, RequiresPostgresHeader } from 'models/requests/requests';
import { TranslateService } from '@ngx-translate/core';
import { locale as tabEditTranslations } from 'assets/i18n/en/config/tab-edit-builders';
import { Logout, SaveRedirectOnLogin } from './../store/root/actions/auth/auth.actions';
import { AuthService } from './auth/auth.service';
import { CubeRequest } from './masked-ids/masked-ids.model';
import { MaskedIdsService } from './masked-ids/masked-ids.service';

@Injectable()
export class CommonApiService {
  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    protected store: Store<RootState>,
    protected route: ActivatedRoute,
    protected maskedIdsService: MaskedIdsService,
    protected actions$: Actions,
  ) {}

  public applyMaskIds(cubeReq, maskedIdsList) {
    return pipe(
      switchMap(headers => {
        if (maskedIdsList) {
          return this.maskedIdsService.applyMaskToCubeRequest({ ...cubeReq, headers }, maskedIdsList);
        } else {
          return of({ ...cubeReq, headers });
        }
      }),
    );
  }

  public getDownloadRequest<T>(url: string, request: any, maskedIdsList?: any, getResponseStatus: string = 'body'): Observable<any> {
    return this.getHeaders({ download: true })
      .pipe(
        this.applyMaskIds({ url, params: this.getHttpParams(request) }, maskedIdsList),
        flatMap((cubeReq: CubeRequest) => this.http.get<T>(cubeReq.url, { params: cubeReq.params, headers: cubeReq.headers, observe: `${getResponseStatus}` as any })),
      )
      .pipe(retryWhen(this.handleRetry));
  }

  public getRequestNoAuth<T>(url: string, request: any, maskedIdsList?: any, getResponseStatus: string = 'body'): Observable<any> {
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return of(headers)
      .pipe(
        this.applyMaskIds({ url, params: this.getHttpParams(request) }, maskedIdsList),
        flatMap((cubeReq: CubeRequest) => this.http.get<T>(cubeReq.url, { params: cubeReq.params, headers: cubeReq.headers, observe: `${getResponseStatus}` as any })),
      )
      .pipe(retryWhen(this.handleRetry));
  }

  public getRequestCubeData<T>(url: string, request: any, maskedIdsList?: any, getResponseStatus: string = 'body'): Observable<any> {
    return this.getHeaders({})
      .pipe(
        this.applyMaskIds({ url, params: this.getHttpParams(request) }, maskedIdsList),
        flatMap((cubeReq: CubeRequest) => this.http.get<T>(cubeReq.url, { params: cubeReq.params, headers: cubeReq.headers, observe: `${getResponseStatus}` as any })),
      )
      .pipe(retryWhen(this.handleRetry));
  }

  public postRequestCubeData<T>(url: string, request: any, body?: any, maskedIdsList?: any): Observable<T> {
    return this.getHeaders({ requestUrl: url })
      .pipe(
        this.applyMaskIds({ url, body, params: this.getHttpParams(request) }, maskedIdsList),
        flatMap((cubeReq: CubeRequest) => this.http.post<T>(cubeReq.url, cubeReq.body, { params: cubeReq.params, headers: cubeReq.headers })),
      )
      .pipe(retryWhen(this.handleRetry));
  }

  public putRequestCubeData<T>(url: string, request: any, body?: any, maskedIdsList?: any): Observable<T> {
    return this.getHeaders({})
      .pipe(
        this.applyMaskIds({ url, params: this.getHttpParams(request), body }, maskedIdsList),
        flatMap((cubeReq: CubeRequest) => this.http.put<T>(cubeReq.url, cubeReq.body, { params: cubeReq.params, headers: cubeReq.headers })),
      )
      .pipe(retryWhen(this.handleRetry));
  }

  public deleteRequestCubeData<T>(url: string, request: any, maskedIdsList?: any, body?: any): Observable<T> {
    return this.getHeaders({})
      .pipe(
        this.applyMaskIds({ url, params: this.getHttpParams(request) }, maskedIdsList),
        flatMap((cubeReq: CubeRequest) => this.http.delete<T>(cubeReq.url, { params: cubeReq.params, headers: cubeReq.headers, ...(body && { body }) })),
      )
      .pipe(retryWhen(this.handleRetry));
  }

  public downloadJSON(url) {
    return this.http.get(url, { responseType: 'blob' }).pipe(switchMap((blob: any) => from(blob.text())));
  }
  protected handleRetry(errors$: Observable<HttpErrorResponse>) {
    let retries = 0;
    return errors$.pipe(
      mergeMap(error => {
        if (error.status === HttpStatusCode.TooManyRequests) {
          retries++; // Increment counter on each error
          if (retries > 3) {
            return throwError(error); // Exceeded retry limit, terminate
          }
          const defaultRetryDelay = 3;
          const apiRetryAfterDelay = parseInt(error.headers.get('retry-after'), 10);
          return timer((apiRetryAfterDelay || defaultRetryDelay) * 1_000); // Delay before retrying
        }
        return throwError(error); // Throw error if not 429
      }),
    );
  }

  private getHeaders({ download = false, requestUrl = '' }: GetRequestHeadersProps): Observable<HttpHeaders> {
    return this.authService.getAuthenticationToken().pipe(
      map(idToken => {
        if (!idToken) {
          this.store.dispatch(new SaveRedirectOnLogin(RouterUtils.getUrlWithParams(this.route.snapshot)));
          this.store.dispatch(new Logout());
        }
        return idToken || null;
      }),
      filter(idToken => idToken != null),
      map(idToken => {
        const headers = { 'Content-type': 'application/json', Authorization: `Bearer ${idToken}` };
        if (download) {
          headers['responseType'] = 'blob';
        }
        if (this.requiresPostgresHeader({ requestUrl })) {
          headers['X-Using-Backend'] = 'POSTGRES';
        }
        return new HttpHeaders(headers);
      }),
    );
  }

  private getHttpParams(request) {
    let params: HttpParams = new HttpParams();
    Object.entries(request).forEach(([key, value]) => {
      if (key === 'include') {
        // Clean up tabs, spaces and carriage returns from the include UI-1317
        if (value instanceof String || typeof value === 'string') {
          value = (<String>value).replace(/(\t|\r\n|\n|\r|\s| )/gm, '');
        }
      }
      params = params.append(key, `${value}`);
    });
    return params;
  }

  /* This method encapsulates the different variations of the token and status checks
      As this is being unified in the BE we'll only need to change this method to adapt.
   */
  public getCheckStatusUrl(sectionDataName: string, response: {}, customStatusPath: string, isEdit: boolean, isClone: boolean, isWorkCreation?: boolean) {
    let token = get(response, 'token', '');
    const statusPath = customStatusPath || 'master';
    const events = get(response, 'events', []);
    const useMasterEndpoint =
      events.length === 0 && (sectionDataName === SectionsConfig.WORKS.name || sectionDataName === SectionsConfig.AGREEMENTS.name || sectionDataName === SectionsConfig.IPS.name);

    if (
      statusPath === 'master' &&
      sectionDataName !== 'claims' &&
      (useMasterEndpoint || sectionDataName !== SectionsConfig.WORKS.name) &&
      sectionDataName !== SectionsConfig.AGREEMENTS.name
    ) {
      /// ???
      token = '';
    } //  status Path 'master' doesn't support token but uses the id/version this is work, agreement updates

    const jobId = get(response, 'jobId', '');
    let sourceId;
    let version;

    const responseInfo = get(response, 'response', null);
    if (responseInfo) {
      sourceId = get(responseInfo, 'sourceId', '');
      version = get(responseInfo, 'version', '');
    } else {
      sourceId = get(response, 'sourceId', '') || get(response, 'events[0].data.requestId', '') || get(response, 'events[0].requestId', '');
      version = get(response, 'version', '') || get(response, 'events[0].masterJobVersion', '') || get(response, 'events[0].requestVersion', '');
    }
    const idNs = dropRight((sourceId || '').split(':'))?.join(':');
    const identifier = token || `${sourceId}/${version}`;
    const type = get(response, 'type', '').toLowerCase(); // This response of MACTH
    if (!sourceId) {
      token = get(response, 'token', '');
    }
    const registerDataSection =
      sectionDataName === SectionsConfig.AGREEMENTS.name || sectionDataName === 'agreementapplications' ? sectionDataName : isWorkCreation ? 'cwr' : 'works';
    return (events.length === 0 && type !== 'work-match' && !jobId) ||
      sectionDataName === 'claims' ||
      (sectionDataName === SectionsConfig.WORKS.name && !useMasterEndpoint) ||
      sectionDataName === SectionsConfig.AGREEMENTS.name
      ? `${environment.apiUrl}/${registerDataSection}/${first(token.split(':'))}/register/status/${token}?timeout=5`
      : !jobId
      ? `${environment.apiUrl}/${sectionDataName}/${idNs}/${statusPath}/status/${identifier}?timeout=5`
      : `${environment.apiUrl}/cascade/status/${jobId}`;
  }

  public checkStatus(
    sectionDataName: string,
    response: {},
    customStatusPath?: string,
    apiCall?: boolean,
    isEdit = false,
    isClone = false,
    isWorkCreation?: boolean,
  ): Observable<any> {
    if ([SectionsConfig.REPORTS.apiSegment, SectionsConfig.COUNTERCLAIMS.apiSegment, SectionsConfig.BULK_UPDATES.name].includes(sectionDataName)) {
      return of({ results: 'saved', status: 'success' });
    }
    if (!this.isStatusInProgress(response) && !get(response, 'jobId', '')) {
      if (get(response, 'status', '') === 'error') {
        return throwError(ErrorsUtils.getResponseErrors(response));
      }
      return of(response);
    }
    const query$ = this.getRequestCubeData(this.getCheckStatusUrl(sectionDataName, response, customStatusPath, isEdit, isClone, isWorkCreation), {}).pipe(
      takeUntil(this.actions$.pipe(ofType(fromRoot.CANCEL_API_CALL))),
    );
    return query$.pipe(
      tap(result => {
        if (apiCall) {
          this.store.dispatch(new fromRoot.ApiCallCheckStatusProcess(result));
        }
      }),
      expand((result: boolean, index: number) => (result && !this.isStatusInProgress(result) ? EMPTY : query$.pipe(delay(3000)))),
      reduce((acc, result) => result),
      switchMap(result => {
        if (get(result, 'status', '') === 'error') {
          return throwError(result);
        } else {
          return of(result);
        }
      }),
      catchError(error => throwError(error)),
    );
  }

  private isStatusInProgress(result): Boolean {
    let status = this.statusToLowerCase(get(result, 'status', ''));
    if (!status) {
      status = this.statusToLowerCase(get(result, 'results[0]', ''));
    }
    if (!status) {
      status = this.statusToLowerCase(get(result, 'jobStatus', ''));
    }
    return status === RESPONSE_STATUS.IN_PROGRESS || status === RESPONSE_STATUS.IN_PROGRESS_NEW;
  }

  private statusToLowerCase(status: string) {
    return typeof status === 'string' ? status.toLowerCase() : status;
  }

  async existReference(url: string): Promise<any> {
    try {
      const res = await this.getRequestCubeData(url, {}).toPromise();
      return res || false;
    } catch (e) {
      return false;
    }
  }

  private requiresPostgresHeader({ requestUrl }: RequiresPostgresHeader): boolean {
    const routeParams = RouterUtils.extractActivatedRouterData(this.route.snapshot).queryParams;
    if (requestUrl?.includes('works/CUBE/search') && routeParams.hasOwnProperty('expert')) {
      return true;
    }
    if (requestUrl?.includes('agreementapplications/CUBE/search')) {
      return true;
    }
    return false;
  }
}
