import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { remove } from 'lodash';

@Injectable()
export class FormlyService {
  private formlyConfigList: Array<{ form: AbstractControl; options: FormlyFormOptions; formBuilder: FormlyFieldConfig[]; model: any; id: string }> = [];

  public attachFormlyConfig(formlyConfig: { form: AbstractControl; options: FormlyFormOptions; formBuilder: FormlyFieldConfig[]; model: any; id: string }) {
    this.formlyConfigList.push(formlyConfig);
  }

  public detachFormlyConfig(id) {
    this.formlyConfigList = remove(this.formlyConfigList, formlyConfig => formlyConfig.id === id);
  }

  public checkExpressions() {
    (this.formlyConfigList || []).forEach((formlyConfig, index) => {
      if ((formlyConfig.options as any)._checkField) {
        (formlyConfig.options as any)._checkField({
          fieldGroup: formlyConfig.formBuilder || [],
          model: formlyConfig.model,
          formControl: formlyConfig.form,
          options: formlyConfig.options,
        });
      }
    });
  }
}
