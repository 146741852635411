import { Injectable } from '@angular/core';
import { FetchApiCallUtils } from '@ice';
import { MULTIPLE_SCROLL_CALLS_ERROR } from 'config/constants/global.constants';
import { ApiCallConfig } from 'config/sections-config/api-call';
import { Subject } from 'rxjs';

@Injectable()
export class DatatableService {
  set scrollApiCall(scrollApiCall: ApiCallConfig | (() => ApiCallConfig)) {
    if (this._scrollApiCall && scrollApiCall) {
      console.warn(`${MULTIPLE_SCROLL_CALLS_ERROR(FetchApiCallUtils.getApiCallConfigName(scrollApiCall), FetchApiCallUtils.getApiCallConfigName(this._scrollApiCall))}`);
    }
    this._scrollApiCall = scrollApiCall;
  }
  get scrollApiCall() {
    return this._scrollApiCall;
  }

  private _scrollApiCall;
  public forceSort = new Subject();
}
