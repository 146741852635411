import { MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AgreementDetail, IpCleaned, SearchUtils } from '@ice';
import { DialogContentComponent } from '@ice/components/dialog-content/dialog-content.component';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { IceLayout } from '@ice/dynamic-components/group-component/group-component';
import { ErrorsUtils } from '@ice/utils/api-responses/errors.utils';
import { Store, select } from '@ngrx/store';
import * as fromApiCalls from 'config/api-calls';
import { CopyrightIpsDataTable } from 'config/data-table-builders/copyright.ips';
import { IpsSearchExpressions, SearchCopyrightIpsForm } from 'config/search-form-builders/search-copyright-ips';
import { get } from 'lodash';
import { BehaviorSubject, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import * as fromRoot from 'store/root';
import { IpSearchDialogsUtils } from './common/ip-search-dialogs-utils';

export class DialogEditLinkedWriter {
  static dialogRef: MatDialogRef<DialogMultiLayoutComponent, any>;

  static openAddWriterDialog(type, dialog, store, storeNewItem, translate, translationLoader: FuseTranslationLoaderService, onSelectSearchItem) {
    const errors$ = new BehaviorSubject(null);
    this.dialogRef = dialog.open(DialogMultiLayoutComponent, {
      data: {
        className: 'dialog-wrapper-width-100vw dialog-search',
        loading: store.pipe(select(fromRoot.getCopyrightLoading)),
        layouts: [this.getIpSearchLayout(type, store, storeNewItem, translate, translationLoader, onSelectSearchItem, errors$), this.getResponseLayout(translate, store, errors$)],
      },
    });

    this.dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => storeNewItem.dispatch(new fromRoot.ResetDialogSearchData()));
  }

  static openDeleteWriterDialog(dialog, store, translate, writerToDelete, onDeleteItem) {
    const errors$ = new BehaviorSubject(null);
    const dialogDeleteWriterReference = dialog.open(DialogContentComponent, {
      disableClose: true,
      data: {
        title: of(`${translate.instant('AGREEMENTS.LINKED_WRITERS.POPUP.DELETE_TITLE')}`),
        ok: translate.instant('POPUP_COMMON.CONFIRM'),
        ko: translate.instant('POPUP_COMMON.CANCEL'),
        preventOkButtonClosePopup: true,
        model: of({}),
        formBuilder: of([
          {
            fieldGroupClassName: 'display-flex-col',
            fieldGroup: [
              {
                template: `${translate.instant('AGREEMENTS.LINKED_WRITERS.POPUP.ARE_YOU_SURE')}<br>${writerToDelete.fullName} - ${writerToDelete.nameKey}`,
              },
            ],
          },
        ]),
        loading: store.pipe(select(fromRoot.getCopyrightLoading)),
        responseMessage: null,
        endButtons: [
          {
            text: of(translate.instant('POPUP.CANCEL')),
            visibleOnError: true,
            action: () => {
              store.dispatch(new fromRoot.CleanResponse());
              dialogDeleteWriterReference.close();
            },
          },
          {
            text: of(translate.instant('POPUP.RETRY')),
            visibleOnError: true,
            action: () => this.deleteWriter(store, writerToDelete, onDeleteItem, dialogDeleteWriterReference, errors$),
          },
        ],
        submitAvailable: true,
        onSubmit: fields => this.deleteWriter(store, writerToDelete, onDeleteItem, dialogDeleteWriterReference, errors$),
      },
    });
  }

  static getIpSearchLayout(type, store, storeNewItem: Store<any>, translate, translationLoader, onSelectSearchItem, errors$): IceLayout {
    const copyrightIpsDatatable = new CopyrightIpsDataTable(translate, translationLoader, store);
    const searchIpsForm = new SearchCopyrightIpsForm(translate, translationLoader);
    const defaultXrefPrefix = null;
    const apiCall = fromApiCalls.searchDialogIps;
    const searchExpressions = new IpsSearchExpressions();
    let formModel = {};
    return {
      title: of(null),
      actions: [],
      layout: [
        {
          group: [
            {
              type: 'formly',
              config: {
                formBuilder: of(searchIpsForm.getForm(type, defaultXrefPrefix)),
                model: IpSearchDialogsUtils.getSearchIpsFormModel(type, storeNewItem),
                submitEnabled: true,
                submitAvailable: true,
                submitLabel: translate.instant('AGREEMENTS.AGREEMENT_PARTIES.BT_SEARCH'),
                submit: $event => {
                  formModel = $event;
                  storeNewItem.dispatch(
                    new fromRoot.StartApiCall({
                      apiCall,
                      apiCallData: { body: JSON.stringify(SearchUtils.generateExpressionCubeData($event, searchExpressions, null)) },
                    }),
                  );
                },
              },
            },
          ],
        },
        {
          group: [
            {
              type: 'dataTable',
              config: {
                data: storeNewItem.select(fromRoot.getCopyrightDialogSearchData).pipe(map(result => result?.items)),
                isLoadingData: storeNewItem.select(fromRoot.getCopyrightLoading),
                schema: copyrightIpsDatatable.getDataTable(),
                sorts: copyrightIpsDatatable.getDefaultSorting(),
                showLoader: true,
                columnMode: copyrightIpsDatatable.getColumnMode(),
                messages: copyrightIpsDatatable.getMessages(),
                selectionType: 'multi',
                onSelect: (selected: any[]) => this.onSelectIp(selected[0], store, onSelectSearchItem, errors$),
                apiCall: () => ({ apiCall, apiCallData: { body: JSON.stringify(SearchUtils.generateExpressionCubeData(formModel, searchExpressions, null)) } }),
              },
            },
          ],
        },
      ],
    };
  }

  static getResponseLayout(translate, store, errors$): IceLayout {
    return {
      title: errors$.pipe(map((errors: string[]) => errors && translate.instant('POPUP.ERROR'))),
      actions: [{ tooltip: translate.instant('POPUP_COMMON.BACK'), nextLayout: 0, icon: 'arrow_back' }],
      layout: [
        {
          group: [
            {
              type: 'response-error',
              config: {
                response: of(null),
                responseButtons: of(null),
                errors: errors$,
                errorButtons: of([]),
              },
            },
          ],
        },
      ],
    };
  }

  static onSelectIp(item: IpCleaned, store, onSelectSearchItem, errors$) {
    if (item) {
      store
        .pipe(
          select(fromRoot.getCopyrightDetailBySection),
          filter(detail => !!detail),
          take(1),
          map((detail: AgreementDetail) => {
            const agreementId = get(detail, 'attributes.key', null);
            const { claimantPartyNameId, claimantPartyId } = this.selectInfoToAddOrDelete(item);

            const body = JSON.stringify({
              attributes: {
                ns: 'ICE',
                claimantPartyNameId,
                claimantPartyId,
                agreementId,
                include: true,
                shares: [],
              },
            });

            this.dialogRef.componentInstance.setLayout(1);
            store.dispatch(
              new fromRoot.StartApiCall({
                apiCall: fromApiCalls.updateAgreementLinkedWritters,
                apiCallData: { labels: { id: agreementId, partyNameId: claimantPartyNameId, partyId: claimantPartyId }, body },
                callBack: (response, errors) => this.afterResponse(response, store, this.dialogRef, errors, errors$, onSelectSearchItem),
              }),
            );
          }),
        )
        .subscribe();
    }
  }

  static afterResponse(response, store, dialog, errors, errors$, onSelectSearchItem) {
    const requestErrors = ErrorsUtils.getResponseErrors(errors) || ErrorsUtils.getResponseErrors(response);
    if (!requestErrors) {
      dialog.close();
      store.dispatch(new fromRoot.RefreshDetail());
      onSelectSearchItem(response);
    } else {
      errors$.next(requestErrors);
    }
  }

  static deleteWriter(store, writerToDelete, onDeleteItem, dialogDeleteWriterReference, errors$) {
    if (writerToDelete) {
      store
        .pipe(
          select(fromRoot.getCopyrightDetailBySection),
          filter(detail => !!detail),
          take(1),
          map((detail: AgreementDetail) => {
            const agreementId = get(detail, 'attributes.key', null);
            const { claimantPartyNameId, claimantPartyId } = this.selectInfoToAddOrDelete(writerToDelete);

            const body = JSON.stringify({
              attributes: {
                ns: 'ICE',
                claimantPartyNameId,
                claimantPartyId,
                agreementId,
                include: false,
                shares: [],
              },
            });
            store.dispatch(
              new fromRoot.StartApiCall({
                apiCall: fromApiCalls.updateAgreementLinkedWritters,
                apiCallData: { labels: { id: agreementId, partyNameId: claimantPartyNameId, partyId: claimantPartyId }, body },
                callBack: (response, errors) => this.afterResponse(response, store, dialogDeleteWriterReference, errors, errors$, onDeleteItem),
              }),
            );
          }),
        )
        .subscribe();
    }
  }

  static selectInfoToAddOrDelete(item) {
    const { baseKey, baseIpiNumber, key, id, ipiNameNumber, partyId } = item;
    const claimantPartyNameId = ipiNameNumber ? `IPI:${ipiNameNumber}` : key || id;
    const claimantPartyId = baseIpiNumber ? `IPI:${baseIpiNumber}` : `ICE:${baseKey}` || partyId;
    return { claimantPartyNameId, claimantPartyId };
  }
}
