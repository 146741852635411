import { Component, ViewEncapsulation } from '@angular/core';
import { FieldType } from '@ngx-formly/material';

@Component({
  selector: 'ice-formly-field-input-icon',
  templateUrl: './input-icon.component.html',
  styleUrls: ['./input-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormlyFieldInputIconComponent extends FieldType {}
