import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivitySearchItemCleaned, ConflictUtils, CurrentOrganization, RouterUtils, SearchUtils, StringUtils } from '@ice';
import { CopyrightUtils } from '@ice/utils';
import { LocalStorageUtils } from '@ice/utils/local-storage/localstorage.utils';
import { NavigationUtils } from '@ice/utils/navigation/navigation.utils';
import { TabsUtils } from '@ice/utils/tabs/tab.utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromRouter from '@ngrx/router-store';
import { ROUTER_CANCEL, ROUTER_ERROR, ROUTER_NAVIGATED, ROUTER_NAVIGATION, ROUTER_REQUEST } from '@ngrx/router-store';
import { Action, select, Store } from '@ngrx/store';
import { SectionsConfig } from 'config/sections-config';
import { get } from 'lodash';
import { CounterClaimSearchItem } from 'models/copyright/search/conflicts-counterclaim-search';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import * as fromRoot from 'store/root';
import { NavigationStates } from 'store/root';
import * as fromUtils from 'store/root/utils';

@Injectable()
export class RouterEffects {
  navigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromRoot.GO),
        withLatestFrom(this.store.pipe(select(fromRoot.getUserCurrentOrganization))),
        map(([action, currentOrganization]: [fromRoot.Go, CurrentOrganization]) => {
          const { path, query, extras, forceReload } = action.payload;
          const queryParams = CopyrightUtils.removeEmptyProperties(query);
          const formattedQueryParams = fromUtils.SearchUtilsFactory.formatQueryParams(queryParams, currentOrganization);
          const formattedExtras = fromUtils.SearchUtilsFactory.formatSearchExtras(extras);
          if (!forceReload) {
            return this.router.navigate(path, { queryParams: formattedQueryParams, ...formattedExtras });
          } else {
            return this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(path, { queryParams: formattedQueryParams, ...formattedExtras }));
          }
        }),
      ),
    { dispatch: false },
  );

  routerRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRouter.ROUTER_REQUEST, fromRouter.ROUTER_NAVIGATION, fromRouter.ROUTER_CANCEL, fromRouter.ROUTER_ERROR, fromRouter.ROUTER_NAVIGATED),
      withLatestFrom(this.store.pipe(select(fromRoot.getRouterSection))),
      map(([action, lastSection]: [Action, string]) => {
        let newRouterState: NavigationStates;
        switch (action.type) {
          case ROUTER_REQUEST:
            newRouterState = NavigationStates.routerRequest;
            break;
          case ROUTER_NAVIGATION:
            newRouterState = NavigationStates.routerNavigation;
            break;
          case ROUTER_CANCEL:
            newRouterState = NavigationStates.routerCancel;
            break;
          case ROUTER_ERROR:
            newRouterState = NavigationStates.routerError;
            break;
          case ROUTER_NAVIGATED:
            newRouterState = NavigationStates.routerNavigated;
            break;
        }

        return new fromRoot.SaveCurrentNavigationEvent({
          state: newRouterState,
          nextUrl: {
            url: this.router.url,
            isNewElementPage: RouterUtils.isNewElementPage(this.router.url),
          },
          lastSection,
        });
      }),
    ),
  );

  navigateToNewTab$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromRoot.OPEN_NEW_TAB),
        map((action: fromRoot.OpenNewTab) => action.payload),
        withLatestFrom(this.store.pipe(select(fromRoot.getRouterSection))),
        tap(([{ url, path, id }, section]) => {
          if (url) {
            window.open(url);
          } else if (path.length === 0) {
            const sectionData = CopyrightUtils.getSectionData(section);
            const { domainName, name } = sectionData;
            const defaultTabName = TabsUtils.getDefaultTabName(section);
            window.open(`${domainName}/${name}/${id}/${defaultTabName}`);
          } else {
            window.open(path[0]);
          }
        }),
      ),
    { dispatch: false },
  );

  navigateBack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromRoot.BACK),
        tap(() => this.location.back()),
      ),
    { dispatch: false },
  );

  navigateForward$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromRoot.FORWARD),
        tap(() => this.location.forward()),
      ),
    { dispatch: false },
  );

  returnToSearchResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.GO_TO_SEARCH_RESULTS),
      withLatestFrom(
        this.store.pipe(select(fromRoot.getRouterSection)),
        this.store.pipe(select(fromRoot.getSearchResultsInputTerm)),
        this.store.pipe(select(fromRoot.getSearchResultsInputParams)),
        this.store.pipe(select(fromRoot.getSearchHistory)),
      ),
      map(([action, section, term, searchParams, history]) => {
        const redirectSections = NavigationUtils.getRedirectSections(section);
        section = SearchUtils.getLastSearchedSection(history, redirectSections) || section;
        const queryParams = term ? { term: StringUtils.removeExtraSpaces(term) } : searchParams ? searchParams : {};
        return new fromRoot.Go({ path: RouterUtils.getSectionPath(section), extras: { queryParams } });
      }),
    ),
  );

  setRouteProgressBarVisibile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      map(() => new fromRoot.ShowRouterLoadingVisibility(true)),
    ),
  );

  setRouteProgressBarInvisibile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED, ROUTER_CANCEL),
      map(() => new fromRoot.ShowRouterLoadingVisibility(false)),
    ),
  );

  // TODO - maybe should go to previous page, not always to home
  // Jira UI-588
  goToCopyrightHome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.GO_TO_COPYRIGHT_HOME),
      withLatestFrom(this.store.pipe(select(fromRoot.getRouterSection))),
      map(([action, section]) => {
        return new fromRoot.Go({ path: RouterUtils.getSectionPath(section) });
      }),
    ),
  );

  goToConflict$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.GO_TO_CONFLICT),
      map(action => action['payload']),
      map((payload: { conflict: ActivitySearchItemCleaned; openInNewTab?: boolean }) => {
        let path: string[];
        const { conflict, openInNewTab } = payload;
        const conflictSubType = get(conflict, 'conflictSubType', '');
        if (ConflictUtils.goToWork(conflictSubType)) {
          const defaultTabName = TabsUtils.getDefaultTabName(SectionsConfig.WORKS.name);
          path = [`/${SectionsConfig.WORKS.domainName}/${SectionsConfig.WORKS.name}/${conflict.workId}/${defaultTabName}`];
        } else if (conflictSubType === 'MISSING_CREATOR_LINK_DATA') {
          const section = SectionsConfig.CONFLICTS.name;
          const { conflictId, workId } = payload.conflict;
          this.store.dispatch(new fromRoot.BeginEditClaim({ conflictId }));
          if (openInNewTab) {
            path = [`${SectionsConfig.WORKS.domainName}/${SectionsConfig.WORKS.name}/${workId}/edit-claim/edit?editConflictId=${conflictId}`];
          } else {
            path = [`${SectionsConfig.WORKS.domainName}/${SectionsConfig.WORKS.name}/${workId}/edit-claim/edit`];
          }
        } else {
          path = [`${SectionsConfig.CONFLICTS.domainName}/${SectionsConfig.CONFLICTS.name}/${conflict.id}/${TabsUtils.getActivityTab(conflict)}`];
        }
        if (openInNewTab) {
          return new fromRoot.OpenNewTab({ path });
        } else {
          return new fromRoot.Go({ path });
        }
      }),
    ),
  );

  goToCounterclaim$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.GO_TO_COUNTERCLAIM),
      map(action => action['payload']),
      map((payload: { counterclaim: CounterClaimSearchItem; openInNewTab?: boolean }) => {
        const { counterclaim, openInNewTab } = payload;
        const path = [
          `${SectionsConfig.COUNTERCLAIMS.domainName}/${SectionsConfig.COUNTERCLAIMS.name}/${counterclaim.id}/${TabsUtils.getDefaultTabName(SectionsConfig.WORKS.name)}`,
        ];
        if (openInNewTab) {
          return new fromRoot.OpenNewTab({ path });
        } else {
          return new fromRoot.Go({ path });
        }
      }),
    ),
  );

  saveXrefAndNavigate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromRoot.SaveXrefAndNavigate>(fromRoot.SAVE_XREF_AND_NAVIGATE),
      map(action => {
        this.store.dispatch(new fromRoot.SetSearchXrefList(action.payload.xref.slice(0, 1000)));
        LocalStorageUtils.removeXrefList();
        return new fromRoot.Go({
          path: [SectionsConfig.WORKS.domainName, SectionsConfig.WORKS.name, 'search'],
          extras: { queryParams: { xrefList: true } },
        });
      }),
    ),
  );

  constructor(private actions$: Actions, private router: Router, private location: Location, private store: Store<fromRoot.RootState>, private route: ActivatedRoute) {}
}
