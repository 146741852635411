<mat-expansion-panel
  [expanded]="expanded | async"
  (opened)="onOpened()"
  (closed)="onClosed()"
  [hideToggle]="hideExpansionIcon"
  id="{{ listIdentifier ? 'expansion-list-' + listIdentifier : '' }}"
  [ngClass]="cardClass"
>
  <mat-expansion-panel-header class="mat-accent-bg" [ngClass]="className">
    <mat-panel-title>
      <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <h3 class="ice-txt-white header-text">{{ title }} ({{ (model | filter: filterValue:'value')?.length }})</h3>
        <button mat-icon-button *ngIf="actionButton" [matTooltip]="actionButton.tooltip" (click)="actionButton.onClick()">
          <mat-icon class="mat-icon material-icons" [ngClass]="actionButton.class">{{ actionButton.icon }}</mat-icon>
        </button>
      </div>
    </mat-panel-title>
    <mat-panel-description> </mat-panel-description>
    <mat-icon
      *ngIf="(iconConflict | async) && model && model.length"
      [matTooltip]="tooltipIconConflict | async"
      class="nav-link-icon button-header ice-yellow button-title-dropdown"
      >{{ iconConflict | async }}</mat-icon
    >
    <mat-icon *ngIf="buttonTitle && model && model.length" (click)="clickHeaderButton($event, extraData)" class="nav-link-icon button-header button-title-dropdown">{{
      buttonTitle
    }}</mat-icon>
  </mat-expansion-panel-header>
  <mat-form-field class="ice-mt-5" *ngIf="model && model.length > filterItemsOn">
    <input matInput type="text" placeholder=" {{ 'FORM_FILTER' | translate }}" [(ngModel)]="filterValue" (ngModelChange)="filterChanged($event)" />
    <button mat-button *ngIf="filterValue" matSuffix mat-icon-button aria-label="Clear" (click)="clear($event)">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <div class="ice-overflow-auto" [ngStyle]="{ 'max-height.px': maxHeight, 'min-height.px': (height | async) || 0 }">
    <div *ngFor="let item of model | filter: filterValue:'value'; let elemIndex = index">
      <div
        [class.cursor-pointer]="selectable"
        [ngClass]="{ highlight: item.highlight }"
        [attr.data-elem-index]="elemIndex"
        (click)="onSelect(item)"
        (mouseup)="onMouseWheelClickSelect($event, item)"
        class="ice-p-8 expansion-list-elem"
      >
        <span [matTooltip]="item.tooltip | translate" [matTooltipPosition]="'above'" [matTooltipClass]="'ice-tooltip ice-tooltip-select'">{{ item.value }}</span>
      </div>
    </div>
  </div>
</mat-expansion-panel>
