import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { RouterUtils } from '@ice';
import { locale as english } from '@ice/i18n/en/error-messages';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PB_COUNTERCLAIM } from 'config/constants/global.constants';
import { SectionRouterViews } from 'config/sections-config';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { PermissionsService } from 'services/permissions/permissions.service';
import * as fromRoot from 'store/root';
import { errorMessageShowtime } from 'store/root/effects/ui/show-error';

@Injectable()
export class PermissionsGuard implements CanActivate {
  constructor(
    private store: Store<fromRoot.AuthState>,
    private router: Router,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    private permissionsService: PermissionsService,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.store.pipe(
      select(fromRoot.getUserCurrentOrganization),
      filter(userCurrentOrganization => !!userCurrentOrganization),
      withLatestFrom(this.store.pipe(select(fromRoot.getUserDetail))),
      map(([userCurrentOrganization, userDetail]) => {
        const { params, section, view } = RouterUtils.extractActivatedRouterData(route);
        const { path } = route.routeConfig;
        const canPath = this.permissionsService.can(`${path}_navbar`);
        const canCreate = this.permissionsService.can(`${section}_bt_new`);
        const canView = this.permissionsService.can(`${section}_navbar`);

        const isUserProfilePage = SectionRouterViews.detail && get(params, 'key') && get(userDetail, 'id') && params.key === userDetail.id;

        if (isUserProfilePage || (canPath && (!section || (canCreate && view === SectionRouterViews.new) || (canView && view !== SectionRouterViews.new)))) {
          return true;
        } else {
          if (window.location.pathname !== '/') {
            this.store.dispatch(
              new fromRoot.IceError({
                message: this.translate.instant('PERMISSION_GUARD'),
                severity: 'error',
              }),
            );
          }
          if (this.permissionsService.userHasRole(PB_COUNTERCLAIM)) {
            this.router.navigate(['/conflicts/counter-claims/dashboard']);
          } else {
            this.router.navigate(['/app/works']);
          }
          return false;
        }
      }),
    );
  }
}
