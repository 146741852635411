import { CopyrightUtils, IpUtils, TerritoryUtils } from '@ice';
import { mrRights, mrRightsSet, prRights, prRightsSet, societiesICE } from 'config/constants/ips.constants';
import { RIGHT_MR, RIGHT_PR, WORK_CLAIM_SHARE_TYPE } from 'config/constants/shares.constants';
import { TerritoryDataType } from 'config/constants/territories.constants';
import { ALL_MR, ALL_PR } from 'config/constants/works.constants';
import { concat, difference, flatMap, flattenDeep, get, groupBy, intersection, mapValues, reduce } from 'lodash';
import moment from 'moment';
import { ContributorsUtils } from '../contributors/contributors.utils';
import { SocietiesUtils } from '../societies/societies.utils';
import { ClaimsUtils } from './claims.utils';

export class WorkClaimsUtils {
  static workClaimsCleaner(claims, translate, claimsFilter?) {
    return flatMap(
      ClaimsUtils.sortSubmittedClaimsByPath(ClaimsUtils.getClaimsByPath(claims || [])).map(claim => {
        let name: string;
        if (claim?.claimant?.partyName) {
          name = ClaimsUtils.getClaimantFullNameFromClaim(claim);
        } else {
          name = get(claim, 'auxFields.name');
        }
        const claimShares = get(claim, 'claimShares', []);
        const typeOf = get(claim, `claimant.party.attributes.typeOf`);
        name = IpUtils.cleanNameLabel(name, typeOf) || name;
        const ipiNumber = claim?.claimant?.partyName ? IpUtils.selectIPINumber(get(claim, `claimant.partyName.relations`)) : get(claim, 'auxFields.ipiNameNumber');
        const ipiBaseNumber = claim?.claimant?.party ? IpUtils.selectIPINumber(get(claim, `claimant.party.relations`)) : get(claim, 'auxFields.ipiBaseNameNumber');
        const partyNameId = get(claim, 'claimant.partyNameId', '');
        const partyId = get(claim, 'claimant.partyId', '');
        const ipiNumberFallback = (partyNameId.includes('IPI') && partyNameId) || '';
        const ipiBaseNumberFallback = (partyId.includes('IPI') && partyId) || '';
        const claimId = claim.claimId;
        const { societyCodePr, societyCodePrTooltip, societyCodePrIcon, societyCodeMr, societyCodeMrTooltip, societyCodeMrIcon } = this.getSocietiesCodes(claim, claimsFilter);
        const isIncomeParticipant = claim?.extensions?.incomeParticipant?.[0] === 'true';
        const incomeParticipantIcon = isIncomeParticipant ? CopyrightUtils.getIncomeparticipantIconWithTooltip(translate) : undefined;
        let counterClaimIcon;
        return [
          ...claimShares.map((cShare, index) => ({ ...cShare, type: WORK_CLAIM_SHARE_TYPE.SHARES, index })),
          ...get(claim, `ownershipShares`, []).map((oShare, index) => ({ ...oShare, type: WORK_CLAIM_SHARE_TYPE.OWNERSHIP, index })),
        ].map(tShare => {
          counterClaimIcon = tShare.hasNotResolvedCounterclaim ? CopyrightUtils.getCounterclaimIconWithTooltip() : undefined;
          const { prShares, mrShares } = SocietiesUtils.getPrMrShares(tShare);
          const prSharesString = `${(prShares / 100).toFixed(2)} %`;
          const mrSharesString = `${(mrShares / 100).toFixed(2)} %`;
          const unauthorized = tShare.unauthorized;
          const unauthorizedIcon = tShare.unauthorized ? CopyrightUtils.getUnauthorizedIconWithTooltip(translate) : undefined;
          const alertIcons = flattenDeep([incomeParticipantIcon, unauthorizedIcon, counterClaimIcon].filter(item => item !== undefined));
          return {
            role: `${' '.repeat(claim.path.length - 1)}${claim.role}`,
            roleRaw: claim.role,
            name,
            path: claim.path,
            ipiNumber: CopyrightUtils.getKeySuffix(ipiNumber) || CopyrightUtils.getKeySuffix(ipiNumberFallback),
            ipiBaseNumber: CopyrightUtils.getKeySuffix(ipiBaseNumber) || CopyrightUtils.getKeySuffix(ipiBaseNumberFallback),
            claimId,
            index: tShare.index,
            type: tShare.type,
            priorDate: tShare.priorRoyaltiesDate,
            startDate: tShare.startDate,
            endDate: tShare.endDate,
            postTermCollectionDate: tShare.postTermCollectionDate,
            territories: tShare.territories,
            territoriesTisa: TerritoryUtils.convertTerritoryArrayElements(tShare.territories, TerritoryDataType.TISA).join(''),
            territoriesTooltip: TerritoryUtils.getTerritoriesNamesTooltipText(TerritoryUtils.convertTerritoryArrayElements(tShare.territories, TerritoryDataType.NAME)),
            rights: this.getRightsDisplayValue(tShare.rightTypes, translate),
            share: `${(tShare.share / 100).toFixed(2)} %`,
            roleLabel: ContributorsUtils.getRoleLabelFromRoleValue(claim.role),
            alert: unauthorized || isIncomeParticipant || false,
            alertIcons,
            prSoc: societyCodePr,
            prSocTooltip: societyCodePrTooltip,
            prSocIcon: societyCodePrIcon,
            mrSoc: societyCodeMr,
            mrSocTooltip: societyCodeMrTooltip,
            mrSocIcon: societyCodeMrIcon,
            prShares: prSharesString,
            mrShares: mrSharesString,
            pr: prShares,
            mr: mrShares,
            prTooltip: prSharesString,
            mrTooltip: mrSharesString,
            rightTypes: tShare.rightTypes,
            level: claim.path ? claim.path.length + 1 : 0,
            rowClass: tShare.hasNotResolvedCounterclaim ? 'unresolved-cc-row' : '',
          };
        });
      }),
    );
  }

  static getFormattedCodesAndTooltipsByRight(societyCodes, right: typeof RIGHT_MR | typeof RIGHT_PR) {
    const defaultDisplaySocietyCode = '099';
    const defaultValue = SocietiesUtils.formatSocietyCodeNameFirst(defaultDisplaySocietyCode);
    const sortBySocietyCodes = (a, b) => +societiesICE.includes(b) - +societiesICE.includes(a);

    const matchingSocietyCodes = societyCodes
      .filter(society => society.rights.includes(right))
      .map(society => society.code)
      .sort(sortBySocietyCodes);

    const hasIceSociety = matchingSocietyCodes.some(code => societiesICE.includes(code));
    const icon = matchingSocietyCodes.length > 1 && SocietiesUtils.generateIsICESocietyIcons({ isICE: hasIceSociety, excludeMargin: true })[0];

    const formattedSocietyCodes = matchingSocietyCodes.map(SocietiesUtils.formatSocietyCodeNameFirst);
    return {
      code: formattedSocietyCodes[0] || defaultValue,
      tooltip: formattedSocietyCodes.join(', ') || defaultValue,
      icon,
    };
  }

  static getSocietiesCodes(claim: any, claimsFilter) {
    const societies = get(claim, 'claimant.party.societies', get(claim, 'claimant.partyName.societies'));
    const societiesArray = Array.isArray(societies) ? societies : [];

    const filteredSocieties = !claimsFilter
      ? societiesArray
      : societiesArray.filter(society => {
          const { memberships } = society;
          const societyHasMatchingMembership = memberships.some(membership => {
            const { startDate, endDate, territories, rights } = membership;
            const { territory: filterTerritories, mrRight: filterMrRight, prRight: filterPrRight, usageDate: filterUsageDate } = claimsFilter;

            const isTerritoryCorrect = this.isTerritoryMatchFilter({ claimTerritories: territories, filterTerritories });
            const isRightTypeMatch = this.isRightTypeMatchFilter({ claimRightTypes: rights, filterPrRight, filterMrRight });
            const isDateCorrect = this.isDateCorrectFilter({ startDate, endDate, usageDate: filterUsageDate });

            return isTerritoryCorrect && isRightTypeMatch && isDateCorrect;
          });

          return societyHasMatchingMembership;
        });

    const societyCodes = filteredSocieties.map((society: any) => ({
      code: SocietiesUtils.getSocietyCode(society.societyId),
      rights: society.memberships.map(member => member.rights || []).reduce((acc, it) => [...acc, ...it], []),
    }));

    const { code: societyCodePr, tooltip: societyCodePrTooltip, icon: societyCodePrIcon } = this.getFormattedCodesAndTooltipsByRight(societyCodes, RIGHT_PR);
    const { code: societyCodeMr, tooltip: societyCodeMrTooltip, icon: societyCodeMrIcon } = this.getFormattedCodesAndTooltipsByRight(societyCodes, RIGHT_MR);

    return {
      societyCodePr,
      societyCodePrTooltip,
      societyCodePrIcon,
      societyCodeMr,
      societyCodeMrTooltip,
      societyCodeMrIcon,
    };
  }

  static decomposeWorkClaimsByRightType(claims) {
    const decomposedClaims = [];
    claims.forEach(claim => {
      const claimPrRights = claim.rightTypes.filter(rightType => prRightsSet.has(rightType));
      const claimMrRights = claim.rightTypes.filter(rightType => mrRightsSet.has(rightType));

      if (claimPrRights.length && claimMrRights.length) {
        decomposedClaims.push({ ...claim, rightTypes: claimPrRights });
        decomposedClaims.push({ ...claim, rightTypes: claimMrRights });
      } else {
        decomposedClaims.push(claim);
      }
    });
    return decomposedClaims;
  }

  static isDateCorrectFilter({ usageDate, endDate, startDate }) {
    return (
      !usageDate ||
      (moment(startDate).isSameOrBefore(moment(usageDate)) && moment(endDate).isSameOrAfter(moment(usageDate))) ||
      (!startDate && !endDate) ||
      (!startDate && moment(endDate).isSameOrAfter(moment(usageDate))) ||
      (moment(startDate).isSameOrBefore(moment(usageDate)) && !endDate)
    );
  }

  static isTerritoryMatchFilter({ claimTerritories, filterTerritories }) {
    const flatTerritories = TerritoryUtils.flatTerritories(claimTerritories);
    const intersectionData = intersection(flatTerritories, filterTerritories);
    return !filterTerritories || !!intersectionData.length;
  }

  static isRightTypeMatchFilter({ claimRightTypes, filterPrRight, filterMrRight }) {
    const isPrClaim = !!intersection(claimRightTypes, prRights).length;

    return isPrClaim
      ? !filterPrRight || !!intersection([filterPrRight, ALL_PR], claimRightTypes).length
      : !filterMrRight || !!intersection([filterMrRight, ALL_MR], claimRightTypes).length;
  }

  static filterWorkClaims(workClaims, claimsFilter, mode, translate) {
    return this.decomposeWorkClaimsByRightType(workClaims)
      .filter(claim => claim.type === mode)
      .filter(claim => {
        if (!claimsFilter) {
          return true;
        }

        const { territory, mrRight, prRight, usageDate, removeE, removeSE } = claimsFilter;

        const isRightTypeMatch = this.isRightTypeMatchFilter({ claimRightTypes: claim.rightTypes, filterPrRight: prRight, filterMrRight: mrRight });

        const filterE = !removeE || !['E', 'SE'].includes(claim.role.replace(/&nbsp;/g, ''));
        const filterSE = !removeSE || claim.role.replace(/&nbsp;/g, '') !== 'SE';

        const isTerritoryCorrect = this.isTerritoryMatchFilter({ claimTerritories: claim.territories, filterTerritories: territory });

        const { startDate, endDate } = claim;
        const isDateCorrect = this.isDateCorrectFilter({ startDate, endDate, usageDate });

        return isTerritoryCorrect && isRightTypeMatch && isDateCorrect && filterE && filterSE;
      })
      .map((claim, index) => {
        claim.rights = this.getRightsDisplayValue(claim.rightTypes, translate);
        claim.hierarchy = index;
        return claim;
      });
  }

  static getRightsDisplayValue(rightTypes, translate) {
    const hasAllPr = difference(prRights, rightTypes).length === 0;
    const hasAllMr = difference(mrRights, rightTypes).length === 0;
    let rights = rightTypes.slice();

    if (hasAllPr) {
      if (hasAllMr) {
        rights = [translate.instant('WORKS.WORK_CLAIMS.ALL_PR_MR')];
      } else if (prRights.length === rightTypes.length) {
        rights = [translate.instant('WORKS.WORK_CLAIMS.ALL_PR')];
      }
    } else if (hasAllMr && mrRights.length === rightTypes.length) {
      rights = [translate.instant('WORKS.WORK_CLAIMS.ALL_MR')];
    }

    return rights;
  }

  static groupClaimsRowsByMode(workClaimsFiltered: any[], mode): any[] {
    return this.groupClaimsRows(workClaimsFiltered.filter(claim => claim.type === mode));
  }

  static groupClaimsRows(workClaimsFiltered: any[]): any[] {
    const groupsByUniqueKey = this.getGroupRightsByUniqueKey(workClaimsFiltered);
    const mergeGroupsDataObject = this.getMergeGroupsData(groupsByUniqueKey);
    return this.changeObjectValuesToArray(mergeGroupsDataObject);
  }

  static getGroupRightsByUniqueKey(workClaimsFiltered: any[]): object {
    return groupBy(workClaimsFiltered, item => {
      const { claimId, role, ipiNumber, prSoc, mrSoc, priorDate, startDate, endDate, postTermCollectionDate, territoriesTisa, unauthorized } = item;
      return [claimId, role, ipiNumber, prSoc, mrSoc, priorDate, startDate, endDate, postTermCollectionDate, territoriesTisa, unauthorized].join('/');
    });
  }

  static getMergeGroupsData(groupsByUniqueKey: object) {
    return mapValues(groupsByUniqueKey, (value: any) => {
      return reduce(value, (acc, current) => {
        let rights = '';
        let prShares;
        let mrShares;
        let rightTypes;
        let pr;
        let mr;
        let prTooltip;
        let mrTooltip;
        ({ prShares, mrShares, rightTypes, pr, mr, prTooltip, mrTooltip } = acc);
        if (SocietiesUtils.checkClaimHasRights(current.rightTypes, prRights)) {
          rights = `${current.rights}/${acc.rights}`;
          ({ prShares, pr, prTooltip } = current);
          rightTypes = concat(acc.rightTypes, current.rightTypes);
        }
        if (SocietiesUtils.checkClaimHasRights(current.rightTypes, mrRights)) {
          rights = `${acc.rights}/${current.rights}`;
          ({ mrShares, mr, mrTooltip } = current);
          rightTypes = concat(acc.rightTypes, current.rightTypes);
        }
        return { ...acc, rights, prShares, mrShares, rightTypes, mr, pr, mrTooltip, prTooltip };
      });
    });
  }

  private static changeObjectValuesToArray(mergeGroupsDataObject: object): object[] {
    const mergeGroupsDataArray = [];
    mapValues(mergeGroupsDataObject, value => mergeGroupsDataArray.push(value));
    return mergeGroupsDataArray;
  }
}
