import { get } from 'lodash';
import { createLabelResolver } from '../api-call.factory';

export const getWorkId = createLabelResolver({ id: `works[0].work.id` });

export const getIgnoredConflictWorkId = createLabelResolver({
  id: detail => {
    const workAnalysisId = get(detail, 'workAnalysis.id', '');
    const workToCompareId = get(detail, 'workToCompare.id', '');
    return workAnalysisId.replace(/[^0-9]/g, '') > workToCompareId.replace(/[^0-9]/g, '') ? workToCompareId : workAnalysisId;
  },
});

export const getConflictId = createLabelResolver({ id: detail => detail.id });

export const getActivityId = createLabelResolver({
  id: detail => get(detail, 'works[0].work.id', ''),
});

export const getSelectedOptionToCompare = createLabelResolver({ id: `selectedOptionToCompare` });

export const getComparedWorksPatternLabelResolver = createLabelResolver({
  pattern: '{ "equals": {"id":"<<workId>>"} }',
});

export const getWorksAnalysisId = createLabelResolver({ id: `workAnalysis.id` });

export const getComparesTitlesPattern = createLabelResolver({
  pattern: '{ "equals": {"id":"<<workId>>"} }',
  workId: detail => get(detail, `optionsToCompare`, []).map(option => option.value),
});

export const getIgnoreClaimsPattern = createLabelResolver({
  ignoreClaims: detail => JSON.stringify((detail.ignoredClaims || []).map(option => option.claimId)),
});
