import { Search } from '@ice';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

// Entity adapter
export const adapter = createEntityAdapter<any>();

export type SelectedSearchResultsBySection = Base & ExtraParams;

export interface Base {
  [section: string]: any[];
}

export interface ExtraParams {
  id?: string;
}

export interface SearchState extends EntityState<any> {
  input: Search;
  extra: any;
  searchReseted?: boolean;
  searchXrefList?: string[];
  selectedItem: any;
  conflictStatus?: any;
  forceCancel: boolean;
  history?: {
    [section: string]: Search;
  };
  selectedRowsSearchResults?: SelectedSearchResultsBySection;
}

export const initialSearchState: SearchState = adapter.getInitialState({
  input: null,
  extra: null,
  selectedItem: null,
  forceCancel: true,
  history: null,
});
