<ng-container *ngIf="to.dynamicallyDisplay; else default">
  <ng-container *ngIf="to.shouldDisplay$ | async">
    <button
      *ngIf="!to.materialType || to.materialType === 'mat-button'"
      mat-button
      attr.data-testid="{{ to.name }}"
      [color]="to.color"
      [type]="to.type"
      [ngClass]="'btn btn-' + to.btnType"
      (click)="onClick($event)"
      (keydown.enter)="onEnter($event)"
    >
      <ng-container *ngIf="!to.iconPosition || to.iconPosition === 'left'">
        <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
      </ng-container>
      {{ to.text }}
      <ng-container *ngIf="to.iconPosition === 'right'">
        <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
      </ng-container>
    </button>
  </ng-container>
</ng-container>
<ng-template #default>
  <button
    *ngIf="!to.materialType || to.materialType === 'mat-button'"
    mat-button
    attr.data-testid="{{ to.name }}"
    [color]="to.color"
    [type]="to.type"
    [ngClass]="'btn btn-' + to.btnType"
    (click)="onClick($event)"
    (keydown.enter)="onEnter($event)"
  >
    <mat-icon *ngIf="to.icon" [attr.aria-label]="ariaLabel || label">{{ to.icon }}</mat-icon>
    {{ to.text }}
  </button>
</ng-template>

<button *ngIf="to.materialType === 'mat-icon-button'" mat-icon-button [ngClass]="'btn'" (click)="onClick($event)" (keydown.enter)="onEnter($event)">
  <mat-icon *ngIf="to.icon" [attr.aria-label]="ariaLabel || label">{{ to.icon }}</mat-icon>
</button>
<button
  *ngIf="to?.materialType === 'mat-stroked-button'"
  mat-stroked-button
  attr.data-testid="{{ to.name }}"
  [color]="to.color"
  [type]="to.type"
  [ngClass]="'btn btn-' + to.btnType"
  (click)="onClick($event)"
  (keydown.enter)="onEnter($event)"
>
  {{ to.text }}
</button>
<button
  *ngIf="to?.materialType === 'mat-flat-button'"
  mat-flat-button
  attr.data-testid="{{ to.name }}"
  [color]="to.color"
  [type]="to.type"
  [ngClass]="'btn btn-' + to.btnType"
  [disabled]="to.isDisabled && to.isDisabled()"
  (click)="onClick($event)"
  (keydown.enter)="onEnter($event)"
>
  {{ to.text }}
</button>
<ng-template #iconTemplate>
  <mat-icon [attr.aria-label]="ariaLabel || label">{{ to.icon }}</mat-icon>
</ng-template>
