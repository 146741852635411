import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { WorkUtils } from '@ice';
import { FormlyValidatorUtils } from '@ice/utils/formly/formly-validators.utils';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { WORK_SOURCE_OPTIONS } from 'config/constants/works.constants';
import { StepType } from 'config/stepper-builders/stepper-config';
import { get, last } from 'lodash';
import { FieldValidatorService } from 'services/validators/field.validator.service';

export class WorkDetailStep {
  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<any>,
    private dialog: MatDialog,
    private fieldValidatorService: FieldValidatorService,
  ) {}

  getStep(): StepType {
    return {
      label: this.translate.instant('WORKS.STEP_WORK_DETAIL.TITLE'),
      formBuilder: [
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              key: 'originalTitle',
              type: 'ice-input',
              className: 'flex-2',
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                placeholder: this.translate.instant('WORKS.STEP_WORK_DETAIL.ORIGINAL_TITLE'),
                change: (field, $event) => {
                  if (get(this.fieldValidatorService, 'workRegisterFields.additionalClaims')) {
                    this.fieldValidatorService.workRegisterFields.additionalClaims.templateOptions.originalTitleChanged($event?.target?.value || '');
                  }
                },
                required: true,
              },
              validators: {
                requiredValidator: {
                  expression: (c: any) => c.value,
                  message: (error: any, field: FormlyFieldConfig) => this.translate.instant('WORKS.STEP_WORK_DETAIL.ORIGINAL_TITLE_ERROR'),
                },
              },
            },
            {
              className: 'flex-2',
              key: 'source',
              type: 'select',
              defaultValue: '',
              templateOptions: {
                type: 'text',
                placeholder: this.translate.instant('WORKS.STEP_WORK_DETAIL.SOURCE'),
                required: false,
                options: WORK_SOURCE_OPTIONS,
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              key: 'duration',
              wrappers: ['form-field', 'wrapper-input-text'],
              type: 'input',
              className: 'flex-2',
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                placeholder: this.translate.instant('WORKS.STEP_WORK_DETAIL.DURATION'),
              },
              validators: {
                duration: FormlyValidatorUtils.getDurationValidator(this.translate),
                maxDuration: FormlyValidatorUtils.getMaxDurationValidator(this.translate),
              },
              hooks: {
                onInit: field => field.formControl.value && field.formControl.markAsTouched(),
              },
            },
            {
              key: 'purpose',
              type: 'select',
              className: 'flex-2',
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                placeholder: this.translate.instant('WORKS.STEP_WORK_DETAIL.PURPOSE'),
                options: WorkUtils.getPurposeOptions(this.translate),
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-1',
              template: `<h2>${this.translate.instant('WORKS.STEP_WORK_DETAIL.TITLES.TITLE')}</h2>`,
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              key: 'titles',
              type: 'repeat',
              className: 'flex-1',
              modelOptions: {
                updateOn: 'blur',
              },
              fieldArray: {
                fieldGroupClassName: 'display-flex',
                fieldGroup: [
                  {
                    key: 'type',
                    type: 'select',
                    className: 'flex-3 tab-repeat-type',
                    templateOptions: {
                      placeholder: this.translate.instant('WORKS.STEP_WORK_DETAIL.TITLES.TYPE'),
                      options: WorkUtils.getAlternativeTitlesOptions(this.translate),
                    },
                  },
                  {
                    key: 'name',
                    wrappers: ['form-field', 'wrapper-input-text'],
                    type: 'input',
                    className: 'flex-3',
                    templateOptions: {
                      placeholder: this.translate.instant('WORKS.STEP_WORK_DETAIL.TITLES.NAME'),
                    },
                  },
                  {
                    key: 'duration',
                    wrappers: ['form-field', 'wrapper-input-text'],
                    type: 'input',
                    className: 'flex-3',
                    templateOptions: {
                      placeholder: this.translate.instant('WORKS.STEP_WORK_DETAIL.TITLES.DURATION'),
                    },
                    validators: {
                      duration: FormlyValidatorUtils.getDurationValidator(this.translate),
                      maxDuration: FormlyValidatorUtils.getMaxDurationValidator(this.translate),
                    },
                  },
                ],
              },
              templateOptions: {
                onAdd: () => {
                  setTimeout(() => {
                    const element: HTMLElement = last(document.querySelectorAll('.tab-repeat-type .mat-select'));
                    if (element) element.focus();
                  }, 100);
                },
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-1',
              template: `<h2>${this.translate.instant('WORKS.STEP_WORK_DETAIL.XREFS.TITLE')}</h2>`,
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              key: 'xrefs',
              type: 'repeat',
              className: 'flex-1',
              modelOptions: {
                updateOn: 'blur',
              },
              fieldArray: {
                fieldGroupClassName: 'display-flex',
                fieldGroup: [
                  {
                    key: 'type',
                    type: 'select',
                    className: 'flex-2 tab-repeat-xrefs',
                    templateOptions: {
                      placeholder: this.translate.instant('WORKS.STEP_WORK_DETAIL.XREFS.TYPE'),
                      options: WorkUtils.getXrefTypeOptions(this.translate),
                    },
                  },
                  {
                    key: 'value',
                    wrappers: ['form-field', 'wrapper-input-text'],
                    type: 'input',
                    className: 'flex-2',
                    templateOptions: {
                      placeholder: this.translate.instant('WORKS.STEP_WORK_DETAIL.XREFS.VALUE'),
                    },
                  },
                ],
              },
              templateOptions: {
                onAdd: () => {
                  setTimeout(() => {
                    const element: HTMLElement = last(document.querySelectorAll('.tab-repeat-xrefs .mat-select'));
                    if (element) element.focus();
                  }, 100);
                },
              },
            },
          ],
        },
      ],
    };
  }
}
