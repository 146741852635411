import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { FuseSharedModule } from '@fuse/shared.module';
import { FormlyFieldButtonComponent, FormlyFieldInputIconComponent, HtmlTemplateComponent } from '@ice';
import { FormContainerModule } from '@ice/components/form-container/form-container.module';
import { FormlyFieldButtonMenuModule } from '@ice/components/formly-types/button-menu/button-menu.module';
import { FormlyFieldButtonModule } from '@ice/components/formly-types/button-type/button-type.module';
import { FormlyFieldInputIconModule } from '@ice/components/formly-types/input-icon/input-icon.module';
import { GroupComponentModule } from '@ice/dynamic-components/group-component/group-component.module';
import { IceMaterialModule } from '@ice/material.module';
import { IsValidValidator } from '@ice/utils';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { DataTableModule } from '../data-table/data-table.module';
import { DatepickerTypeComponent } from '../datepicker-type/datepicker-type.component';
import { DatepickerTypeModule } from '../datepicker-type/datepicker-type.module';
import { AutocompleteGroupedTypeModule } from '../formly-types/autocomplete-grouped-type/autocomplete-grouped-type.module';
import { AutocompleteTypeModule } from '../formly-types/autocomplete-type/autocomplete-type.module';
import { FormlyFieldButtonMenuComponent } from '../formly-types/button-menu/button-menu.component';
import { DatatableInputTypeComponent } from '../formly-types/datatable-input/datatable-input-type.component';
import { DatatableInputTypeModule } from '../formly-types/datatable-input/datatable-input-type.module';
import { DatatableSelectTypeComponent } from '../formly-types/datatable-select/datatable-select-type.component';
import { DatatableSelectTypeModule } from '../formly-types/datatable-select/datatable-select-type.module';
import { DatatableTypeComponent } from '../formly-types/datatable-type/datatable-type.component';
import { DatatableTypeModule } from '../formly-types/datatable-type/datatable-type.module';
import { FormlyFieldLabelComponent } from '../formly-types/label-type/label-type.component';
import { FormlyFieldLabelModule } from '../formly-types/label-type/label-type.module';
import { RepeatTypeComponent } from '../formly-types/repeat-type/repeat-type.component';
import { RepeatTypeModule } from '../formly-types/repeat-type/repeat-type.module';
import { SelectWithInfoTypeComponent } from '../formly-types/select-with-info-type/select-with-info-type.component';
import { SelectWithInfoTypeModule } from '../formly-types/select-with-info-type/select-with-info-type.module';
import { SharesRepeatSectionTypeComponent } from '../formly-types/shares-repeat-section-type/shares-repeat-section-type.component';
import { SharesRepeatSectionTypeModule } from '../formly-types/shares-repeat-section-type/shares-repeat-section-type.module';
import { HtmlTemplateModule } from '../html-template/html-template.module';
import { DialogMultiLayoutComponent } from './dialog-multi-layout.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    IceMaterialModule,
    FlexLayoutModule,
    FormsModule,
    FuseSharedModule,
    FormContainerModule,
    FormlyFieldButtonModule,
    FormlyFieldButtonMenuModule,
    FormlyFieldInputIconModule,
    DataTableModule,
    GroupComponentModule,
    SelectWithInfoTypeModule,
    DatepickerTypeModule,
    DatatableTypeModule,
    FormlyFieldLabelModule,
    FormlyFieldLabelModule,
    SharesRepeatSectionTypeModule,
    DatatableInputTypeModule,
    DatatableSelectTypeModule,
    RepeatTypeModule,
    AutocompleteTypeModule,
    AutocompleteGroupedTypeModule,
    HtmlTemplateModule,
    FormlyModule.forRoot({
      extras: { checkExpressionOn: 'modelChange' },
      types: [
        { name: 'select-with-info', component: SelectWithInfoTypeComponent },
        { name: 'label', component: FormlyFieldLabelComponent },
        { name: 'button', component: FormlyFieldButtonComponent },
        { name: 'button-menu', component: FormlyFieldButtonMenuComponent },
        { name: 'input-icon', component: FormlyFieldInputIconComponent },
        { name: 'datatable-input', component: DatatableInputTypeComponent },
        { name: 'datatable-select', component: DatatableSelectTypeComponent },
        {
          name: 'datepicker',
          component: DatepickerTypeComponent,
          wrappers: ['form-field'],
          defaultOptions: {
            defaultValue: '',
            templateOptions: {
              datepickerOptions: {},
            },
            validators: {
              valid: IsValidValidator,
            },
          },
        },
        {
          name: 'datatable',
          component: DatatableTypeComponent,
          defaultOptions: {
            templateOptions: {
              columnMode: 'force',
              rowHeight: 'auto',
              headerHeight: '40',
              footerHeight: '40',
              scrollbarH: 'true',
              reorderable: 'reorderable',
            },
          },
        },
        { name: 'label', component: FormlyFieldLabelComponent },
        { name: 'shares-repeat', component: SharesRepeatSectionTypeComponent },
        { name: 'repeat', component: RepeatTypeComponent },
        { name: 'htmlTemplate', component: HtmlTemplateComponent },
      ],
    }),
  ],
  declarations: [DialogMultiLayoutComponent],
  exports: [DialogMultiLayoutComponent],
})
export class DialogMultiLayoutModule {}
