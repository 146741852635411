import { environment } from 'config/env';
import { SectionRouterViews } from './section-router-views';
import type { SectionConfig } from './sections-config';

export const SectionCounterClaimsSupport: SectionConfig = {
  name: 'counter-claim-support',
  url: '/conflicts/counter-claim-support',
  domainName: 'conflicts',
  baseUrl: environment.apiUrl,
  baseUrlCubeData: environment.apiUrlCubeData,
  apiSegment: 'counterclaims',
  detailSegment: `counterclaims/CUBE/<<id>>`,
  apiIncludes: {
    search: ``,
    detail: ``,
  },
  searchServerSideSortDefault: '',
  allowedActions: {
    newItem: true,
    editItem: 'stepper',
    newNote: false,
    removeItem: false,
  },
  homeRedirect: 'new',
  hideHomeEditButton: true,
  newItemBtn: [SectionRouterViews.home, SectionRouterViews.search],
};
