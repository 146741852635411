import { Location } from '@angular/common';
import { HttpClient, HttpEventType, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import {
  ApiNamespace,
  CopyrightDetail,
  CopyrightDetailRequest,
  CopyrightUtils,
  CurrentOrganization,
  DateTimeUtils,
  FetchApiCallUtils,
  IpUtils,
  RouterUtils,
  SearchUtils,
  SharePictureUtils,
  TerritoryUtils,
  User,
  WorkUtils,
  sharePicturePayload,
} from '@ice';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { ErrorsUtils } from '@ice/utils/api-responses/errors.utils';
import { AuditHistoryUtils } from '@ice/utils/audit-history/audit-history.utils';
import { EditModeUtils } from '@ice/utils/edit-mode/edit-mode-utils';
import { SectionSendItemCleaner } from '@ice/utils/maps/send-update-map';
import { NotesUtils } from '@ice/utils/notes/notes.utils';
import { ReportUtils } from '@ice/utils/report/report.utils';
import { SectionItemCleaner } from '@ice/utils/section-item-cleaner';
import { TabsUtils } from '@ice/utils/tabs/tab.utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromRouter from '@ngrx/router-store';
import { Action, Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/app/common/shared';
import { locale as sectionNewEnglish } from 'assets/i18n/en/config/section-new-data-builders';
import * as fromApiCalls from 'config/api-calls';
import * as fromApiCallConstructors from 'config/api-calls/constructors';
import { ALL, ICE_PREFIX, noteTypeBySection } from 'config/constants/global.constants';
import { IP_CONSTANTS } from 'config/constants/ips.constants';
import { REPORT_DOWNLOAD } from 'config/constants/reports.constants';
import { EVENT_ID } from 'config/constants/works.constants';
import { environment } from 'config/env';
import { SectionsConfig } from 'config/sections-config';
import { ApiCall, ApiCallData, ApiCallStatus, ApiResponseStatus, CustomApiCall, SequenceType, isApiCallPayload, isCustomApiCall } from 'config/sections-config/api-call';
import { SectionCopyrightWorks } from 'config/sections-config/section-copyright-works';
import { sections } from 'config/sections-config/sections';
import { saveAs } from 'file-saver';
import flagsmith from 'flagsmith';
import { cloneDeep, drop, find, get, isArray, isEmpty, isEqual, reverse, uniqBy, uniqWith, values } from 'lodash';
import { PayloadAuditHistoryFilter } from 'models/action-payloads/audit-history-filter.payload';
import { PayloadTerritoriesFilter } from 'models/action-payloads/territories-filter.payload';
import { CounterClaimDetail } from 'models/copyright/detail/counterclaim';
import { EditAction } from 'models/copyright/detail/edit';
import { OrganizationDetail } from 'models/copyright/detail/organizations';
import { ExportMode, ExportModeType } from 'models/copyright/search/export-mode';
import { SelectMode, SelectModeEnum } from 'models/copyright/search/select-mode';
import { UpdateMode, UpdateModeType } from 'models/copyright/search/update-mode';
import { PageableData } from 'models/response/cube-register.response';
import { NotesResponse } from 'models/response/notes.response';
import { SearchIpsParser } from 'models/search-parsers/search-ips-parser';
import { UserCleaned } from 'models/users/users.model';
import moment from 'moment';
import { BehaviorSubject, EMPTY, Subscription, forkJoin, from, merge, of } from 'rxjs';
import { catchError, concatMap, delay, expand, filter, finalize, last, map, mapTo, mergeMap, reduce, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { DetailService } from 'services/detail/detail.service';
import { PermissionsService } from 'services/permissions/permissions.service';
import { ExportService, FileType } from 'services/report/report.service';
import { SaveItemService } from 'services/save-item/save-item.service';
import { SearchService } from 'services/search/search.service';
import * as fromNewSectionItem from 'store/new-section-item';
import * as fromRoot from 'store/root';
import { FetchApiCall, SetExportModeBatch } from 'store/root/actions';
import { CopyrightItemView, CopyrightState, SelectedSearchResultsBySection, initialCopyrightState } from 'store/root/state';
import * as uuid from 'uuid';
import { EditMode } from 'models/copyright/detail/edit-mode';
import { RowNote } from '../../../../models/notes/notes';
import { errorMessageShowtime } from '../ui/show-error';
const CLAIMS_VOID = { claims: [] };
const ERROR_MESSAGE_DURATION = 10000;

@Injectable()
export class CopyrightEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.RootState>,
    private storeNewItem: Store<fromNewSectionItem.NewSectionItemState>,
    private permissionsService: PermissionsService,
    private detailService: DetailService,
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private searchService: SearchService,
    private dialog: MatDialog,
    private saveItemService: SaveItemService,
    private exportService: ExportService,
    private location: Location,
    private router: Router,
    private http: HttpClient,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.fuseTranslationLoader.loadTranslations(sectionNewEnglish);
  }

  setCounterclaimDashboardSearchFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.SET_COUNTERCLAIM_DASHBOARD_SEARCH_FILTER),
      map((action: fromRoot.SetCounterclaimDashboardSearchFilter) => {
        return new fromRoot.StartApiCall(fromApiCalls.getCounterclaimActionsForDashboard);
      }),
    ),
  );

  getOptionsToCompareDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.GET_OPTION_TO_COMPARE_DETAILS),
      map((action: fromRoot.GetOptionsToCompareDetails) => action.payload),
      map(options => {
        const apiCallBody = {
          or: options.map(option => ({
            equals: {
              'relations.otherId': option.value,
            },
          })),
        };
        this.store.dispatch(
          new fromRoot.StartApiCall({
            apiCall: fromApiCalls.searchWorks,
            apiCallData: {
              body: JSON.stringify(apiCallBody, null, 4),
            },
            callBack: (result, error) => {
              if (!error) {
                this.store.pipe(select(fromRoot.getConflictsActivity), take(1)).subscribe({
                  next: activity => {
                    const { works } = activity;
                    const getWorkToCompareDetails = selectedOption => {
                      this.store.dispatch(new fromRoot.SetSelectedOptionToCompare({ key: selectedOption }));
                      this.store.dispatch(new fromRoot.StartApiCall({ apiCall: fromApiCalls.getWorkToCompare }));
                    };
                    const tempOptionsToCompare = result.items
                      .filter(item => item.id !== works[0].workId)
                      .map(item => ({ value: item.id, viewValue: item.id }))
                      .sort((a, b) => Number(CopyrightUtils.getSuffixAsNumber(a.value)) - Number(CopyrightUtils.getSuffixAsNumber(b.value)));
                    if (!tempOptionsToCompare[0]) {
                      // TODO: revisit how this component behaves. It was throwing an error here when trying to access `tempOptionsToCompare[0].value`.
                      // I am now throwing on purpose to re-trigger the effect.
                      throw new Error('Nothing to compare');
                    }
                    if (CopyrightUtils.getSuffixAsNumber(tempOptionsToCompare[0].value) === CopyrightUtils.getSuffixAsNumber(works[0].workId)) {
                      const rightOptionToCompare = tempOptionsToCompare.find(obj => obj.viewValue !== works[0].workId);
                      if (rightOptionToCompare) {
                        getWorkToCompareDetails(rightOptionToCompare.viewValue);
                      }
                    } else {
                      getWorkToCompareDetails(tempOptionsToCompare[0].viewValue);
                    }
                    this.store.dispatch(new fromRoot.SetOptionsToCompare(uniqBy(tempOptionsToCompare, 'viewValue')));
                  },
                });
              }
            },
          }),
        );
        return new fromRoot.SetOptionsToCompare([]);
      }),
    ),
  );

  getCopyrightDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.GET_ITEM, fromRoot.GET_ITEM_TO_EDIT),
      map((action: fromRoot.GetItem | fromRoot.GetItemToEdit) => action.payload),
      withLatestFrom(
        this.store.pipe(select(fromRoot.getRouterSection)),
        this.store.pipe(select(fromRoot.getRouterPaths)),
        this.store.pipe(select(fromRoot.getGlobalApiNamespace)),
        this.store.pipe(select(fromRoot.getRouterQueryParams)),
        this.store.pipe(select(fromRoot.getUserCurrentOrganization)),
      ),
      map(([payload, section, routerPaths, ns, queryParams, currentOrganization]: [CopyrightDetailRequest, string, string[], ApiNamespace, any, CurrentOrganization]) => ({
        ...payload,
        section,
        routerPaths,
        ns,
        queryParams,
        currentOrganization,
      })),
      switchMap(request => {
        const { section, queryParams } = request;
        return this.detailService.getDetail(request).pipe(
          map((detail: CopyrightDetail) => {
            if (section === SectionsConfig.IPS.name && !detail['attributes'] && detail['relations'].length < 1) {
              // edge case: Name with no IPBase data. UI-903
              return new fromRoot.GetItemNotFound();
            }
            const sectionCleaner = SectionItemCleaner[section];
            const payload = { [section]: sectionCleaner ? sectionCleaner(detail, this.translate, queryParams) : detail };
            if (section === SectionsConfig.CONFLICTS.name) {
              this.store.dispatch(new fromRoot.GetOptionsToCompareDetails(payload.activity.optionsToCompare || []));
            }
            if (section === SectionsConfig.IPS.name) {
              const id: string = IpUtils.getBaseIdWithPrefix(detail);
              const apiCallData: ApiCallData = { labels: { id, partyNameId: detail.id } };
              const apiCall: ApiCall = this.permissionsService.can('ips_alternative-names') ? fromApiCalls.getIpsPartyNames : fromApiCalls.getIpsPartyNamesById;
              this.store.dispatch(new fromRoot.StartApiCall({ apiCall, apiCallData }));
            }
            return new fromRoot.GetItemSuccess(payload);
          }),
          catchError(error => {
            const errorStatus = error && error.error && error.error.status;
            if (errorStatus === HttpStatusCode.NotFound) {
              return of(new fromRoot.GetItemNotFound());
            } else if (errorStatus === HttpStatusCode.Forbidden) {
              this.router.navigate(['/app/works']);
              this.store.dispatch(new fromRoot.ResetSearch());
              return of(new fromRoot.GetAccessFail({ message: error.message, severity: 'error' }));
            } else {
              return of(new fromRoot.GetItemFail({ message: error.message, severity: 'error' }));
            }
          }),
        );
      }),
    ),
  );

  refreshDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromRoot.RefreshDetail>(fromRoot.REFRESH_DETAIL),
      withLatestFrom(this.store.select(fromRoot.getRouterParams), this.store.select(fromRoot.getRouterSection)),
      concatMap(([_, params, section]) => [new fromRoot.ResetStoreParam({ section, value: initialCopyrightState[section] }), new fromRoot.GetItem({ key: params.key || '' })]),
    ),
  );

  StartApiCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromRoot.StartApiCall>(fromRoot.START_API_CALL),
      filter(action => action.payload && (!isArray(action.payload) || !isEmpty(action.payload))),
      switchMap(action => {
        let apiCallPayload = action.payload;
        const { prevResponse } = action;
        const { sequenceType, sequence } = FetchApiCallUtils.parseApiCallSequence(apiCallPayload);
        apiCallPayload = FetchApiCallUtils.shiftApiCallPayload(apiCallPayload, sequenceType);
        switch (sequenceType) {
          case SequenceType.Concat: {
            return of(
              new fromRoot.FetchApiCall(cloneDeep({ ...FetchApiCallUtils.getCustomApiCall(sequence[0]), ...(apiCallPayload && { nextPayload: apiCallPayload }), prevResponse })),
            );
          }
          case SequenceType.Merge: {
            const mergeId = uuid.v4().replace(/-/g, '');
            return (sequence || []).map(
              apiCallConfig =>
                new fromRoot.FetchApiCall(cloneDeep({ ...FetchApiCallUtils.getCustomApiCall(apiCallConfig, { id: mergeId }), nextPayload: apiCallPayload, prevResponse })),
            );
          }
        }
      }),
    ),
  );

  CancelApiCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromRoot.CancelApiCall>(fromRoot.CANCEL_API_CALL),
      withLatestFrom(this.store.select(fromRoot.getCopyrightApiCallStatus)),
      tap(_ => this.store.dispatch(new fromRoot.ShowDataLoadingVisibility(false))),
      filter(([action, apiCallStatus]) => {
        return FetchApiCallUtils.hasNextPayload(action?.payload?.apiCallConfig, apiCallStatus, null);
      }),
      map(([action, apiCallStatus]) => new fromRoot.StartApiCall(action.payload?.apiCallConfig?.nextPayload)),
    ),
  );

  FetchApiCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromRoot.FetchApiCall>(fromRoot.FETCH_API_CALL),
      map(action => action.payload),
      withLatestFrom(
        this.store.select(fromRoot.getCopyrightFeatureState),
        this.store.select(fromRoot.getUser),
        this.store.select(fromRoot.getGlobalApiNamespace),
        this.store.pipe(select(fromRoot.getRouterSection)),
        this.store.pipe(select(fromRoot.getRouterTab)),
        this.store.select(fromRoot.getCopyrightApiCallStatus),
        this.store.select(fromRoot.getForcedNS),
        this.store.select(fromRoot.getUserCurrentOrganization),
      ),
      map(
        ([apiCallConfig, copyrightData, user, ns, section, tab, apiCallStatus, forceNS, currentOrganization]: [
          CustomApiCall,
          any,
          User,
          string,
          string,
          string,
          ApiResponseStatus,
          boolean,
          CurrentOrganization,
        ]) => ({
          apiCallConfig,
          populatedApiCall: FetchApiCallUtils.populateApiCall(apiCallConfig, copyrightData, user, ns, tab, section),
          section: apiCallConfig.apiCall.responseSection || section,
          checkStatus: apiCallConfig.apiCall.checkStatus,
          tab,
          apiCallStatus,
          ns,
          forceNS,
          user,
          currentOrganization,
        }),
      ),
      filter(({ apiCallConfig, populatedApiCall, apiCallStatus, ns, forceNS, currentOrganization }) => {
        if (!FetchApiCallUtils.canExecuteApiCall(apiCallConfig, populatedApiCall, ns, forceNS, this.permissionsService)) {
          this.store.dispatch(new fromRoot.CancelApiCall({ apiCallConfig }));
          return false;
        }
        return true;
      }),
      tap(({ populatedApiCall }) => populatedApiCall.responseHandler && this.store.dispatch(new fromRoot.SetCopyrightLoading(true))),
      mergeMap(({ apiCallConfig, populatedApiCall, section, checkStatus, tab, apiCallStatus, user, ns, forceNS, currentOrganization }) =>
        this.detailService.makeRequest(populatedApiCall).pipe(
          this.checkStatus$(checkStatus),
          withLatestFrom(this.store.select(fromRoot.getCopyrightFeatureState), this.store.select(fromRoot.getCopyrightApiCallStatus)),
          filter(([result, state, apiCallStatusAfter]) => {
            if (
              apiCallStatusAfter?.status === ApiCallStatus.Cancel &&
              !(
                apiCallConfig?.apiCallData?.id &&
                apiCallConfig?.apiCallData?.id === apiCallStatusAfter?.apiCallId &&
                apiCallStatusAfter?.mergeCount !== apiCallStatusAfter?.mergeTotal
              )
            ) {
              this.store.dispatch(new fromRoot.CancelApiCall({ apiCallConfig }));
              return false;
            }
            return true;
          }),
          map(([result, state, apiCallStatusAfter]) => ({ result, newItems: FetchApiCallUtils.getNewItems(apiCallConfig, result, state, section, this.translate, tab, user) })),
          map(({ result, newItems }) => {
            const itemsLength = get(result, 'items', []).length;
            if (get(apiCallConfig, 'apiCall.fetchAllData')) {
              this.store.dispatch(new fromRoot.SaveAllApiData(result));
              if (get(result, 'total', 0) > itemsLength && isEqual(get(apiCallConfig, 'apiCall.queryParams.size', 0), itemsLength)) {
                return new fromRoot.PaginateApiCall(apiCallConfig);
              }
            }
            return new fromRoot.ApiCallSuccess({ section, apiCallConfig, result, newItems });
          }),
          catchError(error => {
            if (apiCallConfig.retry && apiCallConfig.retry - 1 > apiCallStatus?.retryCount) {
              return of(new fromRoot.ApiCallRetry({ error, apiCallConfig: cloneDeep(apiCallConfig) }));
            }
            const wasCallForbidden = [error?.error?.status, error?.status].includes(HttpStatusCode.Forbidden);
            if (wasCallForbidden) {
              if (apiCallConfig.apiCall.allowForbiddenResponse) {
                return of(new fromRoot.CancelApiCall({ apiCallConfig }));
              }
              this.router.navigate(['/app/works']);
              this.store.dispatch(new fromRoot.ResetSearch());
              return of(new fromRoot.GetAccessFail());
            }
            return of(new fromRoot.ApiCallError({ error, apiCallConfig }));
          }),
          finalize(() => this.store.dispatch(new fromRoot.SetCopyrightLoading(false))),
        ),
      ),
    ),
  );

  retryApiCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromRoot.ApiCallRetry>(fromRoot.API_CALL_RETRY),
      map(action => action.payload),
      mergeMap(({ apiCallConfig, error }) =>
        of(apiCallConfig).pipe(
          delay(apiCallConfig.retryDelay || 2000),
          withLatestFrom(this.store.select(fromRoot.getCopyrightApiCallStatus)),
          tap(([_, apiCallStatus]) => {
            if (apiCallStatus.status === ApiCallStatus.Cancel && apiCallConfig.onCancel) {
              apiCallConfig.onCancel(null, error, apiCallConfig.apiCallData);
            }
          }),
          filter(([_, apiCallStatus]) => apiCallStatus.status !== ApiCallStatus.Cancel),
          map(() => new FetchApiCall(apiCallConfig)),
        ),
      ),
    ),
  );

  paginateApiCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromRoot.PaginateApiCall>(fromRoot.PAGINATE_API_CALL),
      withLatestFrom(
        this.store.select(fromRoot.getCopyrightFeatureState),
        this.store.pipe(select(fromRoot.getRouterSection)),
        this.store.pipe(select(fromRoot.getCopyrightApiCallStatus)),
        this.store.pipe(select(fromRoot.getRouterTab)),
      ),
      filter(([action, copyrightData, section, apiCallStatus]) => {
        const apiCall = FetchApiCallUtils.getApiCall(action.apiCallConfig);
        return !!apiCall?.pageable && (get(apiCall, 'fetchAllData') || FetchApiCallUtils.apiCallIsIdle(apiCallStatus));
      }),
      map(([action, copyrightData, section, apiCallStatus, tab]) => {
        const apiCall = FetchApiCallUtils.getApiCall(action.apiCallConfig);
        const apiCallData = isCustomApiCall(action.apiCallConfig) && action.apiCallConfig.apiCallData;
        const callBack = get(action, 'apiCallConfig.callBack');
        const sectionDetail =
          copyrightData && ((apiCall.responseSection && copyrightData[apiCall.responseSection]) || (apiCall.copyrightGlobal && copyrightData) || copyrightData[section]);
        const property = FetchApiCallUtils.getPageableProperty(apiCall, sectionDetail, this.translate, tab);
        const pageableData: PageableData<any> = get(sectionDetail, property);
        let apiDataFrom = get(apiCall, 'queryParams.from');
        if (!apiDataFrom || apiDataFrom === 0) {
          apiDataFrom = get(apiCallData, 'queryParams.from', 0);
        }
        const pageableInfo = pageableData
          ? {
              count: get(pageableData, 'count', 0),
              total: get(pageableData, 'total', 0),
              sort: get(pageableData, 'sort'),
              hasAllItems: get(pageableData, 'hasAllItems'),
            }
          : {
              count: apiDataFrom + get(apiCall, 'queryParams.size', 0),
              total: get(pageableData, 'total', 0),
              sort: get(pageableData, 'sort'),
              hasAllItems: get(pageableData, 'hasAllItems'),
            };
        return { apiCall, pageableInfo, apiCallData, callBack };
      }),
      filter(
        ({ apiCall, pageableInfo }) =>
          (!apiCall.isAthena && !!pageableInfo && pageableInfo.count < pageableInfo.total) || (apiCall.isAthena && !pageableInfo.hasAllItems) || get(apiCall, 'fetchAllData'),
      ),
      map(
        ({ apiCall, pageableInfo: { count, sort }, apiCallData, callBack }) =>
          new fromRoot.StartApiCall({
            apiCall,
            apiCallData: { ...apiCallData, queryParams: { ...apiCallData.queryParams, from: count, ...(sort && { sort }) } },
            fromScrollEvent: true,
            callBack,
          }),
      ),
    ),
  );

  successApiCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromRoot.ApiCallSuccess | fromRoot.ApiCallError>(fromRoot.API_CALL_SUCCESS, fromRoot.API_CALL_ERROR),
      map(action => action.payload),
      tap(({ apiCallConfig, result, error }) => {
        const callBack = apiCallConfig?.callBack?.(result, error, apiCallConfig.apiCallData);
        if (isApiCallPayload(callBack)) {
          this.store.dispatch(new fromRoot.StartApiCall(callBack));
        }
      }),
      withLatestFrom(this.store.select(fromRoot.getCopyrightApiCallStatus)),
      tap(([{ apiCallConfig, result, error }, apiCallStatus]) => {
        if (apiCallStatus.status === ApiCallStatus.Cancel && apiCallConfig.onCancel) {
          apiCallConfig.onCancel(result, error, apiCallConfig.apiCallData);
        }
      }),
      filter(([{ apiCallConfig, error }, apiCallStatus]) => FetchApiCallUtils.hasNextPayload(apiCallConfig, apiCallStatus, error)),
      map(([{ apiCallConfig, result }]) => new fromRoot.StartApiCall(apiCallConfig.nextPayload, result)),
    ),
  );

  fetchDetailApiCalls$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromRoot.GET_ITEM_SUCCESS),
        map((action: fromRoot.GetItemSuccess) => action.payload),
        withLatestFrom(this.store.pipe(select(fromRoot.getRouterSection)), this.store.pipe(select(fromRoot.getRouterTab))),
        map(([detail, section, tab]: [any, string, string]) => {
          const itemData = section && detail && detail[section];
          const hasVisibleTabs = tab && itemData && itemData.visibleTabs;
          const tabPropsSelected = hasVisibleTabs && find(hasVisibleTabs, tabProps => tabProps.name === tab);
          const currTabVisible: boolean = hasVisibleTabs && ((tabPropsSelected && tabPropsSelected.isVisible) || !tabPropsSelected) ? true : false;
          const key: string = itemData && CopyrightUtils.getUrlKey(section, itemData);
          if (hasVisibleTabs && !currTabVisible && key) {
            const defaultTabName = TabsUtils.getDefaultTabName(section);
            this.store.dispatch(new fromRoot.Go({ path: [`copyright/${section}/${key}/${defaultTabName}`] }));
          }
          const sectionObject = sections && sections[section];
          const tabObject = sectionObject && sectionObject.tabs && sectionObject.tabs[tab];
          this.store.dispatch(new fromRoot.GetItemFullDataEnd());
          let apiCallPayload = FetchApiCallUtils.getSectionInitialApiCalls(tabObject, sectionObject, detail[section], true);
          apiCallPayload = isArray(apiCallPayload) ? (apiCallPayload as any[]).filter(apiCall => !isEmpty(apiCall)) : apiCallPayload;
          if (!isEmpty(apiCallPayload)) {
            this.store.dispatch(new fromRoot.StartApiCall(apiCallPayload));
          }
          const dispatchAction = tabObject && tabObject.dispatchAction;
          if (dispatchAction) {
            this.store.dispatch(new dispatchAction());
          }
        }),
      ),
    { dispatch: false },
  );

  uploadUsingPreSignedURL$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.UPLOAD_USING_PRE_SIGNED_URL),
      switchMap((action: fromRoot.UploadUsingPreSignedURL) => {
        const { url, file } = action.payload;
        this.store.dispatch(new fromRoot.SetCopyrightLoading(true));
        return this.saveItemService.uploadUsingPreSignedURL(url, file).pipe(
          map(result => {
            this.store.dispatch(new fromRoot.SetCopyrightLoading(false));
            return new fromRoot.UploadResponse({ uploadToS3Success: true });
          }),
          catchError(error => {
            this.store.dispatch(new fromRoot.SetCopyrightLoading(false));
            return of(new fromRoot.UploadResponse(error));
          }),
        );
      }),
    ),
  );

  getItemFullDataEnd$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromRoot.GET_ITEM_FULL_DATA_END),
        withLatestFrom(this.store.pipe(select(fromRoot.getCopyrightItemView))),
        filter(([action, view]) => view === fromRoot.CopyrightItemView.Edit),
        map(() => this.store.dispatch(new fromRoot.EditItem())),
      ),
    { dispatch: false },
  );

  captureCopyrightChangeTab$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromRouter.ROUTER_NAVIGATED),
        map((action: fromRouter.RouterNavigationAction) => action.payload),
        withLatestFrom(
          this.store.pipe(select(fromRoot.getRouterSection)),
          this.store.pipe(select(fromRoot.getRouterTab)),
          this.store.pipe(select(fromRoot.getCopyrightDetailBySection)),
        ),
        map(([payload, section, tab, detail]: [any, string, string, any]) => {
          if (!['HOME', 'SEARCH'].includes(payload.routerState.view)) {
            if (detail && !CopyrightUtils.isEditClaimsDetail(detail) && tab) {
              const dispatchAction = sections[section]?.tabs[tab]?.dispatchAction;
              if (dispatchAction) {
                this.store.dispatch(new dispatchAction());
              }
              const refreshAction = sections[section]?.tabs[tab]?.refreshAction;
              if (refreshAction) {
                this.store.dispatch(new refreshAction());
              }
              let apiCallPayload = FetchApiCallUtils.getSectionInitialApiCalls(sections[section].tabs[tab], null, detail);
              apiCallPayload = isArray(apiCallPayload) ? (apiCallPayload as any[]).filter(apiCall => !isEmpty(apiCall)) : apiCallPayload;
              if (!isEmpty(apiCallPayload)) {
                this.store.dispatch(new fromRoot.StartApiCall(apiCallPayload));
              }
            }
          } else {
            this.store.dispatch(new fromRoot.RestoreApiNamespace());
            this.store.dispatch(new fromRoot.CleanDetail(section));
            this.store.dispatch(new fromRoot.EndUpdateMode());
            this.store.dispatch(new fromRoot.EndExportMode());
          }
          this.store.dispatch(new fromRoot.ToggleTransferIpWorks({ transferIpWorksMode: false }));
        }),
      ),
    { dispatch: false },
  );

  editClaimSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.EDIT_CLAIM_SUCCESS),
      withLatestFrom(this.store.select(fromRoot.getRouterParams)),
      concatMap(([action, params]) => {
        const url = `/copyright/works/${params.key}/details`;
        this.permissionsService.unsetUserTempRoles();
        return [
          new fromRoot.ShowSnackBar({
            duration: 5000,
            message: this.translate.instant('WORKS.EDIT_CLAIM_SUBMIT_SUCESS'),
          }),
          new fromRoot.EndEditClaim(),
          new fromRoot.ResetSearchResults({ section: 'activity', advancedSearchDefaults: null }),
          new fromRoot.EndEditMode({ pathOnEnd: url }),
        ];
      }),
    ),
  );

  createOrEditCopyrightItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromRoot.CREATE_ITEM, fromRoot.CLONE_ITEM, fromRoot.SELECT_SEARCH_ITEM, fromRoot.GET_ITEM, fromRoot.GET_ITEM_TO_EDIT, fromRoot.EDIT_ITEM, fromRoot.START_EDIT_MODE),
        map(
          (action: fromRoot.CreateItem | fromRoot.CloneItem | fromRoot.SelectSearchItem | fromRoot.GetItem | fromRoot.GetItemToEdit | fromRoot.EditItem | fromRoot.StartEditMode) =>
            action,
        ),
        withLatestFrom(
          this.store.pipe(select(fromRoot.getRouterSection)),
          this.store.pipe(select(fromRoot.getRouterParams)),
          this.store.pipe(select(fromRoot.getRouterTab)),
          this.store.pipe(select(fromRoot.getCopyrightItemView)),
          this.store.pipe(select(fromRoot.getRouterQueryParams)),
          this.store.pipe(select(fromRoot.getAdvancedSearchCollapsed)),
        ),
        map(([action, section, routerParams, tab, itemView, queryParams, advancedSearchCollapsed]: [any, string, any, string, any, any, boolean]) => {
          const actionType = action.type;
          this.store.dispatch(new fromRoot.CancelSearch());

          let newItemView: CopyrightItemView;
          switch (actionType) {
            case fromRoot.CREATE_ITEM:
              if (!advancedSearchCollapsed) {
                this.store.dispatch(new fromRoot.AdvancedSearchToggle(true));
              }
              newItemView = fromRoot.CopyrightItemView.New;
              break;
            case fromRoot.CLONE_ITEM:
              newItemView = fromRoot.CopyrightItemView.Clone;
              break;
            case fromRoot.GET_ITEM:
            case fromRoot.SELECT_SEARCH_ITEM:
            case fromRoot.START_EDIT_MODE:
              newItemView = fromRoot.CopyrightItemView.Detail;
              break;
            default:
              newItemView = fromRoot.CopyrightItemView.Edit;
          }
          if (itemView !== newItemView) {
            this.store.dispatch(new fromRoot.SetItemView(newItemView));
          }
          if (actionType === fromRoot.START_EDIT_MODE) {
            const payload = action['payload'];
            const { newItemType, organizationToFilter, editId, editing, editName, multipleNamespaces } = payload;
            const url = RouterUtils.getSectionUrl(section);
            const editNameToSave = multipleNamespaces ? `${multipleNamespaces[0].editName} - ${multipleNamespaces[0].editId.split('PB')[1]}` : editName;
            const editIdToSave = multipleNamespaces ? multipleNamespaces[0].editId : editId;
            this.store.dispatch(
              new fromRoot.SaveEditModeHistory({
                editing,
                editId: editIdToSave,
                editName: editNameToSave,
                multipleNamespaces,
                newItemType,
                organizationToFilter,
                type: (organizationToFilter === undefined && newItemType !== undefined && 'create') || 'edit',
              }),
            );
            if (newItemType) {
              const { model } = payload;
              const newItemQueryParams = (model || organizationToFilter) && { model, organizationToFilter };
              const extras = newItemQueryParams ? { queryParams: newItemQueryParams } : {};
              this.store.dispatch(new fromRoot.Go({ path: [`${url}/${routerParams.key}/new/${newItemType}`], extras }));
            } else {
              const editClaim = queryParams && queryParams.editClaim;
              if (editClaim) {
                this.store.dispatch(new fromRoot.Go({ path: [`${url}/${routerParams.key}/${tab}/edit`], extras: { queryParams: { editClaim } } }));
              } else {
                this.store.dispatch(new fromRoot.Go({ path: [`${url}/${routerParams.key}/${tab}/edit`] }));
              }
            }
          }
        }),
      ),
    { dispatch: false },
  );

  updateField$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.UPDATE_FIELD),
      withLatestFrom(this.store.pipe(select(fromRoot.getRouterSection)), this.store.pipe(select(fromRoot.getCopyrightFeatureState))),
      map(([actionInfo, section, copyrightState]: [{ payload: EditAction; action: string }, string, CopyrightState]) => {
        const action = actionInfo && actionInfo.payload;
        if (action) {
          const value = cloneDeep(copyrightState[section]);
          EditModeUtils.edit(value, action, section);
          return new fromRoot.UpdateFieldSuccess(section, value);
        }
        return new fromRoot.UpdateFieldFail();
      }),
    ),
  );

  endEditModeCopyrightItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromRoot.EndEditMode>(fromRoot.END_EDIT_MODE),
      withLatestFrom(
        this.store.pipe(select(fromRoot.getRouterSection)),
        this.store.pipe(select(fromRoot.getCopyrightBySectionId)),
        this.store.pipe(select(fromRoot.getRouterTab)),
        this.store.pipe(select(fromRoot.getTabHeaderConfig)),
      ),
      map(([action, section, key, tab, tabHeaderConfig]) => {
        const { pathOnEnd } = action.payload || ({} as any);
        this.permissionsService.unsetUserTempRoles();
        if (pathOnEnd) {
          return new fromRoot.Go({ path: [pathOnEnd], forceReload: true });
        }
        const url = RouterUtils.getSectionUrl(section);
        if ((tabHeaderConfig && tabHeaderConfig[tab] && tabHeaderConfig[tab].isVisible === false) || !tab) {
          const defaultTab = TabsUtils.getDefaultTabName(section);
          return new fromRoot.Go({ path: [`${url}/${key}/${defaultTab}`] });
        }
        return new fromRoot.Go({ path: [`${url}/${key}/${tab}`] });
      }),
    ),
  );

  editCopyrightItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.EDIT_ITEM),
      withLatestFrom(this.store.pipe(select(fromRoot.getRouterSection)), this.store.pipe(select(fromRoot.getCopyrightBySectionId))),
      map(([action, section, key]) => {
        const url = RouterUtils.getSectionUrl(section);
        return url ? new fromRoot.Go({ path: [`${url}/${key}/edit`] }) : new fromRoot.Go({ path: [`copyright/${section}/${key}/edit`] });
      }),
    ),
  );

  createCopyrightItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromRoot.CreateItem>(fromRoot.CREATE_ITEM),
      withLatestFrom(this.store.select(fromRoot.getRouterSection)),
      map(([action, section]) => {
        if (action.payload?.newItemType === UpdateModeType.BULK) {
          return new fromRoot.Go({ path: [`copyright/bulk-updates/new`] });
        }
        const query = action.payload?.params;
        const url = RouterUtils.getSectionUrl(section);
        const redirectPayload: { path: any[]; query?: object } = {
          path: [`copyright/${section}/new`],
        };
        if (url) {
          redirectPayload.path = [`${url}/new`];
        }
        if (query) {
          redirectPayload.query = query;
        }
        return new fromRoot.Go(redirectPayload);
      }),
    ),
  );

  cloneCopyrightItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.CLONE_ITEM),
      withLatestFrom(this.store.pipe(select(fromRoot.getRouterSection)), this.store.pipe(select(fromRoot.getCopyrightBySectionId))),
      map(([action, section, key]) => {
        return new fromRoot.Go({
          path: [`copyright/${section}/${key}/clone`],
        });
      }),
    ),
  );

  finishCloneCopyrightItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.FINISH_CLONE_ITEM),
      withLatestFrom(this.store.pipe(select(fromRoot.getRouterSection))),
      map(([action, section]: [fromRoot.FinishCloneItem, string]) => {
        const key = action.payload;
        const defaultTab = TabsUtils.getDefaultTabName(section);
        return new fromRoot.Go({
          path: [`copyright/${section}/${key}/${defaultTab}/edit`],
        });
      }),
    ),
  );

  getSharePicture$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.GET_SHARE_PICTURE),
      map((action: fromRoot.GetSharePicture) => action),
      withLatestFrom(
        this.store.pipe(select(fromRoot.getGlobalApiNamespace)),
        this.store.pipe(select(fromRoot.getRouterSection)),
        this.store.pipe(select(fromRoot.getRouterTab)),
        this.store.pipe(select(fromRoot.getRouterParams)),
        this.store.pipe(select(fromRoot.getWorkId)),
        this.store.pipe(select(fromRoot.getForcedNS)),
      ),
      filter(([request, ns, section, tab, params, copyrightWorkId, forcedNS]) => {
        if (forcedNS) {
          this.store.dispatch(new fromRoot.ClearSharePicture());
          this.store.dispatch(new fromRoot.CleanLoading());
          return false;
        }
        return true;
      }),
      switchMap(([request, ns, section, tab, params, copyrightWorkId, forcedNS]: [sharePicturePayload, any, string, string, any, string, boolean]) => {
        const resultRequest = request.payload || { workId: copyrightWorkId };
        if (section === SectionsConfig.WORKS.name && tab === 'details') {
          if (!resultRequest.workId && params.key) {
            resultRequest['workId'] = params.key;
          }
        }
        return this.detailService.getDetailExtraData(SharePictureUtils.getRequestSharePicture(resultRequest, ns)).pipe(
          map(response => {
            this.store.dispatch(new fromRoot.SaveSharesData(SharePictureUtils.generateSharePicturesRowsAndTree(response, this.translate)));
            return new fromRoot.GetSharePictureSuccess(response, request.saveSettings, request.saveDefaultSettings);
          }),
          catchError(error => {
            // Currently for some users (e.g., Publisher Extended Viewers), we make calls to get the Share Picture
            // even when they are unauthorized to view this data. When that's the case, we should not log an error
            // for the 403. The error.error?.message? check is because currently sometimes the backend responds with
            // 500s arbitrarily and then nests the actual error in a `message` attribute
            const isErrorCausedBecauseSharePictureCallForbidden = error.error?.message?.includes(HttpStatusCode.Forbidden) || error.status === HttpStatusCode.Forbidden;

            return isErrorCausedBecauseSharePictureCallForbidden
              ? of(new fromRoot.CleanLoading(), new fromRoot.ClearSharePicture())
              : of(new fromRoot.CleanLoading(), new fromRoot.ClearSharePicture(), new fromRoot.GetSharePictureFail());
          }),
        );
      }),
    ),
  );

  cancelDownload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.CANCEL_DOWNLOAD),
      withLatestFrom(this.store.select(fromRoot.getExportMode), this.store.select(fromRoot.getRouterSection)),
      filter(([type, exportMode, section]) => exportMode && exportMode.downloadBatch && exportMode.downloading),
      map(([type, exportMode, section]: [FileType, ExportMode, string]) => {
        return new fromRoot.ExportDataSuccess({ name: section });
      }),
    ),
  );

  exportData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromRoot.ExportData>(fromRoot.EXPORT_DATA),
      withLatestFrom(
        this.store.select(fromRoot.getExportMode),
        this.store.select(fromRoot.getRouterSection),
        this.store.pipe(select(fromRoot.getRouterView)),
        this.store.pipe(select(fromRoot.getCopyrightDetailBySection)),
        this.store.pipe(select(fromRoot.getRouterTab)),
        this.store.select(fromRoot.getUpdateMode),
      ),
      mergeMap(([action, exportMode, section, view, detail, tab, updateMode]: [fromRoot.ExportData, ExportMode, string, string, any, string, UpdateMode]) => {
        if (exportMode?.downloadType === ExportModeType.BATCH || (view !== 'SEARCH' && !exportMode.apiCall)) {
          return of(new fromRoot.ExportDataSuccess({ name: section }));
        } else {
          if (view === 'SEARCH') {
            return of(new fromRoot.GetDataFromQueue());
          } else {
            // This is triggered when exporting from a tab or in a detail view
            const property = FetchApiCallUtils.getPageableProperty(exportMode.apiCall, detail, this.translate, tab) || '';
            const results: PageableData<any> = get(detail, property);
            if (results?.total === results?.items?.length) {
              return of(new fromRoot.ExportDataSuccess({ name: section }));
            } else {
              const onFinishExport = (result, error) => {
                if (error) {
                  this.store.dispatch(new fromRoot.PauseExportMode());
                } else {
                  this.store.dispatch(new fromRoot.ExportDataSuccess({ name: section }));
                }
              };
              const onErrorExport = (result, error) => {
                if (error) {
                  this.store.dispatch(new fromRoot.PauseExportMode());
                }
              };
              // Ideally we'd pass existing `results.items` values into the ExportModeBatch,
              // thus reducing the total hits to the search endpoint. However, currently the
              // `exportMode.apiCall` and the Sort state of the pageable data are not in sync
              // across the app. For now we export data unsorted and without any cached results.
              const cachedItemsForExport = [];
              const total = results?.total;
              this.store.dispatch(new SetExportModeBatch({ batch: { total, items: cachedItemsForExport } }));
              return of(
                new fromRoot.StartApiCall(
                  fromApiCallConstructors.getApiCallAutoPagination(
                    exportMode.apiCall,
                    cachedItemsForExport.length,
                    total,
                    FetchApiCallUtils.getApiCallPropertiesForExportMode(exportMode),
                    onFinishExport,
                    onErrorExport,
                    exportMode.queueChunckSize,
                  ),
                ),
              );
            }
          }
        }
      }),
    ),
  );

  getDataFromQueue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.GET_DATA_FROM_QUEUE),
      withLatestFrom(
        this.store.select(fromRoot.getExportMode),
        this.store.select(fromRoot.getUpdateMode),
        this.store.select(fromRoot.getRouterSection),
        this.store.select(fromRoot.getSearchResultsInput),
        this.store.select(fromRoot.getSearchResultsExtraTotal),
        this.store.select(fromRoot.getSearchXrefList),
      ),
      filter(([_, exportMode, updateMode, , , , ,]) => !!exportMode || !!updateMode),
      concatMap(([_, exportMode, updateMode, section, input, total, xrefList]) => {
        if (input) {
          const { size, sort } = input;
          const actualRowsCount = (exportMode?.downloadBatch as [])?.length || updateMode?.items?.length;
          if (actualRowsCount < total && actualRowsCount < 10000) {
            const { search } = this.searchService.formatSearchParamsBySearchMode(section, input.params, xrefList);

            return of(
              new fromRoot.FetchSearchPage({
                search: { ...search, size, from: actualRowsCount, sort: sort || '' },
                addRows: true,
                downloadQueue: true,
              }),
            );
          } else {
            return of(new fromRoot.ExportDataSuccess({ name: section }));
          }
        }
      }),
    ),
  );

  exportDataSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromRoot.ExportDataSuccess>(fromRoot.EXPORT_DATA_SUCCESS),
      withLatestFrom(
        this.store.pipe(select(fromRoot.getRouterSection)),
        this.store.pipe(select(fromRoot.getRouterView)),
        this.store.pipe(select(fromRoot.getExportMode)),
        this.store.select(fromRoot.getUpdateMode),
      ),
      map(([action, section, view, exportMode, updateMode]) => {
        if (updateMode) {
          // SwitchUpdateMode does nothing at the moment. It gives us time to use the updateMode data before it is cleared in the
          // EndUpdateMode action triggered in the bulk-update-type-step.ts
          return new fromRoot.SwitchUpdateMode(updateMode);
        }
        const { name } = action.payload;
        const thisSectionConfig = CopyrightUtils.getSectionData(section);
        this.exportService.exportAs(name, { ...exportMode, ...(view === 'SEARCH' && { thisIdAsFirstElement: thisSectionConfig.exportThisIdAsFirstElement }) });
        return new fromRoot.EndExportMode();
      }),
    ),
  );
  /**
   * Simpler and straightforward version of the `exportData$` + `exportDataSuccess$` effects allowing to export data right in the
   * payload of the action.
   *
   * No access to the store is needed.
   * All data manipulation needs to be done before calling the action.
   */
  dataExporter$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromRoot.DataExporter>(fromRoot.DATA_EXPORTER),
        map(action => {
          this.exportService.exportAs(action.payload.name, action.payload.config);
        }),
      ),
    { dispatch: false },
  );

  updateNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.UPDATE_NOTES),
      map((action: fromRoot.UpdateNotes) => action.payload),
      withLatestFrom(
        this.store.pipe(select(fromRoot.getRouterSection)),
        this.store.pipe(select(fromRoot.getGlobalApiNamespace)),
        this.store.pipe(select(fromRoot.getCopyrightDetailBySection)),
        this.store.pipe(select(fromNewSectionItem.getNewSectionResponse)),
      ),
      switchMap(([action, section, ns, detail, response]) => {
        const { attributes, notSaveResponse, customAPISection, customId } = action;
        const notes = cloneDeep({ attributes });
        let cubeId = !!response && get(response, 'id');
        if (!cubeId) {
          const data = get(response, 'events[0].targetIdVersion');
          const index = data?.indexOf('_');
          if (data && index > 1) {
            cubeId = data.substr(0, index);
          }
        }
        if (detail || cubeId) {
          const id = customId || cubeId || detail?.id || detail?.attributes?.id || null;
          if (id) {
            const url = `${environment.apiUrlCubeData}/admin/${ns}/${customAPISection || noteTypeBySection[section]}/${id}`;
            return this.detailService.putRequestCubeData(url, { force: true }, notes).pipe(
              concatMap((notesResponse: NotesResponse) => {
                const actions: any[] = [];
                if (!action?.avoidSnackbar) {
                  actions.push(
                    new fromRoot.ShowSnackBar({
                      duration: 5000,
                      message: this.translate.instant('NOTES.MESSAGES.UPDATE'),
                    }),
                  );
                }
                if (!notSaveResponse) {
                  if (section === SectionCopyrightWorks.name) {
                    actions.push(new fromRoot.StartApiCall({ apiCall: fromApiCalls.getAuditHistory }));
                  }
                  actions.push(new fromRoot.GetNotesSuccess({ section, response: NotesUtils.getNotes(notesResponse) }));
                }
                return reverse(actions);
              }),
              catchError(() => of(new fromRoot.ShowSnackBar({ message: this.translate.instant('ERROR.UPDATE') }))),
            );
          }
        }
      }),
    ),
  );

  newNote$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromRoot.NEW_NOTE),
        map((action: fromRoot.NewNote) => {
          const currentTimeUTC = new Date().toISOString();
          const notesUtils = new NotesUtils(this.translate, this.store, this.dialog);

          notesUtils.openDialog(
            this.translate.instant('NOTES.DIALOG_TITLES.ADD_NOTE'),
            NotesUtils.getDialogForm(currentTimeUTC, this.translate),
            { ...NotesUtils.DEFAULT_NEW_NOTE },
            this.translate.instant('NOTES.BUTTONS.SAVE_NOTE'),
            this.translate.instant('NOTES.BUTTONS.CANCEL'),
            $event => {
              const newNote = { ...$event };
              newNote['userId'] = notesUtils.userName;
              const notes = NotesUtils.stringifyAllNotesClusters(action.payload.currentNotes);
              const finalNotes = NotesUtils.updateNote(notes, newNote);
              notesUtils.updateNotesStore(finalNotes);
            },
          );
        }),
      ),
    { dispatch: false },
  );

  updateNote$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromRoot.UPDATE_NOTE),
        map((action: fromRoot.UpdateNote) => {
          const currentTimeUTC = new Date().toISOString();
          const { code, note } = action.payload.rowNote;
          const notesUtils = new NotesUtils(this.translate, this.store, this.dialog);

          notesUtils.openDialog(
            this.translate.instant('NOTES.DIALOG_TITLES.EDIT_NOTE'),
            NotesUtils.getDialogForm(currentTimeUTC, this.translate),
            { code, note: note.text },
            this.translate.instant('NOTES.BUTTONS.UPDATE_NOTE'),
            this.translate.instant('NOTES.BUTTONS.CANCEL'),
            ($event: RowNote) => {
              const notes = NotesUtils.stringifyAllNotesClusters(action.payload.currentNotes);
              const finalNotes = NotesUtils.updateNote(notes, $event, action.payload.rowNote);
              notesUtils.updateNotesStore(finalNotes);
            },
          );
        }),
      ),
    { dispatch: false },
  );

  deleteNote$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromRoot.DELETE_NOTE),
        withLatestFrom(this.store.pipe(select(fromRoot.getRouterSection)), this.store.pipe(select(fromRoot.getCopyrightBySectionId))),
        map(([action, section, elemKey]: [fromRoot.DeleteNote, string, string]) => {
          const notesUtils = new NotesUtils(this.translate, this.store, this.dialog);

          notesUtils.openDialog(
            this.translate.instant('NOTES.DIALOG_TITLES.REMOVE_NOTE'),
            NotesUtils.getDialogMessageDelete(this.translate),
            {},
            this.translate.instant('NOTES.BUTTONS.REMOVE'),
            this.translate.instant('NOTES.BUTTONS.CANCEL'),
            () => {
              const notes = Object.assign({}, action.payload.currentNotes);
              const noteToBeDeleted: RowNote = action.payload.deleteRowNote;
              const oldNotesCount = NotesUtils.notesCount(action.payload.currentNotes);
              notes[noteToBeDeleted.code] = NotesUtils.removeRowFromNotes(notes, noteToBeDeleted);

              if (oldNotesCount === 1) {
                setTimeout(() => {
                  this.store.dispatch(new fromRoot.Go({ path: [`copyright/${section}/${elemKey}/details`] }));
                }, 500);
              }

              notesUtils.updateNotesStore(notes);
            },
          );
        }),
      ),
    { dispatch: false },
  );

  selectIpsRelations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.UPDATE_IPS_RELATIONS_SELECT),
      withLatestFrom(this.store.pipe(select(fromRoot.getParentRelationsSearchResults)), this.store.pipe(select(fromRoot.getChildrenRelationsSearchResults))),
      switchMap(([action, parentRelationshipsData, childrenRelationshipsData]: [any, any, any]) => {
        const { payload } = action;
        const { ipSelected, ipiType, baseKey, nameKey, addByBaseValue } = payload;
        const getUrlLabels = relations => ({ id: (ipiType === IP_CONSTANTS.TYPE_CHILDREN && relations[0].partyNameId) || relations[0].otherNameId });
        const key = (ipiType === IP_CONSTANTS.TYPE_CHILDREN && 'childrenRelationshipsData') || 'parentRelationshipsData';
        if (addByBaseValue) {
          const pattern = (ipSelected.baseInformation || []).map(baseInfo => `{"equals": {"parties.party.relations[XREF].otherId": "${baseInfo.baseIdWithPrefix}"}}`);
          const data = {
            isGet: false,
            url: `${environment.apiUrlCubeData}/party-names/CUBE/search`,
            queryParams: {
              include: `attributes{typeOfName,name,firstName},relations{relation,otherId}, parties.party.relations{relation,otherId},
    parties.relations,parties.party.societies{society, memberships}.society{id,attributes}.attributes,
    parties.party.attributes{typeOf, status,sex,dateOfBirth,countryOfBirth,placeOfBirth,dateOfDeath}`,
              followRedirects: false,
            },
            query: `{"or":[${pattern}]}`,
          };

          return this.detailService.getDetailExtraData(data).pipe(
            map((partyNames: any) => {
              const actions = [];
              const ips = SearchIpsParser.IpSearchCleaner(partyNames.items, this.translate, ['baseInformation']);
              ips.forEach(ip => {
                const ipNameKey = (ip.key && ip.key.replace('ICE:', '')) || '';
                const ipBaseKey = ip.baseInformation && ip.baseInformation[0] && ip.baseInformation[0].baseId.replace('ICE:', '') && '';
                const newRelation = IpUtils.formatIpRelationObject(ipiType, baseKey, nameKey, ipBaseKey, ipNameKey);
                actions.push(newRelation);
              });

              const storeRelation = [...(parentRelationshipsData?.items || []), ...(childrenRelationshipsData?.items || [])];
              const actionsRel = actions.filter(
                actionRel =>
                  !storeRelation.some(
                    storeRel =>
                      ((ipiType === IP_CONSTANTS.TYPE_PARENT && storeRel.key === actionRel.otherNameId) ||
                        (ipiType === IP_CONSTANTS.TYPE_CHILDREN && storeRel.key === actionRel.partyNameId)) &&
                      !storeRel.deleted,
                  ),
              );
              const newRelations = uniqWith(actionsRel, isEqual);
              const cleanerData = { relations: newRelations, type: 'add', isMultipleChildren: ipiType === IP_CONSTANTS.TYPE_CHILDREN, key };
              return new fromRoot.StartApiCall({
                apiCall: fromApiCalls.getIpRelationshipData,
                apiCallData: { labels: getUrlLabels(newRelations), cleanerData },
                callBack: (result, error, apiCallData) => {
                  const relations = drop(get(apiCallData, `cleanerData.relations`) || []);
                  if (relations.length) {
                    const newApiCallData = { ...apiCallData, labels: getUrlLabels(relations), cleanerData: { ...apiCallData.cleanerData, relations } };
                    this.store.dispatch(new fromRoot.StartApiCall({ apiCall: fromApiCalls.getIpRelationshipData, apiCallData: newApiCallData }));
                  }
                },
              });
            }),
            catchError(() => EMPTY),
          );
        } else {
          const newRelation = IpUtils.formatIpRelationObject(ipiType, baseKey, nameKey, ipSelected.baseKey, ipSelected.nameKey);
          const cleanerData = { relations: [newRelation], type: 'add', isMultipleChildren: false, key };
          return of(new fromRoot.StartApiCall({ apiCall: fromApiCalls.getIpRelationshipData, apiCallData: { labels: getUrlLabels([newRelation]), cleanerData } }));
        }
      }),
    ),
  );

  registerIPsOnICEparties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.REGISTER_IPS_ON_ICE_PARTIES),
      map((action: fromRoot.RegisterIPsOnICEparties) => action.payload),
      switchMap(({ result }) => {
        const requests = [];
        result.forEach(party => {
          const parties = cloneDeep(party.response.parties);
          if (party.itemData.parties[0]?.relations.length > 0) {
            parties[0].relations = party.itemData.parties[0]?.relations;
          }
          const names =
            party.itemData.attributes?.names?.map(name => {
              return {
                ...name,
                sequenceNumber: name.nameId,
              };
            }) || [];
          requests.push(
            this.saveItemService.registerIPsOnICEparties({
              id: party.response.id,
              apiSegment: 'party-names',
              params: {
                ...party.response,
                attributes: {
                  ...party.response.attributes,
                  clauses: party.itemData.attributes?.clauses || [],
                  names,
                  id: party.response.id,
                  ns: ICE_PREFIX,
                },
                parties,
              },
            }),
          );
        });

        return forkJoin(requests).pipe(
          map(([mainResponse]) => {
            return new fromRoot.SaveSectionItemSuccess(mainResponse);
          }),
          catchError(error => of(new fromRoot.SaveSectionItemFail(error))),
        );
      }),
    ),
  );

  saveSectionItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.SAVE_SECTION_ITEM),
      withLatestFrom(
        this.store.pipe(select(fromRoot.getSectionItemFieldsWithSection)),
        this.store.pipe(select(fromRoot.getGlobalApiNamespace)),
        this.store.pipe(select(fromRoot.getEditingId)),
        this.store.pipe(select(fromRoot.getEditingMultipleNamespaces)),
        this.store.pipe(select(fromRoot.getRouterTab)),
      ),
      map(([action, fields, ns, editingId, multipleNamespaces, tab]) => [action, { ...fields, ns, editingId, multipleNamespaces, tab }]),
      switchMap(([action, fieldsWithSection]) => {
        const onSuccess = get(action, 'payload.onSuccess');
        const { fields, section, ns, editingId, multipleNamespaces, tab } = fieldsWithSection;
        const sectionCleaner = SectionSendItemCleaner[section];
        const namespace = editingId || ns;
        const itemMapped = sectionCleaner(fields[section], namespace, undefined, true);
        const sectionData = CopyrightUtils.getSectionData(section);
        const editKey = sectionData.name === SectionsConfig.WORKS.name && get(itemMapped, 'attributes.key');
        const isWorkCreation = sectionData.name === SectionsConfig.WORKS.name && !editKey;
        return this.saveItemService
          .saveItem({ sectionConfig: sectionData, item: itemMapped, namespace: ns, editKey, nsOnBehalfOf: namespace, multipleNamespaces, editSubSection: null, tab })
          .pipe(
            switchMap(response => {
              return this.detailService.checkStatus(sectionData.apiSegment, response, null, null, true, false, isWorkCreation).pipe(
                map(result => {
                  if (result.status === 'success' && onSuccess) {
                    onSuccess();
                  }
                  return result;
                }),
              );
            }),
            map(result => {
              if (sectionData.name === SectionsConfig.IPS.name) {
                if (result?.[0]?.response?.id) {
                  return new fromRoot.RegisterIPsOnICEparties({ result });
                }
                return new fromRoot.SaveSectionItemFail({ message: `No ICE Party Name ID's found`, severity: 'error' });
              }
              return new fromRoot.SaveSectionItemSuccess({ ...result, section });
            }),
            catchError(error => of(new fromRoot.SaveSectionItemFail(error))),
          );
      }),
    ),
  );

  deleteOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.DELETE_ITEM),
      withLatestFrom(
        this.store.pipe(select(fromRoot.getRouterSection)),
        this.store.pipe(select(fromRoot.getCopyrightDetailBySection)),
        this.store.pipe(select(fromRoot.getGlobalApiNamespace)),
      ),
      // Organization deletion -> delete all users first
      filter(([_, section]) => section === SectionsConfig.ORGANIZATIONS.name),
      map(([_, section, detail, namespace]: [{ type: string }, string, UserCleaned | OrganizationDetail, string]) => {
        const id = detail['id'] || (detail['attributes'] && detail['attributes']['id']);
        const ns = detail['ns'] || (detail['attributes'] && detail['attributes']['ns']) || namespace;
        return { id, ns, section };
      }),
      switchMap(({ id, ns, section }) => {
        const size = 50; // Page size
        const body = { and: [{ equals: { 'roles.organizationId': id } }] };
        const fetchUserPage$ = (pageFrom = 0, lastItems = []) =>
          this.detailService
            .postRequestCubeData(`${environment.apiUrlCubeData}/users/${id.split(':')[1]}/search`, { include: 'attributes', size, pageFrom }, body)
            .pipe(map((result: any) => ({ items: lastItems.concat(result.items.map(({ id: userId }) => userId)), total: result.total })));

        return fetchUserPage$().pipe(
          expand(({ items, total }, index) => (items.length === total ? EMPTY : fetchUserPage$(size * (index + 1), items))),
          reduce((acc, { items, total }) => items, []),
          map(userIds => ({
            id,
            ns,
            section,
            userIds,
          })),
        );
      }),
      switchMap(({ id, ns, section, userIds }) => {
        const deleteUsers$ =
          userIds.length > 0
            ? of(...userIds).pipe(
                // Concat delete requests to prevent parallel execution
                concatMap(userId => this.detailService.deleteRequestCubeData(`${environment.apiUrlCubeData}/users/${id.split(':')[1]}/${userId}`, {})),
                last(),
              )
            : of({});
        return deleteUsers$.pipe(
          switchMap(() => {
            // Finally, delete organization
            const apiSegment = SectionsConfig[section.toUpperCase()].apiSegment;
            const url = `${environment.apiUrlCubeData}/${apiSegment}/${ns}/${id}`;
            return this.detailService.deleteRequestCubeData(url, {}).pipe(
              map(() => {
                return new fromRoot.DeleteItemSuccess({ text: `${id} ${this.translate.instant('DELETE.DELETE_SUCCESS')}` });
              }),
            );
          }),
        );
      }),
      catchError(error => {
        return of(new fromRoot.DeleteItemFail(error.message));
      }),
    ),
  );

  deleteItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.DELETE_ITEM),
      withLatestFrom(
        this.store.pipe(select(fromRoot.getRouterSection)),
        this.store.pipe(select(fromRoot.getCopyrightDetailBySection)),
        this.store.pipe(select(fromRoot.getGlobalApiNamespace)),
      ),
      // Organizations handled separately due to user clearing
      filter(([_, section]) => section !== SectionsConfig.ORGANIZATIONS.name),
      switchMap(([action, section, detail, namespace]: [{ type: string }, string, UserCleaned | OrganizationDetail, string]) => {
        const id = detail['id'] || (detail['attributes'] && detail['attributes']['id']);
        const ns = detail['ns'] || (detail['attributes'] && detail['attributes']['ns']) || namespace;
        const apiSegment = SectionsConfig[section.toUpperCase()].apiSegment;
        if (id) {
          const url = `${environment.apiUrlCubeData}/${apiSegment}/${ns}/${id}`;
          return this.detailService.deleteRequestCubeData(url, {}).pipe(
            map(response => {
              return new fromRoot.DeleteItemSuccess({ text: `${id} ${this.translate.instant('DELETE.DELETE_SUCCESS')}` });
            }),
            catchError(error => {
              return of(new fromRoot.DeleteItemFail(error.message));
            }),
          );
        }
      }),
    ),
  );

  workResolutionConfirm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.WORK_RESOLUTION),
      map((action: fromRoot.WorkResolution) => action.payload),
      withLatestFrom(
        this.store.select(fromRoot.getActivityOptionsToCompare).pipe(map((data: any) => data.map(work => work.value))),
        this.store.select(fromRoot.getActivityWorkToCompare),
        this.store.select(fromRoot.getRouterSection),
        this.store.select(fromRoot.getActivityWorkAnalysisId),
      ),
      switchMap(([data, optionsToCompare, workToCompare, sectionName, workAnalysisId]) => {
        const { ns, ns2, outcome, cause, forcedMerge, item } = data;
        const id1 = data.id1 || workToCompare.id;
        const id2 = data.id2 || workAnalysisId || optionsToCompare[0];
        const sectionData = CopyrightUtils.getSectionData(sectionName);
        const editKey = sectionData.name === SectionsConfig.WORKS.name && get(item, 'attributes.key');
        const isWorkCreation = sectionData.name === SectionsConfig.WORKS.name && !editKey;
        if (id1 && id2) {
          return this.detailService.workResolution(ns, id1, ns2, id2, outcome, cause, forcedMerge, optionsToCompare).pipe(
            switchMap(response =>
              this.detailService
                .checkStatus(SectionsConfig.WORKS.apiSegment, response, null, null, null, null, isWorkCreation)
                .pipe(map(finalStatus => new fromRoot.WorkResolutionSuccess(finalStatus))),
            ),
            catchError(error => {
              const message = CopyrightUtils.getApiErrorText(error);
              const errorMessage = this.translate.instant(ErrorsUtils.addError({ message }, this.translate, true));
              return of(
                new fromRoot.WorkResolutionFail(errorMessage),
                new fromRoot.ShowSnackBar({
                  icon: 'warning',
                  message: 'The system is not responding to your request. Please check for the status of the operation later',
                  duration: errorMessageShowtime.normal,
                  position: {
                    horizontalPosition: 'center',
                    verticalPosition: 'top',
                  },
                }),
              );
            }),
          );
        }
        if (!id1 && !id2 && optionsToCompare) {
          return this.detailService.workResolution(ns, id1, ns2, id2, outcome, cause, forcedMerge, optionsToCompare).pipe(
            switchMap(response => {
              return this.detailService.checkStatus(SectionsConfig.WORKS.apiSegment, response).pipe(map(finalStatus => new fromRoot.WorkResolutionSuccess(finalStatus)));
            }),
            catchError(error => {
              const message = CopyrightUtils.getApiErrorText(error);
              const errorMessage = this.translate.instant(ErrorsUtils.addError({ message }, this.translate, true));
              return of(
                new fromRoot.WorkResolutionFail(errorMessage),
                new fromRoot.ShowSnackBar({
                  icon: 'warning',
                  message: 'The system is not responding to your request. Please check for the status of the operation later',
                  duration: errorMessageShowtime.normal,
                  position: {
                    horizontalPosition: 'center',
                    verticalPosition: 'top',
                  },
                }),
              );
            }),
          );
        }
        return of(new fromRoot.WorkResolutionFail('work to compare not loaded'));
      }),
    ),
  );

  submitAuditHistoryFilter$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(fromRoot.SUBMIT_AUDIT_HISTORY_FILTER),
      map((action: fromRoot.SubmitAuditHistoryFilter) => action.payload),
      withLatestFrom(
        this.store.pipe(select(fromRoot.getRouterSection)),
        this.store.pipe(select(fromRoot.getGlobalApiNamespace)),
        this.store.pipe(select(fromRoot.getCounterClaimList)),
        this.store.pipe(select(fromRoot.getCopyrightAgreementGroup)),
      ),
      map(([payload, section, ns, counterClaimsList, agreementGroupList]: [PayloadAuditHistoryFilter, string, string, any, any]) => ({
        payload,
        section,
        ns,
        counterClaimsList,
        agreementGroupList,
      })),
      mergeMap(request => {
        const { payload, section, ns, counterClaimsList, agreementGroupList } = request;
        const { itemId, auditTypes, emittedStartDate, emittedEndDate, userId, eventDescription, version, sort, eventsWithNotes } = payload;
        const eventID = get(payload, EVENT_ID, '');
        const auditTypeFilter = `{ "or": [${auditTypes.map(auditType => `{"equals": {"attributes.type": "${auditType}"}}`).join(',')}]}`;
        const eventsWithNotesFilter = eventsWithNotes ? `, { "exists": "admin.attributes.id"}` : '';
        const counterClaims = counterClaimsList ? counterClaimsList.map(claims => `{"wildcard": {"attributes.typeId": "*${claims.id}*"}}`).join(',') : '';
        const counterClaimsFilter =
          section === SectionsConfig.WORKS.name &&
          counterClaimsList.length > 0 &&
          `{"and":[{
                    "equals":{
                    "events.nameId":"60101"
                  }
                },
                {
                  "equals":{
                      "attributes.type":"counterclaim"
                  }
                },{
                  "or":[${counterClaims}]
                }
          ]}`;
        const agreementGroup = agreementGroupList?.agreements
          ? agreementGroupList?.agreements.map(agreement => `{"wildcard": {"attributes.typeId": "*${agreement.agreementId}*"}}`).join(',')
          : '';
        const agreementGroupFilter = () => {
          let agreementFilter = '';
          if (agreementGroupFilter && section === SectionsConfig.AGREEMENT_GROUP.name) {
            agreementFilter = `,{ "and":[{"or": [${agreementGroup}]},{"equals": {"attributes.type": "Agreement"
                }
              },
              {
                "or": [
                    {
                        "equals": {
                            "events.nameId": "20703"
                        }
                    },
                    {
                        "equals": {
                            "events.nameId": "20704"
                        }
                    }
                ]
              }
            ]
          }
            `;
          }
          return agreementFilter;
        };

        const filterQueryPrefix = `{"and":[{"or":[{"and":[{"wildcard":{ "attributes.typeId":"*_${itemId}_*"}}${eventsWithNotesFilter}, ${auditTypeFilter}]}
        ${counterClaimsFilter ? `,${counterClaimsFilter}]}` : ']}'}
        ${`${agreementGroupFilter()}`}
        ${emittedStartDate ? `,${this.getStartDateClauseFromFilter(emittedStartDate)}` : ''}
        ${emittedEndDate ? `,${this.getEndDateClauseFromFilter(emittedEndDate)}` : ''}
        ${userId ? `,${this.getUserIdClauseFromFilter(userId)}` : ''}
        ${eventDescription && eventDescription !== ALL ? `,${this.getEventDescriptionClauseFromFilter(eventDescription)}` : ''}`;
        const filterQuery = `${filterQueryPrefix}]}`;
        const filterValues = {
          emittedStartDate: DateTimeUtils.formatDate(moment(emittedStartDate)),
          emittedEndDate: DateTimeUtils.formatDate(moment(emittedEndDate)),
          userId,
          eventDescription,
          version,
          eventID,
          eventsWithNotes,
          workSourceId: payload.workSourceId,
        };
        const SIZE = 100;
        const data = {
          isGet: false,
          url: `${environment.apiUrlCubeData}/cube-audits/${ns}/search`,
          queryParams: {
            include: `admin,admin.attributes,attributes,events.attributes`,
            size: SIZE,
            from: 0,
            sort,
          },
          query: filterQuery,
        };

        const getData = (loadWithEventId = false, fromUpdated = 0, previousData = null) => {
          return this.detailService.getDetailExtraData({ ...data, queryParams: { ...data.queryParams, from: fromUpdated } }).pipe(
            switchMap(auditHistory => {
              const fullData = previousData !== null ? { ...previousData, items: [...previousData.items, ...auditHistory.items] } : auditHistory;
              if (!loadWithEventId || auditHistory.items.findIndex(item => item.id === eventID) !== -1) {
                return of(
                  new fromRoot.AuditHistoryFilterSuccess({
                    auditHistory: {
                      ...fullData,
                      items: AuditHistoryUtils.applyAuditIdFilter(
                        AuditHistoryUtils.filterAuditHistoryVersion(
                          AuditHistoryUtils.formatAuditHistoryJoinXrefs(fullData, this.translate, auditTypes[0], eventDescription),
                          parseInt(version, 10),
                        ),
                        filterValues,
                      ),
                      count: fullData?.items.length || 0,
                    },
                    section,
                    filterQuery,
                    filterValues,
                  }),
                );
              }
              return getData(true, fromUpdated + SIZE, fullData);
            }),
            catchError(error => {
              return of(new fromRoot.AuditHistoryFilterFail(error));
            }),
          );
        };

        if (eventID !== null && eventID !== '') {
          const filterQueryWithEventId = `${filterQueryPrefix},{"equals": {"id": "${eventID}" }}]}`;

          return this.detailService.getDetailExtraData({ ...data, query: filterQueryWithEventId }).pipe(
            switchMap(auditHistory => {
              if (auditHistory?.items.length > 0) {
                return getData(true);
              }
              return of(
                new fromRoot.AuditHistoryFilterSuccess({
                  auditHistory: [],
                  section,
                  filterQuery,
                  filterValues,
                }),
              );
            }),
            catchError(error => {
              return of(new fromRoot.AuditHistoryFilterFail(error));
            }),
          );
        }

        return getData();
      }),
    ),
  );

  getTerritorySuccessors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.SUBMIT_TERRITORIES_FILTER),
      map((action: fromRoot.SubmitTerritoriesFilter) => action.payload),
      withLatestFrom(this.store.pipe(select(fromRoot.getQueryTerritorySuccessors)), this.store.pipe(select(fromRoot.getTerritoryDateFilter))),
      filter(([payload, successors, dateFilter]) => successors && successors.length > 0 && !(dateFilter.substring(0, 4) === '0000')),
      map(([payload, successors, dateFilter]: [PayloadTerritoriesFilter, any[], string]) => ({ payload, successors, dateFilter })),
      mergeMap(request => {
        const { payload, successors, dateFilter } = request;
        const date = dateFilter || moment().format('YYYY-MM-DD');
        const data = {
          isGet: true,
          url: `${environment.apiUrlCubeData}/territories/countries`,
          queryParams: { codes: `${successors}`, includes: 'territories', date },
          query: ``,
        };
        return this.detailService.getDetailExtraData(data).pipe(
          map(list => {
            const territories = list.territories;
            return new fromRoot.GetTerritoryFilteredSuccessorsSuccess({ territories });
          }),
          catchError(error => {
            return of(new fromRoot.IceError({ message: 'Territories filter by date for successors failed', severity: 'error' }));
          }),
        );
      }),
    ),
  );

  getTerritoryPredecessors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.SUBMIT_TERRITORIES_FILTER),
      map((action: fromRoot.SubmitTerritoriesFilter) => action.payload),
      withLatestFrom(this.store.pipe(select(fromRoot.getQueryTerritoryPredecessors)), this.store.pipe(select(fromRoot.getTerritoryDateFilter))),
      filter(([payload, predecessors, dateFilter]) => predecessors && predecessors.length > 0 && !(dateFilter.substring(0, 4) === '0000')),
      map(([payload, predecessors, dateFilter]: [PayloadTerritoriesFilter, any[], string]) => ({ payload, predecessors, dateFilter })),
      mergeMap(request => {
        const { payload, predecessors, dateFilter } = request;
        const date = dateFilter || '1900-01-01';
        const data = {
          isGet: true,
          url: `${environment.apiUrlCubeData}/territories/countries`,
          queryParams: { codes: `${predecessors}`, includes: 'territories', date },
          query: `{}`,
        };
        return this.detailService.getDetailExtraData(data).pipe(
          map(list => {
            const territories = list.territories;
            return new fromRoot.GetTerritoryFilteredPredecessorsSuccess({ territories });
          }),
          catchError(error => {
            return of(new fromRoot.IceError({ message: 'Territories filter by date for predecessors failed', severity: 'error' }));
          }),
        );
      }),
    ),
  );

  getSearchScrollPagination$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.GET_SEARCH_SCROLL_PAGINATION),
      withLatestFrom(
        this.store.pipe(select(fromRoot.getRouterSection)),
        this.store.pipe(select(fromRoot.getSearchResultsInput)),
        this.store.pipe(select(fromRoot.getSearchResultsExtraTotal)),
        this.store.pipe(select(fromRoot.getSearchResultsEntities)),
        this.store.pipe(select(fromRoot.getSearchXrefList)),
      ),
      map(([_, section, input, total, rows, xrefList]) => {
        if (input) {
          const { size, sort } = input;
          const newInputParams = input.params?.xrefList ? SearchUtils.formatXrefList(xrefList) : input.params;
          const actualRowsCount = values(rows).length;
          const sectionData = CopyrightUtils.getSectionData(section);

          // following https://ice-cube.atlassian.net/browse/CUBE-13690 comments Activity search returns 100 works that contains more than 100 conflicts
          const fromUpdated = sectionData?.searchResultsUniqByParameter
            ? uniqBy(values(rows), value => value[sectionData.searchResultsUniqByParameter])?.length || 0
            : actualRowsCount;

          if (actualRowsCount < total) {
            return new fromRoot.FetchSearchPage({ search: { ...input, params: newInputParams, size, from: fromUpdated, sort: sort || '' }, addRows: true });
          }
        }
        return null;
      }),
      filter(action => !!action),
    ),
  );

  getRelationsRelatedWork$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.GET_RELATION_RELATED_WORK),
      map((action: fromRoot.GetRelationRelatedWork) => action.payload),
      switchMap(payload => {
        const workId: string = payload.id;
        const dataUrlGet = {
          isGet: true,
          url: `${environment.apiUrlCubeData}/works/CUBE/${workId}`,
          queryParams: {
            include: `attributes,relations,contributions.attributes,contributions.relations,
              contributions{contributor, role}.contributor{partyName}.partyName{attributes}.attributes{name, firstName}, claims`,
          },
          query: ``,
        };
        return this.detailService.getDetailExtraData(dataUrlGet).pipe(
          map((workRelationData: any) => {
            const { relationType, intExt } = payload;
            return new fromRoot.AddWorkRelation({
              workRelationData,
              relationType,
              intExt,
              relationTypeDisplay: WorkUtils.getWorkRelationDisplayType(relationType, intExt, this.translate),
            });
          }),
          catchError(error => {
            return of(new fromRoot.CleanLoading(), new fromRoot.UpdateIpsRelationsFail());
          }),
        );
      }),
    ),
  );

  enableUser$ = createEffect(() => this.actions$.pipe(ofType(fromRoot.ACTIVATE_USER), mapTo(new fromRoot.SaveSectionItem())));

  disableUser$ = createEffect(() => this.actions$.pipe(ofType(fromRoot.DISABLE_USER), mapTo(new fromRoot.SaveSectionItem())));

  saveOnResendUserActivationMail$ = createEffect(() => this.actions$.pipe(ofType(fromRoot.RESEND_ACTIVATION_EMAIL_SUCCESS), mapTo(new fromRoot.SaveSectionItem())));

  setExpertSearchDefaultOnToggle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromRoot.EXPERT_SEARCH_MODE_TOGGLE),
        withLatestFrom(this.store.pipe(select(fromRoot.getRouterSection))),
        map(([action, section]) => {
          if (section === 'users') {
            this.store.dispatch(new fromRoot.SetUsersExpertSearchDefault());
          }
        }),
      ),
    { dispatch: false },
  );

  setDummyIp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.SET_DUMMY_IP),
      switchMap((action: fromRoot.SetDummyIp) => {
        switch (action.payload.mode) {
          case 'add':
            return this.saveItemService
              .createDummyIps({ ...action.payload.dummyIpPayload, ns: 'OP' })
              .pipe(catchError(error => of(new fromRoot.SetDummyIpError(error || this.translate.instant('ERROR.NEW_PARTY')))));
          case 'edit':
            return this.saveItemService
              .editDummyIps({
                ...action.payload.dummyIpPayload,
                ns: 'OP',
                nsId: IpUtils.selectIPsIdByNS(get(action.payload.dummyIpPayload, 'relations') || [], 'OP') || action.payload.dummyIpPayload.id,
              })
              .pipe(catchError(error => of(new fromRoot.SetDummyIpError(error || this.translate.instant('ERROR.NEW_PARTY')))));
          case 'delete':
            return this.saveItemService
              .deleteDummyIps({ nsId: IpUtils.selectIPsIdByNS(get(action.payload.dummyIpPayload, 'relations') || [], 'OP'), ns: 'OP' })
              .pipe(catchError(error => of(new fromRoot.SetDummyIpError(error || this.translate.instant('ERROR.NEW_PARTY')))));
        }
      }),
      map(result => new fromRoot.SetDummyIpSuccess(result)),
    ),
  );

  getReportTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.GET_REPORT_TYPES),
      withLatestFrom(this.store.pipe(select(fromRoot.getCopyrightItemView))),
      switchMap(sectionData => {
        const url = `${environment.apiUrlCubeRegister}/reports/types`;
        return this.detailService.getRequestCubeData(url, {}).pipe(
          map(rTypes => {
            const { types } = rTypes;
            const typesList = Object.keys(types).map(key => {
              return { id: key, name: types[key] };
            });
            return new fromRoot.GetReportTypesSuccess(typesList);
          }),
          catchError(error => {
            return of(new fromRoot.GetReportTypesFail(error));
          }),
        );
      }),
    ),
  );

  downloadDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromRoot.DownloadDocuments>(fromRoot.DOWNLOAD_DOCUMENTS),
      withLatestFrom(this.store.pipe(select(fromRoot.getCounterClaimWork))),
      switchMap(([action, counterClaimDetail]: [fromRoot.DownloadDocuments, CounterClaimDetail]) => {
        const { id, documentsToDownload } = counterClaimDetail;
        const results = [];
        (documentsToDownload || []).forEach(document => results.push(this.saveItemService.downloadDocument(id, document)));
        return merge(...results).pipe(
          catchError(error => {
            this.store.dispatch(new fromRoot.ShowSnackBar({ message: this.translate.instant('COUNTER_CLAIMS.DOCUMENTS.ERRORS.NOT_FOUND') }));
            return of(new fromRoot.DisableDownloadDocument());
          }),
        );
      }),
      map((results: any) => new fromRoot.DisableDownloadDocument()),
      catchError(error => of(new fromRoot.DisableDownloadDocument())),
    ),
  );

  getReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.GET_REPORT),
      switchMap(action => {
        const { payload } = action;
        const { type, reportType } = payload; // DOWNLOAD or CLONE
        const include = type === REPORT_DOWNLOAD ? 'reportDownloadLink' : 'reportParametersDownloadLink';
        const url = `${environment.apiUrlCubeRegister}/reports/CUBE/${payload['id']}?include=attributes,${include}`;
        return this.detailService.getRequestCubeData(url, {}).pipe(
          map(response => {
            if (response['reportDownloadLink']) {
              return new fromRoot.DownloadReport(response);
            } else if (response['reportParametersDownloadLink']) {
              return new fromRoot.CloneReport({ response, reportType });
            } else {
              // SHOW MESSAGE, DOWNLOAD NOT AVAILABLE
              return new fromRoot.GetReportSuccess();
            }
          }),
          catchError(error => {
            return of(new fromRoot.GetReportFail(error));
          }),
        );
      }),
    ),
  );

  downloadReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.DOWNLOAD_REPORT),
      switchMap(action => {
        const { payload } = action;
        const id = payload['id'];
        const url = payload['reportDownloadLink'];
        return this.detailService
          .getHttp()
          .get(url, { responseType: 'arraybuffer' })
          .pipe(
            map(data => {
              let downloadCall: Subscription;
              const loadingText = new BehaviorSubject(ReportUtils.downloadText(this.translate, 0));
              const dialogRef = this.dialog.open(DialogMultiLayoutComponent, {
                data: {
                  className: 'dialog-wrapper-height-240',
                  loading: of(true),
                  loadingText,
                  loadingButtons: of([
                    {
                      text: of(this.translate.instant('EXPORT_MODE.CANCEL_SENDING')),
                      action: () => ReportUtils.closeDownloadPopup(downloadCall, dialogRef),
                    },
                  ]),
                  layouts: [],
                },
              });
              dialogRef.disableClose = true;
              setTimeout(() => {
                // Timeout to have the popup visible when the file is too small
                downloadCall = ReportUtils.downloadFile(this.http, id, data, 'application/zip').subscribe(result => {
                  if (result.type === HttpEventType.DownloadProgress) {
                    const downloaded = Math.round((100 * result.loaded) / result.total);
                    loadingText.next(ReportUtils.downloadText(this.translate, downloaded));
                  } else if (result.type === HttpEventType.Response) {
                    saveAs(result.body, `${id}.zip`);
                    ReportUtils.closeDownloadPopup(downloadCall, dialogRef);
                  }
                });
              }, 500);
              return new fromRoot.GetReportSuccess();
            }),
            catchError(error => {
              return of(new fromRoot.GetReportFail(error));
            }),
          );
      }),
    ),
  );

  cloneReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.CLONE_REPORT),
      switchMap(action => {
        const { payload } = action;
        const url = get(payload, 'response.reportParametersDownloadLink');
        const { reportType } = payload;
        return this.detailService
          .getHttp()
          .get(url, { responseType: 'json' })
          .pipe(
            map(data => {
              const convertedDataToFields = sections.reports.subSections[reportType].stepper.parseClone(data);
              this.store.dispatch(new fromRoot.CloneReportSuccess(convertedDataToFields));
              return new fromRoot.Go({ path: [`copyright/reports/clone/${reportType}`] });
            }),
            catchError(error => {
              return of(new fromRoot.CloneReportFail(error));
            }),
          );
      }),
    ),
  );

  sendRejectDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromRoot.SendRejectDocuments>(fromRoot.SEND_REJECT_DOCUMENTS),
      withLatestFrom(this.store.pipe(select(fromRoot.getCounterClaimWork))),
      switchMap(([action, counterClaimDetail]: [fromRoot.SendRejectDocuments, CounterClaimDetail]) => {
        const { id, documentsToReject } = counterClaimDetail;
        const reason = get(action, 'payload');
        return this.saveItemService.rejectDocument(id, documentsToReject, reason);
      }),
      map((response: any) => new fromRoot.SendRejectDocumentsSuccess(response)),
      catchError(error => of(new fromRoot.SendRejectDocumentsFail(error))),
    ),
  );

  submitSelectMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromRoot.SubmitSelectMode>(fromRoot.SUBMIT_SELECT_MODE),
      withLatestFrom(
        this.store.pipe(select(fromRoot.getSelectMode)),
        this.store.pipe(select(fromRoot.getGlobalApiNamespace)),
        this.store.pipe(select(fromRoot.getRouterUrl)),
        this.store.pipe(select(fromRoot.getRouterQueryParams)),
        this.store.pipe(select(fromRoot.getSearchSelectedRows)),
      ),
      switchMap(([a, selectMode, ns, path, params, searchResults]: [Action, SelectMode, string, string, { [key: string]: string }, SelectedSearchResultsBySection]) => {
        switch (selectMode.mode) {
          case SelectModeEnum.DELETE:
            return this.saveItemService.getSelectDeleteEndpoint(selectMode).pipe(
              tap(() => this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([path], { queryParams: params }))),
              map(() => new fromRoot.EndSelectMode()),
              catchError(error => of(new fromRoot.UpdateConflictsFail(error))),
            );

          case SelectModeEnum.SHARE:
            return from(cloneDeep(selectMode.selection)).pipe(
              concatMap(selectItem =>
                this.saveItemService.getSelectShareEndpoint(ns, selectItem, searchResults).pipe(
                  reduce((prevResponse, response) => response),
                  tap(() => this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([path], { queryParams: params }))),
                  map(() => new fromRoot.EndSelectMode()),
                  catchError(error => of(new fromRoot.UpdateConflictsFail(error))),
                ),
              ),
            );
        }
      }),
    ),
  );

  getAllTerritories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.GET_ALL_TERRITORIES),
      withLatestFrom(this.store.pipe(select(fromRoot.getAllTerritories))),
      switchMap(([action, allTerritories]) => {
        if (allTerritories && allTerritories.length > 200) {
          return EMPTY;
        }

        return forkJoin([
          this.detailService.makeRequest(TerritoryUtils.getAllTerritoriesRequestConfig(1)),
          this.detailService.makeRequest(TerritoryUtils.getAllTerritoriesRequestConfig(2)),
          this.detailService.makeRequest(TerritoryUtils.getAllTerritoriesRequestConfig(3)),
        ]).pipe(
          map((responses: any[]) => {
            if (responses.length === 3) {
              const allTerritoriesResponse = { items: responses[0]?.items.concat(responses[1]?.items).concat(responses[2]?.items), total: responses[0].total };
              return new fromRoot.SaveAllTerritories(TerritoryUtils.getAllTerritoriesResponseFormatted(allTerritoriesResponse));
            }
            return new fromRoot.GetAllTerritoriesFail();
          }),
          catchError(error => {
            return of(new fromRoot.CleanLoading(), new fromRoot.GetAllTerritoriesFail(error));
          }),
        );
      }),
    ),
  );

  upsertAgreementChains$ = createEffect(() =>
    this.store.select(fromRoot.getIpDetailAgreementChainsInboxItems).pipe(
      filter(items => items.length > 0),
      map(items => new fromRoot.UpsertAgreementChains(items)),
    ),
  );

  sortAgreementChains$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromRoot.UPSERT_AGREEMENT_CHAINS),
      map((action: fromRoot.UpsertAgreementChains) => action.payload),
      withLatestFrom(this.store.pipe(select(fromRoot.getIpDetailAgreementChains))),
      map(([newItems, agreementChains]) => {
        const { tableData, tableMap, entities } = agreementChains;
        const newTableData = [...tableData];
        const newTableMap = { ...tableMap };
        // dispatch action to get children automatically while depth is less than the defined initial depth
        // which can be configured in the flagsmith feature flag
        const maxDepth = flagsmith.getValue('cube-ui.ips.agreement-chains.max-depth') as number;
        const automatedDepth = flagsmith.getValue('cube-ui.ips.agreement-chains') as number;
        const maxRequestsCoefficient = 10; // fine-tuned number affecting the total requests

        let hasUpdates = false;

        newItems.forEach((newItem, newItemIndex) => {
          if (!newTableMap[newItem.id]) {
            hasUpdates = true;
            const index = tableData.findIndex(id => id === newItem.parent);
            newTableMap[newItem.id] = newItem.depth;
            newTableData.splice(index + 1, 0, newItem.id);
            const depthWeight = newItem.depth * automatedDepth;
            const tooManyRequests = newItemIndex > maxRequestsCoefficient - depthWeight;
            if (newItem.depth < automatedDepth && newItem.depth < maxDepth && !tooManyRequests) {
              const rootAgreement = newItem.parent ? entities[newItem.parentAgreementIds[0]] : newItem;
              const depth = newItem.depth + 1;
              const parentAgreementIds = newItem.parent ? newItem.parentAgreementIds.concat(newItem.id) : newItem.parentAgreementIds;
              this.store.dispatch(
                new fromRoot.StartApiCall({
                  apiCall: fromApiCalls.getAgreementChains,
                  apiCallData: {
                    cleanerData: { parent: newItem.id, depth, parentAgreementIds },
                    labels: {
                      tisDate: JSON.stringify(newItem.attributes.termTerritory.tisDate),
                      inExTisns: JSON.stringify(newItem.attributes.termTerritory.inExTisns || []),
                      rightTypes: JSON.stringify(newItem.attributes.shares[0].rights),
                      rootAssignorPartyId: rootAgreement.attributes.partyId,
                      rootAssignorPartyNameId: rootAgreement.attributes.partyNameId,
                      parentAgreementIds: JSON.stringify(parentAgreementIds),
                    },
                  },
                  callBack: (response, error) => {
                    if (error) {
                      if (error?.error?.message?.includes('Parent agreements form a cycle')) {
                        this.store.dispatch(new fromRoot.UpdateAgreementChain({ id: newItem.id, changes: { total: -1 } }));
                      }
                      return;
                    }
                    this.store.dispatch(new fromRoot.UpdateAgreementChain({ id: newItem.id, changes: { total: response.total } }));
                  },
                }),
              );
            }
          }
        });
        if (!hasUpdates) {
          return;
        }
        return new fromRoot.SortedAgreementChains({ tableData: newTableData, tableMap: newTableMap });
      }),
      // as we do an early return, we need to filter out the undefined values
      filter(action => !!action),
    );
  });

  checkStatus$ = checkStatus =>
    concatMap(
      result => (checkStatus && this.detailService.checkStatus(checkStatus.sectionOrObject, result, checkStatus.customStatusPath, true, checkStatus.isClone)) || of(result),
    );

  getStartDateClauseFromFilter(dateFrom: string): string {
    const formattedStartDate = DateTimeUtils.formatDate(moment(dateFrom));
    return `{"range": {"attributes.createdDate": {"gte": "${formattedStartDate}"}}}`;
  }

  getEndDateClauseFromFilter(dateTo: string): string {
    const formattedEndDate = DateTimeUtils.formatDate(moment(dateTo));
    return `{"range": {"attributes.createdDate": {"lte": "${formattedEndDate}"}}}`;
  }

  getUserIdClauseFromFilter(userId: string): string {
    return `{"wildcard":{"attributes.userid":"*${userId}*"}}`;
  }

  getEventDescriptionClauseFromFilter(eventDescription: string): string {
    return `{"equals":{"events.nameId":"${eventDescription}"}}`;
  }

  getVersionClauseFromFilter(version: string): string {
    return `{"equals":{"version":"${version}"}}`;
  }
}
