import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { StringUtils } from '@ice';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/search-form-builders';
import { ICE } from 'config/constants/global.constants';
import { FormConfig, SearchExpressions } from './form-config';

// Do not send IPI:ICE:123131
const getPartyNameRelations = value => {
  const splittedValue = value.split(':');
  const hasPrefix = splittedValue && splittedValue.length > 1;

  if (hasPrefix) {
    return `{"and":
                [
                    {"equals":{"partyName.relations.relation":"XREF"}},
                    {"equals":{"partyName.relations.otherId":"${value}"}}
                ]
            }`;
  }

  return `{"or":[
              {"equals":{"partyName.relations.otherId":"IPI:${value}"}},
              {"equals":{"partyName.relations.otherId":"ICE:${value}"}}
            ]}`;
};

export class CopyrightAgreementGroupSearchExpressions extends SearchExpressions {
  getSearchExpressionConfig(key: string, value: string, prefix: string, allUsers: any) {
    const splittedValue = value.split(':');
    const hasPrefix = splittedValue && splittedValue.length > 1;

    switch (key) {
      case 'id':
      case 'groupId':
        if (hasPrefix && splittedValue[0] === ICE) {
          return `{"equals": {"relations.otherId": "${value}"}}`;
        }
        if (StringUtils.isNumberLike(value)) {
          return `{"wildcard": {"relations.otherId": "*${value}*"}}`;
        }
        return `{"equals":{"attributes.id":"${value}"}}`;

      case 'key': // If we need in future when API works
        const addICEPrefix = !hasPrefix ? 'ICE:' : '';
        return `{"equals":{"relations[XREF].otherId":"${addICEPrefix}${value}"}}`;

      case 'ipName':
        return `{"or":[
                    {"wildcard":{"partyName.attributes.name":"*${value}*"}},
                    ${getPartyNameRelations(value)}
            ]}`;

      case 'ipNameKey':
        return getPartyNameRelations(value);
    }

    return `{"wildcard":{"attributes.${key}":"*${value}*"}}`;
  }

  getExtraSearchExpression() {
    return '';
  }
}

export class SearchCopyrightAgreementGroupForm implements FormConfig {
  constructor(private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  getForm(): FormlyFieldConfig[] {
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'groupId',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('AGREEMENT_GROUP.GROUP_ID'),
              required: false,
            },
          },
          {
            className: 'flex-1',
            key: 'ipName',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('AGREEMENT_GROUP.OWNER'),
              required: false,
            },
          },
          {
            className: 'flex-1',
            key: 'description',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('AGREEMENT_GROUP.DESCRIPTION'),
              required: false,
            },
          },
        ],
      },
    ];
  }
}
