import { Search } from '@ice';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationSearchCleaned, OrganizationSearchItem } from 'models/copyright/search/user-management-organization-search';

export class SearchOrganizationsParser {
  static parse(
    items: any[],
    translate: TranslateService,
    extraParams?: { search?: Search; queryParams?: any; organizations?: any; searchNs?: string },
    extraItems?: string[],
    store?: Store<any>,
  ) {
    return items.map((item: OrganizationSearchItem) => {
      const { id, attributes, roles } = item;
      let name;
      let type = '';
      if (attributes) {
        name = attributes.name;
        type = attributes.type;
      }
      return <OrganizationSearchCleaned>{ id, name, type, roles };
    });
  }
}
