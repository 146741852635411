import { FormControl, ValidationErrors } from '@angular/forms';
import { StringUtils } from '@ice/utils';
import { FormlyFieldConfig } from '@ngx-formly/core';

export class EmailValidatorFormly {
  static emailValidator(control: FormControl): ValidationErrors {
    return StringUtils.isEmail(control.value) ? null : { email: true };
  }
  static emailValidatorMessage(err, field: FormlyFieldConfig) {
    return (field.templateOptions && field.templateOptions['emailErrorMessage']) || '';
  }
}
