import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { ExpertSearchModule } from '@ice/components/expert-search-form/expert-search.module';
import { FormContainerModule } from '@ice/components/form-container/form-container.module';
import { SearchModule } from '@ice/components/search/search.module';
import { IceMaterialModule } from '@ice/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { ToolbarComponent } from 'app/root/components/toolbar/toolbar.component';

@NgModule({
  declarations: [ToolbarComponent],
  imports: [IceMaterialModule, ExpertSearchModule, FuseSharedModule, SearchModule, FormContainerModule, TranslateModule],
  exports: [ToolbarComponent],
})
export class ToolbarModule {}
