import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DataTableRow } from '@ice/components/data-table/data-table';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { TabsUtils } from '@ice';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import { SelectionDatatableBuilder } from 'config/data-table-builders/selection-datatable-builder';
import type { SectionTabConfig } from 'config/tabs-data-builders/tabs-data';
import { pull } from 'lodash';
import { of } from 'rxjs';
import { PermissionsService } from 'services/permissions/permissions.service';
import * as fromRoot from 'store/root';

export class TabWorkActivity implements SectionTabConfig {
  private selectionDatatable: SelectionDatatableBuilder;
  private canViewValueColumn: boolean;

  constructor(
    protected translate: TranslateService,
    protected fuseTranslationLoader: FuseTranslationLoaderService,
    protected store: Store<fromRoot.RootState>,
    private dialog: null,
    private commonApiService: null,
    private detailService: null,
    private nsService: null,
    private iceFacade: null,
    private fieldValidatorService: null,
    private permissionsService: PermissionsService,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.selectionDatatable = new SelectionDatatableBuilder(store, this.getDataTable(), null, null, null, 25, this.store.pipe(select(fromRoot.getWorkActivity)));
    this.canViewValueColumn = this.permissionsService.can('works_activity_value_read');
  }

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('WORKS.WORK_ACTIVITY.TAB_TITLE'),
              model: this.store.pipe(select(fromRoot.getWorkActivity)),
              sorts: [],
              ...this.selectionDatatable.getSelectionDatatableConfig(),
              loadingIndicator: false,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: true,
              shadowed: false,
              columnMode: 'flex',
              getRowClass: (row: any): any => ({ 'ice-search-results-table-row': true }),
              visibleColumns: of([...this.getActivityVisibleColumns(this.getDataTable())]),
            },
          },
        ],
      },
    ];
  }

  private getActivityVisibleColumns(schema: any): string[] {
    const schemaDatatable = TabsUtils.getSChemaPropsToArray(schema);
    return this.canViewValueColumn ? schemaDatatable : pull(schemaDatatable, 'value');
  }

  getDataTable(): DataTableRow[] {
    return [
      { name: this.translate.instant('ACTIVITY.DEADLINE_DATE'), prop: 'deadline', flexGrow: 0.8 },
      { name: this.translate.instant('ACTIVITY.VALUE'), prop: 'value', flexGrow: 0.8 },
      { name: this.translate.instant('ACTIVITY.UPLOAD_CATEGORY'), prop: 'uploadCategoryLabel', flexGrow: 1 },
      { name: this.translate.instant('ACTIVITY.REPORTING_SOCIETY'), prop: 'reportingSocietyLabel', flexGrow: 1 },
      { name: this.translate.instant('ACTIVITY.PROCESS'), prop: 'processLabel', flexGrow: 2 },
    ];
  }
}
