<div fxLayout="row" [ngClass]="to.wrapperClasses" attr.data-testid="wrapper-info">
  <div fxLayout="column" fxFlex="90">
    <ng-container #fieldComponent></ng-container>
  </div>
  <div fxLayout="column" fxFlex="10" fxLayoutAlign="center center">
    <mat-icon
      class="formFieldInfo"
      color="accent"
      [matTooltipPosition]="'above'"
      matTooltip="{{ to.infoText }}"
      [matTooltipClass]="'ice-tooltip ice-tooltip-wrapper-info'"
      attr.data-testid="wrapper-icon"
    >
      info
    </mat-icon>
  </div>
</div>
