import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '@ice/i18n/en/expansion-list';
import { Card } from './card';

@Component({
  selector: 'ice-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [MatExpansionPanel],
})
export class CardComponent {
  @Input() config: Card;
  filterValue = '';
  tempTooltip: string;
  isEdit = false;

  constructor(private fuseTranslationLoader: FuseTranslationLoaderService) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  headerActionButtonClick(event, actionButton) {
    event.stopPropagation();
    actionButton.onClick();
  }

  clear(event) {
    event.stopPropagation();
    event.preventDefault();
    this.filterValue = '';
    if (this.config.filter) {
      this.config.filter.onFilter('');
    }
  }
}
