import { AfterContentInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[iceAutofocus]',
})
export class AutoFocusDirective implements AfterContentInit {
  @Input() focus = true;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterContentInit() {
    if (this.focus) {
      this.renderer.selectRootElement(this.el.nativeElement).focus();
    }
  }
}
