import { Component, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '@ice/i18n/en/button-labels';
import { locale as englishErrors } from '@ice/i18n/en/error-messages';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CheckVersionService } from 'services/check-version/check-version.service';
import * as fromRoot from 'store/root';
import { getNavigation } from 'store/root';

@Component({
  selector: 'ice-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnDestroy {
  private fusePerfectScrollbar: FusePerfectScrollbarDirective;

  @ViewChild(FusePerfectScrollbarDirective) set directive(theDirective: FusePerfectScrollbarDirective) {
    if (!theDirective) {
      return;
    }

    this.fusePerfectScrollbar = theDirective;

    this.navigationServiceWatcher = this.navigationService.onItemCollapseToggled.subscribe(() => {
      this.fusePerfectScrollbarUpdateTimeout = setTimeout(() => {
        this.fusePerfectScrollbar.update();
      }, 310);
    });
  }

  @Input() layout;
  navigation$: Observable<any>;
  navigationServiceWatcher: Subscription;
  fusePerfectScrollbarUpdateTimeout;
  initialVersion$ = this.checkVersionService.currentVersion$.pipe(take(1));
  updatedVersion$ = this.checkVersionService.currentVersion$;
  needsUpdate$ = this.checkVersionService.needsUpdate$;

  constructor(
    private sidebarService: FuseSidebarService,
    private navigationService: FuseNavigationService,
    private store: Store<fromRoot.AuthState>,
    private checkVersionService: CheckVersionService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.fuseTranslationLoader.loadTranslations(englishErrors);
    // Navigation data
    this.navigation$ = this.store.select(getNavigation);

    // Default layout
    this.layout = 'vertical';
  }

  ngOnDestroy() {
    if (this.fusePerfectScrollbarUpdateTimeout) {
      clearTimeout(this.fusePerfectScrollbarUpdateTimeout);
    }

    if (this.navigationServiceWatcher) {
      this.navigationServiceWatcher.unsubscribe();
    }
  }

  toggleSidebarOpened(key) {
    this.sidebarService.getSidebar(key).toggleOpen();
  }

  refreshApp() {
    window.location.reload();
  }

  toggleSidebarFolded(key) {
    this.sidebarService.getSidebar(key).toggleFold();
  }
}
