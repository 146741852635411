import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { IceDialogMultiLayout } from '@ice/dynamic-components/group-component/group-component';
import { Store } from '@ngrx/store';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import { isApiCallConfig } from 'config/sections-config/api-call';
import { Observable, of } from 'rxjs';
import { DatatableService } from 'services/datatable/datatable.service';
import * as fromRoot from 'store/root';
import * as fromActions from 'store/root/actions';
import { BasicButton } from '../response-error/response-error';

@Component({
  selector: 'ice-dialog-multi-layout',
  templateUrl: 'dialog-multi-layout.component.html',
  styleUrls: ['./dialog-multi-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogMultiLayoutComponent implements AfterViewInit {
  @ViewChild('popupHeader', { static: true }) headerElement: ElementRef;
  @ViewChild('scrollSpy', { static: true }) scrollSpy: ElementRef;
  public layout = 0;
  verticalScrollBarVisible: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IceDialogMultiLayout,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private elementRef: ElementRef,
    private detCh: ChangeDetectorRef,
    public dialogRef: MatDialogRef<DialogMultiLayoutComponent>,
    private store: Store<fromRoot.RootState>,
    private datatableService: DatatableService,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  ngAfterViewInit() {
    this.checkScrollBar();
  }

  getSelectedIndex() {
    return 0;
  }

  tabAction(layout) {
    if (this.data.layouts.length > layout) {
      this.layout = layout;
    }
  }

  onScroll(event) {
    if (!!this.data.doPagination && event.target.scrollTop + event.target.offsetHeight >= event.target.scrollHeight - 1) {
      this.data.doPagination();
    }
    const dataTableElem = document.body.querySelector('ngx-datatable');
    if (dataTableElem && Math.round(event.target.scrollTop + event.target.offsetHeight + 2) >= event.target.scrollHeight) {
      const scrollApiCallConfigPayload = this.datatableService.scrollApiCall;
      const scrollApiCallConfig = scrollApiCallConfigPayload && (isApiCallConfig(scrollApiCallConfigPayload) ? scrollApiCallConfigPayload : scrollApiCallConfigPayload());
      if (!!scrollApiCallConfig) {
        this.store.dispatch(new fromActions.PaginateApiCall(scrollApiCallConfig));
      }
    }
  }

  onClick(action) {
    action.onClick(this);
  }

  isLoading(): Observable<boolean> {
    if (this.data.hasOwnProperty('loading')) {
      return this.data.loading;
    }
    return of(false);
  }

  getLoadingText(): Observable<string> {
    if (this.data.hasOwnProperty('loadingText')) {
      return this.data.loadingText;
    }
    return of(null);
  }

  getLoadingButtons(): Observable<BasicButton[]> {
    if (this.data.hasOwnProperty('loadingButtons')) {
      return this.data.loadingButtons;
    }
    return of(null);
  }

  setLayout(layout) {
    this.layout = layout;
    if (!this.detCh['destroyed']) {
      this.detCh.detectChanges();
    }
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
  }

  checkScrollBar() {
    const scrollTopProperty = 'scrollTop';
    let verticalScrollBarVisible = !!this.headerElement?.nativeElement?.parentNode?.parentElement?.scrollTop;

    if (!verticalScrollBarVisible) {
      this.headerElement.nativeElement.parentNode.parentElement.scrollTop = 1;
      verticalScrollBarVisible = !!this.headerElement.nativeElement.parentNode.parentElement.scrollTop;
      this.headerElement.nativeElement.parentNode.parentElement.scrollTop = 0;
    }
    this.verticalScrollBarVisible = verticalScrollBarVisible;
  }

  dialogTitleClass(layout) {
    return layout.customClass ?? 'dialog-title';
  }
}
