import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as englishErrors } from 'assets/i18n/en/app/formly-global-message-errors';
import { locale as english } from 'assets/i18n/en/config/section-new-data-builders';
import * as fromRoot from 'store/root';
import { StepType } from '../stepper-config';
import { CounterclaimNotificationsUploadDocumentationStep } from './steps/counterclaim-notifications-upload-documentation';

export class CounterclaimNotificationsSteps {
  static getSteps(translate: TranslateService, translationLoader: FuseTranslationLoaderService, store: Store<fromRoot.RootState>): StepType[] {
    translationLoader.loadTranslations(english, englishErrors);
    return [new CounterclaimNotificationsUploadDocumentationStep(translate, translationLoader, store).getStep()];
  }
}
