import * as fromActions from 'store/root/actions';
import * as fromState from 'store/root/state';

export function reducer(state: fromState.UiState = fromState.initialUiState, action: fromActions.UiActions): fromState.UiState {
  switch (action.type) {
    case fromActions.SHOW_ROUTE_LOADING_VISIBILITY: {
      return { ...state, progressBar: { ...state.progressBar, routerLoading: action.payload } };
    }
    case fromActions.STATUS_DIALOG_COMPONENT: {
      return { ...state, dialogStatus: { ...state.dialogStatus, isOpen: action.payload } };
    }
    case fromActions.SET_NAVIGATION: {
      return { ...state, navigation: action.payload };
    }
    case fromActions.SHOW_DATA_LOADING_VISIBILITY: {
      return { ...state, progressBar: { ...state.progressBar, dataLoading: action.payload } };
    }
    case fromActions.ADVANCED_SEARCH_TOGGLE: {
      return {
        ...state,
        searchBar: { ...state.searchBar, advancedCollapsed: action.payload || !state.searchBar.advancedCollapsed },
      };
    }
    case fromActions.EXPERT_SEARCH_MODE_SET: {
      return {
        ...state,
        searchBar: { ...state.searchBar, expertCollapsed: action.payload },
      };
    }
    case fromActions.EXPERT_SEARCH_MODE_TOGGLE: {
      return {
        ...state,
        searchBar: { ...state.searchBar, expertCollapsed: !state.searchBar.expertCollapsed },
      };
    }
    case fromActions.SHOW_SNACKBAR: {
      return { ...state, snackBar: { ...state.snackBar, visible: true, configuration: action.payload } };
    }
    case fromActions.RESET_SNACKBAR: {
      return { ...state, snackBar: { ...state.snackBar, visible: false, configuration: null } };
    }
    case fromActions.SET_SIDEBAR_OPEN: {
      return { ...state, sidebar: { isOpen: action.payload } };
    }
    case fromActions.RESET_TERRITORY_SEARCH_INPUT: {
      return { ...state, forms: { resetTerritorySearchInput: action.payload.resetTerritorySearchInput } };
    }
    case fromActions.SET_FAB_MAIN_BUTTONS_COUNT: {
      return {
        ...state,
        fabButtons: { mainCount: action.payload, childCount: 0 },
      };
    }
    case fromActions.SET_FAB_CHILD_BUTTONS_COUNT: {
      return {
        ...state,
        fabButtons: { ...state.fabButtons, childCount: action.payload },
      };
    }
  }

  return state;
}

export const getSnackBar = (state: fromState.UiState) => state.snackBar;
export const getSearchBarToggleState = (state: fromState.UiState) => state.searchBar;
export const getProgressBarState = (state: fromState.UiState) => state.progressBar;
export const getNavigationState = (state: fromState.UiState) => state.navigation;
export const getDialogStatus = (state: fromState.UiState) => state.dialogStatus;
export const getSideBarState = (state: fromState.UiState) => state.sidebar;
export const getForms = (state: fromState.UiState) => state.forms;
export const getFabToggle = (state: fromState.UiState) => state.fabButtons.mainCount + state.fabButtons.childCount > 3;
