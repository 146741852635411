<form [formGroup]="advancedSearchForm" #formDir="ngForm">
  <div class="ice-mb-15">
    <ice-query-editor-expert-search
      (ngModelChange)="ngModelChange($event)"
      [queryLanguageParser]="queryLanguageParser"
      formControlName="expertSearchFormControl"
      name="expertSearchFormControl"
      #expertSearchFormControl
    ></ice-query-editor-expert-search>
    <div *ngIf="f.invalid && showError" style="color: #F44436; margin-top:8px; font-weight: bold">{{ f.errors['syntaxError'] }}</div>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px" class="ice-pb-16">
    <mat-form-field style="flex: 1">
      <mat-chip-list #chipList>
        <mat-chip
          *ngFor="let queryChip of queryChips"
          [removable]="removable"
          (removed)="remove(queryChip)"
          (click)="selectQuery(queryChip)"
          [selected]="queryChip.selected"
          color="accent"
        >
          {{ queryChip.name }}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
          formControlName="queryChipInput"
          placeholder="Save search as..."
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event)"
        />
      </mat-chip-list>
      <mat-hint align="end" *ngIf="queryChips.length >= 10">You reached the maximum of 10 queries. Please delete a query to new another one.</mat-hint>
    </mat-form-field>
    <button *ngIf="showUploadXref" mat-raised-button color="accent" [disabled]="false" class="expert-search-upload-xref" (click)="uploadXrefClick()">
      {{ 'UPLOAD_XREF' | translate }}
    </button>
    <button type="submit" mat-raised-button color="accent" [disabled]="false" class="expert-search-submit" (click)="submitClick($event, expertSearchFormControl.value)">
      {{ 'SEARCH_EXPERT' | translate }}
    </button>
    <button class="search-expert-clear-button" type="button" mat-raised-button color="primary" (click)="resetClick($event)">
      {{ 'FORM_RESET' | translate }}
    </button>
  </div>
</form>
