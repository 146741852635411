import { MatDialogRef } from '@angular/material/dialog';
import { ActivityDetail, CaseWorkDetail, WorkUtils } from '@ice';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { IceLayout } from '@ice/dynamic-components/group-component/group-component';
import { ActivityUtils } from '@ice/utils/activity/activity.utils';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as fromApiCalls from 'config/api-calls';
import { SectionsConfig } from 'config/sections-config';
import { ApiCallStatus, ApiResponseStatus } from 'config/sections-config/api-call';
import { get, isEmpty, uniqBy } from 'lodash';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';
import * as fromRoot from 'store/root';
import * as fromActions from 'store/root/actions';
type ActionValue = 'CONFIRMED' | 'NO_MATCH' | 'NO_MERGE';
export class DialogMatchConflict {
  static dialogRefNoMatch: MatDialogRef<DialogMultiLayoutComponent, any>;
  static dialogRefMerge: MatDialogRef<DialogMultiLayoutComponent, any>;
  static dialogMatchConflictRef: MatDialogRef<DialogMultiLayoutComponent, any>;
  static ignoredClaimsList = [];

  static openDialog(dialog, translate: TranslateService, store: Store<fromRoot.RootState>, actionValue: ActionValue) {
    const responseText = new BehaviorSubject(null);
    const workResolutionStart = new BehaviorSubject(false);
    combineLatest([store.pipe(select(fromRoot.getActivityWorkToCompare)), store.pipe(select(fromRoot.getActivityWorkAnalysis))])
      .pipe(take(1))
      .subscribe((data: any) => {
        store.dispatch(new fromRoot.CleanResponse());
        store.dispatch(
          new fromRoot.WorkResolution({
            id1: data[0].id || '',
            id2: data[1].id || '',
            ns: data[0].ns || '',
            ns2: data[1].ns || '',
            cause: 'not specified',
            outcome: actionValue,
          }),
        );
        workResolutionStart.next(true);
      });
    combineLatest([store.select(fromRoot.getCopyrightResponse), workResolutionStart.pipe(filter(data => !!data))])
      .pipe(
        map(([response]) => response),
        filter(response => !!response),
        withLatestFrom(store.pipe(select(fromRoot.getConflictsActivity)), store.pipe(select(fromRoot.getActivityWorkAnalysis))),
        take(1),
      )
      .subscribe(([response, conflictData, workAnalysis]) => {
        const status = response.status;
        let message: string;
        switch (status) {
          case 'success':
            this.dialogMatchConflictRef.close();
            this.onSuccess({ store, conflictData, actionValue, workAnalysis });
            break;
          case 'error':
            store.dispatch(new fromActions.ShowDataLoadingVisibility(false));
            message = translate.instant('ACTIVITY.MATCH_POPUP.MASTERING_ERROR');
            break;

          case 'in_progress':
          case 'in-progress':
            message = translate.instant('ACTIVITY.MATCH_POPUP.TIMEOUT_ERROR');
        }
        responseText.next({ text: message });
      });
    this.dialogMatchConflictRef = dialog.open(DialogMultiLayoutComponent, {
      disableClose: true,
      data: {
        className: 'force-merge-dialog',
        loading: store.pipe(select(fromRoot.getCopyrightLoading)),
        loadingButtons: of([
          {
            text: of(translate.instant('ACTIVITY.MATCH_POPUP.CLOSE')),
            action: () => {
              store.dispatch(new fromRoot.UpdateField({ object: 'cancelCurrentProcess', newValue: true, type: 'edit' }));
              store.dispatch(new fromRoot.CleanResponse());
              this.dialogMatchConflictRef.close();
            },
          },
        ]),
        layouts: [
          {
            layout: [
              {
                group: [
                  {
                    type: 'response-error',
                    config: {
                      response: responseText,
                      responseButtons: of([
                        {
                          text: of(translate.instant('POPUP.CLOSE')),
                          action: () => {
                            store.dispatch(new fromRoot.CleanResponse());
                            this.dialogMatchConflictRef.close();
                          },
                        },
                      ]),
                      errors: of(null),
                      errorButtons: of(null),
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    });
  }

  static openDialogPossMatch(dialog, translate: TranslateService, store: Store<fromRoot.RootState>, actionValue: ActionValue) {
    const responseText = new BehaviorSubject(null);
    this.dialogRefNoMatch = dialog.open(DialogMultiLayoutComponent, {
      disableClose: false,
      data: {
        className: 'no-match-dialog',
        loading: store.pipe(select(fromRoot.getCopyrightLoading)),
        layouts: [
          this.getPossMatchLayout(store, translate, responseText, actionValue),
          this.getResponseLayout(store, translate, responseText, 'ACTIVITY.NO_MATCH.TITLE', 'NO_MATCH'),
        ],
      },
    });

    store
      .select(fromRoot.getConflictsActivity)
      .pipe(
        take(1),
        filter((activity: ActivityDetail) => activity && activity.optionsToCompare && activity.optionsToCompare.length === 0),
      )
      .subscribe(() => this.updateNoMatchConflict(store, translate, responseText, null, actionValue));
  }

  static openDialogMerge(dialog, translate: TranslateService, store: Store<fromRoot.RootState>, actionValue: ActionValue) {
    const responseText = new BehaviorSubject(null);
    store
      .select(fromRoot.getActivityWorkAnalysis)
      .pipe(withLatestFrom(store.pipe(select(fromRoot.getActivityWorkToCompare)), store.pipe(select(fromRoot.getActivityWorkAnalysis))), take(1))
      .subscribe(([workAnalysis, workToCompare]) => {
        this.dialogRefMerge = dialog.open(DialogMultiLayoutComponent, {
          disableClose: true,
          data: {
            className: 'merge-dialog',
            loading: store.pipe(select(fromRoot.getCopyrightLoading)),
            layouts: [
              this.getMergeConfirmationLayout(store, translate, responseText, actionValue, workToCompare, workAnalysis),
              this.getResponseLayout(store, translate, responseText, 'ACTIVITY.MERGE.TITLE', 'MERGE'),
            ],
          },
        });
      });

    responseText.pipe(take(1)).subscribe(data => {
      if (data) {
        this.dialogMatchConflictRef = dialog.open(DialogMultiLayoutComponent, {
          disableClose: true,
          data: {
            className: 'force-merge-dialog',
            loading: store.pipe(select(fromRoot.getCopyrightLoading)),
            loadingButtons: of([
              {
                text: of(translate.instant('ACTIVITY.MATCH_POPUP.CLOSE')),
                action: () => {
                  store.dispatch(new fromRoot.UpdateField({ object: 'cancelCurrentProcess', newValue: true, type: 'edit' }));
                  store.dispatch(new fromRoot.CleanResponse());
                  this.dialogMatchConflictRef.close();
                },
              },
            ]),
            layouts: [
              {
                layout: [
                  {
                    group: [
                      {
                        type: 'response-error',
                        config: {
                          response: responseText,
                          responseButtons: of([
                            {
                              text: of(translate.instant('POPUP.CLOSE')),
                              action: () => {
                                store.dispatch(new fromRoot.CleanResponse());
                                this.dialogMatchConflictRef.close();
                              },
                            },
                          ]),
                          errors: of(null),
                          errorButtons: of(null),
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        });
      }
    });
  }

  static getResponseLayout(store: Store<fromRoot.RootState>, translate: TranslateService, responseText, title, sourceType) {
    return {
      title: of(translate.instant(title)),
      actions: null,
      layout: [
        {
          group: [
            {
              type: 'response-error',
              config: {
                response: responseText,
                responseButtons: of([
                  {
                    text: of(translate.instant('POPUP.CLOSE')),
                    action: () => {
                      store.dispatch(new fromRoot.CleanResponse());
                      if (sourceType === 'MERGE') {
                        this.dialogRefMerge.close();
                      } else {
                        this.dialogRefNoMatch.close();
                      }
                    },
                  },
                ]),
                errors: of(null),
                errorButtons: of(null),
              },
            },
          ],
        },
      ],
    };
  }

  static getPossMatchLayout(store: Store<fromRoot.RootState>, translate: TranslateService, responseText, actionValue: ActionValue): IceLayout {
    return {
      title: of(translate.instant('ACTIVITY.NO_MATCH.TITLE')),
      actions: null,
      layout: [
        {
          group: [
            {
              type: 'formly',
              config: {
                submitAvailable: true,
                submitLabel: translate.instant('ACTIVITY.NO_MATCH.CONFIRM'),
                button1Enabled: true,
                button1Available: true,
                button1Label: translate.instant('ACTIVITY.NO_MATCH.CANCEL'),
                submit: (event: any) => this.updateNoMatchConflict(store, translate, responseText, event, actionValue),
                onButton1: () => this.dialogRefNoMatch.close(),
                model: combineLatest([
                  store.pipe(select(fromRoot.getActivityWorkToCompare)),
                  store.pipe(select(fromRoot.getActivityOptionsToCompare)),
                  store.pipe(select(fromRoot.getActivityWorkAnalysis)),
                ]).pipe(
                  map(data => {
                    const masteredWork = WorkUtils.trimCubeId(data[0]['id']);
                    let compareTo = WorkUtils.trimCubeId(data[2]['id']);
                    let compareToID = data[2]['id'];
                    if (masteredWork === compareTo) {
                      const nextOption = (data[1] as Array<any>).find(option => !(option as any).value.includes(masteredWork));
                      compareTo = nextOption.value.includes('ICE') ? WorkUtils.trimICEId((nextOption as any).value) : WorkUtils.trimCubeId((nextOption as any).value);
                      compareToID = (nextOption as any).value;
                    }
                    return [...data, masteredWork, compareTo, compareToID];
                  }),
                  map(data => ({
                    masteredWork: data[3],
                    compareTo: data[4],
                    mastered: data[0]['id'],
                    toCompare: data[5],
                  })),
                ),
                formBuilder: of([
                  {
                    key: 'toCompare',
                    type: 'input',
                    hideExpression: true,
                  },
                  {
                    key: 'mastered',
                    type: 'input',
                    hideExpression: true,
                  },
                  {
                    className: 'flex-1 non-match-work-relation-mastered-to',
                    key: 'masteredWork',
                    type: 'input',
                    templateOptions: {
                      label: translate.instant('ACTIVITY.NO_MATCH.MASTERED_TO'),
                      disabled: true,
                    },
                  },
                  {
                    className: 'flex-1 edit-mode-type-select',
                    key: 'typeSelected',
                    type: 'select',
                    defaultValue: '',
                    templateOptions: {
                      label: translate.instant('ACTIVITY.NO_MATCH.OPTIONS.LABEL'),
                      required: true,
                      options: [
                        { label: translate.instant('ACTIVITY.NO_MATCH.OPTIONS.SAMPLES'), value: 'MTCH_CONFLICT_SAMPLE' },
                        { label: translate.instant('ACTIVITY.NO_MATCH.OPTIONS.MODIFIES'), value: 'MTCH_CONFLICT_MODIFICATION' },
                        { label: translate.instant('ACTIVITY.NO_MATCH.OPTIONS.EXCERPTS'), value: 'MTCH_CONFLICT_EXCERPT' },
                        { label: translate.instant('ACTIVITY.NO_MATCH.OPTIONS.IS_SAMPLED_BY'), value: 'MTCH_CONFLICT_SAMPLE#MTCH_CONFLICT_SAMPLE' },
                        { label: translate.instant('ACTIVITY.NO_MATCH.OPTIONS.IS_MODIFIED_BY'), value: 'MTCH_CONFLICT_MODIFICATION#MTCH_CONFLICT_MODIFICATION' },
                        { label: translate.instant('ACTIVITY.NO_MATCH.OPTIONS.IS_EXCERPTS_BY'), value: 'MTCH_CONFLICT_EXCERPT#MTCH_CONFLICT_EXCERPT' },
                      ],
                    },
                  },
                  {
                    className: 'flex-1 non-match-work-relation-compare-to',
                    key: 'compareTo',
                    type: 'input',
                    templateOptions: {
                      label: translate.instant('ACTIVITY.NO_MATCH.COMPARED_TO'),
                      disabled: true,
                    },
                  },
                ]),
              },
            },
          ],
        },
      ],
    };
  }

  static updateNoMatchConflict(store: Store<fromRoot.RootState>, translate: TranslateService, responseText, response, actionValue: ActionValue) {
    this.dialogRefNoMatch.componentInstance.setLayout(1);
    // # means isXXX relation and withouth is the other type (sampled, modified...)
    const cause = (response && response.typeSelected && response.typeSelected.split('#')) || null;
    store.dispatch(new fromRoot.CleanResponse());
    store.dispatch(
      new fromRoot.WorkResolution({
        // cause.length>1 means isXX relation
        id1: cause && cause.length > 1 ? (response && response.toCompare) || '' : (response && response.mastered) || '',
        id2: cause && cause.length > 1 ? (response && response.mastered) || '' : (response && response.toCompare) || '',
        ns: 'CUBE',
        ns2: 'CUBE',
        cause: (cause && cause[0]) || '',
        outcome: actionValue,
      }),
    );
    store
      .pipe(
        select(fromRoot.getCopyrightResponse),
        filter(res => !!res),
        withLatestFrom(
          store.pipe(select(fromRoot.getConflictsActivity)),
          store.pipe(select(fromRoot.getCopyrightApiCallStatus)),
          store.pipe(select(fromRoot.getActivityWorkAnalysis)),
        ),
        take(1),
        map(([res, conflictData, currentApiCallStatus, workAnalysis]) => {
          let message = translate.instant('ACTIVITY.MATCH_POPUP.MASTERING_ERROR');
          const status = res.status;

          if (currentApiCallStatus.status === ApiCallStatus.Cancel) {
            store.dispatch(new fromActions.ShowDataLoadingVisibility(false));
            store.dispatch(new fromRoot.CleanResponse());
            store.dispatch(new fromRoot.UpdateField({ object: 'cancelCurrentProcess', newValue: false, type: 'edit' }));
          } else {
            switch (status) {
              case 'success':
                this.dialogRefNoMatch.close();
                this.onSuccess({ store, conflictData, actionValue, workAnalysis });
                message = ''; // Set message to empty string for 'success' case
                break;
              case 'error':
                store.dispatch(new fromActions.ShowDataLoadingVisibility(false));
                message = res && res.error ? res.error : message;
                break;
              case 'in_progress':
              case 'in-progress':
                message = translate.instant('ACTIVITY.MATCH_POPUP.TIMEOUT_ERROR');
                break;
              default:
                break;
            }
          }
          responseText.next({ text: message });
        }),
      )
      .subscribe();
  }

  static updateMergeConflict(store: Store<fromRoot.RootState>, translate: TranslateService, responseText, actionValue: ActionValue, singleOption) {
    if (!singleOption) {
      this.dialogRefMerge.componentInstance.setLayout(1);
    }
    store.dispatch(new fromRoot.CleanResponse());
    store.dispatch(
      new fromRoot.WorkResolution({
        ns: 'CUBE',
        ns2: 'CUBE',
        cause: 'User initiated forced merge',
        forcedMerge: true,
        outcome: actionValue || '',
      }),
    );
    store
      .pipe(
        select(fromRoot.getCopyrightResponse),
        filter(res => !!res),
        withLatestFrom(
          store.pipe(select(fromRoot.getConflictsActivity)),
          store.pipe(select(fromRoot.getCopyrightApiCallStatus)),
          store.pipe(select(fromRoot.getActivityWorkAnalysis)),
        ),
        take(1),
        map(([res, conflictData, currentApiCallStatus, workAnalysis]) => {
          if (currentApiCallStatus.status === ApiCallStatus.Cancel) {
            store.dispatch(new fromActions.ShowDataLoadingVisibility(false));
            store.dispatch(new fromRoot.CleanResponse());
            store.dispatch(new fromRoot.UpdateField({ object: 'cancelCurrentProcess', newValue: false, type: 'edit' }));
          } else {
            const status = res.status;
            let message: string;
            switch (status) {
              case 'success':
                this.dialogRefMerge.close();
                this.onSuccess({ store, conflictData, actionValue, workAnalysis });
                break;
              case 'error':
                store.dispatch(new fromActions.ShowDataLoadingVisibility(false));
                if (res && res.error) {
                  message = res.error;
                  break;
                } else {
                  message = translate.instant('ACTIVITY.MATCH_POPUP.MASTERING_ERROR');
                  break;
                }
              case 'in_progress':
                message = translate.instant('ACTIVITY.MATCH_POPUP.TIMEOUT_ERROR');
            }
            responseText.next({ text: message });
          }
        }),
      )
      .subscribe();
  }

  static updateIgnoreClaimsInMergeConflict(store: Store<fromRoot.RootState>, translate: TranslateService, responseText, actionValue: ActionValue) {
    const topLevelClaims = ActivityUtils.getTopLevelParents(this.ignoredClaimsList).map(claim => ({ claimId: claim.claimId, ipiNameKey: claim.ipiNameKey, name: claim.name }));

    store.dispatch(
      new fromRoot.StartApiCall({
        apiCall: fromApiCalls.getActivityConflicts,
        callBack: (conflictsResponse, conflictsError, apiCallData) => {
          if (conflictsError) {
            return;
          }
          store
            .select(fromRoot.getActivityWorkAnalysis)
            .pipe(
              withLatestFrom(store.select(fromRoot.getActivityWorkToCompare)),
              filter(([workAnalysis, workToCompare]) => !isEmpty(workAnalysis) && !isEmpty(workToCompare)),
              take(1),
            )
            .subscribe(([workAnalysis, workToCompare]) => {
              const conflictWorkIds: string[] = conflictsResponse.attributes.data.conflictWorkIds;
              // conflictWorkIds can have very strange work ID formats, so we need to get the normalized ones from
              // works search API.
              const apiCallBody = JSON.stringify({
                or: conflictWorkIds.map(id => ({
                  equals: {
                    'relations.otherId': id,
                  },
                })),
              });

              store.dispatch(
                new fromRoot.StartApiCall({
                  apiCall: fromApiCalls.searchWorks,
                  apiCallData: {
                    body: apiCallBody,
                  },
                  callBack: (worksResponse, worksError) => {
                    if (!worksError) {
                      const shortWorkIds = [workAnalysis.id, workToCompare.id].map(WorkUtils.workIdWithoutNamespaces);
                      const shortConflictWorkIds = new Set(worksResponse.items.map(({ id }) => WorkUtils.workIdWithoutNamespaces(id)));

                      const worksMatchConflictWorks = shortWorkIds.every(workId => shortConflictWorkIds.has(workId));
                      if (!worksMatchConflictWorks) {
                        store.dispatch(new fromRoot.IceError({ message: translate.instant('ERROR.UI.WORKS_CONFLICT_OUTDATED'), severity: 'info' }));
                        return;
                      }

                      store.dispatch(new fromRoot.IgnoreMergeClaims(uniqBy(topLevelClaims, 'claimId')));
                      store.dispatch(
                        new fromRoot.StartApiCall({
                          apiCall: fromApiCalls.IgnoreMergeClaims,
                          callBack: (resultIgnoredClaimApiCall, errorIgnoredClaimApiCall, apiCallDataIgnoredClaim) => {
                            if (!errorIgnoredClaimApiCall) {
                              this.updateMergeConflict(store, translate, responseText, actionValue, false);
                            }
                          },
                        }),
                      );
                    }
                  },
                }),
              );
            });
        },
      }),
    );
  }

  static onSuccess({
    store,
    conflictData,
    actionValue,
    workAnalysis,
  }: {
    store: Store<fromRoot.RootState>;
    conflictData?: any;
    actionValue: ActionValue;
    workAnalysis?: CaseWorkDetail;
  }) {
    store.dispatch(
      new fromRoot.StartApiCall({
        apiCall: fromApiCalls.getConflictsById,
        apiCallData: { labels: { id: workAnalysis?.id || '' } },
        callBack: work => {
          const possMatch = 'POSS_MATCH';
          const conflictsMatchId = [];
          const conflictsNoMatchId = [];
          const conflicts = get(work, 'conflicts', []);
          const conflictId = (conflictData && conflictData.id) || '';
          const pattern = /[^_]*\d+/;
          const workId = conflictData.id?.match(pattern)?.[0] || get(work, 'id', '');
          conflicts.forEach(conflict => {
            if (conflict.conflictId.includes(possMatch)) {
              conflictsMatchId.push(conflict.conflictId);
            } else {
              conflictsNoMatchId.push(conflict.conflictId);
            }
          });

          if (conflictId.includes(possMatch)) {
            if (conflictsMatchId?.length) {
              const conflictsMatchNextId = conflictsMatchId.find(match => match === conflictId) || conflictsMatchId[0];
              store.dispatch(
                new fromRoot.Go({ path: [`/${SectionsConfig.CONFLICTS.domainName}/${SectionsConfig.CONFLICTS.name}/${conflictsMatchNextId}/analysis`], forceReload: true }),
              );
            } else if (conflictsNoMatchId?.length) {
              store.dispatch(new fromRoot.Go({ path: [`/${SectionsConfig.WORKS.domainName}/${SectionsConfig.WORKS.name}/${workId}/conflicts-cc`] }));
            } else {
              store.dispatch(new fromRoot.Go({ path: [`/${SectionsConfig.WORKS.domainName}/${SectionsConfig.WORKS.name}/${workId}/details`] }));
            }
          } else if (conflicts && conflicts.length && conflicts.length > 0) {
            if (conflicts.length === 1 && conflicts[0].conflictId === conflictId) {
              store.dispatch(new fromRoot.Go({ path: [`/${SectionsConfig.WORKS.domainName}/${SectionsConfig.WORKS.name}/${workId}/details`] }));
            } else {
              store.dispatch(new fromRoot.Go({ path: [`/${SectionsConfig.WORKS.domainName}/${SectionsConfig.WORKS.name}/${workId}/conflicts-cc`] }));
            }
          } else {
            if (actionValue === 'CONFIRMED') {
              return store.dispatch(new fromRoot.Go({ path: [`/${SectionsConfig.WORKS.domainName}/${SectionsConfig.WORKS.name}/${workId}/all-claims`] }));
            }
            if (actionValue === 'NO_MATCH') {
              return store.dispatch(new fromRoot.Go({ path: [`/${SectionsConfig.WORKS.domainName}/${SectionsConfig.WORKS.name}/${workId}/details`] }));
            }
            console.error(`Action value ${actionValue} not supported`); // let's see if this happens
          }
        },
      }),
    );
  }

  static getMergeConfirmationLayout(store: Store<fromRoot.RootState>, translate: TranslateService, responseText, actionValue: ActionValue, workToCompare, workAnalysis) {
    return {
      className: 'w40-vw h95-vh',
      title: of(translate.instant('ACTIVITY.MERGE.MERGE_IGNORE_CLAIMS')),
      customClass: 'dialog-title not-border',
      titleClass: 'ice-ignore-claim-dialog-description',
      actions: null,
      layout: [
        {
          group: [
            {
              type: 'htmlTemplate',
              config: {
                htmlTemplate: of(`${translate.instant('ACTIVITY.MERGE.MERGE_IGNORE_CLAIMS_TITLE', { iceWorkKey: workToCompare.id, workTitle: workToCompare.title })}`),
                htmlClass: 'ice-ignore-claim-dialog-description ice-ignore-claim-title-margin',
              },
            },
          ],
        },
        {
          group: [
            {
              type: 'dataTable',
              config: {
                headerTitle: translate.instant('ACTIVITY.MERGE.MERGE_IGNORE_CLAIMS_COLLECTIONS'),
                headerClass: 'ice-ignore-claim-dialog-description ice-ignore-claim-description-margin',
                schema: this.getMergeDataTableSchema(translate),
                isSelectable: false,
                showLoader: true,
                data: this.getMergeDataTableData('selectedClaimsAnalysisLeft_collection'),
              },
            },
          ],
        },
        {
          group: [
            {
              type: 'dataTable',
              config: {
                headerTitle: translate.instant('ACTIVITY.MERGE.MERGE_IGNORE_CLAIMS_OWNERSHIPS'),
                headerClass: 'ice-ignore-claim-dialog-description ice-ignore-claim-title-margin',
                schema: this.getMergeDataTableSchema(translate),
                isSelectable: false,
                showLoader: true,
                data: this.getMergeDataTableData('selectedClaimsAnalysisLeft_ownership'),
              },
            },
          ],
        },
        {
          group: [
            {
              type: 'htmlTemplate',
              config: {
                htmlTemplate: of(`${translate.instant('ACTIVITY.MERGE.MERGE_IGNORE_CLAIMS_TITLE', { iceWorkKey: workAnalysis.id, workTitle: workAnalysis.title })}`),
                htmlClass: 'ice-ignore-claim-dialog-description ice-ignore-claim-title-margin',
              },
            },
          ],
        },
        {
          group: [
            {
              type: 'dataTable',
              config: {
                headerTitle: translate.instant('ACTIVITY.MERGE.MERGE_IGNORE_CLAIMS_COLLECTIONS'),
                headerClass: 'ice-ignore-claim-dialog-description ice-ignore-claim-description-margin',
                schema: this.getMergeDataTableSchema(translate),
                isSelectable: false,
                showLoader: true,
                data: this.getMergeDataTableData('selectedClaimsAnalysisRight_collection'),
              },
            },
          ],
        },
        {
          group: [
            {
              type: 'dataTable',
              config: {
                headerTitle: translate.instant('ACTIVITY.MERGE.MERGE_IGNORE_CLAIMS_OWNERSHIPS'),
                headerClass: 'ice-ignore-claim-dialog-description ice-ignore-claim-title-margin',
                schema: this.getMergeDataTableSchema(translate),
                isSelectable: false,
                showLoader: true,
                data: this.getMergeDataTableData('selectedClaimsAnalysisRight_ownership'),
              },
            },
          ],
        },
        {
          group: [
            {
              type: 'formly',
              config: {
                model: of({
                  body: 'activity.merge.ignore.claims',
                }),
                submitAvailable: true,
                submitEnabled: true,
                submitClass: 'mat-fab',
                submitButtonIcon: 'check',
                button1Enabled: true,
                button1CustomClass: 'mat-fab',
                button1Available: true,
                button1ButtonIcon: 'close',
                submit: (event: any) => this.updateIgnoreClaimsInMergeConflict(store, translate, responseText, actionValue),
                onButton1: () => this.dialogRefMerge.close(),
              },
            },
          ],
        },
      ],
    };
  }

  static getMergeDataTableSchema(translate) {
    return [
      {
        name: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_ROLE'),
        prop: 'role',
      },
      {
        name: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_NAME'),
        prop: 'name',
      },
      {
        name: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_IP_NAME_NUMBER'),
        prop: 'ipiName',
      },
      {
        name: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_PR_SOCIETY'),
        prop: 'prSociety',
      },
      {
        name: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_MR_SOCIETY'),
        prop: 'mrSociety',
      },
      {
        name: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_PR'),
        prop: 'pr',
      },
      {
        name: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_MR'),
        prop: 'mr',
      },
      {
        name: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_TERRITORY'),
        prop: 'territoriesLabel',
      },
      {
        name: translate.instant('CLAIMS.ALERT'),
        prop: 'alert',
      },
    ];
  }

  static getMergeDataTableData(selectedClaims) {
    try {
      const item = sessionStorage.getItem(selectedClaims);
      if (item === null) {
        const dummyRow = { role: '', ipiName: '', name: '', ipiNameKey: '', territories: ' ', prSociety: '', prRights: '', pr: '', mrSociety: '', mrRights: '', mr: '' };
        return of([dummyRow]);
      }
      const parsedItem = JSON.parse(item);
      this.ignoredClaimsList.push(...parsedItem);
      return of(parsedItem);
    } catch (error) {
      return of(null);
    }
  }
}
