<mat-sidenav-container>
  <div id="ice-main-content">
    <!-- TOOLBAR: Above -->
    <ng-container *ngIf="fuseSettings.layout.toolbar === 'above'">
      <ice-toolbar class="above" [ngClass]="fuseSettings.colorClasses.toolbar"></ice-toolbar>
    </ng-container>
    <!-- / TOOLBAR: Above -->

    <!-- NAVBAR: Top -->
    <ice-navbar layout="horizontal" class="top-navbar" fxHide fxShow.gt-md [ngClass]="fuseSettings.colorClasses.navbar" *ngIf="fuseSettings.layout.navigation === 'top'">
    </ice-navbar>
    <!-- / NAVBAR: Top -->

    <div id="wrapper">
      <!-- NAVBAR: Left -->
      <fuse-sidebar
        [name]="'navbar'"
        [folded]="fuseSettings.layout.navigationFolded || isOpenSidebar$ | async"
        [lockedOpen]="'gt-md'"
        class="left-navbar"
        [ngClass]="fuseSettings.colorClasses.navbar"
        *ngIf="fuseSettings.layout.navigation === 'left' || fuseSettings.layout.navigation === 'top'"
      >
        <ice-navbar layout="vertical"></ice-navbar>
      </fuse-sidebar>
      <!-- / NAVBAR: Left -->

      <div class="content-wrapper" [ngClass]="(forcedNSVisible$ | async) ? 'source-works' : ''">
        <!-- TOOLBAR: Below -->
        <ng-container *ngIf="fuseSettings.layout.toolbar === 'below'">
          <ice-toolbar class="below" [ngClass]="fuseSettings.colorClasses.toolbar"></ice-toolbar>
        </ng-container>
        <!-- / TOOLBAR: Below -->
        <router-outlet></router-outlet>

        <!-- FOOTER: Below -->
        <ng-container *ngIf="fuseSettings.layout.footer === 'below'">
          <ice-footer class="below" [ngClass]="fuseSettings.colorClasses.footer"></ice-footer>
        </ng-container>
        <!-- / FOOTER: Below -->
      </div>

      <!-- NAVBAR: Right -->
      <fuse-sidebar
        [name]="'navbar'"
        [position]="'right'"
        [folded]="fuseSettings.layout.navigationFolded"
        [lockedOpen]="'gt-md'"
        class="right-navbar"
        [ngClass]="fuseSettings.colorClasses.navbar"
        *ngIf="fuseSettings.layout.navigation === 'right'"
      >
        <ice-navbar layout="vertical"></ice-navbar>
      </fuse-sidebar>
      <!-- / NAVBAR: Right -->
    </div>

    <!-- FOOTER: Above -->
    <ng-container *ngIf="fuseSettings.layout.footer === 'above'">
      <ice-footer class="above" [ngClass]="fuseSettings.colorClasses.footer"></ice-footer>
    </ng-container>
    <!-- FOOTER: Above -->
  </div>

  <!-- QUICK PANEL -->
  <mat-sidenav fuseMatSidenavHelper="quick-panel" align="end">
    <ice-quick-panel></ice-quick-panel>
  </mat-sidenav>
  <!-- / QUICK PANEL -->
</mat-sidenav-container>
