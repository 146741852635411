import { FormGroup } from '@angular/forms';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { IpUtils } from '@ice';
import { DataTableRow } from '@ice/components/data-table/data-table';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SelectionType } from '@stage/ngx-datatable';
import { SearchCopyrightIpsTransferWorkTargetsForm } from 'config/search-form-builders/search-copyright-ips-transfer-work-targets';
import { SectionsConfig } from 'config/sections-config';
import { StepType } from 'config/stepper-builders/stepper-config';
import { clone, cloneDeep } from 'lodash';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromNewSectionItem from 'store/new-section-item';
import * as fromRoot from 'store/root';
import { IPI_PREFIX } from '../../../../constants/global.constants';

export class TransferIpWorksSelectTargetStep {
  private hideTargetIpFromSearchResults: Subject<boolean> = new BehaviorSubject<boolean>(true);
  private hidetargetIpFromBase: Subject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    protected translate: TranslateService,
    protected translationLoader: FuseTranslationLoaderService,
    protected store: Store<fromRoot.RootState>,
    protected storeNewItem: Store<fromNewSectionItem.NewSectionItemState>,
    protected fieldValidatorService: FieldValidatorService,
    protected sourceIp,
    protected closeTransferIpWorksDialog,
  ) {}

  getStep(): StepType {
    let formData: FormGroup;
    const searchIpType = 'TransferIpWorksIpSearch';

    return {
      label: this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.SELECT_TARGET_IP'),
      formBuilder: [
        {
          fieldGroupClassName: 'display-flex ice-mb-20',
          fieldGroup: [
            {
              type: 'htmlTemplate',
              templateOptions: {
                config: {
                  htmlTemplate: of(`<span><b>${this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.IP_MOVE_WORK')}</b></span>`),
                },
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex ice-mb-10',
          fieldGroup: [
            {
              type: 'htmlTemplate',
              templateOptions: {
                config: {
                  htmlTemplate: of(
                    `<span><b>${this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.SOURCE')}:</b> ${
                      this.sourceIp.fullName + ' / ' + this.sourceIp.ipiNameNumber + ' / ' + this.sourceIp.typeOfName
                    }</span>`,
                  ),
                },
              },
            },
          ],
        },
        {
          className: 'flex-1 ice-same-ipi-base-number-field',
          key: 'sameIpiBaseNumber',
          type: 'checkbox',
          defaultValue: true,
          templateOptions: {
            label: this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.SAME_IPI_BASE_NUMBER_FIELD_LABEL'),
            required: false,
            change: (field, $event) => {
              this.hideTargetIpFromSearchResults.next($event.checked);
              this.hidetargetIpFromBase.next(!$event.checked);
            },
          },
          hooks: {
            onInit: field => {
              formData = field.form;
            },
          },
        },
        {
          key: 'targetIpFromBase',
          type: 'cardWithDataTable',
          templateOptions: {
            config: {
              hide: this.hidetargetIpFromBase,
              title: this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.TARGET_IP'),
              class: 'ice-data-table-radio-selection ice-data-table-target-ip',
              model: this.store.pipe(
                select(fromRoot.getIpDetailNames),
                map(detailNames => detailNames.filter(detailName => detailName.ipiNameNumber !== this.sourceIp.ipiNameNumber)),
              ),
              sorts: [{ prop: 'fullName', dir: 'asc' }],
              selectionType: of(SelectionType.radio),
              onSelect: rows => {
                formData?.controls?.targetIpFromBase?.setValue(rows[0]);
              },
              displayRadio: () => true,
              schema: [
                {
                  name: '',
                  prop: 'rowSelected',
                  flexGrow: 0.55,
                  radiable: true,
                  width: 50,
                  resizeable: false,
                  canAutoResize: false,
                  draggable: false,
                  sortable: false,
                },
                { cellClass: 'cursor-pointer', name: this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.NAME'), prop: 'fullName' },
                {
                  cellClass: 'cursor-pointer',
                  name: this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.IPI_NAME_NUMBER'),
                  prop: 'ipiNameNumber',
                },
                { cellClass: 'cursor-text ip-name-key', name: this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.REFERENCE'), prop: 'nameKey' },
                {
                  cellClass: 'cursor-text',
                  name: this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.TYPE_OF_NAME'),
                  prop: 'typeOfName',
                  tooltip: 'typeOfNameTooltip',
                },
                {
                  name: this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.WORKS'),
                  prop: 'works',
                  tooltip: this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.TOOLTIP_WORKS'),
                },
                {
                  name: this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.AGREEMENTS'),
                  prop: 'agreements',
                  tooltip: this.translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.TOOLTIP'),
                },
              ],
            },
          },
        },
        {
          hideExpression: model => model?.sameIpiBaseNumber,
          fieldGroupClassName: 'display-flex-col ice-p-25 bg-filter-form bg-filter-form-search-ips',
          fieldGroup: new SearchCopyrightIpsTransferWorkTargetsForm(this.translate, this.translationLoader).getForm(null, null, null, [
            {
              className: 'ice-accent',
              type: 'button',
              templateOptions: {
                text: this.translate.instant('SELECT_ORGANIZATION_POPUP.RESET'),
                btnType: ' ice-accent',
                materialType: 'mat-button',
                onClick: () => {
                  const resetValues = this.resetFormValues();
                  formData.setValue({ ...formData.value, ...resetValues });
                },
              },
            },
            {
              className: 'ice-accent',
              type: 'button',
              templateOptions: {
                type: 'submit',
                text: this.translate.instant('SELECT_ORGANIZATION_POPUP.SEARCH'),
                color: 'accent',
                materialType: 'mat-flat-button',
                onClick: () => {
                  const formValues = this.cleanFormValues(formData.value);
                  const search = { ...formValues, needsBase: 'Y', includeInactive: formValues['includeInactive'] || false };
                  if (search['baseId'] && formValues['xrefPrefix']) {
                    switch (formValues['xrefPrefix']) {
                      case 'IPINAME':
                        search['xref'] = search['baseId'];
                        search['xrefPrefix'] = 'IPI';
                        search['baseId'] = undefined;
                        break;
                      case 'IPIBASE':
                        search['baseIdPrefix'] = 'IPI';
                        search['xrefPrefix'] = 'IPI';
                        break;
                      case 'ICENAME':
                        search['xref'] = search['baseId'];
                        search['xrefPrefix'] = 'ICE';
                        search['baseId'] = undefined;
                        break;
                      case 'ICEBASE':
                        search['baseIdPrefix'] = 'ICE';
                        search['xrefPrefix'] = 'ICE';
                        break;
                      default:
                        search['baseIdPrefix'] = formValues['xrefPrefix'];
                    }
                  }
                  const valueToSearch = { ...search, targetIpFromBase: null, targetIpFromSearchResults: null };
                  this.storeNewItem.dispatch(
                    new fromNewSectionItem.NewItemDoSearch({
                      search: valueToSearch,
                      section: SectionsConfig.IPS.name,
                      type: searchIpType,
                      reset: true,
                    }),
                  );
                },
                isDisabled: () => this.isSearchIpFormValid(formData?.controls),
                disablePreventDefault: true,
              },
            },
          ]),
        },
        {
          key: 'targetIpFromSearchResults',
          type: 'cardWithDataTable',
          templateOptions: {
            config: {
              hide: this.hideTargetIpFromSearchResults,
              title: this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.TARGET_IP_SEARCH_RESULTS'),
              class: 'ice-data-table-radio-selection ice-data-table-target-ip-search-results',
              model: this.store.pipe(
                select(fromNewSectionItem.getNewSectionSearchResult),
                map(searchResultsItem => {
                  if (searchResultsItem) {
                    const typedSearchResults = searchResultsItem[searchIpType] && searchResultsItem[searchIpType].items;
                    return clone(typedSearchResults);
                  }
                  return [];
                }),
                map(detailNames => {
                  if (!detailNames) return [];

                  const names = cloneDeep(detailNames);

                  return names.filter(detailName => detailName.ipiNameNumber !== this.sourceIp.ipiNameNumber);
                }),
              ),
              sorts: [{ prop: 'fullName', dir: 'asc' }],
              selectionType: of(SelectionType.radio),
              displayRadio: () => true,
              onSelect: rows => {
                formData?.controls?.targetIpFromSearchResults?.setValue(rows[0]);
              },
              schema: this.getSearchIpDataTable(),
              doPagination: _ => this.storeNewItem.dispatch(new fromNewSectionItem.NewItemDoSearchNextPage({ type: searchIpType, section: SectionsConfig.IPS.name })),
            },
          },
        },
      ],
      stepButtons: [
        {
          matStepperPrevious: true,
          btnType: 'ice-border-accent',
          text: this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.BACK'),
          color: 'accent',
          materialType: 'mat-button',
          onClick: () => this.closeTransferIpWorksDialog(),
        },
        {
          matStepperNext: true,
          text: this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.SELECT_TARGET_IP'),
          color: 'accent',
          materialType: 'mat-flat-button',
          onClick: () => this.selectTargetIp(formData?.controls),
          isDisabled: () => this.isStepValid(formData?.controls),
        },
      ],
    };
  }

  private resetFormValues = () => {
    return {
      baseId: '',
      firstName: '',
      includeInactive: false,
      name: '',
      typeOf: '',
      typeOfName: '',
      xrefPrefix: 'IPI',
    };
  };

  cleanFormValues(values) {
    const result = Object.entries(values)
      .filter(value => value[1] && value[1] !== '')
      .reduce((acum, [key, value]) => {
        acum[key] = value;
        return acum;
      }, {});
    return result;
  }

  getSearchIpDataTable(): DataTableRow[] {
    return [
      {
        name: '',
        prop: 'rowSelected',
        flexGrow: 0.55,
        radiable: true,
        width: 50,
        resizeable: false,
        canAutoResize: false,
        draggable: false,
        sortable: false,
      },
      {
        name: this.translate.instant('IPS.NAME'),
        prop: 'fullName',
        flexGrow: 3,
        cellClass: 'ip-name',
      },
      { name: this.translate.instant('IPS.IPI_NAME_NUMBER'), prop: 'ipiNameNumber', flexGrow: 2, cellClass: 'ip-name-number' },
      { name: this.translate.instant('IPS.REFERENCE'), prop: 'nameKey', flexGrow: 1, cellClass: 'ip-name-key' },
      {
        name: this.translate.instant('IPS.TYPE_OF_NAME'),
        prop: 'typeOfName',
        flexGrow: 1,
        cellClass: 'ip-type-of-name',
        tooltip: 'tooltipTypeOfName',
      },
      {
        name: this.translate.instant('IPS.BASE_ID'),
        prop: 'baseIpiNumbersFormatted',
        icons: 'baseIpiNumbersIcons',
        flexGrow: 2,
        cellClass: 'ip-base-ipi-number ice-search-results-font-16 cursor-pointer',
      },
      {
        name: this.translate.instant('IPS.TYPE_OF'),
        prop: 'typeOf',
        icons: 'typeOfIcon',
        flexGrow: 1,
        cellClass: 'ip-type-of ice-search-results-font-16',
      },
      {
        name: this.translate.instant('IPS.PR'),
        prop: 'prSocietyCodeAndName',
        icons: 'prSocietyIcons',
        flexGrow: 2,
        cellClass: 'ip-pr',
        headerTooltip: this.translate.instant('IPS.PR_SOC_TOOLTIP'),
        comparator: (_valueA, _valueB, modelA, modelB) => IpUtils.prOrMrComparator(modelA, modelB, 'prSociety'),
      },
      {
        name: this.translate.instant('IPS.MR'),
        prop: 'mrSocietyCodeAndName',
        icons: 'mrSocietyIcons',
        flexGrow: 2,
        cellClass: 'ip-mr',
        headerTooltip: this.translate.instant('IPS.MR_SOC_TOOLTIP'),
        comparator: (_valueA, _valueB, modelA, modelB) => IpUtils.prOrMrComparator(modelA, modelB, 'mrSociety'),
      },
    ];
  }

  isStepValid(controls) {
    return (
      (controls?.sameIpiBaseNumber?.value === true && controls?.targetIpFromBase?.value === null) ||
      (controls?.sameIpiBaseNumber?.value === false && controls?.targetIpFromSearchResults?.value === null)
    );
  }

  selectTargetIp(controls) {
    const result = {
      sourceIp: cloneDeep(this.sourceIp),
      targetIp: {},
      sourceIpWorksFilter: {
        title: '',
        workId: '',
      },
    };

    if (controls?.sameIpiBaseNumber?.value === true) {
      result.targetIp = controls?.targetIpFromBase?.value;
    } else {
      result.targetIp = controls?.targetIpFromSearchResults?.value;
    }
    this.store.dispatch(new fromRoot.SetTransferIpWorksInfo(result));
  }

  isSearchIpFormValid(controls) {
    return controls?.baseId?.status === 'INVALID' || controls?.xref?.status === 'INVALID';
  }
}
