import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { StringUtils } from '@ice';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/search-form-builders';
import { countryGroups } from 'assets/ts/countries';
import { ipsSocietiesCodes } from 'assets/ts/ips-societies';
import { EMPTY_RESULTSET } from 'config/constants/global.constants';
import { societiesICE } from 'config/constants/ips.constants';
import { filter, startsWith } from 'lodash';
import { FormConfig, SearchExpressions } from './form-config';

export class SocietiesSearchExpressions extends SearchExpressions {
  alternativeNS = 'CISAC';

  getSearchExpressionConfig(key: string, value: string, prefix: string, allUsers: any) {
    switch (key) {
      case 'socCode':
        const socCodeTrim = StringUtils.trimLeadingZerosFromString(value);
        return `{"equals":{"attributes.id":"CISAC:${socCodeTrim}"}}`;
      case 'socName':
        const codes = filter(ipsSocietiesCodes, society => startsWith(society.name, value.toUpperCase()));
        const orOptions = codes.length > 0 && codes.map(code => `{"words":{"attributes.id":"${StringUtils.trimLeadingZerosFromString(code.code)}"}}`).join(',');
        return orOptions ? `{"or":[${orOptions}]}` : `{"result":"${EMPTY_RESULTSET}"}`;
      case 'countryCode':
      case 'country':
        return `{"words":{"attributes.memberships.territories":"${value}"}}`;
      case 'isIce':
        if (value === 'yes') {
          const queryExpressions = societiesICE.map(society => `{"words":{"attributes.id":"${StringUtils.trimLeadingZerosFromString(society)}"}}`);
          return `{"or":[${queryExpressions}]}`;
        } else if (value === 'no') {
          const queryExpressions = societiesICE.map(society => `{"not":{"words":{"attributes.id":"${StringUtils.trimLeadingZerosFromString(society)}"}}}`);
          return `{"and":[${queryExpressions}]}`;
        }
    }
    return `{"wildcard":{"${'attributes.' + key}":"*${value}*"}}`;
  }

  getExtraSearchExpression() {
    return '';
  }
}

export class SearchCopyrightSocietiesForm implements FormConfig {
  constructor(private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  getForm(): FormlyFieldConfig[] {
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'socName',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.getTranslate('SOCIETY_NAME'),
              required: false,
            },
          },
          {
            className: 'flex-1',
            key: 'socCode',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.getTranslate('SOCIETY_CODE'),
              required: false,
              maxLength: 3,
            },
          },
          {
            className: 'flex-1',
            key: 'countryCode',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              label: this.getTranslate('COUNTRY_CODE'),
              type: 'text',
              required: false,
            },
          },
          {
            className: 'flex-1',
            key: 'country',
            type: 'ice-autocomplete-grouped',
            templateOptions: {
              placeholder: this.getTranslate('COUNTRY_NAME'),
              options: countryGroups,
              required: false,
              useCountryShortcuts: true,
              panelWidth: 'auto',
            },
          },
          {
            className: 'flex-1 is-ice-search',
            key: 'isIce',
            type: 'select',
            templateOptions: {
              label: this.getTranslate('IS_ICE'),
              options: [
                { label: '', value: '' },
                { label: 'YES', value: 'yes' },
                { label: 'NO', value: 'no' },
              ],
              required: false,
            },
          },
        ],
      },
    ];
  }

  getTranslate(translationPath) {
    return this.translate.instant(`SOCIETIES.${translationPath}`);
  }
}
