import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { StringUtils, TabsUtils, SocietiesUtils } from '@ice';
import { DataTableRow } from '@ice/components/data-table/data-table';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { select, Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import { ipsSocietiesCodes } from 'assets/ts/ips-societies';
import { PUBLISHER_PREFIX, SOCIETY_PREFIX } from 'config/constants/global.constants';
import { DialogForm, DIALOG_NORMAL_BT, DIALOG_NO_BT } from 'config/dialog-builders/dialog-form';
import { DialogInfo } from 'config/dialog-builders/dialog-info';
import { IceFacade } from 'facades/ice.facade';
import { cloneDeep, get } from 'lodash';
import { EditMode } from 'models/copyright/detail/edit-mode';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonApiService } from 'services/common-api.service';
import { DetailService } from 'services/detail/detail.service';
import { NamespaceService } from 'services/namespace/namespace.service';
import { PermissionsService } from 'services/permissions/permissions.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';
import type { SectionTabConfig } from '../../tabs-data';

const TYPE_SOC_ATTRIBUTES = 'attributes';
const TYPE_SOC_GRADINGS = 'gradings';
const generateOptions = (values: any[]) => values.map(value => ({ value, label: value }));
const SOCIETIES_GRADINGS = [
  {
    value: '089',
    label: 'TEOSTO',
  },
  {
    value: '090',
    label: 'TONO',
  },
  {
    value: '040',
    label: 'KODA',
  },
  {
    value: '079',
    label: 'STIM',
  },
];
const SOCIETIES_ATTRIBUTES = [
  {
    value: '023',
    label: 'BUMA/STEMRA',
  },
  {
    value: '055',
    label: 'SABAM',
  },
  {
    value: '052',
    label: 'MCPS/PRS',
  },
  ...SOCIETIES_GRADINGS,
];
const GRADINGS_TONO_STIM = ['a', 'b', 'c', 'd', 'e', 'p'];
const GRADINGS_TEOSTO = ['a', 'b', 'c', 'd', 'e', 'p'];
const GRADINGS_KODA = ['a', 'b', 'c', 'd', 'e', 'p'];
const OPTIONS_GRADINGS_KODA = generateOptions(GRADINGS_KODA);
const OPTIONS_GRADINGS_TEOSTO = generateOptions(GRADINGS_TEOSTO);
const OPTIONS_GRADINGS_TONO_STIM = generateOptions(GRADINGS_TONO_STIM);
const OPTIONS_GRADINGS = {
  '040': OPTIONS_GRADINGS_KODA, // KODA
  '079': OPTIONS_GRADINGS_TONO_STIM, // STIM
  '090': OPTIONS_GRADINGS_TONO_STIM, // TONO
  '089': OPTIONS_GRADINGS_TEOSTO, // TEOSTO
};
export class TabWorksSocietyAttributes implements SectionTabConfig {
  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private dialog: MatDialog,
    private commonApiService: CommonApiService,
    private detailService: DetailService,
    private nsService: NamespaceService,
    private iceFacade: IceFacade,
    private fieldValidatorService: FieldValidatorService,
    private permissionsService: PermissionsService,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
  }
  private optionSelectSociety = {
    value: 0,
    label: this.translate.instant('WORKS.SOCIETY_ATTRIBUTES.ATTRIBUTES_POPUP.SELECT_SOCIETY'),
  };
  private optionsSocietiesAttributes = [this.optionSelectSociety, ...SOCIETIES_ATTRIBUTES];
  private optionsSocietiesGradings = [this.optionSelectSociety, ...SOCIETIES_GRADINGS];

  private canEditSocietyAttributes$ = this.store.pipe(
    select(fromRoot.getEditModeState),
    map(editMode => {
      return this.canEdit(editMode, this.optionsSocietiesAttributes);
    }),
  );

  private dialogAddAttributeOptions$ = combineLatest([
    this.store.select(fromRoot.getEditModeState),
    this.store.pipe(
      select(fromRoot.getWorkSocieties),
      map(societies => SocietiesUtils.getSocietiesAttributes(societies)),
      map(societiesAttributes => (societiesAttributes || []).map(societiesAttribute => societiesAttribute.societyCode)),
    ),
  ]).pipe(
    map(([editName, attributes]) => {
      return editName?.editId?.startsWith(SOCIETY_PREFIX)
        ? this.optionsSocietiesAttributes.filter(
            soc =>
              soc.value === StringUtils.padZeros((editName.editId || '').split(':')[0].substring(2), 3) ||
              editName.extraSocPermissions.includes(SOCIETY_PREFIX + StringUtils.trimLeadingZerosFromString(soc.value.toString())),
          )
        : this.optionsSocietiesAttributes;
    }),
  );

  private dialogAddGradingsOptions$ = combineLatest([
    this.store.select(fromRoot.getEditModeState),
    this.store.pipe(
      select(fromRoot.getWorkSocieties),
      map(societies => SocietiesUtils.getSocietiesAttributeGradings(societies)),
      map(societies => (societies || []).map(society => society.societyName)),
    ),
  ]).pipe(
    map(([editName, gradings]) => {
      return editName?.editId?.startsWith(SOCIETY_PREFIX)
        ? this.optionsSocietiesGradings.filter(
            soc =>
              (soc.value === StringUtils.padZeros((editName.editId || '').split(':')[0].substring(2), 3) ||
                editName.extraSocPermissions.includes(SOCIETY_PREFIX + StringUtils.trimLeadingZerosFromString(soc.value.toString()))) &&
              !gradings.includes(soc.label),
          )
        : this.optionsSocietiesGradings.filter(soc => !gradings.includes(soc.label));
    }),
  );

  private canEditSocietyGradings$ = this.store.pipe(
    select(fromRoot.getEditModeState),
    map(editMode => {
      return this.canEdit(editMode, this.optionsSocietiesGradings);
    }),
  );

  private canEdit(editMode: EditMode, societies: any) {
    if (!editMode?.editing) return false;
    if (editMode.editName === 'ICE') return true;

    const publisher = get(editMode, 'multipleNamespaces[0].editId');
    const isEditingAsPublisher = publisher?.startsWith(PUBLISHER_PREFIX) || editMode.editId?.startsWith(PUBLISHER_PREFIX);
    const doesEditorMatchSociety = !!societies.find(soc => soc.value === StringUtils.padZeros((editMode.editId || '').split(':')[0].substring(2), 3));
    const doExtraSocietyPermissionsMatch = editMode.extraSocPermissions?.length && this.checkExtraPermissions(editMode.extraSocPermissions).length;

    return !isEditingAsPublisher && this.permissionsService.can('works_society_attributes') && (doesEditorMatchSociety || doExtraSocietyPermissionsMatch);
  }

  private checkExtraPermissions(extraSocPermissions: String[]) {
    return (
      extraSocPermissions.map(extraSocPermission => {
        ipsSocietiesCodes.filter(
          society => society.extraSocPermissions && society.extraSocPermissions.length > 0 && society.extraSocPermissions.indexOf(extraSocPermission) !== -1,
        );
      }) || []
    );
  }

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('WORKS.SOCIETY_ATTRIBUTES.ATTRIBUTES_TABLE.TITLE'),
              model: this.store.pipe(
                select(fromRoot.getWorkSocieties),
                map(societies => SocietiesUtils.getSocietiesAttributes(societies)),
              ),
              columnMode: 'flex',
              schema: this.setupAttributesDatatableSchema(),
              tableWidth: '100',
              sorts: [{ prop: 'societyName', dir: 'asc' }],
              actionButtons: combineLatest([this.canEditSocietyAttributes$, this.dialogAddAttributeOptions$]).pipe(
                map(([canEdit, options]) => {
                  return canEdit && options.length > 0 && !(options.length === 1 && options[0].value === 0)
                    ? [
                        {
                          icon: 'add',
                          tooltip: this.translate.instant('WORKS.SOCIETY_ATTRIBUTES.ATTRIBUTES_TABLE.ADD_SOCIETY_ATTRIBUTE'),
                          class: 'mat-white-icon',
                          onClick: () => this.popupAddRow(TYPE_SOC_ATTRIBUTES),
                        },
                      ]
                    : null;
                }),
              ),
              visibleColumns: this.getVisibleColumnsAttributes(this.setupAttributesDatatableSchema()),
            },
          },
        ],
      },
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('WORKS.SOCIETY_ATTRIBUTES.GRADING_TABLE.TITLE'),
              model: this.store.pipe(
                select(fromRoot.getWorkSocieties),
                map(societies => SocietiesUtils.getSocietiesAttributeGradings(societies)),
              ),
              columnMode: 'flex',
              schema: this.setupGradingsDatatableSchema(),
              tableWidth: '100',
              sorts: [{ prop: 'societyName', dir: 'asc' }],
              actionButtons: combineLatest([this.canEditSocietyGradings$, this.dialogAddGradingsOptions$]).pipe(
                map(([canEdit, options]) => {
                  return canEdit && options.length > 0 && !(options.length === 1 && options[0].value === 0)
                    ? [
                        {
                          icon: 'add',
                          tooltip: this.translate.instant('WORKS.SOCIETY_ATTRIBUTES.GRADING_TABLE.ADD_SOCIETY_GRADING'),
                          class: 'mat-white-icon',
                          onClick: () => this.popupAddRow(TYPE_SOC_GRADINGS),
                        },
                      ]
                    : null;
                }),
              ),
              visibleColumns: this.getVisibleColumnsGradings(this.setupGradingsDatatableSchema()),
            },
          },
        ],
      },
    ];
  }

  private setupAttributesDatatableSchema(): DataTableRow[] {
    const schema: DataTableRow[] = [
      { name: this.translate.instant('WORKS.SOCIETY_ATTRIBUTES.ATTRIBUTES_TABLE.SOCIETY_NAME'), prop: 'societyName', flexGrow: 0.75 },
      { name: this.translate.instant('WORKS.SOCIETY_ATTRIBUTES.ATTRIBUTES_TABLE.SOCIETY_CODE'), prop: 'societyCode', flexGrow: 0.75 },
      { name: this.translate.instant('WORKS.SOCIETY_ATTRIBUTES.ATTRIBUTES_TABLE.ATTRIBUTE_NAME'), prop: 'attributeName', flexGrow: 1 },
      { name: this.translate.instant('WORKS.SOCIETY_ATTRIBUTES.ATTRIBUTES_TABLE.ATTRIBUTE_VALUE'), prop: 'attributeValue', flexGrow: 1 },
    ];
    const editButtons: DataTableRow[] = [
      {
        name: '',
        prop: 'editBtn',
        actionButtonIcon: 'edit',
        actionButtonTooltip: this.translate.instant('WORKS.SOCIETY_ATTRIBUTES.ATTRIBUTES_TABLE.EDIT_SOCIETY_ATTRIBUTE'),
        flexGrow: 0.001,
        maxWidth: 40,
        minWidth: 40,
        resizeable: false,
        action: (row: any) => {
          const originalSocietyCode = SocietiesUtils.getOriginalSocietyCode(row.societyCode);
          this.store
            .pipe(select(fromRoot.getWorkSocieties))
            .subscribe(societies => {
              this.editSocietyAttributesPopup({ societyCode: originalSocietyCode }, societies);
            })
            .unsubscribe();
        },
        hideActionButton: row => {
          const rowSocietyName = SocietiesUtils.getOriginalSocietyName(row.societyName);
          return this.store.pipe(
            select(fromRoot.getEditModeState),
            map(editMode => {
              const editName = editMode && editMode.editName;
              const socId = SocietiesUtils.getSocietyCodeByName(editName);
              const rowId = SocietiesUtils.getSocietyCodeByName(rowSocietyName);
              const extraSocPermissions = SocietiesUtils.getSocietyExtraPermissions(socId.replace('SO', ''));
              return editName !== 'ICE' && rowSocietyName !== editName && !extraSocPermissions.includes(SOCIETY_PREFIX + StringUtils.trimLeadingZerosFromString(rowId));
            }),
          );
        },
      },
      {
        name: '',
        prop: 'deleteBtn',
        actionButtonIcon: 'delete',
        actionButtonTooltip: this.translate.instant('WORKS.SOCIETY_ATTRIBUTES.ATTRIBUTES_TABLE.REMOVE_SOCIETY_ATTRIBUTE'),
        flexGrow: 0.001,
        maxWidth: 50,
        minWidth: 50,
        resizeable: false,
        action: (row: any) => this.deleteSocietyAttributesPopup(row, false),
        hideActionButton: row => {
          const rowSocietyName = SocietiesUtils.getOriginalSocietyName(row.societyName);
          return this.store.pipe(
            select(fromRoot.getEditingName),
            map(editName => {
              const socId = SocietiesUtils.getSocietyCodeByName(editName);
              const rowId = SocietiesUtils.getSocietyCodeByName(rowSocietyName);
              const extraSocPermissions = SocietiesUtils.getSocietyExtraPermissions(socId.replace('SO', ''));
              return editName !== 'ICE' && rowSocietyName !== editName && !extraSocPermissions.includes(SOCIETY_PREFIX + StringUtils.trimLeadingZerosFromString(rowId));
            }),
          );
        },
      },
    ];

    schema.push(...editButtons);
    return schema;
  }

  private setupGradingsDatatableSchema(): DataTableRow[] {
    const schema: DataTableRow[] = [
      { name: this.translate.instant('WORKS.SOCIETY_ATTRIBUTES.ATTRIBUTES_TABLE.SOCIETY_NAME'), prop: 'societyName', flexGrow: 1.5 },
      { name: this.translate.instant('WORKS.SOCIETY_ATTRIBUTES.GRADING_TABLE.GRADING_VALUE'), prop: 'gradingValue', flexGrow: 0.6 },
    ];

    const editButtons: DataTableRow[] = [
      {
        name: '',
        prop: 'editGradingBtn',
        actionButtonIcon: 'edit',
        actionButtonTooltip: this.translate.instant('WORKS.SOCIETY_ATTRIBUTES.ATTRIBUTES_TABLE.EDIT_SOCIETY_ATTRIBUTE'),
        flexGrow: 0.001,
        maxWidth: 40,
        minWidth: 40,
        resizeable: false,
        action: (row: any) => {
          this.store
            .pipe(select(fromRoot.getWorkSocieties))
            .subscribe(societies => {
              this.editSocietyGradingsPopup(row, societies);
            })
            .unsubscribe();
        },
        hideActionButton: row => {
          return this.store.pipe(
            select(fromRoot.getEditModeState),
            map(editMode => {
              const editName = editMode && editMode.editName;
              const socId = SocietiesUtils.getSocietyCodeByName(editName);
              const rowId = SocietiesUtils.getSocietyCodeByName(row.societyName);
              const extraSocPermissions = SocietiesUtils.getSocietyExtraPermissions(socId.replace('SO', ''));
              return editName !== 'ICE' && row.societyName !== editName && !extraSocPermissions.includes(SOCIETY_PREFIX + StringUtils.trimLeadingZerosFromString(rowId));
            }),
          );
        },
      },
      {
        name: '',
        prop: 'deleteGradingBtn',
        actionButtonIcon: 'delete',
        actionButtonTooltip: this.translate.instant('WORKS.SOCIETY_ATTRIBUTES.ATTRIBUTES_TABLE.REMOVE_SOCIETY_ATTRIBUTE'),
        flexGrow: 0.001,
        maxWidth: 50,
        minWidth: 50,
        resizeable: false,
        action: (row: any) => this.deleteSocietyAttributesPopup(row, true),
        hideActionButton: row =>
          this.store.pipe(
            select(fromRoot.getEditingName),
            map(editName => {
              const socId = SocietiesUtils.getSocietyCodeByName(editName);
              const rowId = SocietiesUtils.getSocietyCodeByName(row.societyName);
              const extraSocPermissions = SocietiesUtils.getSocietyExtraPermissions(socId.replace('SO', ''));
              return editName !== 'ICE' && row.societyName !== editName && !extraSocPermissions.includes(SOCIETY_PREFIX + StringUtils.trimLeadingZerosFromString(rowId));
            }),
          ),
      },
    ];

    schema.push(...editButtons);

    return schema;
  }

  // type: TYPE_SOC_ATTRIBUTES, TYPE_SOC_GRADINGS
  private popupAddRow(type) {
    const dialogForm = new DialogForm(this.translate, this.dialog, DIALOG_NO_BT);
    const dialogRef = dialogForm.openDialog(
      this.translate.instant('WORKS.SOCIETY_ATTRIBUTES.ATTRIBUTES_POPUP.TITLE'),
      {},
      [
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-1',
              key: 'societyCode',
              type: 'select',
              templateOptions: {
                type: 'text',
                placeholder: this.translate.instant('WORKS.SOCIETY_ATTRIBUTES.ATTRIBUTES_POPUP.SELECT_SOCIETY'),
                required: false,
                change: field => {
                  const value = field.model && field.model.societyCode;
                  if (value) {
                    this.store
                      .pipe(select(fromRoot.getWorkSocieties))
                      .subscribe(societies => {
                        if (type === TYPE_SOC_ATTRIBUTES) {
                          this.editSocietyAttributesPopup({ societyCode: value }, societies);
                        } else {
                          this.editSocietyGradingsPopup({ societyCode: value }, societies);
                        }
                        dialogRef.close();
                      })
                      .unsubscribe();
                  }
                },
                options: type === TYPE_SOC_ATTRIBUTES ? this.dialogAddAttributeOptions$ : this.dialogAddGradingsOptions$,
              },
            },
          ],
        },
      ],
      undefined,
      undefined,
      undefined,
      undefined,
      'dialog-wrapper-width-420-h',
    );
  }

  private deleteSocietyAttributesPopup(row, isGrading: boolean) {
    const rowToDelete = cloneDeep(row);
    if (isGrading) {
      rowToDelete.societyCode = SocietiesUtils.getSocietyCodeByName(row.societyName);
    } else {
      rowToDelete.societyCode = SocietiesUtils.getOriginalSocietyCode(row.societyCode);
      rowToDelete.societyName = SocietiesUtils.getOriginalSocietyName(row.societyName);
    }
    const dialogInfo = new DialogInfo(this.translate, this.dialog, this.store);
    const dialogRefInfo = dialogInfo.openDialog(
      this.translate.instant('WORKS.SOCIETY_ATTRIBUTES.ATTRIBUTES_POPUP.REMOVE.TITLE'),
      this.translate.instant('WORKS.SOCIETY_ATTRIBUTES.ATTRIBUTES_POPUP.REMOVE.TEXT'),
      () => {
        this.store.dispatch(new fromRoot.UpdateField({ object: isGrading ? 'societyGradings' : 'societyAttributes', newValue: rowToDelete, type: 'delete' }));
        dialogRefInfo.close();
      },
      () => dialogRefInfo.close(),
    );
  }

  private editSocietyAttributesPopup(row, societies) {
    const { societyCode } = row;
    const societyName = SocietiesUtils.getSocietyName(societyCode);
    const dialogForm = new DialogForm(this.translate, this.dialog, DIALOG_NORMAL_BT);
    const dialogRef = dialogForm.openDialog(
      `${this.translate.instant('WORKS.SOCIETY_ATTRIBUTES.ATTRIBUTES_POPUP.TITLE')} ${SocietiesUtils.getFormattedSocietyName(societyName)}`,
      SocietiesUtils.getSocietyAttributesLastValue(societies, societyCode),
      this.getSocietyAttributesForm(societyCode),
      newValue => {
        this.store.dispatch(new fromRoot.UpdateField({ object: 'societyAttributes', newValue: { societyCode, ...newValue }, type: 'edit' }));
        dialogRef.close();
      },
      () => dialogRef.close(),
      this.translate.instant('POPUP_COMMON.CONFIRM'),
      this.translate.instant('POPUP_COMMON.CANCEL'),
      'dialog-wrapper-width-420-h',
    );
  }

  private editSocietyGradingsPopup(grading, societies) {
    const { societyCode, societyName } = grading; // If is from Add we have societyCode else we have societyName
    const societyCodeFromName = societyCode || SocietiesUtils.getSocietyCodeByName(societyName);
    const societyNameFromCode = societyName || SocietiesUtils.getSocietyName(societyCode);
    const dialogForm = new DialogForm(this.translate, this.dialog, DIALOG_NORMAL_BT);
    const dialogRef = dialogForm.openDialog(
      `${this.translate.instant('WORKS.SOCIETY_ATTRIBUTES.ATTRIBUTES_POPUP.TITLE')} ${societyNameFromCode}`,
      SocietiesUtils.getSocietyGradingLastValue(societies, societyCodeFromName, grading),
      this.getSocietyAttributesForm(societyCodeFromName, true),
      newValue => {
        this.store.dispatch(new fromRoot.UpdateField({ object: 'societyGradings', newValue: { societyCode: societyCodeFromName, ...newValue, grading }, type: 'edit' }));
        dialogRef.close();
      },
      () => dialogRef.close(),
      this.translate.instant('POPUP_COMMON.CONFIRM'),
      this.translate.instant('POPUP_COMMON.CANCEL'),
      'dialog-wrapper-width-420-h',
    );
  }

  private getSocietyAttributesForm(societyCode, isGradings?: boolean): FormlyFieldConfig[] {
    let attributes: string[];
    if (isGradings) {
      attributes = ['gradingValue'];
    } else {
      switch (societyCode) {
        case '023': // BUMA
          attributes = ['arrAdaptorAuthorized', 'agreedSplitAuthorized'];
          break;
        case '040': // KODA
        case '079': // STIM
        case '090': // TONO
          attributes = ['classification', 'arrangementWeighting'];
          break;
        case '089': // TEOSTO
          attributes = ['classification', 'arrangementWeighting', 'trueOrigWorkIndicator'];
          break;
        case '044': // MCPS
        case '052': // PRS
          attributes = ['arrangementGrade', 'dramaticoMusical', 'lastDistRefMech', 'lastDistRefPerf', 'broadcastInfo'];
          break;
        case '055': // SABAM
          attributes = ['status', 'genre'];
          break;
        default:
          attributes = [
            'classification',
            'arrangementWeighting',
            'trueOrigWorkIndicator',
            'arrangementGrade',
            'dramaticoMusical',
            'lastDistRefMech',
            'lastDistRefPerf',
            'broadcastInfo',
            'status',
            'genre',
            'arrangerAdaptorAutorized',
            'agreedSplitAuthorized',
          ];
      }
    }

    return attributes.map(attribute => {
      return this.getFormlyField(attribute, societyCode);
    });
  }

  private getFormlyField(fieldName: string, societyCode: string): FormlyFieldConfig {
    let type: string;
    let options: Object[];
    let pattern: any;
    let errorMessage: string;
    let infoText: string;
    let wrappers: Array<string>;
    let requiredField = false;
    switch (fieldName) {
      case 'classification':
        switch (societyCode) {
          case '040': // KODA
          case '079': // STIM
          case '090': // TONO
          case '089': // TEOSTO
            type = 'input';
            pattern = /^([0-9]|[1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[01])$/;
            errorMessage = 'NUMBER_0_991';
            infoText = this.translate.instant('WORKS.SOCIETY_ATTRIBUTES.ATTRIBUTES_POPUP.CLASSIFICATION_VALIDATION_INFO');
            wrappers = ['wrapper-info', 'form-field'];
            break;
          default:
            type = 'input';
        }
        break;
      case 'arrAdaptorAuthorized':
      case 'agreedSplitAuthorized':
      case 'dramaticoMusical':
      case 'trueOrigWorkIndicator':
        type = 'select';
        options = this.generateOptions(['Y', 'N']);
        break;
      case 'arrangementGrade':
        type = 'select';
        options = this.generateOptions(['PAY', 'NOP']);
        break;
      case 'lastDistRefMech':
      case 'lastDistRefPerf':
      case 'broadcastInfo':
        type = 'input';
        pattern = /^[a-zA-Z0-9_]{0,60}$/;
        errorMessage = 'ALPHANUMERIC_LENGTH_60';
        break;
      case 'status':
        type = 'select';
        options = this.generateOptions(['LEG', 'OPE', 'SIG', 'CDP', 'CCL', 'BIP']);
        break;
      case 'genre':
        type = 'select';
        options = this.generateOptions(['DID M', 'SER', 'DP SER', 'JAZZ', 'LIGHT', 'GEN/JING']);
        break;
      case 'arrangementWeighting':
        type = 'select';
        options = this.generateOptions(['T', 'U', 'V', 'X', 'Y', 'Z']);
        wrappers = ['wrapper-no-icon', 'form-field'];
        break;
      case 'gradingDate':
      case 'gradingDecider':
      case 'gradingStatus':
        type = 'input';
        break;
      case 'gradingValue':
        type = 'select';
        options = OPTIONS_GRADINGS[societyCode];
        requiredField = true;
        break;
    }
    const validation = errorMessage
      ? {
          messages: {
            pattern: (error, field: FormlyFieldConfig) => this.translate.instant(`WORKS.SOCIETY_ATTRIBUTES.ATTRIBUTES_POPUP.ERRORS.${errorMessage}`),
          },
        }
      : null;
    return {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          className: 'flex-1',
          key: fieldName,
          wrappers,
          type,
          templateOptions: { type: 'text', placeholder: this.getTranslateText(fieldName), required: requiredField, options, pattern, infoText },
          validation,
        },
      ],
    };
  }

  getTranslateText(fieldName) {
    let translateID: string;
    switch (fieldName) {
      case 'classification':
        translateID = 'CLASSIFICATION';
        break;
      case 'arrAdaptorAuthorized':
        translateID = 'ARRANGER_ADAPTOR_AUTHORIZED';
        break;
      case 'agreedSplitAuthorized':
        translateID = 'AGREED_SPLIT_AUTHORIZED';
        break;
      case 'dramaticoMusical':
        translateID = 'DRAMATICO_MUSICAL';
        break;
      case 'arrangementGrade':
        translateID = 'ARRANGEMENT_GRADE';
        break;
      case 'lastDistRefMech':
        translateID = 'LAST_DIST_REF_MECH';
        break;
      case 'lastDistRefPerf':
        translateID = 'LAST_DIST_REF_PERF';
        break;
      case 'broadcastInfo':
        translateID = 'BROADCAST_INFO';
        break;
      case 'status':
        translateID = 'STATUS';
        break;
      case 'genre':
        translateID = 'GENRE';
        break;
      case 'arrangementWeighting':
        translateID = 'ARRANGEMENT_WEIGHTING';
        break;
      case 'trueOrigWorkIndicator':
        translateID = 'TRUE_ORIG_WORK_INDICATOR';
        break;
      case 'trueOrigWorkIndicator':
        translateID = 'TRUE_ORIG_WORK_INDICATOR';
        break;
      case 'gradingDate':
        translateID = 'GRADING_DATE';
        break;
      case 'gradingDecider':
        translateID = 'GRADING_DECIDER';
        break;
      case 'gradingStatus':
        translateID = 'GRADING_STATUS';
        break;
      case 'gradingValue':
        translateID = 'GRADING_VALUE';
        break;
    }
    return this.translate.instant(`WORKS.SOCIETY_ATTRIBUTES.ATTRIBUTES_POPUP.${translateID}`);
  }

  generateOptions(values: any[]): Object[] {
    return values.map(value => ({ value, label: value }));
  }

  private getVisibleColumnsAttributes(schema: any): Observable<string[]> {
    const schemaDatatable = TabsUtils.getSChemaPropsToArray(schema);
    return this.store.pipe(
      select(fromRoot.getEditMode),
      map(editMode => {
        if (editMode) {
          return schemaDatatable;
        } else {
          return TabsUtils.getSchemaFiltered(schemaDatatable, ['editBtn', 'deleteBtn']);
        }
      }),
    );
  }

  private getVisibleColumnsGradings(schema: any): Observable<string[]> {
    const schemaDatatable = TabsUtils.getSChemaPropsToArray(schema);

    return this.store.pipe(
      select(fromRoot.getEditMode),
      map(editMode => {
        if (editMode) {
          return schemaDatatable;
        } else {
          return TabsUtils.getSchemaFiltered(schemaDatatable, ['editGradingBtn', 'deleteGradingBtn']);
        }
      }),
    );
  }
}
