<div fxLayout="column" class="label-container">
  <mat-label
    *ngIf="to.text"
    [ngClass]="{
      'label-title': true,
      'label-summary-title': to.isSummary,
      'ice-txt-size-11': !to.isSummary,
      'ice-cursor-none': !to.linkTitle
    }"
    (click)="to.linkTitle && onClick($event, model)"
  >
    {{ to.text }}
  </mat-label>

  <mat-label
    *ngIf="!to.icons && !(to.icon | async)"
    class="{{ to.class }}"
    [ngClass]="{
      'label-value': true,
      'ice-label-link': to.onClick
    }"
    [matTooltipPosition]="to.tooltipPosition || 'above'"
    [matTooltip]="to.tooltip"
    [matTooltipClass]="'ice-tooltip'"
    (click)="onClick($event, model)"
  >
    {{ formControl.value }}
  </mat-label>

  <div class="underline" *ngIf="!to.icons && !(to.icon | async)"></div>
  <button type="button" mat-icon-button *ngIf="to.icons" (click)="onClick($event, model)" [disabled]="to.disabled" attr.data-testid="button-icon-{{ to.icons.icon }}">
    <mat-icon
      [innerHTML]="to.icons.icon"
      [matTooltipPosition]="to.tooltipPosition || 'above'"
      [matTooltip]="to.tooltipText || to.icons.text"
      [matTooltipClass]="'ice-tooltip'"
      class="{{ to.icons.class }} mat-icon material-icons ice-mt-5"
    ></mat-icon>
  </button>
  <mat-icon
    *ngIf="to.icon | async; let icon"
    [innerHTML]="icon.icon"
    [matTooltipPosition]="to.tooltipPosition || 'above'"
    [matTooltip]="icon.text"
    [matTooltipClass]="'ice-tooltip'"
    class="{{ icon.class }} mat-icon material-icons ice-mt-5"
  ></mat-icon>
  <div *ngIf="formControl.status === 'INVALID' && to.errorMessages" style="color: red; font-size: 10pt !important; margin-top: -7px">{{ getCurrentError() }}</div>
</div>
