import { MatDialog } from '@angular/material/dialog';
import { Note } from '@ice/utils/notes/note.model';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_CODE, PurposeType } from 'config/constants/global.constants';
import { DialogForm, DIALOG_NORMAL_BT, LayoutConfig } from 'config/dialog-builders/dialog-form';
import { RowNote } from 'models/notes/notes';
import moment from 'moment';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import * as fromRoot from 'store/root';
import { escape } from 'lodash';
import * as fromSelectors from '../../../store/root/selectors';

export class NotesUtils {
  public static DEFAULT_NEW_NOTE = { code: DEFAULT_CODE, note: '' };
  public userName: string;
  constructor(private translate: TranslateService, private store: Store<fromRoot.RootState>, private dialog: MatDialog) {
    this.setCurrentUserName();
  }

  /*
  input: JSON of notes
  {
    GEN: ['note 0', 'note 1'],
    CAS: ['note 2', 'note 3', 'note 4', '<Note>{}' as string]
  }
  return: array of rows (code, type, note)
  */
  public static convertNotesToRows(notes: any, translate: TranslateService): RowNote[] {
    const rows: RowNote[] = [];
    if (notes) {
      Object.keys(notes).forEach(key => {
        const noteList = notes[key];
        if (noteList.length && noteList.length > 0) {
          const type = translate.instant(`NOTES.TYPES.${key}`);
          noteList.map((note, index) => {
            let noteContent: Note | string;
            try {
              noteContent = JSON.parse(note) as Note;
            } catch (e) {
              noteContent = { text: note } as Note;
            }
            if (noteContent) {
              rows.push({
                index,
                code: key,
                type,
                note: noteContent,
              });
            }
          });
        }
      });
    }
    return rows;
  }

  static getNotes(value) {
    const attr = value['attributes'];
    return attr && attr['notes'] ? attr['notes'] : null;
  }

  public static parseRowNotesDate(rowNotes: RowNote[]): RowNote[] {
    return rowNotes.map((rowNote: RowNote) => {
      if (!!rowNote.note.lastEdit) {
        const localFormattedDate = moment.utc(rowNote.note.lastEdit).local().format('YYYY-MM-DD HH:mm:ss');
        rowNote.note.lastEdit = localFormattedDate;
      }
      if (!!rowNote.note.created) {
        rowNote.note.created = moment.utc(rowNote.note.created).local().format('YYYY-MM-DD HH:mm:ss');
      }
      rowNote.note.text = escape(rowNote.note.text);
      return rowNote;
    });
  }

  public static removeRowFromNotes(notes: any, row: RowNote): any {
    const list = [...notes[row.code]];
    if (Array.isArray(list) && list[row.index]) {
      list.splice(row.index, 1);
    }
    return list;
  }

  public static updateNote(notes: any, newRow: RowNote, oldRow?: RowNote) {
    let notesCopy = { ...notes };

    if (newRow) {
      if (!notesCopy) {
        notesCopy = {};
      }
      const { code } = newRow;
      const newNote: Note = {
        category: newRow.code,
        text: typeof newRow.note === 'object' ? newRow.note.text : newRow.note,
        creatorName: newRow.userId,
        lastEdit: newRow.timestamp,
        created: newRow.timestamp,
      } as Note;

      if (oldRow) {
        if (code === oldRow.code) {
          const notesTypeCopy = Array.from(notesCopy[code]);
          newNote.creatorName = oldRow.note.creatorName;
          newNote.created = oldRow?.note?.created ? new Date(oldRow?.note?.created).toISOString() : '';
          notesTypeCopy[oldRow.index] = newNote;
          notesCopy[code] = notesTypeCopy;
          return this.stringifyNotesCluster(notesCopy);
        } else {
          notesCopy[oldRow.code] = NotesUtils.removeRowFromNotes(notesCopy, oldRow);
        }
      }
      if (!notesCopy[code]) {
        notesCopy[code] = [];
      }
      try {
        notesCopy[code].push(newNote);
      } catch (error) {}
    }

    return this.stringifyNotesCluster(notesCopy);
  }

  public static parseToNotes(notesStringified: string[]): Note[] {
    const notes: Note[] = [];

    notesStringified.forEach(strNote => {
      try {
        notes.push(JSON.parse(strNote));
      } catch (e) {
        notes.push({ text: strNote } as Note);
      }
    });
    return notes;
  }

  public static stringifyNotesCluster(notesCluster: any): any {
    const notesClusterStringified = {};

    Object.entries(notesCluster).forEach(entry => {
      const key = entry[0];
      const value: any = entry[1];
      notesClusterStringified[key] = value.map((note: Note) => JSON.stringify(note));
    });

    return notesClusterStringified;
  }

  public static stringifyAllNotesClusters(notes: any): any {
    const resultNotes = {};
    Object.keys(notes).forEach(key => {
      const notesStringified: string[] = Array.from(notes[key]);
      const notesObjectified: Note[] = NotesUtils.parseToNotes(notesStringified).filter((note: Note) => !!note);
      resultNotes[key] = notesObjectified;
    });
    return resultNotes;
  }

  public static getDialogForm(
    currentTimeUTC: string,
    translate: TranslateService,
    hideTypeField = false,
    extraFields?: FormlyFieldConfig[],
    addCharacterCounter = false,
    disableNoteField = false,
  ): FormlyFieldConfig[] {
    const noteTypes = [
      { value: 'CAS', label: translate.instant('NOTES.TYPES.CAS') },
      { value: 'DIS', label: translate.instant('NOTES.TYPES.DIS') },
      { value: 'GEN', label: translate.instant('NOTES.TYPES.GEN') },
      { value: 'INF', label: translate.instant('NOTES.TYPES.INF') },
      { value: 'INS', label: translate.instant('NOTES.TYPES.INS') },
      { value: 'MEC', label: translate.instant('NOTES.TYPES.MEC') },
      { value: 'URG', label: translate.instant('NOTES.TYPES.URG') },
      { value: 'DUT', label: translate.instant('NOTES.TYPES.DUT') },
      { value: 'CPD', label: translate.instant('NOTES.TYPES.CPD') },
      { value: 'ASM', label: translate.instant('NOTES.TYPES.ASM') },
      { value: 'AMG', label: translate.instant('NOTES.TYPES.AMG') },
      { value: 'PUB', label: translate.instant('NOTES.TYPES.PUB') },
      { value: 'SRA', label: translate.instant('NOTES.TYPES.SRA') },
      { value: 'UND', label: translate.instant('NOTES.TYPES.UND') },
      { value: 'UKG', label: translate.instant('NOTES.TYPES.UKG') },
      { value: 'UKT', label: translate.instant('NOTES.TYPES.UKT') },
      { value: 'EDI', label: translate.instant('NOTES.TYPES.EDI') },
    ];

    const basicWrappers = ['form-field', 'wrapper-input-text'];
    const noteWrappers = addCharacterCounter ? ['wrapper-character-counter', ...basicWrappers] : basicWrappers;
    const formlyConf: FormlyFieldConfig[] = [
      {
        fieldGroupClassName: 'display-flex-col',
        fieldGroup: [
          {
            key: 'code',
            type: 'select',
            templateOptions: {
              label: translate.instant('NOTES.DIALOG_TITLES.SELECT_TYPE'),
              required: true,
              options: noteTypes,
            },
            hideExpression: model => hideTypeField,
          },
          {
            key: 'note',
            wrappers: noteWrappers,
            type: 'textarea',
            className: 'dialog-note-textarea',
            templateOptions: {
              label: translate.instant('NOTES.DIALOG_TITLES.NOTE'),
              required: true,
              maxLength: 500,
              rows: 5,
              disabled: disableNoteField,
            },
            validation: {
              messages: {
                maxlength: translate.instant('NOTES.MESSAGES.MAX_LENGTH'),
                required: translate.instant('NOTES.MESSAGES.REQUIRED'),
              },
            },
          },
          {
            key: 'timestamp',
            type: 'input',
            hide: true,
            defaultValue: currentTimeUTC,
            templateOptions: {
              label: 'current time',
              required: true,
            },
          },
          ...(extraFields || []),
        ],
      },
    ];

    return formlyConf;
  }

  public static getDialogMessageDelete(translate: TranslateService): any[] {
    return [
      {
        fieldGroupClassName: 'display-flex-col',
        fieldGroup: [
          {
            template: `<h3>${translate.instant('NOTES.MESSAGES.CHECK_DELETE')}</h3>`,
          },
        ],
      },
    ];
  }

  public static notesCount(notesByType: any): number {
    let count = 0;
    Object.values(notesByType).map((value: any[]) => {
      count += value.length;
    });
    return count;
  }

  public updateNotesStore(notes: any, customAPISection?: string, customId?: string): void {
    const payload = { attributes: this.getNotes(notes), customAPISection, customId };
    this.store.dispatch(new fromRoot.UpdateNotes(payload));
    this.dialog.closeAll();
  }

  private getNotes(notes: object): object {
    const newNotes = {};
    Object.entries(notes).forEach(([key, value]) => {
      if (value.length !== 0) {
        newNotes[key] = value;
      }
    });
    const hasItems = Object.entries(newNotes).some(([key, value]: [string, Array<object>]) => value.length !== 0);
    return hasItems ? { notes: newNotes } : {};
  }

  public openDialog(
    title: string,
    formBuilder: FormlyFieldConfig[],
    model: any,
    submitLabel: string,
    cancelLabel: string,
    onSubmit: Function,
    onCancel?: Function,
    onInit?: Function,
    showSubmit?: Observable<boolean>,
    getDialogRef = false,
    secondLayout?: LayoutConfig,
    button1CustomClass?: string,
    dialogClass?: string,
  ): void | any {
    const dialogForm = new DialogForm(this.translate, this.dialog, DIALOG_NORMAL_BT);
    const dialogRef = dialogForm.openDialog(
      title,
      model,
      formBuilder,
      $event => {
        onSubmit($event);
        dialogRef.close();
      },
      () => dialogRef.close(),
      submitLabel,
      cancelLabel,
      dialogClass || 'dialog-wrapper-width-420-h',
      null,
      null,
      null,
      null,
      onInit,
      showSubmit,
      secondLayout,
      button1CustomClass,
    );
    if (getDialogRef) {
      return dialogRef;
    }
  }

  private setCurrentUserName(): void {
    this.store
      .select(fromSelectors.getUserName)
      .pipe(take(1))
      .subscribe((userName: string) => {
        this.userName = userName;
      });
  }

  public getNotePayload(noteText: string, isEdit: boolean, sectionName: string, previousNotes?: any): { attributes: any } {
    const newRow: RowNote = this.getNewNoteRow(isEdit, sectionName, noteText);
    const notes = NotesUtils.updateNote(previousNotes, newRow);
    return { attributes: this.getNotes(notes) };
  }

  private getNewNoteRow(isEdit: boolean, sectionName: string, noteText: string): RowNote {
    const currentTimeUTC = new Date().toISOString();
    const newNote: Note = this.getNewNote(isEdit, sectionName, noteText, currentTimeUTC);
    return <RowNote>{
      code: `${PurposeType.GEN}`,
      note: newNote,
      timestamp: currentTimeUTC,
      userId: this.userName,
    };
  }

  private getNewNote(isEdit: boolean, sectionName: string, noteText: string, currentTimeUTC: string = new Date().toISOString()): Note {
    const prefix = this.getNotePrefix(isEdit, sectionName);
    return <Note>{
      category: `${PurposeType.GEN}`,
      text: `${prefix} ${noteText}`,
      creatorName: this.userName,
      lastEdit: currentTimeUTC,
      created: currentTimeUTC,
    };
  }

  getNotePrefix(isEdit: boolean, sectionName: string): string {
    return `${isEdit ? '' : 'NEW'} ${sectionName.toUpperCase()} ${isEdit ? 'UPDATE' : 'CREATION'}`;
  }
}
