import { environment } from 'config/env';
import { SectionRouterViews } from './section-router-views';
import type { SectionConfig } from './sections-config';

export const SectionCopyrightWorks: SectionConfig = {
  name: 'works',
  url: '/copyright/works',
  domainName: 'copyright',
  baseUrl: environment.apiUrl,
  baseUrlCubeData: environment.apiUrlCubeData,
  apiSegment: 'works',
  detailSegment: `submitted-claims/<<ns>>/<<id>>`,
  minCharsToSearch: 3,
  apiIncludes: {
    search: `
      attributes{ id, genre, publicationDate, source, owner, status, duration, language, titles, tags, societyMarkers, purpose, origin },
      relations{ relation, otherId },
      contributions{ contributor, role,contributorPartyNameId }.contributor{ partyName }.partyName{ attributes, relations }.relations{ relation, otherId },
      contributions.contributor.partyName.attributes{ name, firstName },
      conflicts.conflict.claimCases.claimCase.attributes,counterclaims.attributes{status},
      counterclaimsCount,
      activityTriggersCount,
      conflictsCount`,
    expertSearch: `
      attributes{ id, genre, publicationDate, source, owner, status, duration, language, titles, tags, societyMarkers, purpose, origin },
      relations{ relation, otherId },
      contributions{ contributor, role,contributorPartyNameId }.contributor{ partyName }.partyName{ attributes, relations }.relations{ relation, otherId },
      contributions.contributor.partyName.attributes{ name, firstName }`,
    detail: `
      attributes,
      claimsCount,
      conflictsCount,
      counterclaimsCount,
      partyNamesCount,
      contributions.agreementsCount,
      relations{ relation, otherId },
      societies{societyId,grading, attributes}.society{societyId,attributes}.attributes,
      admin{attributes}.attributes{notes}.notes,
      permissions,
      activityTriggers{attributes}.attributes,
      claims,
      claimsCount,
      claims.claimant.partyName.attributes,
      claims.claimant.party.relations[XREF]{ relation, otherId },
      claims.claimant.party.societies,
      claims.claimant.party.attributes,
      claims.claimant.partyName.relations[XREF]{ relation, otherId },
      claims.extensions,
      conflicts.conflict.works.work.activityTriggers.attributes,
      conflicts.conflict.works.work.relations,
      conflicts.conflict.attributes,
      conflicts.conflict.case,
      conflicts.conflict.claimConflictPoints,
      conflicts.conflict.claimCases.claimCase.claimants,
      partyNames,
      partyNames.partyName.parties.party.attributes{ typeOf, status, sex, dateOfBirth, countryOfBirth, placeOfBirth, dateOfDeath },
      partyNames{ partyName, role, clauses, partyNameId }.partyName{attributes, relations}.attributes{ id, typeOfName, name, firstName },
      partyNames.partyName.relations[XREF]{ relation, otherId },
      conflicts.conflict.claimCases.claimCase.attributes,
      contributions.contributor.partyName.relations[XREF]{relation,otherId},
      contributions.contributor.partyName.parties.party.relations[XREF]{relation,otherId},
      contributions.contributor.partyName.parties.party.societies.society.memberships,
      contributions.contributor.partyName.parties.party.attributes,
      contributions.contributor.partyName.attributes,
      contributions.contributor.societies,
      contributions.manuscriptShares.shares,
      recordings`,
  },
  searchServerSideSortDefault: 'attributes.titles.OT.titleValue',
  exportThisIdAsFirstElement: 'keyWithoutPrefix',
  newItemBtn: [SectionRouterViews.home, SectionRouterViews.search],
  allowedActions: {
    newItem: true,
    newNote: true,
    newCounterClaim: true,
    workEditActions: true,
    removeItem: false,
    workEditBySociety: true,
    clone: true,
    createClaimReadMode: true,
    createRemoteRod: true,
  },
  auditTypes: ['work', 'conflict'],
};
