import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ClaimGraphUtils, fieldConfig, IpUtils, SharePictureUtils, TerritoryUtils } from '@ice';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { ClaimsUtils } from '@ice/utils/claim/claims.utils';
import { DatepickerUtils } from '@ice/utils/datepicker/datepicker.utils';
import { LocalStorageUtils } from '@ice/utils/local-storage/localstorage.utils';
import { TreeChartUtils } from '@ice/utils/tree-chart/tree-chart.utils';
import { select, Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { URL_CONFLICTS } from 'app/sections/wrapper/detail/detail';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import * as fromApiCalls from 'config/api-calls';
import { ICE } from 'config/constants/global.constants';
import { CLAIM_TYPES } from 'config/constants/repertoires.constants';
import { ALL_MR, ALL_PR, ALL_STATUS, DEFAULT_FILTER_MODEL } from 'config/constants/works.constants';
import { CopyrightClaimsDataTable } from 'config/data-table-builders/copyright.claims';
import { SectionsConfig } from 'config/sections-config';
import { SectionTabConfig } from 'config/tabs-data-builders/tabs-data';
import { capitalize, cloneDeep, get, isEqual, pickBy } from 'lodash';
import moment from 'moment';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { DatatableService } from 'services/datatable/datatable.service';
import { NamespaceService } from 'services/namespace/namespace.service';
import { PermissionsService } from 'services/permissions/permissions.service';
import * as fromNewSectionItem from 'store/new-section-item';
import * as fromRoot from 'store/root';

export class TabWorkAllClaims implements SectionTabConfig {
  private schema: CopyrightClaimsDataTable;
  private workId: string;
  private workCubeId: string;
  private dialogRef: any;
  private postTermCollectionDateSubscription: Subscription;
  private terminationDateSubscription: Subscription;
  private terminateModel: any;
  private refreshAllClaimsFilter$ = new BehaviorSubject(true);
  private hierarchySort$ = new BehaviorSubject(true);
  private resetFilter$ = new BehaviorSubject(false);
  canEditTitles = false;
  private canCreateCounterClaim = false;
  private deleteClaim;
  private forcedNS;
  private minHeight = 105;
  private maxHeight = 425;
  private rowHeight = 40;
  private dataTableHeight = new BehaviorSubject(this.maxHeight);
  public unsubscribeAll$ = new Subject();
  private allClaimsFiltered$ = combineLatest([
    this.store.select(fromRoot.getAllClaims).pipe(distinctUntilChanged((prev, next) => isEqual(prev, next))),
    this.refreshAllClaimsFilter$,
    this.resetFilter$,
    this.store.select(fromRoot.getUserDetail),
  ]).pipe(
    map(([claims, _refreshFilter, _resetFilter, userDetail]) => {
      const data = ClaimsUtils.workTabAllClaimsCleaner(claims, LocalStorageUtils.getAllClaimsFilter(userDetail?.cognitoUserName) || DEFAULT_FILTER_MODEL);
      if (!!data?.length) {
        this.dataTableHeight.next(this.getCardMinHeight(data));
      } else {
        this.dataTableHeight.next(this.maxHeight);
      }
      return data;
    }),
  );

  constructor(
    protected translate: TranslateService,
    protected fuseTranslationLoader: FuseTranslationLoaderService,
    protected store: Store<fromRoot.RootState>,
    private dialog: MatDialog,
    commonApiService: null,
    detailService: null,
    private nsService: NamespaceService,
    iceFacade: null,
    validatorService: null,
    private permissionsService: PermissionsService,
    private searchService,
    storeNewItem: null,
    params: null,
    private datatableService: DatatableService,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.schema = new CopyrightClaimsDataTable(this.translate, this.fuseTranslationLoader, this.store, this.permissionsService);
    this.getWorkId();
    this.canCreateCounterClaim = this.permissionsService.can('works_all_claims_counterclaim');
    this.store.pipe(select(fromRoot.getForcedNS), take(1)).subscribe(forcedNS => {
      this.forcedNS = forcedNS;
    });
  }

  getWorkId() {
    combineLatest([
      this.store.select(fromRoot.getRouterParams),
      this.store.pipe(
        select(fromRoot.getWorkId),
        filter(id => !!id),
      ),
    ])
      .pipe(take(1))
      .subscribe(([params, id]) => {
        this.workId = params.key;
        this.workCubeId = id;
      });
  }

  showDeleteClaimPopup(row) {
    this.dialogRef = this.dialog.open(DialogMultiLayoutComponent, {
      data: {
        loading: this.store.pipe(select(fromRoot.getDataProgressBar)),
        className: 'delete-claim-dialog',
        layouts: [this.deleteClaimLayout(row)],
      },
    });
  }

  responseHandler(action) {
    this.store
      .pipe(
        select(fromNewSectionItem.getNewSectionResponse),
        filter(response => !!response),
        take(1),
      )
      .subscribe(_ => {
        this.dialogRef.close();
        if (action === 'DELETE_CLAIM' && this.deleteClaim.status.toUpperCase() === CLAIM_TYPES.VALIDATED) {
          this.store.dispatch(
            new fromRoot.ShowSnackBar({
              message: this.translate.instant('WORKS.CLAIM_DELETE_OK_MESSAGE'),
              duration: 5000,
            }),
          );
        }
        this.store.dispatch(new fromNewSectionItem.ResetNewSectionItem());
        this.store.dispatch(new fromRoot.EndEditMode());
        this.store.dispatch(new fromRoot.StartApiCall([fromApiCalls.getTerritoriesCountries, fromApiCalls.getAllClaimsWork]));
      });
  }

  deleteClaimLayout(claim) {
    const isFormValid = new BehaviorSubject(false);
    this.deleteClaim = claim;

    const terminationName = 'terminationDate';
    const terminationLabel: string = this.translate.instant('WORKS.DELETE_CLAIM.TERMINATION_DATE');
    const terminationExtraValidators: Object = {
      isAfterPostTerm: {
        expression: (control: FormControl, field) => {
          const fieldPostTermDateValue = control && control.parent && control.parent.get('postTermCollectionDate') && control.parent.get('postTermCollectionDate').value;
          const endDateError = !fieldPostTermDateValue || moment(fieldPostTermDateValue).isSameOrAfter(control.value);
          return endDateError;
        },
        message: this.translate.instant('WORKS.DELETE_CLAIM.TERMINATION_ERROR'),
      },
    };
    const terminationRequired = true;
    const translate: TranslateService = this.translate;
    const terminationHooks = {
      onInit: field => {
        this.terminationDateSubscription = field.formControl.valueChanges.subscribe(value => {
          if (field.form.controls['postTermCollectionValue'].value === 'none') {
            field.form.controls['postTermCollectionDate'].markAsUntouched();
            field.form.controls['postTermCollectionDate'].setValue(field.formControl.value);
          }
        });
      },
      onDestroy: () => {
        this.unsubscribeAll$.next();
        this.unsubscribeAll$.complete();
        if (this.terminationDateSubscription) {
          this.terminationDateSubscription.unsubscribe();
        }
      },
    };

    const postTermName = 'postTermCollectionDate';
    const postTermLabel: string = this.translate.instant('WORKS.DELETE_CLAIM.POSTTERM_COLLECTION_DATE');
    const postTermRequired = true;
    const postTermHooks = {
      onInit: field => {
        if (field.form.controls['postTermCollectionValue']) {
          this.postTermCollectionDateSubscription = field.formControl.valueChanges.subscribe(value => {
            if (value && field.form.controls['postTermCollectionDate'].touched) {
              if (moment(value).isSame(moment('9999-12-31'))) {
                field.form.controls['postTermCollectionValue'].setValue('indefinite');
              } else {
                field.form.controls['postTermCollectionValue'].setValue('date');
              }
              field.form.controls['terminationDate'].updateValueAndValidity();
            }
          });
        }
      },
      onDestroy: () => {
        if (this.postTermCollectionDateSubscription) {
          this.postTermCollectionDateSubscription.unsubscribe();
        }
      },
    };

    return {
      title: of(this.translate.instant('WORKS.DELETE_CLAIM.TITLE')),
      actions: [],
      layout: [
        {
          group: [
            {
              type: 'formly',
              flex: 99,
              config: {
                model: of({}),
                setValidForm: isValid => isFormValid.next(isValid),
                change: model => (this.terminateModel = model),
                formBuilder: of([
                  DatepickerUtils.getDatepickerField({
                    name: terminationName,
                    label: terminationLabel,
                    extraValidators: terminationExtraValidators,
                    required: terminationRequired,
                    translate,
                    hooks: terminationHooks,
                  }),
                  {
                    key: 'postTermCollectionValue',
                    modelOptions: { updateOn: 'blur' },
                    type: 'select',
                    templateOptions: {
                      attributes: { 'data-testid': 'postTermCollectionValue' },
                      label: this.translate.instant('WORKS.DELETE_CLAIM.POSTTERM_COLLECTION'),
                      required: true,
                      options: [
                        { value: 'none', label: 'None' },
                        { value: 'date', label: 'Until Date' },
                        { value: 'indefinite', label: 'Indefinite' },
                      ],
                      change: (field, $event) => {
                        if ($event.value === 'none') {
                          if (field.model.terminationDate) {
                            field.form.controls['postTermCollectionDate'].markAsUntouched();
                            field.form.controls['postTermCollectionDate'].setValue(field.model.terminationDate);
                          }
                        }
                        if ($event.value === 'indefinite') {
                          field.form.controls['postTermCollectionDate'].markAsUntouched();
                          field.form.controls['postTermCollectionDate'].setValue('9999-12-31');
                        }
                        if ($event.value === 'date') {
                          field.form.controls['postTermCollectionDate'].markAsUntouched();
                          field.form.controls['postTermCollectionDate'].setValue('');
                        }
                        field.form.controls['terminationDate'].updateValueAndValidity();
                      },
                    },
                  },
                  DatepickerUtils.getDatepickerField({
                    name: postTermName,
                    label: postTermLabel,
                    required: postTermRequired,
                    translate,
                    hooks: postTermHooks,
                  }),
                ]),
              },
            },
          ],
        },
        {
          group: [
            {
              type: 'buttonsGroup',
              config: {
                emitEvent: (action: any) => {
                  this.store.dispatch(new fromNewSectionItem.ResetNewSectionItem());
                  const { postTermCollectionDate, terminationDate } = this.terminateModel;
                  this.responseHandler(action);
                  switch (action) {
                    case 'TERMINATE_CLAIM':
                      this.store.dispatch(
                        new fromNewSectionItem.TerminateClaim({
                          workId: this.workId,
                          claimIds: claim.claimId,
                          postTermCollectionDate: moment(postTermCollectionDate).format('YYYY-MM-DD'),
                          terminationDate: moment(terminationDate).format('YYYY-MM-DD'),
                        }),
                      );
                      break;
                    case 'DELETE_CLAIM':
                      this.store.dispatch(new fromNewSectionItem.DeleteClaim({ workId: this.workId, claimIds: claim.claimId }));
                  }
                },
                config: {
                  type: 'matBtn',
                  layout: 'row',
                  customClass: 'setup-counterclaim-button-wrapper',
                  buttons: [
                    {
                      color: 'primary',
                      label: `BT_TERMINATE_CLAIM`,
                      action: 'TERMINATE_CLAIM',
                      disabled: isFormValid.pipe(map(valid => !valid)),
                    },
                    {
                      className: 'dialog-delete-claim-button',
                      color: 'accent',
                      label: `BT_DELETE_CLAIM`,
                      action: 'DELETE_CLAIM',
                    },
                  ],
                },
              },
            },
          ],
        },
      ],
    };
  }

  private getClaimsFilter(getModel: Observable<any>) {
    const { translate } = this;
    let formData: FormGroup;
    let initialModel;
    getModel.subscribe(model => (initialModel = cloneDeep(model))).unsubscribe();

    const firstLine: FormlyFieldConfig = fieldConfig([
      {
        className: 'flex-4-no-mw not-remove-pl ice-claims-territory-field',
        key: 'territory',
        type: 'ice-select',
        modelOptions: {
          updateOn: 'blur',
        },
        templateOptions: {
          placeholder: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.FIELD_TERRITORY'),
          label: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.FIELD_TERRITORY'),
          required: true,
          multiple: true,
          selectAllLabel: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.ALL_TERRITORIES'),
          selectAllOption: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.SELECT_ALL'),
          options: this.store.select(fromRoot.getAllTerritories).pipe(map(TerritoryUtils.getTerritoriesAutocompleteOptions)),
          useCountryShortcuts: true,
          filterActive: true,
          filterPlaceHolder: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.FILTER_PLACEHOLDER'),
          change: (field, $event) => TerritoryUtils.cleanTerritorySelectorValue(field, $event.value, $event.lastValue),
          componentVersion: 1,
          clearAllLabel: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.CLEAR_ALL'),
        },
        hooks: {
          onInit: field => {
            formData = field.form;
            this.store
              .select(fromRoot.getAllTerritories)
              .pipe(
                filter(territories => !!territories),
                take(1),
              )
              .subscribe(() => {
                // we need to set the value once the territories are loaded
                field.formControl.setValue(initialModel.territory);
              });
          },
        },
      },
      {
        className: 'flex-1 ice-min-width-170',
        key: 'prRight',
        type: 'ice-select',
        defaultValue: '',
        templateOptions: {
          label: `${translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.FIELD_PR_RIGHTS')} *`,
          options: IpUtils.getPrOptions([{ label: ALL_PR, value: '' }]),
          selectedLabelsAsValue: true,
        },
      },
      {
        className: 'flex-1 ice-min-width-170',
        key: 'mrRight',
        type: 'ice-select',
        defaultValue: '',
        templateOptions: {
          label: `${translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.FIELD_MR_RIGHTS')} *`,
          options: IpUtils.getMrOptions([{ label: ALL_MR, value: '' }]),
          selectedLabelsAsValue: true,
        },
      },
      DatepickerUtils.getDatepickerField({
        name: 'usageDate',
        label: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.FIELD_USAGE_DATE'),
        translate: this.translate,
        extraClass: 'flex-1 not-type-field ice-min-width-140',
        infoText: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.FIELD_USAGE_DATE_INFO'),
      }),
      DatepickerUtils.getDatepickerField({
        name: 'distributionDate',
        label: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.FIELD_DISTRIBUTION_DATE'),
        translate: this.translate,
        extraClass: 'flex-1 not-type-field ice-min-width-140',
        infoText: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.FIELD_DISTRIBUTION_DATE_INFO'),
      }),
      {
        className: 'flex-1 ice-min-width-170',
        key: 'status',
        type: 'ice-select',
        defaultValue: '',
        modelOptions: {
          updateOn: 'blur',
        },
        templateOptions: {
          optgroupClass: 'primary-checkbox',
          label: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.CLAIM_STATUS'),
          placeholder: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.CLAIM_STATUS'),
          required: true,
          multiple: true,
          options: [
            {
              header: translate.instant(`WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.${ALL_STATUS}`),
              options: Object.values(CLAIM_TYPES).map(value => ({ value, label: capitalize(value) })),
            },
          ],
        },
      },
    ]);

    const checkboxButtons = [
      {
        className: 'flex-1 ice-mr-20',
        key: 'onlyInConflict',
        type: 'checkbox',
        defaultValue: false,
        templateOptions: {
          label: translate.instant('WORKS.ALL_CLAIMS.ONLY_CONFLICTS'),
          indeterminate: false,
        },
      },
      {
        className: 'flex-1 ice-mr-20',
        key: 'removeE',
        type: 'checkbox',
        defaultValue: false,
        templateOptions: {
          label: translate.instant('WORKS.SHARE_PICTURE.FILTER.NO_E'),
          indeterminate: false,
          required: false,
          change: (field, $event) => {
            this.store.dispatch(new fromRoot.SetWorkFilter({ removeE: $event.checked }));
          },
        },
      },
      {
        className: 'flex-1 ice-mr-20',
        key: 'removeSE',
        type: 'checkbox',
        defaultValue: false,
        templateOptions: {
          label: translate.instant('WORKS.SHARE_PICTURE.FILTER.NO_SE'),
          indeterminate: false,
          required: false,
          change: (field, $event) => {
            this.store.dispatch(new fromRoot.SetWorkFilter({ removeSE: $event.checked }));
          },
        },
      },
    ];

    const formButtons = [
      {
        className: 'flex-1 mat-form-field-infix ice-accent',
        type: 'button',
        templateOptions: {
          text: translate.instant('FORM_RESET'),
          btnType: ' ice-accent',
          materialType: 'mat-button',
          onClick: () => {
            formData.reset(initialModel);
            this.resetFilter$.next(true);
          },
        },
        hooks: {
          onInit: field => {
            formData = field.form;
          },
        },
      },
      {
        className: 'flex-1 mat-form-field-infix ice-accent',
        type: 'button',
        templateOptions: {
          text: translate.instant('WORKS.ALL_CLAIMS.SUBMIT'),
          type: 'submit',
          color: 'accent',
          materialType: 'mat-flat-button',
          onClick: () => {
            const model = formData.value;
            this.store.pipe(select(fromRoot.getUserDetail), withLatestFrom(this.resetFilter$), take(1)).subscribe(([userDetail, reset]) => {
              const lastFilter = LocalStorageUtils.getAllClaimsFilter(userDetail.cognitoUserName);
              if (!reset) {
                LocalStorageUtils.setFilterAllClaims(userDetail.cognitoUserName, model);
                this.store.dispatch(new fromRoot.SetAllClaimsFilter(model));
              } else {
                this.resetFilter$.next(false);
              }
              if (ClaimGraphUtils.needsClaimGraphApiCall(model, lastFilter) || reset) {
                this.store.dispatch(new fromRoot.StartApiCall([fromApiCalls.getTerritoriesCountries, fromApiCalls.getAllClaimsWork]));
              } else {
                this.refreshAllClaimsFilter$.next(true);
              }
            });
          },
          isDisabled: () => formData?.invalid,
        },
      },
    ];

    const secondLine: FormlyFieldConfig = {
      fieldGroupClassName: 'display-flex ice-justify-right ice-pr-0 group-inputs ice-accent',
      fieldGroup: [...checkboxButtons, ...formButtons],
    };

    return [firstLine, secondLine];
  }

  getConf(): IceGroupComponent[] {
    const ACTIONS = { SORT_CLAIMS: 'SORT_CLAIMS', SHOW_TREE_CHART: 'SHOW_TREE_CHART', SHOW_TABLE_VIEW: 'SHOW_TABLE_VIEW' };
    const VIEWS = { TREE: 'TREE', TABLE: 'TABLE' };
    const view$ = new BehaviorSubject(VIEWS.TABLE);
    const defaultFilterValues = {
      ...DEFAULT_FILTER_MODEL,
      status: [CLAIM_TYPES.VALIDATED, CLAIM_TYPES.SUBMITTED],
    };
    const getModel = this.resetFilter$.pipe(
      withLatestFrom(this.store.select(fromRoot.getAllClaimsFilter), this.store.select(fromRoot.getUserDetail).pipe(filter(user => !!user?.cognitoUserName))),
      map(([resetFilter$, allClaimsFilter, userDetail]) => ({ userDetail, resetFilter$, allClaimsFilter })),
      tap(({ userDetail, resetFilter$ }) => {
        if (!!resetFilter$) {
          LocalStorageUtils.setFilterAllClaims(userDetail.cognitoUserName, defaultFilterValues);
        }
      }),
      map(({ userDetail, allClaimsFilter }) => {
        // The filters' values are determined in the following order:
        // 1. Redux state
        // 2. LocalStorage
        // 3. Default values
        const cachedAllClaimsFilter = { ...defaultFilterValues, ...LocalStorageUtils.getAllClaimsFilter(userDetail.cognitoUserName), ...allClaimsFilter };
        return cachedAllClaimsFilter;
      }),
    );
    getModel.pipe(takeUntil(this.unsubscribeAll$), withLatestFrom(this.resetFilter$)).subscribe(([allClaimsFilter, resetFilter$]) => {
      this.store.dispatch(new fromRoot.SetAllClaimsFilter(resetFilter$ ? defaultFilterValues : allClaimsFilter));
    });
    return [
      {
        group: [
          {
            type: 'formly',
            fxFlex: '1 1 calc(100% - 180px)',
            config: {
              className: 'all-claims-filter',
              formClass: 'bg-filter-form ice-p-16',
              formBuilder: of(this.getClaimsFilter(getModel)),
              model: getModel,
            },
          },
        ],
      },
      {
        group: [
          {
            type: 'buttonsGroup',
            fxFlex: '1 1 120px',
            config: {
              class: '',
              emitEvent: (action: any) => {
                switch (action) {
                  case ACTIONS.SORT_CLAIMS:
                    this.datatableService.forceSort.next();
                    break;
                  case ACTIONS.SHOW_TREE_CHART:
                    view$.next(VIEWS.TREE);
                    break;
                  case ACTIONS.SHOW_TABLE_VIEW:
                    view$.next(VIEWS.TABLE);
                    break;
                }
              },
              config: {
                type: 'matBtn',
                layout: 'row',
                customClass: 'all-claims-buttons-group',
                buttons: [
                  {
                    label: `BT_SORT_CLAIMS`,
                    action: ACTIONS.SORT_CLAIMS,
                    disabled: this.hierarchySort$,
                    isHidden: view$.pipe(map(view => view !== VIEWS.TABLE)),
                    className: 'ice-text-button',
                    icon: 'restart_alt',
                    iconClassName: 'ice-ml-8',
                  },
                  {
                    label: 'WORKS.SHARE_PICTURE.SHOW_TREE_CHART',
                    action: ACTIONS.SHOW_TREE_CHART,
                    isHidden: view$.pipe(map(view => view !== VIEWS.TABLE)),
                    className: 'ice-text-button',
                    icon: 'account_tree',
                    iconClassName: 'material-icons-outlined ice-ml-8',
                  },
                  {
                    label: 'WORKS.SHARE_PICTURE.SHOW_TABLE_VIEW',
                    action: ACTIONS.SHOW_TABLE_VIEW,
                    isHidden: view$.pipe(map(view => view !== VIEWS.TREE)),
                    className: 'ice-text-button',
                    icon: 'table_chart',
                    iconClassName: 'material-icons-outlined ice-ml-8',
                  },
                ],
              },
            },
          },
        ],
      },
      {
        group: [
          {
            type: 'dataTable',
            isHidden: view$.pipe(map(view => view !== VIEWS.TABLE)),
            config: {
              data: this.allClaimsFiltered$,
              dontStrip: true,
              customClass: 'claim-graph ice-fixed-header',
              sorts: [{ prop: 'hierarchy', dir: 'asc' }],
              schema: this.schema.getDataTable(
                null,
                row => this.showDeleteClaimPopup(row),
                null,
                null,
                null,
                row => this.goToIPS(row),
                null,
                null,
                row => this.goToAgreement(row),
              ),
              onSort: sort => (get(sort, '[0].prop') === 'hierarchy' ? this.hierarchySort$.next(true) : this.hierarchySort$.next(false)),
              visibleColumns: this.store.pipe(
                select(fromRoot.getEditMode),
                withLatestFrom(this.store.select(fromRoot.getEditingId)),
                map(([editMode, editId]) => editMode && this.permissionsService.can('works_edit_all_claim') && editId === ICE),
                map(canDelete => [
                  'role',
                  'name',
                  'refLabel',
                  'prSoc',
                  'mrSoc',
                  'agreementId',
                  'priorRoyaltyDate',
                  'startDate',
                  'endDate',
                  'postTermCollectionDate',
                  'territoriesTisa',
                  'allRights',
                  'status',
                  'inDispute',
                  'pr',
                  'mr',
                  'alert',
                  'counterclaim',
                  ...(canDelete ? ['delete'] : []),
                ]),
              ),
              loadingIndicator: false,
              isLoadingData: this.store.select(fromRoot.getDataProgressBar),
              reorderable: true,
              shadowed: false,
              height: this.dataTableHeight,
              maxHeight: this.dataTableHeight,
              columnMode: 'flex',
              onMouseSelect: event => this.onMouseSelect(event),
            },
          },
        ],
      },
      {
        group: [
          {
            type: 'dataTableTotals',
            isHidden: view$.pipe(map(view => view !== VIEWS.TABLE)),
            config: {
              totalsTiles: ClaimGraphUtils.getTotalTitles(
                this.allClaimsFiltered$,
                (rows: any) => SharePictureUtils.getSharesTotals(rows),
                this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.FOOTER.LABEL_CREATOR_SUB_TOTAL'),
                this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.FOOTER.LABEL_PUBLISHER_SUB_TOTAL'),
                this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.FOOTER.LABEL_SPECIAL_SUB_TOTAL'),
                this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.FOOTER.LABEL_TOTAL'),
              ),
              class: of('manuscript-totals work-detail-alert-col-margin'),
            },
          },
        ],
      },
      {
        group: [
          {
            type: 'buttonsGroup',
            isHidden: view$.pipe(map(view => view !== VIEWS.TABLE || (view === VIEWS.TABLE && !this.canCreateCounterClaim))),
            config: {
              emitEvent: (action: any) => {
                switch (action) {
                  case 'SET_UP_COUNTERCLAIM': {
                    const workId = this.workId;
                    const workCubeId = this.workCubeId;
                    this.store
                      .select(fromRoot.getUser)
                      .pipe(take(1))
                      .subscribe(user => {
                        const extras = { queryParams: { workId, ...pickBy(ClaimGraphUtils.getAllClaimsFilterModel(user), property => !!property), workCubeId } };
                        this.store.dispatch(
                          new fromRoot.Go({
                            path: [URL_CONFLICTS.works],
                            extras,
                          }),
                        );
                      });
                  }
                }
              },
              config: {
                type: 'matBtn',
                layout: 'row',
                customClass: 'setup-counterclaim-button-wrapper',
                buttons:
                  (!this.forcedNS && [
                    {
                      color: 'accent',
                      label: `BT_SET_UP_COUNTERCLAIM`,
                      action: 'SET_UP_COUNTERCLAIM',
                      disabled: this.store.select(fromRoot.getDataProgressBar),
                    },
                  ]) ||
                  [],
              },
            },
          },
        ],
      },
      {
        group: [
          {
            type: 'treeView',
            config: combineLatest([this.allClaimsFiltered$, this.store.select(fromRoot.getWorkTitle)]).pipe(
              map(([allClaims, title]) => {
                let claimNodes = [];
                if (allClaims) {
                  claimNodes = allClaims.map(claim => {
                    const parent = allClaims.find(searchedClaim => (searchedClaim.claimId || []).includes(claim.parentId));
                    return this.createNewNodeItem(claim, parent);
                  });
                }
                const levels = TreeChartUtils.calculateLevels(claimNodes);
                return {
                  treeData: [this.getInitialRootElement(title), ...claimNodes],
                  maxHLevel: levels.maxHLevel,
                  vLevel: levels.vLevel,
                };
              }),
            ),
            isHidden: view$.pipe(map(view => view !== VIEWS.TREE)),
          },
        ],
      },
    ];
  }

  onMouseSelect(event: any): void {
    const { activatedColumn } = event;
    switch (activatedColumn) {
      case 'name':
      case 'refLabel':
        this.goToIPS(event);
        break;
      case 'agreementId':
        this.goToAgreement(event);
        break;
    }
  }

  goToIPS(model) {
    this.store.dispatch(
      new fromRoot.Go({
        path: [`copyright/${SectionsConfig.IPS.name}/${model.key}/details`],
      }),
    );
  }

  goToAgreement(model) {
    this.store.dispatch(
      new fromRoot.Go({
        path: [`copyright/${SectionsConfig.AGREEMENTS.name}/ICE:${model.agreementId}/details`],
      }),
    );
  }

  private getInitialRootElement(title: string) {
    return {
      label: 'root',
      name: title,
      parent: null,
      color: '#17becf',
      level: 0,
    };
  }

  createNewNodeItem(claim, parentClaim) {
    const { name, allRights, startDate, endDate, territoriesTisa, mr, pr, claimId, level } = claim;
    const rights = allRights?.split('/') || [];
    const parentLabel = parentClaim?.claimId[0] || 'root';
    return {
      label: claimId[0],
      dates: `${startDate}/${endDate}`,
      territories: territoriesTisa,
      name,
      parent: parentLabel,
      color: '#9B0CFF',
      pr,
      mr,
      mrRights: (rights.length > 1 && rights[1]) || '',
      prRights: (rights.length > 0 && rights[0]) || '',
      level,
      borderColor: null,
    };
  }

  private getCardMinHeight(data: any[]): number {
    const tableHeaderHeight = 60;
    return Math.min(Math.max(this.minHeight, (data?.length || 0) * (this.rowHeight + 2) + tableHeaderHeight), this.maxHeight) || this.maxHeight;
  }
}
