import { NgModule } from '@angular/core';
import { FuseNavigationModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { IceMaterialModule } from '@ice/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { NavbarComponent } from 'app/root/components/navbar/navbar.component';

@NgModule({
  declarations: [NavbarComponent],
  imports: [IceMaterialModule, FuseSharedModule, FuseNavigationModule, TranslateModule],
  exports: [NavbarComponent],
})
export class NavbarModule {}
