import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DataTableRow } from '@ice/components/data-table/data-table';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { NotesUtils, TabsUtils } from '@ice';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import { RowNote } from 'models/notes/notes';
import { Observable } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';
import * as fromRoot from 'store/root';
import * as fromSelectors from 'store/root/selectors';
import type { SectionTabConfig } from '../tabs-data';

export class TabNotes implements SectionTabConfig {
  private userName: string;

  constructor(private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService, private store: Store<fromRoot.RootState>) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.setCurrentUserName();
  }

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('NOTES.TITLE'),
              model: this.store.pipe(select(fromRoot.getCopyrightNotes)).pipe(
                map(notes => {
                  if (notes) {
                    const result: RowNote[] = NotesUtils.convertNotesToRows(notes, this.translate);
                    return NotesUtils.parseRowNotesDate(result);
                  } else {
                    return [];
                  }
                }),
              ),
              columnMode: 'flex',
              schema: this.getDataTable(),
              tableWidth: '100',
              sorts: [{ prop: 'type', dir: 'asc' }],
              visibleColumns: this.getVisibleColumnsNotes(this.getDataTable()),
            },
          },
        ],
      },
    ];
  }

  getDataTable(): DataTableRow[] {
    return [
      { name: this.translate.instant('NOTES.DATATABLE.TYPE'), prop: 'type', flexGrow: 1 },
      { name: this.translate.instant('NOTES.DATATABLE.INFORMATION'), prop: 'note.text', flexGrow: 3 },
      { name: this.translate.instant('NOTES.DATATABLE.USER'), prop: 'note.creatorName', flexGrow: 1 },
      { name: this.translate.instant('NOTES.DATATABLE.CREATED'), prop: 'note.created', flexGrow: 1 },
      { name: this.translate.instant('NOTES.DATATABLE.LAST_EDIT'), prop: 'note.lastEdit', flexGrow: 1 },
      {
        name: '',
        prop: 'editBtn',
        actionButtonIcon: 'edit',
        flexGrow: 0.001,
        maxWidth: 40,
        minWidth: 40,
        action: (row: RowNote) => {
          this.store.pipe(select(fromRoot.getCopyrightNotes), take(1)).subscribe(objectifiedNotes => {
            const updateNoteAction = new fromRoot.UpdateNote({
              rowNote: row,
              currentNotes: objectifiedNotes,
            });
            this.store.dispatch(updateNoteAction);
          });
        },
      },
      {
        actionButtonIcon: 'delete',
        flexGrow: 0.001,
        maxWidth: 50,
        minWidth: 50,
        action: (row: RowNote) => {
          this.store.pipe(select(fromRoot.getCopyrightNotes), take(1)).subscribe(objectifiedNotes => {
            const deleteNoteAction = new fromRoot.DeleteNote({
              deleteRowNote: row,
              currentNotes: objectifiedNotes,
            });
            this.store.dispatch(deleteNoteAction);
          });
        },
      },
    ];
  }

  private setCurrentUserName(): void {
    this.store.pipe(select(fromSelectors.getUserName), take(1)).subscribe((userName: string) => {
      this.userName = userName;
    });
  }

  private getVisibleColumnsNotes(schema: any): Observable<string[]> {
    const schemaDatatable = TabsUtils.getSChemaPropsToArray(schema);

    return this.store.pipe(
      select(fromRoot.getEditMode),
      withLatestFrom(this.store.pipe(select(fromRoot.getRouterSection))),
      map(([editMode, section]) => {
        if (editMode || section === 'agreements') {
          return schemaDatatable;
        } else {
          return TabsUtils.getSchemaFiltered(schemaDatatable, ['editBtn']);
        }
      }),
    );
  }
}
