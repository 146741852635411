import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseWidgetModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { IceMaterialModule } from '@ice/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { TreeChartComponent } from './tree-chart.component';

@NgModule({
  declarations: [TreeChartComponent],
  imports: [CommonModule, TranslateModule, FuseSharedModule, FlexLayoutModule, FuseWidgetModule, IceMaterialModule],
  exports: [TreeChartComponent],
})
export class TreeChartModule {}
