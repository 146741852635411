import { Injectable } from '@angular/core';
import { last } from 'lodash';
import moment from 'moment/moment';
import { ExpertSearchParseService } from './expert-search-parse.service';

export enum RepertoirType {
  PUBLISHER = 'PUBLISHER',
  SOCIETY = 'SOCIETY',
  WORK = 'WORK',
}

export enum SocietyRightsType {
  MR = 'MR',
  PR = 'PR',
}

export enum PublisherRoleType {
  COLLECTOR = 'COLLECTOR',
  CONTRACTOR = 'CONTRACTOR',
}

@Injectable()
export class ExpertSearchParseRepertoiresService extends ExpertSearchParseService {
  constructor() {
    const AttributesMapRepertoires = {
      repertoireDescription: 'attributes.name',
      repertoireKey: async (comparator: string, value: string, _deep = false) => ({
        [comparator]: { 'relations[XREF].otherId': `${value.includes(':') ? value.split(':')[0].replace('*', '') : 'ICE'}:${last(value.split(':'))}` },
      }),
      repertoireStartDate: async (comparator: string, value: string, _deep = false) => ({
        equals: { 'attributes.startDate': moment(value).format('YYYY-MM-DD') },
      }),
      repertoireEndDate: async (comparator: string, value: string, _deep = false) => ({
        equals: { 'attributes.endDate': moment(value).format('YYYY-MM-DD') },
      }),
      validFrom: async (comparator: string, value: string, _deep = false) => ({
        equals: { 'attributes.startDate': moment(value).format('YYYY-MM-DD') },
      }),
      validTo: async (comparator: string, value: string, _deep = false) => ({
        equals: { 'attributes.endDate': moment(value).format('YYYY-MM-DD') },
      }),
      repertoireType: 'attributes.type',
      societyRights: 'societies.rights',
      societyId: 'societies.societyId',
      linkedWorkKey: 'works.work.relations[XREF].otherId',
      publisherName: 'parties.party.partyNames.partyName.attributes.name',
      publisherIpiNameNumber: 'parties.party.partyNames.partyName.relations[XREF].otherId',
      publisherIpiBaseNumber: 'parties.party.relations[XREF].otherId',
      publisherRole: 'parties.relation',
      repertoireContractorNameId: async (comparator: string, value: string, _deep = false) => ({
        and: [
          { equals: { 'parties.relation': PublisherRoleType.CONTRACTOR.toLowerCase() } },
          { [comparator]: { 'parties.party.partyNames.partyName.relations[XREF].otherId': value } },
        ],
      }),
      repertoireCollectorNameId: async (comparator: string, value: string, _deep = false) => ({
        and: [
          { equals: { 'parties.relation': PublisherRoleType.COLLECTOR.toLowerCase() } },
          { [comparator]: { 'parties.party.partyNames.partyName.relations[XREF].otherId': value } },
        ],
      }),
      repertoireContractorBaseId: async (comparator: string, value: string, _deep = false) => ({
        and: [{ equals: { 'parties.relation': PublisherRoleType.CONTRACTOR.toLowerCase() } }, { [comparator]: { 'parties.party.relations[XREF].otherId': value } }],
      }),
      repertoireCollectorBaseId: async (comparator: string, value: string, _deep = false) => ({
        and: [{ equals: { 'parties.relation': PublisherRoleType.COLLECTOR.toLowerCase() } }, { [comparator]: { 'parties.party.relations[XREF].otherId': value } }],
      }),
      repertoirePRSociety: async (comparator: string, value: string, _deep = false) => ({
        and: [{ equals: { [`societies[*:${value}*].rights`]: SocietyRightsType.PR } }],
      }),
      repertoireMRSociety: async (comparator: string, value: string, _deep = false) => ({
        and: [{ equals: { [`societies[*:${value}*].rights`]: SocietyRightsType.MR } }],
      }),
      repertoireActive: 'attributes.active',
      unpublishedWriterShares: 'attributes.unpublishedWriterShares',
    };
    const attributesEnumMapRepertoires = {
      ['repertoireType']: Object.keys(RepertoirType),
      ['societyRights']: Object.keys(SocietyRightsType),
      ['publisherRole']: Object.keys(PublisherRoleType),
    };
    super(attributesEnumMapRepertoires, AttributesMapRepertoires);
  }
}
