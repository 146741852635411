import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { RepertoireUtils } from '@ice';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import { IPI } from 'config/constants/ips.constants';
import { SelectionDatatableBuilder } from 'config/data-table-builders/selection-datatable-builder';
import { SectionsConfig } from 'config/sections-config';
import { get, isEmpty } from 'lodash';
import { of } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { PermissionsService } from 'services/permissions/permissions.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';
import { HttpClient } from '@angular/common/http';
import { SectionTabConfig } from '../../tabs-data';
import { DialogAddContractingCompany } from '../../../dialog-builders/dialog-add-contracting-company';
import { RepertoireEditInline } from './shared/edit';
import { PartiesSharedSchemas } from './shared/parties';

export class TabRepertoireContractingCompanies implements SectionTabConfig {
  private dialogAddContractingCompanies: DialogAddContractingCompany;
  private repertoireRequiredFields: any;
  private dialogRef: MatDialogRef<any, any>;
  private currentIds = [];
  private selectionDatatable: SelectionDatatableBuilder;

  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private dialog: MatDialog,
    private commonApiService: null,
    private detailService: null,
    private nsService: null,
    private iceFacade: null,
    private fieldValidatorService: FieldValidatorService,
    protected permissionsService: PermissionsService,
    private http: HttpClient,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);

    this.dialogAddContractingCompanies = new DialogAddContractingCompany(this.translate, this.dialog, this.store, this.fieldValidatorService);
    this.store
      .select(fromRoot.getRepertoireDetail)
      .pipe(
        filter(detail => !isEmpty(detail)),
        take(1),
      )
      .subscribe((detail: any) => {
        const { id, version, attributes } = detail;
        this.repertoireRequiredFields = { id, version, attributes };
      });
    this.selectionDatatable = new SelectionDatatableBuilder(store, [
      ...PartiesSharedSchemas.getSchema(
        this.translate,
        this.dialog,
        this.store,
        this.translate.instant('REPERTOIRES.CONTRACTING_COMPANIES.TAB_TITLE'),
        this.translate.instant('REPERTOIRES.CONTRACTING_COMPANIES.DELETE_MESSAGE'),
        (row, dialogRef) => {
          const body = JSON.stringify(RepertoireUtils.getRepertoirePartyUpdateBody(this.repertoireRequiredFields, [get(row, `rawItem.partyId`)], 'CONTRACTOR'));
          RepertoireEditInline.updateReportoireWithMode(
            this.store,
            dialogRef,
            this.translate,
            this.repertoireRequiredFields.id,
            body,
            'DELETE',
            get(row, `rawItem.partyId`),
            this.currentIds,
          );
        },
        !this.permissionsService.can(`repertoires_edit`),
      ),
    ]);
  }

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('REPERTOIRES.CONTRACTING_COMPANIES.TAB_TITLE'),
              model: this.store.pipe(
                select(fromRoot.getRepertoireContractingCompanies),
                tap(rows => (this.currentIds = rows?.map(row => get(row, `rawItem.partyId`)))),
              ),
              sorts: [{ prop: 'name', dir: 'asc' }],
              ...{
                ...this.selectionDatatable.getSelectionDatatableConfig(),
                onSelect: event => {
                  this.store.dispatch(
                    new fromRoot.Go({
                      path: [`copyright/${SectionsConfig.IPS.name}/${IPI}:${event[0]?.ipiNameNumber}`],
                    }),
                  );
                },
              },
              loadingIndicator: false,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: true,
              shadowed: false,
              columnMode: 'flex',
              onMouseSelect: event => {
                this.store.dispatch(
                  new fromRoot.OpenNewTab({
                    path: [`copyright/${SectionsConfig.IPS.name}/${IPI}:${event.ipiNameNumber}`],
                  }),
                );
              },
              getRowClass: (row: any): any => ({ 'ice-search-results-table-row': true }),
              actionButtons: of(
                this.permissionsService.can(`repertoires_edit`)
                  ? [
                      {
                        icon: 'add',
                        tooltip: `${this.translate.instant('REPERTOIRES.COMMON.ADD')} ${this.translate.instant('REPERTOIRES.CONTRACTING_COMPANIES.TAB_TITLE')}`,
                        class: 'mat-white-icon',
                        onClick: () =>
                          (this.dialogRef = this.dialogAddContractingCompanies.openDialog(partyIds => {
                            const body = JSON.stringify(RepertoireUtils.getRepertoirePartyUpdateBody(this.repertoireRequiredFields, partyIds, 'CONTRACTOR'));
                            RepertoireEditInline.updateReportoireWithMode(
                              this.store,
                              this.dialogRef,
                              this.translate,
                              this.repertoireRequiredFields.id,
                              body,
                              'MERGE',
                              partyIds,
                              this.currentIds,
                              this.http,
                            );
                          })),
                      },
                    ]
                  : [],
              ),
            },
          },
        ],
      },
    ];
  }
}
