import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SectionsConfig } from 'config/sections-config';
import { ExpandableData } from 'config/tabs-data-builders/shared/audit-history.model';
import { TabAuditHistory } from 'config/tabs-data-builders/shared/tab-audit-history';
import { IceFacade } from 'facades/ice.facade';
import { map } from 'rxjs/operators';
import { CommonApiService } from 'services/common-api.service';
import { DetailService } from 'services/detail/detail.service';
import { NamespaceService } from 'services/namespace/namespace.service';
import { PermissionsService } from 'services/permissions/permissions.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';

export class TabIpsAuditHistory extends TabAuditHistory {
  constructor(
    protected translate: TranslateService,
    protected fuseTranslationLoader: FuseTranslationLoaderService,
    protected store: Store<fromRoot.RootState>,
    protected dialog: MatDialog,
    protected commonApiService: CommonApiService,
    protected detailService: DetailService,
    protected nsService: NamespaceService,
    private iceFacade: IceFacade,
    private fieldValidatorService: FieldValidatorService,
    protected permissionsService: PermissionsService,
  ) {
    super(translate, fuseTranslationLoader, store, dialog, permissionsService);
    this.auditTypes = SectionsConfig.IPS.auditTypes;
    this.addDetailsOnMainDataTable = true;
  }

  protected getTableOneModel() {
    return this.store.pipe(
      select(fromRoot.getIpAuditHistory),
      map(auditEvents => auditEvents),
    );
  }

  protected getAuditType() {
    return 'party-name';
  }

  protected onSelectRow(row: any[]) {}

  protected onSelectExpandable(expandableData: ExpandableData) {}
}
