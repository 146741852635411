<ng-container *ngIf="config">
  <mat-card [ngClass]="config.formClass">
    <mat-card-header class="mat-accent-bg card-expanded-header">
      <mat-card-title [ngClass]="config.titleClass || ''">{{ config.title }}</mat-card-title>
      <ice-form
        fxFlex="100"
        *ngIf="config.filter"
        [resetAvailable]="config.filter.resetAvailable"
        [submitAvailable]="config.filter.submitAvailable"
        [formBuilder]="config.filter.formBuilder"
        [model]="config.filter.model"
        [submitLabel]="config.filter.submitLabel"
        [forceSubmitDisabled]="config.filter.forceSubmitDisabled && (config.filter.forceSubmitDisabled[selectedType] || config.filter.forceSubmitDisabled['default'])"
        [submitShortcutEnable]="config.submitShortcutEnable"
        (submit)="config.filter.onSubmit($event)"
      ></ice-form>
    </mat-card-header>
    <mat-card-content class="ice-p-12" [ngStyle]="{ height: config.height || 'auto' }">
      <ice-form
        [formBuilder]="config.formBuilder"
        [model]="config.model | async"
        [submitLabel]="config.submitLabel"
        [button1Label]="config.buttonLabel"
        [resetAvailable]="config.resetAvailable"
        [submitAvailable]="config.submitAvailable"
        [button1Available]="config.button1Available"
        [submitShortcutEnable]="config.submitShortcutEnable"
        (submit)="config.onSubmit($event)"
        (button1Clicked)="config.onButtonClicked($event)"
      ></ice-form>
    </mat-card-content>
  </mat-card>
</ng-container>
