// TODO - refactor //

import { CaseClaimant, CopyrightOverride } from '@ice';
import { ApiNamespace, CurrentOrganization } from 'models';

export interface Copyright {
  id?: string;
  version?: number;
  tags?: any;
  ns?: ApiNamespace;
  visibleTabs?: Object;
  notes?: any;
  permissions?: FieldPermissions;
  eventTypes?: eventTypeOption[];
  eventDescriptions?: string[];
  auditTypes?: string[];
  lastAuditFilterQuery?: string;
}

export type CopyrightDetail = Pick<
  Copyright,
  'id' | 'version' | 'visibleTabs' | 'notes' | 'permissions' | 'eventTypes' | 'eventDescriptions' | 'auditTypes' | 'lastAuditFilterQuery'
>;
export type CopyrightTerritoryDetail = Pick<CopyrightDetail, 'version'>;

export interface CopyrightDetailRequest {
  ns?: ApiNamespace;
  key?: string;
  id?: string;
  section?: string;
  routerPaths?: string[];
  customInclude?: string;
  currentOrganization?: CurrentOrganization;
}

export interface CopyrightOwnershipTotals {
  totalPR?: string;
  totalMR?: string;
  writerSubTotalMR?: string;
  writerSubTotalPR?: string;
  publisherSubTotalPR: string;
  publisherSubTotalMR: string;
  specialSubTotalPR: string;
  specialSubTotalMR: string;
}

export interface WorkClause {
  type: string;
  ipiNumber: string;
  ipiName: string;
  name: string;
  partyNameId: string;
  clauseType: string;
}

export interface CopyrightOwnershipTableItem {
  contributorId?: string;
  parentId?: string;
  roleRaw?: string;
  name?: string;
  ref?: string;
  ip?: string;
  key?: string;
  partyNameId?: string;
  society?: string;
  prSociety?: string;
  mrSociety?: string;
  level?: number;
  pr?: string;
  mr?: string;
  prTooltip?: string;
  mrTooltip?: string;
  unauthorised?: string;
  ipiNameNumber?: string;
  ipiNameKey?: string;
  ipiBaseNumber?: string;
  ipiBaseKey?: string;
  refLabel?: string;
  prMr?: string;
  role?: string;
  copyrightOverrides?: CopyrightOverride[];
  typeOf?: string;
  hasChildren?: boolean;
  hasNotResolvedCounterclaim?: boolean;
}

export interface FieldPermissions {
  writable?: { field: string; value: boolean }[];
}

export interface ClaimCaseItem {
  claimCaseId: string;
  claimCase: ClaimCase;
  conflictId: string;
}
export interface ClaimCase {
  id: string;
  conflictId: string;
  endDate: string;
  startDate: string;
  status: string;
  territories: string[];
  type: string[];
  rights: string[];
  attributes: any;
  claimants: CaseClaimant[];
}

export enum SimpleDatatableValueType {
  BOOLEAN = 'boolean',
  NUMBER = 'number',
  STRING = 'string',
}

export interface SimpleDatatableValue {
  label: string;
  valueType?: SimpleDatatableValueType;
  customValueParser?: (value) => string;
  tooltipParser?: (value) => string;
  icons?: any;
  rowClass?: string;
}

export interface SimpleDatatableLayout {
  [key: string]: SimpleDatatableValue;
}
export interface eventTypeOption {
  value: string;
  label: string;
}

export enum InputTagName {
  BUTTON = 'BUTTON',
  INPUT = 'INPUT',
  MAT_CHECKBOX = 'MAT-CHECKBOX',
  MAT_SELECT = 'MAT-SELECT',
}

export enum InputQuerySelector {
  A = 'a',
  BUTTON = 'button',
  CDK_VISUALLY_HIDDEN = 'cdk-visually-hidden',
  EXTRA_BUTTON = 'extraButton',
  INPUT = 'input',
  ICE_FORM = 'ice-form',
  ICE_STEPPER = 'ice-stepper',
  MAT_DIALOG_CONTAINER = 'mat-dialog-container',
  MAT_FOCUS_INDICATOR = 'mat-focus-indicator',
  MAT_CHECKBOX = 'mat-checkbox',
  MAT_CHECKBOX_INNER_CONTAINER = 'mat-checkbox-inner-container',
  MAT_CHECKBOX_INPUT = 'mat-checkbox-input',
  MAT_ICON_BUTTON = 'mat-icon-button',
  MAT_RADIO = 'mat-radio',
  MAT_RADIO_INPUT = 'mat-radio-input',
  MAT_SELECT = 'mat-select',
}

export const CDK_PANE_SELECTOR = '.cdk-overlay-pane';
