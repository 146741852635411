export const locale = {
  lang: 'en',
  data: {
    WORKS: {
      TITLE: 'Title',
      NORMALIZED: 'Compressed Search',
      XREF: 'Work XRef',
      CREATOR: 'Creator',
      CREATOR_ID: 'IP Number',
      PUBLISHER: 'Publisher Name',
      PUBLISHER_ID: 'Publisher Id',
      ARTIST_NAME: 'Artist Name',
      IP_NAME_KEY: 'IP Name key',
      TEXT: 'Text',
      AGREEMENT_REFERENCE: 'Agreement Reference Number',
      WORK_TYPE: 'Work Type',
      PURPOSE: 'Intended Purpose',
      ACTIVITY_ICON: 'Active',
      CONFLICT: 'Conflict',
      CONFLICT_TOOLTIP: 'Work conflict exists',
      CONFLICT_NUMBER_TOOLTIP: 'Number of conflicts',
      PURPOSES: {
        AUD: 'Audio',
        COM: 'Commercial',
        FIL: 'Film',
        GEN: 'General',
        LIB: 'Library',
        MUL: 'Multimedia',
        RAD: 'Radio',
        TEL: 'Television',
        THR: 'Theatre',
        VID: 'Video',
        FIB: 'Film - Background',
        FLL: 'Film - Logo',
        FIT: 'Film - Theme',
        FIV: 'Film - Visual',
        FIR: 'Film - Rolled Up',
        TEB: 'Television - Background',
        TLL: 'Television - Logo',
        TET: 'Television - Theme',
        TEV: 'Television - Visual',
        TER: 'Television - Rolled Up',
      },
      TYPE_ORIGIN: {
        ORI: 'Original',
        MOD: 'Modification/Version',
      },
      ERRORS: {
        INVALID_WORKS_XREF: 'Invalid works reference',
      },
    },
    AGREEMENTS: {
      AGREEMENT_NAME: 'Agreement Name',
      ASSIGNOR_ID: 'Assignor Id',
      ASSIGNOR_NAME: 'Assignor Name',
      ASSIGNEE_ID: 'Assignee Id',
      ASSIGNEE_NAME: 'Assignee Name',
      REFERENCE: 'Agreement Reference',
      REFERENCE_INFO: 'Use the format "Prefix:Reference number" to filter by Society or Publisher prefix',
      ASSIGNOR_INFO: 'IP name key or IPI name number',
      ASSIGNEE_INFO: 'IP name key or IPI name number',
      ASSIGNOR: 'Assignor Name or IP',
      ASSIGNEE: 'Assignee Name or IP',
      TERRITORIES: 'Territory',
      TERRITORIES_MATCH: {
        TITLE: 'Territory Match Type',
        EXCLUDE: 'Exclude',
        INCLUDE: 'Include',
      },
      START_DATE: 'Start Date',
      AGREEMENT_TYPE: { TITLE: 'Agreement Type', GENERAL: 'General', SPECIFIC: 'Specific' },
      SOURCE: 'Source',
      INCLUDE_TERMINATED: 'Include terminated agreements',
      GROUP_ID: 'Agreement Group Id',
      GROUP_ID_SEARCH_TYPE: {
        TITLE: 'Group Id Search Type',
        GROUP: 'Group',
        RECIPIENT: 'Recipient',
        BOTH: 'Both',
      },
      ERRORS: {
        INVALID_TERRITORIES: 'Invalid territory',
        DISTRIBUTION_DATE_AFTER_USAGE_DATE: 'Distribution Date must be after Usage Date',
        DATE_AFTER_1999_12_31: 'Date must be after 1999-12-31',
        INVALID_AGREEMENT_XREF: 'Invalid agreement reference',
        LEGACY_AGREEMENT_CHANGE: 'Legacy agreement {{searchedXref}} replaced with {{correctXref}}',
      },
    },
    IPS: {
      ID: 'IP Base Number',
      TYPE_OF: 'IP Base Type',
      TYPE_OF_IPI_BASE_TYPE: 'IPI Base Type',
      LEGAL_ENTITY: 'Legal Entity',
      NATURAL_PERSON: 'Natural Person',
      VALUES_ALL: 'All',
      TYPE_OF_NAME: 'IPI Name Type',
      TYPE_OF_NAME_OPTIONS: {
        DF: 'Different Spelling (DF)',
        HR: 'Holding Reference (HR)',
        MO: 'Modification (MO)',
        OR: 'Other Reference (OR)',
        PA: 'Patronym (PA)',
        PG: 'Pseudonym Group (PG)',
        PP: 'Pseudonym of Individuals (PP)',
        ST: 'Standardised Spelling (ST)',
      },
      TYPE_OF_NAME_TOOLTIPS: {
        DF: 'Different Spelling',
        HR: 'Holding Reference',
        MO: 'Modification',
        OR: 'Other Reference',
        PA: 'Patronym',
        PG: 'Pseudonym Group',
        PP: 'Pseudonym of Individuals',
        ST: 'Standardised Spelling',
      },
      FIRST_NAME: 'First Name',
      LAST_NAME: 'Name',
      SELECT_ALL: 'All',
      XREF_PREFIX: {
        NAME: 'IPI Name Cross Reference',
        XREF: 'Cross Reference',
        SOCIETY_REFERENCE: 'Society Reference',
        SOCIETY: 'Society',
        PUBLISHER: 'Publisher',
        IPI_NAME: 'IPI Name Number',
        IP_NAME_KEY: 'IP Name Key',
        IP_NAME_TYPE: 'IP Name Type',
        IPI_NAME_TYPE: 'IPI Name Type',
        IP_NAME_REFERENCE_TYPE: 'Reference Type',
        IP_NAME_REREFENCE: 'Reference',
      },
      IPI_BASE_TYPE: 'IPI Base Type',
      IPI_BASE_NUMBER_OR_KEY: { NAME: 'IPI Base Number or ICE IP Base Key', IPI_BASE_NUMBER: 'IPI Base Number', ICE_IP_BASE_KEY: 'ICE IP Base Key', IPI_BASE_KEY: 'IP Base Key' },
      IP_BASE_STATUS: { ACTIVE: 'Active', INACTIVE: 'Inactive' },
      INCLUDE_INACTIVE: 'Include inactive IPs',
      ADD_BY_BASE: 'Add By Base',
      ERRORS: {
        INVALID_IP_NUMBER: 'Invalid IP number',
        INVALID_IP_NAME_KEY: 'Invalid IP name key',
      },
    },
    REPERTOIRES: {
      NAME: 'Repertoire description',
      XREF: 'Repertoire key',
      VALID_FROM: 'Valid From (YYYY-MM-DD)',
      VALID_TO: 'Valid To (YYYY-MM-DD)',
      TYPE: {
        NAME: 'Repertoire type',
        PUBLISHER: 'Publisher',
        SOCIETY: 'Society',
        WORK_LIST: 'Work List',
      },
      CREATOR_MR_SOCIETY: 'Creator MR soc',
      CREATOR_PR_SOCIETY: 'Creator PR soc',
      PUBLISHER: {
        NAME: 'Publisher Name',
        IPI_NAME_NUMBER: 'Publisher IPI Name Number',
        IPI_BASE_NUMBER: 'Publisher IPI Base Number',
      },
      PUBLISHER_ROLE: {
        NAME: 'Publisher Role',
        BOTH: 'Both',
        COLLECTOR: 'Collector',
        CONTRACTOR: 'Contractor',
      },
      WORK_KEY: 'Linked Work Key',
      ACTIVE: 'Active',
      UNPUBLISHED_WRITER_SHARES: 'Unpublished Writer Shares',
    },
    SOCIETIES: {
      SOCIETY_NAME: 'Society Name',
      SOCIETY_CODE: 'Society Code',
      COUNTRY_CODE: 'TISN',
      COUNTRY_NAME: 'Countries',
      IS_ICE: 'Is ICE',
    },
    TERRITORIES: {
      ICE_KEY: 'ICE Key',
      TISA: 'TISA',
      TISN: 'TISN',
      TISA_EXT: 'Extended TISA',
      NAME: 'Territory name',
      OFFICIAL_NAME: 'Official Name',
      UNOFFICIAL_NAME: 'Unofficial Name',
      CREATION_DATE: 'Creation Date',
      INCLUDED_COUNTRIES: 'Included countries',
      EXCLUDED_COUNTRIES: 'Excluded countries',
    },
    ACTIVITY: {
      SOCIETY: 'Society',
      DEADLINE_REACHED: 'Deadline has been reached',
      DEADLINE_DATE: 'Deadline date',
      DUE_DATE_FROM: 'Due date from',
      DUE_DATE_TO: 'Due date to',
      WORK_KEY: 'Work Key',
      VALUE: 'Value',
      VALUE_FROM: 'Value From',
      VALUE_TO: 'Value To',
      ASSIGNEE: 'Assignee',
      ASSIGNED_USER: 'Assigned User',
      CASE_TYPE: 'Case Type',
      CONFLICT_SUB_TYPE: 'Conflict Sub Type',
      PROCESS_DESCRIPTION: 'Process Description',
      WORK_ID: 'Work ID',
      AGREEMENT_ID: 'Agreement ID',
      PARTYNAME: 'Partyname',
      CONFLICT_DATA: 'Conflict Data',
      CASE_ID: ' Case ID',
      CASE_STATUS: 'Case Status',
      COUNTERCLAIM_ID: 'CounterClaim ID',
      RESOLUTION_OWNERS: 'Resolution Owner',
      COUNTER_CLAIM_CREATION_FROM: 'CounterClaim creation from',
      COUNTER_CLAIM_CREATION_TO: 'CounterClaim creation to',
      COUNTER_CLAIM_RESOLUTION_FROM: 'CounterClaim resolution from',
      COUNTER_CLAIM_RESOLUTION_TO: 'CounterClaim resolution to',
      COUNTER_CLAIM_PARTY: 'CounterClaim party',
      COUNTER_CLAIM_RELATION_TYPE: 'CounterClaim relation type',
      ACTION_DEADLINE_FROM: 'Action deadline from',
      ACTION_DEADLINE_TO: 'Action deadline to',
      ACTION_TYPE: 'Action Type',
      ACTION_STATUS: 'Action Status',
      ACTION_PARTICIPANT: 'Action Participant',
      ACTION_PARTICIPANT_INFO: 'Enter the IPI name number',
      ACTION_OWNER: 'Action Owner',
      ACTION_OWNER_INFO: 'Enter ICE or an IPI Name Number',
      ACTION_USER: 'Action User',
      ACTION_USER_INFO: 'Enter the username of the assigned user',
      ACTION_RESPONSE: 'Action Response',
      COUNTERCLAIM_STATUS: 'CounterClaim Status',
      COUNTERCLAIM_TYPE: 'CounterClaim Type',
      CONFLICT_STATUS: 'Conflict Status',
      WORK_TITLE: 'Work Title',
      IPI_BASE_NUMBER: 'Publisher IPI Base Number',
      CREATOR: 'Creator',
      CUSTOMER: 'Customer',
      DEADLINE_DATE_FROM: 'Deadline Date From',
      DEADLINE_DATE_TO: 'Deadline Date To',
      DISTRIBUTION_CATEGORY: 'Distribution Category',
      UPLOAD_CATEGORY: 'Upload Category',
      UPLOAD_CATEGORY_TYPE: {
        '023O': 'Buma Stemra Online',
        '023P': 'Buma Performing',
        '040': 'KODA Performing',
        '040L': 'KODA On-line',
        '040M': 'KODA Mechanical',
        '040O': 'STIM - KODA Online',
        '040P': 'STIM - KODA Performing',
        '044M': 'MCPS Mechanical',
        '048M': 'NCB Mechanical',
        '048O': 'NCB Online',
        '052C': 'PRS Cue Sheet',
        '052J': 'PRS/MCPS Joint',
        '052O': 'PRS/MCPS Online',
        '052P': 'PRS Performing',
        '055J': 'SABAM Joint',
        '055M': 'SABAM Mechanical',
        '055O': 'SABAM On-line',
        '055P': 'SABAM Performing',
        '077P': 'STEF Performing',
        '078M': 'Stemra mechanical',
        '079K': 'STIM PEL Kobalt',
        '079O': 'STIM Online',
        '079P': 'STIM Performing',
        '079W': 'STIM PEL Warner',
        '089': 'TEOSTO Performing',
        '089L': 'TEOSTO On-line',
        '089M': 'TEOSTO Mechanical',
        '089O': 'STIM - TEOSTO Online',
        '089P': 'STIM - TEOSTO Performing',
        '090': 'TONO Performing',
        '090L': 'TONO On-line',
        '090M': 'TONO Mechanical',
        '090O': 'STIM - TONO Online',
        '090P': 'STIM - TONO Performing',
        '110P': 'LATGA-A Performing',
        '116P ': 'EAU Performing',
        '122P': 'AKKA-LAA Performing',
        '319I': 'ICE Internal',
        '800O': 'ICE Online Services',
        M40: 'KODA Perf via NCB',
        M40L: 'KODA On-Line via NCB',
        M89: 'TEOSTO Perf via NCB',
        '89L': 'TEOSTO On-Line via NCB',
        M90: 'TONO Perf via NCB',
        M90L: 'TONO On-Line via NCB',
      },
      CONFLICT_AREA: 'Conflict Area',
      CONFLICT_AREA_TYPE: {
        STN: 'Standard',
        CUE: 'Cues',
        LIB: 'Library',
        SER: 'Serious',
        ALL_CONFLICT_AREAS: 'ALL Conflict Areas',
      },
      DOMESTIC_SOCIETY: 'Domestic Society',
      STATUS_NEW: 'New',
      ALL_STATUS: 'All Status',
      STATUS_IN_PROGRESS: 'In progress',
      STATUS_RESEARCHED: 'Researched',
      AWAITING_REPLY: 'Awaiting Reply',
      CREATION_DATE: 'Creation Date',
      UNASSIGNED: 'Unassigned',
      SOCIETIES_CATEGORIES: {
        BUMA_STEMRA_ONLINE: '023O - Buma Stemra Online',
        BUMA_PERFORMING: '023P - Buma Performing',
        KODA_PERFORMING: '040 - KODA Performing',
        KODA_ON_LINE: '040L - KODA On-line',
        KODA_MECHANICAL: '040M - KODA Mechanical',
        STIM_KODA_ONLINE: '040O - STIM - KODA Online',
        STIM_KODA_PERFORMING: '040P - STIM - KODA Performing',
        MCPS_MECHANICAL: '044M - MCPS Mechanical',
        NCB_MECHANICAL: '048M - NCB Mechanical',
        NCB_ONLINE: '048O - NCB Online',
        PRS_CUE_SHEET: '052C - PRS Cue Sheet',
        PRS_MCPS_JOINT: '052J - PRS/MCPS Joint',
        PRS_MCPS_ONLINE: '052O - PRS/MCPS Online',
        PRS_PERFORMING: '052P - PRS Performing',
        SABAM_JOINT: '055J - SABAM Joint',
        SABAM_MECHANICAL: '055M - SABAM Mechanical',
        SABAM_ON_LINE: '055O - SABAM On-line',
        SABAM_PERFORMING: '055P - SABAM Performing',
        STEF_PERFORMING: '077P - STEF Performing',
        STEMRA_MECHANICAL: '078M - Stemra mechanical',
        STIM_PEL_KOBALT: '079K - STIM PEL Kobalt',
        STIM_ONLINE: '079O - STIM Online',
        STIM_PERFORMING: '079P - STIM Performing',
        STIM_PEL_WARNER: '079W - STIM PEL Warner',
        TEOSTO_PERFORMING: '089 - TEOSTO Performing',
        TEOSTO_ON_LINE: '089L - TEOSTO On-line',
        TEOSTO_MECHANICAL: '089M - TEOSTO Mechanical',
        STIM_TEOSTO_ONLINE: '089O - STIM - TEOSTO Online',
        STIM_TEOSTO_PERFORMING: '089P - STIM - TEOSTO Performing',
        TONO_PERFORMING: '090 - TONO Performing',
        TONO_ON_LINE: '090L - TONO On-line',
        TONO_MECHANICAL: '090M - TONO Mechanical',
        STIM_TONO_ONLINE: '090O - STIM - TONO Online',
        STIM_TONO_PERFORMING: '090P - STIM - TONO Performing',
        LATGA_A_PERFORMING: '110P - LATGA-A Performing',
        EAU_PERFORMING: '116P - EAU Performing',
        AKKA_LAA_PERFORMING: '122P - AKKA-LAA Performing',
        ICE_INTERNAL: '319I - ICE Internal',
        ICE_ONLINE_SERVICES: '800O - ICE Online Services',
        KODA_PERF_VIA_NCB: 'M40 - KODA Perf via NCB',
        KODA_ON_LINE_VIA_NCB: 'M40L - KODA On-Line via NCB',
        TEOSTO_PERF_VIA_NCB: 'M89 - TEOSTO Perf via NCB',
        TEOSTO_ON_LINE_VIA_NCB: '89L - TEOSTO On-Line via NCB',
        TONO_PERF_VIA_NCB: 'M90 - TONO Perf via NCB',
        TONO_ON_LINE_VIA_NCB: 'M90L - TONO On-Line via NCB',
      },
      DOMESTIC_SOCIETIES: {
        NORDIC_DOMESTIC: 'Nordic Domestic',
        BUMA_DOMESTIC: 'BUMA Domestic',
        STANDAR_DOMESTIC: 'Standard',
        NS: 'Non-society (NS)',
      },
      CONFLICT_TYPE: 'Conflict Type',
      CONFLICT_TYPES: {
        ALL_CONFLICT_TYPES: 'ALL Conflict Types',
        MERGE: 'Merge',
        MTCH: 'Match',
        DATA: 'Data',
      },
      ALL_CONFLICT_SUB_TYPES: 'ALL Conflict Sub Types',
      ALL_SOCIETIES: 'ALL Societies',
      EXCLUDE_CONTERCLAIMS: 'Exclude overclaims with counterclaims',
      EXCLUDE_CONTERCLAIMS_INFO:
        'When selected the returned results will exclude any Overclaim conflicts where there is already a corresponding counterclaim(s) associated with the work',
      PRIORITY_INDICATOR: 'Exclude non-priority conflicts',
      PRIORITY_INDICATOR_INFO: 'Excludes match and merge conflicts where the related works are not matching the same WSM as the reporting society of the activity trigger',
    },
    ORGANIZATIONS: {
      NAME: 'Organization Name',
      ID: 'Organization ID',
      TYPE: 'Organization Type',
      VALUES_ALL: 'All',
      PUBLISHER: 'Publisher',
      SOCIETY: 'Society',
      ICE: 'ICE',
      STAGE: 'STAGE',
    },
    USERS: {
      ID: 'Username',
      FIRST_NAME: 'First Name',
      LAST_NAME: 'Last Name',
      EMAIL: 'Email',
      ROLE: 'Role',
      ORGANIZATION: 'Organization',
      ASSOCIATED_ORGANIZATION: 'Associated Organization',
      SHOW_INACTIVE: 'Show inactive users',
      ORGANIZATION_DOESNT_EXIST: 'Organization does not exist',
      ORGANIZATION_ID: `ORGANIZATION ID's`,
    },
    COMMON: {
      REFERENCE_INFO: 'Use the formats "Prefix:Reference number" or "Reference number"',
      CREATOR_INFO: 'Comma separated names or first names. E.g. "Smith, Johnson, Brown, John"',

      ERRORS: {
        INVALID_REFERENCE: 'Invalid reference',
      },
    },
    REPORTS: {
      ID: 'Report ID',
      REQUESTED_BY: 'Requested By',
      REPORT_NAME: 'Report Name',
      REPORT_NAME_OPTIONS_ALL: 'All Report Types',
    },
  },
};
