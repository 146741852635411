import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DataTableRow } from '@ice/components/data-table/data-table';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import { SectionsConfig } from 'config/sections-config';
import { SectionTabConfig } from 'config/tabs-data-builders/tabs-data';
import { NamespaceService } from 'services/namespace/namespace.service';
import * as fromRoot from 'store/root';

export class TabCounterClaimsOtherCounterClaims implements SectionTabConfig {
  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    dialog: null,
    commonApiService: null,
    detailService: null,
    private nsService: NamespaceService,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('COUNTER_CLAIMS.OTHER_COUNTER_CLAIMS.TAB_TITLE'),
              model: this.store.pipe(select(fromRoot.getCounterClaimOtherCounterClaims)),
              dontStrip: true,
              sorts: [],
              schema: this.getDataTable(),
              onSelect: event =>
                this.store.dispatch(
                  new fromRoot.Go({
                    path: [`${SectionsConfig.COUNTERCLAIMS.domainName}/${SectionsConfig.COUNTERCLAIMS.name}/${event[0].id}`],
                  }),
                ),
              onMouseSelect: event =>
                this.store.dispatch(
                  new fromRoot.OpenNewTab({
                    path: [`${SectionsConfig.COUNTERCLAIMS.domainName}/${SectionsConfig.COUNTERCLAIMS.name}/${event.id}`],
                  }),
                ),
              loadingIndicator: false,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: true,
              shadowed: false,
              columnMode: 'flex',
            },
          },
        ],
      },
    ];
  }

  getDataTable(): DataTableRow[] {
    return [
      { name: this.translate.instant('COUNTER_CLAIMS.COUNTERCLAIM_ID'), prop: 'id', flexGrow: 2, headerTooltip: ' ' },
      { name: this.translate.instant('COUNTER_CLAIMS.TYPE'), prop: 'type', flexGrow: 1.5, headerTooltip: ' ' },
      { name: this.translate.instant('COUNTER_CLAIMS.POINT_OF_CONFLICT'), prop: 'pointOfConflict', flexGrow: 1.5, tooltip: 'pointOfConflict', headerTooltip: ' ' },
      { name: this.translate.instant('COUNTER_CLAIMS.CREATION_DATE'), prop: 'createdDate', flexGrow: 1.5, headerTooltip: ' ' },
      { name: this.translate.instant('COUNTER_CLAIMS.RESOLUTION_OWNER'), prop: 'resolutionOwner', flexGrow: 1.5, headerTooltip: ' ' },
      { name: this.translate.instant('COUNTER_CLAIMS.STATUS'), prop: 'status', flexGrow: 2, headerTooltip: ' ' },
      {
        name: this.translate.instant('COUNTER_CLAIMS.TERRITORY_SCOPE'),
        prop: 'territoriesLabel',
        tooltip: 'territoriesTooltip',
        tooltipDuration: 250,
        flexGrow: 1.5,
        headerTooltip: ' ',
      },
    ];
  }
}
