import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { TableConfig } from './table.model';

@Component({
  selector: 'ice-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnDestroy {
  @Input() config: Observable<TableConfig>;
  currentConfig: TableConfig;
  sort: any;
  selectedRows: any;
  unsubscribeAll = new Subject();

  ngOnInit() {
    this.config.pipe(take(1)).subscribe(config => {
      this.sort = config.sort;
    });
    this.config.pipe(takeUntil(this.unsubscribeAll)).subscribe(config => {
      this.currentConfig = config;
    });
  }

  onRowSelect(row) {
    if (this.currentConfig.multiselect) {
      row.checked = !row.checked;
    }
    if (this.currentConfig.selectable) {
      this.currentConfig.onSelect(row);
    }
  }

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  onSort(prop) {
    const toSort = this.sort[prop];
    if (toSort && toSort.direction.length > 0) {
      this.sort[prop].direction = toSort.direction === 'asc' ? 'des' : 'asc';
      this.sort[prop].onSort(this.sort[prop].direction, prop);
    }
  }

  @HostListener('document:keydown.Enter', ['$event'])
  onEnter(event: KeyboardEvent) {
    const activeElement = document.activeElement;
    if (activeElement.classList.contains('tab-navigation')) {
      const child: any = activeElement?.firstElementChild;
      child?.click();
    }
  }
}
