import { OrganizationType } from 'config/constants/organizations.constants';

export class EditModeDialog {
  static getEditModeDatatableSchema(translate) {
    return [
      { name: translate.instant('SELECT_ORGANIZATION_POPUP.DATATABLE_FIELDS.NAME'), prop: 'value', flexGrow: 1, class: 'edit-name' },
      { name: translate.instant('SELECT_ORGANIZATION_POPUP.DATATABLE_FIELDS.CODE'), prop: 'key', flexGrow: 1 },
      { name: translate.instant('SELECT_ORGANIZATION_POPUP.DATATABLE_FIELDS.TYPE'), prop: 'type', flexGrow: 1 },
    ];
  }

  static getEditModeTypes(translate, hideICE = false, hidePublishers = false) {
    const { ice, publisher, society } = OrganizationType;
    const publisherType = hidePublishers ? [] : [{ label: translate.instant('SELECT_ORGANIZATION_POPUP.ORGANIZATION_TYPES.PUBLISHERS'), value: publisher }];
    const societiesType = [{ label: translate.instant('SELECT_ORGANIZATION_POPUP.ORGANIZATION_TYPES.SOCIETIES'), value: society }];
    const iceType = hideICE ? [] : [{ label: translate.instant('SELECT_ORGANIZATION_POPUP.ORGANIZATION_TYPES.ICE'), value: ice }];
    return [...iceType, ...publisherType, ...societiesType];
  }
}
