export const locale = {
  lang: 'en',
  data: {
    COUNTER_CLAIMS: {
      TITLE: 'Your Counterclaim Dashboard',
      ACTION_ON_ME: {
        TITLE: 'Action on me',
        REQUEST_TO_SUPPORT_CLAIM: 'Request to support claim',
        MEMBERS_TO_RESOLVE: 'Members to Resolve',
      },
      ACTION_ON_ICE: {
        TITLE: 'Action on ICE',
        WITHDRAW_CLAIM: 'Claim Withdrawn',
        CLAIM_NOT_SUPPORTED: 'Claim Not Supported',
        REDUCE_CLAIM: 'Claim Reduced',
      },
      INFORMED_PARTY: {
        TITLE: 'Informed Party',
      },
      COUNTER_CLAIM_SEARCH: {
        TITLE: 'Counterclaim List',
        SUBMIT: 'Apply filter',
      },
    },
    BULK_UPDATES: {
      TITLE: 'Bulk Updates Dashboard',
      SUBMIT: 'Apply filter',
      LIST: {
        ID: 'Bulk Update ID',
        USER_ID: 'User ID',
        TYPE: 'Bulk Update type',
        TITLE: 'Bulk Updates List',
        DATE: 'Creation Date',
        STATUS: 'Status',
        COMMENTS: 'Comments',
        NOTES: 'Notes',
        TOOLTIP_COUNT: 'This bulk update has {{count}} items',
        DOWNLOAD_CSV: 'Download CSV',
        DOWNLOAD_XLSX: 'Download XLSX',
      },
      FEEDBACK: {
        FAILED: 'Failed',
        SUCCESS: 'Delivered successfully',
        IN_PROGRESS: 'Running',
        HAS_ERRORS: 'Delivered with errors',
        CANCELLED: 'Cancelled',
        REQUESTED: 'Requested',
        MASTERING: 'Mastering',
        SKIPPED: 'Skipped',
        NOT_STARTED: 'Not started',
      },
      FORM: {
        ALL: 'All',
        BULK_UPDATE_TYPE: 'Bulk Update type',
        CREATED_FROM: 'Created From',
        CREATED_TO: 'Created To',
        FAILED: 'Failed',
        RUNNING: 'Running',
        HAS_ERRORS: 'Delivered with errors',
        STATUS: 'Status',
        SUCCESS: 'Delivered successfully',
        SWAP_IP: 'IP Swap',
        TERMINATE_IP: 'IP Termination',
        TRANSFER_IP: 'IP Transfer',
        USER_ID: 'User ID',
        PURPOSE: 'Work Purpose',
        TITLES: 'Work Titles',
        OTHER_PARTIES: 'Work Other Parties',
        WORK_XREFS: 'Work Cross References (XREF)',
        WORK_MATCHES: 'Work Matches',
        WORK_BROADCAST_INFO: 'Work Broadcast Info',
      },
    },
  },
};
