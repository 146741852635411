export enum REPORT_TYPES {
  SHARE = 'CUBE025',
  IPS_SHARES = 'CUBE001',
  WORK_DETAILS_GENERATE_FICHE = 'CUBE013',
  LIST_WORKS_HEADER = 'CUBE012',
}

export const REPORT_TYPES_TRANSLATIONS = {
  [REPORT_TYPES.SHARE]: 'REPORTS.TYPE_SHARE',
  [REPORT_TYPES.IPS_SHARES]: 'REPORTS.TYPE_IPS_SHARES',
  [REPORT_TYPES.WORK_DETAILS_GENERATE_FICHE]: 'REPORTS.TYPE_WORK_DETAILS_GENERATE_FICHE',
  [REPORT_TYPES.LIST_WORKS_HEADER]: 'REPORTS.TYPE_LIST_WORKS_HEADER',
};

export enum REPORT_STATUS {
  FINISHED = 'FINISHED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export const REPORTS_IP_SHARES_REF_NUMBER_TYPES = [
  { value: 'ICE', label: 'ICE Work Key' },
  { value: 'ALLTC', label: 'Alliance Tunecode' },
];

export const REPORT_REF_TYPE_WORK = 'refWork';
export const REPORT_REF_TYPE_AGREEMENT = 'refAgreement';
export const REPORT_REF_TYPE_IPI = 'refIPI';

export const REPORTS_REF_TYPES = [
  { value: REPORT_REF_TYPE_WORK, label: 'ICE Work Key' },
  { value: REPORT_REF_TYPE_AGREEMENT, label: 'ICE Agreement Number' },
  { value: REPORT_REF_TYPE_IPI, label: 'IPI Name Number' },
];

export const REPORTS_REPORTING_SOC = [
  { value: null, label: '' },
  { value: 'CISAC:44', label: 'PRS/MCPS' },
  { value: 'CISAC:79', label: 'STIM' },
  { value: 'CISAC:40', label: 'KODA' },
  { value: 'CISAC:89', label: 'TEOSTO' },
  { value: 'CISAC:90', label: 'TONO' },
  { value: 'CISAC:55', label: 'SABAM' },
  { value: 'CISAC:23', label: 'BUMA' },
];

export const REPORTS_PAYMENT_OWNERSHIP = 'ownership';
export const REPORTS_PAYMENT_PAYMENT = 'payment';

export const REPORT_DOWNLOAD = 'download';
export const REPORT_CLONE = 'clone';

export const DATE_FORMAT_YYYYMMDD = 'YYYYMMDD';

export const DEFAULT_START_DATE_YYYY_MM_DD = '2020-01-01';
export const DEFAULT_START_DATE_YYYYMMDD = '20200101';
