import {
  AgreementDetail,
  AgreementUtils,
  ConflictUtils,
  CopyrightUtils,
  IpBaseInformation,
  IpDetail,
  IpSociety,
  IpSocietyRelation,
  IpUtils,
  OrganizationUtils,
  RepertoireUtils,
  TerritoryDetail,
  WorkClause,
  WorkDetail,
  WorkUtils,
} from '@ice';
import { TranslateService } from '@ngx-translate/core';
import { ipsSocietiesCodes } from 'assets/ts/ips-societies';
import { societiesICE } from 'config/constants/ips.constants';
import { RELATIONTYPES } from 'config/constants/relation.constants';
import { flatten, get, intersection, isEmpty, reverse, sortBy, uniq } from 'lodash';
import { ActivityDetail, AgreementShares, ClaimCase, CopyrightOwnershipTableItem, RepertoireDetail, TitlesInterface } from 'models';
import { OrganizationDetail } from 'models/copyright/detail/organizations';
import { Membership, SocietyDetail } from 'models/copyright/detail/society';
import { PageableData } from 'models/response/cube-register.response';
import { SearchActivityParser } from 'models/search-parsers/search-activity-parser';
import moment from 'moment';
import { AgreementGroupUtils } from './agreement-group/agrement-group.utils';
import { ClaimsUtils } from './claim/claims.utils';
import { SharePictureUtils } from './claim/share-picture.utils';
import { hasItems } from './hasItems';
import { SocietiesUtils } from './societies/societies.utils';
import { TerritoryUtils } from './territory/territory.utils';
import { UsersUtils } from './users/users.utils';

export const SectionItemCleaner = {
  works: (item: WorkDetail, translate: TranslateService, queryParams: any) => {
    const {
      attributes,
      id,
      version,
      tags,
      contributions,
      claims,
      relations,
      partyNames,
      sourceWorks,
      societies,
      permissions,
      conflicts,
      activityTriggers,
      claimsCount,
      conflictsCount,
      admin,
      counterclaims,
      counterclaimsCount,
      unresolvedCounterclaimsCount,
      recordings,
      userHasInterestInWork,
    } = item;
    let titleItem: TitlesInterface;
    let title: string;
    let titleType: string;
    let key: string;
    let musicArrangement: string;
    let manuscriptData: CopyrightOwnershipTableItem[];
    let clauses: WorkClause[];
    let claimCases: ClaimCase[];
    let societyMarkers: string[];
    const ipClauses: WorkClause[] = [];

    if (attributes) {
      titleItem = WorkUtils.selectTitle(attributes.titles);
      title = titleItem ? titleItem.title : '';
      titleType = titleItem ? titleItem.type : '';
      musicArrangement = attributes.musicArrangement || '';
      key = relations && WorkUtils.selectWorkKey(relations, id);
      societyMarkers = attributes.societyMarkers
        ? uniq(
            attributes.societyMarkers.map(marker => {
              const markerSocietyIsName = ipsSocietiesCodes.find(society => society.name === marker);
              return markerSocietyIsName ? markerSocietyIsName.code : marker;
            }),
          )
        : [];
    }

    if (contributions) {
      manuscriptData = SharePictureUtils.getManuscriptData(contributions, translate);
      contributions.forEach(contribution => {
        const { partyName, partyNameId } = contribution?.contributor || {};
        if (partyName?.attributes?.clauses?.length) {
          const formattedClauses = WorkUtils.getFormattedClauses(partyName.attributes.clauses, partyName.attributes, partyName.relations, 'ip', partyNameId, translate);
          ipClauses.push(...formattedClauses);
        }
      });
    }

    if (conflicts) {
      claimCases = WorkUtils.getCounterClaimsFromWorkConflicts(conflicts);
    }

    if (partyNames) {
      clauses = WorkUtils.getWorkClauses(partyNames, translate);
    }

    if (ipClauses.length) {
      clauses.push(...ipClauses);
    }

    const detailActivityTriggers = (activityTriggers && activityTriggers.map(activityTrigger => activityTrigger.attributes)) || [];
    const orderedActivityDetailTriggers = reverse(sortBy(detailActivityTriggers, ['value']));
    return <WorkDetail>{
      id,
      version,
      attributes: { ...attributes, title, titleType, key, musicArrangement, societyMarkers },
      tags,
      contributions,
      relations,
      partyNames,
      sourceWorks,
      societies,
      admin,
      permissions,
      manuscriptData,
      claimCases,
      claims,
      claimsCount,
      conflictsCount,
      clauses,
      counterclaims,
      counterclaimsCount,
      unresolvedCounterclaimsCount,
      recordings,
      notes: get(admin, 'attributes.notes', []),
      activity: WorkUtils.formatWorkActivityTriggers(translate, activityTriggers),
      conflicts:
        conflicts &&
        SearchActivityParser.workConflictParse(
          conflicts.map(conflict => conflict.conflict),
          translate,
        ).map(conflict => ({
          ...conflict,
          detail: orderedActivityDetailTriggers,
        })),
      userHasInterestInWork: userHasInterestInWork ?? true,
    };
  },
  agreements: (item: AgreementDetail, translate: TranslateService, queryParams: any) => {
    const { id, assignor, assignee, attributes, conflicts, groups, relations } = <AgreementDetail>item;
    const { shares } = attributes;
    const sharesItem: AgreementShares = shares && shares.length > 0 && shares[0];

    let fromShares = { startDate: null, endDate: null, postTermCollectionDate: null, priorRoyaltiesDate: null };
    if (sharesItem) {
      const { startDate, endDate, postTermCollectionDate, priorRoyaltiesDate } = sharesItem;
      fromShares = { startDate, endDate, postTermCollectionDate, priorRoyaltiesDate };
    }

    const newAttributes = {
      ...attributes,
      key: IpUtils.selectIpsKey(relations, id),
      name: item && item.attributes && item.attributes.searchTerm ? item.attributes.searchTerm : '',
      startDate: fromShares.startDate,
      endDate: fromShares.endDate,
      shares: AgreementUtils.cleanAgreementShares(attributes.shares) as AgreementShares[],
      postTermCollectionDate: fromShares.postTermCollectionDate,
      priorRoyaltiesFlag: ClaimsUtils.selectPriorRoyaltiesStartDate(fromShares.startDate, fromShares.priorRoyaltiesDate),
    };
    return {
      attributes: newAttributes,
      groups,
      relations,
      conflicts,
      assignor: AgreementUtils.cleanAssignorOrAssignee(assignor, translate),
      assignee: AgreementUtils.cleanAssignorOrAssignee(assignee, translate),
      admin: item.admin,
      notes: get(item.admin, 'attributes.notes', []),
    };
  },
  'agreement-group': (item: any, translate: TranslateService, queryParams: any) => {
    const { attributes, agreements, partyName } = item;
    const ipName = (partyName && IpUtils.getNameFromParty(partyName)) || '';
    const IPINameNumber = IpUtils.getIPNameNumberWithouthPrefix(partyName);
    const groupId = attributes && attributes.id && attributes.id.replace('CUBE:', '');
    const owner = `${ipName} - ${IPINameNumber}`;
    return {
      ...item,
      attributes: { ...attributes, ipName, groupId, owner },
      includedAgreements: AgreementGroupUtils.getAgreementsByType(agreements, RELATIONTYPES.GROUP, translate),
      relatedAgreements: AgreementGroupUtils.getAgreementsByType(agreements, RELATIONTYPES.RECIPIENT, translate),
    };
  },
  'agreement-conflict': (item: any, translate: TranslateService, queryParams: any) => {
    const { actions } = item;
    if (actions && actions.length && actions.length > 0) {
      const lastAction = actions[actions.length - 1];
      const deadline = get(lastAction, 'attributes.deadline', '');
      return { ...item, deadline };
    }
    return item;
  },
  ips: (item: any, translate) => {
    const key = IpUtils.selectIpsKey(item.relations, item.id);
    const attributes = { ...item.attributes, key };
    const { relations, admin } = item;
    if (attributes && attributes.names) {
      // Add one field Id to edit Names in edit mode
      let nameId = 0;
      attributes.names.forEach(name => {
        nameId++;
        name.nameId = nameId;
      });
    }
    let societies: IpSociety[];
    let baseInformation: IpBaseInformation[];
    let partyRelationsSocieties: IpSocietyRelation[];
    let partyRelationsPublishers: IpSocietyRelation[];
    let parentRelations: any[];
    let childrenRelations: any;

    const ipBaseInfo = IpUtils.getIpBaseInfo(item, translate);
    const parties = item.parties;
    if (parties) {
      societies = IpUtils.getIpSocieties(parties, translate) || [];
      baseInformation = (!isEmpty(ipBaseInfo) && ipBaseInfo) || null;
      partyRelationsSocieties = IpUtils.getPartyRelationsSocieties(item) || [];
      partyRelationsPublishers = IpUtils.getPartyRelationsPublishers(item) || [];
      parentRelations = IpUtils.getIpRelations(parties, 'relations') || [];
      childrenRelations = IpUtils.getIpRelations(parties, 'otherRelations') || [];
    }

    const linkedWritersCount = item?.agg?.attributes?.linkedWritersCount || 0;
    const linkedPublishersCount = item?.agg?.attributes?.linkedPublishersCount || 0;
    const workClausesCount = item?.agg?.attributes?.workClausesCount || 0;
    const baseParty = item['parties'] && item['parties'][0] && item['parties'][0]['party'];

    return <IpDetail>{
      id: item.id,
      version: item.version,
      attributes,
      baseInformation,
      societies,
      parties,
      childrenRelations,
      parentRelations,
      partyRelationsSocieties,
      partyRelationsPublishers,
      admin: item.admin,
      relations: relations || [],
      linkedWritersCount,
      linkedPublishersCount,
      workClausesCount,
      notes: get(admin, 'attributes.notes', []),
      baseParty,
    };
  },
  repertoires: (item: RepertoireDetail, translate: TranslateService, queryParams: any) => {
    return RepertoireUtils.repertoireDetailCleaner(item);
  },
  societies: (item: SocietyDetail, translate: TranslateService, queryParams: any) => {
    const attributes = item && item.attributes;
    const memberships: Membership[] = attributes && attributes.memberships;

    const socCode = attributes && SocietiesUtils.getSocietyCode(attributes.id);
    const socName = socCode && SocietiesUtils.searchSocietyNameById(socCode);
    const isIce = intersection(societiesICE, [socCode]).length > 0;
    const territories: string[] = (memberships && uniq(flatten(memberships.map(m => m.territories)))) || [];

    const rights =
      (memberships &&
        flatten(
          memberships.map(membership => {
            return membership.rights.map(
              right =>
                <Object>{
                  rightCode: right,
                  rightDescription: translate.instant('RIGHTS.' + right),
                  territories: membership.territories,
                  roleCodes: membership.roles,
                  validFrom: membership.startDate,
                  validTo: membership.endDate,
                },
            );
          }),
        )) ||
      [];
    return <SocietyDetail>{
      id: item.id,
      version: item.version,
      attributes,
      socName,
      socCode,
      isIce,
      territories,
      rights,
    };
  },
  territories: (territoriesResponse: PageableData<TerritoryDetail>, translate: TranslateService, queryParams: any) => {
    const item: TerritoryDetail = TerritoryUtils.getTerritoryDetail(territoriesResponse);
    let tisa: string;
    let endDate: string;
    let hasSuccessors = false;
    let hasPredecessors = false;
    const firstTerritory = get(item, 'territories[0]');
    if (firstTerritory) {
      const correctCode = TerritoryUtils.selectTerritoryDetail(item.territories, queryParams);
      tisa = correctCode.tisa || '';
      endDate = correctCode.end || '';
      hasSuccessors = hasItems(firstTerritory.successors);
      hasPredecessors = hasItems(firstTerritory.predecessors);
    }

    return <TerritoryDetail>{
      version: item.version,
      territories: item.territories,
      territoriesList: {
        successors: hasSuccessors ? firstTerritory.successors.join('+') : '',
        successorsArray: hasSuccessors ? firstTerritory.successors : [],
        predecessors: hasPredecessors ? firstTerritory.predecessors.join('+') : '',
        predecessorsArray: hasPredecessors ? firstTerritory.predecessors : [],
        tisa,
        endDate,
      },
      hasSuccessors,
      hasPredecessors,
      byTisA: item.byTisA,
      successorsList: [],
      predecessorsList: [],
    };
  },
  activity: (item: ActivityDetail, translate: TranslateService, queryParams: any) => {
    const conflictWorkIds = item.attributes.data.conflictWorkIds;
    let optionsToCompare = get(item, 'attributes.data.conflictWorkIds', []);
    const uniqData: string[] = uniq(conflictWorkIds);
    item.attributes.data.conflictWorkIds = uniqData;
    optionsToCompare = uniqData.sort((a, b) => Number(CopyrightUtils.getKeySuffix(a)) - Number(CopyrightUtils.getKeySuffix(b))).map(value => ({ value, viewValue: value }));
    const assignee = get(item, 'case.userId', null);
    const selectedOptionToCompare = optionsToCompare[0].value;
    return <ActivityDetail>{ ...item, assignee, optionsToCompare, selectedOptionToCompare };
  },
  'counter-claims': (item: ActivityDetail, translate: TranslateService, queryParams: any) => ConflictUtils.cleanCounterClaimDetail(item),
  organizations: (item: OrganizationDetail, translate: TranslateService, queryParams: any) => {
    const { attributes, tags } = item;
    if (attributes && tags) {
      const society = OrganizationUtils.getOrganizationSociety(item);
      const societyString = OrganizationUtils.getOrganizationSocietyString(item);
      const creationDateMoment = get(tags, 'created[0]');
      const creationDate = moment.utc(creationDateMoment).local().format('YYYY-MM-DD');
      return { ...item, attributes: { ...attributes, creationDate, society, societyString }, partyNames: null, users: null };
    }
    return item;
  },
  users: (item: any, translate: TranslateService, queryParams: any) => UsersUtils.cleanUserResponse(item),
};
