import { FormlyFieldConfig } from '@ngx-formly/core';
import { SelectOptions } from '../stepper-select-options';

export class SummaryCoreAgreement {
  static getFormField(translate): FormlyFieldConfig[] {
    const options = SelectOptions.getOptions(translate);
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [{ className: 'flex-1', template: `<h2 class="ice-text-color-blue">${translate.instant('AGREEMENTS.CORE_AGREEMENT.TITLE')}</h2>` }],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1 ice-retention-agreement-label',
            key: 'ipi_name_number',
            type: 'label',
            templateOptions: { text: translate.instant('AGREEMENTS.CORE_AGREEMENT.IPI_NUMBER.NAME'), disabled: true, isSummary: true },
          },
          {
            className: 'flex-1 ice-value-mt-25 ice-txt-size-18',
            key: 'type_of_agreement',
            type: 'select',
            templateOptions: {
              label: translate.instant('AGREEMENTS.CORE_AGREEMENT.TYPE_OF_AGREEMENT.NAME'),
              options: options.coreAgreements.type_of_agreement,
              disabled: true,
              isSummary: true,
            },
            hideExpression: model => !!model.retention,
          },
          {
            className: 'flex-1 ice-retention-agreement-label',
            key: 'retentionLabel',
            type: 'label',
            defaultValue: translate.instant('AGREEMENTS.CORE_AGREEMENT.RETENTION'),
            templateOptions: { text: translate.instant('AGREEMENTS.CORE_AGREEMENT.TYPE_OF_AGREEMENT.NAME'), disabled: true, isSummary: true },
            hideExpression: model => !model.retention,
          },
          {
            className: 'flex-1 ice-value-mt-25 ice-txt-size-18',
            key: 'sales_manufacture',
            type: 'select',
            templateOptions: {
              label: translate.instant('AGREEMENTS.CORE_AGREEMENT.SALES_MANUFACTURE.NAME'),
              options: options.coreAgreements.sales_manufacture,
              disabled: true,
              isSummary: true,
            },
          },
          {
            className: 'flex-1 ice-value-mt-25 ice-txt-size-18',
            key: 'transfer_works',
            type: 'select',
            templateOptions: {
              label: translate.instant('AGREEMENTS.CORE_AGREEMENT.TRANSFER_WORKS.NAME'),
              options: options.coreAgreements.transfer_works,
              disabled: true,
              isSummary: true,
            },
          },
          {
            className: 'flex-1 ice-value-mt-25 ice-txt-size-18',
            key: 'share_in_op_assignor_only',
            type: 'select',
            defaultValue: translate.instant('AGREEMENTS.CORE_AGREEMENT.SHARE_IN_OVERRIDE.VALUES.NO'),
            templateOptions: {
              label: translate.instant('AGREEMENTS.CORE_AGREEMENT.SHARE_IN_OVERRIDE.NAME'),
              options: options.coreAgreements.share_in_override,
              disabled: true,
              isSummary: true,
            },
          },
        ],
      },
    ];
  }
}
