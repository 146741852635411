export enum RELATIONTYPES {
  XREF = 'XREF',
  COPUB = 'COPUB',
  RECIPIENT = 'RECIPIENT',
  GROUP = 'GROUP',
}

export const RELATION_ORIGIN = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
};

export const XREFALLOWED = ['ALLAW', 'ALLIB', 'ALLRW', 'ALLTC', 'BSREG', 'DELTC', 'EJNW', 'ICE', 'ISRC', 'ISWC', 'ISWCA', 'NDREF', 'PWREF', 'SIWID', 'SWREF', 'SWWRR', 'WIDNO'];
