import { WorkUtils } from '@ice';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as fromRoot from 'store/root';

export class SearchWorkParser {
  static parse(items: any[], translate: TranslateService, extraParams?: Object, extraItems?: string[], store?: Store<any>) {
    return WorkUtils.workSearchCleaner(items, '', false, null, translate, null, store, fromRoot);
  }
}
