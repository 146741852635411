import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import { SelectionDatatableBuilder } from 'config/data-table-builders/selection-datatable-builder';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromRoot from 'store/root';
import { SectionTabConfig } from '../../tabs-data';

export class TabIpXref implements SectionTabConfig {
  private selectionDatatable: SelectionDatatableBuilder;
  constructor(private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService, private store: Store<fromRoot.RootState>) {
    this.fuseTranslationLoader.loadTranslations(english);
    const schema = [
      { name: this.translate.instant('IPS.XREF.XREF_TABLE.SOCIETY_CODE'), prop: 'societyNumber', flexGrow: 1 },
      { name: this.translate.instant('IPS.XREF.XREF_TABLE.SOCIETY_NAME'), prop: 'societyName', flexGrow: 1 },
      { name: this.translate.instant('IPS.XREF.XREF_TABLE.SOCIETY_IP_REFERENCE'), prop: 'societyIpReference', flexGrow: 1 },
      { name: this.translate.instant('IPS.XREF.XREF_TABLE.TYPE'), prop: 'type', flexGrow: 0.5 },
    ];
    this.selectionDatatable = new SelectionDatatableBuilder(this.store, schema);
  }

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('IPS.XREF.XREF_TABLE.TITLE'),
              model: combineLatest([this.store.pipe(select(fromRoot.getPartyRelationsSocieties)), this.store.pipe(select(fromRoot.getPartyRelationsPublishers))]).pipe(
                map(([relationsSocieties, relationsPublishers]) => [
                  ...relationsPublishers.map(publisher => ({ ...publisher, type: this.translate.instant('IPS.XREF.XREF_TABLE.PUBLISHER') })),
                  ...relationsSocieties.map(society => ({ ...society, type: this.translate.instant('IPS.XREF.XREF_TABLE.SOCIETY') })),
                ]),
              ),
              ...{ ...this.selectionDatatable.getSelectionDatatableConfig(), onSelect: null },
              sorts: [{ prop: 'societyNumber', dir: 'asc' }],
            },
          },
        ],
      },
    ];
  }
}
