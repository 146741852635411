<div [class]="footerOptions.footerClassName">
  <div *ngIf="footerOptions" [innerHTML]="footerOptions.footerTemplate"></div>
  <div class="footer-buttons-container" *ngIf="footerOptions">
    <mat-buton
      *ngFor="let fa of footerOptions.footerButtons"
      [attr.disabled]="buttonDisabled(fa)"
      type="{{ fa.type }}"
      [class]="fa.className"
      (click)="fa.action()"
      attr.data-testid="footer-button-{{ fa.type }}"
    >
      <span *ngIf="fa.icon" class="mat-button-wrapper display-flex">
        <mat-icon>{{ fa.icon }}</mat-icon>
      </span>
      <span class="button-title">{{ fa.title }}</span>
    </mat-buton>
  </div>
</div>
