import moment from 'moment';
import { DATE_FORMAT } from './global.constants';

export const GEMA = 'GEMA_CUBE';
export const DEFAULT_DISTRIBUTION_SOCIETY_MR = '044';
export const DEFAULT_DISTRIBUTION_SOCIETY_PR = '052';
export const DIST_SOCIETY_GEMA = [{ label: 'GEMA', value: '35' }];
export const DEFAULT_CISAC_GEMA = 'CISAC:35';
export const DEFAULT_MR_CISAC = `CISAC:${DEFAULT_DISTRIBUTION_SOCIETY_MR}`;
export const DEFAULT_PR_CISAC = `CISAC:${DEFAULT_DISTRIBUTION_SOCIETY_PR}`;
export const USAGE_FACTOR_PR = ['DK99', 'FS12', 'FS24', 'T99', 'TD99', 'U12', 'U24', 'UD99'];
export const USAGE_FACTOR_MR = ['BT VR0', 'PHO VR0', 'R VR0'];
export const AUTHOR_ROLES = ['A', 'AR', 'C', 'CA', 'SA', 'AD', 'SR', 'TR'];
export const DEFAULT_FILTER_TERRITORY = '826';
export const SHARE_PICTURES_MAX_RETRIES = 3;
export const MAX_SHARE_CACHE_SIZE = 8;
export const DEFAULT_MR_RIGHT_TYPE = 'MR';
export const DEFAULT_PR_RIGHT_TYPE = 'PR';
export const DEFAULT_STATUS = '';
export const TYPE_CLAIMANT = 'Claimant';
export const TYPE_PUBLISHER = 'Publisher';
export const WORLD = '2WL';
export const FIRST_VALIDATOR_REQUEST_DELAY = 2000;
export const ALL_RIGHTS = 'ALL';

export const COUNTERCLAIM_TYPES = {
  AUTHOR_SHARE: 'AS',
  AUTHORSHIP: 'AU',
  OWNERSHIP: 'OW',
  ORIGINAL: 'OR',
  SAMPLE: 'SA',
  NO_AGREEMENT: 'NA',
  BREACH_OF_CONTRACT: 'BR',
  INFRINGEMENT: 'IN',
};

export const WORK_CLAIM_SHARE_TYPE = {
  SHARES: 'shares',
  OWNERSHIP: 'ownership',
  COLLECTION: 'collection',
};

export type WorkClaimShareType = keyof typeof WORK_CLAIM_SHARE_TYPE;

export enum RightTypes {
  PR = 'Performing',
  MR = 'Mechanical',
}
export type RightType = RightTypes.MR | RightTypes.PR;

export enum SharePicReturnCases {
  GET_STATUS_SHAREPICTURE = 1,
  GET_SHAREPICTURE = 2,
  ERROR_RETRY_MANUALLY = 3,
  TREE_NOT_AVAILABLE = 4,
}
export type SharePicReturnCasesType =
  | SharePicReturnCases.GET_STATUS_SHAREPICTURE
  | SharePicReturnCases.GET_SHAREPICTURE
  | SharePicReturnCases.ERROR_RETRY_MANUALLY
  | SharePicReturnCases.TREE_NOT_AVAILABLE;

// This includes the first column 'Right Type'
export const ERROR_NAN = 0;
export const ERROR_ONLY_2_DECIMALS = 1;
export const ERROR_TOTAL_100 = 2;
export const ERROR_AT_LEAST_ONE_RIGHT_FILLED = 3;
export const ERROR_NO_REPETITION_RIGHT_TERRITORY = 4;
export const ERROR_AT_LEAST_ONE_RIGHT_TERRITORY = 5;
export const ERROR_NO_NEGATIVE_VALUE = 6;
export const ERROR_LIMIT_EXCEEDED = 9;
export const ERROR_TERRITORY_NOT_EXISTS = 7;
export const ERROR_TERRITORY_IS_EMPTY = 8;
export const ERROR_WRITER_MECHANICAL_REASON_IS_EMPTY = 9;
export const ERROR_WRONG_END_DATE = 10;
export const ERROR_TOTAL_MORE_THAN_100 = 11;
export const ERROR_NO_ENOUGH_MR_RIGHTS = 12;
export const ERROR_NO_ENOUGH_PR_RIGHTS = 13;
export const N_ROWS = 6;
export const ROW_RIGHT_TYPE = 0;
export const ROW_CREATOR = 1;
export const ROW_PUBLISHER = 2;
export const ROW_ASSIGNEE = 3;
export const ROW_TERRITORY = 4;
export const ROW_INCLUSION = 5;

export const TRUNCATE_LENGHT = 20;
export const MIN_VERTICAL_LEVELS = 1;

// Those are node colors regarding their type not their level
// Values are overwritten in tree-chart.component.ts
export const NODE_COLORS = {
  root: '#5090FF',
  CA: '#5A49E8',
  C: '#5A49E8',
  A: '#5A49E8',
  AR: '#5A49E8',
  E: '#9B0CFF',
  SE: '#E856D5',
  final: '#FF5857',
};

export const ROOT_COLOR = '#17becf';
export const DEFAULT_COUNTRY = '826';

export const commonVisibleColumns = ['role', 'name', 'ipiNameNumber', 'ipiNameKey', 'ipiBaseNumber', 'ipiBaseKey', 'prSociety', 'mrSociety', 'pr', 'mr', 'alert'];

export const workDetailVisibleColumns = ['role', 'name', 'ipiNameNumber', 'prSociety', 'mrSociety', 'agreementId', 'status', 'pr', 'mr', 'alert'];
export const workDetailRepertoireVisibleColumns = [
  'role',
  'name',
  'ipiNameNumber',
  'prSociety',
  'mrSociety',
  'agreementId',
  'prRepertoireName',
  'mrRepertoireName',
  'pr',
  'mr',
  'alert',
];

export const workTitelsVisibleColumns = ['value', 'type', 'durationFormatted', 'delete', 'edit'];

export const MOCK_SHAREPIC = [
  {
    country: 826,
    usageDate: '2019-07-15',
    distributionDate: '2019-07-15',
    currentDate: '2020-03-18',
    mrRightType: 'MR',
    prRightType: 'PR',
    mrOverrideRightType: 'PT',
    prOverrideRightType: 'RB',
    mrDistributionSociety: 'CISAC:44',
    prDistributionSociety: 'CISAC:52',
    works: ['ICE:1', 'ICE:2'],
  },
  {
    country: 826,
    usageDate: '2019-07-15',
    distributionDate: '2019-07-15',
    currentDate: '2020-03-18',
    mrRightType: 'MR',
    prRightType: 'PR',
    mrDistributionSociety: 'CISAC:44',
    prDistributionSociety: 'CISAC:52',
    works: ['ICE:3', 'ICE:4'],
  },
];

export const MARGIN_ROLE = '&nbsp;&nbsp;';
export const RIGHT_PR = DEFAULT_PR_RIGHT_TYPE;
export const RIGHT_MR = DEFAULT_MR_RIGHT_TYPE;
export const ROLE_UNDEFINED = 'n/a';

export const CREATOR_ROLES = ['A', 'AD', 'AR', 'C', 'CA', 'SR'];
export const ARRANGERS_AND_ADAPTER_ROLES = ['AD', 'AR', 'SR'];
export const PUBLISHER_ROLES = ['E'];
export const SUBPUBLISHER_ROLES = ['SE', 'SA', 'SR', 'TR'];
export const WORK_CREATION_SUBPUBLISHER_ROLES = ['SE'];

export const STATUS_NON_APPLICABLE = 'NA';

export const DEFAULT_SHARES_FILTER_FORM = {
  territory: DEFAULT_FILTER_TERRITORY,
  prRightType: DEFAULT_PR_RIGHT_TYPE,
  mrRightType: DEFAULT_MR_RIGHT_TYPE,
  usageDate: moment().format(DATE_FORMAT),
  distributionDate: moment().format(DATE_FORMAT),
  prSociety: DEFAULT_DISTRIBUTION_SOCIETY_PR,
  mrSociety: DEFAULT_DISTRIBUTION_SOCIETY_MR,
  repertoire: false,
  removeE: false,
  removeSE: false,
};

export const CLAIM_SHARES = 'claimShares';
export const OWNERSHIP_SHARES = 'ownershipShares';
export const SHARES_PROPERTIES = [CLAIM_SHARES, OWNERSHIP_SHARES];

export interface ClaimInfo {
  roleRaw?: string;
  name?: string;
  ipiBaseNameKey?: string;
  claimId?: string;
  ipiName?: string;
  ipiNameKey?: string;
  parentId?: string;
  prSociety?: string;
  mrSociety?: string;
  roleTooltip?: string;
  prSocietyTooltip?: string;
  mrSocietyTooltip?: string;
  isIncomeParticipant?: boolean;
  pr?: string;
  mr?: string;
  territories?: string[];
  tisnTerritories?: string[];
  mrRights?: string[];
  prRights?: string[];
  sourceWork?: string;
  unauthorized?: boolean[];
  hasNotResolvedCounterclaim?: boolean;
  territoriesLabel?: string;
  territoriesTooltip?: string;
  mrRightsLabel?: string;
  prRightsLabel?: string;
  rowClass?: string;
  alertIcon?: null;
  role?: string;
  path?: string[];
  matchScore?: number;
  prMrRights?: string;
  prMrRightsTooltip?: string;
  prMrSociety?: string;
  prMrSocietyTooltip?: string;
  alertIcons?: Array<{
    icon?: string;
    text?: string;
    class?: string;
  }>;
  startDate?: string;
  endDate?: string;
  postTermCollectionDate?: string;
  claimView?: string;
}
