import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { TreeChartModule } from '@ice/components/work-tree-chart/tree-chart.module';
import { IceMaterialModule } from '@ice/material.module';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { TranslateModule } from '@ngx-translate/core';
import { TreeChartTypeComponent } from './tree-chart-type.component';

@NgModule({
  declarations: [TreeChartTypeComponent],
  imports: [TranslateModule, FuseSharedModule, IceMaterialModule, FormlyMaterialModule, FormlyModule.forRoot(), TreeChartModule],
  exports: [TreeChartTypeComponent],
})
export class TreeChartTypeModule {}
