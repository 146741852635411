import { Injectable } from '@angular/core';
import { ConflictUtils } from '@ice';
import moment from 'moment';
import { ExpertSearchParseService } from './expert-search-parse.service';

@Injectable()
export class ExpertSearchParseAgreementConflictsService extends ExpertSearchParseService {
  constructor() {
    const AttributesMapAgreementConflicts = {
      entityType: 'attributes.entityType',
      agreementId: 'agreements.agreementId',
      id: 'id',
      status: 'attributes.status',
      user: 'actions.attributes.assignor',
      publisherNameOrKey: async (comparator: string, value: string, _deep = false) => ({
        and: [
          {
            or: [
              { [comparator]: { 'agreements.agreement.assignor.partyName.attributes.name': `${value}` } },
              { [comparator]: { 'agreements.agreement.assignor.partyName.relations[XREF].otherId': `${value}` } },
            ],
          },
        ],
      }),
      deadline: 'actions.attributes.deadline',
      created: 'attributes.createdDate',
      createdFrom: async (comparator: string, value: string, _deep = false) => ({
        and: [{ range: { 'attributes.createdDate': { gte: moment(value).format('YYYY-MM-DD') } } }],
      }),
      createdTo: async (comparator: string, value: string, _deep = false) => ({
        and: [{ range: { 'attributes.createdDate': { lte: moment(value).format('YYYY-MM-DD') } } }],
      }),
    };

    const attributesEnumMapAgreementConflicts = {
      ['status']: ConflictUtils.getAgreementConflictStates(),
    };

    super(attributesEnumMapAgreementConflicts, AttributesMapAgreementConflicts);
  }
}
