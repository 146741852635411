import { MAX_SEARCH_SIZE } from 'config/constants/search.constants';
import { SectionsConfig } from 'config/sections-config';
import { RequestType } from 'config/sections-config/api-call';
import { environment } from 'config/env';
import {
  copyrightGlobal,
  createApiCall,
  createApiCallFromBase,
  createPermissionResolver,
  isAthena,
  isCounterApiCall,
  isNOTPageable,
  isPageable,
  isResponseHandler,
  setBody,
  setCheckStatus,
  setInclude,
  setLabelResolver,
  setPageable,
  setQueryParams,
  setResultCleaner,
  validateLabels,
} from '../api-call.factory';
import { getAuditHistory, getAuditHistoryFilterEndDate, getAuditHistoryFilterStartDate } from '../common/common.api-calls';
import * as fromLabelResolvers from './agreements.label-resolvers';
import * as fromResultCleaners from './agreements.result-cleaners';

export const getAgreementWorks = createApiCall(
  `${environment.apiUrlCubeData}/agreementapplications/<<ns>>/search`,
  RequestType.POST,
  setInclude(
    `attributes{agreementId,workId, claimantId,shares,include},
    work.attributes{ id, genre, publicationDate, source, owner, status, duration, language, titles, tags, societyMarkers, purpose, origin },
    work.relations{ relation, otherId },
    work.contributions{ contributor, role,contributorPartyNameId }.contributor{ partyName }.partyName{ attributes, relations }.relations{ relation, otherId },
    work.contributions.contributor.partyName.attributes{ name, firstName }`,
  ),
  setBody(`{"and":[{"equals": {"attributes.agreementId": "<<id>>"}}, {"equals": {"attributes.include": "<<extraParam>>"}},{"wildcard": {"attributes.workId": "*"}}]}`),
  setLabelResolver(SectionsConfig.AGREEMENTS.name, fromLabelResolvers.getAttributesId, fromLabelResolvers.getAgreementWorksLabelResolver),
  setResultCleaner([fromResultCleaners.getAgreementWorksPropertyCleaner, fromResultCleaners.getAgreementWorksResultCleaner]),
  isPageable,
);

export const searchDialogAgreements = createApiCall(
  `${environment.apiUrlCubeData}/agreements/<<ns>>/search`,
  RequestType.POST,
  setInclude(`attributes{id, retention, administrator, agreementType, searchTerm, shares, agreementDispute, sourceOfDoc, termTerritory},counterclaims.counterclaim.attributes,
  relations[XREF]{relation,otherId},
  assignor{party,partyName}.partyName.attributes{id, name, firstName},
  assignor.partyName.relations[XREF]{relation,otherId}, assignor.party.attributes{typeOf},
  assignor.party.societies{societyId,society,memberships}, assignee{party,partyName}.partyName.attributes{id, name, firstName},
  assignee.partyName.relations[XREF]{relation,otherId}, assignee.party.societies{societyId,society,memberships},
  agg.attributes.workCount, counterclaims.counterclaim.attributes`),
  setResultCleaner(['dialogSearchData', fromResultCleaners.searchDialogAgreementsResultCleaner]),
  isPageable,
  copyrightGlobal,
);

export const getAgreementWritersSummary = createApiCall(
  `${environment.apiUrlCubeData}/postgres/agreement_writers_summary/search`,
  RequestType.POST,
  setQueryParams({
    sort: 'creatorName:asc',
  }),
  setBody(`{"equals": {"agreementid": "<<id>>"}}`),
  setLabelResolver(SectionsConfig.AGREEMENTS.name, fromLabelResolvers.getAttributesId),
  setResultCleaner(['writers', fromResultCleaners.getAgreementWritersCleaner]),
  isPageable,
);

export const getAgreementWritersCounter = createApiCall(
  `${environment.apiUrlCubeData}/postgres/agreement_writers_summary/search`,
  RequestType.POST,
  setQueryParams({
    from: 0,
    size: 0,
  }),
  setBody(`{"equals": {"agreementid": "<<id>>"}}`),
  setLabelResolver(SectionsConfig.AGREEMENTS.name, fromLabelResolvers.getAttributesId),
  setResultCleaner(['writersCount', fromResultCleaners.getAgreementWritersCountCleaner]),
);

export const getAgreementWritersDetails = createApiCall(
  `${environment.apiUrlCubeData}/postgres/agreement_writers_details/search`,
  RequestType.POST,
  setBody(`{ "and": [{"equals": {"agreementid": "<<agreementId>>"}}, { "equals": { "creatorIpi": "<<creatorIpi>>" } }, { "equals": { "publisherIpi": "<<publisherIpi>>" }}]}`),
  isResponseHandler,
  isAthena,
);

export const getAgreementAuditHistoryFilterStartDate = createApiCallFromBase(getAuditHistory, getAuditHistoryFilterStartDate);

export const getAgreementAuditHistoryFilterEndDate = createApiCallFromBase(getAuditHistory, getAuditHistoryFilterEndDate);

export const getAgreementLinkedWriters = createApiCall(
  `${environment.apiUrlCubeData}/agreementapplications/CUBE/search`,
  RequestType.POST,
  setInclude(`attributes{agreementId,workId,claimantId,shares,include},
  claimant.partyName.attributes{id,name,firstName, typeOfName, publisher},
  claimant.partyName.relations{relation,otherId},
  claimant.partyName.parties.party.relations[XREF]{relation,otherId},
  claimant.partyName.parties.party.societies{societyId,society,memberships},
  claimant.partyName.parties.party.attributes{typeOf}`),
  isPageable,
  setBody(`{"and":[{"equals": {"attributes.agreementId": "<<id>>"}},{"equals": {"attributes.include": true}},{"not":{"wildcard": {"attributes.workId": "*"}}}]}`),
  setLabelResolver(SectionsConfig.AGREEMENTS.name, fromLabelResolvers.getAttributesId),
  setResultCleaner(['linkedWriters', fromResultCleaners.getAgreementLinkedWriters]),
);

export const getAgreementLinkedWritersCounter = createApiCallFromBase(
  getAgreementLinkedWriters,
  isCounterApiCall,
  setResultCleaner(['linkedWritersCount', fromResultCleaners.getCounterResultCleaner]),
);

export const getAgreementsWorksCount = createApiCall(
  `${environment.apiUrlCubeData}/agreementapplications/<<ns>>/search`,
  RequestType.POST,
  setQueryParams({
    accurateCount: true,
    includes: ``,
    from: 0,
    size: 0,
  }),
  setBody(`{
    "and": [
      {
        "equals": {
          "attributes.agreementId": "<<id>>"
        }
      },
      {
        "exists": {
          "field": "attributes.workId"
        }
      },
      {
        "equals": {
          "attributes.include": true
        }
      }
    ]
  }`),
  setLabelResolver(SectionsConfig.AGREEMENTS.name, fromLabelResolvers.getAttributesId),
  setResultCleaner(['totalWorksCount', fromResultCleaners.getCounterResultCleaner]),
  isNOTPageable,
);

export const getAgreementIncludedWorksCounter = createApiCallFromBase(
  getAgreementsWorksCount,
  setBody(`{"and":[{"equals": {"attributes.agreementId": "<<id>>"}}, {"equals": {"attributes.include": "true"}},{"wildcard": {"attributes.workId": "*"}}]}`),
  setLabelResolver(SectionsConfig.AGREEMENTS.name, fromLabelResolvers.getAttributesId),
  setResultCleaner(['includedWorksCount', fromResultCleaners.getCounterResultCleaner]),
);

export const getAgreementExcludedWorksCounter = createApiCallFromBase(
  getAgreementsWorksCount,
  setBody(`{"and":[{"equals": {"attributes.agreementId": "<<id>>"}}, {"equals": {"attributes.include": "false"}},{"wildcard": {"attributes.workId": "*"}}]}`),
  setLabelResolver(SectionsConfig.AGREEMENTS.name, fromLabelResolvers.getAttributesId),
  setResultCleaner(['excludedWorksCount', fromResultCleaners.getCounterResultCleaner]),
);

export const getAgreementRetentions = createApiCall(
  `${environment.apiUrlCubeData}/agreements/<<ns>>/search`,
  RequestType.POST,
  setPageable(0, MAX_SEARCH_SIZE),
  isNOTPageable,
  setInclude(`relations`),
  setBody(`{"and":[{"equals":
      {"relations.otherId":"<<id>>" }},
      {"equals":{"relations.relation":"RETENTION" }},
      {"phrase":{"relations.otherId":"ICE"}}]}`),
  setLabelResolver(SectionsConfig.AGREEMENTS.name, fromLabelResolvers.getAttributesKey),
  setResultCleaner(['retentions', fromResultCleaners.getAgreementRetentionsResultCleaner]),
);

export const updateAgreementCrossReference = createApiCall(
  `${environment.apiUrlCubeData}/${SectionsConfig.AGREEMENTS.apiSegment}/CUBE/<<id>>/origins/ICE`,
  RequestType.PUT,
  setQueryParams({
    force: true,
  }),
  setCheckStatus(SectionsConfig.AGREEMENTS.apiSegment),
  isResponseHandler,
);

export const updateAgreementLinkedWritters = createApiCall(
  `${environment.apiUrlCubeRegister}/agreementapplications/ICE/claimant/register/<<id>>/<<partyNameId>>/<<partyId>>`,
  RequestType.PUT,
  setQueryParams({
    force: true,
  }),
  isResponseHandler,
  setCheckStatus('agreementapplications', 'register'),
);

export const getAgreementSubmitterPartyRelations = createApiCall(
  `${environment.apiUrlCubeData}/party-names/CUBE/search`,
  RequestType.POST,
  setInclude(`attributes{id,name,firstName,typeOfName},relations{otherId,relation}`),
  setQueryParams({ followRedirects: false }),
  setBody(`{"and":[{"not":{"equals":{"parties.party.id":""}}},{"or" : [<<pattern>>]},{"phrase":{"relations.otherId":"ICE"}}]}`),
  setLabelResolver(SectionsConfig.AGREEMENTS.name, fromLabelResolvers.getSubmitterPartyRelationPatternLabelResolver, fromLabelResolvers.getSubmitterPartyCondition),
  setResultCleaner(['submitterPartyRelations', fromResultCleaners.getAgreementSubmitterPartyRelations]),
  validateLabels,
);

export const getAgreementCounterclaims = createApiCall(
  `${environment.apiUrlCubeData}/agreements/CUBE/<<id>>`,
  RequestType.GET,
  setInclude(`counterclaims.counterclaim.attributes,counterclaims.counterclaim.agreements`),
  setLabelResolver(SectionsConfig.AGREEMENTS.name, fromLabelResolvers.getAttributesId),
  setResultCleaner(['conflictIcon', fromResultCleaners.getAgreementConflictIconCleaner], ['counterclaims', fromResultCleaners.getAgreementCounterclaimsCleaner]),
  createPermissionResolver('agreements_counterclaim_icon'),
);

export const getAgreementOriginalPublishersList = createApiCall(
  `${environment.apiUrlCubeData}/postgres/agreement_original_publishers_with_counts/search`,
  RequestType.POST,
  setBody(`{"equals": {"agreementid": "<<id>>"}}`),
  setLabelResolver(SectionsConfig.AGREEMENTS.name, fromLabelResolvers.getAttributesId),
  setResultCleaner(['originalPublishersList', fromResultCleaners.getAgreementOriginalPublishersList]),
);

export const getAgreementPublisherWorks = createApiCall(
  `${environment.apiUrlCubeData}/agreementapplications/<<ns>>/search`,
  RequestType.POST,
  setInclude(
    `attributes{agreementId,workId, claimantId,shares,include},
    work.attributes{ id, genre, publicationDate, source, owner, status, duration, language, titles, tags, societyMarkers, purpose, origin },
    work.relations{ relation, otherId },
    work.contributions{ contributor, role,contributorPartyNameId }.contributor{ partyName }.partyName{ attributes, relations }.relations{ relation, otherId },
    work.contributions.contributor.partyName.attributes{ name, firstName }`,
  ),
  setLabelResolver(SectionsConfig.AGREEMENTS.name, fromLabelResolvers.getAttributesId),
  setResultCleaner(['publisherWorks', fromResultCleaners.getAgreementWorksResultCleaner]),
  isPageable,
);
