<div class="table-container" *ngIf="config | async; let c">
  <ng-container *ngFor="let g of c.groups && c.groups.length && c.groups.length > 0 ? c.groups : [0]">
    <div class="table-rows" *ngIf="(g !== 0 && c.rows[g.prop]) || c.rows; let rows">
      <!--GROUP TITLE-->
      <div *ngIf="g !== 0" class="table-group-row">{{ g.name }}</div>
      <ng-container *ngFor="let row of rows; index as i">
        <div tabindex="-1" class="tab-navigation">
          <div [ngClass]="{ 'table-row-selectable': c.selectable, 'table-row': true }" (click)="onRowSelect(row)">
            <ng-container *ngIf="c.multiselect">
              <mat-checkbox class="table-checkbox" [(ngModel)]="row.checked" attr.data-testid="row-checkbox"></mat-checkbox>
            </ng-container>
            <ng-container *ngFor="let headerCol of c.header">
              <div class="{{ headerCol.class || 'col1' }} {{ headerCol.prop }}" [matTooltip]="(!headerCol.hideTooltip && row[headerCol.prop]) || null">
                {{ row[headerCol.prop] }}
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
    <!--END table-rows-->
  </ng-container>
  <!--END ngFor groups-->
</div>
<!--END table-container-->
