import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SectionsConfig } from 'config/sections-config';
import { BehaviorSubject, of } from 'rxjs';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';

export class DialogAddById {
  private dialogRef: MatDialogRef<DialogMultiLayoutComponent, any>;
  private id = null;
  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private store: Store<fromRoot.RootState>,
    private fieldValidatorService: FieldValidatorService,
    private model: string,
  ) {}

  openDialog(onSubmit: (id) => void, initialValue = null) {
    this.id = null;
    this.dialogRef = this.dialog.open(DialogMultiLayoutComponent, {
      panelClass: 'overflow-hidden',
      data: {
        loading: this.store.pipe(select(fromRoot.getCopyrightLoading)),
        layouts: [this.getAddByIdLayout(onSubmit, initialValue)],
      },
    });
    return this.dialogRef;
  }

  getDialogConfigByModel() {
    switch (this.model) {
      case SectionsConfig.WORKS.name:
        return {
          title: of(this.translate.instant('ADD_BY_ID_DIALOG.WORK.TITLE')),
          placeholder: `${this.translate.instant('ADD_BY_ID_DIALOG.COMMON.ADD')} ${this.translate.instant('ADD_BY_ID_DIALOG.WORK.TITLE')}`,
          validator: this.getWorkValidator(),
        };
      case SectionsConfig.AGREEMENTS.name:
        return {
          title: of(this.translate.instant('ADD_BY_ID_DIALOG.AGREEMENT.TITLE')),
          placeholder: `${this.translate.instant('ADD_BY_ID_DIALOG.COMMON.ADD')} ${this.translate.instant('ADD_BY_ID_DIALOG.AGREEMENT.TITLE')}`,
          validator: this.getAgreementValidator(),
        };
      case SectionsConfig.IPS.name:
        return {
          title: of(this.translate.instant('ADD_BY_ID_DIALOG.IPS.TITLE')),
          placeholder: `${this.translate.instant('ADD_BY_ID_DIALOG.COMMON.ADD')} ${this.translate.instant('ADD_BY_ID_DIALOG.IPS.NAME')}`,
          validator: this.getIpsLegalEntityValidator(),
        };
    }
  }

  getAddByIdLayout(onSubmit: (id) => void, initialValue = null) {
    const idSubject = new BehaviorSubject(true);
    const dialogConfigBySection = this.getDialogConfigByModel();
    return {
      title: dialogConfigBySection.title,
      className: 'dialog-wrapper-auto',
      layout: [
        {
          group: [
            {
              type: 'formly',
              config: {
                setValidForm: valid => idSubject.next(!valid || !this.id),
                formBuilder: of([
                  {
                    className: 'ice-field-w-80',
                    key: 'key',
                    type: 'input',
                    wrappers: ['form-field', 'wrapper-input-text'],
                    templateOptions: {
                      type: 'text',
                      placeholder: dialogConfigBySection.placeholder,
                      required: true,
                    },
                    hooks: {
                      onInit: field => {
                        field.formControl.markAsTouched();
                        field.formControl.markAsDirty();
                      },
                    },
                    ...(dialogConfigBySection.validator && { asyncValidators: dialogConfigBySection.validator }),
                  },
                ]),
                model: of({ key: initialValue }),
                submitEnabled: true,
                submitAvailable: 'true',
                submitLabel: this.translate.instant('POPUP.CONFIRM'),
                button1Enabled: true,
                button1Available: 'true',
                button1Label: this.translate.instant('POPUP.CANCEL'),
                submit: value => onSubmit(this.id),
                onButton1: () => this.dialog.closeAll(),
              },
            },
          ],
        },
      ],
    };
  }

  getAgreementValidator() {
    return {
      agreement_valitation: {
        expression: (control: FormControl, field) =>
          this.fieldValidatorService.useValidatorOnTouch(field) ||
          new Promise((resolve, reject) =>
            this.fieldValidatorService.validAgreementKey(
              control,
              agreement => {
                this.id = agreement?.id;
                return resolve(true);
              },
              () => {
                this.id = null;
                return resolve(false);
              },
            ),
          ),
        message: this.translate.instant('ADD_BY_ID_DIALOG.AGREEMENT.ERROR'),
      },
    };
  }

  getWorkValidator() {
    return {
      works_validation: {
        expression: (control: FormControl, field) =>
          this.fieldValidatorService.useValidatorOnTouch(field) ||
          new Promise((resolve, reject) =>
            this.fieldValidatorService.validWorkKey(
              control,
              workId => {
                this.id = workId;
                return resolve(true);
              },
              () => {
                this.id = null;
                return resolve(false);
              },
            ),
          ),
        message: this.translate.instant('ADD_BY_ID_DIALOG.WORK.ERROR'),
      },
    };
  }

  getIpsLegalEntityValidator() {
    return {
      additional_collectors_validation: {
        expression: (control: FormControl, field) =>
          this.fieldValidatorService.useValidatorOnTouch(field) ||
          new Promise((resolve, reject) => {
            this.fieldValidatorService.validIPLegalEntityKey(
              control,
              partyId => {
                this.id = partyId;
                resolve(true);
              },
              () => {
                this.id = null;
                resolve(false);
              },
            );
          }),
        message: this.translate.instant('ADD_BY_ID_DIALOG.IPS.ERROR'),
      },
    };
  }
}
