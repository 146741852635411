<div style="padding:20px;min-width: 1000px;min-height: 400px;" fxLayout="column" #popupHeader>
  <h2 mat-dialog-title>{{ data.title | async }}</h2>
  <mat-dialog-content>
    <div *ngIf="!(data.json | async)" class="spinner-container">
      <mat-spinner [diameter]="70"></mat-spinner>
    </div>
    <ngx-json-viewer *ngIf="(data.json | async)" [json]="data.json | async" [expanded]="data.expanded | async"></ngx-json-viewer>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="float-buttons" [fxLayout]="'row-reverse'">
      <button mat-fab mat-dialog-close [matTooltip]="data.tooltip">
        <mat-icon class="white-icon">close</mat-icon>
      </button>
    </div>
  </mat-dialog-actions>
</div>
