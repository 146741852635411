import { environment } from 'config/env';
import { SectionRouterViews } from './section-router-views';
import type { SectionConfig } from './sections-config';

export const SectionAgreementGroup: SectionConfig = {
  name: 'agreement-group',
  url: '/copyright/agreement-group',
  domainName: 'copyright',
  baseUrl: environment.apiUrl,
  baseUrlCubeData: environment.apiUrlCubeData,
  apiSegment: 'agreement-groups',
  detailSegment: `agreement-groups/CUBE/<<id>>`,
  minCharsToSearch: 3,
  apiIncludes: {
    search: `attributes, partyName.attributes, partyName.relations, relations`,
    detail: `attributes, agreements[ns="CUBE"].agreement.attributes{id, administrator, agreementType, searchTerm, shares},
            agreements[ns="CUBE"].agreement.relations[XREF]{relation,otherId},
            agreements[ns="CUBE"].agreement.assignor{partyNameId,party,partyName}.partyName.attributes{id, name, firstName},
            agreements[ns="CUBE"].agreement.assignor.partyName.relations[XREF]{relation,otherId},
            agreements[ns="CUBE"].agreement.assignor.party.attributes{typeOf},
            agreements[ns="CUBE"].agreement.assignor.party.societies{societyId,society,memberships},
            agreements[ns="CUBE"].agreement.assignee{partyNameId,party,partyName}.partyName.attributes{id, name, firstName},
            agreements[ns="CUBE"].agreement.assignee.partyName.relations[XREF]{relation,otherId},
            agreements[ns="CUBE"].agreement.assignee.party.societies{societyId,society,memberships},
            partyName.attributes, partyName.relations, relations`,
  },
  searchServerSideSortDefault: '',
  allowedActions: {
    newItem: true,
    editItem: 'stepper',
    newNote: false,
    removeItem: false,
  },
  newItemBtn: [SectionRouterViews.home, SectionRouterViews.search],
  homeRedirect: '',
  hideHomeEditButton: true,
  auditTypes: ['agreement-group'],
};
