import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import { StepType } from '../../stepper-config';

export class ReportShareStep {
  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<any>,
    private fieldValidatorService: FieldValidatorService,
  ) {}

  onInitForm(field) {}
  onDestroyForm() {}

  getStep(translate: TranslateService): StepType {
    return {
      label: translate.instant('REPORTS.AGREEMENTS_LIST.TITLE'),
      formBuilder: [
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              key: 'ipBaseKey',
              wrappers: ['form-field', 'wrapper-input-text'],
              type: 'input',
              className: 'flex-1',
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                required: true,
                placeholder: translate.instant('REPORTS.AGREEMENTS_LIST.IP_BASE'),
              },
            },
          ],
        },
      ],
    };
  }
}
