import { environment } from 'config/env';
import * as fromActions from 'store/root/actions';
import * as fromState from 'store/root/state';

export function reducer(state: fromState.GlobalState = fromState.initialGlobalState, action: fromActions.GlobalActions): fromState.GlobalState {
  // Prettier is removing the last return adding this to keep it
  if (action && action.type) {
    switch (action.type) {
      case fromActions.SAVE_CURRENT_NAVIGATION_EVENT: {
        // When url is different from last one, then is added to the stack.
        let stack = [];
        if (state.navigation.urlStack.length === 0 || state.navigation.urlStack.slice(-1)[0].url !== action.payload.nextUrl.url) {
          stack = [...state.navigation.urlStack, action.payload.nextUrl];
        } else {
          stack = [...state.navigation.urlStack];
        }
        const navigation = {
          ...state.navigation,
          state: action.payload.state,
          stateStack: [...state.navigation.stateStack, action.payload.state].slice(-100),
          urlStack: stack.slice(-100),
          lastSection: action.payload.lastSection || state.navigation.lastSection,
        };
        return { ...state, navigation };
      }
      case fromActions.CHANGE_API_NAMESPACE: {
        return { ...state, apiNamespace: action.payload, forcedNS: true };
      }
      case fromActions.RESTORE_API_NAMESPACE: {
        return { ...state, apiNamespace: environment.apiNamespace, forcedNS: false, forcedNsBackLink: '' };
      }
      case fromActions.SAVE_FORCEDNS_BACKLINK: {
        return { ...state, forcedNsBackLink: action.payload };
      }
    }
  }
  return state;
}

export const getGlobalNavigationState = (state: fromState.GlobalState) => state.navigation;
export const getGlobalApiNamespaceState = (state: fromState.GlobalState) => state.apiNamespace;
