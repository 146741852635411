import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'ice-formly-wrapper-card',
  templateUrl: './wrapper-card.component.html',
  styleUrls: ['./wrapper-card.component.scss'],
})
export class WrapperCardComponent extends FieldWrapper {
  @ViewChild('fieldComponent', { read: ViewContainerRef, static: true }) fieldComponent: ViewContainerRef;
}
