import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Amplify } from '@aws-amplify/core';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TabsUtils } from '@ice/utils/tabs/tab.utils';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { loadSpace } from '@usersnap/browser';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { locale as english } from 'assets/i18n/en/app/root-containers-app';
import { locale as navigationEnglish } from 'assets/i18n/en/config/navigation';
import { locale as tabs } from 'assets/i18n/en/config/tabs-data-builders';
import { environment } from 'config/env';
import { get } from 'lodash';
import { forkJoin, of } from 'rxjs';
import { catchError, filter, take, withLatestFrom } from 'rxjs/operators';
import { AuthService } from 'services/auth/auth.service';
import { CheckVersionService } from 'services/check-version/check-version.service';
import { DataDogRUMService } from 'services/datadog-rum/datadog-rum.service';
import { FeatureFlagService } from 'services/feature-flags/feature-flags-flagsmith.service';
import * as fromRoot from 'store/root';
import { SnackBarConfiguration } from 'store/root';
import { SnackBarTemplateComponent } from '../../components/snackbar-template/snackbar-template.component';
@Component({
  selector: 'ice-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  hotKeyInputArray = ['INPUT', 'SELECT', 'TEXTAREA'];

  snackBarSubscription;
  snackBarDismissedSubscription;

  constructor(
    private translate: TranslateService,
    private fuseNavigationService: FuseNavigationService, // DO NOT REMOVE
    private fuseSplashScreen: FuseSplashScreenService, // DO NOT REMOVE
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private hotkeys: HotkeysService,
    private store: Store<fromRoot.RootState>,
    private snackBar: MatSnackBar,
    private router: Router,
    private titleService: Title,
    private featureFlagService: FeatureFlagService,
    private checkVersionService: CheckVersionService,
    private authService: AuthService,
    private datadogService: DataDogRUMService, // DO NOT REMOVE
  ) {}

  ngOnInit() {
    // Add languages
    this.translate.addLangs(['en']);

    Amplify.configure({ Auth: environment.AuthConf, authenticationFlowType: 'USER_PASSWORD_AUTH' });

    // Set the default language
    this.translate.setDefaultLang('en');

    // Set the navigation translations
    this.fuseTranslationLoader.loadTranslations(navigationEnglish);
    this.fuseTranslationLoader.loadTranslations(english);
    this.fuseTranslationLoader.loadTranslations(tabs);

    // Use a language
    this.translate.use('en');

    this.setHotKeys();

    this.snackBarSubscription = this.store.pipe(select(fromRoot.getSnackBar)).subscribe(snackBar => {
      if (snackBar && snackBar.visible && snackBar.configuration) {
        this.openSnackBar(snackBar.configuration);
      }
    });

    this.updateTitleListener();
    this.featureFlagService.initialize();
    this.loadUsernap();

    if (environment.dataDogConf.clientId) {
      this.datadogService.initialize();
    }
  }

  ngOnDestroy() {
    if (this.snackBarSubscription) {
      this.snackBarSubscription.unsubscribe();
    }
    if (this.snackBarDismissedSubscription) {
      this.snackBarDismissedSubscription.unsubscribe();
    }
  }

  private loadUsernap() {
    if (environment.userSnapEnable) {
      const versionObservable$ = this.checkVersionService.currentVersion$.pipe(take(1));
      const userObservable$ = this.authService.getCurrentUserInfo().pipe(
        take(1),
        catchError(error => {
          return of({
            userName: 'not authenticated',
            email: '',
            surname: '',
            name: '',
          });
        }),
      );

      forkJoin([versionObservable$, userObservable$]).subscribe(([version, user]) => {
        loadSpace(environment.userSnapAPIKey).then(usersnapAPI => {
          usersnapAPI.init();
          usersnapAPI.on('open', event => {
            event.api.setValue('custom', {
              appVersion: version,
              environment: environment.baseUrl,
              region: environment.region,
              user: user.userName,
              userEmail: user.email,
            });
          });
        });
      });
    }
  }

  private openSnackBar(config: SnackBarConfiguration) {
    const horizontalPosition = get(config, 'position.horizontalPosition', 'center');
    const verticalPosition = get(config, 'position.verticalPosition', 'top');
    const duration = get(config, 'duration', 3000);
    const icon = get(config, 'icon', null);
    const snackBarConf = {
      duration,
      horizontalPosition,
      verticalPosition,
      data: {
        message: config.message,
        icon,
      },
    };

    this.snackBarDismissedSubscription = this.snackBar
      .openFromComponent(SnackBarTemplateComponent, snackBarConf)
      .afterDismissed()
      .subscribe(() => {
        this.store.dispatch(new fromRoot.ResetSnackBar());
        this.snackBarDismissedSubscription.unsubscribe();
      });
  }

  private setHotKeys() {
    this.hotkeys.add([
      new Hotkey(
        'ctrl+shift+a',
        (): boolean => {
          this.store.dispatch(new fromRoot.AdvancedSearchToggle());
          return false;
        },
        this.hotKeyInputArray,
        this.translate.instant('HOT_KEYS.ADV_SEARCH'),
      ),
    ]);
  }

  private updateTitleListener(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        withLatestFrom(this.store.select(fromRoot.getRouterPaths)),
      )
      .subscribe(([a, paths]) => {
        this.titleService.setTitle(this.getPathTitles(paths || []).join(' --> '));
      });
  }

  private getPathTitles(paths: string[]): string[] {
    return paths.map((path, index, arr) => {
      let formattedKey: string;
      const prependNav = 'NAV.';
      if (path === 'edit') return this.translate.instant('EDIT');
      switch (index) {
        case 0:
          formattedKey = `${prependNav}${path.toUpperCase().replace(/\-/g, '_')}.TITLE`;
          return this.translate.instant(formattedKey);
        case 1:
          formattedKey = prependNav.concat(
            arr
              .slice(0, index + 1)
              .map(el => el.toUpperCase().replace(/\-/g, '_'))
              .join('.'),
          );
          return this.translate.instant(formattedKey);
        case 3:
          formattedKey = TabsUtils.getTabTitle(arr[1], arr[3]);
          return this.translate.instant(formattedKey);
        default:
          return path.charAt(0).toUpperCase() + path.slice(1).replace(/\-/g, ' ');
      }
    });
  }
}
