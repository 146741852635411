import { Injectable } from '@angular/core';
import { ExpertSearchParseService } from './expert-search-parse.service';

@Injectable()
export class ExpertSearchParseAgreementGroupService extends ExpertSearchParseService {
  constructor() {
    const AttributesMapAgreementGroup = {
      groupId: 'attributes.id',
      owner: 'partyName.attributes.name',
      description: 'attributes.description',
    };
    const attributesEnumMapAgreementGroup = {};
    super(attributesEnumMapAgreementGroup, AttributesMapAgreementGroup);
  }
}
