import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DateTimeUtils, Repertoire, TerritoryUtils } from '@ice';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import { REPERTOIRE_TYPES } from 'config/constants/repertoires.constants';
import { TerritoryDataType } from 'config/constants/territories.constants';
import { SectionsConfig } from 'config/sections-config';
import { get } from 'lodash';
import { map } from 'rxjs/operators';
import * as fromRoot from 'store/root';
import { SectionTabConfig } from '../../tabs-data';

export class TabRepertoireDetail implements SectionTabConfig {
  constructor(private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService, private store: Store<fromRoot.RootState>) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  getConf(): IceGroupComponent[] {
    const attributesModel = this.store.pipe(
      select(fromRoot.getRepertoireAttributes),
      map(repertoireAttributes => DateTimeUtils.cleanObjectIndefiniteDateToAlias(repertoireAttributes)),
    );
    const TOP_CARDS_HEIGHT = '310px';
    const BOTTOM_CARDS_HEIGHT = '120px';

    return [
      {
        group: [
          {
            type: 'cardWithForm',
            config: {
              class: 'card-repertoire-details',
              title: this.getTranslate('DETAILS_CARD.', 'TITLE'),
              model: attributesModel,
              resetAvailable: false,
              submitAvailable: false,
              formBuilder: this.getDetailsFormly(),
              height: TOP_CARDS_HEIGHT,
            },
          },
          {
            type: 'cardWithForm',
            config: {
              class: 'card-repertoire-details',
              title: this.getTranslate('TERMS_CARD.', 'TITLE'),
              model: attributesModel,
              resetAvailable: false,
              submitAvailable: false,
              formBuilder: this.getTermsFormly(),
              height: TOP_CARDS_HEIGHT,
            },
          },
          {
            type: 'cardWithForm',
            isHidden: attributesModel.pipe(map((data: Repertoire) => (data.type === 'SOCIETY' ? false : true))),
            config: {
              class: 'card-repertoire-details',
              title: this.getTranslate('ROLL_UP_CARD.', 'TITLE'),
              model: attributesModel,
              resetAvailable: false,
              submitAvailable: false,
              formBuilder: this.getRollUpFormly(),
              height: TOP_CARDS_HEIGHT,
            },
          },
        ],
      },
      {
        group: [
          {
            type: 'cardWithForm',
            config: {
              class: 'card-repertoire-details',
              title: this.getTranslate('EXCLUSIONS_CARD.', 'TITLE'),
              model: attributesModel,
              resetAvailable: false,
              submitAvailable: false,
              formBuilder: this.getExclusionsFormly(),
              height: BOTTOM_CARDS_HEIGHT,
            },
          },
          {
            type: 'cardWithForm',
            config: {
              class: 'card-repertoire-details',
              title: this.getTranslate('CLAIM_TYPES_CARD.', 'TITLE'),
              model: attributesModel,
              resetAvailable: false,
              submitAvailable: false,
              formBuilder: this.getClaimTypesFormly(),
              height: BOTTOM_CARDS_HEIGHT,
            },
          },
        ],
      },
    ];
  }

  private getDetailsFormly() {
    const cardTranslatePath = 'DETAILS_CARD.';
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'key',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.getTranslate(cardTranslatePath, 'KEY'), required: false, disabled: true },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'name',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.getTranslate(cardTranslatePath, 'DESCRIPTION'), required: false, disabled: true },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'type',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.getTranslate(cardTranslatePath, 'TYPE'), required: false, disabled: true },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            key: 'ruleSetId',
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.getTranslate(cardTranslatePath, 'RULE_SET_ID'),
              required: false,
              disabled: true,
            },
            expressionProperties: {
              className: model => (model && model.type && model.type !== REPERTOIRE_TYPES.SOCIETY ? 'flex-1 ice-px-0' : 'ice-w-50'),
            },
          },
          {
            className: 'ice-w-50 ice-align-right',
            key: 'opRepertoireAllowed',
            type: 'checkbox',
            templateOptions: {
              type: 'text',
              placeholder: this.getTranslate(cardTranslatePath, 'OP_REPERTOIRE_ALLOWED'),
              required: false,
              disabled: true,
            },
            hideExpression: model => model && model.type && model.type !== REPERTOIRE_TYPES.SOCIETY,
          },
        ],
      },
    ];
  }

  private getTermsFormly() {
    const cardTranslatePath = 'TERMS_CARD.';
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'startDate',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.getTranslate(cardTranslatePath, 'START_DATE'), required: false, disabled: true },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'endDate',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.getTranslate(cardTranslatePath, 'END_DATE'), required: false, disabled: true },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'territoriesText',
            type: 'ice-input',
            templateOptions: {
              type: 'text',
              placeholder: this.getTranslate(cardTranslatePath, 'TERRITORY'),
              required: false,
              disabled: true,
              tooltipDuration: 250,
              tooltipField: 'territoriesTooltipWithoutHtml',
              tooltipText: model =>
                TerritoryUtils.getTerritoriesNamesTooltipText(TerritoryUtils.convertTerritoryArrayElements(get(model, 'territories', []), TerritoryDataType.NAME)),
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'active',
            type: 'checkbox',
            templateOptions: {
              type: 'text',
              label: this.getTranslate(cardTranslatePath, 'ACTIVE'),
              required: false,
              disabled: true,
            },
          },
          {
            className: 'flex-1',
            key: 'unpublishedWriterShares',
            type: 'checkbox',
            templateOptions: {
              type: 'text',
              label: this.getTranslate(cardTranslatePath, 'UNPUBLISHED_WRITER_SHARES'),
              required: false,
              disabled: true,
            },
          },
        ],
      },
    ];
  }

  private getRollUpFormly() {
    const cardTranslatePath = 'ROLL_UP_CARD.';
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'publisherRollup',
            type: 'checkbox',
            templateOptions: {
              type: 'text',
              placeholder: this.getTranslate(cardTranslatePath, 'ROLL_UP'),
              required: false,
              disabled: true,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'rollupExcludeTerritoriesText',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.getTranslate(cardTranslatePath, 'EXCLUDED_TERRITORIES'), required: false, disabled: true },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'publisherRollupToNonSoc',
            type: 'checkbox',
            templateOptions: {
              type: 'text',
              placeholder: this.getTranslate(cardTranslatePath, 'ALLOW_NON_SOCIETY_OP'),
              required: false,
              disabled: true,
            },
          },
        ],
      },
    ];
  }

  private getExclusionsFormly() {
    const cardTranslatePath = 'EXCLUSIONS_CARD.';
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            key: 'hasWorks',
            className: 'ice-w-33 ice-align-center field-labelCheck flex-2',
            type: 'label',
            expressionProperties: {
              'templateOptions.icons': model => ({
                icon: model.hasWorks ? 'check_box' : 'check_box_outline_blank',
                class: 'ice-checkbox-icon',
              }),
            },
            templateOptions: {
              materialType: 'mat-icon-button',
              text: this.getTranslate(cardTranslatePath, 'WORKS'),
              linkTitle: true,
              disabled: true,
              onClick: model => {
                this.store.dispatch(
                  new fromRoot.Go({
                    path: [`copyright/${SectionsConfig.REPERTOIRES.name}/${model.key}/excluded-works`],
                  }),
                );
              },
            },
          },
          {
            key: 'hasAgreements',
            className: 'ice-w-33 ice-align-center field-labelCheck flex-2',
            type: 'label',
            expressionProperties: {
              'templateOptions.icons': model => ({
                icon: model.hasAgreements ? 'check_box' : 'check_box_outline_blank',
                class: 'ice-checkbox-icon',
              }),
            },
            templateOptions: {
              materialType: 'mat-icon-button',
              text: this.getTranslate(cardTranslatePath, 'AGREEMENTS'),
              linkTitle: true,
              disabled: true,
              onClick: model => {
                this.store.dispatch(
                  new fromRoot.Go({
                    path: [`copyright/${SectionsConfig.REPERTOIRES.name}/${model.key}/excluded-agreements`],
                  }),
                );
              },
            },
          },
        ],
      },
    ];
  }

  private getClaimTypesFormly() {
    const cardTranslatePath = 'CLAIM_TYPES_CARD.';
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'ice-w-33 ice-align-center',
            key: 'isDirectClaimType',
            type: 'checkbox',
            templateOptions: {
              type: 'text',
              placeholder: this.getTranslate(cardTranslatePath, 'DIRECT_CLAIMS'),
              required: false,
              disabled: true,
            },
          },
          {
            className: 'ice-w-33 ice-align-center',
            key: 'isDerivedClaimType',
            type: 'checkbox',
            templateOptions: {
              type: 'text',
              placeholder: this.getTranslate(cardTranslatePath, 'DERIVED_CLAIMS'),
              required: false,
              disabled: true,
            },
          },
          {
            className: 'ice-w-33 ice-align-center',
            key: 'isAssumedClaimType',
            type: 'checkbox',
            templateOptions: {
              type: 'text',
              placeholder: this.getTranslate(cardTranslatePath, 'ASSUMED_CLAIMS'),
              required: false,
              disabled: true,
            },
          },
        ],
      },
    ];
  }

  private getTranslate(cardTranslatePath, fieldTranslatePath) {
    return this.translate.instant(`REPERTOIRES.DETAILS.${cardTranslatePath}${fieldTranslatePath}`);
  }
}
