import { TranslateService } from '@ngx-translate/core';
import { CUSTOM_ERROR_TYPES, TRANSLATION_PREFIX } from 'config/constants/response-errors-constants';
import { find, forEach, get, isEmpty, isString, some, map } from 'lodash';
import { CubeRegisterResponseEvent } from 'models/response/cube-register.response';

export class ErrorsUtils {
  static getErrors(events: CubeRegisterResponseEvent[], translate: TranslateService, noEllipsis = false, onlyTransactionErrors = false) {
    const errors = [];
    forEach(events, event => {
      if (!isEmpty(event?.data?.exceptions)) {
        this.addErrors(event.data.exceptions, errors, translate, noEllipsis);
      } else if (!isEmpty(event?.exceptions)) {
        this.addErrors(event.exceptions, errors, translate, noEllipsis);
      } else if (!isEmpty(event.constraintViolations)) {
        const transactionRuleErrors = (event.constraintViolations || []).filter(error => error.name === 'TransactionRuleViolation');
        const constraintViolationsErrors = onlyTransactionErrors && transactionRuleErrors.length > 0 ? transactionRuleErrors : event.constraintViolations;
        this.addErrors(constraintViolationsErrors, errors, translate, noEllipsis);
      }
    });
    return errors;
  }

  static addErrors(ErrorsObject, errors, translate, noEllipsis = false) {
    forEach(ErrorsObject, errorMessage => {
      errors.push(this.addError(errorMessage, translate, noEllipsis));
    });
  }

  static isResponseError(response) {
    if (response) {
      const results = get(response, 'results', '');
      if (results.indexOf?.('error') > -1) {
        return true;
      }

      const status = get(response, 'status', '');
      if (status) {
        return status !== 'success';
      }
      if (response.id) {
        return false;
      } // ORG, USERS and GROUPS that use the data-api return the object without mastering
    }
    return true;
  }

  static addError(item, translate, noEllipsis = false) {
    const customError = this.findAndSetCustomErrors(item.message);
    const textTotranslate = customError && (translate ? this.addCustomError(customError.translateKey, translate) : `${TRANSLATION_PREFIX}${customError.translateKey}`);
    return textTotranslate || (noEllipsis ? item.message : `${item.message.substring(0, 80)} ...`);
  }

  static getResponseErrors(response, translate?, getAllErrors = false, noEllipsis = false) {
    if (response) {
      if (response?.type === 'error') {
        return response.type;
      }
      if (isString(response)) {
        return response;
      }
      const { status, results, events, message, error, causes, statusCode } = response;
      const hasResultErrors = results && some(results, result => result.status === 'error');
      if (hasResultErrors) {
        return [`There's been some errors in the request. Please check the details.`];
      }
      if (!this.checkValidStatusResponseCode(status, statusCode) && (error?.causes || causes)) {
        return [error?.message || message, ...(causes || error?.causes || [])];
      } else if (error) {
        let errors = error.events ? ErrorsUtils.getErrors(error.events, translate, noEllipsis) : [];
        if (errors.length === 0 && error.error) {
          // some responses have error.error object
          errors = error.error.events ? ErrorsUtils.getErrors(error.error.events, translate, noEllipsis) : [];
        } else if (errors.length === 0 && isString(error)) {
          errors = [this.addCustomError(error, translate)];
        } else if (get(error, 'statusMessage')) {
          errors = [error.statusMessage];
        } else if (get(error, 'message')) {
          errors = [this.addError(error, translate, noEllipsis)];
        } else if (translate) {
          errors = [translate.instant('ERROR.UPDATE')];
        }
        return errors[0];
      } else if (results) {
        const resultsError = find(results, resultType => resultType === 'error');
        if (resultsError) {
          const errors = events ? ErrorsUtils.getErrors(events, translate, noEllipsis) : [];
          return errors[0];
        }
      } else if (status === 'error') {
        const errors = events ? ErrorsUtils.getErrors(events, translate, noEllipsis, true) : [];
        return getAllErrors ? errors : errors[0];
      } else if (!this.checkValidStatusResponseCode(status, statusCode)) {
        return message || ErrorsUtils.getGenericError(translate);
      }
    }
    return null;
  }

  private static checkValidStatusResponseCode(status: any, statusCode: any) {
    const responseCode = status || statusCode;
    const invalidDigitResponse = ['4', '5'];
    return !invalidDigitResponse?.includes(responseCode?.toString()[0]);
  }

  static addCustomError(error, translate) {
    const instantText = `POPUP.CUSTOM_ERRORS.${error.toUpperCase()}`;
    const translateText = translate && translate.instant(instantText);
    return instantText === translateText ? error : translateText;
  }

  static findAndSetCustomErrors(error) {
    return CUSTOM_ERROR_TYPES.find(customError => error.toLowerCase().includes(customError.textToFind));
  }

  static getGenericError(translate) {
    return [translate?.instant(`ERROR.UPDATE`)];
  }

  static parseResponseCustomError(translate: any, translationPrefix: string, responseCustomErrorsObj: any, error: string): string {
    if (error.includes(translationPrefix) && responseCustomErrorsObj.hasOwnProperty(error.replace(translationPrefix, ''))) {
      return translate.instant(`RESPONSE_CUSTOM_ERRORS.${error.replace(translationPrefix, '')}`);
    }
    return error;
  }
}
