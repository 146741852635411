import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { DataTableComponent } from '@ice';
import { DirectivesModule } from '@ice/directives/directives.module';
import { IceMaterialModule } from '@ice/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AngularResizedEventModule } from 'angular-resize-event';
import { CustomIconWithHtmlToolTipModule } from '../custom-icon-with-html-tooltip/custom-icon-with-html-tooltip.module';
import { DatatableSelectModule } from '../datatable-select/datatable-select.module';
import { EditableTextModule } from '../editable-text/editable-text.module';
import { TableComponent } from './table.component';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    TranslateModule,
    IceMaterialModule,
    NgxDatatableModule,
    FlexLayoutModule,
    EditableTextModule,
    DatatableSelectModule,
    CustomIconWithHtmlToolTipModule,
    DirectivesModule,
    AngularResizedEventModule,
  ],
  declarations: [TableComponent],
  exports: [TableComponent],
})
export class TableModule {}
