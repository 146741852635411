import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  CopyrightUtils,
  CurrentOrganization,
  DateTimeUtils,
  Search,
  SearchAndExpression,
  SearchExpression,
  SearchMatchExpression,
  SearchOrExpression,
  SearchResult,
  SearchUtils,
  StringUtils,
  TerritoryDetail,
  TerritoryUtils,
} from '@ice';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SEARCH_TYPE_DASHBOARD } from 'config/constants/counter-claims.constants';
import { EMPTY_RESULTSET } from 'config/constants/global.constants';
import { agreementsSearchMask, ipsSearchMask, repertoiresSearchMask, societiesSearchMask, worksSearchMask } from 'config/constants/masked-ids.constants';
import { DEFAULT_SEARCH_FROM, DEFAULT_SEARCH_SIZE } from 'config/constants/search.constants';
import type { SearchExpressions } from 'config/search-form-builders/form-config';
import { ActionsSearchExpressions } from 'config/search-form-builders/search-conflicts-actions';
import { ConflictsSearchExpressions } from 'config/search-form-builders/search-conflicts-activity';
import { ConflictsAgreementConflictSearchExpressions } from 'config/search-form-builders/search-conflicts-agreement-conflict';
import { CounterClaimsSearchExpressions } from 'config/search-form-builders/search-conflicts-counter-claims';
import { CopyrightAgreementGroupSearchExpressions } from 'config/search-form-builders/search-copyright-agreement-group';
import { IpsSearchExpressions } from 'config/search-form-builders/search-copyright-ips';
import { RepertoiresSearchExpressions } from 'config/search-form-builders/search-copyright-repertoires';
import { SocietiesSearchExpressions } from 'config/search-form-builders/search-copyright-societies';
import { AgreementsSearchExpressions } from 'config/search-form-builders/search-copyright.agreements';
import { TerritoriesSearchExpressions } from 'config/search-form-builders/search-copyright.territories';
import { WorksSearchExpressions } from 'config/search-form-builders/search-copyright.works';
import { ReportsSearchExpressions } from 'config/search-form-builders/search-reports';
import { OrganizationsSearchExpressions } from 'config/search-form-builders/search-user-management-organizations';
import { UsersSearchExpressions } from 'config/search-form-builders/search-user-management-users';
import { SectionConfig, SectionsConfig } from 'config/sections-config';
import { SectionCopyrightAgreements } from 'config/sections-config/section-copyright-agreements';
import { SectionCopyrightWorks } from 'config/sections-config/section-copyright-works';
import { filter, get, isObject, keys } from 'lodash';
import { PageableData } from 'models/response/cube-register.response';
import moment from 'moment';
import { Observable, Subject, of, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CommonApiService } from 'services/common-api.service';
import { IceMaskedIdsConfig } from 'services/masked-ids/masked-ids.model';
import { RootState } from 'store/root';
import { SearchUtilsFactory } from 'store/root/utils';
import { environment } from 'config/env';
import { AuthService } from '../auth/auth.service';
import { MaskedIdsService } from '../masked-ids/masked-ids.service';

@Injectable()
export class SearchService extends CommonApiService {
  private requests: Map<string, any>;

  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    protected store: Store<RootState>,
    protected route: ActivatedRoute,
    protected maskedIdsService: MaskedIdsService,
    protected actions$: Actions,
  ) {
    super(http, authService, store, route, maskedIdsService, actions$);
    this.requests = new Map<string, any>();
  }

  getSearchResults(sectionConfig?: SectionConfig, options?: any, currentOrganization?: CurrentOrganization, allUsers = null): Observable<SearchResult> {
    const { ns, size, from, publisherList, sort, expertQueryParams, xrefList, customMaskedIdsConfig, source, ...data } = options;
    const apiIncludes = CopyrightUtils.getSectionData(sectionConfig.name).apiIncludes;
    let include = options.include !== undefined ? options.include : source === SEARCH_TYPE_DASHBOARD ? get(apiIncludes, 'dashboard', '') : get(apiIncludes, 'search', '');
    let currentSort = sort;
    let searchExpressions: SearchExpressions;
    let maskedIdsConfig: IceMaskedIdsConfig[];
    if (!expertQueryParams) {
      switch (sectionConfig.name) {
        case SectionsConfig.WORKS.name:
          maskedIdsConfig = worksSearchMask;
          searchExpressions = new WorksSearchExpressions();
          if (data.agreementId && !data.contributorsAgreementId) {
            include = SectionCopyrightAgreements.apiIncludes.search;
            currentSort = '';
          }
          break;
        case SectionsConfig.IPS.name:
          maskedIdsConfig = ipsSearchMask;
          searchExpressions = new IpsSearchExpressions();
          break;
        case SectionsConfig.REPERTOIRES.name:
          maskedIdsConfig = repertoiresSearchMask;
          searchExpressions = new RepertoiresSearchExpressions();
          break;
        case SectionsConfig.AGREEMENTS.name:
          maskedIdsConfig = agreementsSearchMask;
          searchExpressions = new AgreementsSearchExpressions();
          break;
        case SectionsConfig.AGREEMENT_GROUP.name:
          searchExpressions = new CopyrightAgreementGroupSearchExpressions();
          break;
        case SectionsConfig.SOCIETIES.name:
          maskedIdsConfig = societiesSearchMask;
          searchExpressions = new SocietiesSearchExpressions();
          break;
        case SectionsConfig.TERRITORIES.name:
          searchExpressions = new TerritoriesSearchExpressions();
          break;
        case SectionsConfig.CONFLICTS.name:
          searchExpressions = new ConflictsSearchExpressions();
          break;
        case SectionsConfig.AGREEMENTCONFLICT.name:
          searchExpressions = new ConflictsAgreementConflictSearchExpressions();
          break;
        case SectionsConfig.COUNTERCLAIMS.name:
          if (source === SEARCH_TYPE_DASHBOARD) {
            searchExpressions = new ActionsSearchExpressions();
          } else {
            searchExpressions = new CounterClaimsSearchExpressions();
          }
          break;
        case SectionsConfig.COUNTERCLAIMS_ACTIONS.name:
          searchExpressions = new ActionsSearchExpressions();
          break;
        case SectionsConfig.ORGANIZATIONS.name:
          searchExpressions = new OrganizationsSearchExpressions();
          break;
        case SectionsConfig.USERS.name:
          searchExpressions = new UsersSearchExpressions();
          break;
        case SectionsConfig.REPORTS.name:
          searchExpressions = new ReportsSearchExpressions();
          break;
      }
    } else {
      if (sectionConfig.name === SectionsConfig.WORKS.name) {
        include = get(apiIncludes, 'expertSearch', '');
      }
    }

    // Enable custom masked Ids
    maskedIdsConfig = customMaskedIdsConfig || maskedIdsConfig;
    if (searchExpressions || expertQueryParams) {
      let userNs = null;
      let dataWithExtras = data;
      if (sectionConfig.name === SectionsConfig.USERS.name) {
        // User advanced search by organizationId
        const orgId = get(dataWithExtras, 'organizationId', null);

        if (orgId) {
          const orgIdSplitted = orgId.split(':');
          userNs = orgIdSplitted[orgIdSplitted.length - 1];
        } else {
          // SAYT requirement
          if (currentOrganization) {
            dataWithExtras = { ...dataWithExtras, organizationId: currentOrganization.id };
          }
          userNs = (currentOrganization?.id !== 'ICE:ICE' && ns) || '*';
        }
      }
      if (sectionConfig.name === SectionCopyrightWorks.name && dataWithExtras.agreementId && dataWithExtras.contributorsAgreementId) {
        delete dataWithExtras.agreementId;
      }

      const hasSearchParameters = keys(dataWithExtras).length > 0;
      const hasExtraSearchExpression = keys(searchExpressions?.getExtraSearchExpression()).length > 0;
      let searchObject =
        expertQueryParams || ((hasSearchParameters || hasExtraSearchExpression) && SearchUtils.generateExpressionCubeData(dataWithExtras, searchExpressions, xrefList, allUsers));

      if (searchObject && filter(searchObject['and'], o => o['result'] === EMPTY_RESULTSET).length > 0) {
        // To fake an empty result set from searchExpressions UI-954
        const expanded0Subject: Subject<any> = new Subject<any>();
        const obs = expanded0Subject.asObservable();
        setTimeout(() => expanded0Subject.next({ items: [], total: 0 }), 1000);
        return obs;
      }
      const requiredQueryParams = SearchUtils.getRequiredSearchFields(sectionConfig.name, ns, source);
      searchObject = SearchUtils.joinRequiredSearchFields(requiredQueryParams, searchObject);
      const namespace = userNs || (searchExpressions && searchExpressions['alternativeNS']) || ns;
      const request = { size: (size === undefined && DEFAULT_SEARCH_SIZE) || size, from: from || DEFAULT_SEARCH_FROM };
      if (include && include !== '') {
        request['include'] = include;
      }
      if (sort && sort !== '') {
        request['sort'] = currentSort;
      }
      if (sectionConfig.name === SectionsConfig.IPS.name) {
        request['followRedirects'] = false;
      }
      const extraCall = (!expertQueryParams && searchExpressions?.getExtraCall(this, dataWithExtras)) || of({});
      return extraCall.pipe(
        catchError(error => throwError(get(error, 'error.error', null))),
        mergeMap(extraCallResponse => {
          const formattedSearchObject = (!expertQueryParams && searchExpressions?.extraCallDataCleaner(extraCallResponse, dataWithExtras, searchObject)) || searchObject;
          return this.postRequestCubeData<HttpResponse<SearchResult>>(
            SearchUtilsFactory.getSearchUrl(sectionConfig, namespace, dataWithExtras),
            request,
            formattedSearchObject,
            maskedIdsConfig,
          );
        }),
      );
    }
  }

  getSearchParams(payload, section, ns, userNs) {
    const { search, addRows, downloadQueue } = payload;
    const { term, params, expertQueryParams, size, from: pageFrom, sort } = search;
    const queryParams = params ? SearchUtilsFactory.formatParamsAdvancedSearch(params, section) : { term };
    const searchNs = this.getSearchNs(section, ns, userNs, expertQueryParams);
    const sectionData = CopyrightUtils.getSectionData(section);
    const xrefList = params && Object.keys(params).includes('xref0');
    return { addRows, downloadQueue, queryParams, sectionData, expertQueryParams, searchNs, pageFrom, size, sort, xrefList, search };
  }

  getSearchNs(section, ns, userNs, expertQueryParams) {
    return section === SectionsConfig.ORGANIZATIONS.name || section === SectionsConfig.USERS.name
      ? expertQueryParams
        ? '*'
        : userNs
      : section === SectionsConfig.SOCIETIES.name && expertQueryParams
      ? 'CISAC'
      : ns;
  }

  public getTerritoriesCountries(codes): Observable<any> {
    const request = this.requests.get(codes);
    if (request) {
      return request.data !== null ? of(request.data) : request.observable;
    }
    const cachingRequest = { data: null, observable: null };
    this.requests.set(codes, cachingRequest);
    const codesArray = Array.isArray(codes) ? codes : TerritoryUtils.splitTerritoryStringCodes(codes);
    cachingRequest.observable = this.postRequestCubeData<HttpResponse<PageableData<TerritoryDetail>>>(environment.territoriesApiUrl, {}, codesArray).pipe(
      map(res => {
        const oldRequestStructure = { tisns: res };
        cachingRequest.observable = null;
        cachingRequest.data = oldRequestStructure;
        return oldRequestStructure;
      }),
      catchError((error, caught) => {
        cachingRequest.observable = null;
        cachingRequest.data = false;
        return of(false); // error.status = 400 --> bad request, not found incl/excl of codes
      }),
    );
    return cachingRequest.observable;
  }

  public getShortenTerritory(codes: number[] | string[], territoryDate?: string): Observable<any> {
    const date = territoryDate ? DateTimeUtils.formatDate(moment(territoryDate)) : DateTimeUtils.getTodayFormatted();
    const url = `${environment.apiUrlCubeData}/territories/shorten?effectiveDate=${date}`;
    return this.postRequestCubeData<HttpResponse<number[]>>(url, {}, codes);
  }

  private generateExpression(data: any): SearchExpression {
    const dataKeys = Object.keys(data);
    const isSingleMatch = dataKeys.length === 1 && !isObject(data[dataKeys[0]]);
    let exp: SearchExpression = isSingleMatch ? new SearchMatchExpression() : new SearchAndExpression();

    Object.entries(data).forEach(([key, value]) => {
      if (value) {
        // prevent empty values
        if (isObject(value)) {
          exp['and'].push(this.generateExpression(value));
        } else {
          let newExp: SearchExpression;
          if (`${value}`.split(',').length > 1) {
            newExp = new SearchOrExpression();
            newExp['or'] = this.addMatchValuesToExpression(key, `${value}`.split(','));
          } else if (`${value}`.split('+').length > 1) {
            newExp = new SearchAndExpression();
            newExp['and'] = this.addMatchValuesToExpression(key, `${value}`.split('+'));
          } else {
            newExp = new SearchMatchExpression();
            newExp['match'][`${key}`] = `${value}`;
          }

          if (isSingleMatch) {
            exp = newExp;
          } else {
            exp['and'].push(newExp);
          }
        }
      }
    });
    return exp;
  }

  private addMatchValuesToExpression(key: string, matchListValues: string[]): SearchMatchExpression[] {
    const matchList: SearchMatchExpression[] = [];
    Object.entries(matchListValues).forEach(([i, matchValue]) => {
      const match = new SearchMatchExpression();
      match.match[`${key}`] = `*${matchValue}*`;
      matchList.push(match);
    });
    return matchList;
  }

  public customSearch(url: string, request: any, body: any, maskParams?: IceMaskedIdsConfig[]): Observable<any> {
    return this.postRequestCubeData(url, request, body, maskParams);
  }

  public formatSearchParamsBySearchMode(section, queryParamsRaw, xrefList) {
    const advancedSearch = queryParamsRaw && !queryParamsRaw.term;
    const expert = queryParamsRaw && queryParamsRaw.expert;
    const term = queryParamsRaw.term ? queryParamsRaw.term : '';
    const paramXrefList = queryParamsRaw && queryParamsRaw.xrefList;
    let params;
    if (!advancedSearch && !paramXrefList) {
      params = new SearchUtilsFactory(section).getFormatted(StringUtils.removeExtraSpaces(term));
      params = {
        ...params,
        ...SearchUtils.getSAYTDefaultExtraSearchFields(section),
        ...SearchUtils.formatSAYTPrefixField(section, term),
      };
    } else {
      if (!expert && !paramXrefList) {
        params = SearchUtils.formatAdvancedSearchBooleans(queryParamsRaw);
      } else if (paramXrefList) {
        params = SearchUtils.formatXrefList(xrefList);
      }
    }
    const query = queryParamsRaw.query;

    const sectionData = CopyrightUtils.getSectionData(section);
    const size = sectionData.customSearchSize || DEFAULT_SEARCH_SIZE;
    const search: Search = expert
      ? { term, query, size, from: DEFAULT_SEARCH_FROM, sort: sectionData.searchServerSideSortDefault }
      : { term, params, size, from: DEFAULT_SEARCH_FROM, sort: sectionData.searchServerSideSortDefault };
    return { expert, term, params, search, sectionData };
  }

  existAgreementReference(value: string, ns = 'CUBE'): Promise<any> {
    const url = `${SectionCopyrightAgreements.baseUrlCubeData}/${SectionsConfig.AGREEMENTS.apiSegment}/${ns}/${this.formatKeyValue(value)}`;
    return this.existReference(url);
  }

  existLegacyAgreementReference(value: string): Promise<any> {
    const url = `${SectionCopyrightAgreements.baseUrlCubeData}/agreement-legacy-ids/${this.formatKeyValue(value)}`;
    return this.existReference(url);
  }

  formatKeyValue(value: string): string {
    return StringUtils.isContainsLetters(value) ? value : `ICE:${value}`;
  }
}
