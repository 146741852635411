import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, LoggedGuard } from './guards';
import { PermissionsGuard } from './guards/permissions/permissions.guard';

const routes: Routes = [
  {
    path: 'copyright',
    canActivate: [AuthGuard, PermissionsGuard],
    loadChildren: () => import('app/sections/section-copyright/section-copyright.module').then(m => m.SectionCopyrightModule),
  },
  {
    path: 'conflicts',
    canActivate: [AuthGuard, PermissionsGuard],
    loadChildren: () => import('app/sections/section-conflicts/section-conflicts.module').then(m => m.SectionConflictsModule),
  },
  {
    path: 'user-management',
    canActivate: [AuthGuard, PermissionsGuard],
    loadChildren: () => import('app/sections/section-user-management/section-user-management.module').then(m => m.SectionUserManagementModule),
  },
  {
    path: 'login',
    canActivate: [LoggedGuard],
    loadChildren: () => import('../auth/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('../auth/federated/federated.module').then(m => m.FederatedModule),
  },
  {
    path: 'new-password',
    canActivate: [LoggedGuard],
    loadChildren: () => import('../auth/new-password/new-password.module').then(m => m.NewPasswordModule),
  },
  {
    path: 'forgot',
    canActivate: [LoggedGuard],
    loadChildren: () => import('../auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: '',
    redirectTo: '/copyright/works',
    pathMatch: 'full',
  },
  { path: '**', redirectTo: '/copyright/works' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
