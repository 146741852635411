import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromRoot from 'store/root';

export class UsersFacade {
  constructor(private store: Store<fromRoot.RootState>) {}

  getUsers(): Observable<any> {
    return this.store.pipe(
      select(fromRoot.getUsers),
      map(user => cloneDeep(user)),
    );
  }

  getUserDetailId(): Observable<any> {
    return this.store.pipe(select(fromRoot.getUserDetailId));
  }
}
