import { TerritoryDetail, TerritoryUtils } from '@ice';
import { createSelector } from '@ngrx/store';
import * as fromFeature from 'store/root/reducers';
import * as fromReducer from 'store/root/reducers/copyright/copyright.reducer';

export const getCopyrightTerritory = createSelector(fromFeature.getCopyrightFeatureState, fromReducer.getCopyrightTerritoryState);

export const getTerritoryDetailTerritories = createSelector(getCopyrightTerritory, (detail: TerritoryDetail) =>
  detail && detail.territories && detail.territories.length ? detail.territories : [],
);

export const getTerritoryLanguageNames = createSelector(getCopyrightTerritory, (detail: TerritoryDetail) =>
  detail && detail.territories ? TerritoryUtils.getLanguageNames(detail.territories) : [],
);

export const getCopyrightDetailTerritoriesList = createSelector(getCopyrightTerritory, (detail: TerritoryDetail) =>
  detail && detail.byTisA && detail.byTisA.length ? detail.byTisA : [],
);

export const getTerritoryIncludedCountries = createSelector(getCopyrightTerritory, (detail: TerritoryDetail) =>
  detail && detail.includedCountries && detail.includedCountries.length ? detail.includedCountries : [],
);

export const getTerritorySuccessors = createSelector(getCopyrightTerritory, (detail: TerritoryDetail) =>
  detail && detail.successorsList && detail.successorsList.length ? detail.successorsList : [],
);

export const getTerritoryPredecessors = createSelector(getCopyrightTerritory, (detail: TerritoryDetail) =>
  detail && detail.predecessorsList && detail.predecessorsList.length ? detail.predecessorsList : [],
);

export const getTerritoryDateFilter = createSelector(getCopyrightTerritory, (detail: TerritoryDetail) =>
  detail && detail.territoriesDateFilter ? detail.territoriesDateFilter : '',
);

export const getQueryTerritorySuccessors = createSelector(getCopyrightTerritory, (detail: TerritoryDetail) =>
  detail && detail.territoriesList ? detail.territoriesList.successors : [],
);

export const getQueryTerritoryPredecessors = createSelector(getCopyrightTerritory, (detail: TerritoryDetail) =>
  detail && detail.territoriesList ? detail.territoriesList.predecessors : [],
);

export const getTerritoryIncludedCountriesMembers = createSelector(getCopyrightTerritory, (detail: TerritoryDetail) => detail?.includedCountriesMembers?.items || []);
