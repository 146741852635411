import { ClaimGraphUtils, CopyrightUtils, TerritoryUtils } from '@ice';
import { AgreementWorksType } from 'config/constants/agreements.constants';
import { CountersQueryPublishersPattern } from 'config/constants/counter-claims.constants';
import { OrganizationType } from 'config/constants/organizations.constants';
import { CLAIM_TYPES } from 'config/constants/repertoires.constants';
import { TerritoryDataType } from 'config/constants/territories.constants';
import { cloneDeep, get, isEqual, remove } from 'lodash';
import moment from 'moment';
import { createLabelResolver } from '../api-call.factory';

export const transferAgreementWorksApiCall = createLabelResolver({ userId: (detail, user) => user.detail.id });

export const fetchAgreementWorksUploadURLLabelResolver = createLabelResolver({
  agreementId: `attributes.id`,
  userId: (detail, user) => user.detail.id,
  type: (detail, user, tabName) => (tabName === 'included-works' ? AgreementWorksType.Inclusion : AgreementWorksType.Exclusion),
});

export const updateCounterclaimLabelResolver = createLabelResolver({ counterclaimId: `id` });

export const getClaimGraphCounterclaimLabelResolver = createLabelResolver({
  id: `workId`,
  filter: detail =>
    ClaimGraphUtils.getClaimFilter(
      TerritoryUtils.convertTerritoryStringElements(
        Array.isArray(detail?.territories) && detail?.territories?.length > 1 ? detail?.territories : detail?.territories?.toString().split(' '),
        TerritoryDataType.TISN,
      ),
      moment().format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD'),
      '',
      '',
      detail?.rawItem?.attributes?.claimStatuses || Object.values(CLAIM_TYPES),
    ),
});

export const editAgreementWorksLabelResolver = createLabelResolver({ agreementId: `attributes.key` });

export const deleteAgreementWorksLabelResolver = createLabelResolver({ agreementId: `attributes.key` });

export const getIpWorksLabelResolver = createLabelResolver({ id: `id` });

export const getDashboardPatternLabelResolver = createLabelResolver({
  pattern: (detail, user, tabName) => {
    return user.currentOrganization.type === OrganizationType.publisher
      ? CountersQueryPublishersPattern
      : `{"equals": { "counterclaimParticipant.claimant.relations[XREF].otherId" : "<<id>>"}}`;
  },
  id: (detail, user, tabName) => user?.currentOrganization?.accessPartyNames,
});

export const getAuditHistoryLabelResolver = createLabelResolver({ id: `attributes.id`, lastFilter: `lastAuditFilterQuery` });

export const getAuditHistoryIPILabelResolver = createLabelResolver({
  id: detail => {
    const id = detail?.baseInformation?.[0].baseIPINumber || detail?.parties?.[0]?.partyId;
    return id || ' ';
  },
});

export const getFullAuditHistoryLabelResolver = createLabelResolver({ auditTypes: `auditTypes`, lastFilter: `lastAuditFilterQuery` });

export const getPartyNamesLabelResolver = createLabelResolver({
  partyNames: detail => {
    const partyNames = detail.baseParty?.partyNames?.map(partyName => `{"wildcard": {"attributes.typeId": "*${partyName.partyNameId}*"}}`).join(',');
    return partyNames || ` `;
  },
});

export const getAuditHistoryWorkCounterClaimsLabelResolver = createLabelResolver({
  pattern: detail => {
    const counterclaims = detail.counterclaims;
    const counterclaimQuery =
      counterclaims &&
      counterclaims.length > 0 &&
      counterclaims.map(counterclaim => `{"wildcard": {"attributes.typeId": "*_${counterclaim?.attributes?.id || counterclaim?.id}_*"}}`).join(',');
    return counterclaimQuery
      ? `
        ,{ "and":
          [
            {
              "equals": {
                  "events.nameId": "60101"
              }
            },
            {
                "equals": {
                    "attributes.type": "counterclaim"
                }
            },
            {
              "or": [
                ${counterclaimQuery}
              ]
            }
          ]
        }
      `
      : ` `;
  },
});

export const getAgreementsGroupLabelResolver = createLabelResolver({
  pattern: detail => {
    const agreements = detail.agreements;
    const agreementsQuery =
      agreements &&
      agreements.length > 0 &&
      agreements.map(agreement => `{"wildcard": {"attributes.typeId": "*_${CopyrightUtils.clearNameSpace(agreement?.agreementId)}_*"}}`).join(',');
    return agreementsQuery
      ? `
        ,{ "and":
          [
            {
              "or": [
                ${agreementsQuery}
              ]
            },
            {
              "equals": {
                  "attributes.type": "Agreement"
              }
            },
            {
              "or": [
                  {
                      "equals": {
                          "events.nameId": "20703"
                      }
                  },
                  {
                      "equals": {
                          "events.nameId": "20704"
                      }
                  }
              ]
            }
          ]
        }
      `
      : ` `;
  },
});

export const getAuditHistoryCounterClaimsLabelResolver = createLabelResolver({
  pattern: detail => {
    const auditTypes = cloneDeep(get(detail, 'auditTypes'));
    const actionsList = get(detail, 'actionsList', []);
    if (!actionsList.length) {
      remove(auditTypes, type => isEqual(type, 'action'));
    }
    return auditTypes?.map(type => `{"and":[{"equals":{"attributes.type":"${type}"}}, {"or":[<<${type}>>]}]}`).join(',');
  },
  counterclaim: detail => `{"wildcard": {"attributes.typeId": "*_${detail?.attributes?.id || detail?.id}_*"}}`,
  action: detail =>
    get(detail, 'actionsList')
      ?.map(action => `{"wildcard":{"attributes.typeId":"*${CopyrightUtils.getKeySuffix(action?.id)}*"}}`)
      ?.join(','),
});
