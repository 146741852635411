import { Injectable } from '@angular/core';
import { BooleanType, OriginType, PurposeType, WorkStatusType } from 'config/constants/global.constants';
import moment from 'moment';
import { ExpertSearchCommonFunctions } from './expert-search-common-functions';
import { ExpertSearchParseService, IceQueryLanguageTokenStates } from './expert-search-parse.service';
import { SearchService } from './search.service';

@Injectable()
export class ExpertSearchParseWorksService extends ExpertSearchParseService {
  constructor(private searchService: SearchService) {
    super(
      {
        ['workType']: Object.keys(OriginType),
        ['intendedPurpose']: Object.keys(PurposeType),
        ['status']: Object.keys(WorkStatusType),
        ['prohibited']: Object.keys(BooleanType),
        ['unauthorizedClaims']: Object.keys(BooleanType),
        ['unauthorizedOwnerships']: Object.keys(BooleanType),
      },
      {
        category: 'attributes.category',
        creatorFirstName: 'contributions.contributor.partyName.attributes.firstName',
        creatorName: 'contributions.contributor.partyName.attributes.name',
        duration: 'attributes.duration',
        genre: 'attributes.genre',
        'instrumentation.code': 'attributes.standardInstrumentation.instrumentCode',
        'instrumentation.numberOfInstruments': 'attributes.standardInstrumentation.numberOfInstruments',
        'instrumentation.numberOfParts': 'attributes.standardInstrumentation.numberOfParts',
        'instrumentation.numberOfPlayers': 'attributes.standardInstrumentation.numberOfPlayers',
        'instruments.code': 'attributes.instruments.instrumentCode',
        'instruments.numberOfInstruments': 'attributes.instruments.numberOfInstruments',
        'instruments.numberOfPlayers': 'attributes.instruments.numberOfPlayers',
        intendedPurpose: 'attributes.purpose',
        ipRole: async (comparator: string, value: string, _deep = false, relatedFields = []) => {
          const valueFormatter = input => {
            const roles = input.split(',').filter(val => val !== '');
            if (roles.length === 0) {
              return input;
            }
            if (roles.length === 1) {
              return `'${roles[0]}'`;
            }
            return roles.reduce((prevRole, currRole, index, array) => {
              const prependStr = index === 0 ? '(' : `${prevRole},`;
              const appendStr = index === array.length - 1 ? ')' : '';
              return `${prependStr}'${currRole}'${appendStr}`;
            }, '');
          };

          if (relatedFields.length === 1) {
            const formatValue = valueFormatter(value);
            const relatedFieldName = relatedFields[0].find(({ tokenType }) => tokenType === IceQueryLanguageTokenStates.FIELD)?.token;
            const relatedFieldValue = relatedFields[0].find(
              ({ tokenType }) => [IceQueryLanguageTokenStates.VALUE, IceQueryLanguageTokenStates.VALUE_DOUBLE_QUOTED].indexOf(tokenType) !== -1,
            )?.token;
            let queryPrefix = `claims[role=${formatValue}]`;
            if (['ipiNumberOfParentClaim', 'ipBaseKeyOfParentClaim', 'ipNameOfParentClaim'].includes(relatedFieldName)) {
              queryPrefix = `claims#A[role=${formatValue}]`;
            } else if (['ipiNumberOfChildClaim', 'ipBaseKeyOfChildClaim', 'ipNameOfChildClaim'].includes(relatedFieldName)) {
              queryPrefix = `claims[role=${formatValue}` + ',parentId = `claims#A.claimId`]';
            }

            if (['ipiNumber', 'ipiNumberOfParentClaim', 'ipiNumberOfChildClaim'].includes(relatedFieldName)) {
              return {
                equals: { [`${queryPrefix}.claimant.partyName.relations[XREF].otherId`]: `IPI:${relatedFieldValue}` },
              };
            } else if (['ipBaseKey', 'ipBaseKeyOfParentClaim', 'ipBaseKeyOfChildClaim'].includes(relatedFieldName)) {
              return {
                equals: { [`${queryPrefix}.claimant.party.relations[XREF].otherId`]: `ICE:${relatedFieldValue}` },
              };
            } else if (['ipName', 'ipNameOfParentClaim', 'ipNameOfChildClaim'].includes(relatedFieldName)) {
              return {
                wildcard: { [`${queryPrefix}.claimant.partyName.attributes.name`]: relatedFieldValue },
              };
            }
          }
          return {
            [comparator]: { 'claims.role': value },
          };
        },
        ipiNameKey: async (comparator: string, value: string, _deep = false) => ({
          [comparator]: { 'contributions.contributor.partyName.relations[XREF].otherId': `ICE:${value}` },
        }),
        ipiNumber: async (comparator: string, value: string, _deep = false) => ({
          [comparator]: { 'claims.claimant.partyName.relations[XREF].otherId': `IPI:${value}` },
        }),
        ipBaseKey: async (comparator: string, value: string, _deep = false) => ({
          [comparator]: { 'claims.claimant.party.relations[XREF].otherId': `ICE:${value}` },
        }),
        ipName: async (comparator: string, value: string, _deep = false) => ({
          [comparator]: { 'claims.claimant.partyName.attributes.name': value },
        }),
        language: 'attributes.language',
        notes: 'admin.attributes.notes.*',
        'otherParty.firstName': 'partyNames.partyName.attributes.firstName',
        'otherParty.name': 'partyNames.partyName.attributes.name',
        'otherParty.type': 'partyNames.role',
        prohibited: 'attributes.prohibited',
        protectUntil: async (comparator: string, value: string, _deep = false) => ({
          and: [{ range: { 'attributes.copyrightOverrides.protectionDate': { lte: moment(value).format('YYYY-MM-DD') } } }],
        }),
        societyCode: 'societies.societyId',
        shareDivisionOwner: 'attributes.shareDivisionOwner',
        sourceOfDocumentation: 'attributes.source',
        status: 'attributes.status',
        title: async (comparator: string, value: string, _deep = false) => ({
          and: [
            {
              or: [{ [comparator]: { 'attributes.titles.*.titleValue': `${value}` } }, { [comparator]: { 'attributes.titles.*.national': `${value}` } }],
            },
          ],
        }),
        unauthorizedClaims: 'claims.claimShares.unauthorized',
        unauthorizedOwnerships: 'claims.ownershipShares.unauthorized',
        workType: 'attributes.origin',
        workXref: 'relations[XREF].otherId',
        publisherWorkXref: async (comparator: string, value: string, _deep = false) => {
          const formattedValue = value.split(':').length === 1 ? `*:${value}` : value;
          return {
            [comparator]: { 'work.relations[XREF].otherId': `PWREF:${formattedValue}` },
          };
        },
        agreementRef: async (comparator: string, value: string, deep = false) =>
          ExpertSearchCommonFunctions.agreementRefFunction(searchService, comparator, value, deep, 'contributions.agreements.agreement'),
        ipiNumberOfParentClaim: async (comparator: string, value: string, _deep = false) => ({
          [comparator]: { 'claims#A.claimant.partyName.relations[XREF].otherId': `IPI:${value}` },
        }),
        ipBaseKeyOfParentClaim: async (comparator: string, value: string, _deep = false) => ({
          [comparator]: { 'claims#A.claimant.party.relations[XREF].otherId': `ICE:${value}` },
        }),
        ipNameOfParentClaim: async (comparator: string, value: string, _deep = false) => ({
          [comparator]: { 'claims#A.claimant.partyName.attributes.name': value },
        }),
        ipiNumberOfChildClaim: async (comparator: string, value: string, _deep = false) => ({
          [comparator]: { 'claims[parentId=`claims#A.claimId`].claimant.partyName.relations[XREF].otherId': `IPI:${value}` },
        }),
        ipBaseKeyOfChildClaim: async (comparator: string, value: string, _deep = false) => ({
          [comparator]: { 'claims[parentId=`claims#A.claimId`].claimant.party.relations[XREF].otherId': `ICE:${value}` },
        }),
        ipNameOfChildClaim: async (comparator: string, value: string, _deep = false) => ({
          [comparator]: { 'claims[parentId=`claims#A.claimId`].claimant.partyName.attributes.name': value },
        }),
      },
      {
        ipRole: ['concat-fields'],
      },
    );
  }
}
