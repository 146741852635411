<ngx-datatable
  #table
  [rows]="model"
  [columns]="to.columns"
  [columnMode]="to.columnMode"
  [rowHeight]="to.rowHeight"
  [headerHeight]="to.headerHeight"
  [footerHeight]="to.footerHeight"
  [limit]="to.limit"
  [scrollbarH]="to.scrollbarH"
  [reorderable]="to.reorderable"
  [externalSorting]="true"
  [selectionType]="'single'"
>
  <ng-template #defaultColumn ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row" let-column="column">
    <div fxLayout="columns">
      <formly-field fxFlex="90" [field]="getField(field, column, rowIndex)" [options]="options"></formly-field>
      <button
        mat-icon-button
        *ngIf="to.columns[to.columns.length - 1] === column && removeButtonEnabled && model.length > 1"
        matTooltip="{{ removeButtonText }}"
        [matTooltipClass]="'ice-tooltip'"
        [matTooltipPosition]="'above'"
        (click)="remove(rowIndex)"
      >
        <mat-icon>remove_circle</mat-icon>
      </button>
    </div>
  </ng-template>

  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset">
      <button mat-button *ngIf="addButtonEnabled" (click)="addItem()"><mat-icon>add_circle</mat-icon> {{ addButtonText }}</button>
      <div class="custom-error">{{ customError }}</div>
    </ng-template>
  </ngx-datatable-footer>
</ngx-datatable>
