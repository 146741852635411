import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DataTableRow } from '@ice/components/data-table/data-table';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { SocietiesUtils } from '@ice/utils/societies/societies.utils';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import { map } from 'rxjs/operators';
import * as fromRoot from 'store/root';
import { SectionTabConfig } from '../../tabs-data';

export class TabSocietyDetail implements SectionTabConfig {
  constructor(private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService, private store: Store<fromRoot.RootState>) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'cardWithForm',
            config: {
              class: 'society-base-information',
              title: this.getTranslate('DETAILS_CARD.', 'TITLE'),
              model: this.store.pipe(
                select(fromRoot.getSocietyAttributes),
                select(society => society && SocietiesUtils.cleanSociety(society)),
              ),
              resetAvailable: false,
              submitAvailable: false,
              formBuilder: this.getDetailsFormly(),
              height: '112px',
            },
          },
        ],
      },
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.getTranslate('RIGHTS_TABLE.', 'TITLE'),
              model: this.store.pipe(
                select(fromRoot.getSocietyRights),
                map(rights => SocietiesUtils.cleanRights(rights, this.translate)),
              ),
              schema: this.getRightsTableSchema(),
              columnMode: 'flex',
              tableWidth: '100',
              maxHeight: 300,
            },
          },
        ],
      },
    ];
  }

  private getDetailsFormly() {
    const cardTranslatePath = 'DETAILS_CARD.';
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-2',
            key: 'socName',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.getTranslate(cardTranslatePath, 'SOCIETY_NAME'), required: false, disabled: true },
          },
          {
            className: 'flex-2',
            key: 'socCode',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.getTranslate(cardTranslatePath, 'SOCIETY_CODE'), required: false, disabled: true },
          },
          {
            className: 'flex-2',
            key: 'territories',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.getTranslate(cardTranslatePath, 'COUNTRIES'), required: false, disabled: true },
          },
          {
            className: 'flex-1',
            key: 'isIce',
            type: 'checkbox',
            templateOptions: { type: 'text', placeholder: this.getTranslate(cardTranslatePath, 'IS_ICE'), required: false, disabled: true },
          },
        ],
      },
    ];
  }

  private getRightsTableSchema(): DataTableRow[] {
    const cardName = 'RIGHTS_TABLE.';
    return [
      { name: this.getTranslate(cardName, 'VALID_FROM'), prop: 'validFrom', flexGrow: 1 },
      { name: this.getTranslate(cardName, 'VALID_TO'), prop: 'validTo', flexGrow: 1 },
      { name: this.getTranslate(cardName, 'ROLE_CODES'), prop: 'roleCodes', flexGrow: 2, tooltip: 'roleCodesTooltip' },
      { name: this.getTranslate(cardName, 'RIGHT_CODE'), prop: 'rightCode', flexGrow: 1 },
      { name: this.getTranslate(cardName, 'RIGHT_DESCRIPTION'), prop: 'rightDescription', flexGrow: 2 },
      {
        name: this.getTranslate(cardName, 'TERRITORIES'),
        prop: 'territoriesText',
        icons: 'territoriesIcon',
        cellClass: 'ice-txt-size-14 agreements-territories',
        tooltip: 'territoriesTooltip',
        tooltipDuration: 250,
        flexGrow: 1,
      },
    ];
  }

  private getTranslate(cardTranslatePath, fieldTranslatePath) {
    return this.translate.instant(`SOCIETIES.DETAILS.${cardTranslatePath}${fieldTranslatePath}`);
  }
}
