import { select } from '@ngrx/store';
import { clone } from 'lodash';
import { map } from 'rxjs/operators';
import * as fromNewSectionItem from 'store/new-section-item';

export class IpSearchDialogsUtils {
  static getSearchResults(storeNewItem, type) {
    return storeNewItem.pipe(
      select(fromNewSectionItem.getNewSectionSearchResult),
      map(searchResultsItem => {
        if (searchResultsItem) {
          const typedSearchResults = searchResultsItem[type] && searchResultsItem[type].items;
          return clone(typedSearchResults);
        }
        return null;
      }),
    );
  }

  static getSearchIpsFormModel(type, storeNewItem) {
    return storeNewItem.pipe(
      select(fromNewSectionItem.getNewSectionSearch),
      map(search => {
        const typedSearch = search[type] || {};
        return clone(typedSearch);
      }),
    );
  }
}
