export const locale = {
  lang: 'en',
  data: {
    WORKS_SEARCH_PLACEHOLDER: 'work title : creator1, creator2     OR     #ref ',
    IPS_SEARCH_PLACEHOLDER: 'Last name : First name OR #IPInamenumber OR #IPnamekey',
    AGREEMENTS_SEARCH_PLACEHOLDER: 'AssignorName : IP1, IP2     OR     #AgreementId ',
    'AGREEMENT-GROUP_SEARCH_PLACEHOLDER': 'IP : description     OR     #GroupId ',
    REPERTOIRES_SEARCH_PLACEHOLDER: 'Repertoire description     OR     #reference ',
    SOCIETIES_SEARCH_PLACEHOLDER: 'Society Name OR #societycode',
    TERRITORIES_SEARCH_PLACEHOLDER: 'Tisn OR territory name OR #Tisa',
    REPORTS_SEARCH_PLACEHOLDER: 'Report name',
    ACTIVITY_SEARCH_PLACEHOLDER: 'Assignee',
    // Temporarily redirected to Counterclaim Actions following: https://onstage.atlassian.net/browse/CUBE-12178
    // 'COUNTER-CLAIMS_SEARCH_PLACEHOLDER': 'CounterClaim ID or #ICE WorkKey',
    'COUNTER-CLAIMS_SEARCH_PLACEHOLDER': 'Assigned user or #Action deadline',
    'COUNTER-CLAIMS-ACTIONS_SEARCH_PLACEHOLDER': 'Assigned user or #Action deadline',
    SEARCH_ADVANCED: 'Advanced Search',
    FILTER_SEARCH: 'Search',
    SEARCH_EXPERT: 'Expert Search',
    EXPERT_MODE: 'Expert Mode',
    EXPERT_WARNING: 'Expert search is still being developed and some results might not be fully correct',
    ADV_SEARCH_RESET: 'Reset',
    RESET_SORT: 'Reset sort',
    ORGANIZATIONS_SEARCH_PLACEHOLDER: 'Organization name OR #ID',
    USERS_SEARCH_PLACEHOLDER: 'First name : Last name OR #Username',
    'COUNTER-CLAIM-SUPPORT_SEARCH_PLACEHOLDER': 'Assigned user or #Action deadline',
    'AGREEMENT-CONFLICT_SEARCH_PLACEHOLDER': 'Case ID or #AgreementId',
    UPLOAD_XREF: 'Select Work Keys File',
    XREF_LIST_POPUP: {
      TITLE: 'Select Work Keys File',
      MESSAGE: 'Please select a csv file with the work Keys in the 1st column. 1st Row is the header row',
      MESSAGE_AGREEMENTS: 'Please select a csv file with the work Keys in the 1st column without the header row',
      ERROR_AGREEMENTS: 'The selected file contains errors and not all works will be added.',
      MSG: 'CSV File',
      PLACEHOLDER: 'XREF List ',
      CONFIRM: 'Confirm',
      CANCEL: 'Cancel',
    },
    'BULK-UPDATES_SEARCH_PLACEHOLDER': '',
  },
};
