export class SearchObject {
  constructor(private from: number, private size: number, private expression: SearchExpression) {}
}
export class SearchExpression {}

export class SearchAndExpression extends SearchExpression {
  and: SearchExpression[] = [];
}
export class SearchOrExpression extends SearchExpression {
  or: SearchExpression[] = [];
}
export class SearchMatchExpression extends SearchExpression {
  match: any = {};
}
