import { ConflictScope, ConflictScopeDetail, ConflictUtils, StringUtils, TerritoryUtils, IpUtils, WorkUtils } from '@ice';
import { TranslateService } from '@ngx-translate/core';
import { AGREEMENT_CONFLICT_STATUS, NO_CASE } from 'config/constants/activity.constants';
import { DEFAULT_LANGUAGE } from 'config/constants/global.constants';
import { mrRights, prRights, rpRights, syRights } from 'config/constants/ips.constants';
import { TerritoryDataType } from 'config/constants/territories.constants';
import { type SectionConfig, SectionsConfig } from 'config/sections-config';
import { concat, drop, find, first, get, has, intersection, isEmpty, isEqual, isNaN, isNil, map, mapValues, startsWith, trim } from 'lodash';
import { Name } from 'models';
import { AuditChange } from 'models/copyright/detail/audit-history';
import { IconInterface } from 'models/copyright/detail/icon';
import { SelectGroup, SelectOption } from 'models/copyright/formly/formly';
import { DictionaryCodeItem } from 'models/dictionaryCodeItem';
import { OptionsGroup } from 'models/options-group';
import { SelectCodeItem } from 'models/selectCodeItem';
import { IpiNameType } from 'services/search/expert-search-parse-ips.service';
import * as fromRoot from 'store/root';
import { InputFileType } from '@ice/components/input-upload-file/input-upload-file.model';

export class CopyrightUtils {
  static setDetailTitle(copyright: fromRoot.CopyrightState, section: string, params: any): { title?; key?; status?; iswc?; id? } {
    const cs = copyright[section];
    let title = '';
    let attributes;
    const key = params && params.key;
    if (cs) {
      switch (section) {
        case SectionsConfig.WORKS.name: {
          let status;
          if (cs && cs.attributes && cs.attributes.title) {
            title = `${cs.attributes.title}`;
          }
          let output = key;
          const iswc = cs.relations && cs.relations.find(elem => elem.relation === 'XREF' && elem.otherId.indexOf('ISWC') === 0);
          if (iswc) {
            output += ', ' + iswc.otherId;
          }
          if (cs && cs.attributes && cs.attributes.status) {
            output += ', status:' + cs.attributes.status;
            status = cs.attributes.status;
          }
          return { title, key: output, status, iswc: get(iswc, 'otherId', ''), id: key };
        }

        case SectionsConfig.IPS.name:
          if (cs.partyNames?.length === 1) {
            return { title: cs.partyNames[0].fullName };
          }
          const patronymFullName = cs.partyNames?.find(partyName => partyName.typeOfName === IpiNameType.PA)?.fullName;
          return { title: patronymFullName || '' };
        case SectionsConfig.AGREEMENTS.name:
          if (cs) {
            if (cs.assignor && cs.assignor.detailTitle) {
              title = cs.assignor.detailTitle;
            }
            return { title, key };
          }
          return { title: '', key };
        case SectionsConfig.AGREEMENT_GROUP.name:
          return { title: get(cs, 'partyName.attributes') && IpUtils.getNameFromParty(cs.partyName), key };
        case SectionsConfig.REPERTOIRES.name:
          if (cs && cs.attributes && cs.attributes.name) {
            return { title: cs.attributes.name, key };
          }
          return { title: '', key };
        case SectionsConfig.SOCIETIES.name:
          return { title: `${cs.socName}:${cs.socCode}`, key: null };
        case SectionsConfig.TERRITORIES.name:
          const territory = cs && cs.territories && cs.territories.length && cs.territories.length > 0 && cs.territories[0];
          const codes = territory && territory.codes && territory.codes.length && territory.codes.length > 0 && territory.codes[territory.codes.length - 1];
          const name =
            codes && codes.names
              ? find<Name>(codes.names, { language: DEFAULT_LANGUAGE })
                ? find<Name>(codes.names, { language: DEFAULT_LANGUAGE }).name
                : codes.names[0].name
              : '';
          return { title: name, key: null };

        case SectionsConfig.CONFLICTS.name:
          return { title: ConflictUtils.getWorkTitle(cs), key: null };

        case SectionsConfig.COUNTERCLAIMS.name: {
          return { title: `${cs.workTitle} / Counterclaim ${cs.id} /`, key: cs.iceId };
        }
        case SectionsConfig.ORGANIZATIONS.name:
          attributes = cs && cs.attributes;
          return { title: attributes && attributes.name, key: attributes && attributes.id };

        case SectionsConfig.USERS.name:
          return { title: get(cs, 'cognitoUserName', ''), key: null };
        case SectionsConfig.AGREEMENTCONFLICT.name:
          return { title: get(cs, 'attributes.status', ''), key: get(cs, 'id', '') };
      }
    }
    return {};
  }

  static getUrlKey(section: string, itemData: any) {
    switch (section) {
      case SectionsConfig.WORKS.name:
      case SectionsConfig.IPS.name:
        return itemData && itemData.attributes && itemData.attributes.key;

      case SectionsConfig.TERRITORIES.name:
        return itemData && itemData.territories && itemData.territories.length > 0 && itemData.territories[0].tisN;

      default:
        return undefined;
    }
  }

  static isEditClaimsDetail(detail) {
    return isEqual(Object.keys(detail), ['editClaim', 'editingClaim']);
  }

  static hideEditOnBehalfofPublisher(section) {
    switch (section) {
      case SectionsConfig.IPS.name:
        return true;
      default:
        return false;
    }
  }

  static formatTooltip(worksIncludedToP: string[], translate: any): any {
    let render = `${translate.instant('CONFLICTS.TOOLTIP_TITLE')}\r\n_______________________________\r\n \r\n`;
    worksIncludedToP.forEach(work => {
      render += ` ${work}\r\n`;
    });
    return render;
  }

  static extractPartyNameFirstname(attributes: any): string {
    const fistNameWithSeparator = attributes?.firstName?.trim() ? ', ' + attributes.firstName.trim() : '';
    return (attributes && `${attributes.name}${fistNameWithSeparator}`) || '';
  }

  static compareSuffix(key1: string, key2: string) {
    return StringUtils.trimLeadingZerosFromString(CopyrightUtils.getKeySuffix(key1)) === StringUtils.trimLeadingZerosFromString(CopyrightUtils.getKeySuffix(key2));
  }

  static getKeySuffix(key: string): string {
    const splittedKey = key && key.split(':');
    return (Array.isArray(splittedKey) && drop(splittedKey).join(':')) || key;
  }

  static getNumericValuesFromKey(key: string): number {
    return Number(key.replace(/\D/g, ''));
  }

  static getKeySuffixWithPrefix(key: string): string {
    const splittedKey = key.split(':');
    const numericalSuffix = splittedKey[splittedKey.length - 1].match(/\d+/g);
    const suffix = String(numericalSuffix).padStart(11, '0');
    return `CUBE:${suffix}`;
  }

  static removeDuplicatesByProperty(list: Array<any>, property: string) {
    if (!Array.isArray(list)) return [];
    const cleanList = new Set();
    const filtrados = list.filter(item => {
      let exists = false;
      if (!cleanList.has(item[property])) {
        cleanList.add(item[property]);
        exists = true;
      }
      return exists;
    });
    return filtrados;
  }

  static getKeyPrefix(key: string) {
    const splittedKey = key && key.split(':');
    return (Array.isArray(splittedKey) && first(splittedKey)) || key;
  }

  static getKeySuffixNumerical(key) {
    return this.getKeySuffix(key).replace(/^0+/, '');
  }

  static clearNameSpace(data: string): string {
    if (data && data.includes(':')) {
      data = data.split(':')[1];
    }
    return data;
  }

  static clearVersion(data: string): string {
    if (data && data.includes('_')) {
      data = data.split('_')[0];
    }
    return data;
  }

  static clearNamespaceAndVersion(data: string): string {
    if (data) {
      data = this.clearNameSpace(data);
      data = this.clearVersion(data);
    }
    return data;
  }

  static getSelectedAuditChanges(detail: any, selectedItemId: string): AuditChange[] {
    const selectedItem = find(detail?.auditHistory?.items, item => item.id === selectedItemId);
    return (
      selectedItem &&
      selectedItem.attributes &&
      selectedItem.attributes.data &&
      selectedItem.attributes.data.patch &&
      selectedItem.attributes.data.patch.map(item => {
        const { op, path, value } = item;
        return { operation: op, path, jsonData: value };
      })
    );
  }

  static generateIconWithTooltip(tooltipText: string, iconCode: string, classParam?: string, actionParam?: Function): IconInterface[] {
    return [{ icon: iconCode, text: tooltipText, class: classParam, action: actionParam }];
  }

  static getGranularPermission(permissions: any, permission: string): boolean {
    return get(permissions, 'writable') && permissions.writable[permission];
  }

  static addICEPrefix(key: string) {
    return startsWith(key, 'ICE:') ? key : `ICE:${key}`;
  }

  static parseStatus(detail: any) {
    if (detail && detail.id) {
      const currentCase = detail.case;
      const parseStatus = get(currentCase, 'status', NO_CASE);
      return AGREEMENT_CONFLICT_STATUS[parseStatus] || AGREEMENT_CONFLICT_STATUS[NO_CASE];
    }
    return null;
  }

  static disabledEventPropagation(event) {
    if (event) {
      event.preventDefault();
      if (event.stopPropagation) {
        event.stopPropagation();
      } else if (window.event) {
        window.event.cancelBubble = true;
      }
    }
  }

  static cleanConflicts(conflicts: any[], translate): ConflictScope[] {
    return (conflicts || []).map(conflict => {
      return <ConflictScope>{
        conflictId: conflict.conflictId,
        claimConflictPoints: (conflict?.conflict?.claimConflictPoints || []).map(claimConflictPoint => {
          const mrRightsItems = this.getRightsLabels(claimConflictPoint?.rights, 'mechanical');
          const mrRightsTooltip = this.getRightsLabelsTooltip(mrRightsItems, translate);
          const prRightsItems = this.getRightsLabels(claimConflictPoint?.rights, 'performing');
          const prRightsTooltip = this.getRightsLabelsTooltip(prRightsItems, translate);

          return <ConflictScopeDetail>{
            territories: claimConflictPoint?.territories,
            territoriesString: claimConflictPoint?.territories?.inExTisns?.join(', '),
            territoriesLabel: TerritoryUtils.convertTerritoryArrayElements(claimConflictPoint?.territories?.inExTisns, TerritoryDataType.TISA),
            territoriesTooltip: TerritoryUtils.getTerritoriesNamesTooltipText(
              TerritoryUtils.convertTerritoryArrayElements(claimConflictPoint?.territories?.inExTisns, TerritoryDataType.NAME),
            ),
            rights: claimConflictPoint?.rights,
            mrRights: mrRightsItems,
            mrRightsTooltip,
            prRights: prRightsItems,
            prRightsTooltip,
            startDate: claimConflictPoint?.usageStartDate,
            endDate: claimConflictPoint?.usageEndDate,
            claimShareType: claimConflictPoint?.claimShareType,
            conflictCause: claimConflictPoint?.cause,
            conflictWorkIds: claimConflictPoint?.conflictWorkIds.join(', '),
          };
        }),
      };
    });
  }

  static getRightsLabels(usageTypes: string[], rightType: string): string {
    let rightsData;
    switch (rightType) {
      case 'mechanical':
        rightsData = mrRights;
        break;
      case 'performing':
        rightsData = prRights;
        break;
      case 'synchronisation':
        rightsData = syRights;
        break;
      case 'print':
        rightsData = rpRights;
        break;
      case 'mechanical_performing':
        rightsData = concat(mrRights, prRights);
    }
    if (rightsData) {
      const match = intersection(rightsData, usageTypes);
      return match.length === rightsData.length ? 'ALL' : match.join(',');
    } else {
      return usageTypes.join(',');
    }
  }

  static sortByProperty(collection: any[], property: string): any[] {
    return collection.sort((a, b) => {
      if (a[property] < b[property]) {
        return -1;
      }
      if (a[property] > b[property]) {
        return 1;
      }
      return 0;
    });
  }

  static extenseOptionsToCompare(newData: any, oldDetail: any): any {
    const currentOptions = get(oldDetail, 'optionsToCompare', []);
    const data = currentOptions?.map(option => {
      const matchOption = newData?.items?.find(item => item.id === option.value);
      let title = '';
      if (matchOption) {
        title = WorkUtils.selectTitle(get(matchOption, 'attributes.titles'))?.title || '';
      } else {
        title = get(oldDetail, 'workToCompare.title', '');
      }
      return { ...option, title };
    });
    return data;
  }

  static trimObjectValues(item: object): object {
    return mapValues(item, val => trim(val));
  }

  static sortByPropAndSuffix(list: any[], propToSort: string = 'value'): any[] {
    return list?.sort((a, b) => (this.getSuffixAsNumber(a[propToSort]) < this.getSuffixAsNumber(b[propToSort]) ? -1 : 1));
  }

  static getSuffixAsNumber(value: string): number | string {
    const suffix = Number(CopyrightUtils.getKeySuffix(value));
    return isNaN(suffix) ? value : suffix;
  }

  static removeEmptyProperties(obj: object): object {
    if (isNil(obj)) return obj;
    const newObj = {};
    for (const [key, value] of Object.entries(obj)) {
      if (value !== '') newObj[key] = value;
    }
    return newObj;
  }

  static getRightsLabelsTooltip(rightType: string, translate: any): string {
    if (rightType) {
      const tooltips = rightType
        .split(',')
        .map(right => {
          return translate.instant(`RIGHTS.${right}`);
        })
        .join('<br>');
      return tooltips;
    }
  }

  static getUnauthorizedIconWithTooltip(translate: any) {
    return this.generateIconWithTooltip(translate.instant('WORKS.TOOLTIPS.ICONS.UNAUTHORIZED'), 'priority_high', 'unauthorized-icon');
  }

  static getIncomeparticipantIconWithTooltip(translate: any) {
    return this.generateIconWithTooltip(translate.instant('WORKS.TOOLTIPS.ICONS.INCOME_PARTICIPANT'), 'person', 'incomeparticipant-icon');
  }

  static getRemoveIconWithTooltip(translate: any, className: string = 'ice-data-table-remove-work-icon', action?: Function) {
    return this.generateIconWithTooltip(translate.instant('WORKS.IP.ICONS.REMOVE_ICON.ICON_TOOLTIP'), translate.instant('WORKS.IP.ICONS.REMOVE_ICON.ICON_CODE'), className, action);
  }

  static getCounterclaimIconWithTooltip() {
    return this.generateIconWithTooltip('', '<div class="alerts-cc-icon">CC</div>');
  }

  static getTranslation(translate: TranslateService, translationKey: string): string {
    const translation = translate.instant(translationKey);
    if (translation === translationKey) {
      return '';
    }
    return translation;
  }

  static createOptionsFromDictionary(dictionaryData: DictionaryCodeItem[], translate: TranslateService, translatePrefix: string): SelectOption[] {
    return map(dictionaryData, item => ({ value: get(item, 'code'), label: translate.instant(`${translatePrefix}.${get(item, 'name')}`) }));
  }

  static createSelectGroupFromOptionsGroup(optionsGroup: OptionsGroup[], translate: TranslateService, translatePrefix: string): SelectGroup[] {
    return map(optionsGroup, group => {
      const selectGroup: SelectGroup = {} as SelectGroup;
      selectGroup.options = this.createSelectOptionsFromOptionsGroup(group, translate, translatePrefix);
      selectGroup.header = has(group, 'header') ? translate.instant(`${translatePrefix}.${get(group, 'header')}`) : '';
      return selectGroup;
    });
  }

  static createSelectOptionsFromOptionsGroup(optionsGroup: OptionsGroup, translate: TranslateService, translatePrefix: string): SelectOption[] {
    return map(optionsGroup.options, option => {
      const label = get(option, 'label', '');
      const key = `${translatePrefix}.${label}`;
      const translation = translate.instant(key);
      return { value: get(option, 'value'), label: isEqual(key, translation) ? label : translation };
    });
  }

  static createSelectGroupFromDictionary(header: string, options: DictionaryCodeItem[]): OptionsGroup {
    return {
      header,
      options: this.castToOptionFromDictionaryWithCode(options),
    };
  }

  static castToOptionFromDictionary(dictionaries: DictionaryCodeItem[]): SelectCodeItem[] {
    return map(dictionaries, dictionary => ({
      value: get(dictionary, 'code'),
      label: get(dictionary, 'name'),
    }));
  }

  static castToOptionFromDictionaryWithCode(dictionaries: DictionaryCodeItem[]): SelectCodeItem[] {
    return map(dictionaries, dictionary => ({
      value: get(dictionary, 'code'),
      label: `${get(dictionary, 'name')} (${get(dictionary, 'code')})`,
    }));
  }

  static createSelectGroupFromValues(header: string, options: string[]): OptionsGroup {
    return {
      header,
      options: this.castToOptionFromValues(options),
    };
  }

  static castToOptionFromValues(values: string[]): SelectCodeItem[] {
    return map(values, value => ({
      value,
      label: value,
    }));
  }

  static getSectionData(section: string): SectionConfig {
    return find(SectionsConfig, { name: section });
  }

  static getTitleWithCount(title: string, count: number) {
    return `${title} (${count})`;
  }

  static getApiErrorText(response: any) {
    const error = response.error;
    return error?.message || error?.statusMessage || response.message || response.statusMessage;
  }

  static isValidCSVFile(file: any) {
    return file.name.match(InputFileType.CSV);
  }

  static parseCsvData(csvData: string): string[][] {
    const rows = csvData.split('\n'); // The new lines are \n
    return rows.map(row => row.replace(/[\r"]/g, '').split(',')); // The new lines sometimes are \n\r and csv rows can be wrapped in  ""
  }

  static rightsSpecificToGeneric(rights) {
    const formattedRights = rights.map(right => right.trim().toUpperCase());
    const totalPr = formattedRights.filter(right => prRights.find(pr => right.trim().toUpperCase() === pr)).length;
    const allPr = totalPr === prRights.length;
    const totalMr = formattedRights.filter(right => mrRights.find(mr => right.trim().toUpperCase() === mr)).length;
    const allMr = totalMr === mrRights.length;
    if (allMr && allPr) {
      return 'ALL';
    } else {
      if (allPr && formattedRights.length === totalPr) {
        return 'PR-ALL';
      } else if (allMr && formattedRights.length === totalMr) {
        return 'MR-ALL';
      }
    }
    return rights.join(',');
  }
}
