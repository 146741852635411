import { environment } from 'config/env';
import { SectionRouterViews } from './section-router-views';
import type { SectionConfig } from './sections-config';

export const SectionCopyrightBulkUpdates: SectionConfig = {
  name: 'bulk-updates',
  url: '/copyright/bulk-updates',
  domainName: 'copyright',
  baseUrl: environment.apiUrl,
  baseUrlCubeData: environment.apiUrlCubeData,
  apiSegment: 'jobs',
  detailSegment: `jobs/<<ns>>/<<id>>`,
  minCharsToSearch: 5,
  apiIncludes: {
    search: '',
    detail: '',
  },
  searchServerSideSortDefault: '',
  allowedActions: {
    newItem: false,
    editItem: null,
    newNote: false,
    removeItem: false,
  },
  homeRedirect: 'dashboard',
  newItemBtn: [SectionRouterViews.new],
};
