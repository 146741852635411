<div fxLayout="row">
  <div fxLayout="column" fxFlex="90">
    <ng-container #fieldComponent></ng-container>
  </div>
  <button
    fxLayout="column"
    fxFlex="10"
    class="formFieldSearch"
    matTooltip="{{ to.infoText }}"
    [matTooltipClass]="'ice-txt-size-12'"
    mat-icon-button
    class="secondary-text"
    aria-label="Palette"
    (click)="search($event)"
  >
    <mat-icon class="s-20 search-icon">search</mat-icon>
  </button>
</div>
