import { ButtonConfiguration } from '@ice/components/buttons-group/buttons-group';
import { ButtonsGroupActions } from 'config/buttons-group-builders/buttons-group-actions';
import { SectionsConfig } from 'config/sections-config';
import { of } from 'rxjs';

export const FloatingButtons: { [key: string]: ButtonConfiguration } = {
  newNote: {
    name: 'fab-newNote',
    color: 'accent',
    label: 'NEW_NOTE',
    icon: 'note',
    action: ButtonsGroupActions.goToNewNote,
  },
  remove: {
    name: 'fab-remove',
    color: 'warn',
    label: 'REMOVE_BUTTON',
    icon: 'delete',
    action: ButtonsGroupActions.remove,
  },
  editTab: {
    name: 'fab-editTab',
    color: 'accent',
    label: 'EDIT_ITEM',
    icon: 'edit',
    tooltipClass: 'ice-tooltip-edit-button',
    className: 'edit-mode-button',
    action: ButtonsGroupActions.startTabEdit,
  },
  editDetail: {
    name: 'fab-editDetail',
    color: 'primary',
    label: 'EDIT_ITEM',
    icon: 'edit',
    tooltipClass: 'ice-tooltip-edit-button',
    className: 'edit-mode-button',
    action: ButtonsGroupActions.edit,
  },
  editCancel: {
    name: 'fab-editCancel',
    color: 'warn',
    label: 'CANCEL_NEW',
    icon: 'close',
    className: 'end-edit-mode-button button-primary',
    action: ButtonsGroupActions.cancelEditMode,
  },
  alternativeUpdate: {
    name: 'fab-alternativeUpdate',
    color: 'warn',
    label: 'ALTERNATIVE_UPDATE',
    icon: 'no_sim',
    className: 'alternative-update-button',
    action: ButtonsGroupActions.openAlternativeUpdate,
  },
  save: {
    name: 'fab-save',
    color: 'accent',
    label: 'SAVE_NEW',
    icon: 'publish',
    className: 'save-edit-mode-button button-secondary',
    action: ButtonsGroupActions.saveWithoutSummary,
  },
  mergeIPDetail: {
    name: 'fab-mergeIPDetail',
    color: 'accent',
    label: 'START_MERGE_IP_MODE',
    icon: 'call_merge',
    className: 'merge-ip-button button-primary',
    action: ButtonsGroupActions.mergeIPDetail,
  },
  mergeIPCancel: {
    name: 'fab-mergeIPCancel',
    color: 'warn',
    label: 'CANCEL_MERGE_IP_MODE',
    icon: 'close',
    className: 'end-merge-ip-button button-primary',
    action: ButtonsGroupActions.mergeIPDetailCancel,
  },
  saveMultiple: {
    name: 'fab-saveMultiple',
    color: 'accent',
    label: 'SAVE_NEW',
    icon: 'publish',
    action: ButtonsGroupActions.saveWithoutSummary,
    disabled: null,
  },
  confirmMatch: {
    name: 'fab-confirmMatch',
    color: 'accent',
    label: 'CONFIRM_MATCH',
    icon: 'check',
    className: 'activity-confirm-match-button',
    action: ButtonsGroupActions.confirmMatch,
  },
  forceMerge: {
    name: 'fab-forceMerge',
    color: 'accent',
    label: 'FORCE_MERGE',
    icon: 'call_merge',
    className: 'activity-confirm-merge-button',
    action: ButtonsGroupActions.forceMerge,
  },
  // DISCARD BUTTON
  discardMatch: {
    name: 'fab-discardMatch',
    color: 'warn',
    label: 'DISCARD_MATCH',
    icon: 'swap_horiz',
    action: ButtonsGroupActions.discardMatch,
  },
  // EDIT BUTTON
  modifyMatch: {
    name: 'fab-modifyMatch',
    color: 'warn',
    label: 'NO_MATCH',
    icon: 'library_books',
    className: 'match-modify-button',
    action: ButtonsGroupActions.modifyMatch,
  },
  workNewClaim: {
    name: 'fab-workNewClaim',
    color: 'accent',
    label: 'BTN_NEW_CLAIM',
    icon: 'playlist_add',
    className: 'work-new-claim-button',
    action: ButtonsGroupActions.newClaim,
  },
  workNewClaimEdition: {
    name: 'fab-workNewClaimEdition',
    color: 'primary',
    label: 'BTN_NEW_CLAIM_EDITION',
    icon: 'playlist_add',
    className: 'work-new-claim-button',
    action: ButtonsGroupActions.newClaimEdition,
  },
  workEdit: {
    name: 'fab-workEdit',
    color: 'primary',
    label: 'EDIT_ITEM',
    icon: 'edit',
    tooltipClass: 'ice-tooltip-edit-button',
    className: 'edit-mode-button',
    action: ButtonsGroupActions.startTabEdit,
  },
  moreActions: {
    name: 'fab-moreActions',
    color: 'primary',
    label: 'MORE_ACTIONS',
    icon: 'more_vert',
    className: 'more-options-button',
    menuActionsOnClick: true,
    multipleActions: [],
  },
  cloneItem: {
    name: 'fab-cloneItem',
    color: 'primary',
    label: 'CLONE',
    icon: 'filter_none',
    className: 'clone-button',
    action: ButtonsGroupActions.clone,
  },
  agreementEditActions: {
    name: 'fab-agreementEditActions',
    color: 'primary',
    label: 'EDIT_ITEM',
    icon: 'edit',
    tooltipClass: 'ice-tooltip-edit-button',
    className: 'agreement-edit-button',
    menuActionsOnClick: true,
    multipleActions: [
      { icon: 'edit', action: ButtonsGroupActions.addRetention, label: 'ADD_RETENTION', disabled: false },
      { icon: 'edit', action: ButtonsGroupActions.edit, label: 'EDIT_ITEM', disabled: false, className: `edit-mode-button` },
    ],
  },
  newAgreementConflict: {
    name: 'fab-newAgreementConflict',
    color: 'warn',
    label: `BT_NEW_AGREEMENT_CONFLICT`,
    icon: 'post_add',
    action: ButtonsGroupActions.newAgreementConflict,
    className: `counter-claim-add`,
  },
  saveAgreementAsNew: {
    name: 'fab-saveAgreementAsNew',
    label: `BT_SAVE_AGREEMENT_AS_NEW`,
    icon: 'filter_none',
    action: ButtonsGroupActions.saveAgreementAsNew,
  },
  newClaim: {
    name: 'fab-newClaim',
    color: 'warn',
    label: `BTN_NEW_CLAIM`,
    icon: 'add_to_photos',
    action: ButtonsGroupActions.newClaim,
    className: `counter-claim-add`,
  },
  enableUser: {
    name: 'fab-enableUser',
    color: 'warn',
    label: `USERS_ACTIVATE_BTN_TITLE`,
    icon: 'lock_open',
    action: ButtonsGroupActions.enableUser,
    className: `counter-claim-add`,
  },
  disableUser: {
    name: 'fab-disableUser',
    color: 'warn',
    label: `USERS_DISABLE_BTN_TITLE`,
    icon: 'lock',
    action: ButtonsGroupActions.disableUser,
    className: `counter-claim-add`,
  },
  conflictSelect: {
    name: 'fab-conflictSelect',
    color: 'accent',
    label: `ASSIGN_CONFLICT`,
    icon: 'people',
    action: ButtonsGroupActions.startSelectMode,
    className: `counter-claim-add`,
  },
  actionSelect: {
    name: 'fab-actionSelect',
    color: 'accent',
    label: `ASSIGN_ACTION`,
    icon: 'people',
    action: ButtonsGroupActions.startSelectMode,
    className: `action-add`,
  },
  counterClaimEnableDownloadDocument: {
    name: 'fab-activateDownload',
    color: 'accent',
    label: 'ACTIVATE_DOWNLOAD',
    icon: 'archive',
    action: ButtonsGroupActions.enableDownloadDocument,
    className: `counter-claim-enable-download-document`,
  },
  counterClaimCancelDownloadDocument: {
    name: 'fab-cancelDownload',
    color: 'warn',
    label: 'CANCEL_DOWNLOAD',
    icon: 'close',
    action: ButtonsGroupActions.cancelDownloadDocument,
    className: `counter-claim-cancel-download-document`,
  },
  counterClaimDownloadDocument: {
    name: 'fab-downloadDocument',
    color: 'success',
    label: 'DOWNLOAD_DOCUMENT',
    icon: 'get_app',
    action: ButtonsGroupActions.downloadDocument,
    className: `counter-claim-download-document`,
  },
  counterClaimEnableRejectDocument: {
    name: 'fab-activateReject',
    color: 'accent',
    label: 'ACTIVATE_REJECT_DOCUMENT',
    icon: 'not_interested',
    action: ButtonsGroupActions.enableRejectDocument,
    className: `counter-claim-enable-reject-document`,
  },
  counterClaimCancelRejectDocument: {
    name: 'fab-cancelReject',
    color: 'warn',
    label: 'CANCEL_REJECT_DOCUMENT',
    icon: 'close',
    action: ButtonsGroupActions.cancelRejectDocument,
    className: `counter-claim-cancel-reject-document`,
  },
  counterClaimSaveRejectDocument: {
    name: 'fab-saveReject',
    color: 'success',
    label: 'SAVE_REJECT_DOCUMENT',
    icon: 'send',
    action: ButtonsGroupActions.saveRejectDocument,
    className: `counter-claim-save-reject-document`,
  },
  deleteMode: {
    name: 'fab-deleteMode',
    color: 'warn',
    label: 'DELETE.OK',
    icon: 'delete',
    action: ButtonsGroupActions.startDeleteMode,
    className: `delete-mode`,
  },
  shareMode: {
    name: 'fab-shareMode',
    color: 'accent',
    label: 'SHARE',
    icon: 'share',
    action: ButtonsGroupActions.startShareMode,
    className: `share-mode`,
  },
  startMergeIp: {
    name: 'fab-startMergeIp',
    color: 'accent',
    label: 'MERGE_IPS',
    icon: 'merge_type',
    action: ButtonsGroupActions.startMergeIpMode,
    className: 'merge-ip button-primary',
  },
  startTransferIpWorks: {
    name: 'fab-startTransferIpWorks',
    color: 'primary',
    label: 'START_TRANSFER_IP_WORKS',
    icon: 'sync_alt',
    action: ButtonsGroupActions.startTransferIpWorks,
  },
  endTransferIpWorks: {
    name: 'fab-endTransferIpWorks',
    color: 'accent',
    label: 'END_TRANSFER_IP_WORKS',
    icon: 'close',
    action: ButtonsGroupActions.endTransferIpWorks,
  },
  generateFiche: {
    name: 'fab-generateFiche',
    color: 'accent',
    label: 'GENERATE_FICHE',
    icon: 'share',
    action: ButtonsGroupActions.generateFiche,
  },
};

export const FloatingButtonsActions: { [key: string]: (section: any) => ButtonConfiguration } = {
  new: section => ({
    color: 'warn',
    label: `${section.toUpperCase()}_NEW_BTN_TITLE`,
    icon: 'add',
    action: ButtonsGroupActions.goToNew,
    className: `${section}-add`,
  }),
  edit: section => ({
    color: 'warn',
    label: `${section.toUpperCase()}_EDIT_BTN_TITLE`,
    icon: 'edit',
    action: ButtonsGroupActions.selectEditItem,
    className: `${section}-edit`,
  }),
};

export const getExportButtons = (mode, disabled, section) =>
  mode
    ? [
        {
          name: 'closeExportMode',
          color: 'warn',
          label: 'CLOSE_EXPORT_MODE',
          icon: 'close',
          className: 'close-export-button button-primary',
          action: ButtonsGroupActions.endExportMode,
        },
        {
          name: 'confirmExport',
          color: 'success',
          label: 'EXPORT',
          icon: 'get_app',
          className: `do-export-button ${disabled ? 'button-secondary-disabled' : 'button-secondary'}`,
          action: ButtonsGroupActions.export,
          disabled,
          menuActionsOnClick: true,
          multipleActions: [
            { action: ButtonsGroupActions.exportCSV, label: 'EXPORT_CSV', disabled: false },
            { action: ButtonsGroupActions.exportXLSX, label: 'EXPORT_XLSX', disabled: false },
          ],
        },
      ]
    : [
        {
          name: 'startExportMode',
          color: 'accent',
          label: 'EXPORT',
          icon: 'archive',
          action: ButtonsGroupActions.startExportMode,
          className: section === SectionsConfig.IPS.name ? 'button-secondary' : null,
        },
      ];

export const getActivityButtons = disabled => [
  {
    name: 'cancelSelectMode',
    color: 'warn',
    label: 'CANCEL_NEW',
    icon: 'close',
    className: 'end-edit-mode-button',
    action: ButtonsGroupActions.cancelSelectMode,
  },
  {
    name: 'assignToMe',
    color: 'accent',
    label: 'ASSIGN_ME',
    className: 'assign-to-me-button',
    icon: 'person_outline',
    action: ButtonsGroupActions.assignToMe,
    disabled,
  },
  {
    name: 'changeAssignee',
    color: 'accent',
    label: 'CHANGE_ASSIGNEE',
    icon: 'search',
    action: ButtonsGroupActions.changeAssignee,
    disabled,
  },
];

export const getUpdateButtons = (mode, disabled) =>
  mode
    ? [
        {
          name: 'closeUpdateMode',
          color: 'warn',
          label: 'CLOSE_UPDATE_MODE',
          icon: 'close',
          action: ButtonsGroupActions.endUpdateMode,
        },
        {
          name: 'confirmUpdate',
          color: 'accent',
          label: 'UPDATE',
          icon: 'system_update_alt',
          action: ButtonsGroupActions.update,
          disabled,
        },
      ]
    : [
        {
          name: 'startUpdateMode',
          color: 'accent',
          label: 'BULK_UPDATE',
          icon: 'edit',
          action: ButtonsGroupActions.startUpdateMode,
        },
      ];

export const getReportsSelectingButtons = (isSelecting, disabled) =>
  isSelecting
    ? [
        {
          name: 'cancelReportsSelecting',
          color: 'warn',
          label: 'CANCEL_NEW',
          icon: 'close',
          action: ButtonsGroupActions.endSelectMode,
        },
        {
          name: 'saveReports',
          color: 'accent',
          label: 'SAVE_NEW',
          icon: 'send',
          action: ButtonsGroupActions.submitSelectMode,
          disabled,
        },
      ]
    : [
        {
          name: 'startReportsSelecting',
          color: 'accent',
          label: 'BULK_UPDATE',
          icon: 'edit',
          action: ButtonsGroupActions.startUpdateMode,
        },
      ];

export const getMergeIpButtons = disabled => [
  {
    name: 'mergeIps',
    color: 'accent',
    label: 'MERGE_IPS',
    icon: 'merge_type',
    action: ButtonsGroupActions.mergeIp,
    className: `merge-ip ${disabled ? '' : 'button-primary'}`,
    disabled,
  },
  {
    name: 'cancelMergeIp',
    color: 'primary',
    label: 'CANCEL_MERGE_IP_MODE',
    icon: 'close',
    action: ButtonsGroupActions.endMergeIpMode,
  },
];

export const getFabToggleButtons = (): Array<ButtonConfiguration> => {
  return [
    {
      name: 'fab-moreOptions',
      label: 'MORE_OPTIONS',
      icon: 'more_vert',
      className: 'fab-toggle-open',
      action: ButtonsGroupActions.fabButtonsToggle,
    },
    {
      name: 'fab-fewerOptions',
      label: 'FEWER_OPTIONS',
      icon: 'close',
      className: 'fab-toggle-close',
      action: ButtonsGroupActions.fabButtonsToggle,
      isHidden: of(true),
    },
  ];
};
