import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'ice-formly-wrapper-custom-comment',
  templateUrl: './wrapper-custom-comment.component.html',
  styleUrls: ['./wrapper-custom-comment.component.scss'],
})
export class WrapperCustomCommentComponent extends FieldWrapper {
  @ViewChild('fieldComponent', { read: ViewContainerRef, static: true }) fieldComponent: ViewContainerRef;
}
