<form role="form" novalidate [formGroup]="form" (ngSubmit)="onSubmit($event)">
  <div *ngIf="!formInline">
    <!-- form -->
    <div *ngIf="submitInlineAvailable" fxLayout="row">
      <formly-form [form]="form" fxFlex="90" [options]="options" [fields]="formBuilder" [model]="model"></formly-form>
      <div fxFlex="10" fxLayout="column" fxLayoutAlign="space-around center">
        <button
          *ngIf="submitInlineAvailable"
          type="submit"
          mat-raised-button
          data-testid="advanced-inline-form-submit-button"
          color="accent"
          class="advanced-search-submit ice-butt-align-bottom btn-submit"
          [matTooltip]="!!submitShortcutEnable && 'Alt + Shift + Enter'"
        >
          {{ submitLabel | translate }}
        </button>
      </div>
    </div>
    <formly-form *ngIf="!submitInlineAvailable" [ngClass]="{ 'disabled-div': isLoading }" [form]="form" [options]="options" [fields]="formBuilder" [model]="model"></formly-form>
    <!-- buttons -->
    <div *ngIf="submitAvailable || resetAvailable" fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="20px" class="ice-p-12 ice-form-buttons">
      <formly-form [form]="extraForm" [options]="extraOptions" [fields]="extraFormBuilder" [model]="model" class="search-extra-form"></formly-form>
      <button
        *ngIf="extraActionAvailable"
        type="button"
        mat-raised-button
        [color]="extraActionColor"
        class="extra-action-button"
        (click)="onExtraButtonClick($event)"
        data-testid="extra-option-action"
      >
        {{ extraActionLabel | translate }}
      </button>
      <button
        *ngIf="button1Available"
        type="button"
        mat-stroked-button
        data-testid="advanced-form-button1"
        color="accent"
        class="stroked-button-form-container"
        [ngClass]="button1CustomClass"
        [disabled]="!hasData() && !button1Enabled"
        (click)="onButton1($event)"
      >
        <ng-container *ngIf="button1ButtonIcon; else elseBlock">
          <mat-icon>{{ button1ButtonIcon }}</mat-icon>
        </ng-container>
        <ng-template #elseBlock>
          {{ button1Label | translate }}
        </ng-template>
      </button>
      <button class="search-advanced-clear-button" *ngIf="resetAvailable" type="button" mat-stroked-button color="primary" (click)="onReset($event)">
        {{ 'FORM_RESET' | translate }}
      </button>
      <button
        *ngIf="submitAvailable && (!showSubmit || (showSubmit | async))"
        type="submit"
        mat-raised-button
        color="accent"
        [disabled]="!setSubmitEnabled()"
        class="advanced-search-submit btn-submit"
        data-testid="advanced-form-submit-button"
        [ngClass]="submitClass"
        [matTooltip]="!!submitShortcutEnable ? 'Alt + Shift + Enter' : null"
        [matTooltipPosition]="'above'"
        [matTooltipClass]="'ice-tooltip ice-tooltip-form-button'"
      >
        <ng-container *ngIf="submitButtonIcon; else elseBlock">
          <mat-icon>{{ submitButtonIcon }}</mat-icon>
        </ng-container>
        <ng-template #elseBlock>
          {{ submitLabel | translate }}
        </ng-template>
      </button>
    </div>
  </div>

  <div *ngIf="formInline" fxLayout="row" class="ice-form-h-none form-in-line" [ngClass]="className">
    <formly-form fxFlex="80" [form]="form" [options]="options" [fields]="formBuilder" [model]="model"></formly-form>
    <!-- buttons -->
    <div *ngIf="submitAvailable || submitInlineAvailable || resetAvailable" fxFlex="20" fxLayoutAlign="end start" fxLayoutGap="10px" class="filter-actions-paddin">
      <button *ngIf="extraActionAvailable" type="button" mat-raised-button [color]="extraActionColor" class="extra-action-button" (click)="onExtraButtonClick($event)">
        {{ extraActionLabel | translate }}
      </button>
      <button
        fxFlex="1"
        *ngIf="button1Available"
        data-testid="inline-form-button1"
        type="button"
        mat-stroked-button
        color="accent"
        class="stroked-button-form-container"
        [disabled]="!hasData() && !button1Enabled"
        (click)="onButton1($event)"
      >
        {{ button1Label | translate }}
      </button>
      <button
        class="search-advanced-clear-button"
        *ngIf="resetAvailable && !isFlatButton"
        type="button"
        mat-stroked-button
        color="primary"
        (click)="onReset($event)"
        data-testid="reset-button"
      >
        {{ 'FORM_RESET' | translate }}
      </button>
      <button *ngIf="resetAvailable && isFlatButton" mat-flat-button (click)="onReset($event)" class="ice-accent" data-testid="reset-button">
        {{ 'FORM_RESET' | translate }}
      </button>
      <button
        *ngIf="submitAvailable || submitInlineAvailable"
        type="submit"
        mat-raised-button
        color="accent"
        data-testid="inline-form-submit-button"
        [disabled]="!setSubmitEnabled()"
        class="advanced-search-submit btn-submit"
        [ngClass]="submitClass"
        [matTooltip]="!!submitShortcutEnable ? 'Alt + Shift + Enter' : null"
      >
        {{ submitLabel | translate }}
      </button>
    </div>
  </div>
</form>
