import { User } from '@ice';
import { TranslateService } from '@ngx-translate/core';
import * as fromApiCalls from 'config/api-calls';
import { REQUEST_PARAM_NOT_FOUND } from 'config/constants/global.constants';
import {
  ApiCall,
  ApiCallConfig,
  ApiCallData,
  ApiCallPayload,
  ApiCallSequence,
  ApiCallStatus,
  ApiResponseStatus,
  CustomApiCall,
  hasSequenceType,
  isApiCall,
  isApiCallConfig,
  isApiCallConfigList,
  isApiCallPayload,
  isApiCallSequence,
  isApiCallSequenceOrConfig,
  LabelResolverFunction,
  PropertyCleaner,
  SequenceType,
} from 'config/sections-config/api-call';
import { cloneDeep, first, get, groupBy, initial, isArray, isEmpty, last, mapValues, remove, reverse, set, toPairs, uniq } from 'lodash';
import { PermissionsService } from 'services/permissions/permissions.service';
import { PaginationUtils } from '../pagination/pagination.utils';
export class FetchApiCallUtils {
  static populateApiCall(apiCallConfig: CustomApiCall, copyrightData: any, user: User, ns: string, tabName: string, currentSection: string): ApiCall {
    const { apiCall, apiCallData, prevResponse } = apiCallConfig;
    const labelResolver = apiCall.labelResolver;
    const section = apiCall.labelSection || currentSection;
    const url = apiCallData?.url || this.replaceLabels(apiCall.url, labelResolver, apiCallData?.labels, copyrightData, user, section, tabName, ns, prevResponse);
    let body = apiCallData?.body || this.replaceLabels(apiCall.body, labelResolver, apiCallData?.labels, copyrightData, user, section, tabName, ns, prevResponse);

    if (body === REQUEST_PARAM_NOT_FOUND && apiCall?.initialBody) {
      if (apiCall.bodyArrayParameter && apiCall.bodyArrayParameterModel) {
        const model = `{ "or" : [ ${(copyrightData[section][apiCall.bodyArrayParameter] || [])
          .map(auditType => apiCall.bodyArrayParameterModel.replace(`<<model>>`, auditType))
          .join(',')} ]}`;
        body = apiCall.initialBody.replace(`<<${apiCall.bodyArrayParameter}>>`, model);
      }
      body = this.replaceLabels(body, labelResolver, apiCallData?.labels, copyrightData, user, section, tabName, ns, prevResponse);
    }

    const queryParams =
      (apiCallData?.queryParams && { ...apiCall.queryParams, ...apiCallData.queryParams }) ||
      mapValues(apiCall.queryParams || {}, queryParam =>
        this.replaceLabels(queryParam, labelResolver, apiCallData?.labels, copyrightData, user, section, tabName, ns, prevResponse),
      );
    const validateLabels = apiCall.validateLabels && !`${url}${body}${JSON.stringify(queryParams)}`.includes(REQUEST_PARAM_NOT_FOUND);
    return { ...apiCall, url, body, queryParams, validateLabels };
  }

  static shiftApiCallPayload(apiCallPayload: ApiCallPayload, sequenceType: SequenceType): ApiCallPayload {
    const startApiCallPayload = cloneDeep(apiCallPayload);
    if (!isArray(startApiCallPayload) || isEmpty(startApiCallPayload)) {
      return null;
    }
    if (isApiCallSequence(startApiCallPayload)) {
      if (sequenceType === SequenceType.Concat) {
        this.shiftApiCallSequence(startApiCallPayload);
      } else {
        return null;
      }
    } else {
      if (sequenceType === SequenceType.Concat) {
        this.shiftApiCallSequence(startApiCallPayload[0]);
      }
      if (isEmpty(startApiCallPayload[0]) || sequenceType !== SequenceType.Concat) {
        startApiCallPayload.shift();
      }
    }
    return startApiCallPayload;
  }

  static apiCallIsIdle(apiCallStatus: ApiResponseStatus) {
    return (
      !apiCallStatus ||
      apiCallStatus.status === undefined ||
      apiCallStatus.status === ApiCallStatus.Done ||
      apiCallStatus.status === ApiCallStatus.Error ||
      apiCallStatus.status === ApiCallStatus.Cancel
    );
  }

  static getApiCall(apiCallConfig: ApiCallConfig): ApiCall {
    if (!apiCallConfig) {
      return null;
    }
    if (isApiCall(apiCallConfig)) {
      return apiCallConfig;
    } else {
      return apiCallConfig.apiCall;
    }
  }

  static getCustomApiCall(apiCallConfig: ApiCallConfig, apiCallData?: ApiCallData): CustomApiCall {
    if (isApiCall(apiCallConfig)) {
      return { apiCall: apiCallConfig, ...(apiCallData && { apiCallData }) };
    } else {
      return { ...apiCallConfig, ...(apiCallData && { apiCallData }) };
    }
  }

  static shiftApiCallSequence(apiCallSequence: ApiCallSequence) {
    if (typeof first(apiCallSequence) !== 'number') {
      apiCallSequence.shift();
    }
    if (typeof first(apiCallSequence) === 'number') {
      apiCallSequence.shift();
    }
  }

  static parseApiCallSequence(apiCallPayload: ApiCallPayload): { sequenceType: SequenceType; sequence: ApiCallConfig[] } {
    const startApiCallPayload = !isApiCallSequenceOrConfig(apiCallPayload) ? cloneDeep(apiCallPayload)[0] : cloneDeep(apiCallPayload);
    if (!isArray(startApiCallPayload)) {
      return { sequenceType: SequenceType.Concat, sequence: [startApiCallPayload] };
    }
    const sequenceType = hasSequenceType(startApiCallPayload) ? startApiCallPayload.pop() : SequenceType.Concat;
    if (isApiCallConfigList(startApiCallPayload)) {
      return {
        sequenceType: (typeof sequenceType === 'number' && sequenceType) || SequenceType.Concat,
        sequence: startApiCallPayload,
      };
    } else {
      return null as never;
    }
  }

  static replaceLabels(
    sourceString = '',
    labelResolver: Record<string, string | LabelResolverFunction>,
    labelData: any = {},
    copyrightData: any,
    user: any,
    section?: string,
    tabName?: string,
    ns: string = '',
    prevResponse?: any,
  ): string {
    for (const label of this.getLabels(sourceString)) {
      const paramKey = label.replace('<<', '').replace('>>', '');
      let replaceData =
        labelData[paramKey] || (labelResolver && labelResolver[paramKey] && this.getDataForLabel(labelResolver[paramKey], copyrightData, user, section, tabName, prevResponse));
      if (!isArray(replaceData)) {
        if (/<<(.*?)>>/.test(replaceData)) {
          replaceData = this.replaceLabels(replaceData, labelResolver, labelData, copyrightData, user, section, tabName, ns, prevResponse);
        }
        sourceString = sourceString.replace(new RegExp(label, 'g'), `${(replaceData !== undefined && replaceData) || (paramKey === 'ns' && ns) || REQUEST_PARAM_NOT_FOUND}`);
      } else {
        sourceString =
          replaceData
            .map(innerData => sourceString.replace(new RegExp(label, 'g'), `${(innerData !== undefined && innerData) || (paramKey === 'ns' && ns) || REQUEST_PARAM_NOT_FOUND}`))
            .join(',') || `"${REQUEST_PARAM_NOT_FOUND}"`;
      }
    }
    return sourceString;
  }

  static getDataForLabel(labelResolverForParam: string | LabelResolverFunction, copyrightData, user: User, section: string, tabName: string, prevResponse: any): string | string[] {
    return typeof labelResolverForParam === 'string'
      ? get(copyrightData, `${(section && section + '.') || ''}${labelResolverForParam}`)
      : labelResolverForParam((section && copyrightData[section]) || copyrightData, user, tabName, prevResponse);
  }

  static getLabels(param) {
    const regexp = /<<.*?\>>/g;
    let match;
    const matches = [];
    while ((match = regexp.exec(param)) != null) {
      matches.push(match[0]);
    }
    return uniq(matches);
  }

  static getApiCallPropertiesForExportMode(exportMode) {
    return {
      resultCleaner: get(exportMode, 'apiCall.resultCleaner', []).map(resultCleanerPair => ({
        property: 'downloadBatch',
        resultCleaner: resultCleanerPair.resultCleaner,
      })),
      responseSection: 'exportMode',
    };
  }

  static getNewItems(apiCallConfig: CustomApiCall, result, state: any, section: string, translate: TranslateService, tabName: string, user: User) {
    const apiCall = apiCallConfig.apiCall;
    const detail = apiCall.copyrightGlobal ? state || {} : state[section] || {};
    let newItems = apiCall.copyrightGlobal ? {} : cloneDeep(detail);
    if (!apiCall.responseHandler && apiCall?.resultCleaner && result) {
      for (const resultCleanerPair of apiCall.resultCleaner) {
        const newProp =
          (typeof resultCleanerPair.property === 'string' && resultCleanerPair.property) ||
          (typeof resultCleanerPair.property == 'function' &&
            resultCleanerPair.property(result, detail, get(apiCallConfig, `apiCallData.cleanerData`), translate, tabName, apiCallConfig.prevResponse));
        let cleanResult = resultCleanerPair.resultCleaner(result, detail, get(apiCallConfig, `apiCallData.cleanerData`), translate, tabName, apiCallConfig.prevResponse, user);
        if (apiCall.pageable) {
          cleanResult = PaginationUtils.getPageableData(
            cleanResult,
            result,
            get(detail || {}, `${newProp}`),
            get(apiCallConfig, `apiCallData.requestQueryData.queryParamsLabels.sort`),
            apiCallConfig.fromScrollEvent,
          );
        }
        newItems = { ...set(newItems || {}, `${newProp}`, cleanResult) };
      }
    }
    return newItems;
  }

  static groupIdsByNs(ids: Array<string>): [string, string[]][] {
    return toPairs(
      mapValues(
        groupBy(
          ids.map(id => ({ id: last(id.split(':')), ns: initial(id.split(':')).join(':') })),
          'ns',
        ),
        objectIdGroup => objectIdGroup.map(objectId => `${objectId.ns}:${objectId.id}`),
      ),
    );
  }

  static apiCallToString(apiCall: ApiCall): string {
    for (const key in fromApiCalls) {
      if (fromApiCalls[key] === apiCall) {
        return key;
      }
    }
    return '' as never;
  }

  static getApiCallConfigName(scrollApiCallPayload: ApiCallConfig | ((...params) => ApiCallConfig)) {
    const scrollApiCallConfig = isApiCallConfig(scrollApiCallPayload) ? scrollApiCallPayload : scrollApiCallPayload();
    return this.apiCallToString(this.getApiCall(scrollApiCallConfig));
  }

  static getSectionInitialApiCalls(tabObject, sectionObject, detail, isInitial = false) {
    const apiCalls = tabObject && tabObject.apiCalls;
    const newActions = sectionObject && sectionObject.apiCalls;
    const initialDetailApiCalls: ApiCallPayload = cloneDeep(newActions && (isApiCallPayload(newActions) ? newActions : newActions(detail)));
    let tabApiCalls: ApiCallPayload = cloneDeep(apiCalls && (isApiCallPayload(apiCalls) ? apiCalls : apiCalls(detail)));
    tabApiCalls = !isInitial
      ? tabApiCalls
      : isApiCallSequence(tabApiCalls)
      ? (tabApiCalls.filter(apiCall => !FetchApiCallUtils.getApiCall(apiCall)?.avoidFirstTime) as ApiCallSequence)
      : isApiCallConfig(tabApiCalls)
      ? !FetchApiCallUtils.getApiCall(tabApiCalls).avoidFirstTime && tabApiCalls
      : (tabApiCalls?.map(apiCallGroup => apiCallGroup.filter(apiCall => !FetchApiCallUtils.getApiCall(apiCall)?.avoidFirstTime)) as ApiCallSequence[]);
    if (!tabApiCalls && initialDetailApiCalls) {
      return initialDetailApiCalls;
    } else if (!initialDetailApiCalls) {
      return tabApiCalls;
    }
    if (isApiCallSequenceOrConfig(initialDetailApiCalls)) {
      if (isApiCallSequenceOrConfig(tabApiCalls)) {
        const initialDetailApiCallsSequence = this.getApiCallSequence(initialDetailApiCalls);
        const tabApiCallsSequence = this.getApiCallSequence(tabApiCalls);
        this.removeDuplicatedApiCalls(initialDetailApiCallsSequence, tabApiCallsSequence);
        return [initialDetailApiCallsSequence, tabApiCallsSequence];
      } else {
        const initialDetailApiCallsSequence = this.getApiCallSequence(initialDetailApiCalls);
        tabApiCalls.map(tabApiCallsGroup => this.removeDuplicatedApiCalls(initialDetailApiCallsSequence, tabApiCallsGroup));
        tabApiCalls.push(initialDetailApiCallsSequence);
        return tabApiCalls;
      }
    } else {
      if (isApiCallSequenceOrConfig(tabApiCalls)) {
        const tabApiCallsSequence = this.getApiCallSequence(tabApiCalls);
        initialDetailApiCalls.map(initialDetailApiCallsGroup => this.removeDuplicatedApiCalls(initialDetailApiCallsGroup, tabApiCallsSequence));
        initialDetailApiCalls.push(this.getApiCallSequence(tabApiCalls));
        return initialDetailApiCalls;
      } else {
        return [...tabApiCalls, ...initialDetailApiCalls];
      }
    }
  }

  static getApiCallSequence(apiCallSequence: ApiCallSequence | ApiCallConfig): ApiCallSequence {
    return isApiCallSequence(apiCallSequence) ? apiCallSequence : (apiCallSequence && [apiCallSequence]) || [];
  }

  static removeDuplicatedApiCalls(initialDetailApiCalls: ApiCallSequence, tabApiCalls: ApiCallSequence) {
    remove(initialDetailApiCalls, initialApiCallConfig =>
      tabApiCalls
        .map(tabApiCallConfig => this.getApiCall(typeof tabApiCallConfig !== 'number' && tabApiCallConfig))
        .some(
          tabApiCall =>
            this.getApiCall(typeof initialApiCallConfig !== 'number' && initialApiCallConfig)?.id &&
            tabApiCall?.id &&
            tabApiCall?.id === this.getApiCall(typeof initialApiCallConfig !== 'number' && initialApiCallConfig)?.id,
        ),
    );
  }

  static hasNextPayload(apiCallConfig: CustomApiCall, apiCallStatus: ApiResponseStatus, error: any): boolean {
    const stopDueToErrorOrCancellation = (apiCallConfig?.stopOnError && error) || (apiCallConfig?.stopAllPendingCallsOnCancel && apiCallStatus?.status === ApiCallStatus.Cancel);

    return (
      apiCallConfig?.nextPayload &&
      (!isArray(apiCallConfig.nextPayload) || !isEmpty(apiCallConfig.nextPayload)) &&
      apiCallStatus?.mergeTotal === apiCallStatus?.mergeCount &&
      (apiCallStatus?.status !== ApiCallStatus.Cancel || isArray(apiCallConfig.nextPayload)) &&
      !stopDueToErrorOrCancellation
    );
  }

  static getPageableProperty(apiCall, detail, translate, tab) {
    let property = reverse(get(apiCall, 'resultCleaner', []))
      .map(resultCleanerPair => resultCleanerPair.property)
      .find(cleanerProperty => typeof cleanerProperty === 'string') as string;
    if (!property) {
      const propertyCleaner =
        !isEmpty(apiCall.resultCleaner) && apiCall.resultCleaner[0].property !== 'string' && (get(apiCall, 'resultCleaner[0].property', '') as PropertyCleaner);
      property = propertyCleaner ? propertyCleaner(null, detail, null, translate, tab, null) : '';
    }
    return property;
  }

  static canExecuteApiCall(apiCallConfig: CustomApiCall, populatedApiCall: ApiCall, ns: string, forceNS: boolean, permissionsService: PermissionsService) {
    if (apiCallConfig?.apiCall?.validatePermission) {
      return permissionsService.can(apiCallConfig.apiCall.validatePermission);
    }
    if (apiCallConfig?.apiCall?.validateLabels !== populatedApiCall?.validateLabels) {
      return false;
    }
    if (!populatedApiCall?.url?.split('/')?.includes(ns) && forceNS) {
      return false;
    }
    return true;
  }
}
