import { LocalStorageUtils } from '@ice/utils/local-storage/localstorage.utils';
import { ActionReducer } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { PayloadSharePicture } from 'models';
import * as fromActions from 'store/root/actions';

export const metaReducerPersist = (reducer: ActionReducer<any>): ActionReducer<any> =>
  function (state, action) {
    switch (action.type) {
      case fromActions.GET_SHARE_PICTURE_SUCCESS:
        const userName = LocalStorageUtils.get('currentUser');
        const filter: PayloadSharePicture & Record<string, unknown> = cloneDeep(action['payload'].parameters);
        const saveSettings = action['saveSettings'];
        const saveDefaultSettings = action['saveDefaultSettings'];
        // Dates are not stored in localStorage, as it is confusing when time goes by and those dates are pointing to the past
        const { usageDate, distributionDate, ...reducedFilter } = filter;
        reducedFilter.country = `${filter.country}`; // to fill autocomplete of country we need the code in string
        if (saveSettings) {
          LocalStorageUtils.setFilterSharePicture(userName, reducedFilter);
        }
        if (saveDefaultSettings) {
          reducedFilter.territory = filter.country;
          reducedFilter.mrSociety = filter.mrDistributionSocietyId.split(':')[1].padStart(3, '0');
          reducedFilter.prSociety = filter.prDistributionSocietyId.split(':')[1].padStart(3, '0');
          LocalStorageUtils.setDefaultFilterSharePicture(userName, reducedFilter);
        }
        break;
      case fromActions.LOGOUT:
        state = undefined;
        break;
    }
    return reducer(state, action);
  };
