import { Injectable } from '@angular/core';
import { CopyrightUtils } from '@ice';
import { ExpertSearchParseService } from './expert-search-parse.service';

export enum IpBaseType {
  L = 'L',
  M = 'M',
}

export enum IpiNameType {
  DF = 'DF',
  HR = 'HR',
  MO = 'MO',
  OR = 'OR',
  PA = 'PA',
  PG = 'PG',
  PP = 'PP',
  ST = 'ST',
}

export const IpBaseStatusType = ['1', '2', '3', '4'];

export enum IpNameType {
  PR = 'PR',
  OR = 'OR',
  DF = 'DF',
  MO = 'MO',
  ST = 'ST',
}

@Injectable()
export class ExpertSearchParseIpsService extends ExpertSearchParseService {
  constructor() {
    const AttributesMapIps = {
      ipiBaseNumberOrKey: async (comparator: string, value: string, _deep = false) => ({
        [comparator]: { 'parties.party.relations[XREF].otherId': `IPI:${value}` },
      }),
      ipiNameType: 'attributes.typeOfName',
      ipBaseType: 'parties.party.attributes.typeOf',
      ipBaseStatus: 'parties.party.attributes.status',
      ipDateOfBirth: 'parties.party.attributes.dateOfBirth',
      ipDateOfDeath: 'parties.party.attributes.dateOfDeath',
      ipUnidentified: async (comparator: string, value: string, _deep = false) => ({
        and: [{ [comparator]: { 'relations[DISP].otherId': `ICE:${value}` } }, { not: { [comparator]: { 'relations[XREF].otherId': `IPI:*}` } } }],
      }),
      ipName: 'attributes.name',
      ipFirstName: 'attributes.firstName',
      ipNameType: 'attributes.typeOfName',
      ipSociety: async (comparator: string, value: string, _deep = false) => ({
        [comparator]: { 'parties.party.societies.societyId': `CISAC:${CopyrightUtils.getKeySuffix(value.replace(/\d+/g, Number(value.match(/\d+/g)).toString()))}` },
      }),
      ipNameNumberOrKey: async (comparator: string, value: string, _deep = false) => ({
        and: [{ or: [{ [comparator]: { 'relations[XREF].otherId': `ICE:${value}` } }] }],
      }),
      ipiNameNumberOrKey: async (comparator: string, value: string, _deep = false) => ({
        and: [{ or: [{ [comparator]: { 'relations[XREF].otherId': `IPI:${value}` } }, { [comparator]: { 'relations[XREF].otherId': `ICE:${value}` } }] }],
      }),
      ipNameRelation: 'relations.relation',
      ipBaseNumberOrKey: 'parties.party.relations[XREF].otherId',
      ipBaseRelation: 'parties.party.relations.relation',
      ipNameIsOwnedBy: async (comparator: string, value: string, _deep = false) => ({
        and: [{ equals: { 'parties.relations.relation': 'GR' } }, { [comparator]: { 'relations[XREF].otherId': value } }, { wildcard: { 'relations[XREF].otherId': 'ICE:*' } }],
      }),
      ipBaseOwns: async (comparator: string, value: string, _deep = false) => ({
        and: [{ equals: { 'parties.relations.relation': 'GR' } }, { [comparator]: { 'parties.relations.otherId': value } }, { wildcard: { 'relations[XREF].otherId': 'ICE:*' } }],
      }),
      SocietyReference: async (comparator: string, value: string, _deep = false) => ({
        [comparator]: { 'relations[XREF].otherId': `SIREF:${value.replace('*', '')}` },
      }),
    };

    const attributesEnumMapIps = {
      ['ipBaseType']: Object.keys(IpBaseType),
      ['ipiNameType']: Object.keys(IpiNameType),
      ['ipBaseStatus']: Object.keys(IpBaseStatusType),
      ['ipNameType']: Object.keys(IpNameType),
    };

    const customKeyMap = {
      SocietyReference: ['='],
    };

    super(attributesEnumMapIps, AttributesMapIps, customKeyMap);
  }
}
