import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DataTableRow, SortInfo } from '@ice/components/data-table/data-table';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import { RootDatatable } from 'config/data-table-builders/root-datatable';
import { SectionsConfig } from 'config/sections-config';
import { User } from 'models';
import { pipe } from 'rxjs';
import { take } from 'rxjs/operators';
import * as fromRoot from 'store/root';
import * as fromActions from 'store/root/actions';
import { DataTableBuilder } from './data-table-builder';

export class ConflictsActivityDataTable extends RootDatatable implements DataTableBuilder {
  constructor(protected translate: TranslateService, protected fuseTranslationLoader: FuseTranslationLoaderService, private store: Store<fromRoot.RootState>) {
    super(translate);
    this.fuseTranslationLoader.loadTranslations(english);
  }

  getDefaultSorting(): SortInfo[] {
    return [{ prop: 'id', dir: 'asc' }];
  }

  formatSort(sort: SortInfo) {
    switch (sort.prop) {
      case 'domesticSociety':
        return { prop: 'domesticSocietiesString', dir: sort.dir };
      case 'firstCreator':
        return { prop: 'firstCreator.keyword', dir: sort.dir };
      case 'assignee':
        return { prop: 'assignee', dir: sort.dir };
      case 'formattedWorkId':
        return { prop: 'workId', dir: sort.dir };
      case 'deadline':
        return { prop: 'deadline', dir: sort.dir };
      case 'value':
        return { prop: 'value', dir: sort.dir };
      case 'uploadCategory':
        return { prop: 'uploadCategory', dir: sort.dir };
      case 'conflictArea':
        return { prop: 'caseArea', dir: sort.dir };
      case 'conflictStatus':
        return { prop: 'caseStatus', dir: sort.dir };
      case 'conflictSubType':
        return { prop: 'conflictType', dir: sort.dir };
      case 'workTitle':
        return { prop: 'title.keyword', dir: sort.dir };
    }
  }

  getDataTable(): DataTableRow[] {
    return [
      { name: this.translate.instant('ACTIVITY.WORK_KEY'), prop: 'formattedWorkId', flexGrow: 1, cellClass: 'conflict-id' },
      { name: this.translate.instant('ACTIVITY.DEADLINE_DATE'), prop: 'deadline', flexGrow: 1 },
      { name: this.translate.instant('ACTIVITY.VALUE'), prop: 'value', flexGrow: 0.8 },
      { name: this.translate.instant('ACTIVITY.UPLOAD_CATEGORY'), prop: 'uploadCategory', flexGrow: 0.6, tooltip: 'uploadCategoryTooltip' },
      { name: this.translate.instant('ACTIVITY.CONFLICT_AREA'), prop: 'conflictArea', flexGrow: 0.5, tooltip: 'conflictAreaTooltip' },
      { name: this.translate.instant('ACTIVITY.DOMESTIC_SOCIETY'), prop: 'domesticSociety', flexGrow: 1 },
      {
        name: this.translate.instant('ACTIVITY.CONFLICT_STATUS'),
        prop: 'conflictStatus',
        cellClass: 'conflict-status', // class to select in e2e Test
        selectable: true,
        callbackSelectOptions: (row: any, event: any) => {
          const newState = event.target.value;
          this.store.dispatch(new fromRoot.SetUpdateConflictType(newState));
          this.store.dispatch(new fromRoot.StartSelectMode());
          this.store.dispatch(new fromRoot.SetSelectMode([row]));
          this.store.pipe(select(fromRoot.getLoginUser), pipe(take(1))).subscribe((user: User) => {
            const userId = user.detail.id;
            this.store.dispatch(new fromRoot.SetAssigneeConflictsSelection({ userId }));
            this.store.dispatch(new fromActions.AssignSelectedConflictsToUser());
          });
        },
        flexGrow: 1.5,
      },
      { name: this.translate.instant('ACTIVITY.CONFLICT_SUB_TYPE'), prop: 'conflictSubType', cellClass: 'conflict-subtype', flexGrow: 1 },
      {
        name: this.translate.instant('ACTIVITY.WORK_TITLE'),
        prop: 'workTitle',
        flexGrow: 1.5,
        cellClass: 'work-title', // class to select in e2e Test
        hiddenValue: 'workId',
        onClickAction: row =>
          this.store.dispatch(
            new fromRoot.Go({
              path: [`${SectionsConfig.WORKS.domainName}/${SectionsConfig.WORKS.apiSegment}/${row.workId}`],
            }),
          ),
      },
      { name: this.translate.instant('ACTIVITY.FIRST_CREATOR'), prop: 'firstCreator', flexGrow: 1.5 },
      { name: this.translate.instant('ACTIVITY.ASSIGNEE'), prop: 'assignee', flexGrow: 1 }, ////assigneeUserId
    ];
  }

  getColumnMode(): ColumnMode {
    return ColumnMode.flex;
  }

  displayCheck(row: any, firstSelectedItem: any, targetItem: any, column?: any, value?: any): boolean {
    return true;
  }
}
