import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { FuseSharedModule } from '@fuse/shared.module';
import { IceMaterialModule } from '@ice/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { ExpansionListLabeledComponent } from './expansion-list-labeled.component';

@NgModule({
  imports: [CommonModule, TranslateModule, IceMaterialModule, FlexLayoutModule, FormsModule, FuseSharedModule],
  declarations: [ExpansionListLabeledComponent],
  exports: [ExpansionListLabeledComponent],
})
export class ExpansionListLabeledModule {}
