import { UserCleaned } from 'models/users/users.model';

export const UserInitModel: UserCleaned = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  ns: 'ICE',
  organizationId: '',
  roles: '',
  status: 'NEW',
  lastAttempt: '',
  version: 1,
};
