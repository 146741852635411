export const AGREEMENTS_CONSTANTS = {
  SALES: 'S',
  MANUFACTURE: 'M',
  GENERAL: 'G',
  SPECIFIC: 'S',
  TYPE_ASSIGNOR: 'assignor',
  TYPE_ASSIGNEE: 'assignee',
  GEMA_SOCIETY: '035',
};

export const RELATED_AGREEMENT_TYPES = {
  RECIPIENT: 'r',
  COPUBLISHER: 'c',
};

export const GENERIC_IP_NAME_KEY_WITHOUT_ICE = '99999999999';
export const GENERIC_IP_NAME_KEY = `ICE:${GENERIC_IP_NAME_KEY_WITHOUT_ICE}`;

export enum AddAgreementWorks {
  Single = 'ADD_SINGLE_WORK',
  Search = 'SEARCH_WORK_TO_ADD',
  Import = 'IMPORT_WORKS_TO_ADD',
}

export enum AgreementWorksType {
  Inclusion = 'inclusion',
  Exclusion = 'exclusion',
}

export enum AgreementRetentionSubmitStatus {
  None = 'None',
  SubmitRetention = 'SubmitRetention',
  Error = 'Error',
  AddWorks = 'AddWorks',
  Done = 'Done',
}

export enum AGREEMENT_COUNTERCLAIM_STATUS {
  POTENTIAL_CONFLICT = 'POTENTIAL_CONFLICT',
  CONFIRMED = 'CONFIRMED',
  RESOLVED = 'RESOLVED',
  OPEN = 'OPEN',
}

export const SEARCH_TYPE_AGREEMENT_DIALOG = 'agreementConflict';

export enum AGREEMENT_XREF_TYPE {
  GROUP = 'group',
  RELATION = 'relation',
}

export const MAX_CONFCLITS_IN_TOOLTIP = 3;

export enum AGREEMENT_XREF_LABEL {
  GROUP = 'group',
  SOCIETY = 'society',
  PUBLISHER = 'publisher',
}

export const LINKED_WRITERS_IP_SEARCH = 'LINKED_WRITERS_IP_SEARCH';
