import { FormlyFieldConfig } from '@ngx-formly/core';
import { SelectOptions } from '../stepper-select-options';

export class SummaryAgreementTerms {
  static getFormField(translate): FormlyFieldConfig[] {
    const options = SelectOptions.getOptions(translate);
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [{ className: 'flex-1', template: `<h2 class="ice-text-color-blue">${translate.instant('AGREEMENTS.AGREEMENT_TERM.TITLE')}</h2>` }],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1 ice-retention-agreement-label',
            key: 'start_date',
            type: 'label',
            templateOptions: { text: translate.instant('AGREEMENTS.AGREEMENT_TERM.START_DATE.NAME'), disabled: true, isSummary: true },
          },
          {
            className: 'flex-1 ice-value-mt-25 ice-txt-size-18',
            key: 'prior_royalties',
            type: 'select',
            templateOptions: {
              label: translate.instant('AGREEMENTS.AGREEMENT_TERM.PRIOR_ROYALTIES.NAME'),
              options: options.agreementTerms.prior_royalties,
              disabled: true,
              isSummary: true,
            },
          },
          {
            className: 'flex-1 ice-retention-agreement-label',
            key: 'expected_termination',
            type: 'label',
            templateOptions: { text: translate.instant('AGREEMENTS.AGREEMENT_TERM.EXPECTED_TERMINATION.NAME'), disabled: true, isSummary: true },
          },
        ],
      },
    ];
  }
}
