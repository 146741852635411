import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { get } from 'lodash';

@Component({
  selector: 'ice-formly-field-upload-file',
  templateUrl: './upload-file-type.component.html',
  styleUrls: ['./upload-file-type.component.scss'],
})
export class UploadFileTypeComponent extends FieldType {
  currentErrorMessage = '';

  getCurrentError() {
    if (this.field) {
      const errors = this.formControl.errors || {};
      const currentErrorKey = Object.keys(errors).find(key => errors[key]);
      const currentError = get(this.field, `validators[${currentErrorKey}]`, null) || get(this.field, `asyncValidators[${currentErrorKey}]`, null) || { message: '' };
      this.currentErrorMessage = currentError.message;
    }
    return this.currentErrorMessage;
  }
}
