import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HighlightContainerDirective } from './highlight-container.directive';
import { HighlightElementDirective } from './highlight-element.directive';
import { HighlightComponent } from './highlight.component';

@NgModule({
  declarations: [HighlightComponent, HighlightContainerDirective, HighlightElementDirective],
  imports: [CommonModule],
  exports: [HighlightComponent, HighlightContainerDirective, HighlightElementDirective],
})
export class HighlightModule {}
