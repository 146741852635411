import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '@ice/i18n/en/button-labels';
import { FieldArrayType, FormlyFieldConfig, FormlyFormBuilder } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { isEmpty } from 'lodash';

@Component({
  selector: 'ice-formly-field-datatable',
  templateUrl: './datatable-type.component.html',
  styles: ['.custom-error {color:red; margin-left: 10px;font-size: 12px;}'],
})
export class DatatableTypeComponent extends FieldArrayType implements OnInit {
  @ViewChild('defaultColumn', { static: true }) public defaultColumn: TemplateRef<any>;
  customError: string;
  addButtonText: string;
  addButtonEnabled = false;
  removeButtonText: string;
  removeButtonEnabled = false;

  ngOnInit() {
    this.to.columns.forEach(column => (column.cellTemplate = this.defaultColumn));
    this.addButtonEnabled = !!this.field.templateOptions['addButtonEnabled'];
    if (this.field.templateOptions['addButtonText']) {
      this.addButtonText = this.field.templateOptions['addButtonText'];
    }
    this.removeButtonEnabled = !!this.field.templateOptions['removeButtonEnabled'];
    if (this.field.templateOptions['removeButtonText']) {
      this.removeButtonText = this.field.templateOptions['removeButtonText'];
    }
  }

  constructor(private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService) {
    super();
    this.fuseTranslationLoader.loadTranslations(english);
    this.addButtonText = this.translate.instant('ADD_BUTTON');
    this.removeButtonText = this.translate.instant('REMOVE_BUTTON');
  }

  getField(field: FormlyFieldConfig, column: TableColumn, rowIndex: number): FormlyFieldConfig {
    return field.fieldGroup[rowIndex].fieldGroup.find(f => f.key === column.prop);
  }

  addItem() {
    this.customError = '';
    const model = this.field.model;
    let isNotEmpty = true;
    model.forEach(item => {
      if (isEmpty(item)) {
        isNotEmpty = false;
      }
    });
    if (this.field.formControl.status === 'VALID' && isNotEmpty) {
      this.add();
    } else {
      if (isNotEmpty) {
        this.customError = this.translate.instant('ERROR.DATATABLE.NO_INVALID_VALUES');
      } else {
        this.customError = this.translate.instant('ERROR.DATATABLE.NO_EMPTY_ROWS');
      }
    }
  }
}
