import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ClaimantUtils, IpUtils, RelationsUtils } from '@ice';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as sharedEnglish } from 'assets/i18n/en/app/common/shared';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import { ICE, ICE_PREFIX } from 'config/constants/global.constants';
import { PartyRelationType } from 'config/constants/ips.constants';
import { ClaimSteps } from 'config/stepper-builders/claim/claim-steps';
import { SectionTabConfig } from 'config/tabs-data-builders/tabs-data';
import { IceFacade } from 'facades/ice.facade';
import { get } from 'lodash';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';
import { CommonApiService } from 'services/common-api.service';
import { DetailService } from 'services/detail/detail.service';
import { NamespaceService } from 'services/namespace/namespace.service';
import { PermissionsService } from 'services/permissions/permissions.service';
import { SearchService } from 'services/search/search.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromNewSectionItem from 'store/new-section-item';
import * as fromRoot from 'store/root';

// TODO: Add Angular decorator.
export class TabEditClaim implements SectionTabConfig {
  unsubscribeAll = new Subject();
  isFormValid = new BehaviorSubject(false);
  groupModel = {};
  private claimsSubscription: Subscription;
  editClaim: string;
  editConflictId: string;

  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private dialog: MatDialog,
    private commonApiService: CommonApiService,
    private detailService: DetailService,
    private nsService: NamespaceService,
    private iceFacade: IceFacade,
    private fieldValidatorService: FieldValidatorService,
    private permissionsService: PermissionsService,
    private searchService: SearchService,
    private storeNewItem: Store<fromNewSectionItem.NewSectionItemState>,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.fuseTranslationLoader.loadTranslations(sharedEnglish);
    this.store.dispatch(new fromRoot.ForceEditMode({ editing: true, editId: ICE, editName: ICE, editClaim: 'claimId', editingClaim: true }));
  }

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'stepper',
            config: {
              stepperConfig: {
                model: this.store.pipe(
                  select(fromRoot.getCopyrightWork),
                  withLatestFrom(this.store.pipe(select(fromRoot.getRouterParams))),
                  filter(([work, params]) => work && work.claims && !!work.editConflictId && params.key === work.id),
                  take(1),
                  map(([detail, params]) => {
                    this.editClaim = detail && detail.editClaim;
                    const { claims, editConflictId, conflicts } = detail;
                    const conflictSelected = conflicts.find(conflict => conflict.conflictId === editConflictId);
                    if (conflictSelected && conflictSelected.claimsWithMissingLinks && conflictSelected.claimsWithMissingLinks.length > 0) {
                      this.editClaim = conflictSelected.claimsWithMissingLinks[0];
                      this.store.dispatch(new fromRoot.SetEditClaim({ editClaim: this.editClaim }));
                    }

                    if (this.editClaim && claims && claims.length && claims.length > 0) {
                      const claim = ClaimantUtils.getClaimFromClaims(this.editClaim, claims);
                      const claimantPartyNameRelations = get(claim, 'claimant.partyName.relations', []);
                      const claimantIPI = IpUtils.selectIPINumber(claimantPartyNameRelations);
                      const claimantIPNameNumber = RelationsUtils.selectRelationByTypeAndPrefix(
                        claimantPartyNameRelations,
                        [PartyRelationType.DISPLAY_REFERENCE, PartyRelationType.CROSS_REFERENCE],
                        [ICE_PREFIX],
                      );
                      return {
                        ipiNumber: '',
                        role: '',
                        startDate: '',
                        endDate: '',
                        ClaimantType: '',
                        ClaimantisDummyIP: false,
                        ClaimantIPNameNumber: claimantIPI && claimantIPI.length && claimantIPI.length > 0 ? claimantIPI : claimantIPNameNumber,
                        PublisherType: '',
                        shares: ClaimantUtils.getParsedSharesFromClaim(claim),
                      };
                    }
                    if (!conflictSelected) {
                      this.store.dispatch(new fromRoot.GetItemNotFound());
                      this.store.dispatch(
                        new fromRoot.EndEditMode({
                          pathOnEnd: '/conflicts/activity',
                        }),
                      );
                    }
                    return null;
                  }),
                ),
                steps: ClaimSteps.getSteps(
                  this.translate,
                  this.fuseTranslationLoader,
                  this.store,
                  this.storeNewItem,
                  this.fieldValidatorService,
                  this.searchService,
                  this.dialog,
                  this.permissionsService,
                  null,
                  null,
                  true,
                ),
              },
              change: model => {
                this.store.dispatch(new fromNewSectionItem.SaveField(model));
              },
              setValidForm: isValid => {
                this.store.dispatch(new fromNewSectionItem.SetIsValidAndChangedForm(isValid));
              },
            },
          },
        ],
      },
    ];
  }
}
