import { get, uniq } from 'lodash';
import { createLabelResolver } from '../api-call.factory';

export const getWorksPatternLabelResolver = createLabelResolver({
  pattern: detail => `{ "equals": {"id":"<<workId>>"} }`,
});

export const getExcludedWorkIdsLabelResolver = createLabelResolver({
  workId: detail => get(detail, `attributes.exclude.workIds`, []),
});

export const getIncludedWorkIdsLabelResolver = createLabelResolver({
  workId: detail => get(detail, `works`, []).map(work => work.workId),
});

export const getExcludedAgreementsPatternLabelResolver = createLabelResolver({
  pattern: detail => `{"equals":{"id":"<<agreementId>>"}}`,
});

export const getAgreementIdsLabelResolver = createLabelResolver({
  agreementId: detail => {
    return uniq(get(detail, `attributes.exclude.agreementIds`, []).filter(agreementId => !!agreementId));
  },
});

export const getAttributesId = createLabelResolver({ id: `attributes.id` });
