import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatTreeModule } from '@angular/material/tree';
import { FuseSharedModule } from '@fuse/shared.module';
import { IceMaterialModule } from '@ice/material.module';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormContainerModule } from '../form-container/form-container.module';
import { FormlyFieldButtonComponent } from '../formly-types/button-type/button-type.component';
import { FormlyFieldButtonModule } from '../formly-types/button-type/button-type.module';
import { TreeNestedComponent } from './dialog-json.component';
export const Window = new InjectionToken('window.injection');

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    IceMaterialModule,
    FlexLayoutModule,
    FormsModule,
    FuseSharedModule,
    FormContainerModule,
    FormlyFieldButtonModule,
    MatTreeModule,
    FormlyModule.forRoot({
      extras: { checkExpressionOn: 'modelChange' },
      types: [{ name: 'button', component: FormlyFieldButtonComponent }],
    }),
  ],
  declarations: [TreeNestedComponent],
  exports: [TreeNestedComponent],
})
export class TreeNestedModule {}
