import { CounterClaimTypes } from 'config/constants/activity.constants';

export enum PointOfConflict {
  WRITER_AND_ORIGINAL_PUBLISHER = 'WRITER_AND_ORIGINAL_PUBLISHER',
  PUBLISHER_AND_SUBPUBLISHER = 'PUBLISHER_AND_SUBPUBLISHER',
  BETWEEN_CREATORS = 'BETWEEN_CREATORS',
  INFRINGED_PARTIES = 'INFRINGED_PARTIES',
  NEW_CREATORS = 'NEW_CREATORS',
}

export type PointOfConflictTypes = 'WRITER_AND_ORIGINAL_PUBLISHER' | 'PUBLISHER_AND_SUBPUBLISHER' | 'BETWEEN_CREATORS' | 'INFRINGED_PARTIES' | 'NEW_CREATORS';

export type PointOfConflictMap = {
  [key in PointOfConflictTypes]?: CounterClaimConflictCondition[][];
};

export type PointOfConflictConditionMap = {
  [key in CounterClaimTypes]?: PointOfConflictMap;
};

export type CounterClaimConflictCondition =
  | 'NOT_ALL_SAME_ORIGINAL'
  | 'ALL_SAME_ORIGINAL'
  | 'NEW_CLAIMANT_ORIGINAL_SAME'
  | 'NEW_CLAIMANT_SAME_PARENT'
  | 'NEW_CLAIMANT_NO_ORIGINAL_SAME'
  | 'NEW_CLAIMANT_NOT_SAME_PARENT'
  | 'NEW_CLAIMANT_NO_SUBPUBLISHER'
  | 'FIRST_CLAIMANT_ORIGINAL'
  | 'FIRST_CLAIMANT_CREATOR'
  | 'NEW_CLAIMANT_SUBPUBLISHER'
  | 'FIRST_CLAIMANT_SUBPUBLISHER'
  | 'NEW_CLAIMANT_ORIGINAL'
  | 'NEW_CLAIMANT_SHARE_GREATER_EQUALS_100'
  | 'NEW_CLAIMANT_SHARE_LESS_100'
  | 'FIRST_CLAIMANT_SHARE_EQUALS_100';

export type CounterClaimConflictConditionMap = {
  [key in CounterClaimConflictCondition]?: boolean;
};

export enum ClaimantType {
  ORIGINAL_PUBLISHER = 'ORIGINAL_PUBLISHER',
  CREATOR = 'CREATOR',
  WRITER = 'WRITER',
  SUB_PUBLISHER = 'SUB_PUBLISHER',
}

export enum CounterClaimClaimantStatus {
  AWAITING_REPLY = 'AWAITING_REPLY',
  EARLY_WARNING = 'EARLY_WARNING',
  MEMBER_RESOLVE = 'MEMBER_RESOLVE',
  REDUCED = 'REDUCED',
  WITHDRAWN = 'WITHDRAWN',
  NOT_SUPPORTED = 'NOT_SUPPORTED',
  SUPPORTED = 'SUPPORTED',
  DOC_REJECTED = 'DOC_REJECTED',
  RESOLVED = 'RESOLVED',
  CONFIRMED = 'CONFIRMED',
}

export enum CounterClaimClaimantRelation {
  FIRST = 'FIRST',
  NEW = 'NEW',
  INFORMED = 'INFORMED',
  RESOLVER = 'RESOLVER',
}

export const CC_STATUS_POSS_OVERLAP = 'OPEN';
export const CC_STATUS_POTENTIAL_CONFLICT = 'POTENTIAL_CONFLICT';
export const CC_STATUS_CONFIRMED_DISPUTE = 'CONFIRMED';
export const CC_STATUS_RESOLVED = 'RESOLVED';
export const CC_STATUS_CLOSED = 'CLOSED';

export const AGREEMENT_CONFLICT_STATUS = {
  [CC_STATUS_POSS_OVERLAP]: 'AGREEMENT_CONFLICT.STATUS_OVERLAP',
  [CC_STATUS_POTENTIAL_CONFLICT]: 'AGREEMENT_CONFLICT.STATUS_CONFLICT',
  [CC_STATUS_CONFIRMED_DISPUTE]: 'AGREEMENT_CONFLICT.STATUS_DISPUTE',
  [CC_STATUS_RESOLVED]: 'AGREEMENT_CONFLICT.STATUS_RESOLVED',
  [CC_STATUS_CLOSED]: 'AGREEMENT_CONFLICT.STATUS_CLOSED',
};

export enum CounterClaimConflictStatus {
  OPEN = 'OPEN',
  POTENTIAL_CONFLICT = 'POTENTIAL_CONFLICT',
  CONFIRMED = 'CONFIRMED',
  RESOLVED = 'RESOLVED',
  CLOSED = 'CLOSED',
}

export enum CounterClaimDocumentCurrentState {
  UPLOADING = 'UPLOADING',
  UPLOADED = 'UPLOADED',
  ERROR = 'ERROR',
}

export enum CounterClaimWithdrawnStatus {
  DELETE = 'DELETE',
  TERMINATE = 'TERMINATE',
}

export enum CounterClaimDocumentStatus {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export enum CounterClaimPostTermCollectionValue {
  NONE = 'NONE',
  DATE = 'DATE',
  INDEFINITE = 'INDEFINITE',
}
