import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'ice-json-viewer',
  templateUrl: 'dialog-json-viewer.component.html',
  styleUrls: ['./dialog-json-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogJsonViewerComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: Observable<string>;
      json: Observable<any>;
      expanded: Observable<boolean>;
      tooltip: string;
    },
  ) {}
}
