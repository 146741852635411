import {
  AgreementSearchItem,
  AgreementSearchItemCleaned,
  AgreementUtils,
  CopyrightUtils,
  CounterClaimUtils,
  Ip,
  IpUtils,
  PartyAttributes,
  PartySociety,
  TerritoryUtils,
} from '@ice';
import { ClaimsUtils } from '@ice/utils/claim/claims.utils';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TerritoryDataType } from 'config/constants/territories.constants';
import { get } from 'lodash';

export class SearchAgreementsParser {
  static parse(items: any[], translate: TranslateService, extraParams?: Object, extraItems?: string[], store?: Store<any>) {
    return items.map((item: AgreementSearchItem) => this.parseItem(item, translate));
  }

  static parseItem(item: AgreementSearchItem, translate: TranslateService): AgreementSearchItemCleaned {
    const { id, attributes, relations, assignor, assignee, counterclaims } = item;
    const workCount = get(item, 'agg.attributes.workCount', 0);
    if (attributes) {
      const { shares, administrator, retention, termTerritory, searchTerm } = attributes;
      let fromShares = {
        territoriesIcon: null,
        territoriesText: null,
        territoriesTooltip: null,
        startDate: null,
        endDate: null,
        endDateToExport: null,
        postTermCollectionDate: null,
        postTermCollectionDateToExport: null,
        priorRoyaltiesStartDate: null,
        sharesIcons: null,
        pr: null,
        prIcons: null,
        prToExport: null,
        mr: null,
        mrIcons: null,
        mrToExport: null,
        partiallyTerminatedIcons: null,
      };
      if (shares) {
        fromShares = { ...ClaimsUtils.getSharesFormatted(shares, translate) };
      }

      let assignorPartyAttributes: PartyAttributes;
      let assignorPartyNameAttributes: Ip;
      let assignorSocieties: PartySociety[];
      let assigneePartyNameAttributes: Ip;
      let assigneeSocieties: PartySociety[];
      let assignorObject = {};
      let assigneeObject = {};
      if (assignor) {
        assignorPartyNameAttributes = get(assignor, 'partyName.attributes');
        if (assignor.party) {
          assignorPartyAttributes = assignor.party.attributes;
          assignorSocieties = assignor.party.societies;
          if (assignorPartyNameAttributes) {
            assignorObject = {
              id: assignorPartyNameAttributes.id,
              name: assignorSocieties && AgreementUtils.selectPartyName(assignorPartyNameAttributes, assignorSocieties, true),
              icons: AgreementUtils.generateAssignorIcons(assignorPartyAttributes, translate),
            };
          }
        } else if (assignor.name) {
          assignorObject = assignor;
        }
      }
      if (assignee) {
        assigneePartyNameAttributes = get(assignee, 'partyName.attributes');
        assigneeSocieties = get(assignee, 'party.societies');
        if (assigneePartyNameAttributes) {
          assigneeObject = {
            id: assigneePartyNameAttributes.id,
            name: assigneeSocieties && AgreementUtils.selectPartyName(assigneePartyNameAttributes, assigneeSocieties, true),
          };
        } else if (assignee.name) {
          assigneeObject = assignee;
        }
      }

      const key = IpUtils.selectIpsKey(relations, id);

      const territoriesTISA = TerritoryUtils.convertTerritoryArrayElements(termTerritory ? termTerritory.inExTisns : [], TerritoryDataType.TISA);
      const territoriesNames = TerritoryUtils.convertTerritoryArrayElements(termTerritory ? termTerritory.inExTisns : [], TerritoryDataType.NAME);
      const territoriesTooltip = TerritoryUtils.getTerritoriesNamesTooltipText(TerritoryUtils.getTerritoriesOrderedByCriteria(territoriesNames));

      const agreementCounterClaimStatus = CounterClaimUtils.getAgreementCounterClaimStatus(counterclaims);
      let agreementTypeTooltip;
      if (attributes?.agreementType) {
        agreementTypeTooltip = translate.instant(`AGREEMENTS.TYPE_TOOLTIP.${attributes.agreementType}`);
      }
      const { endDateUpdated, postTermCollectionDateUpdated } = AgreementUtils.comparePTCTerminationDates(fromShares.endDate, fromShares.postTermCollectionDate);

      return <AgreementSearchItemCleaned>{
        ...attributes,
        key,
        keyWithoutPrefix: CopyrightUtils.getKeySuffix(key),
        startDate: fromShares.startDate,
        endDate: endDateUpdated,
        endDateToExport: fromShares.endDateToExport,
        partiallyTerminatedIcons: fromShares.partiallyTerminatedIcons,
        postTermCollectionDate: postTermCollectionDateUpdated,
        postTermCollectionDateToExport: fromShares.postTermCollectionDateToExport,
        priorRoyaltiesStartDate: fromShares.priorRoyaltiesStartDate,
        administratorIcon: AgreementUtils.generateAdministratorIcon(administrator, translate),
        disputeIcon: AgreementUtils.generateDisputeIcon(agreementCounterClaimStatus, translate),
        admin: AgreementUtils.generateAdministratorIcon(administrator, translate)
          .map(icon => icon.text)
          .join(', '),
        dispute: AgreementUtils.generateDisputeIcon(agreementCounterClaimStatus, translate)
          .map(icon => icon.text)
          .join(', '),
        sharesIcons: fromShares.sharesIcons,
        territoriesText: territoriesTISA.join(' '),
        territoriesIcon: [],
        territoriesTooltip,
        pr: fromShares.pr,
        prIcons: fromShares.prIcons,
        prToExport: fromShares.prToExport,
        mr: fromShares.mr,
        mrIcons: fromShares.mrIcons,
        mrToExport: fromShares.mrToExport,
        assignor: assignorObject,
        assignee: assigneeObject,
        workCount,
        dataType: 'agreement',
        retention: false,
        retentionValue: AgreementUtils.getRetentionIcon(retention, translate)
          .map(icon => icon.text)
          .join(', '),
        retentionIcons: AgreementUtils.getRetentionIcon(retention, translate),
        agreementCounterClaimStatus,
        searchTerm: searchTerm === undefined ? '' : searchTerm,
        agreementTypeTooltip,
      };
    }
    return <AgreementSearchItemCleaned>item;
  }
}
