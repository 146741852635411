import { Injectable } from '@angular/core';
import { ApiNamespace } from '@ice';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromRoot from 'store/root';

@Injectable()
export class NamespaceService {
  namespace: ApiNamespace;
  private subscriptionApiNamespace: Subscription;

  constructor(private store: Store<fromRoot.RootState>) {
    this.subscriptionApiNamespace = this.store.pipe(select(fromRoot.getGlobalApiNamespace)).subscribe((ns: ApiNamespace) => {
      this.namespace = ns;
    });
  }

  getNamespace(): string {
    return this.namespace;
  }
}
