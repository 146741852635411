import { NgModule } from '@angular/core';
import { AddPercentToNumberPipe } from './addPercentToNumber.pipe';
import { SafeHtml } from './safeHtml.pipe';
import { Escape } from './escape.pipe';

@NgModule({
  declarations: [SafeHtml, AddPercentToNumberPipe, Escape],
  imports: [],
  exports: [SafeHtml, AddPercentToNumberPipe, Escape],
})
export class IcePipesModule {}
