import { MatDialogRef } from '@angular/material/dialog';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

export class ReportUtils {
  static downloadFile(http, id: string, data: any, type: string) {
    const url = window.URL.createObjectURL(new Blob([data], { type }));
    return http.get(url, {
      responseType: 'blob',
      reportProgress: true,
      observe: 'events',
    });
  }

  static downloadText(translate: TranslateService, percentage: number) {
    return `${translate.instant('REPORTS.DOWNLOADING')} <br>
            ${percentage} % ${translate.instant('EXPORT_MODE.COMPLETED')}`;
  }

  static closeDownloadPopup(downloadCall: Subscription, dialogRef: MatDialogRef<DialogMultiLayoutComponent, any>) {
    if (downloadCall) {
      downloadCall.unsubscribe();
    }
    dialogRef.close();
  }
}
