import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CounterClaimUtils, CurrentOrganization } from '@ice';
import { DataTableRow } from '@ice/components/data-table/data-table';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SelectionType } from '@swimlane/ngx-datatable';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import { SectionTabConfig } from 'config/tabs-data-builders/tabs-data';
import { cloneDeep, differenceBy } from 'lodash';
import { CounterClaimDetail } from 'models/copyright/detail/counterclaim';
import { combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, map, take, withLatestFrom } from 'rxjs/operators';
import { NamespaceService } from 'services/namespace/namespace.service';
import * as fromRoot from 'store/root';
import { getUserCurrentOrganization } from 'store/root';

export class TabCounterClaimsDocuments implements SectionTabConfig {
  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private dialog: null,
    private commonApiService: null,
    private detailService: null,
    private nsService: NamespaceService,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
    combineLatest([
      this.store.select(fromRoot.getCounterClaimWork).pipe(filter(counterClaim => !!counterClaim)),
      this.store.select(getUserCurrentOrganization).pipe(filter(userCurrentOrganization => !!userCurrentOrganization)),
    ])
      .pipe(take(1))
      .subscribe(([counterClaim, userCurrentOrganization]) => {
        this.userCurrentOrganization = userCurrentOrganization;
        this.counterClaim = counterClaim;
      });
  }
  private userCurrentOrganization: CurrentOrganization;
  private counterClaim: CounterClaimDetail;
  private isDownloadMode: boolean;
  private isRejectionMode: boolean;

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('COUNTER_CLAIMS.DOCUMENTS.TAB_TITLE'),
              model: this.store.pipe(
                select(fromRoot.getCounterClaimDocuments),
                distinctUntilChanged((prev, actual) => !differenceBy(actual, prev, value => `${value['id']}-${value['status']}`).length),
              ),
              dontStrip: true,
              sorts: [],
              schema: this.getDataTable(),
              selected: this.store.pipe(
                withLatestFrom(this.store.pipe(select(fromRoot.getCounterClaimDownloadDocumentMode)), this.store.pipe(select(fromRoot.getCounterClaimRejectDocumentMode))),
                map(([_, downloadDocumentMode, rejectDocumentMode]) => {
                  this.isDownloadMode = downloadDocumentMode;
                  this.isRejectionMode = rejectDocumentMode;
                  return { downloadDocumentMode, rejectDocumentMode };
                }),
                distinctUntilChanged((prev, actual) => prev.downloadDocumentMode !== actual.downloadDocumentMode || prev.rejectDocumentMode !== actual.rejectDocumentMode),
                map(allowed => []),
              ),
              onSelect: (selecteds: any[]) => {
                if (this.isDownloadMode) {
                  this.store.dispatch(new fromRoot.SaveDownloadDocuments(cloneDeep(selecteds)));
                } else if (this.isRejectionMode) {
                  this.store.dispatch(new fromRoot.SaveRejectDocuments(cloneDeep(selecteds)));
                }
              },
              visibleColumns: this.store.pipe(
                withLatestFrom(this.store.pipe(select(fromRoot.getCounterClaimDownloadDocumentMode)), this.store.pipe(select(fromRoot.getCounterClaimRejectDocumentMode))),
                map(([_, downloadDocumentMode, rejectDocumentMode]) =>
                  this.getDataTable()
                    .filter(column => (!downloadDocumentMode && !rejectDocumentMode ? column.prop !== 'rowSelected' : true))
                    .map(column => column.prop),
                ),
              ),
              displayCheck: document =>
                CounterClaimUtils.canDownloadDocument(this.isDownloadMode, document, this.userCurrentOrganization, this.counterClaim) ||
                CounterClaimUtils.canRejectDocument(this.isRejectionMode, document, this.userCurrentOrganization),
              loadingIndicator: false,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              selectionType: of(SelectionType.checkbox),
              reorderable: true,
              shadowed: false,
              columnMode: 'flex',
            },
          },
        ],
      },
    ];
  }

  getDataTable(): DataTableRow[] {
    return [
      {
        name: '',
        prop: 'rowSelected',
        flexGrow: 0.55,
        headerCheckboxable: false,
        checkboxable: true,
        width: 50,
        resizeable: false,
        canAutoResize: false,
        draggable: false,
        sortable: false,
        headerTooltip: ' ',
      },
      { name: this.translate.instant('COUNTER_CLAIMS.DOCUMENTS.DATATABLE.DATETIME'), prop: 'dateTime', flexGrow: 2, headerTooltip: ' ' },
      { name: this.translate.instant('COUNTER_CLAIMS.DOCUMENTS.DATATABLE.DOCUMENT_ID'), prop: 'id', flexGrow: 2, headerTooltip: ' ' },
      { name: this.translate.instant('COUNTER_CLAIMS.DOCUMENTS.DATATABLE.FILE_NAME'), prop: 'fileName', flexGrow: 2, headerTooltip: ' ' },
      { name: this.translate.instant('COUNTER_CLAIMS.DOCUMENTS.DATATABLE.USER'), prop: 'user', flexGrow: 2, headerTooltip: ' ' },
      { name: this.translate.instant('COUNTER_CLAIMS.DOCUMENTS.DATATABLE.OWNER_NAME'), prop: 'ownerName', flexGrow: 2, headerTooltip: ' ' },
      { name: this.translate.instant('COUNTER_CLAIMS.DOCUMENTS.DATATABLE.OWNER_IPI_NAME_NUMBER'), prop: 'ownerIPINameNumber', flexGrow: 2, headerTooltip: ' ' },
      { name: this.translate.instant('COUNTER_CLAIMS.DOCUMENTS.DATATABLE.CLAIMANT_NAME'), prop: 'claimantName', flexGrow: 2, headerTooltip: ' ' },
      { name: this.translate.instant('COUNTER_CLAIMS.DOCUMENTS.DATATABLE.CLAIMANT_IPI_NAME_NUMBER'), prop: 'claimantIPINameNumber', flexGrow: 2, headerTooltip: ' ' },
      { name: this.translate.instant('COUNTER_CLAIMS.DOCUMENTS.DATATABLE.STATUS'), prop: 'status', tooltip: 'reason', canHideTooltip: true, flexGrow: 2, headerTooltip: ' ' },
      { name: this.translate.instant('COUNTER_CLAIMS.DOCUMENTS.DATATABLE.SHARED'), prop: 'shared', flexGrow: 2, headerTooltip: ' ' },
    ];
  }
}
