import { Injectable } from '@angular/core';
import { union } from 'lodash';

@Injectable()
export class StorageService {
  getEntity(key: string) {
    if (key && key.length) {
      const entity = localStorage.getItem(key);
      return (entity && this.isJsonString(entity) && JSON.parse(entity)) || entity || null;
    }
    return null;
  }

  setEntity(key: string, value: any, add = false, maxAdd = 100) {
    if (key && key.length) {
      const currentValue = localStorage.getItem(key);
      if (this.isJsonObject(value)) {
        localStorage.setItem(
          key,
          add && currentValue && this.isJsonString(currentValue) ? JSON.stringify(this.concatValues(JSON.parse(currentValue), value, maxAdd)) : JSON.stringify(value),
        );
      } else {
        localStorage.setItem(key, add && currentValue && !this.isJsonString(currentValue) ? `${currentValue},${value}` : `${value}`);
      }
    }
  }

  removeEntity(key: string) {
    if (key && key.length) {
      localStorage.removeItem(key);
    }
  }

  private concatValues(current, next, maxAdd) {
    if (Array.isArray(current) && Array.isArray(next)) {
      if (maxAdd) {
        current = current.slice(current.length - maxAdd, current.length);
      }
      return union(next, current);
    } else {
      return { ...current, ...next };
    }
  }

  private isJsonObject(obj) {
    return ['boolean', 'number', 'string', 'symbol', 'function'].indexOf(typeof obj) === -1;
  }

  private isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
