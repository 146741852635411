import { ATTRIBUTES } from 'config/constants/territories.constants';
import { SectionsConfig } from 'config/sections-config';
import { RequestType } from 'config/sections-config/api-call';
import { environment } from 'config/env';
import { createApiCall, isPageable, setBody, setLabelResolver, setPageable, setQueryParams, setResultCleaner, validateLabels } from '../api-call.factory';
import * as fromLabelResolvers from './territories.label-resolvers';
import * as fromResultCleaners from './territories.result-cleaners';

const territoriesSection = SectionsConfig.TERRITORIES.name;

export const getIncludedCountries = createApiCall(
  `${environment.territoriesTisnApiUrl}`,
  RequestType.POST,
  setQueryParams({ include: ATTRIBUTES }),
  setBody(`{"and":[{"equals":{"attributes.codes.tisa":"<<territoriesTisa>>"}}]}`),
  setLabelResolver(territoriesSection, fromLabelResolvers.getTerritoriesTisa),
  setResultCleaner(['includedCountries', fromResultCleaners.getListTerritoriesResultCleaner]),
  validateLabels,
);

export const getSuccessorsList = createApiCall(
  `${environment.territoriesTisnApiUrl}`,
  RequestType.POST,
  setQueryParams({ include: ATTRIBUTES }),
  setBody(`{"or":[<<pattern>>]}`),
  setLabelResolver(territoriesSection, fromLabelResolvers.getTerritoriesTisnPattern, fromLabelResolvers.getTerritoriesSuccessors),
  setResultCleaner(['successorsList', fromResultCleaners.getListTerritoriesResultCleaner]),
  validateLabels,
);

export const getPredecessorsList = createApiCall(
  `${environment.territoriesTisnApiUrl}`,
  RequestType.POST,
  setQueryParams({ include: ATTRIBUTES }),
  setBody(`{"or":[<<pattern>>]}`),
  setLabelResolver(territoriesSection, fromLabelResolvers.getTerritoriesTisnPattern, fromLabelResolvers.getTerritoriesPredecessors),
  setResultCleaner(['predecessorsList', fromResultCleaners.getListTerritoriesResultCleaner]),
  validateLabels,
);

export const getIncludedCountriesMembers = createApiCall(
  `${environment.territoriesTisnApiUrl}`,
  RequestType.POST,
  setQueryParams({ include: ATTRIBUTES }),
  setPageable(0, 100),
  setBody(`{"or":[<<pattern>>]}`),
  setLabelResolver(territoriesSection, fromLabelResolvers.getTerritoriesTisnPattern, fromLabelResolvers.getTerritoriesTisaMembers),
  setResultCleaner(['includedCountriesMembers', fromResultCleaners.getListTerritoriesResultCleaner]),
  isPageable,
);
