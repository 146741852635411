import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { IceMaterialModule } from '@ice/material.module';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { TranslateModule } from '@ngx-translate/core';
import { SelectTypeComponent } from './select-type.component';
import { FormlySelectOptionsPipe } from './select-type.pipe';

@NgModule({
  declarations: [SelectTypeComponent, FormlySelectOptionsPipe],
  imports: [TranslateModule, FuseSharedModule, IceMaterialModule, FormlyMaterialModule, FormlyModule.forRoot()],
  exports: [SelectTypeComponent],
})
export class SelectTypeModule {}
