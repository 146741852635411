import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FuseWidgetModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import {
  AutocompleteGroupedTypeComponent,
  AutocompleteTypeComponent,
  DatatableInputTypeComponent,
  DatatableSelectTypeComponent,
  DatatableTypeComponent,
  DatepickerTypeComponent,
  FormlyFieldButtonComponent,
  FormlyFieldLabelComponent,
  InputTypeComponent,
  IsValidValidator,
  RepeatTypeComponent,
  SelectWithInfoTypeComponent,
  SharesRepeatSectionTypeComponent,
  StepperComponent,
  WrapperInfoComponent,
} from '@ice';
import { DatepickerTypeModule } from '@ice/components/datepicker-type/datepicker-type.module';
import { FormContainerModule } from '@ice/components/form-container/form-container.module';
import { DatatableTypeModule } from '@ice/components/formly-types/datatable-type/datatable-type.module';
import { InputTypeModule } from '@ice/components/formly-types/input-type/input-type.module';
import { FormlyFieldLabelModule } from '@ice/components/formly-types/label-type/label-type.module';
import { SelectWithInfoTypeModule } from '@ice/components/formly-types/select-with-info-type/select-with-info-type.module';
import { WrapperInfoModule } from '@ice/components/formly-types/wrapper-info/wrapper-info.module';
import { EmailValidatorFormly } from '@ice/formly-validators/email-validator.formly';
import { IceMaterialModule } from '@ice/material.module';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AutocompleteGroupedTypeModule } from '../formly-types/autocomplete-grouped-type/autocomplete-grouped-type.module';
import { AutocompleteTypeModule } from '../formly-types/autocomplete-type/autocomplete-type.module';
import { FormlyFieldButtonModule } from '../formly-types/button-type/button-type.module';
import { CardWithDataTableTypeComponent } from '../formly-types/card-with-data-table-type/card-with-data-table-type.component';
import { CardWithDataTableTypeModule } from '../formly-types/card-with-data-table-type/card-with-data-table-type.module';
import { DatatableInputTypeModule } from '../formly-types/datatable-input/datatable-input-type.module';
import { DatatableSelectTypeModule } from '../formly-types/datatable-select/datatable-select-type.module';
import { HtmlTemplateTypeComponent } from '../formly-types/html-template-type/html-template-type.component';
import { HtmlTemplateTypeModule } from '../formly-types/html-template-type/html-template-type.module';
import { RepeatTypeModule } from '../formly-types/repeat-type/repeat-type.module';
import { SharesRepeatSectionTypeModule } from '../formly-types/shares-repeat-section-type/shares-repeat-section-type.module';
import { TreeChartTypeComponent } from '../formly-types/tree-chart-type/tree-chart-type.component';
import { TreeChartTypeModule } from '../formly-types/tree-chart-type/tree-chart-type.module';
import { UploadFileTypeComponent } from '../formly-types/upload-file-type/upload-file-type.component';
import { UploadFileTypeModule } from '../formly-types/upload-file-type/upload-file-type.module';
import { WrapperCardComponent } from '../formly-types/wrapper-card/wrapper-card.component';
import { WrapperCardModule } from '../formly-types/wrapper-card/wrapper-card.module';
import { WrapperNoIconComponent } from '../formly-types/wrapper-no-icon/wrapper-no-icon.component';
import { WrapperNoIconModule } from '../formly-types/wrapper-no-icon/wrapper-no-icon.module';

@NgModule({
  declarations: [StepperComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FuseSharedModule,
    FlexLayoutModule,
    FuseWidgetModule,
    IceMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FormContainerModule,
    InputTypeModule,
    SelectWithInfoTypeModule,
    FormlyMaterialModule,
    DatepickerTypeModule,
    WrapperInfoModule,
    WrapperNoIconModule,
    WrapperCardModule,
    NgxDatatableModule,
    DatatableTypeModule,
    FormlyFieldLabelModule,
    FormlyFieldButtonModule,
    FormlyFieldLabelModule,
    SharesRepeatSectionTypeModule,
    DatatableInputTypeModule,
    DatatableSelectTypeModule,
    RepeatTypeModule,
    AutocompleteTypeModule,
    AutocompleteGroupedTypeModule,
    UploadFileTypeModule,
    TreeChartTypeModule,
    HtmlTemplateTypeModule,
    CardWithDataTableTypeModule,
    FormlyModule.forRoot({
      extras: { checkExpressionOn: 'modelChange' },
      types: [
        { name: 'ice-input', component: InputTypeComponent },
        { name: 'select-with-info', component: SelectWithInfoTypeComponent },
        { name: 'ice-autocomplete', component: AutocompleteTypeComponent },
        { name: 'ice-autocomplete-grouped', component: AutocompleteGroupedTypeComponent },
        { name: 'label', component: FormlyFieldLabelComponent },
        { name: 'button', component: FormlyFieldButtonComponent },
        { name: 'datatable-input', component: DatatableInputTypeComponent },
        { name: 'datatable-select', component: DatatableSelectTypeComponent },
        {
          name: 'datepicker',
          component: DatepickerTypeComponent,
          wrappers: ['form-field'],
          defaultOptions: {
            defaultValue: '',
            templateOptions: {
              datepickerOptions: {},
            },
            validators: {
              valid: IsValidValidator,
            },
          },
        },
        {
          name: 'datatable',
          component: DatatableTypeComponent,
          defaultOptions: {
            templateOptions: {
              columnMode: 'force',
              rowHeight: 'auto',
              headerHeight: '40',
              footerHeight: '40',
              scrollbarH: 'true',
              reorderable: 'reorderable',
            },
          },
        },
        { name: 'label', component: FormlyFieldLabelComponent },
        { name: 'shares-repeat', component: SharesRepeatSectionTypeComponent },
        { name: 'repeat', component: RepeatTypeComponent },
        { name: 'upload-file', component: UploadFileTypeComponent },
        { name: 'tree-chart', component: TreeChartTypeComponent },
        { name: 'htmlTemplate', component: HtmlTemplateTypeComponent },
        { name: 'cardWithDataTable', component: CardWithDataTableTypeComponent },
      ],
      wrappers: [
        { name: 'wrapper-info', component: WrapperInfoComponent },
        { name: 'wrapper-card', component: WrapperCardComponent },
        { name: 'wrapper-no-icon', component: WrapperNoIconComponent },
      ],
      validators: [{ name: 'email', validation: EmailValidatorFormly.emailValidator }],
      validationMessages: [{ name: 'email', message: EmailValidatorFormly.emailValidatorMessage }],
    }),
  ],
  exports: [StepperComponent],
})
export class StepperModule {}
