import { SelectCodeItem } from '../../models/selectCodeItem';

export const langConversionItem: SelectCodeItem[] = [
  {
    label: 'Afar',
    value: 'AA',
  },
  {
    label: 'Abkhazian',
    value: 'AB',
  },
  {
    label: 'Afrikaans',
    value: 'AF',
  },
  {
    label: 'Amharic',
    value: 'AM',
  },
  {
    label: 'Arabic',
    value: 'AR',
  },
  {
    label: 'Assamese',
    value: 'AS',
  },
  {
    label: 'Aymara',
    value: 'AY',
  },
  {
    label: 'Azerbaijani',
    value: 'AZ',
  },
  {
    label: 'Bashkir',
    value: 'BA',
  },
  {
    label: 'Byelorussian (Belarusian)',
    value: 'BE',
  },
  {
    label: 'Bulgarian',
    value: 'BG',
  },
  {
    label: 'Bihari',
    value: 'BH',
  },
  {
    label: 'Bislama',
    value: 'BI',
  },
  {
    label: 'Bengali (Bangla)',
    value: 'BN',
  },
  {
    label: 'Tibetan',
    value: 'BO',
  },
  {
    label: 'Breton',
    value: 'BR',
  },
  {
    label: 'Catalan',
    value: 'CA',
  },
  {
    label: 'Corsican',
    value: 'CO',
  },
  {
    label: 'Czech',
    value: 'CS',
  },
  {
    label: 'Welsh',
    value: 'CY',
  },
  {
    label: 'Danish',
    value: 'DA',
  },
  {
    label: 'German',
    value: 'DE',
  },
  {
    label: 'Bhutani',
    value: 'DZ',
  },
  {
    label: 'Greek',
    value: 'EL',
  },
  {
    label: 'English',
    value: 'EN',
  },
  {
    label: 'Esperanto',
    value: 'EO',
  },
  {
    label: 'Spanish',
    value: 'ES',
  },
  {
    label: 'Estonian',
    value: 'ET',
  },
  {
    label: 'Basque',
    value: 'EU',
  },
  {
    label: 'Farsi',
    value: 'FA',
  },
  {
    label: 'Finnish',
    value: 'FI',
  },
  {
    label: 'Fiji',
    value: 'FJ',
  },
  {
    label: 'Faeroese',
    value: 'FO',
  },
  {
    label: 'French',
    value: 'FR',
  },
  {
    label: 'Frisian',
    value: 'FY',
  },
  {
    label: 'Irish',
    value: 'GA',
  },
  {
    label: 'Gaelic (Scottish)',
    value: 'GD',
  },
  {
    label: 'Galician',
    value: 'GL',
  },
  {
    label: 'Guarani',
    value: 'GN',
  },
  {
    label: 'Gujarati',
    value: 'GU',
  },
  {
    label: 'Gaelic (Manx)',
    value: 'GV',
  },
  {
    label: 'Hausa',
    value: 'HA',
  },
  {
    label: 'Hebrew',
    value: 'HE',
  },
  {
    label: 'Hindi',
    value: 'HI',
  },
  {
    label: 'Croatian',
    value: 'HR',
  },
  {
    label: 'Hungarian',
    value: 'HU',
  },
  {
    label: 'Armenian',
    value: 'HY',
  },
  {
    label: 'Interlingua',
    value: 'IA',
  },
  {
    label: 'Indonesian',
    value: 'ID',
  },
  {
    label: 'Interlingue',
    value: 'IE',
  },
  {
    label: 'Inupiak',
    value: 'IK',
  },
  {
    label: 'Indonesian',
    value: 'IN',
  },
  {
    label: 'Icelandic',
    value: 'IS',
  },
  {
    label: 'Italian',
    value: 'IT',
  },
  {
    label: 'Inuktitut',
    value: 'IU',
  },
  {
    label: 'Hebrew',
    value: 'IW',
  },
  {
    label: 'Japanese',
    value: 'JA',
  },
  {
    label: 'Yiddish',
    value: 'JI',
  },
  {
    label: 'Javanese',
    value: 'JV',
  },
  {
    label: 'Georgian',
    value: 'KA',
  },
  {
    label: 'Kazakh',
    value: 'KK',
  },
  {
    label: 'Greenlandic',
    value: 'KL',
  },
  {
    label: 'Cambodian',
    value: 'KM',
  },
  {
    label: 'Kannada',
    value: 'KN',
  },
  {
    label: 'Korean',
    value: 'KO',
  },
  {
    label: 'Kashmiri',
    value: 'KS',
  },
  {
    label: 'Kurdish',
    value: 'KU',
  },
  {
    label: 'Kirghiz',
    value: 'KY',
  },
  {
    label: 'Latin',
    value: 'LA',
  },
  {
    label: 'Limburgish ( Limburger)',
    value: 'LI',
  },
  {
    label: 'Lingala',
    value: 'LN',
  },
  {
    label: 'Laothian',
    value: 'LO',
  },
  {
    label: 'Lithuanian',
    value: 'LT',
  },
  {
    label: 'Latvian (Lettish)',
    value: 'LV',
  },
  {
    label: 'Malagasy',
    value: 'MG',
  },
  {
    label: 'Maori',
    value: 'MI',
  },
  {
    label: 'Macedonian',
    value: 'MK',
  },
  {
    label: 'Malayalam',
    value: 'ML',
  },
  {
    label: 'Mongolian',
    value: 'MN',
  },
  {
    label: 'Moldavian',
    value: 'MO',
  },
  {
    label: 'Marathi',
    value: 'MR',
  },
  {
    label: 'Malay',
    value: 'MS',
  },
  {
    label: 'Maltese',
    value: 'MT',
  },
  {
    label: 'Burmese',
    value: 'MY',
  },
  {
    label: 'Nauru',
    value: 'NA',
  },
  {
    label: 'Nepali',
    value: 'NE',
  },
  {
    label: 'Dutch',
    value: 'NL',
  },
  {
    label: 'Norwegian',
    value: 'NO',
  },
  {
    label: 'Occitan',
    value: 'OC',
  },
  {
    label: 'Oromo (Afan, Galla)',
    value: 'OM',
  },
  {
    label: 'Oriya',
    value: 'OR',
  },
  {
    label: 'Punjabi',
    value: 'PA',
  },
  {
    label: 'Polish',
    value: 'PL',
  },
  {
    label: 'Pashto (Pushto)',
    value: 'PS',
  },
  {
    label: 'Portuguese',
    value: 'PT',
  },
  {
    label: 'Quechua',
    value: 'QU',
  },
  {
    label: 'Rhaeto-Romance',
    value: 'RM',
  },
  {
    label: 'Kirundi (Rundi)',
    value: 'RN',
  },
  {
    label: 'Romanian',
    value: 'RO',
  },
  {
    label: 'Russian',
    value: 'RU',
  },
  {
    label: 'Kinyarwanda (Ruanda)',
    value: 'RW',
  },
  {
    label: 'Sanskrit',
    value: 'SA',
  },
  {
    label: 'Sindhi',
    value: 'SD',
  },
  {
    label: 'Sangro',
    value: 'SG',
  },
  {
    label: 'Serbo-Croatian',
    value: 'SH',
  },
  {
    label: 'Sinhalese',
    value: 'SI',
  },
  {
    label: 'Slovak',
    value: 'SK',
  },
  {
    label: 'Slovenian',
    value: 'SL',
  },
  {
    label: 'Samoan',
    value: 'SM',
  },
  {
    label: 'Shona',
    value: 'SN',
  },
  {
    label: 'Somali',
    value: 'SO',
  },
  {
    label: 'Albanian',
    value: 'SQ',
  },
  {
    label: 'Serbian',
    value: 'SR',
  },
  {
    label: 'Siswati',
    value: 'SS',
  },
  {
    label: 'Sesotho',
    value: 'ST',
  },
  {
    label: 'Sundanese',
    value: 'SU',
  },
  {
    label: 'Swedish',
    value: 'SV',
  },
  {
    label: 'Swahili (Kiswahili)',
    value: 'SW',
  },
  {
    label: 'Tamil',
    value: 'TA',
  },
  {
    label: 'Telugu',
    value: 'TE',
  },
  {
    label: 'Tajik',
    value: 'TG',
  },
  {
    label: 'Thai',
    value: 'TH',
  },
  {
    label: 'Tigrinya',
    value: 'TI',
  },
  {
    label: 'Turkmen',
    value: 'TK',
  },
  {
    label: 'Tagalog',
    value: 'TL',
  },
  {
    label: 'Setswana',
    value: 'TN',
  },
  {
    label: 'Tonga',
    value: 'TO',
  },
  {
    label: 'Turkish',
    value: 'TR',
  },
  {
    label: 'Tsonga',
    value: 'TS',
  },
  {
    label: 'Tatar',
    value: 'TT',
  },
  {
    label: 'Twi',
    value: 'TW',
  },
  {
    label: 'Uighur',
    value: 'UG',
  },
  {
    label: 'Ukrainian',
    value: 'UK',
  },
  {
    label: 'Urdu',
    value: 'UR',
  },
  {
    label: 'Uzbek',
    value: 'UZ',
  },
  {
    label: 'Vietnamese',
    value: 'VI',
  },
  {
    label: 'Volapük',
    value: 'VO',
  },
  {
    label: 'Wolof',
    value: 'WO',
  },
  {
    label: 'Xhosa',
    value: 'XH',
  },
  {
    label: 'Yiddish',
    value: 'YI',
  },
  {
    label: 'Yoruba',
    value: 'YO',
  },
  {
    label: 'Chinese',
    value: 'ZH',
  },
  {
    label: 'Zulu',
    value: 'ZU',
  },
  {
    label: 'Hawaii',
    value: 'HW',
  },
  {
    label: 'Javanese',
    value: 'JW',
  },
  {
    label: 'Papiamento',
    value: 'PM',
  },
];
