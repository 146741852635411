import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import * as fromApiCalls from 'config/api-calls';
import { CopyrightAgreementsDataTable } from 'config/data-table-builders/copyright.agreements';
import { SelectionDatatableBuilder } from 'config/data-table-builders/selection-datatable-builder';
import { SectionsConfig } from 'config/sections-config';
import { SectionTabConfig } from 'config/tabs-data-builders/tabs-data';
import * as fromRoot from 'store/root';

export class TabAllAgreements implements SectionTabConfig {
  private schema: CopyrightAgreementsDataTable;
  private selectionDatatable: SelectionDatatableBuilder;
  constructor(protected translate: TranslateService, protected fuseTranslationLoader: FuseTranslationLoaderService, protected store: Store<fromRoot.RootState>) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.schema = new CopyrightAgreementsDataTable(this.translate, this.fuseTranslationLoader, store);
    const onSingleSelection = (event: any[]) => {
      this.store.dispatch(
        new fromRoot.Go({
          path: [`copyright/${SectionsConfig.AGREEMENTS.name}/${event[0].id}`],
        }),
      );
    };
    this.selectionDatatable = new SelectionDatatableBuilder(
      store,
      this.schema.getDataTable(),
      onSingleSelection,
      fromApiCalls.getAgreementApplications,
      null,
      25,
      this.store.pipe(select(fromRoot.getWorkDetailAgreements)),
    );
  }

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'dataTable',
            config: {
              data: this.store.pipe(select(fromRoot.getWorkDetailAgreements)),
              sorts: [],
              ...this.selectionDatatable.getSelectionDatatableConfig(),
              loadingIndicator: false,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: true,
              shadowed: false,
              columnMode: 'flex',
              onMouseSelect: event => {
                this.store.dispatch(
                  new fromRoot.OpenNewTab({
                    path: [`copyright/${SectionsConfig.AGREEMENTS.name}/${event.id}`],
                  }),
                );
              },
              getRowClass: (row: any): any => ({ 'ice-search-results-table-row': true }),
            },
          },
        ],
      },
    ];
  }
}
