import * as fromNewSectionItemActions from 'store/new-section-item/actions';
import * as fromActions from 'store/root/actions';

export type showLoadingTypes =
  | fromActions.FetchSearchPage
  | fromActions.GetItem
  | fromActions.GetSharePicture
  | fromActions.FetchApiCall
  | fromActions.GetWorkToCompare
  | fromNewSectionItemActions.NewItemDoSearch
  | fromNewSectionItemActions.SaveNewSectionItem
  | fromActions.AssignSelectedConflictsToUser
  | fromActions.SubmitAuditHistoryFilter
  | fromNewSectionItemActions.DeleteClaim
  | fromNewSectionItemActions.TerminateClaim
  | fromActions.WorkResolution
  | fromActions.SendRejectDocuments
  | fromNewSectionItemActions.SaveDocument;
export const isLoadingActions = [
  fromActions.FETCH_SEARCH_PAGE,
  fromActions.GET_ITEM,
  fromActions.GET_SHARE_PICTURE,
  fromActions.GET_WORK_TO_COMPARE,
  fromActions.IPS_RELATIONS_DO_SEARCH,
  fromNewSectionItemActions.NEW_ITEM_DO_SEARCH,
  fromNewSectionItemActions.SAVE_NEW_SECTION_ITEM,
  fromActions.ASSIGN_SELECTED_CONFLICTS_TO_USER,
  fromActions.SUBMIT_AUDIT_HISTORY_FILTER,
  fromNewSectionItemActions.DELETE_CLAIM,
  fromNewSectionItemActions.TERMINATE_CLAIM,
  fromActions.WORK_RESOLUTION,
  fromActions.SEND_REJECT_DOCUMENTS,
  fromNewSectionItemActions.SAVE_DOCUMENT,
  fromActions.FETCH_API_CALL,
];
