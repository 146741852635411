import { RepertoireUtils, WorkUtils } from '@ice';
import { concat, get, reverse, sortBy } from 'lodash';
import { SearchActivityParser } from 'models/search-parsers/search-activity-parser';
import { SearchAgreementsParser } from 'models/search-parsers/search-agreements-parser';
import { createResultCleaner } from '../api-call.factory';

export const getAgreementApplicationsResultCleaner = createResultCleaner(
  (value, oldDetail, cleanerData, translate) => value && (value.items || []).map(item => SearchAgreementsParser.parseItem(item.agreement, translate)),
);

export const getWorkConflictsResultCleaner = createResultCleaner(
  (value, oldDetail, cleanerData, translate) =>
    (
      value &&
      SearchActivityParser.workConflictParse(
        value.conflicts.map(conflict => conflict.conflict),
        translate,
      )
    ).map(conflict => ({
      ...conflict,
      detail: reverse(
        sortBy(
          (value?.activityTriggers || []).map(activityTrigger => activityTrigger.attributes),
          ['value'],
        ),
      ),
    })) || [],
);

export const getWorkRepertoiresResultCleaner = createResultCleaner(
  (value, oldDetail, cleanerData, translate) => (value && value.items && RepertoireUtils.repertoireSearchCleaner(value.items)) || [],
);

export const getSourceWorksResultCleaner = createResultCleaner((value, oldDetail, cleanerData, translate) => {
  const oldWorks = get(oldDetail, `sourceWorks`, []);
  const works = value && value.items ? WorkUtils.workSearchCleaner(value.items, '', false, null, translate, oldDetail) : [];
  const worksIds = works.map(work => work.id);
  const items = oldWorks && oldWorks.items && oldWorks.items.filter(oldWork => !worksIds.includes(oldWork.id));
  return concat(items || [], works);
});

export const getWorkInternalRelationsResultCleaner = createResultCleaner((value, oldDetail, cleanerData, translate) =>
  value && value.items ? WorkUtils.buildInternalWorkItemsFromRelations(value.items, oldDetail, translate) : [],
);

export const getWorkExternalRelationsResultCleaner = createResultCleaner((value, oldDetail, cleanerData, translate) =>
  value && value.items ? WorkUtils.workSearchCleaner(value.items, '', true, null, translate, oldDetail) : [],
);

export const getCounterclaimsAttributesCleaner = createResultCleaner((value, oldDetail, cleanerData, translate) => get(value, 'counterclaims', []));
