import { CounterClaimUtils } from '@ice/utils/counter-claim/counter-claim.utils';
import { CounterClaimDashboardCountersConfig } from 'config/constants/counter-claims.constants';
import { CUBE, DEFAULT_EXPORT_RETRY_TIMES } from 'config/constants/global.constants';
import { SectionsConfig } from 'config/sections-config';
import { ApiCall, ApiCallConfig, ApiCallPayload, RequestType, ResultCallBack, SequenceType } from 'config/sections-config/api-call';
import { SearchUtilsFactory } from 'store/root/utils/search/search-utils.factory';
import { createApiCall, createApiCallFromBase, isCounterApiCall, setBody, setInclude, setLabelResolver, setPageable, setResultCleaner } from '../api-call.factory';
import * as fromLabelResolvers from './common.label-resolvers';
import * as fromResultCleaners from './common.result-cleaners';

export const getApiCallAutoPagination = (
  apiCall: ApiCall,
  from: number,
  total: number,
  apiCallProperties?: Partial<ApiCall>,
  onFinish?: ResultCallBack,
  onError?: ResultCallBack,
  size: number = 100,
  maxRepeat: number = 100,
): ApiCallConfig[] => {
  const apiCallRepeats = total <= from ? 0 : Math.min(Math.ceil((total - from) / size), maxRepeat);
  const modTotal = (total - from) % size;
  const response = (total && [...Array(apiCallRepeats).keys()]) || [];
  return response.map((item, index) => {
    return {
      apiCall: createApiCallFromBase(apiCall, apiCallProperties, setPageable(from + index * size, (index === apiCallRepeats - 1 && modTotal) || size)),
      onCancel: onFinish,
      ...((index === apiCallRepeats - 1 && { callBack: onFinish }) || { callBack: onError }),
      fromScrollEvent: true,
      retry: DEFAULT_EXPORT_RETRY_TIMES,
      retryDelay: 5000,
      stopOnError: true,
      stopAllPendingCallsOnCancel: true,
    };
  });
};

export const getCounterClaimsDashboardCounters: ApiCallPayload = [
  ...CounterClaimDashboardCountersConfig.map(counterConfig => {
    const { storeCounterType, apiCounterType, baseQuery } = counterConfig;
    return createApiCall(
      SearchUtilsFactory.getSearchUrl(SectionsConfig.COUNTERCLAIMS_ACTIONS, CUBE),
      RequestType.POST,
      setInclude(`{id}`),
      isCounterApiCall,
      setLabelResolver(SectionsConfig.COUNTERCLAIMS.name, fromLabelResolvers.getDashboardPatternLabelResolver),
      setBody(baseQuery.replace('<<typeFilter>>', CounterClaimUtils.getDashboardTypeFilter(apiCounterType))),
      setResultCleaner([`dashboard.counters.${storeCounterType}`, fromResultCleaners.getCounterResultCleaner]),
    );
  }),
  SequenceType.Merge,
];
