import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from 'services/auth/auth.service';
import * as fromRoot from 'store/root';

@Injectable()
export class LoggedGuard implements CanActivate {
  state: RouterStateSnapshot;

  constructor(private authService: AuthService, private router: Router, private store: Store<fromRoot.AuthState>) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.state = state;

    return this.store.pipe(
      select(fromRoot.getNewPasswordInfo),
      switchMap(newPasswordInfo => {
        if (window.location.pathname === '/new-password' && !newPasswordInfo) {
          this.router.navigate(['/login']);
          return of(false);
        }
        return this.authService.getCurrentUserInfo().pipe(
          map(() => {
            this.router.navigate(['/app/works']);
            return false;
          }),
          catchError(err => of(true)),
        );
      }),
    );
  }
}
