import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import * as fromApiCalls from 'config/api-calls';
import { CopyrightAgreementsDataTable } from 'config/data-table-builders/copyright.agreements';
import { SelectionDatatableBuilder } from 'config/data-table-builders/selection-datatable-builder';
import { DialogAddById } from 'config/dialog-builders/dialog-add-by-id';
import { SectionsConfig } from 'config/sections-config';
import { SectionTabConfig } from 'config/tabs-data-builders/tabs-data';
import { of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PermissionsService } from 'services/permissions/permissions.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';
import { RepertoireEditInline } from './shared/edit';

export class TabRepertoireExcludedAgreements implements SectionTabConfig {
  private schema: CopyrightAgreementsDataTable;
  private selectionDatatable: SelectionDatatableBuilder;
  private dialogAddById: DialogAddById;
  private dialogRef: MatDialogRef<any, any>;
  private unsubscribeAll = new Subject();
  private currentIds = [];
  constructor(
    protected translate: TranslateService,
    protected fuseTranslationLoader: FuseTranslationLoaderService,
    protected store: Store<fromRoot.RootState>,
    protected dialog: MatDialog,
    private commonApiService: null,
    private detailService: null,
    private nsService: null,
    private iceFacade: null,
    private fieldValidatorService: FieldValidatorService,
    protected permissionsService: PermissionsService,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.schema = new CopyrightAgreementsDataTable(this.translate, this.fuseTranslationLoader, store);
    const onSelect = (event: any[]) => {
      this.store.dispatch(
        new fromRoot.Go({
          path: [`copyright/${SectionsConfig.AGREEMENTS.name}/${event[0].key}`],
        }),
      );
    };
    this.selectionDatatable = new SelectionDatatableBuilder(
      store,
      [
        ...this.schema.getDataTable(),
        ...this.schema.getRepertoireAgreementsField(
          this.dialog,
          this.store,
          this.translate.instant('REPERTOIRES.EXCLUDED_AGREEMENTS.TAB_TITLE'),
          this.translate.instant('REPERTOIRES.EXCLUDED_AGREEMENTS.DELETE_MESSAGE'),
          (row, dialogRef) =>
            RepertoireEditInline.updateRepertoire(this.store, dialogRef, this.translate, row.key, 'excludedAgreements', this.unsubscribeAll, 'delete', this.currentIds),
          !this.permissionsService.can(`repertoires_edit`),
        ),
      ],
      onSelect,
      fromApiCalls.getExcludedAgreements,
    );
    this.dialogAddById = new DialogAddById(this.translate, this.dialog, this.store, this.fieldValidatorService, SectionsConfig.AGREEMENTS.name);
    this.unsubscribeAll = new Subject();
  }

  onDestroy = () => {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  };

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('REPERTOIRES.EXCLUDED_AGREEMENTS.TAB_TITLE'),
              model: this.store.pipe(
                select(fromRoot.getRepertoireExcludedAgreements),
                tap(agreements => (this.currentIds = agreements?.map(agreement => agreement.id))),
              ),
              sorts: [],
              ...this.selectionDatatable.getSelectionDatatableConfig(),
              loadingIndicator: false,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: true,
              shadowed: false,
              columnMode: 'flex',
              onMouseSelect: event => {
                this.store.dispatch(
                  new fromRoot.OpenNewTab({
                    path: [`copyright/${SectionsConfig.AGREEMENTS.name}/${event.key}`],
                  }),
                );
              },
              getRowClass: (row: any): any => ({ 'ice-search-results-table-row': true }),
              actionButtons: of(
                this.permissionsService.can(`repertoires_edit`)
                  ? [
                      {
                        icon: 'add',
                        tooltip: `${this.translate.instant('REPERTOIRES.COMMON.ADD')} ${this.translate.instant('REPERTOIRES.EXCLUDED_AGREEMENTS.TAB_TITLE')}`,
                        class: 'mat-white-icon',
                        onClick: () =>
                          (this.dialogRef = this.dialogAddById.openDialog(agreementId =>
                            RepertoireEditInline.updateRepertoire(
                              this.store,
                              this.dialogRef,
                              this.translate,
                              agreementId,
                              'excludedAgreements',
                              this.unsubscribeAll,
                              'add',
                              this.currentIds,
                            ),
                          )),
                      },
                    ]
                  : [],
              ),
            },
          },
        ],
      },
    ];
  }
}
