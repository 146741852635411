import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { StringUtils } from '@ice';
import { DataTableRow, SortInfo } from '@ice/components/data-table/data-table';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import { RootDatatable } from 'config/data-table-builders/root-datatable';
import { DialogInfo } from 'config/dialog-builders/dialog-info';
import { of } from 'rxjs';
import { DataTableBuilder } from './data-table-builder';

export class CopyrightWorksDataTable extends RootDatatable implements DataTableBuilder {
  constructor(protected translate: TranslateService, protected fuseTranslationLoader: FuseTranslationLoaderService) {
    super(translate);
    this.fuseTranslationLoader.loadTranslations(english);
  }

  getDefaultSorting(): SortInfo[] {
    return [
      {
        prop: 'title',
        dir: 'asc',
      },
    ];
  }

  formatSort(sort: SortInfo) {
    switch (sort.prop) {
      case 'key':
        return { prop: 'id', dir: sort.dir };
      case 'namesToString':
      case 'roleCodesToString':
      case 'IPINumbersToString':
      case 'iconCustomTooltipHtmlText':
      case 'errorMessageField':
      case 'migratedFrom':
      case 'activity':
      case 'counterclaim':
        return null;
      case 'title':
        return { prop: 'attributes.titles.OT.titleValue', dir: sort.dir };
      case 'wsm':
        return { prop: 'attributes.societyMarkers', dir: sort.dir };
      case 'keyWithoutPrefix':
        return { prop: 'id', dir: sort.dir };
      case 'durationFormatted':
        return { prop: 'attributes.duration', dir: sort.dir };
      case 'counterclaims':
        return { prop: 'counterclaimsCount', dir: sort.dir };
      case 'iconConflict':
        return { prop: 'conflictsCount', dir: sort.dir };
      case 'activityTriggersCount':
        return { prop: 'activityTriggersCount', dir: sort.dir };
    }
    return { prop: `attributes.${sort.prop}`, dir: sort.dir };
  }

  getDataTable(clientSide?, store?, clientSortSide = false): DataTableRow[] {
    return [...this.getDataTableShareFields(clientSide, clientSortSide), ...this.getCounterClaimsField()];
  }

  getDataTableShareFields(clientSide?, clientSortSide = false): DataTableRow[] {
    return [
      {
        name: this.translate.instant('WORKS.TITLE'),
        prop: 'title',
        flexGrow: 3,
        sortable: !clientSide,
        cellClass: ' ice-bold-cell',
        badge: { text: 'titlesTotal', tooltip: 'tooltipTitles', backgroundColor: '#0A9BE5', textColor: '#fff' },
        ...(clientSortSide && { comparator: () => this.serverSideSorting() }),
      },
      {
        name: this.translate.instant('WORKS.WORK_KEY'),
        prop: 'keyWithoutPrefix',
        flexGrow: 2,
        cellClass: 'work-key',
        sortable: !clientSortSide ? !clientSide : true,
        ...(clientSortSide && { comparator: () => this.serverSideSorting() }),
      },
      {
        name: this.translate.instant('WORKS.ROLE_CODES'),
        prop: 'roleCodesToString',
        flexGrow: 1.2,
        cellClass: 'ice-search-results-table-div-names-field work-role-codes',
        tooltip: 'roleCodesToStringTooltip',
        ...(clientSortSide && {
          comparator: (itemA, itemB) => this.arrayComparator(StringUtils.getArrayFromStringList(itemA), StringUtils.getArrayFromStringList(itemB), this.generalComparator),
        }),
      },
      {
        name: this.translate.instant('WORKS.PARTY_NAMES'),
        prop: 'namesToString',
        flexGrow: 3,
        cellClass: 'ice-search-results-table-div-names-field work-party-names',
        tooltip: 'tooltipCreators',
        comparator: (itemA, itemB) => this.arrayComparator(StringUtils.getArrayFromStringList(itemA), StringUtils.getArrayFromStringList(itemB), this.generalComparator),
      },
      {
        name: this.translate.instant('WORKS.IPI_NUMBER'),
        prop: 'IPINumbersToString',
        flexGrow: 2,
        cellClass: 'ice-search-results-table-div-names-field work-ipi-number',
        comparator: (itemA, itemB) => this.arrayComparator(StringUtils.getArrayFromStringList(itemA), StringUtils.getArrayFromStringList(itemB), this.numericalComparator),
      },
      {
        name: this.translate.instant('WORKS.WORK_TYPE'),
        prop: 'origin',
        flexGrow: 0.9,
        tooltip: 'originTooltip',
        cellClass: 'ice-search-results-table-div-names-field',
      },
      {
        name: this.translate.instant('WORKS.SOURCE'),
        sortable: !clientSide,
        prop: 'source',
        flexGrow: 1,
        cellClass: 'work-source',
        ...(clientSortSide && { comparator: () => this.serverSideSorting() }),
      },
      {
        name: this.translate.instant('WORKS.STATUS'),
        sortable: !clientSide,
        prop: 'status',
        flexGrow: 1,
        cellClass: 'work-status',
        ...(clientSortSide && { comparator: () => this.serverSideSorting() }),
      },
      {
        name: this.translate.instant('WORKS.DURATION'),
        sortable: !clientSide,
        prop: 'durationFormatted',
        flexGrow: 0.9,
        cellClass: 'work-duration',
        ...(clientSortSide && { comparator: () => this.serverSideSorting() }),
      },
      {
        name: this.translate.instant('WORKS.PURPOSE'),
        prop: 'purpose',
        flexGrow: 1.1,
        tooltip: 'purposeTooltip',
        cellClass: 'ice-search-results-table-div-names-field',
      },
      {
        name: this.translate.instant('WORKS.WSM'),
        prop: 'wsm',
        exportProp: 'exportWSM',
        tooltip: 'tooltipWsm',
        flexGrow: 1,
        cellClass: 'ice-search-results-table-div-names-field',
        headerTooltip: this.translate.instant('WORKS.WSM_TOOLTIP'),
      },
      {
        name: this.translate.instant('WORKS.ACTIVITY'),
        prop: 'activityTriggersCount',
        hideTextProperty: true,
        icons: 'activityIcon',
        flexGrow: 1,
        cellClass: 'ice-search-results-table-div-names-field',
      },
    ];
  }

  getRepertoireWorksField(dialog, store, tabTitle, deleteInfo, onDelete: (row, dialogRef) => void, canEdit = false) {
    return [
      {
        name: '',
        prop: 'delete',
        actionButtonIcon: 'delete',
        flexGrow: 0.001,
        maxWidth: 50,
        minWidth: 50,
        resizeable: false,
        hideActionButton: row => of(canEdit),
        action: row => {
          const dialogInfo = new DialogInfo(this.translate, dialog, store);
          const dialogRef = dialogInfo.openDialog(
            `${this.translate.instant('REPERTOIRES.COMMON.DELETE')} ${tabTitle}`,
            deleteInfo,
            () => onDelete(row, dialogRef),
            () => dialogRef.close(),
          );
        },
        actionButtonTooltip: `${this.translate.instant('REPERTOIRES.COMMON.DELETE')} ${tabTitle}`,
      },
    ];
  }

  getCounterClaimsField(): DataTableRow[] {
    return [
      {
        name: this.translate.instant('WORKS.COUNTERCLAIMS'),
        prop: 'counterclaims',
        flexGrow: 1,
        cellClass: 'ice-search-results-table-div-names-field',
        headerTooltip: this.translate.instant('WORKS.COUNTERCLAIMS_HEADER_TOOLTIP'),
        tooltip: this.translate.instant('WORKS.COUNTERCLAIMS_TOOLTIP'),
      },
      {
        name: this.translate.instant('WORKS.CONFLICT'),
        prop: 'iconConflict',
        exportProp: 'conflict',
        tooltip: 'iconConflictTooltip',
        flexGrow: 1,
        cellClass: 'ice-search-results-table-div-names-field',
      },
    ];
  }

  getColumnMode(): ColumnMode {
    return ColumnMode.flex;
  }

  displayCheck(row: any, firstSelectedItem: any, targetItem: any, column?: any, value?: any): boolean {
    return true;
  }
}
