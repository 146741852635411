<ice-tree-chart
  fxFlex
  [_data]="treeData"
  [verticalLevels]="levels?.vLevel"
  [horizontalLevels]="levels?.maxHLevel"
  [addAdvancedInfoLabels]="true"
  [expanseCollapseIcons]="true"
  [expanseDisabledRootContextMenu]="to.expanseDisabledRootContextMenu"
  [rootNodeDefaultName]="to.rootNodeDefaultName"
  [disableExpanseCollapseMode]="to.disableExpanseCollapseMode"
></ice-tree-chart>
