import { NavigationExtras } from '@angular/router';
import { ActivitySearchItemCleaned } from '@ice';
import { Action } from '@ngrx/store';
import { CounterClaimSearchItem } from 'models/copyright/search/conflicts-counterclaim-search';

export const GO = '[Router] Go';
export const OPEN_NEW_TAB = '[Router] Go To New tab';
export const BACK = '[Router] Back';
export const FORWARD = '[Router] Forward';
export const GO_TO_SEARCH_RESULTS = '[Router] Return to Search Results';
export const GO_TO_COPYRIGHT_HOME = '[Router] Go to Copyright Home';
export const GO_TO_CONFLICT = '[Router] Go To Conflict';
export const GO_TO_COUNTERCLAIM = '[Copyright] Go To Counterclaim';
export const SAVE_XREF_AND_NAVIGATE = '[Router] Save Xref And Navigate';

export class Go implements Action {
  readonly type = GO;

  constructor(
    public payload: {
      path: any[];
      query?: object;
      extras?: NavigationExtras;
      forceReload?: boolean;
    },
  ) {}
}

export class OpenNewTab implements Action {
  readonly type = OPEN_NEW_TAB;

  constructor(
    public payload: {
      url?: string;
      path?: any[];
      id?: string;
    },
  ) {}
}

export class Forward implements Action {
  readonly type = FORWARD;
}

export class Back implements Action {
  readonly type = BACK;
}

export class GoToSearchResults implements Action {
  readonly type = GO_TO_SEARCH_RESULTS;
}

export class GoToCopyrightHome implements Action {
  readonly type = GO_TO_COPYRIGHT_HOME;
}

export class GoToConflict implements Action {
  readonly type = GO_TO_CONFLICT;

  constructor(public payload: { conflict: ActivitySearchItemCleaned; openInNewTab?: boolean }) {}
}

export class GoToCounterclaim implements Action {
  readonly type = GO_TO_COUNTERCLAIM;

  constructor(public payload: { counterclaim: CounterClaimSearchItem; openInNewTab?: boolean }) {}
}

export class SaveXrefAndNavigate implements Action {
  readonly type = SAVE_XREF_AND_NAVIGATE;

  constructor(public payload: { xref: any[] }) {}
}

export type Actions = Go | Back | Forward | GoToSearchResults | GoToCopyrightHome | GoToConflict | GoToCounterclaim | SaveXrefAndNavigate;
