import { FooterComponent } from 'app/root/components/footer/footer.component';

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { IceMaterialModule } from '@ice/material.module';

@NgModule({
  declarations: [FooterComponent],
  imports: [RouterModule, IceMaterialModule, FuseSharedModule],
  exports: [FooterComponent],
})
export class FooterModule {}
