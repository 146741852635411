import { OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Injectable } from '@angular/core';
import { CustomToolTipComponent } from '@ice/components/custom-tooltip/ice-custom-tooltip.component';
import { toNumber } from 'lodash';
/**
 * service to make DatatableComponent aware of changes to
 * input bindings of DataTableColumnDirective
 */
@Injectable()
export class ToolbarService {
  public _overlayRef: OverlayRef;
  public timeout: any;
  public componentInstance: any;

  setToolbar(_overlay, _overlayPositionBuilder, _elementRef, iceTooltipHtmlText, duration, offsetY) {
    if (!this._overlayRef) {
      const positionStrategy = _overlayPositionBuilder.flexibleConnectedTo(_elementRef).withPositions([
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          offsetY: toNumber(offsetY),
        },
      ]);
      this._overlayRef = _overlay.create({ positionStrategy });
    }
    if (!this._overlayRef.hasAttached()) {
      const tooltipRef: ComponentRef<CustomToolTipComponent> = this._overlayRef.attach(new ComponentPortal(CustomToolTipComponent));
      this.componentInstance = tooltipRef;
      this.componentInstance.instance.text = iceTooltipHtmlText;
      this.componentInstance.instance.onMouseLeave = () => {
        this.clearTimeout();
        this.setTimeout(duration);
      };
      this.componentInstance.instance.onMouseEnter = () => this.clearTimeout();
    }
  }

  clearTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  setTimeout(duration) {
    this.timeout = setTimeout(() => {
      this.closeToolTip();
    }, duration);
  }

  destroy() {
    this.clearTimeout();
    this.closeToolTip();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this._overlayRef = null!;
  }

  closeToolTip() {
    if (this._overlayRef) {
      this._overlayRef.detach();
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.componentInstance = null!;
    }
  }
}
