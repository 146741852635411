import { SectionsConfig } from 'config/sections-config';

export const TAB_WORKS_SOCIETY_ATTRIBUTES = 'society-attributes';

export const URL_CONFLICTS = {
  agreements: `${SectionsConfig.AGREEMENTCONFLICT.domainName}/${SectionsConfig.AGREEMENTCONFLICT.name}/new`,
  works: `${SectionsConfig.COUNTERCLAIMS.domainName}/${SectionsConfig.COUNTERCLAIMS.name}/new`,
  support: `${SectionsConfig.COUNTERCLAIMS.domainName}/${SectionsConfig.COUNTERCLAIMSSUPPORT.name}/new`,
};

export const ICON_NAMES = {
  ADD_ITEM_CIRCLE: 'add_circle',
};

export const TAB_NAME_RELATIONS = {
  RETENTION_WORKS: 'retention-works',
};
