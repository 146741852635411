export const locale = {
  lang: 'en',
  data: {
    DELETE: {
      TITLE: 'Delete',
      MESSAGE: 'Are you sure you want to delete?',
      OK: 'Delete',
      KO: 'Cancel',
      MSG_ORGANIZATIONS: 'Are you sure you want to proceed with organization deletion (all associated user accounts will be deleted)',
      MSG_USERS: 'Are you sure you want to proceed with user deletion',
    },
    ACTIVITY: {
      DETAILS: {
        TAB_TITLE: 'Case detail',
        CARD_WITH_FORM: {
          CARD_TITLE: 'Case',
          FORM: {
            CLOSED: 'Closed',
            UPDATED: 'Updated',
            NAME: 'Name',
            STATUS_ID: 'Status ID',
            STATUS: 'Status',
            TYPE_ID: 'Type ID',
            TYPE: 'Type',
            DESCRIPTION: 'Description',
            ANALYSE: 'Analyse',
            VIEW_LINK: 'Open in Trello',
            CONFLICT_ID: 'Conflict ID',
            CONFLICT_SOCIETY: 'Conflict Society',
            CONFLICT_DATA: 'Conflict Data',
            CONFLICT_TYPE: 'Conflict Type',
            DUE_DATE: 'Due Date',
            WORK_ID: 'Work ID',
            CONFLICT_SUBTYPE: 'Conflict Subtype',
            CASE_STATUS: 'Case Status',
            ASSIGNEE: 'Assignee',
          },
        },
      },
      ANALYSIS: {
        TAB_TITLE: 'Analysis',
        REVISION: {
          CURRENT: 'Mastered Work',
          COMPARE: 'COMPARE TO',
          MASTERED: 'MASTERED',
          ISWC: 'ISWC',
          STATUS: 'STATUS',
          SUBMIT: 'Apply Filter',
          UNIQUE_CLAIMS_TOGGLE: 'Unique Claims',
          LIST_TITLES: 'All titles',
          CONTRIBUTORS_COL_IP_NUM: 'IP number',
          CONTRIBUTORS_COL_ROLE: 'Role',
          CONTRIBUTORS_COL_NAME: 'Name',
          LIST_MANUSCRIPT: 'Ownership Manuscript',
          LIST_CLAIMS: 'Claim Graph Shares',
          SUBMITTED_CLAIMS: 'Submitted Claims',
          POTENTIAL_CONFLICT: 'Potential Conflict',
          POSSIBLE_MATCH: 'possible match',
          POSSIBLE_MATCHES: 'possible matches',
          CONFIRMED_MATCH: 'confirmed match',
          CONFIRMED_MATCHES: 'confirmed matches',
          OTHER_PARTY: 'Other Party',
          SELECT: 'Select',
        },
        OPEN_WORK: 'Open Work',
      },
      ANALYSIS_MERGE: {
        TAB_TITLE: 'Analysis',
        CLAIMS_HEADER: {
          CLAIM_VIEW: 'Claim view:',
          COLLECTION: 'Collection',
          OWNERSHIP: 'Ownership',
          SHOW_CLAIMS_IN_FULLSCREEN: 'Show claims in fullscreen',
          CLAIMS_COMPARISON: 'Claims comparison',
          CLOSE_BUTTON: 'Close',
        },
        FRONTEND_HIGHLIGHT: 'Frontend Highlight',
        BACKEND_HIGHLIGHT: 'Backend Highlight',
      },
      MATCH_POPUP: {
        TIMEOUT_ERROR: 'We have received a timeout error',
        MASTERING_ERROR: 'An error has occurred during the mastering process',
        CANCEL: 'Cancel',
        CLOSE: 'Close',
      },
      MERGE: {
        TITLE: 'Forced merge',
        MESSAGE: 'Are you sure you want to Force Merge the 2 displayed works?',
        MASTERING_ERROR: 'An error has occurred during the mastering process',
        CHECK: 'Check',
        NO: 'No',
        CLOSE: 'Close',
        MERGE_IGNORE_CLAIMS: 'You are going to ignore the following claims in the force merge:',
        MERGE_IGNORE_CLAIMS_COLLECTIONS: 'Collections Claims',
        MERGE_IGNORE_CLAIMS_OWNERSHIPS: 'Ownerships Claims',
        MERGE_IGNORE_CLAIMS_TITLE: 'For Work: {{iceWorkKey}} - {{workTitle}}',
      },
      NO_MATCH: {
        TITLE: 'Add non-match work relation',
        COMPARE_TO: 'Compare To',
        MASTERED_WORK: 'Mastered Work',
        MASTERING_ERROR: 'An error has occurred during the mastering process',
        CONFIRM: 'Confirm',
        CANCEL: 'Cancel',
        WORK: 'Work',
        MASTERED_TO: 'Mastered To',
        COMPARED_TO: 'Compared To',
        OPTIONS: {
          LABEL: 'Work relation type',
          SAMPLES: 'SAMPLES',
          MODIFIES: 'IS A MODIFICATION OF',
          EXCERPTS: 'IS AN EXCERPT OF',
          IS_SAMPLED_BY: 'IS SAMPLED BY',
          IS_MODIFIED_BY: 'IS MODIFIED BY',
          IS_EXCERPTS_BY: 'CONTAINS THE EXCERPT',
        },
      },
    },
    COUNTER_CLAIMS: {
      DETAILS: {
        TAB_TITLE: 'Counterclaim details',
      },
      OTHER_COUNTER_CLAIMS: {
        TAB_TITLE: 'Other Counterclaims',
      },
      DOCUMENTS: {
        TAB_TITLE: 'Documents',
        DATATABLE: {
          DATETIME: 'Date/Time',
          DOCUMENT_ID: 'Document ID',
          FILE_NAME: 'File Name',
          USER: 'User',
          OWNER_NAME: 'Owner Name',
          OWNER_IPI_NAME_NUMBER: 'Owner IPI Name Number',
          CLAIMANT_NAME: 'Claimant Name',
          CLAIMANT_IPI_NAME_NUMBER: 'Claimant IPI Name Number',
          STATUS: 'Status',
          SHARED: 'Shared',
        },
        ERRORS: {
          NOT_FOUND: "The document doesn't exist",
        },
        REJECT_POPUP: {
          TITLE: 'Please select the rejection reason',
          REASONS: {
            NO_REASON_GIVEN: 'No Reason Given',
            FULL_CHAIN_OF_TITLE_NOT_COVERED: 'Full chain of title not covered',
            POINT_OF_CONFLICT_NOT_COVERED: 'Point of conflict not covered',
            NOT_SIGNED: 'Not signed',
          },
          REJECTION_RESULT: 'Rejection Process Result',
          REJECTION_ERROR: 'There was an error during the Rejection Process, the documents was not rejected',
        },
      },
      ACTIONS: {
        TAB_TITLE: 'Actions',
      },
      XREF: {
        TAB_TITLE: 'Titles / Xrefs',
        CARD_TITLE: 'Additional Titles And Xrefs',
      },
    },
    COUNTER: {
      TITLE: 'Details',
      COUNTERCLAIM_PARTIES: 'Counterclaim parties',
      COUNTERCLAIM_PARTIES_AND_CLAIMS: 'Counterclaim Parties, Actions and Claims',
      ASSOCIATED_CLAIMS: 'Associated claims',
      SELECTED_PARTIES: 'Select parties to withdraw',
      FIELD_STATUS: 'Status',
      FIELD_TYPE: 'Type',
      FIELD_CREATION_DATE: 'Creation Date',
      FIELD_POINT: 'Point of Conflict',
      FIELD_OWNER: 'Resolution Owner(s)',
      FIELD_TERRITORIES: 'Territory(s)',
      FIRST: 'First claimant',
      NEW: 'New claimant',
      PARTY_REMOVED: 'Party Removed',
      RESOLVER: 'Resolver',
      PENDING: 'Pending',
      SUPPORTED: 'Supported',
      WITHDRAW: 'Withdraw',
      REDUCED: 'Reduced',
      RESOLVED: 'Resolved',
      COUNTERCLAIM_RESOLVED: 'Counterclaim resolved',
      REQUESTED_TO_SUPPORT_CLAIM: 'Requested to support claim',
      REDUCED_AND_SUPPORTED: 'Reduced and supported',
      SUPPORTED_AND_DOCUMENT_REJECTED: 'Supported and document rejected',
      CLAIMS_REDUCED: 'Claims reduced',
      CLAIMS_WITHDRAWN: 'Claims withdrawn',
      CLAIMS_REMOVED: 'Claims Removed',
      NOT_SUPPORTED: 'Not supported',
      ALL_STATUSES: 'All statuses',
      OPEN: 'Open',
      CLOSED: 'Closed',
      PAUSED: 'Paused',
      EXPIRED: 'Expired',
      MEMBERS_TO_RESOLVE: 'Members to resolve',
      REQUEST_TO_SUPPORT_CLAIM: 'Request to support claim',
      REQUEST_TO_SUPPORT_AGREEMENT: 'Request to support agreement',
      AWAITING_REPLY: 'Awaiting Reply',
      CONFIRMED: 'Confirmed',
      EARLY_WARNING: 'Early Warning',
      MEMBER_RESOLVE: 'Member resolve',
      WITHDRAWN: 'Withdrawn',
      DOC_REJECTED: 'Document rejected',
      WITHDRAW_CLAIM: 'Withdraw claim',
      REDUCE_CLAIM: 'Reduce claim',
      CLAIM_NOT_SUPPORTED: 'Claim not supported',
      NOTIFICATION: 'Early Warning',
      AUTHOR_SHARE_CLAIM: 'Author share claim',
      AUTHORSHIP_CLAIM: 'Authorship claim',
      OWNERSHIP_CLAIM: 'Ownership claim',
      ORIGINAL_CLAIM: 'Original claim',
      SAMPLE_CLAIM: 'Sample claim',
      INFRINGEMENT_CLAIM: 'Infringement claim',
      NO_AGREEMENT_CLAIM: 'No agreement claim',
      BREACH_OF_CONTRACT_CLAIM: 'Breach of contract claim',
      AGREEMENT_OVERLAP: 'Agreement overlap',
      AGREEMENT_MANUAL_CONFLICT: 'Agreement manual conflict',
      WRITER_AND_ORIGINAL_PUBLISHER: 'Writer And Original Publisher',
      PUBLISHER_AND_SUBPUBLISHER: 'Publisher And SubPublisher',
      BETWEEN_CREATORS: 'Between Creators',
      INFRINGED_PARTIES: 'Infringed Parties',
      NEW_CREATORS: 'New Creators',
      EXPAND_ROWS: 'Expand all',
      COLLAPSE_ROWS: 'Collapse all',
      CROSS_REFERENCES: {
        TITLE: 'Xrefs',
      },
      WORK_TITLES: {
        TITLE: 'Titles',
      },
      VIEW_WITHDRAWN_DIALOG: {
        TITLE: 'Withdrawn Info',
        CARD_TITLE: 'Withdrawn Info',
        FIELDS: {
          TYPE: 'Type',
          POST_TERM_COLLECTION: 'Post Term Collection',
          POST_TERM_COLLECTION_DATE: 'Post Term Collection Date',
          TERMINATION_DATE: 'Termination Date',
        },
      },
      REDUCE_DIALOG: {
        TITLE: 'Please reduce your shares',
        TITLE_VIEW_ONLY: 'Reduced shares',
      },
      REQUEST_SUCCESS: 'Thanks! We received your request',
      WITHDRAW_DIALOG: {
        TITLE: 'Withdraw claims',
        SUBMIT_BUTTON: 'Submit',
        WITHDRAW_TYPE: 'Withdraw type',
        DELETE_CLAIMS: 'Delete claim(s)',
        TERMINATE_CLAIMS: 'Terminate claim(s)',
        POST_TERM_COLLECTION_VALUES: {
          NONE: 'None',
          DATE: 'Until Date',
          INDEFINITE: 'Indefinite',
        },
      },
      ACTION_TABLE: {
        TITLE: 'Actions',
        ACTION: 'Action',
        ASSIGNED_USER: 'Assigned User',
        RESOLVER_NAME: 'Resolver Name',
        IPI_NAME_NUMBER: 'IPI Name Number',
        STATUS: 'Status',
        DEADLINE: 'Deadline',
        CLOSE: 'Close',
      },
      CLOSE_ACTION_DIALOG: {
        TITLE: 'Close Action',
        MESSAGE: 'Are you sure to close this action?',
        SUCCESS: 'The action has been closed',
        ERROR: 'Error closing this action',
      },
      RESOLVE_COUNTERCLAIM_DIALOG: {
        TITLE: 'Resolve Counterclaim',
        MESSAGE: 'Are you sure to resolve and close counterclaim actions?',
      },
      ACTION_TOOLTIP: {
        REDUCE_CLAIM: 'View reduced claim(s)',
        WITHDRAW_CLAIM: 'View withdrawn claim(s)',
      },
    },
    WORKS: {
      IP: {
        ICONS: {
          ADD_ICON: {
            ICON_CODE: 'add_circle',
            ICON_TOOLTIP: 'Add',
          },
          REMOVE_ICON: {
            ICON_CODE: 'do_not_disturb_on',
            ICON_TOOLTIP: 'Remove',
          },
        },
      },
      WORK_ACTIVITY: {
        TAB_TITLE: 'Activity',
      },
      EDIT_CLAIM_SUBMIT_SUCESS: 'Your changes are successfully submitted',
      DELETE_CLAIM: {
        TITLE: 'Delete or terminate',
        TERMINATION_DATE: 'Termination date',
        POSTTERM_COLLECTION: 'Post-term collection',
        POSTTERM_COLLECTION_DATE: 'Post-term collection date',
        TERMINATION_ERROR: 'Termination date is after post-term collection date.',
      },
      HTML_TOOLTIP: {
        CC_ID: 'CC ID',
        TERRITORY: 'Territory',
        PR_RIGHTS: 'PR Rights',
        MR_RIGHTS: 'MR Rights',
        START_DATE: 'Start Date',
        END_DATE: 'End Date',
      },
      ALL_CLAIMS: {
        ONLY_CONFLICTS: 'Only claims in conflict',
        ALL_PR: 'ALL PR Rights',
        ALL_MR: 'ALL MR Rights',
        NA: 'N/A',
        ASSUMED: 'Assumed',
        DERIVED: 'Derived',
        SUBMITTED: 'Submitted',
        UNAUTHORIZED: 'Unauthorized',
        VALIDATED: 'Validated',
        SUBMIT: 'Apply Filter',
        TAB_TITLE: 'All claims',
        TABLE_SCHEMA: {
          FOOTER: {
            LABEL_CREATOR_SUB_TOTAL: 'Creator Sub Total',
            LABEL_PUBLISHER_SUB_TOTAL: 'Publisher Sub Total',
            LABEL_TOTAL: 'Total Claims',
          },
        },
        OPTIONS: {
          OWNERSHIP: 'Ownership',
          COLLECTION: 'Collection',
        },
      },
      WORK_CLAIMS: {
        TAB_TITLE: 'Submitted Claims',
        UPLOAD_DIALOG: {
          TITLE: 'Edit Claim',
        },
        DELETE_DIALOG: {
          TITLE: 'Remove Claim',
          MESSAGE: {
            PARAGRAPH_1: 'The claim you are deleting contains multiple scopes (multiple lines in this table).',
            PARAGRAPH_2: 'When continuing, you will delete all of the scopes as well as all child claims. To be more selective, please use the Edit Claim option.',
          },
        },
        ALL_PR_MR: 'All PR/All MR',
        ALL_PR: 'All PR',
        ALL_MR: 'All MR',
      },
      DETAILS: {
        STATUS: 'STATUS',
        TAB_TITLE: 'Details',
        ISWC_VALIDATION: 'invalid ISWC format',
        CARD_WITH_FORM: {
          CARD_TITLE: 'Other Information',
          FORM: {
            NAME: 'Name',
            VERSION: 'Version',
            STATUS: 'Status',
            ORIGIN: 'Origin',
            ORIGIN_TYPE: {
              ORI: 'Original',
              MOD: 'Modification',
            },
            GENRE: 'Genre',
            GENRE_TYPE: {
              JAZ: 'Jazz',
              POP: 'Pop',
              SER: 'Serious',
              UNC: 'Unconfirmed',
            },
            PROHIBITED: 'Prohibited',
            PUBLICATION_DATE: 'Publication Date',
            LANGUAGE: 'Language',
            CATEGORY: 'Category',
            DURATION: 'Duration (MM:SS)',
            MAX_DURATION_ERROR: 'Title duration cannot exceed 9999 hours 59 minutes and 59 seconds',
            DURATION_FORMAT_ERROR: 'The format should be MM:SS or HHHH:MM:SS',
            SOURCE: 'Source',
            OWNER: 'Owner',
            SHARE_DIVISION_OWNER: 'Share Division Owner',
            ISWC: 'ISWC',
            XREF: 'XREF',
            WORK_SOCIETY_MARKER: 'WORK SOCIETY MARKER',
            PURPOSE: 'Purpose',
            CREATION_DATE: 'Creation Date',
            TEXT_MUSIC_RELATIONSHIP: 'Text Music Relationship',
            LYRIC_ADAPTION: {
              TITLE: 'Lyric Adaption',
              ADDITION: 'Addition',
              MODIFICATION: 'Modification',
              NEW: 'New',
              ORIGINAL: 'Original',
              REPLACEMENT: 'Replacement',
              TRANSLATION: 'Translation',
              UNSPECIFIED: 'Unspecified',
            },
            MUSIC_ARRANGEMENT: {
              TITLE: 'Music Arrangement',
              ADDITION: 'Addition',
              ARRANGEMENT: 'Arrangement',
              NEW: 'New',
              ORIGINAL: 'Original',
              UNSPECIFIED: 'Unspecified',
            },
            WORK_MUSIC_RELATIONSHIP_OPTIONS: {
              MTN: 'Music and Text not Co-authored',
              MTX: 'Music and Text Co-authored',
            },
            CATEGORY_OPTIONS: {
              COS: 'Composite of Samples',
              EXC: 'Excerpt',
              MED: 'Medley',
              POT: 'Potpourri',
              UCO: 'Unspecified Composite',
            },
            OT: 'Original title',
            OV: 'Original work title for version',
            AT: 'Alternative Title',
            TE: 'First line of text',
            FT: 'Formal title',
            IT: 'Incorrect title',
            TT: 'Translated title',
            PT: 'Part title',
            RT: 'Restricted Title',
            ET: 'Special title',
            OL: 'Original title with national characters',
            AL: 'Alternative title with national characters',
            DT: 'Duration title',
            LT: 'Library / Production title',
            NT: 'non-match title',
            FA: 'First album title',
            DI: 'UK National Discography Title',
            CT: 'Recording Title',
            VT: 'Version Title',
            WORK_TITLE: 'Title',
            WORK_TITLES: { TITLES: 'Titles', TITLE: 'Title', TYPE: 'Type', DURATION: 'Duration' },
            ADD_WORK_TITLES: 'Add Titles',
            EDIT_WORK_TITLES: 'Edit Titles',
            DELETE_WORK_TITLES: 'Delete Titles',
            SELECT_TITLE_TYPE: 'Select Title Type',
            SELECT_XREF_TYPE: 'Select XREF Type',
            SELECT_WORK_SOCIETY_MARKER_STATUS: 'Select Work Society Market Status',
            XREF_TYPES: {
              ALLAW: 'PRS Active Work Number',
              ALLIB: 'Library Production Number',
              ALLRW: 'PRS Registered Work Number',
              ALLTC: 'Alliance Tunecode',
              BSREG: 'BUMA/STEMRA Registered Title ID',
              CUBE: 'CUBE',
              DELTC: 'Alliance Deleted Tunecode',
              EJNW: 'Electronic Joint Notification Work Reference Number',
              ICE: 'ICE Work Key',
              ICEADMIN: 'ICE Admin',
              ISRC: 'ISRC Reference',
              ISWC: 'ISWC Number',
              ISWCA: 'ISWC Archived',
              NDREF: 'Norddoc Workkey',
              PAPID: 'Society Paper Id',
              PWREF: 'Publisher Work ID',
              RECID: 'Submitter Recording Identifier',
              SIWID: 'SABAM - SIWID',
              SWREF: 'Society Work ID',
              SWWRR: 'STIM Web Work Registration Reference',
              WIDNO: 'WIDB Number',
            },
            IPI_NAME_NUMBER: 'IPI Name Number',
          },
        },
        EXPANSION_LIST: {
          CONTRIBUTORS: 'Contributors',
          XREFS: 'Xrefs',
          ADD_XREFS: 'Add Xrefs',
          EDIT_XREFS: 'Edit Xrefs',
          DELETE_XREFS: 'Delete Xrefs',
          XREF: 'Xref',
          WORK_SOCIETY_MARKER: 'Work Society Marker',
          EDIT_WORK_SOCIETY_MARKER: 'Edit Work Society Marker',
          INSTRUMENTS: 'Instruments',
          INSTRUMENTATION: 'Instrumentation',
          EXTENDED_TOP: 'Extended ToP',
        },
        SOCIETY_MARKER_CARD: {
          TITLE: 'Work Society Marker',
          SUBTITLE: 'Active Societies',
          ACTIVE: 'Active',
          NOT_ACTIVE: 'Not Active',
        },
        CARD_FILTER_DATATABLE: {
          CARD_TITLE: 'Ownership Views',
          FILTER_SUBMIT: 'Filter',
          TOGGLE_LABEL_1: 'Manuscript View',
          TOGGLE_LABEL_2: 'Claim View',
          FILTER: {
            FIELD_MR_RIGHTS: 'MR Right',
            ALL: 'ALL Rights',
            ALL_PR: 'ALL PR Rights',
            ALL_MR: 'ALL MR Rights',
            EMPTY: 'N/A',
            FIELD_PR_RIGHTS: 'PR Right',
            FIELD_SOCIETY: 'Distribution Society',
            MR_FIELD_SOCIETY: 'MR Distribution Society',
            PR_FIELD_SOCIETY: 'PR Distribution Society',
            FIELD_TERRITORY: 'Territory',
            ALL_TERRITORIES: 'All Territories',
            SELECT_ALL: 'Select All',
            WORLDWIDE: 'Worldwide',
            FIELD_USAGE_DATE: 'Usage Date',
            FIELD_USAGE_DATE_INFO: 'Period between prior royalties date and work/agreement end date',
            FIELD_DISTRIBUTION_DATE: 'Distribution Date',
            FIELD_DISTRIBUTION_DATE_INFO: 'Period between work/agreement start date and post term collection date',
            PR_USAGE_FACTOR: 'PR Usage Factor',
            MR_USAGE_FACTOR: 'MR Usage Factor',
            SUBMIT: 'Apply Filter',
            IP_PROTECTION_POPUP: {
              TITLE: 'Add Work Terms of Protection',
              TYPE: { TITLE: 'Select Type of Extended Protection', SELECT: 'Please Select Type', WORK_LEVEL: 'Work Level', IP_LEVEL: 'IP Level' },
              SOCIETY_CODE: 'Enter Distributing Society Code',
              COUNTRY_CODE: 'Enter Territory Code',
              PROTECTED_UNTIL: 'Protected Until (YYYY-MM-DD)',
              SELECTED_IP: 'Select IP',
              ERROR_SOCIETY: `One or more societies doesn't exists`,
              VALIDATION_ERROR_IP: `Already exists one society/territory combination for the same IP or in the Work Level`,
              VALIDATION_ERROR_WORK: `Already exists one society/territory combination in this work`,
            },
            FILTER_PLACEHOLDER: 'Filter values',
            CLAIM_STATUS: 'Claim Status',
            ALL_STATUS: 'All Status',
            CLEAR_ALL: 'Clear all',
          },
          TABLE_SCHEMA: {
            COL_IGNORE: 'Ign',
            COL_ROLE: 'Role',
            COL_IPINAMEKEY: 'IPI Name Key',
            COL_IPNAMEKEY: 'IP Name Key',
            COL_IPINAME: 'IPI Name',
            COL_TERRITORY: 'Territory',
            COL_NAME: 'Name',
            COL_REF: 'IP',
            COL_IP: 'IPI Name Number',
            COL_IP_NAME_NUMBER_TOOLTIP: 'IPI Name Number',
            COL_IP_NAME_NUMBER: 'IPI No.',
            COL_IP_NAME_KEY: 'IP Name Key',
            COL_IP_BASE_NUMBER: 'IPI B. Num.',
            COL_IP_BASE_KEY: 'IP Base Key',
            COL_SOCIETY: 'Society',
            COL_SOCIETY_PR_MR: 'PR/MR Soc',
            COL_PR_SOCIETY: 'PR Soc.',
            COL_MR_SOCIETY: 'MR Soc.',
            COL_AGREEMENT: 'Agreement',
            COL_STATUS: 'Status',
            COL_RIGHTS_PR_MR: 'Rights (PR/MR)',
            COL_PR_RIGHTS: 'PR Rights',
            COL_MR_RIGHTS: 'MR Rights',
            COL_PR_SOCIETY_TOOLTIP: 'Performing Rights Society',
            COL_MR_SOCIETY_TOOLTIP: 'Mechanical Rights Society',
            COL_PR_RIGHTS_SHARE_TOOLTIP: 'Performing Rights Share',
            COL_MR_RIGHTS_SHARE_TOOLTIP: 'Mechanical Rights Share',
            UNAUTHORISED: 'Unauthorised',
            COL_PR: 'PR',
            COL_MR: 'MR',
            FOOTER: {
              LABEL_CREATOR_SUB_TOTAL: 'Creator Sub Total',
              LABEL_PUBLISHER_SUB_TOTAL: 'Publisher Sub Total',
              LABEL_SPECIAL_SUB_TOTAL: 'Special Sub Total',
              LABEL_TOTAL: 'Total Claims',
            },
          },
          ERRORS: { BAD_RESULT: 'Shares incomplete', NO_CORRECT_SHARES: 'Shares incomplete', ERROR_GETTING_DATA: 'Shares incomplete' },
        },
        CONFLICTS: {
          TITLE: 'Conflict Scope',
          TABLE: {
            ID: 'ID.',
            TERRITORY: 'Territory',
            RIGHTS_PR: 'Rights PR',
            RIGHTS_PR_TOOLTIP: 'Performing Rights',
            RIGHTS_MR: 'Rights MR',
            RIGHTS_MR_TOOLTIP: 'Mechanical Rights',
            START_DATE: 'Conflict Start D.',
            START_DATE_TOOLTIP: 'Conflict Start Date',
            END_DATE: 'Conflict End D.',
            END_DATE_TOOLTIP: 'Conflict End Date',
            CLAIM_TYPE: 'Claim Type',
            CLAIM_TYPE_OWNERSHIP: 'Ownership',
            CLAIM_TYPE_CLAIM: 'Collection',
            WORKS_INCLUDED: 'Works Included',
            CONFLICT_CAUSE: 'Conflict Cause',
            HAS_MORE_CLAIMS: '{{count}} more ...',
            ADDITIONAL_INFORMATION: 'Additional information',
            CAUSED_BY_CLAIMS: 'Caused by claims',
          },
        },
      },
      ALTERNATIVE_TITLES: { TAB_TITLE: 'Alternative Titles', TABLE: { TYPE: 'Type', TITLE: 'Title' } },
      WORK_CLAUSES: {
        TAB_TITLE: 'IP Clauses',
        ADD: 'Add IP Clauses',
        REMOVE: 'Remove IP Clauses',
        SELECT_TYPE: 'Select IP Clause Type*',
        SELECT_IPI_NAME: 'Enter IPI Name Numbers (separated by commas)',
        CHECK_DELETE: 'The IP Clause will be removed, are your sure?',
        WRONG_NAME_NUMBER: 'Some IPI Name Numbers are incorrect.',
        TABLE: {
          CLAUSE_TYPE: 'Clause Type',
          CLAUSE_NAME: 'Clause Name',
          IP_NAME: 'IPI Name',
          IP_NUMBER: 'IPI Number',
        },
      },
      SHARE_PICTURE: {
        TAB_TITLE: 'Share Pictures',
        CARD_WITH_TOGGLE_FILTER: { CARD_TITLE: 'Share Pictures' },
        OWNERSHIP: 'Ownership',
        PAYMENT: 'Payment',
        MANUSCRIPT: 'Manuscript',
        DATA: 'Data',
        NO_PERMISSION: "We are sorry - You don't have sufficient permission to view the share information for this work",
        REPERTOIRE: 'Repertoire',
        SHOW_TREE_CHART: 'Show tree chart',
        SHOW_TABLE_VIEW: 'Show table view',
        SHOW_LOG: 'Show log',
        AUDIT_INFO: 'Audit Info',
        SPECIAL: 'Special',
        EXCLUDED: 'Excluded',
        WARNING_MANUSCRIPT_MSG: 'During manuscript view, tree chart is not available.',
        AGREEMENT_ADMIN: 'Admin',
        FILTER: {
          FIELD_SOCIETY: 'Distribution Society',
          FIELD_TERRITORY: 'Territory',
          FIELD_USAGE_DATE: 'Usage Date',
          FIELD_USAGE_DATE_INFO: 'Period between prior royalties date and work/agreement end date',
          FIELD_DISTRIBUTION_DATE: 'Distribution Date',
          FIELD_DISTRIBUTION_DATE_INFO: 'Period between work/agreement start date and post term collection date',
          FIELD_REPERTOIRE: 'Repertoire',
          ADD_OUTDATED: 'Add outdated claims',
          REMOVE_E: 'Remove E',
          REMOVE_SE: 'Remove SE',
          SUBMIT: 'Filter',
          NO_E: 'No E',
          NO_SE: 'No SE',
        },
        TABLE_SCHEMA: {
          COL_ROLE: 'Role',
          COL_NAME: 'Name',
          COL_IP_NAME_NUMBER: 'IPI Num.',
          COL_IP_NAME_NUMBER_TOOLTIP: 'IPI Name Number',
          COL_IP_NAME_KEY: 'IP Name Key',
          COL_IP_BASE_NUMBER: 'IPI B. Num.',
          COL_IP_BASE_KEY: 'IP Base Key',
          COL_INCOME_PARTICIPATION: 'IncP',
          COL_SOCIETY: 'Society',
          COL_PR_SOCIETY: 'PR Society',
          COL_PR_SOCIETY_TOOLTIP: 'Performing Rights Society',
          COL_MR_SOCIETY: 'MR Society',
          COL_MR_SOCIETY_TOOLTIP: 'Mechanical Rights Society',
          COL_PR: 'PR',
          COL_PR_RIGHTS_SHARE_TOOLTIP: 'Performing Rights Share',
          COL_MR: 'MR',
          COL_MR_RIGHTS_SHARE_TOOLTIP: 'Mechanical Rights Share',
          EDIT_USAGE_TYPES: 'Modify usage types',
          COL_PR_REPERTOIRE: 'PR Repertoire',
          COL_MR_REPERTOIRE: 'MR Repertoire',
          CLAIMANT_STATUS: 'Claimant Status',
          COUNTERCLAIM_RELATION: 'Claimant',
          ADD_CLAIMANT: 'Select claimant',
          EDIT_CLAIMANT: 'Edit claimant',
          RESOLVER: 'Resolver',
          RESPONSE_STATUS: 'Response Status',
          PERFORMANCE_REPERTOIRE: 'Perf Repertoire',
          MECHANICAL_REPERTOIRE: 'Mech Repertoire',
          ALERT: 'Alerts',
        },
        ERRORS: {
          EMPTY_FULLCLAIMS: 'Tree is not available, empty FullClaims',
          TREE_NOT_AVAILABLE: 'Tree is not available',
          RETRY_MANUALLY: 'There has been an error submitting share picture, incorrect server response, please use the filter button to retry.',
          MAX_ATTEMPTS: 'The maximum number of attempts has been made, please use the filter button to retry.',
        },
        DIAGNOSTICS: 'Diagnostics',
        REQUEST_ICE_UPDATE: 'Request ICE update',
      },
      PAYMENT: 'IP Number',
      WORK_MATCHES: 'Publisher Name',
      COUNTERCLAIMS: 'Publisher ID',
      AGREEMENTS: {
        TAB_TITLE: 'Agreements',
        TABLE: {
          COL_ASSIGNOR: 'Assignor',
          COL_ASSIGNEE: 'Assignee',
          COL_AGREEMENT_REFERENCE: 'Agreement Reference',
          COL_AGREEMENT_TERRITORIES: 'Territory',
          COL_AGREEMENT_START_DATE: 'Start Date',
          COL_TYPE: 'Type',
          COL_DISPUTE: 'Dispute',
          COL_PR: 'PR',
          COL_MR: 'MR',
        },
      },
      OTHER_PARTIES: {
        TAB_TITLE: 'Other Party',
        ADD: 'Add Other Parties',
        EDIT: 'Edit Other Parties',
        REMOVE: 'Remove Other Parties',
        CHECK_DELETE: 'The party will be removed',
        ERRORS: { IPI_SEARCH_ERROR: 'No party with this IPI Name Number' },
        TABLE: { TYPE_OF_NAME: 'Type', NAME: 'Name', IPI_NAME_NUMBER: 'IPI Name Number' },
      },
      EDIT_CLAIM: {
        TAB_TITLE: 'Edit Claim',
      },
      SOURCE_WORKS: {
        TAB_TITLE: 'Source Works',
        ADD: 'Add new source work',
        ADD_SUCCESS: 'The new source work is successfully added to the master work',
        ADD_CONFLICT: 'The work has been linked successfully but a no merge conflict has been created',
        ADD_FAILURE: 'Sorry, something went wrong. Please try again',
        REMOVE: 'Remove Source Works',
        REMOVED: 'Successfully removed Source Works',
        UNMATCH: 'Unmatch Source Works',
        TABLE: {
          SOC: 'Soc',
          MIGRATED_FROM: 'Migrated from',
          CWR: 'CWR',
        },
        CHECK_DELETE_ALL: 'Deleting this source work, the mastered work will also be deleted. Do you wish to continue?',
        CHECK_DELETE_MASTER_WITH_SOURCE: 'The mastered work will be deleted, the remaining source works will be rematched to create a new master. Do you wish to continue?',
        CHECK_DELETE_MASTER_ALONE: 'The mastered work will be deleted. Do you wish to continue?',
        CHECK_DELETE_SOURCE: 'The source work selected will be deleted. Unsaved changes will be lost. Do you wish to proceed?',
        CHECK_UNMATCH_SOURCE: 'The source work selected will be unmatched. Do you wish to continue?',
        DELETE_WARNING: 'A data refresh is necessary to guarantee accuracy. This will overwrite any unsaved edits.',
        CREATION_DATE: 'Date of creation',
      },
      WORKS_RELATIONS: {
        TABLE_TITLE: 'Work Relations',
        ADD: 'Add Work Relations',
        REMOVE: 'Remove Work Relations',
        UNMATCH: 'Unmatch Work Relations',
        RELATION_TYPE: 'Relation Type',
        TYPE: 'Type',
        WORK_ID: 'Work Id',
        CHECK_DELETE: 'The relation will be deleted',
        RELATION_TYPES: {
          SAMPLES: 'SAMPLES',
          MODIFIES: 'IS A MODIFICATION OF',
          EXCERPTS: 'CONTAINS THE EXCERPT',
          IS_SAMPLED_BY: 'IS SAMPLED BY',
          IS_MODIFIED_BY: 'IS MODIFIED BY',
          IS_EXCERPTED_BY: 'IS EXCERPTED BY',
        },
      },
      INSTRUMENTS: {
        ERRORS: {
          RANGE_1_999: 'The value must be a number between 1 and 999',
          INVALID_CODE: 'The value must be a valid code',
        },
        CHECK_DELETE: 'The instrument will be removed',
        TAB_TITLE: 'Instruments',
        INSTRUMENTS_TABLE: {
          TITLE: 'Instruments',
          ADD_TITLE: 'Add Instruments',
          EDIT_TITLE: 'Edit Instruments',
          REMOVE_TITLE: 'Remove Instruments',
          INSTRUMENT_CODE: 'Code',
          INSTRUMENT_NAME: 'Instrument',
          INSTRUMENT_FAMILY: 'Family',
          INSTRUMENT_NUMBER: 'Instrument number',
          INSTRUMENT_NUMBER_OF_PLAYERS: 'Number of players',
        },
        INSTRUMENTATION_TABLE: {
          TITLE: 'Instrumentation',
          ADD_TITLE: 'Add Instrumentation',
          EDIT_TITLE: 'Edit Instrumentation',
          REMOVE_TITLE: 'Remove Instrumentation',
          INSTRUMENT_CODE: 'Code',
          INSTRUMENT_NAME: 'Instrument',
          INSTRUMENT_DESCRIPTION: 'Description',
          INSTRUMENT_NUMBER: 'Instrument number',
          INSTRUMENT_NUMBER_OF_PLAYERS: 'Number of players',
          NUMBER_OF_PARTS: 'Parts number',
          CHECK_DELETE: 'The instrumentation will be removed',
        },
      },
      SOCIETY_ATTRIBUTES: {
        TAB_TITLE: 'Soc Attributes',
        TAB_TITLE_TOOLTIP: 'Society Attributes',
        ATTRIBUTES_TABLE: {
          TITLE: 'Attributes',
          SOCIETY_NAME: 'Society',
          SOCIETY_CODE: 'Code',
          ATTRIBUTE_NAME: 'Attribute',
          ATTRIBUTE_VALUE: 'Value',
          INDEX: 'Index',
          ADD_SOCIETY_ATTRIBUTE: 'Add Society Attribute',
          EDIT_SOCIETY_ATTRIBUTE: 'Edit Society Attribute',
          REMOVE_SOCIETY_ATTRIBUTE: 'Remove Society Attribute',
        },
        GRADING_TABLE: {
          TITLE: 'Gradings',
          GRADING_DATE: 'Date',
          GRADING_DECIDER: 'Decider',
          GRADING_STATUS: 'Status',
          GRADING_VALUE: 'Value',
          ADD_SOCIETY_GRADING: 'Add Society Grading',
          EDIT_SOCIETY_GRADING: 'Edit Society Grading',
          REMOVE_SOCIETY_GRADING: 'Remove Society Grading',
        },
        ATTRIBUTES_POPUP: {
          TITLE: 'Local attributes for',
          SELECT_SOCIETY: 'Select a society',
          CLASSIFICATION: 'Classification',
          ARRANGEMENT_GRADE: 'Arrangement Grade',
          DRAMATICO_MUSICAL: 'Dramatico Musical',
          LAST_DIST_REF_MECH: 'Last Dist Ref Mech',
          LAST_DIST_REF_PERF: 'Last Dist Ref Perf',
          BROADCAST_INFO: 'Broadcast Info',
          STATUS: 'Status',
          GENRE: 'Genre',
          ARRANGEMENT_WEIGHTING: 'Arrangement Weighting',
          TRUE_ORIG_WORK_INDICATOR: 'True orig. work indicator (ABO)',
          ARRANGER_ADAPTOR_AUTHORIZED: 'Arranger/Adaptor authorized Y/N',
          AGREED_SPLIT_AUTHORIZED: 'Agreed Split authorized Y/N',
          CLASSIFICATION_VALIDATION_INFO: 'Allowed value is 0 to 991',
          GRADING_DATE: 'Date',
          GRADING_DECIDER: 'Decider',
          GRADING_STATUS: 'Status',
          GRADING_VALUE: 'Value',
          ERRORS: {
            NUMBER_0_99: 'The value must be from 0 to 99',
            NUMBER_0_991: 'The value must be from 0 to 991',
            NUMBER_0_9999999: 'The value must be from 0 to 9999999 (maximum 7 digits)',
            ALPHANUMERIC_LENGTH_60: 'The value must be an alphanumeric (maximum 60 characters)',
          },
          REMOVE: { TITLE: 'Remove attribute', TEXT: 'The attribute will be removed, are your sure?', REMOVE_BUTTON: 'Remove', CANCEL_BUTTON: 'Cancel' },
        },
      },
      REPERTOIRES: {
        TAB_TITLE: 'Repertoires',
      },
      EXTENDED_TOP: {
        TAB_TITLE: 'Ext. ToP',
        TABLE_TITLE: 'Extended Terms of Protection',
        TAB_TITLE_TOOLTIP: 'Extended Terms of Protection',
        SOCIETY: 'Society',
        COUNTRY: 'Territory',
        DATE: 'Date',
        IP_ROLE: 'IP Role',
        IPI_NAME_NUMBER: 'IPI Name Number',
        NAME: 'Name',
        ADD_EXTENDED_ToP: 'Add New Extended ToP',
        CHECK_DELETE: 'The Extended ToP will be removed, are your sure?',
        REMOVE_PROTECTION: 'Remove Extended ToP',
      },
      CONFLICTS_CC: {
        TAB_TITLE: 'Conflicts & CC',
        TAB_TITLE_TOOLTIP: 'Conflicts & Counterclaims',
        TABLE_TITLE: 'Conflicts List',
        DATATABLE_FIELDS: {
          CONFLICT_ID: 'Conflict Id',
          DEADLINE_DATE: 'Deadline Date',
          VALUE: 'Value',
          UPLOAD_CAT: 'Upload Category',
          CASE_STATUS: 'Case Status',
          CONFLICT_SUB_TYPE: 'Conflict Sub Type',
          ASSIGNEE: 'Assignee',
          SCOPE_DETAIL: {
            TERRITORIES: 'Territories',
            RIGHTS: 'Rights',
            CONFLICT_START: 'Conflict Start',
            CONFLICT_END: 'Conflict End',
          },
        },
      },
      COUNTERCLAIM: {
        TABLE_TITLE: 'Counterclaim List',
        TOGGLE_BUTTONS: {
          SHOW_TRIGGERS: 'Trigger Details',
          SHOW_SCOPE: 'Scope Details',
        },
        FIELDS: {
          COUNTERCLAIM_ID: 'Counterclaim Id',
          CREATION_DATE: 'Creation Date',
          OWNER: 'Resolution Owner(s)',
          TYPE: 'Type',
          POINT_CONFLICT: 'Point of Conflict',
          STATUS: 'Status',
          TERRITORY: 'Territory(s)',
        },
      },
      TOOLTIPS: {
        ICONS: {
          UNAUTHORIZED: 'Unauthorized',
          INCOME_PARTICIPANT: 'Income Participant',
        },
      },
    },
    AGREEMENTS: {
      DETAILS: {
        TAB_TITLE: 'Agreement Details',
        CARD_WITH_FORM: { CARD_TITLE: 'Core Agreement' },
        OPTIONAL_CARD_WITH_FORM_1: { CARD_TITLE: 'Assignor' },
        OPTIONAL_CARD_WITH_FORM_2: { CARD_TITLE: 'Assignee' },
        CARD_WITH_DATA_TABLE: {
          PARTIES: 'Parties',
          TERMS: 'Terms',
          SHARES_PER_TYPE_OF_USE: 'Share',
          TERM: 'Term',
          DATE: 'Date',
          SHARE_TYPE: 'Type',
          IPI_RIGHT: 'IPI Right',
          TERRITORY: 'Territory',
          SHARE_IN: 'Share In',
          SHARE_OUT: 'Share Out',
          TERM_DATE: 'Term. Date',
          PTC_END: 'PTC End',
          AGREEMENT_START_DATE: 'Agreement Start Date',
          EXPECTED_TERMINATION_DATE: 'Expected Termination Date',
          PRIOR_ROYALTIES_START_DATE: 'Prior Royalties',
          CONFIRMED_TERMINATION_DATE: 'Confirmed Agreement Termination Date',
          POST_TERM_COLLECTION_END_DATE: 'Post-term Collection',
          ADMIN: 'Admin',
          PARTIALLY_TERMINATED: 'Partially-terminated Agreement',
          INDEFINITE: 'Indefinite',
        },
      },
      EDIT_WORKS_DIALOG: {
        TAB_TITLE: 'Select the next action',
        SEARCH_WORKS: 'Search works to add',
        UPLOAD_CSV: 'Upload CSV file',
        EDIT_ERROR: 'There was an error modifying the agreement work list',
        ADD_WORK_REFERENCE: 'Add Work Reference',
        SELECT_WORKS: 'Select Work Keys File',
        WORK_REFERENCE: 'Work reference',
        INCLUDE: 'Include',
        EXCLUDE: 'Exclude',
        UPLOAD_SUCCESS: 'Your file has been succesfully submitted and the changes will appear in the agreement once the file has been processed.',
        SINGLE_WORK_ERROR: `The work doesn't exist, please enter another reference`,
      },
      MESSAGES_CSV_FILE: {
        G: {
          inclusion: 'Remove works from exclusion list',
          exclusion: 'Add works to exclusion list',
        },
        S: {
          inclusion: 'Add works to work list',
          exclusion: 'Remove works from list',
        },
      },
      INCLUDED_WORKS: {
        TAB_TITLE: 'Works',
        ADD_WORKS: 'Add Included Works',
        DELETE_WORKS: 'Remove work',
        DELETE: 'Do you want to remove the work from the work list?',
        ERROR_EDIT: 'There was an error modifying the agreement work list',
      },
      EXCLUDED_WORKS: {
        TAB_TITLE: 'Excl. Works',
        ADD_WORKS: 'Add excluded works',
        DELETE_WORKS: 'Remove work',
        DELETE: 'Do you want to remove the work from the work list?',
        ERROR_EDIT: 'There was an error modifying the agreement work list',
      },
      LINKED_WRITERS: {
        TAB_TITLE: 'Linked Writers',
        ADD_LINKED_WRITERS: 'Linked Writers',
        POPUP: {
          DELETE_TITLE: 'Delete Linked Writer',
          ARE_YOU_SURE: 'Are you sure you want to delete this Linked Writer?',
        },
      },
      WRITERS: {
        TAB_TITLE: 'Writers',
        FIELDS: {
          WRITER_NAME: 'Writer name',
          WRITER_IPI_NAME_NUMBER: 'Writer IPI name number',
          OP_NAME: 'OP name',
          OP_IPI_NAME_NUMBER: 'OP IPI name number',
          TERRITORY: 'Territory',
          TERMINATION_DATE: 'Termination date',
          COLLECTION_END_DATE: 'Post-term collection end date',
          RIGHT_TYPES: 'Right types',
          WORK_NUMBERS: 'Number of Works',
        },
      },
      ASSIGNOR_ID: 'Assignor Id',
      ASSIGNOR_NAME: 'Assignor Name',
      ASSIGNEE_ID: 'Assignee Id',
      ASSIGNEE_NAME: 'Assignee Name',
      SALES_MANUFACTURE: {
        NAME: 'Sales/Manufacture',
        VALUES: { SALES: 'Sales', MANUFACTURE: 'Manufacture' },
      },
      OPEN_WORKS: 'Open Work list',
      WRITER_MECHANICAL_REASON: 'Writer mechanical reason',
      USA_LICENSE_INDICATOR: 'USA licence indicator',
      SHARE_IN_OVERRIDE: 'Share-in override',
      DISPUTE: { NAME: 'Dispute', VALUES: { YES: 'Yes', NO: 'No' } },
      REFERENCE: 'Reference Number',
      AGREEMENT_NAME: 'Agreement Name',
      IPI_NAME: 'IPI Name',
      IP_NAME_KEY: 'IP Name Key',
      IPI_NAME_NUMBER: 'IPI Name Number',
      SOCIETY: 'Society',
      MR: 'Society MR',
      MR_HEADER_TOOLTIP: 'Mechanical Rights Society',
      PR: 'Society PR',
      PR_HEADER_TOOLTIP: 'Performing Rights Society',
      OPEN_IP_DETAILS: 'Open IP details',
      AGREEMENT_TYPE: {
        NAME: 'Agreement Type',
        VALUES: { GENERAL: 'General', SPECIFIC: 'Specific' },
      },
      AGREEMENT_SOURCE: 'Source',
      CROSS_REFERENCES: {
        TITLE: 'Xrefs',
        ADD_CROSS_REFERENCES: 'Add Xrefs',
        REFERENCE_LIST_LABELS: {
          AGREEMENT_GROUP_REFERENCE: 'Agreement Group Reference',
          SOCIETY_AGREEMENT_REFERENCE: 'Society Agreement Reference',
          PUBLISHER_AGREEMENT_REFERENCE: 'Publisher Agreement Reference',
        },
        REFERENCE_TYPE: 'Agreement Reference Type',
        REFERENCE_TYPES: {
          AGREEMENT_GROUP_REFERENCE: 'Agreement Group Reference',
          SOCIETY_REFERENCE_NUMBER: 'Society Reference Number',
          PUBLISHER_REFERENCE_NUMBER: 'Publisher Reference Number',
        },
        AGREEMENT_GROUP_TYPE: 'Agreement Group Type',
        AGREEMENT_GROUP_TYPES: {
          INCLUDE: 'Include',
          RECIPIENT: 'Recipient',
        },
        AGREEMENT_GROUP_RELATION: {
          INCLUDED: 'Included',
          RELATED: 'Related',
        },
        REFERENCE_NUMBER: 'Xref Number',
        SOCIETY_CODE: 'Society Code',
        PUBLISHER: 'Publisher IPI Number or Name Key',
        ADD_REFERENCE_POPUP: {
          KO_MESSAGE: `The submitted relation can't be added. Please review the submitted information`,
        },
        ERROR_INVALID_AGREEMENT_GROUP: 'Invalid Agreement Group Reference',
        ARE_YOU_SURE: 'Are you sure you want to delete this Reference?',
        XREF_TYPES: {
          ALLTC: 'Alliance Tunecode',
          ALLIB: 'Library Production Number',
          ISWCA: 'ISWC Archived',
          ALLAW: 'PRS Active Work Number',
          ALLRW: 'PRS Registered Work Number',
          ISWC: 'ISWC Number',
          WIDNO: 'WIDB Number',
          DELTC: 'Alliance Deleted Tunecode',
          NDREF: 'Norddoc Workkey',
          EJNW: 'Electronic Joint Notification Work Reference Number',
          SWWRR: 'STIM Web Work Registration Reference',
          ISRC: 'ISRC Reference',
          PAPID: 'Society Paper Id',
          BSREG: 'BUMA/STEMRA Registered Title ID',
          SIWID: 'SABAM - SIWID',
          RECID: 'Submitter Recording Identifier',
          ICE: 'ICE Work Key',
          ICEADMIN: 'ICE Admin',
          SWREF: 'Society Work ID',
          PWREF: 'Publisher Work ID',
          CUBE: 'CUBE',
        },
      },
      SUBMITTER: { TITLE: 'Submitters' },
      WORKS_FILTER: {
        ORIGINAL_PUBLISHER: 'Original publisher',
        RESET: 'Reset',
        APPLY_FILTER: 'Apply Filter',
        INCLUDE_TERMINATED_WORKS: 'Include terminated works',
      },
    },
    AGREEMENT_CONFLICT: {
      DETAILS: {
        TAB_TITLE: 'Details',
        CONFLICT_STATUS: 'Conflict status',
        STATUS: 'Status',
        DEADLINE: 'Deadline',
        AGREEMENTS: 'Agreements',
        POSSIBLE_CONFLICT_CASE: 'Possible Conflict Case ID',
      },
    },
    AGREEMENT_GROUP: {
      DETAILS: {
        TAB_TITLE: 'Group details',
        TITLE: 'Details',
        DESCRIPTION: 'Description',
        ID: 'Group Reference',
        IP_NAME: 'IP Name',
        OWNER: 'Owner',
        GROUP_ID: 'Group ID',
      },
      INCLUDED_AGREEMENTS: {
        TAB_TITLE: 'Included agreements',
        ASSIGNOR: 'Assignor',
        ASSIGNEE: 'Assignee',
        ADMIN: 'Admin',
        AGR_NUMBER: 'Agr. Number',
        TYPE: 'Type',
        TERRITORIES: 'Territory',
        PR_IN_OUT: 'PR in<br>PR out',
        MR_IN_OUT: 'MR in<br>MR out',
        TERMINATION_DATE: 'Termination date',
        POSTTERM_COLLECTION_DATE: 'Post-term collection date',
        ADD_AGREEMENT: 'Add included agreement',
        REMOVE_AGREEMENT: 'Remove included agreement',
      },
      RELATED_AGREEMENTS: {
        TAB_TITLE: 'Related agreements',
        ASSIGNOR: 'Assignor',
        ASSIGNEE: 'Assignee',
        ADMIN: 'Admin',
        AGR_NUMBER: 'Agr. Number',
        TYPE: 'Type',
        TERRITORIES: 'Territory',
        PR_IN_OUT: 'PR in<br>PR out',
        MR_IN_OUT: 'MR in<br>MR out',
        TERMINATION_DATE: 'Termination date',
        POSTTERM_COLLECTION_DATE: 'Post-term collection date',
        ADD_AGREEMENT: 'Add related agreement',
        REMOVE_AGREEMENT: 'Remove related agreement',
      },
    },
    REPERTOIRES: {
      DETAILS: {
        TAB_TITLE: 'Repertoire details',
        DETAILS_CARD: {
          TITLE: 'Details',
          KEY: 'Repertoire Key',
          DESCRIPTION: 'Name',
          TYPE: 'Type',
          OP_REPERTOIRE_ALLOWED: 'OP can determine Repertoire',
          RULE_SET_ID: 'Rule Set ID',
        },
        TERMS_CARD: {
          TITLE: 'Terms',
          START_DATE: 'Valid From',
          END_DATE: 'Valid To',
          TERRITORY: 'Territory',
          ACTIVE: 'Repertoire Active',
          UNPUBLISHED_WRITER_SHARES: 'Unpublished writer shares',
        },
        ROLL_UP_CARD: {
          TITLE: 'Original Publisher Roll-up',
          ROLL_UP: 'Roll-up',
          EXCLUDED_TERRITORIES: 'Excluded Territories',
          ALLOW_NON_SOCIETY_OP: 'Allow Non-society OP',
        },
        EXCLUSIONS_CARD: {
          TITLE: 'Exclusions',
          WORKS: 'Works',
          AGREEMENTS: 'Agreements',
        },
        CLAIM_TYPES_CARD: {
          TITLE: 'Claim Types',
          DIRECT_CLAIMS: 'Direct Claims',
          DERIVED_CLAIMS: 'Derived Claims',
          ASSUMED_CLAIMS: 'Assumed Claims',
        },
      },
      INCLUDED_WORKS: {
        TAB_TITLE: 'Included Works',
        DELETE_MESSAGE: 'Are you sure you want to delete an included work?',
      },
      CREATOR_AFFILIATIONS: {
        TAB_TITLE: 'Creator Affiliations',
        SOCIETY_CODE: 'Society Code',
        SOCIETY_NAME: 'Society Name',
        RIGHT_TYPE: 'Right Type',
        DELETE_MESSAGE: 'Are you sure you want to delete a creator affiliation?',
      },
      RIGHT_TYPES: {
        TAB_TITLE: 'Right Types',
        PERFORMING_RIGHT_TYPES: 'Performing Right Types',
        ONLINE_IPI_RIGHTS: 'Online IPI Rights',
        NON_ONLINE_IPI_RIGHTS: 'Non-online IPI Rights',
        MECHANICAL_RIGHT_TYPES: 'Mechanical Right Types',
        SYNCHRONISATION_RIGHT_TYPES: 'Synchronisation Right Types',
        PRINT_RIGHT_TYPES: 'Print Right Types',
      },
      ADDITIONAL_COLLECTORS: {
        TAB_TITLE: 'Additional Collectors',
        DELETE_MESSAGE: 'Are you sure you want to delete a collector?',
      },
      CONTRACTING_COMPANIES: {
        TAB_TITLE: 'Contracting Companies',
        DELETE_MESSAGE: 'Are you sure you want to delete a company?',
      },
      EXCLUDED_AGREEMENTS: {
        TAB_TITLE: 'Excluded Agreements',
        DELETE_MESSAGE: 'Are you sure you want to delete an agreement?',
      },
      EXCLUDED_WORKS: {
        TAB_TITLE: 'Excluded Works',
        DELETE_MESSAGE: 'Are you sure you want to delete an excluded work?',
      },
      COMMON: {
        NAME: 'Name',
        IPI_NAME_NUMBER: 'IPI Name Number',
        ADD: 'Add',
        DELETE: 'Delete',
      },
    },
    SOCIETIES: {
      DETAILS: {
        TAB_TITLE: 'Society Details',
        DETAILS_CARD: {
          TITLE: 'Base Information',
          SOCIETY_NAME: 'Society Name',
          SOCIETY_CODE: 'Society Code',
          IS_ICE: 'Is ICE',
          COUNTRIES: 'Countries',
        },
        RIGHTS_TABLE: {
          TITLE: 'Rights',
          VALID_FROM: 'Valid From',
          VALID_TO: 'Valid To',
          ROLE_CODES: 'Role',
          RIGHT_CODE: 'Right Code',
          RIGHT_DESCRIPTION: 'Right Description',
          TERRITORIES: 'Territory',
        },
      },
    },
    IPS: {
      DETAILS: {
        TAB_TITLE: 'IP Details',
        CARD_WITH_FORM: {
          CARD_TITLE: 'IP Base Information',
          IP_TYPE_NATURAL_PERSON: 'Natural Person',
          IP_TYPE_LEGAL_ENTITY: 'Legal Entity',
          INVALID_IPI: 'Invalid IPI',
          FORM: {
            NAME: 'Name',
            BASE_ID: 'IPI Base Number',
            IPI_NAME_NUMBER: 'IPI Name Number',
            REFERENCE: 'IP Name Key',
            IP_BASE_KEY: 'ICE IP Base Key',
            TYPE_OF_NAME: 'Name Type',
            BASE_TYPE_OF: 'IP Type',
            BASE_TYPE_NAME: 'IP Base Type',
            SEX: 'Sex',
            MALE: 'Male',
            FEMALE: 'Female',
            DATE_OF_BIRTH: 'Date of Birth',
            DATE_OF_FOUNDATION: 'Date of Foundation',
            COUNTRY_OF_BIRTH: 'Country of Birth',
            PLACE_OF_BIRTH: 'Place of Birth',
            DATE_OF_DEATH: 'Date of Death',
            DATE_OF_DISSOLUTION: 'Date of Dissolution ',
            MR: 'MR',
            PR: 'PR',
            AGREEMENTS: 'Agreements',
            WORKS: 'Works',
            TOOLTIP_AGREEMENTS_COUNT: 'Agreement count is not updated in real-time. To check the real-time agreement count, navigate to this IP’s details page',
            TOOLTIP_AGREEMENTS_COUNT_UPDATED: 'Agreements count',
            TOOLTIP_WORKS_COUNT: 'Work count is not updated in real-time. To check the real-time work count, navigate to this IP’s details page',
            TOOLTIP_WORKS_COUNT_UPDATED: 'Works count',
            STATUS: 'Status',
            REPLACED_BY: 'Replaced by this IP Base Key',
          },
          STATUS: {
            1: 'active IP',
            2: 'purchased IP',
            3: 'merged IP',
            4: 'deleted IP',
          },
        },
        CARD_WITH_DATA_TABLE_ONE: { CARD_TITLE: 'Names' },
        CARD_WITH_DATA_TABLE_TWO: {
          CARD_TITLE: 'Society Relations',
          TABLE_SCHEMA: {
            SOCIETY_CODE: 'Society Code',
            CREATION_CLASS: 'Creation Class',
            CREATION_CLASS_ITEM: {
              MW: 'Musical Works',
            },
            SOCIETY: 'Society',
            SHARE: 'Share',
            TERRITORY: 'Territory',
            VALID_FROM: 'Valid From',
            VALID_TO: 'Valid To',
            ROLE_CODE: 'Role Code',
            RIGHTS_CODE: 'Rights Code',
            NAME: 'Society Name',
          },
        },
        TRANSFER_IP_WORKS: {
          MOVE_THESE_WORKS: 'Move these works',
          SELECT_TARGET_IP: 'Select target IP',
          SELECT_WORKS_TO_MOVE: 'Select works to move',
          REVIEW_AND_CONFIRM: 'Review and confirm',
          IP_MOVE_WORK: 'IP Move Work',
          SOURCE: 'Source',
          TARGET: 'Target',
          SAME_IPI_BASE_NUMBER_FIELD_LABEL: 'Set target IP in the same IPI Base Number',
          TARGET_IP: 'Target IP',
          TARGET_IP_SEARCH_RESULTS: 'Target IP search results',
          BACK: 'Back',
          ICE_WORK_KEY: 'ICE Work Key',
          WORKS_TO_MOVE_SEARCH_RESULTS: 'Works to move search results',
          WORKS_TO_MOVE_SELECTED_WORKS: 'Selected works to move',
          SELECT_WORKS_BUTTON_TEXT: 'Add to selected works',
          SELECT_WORKS: 'Select works',
          TITLE: 'Title',
          PLEASE_REVIEW_THE_SELECTED_IPS_AND_WORKS_TO_MOVE: 'Please review the selected IPs and works to move.',
          SELECTED_WORKS_TO_MOVE: 'Selected works to move',
          CONFIRM_TRANSFER: 'Confirm transfer',
        },
      },
      AGREEMENTS: { TAB_TITLE: 'Agreements' },
      AGREEMENT_CHAINS: {
        TAB_TITLE: 'Agreement Chains',
        OWNER: 'Owner',
        REFERENCE: 'Reference',
        TYPE: 'Type',
        TERRITORY: 'Territory',
        START_DATE: 'Start Date',
        SHARES_IN: 'Shares In',
        SHARES_OUT: 'Shares Out',
        PRIOR_ROYALTIES: 'Prior Royalties',
        TOTAL: 'This agreement has {{total}} sub-agreements',
        TOTAL_UNKNOWN: 'Click on the row to get the sub-agreements',
        MAX_DEPTH: 'Maximum depth reached. We limit the depth to {{maxDepth}} levels. There might be more sub-agreements.',
      },
      WORKS: {
        TAB_TITLE: 'Works',
        TAB_TITLE_SINGULAR: 'Work',
        ROLE: {
          A: 'Author',
          AD: 'Adaptor',
          AR: 'Arranger',
          C: 'Composer',
          CA: 'Composer Author',
          E: 'Original Publisher',
          SA: 'Sub Author',
          SE: 'Sub-publisher',
          SR: 'Sub Arranger',
          TR: 'Translator',
        },
      },
      RELATIONS: {
        TAB_TITLE: 'Relationship',
        BT_SEARCH: 'Search',
        BT_CANCEL: 'Cancel',
        TOOLTIPS: {
          ADD_RELATION: 'Add Relation',
        },
        MESSAGES: {
          CHECK_DELETE: 'The relation will be removed, are your sure?',
        },
        DIALOG_TITLES: {
          REMOVE_RELATION: 'Remove Relation',
        },
        PARENT_TABLE: {
          TITLE: 'Owned by',
          NAME: 'Name',
          IPI_NAME_NUMBER: 'IPI Name Number',
          IP_NAME_KEY: 'IP Name Key',
          IPI_NAME_TYPE: 'IPI Name Type',
          IPI_BASE_NUMBER: 'IPI Base Number',
          IP_BASE_TYPE: 'IP Base Type',
          RELATIONSHIP: 'Relationship',
          AGREEMENT_CONTROLLED: 'Public Agreements Controlled',
          AGREEMENT_EXISTS: 'Public Agreements Exists',
        },
        CHILDREN_TABLE: {
          TITLE: 'Owner of',
          NAME: 'Name',
          IPI_NAME_NUMBER: 'IPI Name Number',
          IP_NAME_KEY: 'IP Name Key',
          IPI_NAME_TYPE: 'IPI Name Type',
          IPI_BASE_NUMBER: 'IPI Base Number',
          IP_BASE_TYPE: 'IP Base Type',
          RELATIONSHIP: 'Relationship',
          AGREEMENT_CONTROLLED: 'Public Agreements Controlled',
          AGREEMENT_EXISTS: 'Public Agreements Exists',
        },
      },
      XREF: {
        TAB_TITLE: 'XRef',
        XREF_TABLE: {
          TITLE: 'Ips XRef',
          SOCIETY_CODE: 'Society Code',
          SOCIETY_NAME: 'Society Name',
          SOCIETY_IP_REFERENCE: 'Society IP Reference',
          TYPE: 'Type',
          SOCIETY: 'society',
          PUBLISHER: 'publisher',
        },
      },
      CLAUSES: {
        TAB_TITLE: 'IP Clauses',
        TAB_TITLE_SINGULAR: 'IP Clause',
        ADD: 'Add IP Clauses',
        REMOVE: 'Remove IP Clauses',
        SELECT_TYPE: 'Select IP Clause Type*',
        ADD_WORK_KEY: 'Enter ICE work keys to add or select from artist',
        CHECK_DELETE: 'The IP Clause will be removed, are your sure?',
        TABLE: {
          CLAUSE_TYPE: 'Clause Type',
          CLAUSE_NAME: 'Clause Name',
          WORK_REFERENCE: 'Work Reference',
          WORK_TITLE: 'Work Title',
        },
      },
      ALTERNATIVE_NAMES: {
        TAB_TITLE: 'Alternative Names',
        ALTERNATIVE_NAME: 'Alternative Name',
        ADD: 'Add Alternative Name',
        EDIT: 'Edit Alternative Name',
        REMOVE: 'Remove Alternative Name',
        NAME: 'Name',
        FIRST_NAME: 'First Name',
        LANGUAGE: { LABEL: 'Language', OPTIONS: { AN: 'Alternative Name' } },
        CHECK_DELETE: 'The Alternative Name will be removed, are your sure?',
        EDIT_MODE: {
          NAME: 'Name *',
          FIRST_NAME: 'First Name *',
        },
      },
      LINKED_PUBLISHERS: {
        TAB_TITLE: 'Linked Publishers',
        TAB_TITLE_SINGULAR: 'Linked Publisher',
      },
      LINKED_WRITERS: {
        TAB_TITLE: 'Writers',
        TAB_TITLE_SINGULAR: 'Writer',
      },
    },
    TERRITORIES: {
      DETAILS: {
        TAB_TITLE: 'Territory details',
        CARD_WITH_FORM: {
          CARD_TITLE: 'Territory',
          FORM: {
            NAME: 'Name',
            TISAEXT: 'Extended TISA',
            TISA: 'TISA',
            TISN: 'TISN',
            CREATION_DATE: 'Date of creation',
            TERMINATION_DATE: 'Date of termination',
          },
        },
        CARD_LANGUAGE_NAMES: {
          TAB_TITLE: 'Language Names',
          LANGUAGE: 'Language',
          NAME: 'Name',
          ABBREVIATED_NAME: 'Abbreviated Name',
          OFFICIAL_NAME: 'Official Name',
          UNOFFICIAL_NAME: 'Unofficial Name',
          START: 'Start',
          END: 'End',
        },
        CARD_TITLES: {
          INC_COUNTRIES: 'Included Countries',
          PRED_TERRITORIES: 'Predecessor Territories',
          SUC_TERRITORIES: 'Successor Territories',
        },
      },
    },
    ORGANIZATIONS: {
      DETAILS: {
        TAB_TITLE: 'Organization Details',
        DETAILS_CARD: {
          TITLE: 'Details',
          NAME: 'Organization Name',
          ID: 'Organization ID',
          TYPE: 'Organization Type',
          CREATION_DATE: 'Creation Date',
          SOCIETY_STRING: 'Society',
        },
        ROLES_CARD: {
          TITLE: 'Roles',
          NAME: 'Name',
          ORGANIZATION_ID: 'Organization ID',
        },
      },
      USERS: {
        TAB_TITLE: 'Users',
      },
    },
    SHARED: {
      NOTES: {
        TAB_TITLE: 'Notes',
      },
      AUDIT_HISTORY: {
        TAB_TITLE: 'History',
        AUDIT_EVENTS_TABLE: {
          TITLE: 'Audit Events',
          DATE: 'Date',
          USER_ID: 'User Id',
          VERSION: 'Version',
          CUBE_AUDIT_ID: {
            HEADER_LABEL: 'Cube Audit ID',
            BUTTON_LABEL: 'ID',
            HOVER_LABEL: 'Copy',
            BEHALF_OF: 'on behalf of',
          },
          ALL_VERSIONS: 'ALL VERSIONS',
          ALL_USERS: 'ALL USERS',
          EVENT_DESCRIPTION: 'Event Description',
          ALL_EVENTS: '(ALL EVENTS)',
          ACTION: 'Action',
          NEW_VALUE: 'New Value',
          PREVIOUS_VALUE: 'Previous Value',
          CREATE_FROM: 'Created From',
          CREATE_FROM_SMAE_OR_BEFORE_CREATE_TO_ERROR: 'Start Date cannot be after the End Date',
          CREATE_TO: 'Created To',
          CREATE_TO_SAME_OR_AFTER_CREATE_FROM_ERROR: 'End Date cannot be before Start Date',
          NO_RESULTS: 'No results  are returned due to the History criteria not matching any of the data range selected  filters',
          MULTIPLE_CHANGES: 'Multiple Changes',
          PUBLISHER_ON_BEHALF_OF: 'IPI',
          USER: 'User',
          CHANGES: 'Changes',
          OLD_VALUE: 'Old Value',
          MISS_TEMPLATE: 'A template has not been found to display the detail of this event',
          ATTRIBUTES: 'Attributes',
          ATTRIBUTE: 'Attribute',
          SOCIETY: 'Society',
          CODE: 'Code',
          VALUE: 'Value',
          START_DATE_EMPTY_ERROR: 'A Valid Start Date must at least be entered before the  Apply Filter option is activated',
          WORK_MASTER_ID: 'Work ID',
        },
        POPUP: {
          TITLE: 'Change Detail',
          CLOSE_BUTTON: 'Close',
        },
        FILTER: {
          EVENTS_WITH_NOTE: 'Events with notes',
        },
      },
    },
    ALTERNATIVE_WORK_TITLES: 'IP Names',
    PAYMENT: 'IP Number',
    WORK_MATCHES: 'Publisher Name',
    COUNTERCLAIMS: 'Publisher ID',
    NOTES: {
      TITLE: 'Notes',
      UPDATE_NOTE: 'Update Note',
      EDIT_NOTE: 'Edit',
      DELETE_NOTE: 'Delete',
      DATATABLE: {
        TYPE: 'Note Type',
        INFORMATION: 'Note Information',
        NOTE: 'Note',
        USER: 'User',
        CREATED: 'Creation Date',
        LAST_EDIT: 'Last edit',
      },
      TOOLTIPS: {
        ADD_NOTE: 'Add note',
      },
      DIALOG_TITLES: {
        SELECT_TYPE: 'Select note type:',
        NOTE: 'Note:',
        ADD_NOTE: 'Add Note',
        EDIT_NOTE: 'Edit Note',
        REMOVE_NOTE: 'Remove Note',
        ADD_EVENT_NOTE: 'Add Event Note',
        EDIT_EVENT_NOTE: 'Edit Event Note',
        VIEW_EVENT_NOTE: 'Event Note',
      },
      BUTTONS: {
        SAVE: 'Save',
        SAVE_NOTE: 'Save Note',
        UPDATE_NOTE: 'Update Note',
        REMOVE: 'Remove',
        CANCEL: 'Cancel',
        EDIT_EVENT_NOTE: 'Edit event note',
      },
      MESSAGES: {
        CHECK_DELETE: 'The note will be removed, are your sure?',
        REQUIRED: 'This field can’t be empty',
        MAX_LENGTH: 'The maximum text length is 500 characters',
        UPDATE: 'Note has been successfully updated.',
      },
      TYPES: {
        CAS: 'NORD-DOC Case Exists',
        DIS: 'NORD-DOC Distribution Comments',
        GEN: 'General note',
        INF: 'Information',
        INS: 'Instrumental',
        MEC: 'Mechanical Rights',
        URG: 'Urgent',
        DUT: 'Duration Text',
        CPD: 'Copyright Publication Date',
        ASM: 'Alliance 7-mill Agreement',
        AMG: 'Alliance General Notes',
        PUB: 'Notified Agreement PRSfM',
        SRA: 'Single Right Agreement PRSfM',
        UND: 'Un-notified Agreement PRSfM',
        UKG: 'Un-notified UKG Agreement PRSfM',
        UKT: 'Un-notified UKT Agreement PRSfM',
        EDI: 'Role code Editor replaced',
      },
    },
    USERS: {
      ENABLE: 'Activate',
      DISABLE: 'Deactivate',
      DETAILS: {
        TAB_TITLE: 'User details',
        FORM: {
          TITLE: 'User Information',
          FIELDS: {
            USERNAME: 'Username',
            FIRST_NAME: 'First Name',
            LAST_NAME: 'Last Name',
            EMAIL: 'Email',
            ASSOCIATED_ORGANIZATION: 'Associated Organization',
            ASSOCIATED_ROLES: 'Associated Roles',
            ACTIVITY_STATUS: 'Activity Status',
            LAST_LOGIN_ATTEMPT: 'Last Login Attempt',
            CREATION_DATE: 'Creation Date',
            ASSOCIATED_ORGANIZATION_PLACEHOLDER: 'Please select associated organization',
          },
        },
      },
    },
    RETURN_TO_RESULTS: 'Return to results',
    SAVE_NEW: 'Save',
    CANCEL_NEW: 'Cancel',
    EDIT_ITEM: 'Edit',
    ADD_BUTTON: 'Add',
    REMOVE_BUTTON: 'Remove',
    'COUNTER-CLAIMS_REGISTRATION': 'COUNTERCLAIM REGISTRATION',
    'COUNTER-CLAIM-SUPPORT_REGISTRATION': 'Support Claim Process',
    'AGREEMENT-GROUP_REGISTRATION': 'NEW AGREEMENT GROUP',
    'AGREEMENT-GROUP_NEW_BTN_TITLE': 'Create a new agreement group',
    WORKS_REGISTRATION: 'WORK REGISTRATION',
    WORKS_NEW_BTN_TITLE: 'Create a new work',
    AGREEMENTS_REGISTRATION: 'AGREEMENT REGISTRATION',
    REPERTOIRES_REGISTRATION: 'REPERTOIRES REGISTRATION',
    NEW_NOTE: 'Add New Note',
    AGREEMENTS_NEW_BTN_TITLE: 'Create a new agreement',
    AGREEMENTS_EDIT_BTN_TITLE: 'Choose agreement to edit',
    EDIT_AGREEMENT: 'Search agreement',
    EDIT_AGREEMENT_MESSAGE: 'ICE reference agreement to edit',
    EDIT_AGREEMENT_SEARCH_PLACEHOLDER: 'Ice key without `ICE:` prefix',
    EDIT_AGREEMENT_INPUT_VALIDATION_MESSAGE: 'is not a valid agreement key',
    CLAIM_REGISTRATION: 'CLAIM REGISTRATION',
    USERS_REGISTRATION: 'USER REGISTRATION',
    USERS_NEW_BTN_TITLE: 'Create a new user',
    USERS_EDIT_BTN_TITLE: 'Choose user to edit',
    USERS_ACTIVATE_BTN_TITLE: 'Activate user',
    USERS_DISABLE_BTN_TITLE: 'Deactivate user',
    ASSIGN_CONFLICT: 'Assign Conflict',
    ASSIGN_ACTION: 'Assign Action(s)',
    ACTIVATE_DOWNLOAD: 'Activate Download',
    CANCEL_DOWNLOAD: 'Cancel Download',
    DOWNLOAD_DOCUMENT: 'Download Document/s',
    ACTIVATE_REJECT_DOCUMENT: 'Reject documentation',
    CANCEL_REJECT_DOCUMENT: 'Cancel Reject Document',
    SAVE_REJECT_DOCUMENT: 'Save Reject Document',
    MSG_CONFIRM_ENABLE_USER: 'Are you sure you want to activate the user?',
    MSG_CONFIRM_DISABLE_USER: 'Are you sure you want to deactivate the user?',
    PUB_NO_MERGE: 'Publisher No Merge Conflict',
    CREATOR_NO_MERGE: 'Creator No merge Conflict',
    EDIT_USERS: 'Search user',
    EDIT_USERS_MESSAGE: 'ICE reference user to edit',
    EDIT_USERS_SEARCH_PLACEHOLDER: 'Ice key without `ICE:` prefix',
    EDIT_USERS_INPUT_VALIDATION_MESSAGE: 'is not a valid user key',
    ORGANIZATIONS_REGISTRATION: 'ORGANIZATION REGISTRATION',
    ORGANIZATIONS_NEW_BTN_TITLE: 'Create a new organization',
    ORGANIZATIONS_EDIT_BTN_TITLE: 'Choose organization to edit',
    EDIT_ORGANIZATION: 'Search organization',
    EDIT_ORGANIZATION_MESSAGE: 'ICE reference organization to edit',
    EDIT_ORGANIZATION_SEARCH_PLACEHOLDER: 'Ice id without `ICE:` prefix',
    EDIT_ORGANIZATION_INPUT_VALIDATION_MESSAGE: 'is not a valid organization key',
    EDIT_MODE: 'EDIT MODE',
    COUNTERCLAIM_NEW_BTN_TITLE: 'Create a new CounterClaim',
    BT_NEW_AGREEMENT_CONFLICT: 'Create a new Agreement Conflict',
    BT_SAVE_AGREEMENT_AS_NEW: 'Save Agreement as new',
    BT_TERMINATE_CLAIM: 'TERMINATE',
    BT_SORT_CLAIMS: 'Reset sort',
    BT_DELETE_CLAIM: 'DELETE',
    BT_SET_UP_COUNTERCLAIM: 'Set-up Counterclaim',
    BT_SUPPORT_CLAIMS: 'Support claims',
    BT_REDUCE_CLAIMS: 'Reduce Claims',
    BT_WITHDRAW_CLAIMS: 'Withdraw Claims',
    BT_RESOLVE_COUNTERCLAIM: 'Resolve Counterclaim',
    BTN_NEW_CLAIM: 'Add unknown IP',
    BTN_NEW_CLAIM_EDITION: 'Create a new Claim',
    REPERTOIRES_NEW_BTN_TITLE: 'Create a new repertoire',
    RETENTION_AGREEMENT: 'Retention Agreement',
    SHOW_CONFLICT_RESULTS: 'Show results',
    RETAINED_AGREEMENT: 'Retained Agreement',
    RETAINS: 'Retains',
    IS_RETAINED_BY: 'Is retained by',
    ADD_RETENTION: 'Add Retention',
    POPUP: {
      RETRY: 'Retry',
    },
    REPORTS_REGISTRATION: 'Report Creation',
    REPORT_CUBE001: 'Create report: Work Details (IPs and Shares)',
    REPORT_CUBE012: 'Create report: List Works Header and Writer',
    REPORT_CUBE013: 'Create report: Work Details (Generate Fiche)',
    REPORTS_NEW_BT_TITLE: 'Create a new report',
    REPORTS: {
      REPORTS_SELECT_TYPE: 'Please select the Report On Demand',
      DOWNLOADING: 'Downloading...',
    },
    SHARE: 'Share',
    EDIT: 'Edit',
    START_MERGE_IP_MODE: 'Merge IP',
    MERGE_IPS: 'Merge IPs',
    CANCEL_MERGE_IP_MODE: 'Cancel merge mode',
    SOURCE_WORK_VIEW_ONLY: 'Source Work View Only',
    AUDIT_HISTORY: {
      WORK: {
        SHARE_PICTURE: {
          SUBMITTED_CLAIMS: 'Submitted Claims',
        },
      },
    },
    START_TRANSFER_IP_WORKS: 'Transfer works',
    END_TRANSFER_IP_WORKS: 'Cancel',
    GENERATE_FICHE: 'Generate Fiche',
  },
};
