import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { FuseSharedModule } from '@fuse/shared.module';
import { IceRadioButtonsSearchComponent, SearchBarComponent } from '@ice';
import { DirectivesModule } from '@ice/directives/directives.module';
import { IceMaterialModule } from '@ice/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { HotkeyModule } from 'angular2-hotkeys';

@NgModule({
  declarations: [SearchBarComponent, IceRadioButtonsSearchComponent],
  imports: [CommonModule, TranslateModule, HotkeyModule, FormsModule, FlexLayoutModule, DirectivesModule, IceMaterialModule, FormsModule, FuseSharedModule],
  exports: [SearchBarComponent, IceRadioButtonsSearchComponent],
})
export class SearchModule {}
