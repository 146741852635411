import { RouterStateSnapshot } from '@angular/router';
import { RouterUtils } from '@ice';
import * as fromRouter from '@ngrx/router-store';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { SectionRouterViews } from 'config/sections-config';
import * as fromState from '../state';
import * as fromAuth from './auth/auth.reducer';
import * as fromCopyright from './copyright/copyright.reducer';
import * as fromGlobal from './global/global.reducer';
import * as fromSearch from './search/search.reducer';
import * as fromUi from './ui/ui.reducer';

export interface RouterStateUrl {
  url: string;
  params: { [key: string]: string };
  queryParams: { [key: string]: string };
  section: string;
  view: SectionRouterViews;
  paths: string[];
}

export interface RootState {
  router: fromRouter.RouterReducerState<RouterStateUrl>;
  search: fromState.SearchState;
  auth: fromState.AuthState;
  ui: fromState.UiState;
  copyright: fromState.CopyrightState;
  global: fromState.GlobalState;
}

export const reducers: ActionReducerMap<RootState> = {
  router: fromRouter.routerReducer,
  search: fromSearch.reducer,
  auth: fromAuth.reducer,
  ui: fromUi.reducer,
  copyright: fromCopyright.reducer,
  global: fromGlobal.reducer,
};

// Feature selectors
export const getRouterFeatureState = createFeatureSelector<fromRouter.RouterReducerState<RouterStateUrl>>('router');
export const getSearchFeatureState = createFeatureSelector<fromState.SearchState>('search');
export const getAuthFeatureState = createFeatureSelector<fromState.AuthState>('auth');
export const getUiFeatureState = createFeatureSelector<fromState.UiState>('ui');
export const getCopyrightFeatureState = createFeatureSelector<fromState.CopyrightState>('copyright');
export const getGlobalFeatureState = createFeatureSelector<fromState.GlobalState>('global');

export class CustomRouterSerializer implements fromRouter.RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    return RouterUtils.extractActivatedRouterData(routerState.root);
  }
}
