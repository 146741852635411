import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { combineLatest, isObservable, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DynamicComponent } from '../dynamic-component';
import { IceComponent, IceGroupComponent } from './group-component';

@Component({
  selector: 'ice-group-component',
  templateUrl: './group-component.component.html',
  styleUrls: ['./group-component.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupComponent implements DynamicComponent, OnDestroy {
  debugUi: boolean;
  @Input() components: IceGroupComponent[];
  isObservable = isObservable;
  onDestroy: () => void;
  constructor() {
    this.debugUi = localStorage.getItem('debugUi') === 'enabled';
  }

  getFlex(group: IceComponent[], component: IceComponent): any | Observable<any> {
    const isHiddenList: Observable<boolean>[] = [];
    group.map((comp: IceComponent) => {
      if ('isHidden' in comp) {
        isHiddenList.push(comp.isHidden);
      }
    });
    if (isHiddenList.length > 0) {
      return combineLatest(isHiddenList).pipe(
        map((list: boolean[]) => {
          const count = group.length - list.filter(e => e).length;
          return count > 1 ? 100 / count - 1 : 100;
        }),
      );
    }
    return of(component.fxFlex ? component.fxFlex : component.flex ? component.flex : group.length > 1 ? 100 / group.length - 1 : 100);
  }

  ngOnDestroy() {
    if (this.onDestroy) {
      this.onDestroy();
    }
  }
}
