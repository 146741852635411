import { MatDialogRef } from '@angular/material/dialog';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as fromApiCalls from 'config/api-calls';
import { SectionCopyrightIps } from 'config/sections-config/section-copyright-ips';
import { cloneDeep, each, filter, get } from 'lodash';
import { MergeIpConfig } from 'models/copyright/ips/ips-models';
import { of } from 'rxjs';
import { map, take as rxjsTake } from 'rxjs/operators';
import * as fromRoot from 'store/root';
import { errorMessageShowtime } from 'store/root/effects/ui/show-error';
import { SOCIETY_PREFIX, PUBLISHER_PREFIX, ICE_PREFIX, IPI_PREFIX } from 'config/constants/global.constants';
import { PartyRelationType } from 'config/constants/ips.constants';
import { HttpStatusCode } from '@angular/common/http';
import { CopyrightUtils } from '../copyright/copyright.utils';
import { IpUtils } from './ip.utils';

export class MergeIpUtils {
  static getMergeIpOpenDialog({ dialog, translate, store }: MergeIpConfig): MatDialogRef<any, any> {
    let disabledSubmit = false;
    const mergeIosData$ = store.pipe(
      select(fromRoot.getMergeIpItems),
      rxjsTake(1),
      map(mergeIpItems => {
        return this.getMergeIpsTypes(mergeIpItems);
      }),
    );
    const targetSchema = this.getTargetSchema(translate);
    const mainSchema = this.getUnidentifyiedSchema(targetSchema);
    const dialogRef: MatDialogRef<any, any> = dialog.open(DialogMultiLayoutComponent, {
      data: {
        layouts: [
          {
            title: of(translate.instant('POPUP.MERGE_IP.TITLE')),
            layout: [
              {
                group: [
                  {
                    type: 'htmlTemplate',
                    config: {
                      htmlTemplate: translate.get('POPUP.MERGE_IP.SUBTITLE'),
                      htmlClass: 'ice-mb-20',
                    },
                  },
                ],
              },
              {
                group: [
                  {
                    type: 'cardWithDataTable',
                    config: {
                      title: translate.instant('POPUP.MERGE_IP.TARGET_IPI'),
                      model: mergeIosData$.pipe(map(data => data.targetItems)),
                      schema: targetSchema,
                    },
                  },
                ],
              },
              {
                group: [
                  {
                    type: 'cardWithDataTable',
                    config: {
                      title: translate.instant('POPUP.MERGE_IP.UNIDENTIFIED_IPS'),
                      model: mergeIosData$.pipe(map(data => data.unidentifiedItems)),
                      schema: mainSchema,
                    },
                  },
                ],
              },
              {
                group: [
                  {
                    type: 'formly',
                    config: {
                      model: of({}),
                      formClass: 'ice-align-right ice-mb-25',
                      formBuilder: of([
                        {
                          className: 'ice-accent ice-mr-20',
                          type: 'button',
                          templateOptions: {
                            text: translate.instant('POPUP.MERGE_IP.CANCEL'),
                            btnType: ' ice-accent',
                            materialType: 'mat-stroked-button',
                            onClick: () => dialogRef.close(),
                          },
                        },
                        {
                          className: 'ice-accent',
                          type: 'button',
                          templateOptions: {
                            text: translate.instant('POPUP.MERGE_IP.CONFIRM'),
                            color: 'accent',
                            materialType: 'mat-flat-button',
                            isDisabled: () => disabledSubmit,
                            onClick: () => {
                              disabledSubmit = true;
                              store
                                .select(fromRoot.getMergeIpItems)
                                .pipe(rxjsTake(1))
                                .subscribe(mergeIpItems => {
                                  const { targetItems: targetIps, unidentifiedItems: sourceIps } = this.getMergeIpsTypes(mergeIpItems);
                                  if (targetIps.length > 1) {
                                    disabledSubmit = false;
                                    this.showToastMessage(store, translate.instant('POPUP.MERGE_IP.TOO_MANY_BASE_IPS_ERROR'));
                                  } else {
                                    const targetIp = targetIps[0];
                                    const isSingleIpMerge = sourceIps.length === 1;
                                    each(sourceIps, sourceIp => {
                                      const { sourceObjectId, targetObjectId } = this.getMergeObjectIds(sourceIp, targetIp);
                                      const sourceObjectNs = IpUtils.getNsFromId(sourceObjectId);
                                      if (sourceObjectId) {
                                        store.dispatch(
                                          new fromRoot.StartApiCall({
                                            apiCall: fromApiCalls.getIpPartyForMerge,
                                            apiCallData: {
                                              labels: {
                                                ns: sourceObjectNs,
                                                id: sourceObjectId,
                                              },
                                            },
                                            callBack: (sourceObject, error) => {
                                              if (!sourceObject || error) {
                                                this.onMergeError(translate, store, dialogRef, sourceIp, isSingleIpMerge, error);
                                                return;
                                              }
                                              store.dispatch(
                                                new fromRoot.StartApiCall([
                                                  {
                                                    apiCall: fromApiCalls.mergeIp,
                                                    apiCallData: {
                                                      body: JSON.stringify(this.getMergeIpPayload(sourceObject, targetObjectId)),
                                                      labels: {
                                                        ns: sourceObjectNs,
                                                        id: sourceObjectId,
                                                      },
                                                    },
                                                    callBack: (mergeResult, mergeError) => {
                                                      if (mergeError) {
                                                        this.onMergeError(translate, store, dialogRef, sourceIp, isSingleIpMerge, mergeError);
                                                        return;
                                                      }
                                                      store.dispatch(
                                                        new fromRoot.Go({
                                                          path: [`${SectionCopyrightIps.domainName}/${SectionCopyrightIps.name}/${targetIp?.key}/details`],
                                                        }),
                                                      );
                                                      dialogRef.close();
                                                      store.dispatch(new fromRoot.EndMergeIpMode());
                                                    },
                                                  },
                                                ]),
                                              );
                                            },
                                          }),
                                        );
                                      }
                                    });
                                  }
                                });
                            },
                          },
                        },
                      ]),
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    });
    return dialogRef;
  }

  private static showToastMessage(store: Store<fromRoot.RootState>, message: any) {
    store.dispatch(
      new fromRoot.ShowSnackBar({
        duration: errorMessageShowtime.normal,
        message,
      }),
    );
  }

  private static getTargetSchema(translate: TranslateService) {
    return [
      { name: translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.NAME'), prop: 'fullName', sortable: false, cellClass: 'flex-1', headerClass: 'flex-1' },
      {
        name: translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.IPI_NAME_NUMBER'),
        prop: 'ipiNameNumber',
        sortable: false,
        cellClass: 'flex-1',
        headerClass: 'flex-1',
      },
      { name: translate.instant('IPS.DETAILS.CARD_WITH_FORM.FORM.REFERENCE'), prop: 'nameKey', sortable: false, cellClass: 'flex-1', headerClass: 'flex-1' },
      {
        name: translate.instant('IPS.TYPE_OF_NAME'),
        prop: 'typeOfName',
        cellClass: 'flex-1 ip-type-of-name',
        tooltip: 'tooltipTypeOfName',
        sortable: false,
        headerClass: 'flex-1',
      },
      {
        name: translate.instant('IPS.BASE_ID'),
        prop: 'baseIpiNumbersFormatted',
        icons: 'baseIpiNumbersIcons',
        cellClass: 'flex-1 ip-base-ipi-number ice-search-results-font-16',
        sortable: false,
        headerClass: 'flex-1',
      },
      {
        name: translate.instant('IPS.TYPE_OF'),
        prop: 'typeOf',
        icons: 'typeOfIcon',
        cellClass: 'flex-1 ip-type-of ice-search-results-font-16 ',
        sortable: false,
        headerClass: 'flex-1',
      },
      {
        name: translate.instant('IPS.PR'),
        prop: 'prSocietyCodeAndName',
        icons: 'prSocietyIcons',
        cellClass: 'flex-1 ip-pr',
        headerTooltip: translate.instant('IPS.PR_SOC_TOOLTIP'),
        sortable: false,
        headerClass: 'flex-1',
      },
      {
        name: translate.instant('IPS.MR'),
        prop: 'mrSocietyCodeAndName',
        icons: 'mrSocietyIcons',
        cellClass: 'flex-1 ip-mr',
        headerTooltip: translate.instant('IPS.MR_SOC_TOOLTIP'),
        sortable: false,
        headerClass: 'flex-1',
      },
    ];
  }

  private static getUnidentifyiedSchema(targetSchema: any[]) {
    const schema = filter(cloneDeep(targetSchema), item => item.prop !== 'ipiNameNumber');
    schema[0].cellClass = 'flex-1 flex-grow-2-25';
    schema[0].headerClass = 'flex-1 flex-grow-2-25';
    return schema;
  }

  private static onMergeError(translate, store, dialogRef, unidentifiedItem, isSingleIpMerge, error?) {
    if (error) {
      const message =
        get(error, 'error.statusCode') === HttpStatusCode.InternalServerError
          ? translate.instant('POPUP.MERGE_IP.API_ERROR')
          : get(error, 'error.statusMessage', translate.instant('POPUP.MERGE_IP.API_ERROR'));
      if (message) {
        this.showToastMessage(store, message);
      }
    }
    dialogRef.close();
    store.dispatch(new fromRoot.EndMergeIpMode());
    if (isSingleIpMerge) {
      store.dispatch(new fromRoot.GetItem({ key: unidentifiedItem.key }));
    }
  }

  static getMergeIpsTypes(mergeIpItems: any[]) {
    const targetItems = filter(mergeIpItems, ip => ip.ipiNameNumber);
    const unidentifiedItems = filter(mergeIpItems, ip => !ip.ipiNameNumber);
    return { targetItems, unidentifiedItems };
  }

  static getMergeObjectIds(sourceIp, targetIp) {
    // For the merge API call, the unidentified IP updated must be in the proper namespace
    // (i.e., not CUBE). Usually this namespace is the namespace of the Publisher/Society that created the IP,
    // but in the case of IPs that were migrated from the IBM system, we use the ICE namespace.
    const sourceXrefRelations = sourceIp?.baseParty?.relations.filter(rel => rel.relation === PartyRelationType.CROSS_REFERENCE);
    const soPbXrefRelationId = sourceXrefRelations.find(rel => [SOCIETY_PREFIX, PUBLISHER_PREFIX].some(ns => CopyrightUtils.getKeyPrefix(rel.otherId).includes(ns)))?.otherId;

    if (soPbXrefRelationId) {
      return {
        sourceObjectId: soPbXrefRelationId,
        targetObjectId: `${IPI_PREFIX}:${targetIp?.baseIpiNumber}`,
      };
    }

    const targetXrefRelations = targetIp?.baseParty?.relations.filter(rel => rel.relation === PartyRelationType.CROSS_REFERENCE);
    return {
      sourceObjectId: sourceXrefRelations.find(rel => CopyrightUtils.getKeyPrefix(rel.otherId).includes(ICE_PREFIX))?.otherId,
      targetObjectId: targetXrefRelations.find(rel => CopyrightUtils.getKeyPrefix(rel.otherId).includes(ICE_PREFIX))?.otherId,
    };
  }

  static getMergeIpPayload(sourceObject, targetObjectId: string) {
    return {
      ...sourceObject,
      attributes: {
        ...sourceObject.attributes,
        status: 3,
      },
      relations: [
        ...sourceObject.relations,
        {
          otherId: targetObjectId,
          relation: PartyRelationType.MERGE,
        },
      ],
    };
  }
}
