import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { environment } from 'config/env';
import flagsmith from 'flagsmith';
import { IFlagsmith } from 'flagsmith/types';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'services/auth/auth.service';
import { RootState, getUserDetail } from 'store/root';

// INFO: Feature Flag values can be changed at https://app.flagsmith.com/
@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService implements OnDestroy {
  isAgreementChainsEnabled$ = new BehaviorSubject<boolean>(false);
  agreementChainsMaxDepth$ = new BehaviorSubject<number>(5);
  showNewMergedTableSbj = new BehaviorSubject<boolean>(false);
  disableIpsMergeFab = new BehaviorSubject<boolean>(false);
  disableIpsAlternativeNamesUpdate = new BehaviorSubject<boolean>(false);
  disableIpsClausesUpdate = new BehaviorSubject<boolean>(false);
  disableCreateUserSoAdminPbAdminEditorRoles = new BehaviorSubject<boolean>(false);
  isExpertSearchExperimentalWarningEnabled$ = new BehaviorSubject<boolean>(false);
  user$: Observable<string>;

  constructor(protected http: HttpClient, protected authService: AuthService, protected store: Store<RootState>) {}

  initialize(): void {
    const typedFlagsmith = flagsmith as IFlagsmith;
    typedFlagsmith.init({
      environmentID: environment.flagsmithEnvironmentId,
      cacheFlags: false,
      enableAnalytics: false,

      onChange: () => {
        if (flagsmith.hasFeature('counterclaims-detail-show-new-merged-table')) {
          this.showNewMergedTableSbj.next(flagsmith.getValue('counterclaims-detail-show-new-merged-table'));
        }
        this.disableIpsMergeFab.next(flagsmith.hasFeature('cube-ui.ips.disable-ips-merge-fab'));
        this.disableIpsAlternativeNamesUpdate.next(flagsmith.hasFeature('cube-ui.ips.disable-alternative-names-update'));
        this.disableIpsClausesUpdate.next(flagsmith.hasFeature('cube-ui.ips.disable-ip-clauses-update'));
        this.disableCreateUserSoAdminPbAdminEditorRoles.next(flagsmith.hasFeature('cube-ui.users.disable-createuser-soc-admin-pub-admin-editor-roles'));
        this.isAgreementChainsEnabled$.next(flagsmith.hasFeature('cube-ui.ips.agreement-chains'));
        this.agreementChainsMaxDepth$.next(flagsmith.getValue('cube-ui.ips.agreement-chains.max-depth'));
        this.isExpertSearchExperimentalWarningEnabled$.next(flagsmith.hasFeature('cube-ui.search.expert-search-experimental-warning'));
      },
    });

    flagsmith.startListening(60000);

    this.store
      .pipe(
        select(getUserDetail),
        map(user => user && user.id),
      )
      .subscribe((value: string) => {
        flagsmith.identify(value);
      });
  }

  completeSubject(subject: BehaviorSubject<boolean>) {
    subject.complete();
  }

  async ngOnDestroy() {
    flagsmith.stopListening();
    this.completeSubject(this.isAgreementChainsEnabled$);
    this.completeSubject(this.showNewMergedTableSbj);
    this.completeSubject(this.disableIpsMergeFab);
    this.completeSubject(this.disableIpsAlternativeNamesUpdate);
    this.completeSubject(this.disableIpsClausesUpdate);
    this.completeSubject(this.disableCreateUserSoAdminPbAdminEditorRoles);
    this.completeSubject(this.isExpertSearchExperimentalWarningEnabled$);
    this.agreementChainsMaxDepth$.complete();
  }
}
