import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DataTableRow } from '@ice/components/data-table/data-table';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import { LinkedByType } from 'config/constants/ips.constants';
import { RootDatatable } from 'config/data-table-builders/root-datatable';

export class CopyrightLinkedDataTable extends RootDatatable {
  constructor(protected translate: TranslateService, protected fuseTranslationLoader: FuseTranslationLoaderService, protected store) {
    super(translate);
    this.fuseTranslationLoader.loadTranslations(english);
  }

  getDataTable(linkedByType, clickWorkCount: (row) => void): DataTableRow[] {
    return [
      { name: this.translate.instant(`IPS.${linkedByType}_NAME`), prop: linkedByType === LinkedByType.Publishers ? 'publisherName' : 'writerName', flexGrow: 2, filter: true },
      { name: this.translate.instant('IPS.IPI_NAME_NUMBER'), prop: 'ipiNameNumber', flexGrow: 1, filter: true },
      { name: this.translate.instant('IPS.REFERENCE'), prop: 'ipNameKey', flexGrow: 1, filter: true },
      { name: this.translate.instant('IPS.WORKS_COUNT'), prop: 'worksCount', flexGrow: 0.4, onClickAction: clickWorkCount, cellClass: 'agreements-workcount' },
    ];
  }
}
