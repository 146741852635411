import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SearchService } from 'services/search/search.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromNewSectionItem from 'store/new-section-item';
import * as fromRoot from 'store/root';
import { StepType } from '../stepper-config';
import { OrganizationStep } from './steps/organization';

// this.fuseTranslationLoader.loadTranslations(english, englishErrors);
export class OrganizationSteps {
  static getSteps(
    translate: TranslateService,
    translationLoader: FuseTranslationLoaderService,
    store: Store<fromRoot.RootState>,
    storeNewItem: Store<fromNewSectionItem.NewSectionItemState>,
    fieldValidatorService: FieldValidatorService,
    searchService: SearchService,
    dialog: MatDialog,
  ): StepType[] {
    return [new OrganizationStep(translate, translationLoader, store, storeNewItem, dialog, fieldValidatorService, searchService).getStep()];
  }
}
