import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ButtonsGroupComponent } from '@ice';
import { IceMaterialModule } from '@ice/material.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, IceMaterialModule, TranslateModule, FlexLayoutModule],
  declarations: [ButtonsGroupComponent],
  exports: [ButtonsGroupComponent],
})
export class ButtonsGroupModule {}
