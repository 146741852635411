import { UsersUtils } from '@ice/utils/users/users.utils';
import * as fromActions from 'store/root/actions';
import * as fromState from 'store/root/state';

export function reducer(state: fromState.AuthState = fromState.initialAuthState, action: fromActions.LoginActions): fromState.AuthState {
  switch (action.type) {
    case fromActions.DO_LOGIN: {
      return { ...state, loading: true };
    }

    case fromActions.GOTO_PASSWORD_STEP1:
    case fromActions.GOTO_PASSWORD_STEP2:
    case fromActions.DO_PASSWORD_STEP1_SUCCESS:
    case fromActions.DO_PASSWORD_STEP2_SUCCESS:
    case fromActions.GOTO_LOGIN: {
      return { ...state, loading: false, error: null };
    }
    case fromActions.DO_LOGIN_FAIL:
    case fromActions.RESET_DO_LOGIN_FAIL:
    case fromActions.DO_PASSWORD_STEP1_FAIL:
    case fromActions.DO_PASSWORD_STEP2_FAIL:
    case fromActions.DO_NEW_PASSWORD_FAIL: {
      return { ...state, loading: false, error: action.payload };
    }
    case fromActions.DO_PASSWORD_STEP1_SEND:
    case fromActions.DO_PASSWORD_STEP2_SEND:
    case fromActions.DO_NEW_PASSWORD: {
      return { ...state, loading: true, error: null };
    }

    case fromActions.DO_UPDATE_USER:
    case fromActions.DO_NEW_PASSWORD_SUCCESS:
    case fromActions.DO_LOGIN_SUCCESS: {
      return { ...state, error: null, loading: false, user: action.payload };
    }
    case fromActions.GET_USER_DETAILS_SUCCESS: {
      const user = { ...state.user };
      user.detail = action.payload;
      user.ns = action.payload.ns;
      return { ...state, user };
    }
    case fromActions.GET_USER_ORGANIZATIONS_SUCCESS: {
      const user = { ...state.user };
      const userOrganization = action.payload;
      const currentOrganization = userOrganization && user && UsersUtils.getCurrentOrganizationFromUser(userOrganization, user);
      user.organizations = Array.isArray(userOrganization) ? userOrganization : [userOrganization];
      if (currentOrganization) {
        user.currentOrganization = currentOrganization;
      }
      return { ...state, user };
    }
    case fromActions.GET_USER_ORGANIZATIONS_FAIL: {
      const user = { ...state.user };
      user.organizations = [];
      user.currentOrganization = null;
      return { ...state, user, error: action.payload };
    }

    case fromActions.GOTO_NEW_PASSWORD: {
      return { ...state, loading: false, error: null, newPasswordInfo: action.payload };
    }
  }
  return state;
}

export const getAuthLoadingState = (state: fromState.AuthState) => state.loading;
export const getLoginUserState = (state: fromState.AuthState) => state && state.user;
export const getLoginErrorState = (state: fromState.AuthState) => state.error;
export const getLoginNewPasswordInfoState = (state: fromState.AuthState) => state.newPasswordInfo;
