import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CognitoResult, CognitoStatus, CopyrightDetail, CopyrightDetailRequest, Credentials, NewPasswordCredentials, PasswordResetCredentials } from '@ice';
import { LocalStorageUtils } from '@ice/utils/local-storage/localstorage.utils';
import { NavigationUtils } from '@ice/utils/navigation/navigation.utils';
import { SectionItemCleaner } from '@ice/utils/section-item-cleaner';
import { UsersUtils } from '@ice/utils/users/users.utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SearchUsersService } from 'app/root/components/search-users/search-users.service';
import { locale as english } from 'assets/i18n/en/app/common/shared';
import { PB_COUNTERCLAIM, PB_EDITOR } from 'config/constants/global.constants';
import { SectionsConfig } from 'config/sections-config';
import { environment } from 'config/env';
import { get, isArray } from 'lodash';
import { of } from 'rxjs';
import { catchError, concatMap, filter, flatMap, map, mapTo, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { AuthService } from 'services/auth/auth.service';
import { DetailService } from 'services/detail/detail.service';
import { PermissionsService } from 'services/permissions/permissions.service';
import { SaveItemService } from 'services/save-item/save-item.service';
import * as fromRoot from 'store/root/actions';
import * as fromSelectors from 'store/root/selectors';
import * as fromActions from '../../actions';

@Injectable()
export class AuthEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.DO_LOGIN, fromRoot.DO_PASSWORD_STEP2_SUCCESS),
      map((action: fromRoot.DoLogin | fromRoot.DoPasswordResetStep2Success) => action.payload),
      switchMap((credentials: Credentials) => {
        return this.auth.login(credentials.username, credentials.password).pipe(
          map(result => {
            if (result?.type === CognitoStatus.newPasswordRequired) {
              return new fromRoot.GotoNewPassword(result.result);
            }
            if (result?.type === CognitoStatus.onSuccess) {
              return new fromRoot.DoLoginSuccess(result.user);
            }
          }),
          catchError(error => of(new fromRoot.DoLoginFail(error.message))),
        );
      }),
    ),
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.DO_LOGIN_SUCCESS, fromRoot.DO_NEW_PASSWORD_SUCCESS),
      withLatestFrom(this.store.pipe(select(fromSelectors.getUser))),
      map(([action, user]) => {
        const userName = (user && user.userName) || null;
        return new fromRoot.GetUserDetails({ userName });
      }),
    ),
  );

  saveRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromRoot.SaveRedirectOnLogin>(fromRoot.SAVE_REDIRECT_URL_ON_LOGIN),
        map(action => LocalStorageUtils.saveRedirectURL(action.payload)),
      ),
    { dispatch: false },
  );

  afterLoginUserTypeRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.GET_USER_ORGANIZATIONS_SUCCESS),
      withLatestFrom(this.store.pipe(select(fromSelectors.getRouterState))),
      map(([action, routerState]) => {
        const dashboardRedirectionRoles = [PB_EDITOR, PB_COUNTERCLAIM];
        const userRoles = this.permissionsService.getUserRoles();
        const redirectToDashboard = userRoles.find(role => dashboardRedirectionRoles.includes(role));
        const afterLoginPath = redirectToDashboard ? 'conflicts/counter-claims/dashboard' : '';
        const newPath = [
          routerState?.url && routerState?.url !== 'login' && routerState?.url !== 'auth' && routerState?.url !== 'forgot' && routerState?.url !== 'new-password'
            ? routerState?.url
            : afterLoginPath,
        ];
        this.store.dispatch(new fromActions.ResetSearch());
        const entryRoute = LocalStorageUtils.getRedirectURL();
        return new fromRoot.Go(entryRoute || (newPath && { path: newPath, query: routerState?.queryParams }));
      }),
    ),
  );

  passwordReset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.DO_PASSWORD_STEP1_SEND),
      map((action: fromRoot.DoPasswordReset) => action.payload),
      switchMap((email: string) => {
        return this.auth.forgotPassword(email).pipe(
          map((data: CognitoResult) => new fromRoot.GoToPasswordResetStep2(email)),
          catchError(error => of(new fromRoot.DoPasswordResetFail(error.message))),
        );
      }),
    ),
  );

  doForgotPasswordStep2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.DO_PASSWORD_STEP2_SEND),
      map((action: fromRoot.DoPasswordResetStep2Send) => action.payload),
      switchMap((creds: PasswordResetCredentials) => {
        return this.auth.confirmNewPassword(creds.email, creds.code, creds.newPassword).pipe(
          map(
            (data: CognitoResult) =>
              new fromRoot.DoPasswordResetStep2Success({
                username: creds.email,
                password: creds.newPassword,
              }),
          ),
          catchError(error => of(new fromRoot.DoPasswordResetStep2Fail(error.message))),
        );
      }),
    ),
  );

  doNewPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.DO_NEW_PASSWORD),
      map((action: fromRoot.DoNewPassword) => action.payload),
      switchMap((credentials: NewPasswordCredentials) => {
        return this.auth.newPassword(credentials).pipe(
          map((data: CognitoResult) => new fromRoot.DoNewPasswordSuccess(data.user)),
          catchError(error => of(new fromRoot.DoNewPasswordFail(error))),
        );
      }),
    ),
  );

  newUserActive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.DO_NEW_PASSWORD_SUCCESS),
      switchMap(() =>
        this.actions$.pipe(
          ofType(fromRoot.GET_USER_DETAILS_SUCCESS),
          map((action: fromRoot.GetUserDetailsSuccess) => action.payload),
          switchMap(user => {
            const { id, ns } = user;
            return this.saveItemService.postRequestCubeData<HttpResponse<any>>(`${environment.apiUrlCubeData}/users/${ns}/${id}/status/active`, {}, {}).pipe(
              startWith(new fromRoot.SetNewuserActive()),
              mapTo(new fromRoot.SetNewuserActiveSuccess()),
              catchError(error => of(new fromRoot.SetNewuserActiveFail(error))),
            );
          }),
        ),
      ),
    ),
  );

  doPasswordResetSetup2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.GOTO_PASSWORD_STEP2),
      map((action: fromRoot.GoToPasswordResetStep2) => new fromRoot.Go({ path: ['/forgot', action.payload] })),
    ),
  );

  goToNewPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.GOTO_NEW_PASSWORD),
      map(() => new fromRoot.Go({ path: ['new-password'] })),
    ),
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromRoot.LOGOUT),
        map(() => {
          this.store.dispatch(new fromActions.ResetSearch());
          this.fuseConfig.setConfig({
            layout: {
              navigation: 'none',
              toolbar: 'none',
              footer: 'none',
            },
          });
          this.auth.logout();
        }),
      ),
    { dispatch: false },
  );

  getUserDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.GET_USER_DETAILS),
      map((action: fromRoot.GetUserDetails) => action.payload),
      switchMap((userName: { userName }) => {
        const userId = UsersUtils.generateUserIdFromUserName(userName.userName);
        const request: CopyrightDetailRequest = { key: userId, section: 'users', routerPaths: ['user-management', 'users', userId, 'details'], ns: 'CUBE' };
        return this.detailService.getDetail(request).pipe(
          concatMap((detail: CopyrightDetail) => {
            const section = SectionsConfig.USERS.name;
            if (!detail['roles'] || !isArray(detail['roles']) || !UsersUtils.isValidRole(detail['roles'].map(role => `${!!role.name ? role.name : ''}`))) {
              return [new fromRoot.Logout(), new fromRoot.DoLoginFail(this.translate.instant('ERROR.SERVER.ROLE_ERROR'))];
            }
            const sectionCleaner = SectionItemCleaner[section];
            const userDetails = sectionCleaner(detail, this.translate);
            return [new fromRoot.GetUserDetailsSuccess(userDetails), new fromRoot.GetUserOrganizations(), new fromRoot.GetAllTerritories()];
          }),
          catchError(error => {
            this.store.dispatch(new fromRoot.DoLoginFail(this.translate.instant('ERROR.SERVER.GET_USER_DETAILS')));
            return of(new fromRoot.GetUserDetailsFail({ message: error.message }));
          }),
        );
      }),
    ),
  );

  getUserOrganizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.GET_USER_ORGANIZATIONS),
      withLatestFrom(this.store.pipe(select(fromSelectors.getUser)), this.store.pipe(select(fromSelectors.getRouterSection))),
      switchMap(([_, user, section]) => {
        return this.detailService.getOrganizationFromId(get(user, 'detail.organizationId', '')).pipe(map(mainOrganizationResult => [mainOrganizationResult, user, section]));
      }),
      switchMap(([userOrganization, user, section]) => {
        const currentOrganization = userOrganization && user && UsersUtils.getCurrentOrganizationFromUser(userOrganization, user);
        const typedRoles = userOrganization && user ? UsersUtils.getTypedRoles(user, currentOrganization) : [];
        this.permissionsService.setUserRoles(typedRoles);
        this.store.dispatch(new fromActions.SetNavigation(NavigationUtils.getNavigation(this.permissionsService)));
        if (!this.permissionsService.checkPermissionToNavigate(section)) {
          this.store.dispatch(
            new fromRoot.Go({
              path: [`copyright`],
            }),
          );
        }
        return [new fromRoot.GetNavigationBadges(user.userName), new fromRoot.GetUserOrganizationsSuccess(userOrganization)];
      }),
      catchError(error => {
        return of(new fromRoot.GetUserOrganizationsFail(error));
      }),
    ),
  );

  resendActivationEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.RESEND_ACTIVATION_EMAIL),
      withLatestFrom(
        this.store.pipe(
          select(fromSelectors.getUsers),
          filter(users => !!users),
        ),
        this.store.pipe(select(fromSelectors.getUserNS)),
      ),
      switchMap(([_, { id, email }, userNs]) => {
        return this.searchUsersService.userResendActivationEmail({ id, email, userNs }).pipe(mapTo([{ id, email }, userNs]));
      }),
      flatMap(([{ id, email }, userNs]: [any, any]) => {
        return [
          new fromRoot.ResendActivationEmailSuccess(id),
          new fromRoot.ShowSnackBar({
            message: `New activation email successfully sent to ${email}`,
            duration: 3000,
          }),
        ];
      }),
      catchError(error => {
        return of(
          new fromRoot.ResendActivationEmailFail(error),
          new fromRoot.ShowSnackBar({
            message: `Sending activation email to failed`,
            duration: 3000,
          }),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private auth: AuthService,
    private store: Store<fromRoot.LoginActions>,
    private detailService: DetailService,
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private fuseConfig: FuseConfigService,
    private permissionsService: PermissionsService,
    private searchUsersService: SearchUsersService,
    private saveItemService: SaveItemService,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
  }
}
