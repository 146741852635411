import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/search-form-builders';
import { DatepickerUtils } from '@ice/utils/datepicker/datepicker.utils';
import { BulkUpdateResponseType, BulkUpdateResultStatus } from 'models/copyright/bulk-updates/jobs.model';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs-compat';
import { SelectOption } from 'models/copyright/formly/formly';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { CopyrightUtils } from '@ice';
import * as fromRoot from 'store/root';
import { Store } from '@ngrx/store';
import * as fromApiCalls from 'config/api-calls';
import { get, isString, map as lodashMap } from 'lodash';
import { UsersUtils } from '@ice/utils/users/users.utils';
import { FormConfig } from './form-config';

export class SearchCopyrightBulkUpdatesForm implements FormConfig {
  private userIdsOptions$ = new Subject<any[]>();
  private unsubscribeAll = new Subject();
  constructor(private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService, private store: Store<fromRoot.RootState>) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.requestUsers();
  }
  private requestUsers({ searchText = '*', upsert = false } = {}) {
    this.store.dispatch(
      new fromRoot.StartApiCall({
        apiCall: fromApiCalls.searchUser,
        apiCallData: { labels: { ns: '*', searchText } },
        callBack: () => {
          this.store
            .select(fromRoot.getAllApiData)
            .subscribe((apiData: any) => {
              const allUsers = lodashMap(get(apiData, 'items', []), user => ({
                ...user,
                userName: UsersUtils.convertUserIDToUserName(user?.id),
                fullName: UsersUtils.composeFullName(user),
              }));
              this.userIdsOptions$.next(allUsers);
              if (upsert) {
                this.store.dispatch(new fromRoot.UpsertUsers(allUsers));
              } else {
                this.store.dispatch(new fromRoot.SaveAllUsers(allUsers));
              }
            })
            .unsubscribe();
          this.store.dispatch(new fromRoot.SaveAllApiData(undefined));
        },
      }),
    );
  }
  private getActiveUsers(translate: TranslateService, userIdsOptions$: Subject<any[]>, sortProperty: string = 'label'): Observable<SelectOption[]> {
    return userIdsOptions$.pipe(
      map(userIdsOptions => {
        const options: SelectOption[] = CopyrightUtils.sortByProperty(
          userIdsOptions.map(item => ({ value: get(item, 'userName', ''), label: get(item, 'fullName', '') })),
          sortProperty,
        );
        return options;
      }),
    );
  }
  getForm(): FormlyFieldConfig[] {
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          DatepickerUtils.getDatepickerField({
            name: 'dateFrom',
            label: this.translate.instant('BULK_UPDATES.FORM.CREATED_FROM'),
            translate: this.translate,
          }),
          DatepickerUtils.getDatepickerField({
            name: 'dateTo',
            label: this.translate.instant('BULK_UPDATES.FORM.CREATED_TO'),
            translate: this.translate,
          }),
          {
            className: 'flex-1',
            key: 'userId',
            type: 'ice-autocomplete',
            templateOptions: {
              flex: 100,
              allowAnyValue: true,
              label: this.translate.instant('BULK_UPDATES.FORM.USER_ID'),
              options: this.getActiveUsers(this.translate, this.userIdsOptions$),
              required: true,
            },
            hooks: {
              onInit: field => {
                this.store
                  .select(fromRoot.getUser)
                  .pipe(
                    map(user => {
                      return user ? { value: user.userName, label: UsersUtils.composeFullName({ attributes: user.detail }) } : null;
                    }),
                    takeUntil(this.unsubscribeAll),
                  )
                  .subscribe(currentUserOption => {
                    field.formControl.setValue(currentUserOption);
                    field.defaultValue = currentUserOption;
                  });
                field.formControl.valueChanges.pipe(debounceTime(300), takeUntil(this.unsubscribeAll)).subscribe(searchText => {
                  if (isString(searchText) && searchText.length > 2) {
                    this.requestUsers({ searchText, upsert: true });
                  }
                });
              },
              onDestroy: () => {
                this.unsubscribeAll.next();
                this.unsubscribeAll.complete();
              },
            },
          },
          {
            className: 'flex-1',
            key: 'status',
            wrappers: ['form-field'],
            type: 'select',
            defaultValue: BulkUpdateResultStatus.ALL,
            templateOptions: {
              label: this.translate.instant('BULK_UPDATES.FORM.STATUS'),
              options: [
                { label: this.translate.instant('BULK_UPDATES.FORM.ALL'), value: BulkUpdateResultStatus.ALL },
                { label: this.translate.instant('BULK_UPDATES.FORM.FAILED'), value: BulkUpdateResultStatus.FAILED },
                { label: this.translate.instant('BULK_UPDATES.FORM.RUNNING'), value: BulkUpdateResultStatus.RUNNING },
                { label: this.translate.instant('BULK_UPDATES.FORM.SUCCESS'), value: BulkUpdateResultStatus.DELIVERED_SUCCESSFULLY },
                { label: this.translate.instant('BULK_UPDATES.FORM.HAS_ERRORS'), value: BulkUpdateResultStatus.DELIVERED_WITH_ERRORS },
              ],
              required: false,
            },
          },
          {
            className: 'flex-1',
            key: 'type',
            wrappers: ['form-field'],
            type: 'select',
            defaultValue: BulkUpdateResponseType.ALL,
            templateOptions: {
              label: this.translate.instant('BULK_UPDATES.FORM.BULK_UPDATE_TYPE'),
              options: [
                { label: this.translate.instant('BULK_UPDATES.FORM.ALL'), value: BulkUpdateResponseType.ALL },
                { label: this.translate.instant('BULK_UPDATES.FORM.SWAP_IP'), value: BulkUpdateResponseType.SWAP_IP },
                { label: this.translate.instant('BULK_UPDATES.FORM.TERMINATE_IP'), value: BulkUpdateResponseType.TERMINATE_IP },
                { label: this.translate.instant('BULK_UPDATES.FORM.TRANSFER_IP'), value: BulkUpdateResponseType.TRANSFER_IP },
                { label: this.translate.instant('BULK_UPDATES.FORM.PURPOSE'), value: BulkUpdateResponseType.PURPOSE },
                { label: this.translate.instant('BULK_UPDATES.FORM.TITLES'), value: BulkUpdateResponseType.TITLES },
                { label: this.translate.instant('BULK_UPDATES.FORM.OTHER_PARTIES'), value: BulkUpdateResponseType.OTHER_PARTIES },
                { label: this.translate.instant('BULK_UPDATES.FORM.WORK_XREFS'), value: BulkUpdateResponseType.WORK_XREFS },
                { label: this.translate.instant('BULK_UPDATES.FORM.WORK_MATCHES'), value: BulkUpdateResponseType.WORK_MATCHES },
                { label: this.translate.instant('BULK_UPDATES.FORM.WORK_BROADCAST_INFO'), value: BulkUpdateResponseType.WORK_BROADCAST_INFO },
              ],
              required: false,
            },
          },
        ],
      },
    ];
  }
}
