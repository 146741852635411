import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { IceMaterialModule } from '@ice/material.module';
import { QuickPanelComponent } from 'app/root/components/quick-panel/quick-panel.component';

@NgModule({
  declarations: [QuickPanelComponent],
  imports: [RouterModule, IceMaterialModule, FuseSharedModule],
  exports: [QuickPanelComponent],
})
export class QuickPanelModule {}
