import { FormGroup } from '@angular/forms';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CopyrightUtils } from '@ice';
import { DataTableRow } from '@ice/components/data-table/data-table';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { StepType } from 'config/stepper-builders/stepper-config';
import { cloneDeep } from 'lodash';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromNewSectionItem from 'store/new-section-item';
import * as fromRoot from 'store/root';

export class TransferIpWorksReviewStep {
  constructor(
    protected translate: TranslateService,
    protected translationLoader: FuseTranslationLoaderService,
    protected store: Store<fromRoot.RootState>,
    protected storeNewItem: Store<fromNewSectionItem.NewSectionItemState>,
    protected fieldValidatorService: FieldValidatorService,
    protected closeTransferIpWorksDialog,
  ) {}

  getStep(): StepType {
    let formData: FormGroup;
    const searchIpType = 'TransferIpWorksIpSearch';

    return {
      label: this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.REVIEW_AND_CONFIRM'),
      formBuilder: [
        {
          fieldGroupClassName: 'display-flex ice-mb-20',
          fieldGroup: [
            {
              type: 'htmlTemplate',
              templateOptions: {
                config: {
                  htmlTemplate: of(`<span><b>${this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.IP_MOVE_WORK')}</b></span>`),
                },
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex ice-mb-10',
          fieldGroup: [
            {
              type: 'htmlTemplate',
              templateOptions: {
                config: {
                  htmlTemplate: of(`<span>${this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.PLEASE_REVIEW_THE_SELECTED_IPS_AND_WORKS_TO_MOVE')}</span>`),
                },
              },
            },
          ],
        },
        {
          type: 'cardWithDataTable',
          templateOptions: {
            config: {
              title: this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.TARGET_IP'),
              class: 'ice-data-table-target-ip-search-results',
              model: this.getTransferIpWorksInfo().pipe(
                map(transferIpWorksInfo => {
                  if (transferIpWorksInfo && transferIpWorksInfo?.targetIp) {
                    return cloneDeep([transferIpWorksInfo.targetIp]);
                  }
                  return [];
                }),
              ),
              schema: this.getSummaryTargetIpDataTable(),
            },
          },
        },
        {
          type: 'cardWithDataTable',
          templateOptions: {
            config: {
              titleWithCount: this.getWorksToMoveTableTitle(),
              class: 'ice-data-table-target-ip-search-results',
              model: this.getTransferIpWorksInfo().pipe(
                map(transferIpWorksInfo => {
                  if (transferIpWorksInfo && transferIpWorksInfo?.worksList) {
                    return cloneDeep(transferIpWorksInfo.worksList);
                  }
                  return [];
                }),
              ),
              schema: this.getSummarySelectedWorksDataTable(),
            },
          },
        },
      ],
      stepButtons: [
        {
          matStepperPrevious: true,
          btnType: 'ice-border-accent',
          text: this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.BACK'),
          color: 'accent',
          materialType: 'mat-button',
        },
        {
          matStepperNext: true,
          text: this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.CONFIRM_TRANSFER'),
          color: 'accent',
          materialType: 'mat-flat-button',
          onClick: () => this.confirmTransfer(),
        },
      ],
    };
  }

  getSummaryTargetIpDataTable(): DataTableRow[] {
    return [
      {
        name: this.translate.instant('IPS.NAME'),
        prop: 'fullName',
        flexGrow: 3,
        cellClass: 'ip-name',
      },
      { name: this.translate.instant('IPS.IPI_NAME_NUMBER'), prop: 'ipiNameNumber', flexGrow: 2, cellClass: 'ip-name-number' },
      { name: this.translate.instant('IPS.REFERENCE'), prop: 'nameKey', flexGrow: 1, cellClass: 'ip-name-key' },
      {
        name: this.translate.instant('IPS.TYPE_OF_NAME'),
        prop: 'typeOfName',
        flexGrow: 1,
        cellClass: 'ip-type-of-name',
        tooltip: 'tooltipTypeOfName',
      },
      {
        name: this.translate.instant('IPS.BASE_ID'),
        prop: 'baseIpiNumbersFormatted',
        icons: 'baseIpiNumbersIcons',
        flexGrow: 2,
        cellClass: 'ip-base-ipi-number ice-search-results-font-16 cursor-pointer',
      },
      {
        name: this.translate.instant('IPS.TYPE_OF'),
        prop: 'typeOf',
        icons: 'typeOfIcon',
        flexGrow: 1,
        cellClass: 'ip-type-of ice-search-results-font-16',
      },
    ];
  }

  getWorksToMoveTableTitle() {
    return this.getTransferIpWorksInfo().pipe(
      map(transferIpWorksInfo => {
        const count = transferIpWorksInfo?.worksList?.length || 0;
        return CopyrightUtils.getTitleWithCount(this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.SELECTED_WORKS_TO_MOVE'), count);
      }),
    );
  }

  getSummarySelectedWorksDataTable(): DataTableRow[] {
    return [
      { name: this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.TITLE'), prop: 'title' },
      { name: this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.ICE_WORK_KEY'), prop: 'keyWithoutPrefix' },
    ];
  }

  confirmTransfer() {
    this.closeTransferIpWorksDialog();
    this.store.dispatch(new fromRoot.ToggleTransferIpWorks({ transferIpWorksMode: false }));
  }

  getTransferIpWorksInfo() {
    return this.store.pipe(select(fromRoot.getTransferIpWorksInfo));
  }
}
