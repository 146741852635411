import { Injectable } from '@angular/core';
import { countryGroups } from 'assets/ts/countries';
import { ExpertSearchParseService } from './expert-search-parse.service';

@Injectable()
export class ExpertSearchParseSocietiesService extends ExpertSearchParseService {
  constructor() {
    const AttributesMapSocieties = {
      attributesId: 'attributes.id',
      name: 'attributes.name',
      countryCode: 'attributes.memberships.territories',
    };
    const attributesEnumMapSocieties = {
      ['countryCode']: countryGroups.reduce((prev, group) => [...prev, ...group.options.map(option => option.value)], []),
    };
    super(attributesEnumMapSocieties, AttributesMapSocieties);
  }
}
