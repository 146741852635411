import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DataTableRow, SortInfo } from '@ice/components/data-table/data-table';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import { DialogSharesUsesTypes } from 'config/dialog-builders/dialog-shares-uses-types';
import { SectionsConfig } from 'config/sections-config';
import { pickBy } from 'lodash';
import * as fromNewSectionItem from 'store/new-section-item';
import * as fromRoot from 'store/root';
import { DataTableBuilder } from './data-table-builder';
import { RootDatatable } from './root-datatable';

export class CopyrightSharesDataTable extends RootDatatable implements DataTableBuilder {
  constructor(protected translate: TranslateService, protected fuseTranslationLoader: FuseTranslationLoaderService, private store: Store<any>, private dialog?: MatDialog) {
    super(translate);
    this.fuseTranslationLoader.loadTranslations(english);
  }

  navigateToIPSTab(event) {
    if (event && event[0]) {
      if (event[0].ipiNameNumber || event[0].ipiNameKey) {
        const path = event[0].ipiNameNumber ? `IPI:${event[0].ipiNameNumber}` : `ICE:${event[0].ipiNameKey}`;
        this.store.dispatch(
          new fromRoot.Go({
            path: [`copyright/${SectionsConfig.IPS.name}/` + path],
          }),
        );
      }
    }
  }

  navigateToAgreementsTab(model) {
    if (model && model.agreementId) {
      this.store.dispatch(
        new fromRoot.Go({
          path: [`copyright/${SectionsConfig.AGREEMENTS.name}/CUBE:${model.agreementId}/details`],
        }),
      );
    }
  }

  openIpsInNewTab(event) {
    if (event && event.ipiNameNumber) {
      let path = `copyright/${SectionsConfig.IPS.name}/IPI:${event.ipiNameNumber}`;

      if (event.activatedColumn === 'agreementId' && event.agreementId) {
        path = `copyright/${SectionsConfig.AGREEMENTS.name}/CUBE:${event.agreementId}/details`;
      }

      this.store.dispatch(
        new fromRoot.OpenNewTab({
          path: [path],
        }),
      );
    }
  }

  getDefaultSorting(): SortInfo[] {
    return [];
  }

  formatSort(sort: SortInfo) {
    return { prop: '', dir: '' };
  }

  getDataTable(dataTableHeaderButtons?, editable = true, onEditStatus?, isWorkDetail = false): DataTableRow[] {
    let claimantStatusDefaultValue = 'new';
    if (!editable) {
      claimantStatusDefaultValue = '';
    }
    const dataTableSchema: DataTableRow[] = [
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_ROLE'),
        prop: 'role',
        cellClass: 'ice-cell-bold',
        flexGrow: 1.1,
        width: 80,
        comparator: (valueA, valueB) => this.generalComparator(valueA.replace(/&nbsp;/g, ''), valueB.replace(/&nbsp;/g, '')),
        tooltip: 'roleLabel',
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_NAME'),
        prop: 'name',
        flexGrow: !!onEditStatus ? 3 : 7,
        minWidth: 100,
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_IP_NAME_NUMBER'),
        prop: 'ipiNameNumber',
        flexGrow: 2.2,
        minWidth: 70,
        tooltip: (isWorkDetail && 'ipiNameNumberTooltip') || '',
        headerTooltip: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_IP_NAME_NUMBER_TOOLTIP'),
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_IP_NAME_KEY'),
        prop: 'ipiNameKey',
        flexGrow: !!onEditStatus ? 2 : 2,
        minWidth: 70,
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_IP_BASE_NUMBER'),
        prop: 'ipiBaseNumber',
        flexGrow: 2,
        minWidth: 100,
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_IP_BASE_KEY'),
        prop: 'ipiBaseKey',
        flexGrow: 2,
        minWidth: 70,
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_INCOME_PARTICIPATION'),
        prop: 'incomeParticipantIcon',
        flexGrow: 1,
        cellClass: 'ice-justify-center',
        headerClass: 'ice-justify-center',
        icons: 'incomeParticipantCheckIcons',
        minWidth: 90,
        onClickAction: row => {
          if (editable) {
            this.store.dispatch(new fromRoot.UpdateField({ object: 'incomeParticipant', newValue: { ...row, incomeParticipant: !row.incomeParticipant }, type: 'edit' }));
          }
        },
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_PR_SOCIETY'),
        prop: 'prSociety',
        icons: 'prSocietyIcons',
        flexGrow: !!onEditStatus ? 2 : 2,
        minWidth: !!onEditStatus ? 30 : 40,
        headerTooltip: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_PR_SOCIETY_TOOLTIP'),
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_MR_SOCIETY'),
        prop: 'mrSociety',
        icons: 'mrSocietyIcons',
        flexGrow: !!onEditStatus ? 2 : 2,
        minWidth: !!onEditStatus ? 30 : 40,
        headerTooltip: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_MR_SOCIETY_TOOLTIP'),
      },
      {
        name: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_AGREEMENT'),
        prop: 'agreementId',
        flexGrow: 2.2,
        minWidth: 100,
        badge: {
          text: 'agreementBadgeText',
          backgroundColor: 'black',
          textColor: 'white',
          tooltip: 'agreementBadgeTextTooltip',
        },
        onClickAction: row => this.navigateToAgreementsTab(row),
      },
      {
        name: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_STATUS'),
        prop: 'status',
        icons: 'statusIcons',
        cellClass: 'display-flex ice-justify-center',
        headerClass: 'display-flex ice-justify-center',
        flexGrow: 2.5,
        minWidth: 100,
      },
      {
        name: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_IP'),
        prop: 'refLabel',
        flexGrow: 2,
        width: 240,
        maxWidth: 240,
      },
      {
        name: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_SOCIETY'),
        prop: 'society',
        flexGrow: 2,
        width: 350,
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_PR'),
        prop: 'pr',
        cellClass: 'ice-justify-right',
        headerClass: 'display-flex ice-justify-right',
        canAutoResize: !!onEditStatus,
        resizeable: !!onEditStatus,
        width: 90,
        flexGrow: 0.7,
        comparator: (valueA, valueB) => this.numericalComparator(valueA.replace(/%/g, ''), valueB.replace(/%/g, '')),
        headerTooltip: this.translate.instant('CLAIMS.PR_SHARES_TOOLTIP'),
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_MR'),
        prop: 'mr',
        cellClass: 'ice-justify-right',
        headerClass: 'display-flex ice-justify-right',
        canAutoResize: !!onEditStatus,
        resizeable: !!onEditStatus,
        width: 90,
        flexGrow: 0.7,
        comparator: (valueA, valueB) => this.numericalComparator(valueA.replace(/%/g, ''), valueB.replace(/%/g, '')),
        headerTooltip: this.translate.instant('CLAIMS.MR_SHARES_TOOLTIP'),
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.PERFORMANCE_REPERTOIRE'),
        prop: 'prRepertoireName',
        flexGrow: 0.7,
        headerClass: 'ice-justify-center',
        cellClass: 'ice-justify-center',
        canAutoResize: false,
        resizeable: false,
        width: 90,
        onClickAction: row => {
          this.store.dispatch(
            new fromRoot.Go({
              path: [`copyright/${SectionsConfig.REPERTOIRES.name}/${row.prRepertoireId}`],
            }),
          );
        },
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.MECHANICAL_REPERTOIRE'),
        prop: 'mrRepertoireName',
        flexGrow: 0.7,
        headerClass: 'ice-justify-center',
        cellClass: 'ice-justify-center',
        canAutoResize: false,
        resizeable: false,
        width: 90,
        onClickAction: row => {
          this.store.dispatch(
            new fromRoot.Go({
              path: [`copyright/${SectionsConfig.REPERTOIRES.name}/${row.mrRepertoireId}`],
            }),
          );
        },
      },
      {
        prop: 'responseStatus',
        flexGrow: 0.5,
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.RESPONSE_STATUS'),
        headerClass: 'ice-justify-center',
        cellClass: 'ice-justify-center',
        canAutoResize: false,
        resizeable: false,
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.ALERT'),
        prop: 'alert',
        canAutoResize: !!onEditStatus,
        resizeable: !!onEditStatus,
        hideTextProperty: true,
        icons: 'alertIcon',
        width: 90,
        flexGrow: 1,
        headerTooltip: ' ',
      },
      {
        name: '',
        prop: 'rowSelected',
        flexGrow: 2,
        headerCheckboxable: false,
        checkboxable: true,
      },
      {
        name: '',
        actionButtonIcon: 'delete',
        prop: 'delete',
        flexGrow: 0.001,
        maxWidth: 50,
        minWidth: 50,
        resizeable: false,
        action: row => {
          this.store.dispatch(new fromNewSectionItem.NewItemUnselectSharePicIps(row));
        },
      },
    ];
    if (dataTableHeaderButtons) {
      return this.addDataTableHeaderButtons(dataTableSchema, dataTableHeaderButtons);
    }
    return dataTableSchema;
  }

  getDataTableRepertoire(dataTableHeaderButtons?, editable = true, onEditStatus?, isWorkDetail = false): DataTableRow[] {
    let claimantStatusDefaultValue = 'new';
    if (!editable) {
      claimantStatusDefaultValue = '';
    }
    const dataTableSchema = [
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_ROLE'),
        prop: 'role',
        cellClass: 'ice-cell-bold',
        flexGrow: 0.7,
        maxWidth: 100,
        minWidth: 50,
        comparator: (valueA, valueB) => this.generalComparator(valueA.replace(/&nbsp;/g, ''), valueB.replace(/&nbsp;/g, '')),
        tooltip: 'roleLabel',
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_NAME'),
        prop: 'name',
        flexGrow: 1,
        minWidth: 100,
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_IP_NAME_NUMBER'),
        prop: 'ipiNameNumber',
        flexGrow: 1,
        maxWidth: 170,
        minWidth: 70,
        tooltip: (isWorkDetail && 'ipiNameNumberTooltip') || '',
        headerTooltip: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_IP_NAME_NUMBER_TOOLTIP'),
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_PR_SOCIETY'),
        prop: 'prSociety',
        icons: 'prSocietyIcons',
        flexGrow: 1,
        minWidth: !!onEditStatus ? 30 : 60,
        headerTooltip: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_PR_SOCIETY_TOOLTIP'),
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_MR_SOCIETY'),
        prop: 'mrSociety',
        icons: 'mrSocietyIcons',
        flexGrow: 1,
        minWidth: !!onEditStatus ? 30 : 60,
        headerTooltip: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_MR_SOCIETY_TOOLTIP'),
      },
      {
        name: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_AGREEMENT'),
        prop: 'agreementId',
        flexGrow: 1,
        maxWidth: 170,
        minWidth: 100,
        onClickAction: row => this.navigateToAgreementsTab(row),
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_PR'),
        prop: 'pr',
        cellClass: 'ice-justify-right',
        canAutoResize: !!onEditStatus,
        resizeable: !!onEditStatus,
        width: 90,
        flexGrow: 0.7,
        comparator: (valueA, valueB) => this.numericalComparator(valueA.replace(/%/g, ''), valueB.replace(/%/g, '')),
        headerTooltip: this.translate.instant('CLAIMS.PR_SHARES_TOOLTIP'),
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.COL_MR'),
        prop: 'mr',
        cellClass: 'ice-justify-right',
        canAutoResize: !!onEditStatus,
        resizeable: !!onEditStatus,
        width: 90,
        flexGrow: 0.7,
        comparator: (valueA, valueB) => this.numericalComparator(valueA.replace(/%/g, ''), valueB.replace(/%/g, '')),
        headerTooltip: this.translate.instant('CLAIMS.MR_SHARES_TOOLTIP'),
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.PERFORMANCE_REPERTOIRE'),
        prop: 'prRepertoireName',
        flexGrow: 3,
        width: 350,
        onClickAction: row => {
          if (!!row.prRepertoireId) {
            this.store.dispatch(
              new fromRoot.Go({
                path: [`copyright/${SectionsConfig.REPERTOIRES.name}/${row.prRepertoireId}`],
              }),
            );
          }
        },
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.MECHANICAL_REPERTOIRE'),
        prop: 'mrRepertoireName',
        flexGrow: 3,
        width: 350,
        onClickAction: row => {
          if (!!row.prRepertoireId) {
            this.store.dispatch(
              new fromRoot.Go({
                path: [`copyright/${SectionsConfig.REPERTOIRES.name}/${row.mrRepertoireId}`],
              }),
            );
          }
        },
      },
      {
        name: this.translate.instant('WORKS.SHARE_PICTURE.TABLE_SCHEMA.ALERT'),
        prop: 'alert',
        canAutoResize: !!onEditStatus,
        resizeable: !!onEditStatus,
        hideTextProperty: true,
        icons: 'alertIcon',
        width: 90,
        flexGrow: 1,
        headerTooltip: ' ',
      },
    ];
    if (dataTableHeaderButtons) {
      return this.addDataTableHeaderButtons(dataTableSchema, dataTableHeaderButtons);
    }
    return dataTableSchema;
  }

  private addDataTableHeaderButtons(dataTableSchema: DataTableRow[], dataTableHeaderButtons: { prop: string; value: Object }[]) {
    return dataTableSchema.map(row => {
      const headerButton = dataTableHeaderButtons.find(button => button.prop === row.prop);
      if (headerButton) {
        return { ...row, ...headerButton.value };
      }
      return row;
    });
  }

  getColumnMode(): ColumnMode {
    return ColumnMode.flex;
  }

  public openUsesTypesDialog(dialog, translate, type, usageTypesFormControl) {
    const counterClaimUsesTypes = { ...((usageTypesFormControl && usageTypesFormControl.value) || {}) };
    const dialogRef = DialogSharesUsesTypes.openDialog(dialog, translate, type, counterClaimUsesTypes[type], (event: { [useType: string]: boolean }) => {
      counterClaimUsesTypes[type] = Object.keys(pickBy(event, value => value));
      if (usageTypesFormControl) {
        usageTypesFormControl.setValue(counterClaimUsesTypes);
      }
      dialogRef.close();
    });
  }
}
