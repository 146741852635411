import { Observable, Subject } from 'rxjs';

export interface InputUploadFileModel {
  title: string;
  placeholder?: string;
  type?: string;
  csvMaxHeightStyle?: boolean;
  fileData?: (data) => void;
  fileError?: (error: InputFileError) => void;
  message?: string;
  error?: string;
  hasError?: Observable<boolean>;
  customPreview?: Observable<any>;
  startReadingFile?: any;
  rawFile?: any;
}

export enum InputFileError {
  FILE_READ_ERROR = 'File Read Error',
  FILE_TYPE_ERROR = 'File Type Error',
}

export enum InputFileType {
  CSV = '.csv',
}
