import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ClaimantUtils, CounterClaimClaimantRelation } from '@ice';
import { CounterClaimInActive } from '@ice/components/expert-search-form/model/enums';
import { Store, select } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { ActionResponse } from 'config/constants/activity.constants';
import { CopyrightClaimsDataTable } from 'config/data-table-builders/copyright.claims';
import { StepType } from 'config/stepper-builders/stepper-config';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { filter, map, take, tap, withLatestFrom } from 'rxjs/operators';
import { FeatureFlagService } from 'services/feature-flags/feature-flags-flagsmith.service';
import { PermissionsService } from 'services/permissions/permissions.service';
import { StorageService } from 'services/storage/storage.service';
import * as fromRoot from 'store/root';

export class CounterClaimSupportSelectResolversStep {
  private schema: CopyrightClaimsDataTable;
  private checkRowsSubject: BehaviorSubject<any[]>;
  private selectedPartiesField: FormlyFieldConfig;
  private flagShowMergedTable: boolean;
  private expandTableSbj = new BehaviorSubject<boolean>(false);
  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<any>,
    private dialog: MatDialog,
    private permissionsService: PermissionsService,
    private storageService: StorageService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.schema = new CopyrightClaimsDataTable(this.translate, this.fuseTranslationLoader, this.store, this.permissionsService);
    this.checkRowsSubject = new BehaviorSubject([]);
    this.featureFlagService.showNewMergedTableSbj
      .pipe(
        tap(value => {
          this.flagShowMergedTable = value;
        }),
      )
      .subscribe();
  }

  toggleRows() {
    const currentValue = this.expandTableSbj.getValue();
    this.expandTableSbj.next(!currentValue);
  }
  getClaimsTable({
    cardTitle = this.translate.instant('COUNTER-CLAIM-SUPPORT.STEPS.PARTIES.SELECT_PARTIES'),
    configTitle = this.translate.instant('COUNTER.COUNTERCLAIM_PARTIES'),
    displayTopLevelIps = false,
    customClass = 'claim-graph',
    expandRowsEnabled = false,
    shouldAddParentRowClass = false,
    expandableProperty = null,
    detailVisibleColumns = null,
    expandableSchema = null,
    schema = this.schema.getDataTable(),
    shouldDisplay = this.flagShowMergedTable,
    expandAllRows = null,
  }) {
    return !shouldDisplay
      ? {}
      : {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-1',
              key: 'parties',
              type: 'datatable-editable',
              wrappers: ['wrapper-card'],
              templateOptions: {
                cardTitle,
                config: {
                  title: configTitle,
                  data: this.store.select(fromRoot.getCounterClaimWork).pipe(
                    filter(counterClaimWork => !!counterClaimWork && !!counterClaimWork.workClaims),
                    map(counterClaimWork =>
                      ClaimantUtils.getCounterClaimsRelatedParties(
                        ClaimantUtils.getCounterClaimParties(counterClaimWork.workClaims, counterClaimWork.participants, counterClaimWork.actions, this.translate),
                        counterClaimWork.participants,
                        this.translate,
                        displayTopLevelIps,
                      ),
                    ),
                    withLatestFrom(this.store.select(fromRoot.getUserCurrentOrganization)),
                    map(([parties, currentOrganization]) => ClaimantUtils.getUserAllowedCounterClaimParties(parties, currentOrganization)),
                  ),
                  dontStrip: true,
                  customClass,
                  expandRowsEnabled,
                  shouldAddParentRowClass,
                  expandableProperty,
                  detailVisibleColumns,
                  expandableSchema,
                  sorts: [],
                  schema,
                  visibleColumns: of([
                    'rowSelected',
                    'relation',
                    'role',
                    'name',
                    'refLabel',
                    'resolutionOwnerLabel',
                    'actionType',
                    'actionResponse',
                    'actionDeadline',
                    'actionStatus',
                  ]),
                  loadingIndicator: false,
                  isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
                  reorderable: true,
                  shadowed: false,
                  columnMode: 'flex',
                  expandAllRows,
                  selectionType: SelectionType.checkbox,
                  selected: this.checkRowsSubject,
                  rowIdentity: row => row.sortProperty,
                  onSelect: (selected: any[]) => {
                    this.selectedPartiesField.formControl.markAsTouched();
                    this.selectedPartiesField.formControl.setValue(selected);
                    this.checkRowsSubject.next(selected);
                    if (this.selectedPartiesField.form.parent.controls[1].get('files')) {
                      this.selectedPartiesField.form.parent.controls[1].get('files').updateValueAndValidity();
                    }
                  },
                  displayCheck: (row: any, column?: any, value?: any) => {
                    return (
                      row.actionId &&
                      row.relationRaw === CounterClaimClaimantRelation.RESOLVER &&
                      row.actionResponseRaw !== ActionResponse.SUPPORTED &&
                      row.actionStatusRaw !== CounterClaimInActive.CLOSED &&
                      row.userAllowed
                    );
                  },
                },
              },
            },
            {
              key: 'selectedParties',
              hooks: {
                onInit: field => (this.selectedPartiesField = field),
              },
            },
          ],
        };
  }

  getStep(translate: TranslateService): StepType {
    return {
      label: translate.instant('COUNTER-CLAIM-SUPPORT.STEPS.PARTIES.TITLE'),
      formBuilder: [
        {
          fieldGroupClassName: 'display-flex',
          hide: this.flagShowMergedTable,
          fieldGroup: [
            {
              key: 'counterclaimId',
              hooks: {
                onInit: field => {
                  this.store
                    .select(fromRoot.getRouterQueryParams)
                    .pipe(take(1))
                    .subscribe(queryParams => field.formControl.setValue(queryParams.counterclaimId || ''));
                },
              },
            },
            {
              className: 'flex-1',
              key: 'claims',
              type: 'datatable-editable',
              wrappers: ['wrapper-card'],
              templateOptions: {
                cardTitle: translate.instant('COUNTER-CLAIM-SUPPORT.STEPS.PARTIES.REVIEW_CLAIMS'),
                config: {
                  data: this.store.select(fromRoot.getCounterClaimWork).pipe(
                    filter(counterClaimWork => !!counterClaimWork && !!counterClaimWork.workClaims),
                    map(counterClaimWork => ClaimantUtils.getRelatedParties(counterClaimWork.workClaims, counterClaimWork.participants, counterClaimWork.actions, this.translate)),
                  ),
                  columnMode: ColumnMode.flex,
                  selectionType: SelectionType.checkbox,
                  dontStrip: true,
                  customClass: 'claim-graph',
                  hideEmpty: true,
                  schema: this.schema.getDataTable(),
                  visibleColumns: of([
                    'role',
                    'relation',
                    'name',
                    'refLabel',
                    'prMr',
                    'agreementId',
                    'territoriesTisa',
                    'allRights',
                    'pr',
                    'mr',
                    'startDate',
                    'endDate',
                    'postTermCollectionDate',
                    'conflict',
                  ]),
                },
              },
            },
          ],
        },
        this.getClaimsTable({ shouldDisplay: !this.flagShowMergedTable }),
        {
          key: 'btn',
          type: 'button',
          className: 'bt-detail-toggle',
          templateOptions: {
            materialType: 'mat-button',
            text: translate.instant('COUNTER.EXPAND_ROWS'),
            type: 'flat',
            icon: 'keyboard_arrow_down',
            btnType: ' ice-accent',
            iconPosition: 'right',
            dynamicallyDisplay: true,
            shouldDisplay$: combineLatest([this.featureFlagService.showNewMergedTableSbj, this.expandTableSbj.asObservable()]).pipe(map(([flag, expanded]) => !flag || !expanded)),
            onClick: () => this.toggleRows(),
          },
        },
        {
          key: 'btn',
          type: 'button',
          className: 'bt-detail-toggle',
          templateOptions: {
            materialType: 'mat-button',
            text: translate.instant('COUNTER.COLLAPSE_ROWS'),
            type: 'flat',
            icon: 'keyboard_arrow_up',
            btnType: ' ice-accent',
            iconPosition: 'right',
            dynamicallyDisplay: true,
            shouldDisplay$: combineLatest([this.featureFlagService.showNewMergedTableSbj, this.expandTableSbj.asObservable()]).pipe(map(([flag, expanded]) => !flag || expanded)),
            onClick: () => this.toggleRows(),
          },
        },
        this.getClaimsTable({
          cardTitle: this.translate.instant('COUNTER.COUNTERCLAIM_PARTIES_AND_CLAIMS'),
          configTitle: this.translate.instant('COUNTER.COUNTERCLAIM_PARTIES_AND_CLAIMS'),
          displayTopLevelIps: true,
          customClass: 'merged-table',
          expandRowsEnabled: true,
          shouldAddParentRowClass: true,
          expandableProperty: of('associatedClaims'),
          detailVisibleColumns: of(['relation', 'role', 'name', 'prMr', 'startDate', 'endDate', 'postTermCollectionDate', 'territoriesTisa', 'allRights', 'status', 'pr', 'mr']),
          schema: this.schema.getCounterClaimDetailsDataTable({ displaySelectColumn: true }),
          expandableSchema: {
            associatedClaims: this.schema.getCounterClaimDetailsDataTable({}),
          },
          shouldDisplay: this.flagShowMergedTable,
          expandAllRows: this.expandTableSbj.asObservable(),
        }),
      ],
    };
  }
}
