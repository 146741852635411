import { environment } from 'config/env';
import type { SectionConfig } from './sections-config';

export const SectionCopyrightTerritories: SectionConfig = {
  name: 'territories',
  url: '/copyright/territories',
  domainName: 'copyright',
  baseUrl: environment.baseUrl,
  apiSegment: 'territories',
  detailSegment: `territories/TISN/`,
  detailParams: { include: 'attributes' },
  filterDetailParams: ['tisa', 'tisaExt'],
  minCharsToSearch: 2,
  apiIncludes: {
    search: 'attributes',
    detail: '',
  },
  searchServerSideSortDefault: '',
  allowedActions: {
    newItem: false,
    editItem: null,
    newNote: false,
    removeItem: false,
  },
  body: '{"and":[{"equals":{"attributes.tisn":"<<territoriesTisa>>"}}]}',
};
