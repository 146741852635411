export const locale = {
  lang: 'en',
  data: {
    REQUIRED: 'Field is required',
    VALIDATING_FIELD: ' Validating field ...',
    REPEATER_ERROR: 'There are errors in the list (Click to go)',
    REPEATER_SEARCH_TITLE: 'Find in list',
    REPEATER_REQUIRED: 'Field is required',
    MAX_ITEMS_REACHED: 'Max items reached',
    DATE_FORMAT_ERROR: 'Wrong date format was entered',
    DATE_DOES_NOT_EXIST: 'Date is incorrect or invalid',
    DATE_AFTER_1999_12_31: 'Date must be after 1999-12-31',
    SINCE_DATE_SHOULD_BE_BEFORE_TO_DATE: 'Date must be before {{toDateLabel}}',
    TO_DATE_SHOULD_BE_AFTER_SINCE_DATE: 'Date must be after {{sinceDateLabel}}',
    FILTER: 'Filter constraints are violated',
    TEXT_COUNTER_TEXT: 'of {{maxCharacters}} characters',
  },
};
