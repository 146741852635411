import { SectionsConfig } from 'config/sections-config';
import { RequestType } from 'config/sections-config/api-call';
import { SectionCopyrightAgreements } from 'config/sections-config/section-copyright-agreements';
import { environment } from 'config/env';
import { createApiCall, createApiCallFromBase, isPageable, setBody, setInclude, setLabelResolver, setPageable, setResultCleaner, validateLabels } from '../api-call.factory';
import { getAuditHistory, getAuditHistoryFilterEndDate, getAuditHistoryFilterStartDate } from '../common/common.api-calls';
import * as fromCommonResultCleaners from '../common/common.result-cleaners';
import * as fromLabelResolvers from './repertoires.label-resolvers';
import * as fromResultCleaners from './repertoires.result-cleaners';

const repertoiresSection = SectionsConfig.REPERTOIRES.name;

export const updateRepertoireParties = createApiCall(`${environment.apiUrlCubeData}/repertoires/CUBE/<<id>>?mode=<<mode>>&force=true`, RequestType.PUT);

export const getExcludedWorks = createApiCall(
  `${environment.apiUrlCubeData}/${SectionsConfig.WORKS.name}/<<ns>>/search`,
  RequestType.POST,
  setInclude(`attributes{ id, genre, publicationDate, source, owner, status, duration, language, titles, tags, societyMarkers, purpose, origin },
        relations[XREF]{relation,otherId},
        contributions{contributor, role}.contributor{partyName}.partyName{attributes}.attributes{name, firstName},
        contributions.contributor.partyName.relations[XREF]{relation,otherId}, counterclaims.attributes{status}, activityTriggersCount, conflictsCount, counterclaimsCount`),
  isPageable,
  setBody(`{"or":[<<pattern>>]}`),
  setLabelResolver(repertoiresSection, fromLabelResolvers.getWorksPatternLabelResolver, fromLabelResolvers.getExcludedWorkIdsLabelResolver),
  validateLabels,
  setResultCleaner(['excludedWorks', fromResultCleaners.getExcludedWorksResultCleaner]),
);

export const getIncludedWorks = createApiCall(
  `${environment.apiUrlCubeData}/${SectionsConfig.WORKS.name}/<<ns>>/search`,
  RequestType.POST,
  setInclude(`attributes{ id, genre, publicationDate, source, owner, status, duration, language, titles, tags, societyMarkers, purpose, origin },
      relations[XREF]{relation,otherId},
      contributions{contributor, role}.contributor{partyName}.partyName{attributes}.attributes{name, firstName},
      contributions.contributor.partyName.relations[XREF]{relation,otherId}, counterclaims.attributes{status}, activityTriggersCount, conflictsCount, counterclaimsCount`),
  isPageable,
  setBody(`{"or":[<<pattern>>]}`),
  setLabelResolver(repertoiresSection, fromLabelResolvers.getWorksPatternLabelResolver, fromLabelResolvers.getIncludedWorkIdsLabelResolver),
  validateLabels,
  setResultCleaner(['includedWorks', fromResultCleaners.getExcludedWorksResultCleaner]),
);

export const getExcludedAgreements = createApiCall(
  `${environment.apiUrlCubeData}/${SectionsConfig.AGREEMENTS.name}/<<ns>>/search`,
  RequestType.POST,
  setInclude(SectionCopyrightAgreements.apiIncludes.search),
  setPageable(0, 100),
  setBody(`{"or":[<<pattern>>]}`),
  setLabelResolver(repertoiresSection, fromLabelResolvers.getExcludedAgreementsPatternLabelResolver, fromLabelResolvers.getAgreementIdsLabelResolver),
  validateLabels,
  setResultCleaner(['excludedAgreements', fromCommonResultCleaners.getAgreementsResultCleaner]),
);

export const getRepertoiresAuditHistoryFilterStartDate = createApiCallFromBase(getAuditHistory, getAuditHistoryFilterStartDate);

export const getRepertoiresAuditHistoryFilterEndDate = createApiCallFromBase(getAuditHistory, getAuditHistoryFilterEndDate);
