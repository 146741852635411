<ng-container *ngIf="config?.visible">
  <div class="progress-bar-title">
    <span *ngIf="!(config?.completed | async)">{{ config?.title }} [ {{ config?.valueItem | async }} / {{ config?.bufferValue | async }} ]</span>
    <span *ngIf="(config?.completed | async)">{{ config?.titleDone }} [ {{ config?.valueItem | async }} / {{ config?.bufferValue | async }} ]</span>
  </div>
  <div class="progress-bar">
    <mat-progress-bar
      class="progress-bar-color"
      [color]="config.color ? config.color : 'accent'"
      [bufferValue]="config?.bufferValue | async"
      [mode]="config?.mode"
      [value]="config?.value | async">
    </mat-progress-bar>
  </div>
  <div class="item-results" *ngIf="(config?.model | async) as items">
    <p *ngFor="let item of items" class="work-key"> {{ item.property }}: {{ item.key }} - <span class="item-success">{{ item.upddated }}</span></p>
  </div>
</ng-container>
