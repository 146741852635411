import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';

import { Subject } from 'rxjs';

@Component({
  selector: 'ice-typeahead-menu',
  template: `
    <div
      *ngIf="tokenType?.length > 0"
      #dropdownMenu
      class="dropdown-menu samp typeahead-dropdown-menu"
      style="font-family: Roboto, monospace !important; font-size: 12pt"
      [style.top.px]="position?.top"
      [style.left.px]="position?.left"
    >
      <a
        href="javascript:;"
        class="dropdown-item pl-2 typeahead-dropdown-item"
        (click)="selectChoice.next(choice)"
        *ngFor="let choice of tokenType; trackBy: trackById"
        [class.active]="activeChoice === choice"
        innerHTML="{{ choice | iceTypeaheadHighlight: searchText }}"
      ></a>
    </div>
  `,
  styles: [
    `
      .dropdown-menu {
        display: block;
        max-height: 200px;
        overflow-y: auto;
      }
    `,
  ],
})
export class TypeaheadMenuComponent {
  @ViewChild('dropdownMenu') dropdownMenuElement: ElementRef<HTMLUListElement>;
  position: { top: number; left: number };
  selectChoice = new Subject();
  activeChoice: any;
  searchText: string;
  choiceLoadError: any;
  choiceLoading = false;
  private _tokenType: any[];
  trackById = (_index: number, choice: any) => (typeof choice.id !== 'undefined' ? choice.id : choice);

  set tokenType(tokenType: any[]) {
    this._tokenType = tokenType;
    if (tokenType.indexOf(this.activeChoice) === -1 && tokenType.length >= 0) {
      this.activeChoice = tokenType[0];
    }
  }

  get tokenType() {
    return this._tokenType;
  }

  @HostListener('document:keydown.ArrowDown', ['$event'])
  onArrowDown(event: KeyboardEvent) {
    event.preventDefault();
    const index = this.tokenType.indexOf(this.activeChoice);
    if (this.tokenType[index + 1]) {
      this.scrollToChoice(index + 1);
    }
  }

  @HostListener('document:keydown.ArrowUp', ['$event'])
  onArrowUp(event: KeyboardEvent) {
    event.preventDefault();
    const index = this.tokenType.indexOf(this.activeChoice);
    if (this.tokenType[index - 1]) {
      this.scrollToChoice(index - 1);
    }
  }

  @HostListener('document:keydown.Enter', ['$event'])
  onEnter(event: KeyboardEvent) {
    if (this.tokenType.indexOf(this.activeChoice) > -1) {
      event.preventDefault();
      this.selectChoice.next(this.activeChoice);
    }
  }

  private scrollToChoice(index: number) {
    this.activeChoice = this._tokenType[index];
    if (this.dropdownMenuElement) {
      const ulPosition = this.dropdownMenuElement.nativeElement.getBoundingClientRect();
      const li = this.dropdownMenuElement.nativeElement.children[index];
      const liPosition = li.getBoundingClientRect();
      if (liPosition.top < ulPosition.top) {
        li.scrollIntoView();
      } else if (liPosition.bottom > ulPosition.bottom) {
        li.scrollIntoView(false);
      }
    }
  }
}
