<div class="cookie-background" *ngIf="showCookies">
  <div class="cookie-container">
    <div class="cookie-title-container">
      <div class="cookie-title">
        <span>{{ 'COOKIE_TITLE' | translate }}</span>
      </div>
      <div class="close-button" role="button" aria-label="close-cookies-banner" (click)="setAcceptCookie()">&times;</div>
    </div>
      <span class="cookie-text" [innerHTML]="'COOKIE_FED_TITLE' | translate"></span>
      <div class="cookie-text">
      <button class="necessary-cookies" (click)="setAcceptCookie()">{{ 'COOKIE_KIND_USE' | translate }}</button>
    </div>
    <div class="cookies-buttons-container">
      <button class="cookies-buttons necessary-button">
        <input type="checkbox" name="necessary-cookies-selector" class="necessary-checkbox" checked disabled />
        <label for="necessary-cookies-selector">{{ 'COOKIE_KIND' | translate }}</label>
      </button>
      <button class="cookies-buttons color-background-grey" (click)="changeCookiesDetailsVisibility()">
        <div class="button-expand-more">
          <span class="details-button-label" for="detailed-cookies">{{ 'SHOW_COOKIE_DETAILS' | translate }} </span>
          <mat-icon>{{ expandIcon }}</mat-icon>
        </div>
      </button>
    </div>
    <div class="cookies-table-container hide-cookie-detail" [ngClass]="{ 'show-cookie-detail': showCookiesDetails }">
      <ul class="tabs">
        <li class="tab min-width-100 no-right-border" [ngClass]="{ 'active-tab': firstTabActive }" (click)="showTab()">
          <span>
            {{ 'COOKIE_DECLARATION' | translate }}
          </span>
        </li>
        <li class="tab" [ngClass]="{ 'active-tab': secondTabActive }" (click)="showTab()">
          <span>{{ 'COOKIE_ABOUT' | translate }}</span>
        </li>
        <li class="tab" class="fake-border"></li>
      </ul>

      <div class="tab-content" [ngClass]="{ active: firstTabActive }">
        <div class="flex-row">
          <div class="flex-col min-width-100">
            <span class="cokies-explain-row">{{ 'COOKIE_KIND' | translate }} ({{ currentCookies.length }})</span>
          </div>
          <div class="flex-col cookies-explain-head">
            <span>
              {{ 'COOKIE_ABOUT_FEED' | translate }}
            </span>
          </div>
        </div>
        <div class="flex-row" *ngIf="currentCookies.length">
          <div class="flex-col min-width-100"></div>
          <div class="flex-col">
            <table summary="'{{ 'SHOW_COOKIE_DETAILS' | translate }}">
              <thead>
                <tr>
                  <th>{{ 'COOKIE_NAME' | translate }}</th>
                  <th>{{ 'COOKIE_PROVIDER' | translate }}</th>
                  <th>{{ 'COOKIE_PURPOSE' | translate }}</th>
                  <th>{{ 'COOKIE_EXPIRE_TIME' | translate }}</th>
                  <th>{{ 'COOKIE_TYPE' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of currentCookies">
                  <td>{{ item.cookieName }}</td>
                  <td>{{ item.provider }}</td>
                  <td>{{ item.purpose }}</td>
                  <td>{{ item.expireTime }}</td>
                  <td>{{ item.type }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div id="about-tab" class="tab-content" [ngClass]="{ active: secondTabActive }">
        <div class="flex-row">
          <span class="about-feed">{{ 'COOKIE_ABOUT_FEED' | translate }} {{ 'COOKIE_WILL_NOT_WORK' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
