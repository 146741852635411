import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DataTable } from '@ice/components/data-table/data-table';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { IceForm } from '@ice/dynamic-components/group-component/group-component';
import { SearchUtils } from '@ice/utils/search/search.utils';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DataTableBuilder } from 'config/data-table-builders/data-table-builder';
import { SearchExpressions } from 'config/search-form-builders/form-config';
import { ApiCall } from 'config/sections-config/api-call';
import { isArray } from 'lodash';
import { of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { SearchService } from 'services/search/search.service';
import * as fromRoot from 'store/root';

export const DIALOG_FLOATING_BT = 'fab';
export const DIALOG_NORMAL_BT = 'normal';
export const DIALOG_NO_BT = 'no buttons';

export class DialogSearchDatatable {
  constructor(private dialog: MatDialog, private store: Store<any>, private searchService: SearchService, private translate: TranslateService) {}

  openDialog(
    title: string,
    dataTable: DataTableBuilder,
    apiCall: ApiCall,
    form: IceForm,
    searchExpressions: SearchExpressions,
    onSelectSearchItem,
    getSubmitModel?: (model) => any,
    cleanDatatable?: (items: any[]) => any[],
  ): MatDialogRef<DialogMultiLayoutComponent, any> {
    let formModel = getSubmitModel ? getSubmitModel({}) : {};
    const dialogRef = this.dialog.open(DialogMultiLayoutComponent, {
      data: {
        className: 'dialog-wrapper-width-100vw dialog-search',
        layouts: [
          {
            actions: [{ tooltip: this.translate.instant('POPUP.CANCEL'), color: 'warn', nextLayout: 0, icon: 'close', onClick: () => dialogRef.close() }],
            title: of(title),
            layout: [
              {
                group: [
                  ...((form && [
                    {
                      type: 'formly',
                      config: {
                        ...form,
                        submit: event => {
                          formModel = getSubmitModel ? getSubmitModel(event) : event;
                          this.store.dispatch(
                            new fromRoot.StartApiCall({
                              apiCall,
                              apiCallData: { body: JSON.stringify(SearchUtils.generateExpressionCubeData(formModel, searchExpressions, null)) },
                            }),
                          );
                        },
                      },
                    },
                  ]) ||
                    []),
                ],
              },
              {
                group: [
                  {
                    type: 'dataTable',
                    config: {
                      ...this.getSearchDatatable(dataTable, onSelectSearchItem, cleanDatatable),
                      apiCall: () => ({ apiCall, apiCallData: { body: JSON.stringify(SearchUtils.generateExpressionCubeData(formModel, searchExpressions, null)) } }),
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => this.store.dispatch(new fromRoot.ResetDialogSearchData()));
    return dialogRef;
  }

  getSearchDatatable(datatable, onSelectSearchItem, cleanDatatable): DataTable {
    return {
      data: this.store.select(fromRoot.getCopyrightDialogSearchData).pipe(map(result => (cleanDatatable ? cleanDatatable(result?.items) : result?.items))),
      isLoadingData: this.store.select(fromRoot.getCopyrightLoading),
      schema: datatable.getDataTable(),
      sorts: datatable.getDefaultSorting(),
      columnMode: datatable.getColumnMode(),
      messages: datatable.getMessages(),
      onSelect: event => onSelectSearchItem((isArray(event) && event[0]) || event),
      sortConfig: { format: sort => datatable.formatSort(sort) },
    };
  }
}
