import { Injectable } from '@angular/core';
import { CurrentOrganization, SearchUtils } from '@ice';
import { select, Store } from '@ngrx/store';
import { SectionsConfig } from 'config/sections-config';
import { from, Observable, of } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { ExpertSearchParseService } from 'services/search/expert-search-parse.service';
import * as fromRoot from 'store/root';
import { ExpertSearchParseActivityService } from './expert-search-parse-activity.service';
import { ExpertSearchParseAgreementConflictsService } from './expert-search-parse-agreement-conflicts.service';
import { ExpertSearchParseAgreementGroupService } from './expert-search-parse-agreement-group.service';
import { ExpertSearchParseAgreementsService } from './expert-search-parse-agreements.service';
import { ExpertSearchParseCounterClaimsActionsService } from './expert-search-parse-counter-claims-actions.service';
import { ExpertSearchParseCounterClaimsService } from './expert-search-parse-counter-claims.service';
import { ExpertSearchParseEmptyService } from './expert-search-parse-empty.service';
import { ExpertSearchParseIpsService } from './expert-search-parse-ips.service';
import { ExpertSearchParseOrganizationsService } from './expert-search-parse-organizations.service';
import { ExpertSearchParseRepertoiresService } from './expert-search-parse-repertiores.service';
import { ExpertSearchParseReportsService } from './expert-search-parse-reports.service';
import { ExpertSearchParseSocietiesService } from './expert-search-parse-societies.service';
import { ExpertSearchParseUsersService } from './expert-search-parse-users.service';
import { ExpertSearchParseWorksService } from './expert-search-parse-works.service';

@Injectable()
export class ExpertSearchService {
  constructor(
    private parserWorks: ExpertSearchParseWorksService,
    private parserAgreements: ExpertSearchParseAgreementsService,
    private parserAgreementGroup: ExpertSearchParseAgreementGroupService,
    private parserIps: ExpertSearchParseIpsService,
    private parserRepertoires: ExpertSearchParseRepertoiresService,
    private parserAgreementConflicts: ExpertSearchParseAgreementConflictsService,
    private parserSocieties: ExpertSearchParseSocietiesService,
    private parserCases: ExpertSearchParseActivityService,
    private parserCounterClaimsActions: ExpertSearchParseCounterClaimsActionsService,
    private parserOrganizations: ExpertSearchParseOrganizationsService,
    private parserUsers: ExpertSearchParseUsersService,
    private parserEmpty: ExpertSearchParseEmptyService,
    private parserCounterClaims: ExpertSearchParseCounterClaimsService,
    private parserReports: ExpertSearchParseReportsService,
    protected store: Store<fromRoot.RootState>,
  ) {}

  forSection(sectionName?: string): ExpertSearchParseService {
    switch (sectionName) {
      case SectionsConfig.WORKS.name:
        return this.parserWorks;
      case SectionsConfig.IPS.name:
        return this.parserIps;
      case SectionsConfig.REPERTOIRES.name:
        return this.parserRepertoires;
      case SectionsConfig.AGREEMENTS.name:
        return this.parserAgreements;
      case SectionsConfig.AGREEMENT_GROUP.name:
        return this.parserAgreementGroup;
      case SectionsConfig.SOCIETIES.name:
        return this.parserSocieties;
      case SectionsConfig.SOCIETIES.name:
        return this.parserSocieties;
      case SectionsConfig.CONFLICTS.name:
        return this.parserCases;
      case SectionsConfig.AGREEMENTCONFLICT.name:
        return this.parserAgreementConflicts;
      case SectionsConfig.COUNTERCLAIMS.name:
        return this.parserCounterClaims;
      case SectionsConfig.ORGANIZATIONS.name:
        return this.parserOrganizations;
      case SectionsConfig.USERS.name:
        return this.parserUsers;
      case SectionsConfig.REPORTS.name:
        return this.parserReports;
      case SectionsConfig.COUNTERCLAIMS_ACTIONS.name:
        return this.parserCounterClaimsActions;
      default:
        return this.parserEmpty;
    }
  }

  parseSearchQuery(payload, section, organizations): Observable<any> {
    if (payload.search.query) {
      const { query } = payload.search;
      delete payload.search.query;
      const queryLanguageParser = this.forSection(section);
      const queryWithoutBreakLines = SearchUtils.removeStringFromString(query, '\n');
      const queryNoRepeated = SearchUtils.removeQuerySearchRepeatedElements(queryWithoutBreakLines);
      return from(queryLanguageParser.parseSearchQuery(queryNoRepeated, true)).pipe(
        withLatestFrom(this.store.pipe(select(fromRoot.getUserCurrentOrganization))),
        map(([{ expression }, currentOrganization]) => {
          if (query && section === SectionsConfig.USERS.name) {
            let cleanExpression = expression;
            if (expression?.and && expression?.and.length > 0) {
              cleanExpression = this.checkOrganizationIdToReplace(expression, currentOrganization);
            }
            const expertQueryParamsWithOrganizationFilter = {
              and: [cleanExpression, { or: organizations.map(({ id }) => ({ equals: { 'attributes.ns': id.split(':')[1] } })) }],
            };
            return [{ ...payload, search: { ...payload.search, expertQueryParams: expertQueryParamsWithOrganizationFilter } }, section];
          }
          return [{ ...payload, search: { ...payload.search, expertQueryParams: expression } }, section];
        }),
      );
    }
    return of([payload, section]);
  }

  checkOrganizationIdToReplace(expression: any, currentOrganization: CurrentOrganization): any {
    expression.and = expression?.and?.map(condition => {
      const query = condition['equals'] || condition['wildcard'];
      const value = query && query['roles.organizationId'];
      if (value && currentOrganization?.id !== 'ICE:ICE') {
        (condition['equals'] || condition['wildcard'])['roles.organizationId'] = (condition['equals'] || condition['wildcard'])['roles.organizationId'].replace(
          /'([^]*)'/g,
          `'${currentOrganization.id}'`,
        );
      }
      return condition;
    });
    return expression;
  }
}
