import { createSelector } from '@ngrx/store';
import { UserCleaned } from 'models/users/users.model';
import * as fromFeature from 'store/root/reducers';
import * as fromReducer from 'store/root/reducers/copyright/copyright.reducer';

export const getUsers = createSelector(fromFeature.getCopyrightFeatureState, fromReducer.getUsersState);

export const getUserDetailId = createSelector(getUsers, (user: UserCleaned) => (user && user.id) || '');

export const getUserDetailStatus = createSelector(getUsers, (user: UserCleaned) => user && user.status);
