import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { StepType } from 'config/stepper-builders/stepper-config';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromNewSectionItem from 'store/new-section-item';
import * as fromRoot from 'store/root';
import { TransferIpWorksMoveStep } from './steps/transfer-ip-works-move.step';
import { TransferIpWorksReviewStep } from './steps/transfer-ip-works-review.step';
import { TransferIpWorksSelectTargetStep } from './steps/transfer-ip-works-select-target.step';

export class DialogTransferIpWorksSteps {
  static getSteps(
    translate: TranslateService,
    translationLoader: FuseTranslationLoaderService,
    store: Store<fromRoot.RootState>,
    storeNewItem: Store<fromNewSectionItem.NewSectionItemState>,
    fieldValidatorService: FieldValidatorService,
    sourceIp: any,
    closeTransferIpWorksDialog: any,
  ): StepType[] {
    return [
      new TransferIpWorksSelectTargetStep(translate, translationLoader, store, storeNewItem, fieldValidatorService, sourceIp, closeTransferIpWorksDialog).getStep(),
      new TransferIpWorksMoveStep(translate, translationLoader, store, storeNewItem, fieldValidatorService).getStep(),
      new TransferIpWorksReviewStep(translate, translationLoader, store, storeNewItem, fieldValidatorService, closeTransferIpWorksDialog).getStep(),
    ];
  }
}
