import { SortInfo } from '@ice/components/data-table/data-table';
import { Observable } from 'rxjs';

export enum SharePictureTypes {
  MANUSCRIPT = 'manuscript',
  OWNERSHIP = 'ownership',
  PAYMENT = 'payment',
  CLAIMS = 'claims',
  DATA = 'data',
  COUNTER_CLAIM = 'counter_claim',
}
export type SharePictureType = `${SharePictureTypes}`;

export type SharesPictureTypeMap<T> = {
  [key in SharePictureType]?: T;
} & {
  default: T;
};

export interface SharesTree {
  model: SharesPictureTypeMap<Observable<any[]>>;
  levels: SharesPictureTypeMap<Observable<any>>;
  visibility: SharesPictureTypeMap<Observable<boolean>>;
}

export interface SharesTable {
  model: SharesPictureTypeMap<Observable<any[]>>;
  visibleColumns: SharesPictureTypeMap<Observable<string[]>>;
  schema: any;
  sort?: any;
  sorts?: SortInfo[];
  onSort?(event: any): void;
  sortReset?: boolean;
  disabledSort?: Observable<boolean>;
  shadowed?: boolean;
  isSelectable?: boolean;
  class?: SharesPictureTypeMap<string>;
  selectableClass?: string;
  select: SharesPictureTypeMap<SharePictureTableSelectFunctions>;
  selectionType?: Observable<string>;
  requestStatus?: Observable<string>;
}

export interface SharePictureTableSelectFunctions {
  onSelect?: (data: any) => void;
  onMouseSelect?: (data: any) => void;
}

export interface SharesFilter {
  model: Observable<any>;
  resetAvailable?: boolean;
  submitAvailable?: boolean;
  submitShortcutEnable?: boolean;
  formInline?: boolean;
  formBuilder: any;
  submitLabel?: string;
  extraActionAvailable?: Observable<boolean>;
  extraActionLabel?: string;
  extraActionShowOn?: SharesPictureTypeMap<boolean>;
  forceSubmitDisabled?: SharesPictureTypeMap<boolean>;
  className?: string;
  formClass?: string;
  avoidResetModel?: boolean;
  onExtraActionClick?: (data: any) => void;
  onSubmit?: (data: any) => void;
  onReset?: (data: any) => void;
}
