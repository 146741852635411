import { FormControl } from '@angular/forms';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CopyrightUtils } from '@ice';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import { StepType } from '../../stepper-config';

export class ReportListWorksStep {
  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<any>,
    private fieldValidatorService: FieldValidatorService,
  ) {}

  onInitForm(field) {}
  onDestroyForm() {}

  getStep(translate: TranslateService): StepType {
    return {
      label: translate.instant('REPORTS.REPORTS_LIST.LABEL'),
      formBuilder: [
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              key: 'agreementid',
              wrappers: ['form-field', 'wrapper-input-text'],
              type: 'input',
              className: 'flex-1',
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                required: false,
                label: translate.instant('REPORTS.REPORTS_LIST.FIELD'),
                change: (field, event) => field.formControl.setValue(CopyrightUtils.getKeySuffix(get(event, 'target.value', ''))),
              },
              asyncValidators: this.agreementValidator(),
            },
            { className: 'flex-1', template: '<div></div>' },
            { className: 'flex-1', template: '<div></div>' },
            { className: 'flex-1', template: '<div></div>' },
          ],
        },
      ],
    };
  }

  agreementValidator() {
    return {
      agreementIdValidator: {
        expression: (control: FormControl) =>
          new Promise(resolve =>
            control.value && control.value.length
              ? this.fieldValidatorService.existAgreementRefKey(
                  control,
                  () => resolve(true),
                  () => resolve(false),
                )
              : resolve(false),
          ),
        message: () => this.translate.instant('REPORTS.IPS_SHARES.ERROR_AGREEMENT_NUMBER'),
      },
    };
  }
}
