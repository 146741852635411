<mat-form-field attr.data-testid="input-wrapper-{{ field.key }}" style="width: 100%">
  <div #container *ngIf="to.disabled">
    <span
      #spanItem
      class="input-disabled-label"
      [matTooltip]="field?.model && field.model[to.tooltipField || field.key]"
      [matTooltipPosition]="'above'"
      [matTooltipClass]="'ice-tooltip ice-tooltip-input ' + to.tooltipClass"
      [matTooltipDisabled]="!field?.model[to.tooltipField || field.key]"
      [ngStyle]="{ 'max-width': container.offsetWidth + 'px' }"
      >{{ formControl.value }}</span
    >
  </div>
  <div
    [matTooltip]="field?.model && field.model[to.tooltipField || field.key]"
    [matTooltipPosition]="'above'"
    [matTooltipClass]="'ice-tooltip ice-tooltip-input ' + to.tooltipClass"
    [matTooltipDisabled]="to.hideTooltip || (!to.disabled && !field?.model[to.tooltipField || field.key])"
  >
    <input
      matInput
      [id]="id"
      [required]="to.required"
      [type]="to.type || 'text'"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [step]="to.step || '1'"
      [placeholder]="to.placeholder"
      [maxLength]="field.templateOptions.maxLength || 1000"
      title="{{ to.tooltip }}"
      [ngClass]="to.disabled ? 'ice-display-none' : ''"
      attr.data-testid="input-{{ field.key }}"
    />
  </div>
  <mat-error [id]="null">
    <formly-validation-message [field]="field"></formly-validation-message>
  </mat-error>
</mat-form-field>
