import { CopyrightEffects } from 'store/root/effects/copyright/copyright.effects';
import { AuthEffects } from './auth/auth.effects';
import { RouterEffects } from './router.effects';
import { SearchEffects } from './search/search.effects';
import { UserInterfaceEffects } from './ui/ui.effects';

export const effects: any[] = [SearchEffects, AuthEffects, RouterEffects, UserInterfaceEffects, CopyrightEffects];

export * from './search/search.effects';
export * from './auth/auth.effects';
export * from './router.effects';
export * from './ui/ui.effects';
export * from './copyright/copyright.effects';
