import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseWidgetModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { WidgetSwitchComponent } from '@ice';
import { IceMaterialModule } from '@ice/material.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [WidgetSwitchComponent],
  imports: [CommonModule, TranslateModule, FuseSharedModule, FlexLayoutModule, FuseWidgetModule, IceMaterialModule],
  exports: [WidgetSwitchComponent],
})
export class WidgetSwitchModule {}
