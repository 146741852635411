<button
  mat-raised-button
  class="mat-slide-toggle-either-or z-100 ice-ml-24 ice-text-button"
  [disabled]="model.disabled | async"
  (click)="switchFace(!front)"
  [ngClass]="{ disabled: fixedFace === 'front' || fixedFace === 'back' }"
>
  {{ slideCaption }}
  <mat-icon *ngIf="!front" class="material-icons-outlined ice-ml-8">{{ model.frontIcon }} </mat-icon>
  <mat-icon *ngIf="front" class="material-icons-outlined ice-ml-8">{{ model.backIcon }} </mat-icon>
</button>
<fuse-widget #fuseWidget class="ice-widget-switch" [ngClass]="{ true: model.cssClass, 'top-negative': !front }" fxLayout="column" fxFlex="100">
  <!-- Front -->
  <div class="fuse-widget-front">
    <button fuseWidgetToggle #frontButton class="fxHide"></button>
    <ng-content select=".widget-switch-front"></ng-content>
  </div>
  <!-- Back -->
  <div class="fuse-widget-back">
    <button fuseWidgetToggle #backButton class="fxHide"></button>
    <ng-content select=".widget-switch-back"></ng-content>
  </div>
</fuse-widget>
