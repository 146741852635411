import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FooterButtonOptions, FooterOptions } from './../data-table';

@Component({
  selector: 'ice-data-table-footer-content',
  templateUrl: './data-table-footer-content.component.html',
  styleUrls: ['./data-table-footer-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DataTableFooterContentComponent {
  constructor() {}

  @Input() footerOptions: FooterOptions;

  buttonDisabled(buttonOptions: FooterButtonOptions): boolean {
    return buttonOptions.enabledCondition && !buttonOptions.enabledCondition();
  }
}
