<div class="dialog-wrapper" #popupHeader>
  <div class="dialog-title" *ngIf="!data.hideHeader">
    <h2 mat-dialog-title>{{ data.title | async }}</h2>
    <button *ngIf="!dialogRef.disableClose" mat-icon-button mat-dialog-close class="close-button" matTooltip="{{ 'CLOSE' | translate }}" matTooltipPosition="above">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content
    id="ice-dialog-content"
    [ngClass]="{ 'ice-dialog-content-buttons-margin': (!data.button1Available || !data.submitAvailable) && !data.noMargin }"
    #contentContainer
  >
    <!-- MESSAGE -->
    <div *ngIf="data.message && !(data.responseMessage | async)" fxLayout="row" fxLayoutAlign="initial center" fxLayoutGap="5px" class="ice-mt-5 ice-mb-5">
      <mat-icon>{{ data.messageIcon || 'info' }}</mat-icon>
      <span>{{ data.message }}</span>
    </div>
    <!-- LOADING -->
    <!-- RL -->
    <h3 *ngIf="(data.loading | async) && (data.sendingText | async)" class="sending-text" [innerHTML]="(data.sendingText | async) + '...'"></h3>
    <div class="spinner-container" *ngIf="data.loading | async">
      <mat-spinner></mat-spinner>
    </div>
    <!-- RESPONSE AND ERROR MESSAGES -->
    <div class="end-message" *ngIf="(data.responseMessage | async) && !(data.loading | async)">
      <span *ngIf="(data.responseMessage | async).text" [innerHTML]="(data.responseMessage | async).text" class="end-message-text"></span>
      <a *ngIf="(data.responseMessage | async).linkText" [className]="'mat-tab-link response-link-action'" (click)="onResponseLinkClick()">{{
        (data.responseMessage | async).linkText
      }}</a>
      <ul *ngIf="data.errors | async; let dataError">
        <ng-container *ngIf="isArray(dataError)">
          <li class="ice-error-msg" *ngFor="let error of dataError">{{ error }}</li>
        </ng-container>
        <ng-container *ngIf="!isArray(dataError)">
          <li class="ice-error-msg">{{ dataError }}</li>
        </ng-container>
      </ul>
    </div>
    <!-- FORM -->
    <ice-form
      *ngIf="(data.model | async) && !(data.loading | async) && !(data.responseMessage | async)"
      [resetAvailable]="data.resetAvailable"
      [submitAvailable]="data.submitAvailable"
      [button1Available]="data.button1Available"
      [form]="form"
      [formBuilder]="formBuilder"
      [model]="data.model | async"
      [submitLabel]="data.submitLabel"
      [button1Label]="data.button1Label"
      [button1Enabled]="data.button1Enabled"
      [submitEnabled]="data.submitEnabled"
      [submitShortcutEnable]="data.submitShortcutEnable"
      (submit)="data.onSubmit($event)"
      (button1Clicked)="data.onButton1($event)"
    >
    </ice-form>
    <!-- SELECT -->
    <div class="cancel-sending-button-wrapper">
      <button mat-button color="warn" *ngIf="!!data.cancelSending" (click)="data.cancelSending()">
        {{ data.cancelSendingTitle }}
      </button>
    </div>
    <mat-select attr.data-testid="select-dialog" placeholder="Select" (selectionChange)="onSelectOption($event)" [(value)]="selected" *ngIf="data.datatableFilter">
      <mat-option *ngFor="let option of data.datatableFilter" [value]="option.value">
        {{ option.viewValue }}
      </mat-option>
    </mat-select>
    <!-- FILTER INPUT-->
    <input matInput type="text" *ngIf="showFilter" class="input-filter" placeholder="{{ 'FILTER_TEXT' | translate }}" (keyup)="onUpdateFilter($event)" />
    <!-- DATATABLE -->
    <ng-container *ngIf="!data.datatable?.hidden || (data.datatable?.hidden | async)">
      <ice-data-table
        *ngIf="data.datatable; let datatable"
        [data]="filteredDatatableModel"
        [sorts]="datatable.sorts"
        class="search-result-table"
        [sliceLimit]="datatable.sliceLimit"
        [schema]="datatable.schema"
        [shadowed]="datatable.shadowed"
        [columnMode]="datatable.columnMode ? datatable.columnMode : 'force'"
        [selectionType]="datatable.selectionType || 'single'"
        [isSelectable]="datatable.isSelectable || datatable.onMouseSelect"
        [visibleColumns]="datatable.visibleColumns"
        [selectedItemsInDialog]="data.selectedItemsInDialog | async"
        [selectedKey]="data.selectedKey"
        [rowIdentity]="datatable.rowIdentity"
        [selectedFilter]="selected"
        [headerTitle]="datatable.headerTitle"
        [headerClass]="datatable.headerClass"
        (selectedRowEmit)="selectSearchItem($event)"
        (sortRowEmit)="onSort($event)"
      ></ice-data-table>
    </ng-container>
  </mat-dialog-content>

  <ng-container *ngIf="!(data.loading | async)">
    <mat-dialog-actions *ngIf="data.ok || data.startButtons || data.endButtons" class="basic-buttons">
      <div class="start-buttons" *ngIf="!(data.loading | async) && !(data.responseMessage | async)">
        <button mat-button mat-dialog-close *ngIf="!data.ko_icon && data.ko" class="ko-button" data-testid="ko-button">{{ data.ko }}</button>
        <button
          mat-button
          *ngIf="!data.ok_icon && data.ok"
          (click)="onSubmit($event, true)"
          [ngClass]="{ 'ok-button': form.valid, 'ok-button-disabled': !form.valid }"
          [disabled]="!form.valid"
          data-testid="ok-button"
        >
          {{ data.ok }}
        </button>
      </div>
      <div class="start-buttons" *ngIf="data.startButtons && !(data.responseMessage | async) && !(data.loading | async)" [fxLayout]="'row'">
        <div class="start-button" *ngFor="let button of data.startButtons">
          <button mat-button (click)="onButtonClick(button.action)" data-testid="start-button">
            {{ button.text | async }}
          </button>
        </div>
      </div>
      <!--end buttons-->
      <div class="end-buttons" *ngIf="data.endButtons && (data.responseMessage | async) && !(data.loading | async)" [fxLayout]="'row'">
        <div class="end-button" *ngFor="let button of data.endButtons; index as closeIndex">
          <button
            mat-button
            (click)="onButtonClick(button.action)"
            *ngIf="
              (button.visibleOnlyWithError && (data.errors | async)) ||
              (!button.visibleOnlyWithError && ((!button.visibleOnError && !(data.errors | async)) || button.visibleOnError))
            "
            attr.data-testid="{{button.attributes?.['data-testid'] || 'end-button-' + closeIndex }}"
          >
            {{ button.text | async }}
          </button>
        </div>
      </div>
    </mat-dialog-actions>

    <mat-dialog-actions *ngIf="(data.ko_icon || data.ok_icon) && (!data.button1Available || !data.submitAvailable)" class="float-buttons">
      <div class="start-buttons" *ngIf="!(data.loading | async) && !(data.responseMessage | async)">
        <!--float buttons-->
        <div fxLayout="column" fxLayoutGap="10px">
          <button mat-fab mat-dialog-close *ngIf="data.ko_icon" matTooltip="{{ data.ko }}" [color]="'warn'" data-testid="action-ko">
            <mat-icon class="white-icon ko-icon-button">{{ data.ko_icon }}</mat-icon>
          </button>
          <button mat-fab *ngIf="data.ok_icon" (click)="onSubmit($event)" matTooltip="{{ data.ok }}" [disabled]="!form.valid" data-testid="action-ok">
            <mat-icon class="white-icon ok-icon-button">{{ data.ok_icon }}</mat-icon>
          </button>
        </div>
      </div>
    </mat-dialog-actions>
  </ng-container>
</div>
<div #scrollSpy></div>
