import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormContainerModule } from '@ice/components/form-container/form-container.module';
import { DirectivesModule } from '@ice/directives/directives.module';
import { IceMaterialModule } from '@ice/material.module';
import { CardWithFormDropdownComponent } from './card-with-form-dropdown.component';

@NgModule({
  imports: [CommonModule, IceMaterialModule, FlexLayoutModule, FormContainerModule, DirectivesModule],
  declarations: [CardWithFormDropdownComponent],
  exports: [CardWithFormDropdownComponent],
})
export class CardWithFormDropdownModule {}
