import { IpUtils } from '@ice';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AgreementGroupSearchItem, AgreementGroupSearchItemCleaned } from 'models/copyright/search/copyright-agreement-group';

export class SearchAgreementGroupParser {
  static parse(items: any[], translate: TranslateService, extraParams?: Object, extraItems?: string[], store?: Store<any>) {
    return items.map((item: AgreementGroupSearchItem) => {
      const { attributes, partyName, relations } = item;
      const { id, description } = attributes;
      const ipName = (partyName && IpUtils.getNameFromParty(partyName)) || '';
      const ipNameKey = (partyName && partyName.relations && IpUtils.selectIpsKey(partyName.relations, partyName.id)) || '';
      const key = IpUtils.selectIpsKey(relations, id);
      return <AgreementGroupSearchItemCleaned>{ id, groupId: id.replace('CUBE:', ''), key, description, ipName, ipNameKey };
    });
  }
}
