import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { fieldConfig, IpClause, IpUtils, TabsUtils } from '@ice';
import { DataTableRow } from '@ice/components/data-table/data-table';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as sharedEnglish } from 'assets/i18n/en/app/common/shared';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import * as fromApiCalls from 'config/api-calls';
import { IP_CLAUSES } from 'config/constants/ips.constants';
import { SelectionDatatableBuilder } from 'config/data-table-builders/selection-datatable-builder';
import { DIALOG_NORMAL_BT, DialogForm } from 'config/dialog-builders/dialog-form';
import { DialogInfo } from 'config/dialog-builders/dialog-info';
import type { SectionTabConfig } from 'config/tabs-data-builders/tabs-data';
import { IceFacade } from 'facades/ice.facade';
import { sum } from 'lodash';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonApiService } from 'services/common-api.service';
import { DetailService } from 'services/detail/detail.service';
import { FeatureFlagService } from 'services/feature-flags/feature-flags-flagsmith.service';
import { NamespaceService } from 'services/namespace/namespace.service';
import { PermissionsService } from 'services/permissions/permissions.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';

export class TabIpClauses implements SectionTabConfig {
  hideWorksList = true;
  hideWorksSubject = new Subject();
  private subscriptionEditMode: Subscription;
  private selectionDatatable: SelectionDatatableBuilder;
  flagDisableIpsClausesUpdate: Observable<boolean>;

  // permissions
  private canEditIpClauses = false;

  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private dialog: MatDialog,
    private commonApiService: CommonApiService,
    private detailService: DetailService,
    private nsService: NamespaceService,
    private iceFacade: IceFacade,
    private fieldValidatorService: FieldValidatorService,
    private permissionsService: PermissionsService,
    private searchService,
    storeNewItem: null,
    params: null,
    private datatableService: null,
    private featureFlagService: FeatureFlagService,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.fuseTranslationLoader.loadTranslations(sharedEnglish);
    this.flagDisableIpsClausesUpdate = this.featureFlagService.disableIpsClausesUpdate.asObservable();
    this.subscriptionEditMode = this.store.pipe(select(fromRoot.getEditMode)).subscribe((editMode: boolean) => {
      if (editMode) {
        this.canEditIpClauses = this.permissionsService.can('ips_clauses_edit');
      }
    });
    this.selectionDatatable = new SelectionDatatableBuilder(
      this.store,
      this.getDataTable(),
      null,
      fromApiCalls.getIpsWorkClauses,
      this.getVisibleColumnsIpClauses(this.getDataTable()),
    );
  }

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              titleWithCount: this.getTitle(),
              model: combineLatest([this.store.pipe(select(fromRoot.getIpClauses)), this.store.pipe(select(fromRoot.getWorkClauses))]).pipe(
                map(([ipClauses, workClauses]) => {
                  return [...ipClauses.map((type): IpClause => ({ type, name: this.translate.instant(`CLAUSES.${type}`), clauseType: 'ip' })), ...workClauses];
                }),
              ),
              sorts: [],
              ...this.selectionDatatable.getSelectionDatatableConfig(),
              loadingIndicator: false,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: true,
              shadowed: false,
              columnMode: 'flex',
              actionButtons: combineLatest([this.store.pipe(select(fromRoot.getEditMode)), this.flagDisableIpsClausesUpdate]).pipe(
                map(([editMode, flagDisableIpsClausesUpdate]) => {
                  if (editMode === true && this.canEditIpClauses && !flagDisableIpsClausesUpdate) {
                    return [
                      {
                        icon: 'add',
                        tooltip: this.translate.instant('IPS.CLAUSES.ADD'),
                        class: 'mat-white-icon',
                        onClick: () => {
                          this.openDialog();
                        },
                      },
                    ];
                  }
                  return null;
                }),
              ),
            },
          },
        ],
      },
    ];
  }

  getTitle() {
    return combineLatest([this.store.pipe(select(fromRoot.getIpClauses)), this.store.pipe(select(fromRoot.getworkClausesCount))]).pipe(
      map(([ipClauses, workClausesCount]) => {
        const total = sum([ipClauses?.length || 0, workClausesCount || 0]);
        return IpUtils.getTabTableTitle(total, this.translate.instant('IPS.CLAUSES.TAB_TITLE_SINGULAR'), this.translate.instant('IPS.CLAUSES.TAB_TITLE'));
      }),
    );
  }

  openDialog() {
    const dialogForm = new DialogForm(this.translate, this.dialog, DIALOG_NORMAL_BT);
    const dialogRef = dialogForm.openDialog(
      this.translate.instant('IPS.CLAUSES.ADD'),
      { type: '' },
      [
        fieldConfig([
          {
            className: 'flex-1',
            key: 'type',
            type: 'select',
            templateOptions: {
              placeholder: `${this.translate.instant('IPS.CLAUSES.SELECT_TYPE')}`,
              required: true,
              options: IP_CLAUSES.map(ipClause => ({ value: ipClause, label: this.translate.instant(`CLAUSES.${ipClause}`) })),
            },
          },
        ]),
      ],
      (event: IpClause) => {
        this.store.dispatch(new fromRoot.UpdateField({ object: 'clauses', newValue: event.type, type: 'new' }));
        dialogRef.close();
      },
      () => dialogRef.close(),
      this.translate.instant('POPUP_COMMON.CONFIRM'),
      this.translate.instant('POPUP_COMMON.CANCEL'),
      'dialog-wrapper-width-420-h',
    );
  }

  getDataTable(): DataTableRow[] {
    return [
      { name: this.translate.instant('IPS.CLAUSES.TABLE.CLAUSE_TYPE'), prop: 'type', flexGrow: 1, filter: true },
      { name: this.translate.instant('IPS.CLAUSES.TABLE.CLAUSE_NAME'), prop: 'name', flexGrow: 1, filter: true },
      { name: this.translate.instant('IPS.CLAUSES.TABLE.WORK_REFERENCE'), prop: 'workRef', flexGrow: 1, filter: true },
      {
        name: this.translate.instant('IPS.CLAUSES.TABLE.WORK_TITLE'),
        prop: 'workTitle',
        flexGrow: 1,
        filter: true,
        badge: { text: 'titlesTotal', tooltip: 'tooltipTitles', backgroundColor: '#0A9BE5', textColor: '#fff' },
      },
      {
        name: '',
        prop: 'deleteIpClausesBtn',
        actionButtonIcon: 'delete',
        flexGrow: 0.001,
        maxWidth: 50,
        minWidth: 50,
        resizeable: false,
        action: row => this.deletePopup(row),
        hideActionButton: row =>
          combineLatest([this.store.pipe(select(fromRoot.getEditMode)), this.flagDisableIpsClausesUpdate]).pipe(
            map(
              ([editMode, flagDisableIpsAlternativeNamesUpdate]) =>
                (!editMode && !this.canEditIpClauses) || (editMode && this.canEditIpClauses && !(row.clauseType === 'ip')) || flagDisableIpsAlternativeNamesUpdate,
            ),
          ),
        actionButtonTooltip: this.translate.instant('IPS.CLAUSES.REMOVE'),
      },
    ];
  }

  deletePopup(row: IpClause) {
    const dialogInfo = new DialogInfo(this.translate, this.dialog, this.store);
    const dialogRefInfo = dialogInfo.openDialog(
      this.translate.instant('IPS.CLAUSES.REMOVE'),
      this.translate.instant('IPS.CLAUSES.CHECK_DELETE'),
      () => {
        this.store.dispatch(new fromRoot.UpdateField({ object: 'clauses', newValue: row.type, type: 'delete' }));
        dialogRefInfo.close();
      },
      () => dialogRefInfo.close(),
    );
  }

  private getVisibleColumnsIpClauses(schema: any): Observable<string[]> {
    const schemaDatatable = TabsUtils.getSChemaPropsToArray(schema);

    return this.store.pipe(
      select(fromRoot.getEditMode),
      map(editMode => {
        if (editMode) {
          return schemaDatatable;
        } else {
          return TabsUtils.getSchemaFiltered(schemaDatatable, ['deleteIpClausesBtn']);
        }
      }),
    );
  }
}
