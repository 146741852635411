import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FuseUtils } from '@fuse/utils';
import { ApiNamespace, CounterClaimUtils, DateTimeUtils, IpDetail, IpUtils, RepertoireUtils } from '@ice';
import { ErrorsUtils } from '@ice/utils/api-responses/errors.utils';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ICEADMIN_ORIGIN, ICE_MODELS_BY_API_SEGMENT, ICE_PREFIX, MODE_OVERWRITE } from 'config/constants/global.constants';
import { SectionConfig, SectionsConfig } from 'config/sections-config';
import * as fileSaver from 'file-saver';
import { cloneDeep, get, groupBy, keys, remove, uniqBy, uniqueId } from 'lodash';
import { EditNamespace, GetICEpartyNamesProps, RegisterIpsOnIcePartiesProps, SaveItemProps } from 'models/copyright/detail/edit-mode';
import { SelectMode } from 'models/copyright/search/select-mode';
import { ShareReportRequest } from 'models/requests/shareReportRequest';
import { UsersPutRequest } from 'models/users/users-put-request.model';
import moment from 'moment';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, timeoutWith, retryWhen } from 'rxjs/operators';
import { CommonApiService } from 'services/common-api.service';
import * as fromRoot from 'store/root';
import { SelectedSearchResultsBySection } from 'store/root';
import { environment } from 'config/env';
import { TRANSLATION_PREFIX } from 'config/constants/response-errors-constants';
import { AuthService } from '../auth/auth.service';
import { MaskedIdsService } from './../masked-ids/masked-ids.service';

@Injectable()
export class SaveItemService extends CommonApiService {
  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    protected store: Store<fromRoot.RootState>,
    protected route: ActivatedRoute,
    protected maskedIdsService: MaskedIdsService,
    protected actions$: Actions,
  ) {
    super(http, authService, store, route, maskedIdsService, actions$);
  }

  public saveItem({ sectionConfig, item, namespace, editKey, nsOnBehalfOf, multipleNamespaces, editSubSection = null, tab = null }: SaveItemProps): Observable<any> {
    const params = {};
    const { apiSegment } = sectionConfig;
    if (item) {
      let id = item.id;
      switch (sectionConfig.name) {
        case SectionsConfig.COUNTERCLAIMS.name:
          return this.saveCounterClaim(apiSegment, namespace, params, item, multipleNamespaces);
        case SectionsConfig.AGREEMENTCONFLICT.name:
          return this.putAgreementCounterClaim(apiSegment, namespace, params, item, multipleNamespaces);
        case SectionsConfig.AGREEMENT_GROUP.name:
          return this.saveAgreementGroup(apiSegment, namespace, params, item, multipleNamespaces);
        case SectionsConfig.WORKS.name:
          if (editKey || editSubSection) {
            // It's an update
            id = editSubSection ? id : editKey;
            return this.saveWork(id, apiSegment, nsOnBehalfOf, params, item, namespace, multipleNamespaces);
          }
          // It's a new
          return this.workRegister(sectionConfig, nsOnBehalfOf, params, item);
        case SectionsConfig.IPS.name:
          return this.saveIps({ id: item.icePartyNameId, apiSegment, ns: nsOnBehalfOf, itemData: item });
        case SectionsConfig.REPERTOIRES.name:
          return this.saveRepetoire(apiSegment, nsOnBehalfOf, params, item, multipleNamespaces);
        case SectionsConfig.AGREEMENTS.name:
          // To add when API will be ready
          if (editKey) {
            // It's an update
            id = editKey;
            return this.saveCubeRegisterBehalfOf(id, apiSegment, item.attributes.ns, params, item, namespace, multipleNamespaces);
          }
          // It's a new
          return this.saveAgreement(id, sectionConfig, namespace, params, item, multipleNamespaces);
        case SectionsConfig.ORGANIZATIONS.name:
          return this.saveOrganization(id, apiSegment, nsOnBehalfOf, params, item, multipleNamespaces);
        case SectionsConfig.USERS.name:
          return this.saveUser(id, apiSegment, params, item, multipleNamespaces);
        case SectionsConfig.REPORTS.name:
          return this.saveReport(apiSegment, namespace, item, params);
        case SectionsConfig.COUNTERCLAIMSSUPPORT.name:
          return this.supportCounterClaim(apiSegment, item);
        case SectionsConfig.COUNTERCLAIM_NOTIFICATIONS.name:
          return this.saveCounterclaimNotifications(apiSegment, item);
        case SectionsConfig.BULK_UPDATES.name:
          return this.doBulkUpdate(item);
      }
    }
    return of(null);
  }

  public doBulkUpdate(item) {
    const endpoint = `${environment.apiUrlCubeRegister}/works/CUBE/bulkUpdate/${item.path}`;
    // When bulk update requests take too much time to be processed in the backend, it's usually due not to any failure but to the sheer amount of data being processed.
    // In those cases, the request is not considered as failed, but as taking too long to process.
    // We don't even have to wait for the response, as the user will be notified of the result in the bulk updates dashboard.
    return this.postRequestCubeData<any>(endpoint, {}, item.body).pipe(timeoutWith(20_000, throwError(`${TRANSLATION_PREFIX}BULK_UPDATE_TIMEOUT`)));
  }

  public saveCounterclaimNotifications(apiSegment, item) {
    const endpoint = `${environment.apiUrlCubeRegister}/${apiSegment}/emails/upload/${item.orgId}/${item.ccNotificationsUploadData.filename}`;
    return this.putRequestCubeData<any>(endpoint, {}, item.ccNotificationsUploadData.list);
  }

  public supportCounterClaim(apiSegment, item) {
    const { id, ...query } = item;
    const endpoint = `${environment.apiUrlCubeRegister}/${apiSegment}/actions/${id}`;
    return this.postRequestCubeData<HttpResponse<any>>(endpoint, {}, query).pipe(
      map(response => ({
        status: 'success',
        id,
      })),
    );
  }

  public editDummyIps(ipData): Observable<any> {
    const { ns, nsId, ...partyName } = ipData;
    return this.saveCubeRegister(nsId, 'party-names', ns, {}, partyName);
  }

  public deleteDummyIps(ipData): Observable<any> {
    const { nsId, ns } = ipData;
    const url = `${environment.apiUrlCubeData}/party-names/${ns}/${nsId}`;
    return this.deleteRequestCubeData(url, {});
  }

  public createDummyIps(ipData): Observable<any> {
    const { firstName, name, creationClass, dateOfBirth, dateOfDeath, typeOf, ns, getIpDetail } = ipData;
    const id = IpUtils.generateIDForDummyIp(ns);
    const queryParams = { force: 'true', mode: MODE_OVERWRITE };
    const putPartyBody = {
      attributes: {
        creationClass,
        dateOfBirth: DateTimeUtils.formatDate(dateOfBirth),
        dateOfDeath: !dateOfDeath || dateOfDeath === '' ? undefined : DateTimeUtils.formatDate(dateOfDeath),
        status: 1,
        typeOf,
        ns,
      },
      id,
    };
    const typeOfName = 'PA';
    return this.putRequestCubeData(`${environment.apiUrlCubeData}/parties/${ns}/${id}`, queryParams, putPartyBody).pipe(
      catchError(error => throwError(get(error, 'error.error', null))),
      switchMap(response => {
        return this.checkStatus('parties', response);
      }),
      switchMap(response => {
        const idPartyName = IpUtils.generateIDForDummyIp(ns);
        const putPartyNameBody = { attributes: { firstName, name, ns, typeOfName }, id: idPartyName, parties: [{ partyId: id, type: typeOfName }] };
        return this.putRequestCubeData(`${environment.apiUrlCubeData}/party-names/${ns}/${idPartyName}`, queryParams, putPartyNameBody).pipe(
          catchError(error => throwError(get(error, 'error.error', null))),
          switchMap(resp => {
            return this.checkStatus('party-names', resp);
          }),
          switchMap(responsePartyName => {
            const xrefPartyName = get(responsePartyName, 'events[0].xrefs[0]', '');
            if (xrefPartyName) {
              return of({ ...ipData, idPartyName, xrefPartyName, id });
            }
            return throwError(ErrorsUtils.getResponseErrors(responsePartyName));
          }),
          switchMap(baseData => {
            if (getIpDetail) {
              const { xrefPartyName } = baseData;
              const { baseUrlCubeData, detailSegment, apiIncludes } = SectionsConfig.IPS;
              const urlCubeData = `${baseUrlCubeData}/${detailSegment.replace(/<<ns>>/, 'CUBE').replace(/<<id>>/, xrefPartyName)}`;
              const params = { include: apiIncludes.detail, ns: 'CUBE' };
              return this.getRequestCubeData<IpDetail>(urlCubeData, params).pipe(
                catchError(error => throwError(get(error, 'error.error', null))),
                switchMap(dummyIpDetail => {
                  return of({ ...baseData, partyInfo: dummyIpDetail });
                }),
              );
            }
            return of(baseData);
          }),
        );
      }),
    );
  }

  public saveWorkBatch(id: string, apiSegment: any, ns: any, params: any, item: any, namespace: string, multipleNamespaces: EditNamespace[]): Observable<any> {
    return this.saveCubeRegisterBehalfOf(id, apiSegment, ns, params, item, namespace, multipleNamespaces).pipe(
      tap(response => {
        this.store.dispatch(new fromRoot.SaveWorkBatchSuccess(id));
      }),
      catchError(error => {
        this.store.dispatch(new fromRoot.SaveWorkBatchError(id || `${error.status}`));
        return of(error);
      }),
    );
  }

  public syncExternalWorkRelations(workExternalRelations, workId) {
    workExternalRelations = (workExternalRelations?.items || []).filter(relation => !!relation.modified);
    cloneDeep(workExternalRelations).forEach((externalWork, index) => {
      if (externalWork.modified === 'added') {
        workExternalRelations[index].relations = [...workExternalRelations[index].relations, { otherId: workId, relation: externalWork.relationType }];
      }
      if (externalWork.modified === 'deleted') {
        remove(workExternalRelations[index].relations, (relation: any) => relation.otherId === workId && relation.relation === externalWork.relationType);
      }
    });
    return workExternalRelations;
  }

  public saveIps({ id, apiSegment, ns, itemData }: GetICEpartyNamesProps) {
    const { childrenRelationshipsData } = itemData; // The item send shouldn't contain id.
    const uploadIps = (childrenRelationshipsData?.items || []).map(ips => ips.rawItem).filter(ips => !!ips);
    const ipRequests = [this.getICEpartyNames({ id, apiSegment })];
    uploadIps.forEach(uploadItem =>
      ipRequests.push(
        this.getICEpartyNames({
          id: uploadItem.id,
          apiSegment,
        }),
      ),
    );
    return forkJoin(ipRequests).pipe(
      map(responses =>
        responses.map((res, index) => {
          return {
            response: res,
            itemData: index === 0 ? itemData : uploadIps[index - 1],
          };
        }),
      ),
    );
  }

  public saveWork(id: string, apiSegment: any, ns: any, params: any, item: any, namespace: string, multipleNamespaces: EditNamespace[]): Observable<any> {
    const { workExternalRelations, ...itemRest } = item;
    const externalRelationsCopy = this.syncExternalWorkRelations(cloneDeep(workExternalRelations), itemRest.id);
    const cleanedExternalRelations = Object.values(groupBy(externalRelationsCopy || [], (work: any) => work.id)).map(externalRelationGroup => {
      return {
        relations: uniqBy(
          externalRelationGroup.map(work => work.relations).reduce((acc, it) => [...acc, ...it], []),
          (relation: any) => `${relation.otherId}${relation.relation}`,
        ),
        id: externalRelationGroup[0].id,
        attributes: externalRelationGroup[0].attributes,
        claims: externalRelationGroup[0].claims,
      };
    });
    const workRequests = [
      this.saveWorkBatch(id, apiSegment, ns, params, itemRest, namespace, multipleNamespaces),
      ...cleanedExternalRelations.map(externalWork => this.saveWorkBatch(externalWork.id, apiSegment, ns, params, externalWork, namespace, multipleNamespaces)),
    ];
    return forkJoin(workRequests).pipe(map(response => response[0]));
  }

  public requestNewItemId(item: string): Observable<any> {
    const requestNewCaseIdUrl = `${environment.apiUrlCubeRegister}/ids/new/${item}`;
    return this.getRequestCubeData<any>(requestNewCaseIdUrl, {});
  }

  public putNewConflict(item, ns: string): Observable<any> {
    const conflictId = `${ns}:${FuseUtils.generateGUID()}`;
    const requestNewCaseIdUrl = `${environment.apiUrlCubeRegister}/conflicts/${ns}/${conflictId}?force=true&mode=OVERWRITE`;
    const firstConflict = get(item, 'conflicts[0]');
    const conflict = firstConflict && firstConflict.conflict;
    const attributes = { conflictType: 'CLAIM', conflictSubType: 'CLAIM' };
    return this.putRequestCubeData<any>(requestNewCaseIdUrl, {}, { ...firstConflict, conflict: { ...conflict, id: conflictId }, conflictId, attributes });
  }

  public uploadUsingPreSignedURL(url, file, contentType = 'text/csv') {
    return this.http.put(url, file, { headers: new HttpHeaders({ 'Content-Type': contentType }) }).pipe(retryWhen(this.handleRetry));
  }

  public uploadDocumentUsingPreSignedURL(file, counterclaimId, actionId) {
    const url = `${environment.apiUrlCubeData}/counterclaims/documents/${counterclaimId}/${actionId}/${file.name}`;

    return this.putRequestCubeData<any>(url, {}).pipe(
      catchError(error => throwError(get(error, 'error.error', null))),
      switchMap(response => this.http.put(response.url, file, { headers: new HttpHeaders({ 'Content-Type': 'binary' }) }).pipe(map(() => response))),
      map(response => ({ ...response, file, actionId })),
    );
  }

  public deleteDocument(location) {
    const url = `${environment.apiUrlCubeData}/counterclaims/documents/${location}`;
    return this.deleteRequestCubeData(url, {}).pipe(
      catchError(error => throwError(get(error, 'error.error', null))),
      map(response => ({ response, location })),
    );
  }

  public deleteDocuments(documents) {
    const requests = [];
    documents.forEach(document => requests.push(this.deleteDocument(document.location)));
    return forkJoin(...requests).pipe(map(response => response[0]));
  }

  public downloadDocument(counterClaimId, document) {
    const { actionId, id } = document;
    const url = `${environment.apiUrlCubeData}/counterclaims/actions/${counterClaimId}/${actionId}/documents/${id}`;

    return this.getRequestCubeData<any>(url, {})
      .pipe(retryWhen(this.handleRetry))
      .pipe(
        catchError(error => throwError(get(error, 'error.error', null))),
        switchMap(response => {
          return this.http.get(response.url, { responseType: 'blob' }).pipe(
            map((blob: any) => {
              fileSaver.saveAs(blob, document.fileName);
              return { fileName: document.fileName, result: response };
            }),
          );
        }),
      );
  }

  public rejectDocument(counterClaimId, documents, reason) {
    const url = `${environment.apiUrlCubeData}/counterclaims/actions/${counterClaimId}`;
    const actions = groupBy(documents, document => document.actionId);
    const body = {
      response: 'SUPPORTED_AND_DOCUMENT_REJECTED',
      actions: keys(actions).map(actionId => ({
        id: actionId,
        documentIds: (actions[actionId] || []).map(document => document.id),
        reason,
      })),
    };

    return this.postRequestCubeData<HttpResponse<any>>(url, {}, body);
  }

  public putAgreementCounterClaim(apiSegment, ns, params, item, multipleNamespaces: EditNamespace[]) {
    const { id } = item;
    if (id) {
      return this.setupCounterClaimAndAction(id, item);
    }
    return this.setupCounterClaimAndAction(undefined, item, true);
  }

  private setupCounterClaimAndAction(id, item, creation?) {
    const requestBody = CounterClaimUtils.getPutAgreementRequest(id, item, creation);
    const request = creation
      ? this.postRequestCubeData<any>(`${environment.apiUrlCubeRegister}/counterclaims/CUBE/`, {}, requestBody)
      : this.putRequestCubeData<any>(`${environment.apiUrlCubeRegister}/counterclaims/CUBE/${id}`, {}, requestBody);
    return request.pipe(
      mergeMap(ccResponse => {
        return this.requestNewItemId('ICECASE').pipe(
          mergeMap(actionId => {
            const aid = `CUBE:${actionId.value}`;
            const bodyActionRequest = CounterClaimUtils.getPutAgreementConflictAction(ccResponse.id, aid, item, creation);
            return creation
              ? this.postRequestCubeData<any>(`${environment.apiUrlCubeRegister}/actions/CUBE`, {}, bodyActionRequest)
              : this.putRequestCubeData<any>(`${environment.apiUrlCubeRegister}/actions/CUBE/${aid}`, {}, bodyActionRequest);
          }),
        );
      }),
    );
  }

  private saveCounterClaim(apiSegment, ns, params, item, multipleNamespaces: EditNamespace[]) {
    const newEndPointApiSegment = 'counterclaims';
    if (item.id) {
      return this.saveCubeRegister(item.id, newEndPointApiSegment, ns, params, item);
    } else {
      const participants = (item.participants || []).map(participant => ({ ...participant, id: uniqueId(`${ns}:${moment().valueOf()}_`) }));
      return this.postRequestCubeData(`${environment.apiUrlCubeRegister}/${newEndPointApiSegment}/${ns}`, params, {
        attributes: {
          ...item.attributes,
          entityType: 'work',
        },
        participants: participants.map(participant => {
          const parentId = this.getParentParticipantId(participant, participants);
          return { ...participant, version: 1, isRemoved: false, ns: 'ICE', ...(parentId ? { parentId } : {}) };
        }),
        scope: item.scope,
      });
    }
  }

  private getParentParticipantId(participant, participantList) {
    if (!participant.parentParticipantNameId) {
      return null;
    }
    const findGroup = participantList.find(
      claimGroup =>
        !claimGroup.parentParticipantNameId && claimGroup.participantNameId === participant.parentParticipantNameId && participant.topLevelNameId === claimGroup.topLevelNameId,
    );
    return findGroup ? findGroup.id : null;
  }

  private saveReport(apiSegment, ns, item, params) {
    const endpoint = `${environment.apiUrlCubeRegister}/${apiSegment}/create/${ns}/${item.reportType}`;
    let body = {};
    switch (item.reportType) {
      case 'CUBE001':
        if (item.paymentOrOwnership.toLowerCase() === 'ownership') {
          body = {
            outputType: 'json',
            stageType: 'ownership',
          };
        } else {
          body = { stageType: 'payment' };
          if (item.includeRepertoireDetails) {
            body = { ...body, includeRepertoireDetails: 'true' };
          }
        }
        body = { ...body, ...(item.fiche && { fiche: item.fiche }) };
        break;
    }
    delete item.reportType;
    return this.postRequestCubeData<HttpResponse<any>>(endpoint, params, body).pipe(
      mergeMap(genReq => this.uploadUsingPreSignedURL(get(genReq, 'reportResult.reportParametersUploadLink', ''), item, 'application/json')),
    );
  }

  private saveAgreementGroup(apiSegment, ns, params, item, multipleNamespaces: EditNamespace[]) {
    const id = get(item, 'attributes.id');
    if (id) {
      // Update
      return this.saveCubeRegister(id, apiSegment, ns, params, item);
    } else {
      // New
      const endpoint = `${environment.apiUrlCubeRegister}/${apiSegment}/${ns}?include=relations&mode=OVERWRITE`;
      return this.postRequestCubeData<HttpResponse<any>>(endpoint, params, item);
    }
  }

  private saveRepetoire(apiSegment, ns, params, item, multipleNamespaces: EditNamespace[]) {
    if (item.id) {
      // Update
      const repertoireId = item.id;
      return this.saveCubeRepertoire(item.id, apiSegment, ns, params, RepertoireUtils.formatSendItemWithId(item, repertoireId, 'update')).pipe(
        map((response: any) => {
          const iceId = IpUtils.selectIpsKey(response.relations, response.id);
          return { ...response, identifier: iceId };
        }),
      );
    } else {
      // New
      return this.requestNewItemId('ICEREPERTOIRE').pipe(
        mergeMap(responseId => {
          const repertoireId = `${ns}:${responseId.value}`;
          return this.saveCubeRegister(repertoireId, apiSegment, ns, params, RepertoireUtils.formatSendItemWithId(item, repertoireId, 'new')).pipe(
            map((response: any) => {
              const iceId = IpUtils.selectIpsKey(response.relations, response.id);
              return { ...response, identifier: iceId };
            }),
          );
        }),
      );
    }
  }

  private saveAgreement(id: string, sectionConfig: SectionConfig, namespace: ApiNamespace, params: any, item: any, multipleNamespaces: EditNamespace[]) {
    return this.postRequestCubeData<HttpResponse<any>>(`${environment.apiUrlCubeRegister}/${sectionConfig.apiSegment}/ICE/register?force=true`, params, item);
  }

  private workRegister(sectionConfig: SectionConfig, namespace: string, params: any, item: any) {
    return this.postRequestCubeData<HttpResponse<any>>(`${environment.apiUrlCubeRegister}/${sectionConfig.apiSegment}/${namespace}/register?force=true`, params, item);
  }

  private saveOrganization(id: string, apiSegment: any, ns: any, params: any, item: any, multipleNamespaces: EditNamespace[]): Observable<any> {
    const endpoint = `${environment.apiUrlCubeData}/${apiSegment}/${ns}/${id}?force=true`;
    return this.putRequestCubeData<HttpResponse<any>>(endpoint, params, item);
  }

  private saveCubeRepertoire(id: string, apiSegment: any, ns: any, params: any, item: any): Observable<any> {
    const endpoint = `${environment.apiUrlCubeRegister}/${apiSegment}/${ns}/${id}?mode=MERGE&force=true`;
    return this.putRequestCubeData<HttpResponse<any>>(endpoint, params, item);
  }

  private saveCubeRegister(id: string, apiSegment: any, ns: any, params: any, item: any): Observable<any> {
    const endpoint = `${environment.apiUrlCubeRegister}/${apiSegment}/${ns}/${id}?force=true`;
    return this.putRequestCubeData<HttpResponse<any>>(endpoint, params, item);
  }

  private saveCubeRegisterBehalfOf(id: string, apiSegment: any, ns: any, params: any, item: any, namespace: string, multipleNamespaces: EditNamespace[]): Observable<any> {
    if (multipleNamespaces) {
      const multipleNamespacesEndpoint = `${environment.apiUrlCubeRegister}/${apiSegment}/${namespace}/${id}/origins?force=true`;
      const sendItem = { [`${ICE_MODELS_BY_API_SEGMENT[apiSegment]}Edit`]: { entity: item, onBehalfNamespaces: multipleNamespaces.map(editNamespace => editNamespace.editId) } };
      return this.postRequestCubeData<HttpResponse<any>>(multipleNamespacesEndpoint, params, sendItem);
    } else {
      let originsNS = ns === ICE_PREFIX ? (apiSegment === SectionsConfig.AGREEMENTS.apiSegment ? ICE_PREFIX : ICEADMIN_ORIGIN) : ns;
      originsNS = originsNS === 'CUBE' ? ICEADMIN_ORIGIN : originsNS; // This can't never be CUBE as the origin ns the API fails
      const endpoint = `${environment.apiUrlCubeRegister}/${apiSegment}/${namespace}/${id}/origins/${originsNS}?force=true`;
      return this.putRequestCubeData<HttpResponse<any>>(endpoint, params, item);
    }
  }

  private getICEpartyNames({ id, apiSegment }): Observable<any> {
    const endpoint = `${environment.apiUrlCubeRegister}/${apiSegment}/${ICE_PREFIX}/${id}`;
    return this.getRequestCubeData<HttpResponse<any>>(endpoint, { include: 'attributes,parties,relations', followRedirects: false });
  }

  public registerIPsOnICEparties({ id, apiSegment, params }: RegisterIpsOnIcePartiesProps): Observable<any> {
    const endpoint = `${environment.apiUrlCubeRegister}/${apiSegment}/${ICE_PREFIX}/${id}`;
    return this.putRequestCubeData<HttpResponse<any>>(endpoint, {}, params);
  }

  private saveUser(id: string, apiSegment: any, params: any, item: any, multipleNamespaces: EditNamespace[]): Observable<any> {
    const user = <UsersPutRequest>item;
    const roles = (<UsersPutRequest>item).roles;
    const userNs = user.attributes.ns;
    const endpoint = `${environment.apiUrlCubeData}/${apiSegment}/${userNs}/${id}?force=true`;
    return this.putRequestCubeData<HttpResponse<any>>(endpoint, params, item);
  }

  public getSelectDeleteEndpoint(mode: SelectMode): Observable<any> {
    return this.deleteRequestCubeData<HttpResponse<any>>(
      `${environment.apiUrlCubeRegister}/reports/bulk`,
      {},
      null,
      (mode.selection || []).map(item => item.id),
    );
  }

  public getSelectShareEndpoint(ns: string, selectItem: any, searchResults: SelectedSearchResultsBySection): Observable<any> {
    const request: ShareReportRequest = {
      userIds: [searchResults.id],
    };
    return this.postRequestCubeData<HttpResponse<any>>(`${environment.apiUrlCubeRegister}/reports/${ns}/${selectItem.id}/users`, {}, request);
  }
}
