import { NewIpType } from 'config/constants/ips.constants';

export interface NewIps {
  assignor: any;
  assignee: any;
  assignorFields: any;
  assigneeFields: any;
  claimant: any;
  publisher: any;
  claimantFields: any;
  publisherFields: any;
  current: NewIpType;
  isLoading: boolean;
  error: any;
}

export interface NewSectionItemState {
  initialFields: object;
  fields: object;
  validForm: boolean;
  search?: any;
  searchResult?: any;
  isValid: boolean;
  loading: boolean;
  response: any;
  isLastActionClose: boolean;
  isPopupOkButtonMarked: boolean;
  newIp: NewIps;
  counterClaimIps: any[];
  dialogSelectedcounterClaimIps: any[];
  claimantGroups: any[];
  isCloning?: boolean;
  isChanged?: boolean;
}

export const initialNewSectionItemState: NewSectionItemState = {
  initialFields: {},
  fields: {},
  validForm: false,
  search: {},
  searchResult: {},
  isValid: false,
  loading: false,
  response: null,
  isLastActionClose: false,
  isPopupOkButtonMarked: false,
  newIp: {
    assignor: null,
    assignee: null,
    assignorFields: {},
    assigneeFields: {},
    claimant: null,
    claimantFields: {},
    publisher: null,
    publisherFields: {},
    current: -1,
    isLoading: false,
    error: null,
  },
  counterClaimIps: [],
  dialogSelectedcounterClaimIps: [],
  claimantGroups: [],
  isCloning: false,
  isChanged: false,
};
