import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ExpandableData } from 'config/tabs-data-builders/shared/audit-history.model';
import { isObservable } from 'rxjs';
import { CardWithDataTable } from './card-with-data-table';

@Component({
  selector: 'ice-card-with-data-table',
  templateUrl: './card-with-data-table.component.html',
  styleUrls: ['./card-with-data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardWithDataTableComponent {
  @Input() config: CardWithDataTable;
  isObservable = isObservable;

  selectItem(event) {
    if (event && this.config && this.config.onSelect) {
      this.config.onSelect(event);
    }
  }

  onMouseWheelClickSelect(event) {
    if (event && this.config && this.config.onMouseSelect) {
      this.config.onMouseSelect(event);
    }
  }

  takeFirstLetter(label: string) {
    return label.substring(0, 1).toLocaleUpperCase();
  }

  selectExpandableItem(event: ExpandableData) {
    if (event && this.config && this.config.onSelectExpandable) {
      this.config.onSelectExpandable(event);
    }
  }
}
