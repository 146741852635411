import { AgreementItem, DateTimeUtils, IpUtils, WorkUtils } from '@ice';
import { LinkedTabsName } from 'config/constants/ips.constants';
import { get, uniqBy } from 'lodash';
import { SearchIpsParser } from 'models/search-parsers/search-ips-parser';
import { BulkUpdatesUtils } from '@ice/utils/bulk-updates/bulk-updates.utils';
import { BulkUpdateResultStatus, JobItem } from 'models/copyright/bulk-updates/jobs.model';
import { createPropertyCleaner, createResultCleaner } from '../api-call.factory';

export const getIpsWorksResultCleaner = createResultCleaner((result, detail, data, translate) =>
  result && result.items
    ? WorkUtils.workSearchCleaner(
        result.items.map(item => item.work),
        '',
        false,
        '',
        translate,
      )
    : [],
);

export const searchDialogIpsResultCleaner = createResultCleaner((value, detail, data, translate, tabName) =>
  SearchIpsParser.parse(get(value, 'items', []), translate, null, ['baseInformation']),
);

export const getWorkClausesResultCleaner = createResultCleaner(
  (value, detail, data, translate, tabName) => WorkUtils.getWorkClausesFromWorks(value.items, detail.id, translate) || [],
);

export const getLinkedPropertyCleaner = createPropertyCleaner((value, detail, data, translate, tabName) =>
  tabName === LinkedTabsName.Publishers ? 'linkedPublishers' : 'linkedWriters',
);

export const getLinkedResultCleaner = createResultCleaner((value, detail, data, translate, tabName) =>
  tabName === LinkedTabsName.Publishers ? IpUtils.linkedPublishersCleaner(value, detail) : IpUtils.linkedWritersCleaner(value),
);

export const getIpsPartyNamesResultCleaner = createResultCleaner((value, detail, data, translate) => {
  const oldPartyNames = (detail && detail.partyNames) || [];
  const newPartyNames = value && { items: value.partyNames.map(partyName => partyName.partyName) };
  return uniqBy([...IpUtils.formatPartyNames(newPartyNames, translate), ...oldPartyNames], partyName => partyName.ipiNameNumber);
});

export const getIpsRelationshipResultCleaner = createResultCleaner((value, detail, data, translate) => (value ? SearchIpsParser.IpSearchCleaner(value.items, translate) : []));
export const getAgreementChainsResultCleaner = createResultCleaner((value: { items: AgreementItem[] }, detail, cleanerData, translate) => {
  return {
    ...value,
    items: value.items.map(item => {
      const itemExtension: Partial<AgreementItem> = {};
      if (cleanerData?.parent) {
        itemExtension.parent = cleanerData.parent;
        itemExtension.depth = cleanerData.depth;
        itemExtension.parentAgreementIds = cleanerData.parentAgreementIds;
      } else {
        itemExtension.depth = 1;
        itemExtension.parentAgreementIds = [item.id];
      }

      return { ...item, ...itemExtension };
    }),
  };
});

export const getBulkUpdateJobsResultCleaner = createResultCleaner<JobItem[], JobItem[]>((response, detail, data, translate) => {
  const items = response.map(item => {
    const rawBulkUpdateType = item.bulkUpdateType;
    const bulkUpdateType = BulkUpdatesUtils.translateBulkUpdateTypeValue(translate, rawBulkUpdateType);
    const statusText = translate.instant(BulkUpdatesUtils.getBulkUpdateTranslationKey(item.status));
    const createdAt = DateTimeUtils.formatDateTime(item.createdAt);
    const stagesCount = item.worksCount || '?';
    const tooltipCount = translate.instant('BULK_UPDATES.LIST.TOOLTIP_COUNT', { count: stagesCount });
    return {
      ...item,
      bulkUpdateType,
      createdAt,
      stagesCount,
      statusText,
      tooltipCount,
    };
  });
  return items;
});
