import { Action } from '@ngrx/store';
import { ApiNamespace } from 'models';
import { NavigationStates } from '../../state';

export const SAVE_CURRENT_NAVIGATION_EVENT = '[Global] Spy Current Navigation Event';
export const CHANGE_API_NAMESPACE = '[Global] Change API Namespace';
export const RESTORE_API_NAMESPACE = '[Global] Restore API Namespace';
export const SAVE_FORCEDNS_BACKLINK = '[Global] Save forcedNs back link';

export class SaveCurrentNavigationEvent implements Action {
  readonly type = SAVE_CURRENT_NAVIGATION_EVENT;

  constructor(public payload: SaveCurrentNavigationEventAction) {}
}

export class ChangeApiNamespace implements Action {
  readonly type = CHANGE_API_NAMESPACE;

  constructor(public payload: ApiNamespace) {}
}

export class RestoreApiNamespace implements Action {
  readonly type = RESTORE_API_NAMESPACE;
}

export class SaveForcedNsBackLink implements Action {
  readonly type = SAVE_FORCEDNS_BACKLINK;

  constructor(public payload: string) {}
}

export type GlobalActions = SaveCurrentNavigationEvent | ChangeApiNamespace | RestoreApiNamespace | SaveForcedNsBackLink;

export interface SaveCurrentNavigationEventAction {
  state: NavigationStates;
  nextUrl: IceUrlItem;
  lastSection: string;
}

export interface IceUrlItem {
  url: string;
  isNewElementPage: boolean;
}
