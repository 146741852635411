import { navAdministration, navAdminOrganizations, navAdminUsers, navApplications, navConflicts, navCopyright, navFiches } from 'config/navigation/navigation';
import { SectionModel, SectionsConfig } from 'config/sections-config';
import { cloneDeep, filter, get } from 'lodash';
import { PermissionsService } from 'services/permissions/permissions.service';
import { CopyrightUtils } from '../copyright/copyright.utils';

const NAV_INDEX_APPLICATIONS = 0;
const NAV_INDEX_ADMINISTRATION = 1;

export class NavigationUtils {
  static getNavigation(permissionsService: PermissionsService) {
    const canNavCopyright = permissionsService.can('copyright_navbar');
    const canNavCases = permissionsService.can('cases_navbar');
    const canNavAdmin = permissionsService.can('administration_navbar');
    const canNavOrganizations = permissionsService.can('organizations_navbar');
    const canNavUsers = permissionsService.can('users_navbar');
    const canNavRepertoires = permissionsService.can('repertoires_navbar');
    const nav = [cloneDeep(navApplications)];
    nav[NAV_INDEX_APPLICATIONS].children = [];

    if (canNavCopyright) {
      const navCopyrightFiltered = { ...navCopyright, children: navCopyright.children.filter(child => permissionsService.can(`${child.id}_navbar`)) };
      nav[NAV_INDEX_APPLICATIONS].children.push(navCopyrightFiltered);
    }
    if (canNavCases) {
      nav[NAV_INDEX_APPLICATIONS].children.push({
        ...navConflicts,
        children: navConflicts.children.filter(child => permissionsService.can(`${child.id}_navbar`)),
      });
    }
    nav[NAV_INDEX_APPLICATIONS].children.push(navFiches);
    if (canNavAdmin) {
      nav.push(cloneDeep(navAdministration));
      if (canNavOrganizations) {
        nav[NAV_INDEX_ADMINISTRATION].children[0].children = [navAdminOrganizations];
      }
      if (canNavUsers) {
        nav[NAV_INDEX_ADMINISTRATION].children[0].children.push(navAdminUsers);
      }
    }
    if (!canNavRepertoires) {
      nav[NAV_INDEX_APPLICATIONS].children[0].children = [...nav[NAV_INDEX_APPLICATIONS].children[0].children.filter(child => child.id !== 'repertoires')];
    }
    return nav;
  }

  static getRedirectSections(section) {
    return filter(SectionsConfig, sectionData => sectionData.redirectDetail === section).map(sectionData => sectionData.name);
  }

  static setNavigationVisibility(navigationObject: any, currentUrl: string, sectionHomeConfigLocation: SectionModel, section: string) {
    const FORCE_INACTIVE_CLASS = 'force-inactive';
    const isDashboard = `/${currentUrl}` === sectionHomeConfigLocation;
    const currentSectionConfig = CopyrightUtils.getSectionData(section);
    return navigationObject.map(group => {
      return {
        ...group,
        children: (group.children || []).map(child => {
          return {
            ...child,
            children: (child.children || []).map(childrenChild => {
              const sectionChild = cloneDeep(childrenChild);
              if (
                (!isDashboard && sectionChild.url === sectionHomeConfigLocation) ||
                (isDashboard && sectionChild.url === `/${currentSectionConfig.domainName}/${currentSectionConfig.name}`)
              ) {
                sectionChild.classes = sectionChild.classes ? `${sectionChild.classes} ${FORCE_INACTIVE_CLASS}` : FORCE_INACTIVE_CLASS;
              } else if (isDashboard && sectionChild.url === sectionHomeConfigLocation) {
                sectionChild.classes = 'active';
              } else if (sectionChild.classes) {
                if (sectionChild.classes === FORCE_INACTIVE_CLASS) {
                  sectionChild.classes = '';
                } else if (sectionChild.classes.includes(FORCE_INACTIVE_CLASS)) {
                  sectionChild.classes = sectionChild.classes.replace(FORCE_INACTIVE_CLASS, '');
                }
              }
              return { ...sectionChild };
            }),
          };
        }),
      };
    });
  }

  static setBadge(section: string, permissionsService: PermissionsService, badge: number) {
    const currentNavigation = NavigationUtils.getNavigation(permissionsService);
    const navigation = cloneDeep(currentNavigation);
    if (section === SectionsConfig.AGREEMENTCONFLICT.name) {
      const nConflicts = get(currentNavigation, '[0].children[1].children[1]');
      if (nConflicts) {
        navigation[0].children[1].children[1].badge = { title: badge, bg: '#525e8a' };
      }
    }
    return navigation;
  }
}
