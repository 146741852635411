import { createSelector } from '@ngrx/store';
import * as fromFeature from 'store/root/reducers';

import { SocietyDetail } from 'models/copyright/detail/society';
import * as fromReducer from 'store/root/reducers/copyright/copyright.reducer';

export const getCopyrightSociety = createSelector(fromFeature.getCopyrightFeatureState, fromReducer.getCopyrightSocietyState);

export const getSocietyAttributes = createSelector(getCopyrightSociety, (detail: SocietyDetail) => (detail ? detail : null));

export const getSocietyRights = createSelector(getCopyrightSociety, (detail: SocietyDetail) => (detail && detail.rights ? detail.rights : []));
