import { RelationsUtils, TerritoryUtils, WorkUtils, agreementChainsAdapter } from '@ice';
import { SectionsConfig } from 'config/sections-config';
import { ApiCallStatus } from 'config/sections-config/api-call';
import * as _ from 'lodash';
import { cloneDeep, concat, get, isArray } from 'lodash';
import { EditModeHistory } from 'models/copyright/detail/edit-mode';
import { ExportModeType } from 'models/copyright/search/export-mode';
import { UpdateModeType } from 'models/copyright/search/update-mode';
import { FileType } from 'services/report/report.service';
import * as fromActions from 'store/root/actions';
import * as fromState from 'store/root/state';
import { initialCopyrightState } from 'store/root/state';

export function reducer(state: fromState.CopyrightState = fromState.initialCopyrightState, action: fromActions.CopyrightActions): fromState.CopyrightState {
  const shares = cloneDeep(state.shares);
  switch (action.type) {
    case fromActions.GET_ITEM_SUCCESS: {
      const key = Object.keys(action.payload)[0];
      const decorated = { [key]: { ...state[key], ...action.payload[key] } };
      return { ...state, ...decorated, loading: false };
    }
    case fromActions.GET_ITEM_FULL_DATA_END: {
      return { ...state, loading: false };
    }
    case fromActions.CLEAN_DETAIL: {
      const sectionInitObject = initialCopyrightState[action.payload];
      return { ...state, [action.payload]: sectionInitObject };
    }
    case fromActions.GET_WORK_TO_COMPARE: {
      return {
        ...state,
        activity: {
          ...state.activity,
          workToCompare: null,
          selectedOptionToCompare: action.payload.key,
        },
      };
    }
    case fromActions.SET_SELECTED_OPTION_TO_COMPARE: {
      return {
        ...state,
        activity: {
          ...state.activity,
          selectedOptionToCompare: action.payload.key,
        },
      };
    }
    case fromActions.SET_OPTIONS_TO_COMPARE: {
      return {
        ...state,
        activity: {
          ...state.activity,
          optionsToCompare: action.payload.length > 0 && action.payload,
        },
      };
    }
    case fromActions.RESET_WORK_TO_COMPARE:
      return {
        ...state,
        activity: {
          ...state.activity,
          workToCompare: null,
        },
      };
    case fromActions.RETRIEVE_SHARE_PICTURE_FROM_CACHE:
      return { ...state, shares: { ...shares, loading: false } };
    case fromActions.GET_SHARE_PICTURE:
      return { ...state, shares: { ...shares, loading: true } };
    case fromActions.GET_SHARE_PICTURE_SUCCESS: {
      return {
        ...state,
        shares: {
          ...shares,
          sharePictures: { ...action.payload },
          salientSessionId: action.payload?.parameters?.salientSessionId,
        },
        works: { ...state.works, filterSharePicture: { ...action.payload.parameters, country: `${action.payload.parameters.country}` } },
      };
    }
    case fromActions.SAVE_SHARES_DATA: {
      const { share } = action.payload;
      return { ...state, shares: { ...shares, loading: false, share } };
    }
    case fromActions.SET_COUNTERCLAIM_DASHBOARD_SEARCH_FILTER: {
      const filter = action.payload;
      const counterClaims = state['counter-claims'];
      return {
        ...state,
        'counter-claims': {
          ...counterClaims,
          dashboardSearch: { filter },
        },
      };
    }
    case fromActions.CLEAR_SHARE_PICTURE:
      return { ...state, shares: null };
    case fromActions.SET_UPDATE_MODE_BULK: {
      return {
        ...state,
        updateMode: state.updateMode
          ? {
              ...state.updateMode,
              ...action.payload,
            }
          : null,
      };
    }
    case fromActions.AUDIT_FILTER_HISTORY_SUCCESS: {
      const { auditHistory, section, filterQuery, filterValues } = action.payload;
      const sectionStore = {
        ...state[section],
        auditHistory,
        lastAuditFilterQuery: filterQuery,
        auditHistoryFilter: { ...(state[section].auditHistoryFilter || {}), ...filterValues },
      };
      return { ...state, [section]: sectionStore };
    }
    case fromActions.SWITCH_OWNERSHIP_TABLE_DATA: {
      {
        return {
          ...state,
          manuscriptTableSelected: action.payload,
        };
      }
    }
    case fromActions.ASSIGN_OWNERSHIP_TO_ITEMS: {
      if (state.works) {
        return {
          ...state,
          ownershipTableItems: state.works.manuscriptData,
        };
      }
      return state;
    }
    case fromActions.SET_ITEM_VIEW: {
      return { ...state, itemView: action.payload };
    }
    case fromActions.GET_NOTES_SUCCESS: {
      const section = action.payload.section;
      const stateSection = state[section];
      return { ...state, [section]: { ...stateSection, notes: action.payload.response } };
    }
    case fromActions.CLEAR_NOTES: {
      const section = action.payload.section;
      const stateSection = state[section];
      return { ...state, [section]: { ...stateSection, notes: null } };
    }
    case fromActions.CLONE_ITEM_IN_PLACE:
    case fromActions.CREATE_ITEM:
    case fromActions.CLONE_ITEM: {
      if (action && action.payload) {
        const { editId, editName, multipleNamespaces, extraSocPermissions, params } = action && action.payload;
        return { ...state, createMode: { editing: true, editId, editName, multipleNamespaces, extraSocPermissions, params } };
      }
      break;
    }
    case fromActions.SET_EDIT_MODE_FROM_CREATE_MODE:
    case fromActions.FINISH_CLONE_ITEM:
      return { ...state, createMode: null, editMode: { ...state.createMode } };
    case fromActions.RESET_CREATE_MODE: {
      return { ...state, createMode: null };
    }
    case fromActions.FORCE_EDIT_MODE:
    case fromActions.START_EDIT_MODE: {
      const { editId, editName, multipleNamespaces, extraSocPermissions } = action && action.payload;
      return { ...state, editMode: { editing: true, editId, editName, multipleNamespaces, extraSocPermissions } };
    }
    case fromActions.SAVE_EDIT_MODE_HISTORY: {
      const MAX_ITEMS_STORED = 5;
      const MIN_LENGTH_NEEDED_TO_MODIFY_ARRAY = 2;
      const editItem = action && action.payload;
      const items: EditModeHistory[] = [...state.editModeHistory, editItem] || [];
      const reverseItems: EditModeHistory[] = items.reverse();
      const uniqItems: EditModeHistory[] = _.uniqWith(reverseItems, _.isEqual) || [];
      if (uniqItems.length > MIN_LENGTH_NEEDED_TO_MODIFY_ARRAY) {
        const firstItem = uniqItems[0];
        uniqItems.shift();
        uniqItems.reverse();
        uniqItems.unshift(firstItem);
      }
      const createItemsToSave: EditModeHistory[] = WorkUtils.reorderEditHistoryItems(uniqItems.filter((item: EditModeHistory) => item.type === 'create') || [], MAX_ITEMS_STORED);
      const editItemsToSave: EditModeHistory[] = WorkUtils.reorderEditHistoryItems(uniqItems.filter((item: EditModeHistory) => item.type === 'edit') || [], MAX_ITEMS_STORED);
      return { ...state, editModeHistory: [...createItemsToSave, ...editItemsToSave] || [] };
    }
    case fromActions.START_SELECT_MODE: {
      return { ...state, selectionMode: { selection: [], mode: action.payload } };
    }
    case fromActions.CLEAR_SELECT_MODE: {
      return { ...state, selectionMode: { selection: [] } };
    }
    case fromActions.END_SELECT_MODE: {
      return { ...state, selectionMode: null };
    }
    case fromActions.SET_SELECT_MODE: {
      return { ...state, selectionMode: { selection: action.payload, mode: state.selectionMode.mode } };
    }
    case fromActions.START_EXPORT_MODE: {
      return { ...state, exportMode: action.payload };
    }
    case fromActions.START_UPDATE_MODE: {
      return { ...state, updateMode: action.payload };
    }
    case fromActions.END_UPDATE_MODE: {
      return { ...state, updateMode: null };
    }
    case fromActions.END_EXPORT_MODE: {
      return { ...state, exportMode: null };
    }
    case fromActions.SET_EXPORT_BATCH: {
      return {
        ...state,
        exportMode: state.exportMode
          ? {
              ...state.exportMode,
              ...(action.payload.batch && {
                downloadBatch:
                  action.payload.add && state.exportMode.downloadBatch
                    ? [...action.payload.batch, ...(isArray(state.exportMode.downloadBatch) && state.exportMode.downloadBatch)]
                    : action.payload.batch,
              }),
              ...(action.payload.columnLabelDictionary && { columnLabelDictionary: action.payload.columnLabelDictionary }),
              ...(action.payload.visibleColumns && { visibleColumns: action.payload.visibleColumns }),
              ...(action.payload.apiCall && { apiCall: action.payload.apiCall }),
              ...(action.payload.queueChunckSize && { queueChunckSize: action.payload.queueChunckSize }),
            }
          : null,
      };
    }
    case fromActions.EXPORT_DATA: {
      if (Object.values(UpdateModeType).includes(action.payload as UpdateModeType)) {
        return {
          ...state,
          updateMode: state.updateMode ? { ...state.updateMode, updating: true } : null,
        };
      }
      if (Object.values(FileType).includes(action.payload as FileType)) {
        return {
          ...state,
          exportMode: state.exportMode
            ? { ...state.exportMode, downloading: true, fileType: (action.payload as FileType) || (state.exportMode && state.exportMode.fileType) || FileType.CSV }
            : null,
        };
      }
    }
    case fromActions.SET_EXPORT_TYPE: {
      return { ...state, exportMode: state.exportMode ? { ...state.exportMode, downloadType: action.payload as ExportModeType } : null };
    }
    case fromActions.SET_UPDATE_TYPE: {
      return { ...state, updateMode: state.updateMode ? { ...state.updateMode, updateType: action.payload } : null };
    }
    case fromActions.PAUSE_EXPORT_MODE: {
      return { ...state, exportMode: state.exportMode ? { ...state.exportMode, downloading: false } : null };
    }
    case fromActions.RESET_EXPORT_MODE: {
      return {
        ...state,
        exportMode: state.exportMode ? { downloading: false } : null,
      };
    }
    case fromActions.UPDATE_FIELD_SUCCESS: {
      return { ...state, [action.section]: { ...action.value } };
    }
    case fromActions.END_EDIT_MODE:
    case fromActions.END_EDIT_MODE_NEW_ITEM: {
      const { works } = state;
      return {
        ...state,
        works: { ...state.works, editClaim: null, editingClaim: false, editConflictId: null },
        editMode: { editing: false, editId: null, editName: null, multipleNamespaces: null },
        response: null,
      };
    }
    case fromActions.CLEAN_LOADING: {
      return { ...state, loading: false, shares: { ...shares, loading: false } };
    }
    case fromActions.CLEAN_RESPONSE: {
      return { ...state, response: null };
    }
    case fromActions.SAVE_SECTION_ITEM:
      return { ...state, response: null, loading: true };
    case fromActions.SAVE_SECTION_ITEM_SUCCESS:
    // check loading true false
    case fromActions.SAVE_SECTION_ITEM_FAIL:
    case fromActions.UPDATE_IPS_RELATIONS_FAIL:
      return { ...state, loading: false, response: action.payload };
    case fromActions.MARK_POPUP_OK: {
      return { ...state, isPopupOkButtonMarked: true };
    }
    case fromActions.UNMARK_POPUP_OK: {
      return { ...state, isPopupOkButtonMarked: false };
    }
    case fromActions.SET_WORK_ANALYSIS: {
      const { activity } = state;
      return { ...state, activity: { ...activity, workAnalysis: action.payload } };
    }
    case fromActions.RESET_STORE_PARAM: {
      const { section, value } = action.payload;
      const editClaim = get(state[section], 'editClaim');
      if (section === SectionsConfig.WORKS.name && editClaim) {
        return state;
      }
      return { ...state, [section]: value };
    }
    case fromActions.RESET_DELETE_ITEM: {
      return { ...state, deleteItem: { deleting: false, msg: null, error: null } };
    }
    case fromActions.DELETE_ITEM: {
      return { ...state, deleteItem: { ...state.deleteItem, deleting: true } };
    }
    case fromActions.DELETE_ITEM_SUCCESS: {
      return { ...state, deleteItem: { ...state.deleteItem, deleting: false, msg: action.payload } };
    }
    case fromActions.DELETE_ITEM_FAIL: {
      return { ...state, deleteItem: { ...state.deleteItem, deleting: false, error: action.payload } };
    }
    case fromActions.SUBMIT_TERRITORIES_FILTER: {
      const { territories } = state;
      const { includedCountries } = territories;
      return { ...state, territories: { ...territories, territoriesDateFilter: action.payload.filterDate, includedCountries: cloneDeep(includedCountries) } };
    }
    case fromActions.TERRITORY_FILTERED_SUCCESSORS_SUCCESS: {
      const { territories } = state;
      const filteredTerritories = TerritoryUtils.getCleanFilteredTerritorySuccessorsOrPredecessorsByDate(action.payload.territories);
      return { ...state, territories: { ...territories, successorsList: filteredTerritories } };
    }
    case fromActions.TERRITORY_FILTERED_PREDECESSORS_SUCCESS: {
      const { territories } = state;
      const filteredTerritories = TerritoryUtils.getCleanFilteredTerritorySuccessorsOrPredecessorsByDate(action.payload.territories);
      return { ...state, territories: { ...territories, predecessorsList: filteredTerritories } };
    }
    case fromActions.SET_CLAIMS_FILTER_TO_COMPARE: {
      return { ...state, activity: { ...state.activity, claimsFilter: { ...action.payload } } };
    }
    case fromActions.SET_RETENTION_WORKS: {
      return { ...state, agreements: { ...state.agreements, retentionWorks: [...action.payload] } };
    }
    case fromActions.ADD_WORK_RELATION: {
      const { workRelationData, relationType, intExt, relationTypeDisplay } = action.payload;
      return { ...state, works: RelationsUtils.addWorkRelation(state.works, workRelationData, relationType, intExt, relationTypeDisplay) };
    }
    case fromActions.ACTIVATE_USER: {
      const usersState = { ...state.users, status: 'ACTIVE' };
      return { ...state, users: usersState };
    }
    case fromActions.DISABLE_USER: {
      const usersState = { ...state.users, status: 'INACTIVE' };
      return { ...state, users: usersState };
    }
    case fromActions.SET_DUMMY_IP_SUCCESS: {
      return {
        ...state,
        dummyIp: { data: action.payload, status: 'success' },
      };
    }
    case fromActions.SET_DUMMY_IP_ERROR: {
      return {
        ...state,
        dummyIp: { error: action.payload, status: 'error' },
      };
    }
    case fromActions.SET_DUMMY_IP_EMPTY: {
      return {
        ...state,
        dummyIp: null,
      };
    }
    case fromActions.RESEND_ACTIVATION_EMAIL_SUCCESS: {
      const usersState = { ...state.users, status: 'NEW' };
      return { ...state, users: usersState };
    }
    case fromActions.WORK_RESOLUTION: {
      return { ...state, loading: true };
    }
    case fromActions.SET_COPYRIGHT_LOADING: {
      return { ...state, loading: action.payload };
    }
    case fromActions.WORK_RESOLUTION_SUCCESS: {
      return { ...state, loading: false, response: action.payload };
    }
    case fromActions.WORK_RESOLUTION_FAIL: {
      return { ...state, loading: false, response: { status: 'error', error: action.payload } };
    }
    case fromActions.UPLOAD_RESPONSE: {
      return { ...state, response: action.payload };
    }
    case fromActions.BEGIN_EDIT_CLAIM: {
      const { works } = state;
      const { conflictId } = action.payload;
      return { ...state, works: { ...works, editConflictId: conflictId, editingClaim: true } };
    }
    case fromActions.SET_EDIT_CLAIM: {
      const { works } = state;
      const { editClaim } = action.payload;
      return { ...state, works: { ...works, editClaim, editingClaim: true } };
    }
    case fromActions.SAVE_SCROLL_BY_SECTION: {
      const { scroll } = state;
      return { ...state, scroll: { ...scroll, ...action.payload } };
    }
    case fromActions.END_EDIT_CLAIM: {
      const { works } = state;
      return { ...state, works: { ...works, editingClaim: false } };
    }
    case fromActions.SET_ALL_CLAIMS_FILTER: {
      const { works } = state;
      return { ...state, works: { ...works, allClaimsFilter: action.payload } };
    }
    case fromActions.START_API_CALL: {
      return { ...state, apiCallStatus: null };
    }
    case fromActions.ENABLE_DOWNLOAD_DOCUMENT: {
      return { ...state, 'counter-claims': { ...state['counter-claims'], downloadDocumentMode: true } };
    }
    case fromActions.DISABLE_DOWNLOAD_DOCUMENT: {
      return { ...state, 'counter-claims': { ...state['counter-claims'], downloadDocumentMode: false, documentsToDownload: [] } };
    }
    case fromActions.SAVE_DOWNLOAD_DOCUMENTS: {
      return { ...state, 'counter-claims': { ...state['counter-claims'], documentsToDownload: action.payload } };
    }
    case fromActions.GET_REPORT_TYPES_FAIL:
      return { ...state, reports: { error: action.payload } };
    case fromActions.GET_REPORT_TYPES_SUCCESS:
      return { ...state, reports: action.payload };
    case fromActions.CLONE_REPORT_SUCCESS:
      return { ...state, reports: action.payload };
    case fromActions.ENABLE_REJECT_DOCUMENT: {
      return { ...state, 'counter-claims': { ...state['counter-claims'], rejectDocumentMode: true } };
    }
    case fromActions.DISABLE_REJECT_DOCUMENT:
    case fromActions.SEND_REJECT_DOCUMENTS_SUCCESS: {
      return { ...state, 'counter-claims': { ...state['counter-claims'], rejectDocumentMode: false, documentsToReject: [] } };
    }
    case fromActions.SAVE_REJECT_DOCUMENTS: {
      return { ...state, 'counter-claims': { ...state['counter-claims'], documentsToReject: action.payload } };
    }
    case fromActions.FETCH_API_CALL: {
      const mergeTotal = action.payload.apiCallData?.id && (state.apiCallStatus?.mergeTotal || 0) + 1;
      return {
        ...state,
        apiCallStatus: {
          ...(action.payload.apiCallData?.id && action.payload.apiCallData?.id === state.apiCallStatus?.apiCallId && state.apiCallStatus),
          status: ApiCallStatus.StartingRequest,
          ...(action.payload.apiCallData?.id && { apiCallId: action.payload.apiCallData?.id }),
          ...(mergeTotal && { mergeTotal }),
          ...(action.payload.retry && { retryCount: state.apiCallStatus?.retryCount || 0 }),
        },
      };
    }
    case fromActions.API_CALL_RETRY: {
      return {
        ...state,
        apiCallStatus: {
          ...state.apiCallStatus,
          status: (state.apiCallStatus?.status === ApiCallStatus.Cancel && ApiCallStatus.Cancel) || ApiCallStatus.Error,
          error: action.payload.error,
          retryCount: state.apiCallStatus?.retryCount + 1,
        },
      };
    }
    case fromActions.API_CALL_SUCCESS: {
      const { newItems, result, section, apiCallConfig } = action.payload;
      const copyrightGlobal = apiCallConfig.apiCall.copyrightGlobal;
      const mergeCount = apiCallConfig?.apiCallData?.id && apiCallConfig?.apiCallData?.id === state.apiCallStatus.apiCallId && (state.apiCallStatus.mergeCount || 0) + 1;
      const apiCallStatus = { ...state.apiCallStatus, status: ApiCallStatus.Done, retryCount: 0, response: result, newItems, ...(mergeCount && { mergeCount }) };
      return { ...state, ...(!copyrightGlobal ? { [section]: newItems } : { ...newItems }), apiCallStatus };
    }
    case fromActions.API_CALL_ERROR: {
      const { apiCallConfig } = action.payload;
      const mergeCount = apiCallConfig?.apiCallData?.id && apiCallConfig?.apiCallData?.id === state.apiCallStatus.apiCallId && (state.apiCallStatus.mergeCount || 0) + 1;
      const apiCallStatus = { ...state.apiCallStatus, status: ApiCallStatus.Error, error: action.payload.error, retryCount: 0, ...(mergeCount && { mergeCount }) };
      return { ...state, apiCallStatus };
    }
    case fromActions.CANCEL_API_CALL: {
      const { apiCallConfig } = action.payload || {};
      const mergeCount = apiCallConfig?.apiCallData?.id && apiCallConfig?.apiCallData?.id === state.apiCallStatus.apiCallId && (state.apiCallStatus.mergeCount || 0) + 1;
      const apiCallStatus = { ...state.apiCallStatus, status: ApiCallStatus.Cancel, retryCount: 0, ...(mergeCount && { mergeCount }) };
      return { ...state, apiCallStatus };
    }
    case fromActions.API_CALL_CHECK_STATUS_PROCESS: {
      return { ...state, apiCallStatus: { ...state.apiCallStatus, status: ApiCallStatus.CheckingStatus, response: action.payload } };
    }
    case fromActions.SET_WORK_FILTER: {
      return {
        ...state,
        works: { ...state.works, filterSharePicture: { ...state.works.filterSharePicture, ...action.payload } },
      };
    }
    case fromActions.RESET_DIALOG_SEARCH_DATA:
      return { ...state, dialogSearchData: null };
    case fromActions.START_MERGE_IP_MODE: {
      return { ...state, mergeIpMode: true };
    }
    case fromActions.SET_MERGE_IP_ITEMS: {
      return {
        ...state,
        mergeIpItems: action.payload,
      };
    }
    case fromActions.END_MERGE_IP_MODE: {
      return { ...state, mergeIpMode: false, mergeIpItems: undefined, [SectionsConfig.IPS.name]: { ...state[SectionsConfig.IPS.name], mergingIP: false, partyNames: null } };
    }
    case fromActions.START_MERGING_IP:
      return { ...state, [SectionsConfig.IPS.name]: { ...state[SectionsConfig.IPS.name], mergingIP: true } };
    case fromActions.END_MERGING_IP:
      return { ...state, [SectionsConfig.IPS.name]: { ...state[SectionsConfig.IPS.name], mergingIP: false, partyNames: null } };
    case fromActions.SAVE_ALL_USERS:
      return {
        ...state,
        allUsers: action.payload,
      };
    case fromActions.UPSERT_USERS:
      return {
        ...state,
        allUsers: { ...state.allUsers, ...action.payload },
      };
    case fromActions.SAVE_ALL_API_DATA:
      return {
        ...state,
        allApiData: !action.payload ? action.payload : { ...action.payload, items: concat(get(action, 'payload.items', []), get(state, 'allApiData.items', [])) },
      };
    case fromActions.TOGGLE_TRANSFER_IP_WORKS:
      return { ...state, transferIpWorksMode: action.payload.transferIpWorksMode };
    case fromActions.SET_TRANSFER_IP_WORKS_INFO:
      return { ...state, ips: { ...state.ips, transferIpWorksInfo: action.payload ? { ...state.ips.transferIpWorksInfo, ...action.payload } : action.payload } };
    case fromActions.SAVE_ALL_TERRITORIES:
      return { ...state, allTerritories: action.payload };
    case fromActions.UPSERT_AGREEMENT_CHAINS: {
      return { ...state, ips: { ...state.ips, agreementChains: agreementChainsAdapter.upsertMany(action.payload, state.ips.agreementChains) } };
    }
    case fromActions.SORTED_AGREEMENT_CHAINS: {
      // update the tableMap and table with the new order and clean up the inbox array
      return { ...state, ips: { ...state.ips, agreementChains: { ...state.ips.agreementChains, ...action.payload, inbox: { items: [] } } } };
    }
    case fromActions.UPDATE_AGREEMENT_CHAIN: {
      return {
        ...state,
        ips: {
          ...state.ips,
          agreementChains: agreementChainsAdapter.updateOne(action.payload, state.ips.agreementChains),
        },
      };
    }
    case fromActions.IGNORE_MERGE_CLAIMS: {
      return {
        ...state,
        activity: {
          ...state.activity,
          ignoredClaims: action.payload,
        },
      };
    }
  }
  return state;
}

export const getCopyrightWorkState = (state: fromState.CopyrightState) => state.works;
export const getCopyrightAgreementState = (state: fromState.CopyrightState) => state.agreements;
export const getCopyrightAgreementConflictState = (state: fromState.CopyrightState) => state['agreement-conflict'];
export const getCopyrightAgreementGroupState = (state: fromState.CopyrightState) => state['agreement-group'];
export const getCopyrightSaveEditModeItemsState = (state: fromState.CopyrightState) => state.editModeHistory;
export const getCopyrightOwnershipTableItemsState = (state: fromState.CopyrightState) => state.ownershipTableItems;
export const getCopyrightSharePictureState = (state: fromState.CopyrightState) => state.sharePictures;
export const getCopyrightTerritoryState = (state: fromState.CopyrightState) => state.territories;
export const getConflictsActivityState = (state: fromState.CopyrightState) => state.activity;
export const getCounterClaimsState = (state: fromState.CopyrightState) => state['counter-claims'];
export const getCopyrightIpState = (state: fromState.CopyrightState) => state.ips;
export const getManuscriptTableSelectedState = (state: fromState.CopyrightState) => state.manuscriptTableSelected;
export const getCopyrightItemViewState = (state: fromState.CopyrightState) => state.itemView;
export const getCopyrightRepertoireState = (state: fromState.CopyrightState) => state.repertoires;
export const getCopyrightSocietyState = (state: fromState.CopyrightState) => state.societies;
export const getUsersState = (state: fromState.CopyrightState) => state.users;
export const getCopyrightReportsState = (state: fromState.CopyrightState) => state.reports;
export const getUserManagementOrganizationsState = (state: fromState.CopyrightState) => state.organizations;
export const getCopyrightSharesState = (state: fromState.CopyrightState) => state.shares;
export const getCopyrightWorksEditClaimState = (state: fromState.CopyrightState) => state.works && state.works.editClaim;
export const getCopyrightApiCallStatus = (state: fromState.CopyrightState) => state.apiCallStatus;
export const getCopyrightDialogSearchData = (state: fromState.CopyrightState) => state.dialogSearchData;
