import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { IpUtils } from '@ice';
import { DataTableRow, SortInfo } from '@ice/components/data-table/data-table';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import { DataTableBuilder } from 'config/data-table-builders/data-table-builder';
import { RootDatatable } from 'config/data-table-builders/root-datatable';
import { SectionsConfig } from 'config/sections-config';
import { get } from 'lodash';
import * as fromRoot from 'store/root';

export class CopyrightIpsDataTable extends RootDatatable implements DataTableBuilder {
  constructor(protected translate: TranslateService, protected fuseTranslationLoader: FuseTranslationLoaderService, protected store: Store<any>) {
    super(translate);
    this.fuseTranslationLoader.loadTranslations(english);
  }

  getDefaultSorting(): SortInfo[] {
    return [{ prop: 'fullName', dir: 'asc' }];
  }

  formatSort(sort: SortInfo) {
    switch (sort.prop) {
      case 'ipiNameNumber':
      case 'nameKey':
      case 'baseIpiNumbersFormatted':
      case 'prSocietyCodeAndName':
      case 'mrSocietyCodeAndName':
        return null;
      case 'typeOf':
        return { prop: `parties.party.attributes.typeOf.keyword`, dir: sort.dir };
      case 'statusLiteral':
        return { prop: `parties.party.attributes.status.keyword`, dir: sort.dir };
      case 'fullName':
        return [
          { prop: `attributes.name`, dir: sort.dir },
          { prop: `attributes.firstName`, dir: sort.dir },
        ];
      case 'agreementCount':
        return { prop: `agg.attributes.agreementCount`, dir: sort.dir };
      case 'workCount':
        return { prop: `agg.attributes.workCount`, dir: sort.dir };
    }
    return { prop: `attributes.${sort.prop}`, dir: sort.dir };
  }

  getDataTable(): DataTableRow[] {
    return [
      {
        name: this.translate.instant('IPS.NAME'),
        prop: 'fullName',
        flexGrow: 3,
        cellClass: 'ip-name',
        comparator: () => this.serverSideSorting(),
      },
      { name: this.translate.instant('IPS.IPI_NAME_NUMBER'), prop: 'ipiNameNumber', flexGrow: 2, cellClass: 'ip-name-number' },
      { name: this.translate.instant('IPS.REFERENCE'), prop: 'nameKey', flexGrow: 1, cellClass: 'ip-name-key' },
      {
        name: this.translate.instant('IPS.TYPE_OF_NAME'),
        prop: 'typeOfName',
        flexGrow: 1,
        cellClass: 'ip-type-of-name',
        comparator: () => this.serverSideSorting(),
        tooltip: 'tooltipTypeOfName',
      },
      {
        name: this.translate.instant('IPS.BASE_ID'),
        prop: 'baseIpiNumbersFormatted',
        icons: 'baseIpiNumbersIcons',
        flexGrow: 2,
        cellClass: 'ip-base-ipi-number ice-search-results-font-16 cursor-pointer',
      },
      {
        name: this.translate.instant('IPS.TYPE_OF'),
        prop: 'typeOf', // Dummy property name used for the sorting
        icons: 'typeOfIcon',
        flexGrow: 1,
        cellClass: 'ip-type-of ice-search-results-font-16',
        comparator: () => this.serverSideSorting(),
      },
      {
        name: this.translate.instant('IPS.STATUS'),
        prop: 'statusLiteral',
        flexGrow: 1,
        cellClass: 'ip-type-of ice-search-results-font-16',
        comparator: () => this.serverSideSorting(),
      },
      {
        name: this.translate.instant('IPS.PR'),
        prop: 'prSocietyCodeAndName',
        icons: 'prSocietyIcons',
        flexGrow: 2,
        cellClass: 'ip-pr',
        headerTooltip: this.translate.instant('IPS.PR_SOC_TOOLTIP'),
        comparator: (_valueA, _valueB, modelA, modelB) => IpUtils.prOrMrComparator(modelA, modelB, 'prSociety'),
      },
      {
        name: this.translate.instant('IPS.MR'),
        prop: 'mrSocietyCodeAndName',
        icons: 'mrSocietyIcons',
        flexGrow: 2,
        cellClass: 'ip-mr',
        headerTooltip: this.translate.instant('IPS.MR_SOC_TOOLTIP'),
        comparator: (_valueA, _valueB, modelA, modelB) => IpUtils.prOrMrComparator(modelA, modelB, 'mrSociety'),
      },
      {
        name: this.translate.instant('IPS.AGREEMENTS_COUNT'),
        prop: 'agreementCount',
        flexGrow: 1,
        cellClass: 'ip-type-of ice-search-results-font-16',
        comparator: () => this.serverSideSorting(),
        onClickAction: this.goToIpsTab.bind(this, 'agreements'),
      },
      {
        name: this.translate.instant('IPS.WORKS_COUNT'),
        prop: 'workCount',
        flexGrow: 1,
        cellClass: 'ip-type-of ice-search-results-font-16',
        comparator: () => this.serverSideSorting(),
        onClickAction: this.goToIpsTab.bind(this, 'works'),
      },
    ];
  }

  getColumnMode(): ColumnMode {
    return ColumnMode.flex;
  }

  private goToIpsTab(targetTab, row, getPath = false) {
    if (row && row.key) {
      const path = [`${SectionsConfig.IPS.domainName}/${SectionsConfig.IPS.name}/${row.key}/${targetTab}`];
      if (getPath) {
        return path;
      }
      this.store.dispatch(new fromRoot.Go({ path }));
    }
  }

  displayCheck(row: any, firstSelectedItem: any, targetItem: any, column?: any, value?: any) {
    let display = true;
    if (firstSelectedItem) {
      display = this.hasRowSameValue(firstSelectedItem, row, 'typeOf');
    }
    if (display && targetItem) {
      display = !get(row, 'ipiNameNumber') || this.hasRowSameValue(targetItem, row, 'ipiNameNumber');
    }
    return display;
  }

  private hasRowSameValue(item: any, row: any, property: string) {
    return get(item, property) === get(row, property);
  }
}
