import { Injectable } from '@angular/core';
import { PROCESS_DESCRIPTION } from 'config/constants/activity.constants';
import { ExpertSearchParseService } from './expert-search-parse.service';

export enum CaseStatusType {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  AWAITING_REPLY = 'AWAITING_REPLY',
  SUPPORTED = 'SUPPORTED',
  CONFIRMED = 'CONFIRMED',
  RESOLVED = 'RESOLVED',
  CLOSED = 'CLOSED',
}

@Injectable()
export class ExpertSearchParseActivityService extends ExpertSearchParseService {
  constructor() {
    const AttributesMapActivity = {
      activityDeadlineDate: async (comparator: string, value: string, _deep = false) => ({
        [comparator]: { deadline: value },
      }),
      activityUploadCategory: async (comparator: string, value: string, _deep = false) => ({
        wildcard: { 'activityTriggers.uploadCategory': `*${value}*` },
      }),
      assigneeUserId: 'assignee',
      caseAssignedUser: async (comparator: string, value: string, _deep = false) => ({
        wildcard: { assignee: `*${value}*` },
      }),
      conflictStatus: 'caseStatus',
      conflictArea: 'caseArea',
      conflictId: 'conflictId',
      conflictSubType: 'conflictSubtype',
      conflictType: 'conflictType',
      domesticSociety: 'domesticSocieties',
      firstCreator: 'firstCreator',
      value: 'totalValue',
      workTitle: 'title',
      processDescription: 'activityTriggers.processDescription',
    };
    const attributesEnumMapActivity = {
      ['conflictStatus']: Object.keys(CaseStatusType),
      ['processDescription']: PROCESS_DESCRIPTION,
    };
    super(attributesEnumMapActivity, AttributesMapActivity);
  }
}
