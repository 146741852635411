<div class="page-layout simple tabbed full-width carded" data-testid="ice-group-component">
  <div class="ice-p-12" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{ scrollXMarginOffset: 10, scrollYMarginOffset: 10 }">
    <ng-container *ngIf="components && components.length > 0">
      <div *ngFor="let row of components; let componentIndex = index" attr.data-testid="ice-group-component-{{ componentIndex }}">
        <!-- The gap between rows will be the same -->
        <ng-container *ngIf="row.group && row.group.length > 0">
          <div fxLayout="row nowrap" fxLayoutGap="1%">
            <ng-container *ngFor="let comp of row.group" [ngSwitch]="comp.type">
              <span
                *ngIf="debugUi"
                style="
                  position: absolute;
                  background-color: #3370866a;
                  color: rgb(253, 224, 229);
                  z-index: 99999999;
                  border-radius: 4px;
                  padding: 2px;
                  backdrop-filter: blur(0.5px);
                  font-size: 8px;
                "
                >{{ comp.type }}</span
              >
              <ng-container *ngIf="!(comp.isHidden | async)">
                <ng-container *ngSwitchCase="'cardWithForm'">
                  <ice-card-with-formly
                    fxFlex="{{ getFlex(row.group, comp) | async }}"
                    *ngIf="comp.config; let cardForm"
                    [config]="cardForm"
                    class="{{ cardForm.class || '' }}"
                  ></ice-card-with-formly>
                </ng-container>
                <ng-container *ngSwitchCase="'cardWithExpansionList'">
                  <ice-card-with-expansion-list
                    fxFlex="{{ getFlex(row.group, comp) | async }}"
                    *ngIf="comp.config; let cardList"
                    [config]="cardList"
                    class="{{ cardList.class || '' }}"
                  ></ice-card-with-expansion-list>
                </ng-container>
                <ng-container *ngSwitchCase="'cardWithDataTable'">
                  <ice-card-with-data-table
                    fxFlex="{{ getFlex(row.group, comp) | async }}"
                    *ngIf="comp.config; let cardTable"
                    [config]="cardTable"
                    class="{{ cardTable.class || '' }}"
                  ></ice-card-with-data-table>
                </ng-container>
                <ng-container *ngSwitchCase="'card'">
                  <ice-card fxFlex="{{ getFlex(row.group, comp) | async }}" *ngIf="comp.config; let card" [config]="card" class="{{ card.class || '' }}">
                    <ice-group-component [components]="card.content"></ice-group-component>
                  </ice-card>
                </ng-container>
                <ng-container *ngSwitchCase="'progressBar'">
                  <ice-progress-bar-component fxFlex="{{ getFlex(row.group, comp) | async }}" *ngIf="comp.config" [config]="comp.config"></ice-progress-bar-component>
                </ng-container>
                <ng-container *ngSwitchCase="'response-error'">
                  <ice-response-error-component
                    style="justify-content: flex-end"
                    fxFlex="{{ getFlex(row.group, comp) | async }}"
                    *ngIf="comp.config"
                    [config]="comp.config"
                  ></ice-response-error-component>
                </ng-container>
                <ng-container *ngSwitchCase="'table'">
                  <ice-table fxFlex="{{ getFlex(row.group, comp) | async }}" class="{{ comp.config.class || '' }}" [config]="comp.config"></ice-table>
                </ng-container>
                <ng-container *ngSwitchCase="'dataTable'">
                  <ice-data-table
                    fxFlex="{{ getFlex(row.group, comp) | async }}"
                    *ngIf="comp.config; let table"
                    [data]="table.data | async"
                    [hidden]="table.hidden | async"
                    [selected]="table.selected | async"
                    [sorts]="table.sorts"
                    [apiCall]="table.apiCall"
                    [showLoader]="!!table.showLoader"
                    [schema]="table.schema"
                    [sliceLimit]="table.sliceLimit"
                    [dontStrip]="table.dontStrip"
                    [sortConfig]="table.sortConfig"
                    [customClass]="table.customClass"
                    [fixedHeight]="table.fixedHeight"
                    [height]="table.height"
                    [maxHeight]="(table.maxHeight | async) || 300"
                    [rowHeight]="table.rowHeight || 0"
                    [headerHeight]="table.headerHeight"
                    [displayCheck]="table.displayCheck"
                    [displayRadio]="table.displayRadio"
                    [columnMode]="table.columnMode ? table.columnMode : 'force'"
                    [isSelectable]="table.isSelectable || table.onSelect"
                    [isSelectableMode]="table?.isSelectableMode"
                    [selectionType]="isObservable(table.selectionType) ? (table.selectionType | async) : table.selectionType || 'single'"
                    [loadingIndicator]="table.loadingIndicator | async"
                    [messages]="table.messages"
                    [isLoadingData]="table.isLoadingData | async"
                    [shadowed]="table.shadowed"
                    [visibleColumns]="table.visibleColumns"
                    [expandRowsEnabled]="table.expandRowsEnabled"
                    (selectedRowEmit)="table.onSelect && table.onSelect($event)"
                    (mouseWheelClickRowEmit)="table.onMouseSelect && table.onMouseSelect($event)"
                    (sortRowEmit)="table && table.onSort && table.onSort($event)"
                    (allSelected)="table?.onAllSelected && table.onAllSelected($event)"
                    (reorderColumn)="table?.onReorderColumn && table.onReorderColumn($event)"
                    [detailVisibleColumns]="table?.detailVisibleColumns"
                    [detailSorts]="table?.detailSorts"
                    [expandableSchema]="table?.expandableSchema"
                    [expandableProperty]="table?.expandableProperty"
                    [headerTitle]="table?.headerTitle"
                    [headerClass]="table?.headerClass"
                    [addDetailsOnMainDataTable]="table?.addDetailsOnMainDataTable"
                    [totals]="table?.totals"
                    [rowIdentity]="table?.rowIdentity"
                    [selectedKey]="table?.selectedKey"
                  ></ice-data-table>
                </ng-container>
                <ng-container *ngSwitchCase="'dataTableTotals'">
                  <ice-data-table-totals
                    fxFlex="{{ getFlex(row.group, comp) | async }}"
                    *ngIf="comp.config; let dataTablaConfig"
                    [config]="dataTablaConfig"
                    class="{{ dataTablaConfig.class || '' }}"
                  ></ice-data-table-totals>
                </ng-container>
                <ng-container *ngSwitchCase="'buttonsGroup'">
                  <ice-buttons-group
                    fxFlex="{{ getFlex(row.group, comp) | async }}"
                    class="{{ buttonsGroup.class || '' }}"
                    *ngIf="comp.config; let buttonsGroup"
                    style="width: 100%"
                    [config]="buttonsGroup.config"
                    (emitEvent)="buttonsGroup.emitEvent($event)"
                    data-testid="ice-buttons-group"
                  ></ice-buttons-group>
                </ng-container>
                <ng-container *ngSwitchCase="'cardWithShares'">
                  <ice-card-with-shares
                    fxFlex="{{ getFlex(row.group, comp) | async }}"
                    *ngIf="comp.config; let shareConfig"
                    [config]="shareConfig"
                    class="{{ shareConfig.class || '' }}"
                  ></ice-card-with-shares>
                </ng-container>
                <ng-container *ngSwitchCase="'cardWithSharesRepertoire'">
                  <ice-card-with-shares-repertoire
                    fxFlex="{{ getFlex(row.group, comp) | async }}"
                    *ngIf="comp.config; let shareConfig"
                    [config]="shareConfig"
                    class="{{ shareConfig.class || '' }}"
                  ></ice-card-with-shares-repertoire>
                </ng-container>
                <ng-container *ngSwitchCase="'formly'">
                  <ice-form
                    fxFlex="{{ getFlex(row.group, comp) | async }}"
                    *ngIf="comp.config; let formConfig"
                    [resetAvailable]="formConfig.resetAvailable"
                    [submitAvailable]="formConfig.submitAvailable"
                    [button1Available]="formConfig.button1Available"
                    [button1Enabled]="formConfig.button1Enabled"
                    [submitEnabled]="formConfig.submitEnabled"
                    [formBuilder]="formConfig.formBuilder | async"
                    [model]="formConfig.model | async"
                    [extraFormBuilder]="formConfig.extraFormBuilder"
                    [submitLabel]="formConfig.submitLabel"
                    [button1Label]="formConfig.button1Label"
                    [extraActionAvailable]="formConfig.extraActionAvailable"
                    [extraActionColor]="formConfig?.extraActionColor"
                    [extraActionLabel]="formConfig.extraActionLabel"
                    [submitShortcutEnable]="formConfig.submitShortcutEnable"
                    [formInline]="formConfig.formInline"
                    [ngClass]="formConfig.formInline ? 'bg-filter-form' : ''"
                    [ngClass]="formConfig.formClass"
                    [className]="formConfig.className"
                    (submit)="formConfig.submit && formConfig.submit($event)"
                    (emitChange)="formConfig.change && formConfig.change($event)"
                    (emitValidForm)="formConfig.setValidForm && formConfig.setValidForm($event)"
                    (button1Clicked)="formConfig.onButton1 && formConfig.onButton1($event)"
                    (extraActionClick)="formConfig.extraActionClick($event)"
                    (resetClick)="formConfig.onReset && formConfig.onReset($event)"
                    [showSubmit]="formConfig.showSubmit"
                    [button1CustomClass]="formConfig.button1CustomClass"
                    [submitClass]="formConfig.submitClass"
                    [submitButtonIcon]="formConfig.submitButtonIcon"
                    [button1ButtonIcon]="formConfig.button1ButtonIcon"
                  ></ice-form>
                </ng-container>
                <ng-container *ngSwitchCase="'input-upload-file'">
                  <ice-input-upload-file
                    fxFlex="{{ getFlex(row.group, comp) | async }}"
                    *ngIf="comp.config; let fileInput"
                    [title]="fileInput.title"
                    [message]="fileInput.message"
                    [error]="fileInput.error"
                    [hasError]="fileInput.hasError"
                    [placeholder]="fileInput.placeholder"
                    [customPreview]="fileInput.customPreview"
                    [csvMaxHeightStyle]="fileInput.csvMaxHeightStyle"
                    (startReadingFile)="fileInput.startReadingFile && fileInput.startReadingFile($event)"
                    (rawFile)="fileInput.rawFile && fileInput.rawFile($event)"
                    (fileData)="fileInput.fileData && fileInput.fileData($event)"
                  ></ice-input-upload-file>
                </ng-container>
                <ng-container *ngSwitchCase="'stepper'">
                  <ice-stepper
                    fxFlex="{{ getFlex(row.group, comp) | async }}"
                    *ngIf="comp.config; let config"
                    [stepperConfig]="config.stepperConfig"
                    (emitChange)="config.change && config.change($event)"
                    (emitValidForm)="config.setValidForm && config.setValidForm($event)"
                    #stepperElement
                  ></ice-stepper>
                </ng-container>

                <ng-container *ngSwitchCase="'treeView'">
                  <ice-tree-chart
                    fxFlex
                    *ngIf="comp.config | async; let config"
                    [_data]="config.treeData"
                    [verticalLevels]="config.vLevel"
                    [horizontalLevels]="config.maxHLevel"
                    [addAdvancedInfoLabels]="true"
                    [expanseCollapseIcons]="true"
                    [expanseDisabledRootContextMenu]="config.to?.expanseDisabledRootContextMenu"
                    [rootNodeDefaultName]="config.to?.rootNodeDefaultName"
                    [disableExpanseCollapseMode]="to?.disableExpanseCollapseMode"
                  ></ice-tree-chart>
                </ng-container>
                <ng-container *ngSwitchCase="'htmlTemplate'">
                  <ice-html-template
                    fxFlex
                    *ngIf="comp.config; let config"
                    [htmlTemplate]="config.htmlTemplate"
                    [htmlClass]="config.htmlClass"
                    [buttons]="config.buttons"
                  ></ice-html-template>
                </ng-container>
                <ng-container *ngSwitchCase="'card-basic'">
                  <ice-card-basic *ngIf="comp.config; let config" [config]="config" class="ice-w-100">
                    <ice-group-component *ngIf="config.content" [components]="config.content"></ice-group-component>
                  </ice-card-basic>
                </ng-container>
                <ng-container *ngSwitchCase="'analysisAndComparisons'">
                  <ice-analysis-and-comparisons fxFlex *ngIf="comp.config; let config" [components]="config"></ice-analysis-and-comparisons>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
