import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ConflictUtils, CopyrightUtils, SearchItemCleaned, SearchResult, SearchUtils, StringUtils } from '@ice';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { LocalStorageUtils } from '@ice/utils/local-storage/localstorage.utils';
import { TabsUtils } from '@ice/utils/tabs/tab.utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Dictionary } from '@ngrx/entity';
import * as fromRouter from '@ngrx/router-store';
import { RouterNavigatedAction } from '@ngrx/router-store';
import { Action, Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import { ActionStatus, OVERCLAIM_WITH_COUNTERCLAIM_FLAG_KEY } from 'config/constants/activity.constants';
import { AUTO_SEARCH_SECTIONS, EMPTY_SEARCH_ERROR } from 'config/constants/global.constants';
import { DEFAULT_OVERWRITE_CONFLICT_QUERY, DEFAULT_OVERWRITE_QUERY, DEFAULT_SEARCH_FROM, DEFAULT_SEARCH_SIZE, XREF_LIST } from 'config/constants/search.constants';
import { environment } from 'config/env';
import { SectionsConfig } from 'config/sections-config';
import { sections } from 'config/sections-config/sections';
import { cloneDeep, dropRight, get, isBoolean, isEmpty, omitBy, pickBy } from 'lodash';
import { ExportMode } from 'models/copyright/search/export-mode';
import { SelectMode } from 'models/copyright/search/select-mode';
import { UpdateMode } from 'models/copyright/search/update-mode';
import { OrganizationCaseStatusType } from 'models/copyright/search/user-management-organization-search';
import { BehaviorSubject, EMPTY, Observable, forkJoin, from, of, throwError } from 'rxjs';
import { catchError, concatMap, filter, flatMap, map, mergeMap, reduce, retry, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { SaveItemService } from 'services/save-item/save-item.service';
import { ExpertSearchService } from 'services/search/expert-search.service';
import { SearchService } from 'services/search/search.service';
import * as fromRoot from 'store/root';
import { UpdateConflictsSuccess } from 'store/root';
import { SearchUtilsFactory } from 'store/root/utils/search';
import * as uuid from 'uuid';
import { CommonApiService } from '../../../../services/common-api.service';
import * as fromActions from '../../actions';
import { UpdateConflictsFail } from '../../actions';
import * as fromSelectors from '../../selectors';
import { getExportMode } from '../../selectors';
import * as fromSearch from '../../state';
import { SearchState } from '../../state';
import { errorMessageShowtime } from '../ui/show-error';

@Injectable()
export class SearchEffects {
  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.FETCH_SEARCH_PAGE),
      map((action: fromActions.FetchSearchPage) => action.payload),
      withLatestFrom(
        this.store.pipe(select(fromRoot.getRouterSection)),
        this.store.pipe(select(fromRoot.getUserOrganizations)),
        this.store.pipe(select(fromRoot.getGlobalApiNamespace)),
        this.store.pipe(select(fromRoot.getUserNS)),
        this.store.pipe(select(fromSelectors.getSearchResultsForceCancel)),
        this.store.pipe(select(fromRoot.getUserCurrentOrganization)),
        this.store.pipe(select(fromRoot.getAllUsers)),
      ),
      filter(([fetchPayload, section, organizations, ns, userNs, cancel, currentOrganization, allUsers]) => !cancel),
      flatMap(([fetchPayload, section, organizations, ns, userNs, cancel, currentOrganization, allUsers]) => {
        return this.expertSearchService.parseSearchQuery(cloneDeep(fetchPayload), section, organizations).pipe(
          map(([payload]) => this.searchService.getSearchParams(payload, section, ns, userNs)),
          switchMap(({ addRows, downloadQueue, queryParams, sectionData, expertQueryParams, searchNs, pageFrom, size, sort, xrefList, search }) => {
            const searchParams = {
              ...queryParams,
              expertQueryParams,
              ns: searchNs,
              size: downloadQueue ? (pageFrom + 100 > 10000 ? 10000 - pageFrom : 100) : size,
              from: pageFrom,
              sort,
              xrefList,
            };
            let cleanSearchParams = searchParams;
            if (searchParams?.organizationId && currentOrganization?.id !== 'ICE:ICE') {
              cleanSearchParams = { ...searchParams, organizationId: currentOrganization?.id };
            }
            if (
              cleanSearchParams?.expertQueryParams?.and &&
              cleanSearchParams?.expertQueryParams?.and[0]?.equals &&
              cleanSearchParams?.expertQueryParams?.and[0]?.equals['roles.organizationId'] &&
              currentOrganization?.id !== 'ICE:ICE'
            ) {
              cleanSearchParams.expertQueryParams.and[0].equals = { ...cleanSearchParams.expertQueryParams.and[0].equals, 'roles.organizationId': currentOrganization?.id };
            }
            return this.searchService.getSearchResults(sectionData, cleanSearchParams, currentOrganization, allUsers).pipe(
              withLatestFrom(this.store.pipe(select(getExportMode)), this.store.select(fromRoot.getUpdateMode), this.store.pipe(select(fromRoot.getRouterSection))),
              mergeMap(([data, exportMode, updateMode, currentSection]: [SearchResult, ExportMode, UpdateMode, string]) => {
                if (section !== currentSection) {
                  return EMPTY;
                }

                if (section === SectionsConfig.WORKS.name && searchParams.agreementId && !searchParams.contributorsAgreementId && data.total && data.total > 0) {
                  const newSearch = search;
                  newSearch['params']['contributorsAgreementId'] = get(data, 'items[0].id');
                  return of(new fromActions.FetchSearchPage({ search: newSearch, notSaveParams: true }));
                }
                const dataItems = (data && data.items) || data.data || data.territories || data || [];
                const cleanedItems: SearchItemCleaned[] = sections[section].search.cleaner.parse(
                  dataItems,
                  this.translate,
                  {
                    search,
                    queryParams,
                    organizations,
                    userNs,
                    allUsers,
                  },
                  null,
                  this.store,
                );
                const { items, ...callExtra } = data;
                const extra = callExtra.total == null ? { total: SearchUtils.getSearchTotalLength(data, section) } : callExtra;

                const sectionDataInfo = CopyrightUtils.getSectionData(section);
                const avoidRedirect = get(sectionDataInfo, 'avoidSearchRedirect', false);
                const autoSelectActions = cleanedItems.length === 1 && !addRows && !avoidRedirect ? [new fromRoot.SelectSearchItem(cleanedItems[0])] : [];

                if (downloadQueue) {
                  if (isEmpty(cleanedItems)) {
                    return throwError(EMPTY_SEARCH_ERROR);
                  }
                  if (exportMode?.downloading) {
                    return of(new fromRoot.SetExportModeBatch({ batch: cleanedItems, add: true }), new fromRoot.GetDataFromQueue());
                  }
                  if (updateMode?.updating) {
                    return of(new fromRoot.SetUpdateModeBulk({ items: updateMode.items.concat(cleanedItems) }), new fromRoot.GetDataFromQueue());
                  }

                  return of<Action>(new fromActions.FetchSearchPageSuccess({ items: cleanedItems, extra, addRows }), ...autoSelectActions);
                }

                return of<Action>(new fromActions.FetchSearchPageSuccess({ items: cleanedItems, extra, addRows }), ...autoSelectActions);
              }),
              catchError(error => {
                if (downloadQueue) {
                  return of(new fromActions.PauseExportMode());
                }
                return of(new fromActions.FetchSearchPageFail(error));
              }),
            );
          }),
        );
      }),
    ),
  );

  searchSimple$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.SEARCH_SIMPLE),
      map((action: fromActions.SearchSimple) => [action.payload, action.searchSection]),
      withLatestFrom(this.store.pipe(select(fromSelectors.getRouterMainPath))),
      map(([[termRaw, section], path]) => {
        return new fromRoot.Go({
          path: [path, sections[section].search.searchSection || section, 'search'],
          extras: {
            queryParams: { term: StringUtils.removeExtraSpaces(termRaw), ...SearchUtils.getSAYTDefaultExtraSearchFields(section), searchSimple: true },
          },
        });
      }),
    ),
  );

  searchAdvanced$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.SEARCH_ADVANCED),
      map((action: fromActions.SearchAdvanced) => [action.payload, action.searchSection]),
      withLatestFrom(this.store.pipe(select(fromSelectors.getRouterMainPath))),
      switchMap(([[params, section], path]: [any, string]) => {
        params = params && pickBy(params, (value, key) => !/\bxref.*[0-9]\b/.test(key));
        return of(
          new fromRoot.AdvancedSearchToggle(true),
          new fromRoot.Go({
            path: [path, sections[section].search.searchSection || section, 'search'],
            extras: {
              queryParams: omitBy(params, val => !val && !isBoolean(val)),
            },
          }),
        );
      }),
    ),
  );

  searchExpert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.SEARCH_EXPERT),
      map((action: fromActions.SearchExpert) => [action.payload, action.searchSection]),
      withLatestFrom(this.store.pipe(select(fromSelectors.getRouterMainPath))),
      switchMap(([[query, section], path]: [any, string]) => {
        return of(
          new fromRoot.AdvancedSearchToggle(true),
          new fromRoot.Go({
            path: [path, section, 'search'],
            extras: { queryParams: { query, expert: true } },
          }),
        );
      }),
    ),
  );

  searchReset$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.SearchExpert>(fromActions.SEARCH_RESET),
      withLatestFrom(this.store.pipe(select(fromSelectors.getRouterPaths)), this.store.pipe(select(fromSelectors.getRouterView))),
      switchMap(([action, routerPaths, routerView]) => {
        const section = action.searchSection;
        const route = routerView === 'SEARCH' ? dropRight(routerPaths).join('/') : routerPaths.join('/');
        const path = [route];
        return of(new fromRoot.ResetSearchResults({ section, advancedSearchDefaults: null }), new fromRoot.AdvancedSearchToggle(true), new fromRoot.Go({ path }));
      }),
    ),
  );

  triggerSearchOnRouteSectionChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRouter.ROUTER_NAVIGATED),
      map((action: RouterNavigatedAction<any>) => action.payload),
      withLatestFrom(
        this.store.pipe(select(fromSelectors.getUserDetail)),
        this.store.pipe(select(fromSelectors.getSearchHistory)),
        this.store.pipe(select(fromSelectors.isSearchReseted)),
      ),
      filter(
        ([
          {
            routerState: { view = '' },
          },
        ]) => view === 'HOME',
      ),
      switchMap(
        ([
          {
            routerState: { section = '' },
          },
          user,
          history,
          searchReseted,
        ]) => {
          const sectionData = CopyrightUtils.getSectionData(section);
          const { name, homeRedirect, domainName } = sectionData;
          const historySection = history && history[section];
          const advancedSearchDefaults: Object = {};
          switch (section) {
            case SectionsConfig.USERS.name:
              if (!searchReseted) {
                advancedSearchDefaults['organizationId'] = user && user.organizationId;
              }
              break;
            case SectionsConfig.CONFLICTS.name:
              if (!searchReseted) {
                advancedSearchDefaults['userId'] = user && user.id;
                advancedSearchDefaults[OVERCLAIM_WITH_COUNTERCLAIM_FLAG_KEY] = true;
              }
              break;
            case SectionsConfig.COUNTERCLAIMS_ACTIONS.name:
              if (!searchReseted) {
                advancedSearchDefaults['actionOwner'] = 'ICE';
                advancedSearchDefaults['actionStatus'] = [ActionStatus.OPEN, ActionStatus.EXPIRED];
              }
          }
          if (historySection) {
            const { term, params, query } = historySection;
            return of(
              new fromRoot.Go({
                path: [`${domainName}/${name}/search`],
                extras: { queryParams: term ? { term } : query && isEmpty(params) ? { expert: true, query } : params },
              }),
            );
          } else if (homeRedirect) {
            return of(
              new fromRoot.ResetSearchResults({ section, advancedSearchDefaults }),
              new fromRoot.Go({
                path: [`${domainName}/${name}/${homeRedirect}`],
                extras: { queryParams: advancedSearchDefaults },
              }),
            );
          }
          return of(new fromRoot.ResetSearchResults({ section, advancedSearchDefaults }));
        },
      ),
    ),
  );

  triggerSearchOnRouteSectionChangeDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRouter.ROUTER_NAVIGATED),
      map((action: RouterNavigatedAction<any>) => action.payload),
      filter(({ routerState: { view = '' } }) => view === 'DASHBOARD'),
      switchMap(({ routerState: { section = '' } }) => {
        const apiCalls = sections[section]?.homeConfig?.apiCalls;
        return [new fromRoot.ResetSearchResults({ section, advancedSearchDefaults: {} }), ...(apiCalls && [new fromRoot.StartApiCall(apiCalls)])];
      }),
    ),
  );

  resetSearchPageWhenRouteParamsCleared$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRouter.ROUTER_NAVIGATED),
      map((action: RouterNavigatedAction<any>) => action.payload),
      withLatestFrom(this.store.pipe(select(fromSelectors.getUserDetail)), this.store.pipe(select(fromRoot.getCopyrightWork))),
      filter(
        ([
          {
            routerState: { view = '', section = '', queryParams = {} },
          },
        ]) =>
          // seach results page
          view === 'SEARCH' &&
          // and no search params
          Object.keys(queryParams).length === 0 &&
          // and not on cases page (cases show content also with no search params)
          ['counter-claims', 'activity', 'agreement-conflict'].indexOf(section) === -1,
      ),
      map(
        ([
          {
            routerState: { section = '', queryParams: queryParamsRaw = '' },
          },
          user,
          work,
        ]) => {
          const advancedSearchDefaults: Object = {};
          switch (section) {
            case SectionsConfig.USERS.name:
              advancedSearchDefaults['organizationId'] = user && user.organizationId;
              break;
            case SectionsConfig.CONFLICTS.name:
              advancedSearchDefaults['userId'] = user && user.id;
              break;
            case SectionsConfig.AGREEMENTCONFLICT.name:
              advancedSearchDefaults['entityType'] = 'agreement';
              break;
            case SectionsConfig.COUNTERCLAIMS_ACTIONS.name:
            case SectionsConfig.COUNTERCLAIMS.name:
              advancedSearchDefaults['entityType'] = 'work';
              break;
          }
          if (section === SectionsConfig.CONFLICTS.name && work && work.editClaim) {
            return null;
          }
          return new fromRoot.ResetSearchResults({ section, advancedSearchDefaults });
        },
      ),
    ),
  );

  triggerSearchPageLoadWhenRouteParamsUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRouter.ROUTER_NAVIGATED),
      map((action: RouterNavigatedAction<any>) => action.payload),
      withLatestFrom(this.store.select(fromSelectors.getSearchXrefList)),
      filter(
        ([
          {
            routerState: { view = '', section = '', queryParams = {} },
          },
        ]) => view === 'SEARCH' && (Object.keys(queryParams).length > 0 || AUTO_SEARCH_SECTIONS.indexOf(section) !== -1),
      ),
      switchMap(
        ([
          {
            routerState: { section = '', queryParams: queryParamsRaw = '' },
          },
          xrefList,
        ]) => {
          const xrefListStorage = LocalStorageUtils.getObject(XREF_LIST);
          // when user writes a term in the searchbar, we ignore the xrefList from local storage and proceed with the search
          if (!queryParamsRaw.term) {
            if (xrefListStorage?.length) {
              LocalStorageUtils.removeXrefList();
              return of(new fromRoot.SaveXrefAndNavigate({ xref: xrefListStorage }));
            }
            if (xrefList) {
              // we save temporarily the xrefList in local storage to be able to use it in the search.
              // next loop will remove it from local storage and SaveXrefAndNavigate
              LocalStorageUtils.setXrefList(xrefList);
            }
          }
          LocalStorageUtils.removeXrefList();

          const { expert, term, params, search, sectionData } = this.searchService.formatSearchParamsBySearchMode(section, queryParamsRaw, xrefList);
          const nextActions = [
            new fromActions.EmptyResultOnSearchChange({
              search: { term, params, size: DEFAULT_SEARCH_SIZE, from: DEFAULT_SEARCH_FROM, sort: sectionData.searchServerSideSortDefault },
              section,
            }),
            new fromActions.FetchSearchPage({ search }),
          ] as const;

          if (expert) {
            return of(new fromRoot.ExpertSearchModeSet(true), ...nextActions);
          }
          return of(...nextActions);
        },
      ),
    ),
  );

  onSearchSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.FETCH_SEARCH_PAGE_SUCCESS),
      withLatestFrom(this.store.pipe(select(fromSelectors.getRouterSection))),
      map(([__, section]) => new fromActions.SaveSearchToHistory(section)),
    ),
  );

  selectSearchItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.SELECT_SEARCH_ITEM),
        map((action: fromActions.SelectSearchItem) => ({ newItem: action.payload, openInNewTab: action.openInNewTab })),
        withLatestFrom(
          this.store.pipe(select(fromSelectors.getSearchResultsSelectedItem)),
          this.store.pipe(select(fromSelectors.getRouterSection)),
          this.store.pipe(select(fromSelectors.getSearchResultsInputParams)),
        ),
        filter(([config, selectedItem, section, params]) => (config && !!config.newItem) || !!selectedItem),
        map(([config, selectedItem, section, params]) => {
          const { newItem, openInNewTab } = config;
          const item = newItem || selectedItem;
          let sectionData = CopyrightUtils.getSectionData(section);
          const newItemKey = (sectionData.itemId && item[sectionData.itemId]) || item.key || item.id;
          // prevent going to detail and show error if no search item has no key
          // TODO - section must be deleted when key is returned for all copyright types
          if (!newItemKey && (section === SectionsConfig.WORKS.name || section === SectionsConfig.IPS.name)) {
            this.store.dispatch(new fromActions.SelectedSearchItemMissingKey());
            return false;
          }
          if (item) {
            if (sectionData.redirectDetail) {
              section = sectionData.redirectDetail;
              sectionData = CopyrightUtils.getSectionData(sectionData.redirectDetail);
            }
            const { domainName, name } = sectionData;
            // Details view might need to know some search params to show correctly, example: Territory tisa and tisaExt
            const query = {};
            if (sectionData.filterDetailParams) {
              const possibleDetailParams = sectionData.filterDetailParams;
              Object.keys(params).forEach(key => {
                if (possibleDetailParams.find(param => param === key)) {
                  query[key] = params[key];
                }
              });
            }
            if (section === SectionsConfig.CONFLICTS.name) {
              this.store.dispatch(new fromActions.GoToConflict({ conflict: { ...item, id: item?.conflictId }, openInNewTab }));
            } else {
              const { redirectPath } = item || '';
              let path: string[];
              if (redirectPath) {
                path = redirectPath(item, true);
              } else {
                const defaultTabName = TabsUtils.getDefaultTabName(section);
                path = [`${domainName}/${name}/${newItemKey}/${defaultTabName}`];
              }
              if (path) {
                if (openInNewTab) {
                  this.store.dispatch(new fromActions.OpenNewTab({ path }));
                } else {
                  this.store.dispatch(new fromActions.Go({ path, query }));
                }
              }
            }
          }
        }),
      ),
    { dispatch: false },
  );

  assignSelectedActionsToUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.ASSIGN_SELECTED_ACTIONS_TO_USER),
      withLatestFrom(
        this.store.pipe(select(fromRoot.getSearchSelectedRows)),
        this.store.pipe(select(fromSelectors.getSelectMode)),
        this.store.pipe(select(fromSelectors.getRouterUrl)),
      ),
      switchMap(([a, elemsBySection, selectMode, path]: [Action, fromRoot.SelectedSearchResultsBySection, SelectMode, any]) =>
        from(cloneDeep(selectMode.selection)).pipe(
          mergeMap(action =>
            this.commonApiService
              .putRequestCubeData<HttpResponse<any>>(
                `${environment.apiUrlCubeData}/actions/CUBE/${action.id}`,
                DEFAULT_OVERWRITE_QUERY,
                JSON.stringify(ConflictUtils.getAssignActionPayload(elemsBySection.id, action)),
              )
              .pipe(
                reduce((prevResponse, response) => response),
                tap(() => this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([path]))),
                map(() => new fromRoot.EndSelectMode()),
                catchError(error => of(new fromRoot.UpdateConflictsFail(error))),
              ),
          ),
        ),
      ),
    ),
  );

  assignSelectedConflictsToUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.ASSIGN_SELECTED_CONFLICTS_TO_USER),
      withLatestFrom(
        this.store.pipe(select(fromRoot.getSearchSelectedRows)),
        this.store.pipe(select(fromSelectors.getSelectMode)),
        this.store.pipe(select(fromSelectors.getConflictCaseType)),
      ),
      switchMap(([a, elemsBySection, selectMode, conflictCase]: [Action, fromRoot.SelectedSearchResultsBySection, SelectMode, any]) => {
        if (elemsBySection) {
          const { id } = elemsBySection;
          const conflicts = cloneDeep(selectMode.selection);
          const updateConflictRequests: Observable<any>[] = [];

          // Assign user
          conflicts.forEach(conflict => {
            if (conflict.case) {
              conflict.case.userId = id;
            }
            const request = of(conflict);
            const overwriteMode = conflict ? DEFAULT_OVERWRITE_QUERY : DEFAULT_OVERWRITE_CONFLICT_QUERY;

            const conflictStatus = () => {
              if (!conflict) {
                return undefined;
              }
              if (conflictCase) {
                return conflictCase;
              }
              if (conflict.conflictStatus.length > 0) {
                return conflict.conflictStatus[0].value === OrganizationCaseStatusType.NEW ? OrganizationCaseStatusType.IP : conflict.conflictStatus[0].value;
              }
              return undefined;
            };

            updateConflictRequests.push(
              request.pipe(
                concatMap(conf => {
                  const endpointUrl = `${environment.apiUrlCubeData}/conflicts/CUBE/` + conf.conflictId;
                  const updateObject = {
                    case: {
                      userId: id,
                      status: conflictStatus(),
                    },
                  };
                  return this.commonApiService
                    .putRequestCubeData<HttpResponse<any>>(endpointUrl, overwriteMode, JSON.stringify(updateObject))
                    .pipe(catchError(error => of(new fromRoot.UpdateConflictsFail(error))));
                }),
              ),
            );
          });
          this.store.dispatch(new fromRoot.SetUpdateConflictType(null));
          return forkJoin(updateConflictRequests);
        }
        return null;
      }),
      filter(response => !!response),
      withLatestFrom(this.store.pipe(select(fromSelectors.getRouterPaths)), this.store.pipe(select(fromSelectors.getRouterQueryParams))),
      map(([response, path, query]) => {
        this.store.dispatch(new fromRoot.EndSelectMode());
        setTimeout(() => {
          this.store.dispatch(new fromRoot.Go({ path, query, forceReload: true }));
        }, 1000);
        return response;
      }),
      map(response => {
        if (response.length && response[0] instanceof UpdateConflictsFail) {
          return new fromRoot.UpdateConflictsFail(null);
        }
        return new fromRoot.UpdateConflictsSuccess(response);
      }),
    ),
  );

  updateConflictsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.UPDATE_CONFLICTS_SUCCESS),
      withLatestFrom(this.store.pipe(select(fromRoot.getSearchResultsEntities)), this.store.pipe(select(fromRoot.getSearchResultsExtra))),
      switchMap(([conflictUpdated, results, extra]: [UpdateConflictsSuccess, Dictionary<any>, SearchState]) => {
        this.store.dispatch(
          new fromActions.ShowSnackBar({
            icon: 'done',
            message: this.translate.instant('UPDATE_CONFLICTS_SUCCESS'),
            duration: errorMessageShowtime.normal,
            position: {
              horizontalPosition: 'center',
              verticalPosition: 'top',
            },
          }),
        );

        const _results = cloneDeep(results);

        conflictUpdated.payload.forEach(conflict => {
          if (conflict.case && _results[conflict.id]) {
            const { userId, status } = conflict.case;
            _results[conflict.id].case = conflict.case;
            _results[conflict.id].assignee = userId;
            _results[conflict.id].caseStatus = status;
          }
        });

        return of(new fromRoot.ResetWorkToCompare(), new fromActions.FetchSearchPageSuccess({ items: Object.values(_results), extra }));
      }),
    ),
  );

  OpenUploadXrefDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromRoot.OPEN_UPLOAD_XREF_DIALOG),
        withLatestFrom(this.store.pipe(select(fromSelectors.getRouterMainPath)), this.store.pipe(select(fromSelectors.getRouterSection))),
        map(([__, path, section]) => {
          let xref;
          const xrefSubject = new BehaviorSubject(true);
          const dialogRef = this.dialog.open(DialogMultiLayoutComponent, {
            data: {
              layouts: [
                {
                  title: of(this.translate.instant('XREF_LIST_POPUP.TITLE')),
                  actions: [
                    { tooltip: this.translate.instant('XREF_LIST_POPUP.CANCEL'), color: 'warn', nextLayout: 0, icon: 'close', onClick: () => dialogRef.close() },
                    {
                      tooltip: this.translate.instant('XREF_LIST_POPUP.CONFIRM'),
                      nextLayout: 1,
                      disabled: xrefSubject,
                      icon: 'done',
                      onClick: () => {
                        if (xref) {
                          dialogRef.close();
                          this.store.dispatch(new fromRoot.AdvancedSearchToggle(true));
                          this.store.dispatch(new fromRoot.SetSearchXrefList(xref));
                          this.store.dispatch(
                            new fromRoot.Go({
                              path: [path, section, 'search'],
                              extras: { queryParams: { xrefList: uuid.v4().replace(/-/g, '') } },
                            }),
                          );
                        }
                      },
                    },
                  ],
                  layout: [
                    {
                      group: [
                        {
                          type: 'input-upload-file',
                          config: {
                            title: this.translate.instant('XREF_LIST_POPUP.MSG'),
                            placeholder: this.translate.instant('XREF_LIST_POPUP.PLACEHOLDER'),
                            message: this.translate.instant('XREF_LIST_POPUP.MESSAGE'),
                            fileData: data => {
                              xref = SearchUtilsFactory.getXrefFromData(data);
                              if (xref) {
                                xrefSubject.next(false);
                              }
                            },
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          });
        }),
      ),
    { dispatch: false },
  );

  updateConflictsFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromRoot.UPDATE_CONFLICTS_FAIL),
        map((err: Error) => {
          this.store.dispatch(
            new fromActions.ShowSnackBar({
              icon: 'warning',
              message: err.message || this.translate.instant('UPDATE_CONFLICTS_FAIL'),
              duration: errorMessageShowtime.normal,
              position: {
                horizontalPosition: 'center',
                verticalPosition: 'top',
              },
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  emptyResultsOnSearchChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.EMPTY_RESULTS_ON_SEARCH_CHANGE),
      map((action: any) => {
        return new fromRoot.MarkSearchItem({ value: [], section: action.payload.section });
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private searchService: SearchService,
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromSearch.SearchState>,
    private commonApiService: CommonApiService,
    private expertSearchService: ExpertSearchService,
    private saveItemService: SaveItemService,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
  }
}
