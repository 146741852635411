import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { BUTTON_DEFAULT_COLOR, HtmlButton } from './html-template.model';

@Component({
  selector: 'ice-html-template',
  templateUrl: './html-template.component.html',
  styleUrls: ['./html-template.component.scss'],
})
export class HtmlTemplateComponent {
  @Input() htmlTemplate: Observable<string>;
  @Input() htmlClass: string;
  @Input() buttons: HtmlButton[];

  buttonDefaultColor = BUTTON_DEFAULT_COLOR;
}
