import { NgModule } from '@angular/core';
import { ExpertSearchParseActivityService } from './expert-search-parse-activity.service';
import { ExpertSearchParseAgreementConflictsService } from './expert-search-parse-agreement-conflicts.service';
import { ExpertSearchParseAgreementGroupService } from './expert-search-parse-agreement-group.service';
import { ExpertSearchParseAgreementsService } from './expert-search-parse-agreements.service';
import { ExpertSearchParseCounterClaimsActionsService } from './expert-search-parse-counter-claims-actions.service';
import { ExpertSearchParseCounterClaimsService } from './expert-search-parse-counter-claims.service';
import { ExpertSearchParseEmptyService } from './expert-search-parse-empty.service';
import { ExpertSearchParseIpsService } from './expert-search-parse-ips.service';
import { ExpertSearchParseOrganizationsService } from './expert-search-parse-organizations.service';
import { ExpertSearchParseRepertoiresService } from './expert-search-parse-repertiores.service';
import { ExpertSearchParseReportsService } from './expert-search-parse-reports.service';
import { ExpertSearchParseSocietiesService } from './expert-search-parse-societies.service';
import { ExpertSearchParseUsersService } from './expert-search-parse-users.service';
import { ExpertSearchParseWorksService } from './expert-search-parse-works.service';
import { ExpertSearchService } from './expert-search.service';
import { SearchService } from './search.service';

@NgModule({
  providers: [
    ExpertSearchService,
    ExpertSearchParseWorksService,
    ExpertSearchParseAgreementsService,
    ExpertSearchParseAgreementGroupService,
    ExpertSearchParseAgreementConflictsService,
    ExpertSearchParseIpsService,
    ExpertSearchParseCounterClaimsActionsService,
    ExpertSearchParseRepertoiresService,
    ExpertSearchParseActivityService,
    ExpertSearchParseEmptyService,
    ExpertSearchParseOrganizationsService,
    ExpertSearchParseSocietiesService,
    ExpertSearchParseUsersService,
    ExpertSearchParseCounterClaimsService,
    ExpertSearchParseReportsService,
    SearchService,
  ],
})
export class ExpertSearchModule {}
