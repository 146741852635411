import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { DatatableTypeComponent } from '@ice';
import { IceMaterialModule } from '@ice/material.module';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  declarations: [DatatableTypeComponent],
  imports: [TranslateModule, FuseSharedModule, IceMaterialModule, FormlyMaterialModule, NgxDatatableModule, FormlyModule.forRoot()],
  exports: [DatatableTypeComponent],
})
export class DatatableTypeModule {}
