import { RelationsUtils } from '@ice';
import { CounterClaimStatus } from '@ice/components/expert-search-form/model/enums';
import { TranslateService } from '@ngx-translate/core';
import { ActionStatus, CounterClaimType } from 'config/constants/activity.constants';
import { DashboardCountersTypes, DashboardTypeQuery } from 'config/constants/counter-claims.constants';
import { PartyRelationType, allRights, mrRights, prRights } from 'config/constants/ips.constants';
import { OrganizationType } from 'config/constants/organizations.constants';
import { RIGHT_TYPES } from 'config/constants/repertoires.constants';
import { SectionsConfig } from 'config/sections-config';
import { concat, flatten, get, intersection, isArray, join, map, uniq } from 'lodash';
import {
  CaseClaimant,
  ClaimCase,
  CounterClaimClaimantRelation,
  CounterClaimClaimantStatus,
  CounterClaimDocumentCurrentState,
  CounterClaimDocumentStatus,
  CounterClaimParty,
  EditNewCounterClaim,
} from 'models';
import { DashboardHome } from 'models/home/dashboard';
import moment from 'moment';
import { IPI_PREFIX } from 'config/constants/global.constants';
import { INCLUDE_WORLD_IN_EX_TISN } from 'config/constants/territories.constants';
import { DateTimeUtils } from '../date-time/date-time.utils';

export class CounterClaimUtils {
  static getCaseStatus(item: EditNewCounterClaim) {
    switch (item.type) {
      case CounterClaimType.AUTHORSHIP_CLAIM:
      case CounterClaimType.BREACH_OF_CONTRACT_CLAIM:
      case CounterClaimType.NO_AGREEMENT_CLAIM:
      case CounterClaimType.ORIGINAL_CLAIM:
      case CounterClaimType.OWNERSHIP_CLAIM:
        return 'AWAITING_REPLY';
      case CounterClaimType.SAMPLE_CLAIM:
      case CounterClaimType.INFRINGEMENT_CLAIM:
        return 'CONFIRMED';
      case CounterClaimType.AUTHOR_SHARE_CLAIM:
        const claimantsRelations = item.parties && uniq(item.parties.map(party => party.claimantStatus));
        if (claimantsRelations) {
          if (claimantsRelations.length === 1 && claimantsRelations[0] === CounterClaimClaimantRelation.FIRST) {
            return 'CONFIRMED';
          }
        }
        return 'AWAITING_REPLY';
    }
  }

  static canDownloadDocument(downloadMode, doc, userCurrentOrganization, counterClaim): boolean {
    return downloadMode && this.userCanDownloadByOrganization(doc, userCurrentOrganization, counterClaim);
  }

  static canRejectDocument(rejectionMode, doc, userCurrentOrganization) {
    return (
      rejectionMode &&
      doc.status === CounterClaimDocumentStatus.ACCEPTED &&
      (userCurrentOrganization.isIce ||
        (userCurrentOrganization.type === OrganizationType.society && get(doc, 'participantResolutionOwner', '').includes(userCurrentOrganization.societyCode)))
    );
  }

  static userCanDownloadByOrganization(doc, userCurrentOrganization, counterClaim) {
    return (
      userCurrentOrganization.isIce ||
      (userCurrentOrganization.type === OrganizationType.society && get(doc, 'participantResolutionOwner', '').includes(userCurrentOrganization.societyCode)) ||
      (userCurrentOrganization.type === OrganizationType.publisher &&
        (this.documentIsConfirmedAndAccepted(doc, counterClaim) ||
          (userCurrentOrganization.accessPartyNames || []).some(partyNameId => get(doc, 'claimantPartyNameIds', []).includes((partyNameId || '').split(':')[1]))))
    );
  }

  static documentIsConfirmedAndAccepted(doc, counterClaim) {
    return (
      get(counterClaim, 'rawItem.attributes.status') === CounterClaimStatus.CONFIRMED &&
      get(counterClaim, 'rawItem.attributes.type') !== CounterClaimType.INFRINGEMENT_CLAIM &&
      doc.status === CounterClaimDocumentStatus.ACCEPTED
    );
  }

  static joinSharesWithClaimants(sharesRows: any[], claimants: CaseClaimant[], ccPartiesOnlyFilter: boolean = true) {
    let parentIds = [];
    claimants.forEach(claimant => {
      parentIds = concat(parentIds, CounterClaimUtils.getClaimantParentsIds(sharesRows, claimant.claimantId, 'originalParentId'));
    });
    const rows = sharesRows.map(row => {
      let claimantStatus: string;
      let responseStatus: string;
      let isParent = false;
      const selectedClaimant = claimants.find(claimant => claimant.claimantId === row.id);
      if (selectedClaimant) {
        claimantStatus = selectedClaimant.relation && selectedClaimant.relation.toLowerCase();
        responseStatus = selectedClaimant.status;
      } else {
        isParent = parentIds.find(parentId => parentId === row.id) !== undefined;
      }
      const markAsClaimantClass = selectedClaimant !== undefined ? 'indent-claimant' : isParent ? 'indent-parent' : '';
      return { ...row, claimantStatus, responseStatus, markAsClaimantClass };
    });
    if (ccPartiesOnlyFilter) {
      return rows.filter(row => row.markAsClaimantClass !== '');
    }
    return rows;
  }

  static getClaimantParentsIds(sharesRows, claimantId, parentIdField = 'parentId') {
    const currentShare = sharesRows.find(share => share.id === claimantId);
    let currentParentId = (currentShare && currentShare[parentIdField]) || null;
    const ids = [currentParentId];
    while (currentParentId) {
      currentParentId = CounterClaimUtils.getClaimantParentId(sharesRows, currentParentId, parentIdField);
      if (currentParentId) {
        ids.push(currentParentId);
      }
    }
    return ids;
  }

  static getClaimantParentId(sharesRows, claimantId, parentIdField) {
    const selectedShare = sharesRows.find(share => share.id === claimantId);
    return (selectedShare && selectedShare[parentIdField]) || null;
  }

  static getRightsOptions(translate) {
    return allRights.sort().map(right => ({ label: `${right} - ${translate.instant(`RIGHTS.${right}`)}`, value: right }));
  }

  static getRightsCreatorAffiliations(translate) {
    return [
      { label: translate.instant('REPERTOIRES.STEPS.DETAILS.FIELDS.CREATOR_AFFILIATIONS.FIELDS.RIGHTS.PR'), value: RIGHT_TYPES.PR },
      { label: translate.instant('REPERTOIRES.STEPS.DETAILS.FIELDS.CREATOR_AFFILIATIONS.FIELDS.RIGHTS.MR'), value: RIGHT_TYPES.MR },
    ];
  }

  static getClaimantStatus(type: string, parties: CounterClaimParty[], relation: string) {
    const partiesRelationsType = uniq(parties.map(party => party.claimantStatus));
    switch (type) {
      case CounterClaimType.AUTHOR_SHARE_CLAIM:
        if (partiesRelationsType.length > 1) {
          // FIRST and NEW
          if (relation === CounterClaimClaimantRelation.FIRST) {
            return CounterClaimClaimantStatus.EARLY_WARNING;
          } else if (relation === CounterClaimClaimantRelation.NEW) {
            return CounterClaimClaimantStatus.AWAITING_REPLY;
          }
        } else if (partiesRelationsType.length === 1 && parties.length > 1 && partiesRelationsType[0] === CounterClaimClaimantRelation.FIRST) {
          // Only FIRST
          return CounterClaimClaimantStatus.MEMBER_RESOLVE;
        }
        break;
      case CounterClaimType.AUTHORSHIP_CLAIM:
      case CounterClaimType.BREACH_OF_CONTRACT_CLAIM:
      case CounterClaimType.NO_AGREEMENT_CLAIM:
      case CounterClaimType.OWNERSHIP_CLAIM:
        if (partiesRelationsType.length > 1) {
          // FIRST and NEW
          if (relation === CounterClaimClaimantRelation.FIRST) {
            return CounterClaimClaimantStatus.EARLY_WARNING;
          } else if (relation === CounterClaimClaimantRelation.NEW) {
            return CounterClaimClaimantStatus.AWAITING_REPLY;
          }
        }
        break;
      case CounterClaimType.ORIGINAL_CLAIM:
        if (partiesRelationsType.length === 1 && parties.length > 1 && partiesRelationsType[0] === CounterClaimClaimantRelation.FIRST) {
          // Only FIRST
          return CounterClaimClaimantStatus.AWAITING_REPLY;
        }
        break;
      case CounterClaimType.SAMPLE_CLAIM:
        if (partiesRelationsType.length === 1 && parties.length > 1 && partiesRelationsType[0] === CounterClaimClaimantRelation.FIRST) {
          // Only FIRST
          return CounterClaimClaimantStatus.MEMBER_RESOLVE;
        }
    }
    return CounterClaimClaimantStatus.AWAITING_REPLY;
  }

  static setResponseStatus(counterClaimType: string, parties: CounterClaimParty[]) {
    if (parties) {
      return parties.map(party => {
        const responseStatus = CounterClaimUtils.getClaimantStatus(counterClaimType, parties, party.claimantStatus);
        return { ...party, responseStatus };
      });
    }
    return [];
  }

  static getClaimantsFormCounterClaims(counterClaims: ClaimCase[]): CaseClaimant[] {
    return flatten(counterClaims.map(claimCase => claimCase && claimCase.claimants));
  }

  static getPutAgreementConflictAction(counterclaimId, actionId, item, creation) {
    const { attributes, tags, deadline, userName } = item;
    const today = DateTimeUtils.getTodayFormatted();
    return {
      ...(!creation && { id: actionId }),
      attributes: {
        ns: 'CUBE',
        ...(!creation && { createdDate: today }),
        ...(!creation && { updatedDate: today }),
        assignor: userName,
        owner: 'ICE:ICE',
        deadline,
        type: 'REQUEST_TO_SUPPORT_AGREEMENT',
        status: get(attributes, 'status'),
        supportingDocumentRequired: false,
        counterclaimId,
        tags: tags ? tags : {},
      },
    };
  }

  static getPutAgreementRequest(ccId, item, creation?) {
    const { status, agreements, userName, tags } = item;
    const today = DateTimeUtils.getTodayFormatted();
    return {
      ...(!creation && { id: ccId }),
      version: 0,
      attributes: {
        ns: 'CUBE',
        onlyIncludeConflicts: false,
        pointOfConflict: 'BETWEEN_AGREEMENTS',
        type: 'AGREEMENT_MANUAL_CONFLICT',
        ...(!creation && { createdDate: today }),
        ...(!creation && { updatedDate: today }),
        status,
        entityType: 'agreement',
        counterClaimTerritories: [
          {
            tisDate: today,
            inExTisns: [INCLUDE_WORLD_IN_EX_TISN],
          },
        ],
        tags: {
          isAutomatic: tags && tags.isAutomatic ? tags.isAutomatic : [false],
        },
      },
      agreements: agreements.map(agreement => ({
        ns: 'CUBE',
        agreementId: agreement.id,
        ...(!creation && { counterclaimId: ccId }),
      })),
      tags: {
        userId: [userName],
        username: [userName],
      },
    };
  }

  static formatUploadDocumentSuccess(documents, result, status) {
    const location = status === CounterClaimDocumentCurrentState.UPLOADED ? result.location : '';
    return documents.map(document => (document.file === result.file && document.actionId === result.actionId ? { ...document, status, location } : document));
  }

  static getOwnerAndClaimantInfoFromAction(action, participants) {
    const { owner, participantRelationshipId } = get(action, 'attributes', {});
    const ownerObject = (participants || []).filter(participant => participant.participantNameId === owner).map(participant => get(participant, 'claimant', {}))[0] || {};
    let ownerIPI = ownerObject && ownerObject.relations && RelationsUtils.selectRelationByTypeAndPrefix(ownerObject.relations, [PartyRelationType.CROSS_REFERENCE], [IPI_PREFIX]);
    ownerIPI = ownerIPI && ownerIPI.split(':')[1];
    const selectedParticipant = (participants || []).find(participant => participant.id === participantRelationshipId);
    const claimant = get(selectedParticipant, 'claimant', {});
    let claimantIPI = claimant && claimant.relations && RelationsUtils.selectRelationByTypeAndPrefix(claimant.relations, [PartyRelationType.CROSS_REFERENCE], [IPI_PREFIX]);
    claimantIPI = claimantIPI && claimantIPI.split(':')[1];
    return {
      ownerName: get(ownerObject, 'attributes.name', ''),
      ownerIPINameNumber: ownerIPI,
      claimantName: get(claimant, 'attributes.name', ''),
      claimantIPINameNumber: claimantIPI,
    };
  }

  static getDocuments(counterClaim) {
    const { actions, participants, status, type } = counterClaim;
    return (
      actions &&
      flatten(
        actions.map(action => {
          const { attributes } = action;
          const { resolver, updatedDate, resolutionOwner, participantRelationshipId } = attributes || ({} as any);
          const ownerAndClaimantInfo = this.getOwnerAndClaimantInfoFromAction(action, participants);
          const createdDate = updatedDate ? moment(updatedDate).format('YYYY-MM-DD HH:mm') : updatedDate;
          const selectedParticipant = (participants || []).find(participant => participant.id === participantRelationshipId);
          const participantResolutionOwner = get(selectedParticipant, 'resolutionOwner', '');
          const claimantPartyNameIds = get(selectedParticipant, 'claimant.relations', []).map(relation => (relation.otherId || '').split(':')[1]);
          return (action.documents || []).map(document => ({
            ...document,
            ...ownerAndClaimantInfo,
            dateTime: createdDate,
            user: resolver,
            resolutionOwner,
            participantResolutionOwner,
            claimantPartyNameIds,
            shared: status === CounterClaimStatus.CONFIRMED && type !== CounterClaimType.INFRINGEMENT_CLAIM && document.status === ActionStatus.ACCEPTED ? 'Y' : 'N',
          }));
        }),
      )
    );
  }

  static updateDashboardCounter(oldDetail, prop, value) {
    const counterType = prop.replace('counters.', '');
    const counters = { ...get(oldDetail, 'dashboard.counters', {}) };
    counters[counterType] = get(value, 'total', 0);
    return {
      dashboard: { ...oldDetail['dashboard'], counters },
    };
  }

  static getDashboardTypeFilter(types: string | string[]) {
    return isArray(types) && types.length > 1
      ? `{"or":[${types.map(type => DashboardTypeQuery.replace('<<type>>', type)).join(',')}]}`
      : DashboardTypeQuery.replace('<<type>>', isArray(types) ? types[0] : types);
  }

  static getDashboardCountersType(dashboard: DashboardHome, counterType: string, translate: TranslateService) {
    if (dashboard && dashboard.counters) {
      const { counters } = dashboard;
      return DashboardCountersTypes.filter(type => type.countersGroup === counterType).map(type => {
        const { apiType, countersGroup, name } = type;
        const translationText = countersGroup === apiType ? 'TITLE' : apiType;
        return { value: `${counters[name]}`, labelInLine: translate.instant(`COUNTER_CLAIMS.${countersGroup}.${translationText}`), type: apiType };
      });
    }
    return [];
  }

  static getCounterClaimsHtmlTable(counterClaimList: ClaimCase[], translate: TranslateService): string {
    if (!counterClaimList || counterClaimList.length < 1) {
      return '';
    }

    const html = `<div style="display: flex; flex-direction: column">
                    <table class="tooltip-table">
                      <tr class="tooltip-header-row">
                        <th class="text-align-left cell-padding">${translate.instant('WORKS.HTML_TOOLTIP.CC_ID')}</th>
                        <th class="text-align-left cell-padding">${translate.instant('WORKS.HTML_TOOLTIP.TERRITORY')}</th>
                        <th class="text-align-left cell-padding">${translate.instant('WORKS.HTML_TOOLTIP.PR_RIGHTS')}</th>
                        <th class="text-align-left cell-padding">${translate.instant('WORKS.HTML_TOOLTIP.MR_RIGHTS')}</th>
                        <th class="text-align-left cell-padding">${translate.instant('WORKS.HTML_TOOLTIP.START_DATE')}</th>
                        <th class="text-align-left cell-padding">${translate.instant('WORKS.HTML_TOOLTIP.END_DATE')}</th>
                      </tr>

              ${map(
                counterClaimList.filter(counterClaim => !!counterClaim),
                counterClaim => {
                  return `<tr>
                          <td class="text-align-left cell-padding"><a href="/${SectionsConfig.COUNTERCLAIMS.domainName}/${SectionsConfig.COUNTERCLAIMS.name}/${
                    counterClaim.id
                  }/analysis" target="_blank">${counterClaim.id}</a></td>
                          <td class="text-align-left text-align-vertical-center cell-padding">${this.getTooltipTerritoriesData(counterClaim.territories) || ''}</td>
                          <td class="text-align-left">${this.getTooltipRightsData(intersection(counterClaim.rights, prRights)) || ''}</td>
                          <td class="text-align-left">${this.getTooltipRightsData(intersection(counterClaim.rights, mrRights)) || ''}</td>
                          <td class="text-align-left">${counterClaim.startDate || ''}</td>
                          <td class="text-align-left">${counterClaim.endDate || ''}</td>
                        </tr>`;
                },
              ).join('')}
                    </table>
                  </div>`;
    return html;
  }

  static getTooltipTerritoriesData(territories: string[] = []): string {
    if (!territories || territories.length < 1) {
      return '';
    }
    const territoriesString = join(territories, ',');

    if (territoriesString.length > 6) {
      return `<mat-icon class="mat-icon material-icons text-align-vertical-center">language</mat-icon><span class="text-align-vertical-center">${territories.length}</span>`;
    }
    return territoriesString;
  }

  static getTooltipRightsData(rights: string[] = []): string {
    if (rights.length < 1) {
      return '';
    }

    const rightsString = join(rights, ',');

    if (rightsString.length > 6) {
      return `<mat-icon class="mat-icon material-icons">list</mat-icon>${rights.length}`;
    }
    return rightsString;
  }

  static getAgreementCounterClaimStatus(counterclaims: any[]) {
    return counterclaims ? counterclaims.map(counterClaim => counterClaim.counterclaim.attributes && counterClaim.counterclaim.attributes.status) : [];
  }

  static getCurrentDeadlineFromActions(actions) {
    let currentDeadline;
    let currentCreatedDate;
    actions.map(action => {
      if (!currentDeadline) {
        currentDeadline = get(action, 'attributes.deadline');
        currentCreatedDate = get(action, 'attributes.createdDate');
      } else {
        const deadline = get(action, 'attributes.deadline');
        const createdDate = get(action, 'attributes.createdDate');
        if (moment(currentCreatedDate).isBefore(createdDate)) {
          currentDeadline = deadline;
          currentCreatedDate = createdDate;
        }
      }
    });
    return currentDeadline;
  }
}
