import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'ice-wrapper-tooltip',
  templateUrl: './wrapper-tooltip.component.html',
  styleUrls: ['./wrapper-tooltip.component.scss'],
})
export class WrapperTooltipComponent extends FieldWrapper {
  @ViewChild('fieldComponent', { read: ViewContainerRef, static: true }) fieldComponent: ViewContainerRef;
}
