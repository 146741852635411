<ng-container *ngIf="!(config.isHidden | async) && config; let c">
  <mat-expansion-panel
    [expanded]="c.expanded | async"
    (opened)="c.onOpen && c.onOpen()"
    (closed)="c.onClosed && c.onClosed()"
    [hideToggle]="c.hideToggle"
    id="{{ c.listIdentifier ? 'expansion-list-' + c.listIdentifier : '' }}"
  >
    <mat-expansion-panel-header class="mat-accent-bg" [ngClass]="c.hideToggle ? 'expandable-card-fixed' : ''">
      <mat-panel-title>
        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
          <h3 class="ice-txt-white">
            {{ c.title }}
            <ng-container *ngIf="c.showCounter && c.showCounter | async"> ({{ c.showCounter | async }}) </ng-container>
          </h3>
          <button
            *ngIf="config.actionButton | async; let actionButton"
            mat-icon-button
            [matTooltip]="actionButton.tooltip"
            [matTooltipClass]="'ice-tooltip'"
            [matTooltipPosition]="'above'"
            (click)="headerActionButtonClick($event, actionButton)"
          >
            <mat-icon class="mat-icon material-icons" [ngClass]="actionButton.class">{{ actionButton.icon }} </mat-icon>
          </button>
        </div>
        <div *ngIf="config.optionsGroup | async; let optionsGroup" fxFlex fxLayout="row" fxLayoutAlign="end center" class="display-flex-row-reverse flex-align-items-center flex-1">
          <mat-radio-group (change)="optionsGroup.change($event)">
            <mat-radio-button
              *ngFor="let op of optionsGroup.options"
              [tabindex]="-1"
              [ngClass]="{ disabled: op.disabled || (config.loading | async) }"
              [checked]="op.checked"
              [value]="op.value"
            >
              {{ op.label }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </mat-panel-title>
      <mat-panel-description> </mat-panel-description>
    </mat-expansion-panel-header>

    <div [ngStyle]="{ 'max-height.px': c.maxHeight ? c.maxHeight : 'auto', 'min-height.px': c.minHeight ? c.minHeight : 'auto' }" style="overflow-y: scroll">
      <div class="card-filter" *ngIf="c.filter">
        <mat-form-field #scrollParent class="ice-mt-5" *ngIf="(c.filter.model | async) && (c.filter.model | async)?.length > c.filter.filterItemsOn">
          <input matInput type="text" placeholder=" {{ 'FORM_FILTER' | translate }}" [(ngModel)]="filterValue" (keyup)="c.filter.onFilter(filterValue)" />
          <button mat-button *ngIf="filterValue" matSuffix mat-icon-button aria-label="Clear" (click)="clear($event)"><mat-icon>close</mat-icon></button>
        </mat-form-field>
      </div>
      <ng-content></ng-content>
    </div>
  </mat-expansion-panel>
</ng-container>
