import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IceMaterialModule } from '@ice/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { InputUploadFileComponent } from './input-upload-file.component';

@NgModule({
  declarations: [InputUploadFileComponent],
  imports: [CommonModule, MaterialFileInputModule, IceMaterialModule, FlexLayoutModule, TranslateModule],
  exports: [InputUploadFileComponent],
})
export class InputUploadFileModule {}
