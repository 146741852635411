import { RelationsUtils } from '@ice';
import { ipsSocietiesCodes } from 'assets/ts/ips-societies';
import { PartyRelationType, mrRights, prRights } from 'config/constants/ips.constants';
import { CLAIM_TYPES, PUBLISHER_ROLES, RIGHT_TYPES } from 'config/constants/repertoires.constants';
import { TerritoryDataType } from 'config/constants/territories.constants';
import { find, get, isArray, map, padStart, toNumber } from 'lodash';
import { RepertoireDetail, RepertoireParty, RepertoireSociety } from 'models';
import { IconInterface } from 'models/copyright/detail/icon';
import { EditNewRepertoire, RepertoireClaimType } from 'models/copyright/edit-new-stepper/repertoire';
import { RepertoireCleaned, RepertoireSearchItem } from 'models/copyright/search/copyright-repertoire-search';
import { IPI_PREFIX } from 'config/constants/global.constants';
import { IpUtils } from '../ip/ip.utils';
import { SocietiesUtils } from '../societies/societies.utils';
import { TerritoryUtils } from '../territory/territory.utils';

export class RepertoireUtils {
  static hasClaimType(claimType: string, claimTypes: RepertoireClaimType[]): boolean {
    if (claimType && claimTypes) {
      let claimFound: RepertoireClaimType;
      switch (claimType) {
        // Both are Direct Claims
        case CLAIM_TYPES.SUBMITTED:
        case CLAIM_TYPES.VALIDATED:
          claimFound = find(claimTypes, type => type === CLAIM_TYPES.SUBMITTED || type === CLAIM_TYPES.VALIDATED);
          break;
        default:
          claimFound = find(claimTypes, type => type === claimType);
          break;
      }
      return claimFound ? true : false;
    }
    return false;
  }

  static formatCreatorAffiliations(affiliations: RepertoireSociety[], translate) {
    const additionalAffiliations = [];
    for (const s of affiliations) {
      if (s.rights.includes(RIGHT_TYPES.PR) && s.rights.includes(RIGHT_TYPES.MR)) {
        additionalAffiliations.push({
          ...s,
          rights: [RIGHT_TYPES.MR],
        });
      }
    }
    return map([...affiliations, ...additionalAffiliations], affiliation => {
      const societyCode = SocietiesUtils.getSocietyCode(affiliation.societyId);
      const societyName = SocietiesUtils.searchSocietyNameById(societyCode);
      const rightType = IpUtils.mapRightsCodes(affiliation.rights, translate);
      const rightTypeTooltip = translate.instant('RIGHTS.' + rightType);
      return { ...affiliation, societyCode, societyName, rightType, rightTypeTooltip };
    });
  }

  static getRepertoirePartyUpdateBody(repertoireRequiredFields, partyIds, relation) {
    return {
      ...repertoireRequiredFields,
      parties: this.getRepertoireParties(repertoireRequiredFields.id, partyIds, relation),
    };
  }

  static getRepertoireWorkUpdateBody(repertoireRequiredFields, workId) {
    return {
      ...repertoireRequiredFields,
      works: [this.getRepertoireIncludedWork(repertoireRequiredFields.id, workId, repertoireRequiredFields.version)],
    };
  }

  static getRepertoireIncludedWork(repertoireId, workId, version) {
    return { relation: 'INCLUDE', repertoireId, workId, version };
  }

  static getRepertoireSocietyUpdateBody(repertoireRequiredFields, society) {
    return {
      ...repertoireRequiredFields,
      societies: [
        {
          relation: 'INCLUDE',
          societyId: `CISAC:${toNumber(get(society, 'societyCode'))}`,
          rights: (isArray(society?.rights) && society?.rights) || society?.rights?.split(',').map(right => right.trim()),
          repertoireId: repertoireRequiredFields.id,
          version: repertoireRequiredFields.version + 1,
        },
      ],
    };
  }

  static getRepertoireParties(repertoireId, ipIds, relation) {
    return ipIds.filter(ipId => ipId).map(ipId => this.getRepertoireParty(repertoireId, ipId, relation));
  }

  static getRepertoireParty(repertoireId, ipId, relation) {
    return {
      repertoireId,
      ns: 'CUBE',
      partyId: ipId,
      version: 0,
      relation,
    };
  }

  static formatRepertoireParties(parties: RepertoireParty[]) {
    return (parties || []).map(party => {
      const ipiNameNumber = get(party, 'ipiNameNumber', '').replace('IPI:', '');
      return { ...party, ipiNameNumber };
    });
  }

  static repertoireSearchCleaner(repertoires: RepertoireSearchItem[]) {
    return (
      (repertoires &&
        repertoires.map((item: RepertoireSearchItem) => {
          const { id, attributes, relations } = item;
          let territoriesList: string[];
          let territoriesIcon: IconInterface[];
          let territoriesText: string;
          let territoriesTooltip: string;

          if (attributes) {
            territoriesList = attributes.territories;
            if (territoriesList) {
              ({ territoriesIcon, territoriesText, territoriesTooltip } = TerritoryUtils.getTerritoriesDisplayListData(territoriesList, TerritoryDataType.NAME));
            }
          }
          const key = IpUtils.selectIpsKey(relations, id);
          const keySplitted = key.split(':');
          const keyWithoutPrefix = keySplitted && keySplitted.length > 1 && key.split(':')[1];
          return <RepertoireCleaned>{
            ...attributes,
            key,
            keyWithoutPrefix,
            dataType: 'repertoire',
            territoriesIcon,
            territoriesText,
            territoriesTooltip,
          };
        })) ||
      []
    );
  }

  static repertoireDetailCleaner(repertoireDetail: RepertoireDetail): RepertoireDetail {
    const { admin, id, version, attributes, relations, note, works, parties, societies } = repertoireDetail;
    const key = IpUtils.selectIpsKey(relations, id);
    let hasWorks = false;
    let hasAgreements = false;
    let isDirectClaimType = false;
    let isDerivedClaimType = false;
    let isAssumedClaimType = false;
    let rollupExcludeTerritoriesText: string;
    let territoriesText: string;
    let territoriesTooltip: string;
    let territoriesTooltipWithoutHtml: string;
    if (attributes) {
      const { exclude, claimTypes, publisherRollupExcludeTerritories, territories } = attributes;
      if (exclude) {
        hasWorks = isArray(exclude.workIds) ? exclude.workIds.length > 0 : false;
        hasAgreements = isArray(exclude.agreementIds) ? exclude.agreementIds.length > 0 : false;
      }
      if (claimTypes) {
        isDirectClaimType = RepertoireUtils.hasClaimType(CLAIM_TYPES.SUBMITTED, claimTypes);
        isDerivedClaimType = RepertoireUtils.hasClaimType(CLAIM_TYPES.DERIVED, claimTypes);
        isAssumedClaimType = RepertoireUtils.hasClaimType(CLAIM_TYPES.ASSUMED, claimTypes);
      }
      rollupExcludeTerritoriesText = TerritoryUtils.convertTerritoryArrayElements(publisherRollupExcludeTerritories || [], TerritoryDataType.TISA).join(' ');
      territoriesText = TerritoryUtils.convertTerritoryArrayElements(territories, TerritoryDataType.TISA).join(' ');
      if (territories) {
        ({ territoriesTooltip, territoriesTooltipWithoutHtml } = TerritoryUtils.getTerritoriesDisplayListData(territories, TerritoryDataType.NAME));
      }
    }

    const formattedParties = RepertoireUtils.cleanRepertoireParties(parties);
    return <RepertoireDetail>{
      id,
      version,
      attributes: {
        ...attributes,
        key,
        hasWorks,
        hasAgreements,
        isDirectClaimType,
        isDerivedClaimType,
        isAssumedClaimType,
        rollupExcludeTerritoriesText,
        territoriesText,
        territoriesTooltip,
        territoriesTooltipWithoutHtml,
      },
      relations,
      note,
      works,
      parties,
      contractingCompanies: (parties && formattedParties.filter(party => party.relation === PUBLISHER_ROLES.CONTRACTOR)) || [{ key: '' }],
      additionalCollectors: (parties && formattedParties.filter(party => party.relation === PUBLISHER_ROLES.COLLECTOR)) || [{ key: '' }],
      societies,
      admin,
      excludedAgreements: null,
      excludedWorks: null,
      notes: get(admin, 'attributes.notes', []),
    };
  }

  static cleanRepertoireParties(parties): RepertoireParty[] {
    return (parties || []).map(party => ({
      ipiNameNumber: RelationsUtils.selectRelationByTypeAndPrefix(get(party, 'party.partyNames[0].partyName.relations'), [PartyRelationType.CROSS_REFERENCE], [IPI_PREFIX]).replace(
        'IPI:',
        '',
      ),
      name: get(party, 'party.partyNames[0].partyName.attributes') && IpUtils.getIpFullName(get(party, 'party.partyNames[0].partyName.attributes')),
      relation: party.relation,
      repertoireId: party.repertoireId,
      type: get(party, 'party.partyNames[0].type'),
      rawItem: party,
    }));
  }

  static cleanRepertoireForUpdate(repertoire: RepertoireDetail): EditNewRepertoire {
    const { id, version, attributes, parties, societies, works, relations } = repertoire;
    const {
      type,
      name,
      opRepertoireAllowed,
      publisherRollup,
      publisherRollupToNonSoc,
      rollupExcludeTerritoriesText,
      rights,
      claimTypes,
      startDate,
      endDate,
      territories,
      exclude,
      ruleSetId,
      active,
      unpublishedWriterShares,
    } = attributes;
    const { creatorIds, workIds, agreementIds } = exclude || {};
    const formattedParties = RepertoireUtils.getPartiesIPINameNumber(parties);
    const formattedTerritories = rollupExcludeTerritoriesText.replace(/  +/g, ' ');
    return {
      id,
      version,
      type,
      name,
      relations,
      opDetermine: opRepertoireAllowed,
      publisherRollup,
      rollupExcludeTerritoriesText: formattedTerritories,
      publisherRollupToNonSoc,
      usageTypes: rights,
      claimTypes,
      creatorAffiliations: (societies &&
        societies.map(society => {
          const value = (society.societyId && padStart(society.societyId.replace('CISAC:', ''), 3, '0')) || '';
          const societyObject = ipsSocietiesCodes.find(soc => soc.code === value.trim());
          return {
            societyCode: {
              value,
              label: (societyObject && societyObject.name) || '',
            },
            rights: (Array.isArray(society.rights) && ((society.rights.includes('MP') && RIGHT_TYPES.PR) || RIGHT_TYPES.MR)) || '',
          };
        })) || [{ societyCode: { value: '', label: '' }, rights: '' }],
      workList: (works && works.map(work => ({ key: work.workId }))) || [{ key: '' }],
      contractingCompanies: (parties && formattedParties.filter(party => party.relation === 'CONTRACTOR')) || [{ key: '' }],
      additionalCollectors: (parties && formattedParties.filter(party => party.relation === 'COLLECTOR')) || [{ key: '' }],
      startDate,
      endDate,
      territory: territories && TerritoryUtils.convertTerritoryArrayElements(territories, TerritoryDataType.TISA).join(','),
      exclusionsWorks: workIds && workIds.map(work => ({ key: RepertoireUtils.formatIdToKeyWithoutPrefix(work) })),
      exclusionsAgreements: agreementIds && agreementIds.map(agreement => (agreement ? { key: RepertoireUtils.formatIdToKeyWithoutPrefix(agreement) } : { key: '' })),
      notes: '',
      ruleSetId,
      active,
      unpublishedWriterShares,
    };
  }

  static getPartiesIPINameNumber(parties) {
    return (parties || []).map(party => {
      const relations = get(party, 'party.partyNames[0].partyName.relations');
      return {
        relation: party.relation,
        key: relations && IpUtils.selectIPINumber(relations).replace('IPI:', ''),
        partyId: party.partyId,
      };
    });
  }

  static formatSendItemWithId(item, repertoireId: string, mode = 'new') {
    return {
      ...item,
      attributes: { ...item.attributes, id: repertoireId },
      relations:
        mode === 'new'
          ? [
              { relation: 'DISP', otherId: repertoireId.replace('CUBE:', 'ICE:'), version: item.version, repertoireId },
              { relation: 'XREF', otherId: repertoireId.replace('CUBE:', 'ICE:') },
            ]
          : item.relations,
      parties: (item.parties || []).map(party => ({ ...party, repertoireId })),
      societies: (item.societies || []).map(society => ({
        ...society,
        repertoireId,
        rights: (Array.isArray(society.rights) && society.rights[0] === RIGHT_TYPES.PR && prRights) || mrRights,
      })),
      works: (item.works || []).map(work => ({ ...work, repertoireId })),
    };
  }

  static formatIdToKeyWithoutPrefix(id: string) {
    if (!id) return '';
    return toNumber(id.replace('CUBE:', '')).toString();
  }
}
