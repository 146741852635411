import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'config/env';
import * as jp from 'jsonpath';
import { cloneDeep, groupBy, keyBy, mapValues } from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CubeRequest, IceMaskedIdsConfig } from './masked-ids.model';

@Injectable()
export class MaskedIdsService {
  constructor(private http: HttpClient) {}

  applyMaskToCubeRequest(req: CubeRequest, maskedIdsList: IceMaskedIdsConfig[]): Observable<CubeRequest> {
    const maskParams = this.getMaskParams(req, maskedIdsList);
    if (maskParams && maskParams.length) {
      return this.requestMaskedIds(req, maskParams).pipe(map(maskResponse => this.applyMaskToRequest(maskResponse, req)));
    } else {
      return of(req);
    }
  }

  requestMaskedIds(req: CubeRequest, maskParams: IceMaskedIdsConfig[]): Observable<any[]> {
    const maskedIdRequests = maskParams.map(maskedIdConfig =>
      this.http
        .get(`${environment.urlGetMaskedId}/${maskedIdConfig.type}/${this.cleanId(maskedIdConfig.id)}`, { headers: req.headers })
        .pipe(map((response: any) => ({ ...maskedIdConfig, newId: response.maskedId }))),
    );
    return forkJoin(maskedIdRequests);
  }

  getMaskParams(req: CubeRequest, maskedIdsList: IceMaskedIdsConfig[]): IceMaskedIdsConfig[] {
    const serializedRequestUrl = this.getSerializedRequestUrl(req);
    const maskParams = [];
    (maskedIdsList || []).map(maskedIdConfig => {
      if (!maskedIdConfig.source) {
        maskedIdConfig.source = maskedIdConfig.url;
      }
      const { type, source, url } = maskedIdConfig;
      if (url) {
        const id = serializedRequestUrl.find((param, index) => url.split('/')[index].match(/<<id>>/) && param.split(':').length === 2);
        maskParams.push({ id, type, source });
      } else {
        if (req.body) {
          const ids = jp.query(req.body, source);
          if (ids && ids.length) {
            maskParams.push(...ids.map(id => ({ id, type, source })));
          }
        }
      }
    });
    return maskParams;
  }

  applyMaskToRequest(maskResponse: IceMaskedIdsConfig[], req: CubeRequest): CubeRequest {
    let url = req.url;
    const body = cloneDeep(req.body);
    const maskResponseBySource = Object.values(groupBy(maskResponse, 'source')).map(maskResponseList => ({
      source: maskResponseList[0].source,
      idMap: mapValues(keyBy(maskResponseList, 'id'), 'newId'),
      url: maskResponseList[0].url,
    }));
    for (const mask of maskResponseBySource) {
      const idMap = mask.idMap;
      if (mask.url) {
        for (const id of Object.keys(idMap)) {
          url = url.replace(id, idMap[id]);
        }
      } else {
        jp.apply(body, mask.source, value => this.cloneSpecialChars(value, idMap[value]));
      }
    }
    return { ...req, url, body };
  }

  cleanId(id) {
    return id.replace(/[*]/g, '');
  }

  cloneSpecialChars(oldId, newId) {
    const specialChars = /^[^a-zA-Z0-9]+$/;
    const firstChar = oldId[0].match(specialChars) ? oldId[0] : '';
    const lastChar = oldId[oldId.length - 1].match(specialChars) ? oldId[oldId.length - 1] : '';
    return `${firstChar}${newId}${lastChar}`;
  }

  getSerializedRequestUrl(req: CubeRequest): string[] {
    return req.url.split('/').slice(3);
  }
}
