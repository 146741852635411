import { Component, Input } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '@ice/i18n/en/upload-file';
import { InputFileType } from '../input-upload-file/input-upload-file.model';

@Component({
  selector: 'ice-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent {
  @Input() label: string;
  @Input() fileType?: InputFileType;
  @Input() customAddFile: Function;
  files: any = [];
  constructor(private fuseTranslationLoader: FuseTranslationLoaderService) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  uploadFile(files, event?) {
    for (const element of files) {
      const fileType = `.${element.name.split('.').pop()}`;
      if (this.fileType && this.fileType !== fileType) {
        continue;
      }
      if (this.customAddFile) {
        this.customAddFile(element);
      } else {
        this.files.push(element);
      }
    }
    if (event && event.target && event.target.value) {
      event.target.value = '';
    }
  }

  deleteAttachment(index) {
    this.files.splice(index, 1);
  }
}
