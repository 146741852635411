import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CardWithForm } from './card-with-form';

@Component({
  selector: 'ice-card-with-form',
  templateUrl: './card-with-form.component.html',
  styleUrls: ['./card-with-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardWithFormComponent implements CardWithForm {
  @Input() title: string;
  @Input() titleTooltip: string;
  @Input() iswc: string;
  @Input() wsm: string;
  @Input() expanded: string;
  @Input() referenceId: string;
  @Input() formBuilder: FormlyFieldConfig[];
  @Input() model: any;
  @Input() resetAvailable = false;
  @Input() submitAvailable = true;
  @Input() button1Available = false;
  @Input() button1Label: string;
  @Input() submitLabel: string;
  @Input() cardHeight = 'auto';
  @Input() submitShortcutEnable = false;
  @Output() submitEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() button1Emitter: EventEmitter<any> = new EventEmitter<any>();

  onSubmit(data: any) {
    this.submitEmitter.emit(data);
  }

  onButton1(data: any) {
    this.button1Emitter.emit(data);
  }
}
