import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { RouterUtils } from '@ice';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AuthService } from 'services/auth/auth.service';
import * as fromRoot from 'store/root';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private store: Store<fromRoot.AuthState>, private actions$: Actions) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.getCurrentUserInfo().pipe(
      withLatestFrom(this.store.pipe(select(fromRoot.getUserDetail))),
      switchMap(([user, userDetail]) => {
        const userName = (user && user.userName) || null;
        if (userName) {
          localStorage.setItem('currentUser', userName);
          if (!userDetail) {
            this.store.dispatch(new fromRoot.GetUserDetails({ userName }));
            this.store.dispatch(new fromRoot.DoUpdateUser(user));
            return this.actions$.pipe(
              ofType(fromRoot.GET_USER_DETAILS_FAIL, fromRoot.GET_USER_ORGANIZATIONS_FAIL, fromRoot.GET_USER_ORGANIZATIONS_SUCCESS),
              map(({ type }) => {
                if (type === fromRoot.GET_USER_DETAILS_FAIL) {
                  this.store.dispatch(new fromRoot.SaveRedirectOnLogin(RouterUtils.getUrlWithParams(route)));
                  this.store.dispatch(new fromRoot.Logout());
                  return false;
                }
                return true;
              }),
            );
          }
          return of(true);
        } else {
          this.store.dispatch(new fromRoot.SaveRedirectOnLogin(RouterUtils.getUrlWithParams(route)));
          this.store.dispatch(new fromRoot.Logout());
          return of(false);
        }
      }),
      catchError(err => {
        this.store.dispatch(new fromRoot.SaveRedirectOnLogin(RouterUtils.getUrlWithParams(route)));
        this.store.dispatch(new fromRoot.Logout());
        if (window.location.pathname !== '/') {
          this.store.dispatch(
            new fromRoot.AuthError({
              message: err,
              severity: 'error',
            }),
          );
        }
        return of(false);
      }),
    );
  }
}
