export const locale = {
  lang: 'en',
  data: {
    'COUNTER-CLAIMS': {
      ITEM_TITLE: 'CounterClaims',
      OK_MESSAGE: 'CounterClaim was successfully updated.',
    },
    'AGREEMENT-CONFLICT_REGISTRATION': 'Agreement Conflict',
    'AGREEMENT-CONFLICT': {
      ITEM_TITLE: 'Agreement Conflict',
      POPUP: {
        OK_MESSAGE: 'Agreement conflict was successfully created.',
        OK_MESSAGE_EDIT: 'Agreement conflict was successfully updated',
      },
      STATUS_OVERLAP: 'Possible Overlap',
      STATUS_CONFLICT: 'Potential Conflict',
      STATUS_DISPUTE: 'Confirmed Dispute',
      STATUS_RESOLVED: 'Resolved',
      STATUS_CLOSED: 'Closed',
      DEADLINE: 'Deadline',
      DEADLINE_REACHED: 'Deadline has been reached',
      TITLE_CREATION: 'Agreement conflict creation',
      ERROR: {
        DUPLICATE_AGREEMENT: 'This agreement is already in the conflict, please select another one',
      },
    },
    'AGREEMENT-GROUP': {
      ITEM_TITLE: 'Agreement Group',
      POPUP: {
        OK_MESSAGE: 'Agreement group was successfully created.',
        OK_MESSAGE_EDIT: 'Agreement group was successfully updated',
      },
    },
    COUNTERCLAIMS: {
      STEPS: {
        TYPE: 'Type',
        PARTIES: 'Parties',
      },
      FORM: {
        FIELDS: {
          ID_TOOLTIP: 'CounterClaim Type',
          TYPE: 'Counterclaim Type',
          CONFIRM: 'Type to be confirmed by member',
        },
      },
      TYPES: {
        AS: 'Author Share',
        AU: 'Authorship',
        OW: 'Ownership',
        OR: 'Original',
        SA: 'Sample',
        NA: 'No Agreement',
        BC: 'Breach of Contract',
        IN: 'Infringement',
      },
    },
    SELECT_ORGANIZATION_POPUP: {
      TITLE_EDIT_MODE_DIALOG: 'In which behalf of you edit',
      TITLE_CREATION_MODE_DIALOG: 'In which behalf of you create',
      TITLE_SOCIETY_ON_BEHALF: 'Please select the party on which behalf you like to..',
      TITLE_SEARCH_PARTIES: "Search and select the party on behalf of which you'd like to execute an edit",
      TITLE_SELECT_PUBLISHER: 'Select work publisher',
      MESSAGE: 'Select Organization',
      CANCEL: 'Cancel',
      SEARCH: 'Search',
      RESET: 'Reset',
      FILTER_NAME: 'Type to filter on the name',
      PLACEHOLDER_SEARCH: 'Search',
      BT_SEARCH_PARTY: 'Search Party',
      TYPE_DROPDOWN: 'Edit Type',
      CLONE: 'Clone',
      CLONE_ERROR: 'Could not clone work because:',
      DATATABLE_FIELDS: { NAME: 'Name', CODE: 'Code', TYPE: 'Type' },
      ORGANIZATION_TYPES: { ICE: 'ICE', PUBLISHERS: 'Publishers', SOCIETIES: 'Societies', CREATORS: 'Creators' },
      EDIT_TYPE: 'Edit Type',
      SOCIETY_EDIT_TYPES: { EDIT_SOCIETY_DATA: 'Edit Society Data', EDIT_ON_BEHALF: 'Edit on behalf of a Member', ADD_MEMBERS_CLAIM: 'Add Members Claim' },
      EDIT_ON_BEHALF_FIELDS: { ROLE: 'Role', NAME: 'Name', ICE_PARTYNAME_ID: 'ICE PartyName Id', IPI_NAME_NUMBER: 'IPI Name Number' },
      SEARCH_CLAIMANT: 'Search claimant',
      CLAIMANT_SEARCH_RESULTS: 'Claimant search results',
    },
    POPUP_COMMON: {
      ADD: 'Add',
      SENDING: 'Sending',
      DISABLED: 'This functionality is not yet active',
      CANCEL: 'Cancel',
      CONFIRM: 'Confirm',
      CLOSE: 'Close',
      OK: 'Ok',
      DELETE: 'Delete',
      EDIT: 'Edit',
      REMOVE: 'Remove',
      GO_TO_DETAIL: 'Return to detail',
      RE_SUBMIT: 'Re-submit',
      BACK: 'Back',
      YES: 'Yes',
      NO: 'No',
      ACTIVATE: 'Activate',
      DISABLE: 'Deactivate',
      UNMATCH: 'Unmatch',
    },
    OK_MESSAGE_CLONE: 'New work ',
    OK_MESSAGE_CLONE_AFTER_LINK: ' created. Please update the information if needed.',
    SUMMARY_POPUP: {
      RETURN_TO_DETAIL: 'Return to Detail',
    },
    DIALOG_SUBMIT: {
      SENDING_TEXT_CLAIM: 'Creating new Claim',
    },
    EXIT_EDIT_POPUP: {
      TITLE: 'Cancel edited <<placeholder>>',
      MSG: 'Everything in this Edit Mode will be lost. Are you sure?',
    },
    MERGE_ERROR_POPUP: {
      TITLE: 'Merge Error',
      MSG: 'The merge can not be performed because the selected works are the same.',
      ALL_CLAIMS_IGNORED: 'The merge can not be performed because all claims are ignored.',
    },
    CONFLICTS: {
      TOOLTIP_TITLE: `' TOP_NO_MERGE '  Conflict list:`,
    },
    WORKS: {
      ITEM_TITLE: 'work',
      SENDING_TITLE: 'Sending Work',
      OK_MESSAGE: 'The submitted work was successfully updated',
      KO_MESSAGE: `The submitted work can't be updated. Please review the submitted information`,
      NETWORK_ERROR: `The submitted work can't be updated. Network error.`,
      SO_PB_OK_MESSAGE: 'Your Edit has been submitted. If the work details are not revised as you expect, either submit a new edit or raise an Update Request',
      CLAIM_DELETE_OK_MESSAGE: 'Your Edit has been submitted. Requested claim deletion may require Agreement update to take effect.',
      WORK_CREATION: { SEARCH_PUBLISHER_SUBMITTER: 'Search Publisher Submitter', PUBLISHER_SEARCH_RESULT: 'Publisher search result' },
    },
    CLAIM: {
      ITEM_TITLE: 'claim',
      SENDING_TITLE: 'Sending Claim',
      OK_MESSAGE: 'The submitted claim was successfully updated',
      KO_MESSAGE: `The submitted claim can't be updated. Please review the submitted information`,
      NETWORK_ERROR: `The submitted claim can't be updated. Network error.`,
      NEW_CLAIM_DIALOG_TITLE: `Select Claim Type`,
      NEW_STANDARD_CLAIM: `STANDARD CLAIM`,
      NEW_UNKNOWN_PUBLISHER: `UNKNOWN PUBLISHER`,
      NEW_UNKNOWN_AUTHOR_COMPOSER: `UNKNOWN AUTHOR/COMPOSER`,
    },
    IPS: {
      ITEM_TITLE: 'ip',
      SENDING_TITLE: 'Sending Party Name',
      OK_MESSAGE: 'The submitted Party Name was successfully updated',
      KO_MESSAGE: `The submitted Party Name can't be updated. Please review the submitted information`,
      NETWORK_ERROR: `The submitted Party Name can't be updated. Network error.`,
      RELATIONS: {
        KO_MESSAGE: `The submitted relation can't be updated. Please review the information or retry later`,
      },
    },
    AGREEMENTS: {
      ITEM_TITLE: 'agreement',
    },
    REPERTOIRES: { ITEM_TITLE: 'repertoire' },
    TERRITORIES: { ITEM_TITLE: 'territory' },
    ORGANIZATIONS: {
      ITEM_TITLE: 'organization',
      POPUP: {
        OK_MESSAGE: 'Organization was successfully created',
        OK_MESSAGE_EDIT: 'Organization was successfully updated',
      },
    },
    USERS: { ITEM_TITLE: 'user' },
    RESPONSE_CUSTOM_ERRORS: {
      SHARES_EXCEED_100_PERCENT_IN_WORK:
        // eslint-disable-next-line max-len
        'Your work update cannot be processed automatically as the total claims you are submitting are greater than 100%. Please adjust the claims and resubmit or raise an update request with ICE to update the work',
      DUPLICATE_KEY: 'The key is duplicated',
      AGREEMENT_CRF: {
        DUPLICATE_KEY: "Xrefs can't be registered, the key is duplicated",
      },
      BULK_UPDATE_TIMEOUT: `The processing of your bulk update request may have exceeded the allotted time. \
        While confirmation is not yet available, it is possible that the update was submitted successfully. \
        You can verify the status within the Dashboard`,
      RETRIES_EXHAUSTED: `We are having trouble connecting to the database. Try again later.`,
    },
    'COUNTER-CLAIM-SUPPORT': {
      POPUP: {
        OK_MESSAGE: 'The counterclaim was successfully supported',
        KO_MESSAGE: `The counterclaim can't be supported. Please review the submitted information`,
      },
    },
  },
};
