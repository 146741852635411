export interface SelectMode {
  userId?: string;
  selection: any[];
  mode?: SelectModeEnum;
}

export enum SelectModeEnum {
  DELETE = 'DELETE',
  SHARE = 'SHARE',
}
