import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CopyrightUtils, fieldConfig, IpUtils, WorkUtils, TabsUtils } from '@ice';
import { DataTableRow } from '@ice/components/data-table/data-table';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { IceGroupComponent, IceLayout } from '@ice/dynamic-components/group-component/group-component';
import { select, Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectionType } from '@swimlane/ngx-datatable';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import * as fromApiCalls from 'config/api-calls';
import { ActivityType } from 'config/constants/activity.constants';
import { IP_TYPES, IP_TYPES_PERSON, IP_TYPE_LEGAL_ENTITY, IP_TYPE_NATURAL_PERSON } from 'config/constants/ips.constants';
import { DialogInfo } from 'config/dialog-builders/dialog-info';
import { IceFacade } from 'facades/ice.facade';
import { cloneDeep, find, get, has, isEqual, startsWith } from 'lodash';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { CommonApiService } from 'services/common-api.service';
import { DetailService } from 'services/detail/detail.service';
import { NamespaceService } from 'services/namespace/namespace.service';
import { PermissionsService } from 'services/permissions/permissions.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';
import type { SectionTabConfig } from '../../tabs-data';

// TODO: Add Angular decorator.
export class TabOtherParties implements SectionTabConfig {
  dialogRef: MatDialogRef<DialogMultiLayoutComponent, any>;
  subscriptionEditMode: Subscription;
  isEditMode: boolean;
  canEdit = false;
  otherPartiesModel: any;
  otherPartiesForm: any;
  dummyIpModel: any = {
    dateOfDeath: '',
    typeOf: 'L',
    creationClass: 'MW',
    status: 'ACTIVE',
    name: '',
    ns: 'OP',
  };
  dummyIpMode = false;
  createDummyIpProccess = new BehaviorSubject(false);
  previousRole: any;

  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private dialog: MatDialog,
    protected commonApiService: CommonApiService,
    protected detailService: DetailService,
    protected nsService: NamespaceService,
    protected iceFacade: IceFacade,
    protected fieldValidatorService: FieldValidatorService,
    private permissionsService: PermissionsService,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.subscriptionEditMode = this.store.pipe(select(fromRoot.getEditMode)).subscribe((editMode: boolean) => {
      this.isEditMode = editMode;
      if (editMode) {
        this.canEdit = this.permissionsService.can('works_other_parties');
      }
    });
  }

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('WORKS.OTHER_PARTIES.TAB_TITLE'),
              model: this.store.pipe(select(fromRoot.getWorkPartyNames)),
              columnMode: 'flex',
              selectionType: of((this.canEdit && SelectionType.checkbox) || SelectionType.single),
              actionButtons: this.canEdit
                ? of([
                    {
                      icon: 'add',
                      tooltip: this.translate.instant('WORKS.OTHER_PARTIES.ADD'),
                      class: 'mat-white-icon',
                      onClick: () => this.openDialogOtherParties(),
                    },
                  ])
                : null,
              schema: this.getDataTable(),
              tableWidth: '100',
              sorts: [{ prop: 'role', dir: 'asc' }],
              loadingIndicator: false,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: true,
              shadowed: false,
              getRowClass: (): any => ({ 'ice-search-results-table-row': true }),
              visibleColumns: this.getVisibleColumnsOtherParties(this.getDataTable()),
            },
          },
        ],
      },
    ];
  }

  getDataTable(): DataTableRow[] {
    return [
      ...WorkUtils.getOtherPartyTableCommonFields(this.translate),
      {
        name: '',
        prop: 'editBtn',
        actionButtonIcon: 'edit',
        flexGrow: 0.001,
        maxWidth: 40,
        minWidth: 40,
        resizeable: false,
        action: (row: any) => {
          this.store
            .pipe(select(fromRoot.getWorkPartyNames))
            .subscribe(partyNames => {
              this.openDialogOtherParties(row, partyNames);
            })
            .unsubscribe();
        },
        hideActionButton: row => of(!this.canEdit),
        actionButtonTooltip: this.translate.instant('WORKS.OTHER_PARTIES.EDIT'),
      },
      {
        name: '',
        prop: 'deleteBtn',
        actionButtonIcon: 'delete',
        flexGrow: 0.001,
        maxWidth: 50,
        minWidth: 50,
        resizeable: false,
        action: row => this.deleteOtherPartiesPopup(row),
        hideActionButton: row => of(!this.canEdit),
        actionButtonTooltip: this.translate.instant('WORKS.OTHER_PARTIES.REMOVE'),
      },
    ];
  }

  openDialogOtherParties(row?, otherParties?) {
    let mode = 'add';
    let otherParty;
    let dummyIp;
    if (row && otherParties) {
      otherParty = otherParties.find(party => party.partyNameId === row.partyNameId && party.fullName === row.fullName && party.role === row.role);
      otherParty.fullName = otherParty && otherParty.fullName;
      otherParty.name = get(otherParty, 'rawItem.partyName.attributes.name');
      otherParty.firstName = get(otherParty, 'rawItem.partyName.attributes.firstName');
      otherParty.partyId = get(otherParty, 'rawItem.partyName.parties[0].partyId');
      dummyIp = otherParty && IpUtils.dummyIpCleaner(otherParty.rawItem, this.translate);
      this.previousRole = otherParty.role;
      mode = 'edit';
      this.dummyIpMode = otherParty && !otherParty.ipiNameNumber;
    } else {
      this.dummyIpMode = false;
      this.fieldValidatorService.checkFormlyExpressions();
    }
    this.dialogRef = this.dialog.open(DialogMultiLayoutComponent, {
      data: {
        className: 'dialog-wrapper-width-420',
        layouts: [this.getLayoutAddOtherParties(mode, otherParty)],
        loading: this.createDummyIpProccess,
      },
    });
  }

  private getLayoutAddOtherParties(mode, otherParty): IceLayout {
    const isFormValid = new BehaviorSubject(false);
    return {
      title: of(this.translate.instant('WORKS.OTHER_PARTIES.ADD')),
      actions: [
        { tooltip: this.translate.instant('POPUP.CANCEL'), color: 'warn', nextLayout: 0, icon: 'close', onClick: () => this.dialogRef.close() },
        {
          tooltip: this.translate.instant('POPUP.CONFIRM'),
          nextLayout: 1,
          icon: 'done',
          disabled: isFormValid.pipe(map(isValid => !isValid)),
          onClick: () => this.submitOtherParty(mode),
        },
      ],

      layout: [
        {
          group: [
            {
              type: 'formly',
              config: {
                formBuilder: of(this.getOtherPartiesForm(this.fieldValidatorService, mode)),
                model: mode === 'add' ? of({}) : of(otherParty),
                change: model => {
                  this.otherPartiesModel = cloneDeep(model);
                },
                setValidForm: isValid => {
                  isFormValid.next(isValid && (!!this.otherPartiesModel.ipiNameNumber || !!this.otherPartiesModel.fullName));
                },
              },
            },
          ],
        },
      ],
    };
  }

  private getDummyIpPayload() {
    return { ...this.dummyIpModel, typeOf: this.getDummyType() };
  }

  getDummyType() {
    return IP_TYPES_PERSON.includes(this.otherPartiesModel?.role || '') ? IP_TYPE_NATURAL_PERSON : IP_TYPE_LEGAL_ENTITY;
  }

  private submitDummyIP(): Observable<any> {
    const add = 'add'; // There is no more edit for dummy IP in this view
    this.store.dispatch(new fromRoot.SetDummyIp({ mode: add, dummyIpPayload: this.getDummyIpPayload() }));
    this.createDummyIpProccess.next(true);
    return this.store.select(fromRoot.getDummyIp).pipe(
      filter(dummyIp => !!dummyIp),
      tap(dummyIp => this.createDummyIpProccess.next(false)),
    );
  }

  private submitOtherParty(mode: string) {
    const { fullName, name, partyNameId, role } = this.otherPartiesModel;
    const newName = mode === 'edit' ? fullName : name || fullName;
    const ipiNameNumberFormControl = this.otherPartiesForm.get('ipiNameNumber');
    if (!ipiNameNumberFormControl?.value) {
      this.store.dispatch(
        new fromRoot.StartApiCall({
          apiCall: fromApiCalls.searchIpByName,
          apiCallData: { labels: { name: newName.trim() } },
          callBack: (response, errors) => {
            this.otherPartiesModel = this.getDummyIpPayload();
            this.otherPartiesModel.name = newName;
            this.otherPartiesModel.fullName = newName;
            this.otherPartiesModel.previousPartyNameId = partyNameId;
            this.otherPartiesModel.previousRole = this.previousRole;
            this.otherPartiesModel.role = role;
            if (response?.total === 1) {
              const { id, relations } = get(response, 'items[0]', []);
              const partyId = find(relations, relation => startsWith(relation.otherId, 'OP:'));
              const ipiNameNumber = find(relations, relation => isEqual(get(relation, 'relation', ''), ActivityType.MATCH) && startsWith(relation.otherId, 'IPI:'));
              this.otherPartiesModel.partyNameId = id;
              this.otherPartiesModel.partyId = partyId?.otherId;
              if (ipiNameNumber) {
                this.otherPartiesModel.ipiNameNumber = CopyrightUtils.getKeySuffix(ipiNameNumber?.otherId);
              }
              this.submitEditedOtherParty(mode);
            } else {
              this.createNewDummyIP(mode);
            }
          },
        }),
      );
    } else {
      this.submitEditedOtherParty(mode);
    }
  }

  private createNewDummyIP(mode: string) {
    this.submitDummyIP().subscribe(dummyIp => {
      if (dummyIp.status === 'success') {
        if (!has(dummyIp, 'data.payload') || dummyIp.data.payload.ok) {
          this.otherPartiesModel.partyNameId = dummyIp.data.idPartyName;
          this.otherPartiesModel.partyId = dummyIp.data.id;
        }
        this.submitEditedOtherParty(mode);
      }
    });
  }

  private submitEditedOtherParty(mode: string) {
    const { partyNameId, ipiNameNumber, fullName, role, firstName, name, partyId, previousPartyNameId, previousRole } = this.otherPartiesModel;
    const newName = mode === 'edit' ? fullName : name || fullName;
    this.dummyIpMode = true;
    this.store.dispatch(
      new fromRoot.UpdateField({
        object: 'otherParties',
        newValue: { partyNameId, ipiNameNumber, name: newName.trim(), role, firstName, partyId, previousPartyNameId, previousRole },
        type: mode === 'edit' ? 'edit' : 'new',
      }),
    );
    this.store.dispatch(new fromRoot.SetDummyIpEmpty());
    this.dialog.closeAll();
    this.otherPartiesModel.partyNameId = undefined;
  }

  private getOtherPartiesForm(fieldValidatorService: FieldValidatorService, mode: string): FormlyFieldConfig[] {
    return [
      fieldConfig([
        {
          className: 'flex-1',
          key: 'ipiNameNumber',
          hideExpression: model => !!this.dummyIpMode,
          wrappers: ['form-field', 'wrapper-input-text'],
          type: 'input',
          templateOptions: {
            placeholder: `${this.translate.instant('WORKS.OTHER_PARTIES.TABLE.IPI_NAME_NUMBER')}`,
          },
          expressionProperties: {
            'templateOptions.disabled': model => (!!model.fullName && !model.ipiNameNumber) || mode === 'edit',
          },
          asyncValidators: {
            other_party_validation: {
              expression: (control: FormControl, field) => {
                return new Promise((resolve, reject) => {
                  return fieldValidatorService.validIPKey(
                    control,
                    ({ partyNameId, firstName, name, fullName }) => {
                      if (control.parent) {
                        control.parent.get('name').setValue(name);
                        control.parent.get('firstName').setValue(firstName);
                        control.parent.get('fullName').setValue(fullName);
                        control.parent.get('partyNameId').setValue(partyNameId);
                      }
                      resolve(true);
                    },
                    () => {
                      resolve(false);
                    },
                  );
                });
              },
              message: this.translate.instant('WORKS.OTHER_PARTIES.ERRORS.IPI_SEARCH_ERROR'),
            },
          },
        },
      ]),
      fieldConfig([
        {
          className: 'ice-display-none',
          key: 'firstName',
          type: 'input',
          templateOptions: {
            placeholder: '',
            required: false,
          },
          validators: {},
        },
      ]),
      fieldConfig([
        {
          className: 'ice-display-none',
          key: 'name',
          type: 'input',
          templateOptions: {
            placeholder: '',
            required: false,
          },
          validators: {},
        },
      ]),
      fieldConfig([
        {
          className: 'ice-display-none',
          key: 'partyNameId',
          type: 'input',
          templateOptions: {
            placeholder: '',
            required: false,
          },
          validators: {},
        },
      ]),
      fieldConfig([
        {
          className: 'ice-display-none',
          key: 'partyId',
          type: 'input',
          templateOptions: {
            placeholder: '',
            required: false,
          },
          validators: {},
        },
      ]),
      fieldConfig([
        {
          className: 'flex-1',
          key: 'fullName',
          type: 'input',
          templateOptions: {
            placeholder: `${this.translate.instant('WORKS.OTHER_PARTIES.TABLE.NAME')}`,
            change: field => (this.dummyIpModel = { ...this.dummyIpModel, name: field.form.get('fullName').value }),
          },
          expressionProperties: {
            'templateOptions.disabled': model => !!model.ipiNameNumber,
          },
          validators: {},
        },
      ]),
      fieldConfig([
        {
          className: 'flex-1',
          key: 'role',
          type: 'select',
          templateOptions: {
            label: `${this.translate.instant('WORKS.OTHER_PARTIES.TABLE.TYPE_OF_NAME')}`,
            required: true,
            options: IP_TYPES,
          },
          hooks: {
            onInit: field => {
              this.otherPartiesForm = field.formControl.parent;
            },
            onDestroy: () => {
              this.subscriptionEditMode.unsubscribe();
            },
          },
          validators: {},
        },
      ]),
    ];
  }

  deleteOtherPartiesPopup(row: any) {
    const dialogInfo = new DialogInfo(this.translate, this.dialog, this.store);
    const dialogRefInfo = dialogInfo.openDialog(
      this.translate.instant('WORKS.OTHER_PARTIES.REMOVE'),
      this.translate.instant('WORKS.OTHER_PARTIES.CHECK_DELETE'),
      () => {
        this.store.dispatch(new fromRoot.UpdateField({ object: 'otherParties', newValue: { partyNameId: row.partyNameId, role: row.role }, type: 'delete' }));
        this.dialog.closeAll();
      },
      () => dialogRefInfo.close(),
    );
  }

  private getVisibleColumnsOtherParties(schema: any): Observable<string[]> {
    const schemaDatatable = TabsUtils.getSChemaPropsToArray(schema);

    return this.store.pipe(
      select(fromRoot.getEditMode),
      map(editMode => {
        if (editMode) {
          return schemaDatatable;
        } else {
          return TabsUtils.getSchemaFiltered(schemaDatatable, ['editBtn', 'deleteBtn']);
        }
      }),
    );
  }
}
