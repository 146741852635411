import { ComponentFactoryResolver, ComponentRef, Directive, ElementRef, EventEmitter, HostListener, Injector, Input, OnDestroy, Output, ViewContainerRef } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getCaretCoordinates } from './helper';
import type { ParsedInput, QueryLanguageParser } from './query-editor.component';
import { TypeaheadMenuComponent } from './typeahead-menu.component';
import type { TypeaheadState, ChoiceSelectedEvent } from './typeahead.model';

const isMac = navigator.platform.startsWith('Mac');

@Directive({
  selector: 'textarea[iceTypeahead],input[type="text"][iceTypeahead]',
})
export class TypeaheadDirective<T> implements OnDestroy {
  typeaheadState: TypeaheadState<T> = {
    key: '',
    text: '',
    typeMask: [],
    levelMask: [],
    tokenTypeType: null,
    tokenType: [],
    selectionStart: 0,
    triggerCharacterPosition: 0,
    tags: [],
    tokens: [],
  };

  /**
   * The regular expression that will match the search text after the trigger character
   */
  @Input() parsed: ParsedInput<T>;
  @Input() searchRegexp = /^\w*$/;

  /**
   * The menu component to show with available options.
   * You can extend the built in `TextInputAutocompleteMenuComponent` component to use a custom template
   */
  @Input() menuComponent = TypeaheadMenuComponent;

  /**
   * Called when the options menu is shown
   */
  @Output() menuShown = new EventEmitter();

  /**
   * Called when the options menu is hidden
   */
  @Output() menuHidden = new EventEmitter();

  /**
   * Called when a choice is selected
   */
  @Output() choiceSelected = new EventEmitter<ChoiceSelectedEvent>();

  /**
   * A function that accepts a search string and returns an array of tokenType. Can also return a promise.
   */

  @Input()
  queryLanguageParser: QueryLanguageParser<T>;

  /* eslint-disable  @typescript-eslint/member-ordering */
  private menu:
    | {
        component: ComponentRef<TypeaheadMenuComponent>;
        triggerCharacterPosition: number;
        triggerEndPosition?: number;
        lastCaretPosition?: number;
      }
    | undefined;

  private menuHidden$ = new Subject();
  private ctrlDown: boolean;
  private altDown: boolean;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private viewContainerRef: ViewContainerRef, private injector: Injector, private elm: ElementRef) {}

  @HostListener('keyup', ['$event.keyCode'])
  onKeyup(keyCode: number) {
    if (keyCode === 17 && !isMac) {
      this.ctrlDown = false;
    } else if (keyCode === 18 && isMac) {
      this.altDown = false;
    }
  }

  @HostListener('keydown', ['$event.key', '$event.target', '$event.ctrlKey', '$event.metaKey', '$event.keyCode', '$event.ctrlKey', '$event'])
  onKeydown(key: string, target: any, ctrlKey: boolean, metaKey: boolean, keyCode: number, altKey: boolean, e: any) {
    if (keyCode === 17 && !isMac) {
      this.ctrlDown = true;
    } else if (keyCode === 18 && isMac) {
      this.altDown = true;
    }
    if ((this.altDown || this.ctrlDown) && keyCode === 32) {
      // Open "intellisense" and show all options
      e.preventDefault();
      const text = target.value;
      const selectionStart = target.selectionStart;
      const cursor = selectionStart;
      const parsed = this.queryLanguageParser.tokenize('', text, selectionStart);
      const typeaheadState = this.queryLanguageParser.calculateTypeaheadState(parsed, cursor);
      this.typeaheadState = typeaheadState;
      if (!this.menu) {
        this.showMenu();
      } else {
        this.hideMenu();
        this.showMenu();
      }
    } else if (key.length === 1 || key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Backspace' || key === 'Delete') {
      const text = target.value;
      const selectionStart = target.selectionStart;
      const parsed = this.queryLanguageParser.tokenize(key, text, selectionStart);
      let cursor = selectionStart;
      if (key === 'Backspace' || key === 'ArrowLeft') {
        cursor = selectionStart > 1 ? selectionStart - 2 : 0;
      } else if (key === 'ArrowRight') {
        cursor = selectionStart < text.length ? selectionStart + 1 : 0;
      }
      const typeaheadState = this.queryLanguageParser.calculateTypeaheadState(parsed, cursor);
      if (typeaheadState.tokenTypeType !== this.typeaheadState.tokenTypeType) {
        this.hideMenu();
      }
      if (key.length === 1 && typeaheadState.tokenType.length > 0) {
        this.typeaheadState = typeaheadState;
        if (!this.menu) {
          this.showMenu();
        }
      } else {
        this.typeaheadState.triggerCharacterPosition = typeaheadState.triggerCharacterPosition;
        this.typeaheadState.triggerEndPosition = typeaheadState.triggerEndPosition;
        if (this.menu) {
          this.menu.triggerCharacterPosition = this.typeaheadState.triggerCharacterPosition;
          this.menu.triggerEndPosition = this.typeaheadState.triggerEndPosition;
        }
      }
    } else if (this.menu && key === 'Escape') {
      this.hideMenu();
    }
  }

  @HostListener('input', ['$event.target.value'])
  onChange(value: string) {
    if (this.menu) {
      const cursor = this.elm.nativeElement.selectionStart;
      if (cursor < this.menu.triggerCharacterPosition) {
        this.hideMenu();
      } else {
        const searchText = value.slice(this.menu.triggerCharacterPosition, cursor);
        this.menu.component.instance.searchText = searchText;
        this.menu.component.instance.tokenType = [];
        this.menu.component.instance.choiceLoadError = undefined;
        this.menu.component.instance.choiceLoading = true;
        this.menu.component.changeDetectorRef.detectChanges();
        Promise.resolve(this.queryLanguageParser.findTokenType(this.typeaheadState, searchText))
          .then(tokenType => {
            if (this.menu) {
              this.menu.component.instance.tokenType = tokenType;
              this.menu.component.instance.choiceLoading = false;
              this.menu.component.changeDetectorRef.detectChanges();
            }
          })
          .catch(err => {
            if (this.menu) {
              this.menu.component.instance.choiceLoading = false;
              this.menu.component.instance.choiceLoadError = err;
              this.menu.component.changeDetectorRef.detectChanges();
            }
          });
      }
    }
  }

  @HostListener('blur')
  onBlur() {
    if (this.menu) {
      this.menu.lastCaretPosition = this.elm.nativeElement.selectionStart;
    }
  }

  showMenu() {
    if (!this.menu) {
      const menuFactory = this.componentFactoryResolver.resolveComponentFactory<TypeaheadMenuComponent>(this.menuComponent);
      this.menu = {
        component: this.viewContainerRef.createComponent(menuFactory, 0, this.injector),
        triggerCharacterPosition: this.typeaheadState.triggerCharacterPosition,
        triggerEndPosition: this.typeaheadState.triggerEndPosition,
      };
      const style = getComputedStyle(this.elm.nativeElement);
      const lineHeight = style && style.lineHeight && style.lineHeight.replace(/px$/, '');
      const { top, left } = getCaretCoordinates(this.elm.nativeElement, this.typeaheadState.selectionStart);
      this.menu.component.instance.position = {
        top: +top + +lineHeight,
        left: left - 9,
      };
      this.menu.component.changeDetectorRef.detectChanges();

      const textarea: HTMLTextAreaElement = this.elm.nativeElement;
      textarea.dispatchEvent(new Event('input'));
      this.menu.component.instance.selectChoice.pipe(takeUntil(this.menuHidden$)).subscribe(choice => {
        const label = this.queryLanguageParser.getChoiceLabel(choice as string);
        // const textarea: HTMLTextAreaElement = this.elm.nativeElement
        const value: string = textarea.value;

        const startIndex = this.menu && this.menu.triggerCharacterPosition;
        const start = value.slice(0, startIndex);
        const caretPosition = (this.menu && this.menu.triggerEndPosition) || (this.menu && this.menu.lastCaretPosition) || textarea.selectionStart;
        const end = value.slice(caretPosition);
        textarea.value = start + label + end;
        // force ng model / form control to update
        textarea.dispatchEvent(new Event('input'));
        this.hideMenu();
        const setCursorAt = (start + label).length;
        textarea.setSelectionRange(setCursorAt, setCursorAt);
        textarea.focus();
        this.choiceSelected.emit({
          choice,
          insertedAt: {
            start: startIndex,
            end: startIndex + label.length,
          },
        });
      });
      this.menuShown.emit();
    }
  }

  private hideMenu() {
    if (this.menu) {
      this.menu.component.destroy();
      this.menuHidden$.next();
      this.menuHidden.emit();
      this.menu = undefined;
    }
  }

  ngOnDestroy() {
    this.hideMenu();
  }
}
