import { NgModule } from '@angular/core';
import { CustomTooltipModule } from '@ice/components/custom-tool-tip/custom-tool-tip.module';
import { AutoFocusDirective } from './auto-focus/auto-focus.directive';
import { ToolTipRendererDirective } from './custom-tool-tip/tool-tip-renderer.directive';
import { DebounceKeyDirective } from './debounce-key/debounce-key.directive';

@NgModule({
  imports: [CustomTooltipModule],
  declarations: [DebounceKeyDirective, AutoFocusDirective, ToolTipRendererDirective],
  exports: [DebounceKeyDirective, AutoFocusDirective, ToolTipRendererDirective],
})
export class DirectivesModule {}
