export enum SectionRouterViews {
  home = 'HOME',
  search = 'SEARCH',
  detail = 'DETAIL',
  new = 'NEW',
  edit = 'EDIT',
  editTab = 'EDIT_TAB',
  clone = 'CLONE',
  dashboard = 'DASHBOARD',
}
