import { CounterClaimUtils, TerritoryUtils } from '@ice';
import { select } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { CLAIM_TYPES, REPERTOIRE_TYPES } from 'config/constants/repertoires.constants';
import { StepType } from 'config/stepper-builders/stepper-config';
import { countBy, get } from 'lodash';
import { Observable, Subject, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromNewSectionItem from 'store/new-section-item';

const TOGGLE_FIELD_BUTTON = value => ({ key: 'buttonDisabled', value });
const TOGGLE_VALIDATING_FIELD = value => ({ key: 'validatingField', value });
const TOGGLE_EXISTS_PARTY_FIELD = value => ({ key: 'existsPartyId', value });

export class RepertoireDetailsStep {
  private isTypeSociety: Observable<boolean> = of(false);
  private unsubscribeAll = new Subject();
  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: any,
    private store: any,
    private storeNewItem: any,
    private dialog: any,
    private fieldValidatorService: FieldValidatorService,
  ) {
    this.isTypeSociety = this.storeNewItem.pipe(
      select(fromNewSectionItem.getNewSectionItemFields),
      map((items: any) => {
        return items?.type === REPERTOIRE_TYPES.SOCIETY;
      }),
    );
  }

  updateTemplateOptions(field, options) {
    if (field) {
      options.forEach(option => (field.templateOptions[option.key] = option.value));
    }
  }

  getStep(translate: TranslateService): StepType {
    const bindSocietyType = field => {
      field.hide = true;
      this.isTypeSociety.pipe(takeUntil(this.unsubscribeAll)).subscribe(value => {
        field.hide = !value;
      });
    };

    return {
      label: translate.instant('REPERTOIRES.STEPS.DETAILS.TITLE'),
      formBuilder: [
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-2',
              fieldGroup: [
                {
                  key: 'opDetermine',
                  type: 'checkbox',
                  defaultValue: false,
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  templateOptions: {
                    label: translate.instant('REPERTOIRES.STEPS.DETAILS.FIELDS.OP_CAN_DETERMINE'),
                    required: false,
                  },
                  hooks: {
                    onInit: field => {
                      bindSocietyType(field);
                    },
                  },
                },
              ],
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-1',
              template: `<h2>${translate.instant('REPERTOIRES.STEPS.DETAILS.FIELDS.ROLL_UP.TITLE')}</h2>`,
              hooks: {
                onInit: field => {
                  bindSocietyType(field);
                },
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-1',
              key: 'publisherRollup',
              type: 'checkbox',
              defaultValue: false,
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                type: 'text',
                label: translate.instant('REPERTOIRES.STEPS.DETAILS.FIELDS.ROLL_UP.FIELDS.ROLL_UP'),
                required: false,
              },
              hooks: {
                onInit: field => {
                  bindSocietyType(field);
                },
              },
            },
            TerritoryUtils.getTerritoryField(
              'rollupExcludeTerritoriesText',
              'term_territory_codes',
              translate.instant('REPERTOIRES.STEPS.DETAILS.FIELDS.ROLL_UP.FIELDS.EXCLUDED_TERRITORIES'),
              translate,
              this.fieldValidatorService,
              false,
              null,
              null,
              {
                'templateOptions.disabled': model => {
                  return !model.publisherRollup;
                },
              },
              {
                onInit: field => {
                  const form = field.form;
                  field.templateOptions.bindedFieldTermTerritoryCodes = form.controls['term_territory_codes'];
                  bindSocietyType(field);
                },
              },
            ),
            {
              className: 'flex-1',
              key: 'publisherRollupToNonSoc',
              type: 'checkbox',
              defaultValue: false,
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                type: 'text',
                label: translate.instant('REPERTOIRES.STEPS.DETAILS.FIELDS.ROLL_UP.FIELDS.ALLOW_NON_SOCIETY_OP'),
                required: false,
              },
              expressionProperties: {
                'templateOptions.disabled': model => {
                  return !model.publisherRollup;
                },
              },
              hooks: {
                onInit: field => {
                  bindSocietyType(field);
                },
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          className: 'flex-2',
          fieldGroup: [
            {
              className: 'flex-2',
              fieldGroup: [
                {
                  key: 'usageTypes',
                  type: 'select',
                  defaultValue: '',
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  templateOptions: {
                    label: translate.instant('REPERTOIRES.STEPS.DETAILS.FIELDS.USAGE_TYPES.TITLE'),
                    required: true,
                    multiple: true,
                    options: CounterClaimUtils.getRightsOptions(translate),
                  },
                },
              ],
            },
            {
              className: 'flex-2',
              fieldGroup: [
                {
                  key: 'claimTypes',
                  type: 'select',
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  templateOptions: {
                    label: translate.instant('REPERTOIRES.STEPS.DETAILS.FIELDS.CLAIM_TYPES.TITLE'),
                    required: true,
                    multiple: true,
                    options: [
                      { label: translate.instant('REPERTOIRES.STEPS.DETAILS.FIELDS.CLAIM_TYPES.OPTIONS.DIRECT'), value: CLAIM_TYPES.SUBMITTED },
                      // DIRECT IS EQUAL TO SUBMITTED
                      { label: translate.instant('REPERTOIRES.STEPS.DETAILS.FIELDS.CLAIM_TYPES.OPTIONS.DERIVED'), value: CLAIM_TYPES.DERIVED },
                      { label: translate.instant('REPERTOIRES.STEPS.DETAILS.FIELDS.CLAIM_TYPES.OPTIONS.ASSUMED'), value: CLAIM_TYPES.ASSUMED },
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    };
  }

  assignPartyId(field: FormlyFieldConfig, value: string) {
    const partyIdFormControl = field.formControl.parent.controls['partyId'];
    partyIdFormControl.setValue(value);
  }

  ownRepeaterValidation(repeaterField: FormlyFieldConfig) {
    const counter = countBy(
      repeaterField.fieldGroup.map(currentfield => currentfield.formControl.value),
      'partyId',
    );
    repeaterField.fieldGroup.forEach(repeaterItem => {
      const repeaterItemKeyField = get(repeaterItem, 'fieldGroup[0].fieldGroup', []).find(fieldInfo => fieldInfo.key === 'key');
      if (repeaterItemKeyField) {
        const partyIdValue = get(repeaterItem, 'formControl.value.partyId');
        const partyIdValueCount = (partyIdValue && counter[`${partyIdValue}`]) || 0;
        if (repeaterItemKeyField.templateOptions.existsPartyIdInThisRepeater !== partyIdValueCount > 1) {
          repeaterItemKeyField.templateOptions.existsPartyIdInThisRepeater = partyIdValueCount > 1;
          repeaterItemKeyField.formControl.updateValueAndValidity();
        }
      }
    });
  }

  otherRepeaterValidation(repeaterField: FormlyFieldConfig, otherRepeaterField: FormlyFieldConfig) {
    const counter = countBy(
      repeaterField.fieldGroup.map(currentfield => currentfield.formControl.value),
      'partyId',
    );
    otherRepeaterField.fieldGroup.forEach(repeaterItem => {
      const repeaterItemKeyField = get(repeaterItem, 'fieldGroup[0].fieldGroup', []).find(fieldInfo => fieldInfo.key === 'key');
      if (repeaterItemKeyField) {
        const partyIdValue = get(repeaterItem, 'formControl.value.partyId');
        const partyIdValueCount = (partyIdValue && counter[`${partyIdValue}`]) || 0;
        if (repeaterItemKeyField.templateOptions.existsPartyId !== partyIdValueCount > 0) {
          repeaterItemKeyField.templateOptions.existsPartyId = partyIdValueCount > 0;
          repeaterItemKeyField.formControl.updateValueAndValidity();
        }
      }
    });
  }

  updateExistsPartyIdValue(field: FormlyFieldConfig, value: string, otherRepeaterField: FormlyFieldConfig) {
    const isThisValueInTheOtherRepeater = otherRepeaterField.formControl.value.some(collector => collector.partyId === value);
    field.templateOptions.existsPartyId = isThisValueInTheOtherRepeater;
    field.formControl.updateValueAndValidity();
  }

  formlyOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
