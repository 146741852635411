import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { IceMaterialModule } from '@ice/material.module';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { TranslateModule } from '@ngx-translate/core';
import { FormlyFieldLabelComponent } from './label-type.component';

@NgModule({
  declarations: [FormlyFieldLabelComponent],
  imports: [TranslateModule, FuseSharedModule, IceMaterialModule, FormlyMaterialModule, FormlyModule.forRoot()],
  exports: [FormlyFieldLabelComponent],
})
export class FormlyFieldLabelModule {}
