import * as fromRouter from '@ngrx/router-store';

import { createSelector } from '@ngrx/store';
import * as fromFeature from 'store/root/reducers';
import { RouterStateUrl } from 'store/root/reducers';

export const getRouterUrl = createSelector(fromFeature.getRouterFeatureState, (state: fromRouter.RouterReducerState<RouterStateUrl>) => state && state.state && state.state.url);

export const getRouterParams = createSelector(
  fromFeature.getRouterFeatureState,
  (state: fromRouter.RouterReducerState<RouterStateUrl>) => state && state.state && state.state.params,
);

export const getRouterQueryParams = createSelector(
  fromFeature.getRouterFeatureState,
  (state: fromRouter.RouterReducerState<RouterStateUrl>) => (state && state.state && state.state.queryParams) || {},
);

export const getRouterTab = createSelector(
  fromFeature.getRouterFeatureState,
  (state: fromRouter.RouterReducerState<RouterStateUrl>) => state && state.state && state.state.params && state.state.params.tab,
);

export const getRouterSection = createSelector(fromFeature.getRouterFeatureState, (state: fromRouter.RouterReducerState<RouterStateUrl>): string => {
  if (state && state.state && state.state.section) {
    return state.state.section;
  }
  return '';
});

export const getRouterView = createSelector(
  fromFeature.getRouterFeatureState,
  (state: fromRouter.RouterReducerState<RouterStateUrl>) => (state && state.state && state.state.view) || '',
);

export const getRouterPaths = createSelector(
  fromFeature.getRouterFeatureState,
  (state: fromRouter.RouterReducerState<RouterStateUrl>) => (state && state.state && state.state.paths) || [],
);

export const getRouterMainPath = createSelector(getRouterPaths, paths => (paths && paths.length ? paths[0] : ''));

export const getRouterState = createSelector(fromFeature.getRouterFeatureState, state => state && state.state);

export const getRouterSimpleSearchTerm = createSelector(
  fromFeature.getRouterFeatureState,
  (state: fromRouter.RouterReducerState<RouterStateUrl>) =>
    (state &&
      state.state &&
      state.state.paths &&
      state.state.paths.length > 0 &&
      state.state.paths[state.state.paths.length - 1] === 'search' &&
      state.state.queryParams &&
      state.state.queryParams.term) ||
    '',
);

export const getRouterAdvancedSearchParams = createSelector(
  fromFeature.getRouterFeatureState,
  (state: fromRouter.RouterReducerState<RouterStateUrl>) =>
    (state && state.state && state.state.paths && state.state.paths.length > 0 && state.state.paths[state.state.paths.length - 1] === 'search' && state.state.queryParams) || {},
);
