import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AgreementSearchItemCleaned, AgreementUtils, CC_STATUS_CLOSED, CC_STATUS_RESOLVED, CounterClaimUtils, Ip, IpUtils, PartyAttributes } from '@ice';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { ClaimsUtils } from '@ice/utils/claim/claims.utils';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as englishShared } from 'assets/i18n/en/app/common/shared';
import { locale as englishEdit } from 'assets/i18n/en/config/section-new-data-builders';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import { CopyrightAgreementsDataTable } from 'config/data-table-builders/copyright.agreements';
import { get } from 'lodash';
import { AgreementConflict } from 'models/copyright/detail/agreement-conflict';
import { PartySociety } from 'models/response/search-party-names.response';
import moment from 'moment';
import { map } from 'rxjs/operators';
import * as fromRoot from 'store/root';
import { SectionTabConfig } from '../tabs-data';

export class TabAgreementConflict implements SectionTabConfig {
  searchTableBuilder: CopyrightAgreementsDataTable;

  constructor(private translate: TranslateService, private translationLoader: FuseTranslationLoaderService, private store: Store<fromRoot.RootState>) {
    this.translationLoader.loadTranslations(english);
    this.translationLoader.loadTranslations(englishShared);
    this.translationLoader.loadTranslations(englishEdit);
    this.searchTableBuilder = new CopyrightAgreementsDataTable(translate, translationLoader, store);
  }

  getConf(): IceGroupComponent[] {
    const cardWithDataTableHeight = '380px';
    return [
      {
        group: [
          {
            type: 'cardWithForm',
            config: {
              title: this.translate.instant('AGREEMENT_CONFLICT.DETAILS.CONFLICT_STATUS'),
              model: this.store.pipe(select(fromRoot.getCopyrightAgreementConflict)).pipe(map(this.parseStatusAndDeadline.bind(this))),
              formBuilder: this.getFormly(),
            },
          },
        ],
      },
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('AGREEMENT_CONFLICT.DETAILS.AGREEMENTS'),
              model: this.store.pipe(select(fromRoot.getCopyrightAgreementConflict)).pipe(map(this.parseAgreements.bind(this))),
              columnMode: 'flex',
              schema: this.searchTableBuilder.getDataTable(),
              tableWidth: '100',
              height: cardWithDataTableHeight,
              onSelect: rowList => this.store.dispatch(new fromRoot.Go({ path: [`copyright/agreements/${get(rowList, '[0].key', '')}/details`] })),
              onMouseSelect: row => this.store.dispatch(new fromRoot.OpenNewTab({ path: [`copyright/agreements/${get(row, 'key', '')}/details`] })),
            },
          },
        ],
      },
    ];
  }

  private getFormly() {
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-2',
            key: 'id',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('AGREEMENT_CONFLICT.DETAILS.POSSIBLE_CONFLICT_CASE'), required: false, disabled: true },
          },
          {
            className: 'flex-2',
            key: 'status',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('AGREEMENT_CONFLICT.DETAILS.STATUS'), required: false, disabled: true },
          },
          {
            className: 'ice-display-none',
            key: 'statusValue',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('AGREEMENT_CONFLICT.DETAILS.STATUS'), required: false, disabled: true },
          },
          {
            className: 'flex-2',
            key: 'deadline',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('AGREEMENT_CONFLICT.DETAILS.DEADLINE'), required: false, disabled: true },
          },
          {
            key: 'icon',
            className: 'ice-bt-ip',
            type: 'label',
            templateOptions: {
              materialType: 'mat-icon-button',
              icons: {
                icon: 'error_outline',
              },
              tooltipText: this.translate.instant('AGREEMENT-CONFLICT.DEADLINE_REACHED'),
            },
            hideExpression: model => {
              const { statusValue, deadline } = model;
              return !(![CC_STATUS_RESOLVED, CC_STATUS_CLOSED].includes((statusValue || '').toUpperCase()) && moment(deadline).isBefore());
            },
          },
        ],
      },
    ];
  }

  parseStatusAndDeadline(detail: AgreementConflict) {
    if (detail) {
      const statusValue = get(detail, 'attributes.status');
      return {
        id: detail.id,
        status: this.translate.instant(`AGREEMENTS.${statusValue}`),
        statusValue,
        deadline: get(detail, 'deadline'),
      };
    }
    return {};
  }

  parseAgreements(detail: AgreementConflict) {
    if (detail && detail.agreements && detail.agreements.length > 0) {
      return detail.agreements.map(item => {
        const { agreement } = item;
        const { id, attributes, relations, assignor, assignee, counterclaims } = agreement;
        const workCount = get(agreement, 'agg.attributes.workCount', 0);
        const { shares, administrator, retention } = attributes;
        let fromShares = {
          territoriesIcon: null,
          territoriesText: null,
          territoriesTooltip: null,
          startDate: null,
          endDate: null,
          postTermCollectionDate: null,
          priorRoyaltiesStartDate: null,
          sharesIcons: null,
          pr: null,
          prIcons: null,
          mr: null,
          mrIcons: null,
        };
        if (shares) {
          fromShares = { ...ClaimsUtils.getSharesFormatted(shares, this.translate) };
        }

        const agreementCounterClaimStatus = CounterClaimUtils.getAgreementCounterClaimStatus(counterclaims);

        let assignorPartyAttributes: PartyAttributes;
        let assignorPartyNameAttributes: Ip;
        let assignorSocieties: PartySociety[];
        let assigneePartyNameAttributes: Ip;
        let assigneeSocieties: PartySociety[];
        let assignorObject = {};
        let assigneeObject = {};
        if (assignor) {
          assignorPartyNameAttributes = get(assignor, 'partyName.attributes');
          if (assignor.party) {
            assignorPartyAttributes = assignor.party.attributes;
            assignorSocieties = assignor.party.societies;
            if (assignorPartyNameAttributes) {
              assignorObject = {
                id: assignorPartyNameAttributes.id,
                name: assignorSocieties && AgreementUtils.selectPartyName(assignorPartyNameAttributes, assignorSocieties, true),
                icons: AgreementUtils.generateAssignorIcons(assignorPartyAttributes, this.translate),
              };
            }
          } else if (assignor.name) {
            assignorObject = assignor;
          }
        }
        if (assignee) {
          assigneePartyNameAttributes = get(assignee, 'partyName.attributes');
          assigneeSocieties = get(assignee, 'party.societies');
          if (assigneePartyNameAttributes) {
            assigneeObject = {
              id: assigneePartyNameAttributes.id,
              name: assigneeSocieties && AgreementUtils.selectPartyName(assigneePartyNameAttributes, assigneeSocieties, true),
            };
          } else if (assignee.name) {
            assigneeObject = assignee;
          }
        }

        let agreementTypeTooltip;
        if (attributes?.agreementType) {
          agreementTypeTooltip = this.translate.instant(`AGREEMENTS.TYPE_TOOLTIP.${attributes.agreementType}`);
        }

        return <AgreementSearchItemCleaned>{
          ...attributes,
          key: IpUtils.selectIpsKey(relations, id),
          startDate: fromShares.startDate,
          endDate: fromShares.endDate,
          postTermCollectionDate: fromShares.postTermCollectionDate,
          priorRoyaltiesStartDate: fromShares.priorRoyaltiesStartDate,
          administratorIcon: AgreementUtils.generateAdministratorIcon(administrator, this.translate),
          disputeIcon: AgreementUtils.generateDisputeIcon(agreementCounterClaimStatus, this.translate),
          sharesIcons: fromShares.sharesIcons,
          territoriesText: fromShares.territoriesText,
          territoriesIcon: fromShares.territoriesIcon,
          territoriesTooltip: fromShares.territoriesTooltip,
          pr: fromShares.pr,
          prIcons: fromShares.prIcons,
          mr: fromShares.mr,
          mrIcons: fromShares.mrIcons,
          assignor: assignorObject,
          assignee: assigneeObject,
          workCount,
          agreementTypeTooltip,
          retention: false,
          retentionValue: AgreementUtils.getRetentionIcon(retention, this.translate)
            .map(icon => icon.text)
            .join(', '),
          retentionIcons: AgreementUtils.getRetentionIcon(retention, this.translate),
          dataType: 'agreement',
        };
      });
    }
    return [];
  }
}
