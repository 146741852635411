import { cloneDeep, concat, pickBy, values } from 'lodash';
import moment from 'moment';
import * as fromActions from 'store/root/actions';
import * as fromState from 'store/root/state';
import { SelectedSearchResultsBySection } from 'store/root/state';
import * as uuid from 'uuid';

export function reducer(state: fromState.SearchState = fromState.initialSearchState, action: fromActions.SearchActions): fromState.SearchState {
  switch (action.type) {
    case fromActions.FETCH_SEARCH_PAGE: {
      const xrefList = (action.payload.search.params && Object.keys(action.payload.search.params).includes('xref0') && { xrefList: uuid.v4().replace(/-/g, '') }) || {};
      const params = action.payload.search.params && pickBy(action.payload.search.params, (value, key) => !/\bxref.*[0-9]\b/.test(key));
      const input = { ...state.input, ...action.payload.search, params: { ...(action.payload.notSaveParams ? state.input.params : params), ...xrefList } };
      return {
        ...state,
        input,
        forceCancel: false,
      };
    }
    case fromActions.SET_SEARCH_XREF_LIST: {
      return { ...state, searchXrefList: action.payload };
    }
    case fromActions.CANCEL_SEARCH: {
      return { ...state, forceCancel: true };
    }
    case fromActions.FETCH_SEARCH_PAGE_SUCCESS: {
      let items = [];
      if (action.payload.addRows) {
        items = concat(values(state.entities), action.payload.items);
      } else {
        items = action.payload.items;
      }
      const successPayload = {
        ...state,
        extra: action.payload.extra || null,
      };
      return fromState.adapter.setAll(items, successPayload);
    }
    case fromActions.SELECT_SEARCH_ITEM: {
      return action.payload
        ? {
            ...state,
            selectedItem: action.payload,
          }
        : state;
    }
    case fromActions.RESET_SEARCH_RESULTS: {
      const history = { ...state.history };
      if (action.payload) {
        if (action.payload.section) {
          delete history[action.payload.section];
        }
      }

      return {
        ...state,
        ...fromState.initialSearchState,
        history,
      };
    }
    case fromActions.RESET_SEARCH: {
      return {
        ...fromState.initialSearchState,
      };
    }
    case fromActions.SEARCH_RESET: {
      return {
        ...state,
        searchReseted: true,
      };
    }
    case fromActions.EMPTY_RESULTS_ON_SEARCH_CHANGE: {
      const input = { ...state.input, ...action.payload.search };
      return {
        ...state,
        input,
        entities: {},
        extra: null,
        ids: [],
      };
    }
    case fromActions.SAVE_SEARCH_TO_HISTORY: {
      const newEntry = !state.searchReseted ? { [action.payload]: { ...state.input, timestamp: moment().valueOf() } } : null;
      const history = { ...state.history, ...newEntry };
      return { ...state, history, searchReseted: false };
    }
    case fromActions.MARK_SEARCH_ITEM: {
      const newElements = action.payload.value;
      const sectionName = action.payload.section;
      const newSelectedRowsSearchResults = Object.assign({}, state.selectedRowsSearchResults) as SelectedSearchResultsBySection;
      newSelectedRowsSearchResults[sectionName] = [...newElements];

      return {
        ...state,
        selectedRowsSearchResults: newSelectedRowsSearchResults,
      };
    }
    case fromActions.SET_ASSIGNEE_CONFLICTS_SELECTION: {
      const newSelectedRowsSearchResults = Object.assign({}, state.selectedRowsSearchResults) as SelectedSearchResultsBySection;
      newSelectedRowsSearchResults.id = action.payload.userId;

      return {
        ...state,
        selectedRowsSearchResults: newSelectedRowsSearchResults,
      };
    }
    case fromActions.UPDATE_CONFLICTS_SUCCESS: {
      const selectedRowsSearchResults = cloneDeep(state.selectedRowsSearchResults);
      selectedRowsSearchResults['activity']?.forEach(conflict => {
        const matchConflict = action.payload.find(match => match.id === conflict.id);
        if (matchConflict) {
          conflict.case = matchConflict.case;
          conflict.caseStatus = matchConflict.case.status;
        }
      });
      return { ...state, selectedRowsSearchResults };
    }

    case fromActions.SET_USERS_EXPERT_SEARCH_DEFAULT: {
      const input = { ...state.input, query: 'status == ACTIVE' };
      return {
        ...state,
        input,
        forceCancel: false,
      };
    }

    case fromActions.SET_UPDATE_CONFLICT_TYPE: {
      return {
        ...state,
        conflictStatus: action.payload,
      };
    }
  }
  return state;
}

export const getSearchInputState = (state: fromState.SearchState) => state.input;
export const getSearchExtra = (state: fromState.SearchState) => state.extra;
export const getSearchSelectedItem = (state: fromState.SearchState) => state.selectedItem;
export const getSearchHistoryState = (state: fromState.SearchState) => state.history;
export const getSearchForceCancelState = (state: fromState.SearchState) => state.forceCancel;
export const getSearchXrefList = (state: fromState.SearchState) => state.searchXrefList;
export const { selectIds, selectEntities, selectAll, selectTotal } = fromState.adapter.getSelectors();
