import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { FormContainerComponent, FormlyFieldButtonComponent, FormlyFieldLabelComponent, InputTypeComponent, WrapperInfoComponent } from '@ice';
import { InputTypeModule } from '@ice/components/formly-types/input-type/input-type.module';
import { FormlyFieldLabelModule } from '@ice/components/formly-types/label-type/label-type.module';
import { IceMaterialModule } from '@ice/material.module';
import { IsValidValidator } from '@ice/utils';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { TranslateModule } from '@ngx-translate/core';
import { DatepickerTypeComponent } from '../datepicker-type/datepicker-type.component';
import { DatepickerTypeModule } from '../datepicker-type/datepicker-type.module';
import { AutocompleteGroupedTypeComponent } from '../formly-types/autocomplete-grouped-type/autocomplete-grouped-type.component';
import { AutocompleteGroupedTypeModule } from '../formly-types/autocomplete-grouped-type/autocomplete-grouped-type.module';
import { AutocompleteTypeComponent } from '../formly-types/autocomplete-type/autocomplete-type.component';
import { AutocompleteTypeModule } from '../formly-types/autocomplete-type/autocomplete-type.module';
import { FormlyFieldButtonModule } from '../formly-types/button-type/button-type.module';
import { DatatableEditableTypeComponent } from '../formly-types/datatable-editable-type/datatable-editable-type.component';
import { DatatableEditableTypeModule } from '../formly-types/datatable-editable-type/datatable-editable-type.module';
import { SelectTypeComponent } from '../formly-types/select-type/select-type.component';
import { SelectTypeModule } from '../formly-types/select-type/select-type.module';
import { WrapperCharacterCounterComponent } from '../formly-types/wrapper-character-counter/wrapper-character-counter.component';
import { WrapperCharacterCounterModule } from '../formly-types/wrapper-character-counter/wrapper-character-counter.module';
import { WrapperCustomCommentComponent } from '../formly-types/wrapper-custom-comment/wrapper-custom-comment.component';
import { WrapperCustomCommentModule } from '../formly-types/wrapper-custom-comment/wrapper-custom-comment.module';
import { WrapperInfoModule } from '../formly-types/wrapper-info/wrapper-info.module';
import { WrapperInputTextComponent } from '../formly-types/wrapper-input-text/wrapper-input-text/wrapper-input-text.component';
import { WrapperInputTextModule } from '../formly-types/wrapper-input-text/wrapper-input-text/wrapper-input-text.module';
import { WrapperNoIconComponent } from '../formly-types/wrapper-no-icon/wrapper-no-icon.component';
import { WrapperNoIconModule } from '../formly-types/wrapper-no-icon/wrapper-no-icon.module';
import { WrapperSearchComponent } from '../formly-types/wrapper-search/wrapper-search.component';
import { WrapperSearchModule } from '../formly-types/wrapper-search/wrapper-search.module';
import { WrapperTooltipComponent } from '../formly-types/wrapper-tooltip/wrapper-tooltip.component';
import { WrapperTooltipModule } from '../formly-types/wrapper-tooltip/wrapper-tooltip.module';
import { HtmlTemplateComponent } from '../html-template';
import { HtmlTemplateModule } from '../html-template/html-template.module';
@NgModule({
  declarations: [FormContainerComponent],
  imports: [
    TranslateModule,
    CommonModule,
    ReactiveFormsModule,
    IceMaterialModule,
    FlexLayoutModule,
    InputTypeModule,
    SelectTypeModule,
    FormlyFieldLabelModule,
    FormlyFieldButtonModule,
    DatepickerTypeModule,
    WrapperInfoModule,
    WrapperSearchModule,
    AutocompleteTypeModule,
    AutocompleteGroupedTypeModule,
    DatatableEditableTypeModule,
    HtmlTemplateModule,
    FormlyModule.forRoot({
      extras: { checkExpressionOn: 'modelChange' },
      types: [
        { name: 'label', component: FormlyFieldLabelComponent },
        { name: 'ice-input', component: InputTypeComponent },
        { name: 'ice-select', component: SelectTypeComponent },
        { name: 'button', component: FormlyFieldButtonComponent },
        { name: 'ice-autocomplete', component: AutocompleteTypeComponent },
        { name: 'ice-autocomplete-grouped', component: AutocompleteGroupedTypeComponent },
        { name: 'datatable-editable', component: DatatableEditableTypeComponent },
        {
          name: 'datepicker',
          component: DatepickerTypeComponent,
          wrappers: ['form-field'],
          defaultOptions: {
            defaultValue: '',
            templateOptions: {
              datepickerOptions: {},
            },
            validators: {
              valid: IsValidValidator,
            },
          },
        },
        { name: 'htmlTemplate', component: HtmlTemplateComponent },
      ],
      wrappers: [
        { name: 'wrapper-info', component: WrapperInfoComponent },
        { name: 'wrapper-search', component: WrapperSearchComponent },
        { name: 'wrapper-custom-comment', component: WrapperCustomCommentComponent },
        { name: 'wrapper-tooltip', component: WrapperTooltipComponent },
        { name: 'wrapper-input-text', component: WrapperInputTextComponent },
        { name: 'wrapper-no-icon', component: WrapperNoIconComponent },
        { name: 'wrapper-character-counter', component: WrapperCharacterCounterComponent },
      ],
      validationMessages: [{ name: 'required', message: 'Field is required' }],
    }),
    FormlyMaterialModule,
    WrapperTooltipModule,
    WrapperCustomCommentModule,
    WrapperInputTextModule,
    WrapperNoIconModule,
    WrapperCharacterCounterModule,
  ],
  exports: [FormContainerComponent],
})
export class FormContainerModule {}
