import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ClaimGraphUtils, CopyrightUtils, SharePictureUtils, WorkClaimsUtils, TabsUtils, DatatableUtils } from '@ice';
import { DataTable, DataTableRow } from '@ice/components/data-table/data-table';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { IceComponent, IceGroupComponent, IceLayout } from '@ice/dynamic-components/group-component/group-component';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { WORK_CLAIM_SHARE_TYPE } from 'config/constants/shares.constants';
import { WorkAuditEvents } from 'config/constants/works.constants';
import { CopyrightWorkClaimsDataTable } from 'config/data-table-builders/copyright.work-claims';
import { SectionsConfig } from 'config/sections-config';
import { ExpandableData } from 'config/tabs-data-builders/shared/audit-history.model';
import { TabAuditHistory } from 'config/tabs-data-builders/shared/tab-audit-history';
import { IceFacade } from 'facades/ice.facade';
import { cloneDeep, concat, each, filter, find, flattenDeep, get, has, isEqual, isString, map as lodashMap, slice, values } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonApiService } from 'services/common-api.service';
import { DetailService } from 'services/detail/detail.service';
import { NamespaceService } from 'services/namespace/namespace.service';
import { PermissionsService } from 'services/permissions/permissions.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';

export class TabWorksAuditHistory extends TabAuditHistory {
  private dialogRef: MatDialogRef<DialogMultiLayoutComponent, any>;
  private workClaimsMode$ = new BehaviorSubject(WORK_CLAIM_SHARE_TYPE.SHARES);
  private highlightSuffix = 'IsHighlight';
  private highlightClassName = 'highlightCellContent';

  constructor(
    protected translate: TranslateService,
    protected fuseTranslationLoader: FuseTranslationLoaderService,
    protected store: Store<fromRoot.RootState>,
    protected dialog: MatDialog,
    protected commonApiService: CommonApiService,
    protected detailService: DetailService,
    protected nsService: NamespaceService,
    private iceFacade: IceFacade,
    private fieldValidatorService: FieldValidatorService,
    protected permissionsService: PermissionsService,
  ) {
    super(translate, fuseTranslationLoader, store, dialog, permissionsService);
    this.auditTypes = SectionsConfig.WORKS.auditTypes;
    this.addDetailsOnMainDataTable = true;
  }

  protected getTableOneModel() {
    return this.store.pipe(
      select(fromRoot.getWorkAuditHistory),
      map(items => items.filter(item => item?.eventDescription !== 'Work Share Picture Added')),
    );
  }

  protected onSelectRow(row: any[]) {
    const rowEvent = row[0]?.eventDescription || '';
    if (rowEvent && !row[0]?.detail?.length) {
      this.onSelect(rowEvent, row);
    }
  }

  protected onSelectExpandable(expandableData: ExpandableData) {
    const rowEvent = expandableData?.child[0]?.eventDescription || '';
    this.onSelect(rowEvent, expandableData);
  }

  private onSelect(rowEvent: string, data: any) {
    const layoutData = this.getLayoutData(data);
    const dialogLayout: IceLayout = this.getCommonDialogLayout(layoutData);
    let className = '';
    if (rowEvent.includes(WorkAuditEvents.WORK_CLAIM)) {
      dialogLayout.layout = concat(dialogLayout.layout, this.getWorkClaimChangedDataTableLayout(layoutData.data));
      className = 'dialog-wrapper-width-100-90vh';
    } else if (rowEvent.includes(WorkAuditEvents.WORK_SOCIETY)) {
      dialogLayout.layout = concat(dialogLayout.layout, this.getWorkSocietyAttributesChangedDataTables(layoutData.data));
      className = 'dialog-wrapper-width-100-no-h';
    } else {
      dialogLayout.layout = concat(dialogLayout.layout, this.getNotMatchTemplate());
    }

    dialogLayout.layout = concat(dialogLayout.layout, this.getCloseButton());
    this.openDialog([dialogLayout], className);
  }

  getCommonDialogLayout(layoutData: any): IceLayout {
    return {
      title: of(
        `${get(layoutData, 'data[0].eventDescription', '')}<span class="ice-ml-10 has-changes-indicator">${get(layoutData, 'data', [])?.length} ${this.translate.instant(
          'SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.CHANGES',
        )}</span>`,
      ),
      layout: [
        {
          group: [
            {
              type: 'htmlTemplate',
              config: {
                htmlTemplate: this.getCardSubTitleHtmlCode(layoutData),
                htmlClass: 'ice-mb-20',
              },
            },
          ],
        },
      ],
    };
  }

  getDataTableCommonConfig(): Partial<DataTable> {
    const dt: CopyrightWorkClaimsDataTable = new CopyrightWorkClaimsDataTable(this.translate, this.fuseTranslationLoader, this.store, null);
    const schema = slice(dt.getDataTable(), 0, -1);
    return {
      shadowed: true,
      schema,
      visibleColumns: of(TabsUtils.getSChemaPropsToArray(schema)),
      onSelect: () => {
        this.dialogRef.close();
      },
    };
  }

  getLayoutData(data: any) {
    if (has(data, 'parent')) {
      return { data: get(data, 'child', []), userId: get(data, 'parent.userId', ''), date: get(data, 'parent.date', '') };
    } else {
      return { data, userId: get(data, '[0].userId', ''), date: get(data, '[0].date', '') };
    }
  }

  getCardSubTitleHtmlCode(layoutData: any): Observable<string> {
    return of(
      `<span class="text-bold">${this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.DATE')}:</span> <span class="ice-mr-10">${get(
        layoutData,
        'date',
        '',
      )}</span><span class="text-bold ">${this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.USER')}:</span> <span>${get(layoutData, 'userId', '')}</span>`,
    );
  }

  openDialog(layouts: IceLayout[], className?: string) {
    this.dialogRef = this.dialog.open(DialogMultiLayoutComponent, {
      data: {
        className,
        loading: this.store.pipe(select(fromRoot.getCopyrightLoading)),
        layouts,
      },
    });
  }

  getNotMatchTemplate(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'htmlTemplate',
            config: {
              htmlTemplate: this.getNotMatchTemplateHtmlCode(),
            },
          },
        ],
      },
    ];
  }

  private getCloseButton(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'htmlTemplate',
            config: {
              buttons: [
                {
                  label: this.translate.instant('POPUP.CANCEL'),
                  action: () => this.dialog.closeAll(),
                },
              ],
            },
          },
        ],
      },
    ];
  }

  private getOldValueTemplate(): IceComponent {
    return {
      type: 'htmlTemplate',
      config: {
        htmlTemplate: this.translate.get('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.OLD_VALUE'),
        htmlClass: 'ice-mt-5 ice-mb-15 text-bold text-18',
      },
    };
  }

  private getNewValueTemplate(): IceComponent {
    return {
      type: 'htmlTemplate',
      config: {
        htmlTemplate: this.translate.get('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.NEW_VALUE'),
        htmlClass: 'ice-mt-5 ice-pl-2 ice-mb-15 text-bold text-18',
      },
    };
  }

  getWorkSocietyAttributesChangedDataTables(layoutData: any[]): IceGroupComponent[] {
    const leftData = this.getWorkSocietyAttributesChangedDataTablesModelData(layoutData, 'jsonNewValue');
    const rightData = this.getWorkSocietyAttributesChangedDataTablesModelData(layoutData, 'jsonOldValue');
    const commonHeight = DatatableUtils.getCommonTablesHeight(leftData, rightData);
    return [
      {
        group: [this.getNewValueTemplate(), this.getOldValueTemplate()],
      },
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              customClass: 'display-flex',
              title: this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.ATTRIBUTES'),
              model: leftData,
              schema: this.getWorkSocietyAttributesChangedDataTableSchema(),
              height: commonHeight,
            },
          },
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.ATTRIBUTES'),
              model: rightData,
              schema: this.getWorkSocietyAttributesChangedDataTableSchema(),
              height: commonHeight,
            },
          },
        ],
      },
    ];
  }

  private getWorkSocietyAttributesChangedDataTableSchema(): any[] {
    return [
      {
        name: this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.SOCIETY'),
        prop: 'society',
        sortable: false,
        maxWidth: 75,
        minWidth: 50,
      },
      {
        name: this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.CODE'),
        prop: 'code',
        sortable: false,
        maxWidth: 50,
        minWidth: 20,
      },
      {
        name: this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.ATTRIBUTE'),
        prop: 'attribute',
        sortable: false,
        flexGrow: 3,
        className: 'flex-1 flex-grow-3',
      },
      {
        name: this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.VALUE'),
        prop: 'value',
        sortable: false,
        flexGrow: 3,
        className: 'flex-1 flex-grow-3',
      },
    ];
  }

  getWorkSocietyAttributesChangedDataTablesModelData(layoutData: any[], prop: string): Observable<any> {
    return of(
      flattenDeep(
        (layoutData || []).map(change => {
          const parsedChange = JSON.parse(get(change, prop, '{}'));
          return (
            Object.entries(parsedChange).map(([key, val]) => {
              const indexSeparator = key.indexOf(']');
              const keyPair = key.substring(1, indexSeparator - 1);
              const society = CopyrightUtils.getKeyPrefix(keyPair);
              const code = CopyrightUtils.getKeySuffix(keyPair);
              const attribute = key.substring(indexSeparator + 1);
              const value = get(val, '[0]', '');
              return { society, code, attribute, value };
            }) || []
          );
        }),
      ),
    );
  }

  getWorkClaimChangedDataTableLayout(layoutData: any[]): IceGroupComponent[] {
    const schema = new CopyrightWorkClaimsDataTable(this.translate, this.fuseTranslationLoader, this.store, null);

    const { oldAllData, newAllData } = this.getWorkSharePictureAttributesChangedDataTablesModelDataHighlighted(layoutData);
    const tablesSchema = this.cleanWorkClaimChangedDataTableSchema(schema.getDataTable());
    const cleanData$ = this.workClaimsMode$.pipe(
      map((claimMode: string) => ({ oldData: filter(oldAllData, ['type', claimMode]), newData: filter(newAllData, ['type', claimMode]) })),
    );
    const oldData$ = cleanData$.pipe(map(data => get(data, 'oldData', [])));
    const newData$ = cleanData$.pipe(map(data => get(data, 'newData', [])));
    return [
      {
        group: [
          {
            type: 'card-basic',
            config: {
              title: this.translate.instant('AUDIT_HISTORY.WORK.SHARE_PICTURE.SUBMITTED_CLAIMS'),
              optionsGroup: of({
                options: [
                  { value: WORK_CLAIM_SHARE_TYPE.SHARES, label: this.translate.instant('WORKS.ALL_CLAIMS.OPTIONS.COLLECTION'), checked: true },
                  { value: WORK_CLAIM_SHARE_TYPE.OWNERSHIP, label: this.translate.instant('WORKS.ALL_CLAIMS.OPTIONS.OWNERSHIP') },
                ],
                change: event => {
                  this.workClaimsMode$.next(event.value);
                },
              }),
              content: [
                {
                  group: [this.getOldValueTemplate()],
                },
                {
                  group: [
                    {
                      type: 'dataTable',
                      config: {
                        customClass: 'work-share-pictures-table',
                        data: oldData$,
                        schema: tablesSchema,
                        sorts: [],
                        totals: this.getTotals(oldData$, 'work-claims-totals ice-mb-40'),
                        maxHeight: of(224),
                      },
                    },
                  ],
                },
                {
                  group: [this.getNewValueTemplate()],
                },
                {
                  group: [
                    {
                      type: 'dataTable',
                      config: {
                        customClass: 'work-share-pictures-table',
                        data: newData$,
                        schema: tablesSchema,
                        sorts: [],
                        totals: this.getTotals(newData$, 'work-claims-totals'),
                        maxHeight: of(224),
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    ];
  }

  getWorkSharePictureAttributesChangedDataTablesModelDataHighlighted(layoutData: any[]): { oldAllData: any[]; newAllData: any[] } {
    const oldAllData = [];
    const newAllData = [];
    each(layoutData || [], change => {
      const oldAllDataItems = this.getWorkSharePictureAttributesChangedDataTablesModelData(change, 'jsonOldValue');
      const newAllDataDirty = this.getWorkSharePictureAttributesChangedDataTablesModelData(change, 'jsonNewValue');
      const newAllDataItems = this.setDifferenceHighlighted(oldAllDataItems, newAllDataDirty);
      oldAllData.push(oldAllDataItems);
      newAllData.push(newAllDataItems);
    });
    return { oldAllData: flattenDeep(oldAllData), newAllData: flattenDeep(newAllData) };
  }

  getWorkSharePictureAttributesChangedDataTablesModelData(change: any, prop: string): any {
    const apiData = cloneDeep(values(JSON.parse(get(change, prop, '{}'))));
    return lodashMap(
      [
        ...WorkClaimsUtils.groupClaimsRowsByMode(WorkClaimsUtils.workClaimsCleaner(apiData, this.translate), WORK_CLAIM_SHARE_TYPE.SHARES),
        ...WorkClaimsUtils.groupClaimsRowsByMode(WorkClaimsUtils.workClaimsCleaner(apiData, this.translate), WORK_CLAIM_SHARE_TYPE.OWNERSHIP),
      ],
      row => {
        const dataRow = find(apiData, { claimId: row.claimId });
        return {
          ...row,
          level: this.updateRowLevel(row),
          name: get(dataRow, `partyName`),
          ipiNumber: CopyrightUtils.getKeySuffix(get(dataRow, 'claimantPartyNameId')),
        };
      },
    );
  }

  private updateRowLevel(row): number {
    switch (row?.role) {
      case 'CA':
        return 2;
      case 'E':
        return 3;
      case 'SE':
        return 4;
      default:
        return 1;
    }
  }

  cleanWorkClaimChangedDataTableSchema(originalSchema: DataTableRow[]): DataTableRow[] {
    const cleanSchema = filter(originalSchema, column => !['prSoc', 'mrSoc', 'editBtn'].includes(column.prop));
    return lodashMap(cleanSchema, colConfig => {
      let className = '';
      if (isString(colConfig.cellClass)) {
        className = colConfig.cellClass;
      }
      return {
        ...colConfig,
        sortable: false,
        cellClass: event => (get(event, `row.${get(event, 'column.prop')}${this.highlightSuffix}`) ? `${className} ${this.highlightClassName}` : className),
      };
    });
  }

  private setDifferenceHighlighted(oldAllDataItems: any[], newAllDataDirty: any[]): any[] {
    const newCleanValue = cloneDeep(newAllDataDirty);
    return lodashMap(newCleanValue, newItem => {
      const oldItem = find(oldAllDataItems, { claimId: get(newItem, 'claimId'), index: get(newItem, 'index'), type: get(newItem, 'type') });
      Object.entries(newItem).forEach(([key, value]) => {
        if (!isEqual(value, get(oldItem, key))) {
          const newProp = `${key}${this.highlightSuffix}`;
          newItem[newProp] = true;
        }
      });
      return newItem;
    });
  }

  private getTotals(data$: Observable<any>, className: string = '') {
    return {
      totalsTiles: ClaimGraphUtils.getTotalTitles(
        data$,
        (rows: any) => SharePictureUtils.getSharesTotals(rows),
        this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.FOOTER.LABEL_CREATOR_SUB_TOTAL'),
        this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.FOOTER.LABEL_PUBLISHER_SUB_TOTAL'),
        this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.FOOTER.LABEL_SPECIAL_SUB_TOTAL'),
        this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.FOOTER.LABEL_TOTAL'),
        true,
      ),
      class: of(className),
      hideHeaderTotals: true,
    };
  }

  getNotMatchTemplateHtmlCode(): Observable<string> {
    return of(`<span class="text-bold">${this.translate.instant('SHARED.AUDIT_HISTORY.AUDIT_EVENTS_TABLE.MISS_TEMPLATE')}:</span>`);
  }
}
