import { FormControl } from '@angular/forms';
import { hasDuplicatedSpaces, hasDuplicatedValue } from '@ice';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { countryExceptions, countryModifiers, iceCountries } from 'assets/ts/countries';
import { tisnTisaConversionItems } from 'assets/ts/tisnTisaConversionItems';
import { ActivityType } from 'config/constants/activity.constants';
import { COUNTRIES_DEFAULT_DATE, DEFAULT_LANGUAGE, END_DATE_ALIAS, TERRITORY_END_DATE_NO_DISPLAY } from 'config/constants/global.constants';
import { FIRST_VALIDATOR_REQUEST_DELAY } from 'config/constants/shares.constants';
import { TerritoryGroup, IceTerritoriesTisnList, TerritoryDataType, TerritoryType } from 'config/constants/territories.constants';
import { territoriesTisnApiUrl } from 'config/env';
import { ApiCall, RequestType } from 'config/sections-config/api-call';
import {
  cloneDeep,
  compact,
  concat,
  difference,
  differenceBy,
  filter,
  find,
  flatMap,
  flatten,
  get,
  groupBy,
  intersection,
  isArray,
  isEmpty,
  last,
  maxBy,
  toNumber,
  toPairs,
  uniq,
} from 'lodash';
import { Code, Name, TerritoryApi, TerritoryCleaned, TerritoryDetail, TerritoryInfoCleaned } from 'models';
import { IconInterface } from 'models/copyright/detail/icon';
import { OptionsGroup } from 'models/options-group';
import { SelectCodeItem } from 'models/selectCodeItem';
import { TerritoryConversionData, TisnTisaConversionItem } from 'models/tisnTisaConversionItem';
import moment from 'moment';
import { of } from 'rxjs';
import type { FieldValidatorService } from 'services/validators/field.validator.service';
import { Store } from '@ngrx/store';
import * as fromRoot from 'store/root';
import { map } from 'rxjs/operators';
import { hasItems } from '../hasItems';

export class TerritoryUtils {
  static oldModifiersRegEx = /(i|e)\d+(?!\w)/gi;

  static selectTerritoryDetail(territories: TerritoryApi[], params: any): TerritoryInfoCleaned {
    const codes = get(territories, '[0].codes');
    let names: Name[] = [];
    let name = '';
    let tisa = '';
    let tisaExt = '';
    let start = get(territories, '[0].start', '');
    let end = get(territories, '[0].end', '');

    if (codes && codes.length) {
      if (params && Object.keys(params).length) {
        const foundCodes = codes.find(code => {
          let result = false;
          Object.keys(params).forEach(key => {
            const filterValue = params[key] && params[key].toLowerCase();
            const apiValue = code[key] && code[key].toLowerCase();
            if (apiValue === filterValue) {
              result = true;
            }
          });
          return result;
        });
        if (foundCodes) {
          ({ names, tisa, tisaExt, start, end } = foundCodes);
        }
      } else {
        ({ names, tisa, tisaExt, end } = codes.reduce((a, b) => (a.end > b.end ? a : b)));
        ({ start } = codes.reduce((a, b) => (a.start < b.start ? a : b)));
      }
      name = this.getTranslatedName(names);
    }
    if (end === TERRITORY_END_DATE_NO_DISPLAY) {
      end = END_DATE_ALIAS;
    }
    return { ...territories[0], name, tisa, tisaExt, start, end };
  }

  private static getTranslatedName(names: Name[]): string {
    if (hasItems(names)) {
      const translatedName = find(names, { language: DEFAULT_LANGUAGE });
      return translatedName['name'] || get(names, '[0].name');
    }
    return '';
  }

  static filterOldTisn(tisnList) {
    if (tisnList) {
      return tisnList.filter(
        tisn => !!tisnTisaConversionItems.find(conversionItem => `${conversionItem.tisn}` === `${tisn}` && moment(conversionItem.endDate).isSameOrAfter(COUNTRIES_DEFAULT_DATE)),
      );
    } else {
      return [];
    }
  }

  static getLanguageNames(territories: TerritoryApi[]): Name[] {
    return territories.reduce((acc, territory) => [...acc, ...((Array.isArray(territory.codes) && this.addDaysData(territory.codes[territory.codes.length - 1])) || [])], []);
  }

  static addDaysData(code: Code) {
    const start = code?.start;
    const end = code?.end;
    return code?.names.map(name => {
      return { ...name, start, end };
    });
  }

  static getListTerritories(prop, value) {
    const territories = value && value.territories;
    if (territories && territories.length > 0) {
      return {
        [prop]: territories.map(territory => {
          const { tisn, start, end } = territory;
          let tisa = '';
          let name = '';
          const codes = territory.codes;
          if (codes && codes.length > 0) {
            const firstCode = codes[codes.length - 1];
            tisa = firstCode.tisa;
            name =
              firstCode.names && firstCode.names.length > 0
                ? find(firstCode.names, { language: DEFAULT_LANGUAGE })
                  ? find(firstCode.names, { language: DEFAULT_LANGUAGE })[TerritoryDataType.NAME]
                  : firstCode.names[0].name
                : '';
          }
          return { tisa, tisn, start, end, name };
        }),
      };
    }
    return { [prop]: [] };
  }

  static getListTerritoriesValue(value) {
    const territories = value && value.territories;
    if (territories && territories.length > 0) {
      return territories.map(territory => {
        const { tisn, start, end, members } = territory;
        let tisa = '';
        let name = '';
        const codes = territory.codes;
        if (codes && codes.length > 0) {
          const firstCode = codes[codes.length - 1];
          tisa = firstCode.tisa;
          name =
            firstCode.names && firstCode.names.length > 0
              ? find(firstCode.names, { language: DEFAULT_LANGUAGE })
                ? find(firstCode.names, { language: DEFAULT_LANGUAGE })[TerritoryDataType.NAME]
                : firstCode.names[0].name
              : '';
        }
        return { tisa, tisn, start, end, name, members };
      });
    }
    return [];
  }

  static territorySearchCleaner(territories, params): TerritoryCleaned[] {
    const key = Object.keys(params)[0];
    return (
      (territories &&
        territories.map(item => {
          const codes = (item?.attributes?.codes || []).find(code => code[key] === params[key]) || (item?.attributes?.codes && last(item?.attributes?.codes)) || [];
          const names = codes?.names || [];
          const name = names.length > 0 ? (find(names, { language: 'EN' }) ? find(names, { language: 'EN' })[TerritoryDataType.NAME] : names[0]?.name) : '';
          return <TerritoryCleaned>{ id: item.attributes.tisn, name, tisA: codes.tisa, tisN: item.attributes.tisn, tisAExt: codes.tisaExt, dataType: 'territory' };
        })) ||
      []
    );
  }

  static convertTerritoryStringElements(codes: string | string[], convertToType: string): string[] {
    const codesArray = Array.isArray(codes) ? codes : TerritoryUtils.splitTerritoryStringCodes(codes);
    const cleanCodesArray = codesArray.map(code => code.replace(/,/gi, '').trim());
    return TerritoryUtils.convertTerritoryArrayElements(cleanCodesArray, convertToType);
  }

  static splitTerritoryStringCodes(codes: string): string[] {
    const { exceptionTerritories, cleanCodes } = this.getExceptionTerritories(codes);
    const regularCodes = this.getRegularCodes(cleanCodes);
    return [...(regularCodes || []).map(code => TerritoryUtils.normalizeTerritoryModifyerCode(code.trim())), ...exceptionTerritories].filter(code => !!code);
  }

  static getRegularCodes(cleanCodes: string): string[] {
    const modifierSeparator = /(\+|\-)/gm;
    const hasModifierSeparator = modifierSeparator.test(cleanCodes);

    if (this.oldModifiersRegEx.test(cleanCodes)) {
      const comaSeparator = /(\,)/gm;
      const hasComaSeparator = comaSeparator.test(cleanCodes);
      if (hasModifierSeparator) {
        throw 'invalidTerritoryFormatOldAndNewSeparators';
      } else if (hasComaSeparator) {
        return this.applyCharacterSeparator(cleanCodes, ',');
      } else {
        return this.applyCharacterSeparator(cleanCodes, ' ');
      }
    } else if (hasModifierSeparator) {
      return this.applyModifierSeparator(cleanCodes);
    } else {
      return [cleanCodes];
    }
  }

  static applyModifierSeparator(cleanCodes: string): string[] {
    const matches = this.applyRegexToCastTerritoryCodes(cleanCodes, /(\+|\-)?\s*\w+((, | )?(')?\w+)*/g);
    return this.cleanMatches(matches);
  }

  static cleanMatches(matches: string[]): string[] {
    return (matches || []).map(code => this.trimCodesWithPrefix(code));
  }

  static trimCodesWithPrefix(territoryCode: string): string {
    if (Array.isArray(territoryCode) && territoryCode.length > 0) {
      territoryCode = territoryCode[0];
    }
    if (territoryCode) {
      return territoryCode.startsWith('+') || territoryCode.startsWith('-') ? `${territoryCode.charAt(0)}${territoryCode.substr(1, territoryCode.length).trim()}` : territoryCode;
    }
    return '';
  }

  static applyRegexToCastTerritoryCodes(cleanCodes: string, regexToApply: RegExp): string[] {
    return cleanCodes.match(regexToApply);
  }

  static applyCharacterSeparator(cleanCodes: string, separator: string): string[] {
    return cleanCodes
      .split(separator)
      .map(code => code.trim())
      .filter(code => !!code);
  }

  static normalizeTerritoryModifyerCode(code: string): string {
    if (code.match(this.oldModifiersRegEx)) {
      switch (code.charAt(0).toLowerCase()) {
        case 'i':
          return `+${code.substr(1, code.length)}`;

        case 'e':
          return `-${code.substr(1, code.length)}`;
      }
    }
    return code;
  }

  // Transform tisn or tisa code to tisa or description
  static convertTerritoryArrayElements(codes: string[], convertToType: string): string[] {
    const dateFilter = moment();
    const convertedArray =
      (codes &&
        codes
          .filter(code => !!code)
          .map(code => {
            const codeWithoutPrefix = TerritoryUtils.trimTerritoryPrefix(code.trim());
            const codeType = TerritoryUtils.getTerritoryCodeType(codeWithoutPrefix);
            const conversionElements = code && filter(tisnTisaConversionItems, obj => obj[codeType] === codeWithoutPrefix.trim().toUpperCase());
            const conversionElementsByDate = filter(conversionElements, obj => moment(obj.endDate).isAfter(dateFilter)); // Active territory

            // Any territory if none active
            const conversionElement = conversionElementsByDate && conversionElementsByDate[0] ? conversionElementsByDate[0] : (conversionElements && conversionElements[0]) || null;
            const terrPrefix = code && TerritoryUtils.getTerritoryPrefix(code);
            return conversionElement ? terrPrefix + conversionElement[convertToType] : code;
          })) ||
      [];
    return convertedArray || [];
  }

  static convertTerritoryArrayElementsPrefix(codes: string[]): TerritoryConversionData {
    const convertedObject = {};
    (codes || [])
      .filter(code => !!code)
      .forEach(code => {
        const terrPrefix = TerritoryUtils.getTerritoryPrefix(code);
        const codeWithoutPrefix = TerritoryUtils.trimTerritoryPrefix(code.trim()).trim().toUpperCase();
        const codeType = TerritoryUtils.getTerritoryCodeType(codeWithoutPrefix);
        const conversionElements = code && filter(tisnTisaConversionItems, obj => obj[codeType] === codeWithoutPrefix);
        const conversionElementsByDate = filter(conversionElements, obj => new Date(obj.endDate).getTime() > Date.now()); // Active territory

        // Any territory if none active
        const conversionElement = conversionElementsByDate && conversionElementsByDate[0] ? conversionElementsByDate[0] : (conversionElements && conversionElements[0]) || null;
        Object.values(TerritoryDataType).forEach(type => {
          const newCode = conversionElement ? terrPrefix + conversionElement[type] : code;
          convertedObject[type] = [...(convertedObject[type] || []), newCode];
        });
      });
    return convertedObject;
  }

  static convertTerritoryArrayElementsToTooltipFormat(codes: string[]): string[] {
    const convertedArray =
      codes &&
      codes.map(code => {
        const codeWithoutPrefix = TerritoryUtils.trimTerritoryPrefix(code);
        const codeType = TerritoryUtils.getTerritoryCodeType(code);
        const conversionElements = code && filter(tisnTisaConversionItems, obj => obj[codeType] === codeWithoutPrefix);
        const conversionElementsByDate = filter(conversionElements, obj => new Date(obj.endDate).getTime() > Date.now()); // Active territory
        const conversionElement = conversionElementsByDate && conversionElementsByDate[0] ? conversionElementsByDate[0] : null;

        const terrPrefix = code && TerritoryUtils.getTerritoryPrefix(code);
        return conversionElement ? `${terrPrefix}${conversionElement['tisn']}/${conversionElement['tisa']}` : code;
      });
    return convertedArray || [];
  }

  static trimTerritoryPrefix(territoryCode: string): string {
    // Hack to fix the random crash when territory code is arrray of one instead of string.
    if (Array.isArray(territoryCode) && territoryCode.length > 0) {
      territoryCode = territoryCode[0];
    }
    if (territoryCode) {
      return territoryCode.startsWith('+') || territoryCode.startsWith('-') ? territoryCode.slice(1) : territoryCode;
    }
    return '';
  }

  static getTerritoryPrefix(territoryCode: string) {
    if (Array.isArray(territoryCode) && territoryCode.length > 0) {
      territoryCode = territoryCode[0];
    }
    if (territoryCode) {
      return territoryCode.startsWith('+') ? '+' : territoryCode.startsWith('-') ? '-' : '';
    }
    return '';
  }

  static getTerritoryPrefixAsObject(territoryCodes: string[]): object {
    const result = {};
    (territoryCodes || []).map(code => (result[code] = TerritoryUtils.getTerritoryPrefix(code)));
    return result;
  }

  // Get all TISN territories (without prefix) from TISN expression (including prefix logic and expanding TISN group codes)
  static flatTerritories(territories: string[]): string[] {
    const territoryFlatData = flatMap(
      territories.map(territory => {
        const trimTerritoryPrefix = TerritoryUtils.trimTerritoryPrefix(territory);
        const territoryMatchData = tisnTisaConversionItems.find(item => item && item.tisn === trimTerritoryPrefix);
        const territoryArray: string[] = get(territoryMatchData, 'tisnGroup')?.map(terr => terr.toString());
        const territoryArrayClean: string[] = territoryArray?.length ? territoryArray : [territory];
        return territoryArrayClean.map(terr => `${TerritoryUtils.getTerritoryPrefix(territory)}${TerritoryUtils.trimTerritoryPrefix(`${terr}`)}`);
      }),
    );
    const territoryPrefixGroup = groupBy(territoryFlatData, territory => (TerritoryUtils.getTerritoryPrefix(territory) === '-' ? 'negative' : 'positive'));
    territoryPrefixGroup.negative = (uniq(territoryPrefixGroup.negative) || []).map(territory => TerritoryUtils.trimTerritoryPrefix(territory));
    territoryPrefixGroup.positive = (uniq(territoryPrefixGroup.positive) || []).map(territory => TerritoryUtils.trimTerritoryPrefix(territory));
    return territoryPrefixGroup.positive.filter(territory => !territoryPrefixGroup.negative.includes(territory));
  }

  // Order TISA territories
  static getTerritoriesOrderedByCriteria(territories: any[]): any[] {
    const territoriesObject = territories.map(territory => {
      return {
        prefix: TerritoryUtils.getTerritoryPrefix(territory),
        tisa: TerritoryUtils.trimTerritoryPrefix(territory),
        tisn: get(
          tisnTisaConversionItems.find(item => item && item.tisa === TerritoryUtils.trimTerritoryPrefix(territory)),
          'tisn',
          '',
        ),
        tisnGroup: get(
          tisnTisaConversionItems.find(item => item && item.tisa === TerritoryUtils.trimTerritoryPrefix(territory)),
          'tisnGroup',
          '',
        ),
        group: get(
          tisnTisaConversionItems.find(item => item && item.tisa === TerritoryUtils.trimTerritoryPrefix(territory)),
          'tisnGroup',
          '',
        ).length,
        children: [],
      };
    });

    return TerritoryUtils.orderGroupBy(territoriesObject)
      .map(item => TerritoryUtils.getChildren(item))
      .reduce((acc, it) => [...acc, ...it], [])
      .map(item => `${item.prefix}${item.tisa}`);
  }

  static sortArray(orderedArray) {
    return orderedArray.sort((a, b) => {
      if (a.prefix === '+' && b.prefix === '-') {
        return -1;
      }
      if (a.prefix === '-' && b.prefix === '+') {
        return 1;
      }

      if (a.group !== 0 && b.group === 0) {
        return 1;
      }
      if (a.group === 0 && b.group !== 0) {
        return -1;
      }

      if (a.tisa > b.tisa) {
        return 1;
      } else {
        return -1;
      }
    });
  }

  static getChildren(item) {
    return item.children.length ? [item, ...item.children.map(child => TerritoryUtils.getChildren(child)).reduce((acc, it) => [...acc, ...it], [])] : [item];
  }

  static orderGroupBy(territoriesObject: any[]) {
    return TerritoryUtils.sortArray(
      toPairs(
        groupBy(territoriesObject, territory => {
          const foundList = territoriesObject.filter(item => {
            return (
              ((item.tisnGroup.length && !territory.tisnGroup.length && item.tisnGroup.includes(+territory.tisn)) ||
                (territory.tisnGroup.length && item.tisnGroup.length && !difference(territory.tisnGroup, item.tisnGroup).length)) &&
              item.tisn !== territory.tisn
            );
          });
          const found = maxBy(foundList, item => item.tisnGroup.length);
          return (found && found.tisa) || territory.tisa;
        }),
      ).map(([tisa, territoriesArray]) => {
        const territoryReturn = territoriesArray.find(territory => territory.tisa === tisa);
        return territoriesArray.length === 1 ? territoryReturn : { ...territoryReturn, children: TerritoryUtils.orderGroupBy(territoriesArray.filter(item => item.tisa !== tisa)) };
      }),
    );
  }

  static getTerritoriesDisplayListData(
    originalTerritoriesArray: string[],
    displayType: string,
  ): { territoriesText: string; territoriesIcon: IconInterface[]; territoriesTooltip: string; territoriesTooltipWithoutHtml: string } {
    const territoriesConvertedToTisa = TerritoryUtils.convertTerritoryArrayElements(originalTerritoriesArray, TerritoryDataType.TISA);
    const orderedTisaTerritoriesByCriteria = TerritoryUtils.getTerritoriesOrderedByCriteria(territoriesConvertedToTisa);
    const territoriesConvertedToNames = TerritoryUtils.convertTerritoryArrayElements(originalTerritoriesArray, TerritoryDataType.NAME);
    const orderedNamesTerritoriesByCriteria = TerritoryUtils.getTerritoriesOrderedByCriteria(territoriesConvertedToNames);
    const territoriesTooltip = TerritoryUtils.getTerritoriesNamesTooltipText(orderedNamesTerritoriesByCriteria);
    const territoriesTooltipWithoutHtml = TerritoryUtils.getTerritoriesNamesTooltipTextWithoutHtml(orderedNamesTerritoriesByCriteria);
    const territoriesText = orderedTisaTerritoriesByCriteria.join(' ');

    return { territoriesText, territoriesIcon: [], territoriesTooltip, territoriesTooltipWithoutHtml };
  }

  static formatShareTerritoriesForRegistration(territoriesList: string[]): string[] {
    const trimmedTerritoriesArray = territoriesList.map(element => element.trim()); // remove extra spaces
    const compactedTerritoriesArray = compact(trimmedTerritoriesArray); // remove empty elements

    const formattedTerritories = compactedTerritoriesArray.map(element => {
      if (element.startsWith('+') || element.startsWith('-')) {
        return `${element.charAt(0)}${TerritoryUtils.convertTerritoryStringElements(element.slice(1).trim().toUpperCase(), TerritoryDataType.TISN)[0]}`;
      }

      if (element.startsWith('I') && !isNaN(Number(element.slice(1)))) {
        return `+${element.slice(1).trim()}`;
      }

      if (element.startsWith('E') && !isNaN(Number(element.slice(1)))) {
        return `-${element.slice(1).trim()}`;
      }

      return `+${TerritoryUtils.convertTerritoryStringElements(element.toUpperCase(), TerritoryDataType.TISN)[0]}`;
    });

    return formattedTerritories;
  }

  static getTerritoryCodeType(code: string): string {
    let codeType: string;
    if (isNaN(+code)) {
      if (code.length > 3) {
        codeType = TerritoryDataType.NAME;
      } else {
        codeType = TerritoryDataType.TISA;
      }
    } else {
      codeType = TerritoryDataType.TISN;
    }
    return codeType;
  }

  static getFilteredTerritoriesByDate(date: string, territories: any[]): any {
    if (date && date.length > 0) {
      return territories.filter(item => {
        return moment(date).isAfter(item.start, 'day') && moment(date).isBefore(item.end, 'day');
      });
    }
    return territories;
  }

  static getCleanFilteredTerritorySuccessorsOrPredecessorsByDate(territories: any[]): any[] {
    return territories.map(item => {
      const { tisn, start, end } = item;
      return {
        tisn,
        start,
        end,
        tisa: item && get(item, `codes[${item.codes.length - 1}].tisa`),
        name: this.getTranslatedName(get(item, `codes[${item.codes.length - 1}].names`)),
      };
    });
  }

  static filterSociety(societyT: any, territory: any) {
    if (territory?.items?.length === 0) return true;
    territory = territory && ((isArray(territory) && territory) || [territory]);
    if (territory) {
      return territory.some(filterTerritory =>
        ((Array.isArray(societyT) && societyT) || [societyT]).some(society => this.flatTerritories(society.territories).includes(filterTerritory.toString().trim())),
      );
    } else {
      return true;
    }
  }

  static getTerritoriesNamesTooltipText(territoryNames: string[]): string {
    return territoryNames && territoryNames.map((territory: string, index: number) => (index !== 0 && index % 3 === 0 ? `<br> ${territory}` : territory)).join(' ');
  }

  static getTerritoriesNamesTooltipTextWithoutHtml(territoryNames: string[]): string {
    return (
      territoryNames &&
      territoryNames
        .map((territory: string, index: number) =>
          index !== 0 && index % 3 === 0 ? `\n${TerritoryUtils.normalizeTerritoryDefaultModifyerCode(territory)}` : TerritoryUtils.normalizeTerritoryDefaultModifyerCode(territory),
        )
        .join(' ')
    );
  }

  static normalizeTerritoryDefaultModifyerCode(code: string): string {
    if (!(code.startsWith('-') || code.startsWith('+'))) {
      return `+${code}`;
    }
    return code;
  }

  static getTerritoryDetail(response): TerritoryDetail {
    const territoryDetail: TerritoryDetail = {
      territories: (response?.items || []).map(item => get(item, 'attributes')),
      tisns: (response?.items || []).map(item => get(item, 'attributes.tisn')),
    };
    return territoryDetail;
  }

  static getExceptionTerritories(codes: string = ''): { exceptionTerritories: string[]; cleanCodes: string } {
    const exceptionTerritories = [];
    let cleanCodes = codes.toUpperCase();
    countryExceptions.forEach(exception => {
      const matchIndex = cleanCodes.indexOf(exception.name);
      if (matchIndex !== -1) {
        let modifyer = countryModifiers.find(mod => mod === TerritoryUtils.getModifyer(cleanCodes, matchIndex).toUpperCase()) || '';

        const matchException = this.normalizeTerritoryModifyerCode(modifyer + exception.tisn);
        exceptionTerritories.push(matchException);
        if (modifyer) {
          modifyer = `\\${modifyer}`;
        }
        const re = new RegExp(`${modifyer}\\s*${exception.name.replace('+', '\\+')}`, 'g');
        cleanCodes = cleanCodes.replace(re, '');
      }
    });
    return { exceptionTerritories, cleanCodes };
  }

  static getIceCountryGroups(activityType: ActivityType = ActivityType.MATCH): OptionsGroup[] {
    const cloneIceCountries = cloneDeep(iceCountries).sort((a, b) => (a?.label > b?.label ? 1 : -1));
    const otherTerritories = this.getNotIceCountries(cloneIceCountries).sort((a, b) => (a?.name > b?.name ? 1 : -1));
    const otherTerritoriesRegions = this.getCleanSelectCodeItem(otherTerritories.filter(item => !isEmpty(item?.tisnGroup)));
    const otherTerritoriesCountries = this.getCleanSelectCodeItem(otherTerritories.filter(item => isEmpty(item?.tisnGroup)).sort((a, b) => (a?.name > b?.name ? 1 : -1)));
    return <OptionsGroup[]>[
      {
        header: TerritoryGroup.ICE_TERRITORIES,
        options: cloneIceCountries,
      },
      {
        header: 'Other Territories',
        options: otherTerritoriesCountries,
      },
      {
        header: 'Other Regions',
        options: otherTerritoriesRegions,
      },
    ];
  }

  static cleanTerritorySelectorValue(field, value, lastValue) {
    const groupItems = TerritoryUtils.getCurrentTisnTisaConversionItems().filter(item => !isEmpty(item.tisnGroup));
    const insertedValues = difference(value, lastValue);
    const removedValues = difference(lastValue, value);
    if (!isEmpty(insertedValues) || !isEmpty(removedValues)) {
      const groupItemsCleaned = groupItems.filter(group => !removedValues.includes(group.tisn));
      const removedGroups = difference(groupItems, groupItemsCleaned);
      const children = this.getNewChildrenValues(groupItemsCleaned, insertedValues as any);
      const cleanValues = this.removeChilds(this.removeGroups(groupItemsCleaned, value), removedGroups);
      const groups = this.getNewGroupsValues(groupItemsCleaned, [...cleanValues, ...children]);
      if (children || groups) {
        field.formControl.setValue(uniq([...cleanValues, ...children, ...groups]));
      }
    }
  }

  private static getModifyer(cleanCodes: string, matchIndex: number) {
    let isSpace = true;
    while (isSpace && matchIndex >= 0) {
      if (cleanCodes.charAt(matchIndex - 1) === ' ') {
        matchIndex--;
      } else {
        isSpace = false;
      }
    }
    const char = cleanCodes.charAt(matchIndex - 1);
    return ['+', '-'].includes(char) ? char : '';
  }

  static getNewChildrenValues(groupItems: TisnTisaConversionItem[], inputValues: string[]): string[] | undefined {
    if (inputValues) {
      const groupsSelected = this.getGroupsSelected(groupItems, inputValues);
      const newChilds = (groupsSelected || []).map(item => item?.tisnGroup?.map(child => child.toString()));
      return flatten(newChilds);
    }
  }

  static getGroupsSelected(groupItems: TisnTisaConversionItem[], inputValues: string[]) {
    return (groupItems || []).filter(group => (inputValues || []).includes(group.tisn));
  }

  static removeGroups(groupItems: TisnTisaConversionItem[], inputValues: string[]): string[] {
    const groupsSelected = this.getGroupsSelected(groupItems, inputValues);
    return difference(
      inputValues,
      groupsSelected.map(group => group.tisn),
    );
  }

  static getNewGroupsValues(groupItems: TisnTisaConversionItem[], inputValues: string[]): string[] | undefined {
    if (inputValues) {
      const numberValues = inputValues.map(strValue => parseInt(strValue, 10));
      const newGroups = (groupItems || []).filter(group => intersection(group.tisnGroup, numberValues).length === group.tisnGroup.length);
      return newGroups.map(group => group.tisn);
    }
  }

  static removeChilds(childs: string[], removedItems: TisnTisaConversionItem[]) {
    return childs.filter(child => !removedItems.some(item => item.tisnGroup.some(subItem => subItem.toString() === child)));
  }

  static getCurrentTisnTisaConversionItems(): TisnTisaConversionItem[] {
    const invalids = tisnTisaConversionItems.filter(item => new Date(item.endDate).getTime() - Date.now() < 0);
    return difference(tisnTisaConversionItems, invalids);
  }

  static getCurrentTisnTisaConversionItemsCleanedGroups(): TisnTisaConversionItem[] {
    const valids = filter(tisnTisaConversionItems, item => new Date(item.endDate).getTime() - Date.now() >= 0);
    return valids.map(valid => {
      valid.tisnGroup = intersection(
        valid.tisnGroup,
        valids.map(_v => parseInt(_v.tisn, 10)),
      );
      return valid;
    });
  }

  static getTerritoryField(
    keyName,
    hideKeyName,
    keyLabel,
    translate,
    fieldValidatorService,
    required = false,
    onChange = field => {},
    extraAsyncValidations = {},
    expressionProperties = {},
    hooks = null,
  ): FormlyFieldConfig {
    return {
      className: 'flex-1',
      fieldGroup: [
        {
          key: keyName,
          type: 'input',
          defaultValue: '',
          modelOptions: {
            updateOn: 'blur',
          },
          templateOptions: {
            label: keyLabel,
            required,
            change: onChange,
          },
          expressionProperties,
          validators: {
            duplicatedValues: {
              expression: hasDuplicatedValue,
              message: translate.instant('ERROR.HAS_DUPLICATED_VALUE'),
            },
            duplicatedSpaces: {
              expression: hasDuplicatedSpaces,
              message: translate.instant('ERROR.HAS_MULTIPLE_SPACES'),
            },
          },
          hooks: hooks || {
            onInit: field => {
              const form = field.form;
              field.templateOptions.bindedFieldTermTerritoryCodes = form.controls[hideKeyName];
            },
          },
          asyncValidators: {
            term_territory: {
              expression: (control, field) =>
                new Promise(resolve => setTimeout(resolve, FIRST_VALIDATOR_REQUEST_DELAY)).then(
                  () =>
                    fieldValidatorService.useValidatorOnTouch(field) ||
                    fieldValidatorService.existTerritory(
                      control,
                      field,
                      () => {},
                      res => {
                        if (res && res['tisns']) {
                          field.templateOptions.bindedFieldTermTerritoryCodes.setValue(res['tisns']);
                        }
                      },
                    ),
                ),
              message: translate.instant('ERROR.NOT_EXISTS').replace(/<<\w+>>/g, keyLabel),
            },
            ...extraAsyncValidations,
          },
        },
        ...TerritoryUtils.getTerritoryHiddenFields(fieldValidatorService, keyName, hideKeyName),
      ],
    };
  }

  static getTerritoryHiddenFields(fieldValidatorService: FieldValidatorService, territoryLabel = 'territory', hideCodesLabel = 'codes') {
    const lastTerritoryValueLabel = 'lastTerritoryValue';
    return [
      {
        key: lastTerritoryValueLabel,
      },
      {
        className: 'ice-display-none',
        key: hideCodesLabel,
        type: 'input',
        defaultValue: '',
        asyncValidators: {
          codesValidator: {
            expression: (control, field) => {
              const lastTerritoryValue = this.getAutocompleteValue(control, lastTerritoryValueLabel);
              const currentTerritoryValue = this.getAutocompleteValue(control, territoryLabel);
              const isTerritoryFielTouched = get(control, `parent.controls.${territoryLabel}.touched`, false);
              if ((lastTerritoryValue || isTerritoryFielTouched) && lastTerritoryValue !== currentTerritoryValue) {
                this.assignLastTerritoryValue(control, lastTerritoryValueLabel, territoryLabel);
                return fieldValidatorService.territoryShortenValidator(control, codesControl =>
                  this.assignLastTerritoryValue(codesControl, lastTerritoryValueLabel, territoryLabel),
                );
              } else {
                if (!lastTerritoryValue) {
                  this.assignLastTerritoryValue(control, lastTerritoryValueLabel, territoryLabel);
                }
                return of(true);
              }
            },
          },
        },
      },
    ];
  }

  private static getAutocompleteValue(control: FormControl, label: string) {
    return get(control, `parent.controls.${label}.value.value`, get(control, `parent.controls.${label}.value`, null));
  }

  private static assignLastTerritoryValue(control: FormControl, lastTerritoryLabel: string, territoryLabel: string) {
    if (control?.parent?.controls && control.parent.controls[lastTerritoryLabel] && control.parent.controls[territoryLabel]) {
      control.parent.controls[lastTerritoryLabel].setValue(control.parent.controls[territoryLabel].value);
    }
  }

  private static getNotIceCountries(cloneIceCountries: SelectCodeItem[]): TisnTisaConversionItem[] {
    const iceCountriesTisn = cloneIceCountries.map(country => country.value);
    return this.getCurrentTisnTisaConversionItemsCleanedGroups().filter(country => !iceCountriesTisn.includes(country.tisn));
  }

  private static getCleanSelectCodeItem(notIceCountriesList: TisnTisaConversionItem[]): SelectCodeItem[] {
    return (notIceCountriesList || []).map(item => {
      return <SelectCodeItem>{
        label: item.name,
        value: item.tisn,
      };
    });
  }

  static reduceGroupedTerritories(originalTerritoriesArray: string[]) {
    const originalTotal = originalTerritoriesArray.length;
    let sumValues: number[] = [];
    let minusValues: number[] = [];
    originalTerritoriesArray.forEach(code => {
      const prefix = code[0];
      const codeWithoutPrefix = TerritoryUtils.trimTerritoryPrefix(code.trim());
      const codeType = TerritoryUtils.getTerritoryCodeType(codeWithoutPrefix);
      const conversionElements: TisnTisaConversionItem[] | any = (code && tisnTisaConversionItems.find(obj => obj[codeType] === codeWithoutPrefix.trim().toUpperCase())) || [];
      const values = !isEmpty(conversionElements.tisnGroup) ? conversionElements.tisnGroup : [toNumber(conversionElements.tisn)];
      if (prefix === '-') {
        minusValues = concat(minusValues, values);
      } else {
        sumValues = concat(sumValues, values);
      }
    });
    if (sumValues.length - minusValues.length >= originalTotal) {
      const uniqTerritories = differenceBy(
        sumValues.map(sumValue => ({ prefix: '+', value: sumValue })),
        minusValues.map(sumValue => ({ prefix: '-', value: sumValue })),
        'value',
      ).map(uniqValue => `${uniqValue.prefix}${uniqValue.value}`);
      return uniqTerritories;
    }
    return originalTerritoriesArray;
  }

  static getAllIceTerritoriesValues(): string[] {
    return iceCountries.map(country => country.value);
  }

  static getTerritoriesSelect(store: Store, translate: TranslateService, activityType: ActivityType = ActivityType.MATCH): FormlyFieldConfig {
    return {
      className: 'flex-1 not-remove-pl ice-claims-territory-field flex-grow-7',
      key: 'territory',
      type: 'ice-select',
      defaultValue: TerritoryUtils.getIceCountryGroups(activityType)
        .find(group => group.header === TerritoryGroup.ICE_TERRITORIES)
        ?.options.map(option => option.value),
      modelOptions: {
        updateOn: 'blur',
      },
      templateOptions: {
        placeholder: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.FIELD_TERRITORY'),
        label: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.FIELD_TERRITORY'),
        multiple: true,
        selectAllLabel: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.ALL_TERRITORIES'),
        selectAllOption: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.SELECT_ALL'),
        options: store.select(fromRoot.getAllTerritories).pipe(map(territories => TerritoryUtils.getTerritoriesAutocompleteOptions(territories))),
        useCountryShortcuts: true,
        filterActive: true,
        filterPlaceHolder: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.FILTER_PLACEHOLDER'),
        change: (field, $event) => TerritoryUtils.cleanTerritorySelectorValue(field, $event.value, $event.lastValue),
        required: true,
        componentVersion: 1,
        clearAllLabel: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.CLEAR_ALL'),
      },
    };
  }

  static territoryOptionsMap(territories: TisnTisaConversionItem[] = []) {
    return territories.map(territory => {
      return {
        label: territory?.name,
        value: territory?.tisn.toString(),
      };
    });
  }

  static getTerritoriesByType(allTerritories: TisnTisaConversionItem[] = []) {
    const modernTerritories = allTerritories.filter(territory => territory.endDate > COUNTRIES_DEFAULT_DATE);
    const territoriesByType = groupBy(modernTerritories, 'territoryType') as Record<TerritoryGroup, TisnTisaConversionItem[]>;
    return territoriesByType;
  }

  static getCountriesSplitOptions(groupedTerritories: Record<TerritoryGroup, TisnTisaConversionItem[]>) {
    const { true: iceTerritories, false: otherTerritories } = groupBy(groupedTerritories[TerritoryType.COUNTRY], 'iceTerritory');

    return [
      {
        header: TerritoryGroup.ICE_TERRITORIES,
        options: TerritoryUtils.territoryOptionsMap(iceTerritories),
      },
      {
        header: TerritoryGroup.OTHER_COUNTRIES,
        options: TerritoryUtils.territoryOptionsMap(otherTerritories),
      },
    ];
  }

  static getCountriesOptions(allTerritories: TisnTisaConversionItem[] = []) {
    const territoriesByType = TerritoryUtils.getTerritoriesByType(allTerritories);
    return TerritoryUtils.getCountriesSplitOptions(territoriesByType);
  }

  static getTerritoriesAutocompleteOptions(allTerritories: TisnTisaConversionItem[] = []) {
    const territoriesByType = TerritoryUtils.getTerritoriesByType(allTerritories);

    return [
      ...TerritoryUtils.getCountriesSplitOptions(territoriesByType),
      {
        header: TerritoryGroup.GEOGRAPHIC_GROUPS,
        options: TerritoryUtils.territoryOptionsMap(territoriesByType[TerritoryType.GEOGRAPHIC_GROUP]),
      },
      {
        header: TerritoryGroup.ECONOMIC_GROUPS,
        options: TerritoryUtils.territoryOptionsMap(territoriesByType[TerritoryType.ECONOMIC_GROUP]),
      },
      {
        header: TerritoryGroup.POLITICAL_GROUPS,
        options: TerritoryUtils.territoryOptionsMap(territoriesByType[TerritoryType.POLITICAL_GROUP]),
      },
    ];
  }

  static getAllTerritoriesResponseFormatted(allTerritoriesResponse) {
    const allTerritoriesFormatted = allTerritoriesResponse?.items
      .map(territory => {
        return {
          tisn: territory?.attributes?.tisn,
          tisnGroup: territory?.attributes?.members?.length ? Array.from(new Set(territory?.attributes?.members.map((member: any) => member?.tisn))) : [],
          tisa: territory?.attributes?.codes[0]?.tisa,
          endDate: territory?.attributes?.end,
          name: territory?.attributes?.codes[0]?.names.find(name => name?.language === 'EN')?.name,
          iceTerritory: IceTerritoriesTisnList.includes(territory?.attributes?.tisn),
          territoryType: territory?.attributes?.type,
        };
      })
      .sort((a, b) => (a?.name > b?.name ? 1 : -1));

    return allTerritoriesFormatted;
  }

  static getAllTerritoriesRequestConfig(reqNr: number): ApiCall {
    return {
      requestType: RequestType.POST,
      url: territoriesTisnApiUrl,
      queryParams: {
        include: 'attributes',
        size: '100',
        from: (reqNr - 1) * 100,
      },
      body: `{"and":[{"wildcard":{"attributes.codes.names.name":"****"}}]}`,
      responseType: 'response',
    };
  }
}
