export const locale = {
  lang: 'en',
  data: {
    ROOT: {
      LOADING_MESSAGE: 'Loading data...',
      SENDING_DATA: 'Sending Data ',
      NO_RESULTS_MESSAGE: 'No results. Please change your search criteria.',
      NO_RESULTS_MESSAGE_TAB: 'No results.',
    },
    WORKS: {
      TITLE: 'Title',
      PARTY_NAMES: 'Creator',
      PUBLICATION: 'Publication',
      GENRE: 'Genre',
      SOURCE: 'Source',
      OWNER: 'Owner',
      STATUS: 'Status',
      DURATION: 'Duration',
      LANGUAGE: 'Language',
      WORK_KEY: 'ICE Work Key',
      ROLE_CODES: 'Role',
      IPI_NUMBER: 'IPI Number',
      COUNTERCLAIMS: 'CC',
      COUNTERCLAIMS_TOOLTIP: 'Counterclaim exists',
      COUNTERCLAIMS_HEADER_TOOLTIP: 'Counterclaim',
      WORK_TYPE: 'Type',
      PURPOSE: 'Purpose',
      WSM: 'WSM',
      WSM_TOOLTIP: 'Work Society Marker',
      ACTIVITY: 'Activity',
      TOOLTIP_CONTRIBUTORS: {
        NAME: 'NAME',
        IPI: 'IPI',
        ROLE: 'ROLE',
      },
    },
    CLAIMS: {
      ROLE: 'Role',
      NAME: 'Name',
      IPI_NAME_NUMBER: 'IPI Name no.',
      IPI_NAME_NUMBER_TOOLTIP: 'IPI Name Number',
      IPI_BASE_NAME_NUMBER: 'IPI B. Num.',
      IPI_BASE_NAME_NUMBER_TOOLTIP: 'IPI Base Number',
      IP_SOCIETY_PR_MR: 'Society Aff. PR / MR',
      IP_SOCIETY_PR_MR_TOOLTIP: 'Performing Rights Society / Mechanical Rights Society',
      AGREEMENT: 'Agreement',
      START_DATE: 'Start Date',
      END_DATE: 'End Date',
      TERRITORY: 'Territory',
      POST_TERM_COLLECTION_DATE: 'PTC',
      POST_TERM_COLLECTION_DATE_TOOLTIP: 'Post-term Collection End Date',
      RESOLUTION_OWNER: 'Resolution Owner',
      ACTION_TYPE: 'Action',
      ACTION_DEADLINE: 'Deadline',
      ACTION_RESPONSE: 'Response',
      ACTION_STATUS: 'Status',
      RIGHTS: 'Rights',
      RIGHTS_PR_MR: 'Rights (PR/MR)',
      RIGHTS_PR_MR_TOOLTIP: 'Performing Right Type / Mechanical Right Type',
      PR_SHARES: 'PR',
      PR_SHARES_TOOLTIP: 'Performing Rights Share',
      MR_SHARES: 'MR',
      MR_SHARES_TOOLTIP: 'Mechanical Rights Share',
      REDUCED_PR_SHARE: 'Reduced PR Sh.',
      REDUCED_PR_SHARE_TOOLTIP: 'Reduced Performing Rights Share',
      REDUCED_MR_SHARE: 'Reduced MR Sh.',
      REDUCED_MR_SHARE_TOOLTIP: 'Reduced Mechanical Rights Share',
      SHARE: 'Share',
      CONFLICT: 'Conflict',
      OVERLAP_EXISTS: 'Overlap exists',
      OVERLAP_EXISTS_TOOLTIP: 'Overlap exists',
      STATUS: 'Status',
      RELATION: 'Relation',
      IN_CONFLICT: 'In Confl.',
      IN_CONFLICT_TOOLTIP: 'In Conflict',
      REDUCED: 'Reduced',
      INFO: 'Info',
      REMOVE_RED_EYE_TOOLTIP: 'View reduced claim(s)',
      PR_SOC: 'PR Soc',
      PR_SOC_TOOLTIP: 'Performing Rights Society',
      MR_SOC: 'MR Soc',
      MR_SOC_TOOLTIP: 'Mechanical Rights Society',
      PRIOR_ROYALTY_DATE: 'Prior R.',
      PRIOR_ROYALTY_DATE_TOOLTIP: 'Prior Royalties',
      UNAUTHORIZED: 'UA',
      UNAUTHORIZED_TOOLTIP: 'Unauthorized Author',
      ALERT: 'Alerts',
      REMOVE_CLAIM: 'Remove claim',
    },
    AGREEMENTS: {
      ASSIGNOR: 'Assignor',
      ASSIGNEE: 'Assignee',
      REFERENCE: 'Agreement<br>Number',
      REFERENCE_NUMBER: 'Agreement Number',
      START_DATE: 'Start Date',
      TYPE: 'Type',
      TYPE_TOOLTIP: {
        G: 'General',
        S: 'Specific',
      },
      NAME: 'Agreement<br>Name',
      DISPUTE: 'Dispute',
      WORKCOUNT: 'Work Count',
      TERRITORIES: 'Territory',
      PR: 'PR in<br>PR out',
      PR_TOOLTIP: 'Performing Right in, Performing Right out',
      MR: 'MR in<br>MR out',
      MR_TOOLTIP: 'Mechanical Right in, Mechanical Right out',
      ADMIN: 'Admin',
      IS_ADMIN: 'Is admin',
      NO_ADMIN: 'No admin',
      IN_CONFLICT: 'In conflict',
      CONFIRMED_CONFLICT: 'Confirmed',
      CONFIRMED: 'Confirmed',
      POTENTIAL_CONFLICT: 'Potential Conflict',
      OPEN: 'Possible Overlap',
      RESOLVED: 'Resolved',
      NO_CONFLICT: 'No conflict',
      CLOSED: 'Closed',
      PRIOR_ROYALTIES: 'Prior royalties',
      TERMINATION_DATE: 'Termination<br>date',
      POST_TERM_COLLECTION_END_DATE: 'Post-term Collection<br>End Date',
      RETENTION_COL: 'Ret.',
      RETENTION_COL_TOOLTIP: 'Retention',
      MULTIPLE_SHARES: 'Multiple Shares',
      NON_GENERAL_SHARES: 'Non-general shares present',
      HAS_RETENTION: 'Has retention',
    },
    AGREEMENT_GROUP: {
      ID: 'Agreemnet Group Ref',
      OWNER: 'Owner',
      DESCRIPTION: 'Description',
      IP_NAME_KEY: 'IP Name Key',
      NAME: 'Agreement Group Name',
      IP_NAME: 'IP Name',
      GROUP_ID: 'Group ID',
    },
    IPS: {
      NAME: 'Name',
      IPI_NAME_NUMBER: 'IPI Name Number',
      REFERENCE: 'IP Name Key',
      PUBLISHERS_NAME: 'Publisher Name',
      PUBLISHER_ID: 'Publisher Id',
      WORKS_COUNT: 'Works count',
      TYPE_OF: 'IP Base Type',
      TYPE_OF_IPI_BASE_TYPE: 'IPI Base Type',
      TYPE_OF_NAME: 'IPI Name Type',
      STATUS: 'IP Base Status',
      PR: 'PR Soc',
      PR_SOC_TOOLTIP: 'Performing Rights Society',
      MR: 'MR Soc',
      MR_SOC_TOOLTIP: 'Mechanical Rights Society',
      BASE_ID: 'IPI Base Number',
      IP_TYPE_NATURAL_PERSON: 'Natural Person',
      MULTIPLE_TYPES: 'Multiple Types',
      IP_TYPE_LEGAL_ENTITY: 'Legal Entity',
      MULTIPLE_SOCIETIES_HAS_ICE_SOCIETY: 'Multiple Societies with at least one ICE afiliated Society',
      MULTIPLE_SOCIETIES_NO_ICE_SOCIETY: 'Multiple Societies',
      WRITERS_NAME: 'Writer Name',
      AGREEMENTS_COUNT: 'Agreements Count',
    },
    SOCIETIES: {
      SOCIETY_NAME: 'Society Name',
      SOCIETY_CODE: 'Society Code',
      COUNTRY_CODE: 'Countries',
      COUNTRY_NAME: 'Territory',
      IS_ICE: 'Is ICE',
    },
    TERRITORIES: { NAME: 'Territory Name', TISA: 'TISA', TISN: 'TISN', STATUS: 'Extended TISA' },
    AGREEMENT_CONFLICT: {
      AGREEMENT_REF: 'Agreement references',
      ASSIGNED_USER: 'Assigned user',
      PUBLISHER: 'Publisher',
      DEADLINE_DATE: 'Deadline date',
      CREATED_FROM: 'Created from',
      CREATED_TO: 'Created to',
      CASE_ID: 'Case ID',
      CASE_STATUS: 'Case status',
      CREATED_DATE: 'Case created date',
      DEADLINE: 'Deadline date',
      STATUS_OVERLAP: 'Possible Overlap',
      STATUS_CONFLICT: 'Potential Conflict',
      STATUS_DISPUTE: 'Confirmed Dispute',
      STATUS_RESOLVED: 'Resolved',
      STATUS_CLOSED: 'Closed',
    },
    ACTIVITY: {
      COUNTERCLAIM_ID: 'Counterclaim ID',
      POINT_OF_CONFLICT: 'Point of conflict',
      CREATED_DATE: 'Counterclaim creation date',
      WORK_REF: 'Work key',
      AGREEMENT_REF: 'Agreement references',
      RESOLUTION_OWNER: 'Counterclaim Resolution Owner(s)',
      STATUS: 'Counterclaim Status',
      ACTION_ID: 'Action Id',
      ACTION_RESOLVER: 'Resolver',
      ACTION_OWNER: 'Action Owner',
      ACTION_STATUS: 'Action Status',
      ACTION_ASSIGNOR: 'Assignor',
      ACTION_ASSIGNED_USER: 'Assigned User',
      ACTION_ASSIGNED_USER_INFO: 'Enter name of assigned user',
      ACTION_RESPONSE: 'Response',
      ACTION_DEADLINE: 'Deadline ',
      CONFLICT_ID: 'Conflict ID',
      CONFLICT_SOCIETY: 'Conflict Society',
      CONFLICT_DATA: 'Conflict Data',
      CONFLICT_TYPE: 'Conflict Type',
      CONFLICT_SUB_TYPE: 'Conflict Sub Type',
      DUE_DATE: 'Due Date',
      WORK_ID: 'Work ID',
      WORK_KEY: 'Work Key',
      CONFLICT_SUBTYPE: 'Conflict Subtype',
      CASE_STATUS: 'Case Status',
      CONFLICT_STATUS: 'Conflict Status',
      ASSIGNEE: 'Assignee',
      WORK_TITLE: 'Work Title',
      FIRST_CREATOR: 'First Creator',
      VALUE: 'Value',
      DISTRIBUTION_SOCIETY: 'Distribution Society',
      CUSTOMER: 'Customer',
      DEADLINE_DATE: 'Deadline Date',
      CUSTOMER_DISTRIBUTION_CATEGORY: 'Distribution Category',
      UPLOAD_CATEGORY: 'Upload Category',
      REPORTING_SOCIETY: 'Reporting Society',
      PROCESS: 'Process',
      DOMESTIC_SOCIETY: 'Domestic Society',
      CONFLICT_AREA: 'Conflict Area',
      ASSIGNED_USER: 'Assigned User',
      POINT_OF_CONFLICT_TYPES: {
        BC: 'Between Creators',
        WAP: 'Writer And Original Publisher',
        PASP: 'Publisher And Sub Publisher',
        IP: 'Infringed Parties',
        NC: 'New Creator',
      },
      COUNTERCLAIMS_TYPES: {
        AS: 'Author Share',
        AU: 'Authorship',
        OW: 'Ownership',
        OR: 'Original',
        SA: 'Sample',
        NA: 'No Agreement',
        BC: 'Breach of Contract',
        IN: 'Infringement',
      },
      RESOLUTION_OWNER_TYPES: {
        PRS: 'PRS (052)',
        STIM: 'STIM (079)',
        BUMA: 'BUMA (023)',
        SABAM: 'SABAM (055)',
        KODA: 'KODA (040)',
        TEOSTO: 'TEOSTO (089)',
        TONO: 'TONO (090)',
      },
      CLAIMANT_RELATION_TYPES: {
        FIRST: 'FIRST',
        NEW: 'NEW',
        INFORMED: 'INFORMED',
        RESOLVER: 'RESOLVER',
      },
      ACTION_TYPES: {
        MEMBERS_TO_RESOLVE: 'Members to resolve',
        REQUEST_TO_SUPPORT_CLAIM: 'Request to support claim',
        REQUEST_TO_SUPPORT_AGREEMENT: 'Request to support agreement',
        EARLY_WARNING: 'Early Warning',
        WITHDRAW_CLAIM: 'Withdraw claim',
        REDUCE_CLAIM: 'Reduce claim',
        CLAIM_NOT_SUPPORTED: 'Claim not supported',
        NOTIFICATION: 'Early Warning',
        NOTIFICATION_SEARCH: 'Notification',
        COUNTERCLAIM_RESOLVED: 'Counterclaim resolved',
      },
      ACTION_RESPONSES: {
        SUPPORTED: 'Supported',
        WITHDRAWN: 'Withdraw',
        REDUCED: 'Reduced',
        REDUCED_AND_SUPPORTED: 'Reduced and supported',
        SUPPORTED_AND_DOCUMENT_REJECTED: 'Supported and document rejected',
        CLAIMS_REDUCED: 'Claims reduced',
        CLAIMS_WITHDRAWN: 'Claims withdrawn',
        CLAIMS_REMOVED: 'Claims Removed',
        NOT_SUPPORTED: 'Not supported',
        CLAIM_NOT_SUPPORTED: 'Claim not supported',
        REQUESTED_TO_SUPPORT_CLAIM: 'Requested to support claim',
        COUNTERCLAIM_RESOLVED: 'Counterclaim Resolved',
        CLAIMS_SUPPORTED_AND_REDUCED: 'Claims supported and reduced',
        CLAIMS_SUPPORTED_AND_REJECTED: 'Claims supported and rejected',
      },
    },
    COUNTER_CLAIMS: {
      COUNTERCLAIM_ID: 'Counterclaim ID',
      TYPE: 'Type',
      POINT_OF_CONFLICT: 'Point of Conflict',
      CREATION_DATE: 'Creation Date',
      RESOLUTION_OWNER: 'Resolution Owner',
      STATUS: 'Counterclaim Status',
      WORK_REF: 'Work key',
      ACTION_ID: 'Action Id',
      ACTION_RESOLVER: 'Action Resolver',
      ACTION_RESOLVER_NAME: 'Action Resolver Name',
      ACTION_ASSIGNED_USER: 'Action Assigned User',
      WORK_TITLE: 'Work Original Title',
      CREATED_DATE: 'Created date',
      COUNTERCLAIM_TYPE: 'Type',
      ACTION_OWNER: 'Action Owner',
      ACTION_STATUS: 'Action Status',
      ACTION_DEADLINE: 'Deadline ',
      ACTION_TYPE: 'Action (type)',
      ACTION_RESPONSE: 'Response',
      TERRITORY_SCOPE: 'Scope',
      ROLE: {
        A: 'Author',
        AD: 'Adaptor',
        AR: 'Arranger',
        C: 'Composer',
        CA: 'Composer Author',
        E: 'Original Publisher',
        SA: 'Sub Author',
        SE: 'Sub-publisher',
        SR: 'Sub Arranger',
        TR: 'Translator',
      },
    },
    ORGANIZATIONS: {
      NAME: 'Organization Name',
      ID: 'Organization ID',
      TYPE: 'Organization Type',
    },
    USERS: {
      ID: 'Username',
      FIRST_NAME: 'First Name',
      LAST_NAME: 'Last Name',
      EMAIL: 'Email',
      ROLE: 'Associated Roles',
      ORGANIZATION: 'Organization',
      LAST_ATTEMPT: 'Last login Timestamp',
      CREATION_DATE: 'Creation Date',
      STATUS: 'Activity Status',
    },
    REPORTS: {
      NAME: 'Name',
      REPORT_ID: 'Report ID',
      DATE_REQUESTED: 'Date requested',
      STATUS: 'Status',
      USERNAME: 'Username',
      DOWNLOAD: 'Download',
      DOWNLOAD_REPORT: 'Download Report',
      CLONE_REPORT: 'Clone Report',
      TYPE_SHARE: 'Sharepic',
      TYPE_MCPS: 'MCPS Daily Status 4 Works',
      TYPE_IPS_SHARES: 'Work Details (IPs and Shares)',
      TYPE_WORK_DETAILS_GENERATE_FICHE: 'Work Details (Generate Fiche)',
      TYPE_CHECK_MATCHING_WORKS: 'Check Matching Works for Expired Claims',
      TYPE_LIST_WORKS_HEADER: 'List Works Header and Writer',
    },
  },
};
