import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { fieldConfig, WorkInstrument, WorkInstrumentation, WorkInstrumentationDisplay, WorkUtils, TabsUtils } from '@ice';
import { DataTableRow } from '@ice/components/data-table/data-table';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { select, Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { locale as englishInstrumentations } from 'assets/i18n/en/app/works/instrumentation';
import { locale as englishInstruments } from 'assets/i18n/en/app/works/instruments';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import { INSTRUMENTATIONS, INSTRUMENTS } from 'config/constants/instruments.constants';
import { DialogForm, DIALOG_NORMAL_BT } from 'config/dialog-builders/dialog-form';
import { DialogInfo } from 'config/dialog-builders/dialog-info';
import { cloneDeep } from 'lodash';
import { ActionButton } from 'models/action-button';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PermissionsService } from 'services/permissions/permissions.service';
import * as fromRoot from 'store/root';
import type { SectionTabConfig } from '../../tabs-data';

const TYPE_INSTRUMENTS = 'instruments';
const TYPE_INSTRUMENTATIONS = 'instrumentations';
export class TabWorksInstruments implements SectionTabConfig {
  dialogRef: MatDialogRef<DialogMultiLayoutComponent, any>;
  private canEditInstruments = false;
  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private dialog: MatDialog,
    private commonApiService: null,
    private detailService: null,
    private nsService: null,
    private iceFacade: null,
    private fieldValidatorService: null,
    private permissionsService: PermissionsService,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.fuseTranslationLoader.loadTranslations(englishInstruments);
    this.fuseTranslationLoader.loadTranslations(englishInstrumentations);
    this.canEditInstruments = this.permissionsService.can('work_instruments');
  }

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTS_TABLE.TITLE'),
              model: this.store.pipe(
                select(fromRoot.getWorkInstruments),
                map(instruments => WorkUtils.getWorkInstruments(instruments, this.translate)),
              ),
              columnMode: 'flex',
              actionButtons: this.editInstruments(),
              schema: this.getDataTableInstruments(),
              tableWidth: '100',
              sorts: [{ prop: 'instrumentCode', dir: 'asc' }],
              visibleColumns: this.getVisibleColumnsInstruments(this.getDataTableInstruments()),
            },
          },
        ],
      },
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTATION_TABLE.TITLE'),
              model: this.store.pipe(
                select(fromRoot.getWorkInstrumentations),
                map(instrumentations => WorkUtils.getWorkInstrumentations(instrumentations, this.translate)),
              ),
              columnMode: 'flex',
              actionButtons: this.editInstrumentations(),
              schema: this.getDataTableInstrumentation(),
              tableWidth: '100',
              sorts: [{ prop: 'instrumentCode', dir: 'asc' }],
              visibleColumns: this.getVisibleColumnsInstrumentation(this.getDataTableInstrumentation()),
            },
          },
        ],
      },
    ];
  }

  getDataTableInstruments(): DataTableRow[] {
    return [
      { name: this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTS_TABLE.INSTRUMENT_CODE'), prop: 'instrumentCode', flexGrow: 1 },
      { name: this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTS_TABLE.INSTRUMENT_NAME'), prop: 'instrumentName', flexGrow: 1 },
      { name: this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTS_TABLE.INSTRUMENT_FAMILY'), prop: 'instrumentFamily', flexGrow: 3 },
      { name: this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTS_TABLE.INSTRUMENT_NUMBER'), prop: 'numberOfInstruments', flexGrow: 1 },
      { name: this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTS_TABLE.INSTRUMENT_NUMBER_OF_PLAYERS'), prop: 'numberOfPlayers', flexGrow: 2 },
      {
        name: '',
        prop: 'editBtnInstrument',
        actionButtonIcon: 'edit',
        flexGrow: 0.001,
        maxWidth: 40,
        minWidth: 40,
        resizeable: false,
        action: (row: any) => {
          this.store
            .pipe(select(fromRoot.getWorkInstruments))
            .subscribe(instruments => {
              this.editInstrumentsPopup(row, instruments);
            })
            .unsubscribe();
        },
        hideActionButton: row =>
          this.store.pipe(
            select(fromRoot.getEditMode),
            map(editMode => !editMode),
          ),
        actionButtonTooltip: `${this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTS_TABLE.EDIT_TITLE')}`,
      },
      {
        name: '',
        prop: 'deleteBtnInstrument',
        actionButtonIcon: 'delete',
        flexGrow: 0.001,
        maxWidth: 50,
        minWidth: 50,
        resizeable: false,
        action: row => this.deleteInstrumentPopup(row),
        hideActionButton: row =>
          this.store.pipe(
            select(fromRoot.getEditMode),
            map(editMode => !editMode),
          ),
        actionButtonTooltip: `${this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTS_TABLE.REMOVE_TITLE')}`,
      },
    ];
  }

  getDataTableInstrumentation(): DataTableRow[] {
    return [
      { name: this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTATION_TABLE.INSTRUMENT_CODE'), prop: 'instrumentCode', flexGrow: 1 },
      { name: this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTATION_TABLE.INSTRUMENT_NAME'), prop: 'instrumentName', flexGrow: 1 },
      { name: this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTATION_TABLE.INSTRUMENT_DESCRIPTION'), prop: 'instrumentDescription', flexGrow: 3 },
      { name: this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTATION_TABLE.INSTRUMENT_NUMBER'), prop: 'numberOfInstruments', flexGrow: 1 },
      { name: this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTATION_TABLE.INSTRUMENT_NUMBER_OF_PLAYERS'), prop: 'numberOfPlayers', flexGrow: 1 },
      { name: this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTATION_TABLE.NUMBER_OF_PARTS'), prop: 'numberOfParts', flexGrow: 1 },
      {
        name: '',
        prop: 'editInstrumentationBtn',
        actionButtonIcon: 'edit',
        flexGrow: 0.001,
        maxWidth: 40,
        minWidth: 40,
        resizeable: false,
        action: (row: any) => {
          this.store
            .pipe(select(fromRoot.getWorkInstrumentations))
            .subscribe(instrumentations => {
              this.editInstrumentationPopup(row, instrumentations);
            })
            .unsubscribe();
        },
        hideActionButton: row =>
          this.store.pipe(
            select(fromRoot.getEditMode),
            map(editMode => !editMode),
          ),
        actionButtonTooltip: `${this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTATION_TABLE.EDIT_TITLE')}`,
      },
      {
        name: '',
        prop: 'deleteInstrumentationBtn',
        actionButtonIcon: 'delete',
        flexGrow: 0.001,
        maxWidth: 50,
        minWidth: 50,
        resizeable: false,
        action: row => this.deleteInstrumentationPopup(row),
        hideActionButton: row =>
          this.store.pipe(
            select(fromRoot.getEditMode),
            map(editMode => !editMode),
          ),
        actionButtonTooltip: `${this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTATION_TABLE.REMOVE_TITLE')}`,
      },
    ];
  }

  // type: instruments OR instrumentation
  openDialogUpdate(type, title, model = {}, row?) {
    const isEdit = Object.keys(model).length > 0;
    const dialogForm = new DialogForm(this.translate, this.dialog, DIALOG_NORMAL_BT);
    const index = (row && row.index) || 0;
    this.dialogRef = dialogForm.openDialog(
      this.translate.instant(title),
      model,
      type === TYPE_INSTRUMENTS ? this.getInstrumentsForm() : this.getInstrumentationsForm(),
      (event: WorkInstrumentationDisplay) => {
        const { instrumentCode, numberOfInstruments, numberOfPlayers, numberOfParts } = event;
        this.store.dispatch(
          new fromRoot.UpdateField({
            object: type,
            newValue:
              type === TYPE_INSTRUMENTS
                ? { instrumentCode: instrumentCode.value, numberOfInstruments, numberOfPlayers, index }
                : { instrumentCode: instrumentCode.value, numberOfInstruments, numberOfPlayers, numberOfParts, index },
            type: isEdit ? 'edit' : 'new',
          }),
        );
        this.dialog.closeAll();
      },
      () => this.dialogRef.close(),
      this.translate.instant('POPUP_COMMON.CONFIRM'),
      this.translate.instant('POPUP_COMMON.CANCEL'),
      'dialog-wrapper-width-420-h',
    );
  }

  editInstruments(): Observable<ActionButton[]> {
    return this.store.pipe(select(fromRoot.getEditMode)).pipe(
      map(editMode => {
        if (editMode === true && this.canEditInstruments) {
          return [
            {
              icon: 'add',
              tooltip: `${this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTS_TABLE.ADD_TITLE')}`,
              class: 'mat-white-icon',
              onClick: () => this.openDialogUpdate(TYPE_INSTRUMENTS, 'WORKS.INSTRUMENTS.INSTRUMENTS_TABLE.ADD_TITLE'),
            },
          ];
        }
        return null;
      }),
    );
  }

  editInstrumentations(): Observable<ActionButton[]> {
    return this.store.pipe(select(fromRoot.getEditMode)).pipe(
      map(editMode => {
        if (editMode === true && this.canEditInstruments) {
          return [
            {
              icon: 'add',
              tooltip: `${this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTATION_TABLE.ADD_TITLE')}`,
              class: 'mat-white-icon',
              onClick: () => this.openDialogUpdate(TYPE_INSTRUMENTATIONS, 'WORKS.INSTRUMENTS.INSTRUMENTATION_TABLE.ADD_TITLE'),
            },
          ];
        }
        return null;
      }),
    );
  }

  deleteInstrumentPopup(row: WorkInstrument) {
    const dialogInfo = new DialogInfo(this.translate, this.dialog, this.store);
    const dialogRefInfo = dialogInfo.openDialog(
      this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTS_TABLE.REMOVE_TITLE'),
      this.translate.instant('WORKS.INSTRUMENTS.CHECK_DELETE'),
      () => {
        this.store.dispatch(new fromRoot.UpdateField({ object: 'instruments', newValue: row.instrumentCode, type: 'delete' }));
        dialogRefInfo.close();
      },
      () => dialogRefInfo.close(),
    );
  }

  deleteInstrumentationPopup(row: WorkInstrumentation) {
    const dialogInfo = new DialogInfo(this.translate, this.dialog, this.store);
    const dialogRefInfo = dialogInfo.openDialog(
      this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTATION_TABLE.REMOVE_TITLE'),
      this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTATION_TABLE.CHECK_DELETE'),
      () => {
        this.store.dispatch(new fromRoot.UpdateField({ object: 'instrumentations', newValue: row.instrumentCode, type: 'delete' }));
        dialogRefInfo.close();
      },
      () => dialogRefInfo.close(),
    );
  }

  private editInstrumentsPopup(row, instruments) {
    const rowCode = row.instrumentCode;
    const model = cloneDeep(instruments.find(instrument => instrument.instrumentCode === rowCode));
    this.openDialogUpdate(TYPE_INSTRUMENTS, 'WORKS.INSTRUMENTS.INSTRUMENTS_TABLE.EDIT_TITLE', model, row);
  }

  private editInstrumentationPopup(row, instrumentations) {
    const rowCode = row.instrumentCode;
    const model = cloneDeep(instrumentations.find(instrumentation => instrumentation.instrumentCode === rowCode));
    this.openDialogUpdate(TYPE_INSTRUMENTATIONS, 'WORKS.INSTRUMENTS.INSTRUMENTATION_TABLE.EDIT_TITLE', model, row);
  }

  private getInstrumentationsForm(): FormlyFieldConfig[] {
    return [
      fieldConfig([
        {
          className: 'flex-1',
          key: 'instrumentCode',
          type: 'ice-autocomplete',
          templateOptions: {
            placeholder: `${this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTATION_TABLE.INSTRUMENT_CODE')}`,
            required: true,
            options: INSTRUMENTATIONS.map(instrumentation => ({ label: instrumentation.code, value: instrumentation.code })),
            flex: 100,
          },
          validators: {
            code: {
              expression: (control: FormControl) =>
                INSTRUMENTATIONS.find(instrumentation => control.value && (!control.value.value ? false : instrumentation.code === control.value.value)),
              message: `${this.translate.instant('WORKS.INSTRUMENTS.ERRORS.INVALID_CODE')}`,
            },
          },
        },
      ]),
      fieldConfig([
        {
          className: 'flex-1',
          key: 'numberOfInstruments',
          wrappers: ['form-field', 'wrapper-input-text'],
          type: 'input',
          templateOptions: {
            placeholder: `${this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTATION_TABLE.INSTRUMENT_NUMBER')}`,
            required: true,
          },
          validators: {
            range: {
              expression: c => !c.value || /^([1-9]|[1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9])$/.test(c.value),
              message: `${this.translate.instant('WORKS.INSTRUMENTS.ERRORS.RANGE_1_999')}`,
            },
          },
        },
      ]),
      fieldConfig([
        {
          className: 'flex-1',
          key: 'numberOfPlayers',
          wrappers: ['form-field', 'wrapper-input-text'],
          type: 'input',
          templateOptions: {
            placeholder: `${this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTATION_TABLE.INSTRUMENT_NUMBER_OF_PLAYERS')}`,
            required: true,
          },
          validators: {
            range: {
              expression: c => !c.value || /^([1-9]|[1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9])$/.test(c.value),
              message: `${this.translate.instant('WORKS.INSTRUMENTS.ERRORS.RANGE_1_999')}`,
            },
          },
        },
      ]),
      fieldConfig([
        {
          className: 'flex-1',
          key: 'numberOfParts',
          wrappers: ['form-field', 'wrapper-input-text'],
          type: 'input',
          templateOptions: {
            placeholder: `${this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTATION_TABLE.NUMBER_OF_PARTS')}`,
            required: true,
          },
          validators: {
            range: {
              expression: c => !c.value || /^([1-9]|[1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9])$/.test(c.value),
              message: `${this.translate.instant('WORKS.INSTRUMENTS.ERRORS.RANGE_1_999')}`,
            },
          },
        },
      ]),
    ];
  }

  private getInstrumentsForm(): FormlyFieldConfig[] {
    return [
      fieldConfig([
        {
          className: 'flex-1',
          key: 'instrumentCode',
          type: 'ice-autocomplete',
          templateOptions: {
            placeholder: `${this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTS_TABLE.INSTRUMENT_CODE')}`,
            required: true,
            options: INSTRUMENTS.map(instrument => ({ label: instrument.code, value: instrument.code })),
            flex: 100,
          },
          validators: {
            code: {
              expression: (control: FormControl) => INSTRUMENTS.find(instrument => control.value && (!control.value.value ? false : instrument.code === control.value.value)),
              message: `${this.translate.instant('WORKS.INSTRUMENTS.ERRORS.INVALID_CODE')}`,
            },
          },
        },
      ]),
      fieldConfig([
        {
          className: 'flex-1',
          key: 'numberOfInstruments',
          wrappers: ['form-field', 'wrapper-input-text'],
          type: 'input',
          templateOptions: {
            placeholder: `${this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTS_TABLE.INSTRUMENT_NUMBER')}`,
            required: true,
          },
          validators: {
            range: {
              expression: c => !c.value || /^([1-9]|[1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9])$/.test(c.value),
              message: `${this.translate.instant('WORKS.INSTRUMENTS.ERRORS.RANGE_1_999')}`,
            },
          },
        },
      ]),
      fieldConfig([
        {
          className: 'flex-1',
          key: 'numberOfPlayers',
          wrappers: ['form-field', 'wrapper-input-text'],
          type: 'input',
          templateOptions: {
            placeholder: `${this.translate.instant('WORKS.INSTRUMENTS.INSTRUMENTS_TABLE.INSTRUMENT_NUMBER_OF_PLAYERS')}`,
            required: true,
          },
          validators: {
            range: {
              expression: c => !c.value || /^([1-9]|[1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9])$/.test(c.value),
              message: `${this.translate.instant('WORKS.INSTRUMENTS.ERRORS.RANGE_1_999')}`,
            },
          },
        },
      ]),
    ];
  }

  private getVisibleColumnsInstruments(schema: any): Observable<string[]> {
    const schemaDatatable = TabsUtils.getSChemaPropsToArray(schema);

    return this.store.pipe(
      select(fromRoot.getEditMode),
      map(editMode => {
        if (editMode) {
          return schemaDatatable;
        } else {
          return TabsUtils.getSchemaFiltered(schemaDatatable, ['editBtnInstrument', 'deleteBtnInstrument']);
        }
      }),
    );
  }

  private getVisibleColumnsInstrumentation(schema: any): Observable<string[]> {
    const schemaDatatable = TabsUtils.getSChemaPropsToArray(schema);

    return this.store.pipe(
      select(fromRoot.getEditMode),
      map(editMode => {
        if (editMode) {
          return schemaDatatable;
        } else {
          return TabsUtils.getSchemaFiltered(schemaDatatable, ['editInstrumentationBtn', 'deleteInstrumentationBtn']);
        }
      }),
    );
  }
}
