import { ClaimGraphUtils, User, WorkDetail } from '@ice';
import { DEFAULT_FILTER_MODEL } from 'config/constants/works.constants';
import { CLAIM_TYPES } from 'config/constants/repertoires.constants';
import { createLabelResolver } from '../api-call.factory';

export const getAttributesKey = createLabelResolver({ id: `attributes.key` });

export const getRelationsPatternLabelResolver = createLabelResolver({
  pattern: detail => `{"equals":{"relations[SMPL].otherId":"<<relations>>"}},{"equals":{"relations[EXCP].otherId":"<<relations>>"}},
  {"equals":{"relations[MOD].otherId":"<<relations>>"}}`,
});

export const getRelationsMatchPatternLabelResolver = createLabelResolver({
  pattern: detail => `{"equals":{"relations[MTCH].otherId":"<<relations>>"}}`,
});

export const getRelationPatternLabelResolver = createLabelResolver({
  pattern: detail => `{"equals":{"relations[XREF].otherId":"<<relations>>"}}`,
});

export const getMatchRelationsLabelResolver = createLabelResolver({
  relations: detail => (detail?.relations || []).filter(relation => relation.relation === 'MTCH').map(relation => relation.otherId),
});

export const getXrefRelationsLabelResolver = createLabelResolver({
  relations: detail => (detail?.relations || []).filter(relation => relation.relation === 'XREF').map(relation => relation.otherId),
});

export const getSMPLEXCPMODRelationsLabelResolver = createLabelResolver({
  relations: detail => (detail?.relations || []).filter(relation => ['SMPL', 'EXCP', 'MOD'].includes(relation.relation)).map(relation => relation.otherId),
});

export const getTerritoryCodesLabelResolver = createLabelResolver({
  codes: (detail: any, user?: User) => `[${ClaimGraphUtils.getAllClaimsFilterModel(user)?.territory}]`,
});

export const getAllClaimsLabelResolver = createLabelResolver({
  filter: (detail: WorkDetail, user?: User, tabName?: string, prevResponse?: any) => {
    const defaultFilterValues = {
      ...DEFAULT_FILTER_MODEL,
      status: [CLAIM_TYPES.VALIDATED, CLAIM_TYPES.SUBMITTED],
    };
    const { mrRight, prRight, usageDate, distributionDate, status } = detail.allClaimsFilter || defaultFilterValues;
    return ClaimGraphUtils.getClaimFilter(prevResponse, usageDate, distributionDate, mrRight, prRight, status);
  },
});

export const getAttributesId = createLabelResolver({ id: `attributes.id` });
