import { environment } from 'config/env';
import { SectionRouterViews } from './section-router-views';
import type { SectionConfig } from './sections-config';

export const SectionUserManagementUsers: SectionConfig = {
  name: 'users',
  url: '/user-management/users',
  domainName: 'user-management',
  baseUrl: environment.apiUrl,
  baseUrlCubeData: environment.apiUrlCubeData,
  apiSegment: 'users',
  detailSegment: `users/<<ns>>/<<id>>`,
  minCharsToSearch: 3,
  apiIncludes: {
    search: 'attributes,roles,metrics,tags',
    detail: `attributes,roles,metrics,tags`,
  },
  searchServerSideSortDefault: 'attributes.firstName',
  newItemBtn: [SectionRouterViews.home, SectionRouterViews.search],
  allowedActions: {
    newItem: true,
    editItem: 'stepper',
    newNote: false,
    removeItem: true,
    enableUser: true,
    disableUser: true,
  },
  hideHomeEditButton: true,
  // homeRedirect: 'search'
};
