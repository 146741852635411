import { FormControl, FormGroup } from '@angular/forms';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { selectControlValue } from '@ice/utils/formly/formly.utils';
import { Store, select } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core/lib/components/formly.field.config';
import { TranslateService } from '@ngx-translate/core';
import { SEARCH_TYPE_ORGANIZATION } from 'config/constants/organizations.constants';
import { SectionsConfig } from 'config/sections-config';
import { StepType } from 'config/stepper-builders/stepper-config';
import { cloneDeep, get } from 'lodash';
import { CurrentOrganization } from 'models';
import { OptionsGroup } from 'models/options-group';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { FeatureFlagService } from 'services/feature-flags/feature-flags-flagsmith.service';
import type { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromNewSectionItem from 'store/new-section-item';
import { getNewSectionItemFields } from 'store/new-section-item';
import * as fromRoot from 'store/root';

export const FIELD_ORGANIZATION_ID = 'organizationId';
export const FIELD_EMAIL = 'email';
const FIELD_ID = 'id';

export class UserRolesOrganizationStep {
  private editMode = false;
  private form: FormGroup;
  private controlOrganization: FormControl;
  private unsubscribeAll = new Subject();
  flagDisableCreateUserSoAdminPbAdminEditorRoles: Observable<boolean>;

  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<any>,
    private fieldValidatorService: FieldValidatorService,
    private featureFlagService?: FeatureFlagService,
  ) {
    this.flagDisableCreateUserSoAdminPbAdminEditorRoles = this.featureFlagService ? this.featureFlagService.disableCreateUserSoAdminPbAdminEditorRoles.asObservable() : of(false);
    this.store
      .pipe(select(fromRoot.getRouterView))
      .subscribe(view => {
        this.editMode = view === 'EDIT';
      })
      .unsubscribe();
  }

  onInitForm(field) {
    if (this.editMode) {
      this.store.pipe(select(getNewSectionItemFields), take(1)).subscribe((fields: any) => {
        return this.store.dispatch(
          new fromNewSectionItem.NewItemDoSearch({
            search: { idOrganization: fields.organizationId },
            section: SectionsConfig.ORGANIZATIONS.name,
            type: SEARCH_TYPE_ORGANIZATION,
            reset: true,
          }),
        );
      });
    }

    this.form = field.form;

    this.store.pipe(select(fromNewSectionItem.getNewSectionItemFieldsWithSection), take(1)).subscribe(storeFields => {
      const stepperControls = this.form.parent.controls;
      const controlsStepperOrganization = stepperControls[0];
      this.controlOrganization = controlsStepperOrganization.get(FIELD_ORGANIZATION_ID);
      const fields = storeFields && storeFields.fields;
      const userId = fields[FIELD_ID];
      this.editMode = userId && userId.length && userId.length > 0;
      if (this.editMode) {
        const value = get(fields, 'organizationId', '');
        this.controlOrganization.setValue({
          value,
          label: value,
        });
        this.controlOrganization.disable({ onlySelf: true });
      } else {
        this.controlOrganization.setValue(get(fields, 'organizationId', ''));
        this.controlOrganization.markAllAsTouched();
      }
      return null;
    });
  }
  onDestroyForm() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  getStep(translate: TranslateService): StepType {
    return {
      label: translate.instant('USERS.STEPS.ROLES_ORGANIZATION'),
      formBuilder: [
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-1',
              wrappers: ['wrapper-info'],
              templateOptions: {
                infoText: translate.instant('USERS.DETAILS.FORM.FIELDS.ASSOCIATED_ORGANIZATION'),
              },
              fieldGroup: [
                {
                  className: 'flex-1',
                  key: 'organizationId',
                  type: 'ice-autocomplete-grouped',
                  modelOptions: {
                    updateOn: 'submit',
                  },
                  templateOptions: {
                    placeholder: translate.instant('USERS.DETAILS.FORM.FIELDS.ASSOCIATED_ORGANIZATION'),
                    asyncOptions: this.getOrganizationsAutocomplete(this.translate.instant('USERS.DETAILS.FORM.FIELDS.ORGANIZATION_ID')),
                    required: true,
                    inputReadonly: this.store.pipe(
                      select(fromRoot.getUserCurrentOrganization),
                      map((currentOrganization: CurrentOrganization) => currentOrganization?.id !== 'ICE:ICE'),
                    ),
                    onSearchEvent: itemSearch => {
                      if (itemSearch && itemSearch.length > 1) {
                        this.store.dispatch(
                          new fromNewSectionItem.NewItemDoSearch({
                            search: cloneDeep({ idOrganization: itemSearch }),
                            section: SectionsConfig.ORGANIZATIONS.name,
                            type: SEARCH_TYPE_ORGANIZATION,
                            reset: true,
                          }),
                        );
                      }
                    },
                    change: field => field.form.controls['rolesList'].setValue([]),
                  },
                  asyncValidators: {
                    organizationIdExists: {
                      expression: (control: FormControl, field: FormlyFieldConfig) => {
                        return control?.dirty ? this.fieldValidatorService.organizationIdExists(selectControlValue(control)) : of(true).toPromise();
                      },
                      message: this.translate.instant('USERS.ORGANIZATION_DOESNT_EXIST'),
                    },
                  },
                  validation: {
                    messages: {
                      required: translate.instant('USERS.DETAILS.FORM.FIELDS.ORGANIZATION_SELECT_PLACEHOLDER'),
                    },
                  },
                  hooks: {
                    onInit: this.onInitForm.bind(this),
                    onDestroy: this.onDestroyForm.bind(this),
                  },
                },
              ],
            },
            {
              className: 'flex-1',
              wrappers: ['wrapper-info'],
              templateOptions: {
                infoText: translate.instant('USERS.DETAILS.FORM.FIELDS.ASSOCIATED_ROLES'),
              },
              fieldGroup: [
                {
                  className: 'flex-1',
                  key: 'rolesList',
                  type: 'select',
                  templateOptions: {
                    placeholder: translate.instant('USERS.DETAILS.FORM.FIELDS.ASSOCIATED_ROLES'),
                    required: true,
                    options: this.getOrganizationRoleList(),
                    multiple: true,
                    change: field => field.formControl.markAsTouched(),
                  },
                },
              ],
            },
            {
              className: 'flex-1',
              template: '<div></div>',
            },
          ],
        },
      ],
    };
  }

  private getOrganizationRoleList(): Observable<any> {
    return combineLatest([
      this.store.pipe(select(fromRoot.getUserOrganizations)),
      this.store.pipe(select(fromNewSectionItem.getNewSectionSearchResult)),
      this.store.pipe(select(getNewSectionItemFields)),
      this.flagDisableCreateUserSoAdminPbAdminEditorRoles,
    ]).pipe(
      map(([organizations, searchResultOrganizations, field, flagDisableCreateUserSoAdminPbAdminEditorRoles]: [any, any, any, boolean]) => {
        const allOrganizations =
          searchResultOrganizations && searchResultOrganizations.organization && searchResultOrganizations.organization.items
            ? organizations.concat(searchResultOrganizations.organization.items)
            : organizations;
        let fieldId = '';
        if (field?.organizationId?.value) {
          fieldId = field.organizationId.value;
        } else if (typeof field?.organizationId === 'string') {
          fieldId = field.organizationId;
        }
        let rolesOptions = (allOrganizations.find(org => org.id === fieldId) || { roles: [] }).roles.map(role => ({
          value: role.name,
          label: role.name,
        }));
        const fieldIdSuffix = fieldId?.split(':')[1];
        if (fieldIdSuffix?.startsWith('ICE')) {
          rolesOptions = rolesOptions.filter(role => this.filterInvalidRoleOption(role, 'counterclaim', this.editMode, field?.roles));
        } else if (fieldIdSuffix?.startsWith('SO') || fieldIdSuffix?.startsWith('PB')) {
          rolesOptions = rolesOptions.filter(role => this.filterInvalidRoleOption(role, 'super', this.editMode, field?.roles));
        }

        if (flagDisableCreateUserSoAdminPbAdminEditorRoles) {
          if (fieldIdSuffix?.startsWith('SO')) {
            rolesOptions = rolesOptions.filter(role => this.filterInvalidRoleOption(role, 'admin', this.editMode, field?.roles));
          } else if (fieldIdSuffix?.startsWith('PB')) {
            rolesOptions = rolesOptions.filter(
              role => this.filterInvalidRoleOption(role, 'admin', this.editMode, field?.roles) && this.filterInvalidRoleOption(role, 'editor', this.editMode, field?.roles),
            );
          }
        }

        return rolesOptions;
      }),
    );
  }

  filterInvalidRoleOption(roleOption, invalidRole, editMode, userRoles = '') {
    return roleOption.value !== invalidRole || (roleOption.value === invalidRole && editMode === true && userRoles.includes(invalidRole));
  }

  public getOrganizationsAutocomplete(header: string): Observable<OptionsGroup[]> {
    return combineLatest([this.store.pipe(select(fromRoot.getUserOrganizations)), this.store.pipe(select(fromNewSectionItem.getNewSectionSearchResult))]).pipe(
      map(([organizations, searchResultOrganizations]: [any, any]) => {
        const allOrganizations =
          searchResultOrganizations && searchResultOrganizations.organization && searchResultOrganizations.organization.items
            ? searchResultOrganizations.organization.items
            : organizations;
        const orgs: OptionsGroup[] = [{ header, options: allOrganizations.map((item: any) => ({ value: item.id, label: item.id })) }];
        return orgs;
      }),
    );
  }
}
