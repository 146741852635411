import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Stepper } from '@ice/components/stepper/stepper';
import { Store } from '@ngrx/store';
import { FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { locale as englishErrors } from 'assets/i18n/en/app/formly-global-message-errors';
import { locale as english } from 'assets/i18n/en/config/section-new-data-builders';
import { StepperConfig } from 'config/stepper-builders/stepper-config';
import { cloneDeep, isEqual, isString, last } from 'lodash';
import { Observable, Subject, Subscription, combineLatest, from, isObservable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs/operators';
import { FormlyService } from 'services/formly/formly.service';
import * as fromRoot from 'store/root';

@Component({
  selector: 'ice-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperComponent implements Stepper, OnInit, OnDestroy {
  @Input() ngrxForm = false;
  @ViewChild('stepper') stepper: MatStepper;
  @Input() stepperConfig: StepperConfig;
  @Output() emitChange: EventEmitter<any> = new EventEmitter();
  @Output() emitValidForm: EventEmitter<boolean> = new EventEmitter();
  isLinear = false;
  iconClass = 'step-valid';
  steps = [];
  model = {};
  form: FormArray;
  options;
  changesSubscription: Subscription;
  firstTouch = 0;
  unSubscribeAll = new Subject();
  isHorizontal = false;
  showGDPRInfo = false;
  GDPRText = '';

  constructor(
    private translate: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private store: Store<any>,
    private changeDetector: ChangeDetectorRef,
    private formlyService: FormlyService,
  ) {
    translationLoader.loadTranslations(english, englishErrors);
  }

  ngOnInit() {
    if (this.stepperConfig) {
      this.steps = this.stepperConfig.steps.map(step => {
        // convert label and hide to observables if they are not defined as observables
        // so we can easily work with them later in the template
        return {
          ...step,
          label: isString(step.label) ? of(step.label) : step.label,
          hide: isObservable(step.hide) ? step.hide : of(step.hide || false),
        };
      });
      if (this.stepperConfig?.horizontal) {
        this.isHorizontal = true;
      }

      if (isObservable(this.stepperConfig.model)) {
        this.stepperConfig.model
          .pipe(
            filter(model => !!model),
            take(1),
            takeUntil(this.unSubscribeAll),
          )
          .subscribe(model => {
            this.initFormWithModel(model);
            try {
              this.changeDetector.detectChanges();
            } catch (e) {}
          });
      } else {
        this.initFormWithModel(this.stepperConfig.model);
      }
      this.GDPRText = this.translate.instant('USERS.STEPS.MESSAGE_GDPR');
      this.checkGDPRInfo();
    }
  }

  initFormWithModel(model) {
    this.model = cloneDeep(model);
    this.form = new FormArray(this.steps.map(() => new FormGroup({})));
    this.options = this.steps.map(() => <FormlyFormOptions>{});

    if (this.form) {
      this.changesSubscription = combineLatest([
        this.form.valueChanges.pipe(distinctUntilChanged((before, after) => isEqual(before, after))),
        this.form.statusChanges.pipe(distinctUntilChanged((before, after) => before === after)),
      ]).subscribe(e => {
        setTimeout(() => {
          if (this.form.touched || this.form.dirty) {
            this.emitChange.emit(cloneDeep(this.model));
            this.emitValidForm.emit(this.form.valid);
          }
        });
      });
    }
    (this.options || []).forEach((options, index) => {
      this.formlyService.attachFormlyConfig({ id: `stepper_${index}`, formBuilder: this.steps[index].formBuilder, model: this.model, options, form: this.form.at(index) });
    });
  }

  ngOnDestroy() {
    (this.options || []).forEach((options, index) => {
      this.formlyService.detachFormlyConfig(`stepper_${index}`);
    });
    if (this.changesSubscription) {
      this.changesSubscription.unsubscribe();
    }
    this.unSubscribeAll.next();
    this.unSubscribeAll.complete();
  }

  checkIndex(index: number) {
    const formAtIndex = this.form.at(index);
    if (formAtIndex && formAtIndex.valid) {
      this.iconClass = 'step-valid';
      return true;
    }
    this.iconClass = 'step-no-valid';
    return false;
  }

  triggerClick(event) {
    const path = (event && event.path) || (event && event.composedPath && event.composedPath());
    if (path) {
      const clickedStep = path.find(value => value.id && value.id.includes('cdk-step-content-'));
      const clickedStepIndex: number = clickedStep && last(clickedStep.id.replace('cdk-step-content-', '').split('-'));
      this.checkStepsValidityAfterLostFocus(clickedStepIndex);
      if (this.stepper && clickedStepIndex && clickedStepIndex !== this.stepper.selectedIndex) {
        if (clickedStepIndex > this.stepper.selectedIndex) {
          const dif = clickedStepIndex - this.stepper.selectedIndex;
          for (let i = 0; i < dif; i++) {
            this.stepper.next();
          }
        } else {
          const dif = this.stepper.selectedIndex - clickedStepIndex;
          for (let i = 0; i < dif; i++) {
            this.stepper.previous();
          }
        }
        event.target.focus();
      }
    }
  }

  checkStepsValidityAfterLostFocus(currentStepIndex) {
    for (let i = 0; i < this.form.controls.length; i++) {
      if (i !== currentStepIndex) {
        this.form.at(i).markAllAsTouched();
        this.form.at(i).updateValueAndValidity();
      }
    }
  }
  checkGDPRInfo() {
    this.store.select(fromRoot.getRouterPaths).subscribe({
      next: path => {
        const stringsToCheck = ['user-management', 'users', 'new'];
        this.showGDPRInfo = stringsToCheck.every(str => path.includes(str));
      },
    });
  }
  isNewUserStep(step: { label: Observable<string>; formBuilder?: Array<any> }) {
    return step.label.pipe(map(label => this.showGDPRInfo && !!label.includes(this.translate.instant('USERS.STEPS.USER_INFO'))));
  }
}
