import { Component, EventEmitter, Input, Output } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { InputFileError, InputFileType } from './input-upload-file.model';

@Component({
  selector: 'ice-input-upload-file',
  templateUrl: './input-upload-file.component.html',
})
export class InputUploadFileComponent {
  @Output() fileData = new EventEmitter();
  @Output() fileError = new EventEmitter();
  @Output() rawFile = new EventEmitter();
  @Output() startReadingFile = new EventEmitter();
  @Input() title;
  @Input() fileType = InputFileType.CSV;
  @Input() placeholder;
  @Input() message;
  @Input() error;
  @Input() hasError;
  @Input() customPreview: Observable<any[]>;
  @Input() csvMaxHeightStyle: boolean;

  output;

  importFile(evt) {
    of(this.startReadingFile.emit(evt))
      .pipe(delay(200))
      .subscribe(_ => {
        const f = evt.target.files[0];
        if (f) {
          const r = new FileReader();
          this.rawFile.emit(f);
          r.onload = (e: any) => {
            switch (this.fileType) {
              case InputFileType.CSV:
                this.fileData.emit(this.processExcel(e.target.result));
                break;
            }
          };
          r.readAsBinaryString(f);
        } else {
          this.fileError.emit(InputFileError.FILE_READ_ERROR);
        }
      });
  }

  processExcel(data) {
    const workbook = XLSX.read(data, {
      type: 'binary',
    });
    const sheet = workbook.Sheets[workbook.SheetNames[0]];
    const json: any = XLSX.utils.sheet_to_json(sheet, { raw: true, header: 1 });
    const fixedJson = json.map(row => row.map(value => `${value}`.split(',')).reduce((acc, it) => [...acc, ...it], []));
    this.output = cloneDeep(fixedJson).slice(0, 100);
    return fixedJson;
  }
}
