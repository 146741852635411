<mat-card class="mat-card-compare">
  <ng-container *ngIf="(expanded | async) !== null">
    <mat-expansion-panel id="{{ listIdentifier ? 'expansion-list-data-table-' + listIdentifier : '' }}" [expanded]="expanded | async" (opened)="onOpened()" (closed)="onClosed()">
      <mat-expansion-panel-header class="mat-accent-bg card-expanded-header" [ngClass]="className">
        <mat-panel-title class="ice-m-0" fxFlex="100">
          <ng-container *ngTemplateOutlet="header"></ng-container>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-card-content class="ice-p-12">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </mat-card-content>
    </mat-expansion-panel>
  </ng-container>

  <ng-container *ngIf="(expanded | async) === null">
    <mat-card-header class="mat-accent-bg card-expanded-header" [ngClass]="className">
      <mat-card-title class="ice-m-0">
        <ng-container *ngTemplateOutlet="header"></ng-container>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="ice-p-12">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </mat-card-content>
  </ng-container>
</mat-card>

<ng-template #header>
  <div class="title-left ice-my-auto" fxLayout="column" fxFlex="60">
    <div class="truncate"><span [innerHTML]="title | async" iceCustomHtmlToolTip [iceTooltipHtmlText]="titleTooltip | async"></span></div>
    <div class="truncate"><span [innerHTML]="iswc | async" iceCustomHtmlToolTip [iceTooltipHtmlText]="iswc | async"></span></div>
  </div>
  <div class="flex-align-items-center" fxLayout="row" fxFlex="40">
    <div>
      <div
        *ngIf="wsm | async"
        class="ice-wsm-icon"
        [ngClass]="{ 'ice-wsm-grey': (wsm | async) === 'NO_WSM', 'ice-wsm-green': (wsm | async) === 'ALL_SM', 'ice-wsm-orange': (wsm | async) === 'AT_LEAST_ONE_SM' }"
      >
        WSM
      </div>
    </div>

    <div class="flex-align-items-center text-align-left table-header" fxFlex="100">
      <div class="cc-icon-container" fxFlex="100">
        <button mat-raised-button color="warn" class="button-white-text small-button ice-cc-button ice-cc-icon" *ngIf="showCounterclaimIndicator">CC</button>
      </div>
      <ng-container *ngIf="options">
        <mat-select
          placeholder="Select Work"
          (click)="$event.stopPropagation()"
          (selectionChange)="onSelectOption($event)"
          [(value)]="selected"
          *ngIf="!extendedWorkSelector"
          fxFlex="90"
          attr.data-testid="select-{{ listIdentifier }}"
        >
          <mat-option *ngFor="let option of options" [value]="option.value">
            {{ option.viewValue }}
          </mat-option>
        </mat-select>
        <span class="selected-view-value" *ngIf="extendedWorkSelector" fxFlex="80">{{ getSelectedViewValue(options, selected) }}</span>
        <mat-icon
          *ngIf="!(referenceId | async) && buttonTitle && options.length > 0"
          class="nav-link-icon button-header button-title-dropdown ice-open-work-icon ice-open-work-icon-left text-align-left"
          (click)="clickHeaderButton($event, selected)"
          [matTooltip]="buttonTitleTooltip"
          [matTooltipClass]="'ice-tooltip ice-tooltip-icon'"
          [matTooltipPosition]="'above'"
          >{{ buttonTitle }}
        </mat-icon>
      </ng-container>
      <ng-container *ngIf="referenceId | async as subTitle">
        <div class="justify-content-around text-align-left" fxFlex="100">
          <span fxFlex="80" class="selected-view-value">
            {{ getReferenceIdSuffix(subTitle) }}
          </span>
          <mat-icon
            class="nav-link-icon button-header ice-open-work-icon"
            id="buttonTitle"
            tabindex="-1"
            (click)="clickHeaderButton($event, subTitle)"
            [matTooltip]="buttonTitleTooltip"
            [matTooltipClass]="'ice-tooltip ice-tooltip-icon'"
            [matTooltipPosition]="'above'"
            *ngIf="buttonTitle"
            >{{ buttonTitle }}
          </mat-icon>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #content>
  <ice-form
    [resetAvailable]="resetAvailable"
    [submitAvailable]="submitAvailable"
    [button1Available]="button1Available"
    [formBuilder]="formBuilder | async"
    [model]="model"
    [submitLabel]="submitLabel"
    [button1Label]="button1Label"
    (submit)="onSubmit($event)"
    (button1Clicked)="onButton1($event)"
  ></ice-form>
</ng-template>
