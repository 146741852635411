import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CopyrightUtils, IpUtils, DatepickerUtils, ClaimsUtils } from '@ice';
import { DialogContentComponent } from '@ice/components/dialog-content/dialog-content.component';
import { Store, select } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { CREATORS, CREATOR_ROLES, ICE } from 'config/constants/global.constants';
import { IP_CONSTANTS, IP_TYPE_LEGAL_ENTITY, IP_TYPE_NATURAL_PERSON, NEW_IP_CLAIMANT, NEW_IP_PUBLISHER, PartyRelationType } from 'config/constants/ips.constants';
import { TYPE_CLAIMANT, TYPE_PUBLISHER } from 'config/constants/shares.constants';
import { DialogClaimSearchParty } from 'config/dialog-builders/dialog-claim-search-party';
import { DialogLayoutCreateIp } from 'config/dialog-layouts/dialog-layout-create-ip';
import { clone, filter, get, isEqual, last, map } from 'lodash';
import { SelectEditorDatatableRow } from 'models/copyright/detail/edit-mode';
import { SearchIpsParser } from 'models/search-parsers/search-ips-parser';
import { Observable, Subscription, combineLatest, of } from 'rxjs';
import { PermissionsService } from 'services/permissions/permissions.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromNewSectionItem from 'store/new-section-item';
import * as fromRoot from 'store/root';

export class ClaimantStepType {
  protected dialogRefCreateIps: MatDialogRef<DialogContentComponent, any>;
  protected nsSubmitNewIp = 'ICEADMIN';
  protected subscriptionNewIp: Subscription;
  protected valueIp = null;
  protected editClaim;

  protected fieldIsPublisherFromWork: any;
  protected workPublishers: SelectEditorDatatableRow[] = [];

  protected valueChangesSubscription: Subscription;

  // CLAIMANT
  protected fieldClaimantIPI: any;
  protected fieldClaimantName: any;
  protected fieldClaimantPartyId: any;
  protected fieldClaimantPartyNameId: any;
  protected fieldClaimantType: any;
  protected fieldClaimantSocietyCode: any;
  protected fieldClaimantIPNameNumber: any;
  protected fieldClaimantisDummyIP: any;
  protected fieldClaimantKey: any;
  protected fieldClaimantSocieties: any;
  // PUBLISHER
  protected fieldPublisherIPI: any;
  protected fieldPublisherName: any;
  protected fieldPublisherPartyId: any;
  protected fieldPublisherPartyNameId: any;
  protected fieldPublisherType: any;
  protected fieldPublisherSocietyCode: any;
  protected fieldPublisherIPNameNumber: any;
  protected fieldlinkCreator: any;
  protected lastClaimantIPIValidator: boolean;
  protected lastClaimantIPIValue: string;
  protected fieldSelectPublisherIPI: any;

  constructor(
    protected translate: TranslateService,
    protected dialog: MatDialog,
    protected fieldValidatorService: FieldValidatorService,
    protected store: Store<fromRoot.RootState>,
    protected storeNewItem: Store<fromNewSectionItem.NewSectionItemState>,
    protected translationLoader: FuseTranslationLoaderService,
    protected permissionsService: PermissionsService,
    protected claimsType?: string,
  ) {
    this.subscribeToNewDummyIpChanges();
  }

  protected getBasicSearchPartyFields(isClaim = true, isWorkRegister = false, ipBaseType: string = null, checkEditModeParams: boolean = true): FormlyFieldConfig[] {
    return [
      {
        className: 'flex-1 wrapper-input-text-fit-95 ice-pl-0',
        key: 'ClaimantIPI',
        wrappers: ['form-field', 'wrapper-input-text'],
        type: 'input',
        modelOptions: {
          updateOn: 'blur',
        },
        templateOptions: {
          label: this.translate.instant('CLAIM.CLAIMANT_IPI_NUMBER'),
        },
        expressionProperties: {
          'templateOptions.required': model => !this.editClaim && this['fieldClaimantName'] && !this['fieldClaimantName'].formControl.value,
          'templateOptions.organizationToFilter': model => isClaim && model.organizationToFilter,
          'templateOptions.disabled': model => isClaim && this.editClaim,
        },
        hooks: {
          onInit: field => {
            this['fieldClaimantIPI'] = field;
            if (isClaim && checkEditModeParams) {
              this.checkEditModeParams();
            }
            if (field.formControl.value) {
              field.formControl.markAsTouched();
            }
          },
          onDestroy: field => this.valueChangesSubscription?.unsubscribe(),
        },
        asyncValidators: {
          ipiNumberValid: {
            expression: (control: FormControl, field: any) => {
              if (this.lastClaimantIPIValue !== control.value) {
                this.lastClaimantIPIValue = control.value;
                this.lastClaimantIPIValidator = true;
                return !this.editClaim && !control.touched && !isWorkRegister ? of(true).toPromise() : this.searchPartyFieldValidator(TYPE_CLAIMANT, control, field, ipBaseType);
              } else {
                return of(this.lastClaimantIPIValidator).toPromise();
              }
            },
            message: this.translate.instant('CLAIM.ERRORS.CLAIMANT_IPI_NUMBER_ERROR'),
          },
        },
      },
      {
        // IP Search
        key: 'ClaimantBTSearch',
        className: 'claim-bt-search-ip',
        hideExpression: model => (isClaim && !!this.isWorkClaimScreen()) || this.editClaim,
        type: 'label',
        templateOptions: {
          materialType: 'mat-icon-button',
          icons: {
            icon: 'search',
          },
          tooltipText:
            isWorkRegister && ipBaseType === IP_TYPE_LEGAL_ENTITY
              ? this.translate.instant('WORKS.WORK_CREATION.SEARCH_PUBLISHER_SUBMITTER')
              : this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.SEARCH_CLAIMANT'),
          onClick: model => {
            if (!this.isWorkClaimScreen() || !isClaim) {
              this.openDialogSearchParty(TYPE_CLAIMANT, model, isWorkRegister);
            }
          },
        },
      },
      {
        // Create Dummy IP
        key: 'ClaimantBTAdd',
        className: 'claim-bt-add-ip',
        hideExpression: model => isClaim && !!this.isWorkClaimScreen(),
        type: 'label',
        templateOptions: {
          materialType: 'mat-icon-button',
          icons: {
            icon: 'add',
          },
          tooltipText: this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.CREATE_DUMMY_IP'),
          onClick: (this.permissionsService.can('create_dummy_ip') || isWorkRegister) && this.openDialogCreateIP.bind(this, TYPE_CLAIMANT),
        },
        expressionProperties: {
          'templateOptions.disabled': model => (!this.permissionsService.can('create_dummy_ip') && !isWorkRegister) || this.editClaim,
        },
      },
      {
        className: 'flex-1',
        key: 'ClaimantIPNameNumber',
        wrappers: ['form-field', 'wrapper-input-text'],
        type: 'input',
        hideExpression: model => isClaim && !!this.isWorkClaimScreen(),
        templateOptions: {
          label: this.translate.instant('CLAIM.IP_NAME_KEY'),
          change: field => {
            this[`fieldClaimantIPI`].formControl.setValue('');
            this.valueIp = field.formControl.value;
            if (field.formControl.value && field.formControl.value.length > 0) {
              this.searchPartyFieldValidatorIPNameKey(TYPE_CLAIMANT, this['fieldClaimantKey'].formControl, this['fieldClaimantKey']);
            } else {
              this.updatePartyFiedsWithType(TYPE_CLAIMANT, '', '', '', '', '');
            }
          },
        },
        hooks: {
          onInit: field => {
            this.valueIp = field.formControl.value;
            this['fieldClaimantKey'] = field;
            if (this['fieldClaimantKey'].formControl.value && !this.isWorkClaimScreen()) {
              this.searchPartyFieldValidatorIPNameKey(TYPE_CLAIMANT, this['fieldClaimantKey'].formControl, this['fieldClaimantKey']);
            }
          },
        },
        expressionProperties: {
          'templateOptions.disabled': model => (isClaim && !!this.isWorkClaimScreen()) || this.editClaim,
          'templateOptions.organizationToFilter': model => isClaim && model.organizationToFilter,
          'model.ClaimantIPNameNumber': '!model.ClaimantIPNameNumber ? null: model.ClaimantIPNameNumber',
        },
      },
      {
        className: 'flex-1',
        key: 'ClaimantName',
        type: 'input',
        templateOptions: {
          label: this.translate.instant('CLAIM.NAME'),
          required: false,
          disabled: true,
        },
        hooks: {
          onInit: field => {
            this['fieldClaimantName'] = field;
          },
        },
        expressionProperties: {
          'templateOptions.disabled': model => (isClaim && !!this.isWorkClaimScreen()) || this.editClaim,
        },
      },
      {
        className: 'flex-1',
        key: 'societies',
        hideExpression: () => true,
        type: 'input',
        templateOptions: {
          disabled: true,
        },
        hooks: {
          onInit: field => {
            this['fieldClaimantSocieties'] = field;
          },
        },
      },
    ];
  }

  getRoleAndDatesLineFields(isClaim = true, onlyCreators = false, availableRoles: string[] = null): any {
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          this.getClaimantRoleField(isClaim, onlyCreators, availableRoles, 'wrapper-input-text-fit-95'),
          this.getEmptyField('ice-min-width-50'),
          ...this.getFieldDatesFieldGroup(),
          this.getEmptyHiddenField(),
        ],
      },
    ];
  }

  getRoleLineField(isClaim = true, onlyCreators = false, availableRoles: string[] = null): any {
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: this.getRoleLineFieldGroup(isClaim, onlyCreators, availableRoles),
      },
    ];
  }

  getRoleLineFieldGroup(isClaim = true, onlyCreators = false, availableRoles: string[] = null): any {
    return [this.getClaimantRoleField(isClaim, onlyCreators, availableRoles), this.getEmptyField()];
  }

  getEmptyField(className = 'flex-1'): any {
    return {
      className,
      template: '<span></span>',
    };
  }

  getEmptyHiddenField(className = 'flex-1'): any {
    return {
      ...this.getEmptyField(className),
      hideExpression: () => true,
      type: 'input',
      templateOptions: {
        disabled: true,
      },
    };
  }

  getClaimantRoleField(isClaim = true, onlyCreators = false, availableRoles: string[] = null, extraClass: string = '') {
    // Work register doesn't work with some or creators roles, while we use this constant, when API accepts all we can change to global.constants.ts CREATORS constant
    const TEMP_WORK_REGISTER_CREATORS = ['A', 'AD', 'AR', 'C', 'CA'];

    return {
      className: `flex-1 ${extraClass}`,
      key: 'role',
      type: 'select',
      templateOptions: {
        label: this.translate.instant('CLAIM.CLAIMANT_ROLE_CODE'),
        required: onlyCreators ? false : true,
        options: CREATOR_ROLES.filter(role => {
          return availableRoles
            ? !!availableRoles.find(creator => creator === role.value)
            : onlyCreators
            ? !!TEMP_WORK_REGISTER_CREATORS.find(creator => creator === role.value)
            : true;
        }),
        change: (field, $event) => {
          if ($event.value === 'SE') {
            this.fieldValidatorService.isClaimRoleSubPublisher.next(true);
          } else {
            this.fieldValidatorService.isClaimRoleSubPublisher.next(false);
          }
        },
      },
      expressionProperties: {
        'templateOptions.disabled': model => (isClaim && !!this.isWorkClaimScreen() && !CREATORS.includes(model.role)) || this.editClaim,
        'templateOptions.options': model => this.getValidOptions(get(model, 'ClaimantIpBaseType') || get(this, 'fieldClaimantType.formControl.value')),
      },
      hooks: {
        onInit: field => {
          this['fieldRole'] = field;
          if (field?.form?.controls['role']?.value === 'SE') {
            this.fieldValidatorService.isClaimRoleSubPublisher.next(true);
          } else {
            this.fieldValidatorService.isClaimRoleSubPublisher.next(false);
          }
        },
      },
      validators: {
        requiredValidator: {
          expression: (c: any) => (availableRoles || TEMP_WORK_REGISTER_CREATORS).includes(c.value) || !onlyCreators,
          message: (error: any, field: FormlyFieldConfig) =>
            availableRoles ? this.translate.instant('CLAIM.CLAIMANT_ROLE_CODE_REQUIRED') : this.translate.instant('CLAIM.CLAIMANT_ROLE_CODE_NO_CREATORS_ERROR'),
        },
        ipRole: {
          expression: (control: FormControl, field: FormlyFieldConfig) => {
            const ipRole = get(control, '_parent.controls.ClaimantIpBaseType.value') || get(this, 'fieldClaimantType.formControl.value');
            const controlValue = get(control, 'value');
            let result = false;
            // when editing a claim, the ipRole is already set, sometimes to null and creator options are valid
            if ((ipRole || this.isWorkClaimScreen()) && controlValue) {
              result = map(this.getValidOptions(ipRole), 'value').includes(controlValue);
            }
            return (get(control, 'touched') || this.isWorkClaimScreen()) && (((!ipRole || this.isWorkClaimScreen()) && !controlValue) || (controlValue && result));
          },
          message: (error: any, field: FormlyFieldConfig) => this.translate.instant('CLAIM.CLAIMANT_ROLE_INVALID_IP_TYPE'),
        },
      },
    };
  }

  openDialogSearchParty(type, model, isWorkRegister = false) {
    if (this['dialogClaimRef'] && this['dialogClaimRef'].componentInstance) {
      this['dialogClaimRef'].componentInstance.setLayout(1);
    } else {
      const organizationToFilter = model && model.organizationToFilter;
      const dialogSearchParty = new DialogClaimSearchParty(this.translate, this.translationLoader, this.store, this.storeNewItem, this.dialog, isWorkRegister);
      const includedOriginalItem = !!isWorkRegister;
      const baseType = isWorkRegister && IP_CONSTANTS.NATURAL_PERSON;
      dialogSearchParty.openDialog(type, organizationToFilter, ClaimsUtils.onSearchPartySelected(this, type), this.storeNewItem, includedOriginalItem, baseType);
    }
  }

  openDialogCreateIP(type) {
    const newIpType = type === TYPE_CLAIMANT ? NEW_IP_CLAIMANT : NEW_IP_PUBLISHER;
    this.store.dispatch(new fromNewSectionItem.SetCurrentNewIp(newIpType));
    if (this['dialogClaimRef'] && this['dialogClaimRef'].componentInstance) {
      this['dialogClaimRef'].componentInstance.setLayout(3);
    } else {
      const { translate } = this;
      const layoutCreateIp = new DialogLayoutCreateIp(translate);
      this.dialogRefCreateIps = this.dialog.open(DialogContentComponent, {
        disableClose: true,
        data: {
          title: of(this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.POPUP_CREATE_IP.TITLE')),
          ok: this.translate.instant('POPUP.CONFIRM'),
          ko: this.translate.instant('POPUP.CANCEL'),
          loading: this.store.pipe(select(fromNewSectionItem.getNewSectionNewIpIsLoading)),
          sendingText: of(this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.POPUP_CREATE_IP.CREATING_DUMMY_IP')),
          model: of({}),
          formBuilder: of(layoutCreateIp.getLayout(false, IP_CONSTANTS.NATURAL_PERSON)),
          ko_icon: 'close',
          ok_icon: 'done',
          submitEnabled: true,
          submitAvailable: 'true',
          onSubmit: (dummyIpFields: any) => {
            if (!dummyIpFields?.dateOfDeath) {
              delete dummyIpFields.dateOfDeath;
            }
            this.store.dispatch(new fromNewSectionItem.PutNewIp({ ...dummyIpFields, submitterIPI: this.nsSubmitNewIp, getIpDetail: true }));
          },
          responseMessage: this.getErrorMessageNewIp(),
          endButtons: [
            {
              text: of(this.translate.instant('POPUP.CLOSE')),
              visibleOnError: true,
              action: () => {
                this.dialogRefCreateIps.close();
              },
            },
          ],
        },
      });
    }
  }

  // case: IPI is from work creator
  // case: IPI is Publisher
  checkEditModeParams() {
    combineLatest([
      this.store.pipe(select(fromRoot.getCopyrightWork)),
      this.store.pipe(select(fromRoot.getWorkCreators)),
      this.store.pipe(select(fromRoot.getEditingId)),
      this.store.pipe(select(fromRoot.getRouterQueryParams)),
    ])
      .subscribe(([detail, creators, editingId, queryParams]: [any, SelectEditorDatatableRow[], string, any]) => {
        this.editClaim = detail?.editClaim || detail?.editConflictId;
        if (!this.editClaim && editingId !== ICE && !this.isWorkClaimScreen()) {
          const creator = creators.find(c => c.key.includes(editingId || '') || (editingId && editingId.includes(c.ipiNameNumber)));
          if (creator) {
            if (creator.ipiNameNumber && creator.ipiNameNumber.length && creator.ipiNameNumber.length > 0) {
              this['fieldClaimantIPI'].formControl.setValue(creator.ipiNameNumber);
            } else {
              this['fieldClaimantIPNameNumber'].formControl.setValue(creator.key);
            }
          }
        }
      })
      .unsubscribe();
  }

  subscribeToNewDummyIpChanges() {
    this.subscriptionNewIp = this.store.pipe(select(fromNewSectionItem.getNewSectionNewIp)).subscribe(newIp => {
      if (newIp) {
        const { current, claimant, publisher } = newIp;
        const currentType = current === NEW_IP_CLAIMANT ? TYPE_CLAIMANT : TYPE_PUBLISHER;
        const ipObject = current === NEW_IP_CLAIMANT ? claimant : publisher;
        if (ipObject && ipObject.xrefPartyName) {
          const { name, firstName, id, typeOf, idPartyName, xrefPartyName, partyInfo } = ipObject;
          const firstNameFormatted = firstName || this.translate.instant('AGREEMENTS.AGREEMENT_PARTIES.POPUP_CREATE_IP.FIRST_NAME_NOT_SPECIFIED');
          this[`field${currentType}IPI`]?.formControl.setValue('');
          this.updatePartyFiedsWithType(
            currentType,
            id,
            idPartyName,
            `${firstNameFormatted} ${name}`.toUpperCase(),
            typeOf,
            CopyrightUtils.getKeySuffix(xrefPartyName),
            '',
            partyInfo,
          );
          if (this.dialogRefCreateIps) {
            this.store.dispatch(new fromNewSectionItem.PutNewIpSuccess(null));
            this.dialogRefCreateIps.close();
          }
        }
      }
    });
  }

  updatePartyFiedsWithType(
    type: string,
    partyId: string,
    partyNameId: string,
    name: string,
    ipType: string = '',
    ipNameNumber: string = '',
    ipBaseNameNumber: string = '',
    partyNameObject: Object = null,
    ipBaseType: string = null,
  ) {
    if (this[`field${type}Name`] && this[`field${type}Name`].formControl) {
      this[`field${type}Name`].formControl.setValue(name);
      this[`field${type}PartyId`].formControl.setValue(partyId);
      this[`field${type}PartyNameId`].formControl.setValue(partyNameId);
      this[`field${type}Type`].formControl.setValue(ipType);
      this[`field${type}IPNameNumber`].formControl.setValue((ipNameNumber || '').replace('ICE:', ''));
      if (this[`field${type}IPBaseNameNumber`]) {
        this[`field${type}IPBaseNameNumber`].formControl.setValue(ipBaseNameNumber);
      }
      if (this[`field${type}Info`]) {
        this[`field${type}Info`].formControl.setValue(partyNameObject);
      }
      if (this[`field${type}IpBaseType`]) {
        this[`field${type}IpBaseType`].formControl.setValue(ipBaseType);
      }
      if (this['fieldRole']) {
        this['fieldRole'].formControl?.markAsTouched();
        this['fieldRole'].formControl?.updateValueAndValidity();
      }
      if (type === TYPE_CLAIMANT) {
        this.valueIp = ipNameNumber;
      }
      const societies = get(partyNameObject, 'parties[0].party.societies', []);
      if (this[`field${type}Societies`] && !!societies?.length) {
        this[`field${type}Societies`].formControl.setValue(societies);
      }
    }
  }

  resolveAndUpdateFields(resolve, type, partyId, partyNameId, name, partyNameObject, partyObject) {
    const relations = get(partyNameObject, 'relations', []);
    const relationFound = relations.find(relation => relation.relation === PartyRelationType.DISPLAY_REFERENCE);
    const ipBaseNameNumber = IpUtils.selectIPINumber(get(partyObject, `relations`));
    const ipBaseType = get(partyObject, 'attributes.typeOf', null);
    if (relationFound && relationFound.otherId) {
      this.updatePartyFiedsWithType(clone(type), partyId, partyNameId, name, type, get(relationFound, 'otherId', ''), ipBaseNameNumber, partyNameObject, ipBaseType);
      resolve(true);
    } else {
      this.updatePartyFiedsWithType(clone(type), '', '', '', '', '', '', null, null);
      resolve(false);
    }
  }

  getHiddenSearchPartyFields(addSocietyCode = true) {
    return [
      this.genHiddenField(TYPE_CLAIMANT, 'PartyId'),
      this.genHiddenField(TYPE_CLAIMANT, 'PartyNameId'),
      this.genHiddenField(TYPE_CLAIMANT, 'Type'),
      this.genHiddenField(TYPE_CLAIMANT, 'IPNameNumber'),
      this.genHiddenField(TYPE_CLAIMANT, 'IPBaseNameNumber'),
      this.genHiddenField(TYPE_CLAIMANT, 'Info'),
      this.genHiddenField(TYPE_CLAIMANT, 'IpBaseType'),
      ...((addSocietyCode && [this.genHiddenField(TYPE_CLAIMANT, 'SocietyCode')]) || []),
    ];
  }

  genHiddenField(type, fieldName, fieldType: string = 'label') {
    return {
      className: 'ice-display-none',
      key: `${type}${fieldName}`,
      type: fieldType,
      hooks: {
        onInit: field => (this[`field${type}${fieldName}`] = field),
      },
    };
  }

  private getErrorMessageNewIp(): Observable<any> {
    return this.store.pipe(select(fromNewSectionItem.getNewSectionNewIpError));
  }

  isWorkClaimScreen() {
    return this.claimsType === 'WORK_CLAIMS';
  }

  async searchPartyFieldValidator(type: string, control: FormControl, field: any, baseType: string = null) {
    const organizationToFilter: string = field.templateOptions.organizationToFilter;
    const organizationFormatted = organizationToFilter && last(organizationToFilter.split(':')).replace('SO', '');
    if (control.value) {
      control.markAsUntouched();
    }
    const extraParams = {};
    if (field.templateOptions.organizationToFilter) {
      extraParams['organizationMembership'] = `CISAC:${organizationFormatted}`;
    }
    if (baseType) {
      extraParams['typeOf'] = baseType;
    }
    return new Promise((resolve, reject) =>
      field && field.formControl && field.formControl.value && field.formControl.value.length && field.formControl.value.length > 0
        ? this.fieldValidatorService.getContributorFieldsFromIPI(
            field,
            control,
            (partyId, partyNameId, name, partyNameObject, partyObject) => {
              this.resolveAndUpdateFields(resolve, type, partyId, partyNameId, name, partyNameObject, partyObject);
            },
            () => {
              this.updatePartyFiedsWithType(clone(type), '', '', '');
              this.lastClaimantIPIValidator = false;
              resolve(false);
            },
            extraParams,
          )
        : resolve(true),
    );
  }

  searchPartyFieldValidatorIPNameKey(type: string, control: FormControl, field: any, xrefKey?: string) {
    const organizationToFilter: string = field && field.templateOptions && field.templateOptions.organizationToFilter;
    const organizationFormatted = organizationToFilter && last(organizationToFilter.split(':')).replace('SO', '');
    return new Promise(resolve =>
      field && field.formControl && ((field.formControl.value && field.formControl.value.length && field.formControl.value.length > 0) || xrefKey)
        ? this.fieldValidatorService.getContributorFieldsFromIPI(
            field,
            control,
            (partyId, partyNameId, name, partyNameObject, partyObject) => {
              const item = SearchIpsParser.parse([partyNameObject], this.translate, null, null, this.store)[0];
              if (item.ipiNameNumber && item.ipiNameNumber.length && item.ipiNameNumber.length > 0 && type === 'Claimant') {
                this[`field${type}IPI`].formControl.setValue(item.ipiNameNumber, { emitEvent: false });
              }
              this.resolveAndUpdateFields(resolve, type, partyId, partyNameId, name, partyNameObject, partyObject);
              this[`field${type}IPI`].formControl.markAsTouched();
            },
            () => {
              this.updatePartyFiedsWithType(clone(type), '', '', '', '', '');
              resolve(false);
            },
            field && field.templateOptions && field.templateOptions.organizationToFilter ? { organizationMembership: `CISAC:${organizationFormatted}` } : {},
            (xrefKey && { key: 'xref', value: xrefKey, customMaskedIdsConfig: null }) || null,
          )
        : resolve(true),
    );
  }

  getpartyFields(): any {
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [...this.getBasicSearchPartyFields(false, true), ...this.getHiddenSearchPartyFields(false)],
      },
    ];
  }

  getFieldDates(): any {
    return {
      fieldGroupClassName: 'display-flex',
      fieldGroup: this.getFieldDatesFieldGroup(),
    };
  }

  getFieldDatesFieldGroup(): any {
    const translate = this.translate;
    const sinceFieldConfig = DatepickerUtils.getDatepickerField({
      name: 'startDate',
      label: this.translate.instant('CLAIM.START_DATE'),
      translate,
      extraTemplateOptions: { popupForcePt: 5 },
    });
    const toFieldConfig = DatepickerUtils.getDatepickerField({
      name: 'endDate',
      label: this.translate.instant('CLAIM.END_DATE'),
      translate,
      extraTemplateOptions: { popupForcePt: 5 },
    });
    DatepickerUtils.createDatepickerRange({ translate, sinceFieldConfig, toFieldConfig });
    return [sinceFieldConfig, toFieldConfig];
  }

  getValidOptions(ipRole: string) {
    if (isEqual(ipRole, IP_TYPE_NATURAL_PERSON)) {
      return filter(CREATOR_ROLES, role => CREATORS.includes(get(role, 'value')));
    } else if (isEqual(ipRole, IP_TYPE_LEGAL_ENTITY)) {
      return filter(CREATOR_ROLES, role => !CREATORS.includes(get(role, 'value')));
    } else {
      return CREATOR_ROLES;
    }
  }
}
