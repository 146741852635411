import { environment } from 'config/env';
import { SectionRouterViews } from './section-router-views';
import type { SectionConfig } from './sections-config';

export const SectionConflictsCounterClaims: SectionConfig = {
  name: 'counter-claims',
  url: '/conflicts/counter-claims',
  domainName: 'conflicts',
  baseUrl: environment.apiUrl,
  baseUrlCubeData: environment.apiUrlCubeData,
  apiSegment: 'counterclaims',
  detailSegment: `counterclaims/v2/CUBE/<<id>>`,
  minCharsToSearch: 5,
  apiIncludes: {
    search: `attributes, tags, actions.attributes, participants, work, work.attributes, work.relations, counterclaimParticipant`,
    detail: `attributes, tags, actions.attributes, actions.audits, actions.documents, actions.ownerName,
    actions.ownerName.attributes{name,firstName},
    participants{id, counterclaimId, isPublishedCreator, isRemoved, ns, parentId, topLevelNameId,  version,
      parentParticipantNameId, parentParticipantRole, participantNameId, participantRole, relation, resolutionOwner},
    participants.claimant.attributes, participants.claimant.relations, work, work.attributes, work.relations`,
  },
  searchServerSideSortDefault: 'id',
  allowedActions: {
    newItem: false,
    editItem: 'tab',
    newNote: false,
    removeItem: false,
  },
  hideHomeEditButton: true,
  homeRedirect: 'search',
  homeInAnotherNavigationItem: true,
  newItemBtn: [SectionRouterViews.home, SectionRouterViews.search],
  auditTypes: ['counterclaim', 'action'],
};
