import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'ice-formly-wrapper-no-icon',
  templateUrl: './wrapper-no-icon.component.html',
  styleUrls: ['./wrapper-no-icon.component.scss'],
})
export class WrapperNoIconComponent extends FieldWrapper {
  @ViewChild('fieldComponent', { read: ViewContainerRef, static: true }) fieldComponent: ViewContainerRef;
}
