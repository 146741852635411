<mat-form-field class="search-full-width" *ngIf="!field.templateOptions.noScroll && model.length > 10">
  <mat-label>{{ 'REPEATER_SEARCH_TITLE' | translate }}</mat-label>
  <input matInput (keyup)="findValues($event)" />
</mat-form-field>
<ng-container *ngIf="!field.templateOptions.noScroll && !field.templateOptions.disabled">
  <cdk-virtual-scroll-viewport itemSize="66" [ngStyle]="{ 'height.px': getVirtualScrollHeight() }" *ngIf="field.fieldGroup?.length">
    <div *cdkVirtualFor="let field of field.fieldGroup; let i = index" class="row">
      <formly-group [field]="field" style="margin-right: 25px">
        <div class="repeat-remove-button">
          <button
            [disabled]="model && model.length < 2"
            mat-icon-button
            matTooltip="{{ removeButtonText }}"
            [matTooltipPosition]="to.tooltipPosition || 'above'"
            [matTooltipClass]="'ice-tooltip ice-repeater-tooltip'"
            (click)="removeItem(i, field)"
            type="button"
          >
            <mat-icon>remove_circle</mat-icon>
          </button>
        </div>
      </formly-group>
    </div>
  </cdk-virtual-scroll-viewport>
</ng-container>
<ng-container *ngIf="field.templateOptions.noScroll && !field.templateOptions.disabled">
  <div *ngFor="let field of field.fieldGroup; let i = index" class="row">
    <formly-group [field]="field" style="margin-right: 25px">
      <div class="repeat-remove-button">
        <button
          mat-icon-button
          matTooltip="{{ removeButtonText }}"
          [matTooltipPosition]="to.tooltipPosition || 'above'"
          [matTooltipClass]="'ice-tooltip ice-repeater-tooltip'"
          (click)="removeItem(i, field)"
        >
          <mat-icon>remove_circle</mat-icon>
        </button>
      </div>
    </formly-group>
  </div>
</ng-container>
<div *ngIf="!field.templateOptions.disabled">
  <div *ngIf="field.formControl.invalid && field.fieldGroup.length > 0" (click)="showRepeaterError()" class="error-message">
    <button mat-icon-button>
      <mat-icon>error</mat-icon>
    </button>
    <label style="align-self: center; cursor: pointer">{{ 'REPEATER_ERROR' | translate }}</label>
  </div>
  <div *ngIf="field.templateOptions.required && field.fieldGroup.length === 0" class="error-message">
    <label style="align-self: center; cursor: pointer">{{ 'REPEATER_REQUIRED' | translate }}</label>
  </div>
  <button
    mat-button
    (click)="addItem()"
    [disabled]="
      field && field.templateOptions && ((field.templateOptions.buttonDisabled && field.fieldGroup.length > 0) || (field.formControl.value.length > 0 && isLastRowEmpty()))
    "
    *ngIf="!field.templateOptions.max || field.fieldGroup.length < field.templateOptions.max"
    type="button"
  >
    <mat-icon>add_circle</mat-icon>
    {{ addButtonText }}
  </button>
  <div class="max-items-message" *ngIf="field.templateOptions.max && field.fieldGroup.length >= field.templateOptions.max">
    {{ field.templateOptions.maxItemsText || 'MAX_ITEMS_REACHED' | translate }}
  </div>
  <span *ngIf="field && field.templateOptions && field.templateOptions.validatingField">
    <mat-spinner style="display: inline-block; filter: invert(1)" [diameter]="17" class="search-spinner"></mat-spinner><span> {{ 'VALIDATING_FIELD' | translate }}</span>
  </span>
  <div class="custom-error">{{ customError }}</div>
</div>
