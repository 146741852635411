import { concat, get } from 'lodash';
import { PageableData } from 'models/response/cube-register.response';

export class PaginationUtils {
  public static getPageableData<ItemModel>(items: ItemModel[], response, oldPageableItem: PageableData<ItemModel>, sort?, fromScrollEvent?): PageableData<ItemModel> {
    const responseTotal = get(response, 'total', 0);
    const oldPageableTotal = get(oldPageableItem, 'total', 0);
    const oldItems = (fromScrollEvent && oldPageableItem?.items) || [];
    const newItems = items || [];
    return {
      items: concat(oldItems, newItems),
      count: ((fromScrollEvent && get(oldPageableItem, 'count', 0)) || 0) + get(response, 'items.length', 0),
      total: Math.max(responseTotal, oldPageableTotal),
      ...((sort && { sort }) || (oldPageableItem && oldPageableItem.sort && { sort: oldPageableItem.sort })),
      hasAllItems: responseTotal < oldPageableTotal,
    };
  }
}
