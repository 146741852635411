import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseSharedModule } from '@fuse/shared.module';
import { ButtonsGroupModule } from '@ice/components/buttons-group/buttons-group.module';
import { CardBasicModule } from '@ice/components/card-basic/card-basic.module';
import { CardWithDataTableModule } from '@ice/components/card-with-data-table/card-with-data-table.module';
import { CardWithExpansionListModule } from '@ice/components/card-with-expansion-list/card-with-expansion-list.module';
import { CardWithFormModule } from '@ice/components/card-with-form/card-with-form.module';
import { CardWithFormlyModule } from '@ice/components/card-with-formly/card-with-formly.component.module';
import { CardWithSharesRepertoireModule } from '@ice/components/card-with-shares-repertoire/card-with-shares-repertoire.module';
import { CardWithSharesModule } from '@ice/components/card-with-shares/card-with-shares.module';
import { CardModule } from '@ice/components/card/card.module';
import { DataTableTotalsModule } from '@ice/components/data-table-totals/data-table-totals.module';
import { DataTableModule } from '@ice/components/data-table/data-table.module';
import { ExpansionListLabeledModule } from '@ice/components/expansion-list-labeled/expansion-list-labeled.module';
import { FormContainerModule } from '@ice/components/form-container/form-container.module';
import { HtmlTemplateModule } from '@ice/components/html-template/html-template.module';
import { InputUploadFileModule } from '@ice/components/input-upload-file/input-upload-file.module';
import { ProgressBarComponentModule } from '@ice/components/progress-bar/progress-bar.module';
import { ResponseErrorComponentModule } from '@ice/components/response-error/response-error.module';
import { StepperModule } from '@ice/components/stepper/stepper.module';
import { TableModule } from '@ice/components/table/table.module';
import { TreeChartModule } from '@ice/components/work-tree-chart/tree-chart.module';
import { IceMaterialModule } from '@ice/material.module';
import { AnalysisAndComparisonsModule } from '../analysis-and-comparisons/analysis-and-comparisons.module';
import { GroupComponent } from './group-component.component';

@NgModule({
  declarations: [GroupComponent],
  imports: [
    CommonModule,
    ButtonsGroupModule,
    IceMaterialModule,
    FlexLayoutModule,
    DataTableModule,
    CardWithExpansionListModule,
    InputUploadFileModule,
    CardWithFormModule,
    CardWithFormlyModule,
    CardWithDataTableModule,
    CardWithSharesModule,
    CardWithSharesRepertoireModule,
    ExpansionListLabeledModule,
    FormContainerModule,
    ResponseErrorComponentModule,
    TableModule,
    ProgressBarComponentModule,
    StepperModule,
    CardModule,
    FuseSharedModule,
    DataTableTotalsModule,
    TreeChartModule,
    HtmlTemplateModule,
    CardBasicModule,
    AnalysisAndComparisonsModule,
  ],
  exports: [GroupComponent],
})
export class GroupComponentModule {}
