import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ClaimantUtils } from '@ice';
import { DataTableRow } from '@ice/components/data-table/data-table';
import { SocietiesUtils } from '@ice/utils/societies/societies.utils';
import { select, Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { iceCustomerSocieties } from 'config/constants/ips.constants';
import { INCLUDE_WORLD_IN_EX_TISN } from 'config/constants/territories.constants';
import { CopyrightClaimsDataTable } from 'config/data-table-builders/copyright.claims';
import { DialogCounterClaimResolvers } from 'config/dialog-builders/dialog-counter-claim-resolvers';
import { StepType } from 'config/stepper-builders/stepper-config';
import { isEmpty } from 'lodash';
import { CounterClaimGroup } from 'models/copyright/send-update/counter-claim';
import { combineLatest, of, Subject, Subscription } from 'rxjs';
import { map, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { PermissionsService } from 'services/permissions/permissions.service';
import * as fromNewSectionItem from 'store/new-section-item';
import * as fromRoot from 'store/root';

export class CounterClaimRegistrationResolversStep {
  private schema: CopyrightClaimsDataTable;
  private territoryScope: string;
  private currentClaimantGroups: CounterClaimGroup[];
  private resolversDialog: DialogCounterClaimResolvers;
  private claimGroupsSubscription: Subscription;
  private unsubscribe = new Subject<any>();
  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<any>,
    private dialog: MatDialog,
    private permissionsService: PermissionsService,
  ) {
    this.schema = new CopyrightClaimsDataTable(this.translate, this.fuseTranslationLoader, this.store, this.permissionsService);
    this.resolversDialog = new DialogCounterClaimResolvers(this.translate, this.store, this.dialog, this.schema);
    this.store.pipe(select(fromRoot.getRouterQueryParams), take(1)).subscribe(queryParams => {
      const { territory } = queryParams;
      this.territoryScope = territory || INCLUDE_WORLD_IN_EX_TISN;
    });
  }

  getStep(translate: TranslateService): StepType {
    const schema = this.schema.getDataTable(
      row => this.resolversDialog.openDialog(row),
      null,
      null,
      row => {
        const options = iceCustomerSocieties.filter(iceSociety => row.prMr.includes(iceSociety));
        return [
          { value: '', label: 'Select ...', class: 'select-resolution-owner-warning', disabled: true },
          ...iceCustomerSocieties.map(societyCode => ({ value: societyCode, label: SocietiesUtils.getSocietyNamesAndIdsByIds(societyCode) })),
        ];
      },
      row => this.store.dispatch(new fromNewSectionItem.SetClaimantGroups(ClaimantUtils.updateResolutionOwnwer(this.currentClaimantGroups, row))),
    );
    const expandableSchema = this.getExpandableSchema(schema);
    return {
      label: translate.instant('COUNTERCLAIMS.STEPS.SET_RESOLUTION_OWNERS'),
      formBuilder: [
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-1',
              key: 'claimantGroups',
              type: 'datatable-editable',
              templateOptions: {
                config: {
                  data: this.store.select(fromNewSectionItem.getClaimantGroups).pipe(
                    tap(claimantGroups => (this.currentClaimantGroups = claimantGroups)),
                    withLatestFrom(this.store.select(fromRoot.getAllClaims)),
                    map(([claimantGroups, claims]) => ClaimantUtils.claimantGroupsCleaner(claimantGroups, claims, translate)),
                  ),
                  expandRows: true,
                  columnMode: ColumnMode.flex,
                  selectionType: SelectionType.checkbox,
                  expandRowsEnabled: true,
                  dontStrip: true,
                  hideEmpty: true,
                  schema,
                  visibleColumns: of(['role', 'name', 'refLabel', 'prMr', 'relation', 'resolutionOwner']),
                  expandableProperty: of('detail'),
                  expandableSchema,
                  expandableNoHeader: true,
                  customClass: 'ice-detail-table-custom-padding',
                },
              },
              hooks: {
                onInit: field => {
                  const control = field.formControl;
                  this.claimGroupsSubscription = this.store.pipe(select(fromNewSectionItem.getClaimantGroups)).subscribe(data => {
                    if (data && data.length) {
                      control.markAsDirty();
                    }
                    control.setValue(data);
                  });
                },
                onDestroy: () => {
                  if (this.claimGroupsSubscription) {
                    this.claimGroupsSubscription.unsubscribe();
                  }
                },
              },
              validators: {
                key: {
                  expression: c => isEmpty(c.value) || ClaimantUtils.hasAllResolutionOwners(c.value),
                  message: (error, field: FormlyFieldConfig) => `${this.translate.instant('COUNTERCLAIMS.STEPS.SET_RESOLUTION_OWNERS_ERROR')}`,
                },
              },
            },
            {
              key: 'claimantShares',
              type: 'input',
              hide: true,
              hooks: {
                onInit: field => {
                  const control = field.formControl;
                  combineLatest([this.store.select(fromRoot.getAllClaims), this.store.select(fromNewSectionItem.getClaimantGroups)])
                    .pipe(
                      map(([claims, claimantGroups]) => ClaimantUtils.getClaimantsShares(claims, claimantGroups)),
                      takeUntil(this.unsubscribe),
                    )
                    .subscribe(data => {
                      control.setValue(data);
                    });
                },
                onDestroy: () => {
                  this.unsubscribe.next();
                  this.unsubscribe.complete();
                },
              },
            },
          ],
        },
      ],
    };
  }

  getExpandableSchema(schema: DataTableRow[]): { detail: DataTableRow[] } {
    return {
      detail: (schema || []).map(row => {
        if (row?.prop === 'resolutionOwner' && row.width) {
          row.minWidth = row.width;
        }
        return { ...row, flexGrow: 1 };
      }),
    };
  }
}
