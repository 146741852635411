export const DEFAULT_SEARCH_SIZE = 35;
export const DEFAULT_SEARCH_FROM = 0;
export const SELECTABLE_SECTIONS = ['activity', 'counter-claims'];
export const MAX_SEARCH_SIZE = 100;
export const EXPERT_SEARCH_SEPARATOR = ' AND ';
export const DEFAULT_OVERWRITE_QUERY = {
  force: true,
  mode: 'OVERWRITE',
};
export const DEFAULT_OVERWRITE_CONFLICT_QUERY = {
  force: false,
  mode: 'MERGE',
};
export const IGNORE_FIELD_TAG = '-Ignore';
export const PREFIX_FIELD_TAG = 'Prefix';
export const ORIGINAL_AGREEMENT_FIELD = `originalAgreementRefSearch${IGNORE_FIELD_TAG}`;
export const LEGACY_AGREEMENT_FIELD = `legacyAgreement${IGNORE_FIELD_TAG}`;
export const FIELD_ORGANIZATION_AUTOCOMPLETE = 'organizationAutocomplete';
export const FIELD_ORGANIZATION_ID = 'organizationId';
export const XREF_LIST = 'xrefList';
