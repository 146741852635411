import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FuseNavigationModule, FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { DialogContentComponent } from '@ice/components/dialog-content/dialog-content.component';
import { DialogContentModule } from '@ice/components/dialog-content/dialog-content.module';
import { DialogJsonViewerComponent } from '@ice/components/dialog-json-viewer/dialog-json-viewer.component';
import { DialogJsonViewerModule } from '@ice/components/dialog-json-viewer/dialog-json-viewer.component.module';
import { TreeNestedComponent } from '@ice/components/dialog-json/dialog-json.component';
import { TreeNestedModule } from '@ice/components/dialog-json/dialog-json.module';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { DialogMultiLayoutModule } from '@ice/components/dialog-multi-layout/dialog-multi-layout.module';
import { IceMaterialModule } from '@ice/material.module';
import { FooterModule } from 'app/root/components/footer/footer.module';
import { NavbarModule } from 'app/root/components/navbar/navbar.module';
import { QuickPanelModule } from 'app/root/components/quick-panel/quick-panel.module';
import { ToolbarModule } from 'app/root/components/toolbar/toolbar.module';
import { FuseMainComponent } from './main.component';

@NgModule({
  declarations: [FuseMainComponent],
  exports: [FuseMainComponent],
  imports: [
    IceMaterialModule,
    FuseSharedModule,
    FuseNavigationModule,
    FuseSidebarModule,
    FooterModule,
    NavbarModule,
    QuickPanelModule,
    ToolbarModule,
    RouterModule,
    TreeNestedModule,
    DialogJsonViewerModule,
    DialogContentModule,
    DialogMultiLayoutModule,
  ],
})
export class FuseMainModule {}
