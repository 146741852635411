import { AfterViewChecked, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ButtonsGroup, ButtonsGroupConfig } from '@ice/components/buttons-group/buttons-group';
import { locale as english } from '@ice/i18n/en/button-labels';
import { Store, select } from '@ngrx/store';
import { Subject, isObservable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as fromRoot from 'store/root';
import { ButtonsGroupActions } from '../../../config/buttons-group-builders/buttons-group-actions';

@Component({
  selector: 'ice-buttons-group',
  templateUrl: './buttons-group.component.html',
  styleUrls: ['./buttons-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonsGroupComponent implements ButtonsGroup, OnDestroy, AfterViewChecked, OnInit {
  @Input() config: ButtonsGroupConfig;
  @Output() emitEvent: EventEmitter<ButtonsGroupActions> = new EventEmitter();
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };
  isObservable = isObservable;
  unsubscribeAll = new Subject();
  cssClassSelectors = {
    hide: 'ice-display-none',
    fabButtonsWrapper: '.fab-buttons-wrapper',
    fabToggleOpen: '.fab-toggle-open',
    fabToggleClose: '.fab-toggle-close',
  };

  constructor(private fuseTranslationLoader: FuseTranslationLoaderService, private elRef: ElementRef, private store: Store<fromRoot.RootState>) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  onClick(event: MouseEvent, button) {
    if (button.menuActionsOnClick) {
      event.preventDefault();
      this.contextMenuPosition.x = event.clientX + 'px';
      this.contextMenuPosition.y = event.clientY + 'px';
      this.contextMenu.menuData = { button };
      this.contextMenu.openMenu();
    } else if (button?.action === ButtonsGroupActions.fabButtonsToggle) {
      this.fabButtonsToggle();
    } else {
      this.emitEvent.emit(button.action);
    }
  }

  onToggle(event) {
    this.emitEvent.emit(event.value);
  }

  onContextMenu(event: MouseEvent, button) {
    if (button.multipleActions) {
      event.preventDefault();
      this.contextMenuPosition.x = event.clientX + 'px';
      this.contextMenuPosition.y = event.clientY + 'px';
      this.contextMenu.menuData = { button };
      this.contextMenu.openMenu();
    }
  }

  onContextMenuAction(changeTo) {
    const { icon, action, label } = changeTo;
    const button = this.contextMenu.menuData.button;

    if (button.menuActionsOnClick) {
      this.emitEvent.emit(action);
    } else {
      button.icon = icon;
      button.action = action;
      button.label = label;
      button.multipleActions = button.multipleActions.map(multipleAction => {
        return { ...multipleAction, disabled: multipleAction.icon === changeTo.icon };
      });
    }
  }

  ngOnInit() {
    if (this.config?.type === 'matFabToggle') {
      this.store.pipe(select(fromRoot.getRouterTab), takeUntil(this.unsubscribeAll)).subscribe(() => {
        this.resetFabToggle();
      });
    }
  }

  ngAfterViewChecked() {
    if (this.config?.type === 'matFabToggle') {
      if (
        !this.elRef.nativeElement
          .closest(this.cssClassSelectors.fabButtonsWrapper)
          .querySelector(this.cssClassSelectors.fabToggleOpen)
          .classList.contains(this.cssClassSelectors.hide)
      ) {
        this.initFabToggle();
      }
    }
  }

  initFabToggle() {
    const fabButtons = this.elRef.nativeElement
      .closest(this.cssClassSelectors.fabButtonsWrapper)
      .querySelectorAll(`[mat-fab]:not(${this.cssClassSelectors.fabToggleOpen},${this.cssClassSelectors.fabToggleClose})`);
    fabButtons.forEach((btn, index) => {
      if (index > 1) {
        btn?.classList.add(this.cssClassSelectors.hide);
      } else {
        btn?.classList.remove(this.cssClassSelectors.hide);
      }
    });
  }

  fabButtonsToggle() {
    const fabToggleButtons = this.elRef.nativeElement
      .closest(this.cssClassSelectors.fabButtonsWrapper)
      .querySelectorAll(`${this.cssClassSelectors.fabToggleOpen},${this.cssClassSelectors.fabToggleClose}`);
    const fabButtons = this.elRef.nativeElement
      .closest(this.cssClassSelectors.fabButtonsWrapper)
      .querySelectorAll(`[mat-fab]:not(${this.cssClassSelectors.fabToggleOpen},${this.cssClassSelectors.fabToggleClose})`);
    fabToggleButtons.forEach(btn => {
      btn?.classList.toggle(this.cssClassSelectors.hide);
    });
    fabButtons.forEach((btn, index) => {
      if (index > 1) {
        btn?.classList.toggle(this.cssClassSelectors.hide);
      }
    });
  }

  resetFabToggle() {
    if (
      this.elRef.nativeElement
        .closest(this.cssClassSelectors.fabButtonsWrapper)
        .querySelector(this.cssClassSelectors.fabToggleOpen)
        ?.classList.contains(this.cssClassSelectors.hide)
    ) {
      this.initFabToggle();
      const fabToggleButtons = this.elRef.nativeElement
        .closest(this.cssClassSelectors.fabButtonsWrapper)
        .querySelectorAll(`${this.cssClassSelectors.fabToggleOpen},${this.cssClassSelectors.fabToggleClose}`);
      fabToggleButtons.forEach(btn => {
        btn?.classList.toggle(this.cssClassSelectors.hide);
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
