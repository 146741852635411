import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { allNavigation } from 'config/navigation/navigation';
import { forEach, map } from 'lodash';
import { IceRedirectPayload } from 'models/action-payloads/ice-redirect.payload';
import { RouterStateUrl } from 'store/root/reducers';
import { CopyrightUtils } from '../copyright/copyright.utils';

export class RouterUtils {
  static extractActivatedRouterData(snapshot: ActivatedRouteSnapshot): RouterStateUrl {
    let params = {};
    let paths = [];
    let queryParams;
    let section;
    let view;
    while (snapshot.firstChild) {
      params = { ...params, ...snapshot.params };
      queryParams = snapshot.queryParams;
      if (snapshot.url.length) {
        paths = [...paths, ...map(snapshot.url, 'path')];
      }
      snapshot = snapshot.firstChild;
      if (snapshot.data.section) {
        section = snapshot.data.section;
      }
      if (snapshot.data.view) {
        view = snapshot.data.view;
      }
    }

    return { url: paths.join('/'), params, queryParams, section, view, paths };
  }

  static getUrlWithParams(snapshot: ActivatedRouteSnapshot): IceRedirectPayload {
    const { url, queryParams } = RouterUtils.extractActivatedRouterData(snapshot);
    const redirectObject = {
      path: [url],
      extras: { queryParams },
    };
    return redirectObject;
  }

  static getSectionUrl(section): string {
    const sectionData = CopyrightUtils.getSectionData(section);
    if (sectionData) {
      return sectionData.url;
    }
    let url: string;
    forEach(allNavigation, nav => {
      forEach(nav.children, sectionParent =>
        forEach(sectionParent.children, childrenSection => {
          if (childrenSection.id === section) {
            url = ((childrenSection.url || '').match(`\/[^/]*\/[^/]*`) || [''])[0];
          }
        }),
      );
    });
    return url;
  }

  static getSectionPath(section): string[] {
    const url = RouterUtils.getSectionUrl(section);
    return url ? [`${url}`] : [`copyright/${section}`];
  }

  static isNewElementPage(url: string): boolean {
    const patterns = ['/new', '/edit'];
    const match = patterns.find(pattern => url.indexOf(pattern) > -1);

    return !!match;
  }

  static getSectionFromUrl(router: Router) {
    if (router) {
      const paths = router.url.split('/');
      if (paths.length > 2) {
        return paths[2].includes('?') ? paths[2].substring(0, paths[2].indexOf('?')) : paths[2];
      }
    }
    return null;
  }
}
