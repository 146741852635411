<mat-toolbar>

    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.gt-xs="space-between center" fxFlex>


        <div fxLayout="row" fxLayoutAlign="end center" fxHide fxShow.gt-xs>
            <a mat-button href="http://fusetheme.com/angular/docs" target="_blank">Documentation</a>
            <span>&bull;</span>
            <a mat-button href="http://fusetheme.com/angular/changelog" target="_blank">Changelog</a>
        </div>

    </div>

</mat-toolbar>
