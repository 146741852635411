import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import { USER_STATE_INACTIVE } from 'config/constants/users.constants';
import { UserManagementUsersDataTable } from 'config/data-table-builders/user-management-users';
import { SectionsConfig } from 'config/sections-config';
import { isArray } from 'lodash';
import * as fromRoot from 'store/root';
import { SectionTabConfig } from '../../tabs-data';

export class TabOrganizationUsers implements SectionTabConfig {
  private schema: UserManagementUsersDataTable;
  constructor(private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService, private store: Store<fromRoot.RootState>) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.schema = new UserManagementUsersDataTable(this.translate, this.fuseTranslationLoader);
  }

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'dataTable',
            config: {
              data: this.store.pipe(
                select(fromRoot.getUserManagementOrganizationUsers),
                select(users => users.filter(user => user.status !== USER_STATE_INACTIVE)),
              ),
              sorts: [],
              schema: this.schema.getDataTable(),
              loadingIndicator: false,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: true,
              shadowed: false,
              columnMode: 'flex',
              onSelect: event => this.goTo(event),
              onMouseSelect: event => this.goTo(event, true),
              getRowClass: (row: any): any => ({ 'ice-search-results-table-row': true }),
            },
          },
        ],
      },
    ];
  }

  private goTo(event: any, newTap: boolean = false): void {
    const id = isArray(event) ? event[0].id : event.id;
    const path = `user-management/${SectionsConfig.USERS.name}/${id}`;
    if (newTap) {
      this.store.dispatch(new fromRoot.OpenNewTab({ path: [path] }));
    } else {
      this.store.dispatch(new fromRoot.Go({ path: [path] }));
    }
  }
}
