import type { ApiCall } from 'config/sections-config/api-call';
import { PageableData } from 'models/response/cube-register.response';
import type { FileType } from 'services/report/report.service';

export interface ExportMode {
  downloading: boolean;
  visibleColumns?: string[];
  downloadType?: ExportModeType;
  downloadBatch?: Array<any> | PageableData<any>;
  columnLabelDictionary?: { [id: string]: string };
  fileType?: FileType;
  thisIdAsFirstElement?: string;
  apiCall?: ApiCall;
  queueChunckSize?: number;
}

export enum ExportModeType {
  QUEUE = 'Queue',
  BATCH = 'Batch',
}
