import { includes } from 'lodash';
import { FieldValidatorService } from 'services/validators/field.validator.service';
export class AgreementPartiesValidator {
  static validIPKey(fieldValidatorService: FieldValidatorService, control, typeOf, onValidate, onFailValidation, searchByXrefPrefix: string = null) {
    return new Promise((resolve, reject) => (!includes(control.value, ',') ? resolve(true) : reject()))
      .then(res => fieldValidatorService.existIpKey(control, typeOf, onValidate, onFailValidation, searchByXrefPrefix))
      .catch(() => {
        onFailValidation();
        return false;
      });
  }

  static validPartyIPBaseKey(fieldValidatorService: FieldValidatorService, control, typeOf, onValidate, onFailValidation, searchByXrefPrefix: string = null) {
    if (control && control.value) {
      // A partyBase Key it has ns it must be ICE:
      const parts = control.value.split(':');
      if (parts.length > 1) {
        if (parts[0] !== 'ICE') {
          onFailValidation();
          return false;
        }
      }
      return new Promise((resolve, reject) => (!includes(control.value, ',') ? resolve(true) : reject())).then(res =>
        fieldValidatorService.existIPBaseKey(control, onValidate).catch(() => {
          onFailValidation();
          return false;
        }),
      );
    } else {
      onFailValidation();
      return false;
    }
  }
}
