import { AGREEMENTS_CONSTANTS } from 'config/constants/agreements.constants';
import { IPI } from 'config/constants/ips.constants';
import { StepType } from 'config/stepper-builders/stepper-config';
import { get, startsWith } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FormControl } from '@angular/forms';
import { IpUtils } from '@ice';
import { FormlyValidatorUtils } from '@ice/utils/formly/formly-validators.utils';

import { SelectOptions } from '../stepper-select-options';

export class CoreAgreementStep {
  unsubscribeAll = new Subject();

  getStep(translate, fieldValidatorService): StepType {
    const options = SelectOptions.getOptions(translate);
    let isAssignorGenericCreator = false;
    return {
      label: translate.instant('AGREEMENTS.CORE_AGREEMENT.TITLE'),
      formBuilder: [
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-2 ipi-name-number-input',
              wrappers: ['wrapper-info'],
              templateOptions: {
                infoText: translate.instant('AGREEMENTS.CORE_AGREEMENT.IPI_NUMBER.INFO_TEXT'),
              },
              fieldGroup: [
                {
                  key: 'ipi_name_number',
                  wrappers: ['form-field', 'wrapper-input-text'],
                  type: 'input',
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  templateOptions: {
                    attributes: { 'data-testid': 'input-ipi_name_number' },
                    required: true,
                    label: translate.instant('AGREEMENTS.CORE_AGREEMENT.IPI_NUMBER.NAME'),
                  },
                  validators: {
                    onlyIPIValidator: {
                      expression: (control: FormControl) => !control.value || control.value.split(':').length === 1 || startsWith(control.value, 'IPI:'),
                      message: translate.instant('AGREEMENTS.CORE_AGREEMENT.IPI_NUMBER.NOT_IPI_PREFIX'),
                    },
                  },
                  asyncValidators: {
                    ipRefValidation: FormlyValidatorUtils.getReferenceValidator(translate, 'COMMON.ERRORS.INVALID_REFERENCE'),
                    ipiNumberValid: {
                      expression: (control, field) => fieldValidatorService.useValidatorOnTouch(field) || this.ipValidation(control, field, fieldValidatorService),
                      message: translate.instant('AGREEMENTS.CORE_AGREEMENT.IPI_NUMBER.NOT_VALID'),
                    },
                  },
                  hooks: {
                    onInit: field => {
                      if (field && field.formControl) {
                        field.formControl.setValue(field.formControl?.value?.toString(), { emitEvent: false });
                        field.formControl.markAsTouched();
                        field.formControl.markAsDirty();
                      }
                    },
                    afterViewInit: field => {
                      field.formControl.updateValueAndValidity(); // Ensure validation after view initialization
                    },
                  },
                },
                { key: 'submitter_prefix' },
                { key: 'submitter_masked_id' },
              ],
            },
            {
              className: 'flex-2',
              wrappers: ['wrapper-info'],
              templateOptions: {
                infoText: translate.instant('AGREEMENTS.CORE_AGREEMENT.TYPE_OF_AGREEMENT.INFO_TEXT'),
              },
              fieldGroup: [
                {
                  key: 'type_of_agreement',
                  type: 'select',
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  templateOptions: {
                    attributes: { 'data-testid': 'select-type_of_agreement' },
                    required: true,
                    label: translate.instant('AGREEMENTS.CORE_AGREEMENT.TYPE_OF_AGREEMENT.NAME'),
                    options: options.coreAgreements.type_of_agreement,
                  },
                  validators: {
                    validateAssignor: {
                      expression: (control: FormControl) => {
                        return !(isAssignorGenericCreator && control.value === AGREEMENTS_CONSTANTS.GENERAL);
                      },
                      message: translate.instant('AGREEMENTS.CORE_AGREEMENT.TYPE_OF_AGREEMENT.ERROR'),
                    },
                  },
                  hooks: {
                    onInit: field => {
                      fieldValidatorService.isAssignorGenericCreator.pipe(takeUntil(this.unsubscribeAll)).subscribe(value => {
                        isAssignorGenericCreator = value;
                        field.formControl.updateValueAndValidity();
                      });
                    },
                  },
                },
              ],
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-2',
              wrappers: ['wrapper-info'],
              templateOptions: {
                infoText: translate.instant('AGREEMENTS.CORE_AGREEMENT.SALES_MANUFACTURE.INFO_TEXT'),
              },
              fieldGroup: [
                {
                  className: 'flex-1',
                  key: 'sales_manufacture',
                  type: 'select',
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  templateOptions: {
                    attributes: { 'data-testid': 'select-sales_manufacture' },
                    required: true,
                    label: translate.instant('AGREEMENTS.CORE_AGREEMENT.SALES_MANUFACTURE.NAME'),
                    options: options.coreAgreements.sales_manufacture,
                  },
                },
              ],
            },
            {
              className: 'flex-2',
              wrappers: ['wrapper-info'],
              hideExpression: 'model.type_of_agreement != "G"',
              templateOptions: {
                infoText: translate.instant('AGREEMENTS.CORE_AGREEMENT.TRANSFER_WORKS.INFO_TEXT'),
              },
              fieldGroup: [
                {
                  className: 'flex-2',
                  key: 'transfer_works',
                  type: 'select',
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  templateOptions: {
                    required: true,
                    label: translate.instant('AGREEMENTS.CORE_AGREEMENT.TRANSFER_WORKS.NAME'),
                    options: options.coreAgreements.transfer_works,
                  },
                },
              ],
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-2',
              wrappers: ['wrapper-info'],
              templateOptions: {
                infoText: translate.instant('AGREEMENTS.CORE_AGREEMENT.SHARE_IN_OVERRIDE.INFO_TEXT'),
              },
              fieldGroup: [
                {
                  className: 'flex-2',
                  key: 'share_in_op_assignor_only',
                  type: 'select',
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  templateOptions: {
                    attributes: { 'data-testid': 'select-share_in_override' },
                    required: true,
                    label: translate.instant('AGREEMENTS.CORE_AGREEMENT.SHARE_IN_OVERRIDE.NAME'),
                    options: options.coreAgreements.share_in_override,
                  },
                },
              ],
            },
            {
              className: 'flex-2',
            },
          ],
        },
      ],
      onDestroy: () => {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
      },
    };
  }

  ipValidation(control, field, fieldValidatorService) {
    return fieldValidatorService.searchPartyFieldValidator(
      (partyId, partyNameId, name, partyName) => {
        if (get(partyName, 'attributes.publisher.prefix', null)) {
          control.parent.get('submitter_prefix').setValue(partyName.attributes.publisher.prefix);
        }
        if (get(partyName, 'relations', null)) {
          const key = IpUtils.selectIpsKey(partyName.relations, null).replace('ICE:', '');
          control.parent.get('submitter_masked_id').setValue(key);
        }
      },
      control,
      field,
      {
        key: 'xref',
        value: control.value,
        customMaskedIdsConfig: [{ source: "$['and'][*]['equals']['relations[XREF].otherId']", type: 'party-name' }],
      },
      IPI,
    );
  }
}
