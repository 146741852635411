import { IceMaskedIdsConfig } from 'services/masked-ids/masked-ids.model';

export const worksSearchMask: IceMaskedIdsConfig[] = [
  { source: "$['and'][*]['equals']['relations[XREF].otherId']", type: 'work' },
  { source: "$['and'][*]['wildcard']['contributions.contributor.partyName.relations[XREF].otherId']", type: 'party-name' },
  { source: "$['and'][*]['or'][*]['equals']['relations[XREF].otherId']", type: 'agreement' },
];
export const agreementsSearchMask: IceMaskedIdsConfig[] = [{ source: "$['and'][*]['or'][*]['equals']['relations[XREF].otherId']", type: 'agreement' }];
export const ipsSearchMask: IceMaskedIdsConfig[] = [{ source: "$['and'][*]['equals']['parties.party.relations[XREF].otherId']", type: 'party' }];
export const societiesSearchMask: IceMaskedIdsConfig[] = [{ source: "$['and'][*]['wildcard']['attributes.id']", type: 'society' }];
export const repertoiresSearchMask: IceMaskedIdsConfig[] = [
  { source: "$['and'][*]['equals']['relations[XREF].otherId']", type: 'repertoire' },
  { source: "$['and'][*]['equals']['parties.party.partyNames.partyName.relations[XREF].otherId']", type: 'party-name' },
];
