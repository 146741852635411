import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CopyrightUtils } from '@ice';
import { InputFileType } from '@ice/components/input-upload-file/input-upload-file.model';
import { DateTimeUtils } from '@ice/utils/date-time/date-time.utils';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { StepType } from 'config/stepper-builders/stepper-config';
import moment from 'moment';
import { BehaviorSubject, of } from 'rxjs';
import { take } from 'rxjs/operators';
import * as fromRoot from 'store/root';

export class CounterclaimNotificationsUploadDocumentationStep {
  private uploadFileField: FormlyFieldConfig;
  private showWarning: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private fileDetails: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  constructor(private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService, private store: Store<any>) {}

  getStep(): StepType {
    return {
      label: this.translate.instant('COUNTERCLAIM-NOTIFICATIONS.UPLOAD_DOCUMENTATION.TITLE'),
      formBuilder: [
        {
          fieldGroupClassName: 'display-flex',
          hideExpression: false,
          fieldGroup: [
            {
              key: 'orgId',
              hooks: {
                onInit: field => {
                  this.store
                    .select(fromRoot.getUserCurrentOrganization)
                    .pipe(take(1))
                    .subscribe(org => field.formControl.setValue(org.id));
                },
              },
            },
            {
              className: 'flex-1',
              key: 'ccNotificationsUploadData',
              type: 'datatable-editable',
              wrappers: ['wrapper-card'],
              templateOptions: {
                required: true,
                cardTitle: this.translate.instant('COUNTERCLAIM-NOTIFICATIONS.UPLOAD_DOCUMENTATION.DOCUMENTS.TITLE'),
                config: {
                  data: this.fileDetails,
                  columnMode: ColumnMode.flex,
                  selectionType: SelectionType.checkbox,
                  schema: [
                    {
                      name: this.translate.instant('COUNTERCLAIM-NOTIFICATIONS.UPLOAD_DOCUMENTATION.DOCUMENTS.DATE_TIME'),
                      prop: 'dateTime',
                      flexGrow: 1.5,
                    },
                    {
                      name: this.translate.instant('COUNTERCLAIM-NOTIFICATIONS.UPLOAD_DOCUMENTATION.DOCUMENTS.FILE_NAME'),
                      prop: 'filename',
                      flexGrow: 2,
                    },
                    {
                      name: '',
                      actionButtonIcon: 'delete',
                      prop: 'delete',
                      flexGrow: 0.001,
                      maxWidth: 50,
                      minWidth: 50,
                      resizeable: false,
                      action: row => {
                        this.onEmptyFileUpload();
                      },
                    },
                  ],
                },
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex ice-mb-40',
          fieldGroup: [
            {
              className: 'flex-1',
              key: 'files',
              type: 'upload-file',
              templateOptions: {
                fileType: InputFileType.CSV,
                showWarning: this.showWarning,
                warningMessage: of(this.translate.instant('COUNTERCLAIM-NOTIFICATIONS.UPLOAD_DOCUMENTATION.NO_CONTENT')),
                label: this.translate.instant('COUNTERCLAIM-NOTIFICATIONS.UPLOAD_DOCUMENTATION.DRAG_DROP_TEXT'),
                customAddFile: file => {
                  this.showWarning.next(false);
                  const reader = new FileReader();
                  reader.onload = e => {
                    const csvData = reader.result as string;
                    this.onFileUpload(csvData, file.name);
                  };
                  reader.readAsText(file);
                },
              },
              hooks: {
                onInit: field => (this.uploadFileField = field),
              },
            },
          ],
        },
        {
          wrappers: ['wrapper-card'],
          templateOptions: { cardTitle: this.translate.instant('COUNTERCLAIM-NOTIFICATIONS.UPLOAD_DOCUMENTATION.CSV_REQUIRED_FORMAT') },
          fieldGroup: [
            {
              template: `<p>${this.translate.instant('COUNTERCLAIM-NOTIFICATIONS.UPLOAD_DOCUMENTATION.CSV_REQUIRED_FORMAT_DESCRIPTION')}</br></br>
              ${this.translate.instant('COUNTERCLAIM-NOTIFICATIONS.UPLOAD_DOCUMENTATION.CSV_REQUIRED_FORMAT_DESCRIPTION_TEXT1')}<br>
              ${this.translate.instant('COUNTERCLAIM-NOTIFICATIONS.UPLOAD_DOCUMENTATION.CSV_REQUIRED_FORMAT_DESCRIPTION_TEXT2')}</p>`,
            },
          ],
        },
      ],
    };
  }

  onFileUpload(csvData: string, filename: string) {
    if (csvData?.length) {
      const parsedCsvData: string[][] = CopyrightUtils.parseCsvData(csvData);
      const list = parsedCsvData
        .map(cols => ({ ipi_no: cols[0], email: cols[1] }))
        .slice(1)
        .filter(row => row?.ipi_no && row?.email);
      if (list.length) {
        this.uploadFileField.parent.form.get('ccNotificationsUploadData').setValue({
          filename,
          list,
        });
        this.fileDetails.next([
          {
            filename,
            dateTime: DateTimeUtils.formatDate(moment(), 'YYYY-MM-DD'),
          },
        ]);
        this.uploadFileField.parent.form.get('files').markAsTouched();
        return;
      }
    }
    this.onEmptyFileUpload();
  }

  onEmptyFileUpload() {
    this.showWarning.next(true);
    this.fileDetails.next([]);
    this.uploadFileField.parent.form.get('ccNotificationsUploadData').setValue(null);
  }
}
