import { RepertoireDetail, RepertoireUtils } from '@ice';
import { createSelector } from '@ngrx/store';
import * as fromFeature from 'store/root/reducers';
import * as fromReducer from 'store/root/reducers/copyright/copyright.reducer';

export const getCopyrightRepertoire = createSelector(fromFeature.getCopyrightFeatureState, fromReducer.getCopyrightRepertoireState);

export const getRepertoireDetail = createSelector(getCopyrightRepertoire, (detail: RepertoireDetail) => detail || {});

export const getRepertoireAttributes = createSelector(getCopyrightRepertoire, (detail: RepertoireDetail) => (detail && detail.attributes) || []);

export const getRepertoireCreatorAffiliations = createSelector(getCopyrightRepertoire, (detail: RepertoireDetail) => (detail && detail.societies) || []);

export const getRepertoireAdditionalCollectors = createSelector(
  getCopyrightRepertoire,
  (detail: RepertoireDetail) => (detail && detail.additionalCollectors && RepertoireUtils.formatRepertoireParties(detail.additionalCollectors)) || [],
);

export const getRepertoireContractingCompanies = createSelector(
  getCopyrightRepertoire,
  (detail: RepertoireDetail) => (detail && detail.contractingCompanies && RepertoireUtils.formatRepertoireParties(detail.contractingCompanies)) || [],
);

export const getRepertoireRights = createSelector(getCopyrightRepertoire, (detail: RepertoireDetail) =>
  detail && detail.attributes && detail.attributes.rights ? detail.attributes.rights : [],
);

export const getRepertoireExcludedAgreements = createSelector(getCopyrightRepertoire, (detail: RepertoireDetail) =>
  detail && detail.excludedAgreements ? detail.excludedAgreements.items : [],
);

export const getRepertoireExcludedWorks = createSelector(getCopyrightRepertoire, (detail: RepertoireDetail) => (detail && detail.excludedWorks ? detail.excludedWorks.items : []));

export const getRepertoireIncludedWorks = createSelector(getCopyrightRepertoire, (detail: RepertoireDetail) => (detail && detail.includedWorks ? detail.includedWorks.items : []));

export const getRepertoireAuditHistory = createSelector(getCopyrightRepertoire, (detail: RepertoireDetail) => (detail && detail.auditHistory ? detail.auditHistory.items : []));
