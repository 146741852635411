import { IpDetail, IpDetailCleaned } from '@ice';
import { createSelector } from '@ngrx/store';
import * as fromFeature from 'store/root/reducers';
import * as fromReducer from 'store/root/reducers/copyright/copyright.reducer';

export const getCopyrightIp = createSelector(fromFeature.getCopyrightFeatureState, fromReducer.getCopyrightIpState);

export const getIpDetailAgreements = createSelector(getCopyrightIp, (detail: IpDetailCleaned) => detail?.agreements?.items || []);

export const getIpDetailAgreementsCount = createSelector(getCopyrightIp, (detail: IpDetailCleaned) => detail?.agreements?.total || 0);

export const getIpDetailAgreementChains = createSelector(getCopyrightIp, (detail: IpDetailCleaned) => {
  return detail?.agreementChains;
});
export const getIpDetailAgreementChainsInboxItems = createSelector(getCopyrightIp, (detail: IpDetailCleaned) => {
  return detail?.agreementChains?.inbox.items || [];
});
export const getIpDetailAgreementChainsTable = createSelector(getCopyrightIp, (detail: IpDetailCleaned) => {
  return detail?.agreementChains?.tableData || [];
});

export const getIpDetailWorks = createSelector(getCopyrightIp, (detail: IpDetailCleaned) => detail?.works?.items || []);

export const getIpDetailWorksCount = createSelector(getCopyrightIp, (detail: IpDetailCleaned) => detail?.works?.total || 0);

export const getIpDetailNames = createSelector(getCopyrightIp, (detail: IpDetailCleaned) => (detail && detail.partyNames) || []);

export const getIpBaseInformation = createSelector(getCopyrightIp, (detail: IpDetailCleaned) => (detail && detail.baseInformation) || []);

export const getIpBaseParty = createSelector(getCopyrightIp, (detail: IpDetailCleaned) => detail && detail?.baseParty);

export const getIpSocieties = createSelector(getCopyrightIp, (detail: IpDetailCleaned) => (detail && detail.societies) || []);

export const getPartyRelationsSocieties = createSelector(getCopyrightIp, (detail: IpDetailCleaned) => (detail && detail.partyRelationsSocieties) || []);

export const getPartyRelationsPublishers = createSelector(getCopyrightIp, (detail: IpDetailCleaned) => (detail && detail.partyRelationsPublishers) || []);

export const getIpAlternativeNames = createSelector(getCopyrightIp, (detail: IpDetail) => (detail && detail.attributes && detail.attributes.names) || []);

export const getIpClauses = createSelector(getCopyrightIp, (detail: IpDetail) => (detail && detail.attributes && detail.attributes.clauses) || []);

export const getWorkClauses = createSelector(getCopyrightIp, (detail: IpDetail) => (detail && detail.workClauses && detail.workClauses.items) || []);

export const getIpLinkedWriters = createSelector(getCopyrightIp, (detail: IpDetail) => (detail && detail.linkedWriters) || []);

export const getIpAuditHistory = createSelector(getCopyrightIp, (detail: IpDetail) => (detail && detail.auditHistory ? detail.auditHistory.items : []));

export const getIpLinkedPublishers = createSelector(getCopyrightIp, (detail: IpDetail) => (detail && detail.linkedPublishers) || []);

export const getParentRelationsSearchResults = createSelector(getCopyrightIp, (detail: IpDetail) => detail?.parentRelationshipsData?.items || []);

export const getChildrenRelationsSearchResults = createSelector(getCopyrightIp, (detail: IpDetail) => detail?.childrenRelationshipsData?.items || []);

export const getSectionSearch = createSelector(getCopyrightIp, (detail: IpDetail) => (detail && detail.relationsSearch ? detail.relationsSearch : []));

export const getSectionSearchResults = createSelector(getCopyrightIp, (detail: IpDetail) => {
  return detail && detail.relationsSearchResult ? detail.relationsSearchResult : [];
});

export const getSectionSearchResultsIsLoading = createSelector(getCopyrightIp, (detail: IpDetail) => {
  if (detail && detail.relationsSearch) {
    return !detail.relationsSearchResult;
  }
  return false;
});

export const getWorksCount = createSelector(getCopyrightIp, (detail: IpDetail) => (detail && detail?.workCount) || 0);

export const getLinkedWritersCount = createSelector(getCopyrightIp, (detail: IpDetail) => (detail && detail?.linkedWritersCount) || 0);

export const getLinkedPublishersCount = createSelector(getCopyrightIp, (detail: IpDetail) => (detail && detail?.linkedPublishersCount) || 0);

export const getworkClausesCount = createSelector(getCopyrightIp, (detail: IpDetail) => (detail && detail?.workClausesCount) || 0);

export const getMergingIP = createSelector(getCopyrightIp, (detail: IpDetail) => detail?.mergingIP);

export const getTransferIpWorksInfo = createSelector(getCopyrightIp, (detail: IpDetail) => detail?.transferIpWorksInfo);
