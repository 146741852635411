import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProgressBar } from './progress-bar';

@Component({
  selector: 'ice-progress-bar-component',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent {
  @Input() config: ProgressBar;
}
