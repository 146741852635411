import { NEW_IP_ASSIGNEE, NEW_IP_ASSIGNOR, NEW_IP_CLAIMANT, NEW_IP_PUBLISHER } from 'config/constants/ips.constants';
import { clone, get, map, remove, uniqBy } from 'lodash';
import moment from 'moment';
import * as fromActions from '../actions';
import * as fromState from '../state';

export function reducer(state: fromState.NewSectionItemState = fromState.initialNewSectionItemState, action: fromActions.NewSectionItemActions): fromState.NewSectionItemState {
  switch (action.type) {
    case fromActions.SAVE_FIELD: {
      const { payload, noRemoveOldFields } = action;
      let fields = payload;
      map(payload, (value, key) => {
        if (value instanceof moment) {
          // TS Complier complaines when (value as any) isn't set
          fields = { ...fields, [key]: (value as any).format('YYYY MM DD').replace(/\s+/g, '-') };
        }
      });
      return noRemoveOldFields ? { ...state, fields: { ...state.fields, ...fields } } : { ...state, fields };
    }
    case fromActions.SET_NEW_SECTION_LOADING: {
      return { ...state, loading: action.payload };
    }
    case fromActions.SET_CLAIMANT_GROUPS:
      return { ...state, claimantGroups: action.payload };
    case fromActions.SAVE_NEW_SECTION_ITEM:
      return { ...state, loading: true };
    case fromActions.SAVE_NEW_SECTION_ITEM_SUCCESS:
    case fromActions.SAVE_NEW_SECTION_ITEM_FAIL:
      return { ...state, loading: false, response: action.payload };
    case fromActions.RESET_NEW_SECTION_ITEM: {
      return { ...state, ...fromState.initialNewSectionItemState, loading: false, response: null };
    }
    case fromActions.SET_IS_VALID_AN_CHANGED_FORM: {
      return { ...state, isChanged: true, isValid: action.payload };
    }
    case fromActions.MARK_IS_CLONING: {
      return { ...state, isCloning: action.payload };
    }
    case fromActions.SET_IS_CHANGED: {
      return { ...state, isChanged: action.payload };
    }
    case fromActions.SET_INITIAL_FIELDS: {
      let initialFields = action.payload;
      map(action.payload, (value, key) => {
        if (value instanceof moment) {
          // TS Complier complaines when (value as any) isn't set
          initialFields = { ...initialFields, [key]: (value as any).format('YYYY MM DD').replace(/\s+/g, '-') };
        }
      });
      return { ...state, initialFields };
    }
    case fromActions.SET_FIELDS: {
      let fields = action.payload;
      map(action.payload, (value, key) => {
        if (value instanceof moment) {
          // TS Complier complaines when (value as any) isn't set
          fields = { ...fields, [key]: (value as any).format('YYYY MM DD').replace(/\s+/g, '-') };
        }
      });
      return { ...state, fields };
    }
    case fromActions.NEW_ITEM_CLEAN_SEARCH: {
      return { ...state, search: {}, searchResult: {} };
    }
    case fromActions.NEW_ITEM_DO_SEARCH: {
      const type = action && action.payload && action.payload.type;
      if (type && action.payload.search) {
        const search = {};
        search[type] = action.payload.search;
        return { ...state, loading: !(state.searchResult && state.searchResult[type]) || action.payload.reset, search };
      }
      return { ...state };
    }
    case fromActions.NEW_ITEM_DO_SEARCH_SUCCESS: {
      const type = action && action.payload && action.payload.extra && action.payload.extra.type;
      const reset = action && action.payload && action.payload.extra && action.payload.extra.reset;
      if (type) {
        const searchResult = clone(state.searchResult);
        if (searchResult) {
          searchResult[type] = searchResult[type] && !reset ? { ...searchResult[type], items: [...searchResult[type].items, ...action.payload.items] } : action.payload;
        }
        return { ...state, loading: false, searchResult };
      }
      return { ...state };
    }
    case fromActions.NEW_ITEM_DO_SEARCH_FAIL: {
      return { ...state, loading: false };
    }
    case fromActions.NEW_ITEM_RESET_RESPONSE:
      return { ...state, response: null };
    case fromActions.DUPLICATE_NEW_ITEM: {
      return { ...state, response: null, isValid: false, isLastActionClose: false };
    }
    case fromActions.NEW_ITEM_CLOSE_POPUP: {
      return { ...state, isLastActionClose: true };
    }
    case fromActions.MARK_POPUP_OK: {
      return { ...state, isPopupOkButtonMarked: true };
    }
    case fromActions.UNMARK_POPUP_OK: {
      return { ...state, isPopupOkButtonMarked: false };
    }
    // NEW IP CREATION FROM NEW/UPDATE AGREEMENT
    case fromActions.SET_CURRENT_NEW_IP: {
      const currentNewIP = state.newIp;
      const newIp = { ...currentNewIP, current: action.payload, error: null, isLoading: false };
      return { ...state, newIp };
    }
    case fromActions.PUT_NEW_IP: {
      const currentNewIP = state.newIp;
      let newIp = null;
      switch (currentNewIP.current) {
        case NEW_IP_ASSIGNOR:
          newIp = { ...currentNewIP, assignor: null, assignorFields: action.payload, error: null, isLoading: true };
          break;
        case NEW_IP_ASSIGNEE:
          newIp = { ...currentNewIP, assignee: null, assigneeFields: action.payload, error: null, isLoading: true };
          break;
        case NEW_IP_CLAIMANT:
          newIp = { ...currentNewIP, claimant: null, claimantFields: action.payload, error: null, isLoading: true };
          break;
        case NEW_IP_PUBLISHER:
          newIp = { ...currentNewIP, publisher: null, publisherFields: action.payload, error: null, isLoading: true };
          break;
      }
      return { ...state, newIp };
    }
    case fromActions.PUT_NEW_IP_SUCCESS: {
      const currentNewIP = state.newIp;
      let newIp = null;
      switch (currentNewIP.current) {
        case NEW_IP_ASSIGNOR:
          newIp = { ...currentNewIP, assignor: action.payload, error: null, isLoading: false };
          break;
        case NEW_IP_ASSIGNEE:
          newIp = { ...currentNewIP, assignee: action.payload, error: null, isLoading: false };
          break;
        case NEW_IP_CLAIMANT:
          newIp = { ...currentNewIP, claimant: action.payload, error: null, isLoading: false };
          break;
        case NEW_IP_PUBLISHER:
          newIp = { ...currentNewIP, publisher: action.payload, error: null, isLoading: false };
          break;
      }
      return { ...state, newIp };
    }
    case fromActions.PUT_NEW_IP_FAIL: {
      const currentNewIP = state.newIp;
      let newIp = null;
      switch (currentNewIP.current) {
        case NEW_IP_ASSIGNOR:
          newIp = { ...currentNewIP, assignor: null, error: action.payload, isLoading: false };
          break;
        case NEW_IP_ASSIGNEE:
          newIp = { ...currentNewIP, assignee: null, error: action.payload, isLoading: false };
          break;
        case NEW_IP_CLAIMANT:
          newIp = { ...currentNewIP, claimant: null, error: action.payload, isLoading: false };
          break;
        case NEW_IP_PUBLISHER:
          newIp = { ...currentNewIP, publisher: null, error: action.payload, isLoading: false };
          break;
      }
      return { ...state, newIp };
    }
    case fromActions.NEW_ITEM_SELECT_SHAREPIC_IPS: {
      return { ...state, counterClaimIps: action.payload, fields: { ...state.fields, parties: action.payload } };
    }
    case fromActions.NEW_ITEM_UNSELECT_SHAREPIC_IPS: {
      remove(state.counterClaimIps, ipi => ipi.ipiNameKey === action.payload.ipiNameKey);
      return { ...state, counterClaimIps: [...state.counterClaimIps] };
    }

    case fromActions.NEW_ITEM_DIALOG_ADD_IP_SELECTION: {
      const dialogSelectedcounterClaimIps = state.dialogSelectedcounterClaimIps ? uniqBy([action.payload, ...state.dialogSelectedcounterClaimIps], 'ipiNameKey') : [action.payload];
      return { ...state, dialogSelectedcounterClaimIps };
    }

    case fromActions.NEW_ITEM_DIALOG_REMOVE_IP_SELECTION: {
      remove(state.dialogSelectedcounterClaimIps, ipi => ipi.ipiNameKey === action.payload.ipiNameKey);
      return { ...state, dialogSelectedcounterClaimIps: [...state.dialogSelectedcounterClaimIps] };
    }

    case fromActions.NEW_ITEM_DIALOG_PRE_SELECT_SHAREPIC_IPS: {
      return { ...state, dialogSelectedcounterClaimIps: action.payload };
    }

    case fromActions.UPDATE_COUNTER_CLAIM_PARTIES_STATUS: {
      const parties = state.counterClaimIps;
      const partyToUpdate = action.payload;
      const newParties = parties.map(party => {
        if (party.id === partyToUpdate['id']) {
          party.claimantStatus = partyToUpdate['claimantStatus'];
        }
        return party;
      });
      return { ...state, counterClaimIps: newParties };
    }
    case fromActions.NEW_ITEM_RESET_SEARCH_RESULTS: {
      const searchType = get(action, 'payload.type', null);
      const search = { ...state.search };
      const searchResult = { ...state.searchResult };
      if (searchType) {
        delete search[searchType];
        delete searchResult[searchType];
      }

      return {
        ...state,
        search,
        searchResult,
      };
    }
  }
  return state;
}

export const getNewSectionFieldsState = (state: fromState.NewSectionItemState) => state.fields;
export const getNewSectionValidFormState = (state: fromState.NewSectionItemState) => state.validForm;
export const getNewSectionSearch = (state: fromState.NewSectionItemState) => state.search;
export const getNewSectionSearchResult = (state: fromState.NewSectionItemState) => state.searchResult;
export const getNewSectionValidOrChangedFormState = (state: fromState.NewSectionItemState) => state.isValid;
export const getNewSectionLoading = (state: fromState.NewSectionItemState) => state.loading;
export const getNewSectionResponse = (state: fromState.NewSectionItemState) => state.response;
export const getNewSectionIsLastActionClose = (state: fromState.NewSectionItemState) => state.isLastActionClose;
export const getNewSectionIsPopupOkButtonMarked = (state: fromState.NewSectionItemState) => state.isPopupOkButtonMarked;
export const getNewSectionInitialFields = (state: fromState.NewSectionItemState) => state.initialFields;
export const getNewSectionNewIp = (state: fromState.NewSectionItemState) => state.newIp;
