import { FetchApiCallUtils } from '@ice';
import { SectionsConfig } from 'config/sections-config';
import { ApiCall, ApiCallConfig } from 'config/sections-config/api-call';
import { get } from 'lodash';
import { createApiCallFromBase, setLabelResolver } from '../api-call.factory';

const workSection = SectionsConfig.WORKS.name;

export const getWorkRelationsRepeater = (apiCall: ApiCall, detail: any): ApiCallConfig[] =>
  FetchApiCallUtils.groupIdsByNs(
    get(detail, `relations`, [])
      .filter(relation => relation.relation === 'MTCH')
      .map(relation => relation.otherId),
  ).map(([ns, relationIdList]) =>
    createApiCallFromBase(
      apiCall,
      setLabelResolver(workSection, { ns: () => ns, pattern: () => relationIdList.map(relationId => `{ "equals": {"id":"${relationId}"} }`).join(',') }),
    ),
  );
