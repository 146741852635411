import { FormControl, FormGroup } from '@angular/forms';
import { DateTimeUtils, IpUtils, PayloadSharePicture, TerritoryUtils, WorkUtils } from '@ice';
import { DatepickerUtils } from '@ice/utils/datepicker/datepicker.utils';
import { LocalStorageUtils } from '@ice/utils/local-storage/localstorage.utils';
import { Store, select } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivityType, COMPARE_CASES_DEFAULT_FILTER_TERRITORY } from 'config/constants/activity.constants';
import { cloneDeep, concat, get, remove } from 'lodash';
import moment from 'moment';
import { filter, map, take } from 'rxjs/operators';
import * as fromRoot from 'store/root';
import { SocietiesUtils } from './../../@ice/utils/societies/societies.utils';
import {
  DEFAULT_DISTRIBUTION_SOCIETY_MR,
  DEFAULT_DISTRIBUTION_SOCIETY_PR,
  DEFAULT_FILTER_TERRITORY,
  DEFAULT_SHARES_FILTER_FORM,
  DIST_SOCIETY_GEMA,
  GEMA,
} from './../constants/shares.constants';

export class SharesFilterConfig {
  static getFormInlineWithoutRepertoire(store: Store<fromRoot.RootState>, translate: TranslateService, ns: string, compare?: boolean, activityType?: string) {
    const form = this.getFormInlineForRepertoire(store, translate, ns, compare, activityType, false);
    if (form[0]?.fieldGroup) {
      form[0].fieldGroup = remove(form[0]?.fieldGroup, field => field.key !== 'repertoire');
    }
    return form;
  }

  static getFormInlineForRepertoire(
    store: Store<fromRoot.RootState>,
    translate: TranslateService,
    ns: string,
    compare?: boolean,
    activityType?: string,
    isRepertoire: boolean = true,
  ) {
    const today = DateTimeUtils.formatDate(moment());
    const SHARES_MIN_DATE = new Date('1999/12/31').toISOString();
    let oldUsageDate: moment.Moment;
    let oldDistributionDate: moment.Moment;
    let formData: FormGroup;
    let territoryField;

    const firstLineCommonFilter: FormlyFieldConfig = {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          className: 'flex-1 flex-grow-5',
          key: 'territory',
          type: 'ice-autocomplete-grouped',
          defaultValue: !compare ? DEFAULT_FILTER_TERRITORY : COMPARE_CASES_DEFAULT_FILTER_TERRITORY,
          templateOptions: {
            placeholder: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.FIELD_TERRITORY'),
            options: [],
            required: true,
            useCountryShortcuts: true,
            panelWidth: 'auto',
          },
          hooks: {
            onInit: field => {
              territoryField = field;
              store.pipe(select(fromRoot.getAllTerritories)).subscribe(territories => {
                if (territories && territoryField.templateOptions.options.length === 0) {
                  territoryField.templateOptions.options.push(...TerritoryUtils.getCountriesOptions(territories));
                  territoryField.formControl.setValue(territoryField.formControl.value);
                }
              });
            },
          },
        },
        {
          className: 'flex-1',
          key: 'prRightType',
          type: 'ice-select',
          defaultValue: !compare ? 'PR' : 'ALL',
          templateOptions: {
            label: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.FIELD_PR_RIGHTS'),
            options: IpUtils.getPrOptions(!compare ? [] : [{ value: 'ALL', label: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.ALL') }]),
            required: true,
            selectedLabelsAsValue: true,
            disableOptionCentering: true,
          },
        },
        {
          className: 'flex-1',
          key: 'mrRightType',
          type: 'ice-select',
          defaultValue: !compare ? 'MR' : 'ALL',
          templateOptions: {
            label: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.FIELD_MR_RIGHTS'),
            options: IpUtils.getMrOptions(!compare ? [] : [{ value: 'ALL', label: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.ALL') }]),
            required: true,
            selectedLabelsAsValue: true,
            disableOptionCentering: true,
          },
        },
        DatepickerUtils.getDatepickerField({
          name: 'usageDate',
          label: translate.instant('WORKS.SHARE_PICTURE.FILTER.FIELD_USAGE_DATE'),
          datepickerOptions: {
            filter: (date: Date) => moment(date).isAfter(SHARES_MIN_DATE),
          },
          extraValidators: {
            dateAfter: {
              expression: (control: FormControl) => moment(control.value).isAfter(SHARES_MIN_DATE),
              message: translate.instant('AGREEMENTS.ERRORS.DATE_AFTER_1999_12_31'),
            },
            distributionDateAfterUsageDate: {
              expression: (control: FormControl) =>
                moment(control?.parent?.get('distributionDate')?.value).isAfter(control.value) || moment(control?.parent?.get('distributionDate')?.value).isSame(control.value),
              message: translate.instant('AGREEMENTS.ERRORS.DISTRIBUTION_DATE_AFTER_USAGE_DATE'),
            },
          },
          required: true,
          translate,
          defaultValue: moment(today),
          infoText: translate.instant('WORKS.SHARE_PICTURE.FILTER.FIELD_USAGE_DATE_INFO'),
          hooks: {
            onInit: field => {
              const form = field.form;
              form
                ?.get('distributionDate')
                ?.valueChanges.pipe(filter(date => date && moment.isMoment(date) && oldDistributionDate !== date))
                .subscribe(date => {
                  oldDistributionDate = date;
                  field.formControl.markAsTouched();
                  field.formControl.setValue(field.formControl.value);
                });
            },
          },
        }),
      ],
    };

    const rolesField = [
      {
        className: 'flex-1 ice-mr-20',
        key: 'removeE',
        type: 'checkbox',
        defaultValue: false,
        templateOptions: {
          label: translate.instant('WORKS.SHARE_PICTURE.FILTER.NO_E'),
          required: false,
          change: (field, $event) => {
            store.dispatch(new fromRoot.SetWorkFilter({ removeE: $event.checked }));
          },
        },
      },
      {
        className: 'flex-1 ice-mr-40',
        key: 'removeSE',
        type: 'checkbox',
        defaultValue: false,
        templateOptions: {
          label: translate.instant('WORKS.SHARE_PICTURE.FILTER.NO_SE'),
          required: false,
          change: (field, $event) => {
            store.dispatch(new fromRoot.SetWorkFilter({ removeSE: $event.checked }));
          },
        },
      },
    ];

    const commonFilter: FormlyFieldConfig = {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        DatepickerUtils.getDatepickerField({
          name: 'distributionDate',
          label: translate.instant('WORKS.SHARE_PICTURE.FILTER.FIELD_DISTRIBUTION_DATE'),
          datepickerOptions: {
            filter: (date: Date) => moment(date).isAfter(SHARES_MIN_DATE),
          },
          extraValidators: {
            dateAfter: {
              expression: (control: FormControl) => moment(control.value).isAfter(SHARES_MIN_DATE),
              message: translate.instant('AGREEMENTS.ERRORS.DATE_AFTER_1999_12_31'),
            },
            distributionDateAfterUsageDate: {
              expression: (control: FormControl) =>
                moment(control.value).isAfter(control.parent.get('usageDate').value) || moment(control.value).isSame(control.parent.get('usageDate').value),
              message: translate.instant('AGREEMENTS.ERRORS.DISTRIBUTION_DATE_AFTER_USAGE_DATE'),
            },
          },
          required: true,
          translate,
          defaultValue: moment(today),
          infoText: translate.instant('WORKS.SHARE_PICTURE.FILTER.FIELD_DISTRIBUTION_DATE_INFO'),
          hooks: {
            onInit: field => {
              const form = field.form;
              form
                .get('usageDate')
                .valueChanges.pipe(filter(date => date && moment.isMoment(date) && oldUsageDate !== date))
                .subscribe(date => {
                  oldUsageDate = date;
                  field.formControl.markAsTouched();
                  field.formControl.setValue(field.formControl.value);
                });
            },
          },
        }),
        {
          className: 'flex-6',
          type: 'ice-autocomplete',
          key: 'prSociety',
          defaultValue: !compare ? (ns === GEMA ? `${DIST_SOCIETY_GEMA[0].value}` : DEFAULT_DISTRIBUTION_SOCIETY_PR) : `ALL`,
          templateOptions: {
            placeholder: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.PR_FIELD_SOCIETY'),
            options: ns === GEMA ? DIST_SOCIETY_GEMA : [...SocietiesUtils.getFormattedSocietiesList(), { label: 'ALL Societies', value: 'ALL' }],
            required: true,
          },
        },
        {
          className: 'flex-6',
          type: 'ice-autocomplete',
          key: 'mrSociety',
          defaultValue: !compare ? (ns === GEMA ? `${DIST_SOCIETY_GEMA[0].value}` : DEFAULT_DISTRIBUTION_SOCIETY_MR) : `ALL`,
          templateOptions: {
            placeholder: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.MR_FIELD_SOCIETY'),
            options: ns === GEMA ? DIST_SOCIETY_GEMA : [...SocietiesUtils.getFormattedSocietiesList(), { label: 'ALL Societies', value: 'ALL' }],
            required: true,
          },
        },
      ],
    };

    const formButtons = [
      {
        className: 'flex-1 mat-form-field-infix ice-accent',
        type: 'button',
        templateOptions: {
          text: translate.instant('SET_AS_DEFAULT'),
          btnType: ' ice-accent',
          materialType: 'mat-button',
          onClick: () => {
            store.pipe(select(fromRoot.getRouterParams), take(1)).subscribe(params => {
              SharesFilterConfig.doSubmit({ ...formData?.value, repertoire: isRepertoire }, store, ns, params.key, true, true);
            });
          },
        },
      },
      {
        className: 'flex-1 mat-form-field-infix ice-accent',
        type: 'button',
        templateOptions: {
          text: translate.instant('FORM_RESET'),
          btnType: ' ice-accent',
          materialType: 'mat-button',
          onClick: () => {
            store.pipe(select(fromRoot.getRouterParams), take(1)).subscribe(params => {
              const userName = LocalStorageUtils.get('currentUser');
              const userFilter = LocalStorageUtils.getDefaultSharePictureFilter(userName);
              const value = {
                ...DEFAULT_SHARES_FILTER_FORM,
                ...userFilter,
                territory: userFilter.country || DEFAULT_SHARES_FILTER_FORM.territory,
              };
              WorkUtils.updateForm(formData, value);
              formData.get('usageDate').updateValueAndValidity();
              SharesFilterConfig.doSubmit(value, store, ns, params.key, true);
            });
          },
        },
        hooks: {
          onInit: field => {
            formData = field.form;
            store.pipe(select(fromRoot.getRouterParams), take(1)).subscribe(params => {
              SharesFilterConfig.doSubmit({ ...formData?.value, repertoire: isRepertoire }, store, ns, params.key);
            });
          },
        },
      },
      {
        className: 'flex-1 mat-form-field-infix ice-accent',
        type: 'button',
        templateOptions: {
          text: translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.SUBMIT'),
          color: 'accent',
          materialType: 'mat-flat-button',
          onClick: () =>
            store.pipe(select(fromRoot.getRouterParams), take(1)).subscribe(params => {
              SharesFilterConfig.doSubmit({ ...formData?.value, repertoire: isRepertoire }, store, ns, params.key, true);
            }),
          isDisabled: () => formData?.status !== 'VALID',
        },
      },
    ];

    const secondLineCommonFilter: FormlyFieldConfig = {
      fieldGroupClassName: 'display-flex ice-justify-right group-inputs ice-accent ice-row-mb-1-25',
      fieldGroup: [...rolesField, ...formButtons],
    };

    const union = { ...firstLineCommonFilter, fieldGroup: concat(firstLineCommonFilter.fieldGroup, commonFilter.fieldGroup) };
    return [union, secondLineCommonFilter];
  }

  static doSubmit(event, store, ns: string, workId: string, saveSettings = false, saveDefaultSettings = false) {
    const mrSociety = event.mrSociety ? event.mrSociety.value || event.mrSociety : DEFAULT_DISTRIBUTION_SOCIETY_MR;
    const prSociety = event.prSociety ? event.prSociety.value || event.prSociety : DEFAULT_DISTRIBUTION_SOCIETY_PR;
    const territory = event.territory ? event.territory.value || event.territory : DEFAULT_FILTER_TERRITORY;
    const usageDate = DateTimeUtils.formatDate(event?.usageDate);
    const distributionDate = DateTimeUtils.formatDate(event?.distributionDate);
    const requestSubmit: PayloadSharePicture = {
      ns,
      workId,
      country: territory,
      usageDate,
      distributionDate,
      mrRightType: event.mrRightType,
      prRightType: event.prRightType,
      usageFactorMR: event.usageFactorMR,
      usageFactorPR: event.usageFactorPR,
      mrDistributionSocietyId: `CISAC:${mrSociety || '0'}`,
      prDistributionSocietyId: `CISAC:${prSociety || '0'}`,
      repertoire: event.repertoire,
      timeout: 25,
      userAction: true,
      avoidStorageUpdate: true,
    };
    store.dispatch(new fromRoot.GetSharePicture(requestSubmit, saveSettings, saveDefaultSettings));
  }

  static getActivityForm(store: Store<fromRoot.RootState>, translate: TranslateService, activityType: ActivityType, submitFn: (model: any) => void) {
    const SHARES_MIN_DATE = new Date('1999/12/31').toISOString();
    let formData: FormGroup;
    let initialModel: any = {};

    const formButtons = [
      {
        className: 'flex-1 ice-accent audit-reset-button',
        type: 'button',
        templateOptions: {
          text: translate.instant('FORM_RESET'),
          btnType: ' ice-accent',
          materialType: 'mat-button',
          onClick: () => formData.reset(initialModel),
        },
      },
      {
        className: 'flex-1 ice-accent audit-apply-button',
        type: 'button',
        templateOptions: {
          text: translate.instant('FORM_APPLY_FILTER'),
          color: 'accent',
          materialType: 'mat-flat-button',
          onClick: () => submitFn(formData?.value),
          isDisabled: () => formData?.status !== 'VALID',
        },
      },
    ];

    const firstLineCommonFilter: FormlyFieldConfig = {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        TerritoryUtils.getTerritoriesSelect(store, translate, activityType),
        SocietiesUtils.getPrRightSelect(translate, 'flex-grow-2', 'ALL'),
        SocietiesUtils.getMrRightSelect(translate, 'flex-grow-2', 'ALL'),
        DatepickerUtils.getDatepickerField({
          name: 'usageDate',
          label: translate.instant('WORKS.SHARE_PICTURE.FILTER.FIELD_USAGE_DATE'),
          datepickerOptions: {
            filter: (date: Date) => moment(date).isAfter(SHARES_MIN_DATE),
          },
          extraValidators: {
            dateAfter: {
              expression: (control: FormControl) => !control.value || moment(control.value).isAfter(SHARES_MIN_DATE),
              message: translate.instant('AGREEMENTS.ERRORS.DATE_AFTER_1999_12_31'),
            },
          },
          translate,
          extraClass: 'flex-grow-1 usage-datepicker',
          infoText: translate.instant('WORKS.SHARE_PICTURE.FILTER.FIELD_USAGE_DATE_INFO'),
          defaultValue: new Date().toISOString(),
        }),
      ],
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          formData = get(field, 'fieldGroup[0].form');
          initialModel = cloneDeep(get(formData, 'value', {}));
          submitFn(initialModel);
        },
      },
    };

    const checkBoxFilterField = [
      {
        className: 'flex-1 ice-ml-25 checkbox-removeE',
        key: 'removeE',
        type: 'checkbox',
        templateOptions: {
          label: translate.instant('WORKS.SHARE_PICTURE.FILTER.NO_E'),
          required: false,
        },
      },
      {
        className: 'flex-1 checkbox-removeSE',
        key: 'removeSE',
        type: 'checkbox',
        templateOptions: {
          label: translate.instant('WORKS.SHARE_PICTURE.FILTER.NO_SE'),
          required: false,
        },
      },
    ];

    const secondLineCommonFilter: FormlyFieldConfig = {
      fieldGroupClassName: 'display-flex ice-justify-right group-inputs ice-accent ice-pr-0',
      fieldGroup: [...checkBoxFilterField, ...formButtons],
    };

    return [firstLineCommonFilter, secondLineCommonFilter];
  }
}
