<ng-container *ngIf="form">
  <form [formGroup]="form">
    <mat-vertical-stepper [linear]="isLinear" *ngIf="!isHorizontal" (click)="triggerClick($event)" (keyup)="triggerClick($event)" #stepper>
      <ng-template matStepperIcon="edit" let-index="index">
        <div [className]="iconClass" *ngIf="checkIndex(index)">
          <mat-icon>check</mat-icon>
        </div>
        <div [className]="iconClass" *ngIf="!checkIndex(index)">
          <mat-icon>warning</mat-icon>
        </div>
      </ng-template>
      <ng-container *ngFor="let step of steps; let index = index">
        <mat-step label="ice-step" *ngIf="!(step.hide | async)">
          <ng-template matStepLabel>{{ step.label | async }}</ng-template>
          <div *ngIf="(step.showError | async) && !form.at(index).valid" class="ice-width-fit">
            <mat-label class="ice-error-msg">{{ step.showError | async }}</mat-label>
            <div class="underline"></div>
          </div>
          <div *ngIf="isNewUserStep(step) | async" class="gdpr-alert-container">
            <mat-icon class="alert-gdpr-color">warning</mat-icon>
            <span class="gpdr-info-text">{{ GDPRText }}</span>
          </div>
          <formly-form [form]="form.at(index)" [model]="model" [fields]="step.formBuilder" [options]="options[index]"> </formly-form>
        </mat-step>
      </ng-container>
    </mat-vertical-stepper>

    <mat-horizontal-stepper *ngIf="isHorizontal" #stepper>
      <ng-container *ngFor="let step of steps; let index = index">
        <mat-step [completed]="false" label="ice-step" [aria-labelledby]="stepper.selectedIndex > index ? 'step-done' : ''" *ngIf="!(step.hide | async)">
          <ng-template matStepLabel>{{ step.label | async }}</ng-template>
          <formly-form [form]="form.at(index)" [model]="model" [fields]="step.formBuilder" [options]="options[index]">
            <div *ngIf="step.stepButtons" class="step-buttons">
              <ng-container *ngFor="let button of step.stepButtons">
                <button
                  *ngIf="button.matStepperPrevious"
                  mat-button
                  matStepperPrevious
                  [color]="button.color"
                  [type]="button.type"
                  [ngClass]="'btn btn-' + button.btnType"
                  [disabled]="button.isDisabled && button.isDisabled()"
                  (click)="button.onClick && button.onClick($event)"
                >
                  {{ button.text }}
                </button>
                <button
                  *ngIf="button.matStepperNext"
                  mat-flat-button
                  matStepperNext
                  [color]="button.color"
                  [type]="button.type"
                  [ngClass]="'btn btn-' + button.btnType"
                  [disabled]="button.isDisabled && button.isDisabled()"
                  (click)="button.onClick && button.onClick($event)"
                  (keydown.enter)="button.onClick && button.onClick($event)"
                >
                  {{ button.text }}
                </button>
              </ng-container>
            </div>
          </formly-form>
        </mat-step>
      </ng-container>
    </mat-horizontal-stepper>
  </form>
</ng-container>
