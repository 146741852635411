export enum UserPermissionType {
  CAN_VIEW = 'CAN_VIEW',
  CAN_EDIT = 'CAN_EDIT',
  CAN_CREATE = 'CAN_CREATE',
}

export enum CounterClaimActive {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  AWAITING_REPLY = 'AWAITING_REPLY',
  SUPPORTED = 'SUPPORTED',
  CONFIRMED = 'CONFIRMED',
}

export enum CounterClaimInActive {
  RESOLVED = 'RESOLVED',
  CLOSED = 'CLOSED',
}

export const CounterClaimStatus = {
  ...CounterClaimActive,
  ...CounterClaimInActive,
};

export type CounterClaimStatus = CounterClaimActive | CounterClaimInActive;
