import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { IceForm } from '@ice/dynamic-components/group-component/group-component';
import * as fromApiCalls from 'config/api-calls';
import { CopyrightIpsDataTable } from 'config/data-table-builders/copyright.ips';
import { FormConfig } from 'config/search-form-builders/form-config';
import { IpsSearchExpressions, SearchCopyrightIpsForm } from 'config/search-form-builders/search-copyright-ips';
import { of } from 'rxjs';
import { SearchService } from 'services/search/search.service';
import { IpSearchDialogsUtils } from './common/ip-search-dialogs-utils';
import { DialogSearchDatatable } from './dialog-search-datatable';

export class DialogSearchParty {
  static openDialog(
    type,
    dialog,
    store,
    storeNewItem,
    translate,
    translationLoader: FuseTranslationLoaderService,
    searchService: SearchService,
    onSelectSearchItem,
    extraFiltersInSearch = {},
    defaultXrefPrefix?: string,
  ) {
    const copyrightIpsDatatable = new CopyrightIpsDataTable(translate, translationLoader, store);
    const searchIpsForm = new SearchCopyrightIpsForm(translate, translationLoader);

    const dialogSearchAgreement = new DialogSearchDatatable(dialog, store, searchService, translate);
    const dialogRef = dialogSearchAgreement.openDialog(
      null,
      copyrightIpsDatatable,
      fromApiCalls.searchDialogIps,
      this.getAgreementSearchForm(searchIpsForm, translate, type, storeNewItem, defaultXrefPrefix),
      new IpsSearchExpressions(),
      item => {
        onSelectSearchItem(item);
        dialogRef.close();
      },
      model => ({ ...model, ...extraFiltersInSearch }),
    );
  }

  static getAgreementSearchForm(searchForm: FormConfig, translate, type, storeNewItem, defaultXrefPrefix): IceForm {
    return {
      formBuilder: of(searchForm.getForm(type, defaultXrefPrefix)),
      model: IpSearchDialogsUtils.getSearchIpsFormModel(type, storeNewItem),
      submitEnabled: true,
      submitAvailable: true,
      submitLabel: translate.instant('AGREEMENTS.AGREEMENT_PARTIES.BT_SEARCH'),
    };
  }
}
