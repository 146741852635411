import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IceMaterialModule } from '@ice/material.module';
import { DataTableTotalsModule } from '../data-table-totals/data-table-totals.module';
import { DataTableModule } from '../data-table/data-table.module';
import { FormContainerModule } from '../form-container/form-container.module';
import { CardWithDataTableComponent } from './card-with-data-table.component';

@NgModule({
  imports: [CommonModule, IceMaterialModule, DataTableModule, FormContainerModule, DataTableTotalsModule],
  declarations: [CardWithDataTableComponent],
  exports: [CardWithDataTableComponent],
})
export class CardWithDataTableModule {}
