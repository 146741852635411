import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, isObservable, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export const DIALOG_FLOATING_BT = 'fab';
export const DIALOG_NORMAL_BT = 'normal';
export const DIALOG_NO_BT = 'no buttons';
export interface LayoutConfig {
  title: string;
  formBuilder: Observable<FormlyFieldConfig[]>;
}

export class DialogForm {
  constructor(private translate: TranslateService, private dialog: MatDialog, private type = DIALOG_FLOATING_BT) {}

  openDialog(
    title,
    model,
    form,
    onSubmit?,
    onCancel?,
    okLabel?,
    koLabel?,
    dialogClass?,
    loading = of(false),
    onErrorMessage: Observable<Object> = of({ text: '' }),
    onErrorCloseAction?: Function,
    messageIcon?: Observable<string>,
    onInit?: Function,
    showSubmit?: Observable<boolean>,
    secondLayout?: LayoutConfig,
    button1CustomClass?: string,
  ): MatDialogRef<DialogMultiLayoutComponent, any> {
    const isFormValid = new BehaviorSubject(false);
    let modelForm = null;
    const formBuilder = isObservable(form) ? form : of(form);
    const model$ = isObservable(model) ? model : of(model);

    const secondLayoutItem = secondLayout
      ? [
          {
            title: of(secondLayout.title),
            layout: [
              {
                group: [
                  {
                    type: 'formly',
                    config:
                      this.type === DIALOG_FLOATING_BT
                        ? {
                            model: model$,
                            formBuilder: secondLayout.formBuilder,
                            change: (modelChange: any) => (modelForm = cloneDeep(modelChange)),
                            setValidForm: isValid => isFormValid.next(isValid),
                          }
                        : this.type === DIALOG_NORMAL_BT
                        ? {
                            formBuilder: secondLayout.formBuilder,
                            model: model$,
                            submitEnabled: false,
                            submitAvailable: 'true',
                            submitLabel: okLabel,
                            button1Enabled: true,
                            button1Available: 'true',
                            button1Label: koLabel,
                            submit: onSubmit,
                            onButton1: onCancel,
                            showSubmit,
                          }
                        : {
                            formBuilder: secondLayout.formBuilder,
                            model: model$,
                          },
                  },
                ],
              },
            ],
          },
        ]
      : [];

    return this.dialog.open(DialogMultiLayoutComponent, {
      data: {
        className: dialogClass || 'dialog-wrapper-width-420',
        onInit,
        loading,
        layouts: [
          {
            title: of(title),
            actions:
              this.type === DIALOG_FLOATING_BT
                ? [
                    { tooltip: this.translate.instant('POPUP.CANCEL'), color: 'warn', nextLayout: 0, icon: 'close', onClick: onCancel },
                    {
                      tooltip: this.translate.instant('POPUP.CONFIRM'),
                      nextLayout: 1,
                      icon: 'done',
                      disabled: isFormValid.pipe(map(isValid => !isValid)),
                      onClick: () => onSubmit(modelForm),
                      className: 'ok-icon-button',
                    },
                  ]
                : [],
            layout: [
              {
                group: [
                  {
                    type: 'formly',
                    config:
                      this.type === DIALOG_FLOATING_BT
                        ? {
                            model: model$,
                            formBuilder,
                            change: (modelChange: any) => (modelForm = cloneDeep(modelChange)),
                            setValidForm: isValid => isFormValid.next(isValid),
                          }
                        : this.type === DIALOG_NORMAL_BT
                        ? {
                            formBuilder,
                            model: model$,
                            submitEnabled: false,
                            submitAvailable: 'true',
                            submitLabel: okLabel,
                            button1Enabled: true,
                            button1Available: 'true',
                            button1Label: koLabel,
                            button1CustomClass,
                            submit: onSubmit,
                            onButton1: onCancel,
                            showSubmit,
                          }
                        : {
                            formBuilder,
                            model: model$,
                          },
                  },
                ],
              },
            ],
          },
          ...secondLayoutItem,
          {
            title: of(title),
            layout: [
              {
                group: [
                  {
                    type: 'response-error',
                    className: 'dialog-wrapper-auto',
                    config: {
                      response: onErrorMessage,
                      responseButtons: of([
                        {
                          text: of(this.translate.instant('POPUP.CLOSE')),
                          action: onErrorCloseAction,
                        },
                      ]),
                      errors: null,
                      errorButtons: null,
                      loading,
                      loadingText: '',
                      messageIcon,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    });
  }

  getSecondLayout(secondLayout) {}
}
