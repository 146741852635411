import { User } from '@ice';
import { SortInfo } from '@ice/components/data-table/data-table';
import { TranslateService } from '@ngx-translate/core';
import { first, has, isArray, last } from 'lodash';
import { IceMaskedIdsConfig } from 'services/masked-ids/masked-ids.model';

export interface ApiCall {
  requestType: RequestType;
  url: string;
  maskedIdsList?: IceMaskedIdsConfig[];
  responseType?: string;
  body?: string;
  initialBody?: string;
  bodyArrayParameter?: string;
  bodyArrayParameterModel?: string;
  queryParams?: any;
  id?: string;
  resultCleaner?: ResultCleanerPair[];
  labelSection?: string;
  labelResolver?: Record<string, string | LabelResolverFunction>;
  responseSection?: string;
  responseHandler?: boolean;
  pageable?: boolean;
  checkStatus?: CheckStatusInfo;
  validateLabels?: boolean;
  copyrightGlobal?: boolean;
  isAthena?: boolean;
  avoidFirstTime?: boolean;
  validatePermission?: string;
  fetchAllData?: boolean;
  // Sometimes we make server calls, knowing that for some users, under some circumstances,
  // the calls will return a 403 FORBIDDEN response. Ideally we would conditionally skip forbidden
  // calls, but for now, allowForbiddenResponse allows us to handle 403 responses without erroring.
  allowForbiddenResponse?: boolean;
}

export interface MultipleRequest {
  multipleIdsPattern?: string;
  oneCallPerId?: boolean;
  multipleNamespaces?: boolean;
}

export interface ApiCallSortConfig {
  format: (sort: SortInfo) => { prop: string; dir: string };
  sortPrefix?: string;
}

export interface ApiCallData {
  url?: string;
  body?: string;
  initialBody?: string;
  queryParams?: any;
  labels?: Record<string, any>;
  cleanerData?: any;
  id?: string;
}

export type LabelResolverFunction = (detail: any, user?: User, tabName?: string, prevResponse?: any) => string | string[];

export type PermissionResolverFunction = (permission: string) => string;

export interface CheckStatusInfo {
  sectionOrObject: string;
  customStatusPath?: string;
  isClone: boolean;
}

export interface CustomApiCall {
  apiCall: ApiCall;
  apiCallData?: ApiCallData;
  callBack?: ResultCallBack;
  onCancel?: ResultCallBack;
  fromScrollEvent?: boolean;
  nextPayload?: ApiCallPayload;
  prevResponse?: any;
  retry?: number;
  retryDelay?: number;
  stopOnError?: boolean;
  stopAllPendingCallsOnCancel?: boolean;
}

export type ApiCallConfig = ApiCall | CustomApiCall;

export type ApiCallSequence = ApiCallConfig[] | [...ApiCallConfig[], SequenceType];

export type ApiCallPayload = ApiCallConfig | ApiCallSequence | ApiCallSequence[];

export interface ResultCleanerPair {
  property: string | PropertyCleaner;
  resultCleaner: ResultCleaner<any>;
}

export const isApiCallPayload = (arg: any): arg is ApiCallPayload => isApiCallSequenceOrConfig(arg) || (isArray(arg) && isApiCallSequenceOrConfig(arg[0]));

export const isApiCallSequenceOrConfig = (arg: any): arg is ApiCallSequence | ApiCallConfig => isApiCallSequence(arg) || isApiCallConfig(arg);

export const isApiCallSequence = (arg: any): arg is ApiCallSequence => isArray(arg) && isApiCallConfig(first(arg));

export const isApiCallConfigList = (arg: any): arg is ApiCallConfig[] => isApiCallSequence(arg) && isApiCallConfig(last(arg));

export const isApiCallConfig = (arg: any): arg is ApiCallConfig => !isArray(arg) && (isApiCall(arg) || isCustomApiCall(arg));

export const hasSequenceType = (arg: any): boolean => isArray(arg) && typeof last(arg) === 'number';

export const isApiCall = (arg: any): arg is ApiCall => has(arg, 'url') && has(arg, 'requestType');

export const isCustomApiCall = (arg: any): arg is CustomApiCall => has(arg, 'apiCall');

export type ResultCallBack = (result?: any, error?: any, apiCallData?: ApiCallData) => void | ApiCallPayload;

export type ResultCleaner<T = any, R = any> = (result?: R, oldDetail?: any, cleanerData?: any, translate?: TranslateService, tabName?: any, prevResponse?: any, user?: User) => T;

export type PropertyCleaner = ResultCleaner<string>;

export enum RequestType {
  PUT = 'PUT',
  POST = 'POST',
  GET = 'GET',
  DELETE = 'DELETE',
}

export enum ApiCallStatus {
  StartingRequest,
  RequestResolved,
  CheckingStatus,
  Done,
  Cancel,
  Error,
}

export interface ApiResponseStatus {
  status: ApiCallStatus;
  apiCallId?: string;
  mergeApiCallId?: string;
  response?: any;
  newItems?: any;
  error?: any;
  mergeTotal?: number;
  mergeCount?: number;
  retryCount?: number;
}

export type ApiCallFactoryResolver<T> = (...param: T[]) => Partial<ApiCall>;

export enum SequenceType {
  Concat,
  Merge,
}
