<ice-upload-file
  [label]="to.label"
  [customAddFile]="to.customAddFile"
  [fileType]="to.fileType"
  *ngIf="!(getCurrentError() || (to.message && (to.message | async))) && !to.disabled"
></ice-upload-file>
<div
  *ngIf="formControl.status === 'INVALID' || (to.message && (to.message | async))"
  class="upload-file-message"
  [ngClass]="formControl.status === 'INVALID' ? 'error-message' : ''"
>
  {{ getCurrentError() || (to.message | async) }}
</div>
<div *ngIf="to.showWarning | async" class="upload-file-warning-message">{{ to.warningMessage | async }}</div>
<div *ngIf="to.showInformation | async" class="upload-file-information">
  <button mat-icon-button class="close-btn" (click)="to.onClose()" type="button">
    <mat-icon class="cursor-pointer mat-icon notranslate material-icons mat-icon-no-color ng-star-inserted" matsuffix="" role="img" aria-hidden="true">clear </mat-icon>
  </button>
  <div>{{ to.information | async }}</div>
</div>
