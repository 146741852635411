import { FormGroup } from '@angular/forms';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CopyrightUtils } from '@ice';
import { DataTableRow } from '@ice/components/data-table/data-table';
import { FormlyValidatorUtils } from '@ice/utils/formly/formly-validators.utils';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SelectionType } from '@stage/ngx-datatable';
import * as fromApiCalls from 'config/api-calls';
import { StepType } from 'config/stepper-builders/stepper-config';
import { cloneDeep, uniqBy } from 'lodash';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromNewSectionItem from 'store/new-section-item';
import * as fromRoot from 'store/root';

export class TransferIpWorksMoveStep {
  constructor(
    protected translate: TranslateService,
    protected translationLoader: FuseTranslationLoaderService,
    protected store: Store<fromRoot.RootState>,
    protected storeNewItem: Store<fromNewSectionItem.NewSectionItemState>,
    protected fieldValidatorService: FieldValidatorService,
  ) {}
  private pageIndex = 0;
  private selectedWorks = [];
  private sourceIpWorks = [];

  getStep(): StepType {
    let formData: FormGroup;

    return {
      label: this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.SELECT_WORKS_TO_MOVE'),
      formBuilder: [
        {
          fieldGroupClassName: 'display-flex ice-mb-20',
          fieldGroup: [
            {
              type: 'htmlTemplate',
              templateOptions: {
                config: {
                  htmlTemplate: of(`<span><b>${this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.IP_MOVE_WORK')}</b></span>`),
                },
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex ice-mb-20',
          fieldGroup: [
            {
              type: 'htmlTemplate',
              templateOptions: {
                config: {
                  htmlTemplate: this.getTransferIpWorksInfo().pipe(
                    map(transferIpWorksInfo => {
                      return `<span><b>${this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.SOURCE')}:</b> ${
                        transferIpWorksInfo?.sourceIp?.fullName + ' / ' + transferIpWorksInfo?.sourceIp?.ipiNameNumber + ' / ' + transferIpWorksInfo?.sourceIp?.typeOfName
                      }</span>`;
                    }),
                  ),
                },
              },
            },
            {
              type: 'htmlTemplate',
              className: 'ice-ml-100',
              templateOptions: {
                config: {
                  htmlTemplate: this.getTransferIpWorksInfo().pipe(
                    map(transferIpWorksInfo => {
                      return `<span><b>${this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.TARGET')}:</b> ${
                        transferIpWorksInfo?.targetIp?.fullName + ' / ' + transferIpWorksInfo?.targetIp?.ipiNameNumber + ' / ' + transferIpWorksInfo?.targetIp?.typeOfName
                      }</span>`;
                    }),
                  ),
                },
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex-col ice-p-25 bg-filter-form bg-filter-form-ip-works-search',
          fieldGroup: [
            {
              fieldGroupClassName: 'display-flex ice-justify-space-between ice-mt-12',
              fieldGroup: [
                {
                  fieldGroupClassName: 'display-flex',
                  fieldGroup: [
                    {
                      className: 'input-wrapper',
                      key: 'title',
                      wrappers: ['form-field', 'wrapper-input-text'],
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        type: 'text',
                        placeholder: this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.TITLE'),
                        required: false,
                      },
                      hooks: {
                        onInit: field => {
                          formData = field.form;
                        },
                      },
                    },
                    {
                      className: 'input-wrapper',
                      key: 'workId',
                      wrappers: ['form-field', 'wrapper-input-text'],
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        type: 'text',
                        placeholder: this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.ICE_WORK_KEY'),
                        required: false,
                      },
                      asyncValidators: {
                        workRefValidation: FormlyValidatorUtils.getReferenceValidator(this.translate, 'COMMON.ERRORS.INVALID_REFERENCE'),
                      },
                    },
                  ],
                },
                {
                  fieldGroupClassName: 'display-flex flex-align-items-center ice-accent',
                  fieldGroup: [
                    {
                      className: 'ice-accent',
                      type: 'button',
                      templateOptions: {
                        text: this.translate.instant('SELECT_ORGANIZATION_POPUP.RESET'),
                        btnType: ' ice-accent',
                        materialType: 'mat-button',
                        onClick: () => {
                          formData.setValue({ ...formData.value, title: '', workId: '' });
                        },
                      },
                    },
                    {
                      className: 'ice-accent ice-ml-10',
                      type: 'button',
                      templateOptions: {
                        type: 'submit',
                        text: this.translate.instant('SELECT_ORGANIZATION_POPUP.SEARCH'),
                        color: 'accent',
                        materialType: 'mat-flat-button',
                        onClick: () => {
                          const result = {
                            sourceIpWorksFilter: {
                              title: formData.value['title'],
                              workId: formData.value['workId'],
                            },
                          };
                          this.store.dispatch(new fromRoot.SetTransferIpWorksInfo(result));
                        },
                        isDisabled: () => this.isSearchIpFormValid(formData?.controls),
                        disablePreventDefault: true,
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex-wrap',
          fieldGroup: [
            {
              key: 'ipWorksList',
              type: 'cardWithDataTable',
              className: 'width-49 ice-mr-1',
              templateOptions: {
                config: {
                  titleWithCount: this.getWorksToMoveSearchResultsTableTitle(),
                  class: 'ice-data-table-target-ip-search-results-40',
                  model: this.getTransferIpWorksInfo().pipe(
                    map(transferIpWorksInfo => {
                      if (transferIpWorksInfo && transferIpWorksInfo?.sourceIpWorks) {
                        const sourceIpWorks: any[] = (cloneDeep(transferIpWorksInfo.sourceIpWorks) as any).items;
                        const workId = transferIpWorksInfo.sourceIpWorksFilter.workId !== '' ? transferIpWorksInfo.sourceIpWorksFilter.workId : null;
                        const title =
                          transferIpWorksInfo.sourceIpWorksFilter.title !== '' && transferIpWorksInfo.sourceIpWorksFilter.title !== '*'
                            ? transferIpWorksInfo.sourceIpWorksFilter.title.toLowerCase()
                            : null;
                        if (workId || title) {
                          return [
                            ...this.sourceIpWorks
                              .filter(ip => (workId ? workId.includes(ip.keyWithoutPrefix) : ip))
                              .filter(ip => (title ? ip.title.toLowerCase().includes(title) : ip)),
                          ];
                        }
                        if (this.sourceIpWorks.length < this.pageIndex * 10) {
                          this.sourceIpWorks = uniqBy([...this.sourceIpWorks, ...sourceIpWorks], 'id');
                        }
                        return this.sourceIpWorks;
                      } else if (transferIpWorksInfo?.targetIp) {
                        this.store.dispatch(new fromRoot.StartApiCall({ apiCall: this.doPaginatedApiCall() }));
                      }
                      return [];
                    }),
                  ),
                  doPagination: _ => this.store.dispatch(new fromRoot.StartApiCall({ apiCall: this.doPaginatedApiCall() })),
                  sorts: [{ prop: 'title', dir: 'asc' }],
                  selectionType: of(SelectionType.checkbox),
                  displayCheck: (row: any, column?: any, value?: any) => true,
                  onSelect: rows => {
                    formData?.controls?.ipWorksList?.setValue(rows);
                  },
                  schema: this.getSearchIpDataTable(),
                  footerHeight: 38,
                  footerOptions: {
                    footerClassName: 'datatable-footer-select-work',
                    footerButtons: [
                      {
                        className: 'custom-add-icon-button',
                        enabledCondition: (): boolean => this.getAddButonActiveStatus(formData),
                        title: this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.SELECT_WORKS_BUTTON_TEXT'),
                        action: () => this.selectWorksToMove(formData),
                        icon: 'add_circle',
                        type: 'button',
                      },
                    ],
                  },
                },
              },
            },
            {
              key: 'ipSelectedWorksList',
              type: 'cardWithDataTable',
              className: 'width-49 ice-ml-1',
              templateOptions: {
                config: {
                  titleWithCount: this.getWorksToMoveTableTitle(),
                  class: 'ice-data-table-target-ip-search-results-40',
                  model: this.getTransferIpWorksInfo().pipe(
                    map(transferIpWorksInfo => {
                      return transferIpWorksInfo?.worksList && transferIpWorksInfo.worksList.length > 0
                        ? transferIpWorksInfo?.worksList.map(ip => {
                            return {
                              ...ip,
                              removeIcon: CopyrightUtils.getRemoveIconWithTooltip(this.translate, 'ice-data-table-remove-work-icon', row => {
                                this.deselectWorks([row]);
                              }),
                            };
                          })
                        : transferIpWorksInfo?.worksList;
                    }),
                  ),
                  modelOptions: {
                    updateOn: 'change',
                  },
                  sorts: [{ prop: 'title', dir: 'asc' }],
                  schema: this.getWorksToMoveTable(),
                },
              },
            },
          ],
        },
      ],
      stepButtons: [
        {
          matStepperPrevious: true,
          btnType: 'ice-border-accent',
          text: this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.BACK'),
          color: 'accent',
          materialType: 'mat-button',
        },
        {
          matStepperNext: true,
          text: this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.SELECT_WORKS'),
          color: 'accent',
          materialType: 'mat-flat-button',
          isDisabled: () => !this.isStepValid(),
          onClick: () => this.selectWorks(),
        },
      ],
    };
  }

  getAddButonActiveStatus(formData): boolean {
    return formData?.controls?.ipWorksList?.value?.length > 0;
  }

  public doPaginatedApiCall = () => {
    this.pageIndex += 1;
    return fromApiCalls.getTransferSourceIpWorks(this.pageIndex);
  };

  getSearchIpDataTable(): DataTableRow[] {
    return [
      {
        name: '',
        prop: 'rowSelected',
        flexGrow: 0.55,
        headerCheckboxable: true,
        checkboxable: true,
        width: 50,
        resizeable: false,
        canAutoResize: false,
        draggable: false,
        sortable: false,
        cellClass: 'ice-transfer-ip-works-data-table-checkbox',
        headerClass: 'ice-transfer-ip-works-data-table-checkbox',
      },
      { name: this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.TITLE'), prop: 'title' },
      { name: this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.ICE_WORK_KEY'), prop: 'keyWithoutPrefix' },
    ];
  }

  getWorksToMoveTable(): DataTableRow[] {
    return [
      { icons: 'removeIcon', maxWidth: 50, sortable: false },
      { name: this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.TITLE'), prop: 'title' },
      { name: this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.ICE_WORK_KEY'), prop: 'keyWithoutPrefix' },
    ];
  }

  isStepValid() {
    return this.selectedWorks && this.selectedWorks.length > 0;
  }

  isSearchIpFormValid(controls) {
    return controls?.workId?.status === 'INVALID';
  }

  preselectWorks(controls) {
    const selectedWorksArray = controls?.ipWorksList?.value ?? [];
    const tempArray = [...this.selectedWorks, ...selectedWorksArray];
    this.selectedWorks = uniqBy(tempArray, 'id');
    const result = {
      worksList: this.selectedWorks,
    };

    this.store.dispatch(new fromRoot.SetTransferIpWorksInfo(result));
  }

  selectWorksToMove(formData) {
    this.preselectWorks(formData?.controls);
    const result = {
      sourceIpWorksFilter: {
        title: formData.value['title'],
        workId: formData.value['workId'],
      },
    };
    this.store.dispatch(new fromRoot.SetTransferIpWorksInfo(result));
  }

  deselectWorks(works) {
    const tempArray = this.selectedWorks.filter(work => work.id !== works[0].id);
    this.selectedWorks = tempArray;
    const result = {
      worksList: tempArray,
    };
    this.store.dispatch(new fromRoot.SetTransferIpWorksInfo(result));
  }

  selectWorks() {
    const result = {
      worksList: this.selectedWorks,
    };

    this.store.dispatch(new fromRoot.SetTransferIpWorksInfo(result));
  }

  getWorksToMoveTableTitle() {
    return this.getTransferIpWorksInfo().pipe(
      map(transferIpWorksInfo => {
        const count = transferIpWorksInfo?.worksList?.length || 0;
        return CopyrightUtils.getTitleWithCount(this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.SELECTED_WORKS_TO_MOVE'), count);
      }),
    );
  }

  getWorksToMoveSearchResultsTableTitle() {
    return this.getTransferIpWorksInfo().pipe(
      map(transferIpWorksInfo => {
        const count = transferIpWorksInfo?.sourceIp?.works || 0;
        return CopyrightUtils.getTitleWithCount(this.translate.instant('IPS.DETAILS.TRANSFER_IP_WORKS.WORKS_TO_MOVE_SEARCH_RESULTS'), count);
      }),
    );
  }

  getTransferIpWorksInfo() {
    return this.store.pipe(select(fromRoot.getTransferIpWorksInfo));
  }
}
