import { withLatestFrom } from 'rxjs/operators';
import { AbstractControl, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { IpCleaned, IpUtils, TerritoryUtils, WorkUtils, selectControlValue } from '@ice';
import { AgreementUtils } from '@ice/utils/agreement/agreement.utils';
import { CopyrightUtils } from '@ice/utils/copyright/copyright.utils';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { IPI, IP_TYPES, allRightsSet } from 'config/constants/ips.constants';
import { DialogSearchParty } from 'config/dialog-builders/dialog-search-party';
import { StepType } from 'config/stepper-builders/stepper-config';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { SearchService } from 'services/search/search.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromNewSectionItem from 'store/new-section-item';
import * as fromRoot from 'store/root';
import { BulkUpdateEditModeValues, BulkUpdateSegment } from 'models/copyright/bulk-updates/jobs.model';
import { DatepickerUtils } from '@ice/utils/datepicker/datepicker.utils';
import moment from 'moment';
import { StorageService } from 'services/storage/storage.service';
import { ERROR_TERRITORY_IS_EMPTY, ERROR_TERRITORY_NOT_EXISTS } from 'config/constants/shares.constants';
import { DATE_FORMAT, MIN_VALID_DATE } from 'config/constants/global.constants';
import { DialogSharesUsesTypes } from 'config/dialog-builders/dialog-shares-uses-types';
import { pickBy } from 'lodash';
import { FormlyValidatorUtils } from '@ice/utils/formly/formly-validators.utils';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { OptionsGroup } from 'models/options-group';
import { FormlyField } from '@ngx-formly/core';

type Category = 'ip' | 'work' | 'workNoDetails' | 'unknown';
const bulkUpdateTypeSelector = (state: any): BulkUpdateSegment => state.newSectionItem?.newItem.fields.bulkUpdateType;
export class BulkUpdateDetailsStep {
  unsubscribeAll = new Subject();
  private bulkUpdateType$ = this.store.pipe(select(bulkUpdateTypeSelector));
  private termTerritoryField$ = new BehaviorSubject(true);
  private terminationDate: any;
  private usageTypesFormControl: AbstractControl;
  private btnUsageTypesFormControl: AbstractControl;
  private secondStepLabel$ = this.bulkUpdateType$.pipe(
    map(this.inferCategory),
    map(category => this.getLabelByCategory(category)),
  );
  private xrefOptions$ = new BehaviorSubject<OptionsGroup[]>([]);
  private xrefOptions = WorkUtils.getXrefGroupedOptions(this.translate);
  private searchXrefEvents$ = new Subject<FormlyFieldConfig>();
  constructor(
    private translate: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private fieldValidatorService: FieldValidatorService,
    private dialog: MatDialog,
    private store: Store<fromRoot.RootState>,
    private storeNewItem: Store<fromNewSectionItem.NewSectionItemState>,
    private searchService: SearchService,
    private storageService?: StorageService,
  ) {
    this.searchXrefEvents$.pipe(debounceTime(250), takeUntil(this.unsubscribeAll)).subscribe({
      next: (field: FormlyFieldConfig) => {
        WorkUtils.getXrefPublisherReference({
          field,
          store: this.store,
          translate: this.translate,
          callback: ({ backendXrefList, frontXrefList, backendOption, frontOption }) => {
            if (backendXrefList.length > 0) {
              this.xrefOptions$.next([{ header: 'Publisher', options: backendXrefList }]);
            } else {
              this.xrefOptions$.next(frontXrefList);
            }
          },
        });
      },
    });
  }
  inferCategory(type: BulkUpdateSegment): Category {
    if ([BulkUpdateSegment.TRANSFER_IP, BulkUpdateSegment.SWAP_IP, BulkUpdateSegment.TERMINATE_IP].includes(type)) {
      return 'ip';
    }
    if (
      [BulkUpdateSegment.PURPOSE, BulkUpdateSegment.TITLES, BulkUpdateSegment.OTHER_PARTIES, BulkUpdateSegment.WORK_XREFS, BulkUpdateSegment.WORK_BROADCAST_INFO].includes(type)
    ) {
      return 'work';
    }
    if (type === BulkUpdateSegment.WORK_MATCHES) {
      return 'workNoDetails';
    }
    return 'unknown';
  }

  getLabelByCategory(category: Category): string {
    switch (category) {
      case 'ip':
        return this.translate.instant('BULK-UPDATES.IP_DETAILS.GROUP_LABEL');
      case 'work':
        return this.translate.instant('BULK-UPDATES.WORK_DETAILS.GROUP_LABEL');
      case 'workNoDetails':
        return this.translate.instant('BULK-UPDATES.WORK_NO_DETAILS.GROUP_LABEL');
      default:
        return 'Something went wrong';
    }
  }
  openDialogSearchParty(control, ipReplacement = false) {
    DialogSearchParty.openDialog('ICE', this.dialog, this.store, this.storeNewItem, this.translate, this.translationLoader, this.searchService, (item: IpCleaned) => {
      const { ipiNameNumber, fullName, baseIpiNumber } = item;
      if (!ipReplacement) {
        control.parent?.get('ipiNameNumber')?.setValue(ipiNameNumber);
        control.parent?.get('ipName')?.setValue(fullName);
        control.parent?.get('ipiNameNumberReplacement')?.updateValueAndValidity();
      } else {
        control.parent?.get('ipiNameNumberReplacement')?.setValue(ipiNameNumber);
        control.parent?.get('ipNameReplacement')?.setValue(fullName);
        control.parent?.get('baseIpiNumberReplacement')?.setValue(baseIpiNumber);
      }
    });
  }
  territoryValidator(control, field) {
    if (!control.value) {
      this.fieldValidatorService.removeError(ERROR_TERRITORY_NOT_EXISTS, field.id);
      return true;
    }
    return this.fieldValidatorService.existTerritory(
      control,
      field,
      () => this.fieldValidatorService.addError(ERROR_TERRITORY_NOT_EXISTS, field.id),
      res => {
        if (res && res['tisns']) {
          this.storageService.setEntity('agreement_term_territory_field', [field.templateOptions.lastValue.toUpperCase()], true, 20);
          this.termTerritoryField$.next(true);
          field.templateOptions.bindedFieldCodes.setValue(res['tisns']);
        }
        this.fieldValidatorService.removeError(ERROR_TERRITORY_NOT_EXISTS, field.id);
      },
    );
  }

  public openUsesTypesDialog(dialog, translate, type, btnUsageTypesFormControl, usageTypesFormControl) {
    const counterClaimUsesTypes = { ...btnUsageTypesFormControl.value };
    const dialogRef = DialogSharesUsesTypes.openDialog(dialog, translate, type, counterClaimUsesTypes[type], (event: { [useType: string]: boolean }) => {
      counterClaimUsesTypes[type] = Object.keys(pickBy(event, value => value)).filter(right => allRightsSet.has(right));
      btnUsageTypesFormControl.setValue(counterClaimUsesTypes);
      const rightTypes = counterClaimUsesTypes[type].filter(right => allRightsSet.has(right));
      usageTypesFormControl.setValue(CopyrightUtils.rightsSpecificToGeneric(rightTypes));

      dialogRef.close();
    });
  }

  getStep(): StepType {
    return {
      label: this.secondStepLabel$,
      formBuilder: [
        {
          key: 'validationWorkaround',
          className: 'ice-display-none',
          hooks: {
            onInit: field => {
              this.bulkUpdateType$.pipe(takeUntil(this.unsubscribeAll)).subscribe(bulkUpdateType => {
                // by updating the value of this hidden field, we trigger the validation of the form
                field.formControl.setValue(bulkUpdateType);
              });
            },
          },
        },
        // IP DETAILS
        {
          fieldGroupClassName: 'display-flex',
          hooks: {
            onInit: field => {
              this.bulkUpdateType$.pipe(takeUntil(this.unsubscribeAll)).subscribe(bulkUpdateType => {
                const category = this.inferCategory(bulkUpdateType);
                const isOtherParties = bulkUpdateType === BulkUpdateSegment.OTHER_PARTIES;
                field.hide = !isOtherParties && category !== 'ip';
              });
            },
          },
          fieldGroup: [
            {
              key: 'ipiNameNumber',
              wrappers: ['form-field', 'wrapper-input-text'],
              type: 'input',
              className: 'flex-1',
              modelOptions: {
                updateOn: 'blur',
              },
              hooks: {
                onInit: field => {
                  this.bulkUpdateType$.pipe(takeUntil(this.unsubscribeAll)).subscribe(bulkUpdateType => {
                    field.templateOptions.label =
                      bulkUpdateType === BulkUpdateSegment.OTHER_PARTIES
                        ? this.translate.instant('BULK-UPDATES.WORK_DETAILS.IPI_NAME_NUMBER')
                        : this.translate.instant('BULK-UPDATES.IP_DETAILS.IPI_NAME_NUMBER.LABEL');
                  });
                },
              },
              templateOptions: {
                required: true,
                label: this.translate.instant('BULK-UPDATES.IP_DETAILS.IPI_NAME_NUMBER.LABEL'),
                change: (field, event) => (event.currentTarget.value === '' ? field.formControl.parent.get('ipName').setValue('') : null),
              },
              asyncValidators: {
                ipiNumberValid: {
                  expression: (control, field) =>
                    this.fieldValidatorService.searchPartyFieldValidator(
                      (partyId, partyNameId, name, partyNameObject) => {
                        control.parent.get('ipName').setValue(name);
                        control.parent?.get('partyNameId')?.setValue(partyNameId);
                        control.parent?.get('ipiNameNumberReplacement')?.updateValueAndValidity();
                      },
                      control,
                      field,
                      null,
                      IPI,
                      () => control.parent.get('ipName').setValue(''),
                    ),
                  message: this.translate.instant('BULK-UPDATES.IP_DETAILS.IPI_NAME_NUMBER.ERROR_MESSAGE'),
                },
              },
            },
            {
              key: 'partyNameId',
              type: 'input',
              className: 'ice-display-none',
            },
            {
              key: 'ipNameBtSearch',
              className: 'bt-search-ip',
              type: 'label',
              templateOptions: {
                materialType: 'mat-icon-button',
                icons: {
                  icon: 'search',
                },
                tooltipText: this.translate.instant('BULK-UPDATES.IP_DETAILS.IPI_NAME_NUMBER.SEARCH_PARTY'),
                onClick: (_, control) => {
                  this.openDialogSearchParty(control);
                },
              },
            },
            {
              key: 'ipName',
              type: 'input',
              className: 'flex-2',
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                disabled: true,
                label: this.translate.instant('BULK-UPDATES.IP_DETAILS.IPI_NAME_NUMBER.IP_NAME'),
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          hooks: {
            onInit: field => {
              this.bulkUpdateType$.pipe(takeUntil(this.unsubscribeAll)).subscribe(bulkUpdateType => {
                field.hide = ![BulkUpdateSegment.TRANSFER_IP, BulkUpdateSegment.SWAP_IP].includes(bulkUpdateType);
              });
            },
          },
          fieldGroup: [
            {
              key: 'ipiNameNumberReplacement',
              wrappers: ['form-field', 'wrapper-input-text'],
              type: 'input',
              className: 'flex-1',
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                required: true,
                label: this.translate.instant('BULK-UPDATES.IP_DETAILS.IPI_NAME_NUMBER_REPLACEMENT'),
                change: (field, event) => (event.currentTarget.value === '' ? field.formControl.parent?.get('ipNameReplacement').setValue('') : null),
              },
              validators: {
                duplicatedValue: {
                  expression: (control: FormControl) => control.value !== control.parent?.get('ipiNameNumber').value,
                  message: this.translate.instant('BULK-UPDATES.IP_DETAILS.IPI_NAME_NUMBER_REPLACEMENT_SAME_ERROR'),
                },
              },
              asyncValidators: {
                ipiNumberValid: {
                  expression: (control, field) =>
                    this.fieldValidatorService.searchPartyFieldValidator(
                      (partyId, partyNameId, name, partyNameObject) => {
                        const baseIpiNumberReplacement = IpUtils.selectIPINumber(partyNameObject?.parties[0]?.party?.relations).replace('IPI:', '');
                        control.parent.get('ipNameReplacement').setValue(name);
                        control.parent.get('baseIpiNumberReplacement').setValue(baseIpiNumberReplacement);
                      },
                      control,
                      field,
                      null,
                      IPI,
                      () => control.parent.get('ipNameReplacement').setValue(''),
                    ),
                  message: this.translate.instant('BULK-UPDATES.IP_DETAILS.IPI_NAME_NUMBER.ERROR_MESSAGE'),
                },
              },
            },
            {
              key: 'ipNameBtSearchReplacement',
              className: 'bt-search-ip',
              type: 'label',
              templateOptions: {
                materialType: 'mat-icon-button',
                icons: {
                  icon: 'search',
                },
                tooltipText: this.translate.instant('BULK-UPDATES.IP_DETAILS.IPI_NAME_NUMBER.SEARCH_PARTY'),
                onClick: (_, control) => {
                  this.openDialogSearchParty(control, true);
                },
              },
            },
            {
              key: 'ipNameReplacement',
              type: 'input',
              className: 'flex-2',
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                disabled: true,
                label: this.translate.instant('BULK-UPDATES.IP_DETAILS.IPI_NAME_NUMBER.IP_NAME'),
              },
            },
            {
              key: `baseIpiNumberReplacement`,
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          hooks: {
            onInit: field => {
              this.bulkUpdateType$.pipe(takeUntil(this.unsubscribeAll)).subscribe(bulkUpdateType => {
                field.hide = ![BulkUpdateSegment.TRANSFER_IP, BulkUpdateSegment.TERMINATE_IP].includes(bulkUpdateType);
              });
            },
          },
          fieldGroup: [
            {
              key: 'priorRoyalties',
              type: 'checkbox',
              className: 'flex-1',
              modelOptions: {
                updateOn: 'change',
              },
              defaultValue: true,
              templateOptions: {
                label: this.translate.instant('BULK-UPDATES.IP_DETAILS.PRIOR_ROYALTIES'),
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          hooks: {
            onInit: field => {
              this.bulkUpdateType$.pipe(takeUntil(this.unsubscribeAll)).subscribe(bulkUpdateType => {
                field.hide = ![BulkUpdateSegment.TRANSFER_IP, BulkUpdateSegment.TERMINATE_IP].includes(bulkUpdateType);
              });
            },
          },
          fieldGroup: [
            DatepickerUtils.getDatepickerField({
              name: 'terminationDate',
              label: this.translate.instant('BULK-UPDATES.IP_DETAILS.TERMINATION_DATE'),
              required: true,
              translate: this.translate,
              extraClass: 'flex-2',
              datepickerOptions: {
                filter: (date: Date) => moment(date).isAfter(moment(MIN_VALID_DATE)),
              },
              hooks: {
                onInit: field => {
                  field.templateOptions.datepickerOptions.terminationDate = this.terminationDate;
                },
              },
              dateChange: (field: FormlyField, value) => {
                this.terminationDate = value;
                const ptcDate = field.form.controls['postTermCollectionDate'];
                // note: we can't update ptcDate when it's hidden, so we will have to do that when sending the request in the
                // bulk update utils (formatBulkUpdateRequestData)
                if (ptcDate && field.form.controls['postTermCollectionValue'].value === 'date') {
                  if (!ptcDate.value) {
                    ptcDate.setValue(moment(value).add(1, 'days').format(DATE_FORMAT));
                  }
                  ptcDate.updateValueAndValidity();
                }
              },
            }),
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          hooks: {
            onInit: field => {
              this.bulkUpdateType$.pipe(takeUntil(this.unsubscribeAll)).subscribe(bulkUpdateType => {
                field.hide = ![BulkUpdateSegment.TRANSFER_IP, BulkUpdateSegment.TERMINATE_IP].includes(bulkUpdateType);
              });
            },
          },
          fieldGroup: [
            {
              className: 'flex-2',
              key: 'postTermCollectionValue',

              modelOptions: { updateOn: 'blur' },
              type: 'select',
              defaultValue: 'none',
              templateOptions: {
                required: true,
                label: this.translate.instant('WORKS.DELETE_CLAIM.POSTTERM_COLLECTION'),
                options: [
                  { value: 'none', label: 'None' },
                  { value: 'date', label: 'Until Date' },
                  { value: 'indefinite', label: 'Indefinite' },
                ],
              },
            },
            DatepickerUtils.getDatepickerField({
              name: 'postTermCollectionDate',
              label: this.translate.instant('BULK-UPDATES.IP_DETAILS.POST_TERM_COLLECTION_DATE'),
              required: true,
              translate: this.translate,
              extraClass: 'flex-2',
              datepickerOptions: {
                filter: (date: Date) => moment(date).isAfter(moment(this.terminationDate)),
              },
              hooks: {
                onInit: field => {
                  const postTermCollectionValue = field.parent.form.get('postTermCollectionValue');
                  field.hide = postTermCollectionValue.value !== 'date'; // initial hide when the default value is not 'date'
                  postTermCollectionValue.valueChanges.pipe(takeUntil(this.unsubscribeAll)).subscribe(value => {
                    if (value === 'date' && !field.formControl.value) {
                      const terminationDate = field.parent.form.get('terminationDate');
                      if (moment(terminationDate.value).isValid()) {
                        field.formControl.setValue(moment(terminationDate.value).add(1, 'days').format(DATE_FORMAT));
                        field.formControl.updateValueAndValidity();
                      }
                    }
                    field.hide = value !== 'date';
                  });
                  this.bulkUpdateType$.pipe(takeUntil(this.unsubscribeAll)).subscribe(bulkUpdateType => {
                    field.hide = ![BulkUpdateSegment.TRANSFER_IP, BulkUpdateSegment.TERMINATE_IP].includes(bulkUpdateType);
                  });
                },
              },
            }),
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          hooks: {
            onInit: field => {
              this.bulkUpdateType$.pipe(takeUntil(this.unsubscribeAll)).subscribe(bulkUpdateType => {
                field.hide = ![BulkUpdateSegment.TRANSFER_IP, BulkUpdateSegment.TERMINATE_IP].includes(bulkUpdateType);
              });
            },
          },
          fieldGroup: [
            {
              className: 'flex-2',
              type: 'ice-autocomplete',
              key: 'territory', // it will be transformed to "territories" in the request
              modelOptions: { updateOn: 'blur' },
              defaultValue: '+2WL',
              templateOptions: {
                allowAnyValue: true,
                listOnly: true,
                required: false,
                addTooltip: true,
                placeholder: this.translate.instant('BULK-UPDATES.IP_DETAILS.TERRITORIES'),
                label: this.translate.instant('BULK-UPDATES.IP_DETAILS.TERRITORIES'),
                options: this.termTerritoryField$.pipe(
                  map(_ => (this.storageService.getEntity('agreement_term_territory_field') || []).map(territory => ({ value: territory, label: territory }))),
                ),
                codes: [],
              },
              hooks: {
                onInit: field => {
                  const form = field.form;
                  field.templateOptions.bindedFieldCodes = form.controls.codes;
                },
                onDestroy: field => {
                  this.fieldValidatorService.removeError(ERROR_TERRITORY_IS_EMPTY, field.id);
                  if (field && field.templateOptions && field.templateOptions.subscriptionChanges) {
                    field.templateOptions.subscriptionChanges.unsubscribe();
                  }
                },
              },
              asyncValidators: {
                territoryValidator: {
                  expression: async (control, field) => this.territoryValidator(control, field),
                  message: this.translate.instant('AGREEMENTS.SHARES.VALIDATION.ERROR_TERRITORY_NOT_EXISTS'),
                },
              },
            },
            ...TerritoryUtils.getTerritoryHiddenFields(this.fieldValidatorService),
            {
              className: 'flex-1',
              fieldGroupClassName: 'display-flex',
              fieldGroup: [
                {
                  className: 'flex-1 ice-no-underline',
                  key: 'rightTypes',
                  type: 'label',
                  wrappers: ['form-field', 'wrapper-input-text'],
                  defaultValue: 'ALL',
                  templateOptions: {
                    type: 'text',
                    disabled: false,
                    required: false,
                    placeholder: this.translate.instant('PRE_STEPPER_DIALOG.AGREEMENTS.TERMINATE_TAB.RIGHT_TYPES'),
                  },
                  validation: {
                    show: true,
                  },
                },
                {
                  key: 'btnEditRightTypes',
                  className: 'ice-bt-ip',
                  type: 'label',
                  templateOptions: {
                    materialType: 'mat-icon-button',
                    icons: {
                      icon: 'edit',
                    },
                    tooltipText: this.translate.instant('PRE_STEPPER_DIALOG.AGREEMENTS.TERMINATE_TAB.EDIT_RIGHT_TYPES'),
                    onClick: model => {
                      this.openUsesTypesDialog(this.dialog, this.translate, 'PRMR', this.btnUsageTypesFormControl, this.usageTypesFormControl);
                    },
                  },
                  hooks: {
                    onInit: field => {
                      const form = field.form;
                      this.btnUsageTypesFormControl = form.get('btnEditRightTypes');
                      this.btnUsageTypesFormControl?.setValue({ PRMR: AgreementUtils.rightsGenericToSpecific('ALL') });
                      this.usageTypesFormControl = form.get('rightTypes');
                    },
                  },
                },
              ],
            },
          ],
        },
        // WORK DETAILS
        {
          fieldGroupClassName: 'display-flex',
          hooks: {
            onInit: field => {
              this.bulkUpdateType$.pipe(takeUntil(this.unsubscribeAll)).subscribe(bulkUpdateType => {
                field.hide = bulkUpdateType !== BulkUpdateSegment.PURPOSE;
              });
            },
          },
          fieldGroup: [
            {
              key: 'purpose',
              type: 'select',
              className: 'flex-2',
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                placeholder: this.translate.instant('WORKS.STEP_WORK_DETAIL.PURPOSE'),
                options: WorkUtils.getPurposeOptions(this.translate),
                required: true,
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          hooks: {
            onInit: field => {
              this.bulkUpdateType$.pipe(takeUntil(this.unsubscribeAll)).subscribe(bulkUpdateType => {
                field.hide = ![BulkUpdateSegment.TITLES, BulkUpdateSegment.OTHER_PARTIES, BulkUpdateSegment.WORK_XREFS].includes(bulkUpdateType);
              });
            },
          },
          fieldGroup: [
            {
              key: 'mode',
              type: 'select',
              className: 'flex-2',
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                placeholder: this.translate.instant('BULK-UPDATES.WORK_DETAILS.MODE'),
                options: this.bulkUpdateType$.pipe(
                  map(bulkUpdateType => {
                    const hasEditMode = ![BulkUpdateSegment.OTHER_PARTIES, BulkUpdateSegment.WORK_XREFS].includes(bulkUpdateType);
                    const options = [
                      {
                        label: this.translate.instant('BULK-UPDATES.WORK_DETAILS.ADD'),
                        value: BulkUpdateEditModeValues.ADD,
                      },
                      {
                        label: this.translate.instant('BULK-UPDATES.WORK_DETAILS.REMOVE'),
                        value: BulkUpdateEditModeValues.REMOVE,
                      },
                    ];
                    if (hasEditMode) {
                      options.push({
                        label: this.translate.instant('BULK-UPDATES.WORK_DETAILS.EDIT'),
                        value: BulkUpdateEditModeValues.EDIT,
                      });
                    }
                    return options;
                  }),
                ),
                required: true,
              },
              hooks: {
                onInit: field => {
                  this.bulkUpdateType$.pipe(takeUntil(this.unsubscribeAll)).subscribe(bulkUpdateType => {
                    field.hide = ![BulkUpdateSegment.TITLES, BulkUpdateSegment.OTHER_PARTIES, BulkUpdateSegment.WORK_XREFS].includes(bulkUpdateType);
                  });
                  field.formControl.valueChanges.pipe(takeUntil(this.unsubscribeAll)).subscribe((value: BulkUpdateEditModeValues) => {
                    const isAdd = value === BulkUpdateEditModeValues.ADD;
                    const isRemove = value === BulkUpdateEditModeValues.REMOVE;
                    if (isAdd) {
                      field.form.controls['oldTitleType']?.setValue(undefined);
                      field.form.controls['oldTitle']?.setValue(undefined);
                    }
                    field.form.controls['role']?.setValue(undefined);

                    if (isRemove) {
                      field.form.controls['newTitleType']?.setValue(undefined);
                      field.form.controls['newTitle']?.setValue(undefined);
                      field.form.controls['newDuration']?.setValue(undefined);
                    }
                  });
                },
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          hooks: {
            onInit: field => {
              this.bulkUpdateType$.pipe(takeUntil(this.unsubscribeAll)).subscribe(bulkUpdateType => {
                field.hide = bulkUpdateType !== BulkUpdateSegment.TITLES;
              });
            },
          },
          fieldGroup: [
            {
              key: 'oldTitleType',
              type: 'select',
              className: 'flex-2',
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                placeholder: this.translate.instant('BULK-UPDATES.WORK_DETAILS.OLD_TITLE_TYPE'),
                options: WorkUtils.getAlternativeTitlesConstantOptions(this.translate),
                required: true,
                change: (field, event) => {
                  setTimeout(() => {
                    field.form.controls['newTitleType']?.updateValueAndValidity();
                  });
                },
              },
              validators: {
                titleTypeNotAllowedToBeRemoved: {
                  expression: (control: FormControl) => {
                    const mode = control.parent?.get('mode');
                    if (mode?.value === BulkUpdateEditModeValues.REMOVE) {
                      return control.value !== 'OT';
                    }
                    return true;
                  },
                  message: this.translate.instant('BULK-UPDATES.WORK_DETAILS.FEEDBACK.TITLE_TYPE_NOT_ALLOWED'),
                },
              },
              hooks: {
                onInit: field => {
                  (field.parent.form.get('mode').valueChanges as Observable<BulkUpdateEditModeValues>)
                    .pipe(withLatestFrom(this.bulkUpdateType$), takeUntil(this.unsubscribeAll))
                    .subscribe(([mode, bulkUpdateType]) => {
                      field.hide = mode === BulkUpdateEditModeValues.ADD || bulkUpdateType !== BulkUpdateSegment.TITLES;
                    });
                },
              },
            },
            {
              key: 'newTitleType',
              type: 'select',
              className: 'flex-2',
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                placeholder: this.translate.instant('BULK-UPDATES.WORK_DETAILS.NEW_TITLE_TYPE'),
                options: WorkUtils.getAlternativeTitlesConstantOptions(this.translate),
                required: true,
              },
              hooks: {
                onInit: field => {
                  (field.parent.form.get('mode').valueChanges as Observable<BulkUpdateEditModeValues>)
                    .pipe(withLatestFrom(this.bulkUpdateType$), takeUntil(this.unsubscribeAll))
                    .subscribe(([mode, bulkUpdateType]) => {
                      field.hide = mode === BulkUpdateEditModeValues.REMOVE || bulkUpdateType !== BulkUpdateSegment.TITLES;
                    });
                },
              },
              validators: {
                titleTypeNotAllowedToBeAdded: {
                  expression: (control: FormControl) => {
                    const mode = control.parent?.get('mode');
                    if (mode?.value === BulkUpdateEditModeValues.ADD) {
                      return control.value !== 'OT';
                    }
                    if (mode?.value === BulkUpdateEditModeValues.EDIT) {
                      return control.parent.get('oldTitleType').value !== 'OT' || control.value !== 'OT';
                    }
                    return true;
                  },
                  message: this.translate.instant('BULK-UPDATES.WORK_DETAILS.FEEDBACK.TITLE_TYPE_NOT_ALLOWED'),
                },
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          hooks: {
            onInit: field => {
              this.bulkUpdateType$.pipe(takeUntil(this.unsubscribeAll)).subscribe(bulkUpdateType => {
                field.hide = bulkUpdateType !== BulkUpdateSegment.TITLES;
              });
            },
          },
          fieldGroup: [
            {
              key: 'oldTitle',
              type: 'ice-input',
              className: 'flex-2',
              modelOptions: {
                updateOn: 'blur',
              },
              hooks: {
                onInit: field => {
                  (field.parent.form.get('mode').valueChanges as Observable<BulkUpdateEditModeValues>)
                    .pipe(withLatestFrom(this.bulkUpdateType$), takeUntil(this.unsubscribeAll))
                    .subscribe(([mode, bulkUpdateType]) => {
                      field.hide = mode === BulkUpdateEditModeValues.ADD || bulkUpdateType !== BulkUpdateSegment.TITLES;
                    });
                },
              },
              templateOptions: {
                placeholder: this.translate.instant('BULK-UPDATES.WORK_DETAILS.OLD_TITLE'),
                required: true,
              },
            },
            {
              key: 'newTitle',
              type: 'ice-input',
              className: 'flex-2',
              modelOptions: {
                updateOn: 'blur',
              },
              hooks: {
                onInit: field => {
                  (field.parent.form.get('mode').valueChanges as Observable<BulkUpdateEditModeValues>)
                    .pipe(withLatestFrom(this.bulkUpdateType$), takeUntil(this.unsubscribeAll))
                    .subscribe(([mode, bulkUpdateType]) => {
                      field.hide = mode === BulkUpdateEditModeValues.REMOVE || bulkUpdateType !== BulkUpdateSegment.TITLES;
                    });
                },
              },
              templateOptions: {
                placeholder: this.translate.instant('BULK-UPDATES.WORK_DETAILS.NEW_TITLE'),
                required: true,
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          hooks: {
            onInit: field => {
              this.bulkUpdateType$.pipe(takeUntil(this.unsubscribeAll)).subscribe(bulkUpdateType => {
                field.hide = bulkUpdateType !== BulkUpdateSegment.TITLES;
              });
            },
          },
          fieldGroup: [
            {
              key: 'newDuration',
              wrappers: ['form-field', 'wrapper-input-text'],
              type: 'input',
              className: 'flex-2',
              templateOptions: {
                placeholder: this.translate.instant('WORKS.STEP_WORK_DETAIL.TITLES.DURATION'),
              },
              hooks: {
                onInit: field => {
                  (field.parent.form.get('mode').valueChanges as Observable<BulkUpdateEditModeValues>)
                    .pipe(withLatestFrom(this.bulkUpdateType$), takeUntil(this.unsubscribeAll))
                    .subscribe(([mode, bulkUpdateType]) => {
                      field.hide = mode === BulkUpdateEditModeValues.REMOVE || bulkUpdateType !== BulkUpdateSegment.TITLES;
                    });
                },
              },
              validators: {
                duration: FormlyValidatorUtils.getDurationValidator(this.translate),
                maxDuration: FormlyValidatorUtils.getMaxDurationValidator(this.translate),
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          hooks: {
            onInit: field => {
              this.bulkUpdateType$.pipe(takeUntil(this.unsubscribeAll)).subscribe(bulkUpdateType => {
                field.hide = bulkUpdateType !== BulkUpdateSegment.OTHER_PARTIES;
              });
            },
          },
          fieldGroup: [
            {
              className: 'flex-2',
              key: 'role',
              type: 'select',
              hooks: {
                onInit: field => {
                  (field.parent.form.get('mode').valueChanges as Observable<BulkUpdateEditModeValues>)
                    .pipe(withLatestFrom(this.bulkUpdateType$), takeUntil(this.unsubscribeAll))
                    .subscribe(([mode, bulkUpdateType]) => {
                      field.hide = mode !== BulkUpdateEditModeValues.ADD || bulkUpdateType !== BulkUpdateSegment.OTHER_PARTIES;
                    });
                },
              },
              templateOptions: {
                label: `${this.translate.instant('WORKS.OTHER_PARTIES.TABLE.TYPE_OF_NAME')}`,
                required: true,
                options: IP_TYPES,
              },
            },
          ],
        },
        {
          className: 'ice-w-90',
          hooks: {
            onInit: field => {
              this.bulkUpdateType$.pipe(takeUntil(this.unsubscribeAll)).subscribe(bulkUpdateType => {
                field.hide = bulkUpdateType !== BulkUpdateSegment.WORK_XREFS;
              });
            },
          },
          fieldGroup: [
            {
              className: 'add-xref-input',
              key: 'xrefValue',
              wrappers: ['form-field', 'wrapper-input-text'],
              type: 'input',
              modelOptions: {
                updateOn: 'change',
              },
              templateOptions: {
                type: 'text',
                required: true,
                label: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.XREF'),
              },
              hooks: {
                onInit: field => {
                  (field.parent.form.get('mode').valueChanges as Observable<BulkUpdateEditModeValues>).pipe(takeUntil(this.unsubscribeAll)).subscribe(mode => {
                    field.templateOptions.required = mode === BulkUpdateEditModeValues.ADD;
                  });
                  field.parent.form
                    .get('xrefTypeHelper')
                    .valueChanges.pipe(takeUntil(this.unsubscribeAll))
                    .subscribe(xrefType => {
                      const isISWC = ['ISWCA', 'ISWC'].includes(xrefType?.value);
                      field.options.formState.isIWSC = isISWC;
                      field.templateOptions.label = isISWC ? 'ISWC: T-000.000.000-0' : this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.XREF');
                      field.formControl.updateValueAndValidity();
                    });
                },
              },
              asyncValidators: {
                iswcValidation: {
                  expression: async (control: FormControl, field: FormlyFieldConfig) => {
                    if (field.options.formState.isIWSC) {
                      const value = (control && control.value) || '';
                      if (!this.fieldValidatorService.iswcRegExp.test(value)) {
                        return false;
                      } else {
                        return this.fieldValidatorService.isIswcValidValue(value);
                      }
                    }
                    return true;
                  },
                  message: this.translate.instant('WORKS.DETAILS.ISWC_VALIDATION'),
                },
              },
            },
            {
              className: 'flex-1',
              fieldGroup: [
                {
                  className: 'type-select flex-2',
                  key: 'xrefTypeHelper',
                  type: 'ice-autocomplete-grouped',
                  modelOptions: {
                    updateOn: 'change',
                  },
                  templateOptions: {
                    required: true,
                    placeholder: this.translate.instant('WORKS.DETAILS.CARD_WITH_FORM.FORM.SELECT_XREF_TYPE'),
                    options: this.xrefOptions,
                    panelWidth: 'auto',
                    change: (field, event) => {
                      if (field.formControl.value?.length >= 2) {
                        this.searchXrefEvents$.next(field);
                      }
                      field.parent.form.get('xrefType').setValue(event?.value);
                    },
                  },
                },
              ],
            },
            {
              className: 'ice-display-none',
              key: 'xrefType',
              type: 'input',
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          hooks: {
            onInit: field => {
              this.bulkUpdateType$.pipe(takeUntil(this.unsubscribeAll)).subscribe(bulkUpdateType => {
                field.hide = bulkUpdateType !== BulkUpdateSegment.WORK_BROADCAST_INFO;
              });
            },
          },
          fieldGroup: [
            {
              key: 'broadcastInfo',
              type: 'input',
              className: 'flex-2',
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                placeholder: this.translate.instant('BULK-UPDATES.WORK_DETAILS.BROADCAST_INFO'),
                required: true,
                pattern: /^[a-zA-Z0-9_]{0,60}$/,
                maxLength: 60,
              },
              validation: {
                messages: {
                  pattern: this.translate.instant('WORKS.SOCIETY_ATTRIBUTES.ATTRIBUTES_POPUP.ERRORS.ALPHANUMERIC_LENGTH_60'),
                },
              },
            },
          ],
        },
      ],
      onDestroy: () => {
        // unsubscribe from all subscriptions
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
      },
      hide: this.bulkUpdateType$.pipe(map(bulkUpdateType => !bulkUpdateType)),
    };
  }
}
