import { TisnTisaConversionItem } from 'models/tisnTisaConversionItem';

export const tisnTisaConversionItems: TisnTisaConversionItem[] = [
  {
    tisn: '2100',
    tisnGroup: [
      12, 24, 72, 108, 120, 132, 140, 148, 174, 178, 180, 204, 226, 230, 231, 232, 262, 266, 270, 288, 324, 384, 404, 426, 430, 434, 450, 454, 466, 478, 480, 504, 508, 516, 562,
      566, 624, 646, 678, 686, 690, 694, 706, 710, 716, 728, 729, 732, 736, 748, 768, 788, 800, 818, 834, 854, 894,
    ],
    tisa: '2AF',
    endDate: '3999-12-31',
    name: 'AFRICA',
  },
  {
    tisn: '2101',
    tisnGroup: [
      28, 32, 44, 52, 68, 76, 84, 124, 152, 170, 188, 192, 212, 214, 218, 222, 308, 320, 328, 332, 340, 388, 484, 558, 591, 600, 604, 630, 659, 662, 670, 740, 780, 840, 858, 862,
    ],
    tisa: '2AM',
    endDate: '3999-12-31',
    name: 'AMERICA',
  },
  {
    tisn: '2102',
    tisnGroup: [32, 68, 76, 84, 124, 152, 170, 188, 218, 222, 320, 328, 340, 484, 558, 591, 600, 604, 740, 840, 858, 862],
    tisa: '2AC',
    endDate: '3999-12-31',
    name: 'AMERICAN CONTINENT',
  },
  {
    tisn: '2103',
    tisnGroup: [28, 52, 192, 212, 214, 308, 332, 388, 630, 659, 662, 670, 780],
    tisa: '2AN',
    endDate: '3999-12-31',
    name: 'ANTILLES',
  },
  {
    tisn: '2104',
    tisnGroup: [36, 96, 124, 152, 156, 158, 344, 360, 392, 410, 446, 458, 484, 554, 598, 604, 608, 630, 643, 702, 704, 764, 840],
    tisa: '2AP',
    endDate: '3999-12-31',
    name: 'APEC COUNTRIES',
  },
  {
    tisn: '2105',
    tisnGroup: [96, 104, 116, 360, 418, 458, 608, 702, 704, 764],
    tisa: '2AE',
    endDate: '3999-12-31',
    name: 'ASEAN COUNTRIES',
  },
  {
    tisn: '2106',
    tisnGroup: [
      4, 31, 48, 50, 51, 64, 96, 104, 116, 144, 156, 158, 196, 268, 344, 356, 360, 364, 368, 376, 392, 398, 400, 408, 410, 414, 417, 418, 422, 446, 458, 462, 496, 512, 524, 586,
      608, 626, 634, 682, 702, 704, 720, 760, 762, 764, 784, 792, 795, 860, 886, 887,
    ],
    tisa: '2AS',
    endDate: '3999-12-31',
    name: 'ASIA',
  },
  {
    tisn: '2107',
    tisnGroup: [36, 90, 242, 540, 548, 554],
    tisa: '2AA',
    endDate: '3999-12-31',
    name: 'AUSTRALASIA',
  },
  {
    tisn: '2108',
    tisnGroup: [8, 70, 100, 191, 300, 499, 642, 688, 705, 807, 890, 891],
    tisa: '2BA',
    endDate: '3999-12-31',
    name: 'BALKANS',
  },
  {
    tisn: '2109',
    tisnGroup: [233, 428, 440],
    tisa: '2BS',
    endDate: '3999-12-31',
    name: 'BALTIC STATES',
  },
  {
    tisn: '2110',
    tisnGroup: [56, 442, 528],
    tisa: '2BE',
    endDate: '3999-12-31',
    name: 'BENELUX',
  },
  {
    tisn: '2111',
    tisnGroup: [372, 826],
    tisa: '2BI',
    endDate: '3999-12-31',
    name: 'BRITISH ISLES',
  },
  {
    tisn: '2112',
    tisnGroup: [28, 44, 52, 212, 308, 388, 659, 662, 670, 780],
    tisa: '2BW',
    endDate: '3999-12-31',
    name: 'BRITISH WEST INDIES',
  },
  {
    tisn: '2113',
    tisnGroup: [84, 188, 222, 320, 340, 558, 591],
    tisa: '2CA',
    endDate: '3999-12-31',
    name: 'CENTRAL AMERICA',
  },
  {
    tisn: '2114',
    tisnGroup: [
      28, 36, 44, 50, 52, 72, 84, 90, 96, 120, 124, 144, 196, 212, 242, 270, 288, 296, 308, 328, 356, 388, 404, 426, 454, 458, 462, 470, 480, 508, 516, 520, 548, 554, 566, 586,
      598, 659, 662, 670, 690, 694, 702, 710, 748, 776, 780, 798, 800, 826, 834, 882, 894,
    ],
    tisa: '2CO',
    endDate: '3999-12-31',
    name: 'COMMONWEALTH',
  },
  {
    tisn: '2115',
    tisnGroup: [72, 120, 270, 288, 404, 426, 454, 480, 508, 516, 566, 690, 694, 710, 748, 800, 834, 894],
    tisa: '2CF',
    endDate: '3999-12-31',
    name: 'COMMONWEALTH AFRICAN TERRITORIES',
  },
  {
    tisn: '2116',
    tisnGroup: [50, 96, 144, 196, 356, 458, 462, 586, 702],
    tisa: '2CS',
    endDate: '3999-12-31',
    name: 'COMMONWEALTH ASIAN TERRITORIES',
  },
  {
    tisn: '2117',
    tisnGroup: [36, 90, 242, 548, 554],
    tisa: '2CU',
    endDate: '3999-12-31',
    name: 'COMMONWEALTH AUSTRALASIAN TERRITORIES',
  },
  {
    tisn: '2118',
    tisnGroup: [31, 51, 112, 398, 417, 498, 643, 762, 795, 804, 860],
    tisa: '2CI',
    endDate: '3999-12-31',
    name: 'COMMONWEALTH OF INDEPENDENT STATES',
  },
  {
    tisn: '2119',
    tisnGroup: [8, 100, 112, 200, 203, 233, 278, 348, 428, 440, 498, 616, 642, 643, 703, 804, 810],
    tisa: '2EE',
    endDate: '3999-12-31',
    name: 'EASTERN EUROPE',
  },
  {
    tisn: '2120',
    tisnGroup: [
      8, 20, 40, 56, 70, 100, 112, 191, 200, 203, 208, 233, 246, 250, 276, 278, 280, 300, 336, 348, 352, 372, 380, 428, 438, 440, 442, 470, 492, 498, 499, 528, 578, 616, 620, 642,
      643, 674, 688, 703, 705, 724, 752, 756, 804, 807, 810, 826, 890, 891,
    ],
    tisa: '2EU',
    endDate: '3999-12-31',
    name: 'EUROPE',
  },
  {
    tisn: '2121',
    tisnGroup: [40, 56, 100, 196, 203, 208, 233, 246, 250, 276, 300, 348, 352, 372, 380, 428, 438, 440, 442, 470, 528, 578, 616, 620, 642, 703, 705, 724, 752, 826],
    tisa: '2EM',
    endDate: '3999-12-31',
    name: 'EUROPEAN ECONOMIC AREA',
  },
  {
    tisn: '2122',
    tisnGroup: [
      8, 20, 40, 56, 70, 100, 112, 191, 200, 203, 208, 233, 246, 250, 276, 278, 280, 300, 336, 348, 380, 428, 438, 440, 442, 492, 498, 499, 528, 578, 616, 620, 642, 643, 674, 688,
      703, 705, 724, 752, 756, 804, 807, 810, 890, 891,
    ],
    tisa: '2EC',
    endDate: '3999-12-31',
    name: 'EUROPEAN CONTINENT',
  },
  {
    tisn: '2123',
    tisnGroup: [40, 56, 100, 191, 196, 203, 208, 233, 246, 250, 276, 300, 348, 372, 380, 428, 440, 442, 470, 528, 616, 620, 642, 703, 705, 724, 752, 826],
    tisa: '2EY',
    endDate: '1993-10-31',
    name: 'EUROPEAN ECONOMIC COMMUNITY',
  },
  {
    tisn: '2123',
    tisnGroup: [40, 56, 100, 191, 196, 203, 208, 233, 246, 250, 276, 300, 348, 372, 380, 428, 440, 442, 470, 528, 616, 620, 642, 703, 705, 724, 752, 826],
    tisa: '2EN',
    endDate: '3999-12-31',
    name: 'EUROPEAN UNION',
  },
  {
    tisn: '2124',
    tisnGroup: [40, 276, 280, 756],
    tisa: '2GC',
    endDate: '3999-12-31',
    name: 'GSA COUNTRIES',
  },
  {
    tisn: '2125',
    tisnGroup: [4, 48, 50, 64, 144, 356, 364, 368, 400, 414, 462, 496, 512, 524, 586, 634, 682, 720, 784, 886, 887],
    tisa: '2ME',
    endDate: '3999-12-31',
    name: 'MIDDLE EAST',
  },
  {
    tisn: '2126',
    tisnGroup: [124, 484, 630, 840],
    tisa: '2NT',
    endDate: '3999-12-31',
    name: 'NAFTA COUNTRIES',
  },
  {
    tisn: '2127',
    tisnGroup: [208, 246, 352, 578, 752],
    tisa: '2NC',
    endDate: '3999-12-31',
    name: 'NORDIC COUNTRIES',
  },
  {
    tisn: '2128',
    tisnGroup: [12, 434, 504, 788, 818],
    tisa: '2NF',
    endDate: '3999-12-31',
    name: 'NORTH AFRICA',
  },
  {
    tisn: '2129',
    tisnGroup: [124, 484, 840],
    tisa: '2NA',
    endDate: '3999-12-31',
    name: 'NORTH AMERICA',
  },
  {
    tisn: '2130',
    tisnGroup: [36, 90, 242, 258, 296, 520, 540, 548, 554, 583, 584, 585, 598, 776, 798, 882],
    tisa: '2OC',
    endDate: '3999-12-31',
    name: 'OCEANIA',
  },
  {
    tisn: '2131',
    tisnGroup: [208, 578, 752],
    tisa: '2SC',
    endDate: '3999-12-31',
    name: 'SCANDINAVIA',
  },
  {
    tisn: '2132',
    tisnGroup: [32, 68, 76, 152, 170, 218, 328, 600, 604, 740, 858, 862],
    tisa: '2SA',
    endDate: '3999-12-31',
    name: 'SOUTH AMERICA',
  },
  {
    tisn: '2133',
    tisnGroup: [96, 104, 116, 360, 418, 458, 608, 626, 702, 704, 764],
    tisa: '2SE',
    endDate: '3999-12-31',
    name: 'SOUTH EAST ASIA',
  },
  {
    tisn: '2134',
    tisnGroup: [28, 44, 52, 192, 212, 214, 308, 332, 388, 630, 659, 662, 670, 780],
    tisa: '2WI',
    endDate: '3999-12-31',
    name: 'WEST INDIES',
  },
  {
    tisn: '2136',
    tisnGroup: [
      4, 8, 12, 20, 24, 28, 31, 32, 36, 40, 44, 48, 50, 51, 52, 56, 64, 68, 70, 72, 76, 84, 90, 96, 100, 104, 108, 112, 116, 120, 124, 132, 140, 144, 148, 152, 156, 158, 170, 174,
      178, 180, 188, 191, 192, 196, 200, 203, 204, 208, 212, 214, 218, 222, 226, 230, 231, 232, 233, 242, 246, 250, 258, 262, 266, 268, 270, 276, 278, 280, 288, 296, 300, 308, 320,
      324, 328, 332, 336, 340, 344, 348, 352, 356, 360, 364, 368, 372, 376, 380, 384, 388, 392, 398, 400, 404, 408, 410, 414, 417, 418, 422, 426, 428, 430, 434, 438, 440, 442, 446,
      450, 454, 458, 462, 466, 470, 478, 480, 484, 492, 496, 498, 499, 504, 508, 512, 516, 520, 524, 528, 540, 548, 554, 558, 562, 566, 578, 583, 584, 585, 586, 591, 598, 600, 604,
      608, 616, 620, 624, 626, 630, 634, 642, 643, 646, 659, 662, 670, 674, 678, 682, 686, 688, 690, 694, 702, 703, 704, 705, 706, 710, 716, 720, 724, 728, 729, 732, 736, 740, 748,
      752, 756, 760, 762, 764, 768, 776, 780, 784, 788, 792, 795, 798, 800, 804, 807, 810, 818, 826, 834, 840, 854, 858, 860, 862, 882, 886, 887, 890, 891, 894,
    ],
    tisa: '2WL',
    endDate: '3999-12-31',
    name: 'WORLD',
  },
  {
    tisn: '4',
    tisa: 'AF',
    endDate: '3999-12-31',
    name: 'AFGHANISTAN',
  },
  {
    tisn: '8',
    tisa: 'AL',
    endDate: '3999-12-31',
    name: 'ALBANIA',
  },
  {
    tisn: '12',
    tisa: 'DZ',
    endDate: '3999-12-31',
    name: 'ALGERIA',
  },
  {
    tisn: '20',
    tisa: 'AD',
    endDate: '3999-12-31',
    name: 'ANDORRA',
  },
  {
    tisn: '24',
    tisa: 'AO',
    endDate: '3999-12-31',
    name: 'ANGOLA',
  },
  {
    tisn: '28',
    tisa: 'AG',
    endDate: '3999-12-31',
    name: 'ANTIGUA AND BARBUDA',
  },
  {
    tisn: '31',
    tisa: 'AZ',
    endDate: '3999-12-31',
    name: 'AZERBAIJAN',
  },
  {
    tisn: '32',
    tisa: 'AR',
    endDate: '3999-12-31',
    name: 'ARGENTINA',
  },
  {
    tisn: '36',
    tisa: 'AU',
    endDate: '3999-12-31',
    name: 'AUSTRALIA',
  },
  {
    tisn: '40',
    tisa: 'AT',
    endDate: '3999-12-31',
    name: 'AUSTRIA',
  },
  {
    tisn: '44',
    tisa: 'BS',
    endDate: '3999-12-31',
    name: 'BAHAMAS',
  },
  {
    tisn: '48',
    tisa: 'BH',
    endDate: '3999-12-31',
    name: 'BAHRAIN',
  },
  {
    tisn: '50',
    tisa: 'BD',
    endDate: '3999-12-31',
    name: 'BANGLADESH',
  },
  {
    tisn: '51',
    tisa: 'AM',
    endDate: '3999-12-31',
    name: 'ARMENIA',
  },
  {
    tisn: '52',
    tisa: 'BB',
    endDate: '3999-12-31',
    name: 'BARBADOS',
  },
  {
    tisn: '56',
    tisa: 'BE',
    endDate: '3999-12-31',
    name: 'BELGIUM',
  },
  {
    tisn: '64',
    tisa: 'BT',
    endDate: '3999-12-31',
    name: 'BHUTAN',
  },
  {
    tisn: '68',
    tisa: 'BO',
    endDate: '3999-12-31',
    name: 'BOLIVIA',
  },
  {
    tisn: '70',
    tisa: 'BA',
    endDate: '3999-12-31',
    name: 'BOSNIA AND HERZEGOVINA',
  },
  {
    tisn: '72',
    tisa: 'BW',
    endDate: '3999-12-31',
    name: 'BOTSWANA',
  },
  {
    tisn: '76',
    tisa: 'BR',
    endDate: '3999-12-31',
    name: 'BRAZIL',
  },
  {
    tisn: '84',
    tisa: 'BZ',
    endDate: '3999-12-31',
    name: 'BELIZE',
  },
  {
    tisn: '90',
    tisa: 'SB',
    endDate: '3999-12-31',
    name: 'SOLOMON ISLANDS',
  },
  {
    tisn: '96',
    tisa: 'BN',
    endDate: '3999-12-31',
    name: 'BRUNEI DARUSSALAM',
  },
  {
    tisn: '100',
    tisa: 'BG',
    endDate: '3999-12-31',
    name: 'BULGARIA',
  },

  {
    tisn: '104',
    tisa: 'BU',
    endDate: '1988-12-31',
    name: 'BURMA',
  },

  {
    tisn: '104',
    tisa: 'MM',
    endDate: '3999-12-31',
    name: 'MYANMAR',
  },

  {
    tisn: '108',
    tisa: 'BI',
    endDate: '3999-12-31',
    name: 'BURUNDI',
  },

  {
    tisn: '112',
    tisa: 'BY',
    endDate: '3999-12-31',
    name: 'BELARUS',
  },

  {
    tisn: '116',
    tisa: 'KH',
    endDate: '3999-12-31',
    name: 'CAMBODIA',
  },

  {
    tisn: '120',
    tisa: 'CM',
    endDate: '3999-12-31',
    name: 'CAMEROON',
  },

  {
    tisn: '124',
    tisa: 'CA',
    endDate: '3999-12-31',
    name: 'CANADA',
  },

  {
    tisn: '132',
    tisa: 'CV',
    endDate: '3999-12-31',
    name: 'CAPE VERDE',
  },

  {
    tisn: '140',
    tisa: 'CF',
    endDate: '3999-12-31',
    name: 'CENTRAL AFRICAN REPUBLIC',
  },

  {
    tisn: '144',
    tisa: 'LK',
    endDate: '3999-12-31',
    name: 'SRI LANKA',
  },

  {
    tisn: '148',
    tisa: 'TD',
    endDate: '3999-12-31',
    name: 'CHAD',
  },

  {
    tisn: '152',
    tisa: 'CL',
    endDate: '3999-12-31',
    name: 'CHILE',
  },

  {
    tisn: '156',
    tisa: 'CN',
    endDate: '3999-12-31',
    name: 'CHINA',
  },

  {
    tisn: '158',
    tisa: 'TW',
    endDate: '3999-12-31',
    name: 'TAIWAN, PROVINCE OF CHINA',
  },

  {
    tisn: '170',
    tisa: 'CO',
    endDate: '3999-12-31',
    name: 'COLOMBIA',
  },

  {
    tisn: '174',
    tisa: 'KM',
    endDate: '3999-12-31',
    name: 'COMOROS',
  },

  {
    tisn: '178',
    tisa: 'CG',
    endDate: '3999-12-31',
    name: 'CONGO',
  },

  {
    tisn: '180',
    tisa: 'ZR',
    endDate: '1997-05-29',
    name: 'ZAIRE',
  },

  {
    tisn: '180',
    tisa: 'CD',
    endDate: '3999-12-31',
    name: 'CONGO, THE DEMOCRATIC REPUBLIC OF THE',
  },

  {
    tisn: '188',
    tisa: 'CR',
    endDate: '3999-12-31',
    name: 'COSTA RICA',
  },

  {
    tisn: '191',
    tisa: 'HR',
    endDate: '3999-12-31',
    name: 'CROATIA',
  },

  {
    tisn: '192',
    tisa: 'CU',
    endDate: '3999-12-31',
    name: 'CUBA',
  },

  {
    tisn: '196',
    tisa: 'CY',
    endDate: '3999-12-31',
    name: 'CYPRUS',
  },

  {
    tisn: '200',
    tisa: 'CS',
    endDate: '1992-12-31',
    name: 'CZECHOSLOVAKIA',
  },

  {
    tisn: '203',
    tisa: 'CZ',
    endDate: '3999-12-31',
    name: 'CZECH REPUBLIC',
  },

  {
    tisn: '204',
    tisa: 'BJ',
    endDate: '3999-12-31',
    name: 'BENIN',
  },

  {
    tisn: '208',
    tisa: 'DK',
    endDate: '3999-12-31',
    name: 'DENMARK',
  },

  {
    tisn: '212',
    tisa: 'DM',
    endDate: '3999-12-31',
    name: 'DOMINICA',
  },

  {
    tisn: '214',
    tisa: 'DO',
    endDate: '3999-12-31',
    name: 'DOMINICAN REPUBLIC',
  },

  {
    tisn: '218',
    tisa: 'EC',
    endDate: '3999-12-31',
    name: 'ECUADOR',
  },

  {
    tisn: '222',
    tisa: 'SV',
    endDate: '3999-12-31',
    name: 'EL SALVADOR',
  },

  {
    tisn: '226',
    tisa: 'GQ',
    endDate: '3999-12-31',
    name: 'EQUATORIAL GUINEA',
  },
  {
    tisn: '230',
    tisa: 'ET',
    endDate: '1993-05-23',
    name: 'ETHIOPIA',
  },
  {
    tisn: '231',
    tisa: 'ET',
    endDate: '3999-12-31',
    name: 'ETHIOPIA',
  },

  {
    tisn: '232',
    tisa: 'ER',
    endDate: '3999-12-31',
    name: 'ERITREA',
  },

  {
    tisn: '233',
    tisa: 'EE',
    endDate: '3999-12-31',
    name: 'ESTONIA',
  },

  {
    tisn: '242',
    tisa: 'FJ',
    endDate: '3999-12-31',
    name: 'FIJI',
  },

  {
    tisn: '246',
    tisa: 'FI',
    endDate: '3999-12-31',
    name: 'FINLAND',
  },

  {
    tisn: '250',
    tisa: 'FR',
    endDate: '3999-12-31',
    name: 'FRANCE',
  },

  {
    tisn: '258',
    tisa: 'PF',
    endDate: '3999-12-31',
    name: 'FRENCH POLYNESIA',
  },

  {
    tisn: '262',
    tisa: 'DJ',
    endDate: '3999-12-31',
    name: 'DJIBOUTI',
  },

  {
    tisn: '266',
    tisa: 'GA',
    endDate: '3999-12-31',
    name: 'GABON',
  },

  {
    tisn: '268',
    tisa: 'GE',
    endDate: '3999-12-31',
    name: 'GEORGIA',
  },

  {
    tisn: '270',
    tisa: 'GM',
    endDate: '3999-12-31',
    name: 'GAMBIA',
  },

  {
    tisn: '276',
    tisa: 'DE',
    endDate: '3999-12-31',
    name: 'GERMANY',
  },

  {
    tisn: '278',
    tisa: 'DD',
    endDate: '1990-10-02',
    name: 'GERMAN DEMOCRATIC REPUBLIC',
  },

  {
    tisn: '288',
    tisa: 'GH',
    endDate: '3999-12-31',
    name: 'GHANA',
  },

  {
    tisn: '280',
    tisa: 'DE',
    endDate: '1990-10-02',
    name: 'GERMANY',
  },

  {
    tisn: '296',
    tisa: 'KI',
    endDate: '3999-12-31',
    name: 'KIRIBATI',
  },

  {
    tisn: '300',
    tisa: 'GR',
    endDate: '3999-12-31',
    name: 'GREECE',
  },

  {
    tisn: '308',
    tisa: 'GD',
    endDate: '3999-12-31',
    name: 'GRENADA',
  },

  {
    tisn: '320',
    tisa: 'GT',
    endDate: '3999-12-31',
    name: 'GUATEMALA',
  },

  {
    tisn: '324',
    tisa: 'GN',
    endDate: '3999-12-31',
    name: 'GUINEA',
  },

  {
    tisn: '328',
    tisa: 'GY',
    endDate: '3999-12-31',
    name: 'GUYANA',
  },

  {
    tisn: '332',
    tisa: 'HT',
    endDate: '3999-12-31',
    name: 'HAITI',
  },

  {
    tisn: '336',
    tisa: 'VA',
    endDate: '3999-12-31',
    name: 'HOLY SEE (VATICAN CITY STATE)',
  },

  {
    tisn: '340',
    tisa: 'HN',
    endDate: '3999-12-31',
    name: 'HONDURAS',
  },

  {
    tisn: '344',
    tisa: 'HK',
    endDate: '3999-12-31',
    name: 'HONG KONG',
  },

  {
    tisn: '348',
    tisa: 'HU',
    endDate: '3999-12-31',
    name: 'HUNGARY',
  },

  {
    tisn: '352',
    tisa: 'IS',
    endDate: '3999-12-31',
    name: 'ICELAND',
  },

  {
    tisn: '356',
    tisa: 'IN',
    endDate: '3999-12-31',
    name: 'INDIA',
  },

  {
    tisn: '360',
    tisa: 'ID',
    endDate: '3999-12-31',
    name: 'INDONESIA',
  },

  {
    tisn: '364',
    tisa: 'IR',
    endDate: '3999-12-31',
    name: 'IRAN, ISLAMIC REPUBLIC OF',
  },

  {
    tisn: '368',
    tisa: 'IQ',
    endDate: '3999-12-31',
    name: 'IRAQ',
  },

  {
    tisn: '372',
    tisa: 'IE',
    endDate: '3999-12-31',
    name: 'IRELAND',
  },

  {
    tisn: '376',
    tisa: 'IL',
    endDate: '3999-12-31',
    name: 'ISRAEL',
  },

  {
    tisn: '380',
    tisa: 'IT',
    endDate: '3999-12-31',
    name: 'ITALY',
  },

  {
    tisn: '384',
    tisa: 'CI',
    endDate: '3999-12-31',
    name: "COTE D'IVOIRE",
  },

  {
    tisn: '388',
    tisa: 'JM',
    endDate: '3999-12-31',
    name: 'JAMAICA',
  },

  {
    tisn: '392',
    tisa: 'JP',
    endDate: '3999-12-31',
    name: 'JAPAN',
  },

  {
    tisn: '398',
    tisa: 'KZ',
    endDate: '3999-12-31',
    name: 'KAZAKHSTAN',
  },

  {
    tisn: '400',
    tisa: 'JO',
    endDate: '3999-12-31',
    name: 'JORDAN',
  },

  {
    tisn: '404',
    tisa: 'KE',
    endDate: '3999-12-31',
    name: 'KENYA',
  },

  {
    tisn: '408',
    tisa: 'KP',
    endDate: '3999-12-31',
    name: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
  },

  {
    tisn: '410',
    tisa: 'KR',
    endDate: '3999-12-31',
    name: 'KOREA, REPUBLIC OF',
  },

  {
    tisn: '414',
    tisa: 'KW',
    endDate: '3999-12-31',
    name: 'KUWAIT',
  },

  {
    tisn: '417',
    tisa: 'KG',
    endDate: '3999-12-31',
    name: 'KYRGYZSTAN',
  },

  {
    tisn: '418',
    tisa: 'LA',
    endDate: '3999-12-31',
    name: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
  },

  {
    tisn: '422',
    tisa: 'LB',
    endDate: '3999-12-31',
    name: 'LEBANON',
  },

  {
    tisn: '426',
    tisa: 'LS',
    endDate: '3999-12-31',
    name: 'LESOTHO',
  },

  {
    tisn: '428',
    tisa: 'LV',
    endDate: '3999-12-31',
    name: 'LATVIA',
  },

  {
    tisn: '430',
    tisa: 'LR',
    endDate: '3999-12-31',
    name: 'LIBERIA',
  },

  {
    tisn: '434',
    tisa: 'LY',
    endDate: '3999-12-31',
    name: 'LIBYAN ARAB JAMAHIRIYA',
  },

  {
    tisn: '438',
    tisa: 'LI',
    endDate: '3999-12-31',
    name: 'LIECHTENSTEIN',
  },

  {
    tisn: '440',
    tisa: 'LT',
    endDate: '3999-12-31',
    name: 'LITHUANIA',
  },

  {
    tisn: '442',
    tisa: 'LU',
    endDate: '3999-12-31',
    name: 'LUXEMBOURG',
  },

  {
    tisn: '446',
    tisa: 'MO',
    endDate: '3999-12-31',
    name: 'MACAO',
  },

  {
    tisn: '450',
    tisa: 'MG',
    endDate: '3999-12-31',
    name: 'MADAGASCAR',
  },

  {
    tisn: '454',
    tisa: 'MW',
    endDate: '3999-12-31',
    name: 'MALAWI',
  },

  {
    tisn: '458',
    tisa: 'MY',
    endDate: '3999-12-31',
    name: 'MALAYSIA',
  },

  {
    tisn: '462',
    tisa: 'MV',
    endDate: '3999-12-31',
    name: 'MALDIVES',
  },

  {
    tisn: '466',
    tisa: 'ML',
    endDate: '3999-12-31',
    name: 'MALI',
  },

  {
    tisn: '470',
    tisa: 'MT',
    endDate: '3999-12-31',
    name: 'MALTA',
  },

  {
    tisn: '478',
    tisa: 'MR',
    endDate: '3999-12-31',
    name: 'MAURITANIA',
  },

  {
    tisn: '480',
    tisa: 'MU',
    endDate: '3999-12-31',
    name: 'MAURITIUS',
  },

  {
    tisn: '484',
    tisa: 'MX',
    endDate: '3999-12-31',
    name: 'MEXICO',
  },

  {
    tisn: '492',
    tisa: 'MC',
    endDate: '3999-12-31',
    name: 'MONACO',
  },

  {
    tisn: '496',
    tisa: 'MN',
    endDate: '3999-12-31',
    name: 'MONGOLIA',
  },

  {
    tisn: '498',
    tisa: 'MD',
    endDate: '3999-12-31',
    name: 'MOLDOVA, REPUBLIC OF',
  },

  {
    tisn: '499',
    tisa: 'ME',
    endDate: '3999-12-31',
    name: 'MONTENEGRO',
  },

  {
    tisn: '504',
    tisa: 'MA',
    endDate: '3999-12-31',
    name: 'MOROCCO',
  },

  {
    tisn: '508',
    tisa: 'MZ',
    endDate: '3999-12-31',
    name: 'MOZAMBIQUE',
  },

  {
    tisn: '512',
    tisa: 'OM',
    endDate: '3999-12-31',
    name: 'OMAN',
  },

  {
    tisn: '516',
    tisa: 'NA',
    endDate: '3999-12-31',
    name: 'NAMIBIA',
  },

  {
    tisn: '520',
    tisa: 'NR',
    endDate: '3999-12-31',
    name: 'NAURU',
  },

  {
    tisn: '524',
    tisa: 'NP',
    endDate: '3999-12-31',
    name: 'NEPAL',
  },

  {
    tisn: '528',
    tisa: 'NL',
    endDate: '3999-12-31',
    name: 'NETHERLANDS',
  },

  {
    tisn: '540',
    tisa: 'NC',
    endDate: '3999-12-31',
    name: 'NEW CALEDONIA',
  },

  {
    tisn: '548',
    tisa: 'VU',
    endDate: '3999-12-31',
    name: 'VANUATU',
  },

  {
    tisn: '554',
    tisa: 'NZ',
    endDate: '3999-12-31',
    name: 'NEW ZEALAND',
  },

  {
    tisn: '558',
    tisa: 'NI',
    endDate: '3999-12-31',
    name: 'NICARAGUA',
  },

  {
    tisn: '562',
    tisa: 'NE',
    endDate: '3999-12-31',
    name: 'NIGER',
  },

  {
    tisn: '566',
    tisa: 'NG',
    endDate: '3999-12-31',
    name: 'NIGERIA',
  },

  {
    tisn: '578',
    tisa: 'NO',
    endDate: '3999-12-31',
    name: 'NORWAY',
  },

  {
    tisn: '583',
    tisa: 'FM',
    endDate: '3999-12-31',
    name: 'MICRONESIA, FEDERATED STATES OF',
  },

  {
    tisn: '584',
    tisa: 'MH',
    endDate: '3999-12-31',
    name: 'MARSHALL ISLANDS',
  },

  {
    tisn: '585',
    tisa: 'PW',
    endDate: '3999-12-31',
    name: 'PALAU',
  },

  {
    tisn: '586',
    tisa: 'PK',
    endDate: '3999-12-31',
    name: 'PAKISTAN',
  },

  {
    tisn: '591',
    tisa: 'PA',
    endDate: '3999-12-31',
    name: 'PANAMA',
  },

  {
    tisn: '598',
    tisa: 'PG',
    endDate: '3999-12-31',
    name: 'PAPUA NEW GUINEA',
  },

  {
    tisn: '600',
    tisa: 'PY',
    endDate: '3999-12-31',
    name: 'PARAGUAY',
  },

  {
    tisn: '604',
    tisa: 'PE',
    endDate: '3999-12-31',
    name: 'PERU',
  },

  {
    tisn: '608',
    tisa: 'PH',
    endDate: '3999-12-31',
    name: 'PHILIPPINES',
  },

  {
    tisn: '616',
    tisa: 'PL',
    endDate: '3999-12-31',
    name: 'POLAND',
  },

  {
    tisn: '620',
    tisa: 'PT',
    endDate: '3999-12-31',
    name: 'PORTUGAL',
  },

  {
    tisn: '624',
    tisa: 'GW',
    endDate: '3999-12-31',
    name: 'GUINEA-BISSAU',
  },

  {
    tisn: '626',
    tisa: 'TL',
    endDate: '3999-12-31',
    name: 'TIMOR-LESTE',
  },

  {
    tisn: '630',
    tisa: 'PR',
    endDate: '3999-12-31',
    name: 'PUERTO RICO',
  },

  {
    tisn: '634',
    tisa: 'QA',
    endDate: '3999-12-31',
    name: 'QATAR',
  },

  {
    tisn: '642',
    tisa: 'RO',
    endDate: '3999-12-31',
    name: 'ROMANIA',
  },

  {
    tisn: '643',
    tisa: 'RU',
    endDate: '3999-12-31',
    name: 'RUSSIAN FEDERATION',
  },

  {
    tisn: '646',
    tisa: 'RW',
    endDate: '3999-12-31',
    name: 'RWANDA',
  },

  {
    tisn: '659',
    tisa: 'KN',
    endDate: '3999-12-31',
    name: 'SAINT KITTS AND NEVIS',
  },

  {
    tisn: '662',
    tisa: 'LC',
    endDate: '3999-12-31',
    name: 'SAINT LUCIA',
  },

  {
    tisn: '670',
    tisa: 'VC',
    endDate: '3999-12-31',
    name: 'SAINT VINCENT AND THE GRENADINES',
  },

  {
    tisn: '674',
    tisa: 'SM',
    endDate: '3999-12-31',
    name: 'SAN MARINO',
  },

  {
    tisn: '678',
    tisa: 'ST',
    endDate: '3999-12-31',
    name: 'SAO TOME AND PRINCIPE',
  },

  {
    tisn: '682',
    tisa: 'SA',
    endDate: '3999-12-31',
    name: 'SAUDI ARABIA',
  },

  {
    tisn: '686',
    tisa: 'SN',
    endDate: '3999-12-31',
    name: 'SENEGAL',
  },

  {
    tisn: '688',
    tisa: 'RS',
    endDate: '3999-12-31',
    name: 'SERBIA',
  },

  {
    tisn: '690',
    tisa: 'SC',
    endDate: '3999-12-31',
    name: 'SEYCHELLES',
  },

  {
    tisn: '694',
    tisa: 'SL',
    endDate: '3999-12-31',
    name: 'SIERRA LEONE',
  },

  {
    tisn: '702',
    tisa: 'SG',
    endDate: '3999-12-31',
    name: 'SINGAPORE',
  },

  {
    tisn: '703',
    tisa: 'SK',
    endDate: '3999-12-31',
    name: 'SLOVAKIA',
  },

  {
    tisn: '704',
    tisa: 'VN',
    endDate: '3999-12-31',
    name: 'VIET NAM',
  },

  {
    tisn: '705',
    tisa: 'SI',
    endDate: '3999-12-31',
    name: 'SLOVENIA',
  },

  {
    tisn: '706',
    tisa: 'SO',
    endDate: '3999-12-31',
    name: 'SOMALIA',
  },

  {
    tisn: '710',
    tisa: 'ZA',
    endDate: '3999-12-31',
    name: 'SOUTH AFRICA',
  },
  {
    tisn: '716',
    tisa: 'ZW',
    endDate: '3999-12-31',
    name: 'ZIMBABWE',
  },

  {
    tisn: '720',
    tisa: 'YD',
    endDate: '1990-05-21',
    name: 'YEMEN, DEMOCRATIC',
  },

  {
    tisn: '724',
    tisa: 'ES',
    endDate: '3999-12-31',
    name: 'SPAIN',
  },
  {
    tisn: '728',
    tisa: 'SS',
    endDate: '3999-12-31',
    name: 'SOUTH SUDAN',
  },
  {
    tisn: '729',
    tisa: 'SD',
    endDate: '3999-12-31',
    name: 'SUDAN',
  },
  {
    tisn: '732',
    tisa: 'EH',
    endDate: '3999-12-31',
    name: 'WESTERN SAHARA',
  },
  {
    tisn: '736',
    tisa: 'SD',
    endDate: '2011-07-08',
    name: 'SUDAN',
  },
  {
    tisn: '740',
    tisa: 'SR',
    endDate: '3999-12-31',
    name: 'SURINAME',
  },
  {
    tisn: '748',
    tisa: 'SZ',
    endDate: '3999-12-31',
    name: 'SWAZILAND',
  },
  {
    tisn: '752',
    tisa: 'SE',
    endDate: '3999-12-31',
    name: 'SWEDEN',
  },
  {
    tisn: '756',
    tisa: 'CH',
    endDate: '3999-12-31',
    name: 'SWITZERLAND',
  },
  {
    tisn: '760',
    tisa: 'SY',
    endDate: '3999-12-31',
    name: 'SYRIAN ARAB REPUBLIC',
  },
  {
    tisn: '762',
    tisa: 'TJ',
    endDate: '3999-12-31',
    name: 'TAJIKISTAN',
  },
  {
    tisn: '764',
    tisa: 'TH',
    endDate: '3999-12-31',
    name: 'THAILAND',
  },
  {
    tisn: '768',
    tisa: 'TG',
    endDate: '3999-12-31',
    name: 'TOGO',
  },
  {
    tisn: '776',
    tisa: 'TO',
    endDate: '3999-12-31',
    name: 'TONGA',
  },
  {
    tisn: '780',
    tisa: 'TT',
    endDate: '3999-12-31',
    name: 'TRINIDAD AND TOBAGO',
  },
  {
    tisn: '784',
    tisa: 'AE',
    endDate: '3999-12-31',
    name: 'UNITED ARAB EMIRATES',
  },
  {
    tisn: '788',
    tisa: 'TN',
    endDate: '3999-12-31',
    name: 'TUNISIA',
  },
  {
    tisn: '792',
    tisa: 'TR',
    endDate: '3999-12-31',
    name: 'TURKEY',
  },
  {
    tisn: '795',
    tisa: 'TM',
    endDate: '3999-12-31',
    name: 'TURKMENISTAN',
  },
  {
    tisn: '798',
    tisa: 'TV',
    endDate: '3999-12-31',
    name: 'TUVALU',
  },
  {
    tisn: '800',
    tisa: 'UG',
    endDate: '3999-12-31',
    name: 'UGANDA',
  },
  {
    tisn: '804',
    tisa: 'UA',
    endDate: '3999-12-31',
    name: 'UKRAINE',
  },
  {
    tisn: '807',
    tisa: 'MK',
    endDate: '3999-12-31',
    name: 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF',
  },
  {
    tisn: '810',
    tisa: 'SU',
    endDate: '1991-12-31',
    name: 'USSR',
  },
  {
    tisn: '818',
    tisa: 'EG',
    endDate: '3999-12-31',
    name: 'EGYPT',
  },
  {
    tisn: '826',
    tisa: 'GB',
    endDate: '3999-12-31',
    name: 'UNITED KINGDOM',
  },
  {
    tisn: '834',
    tisa: 'TZ',
    endDate: '3999-12-31',
    name: 'TANZANIA, UNITED REPUBLIC OF',
  },
  {
    tisn: '840',
    tisa: 'US',
    endDate: '3999-12-31',
    name: 'UNITED STATES',
  },
  {
    tisn: '854',
    tisa: 'BF',
    endDate: '3999-12-31',
    name: 'BURKINA FASO',
  },
  {
    tisn: '858',
    tisa: 'UY',
    endDate: '3999-12-31',
    name: 'URUGUAY',
  },
  {
    tisn: '860',
    tisa: 'UZ',
    endDate: '3999-12-31',
    name: 'UZBEKISTAN',
  },
  {
    tisn: '862',
    tisa: 'VE',
    endDate: '3999-12-31',
    name: 'VENEZUELA',
  },
  {
    tisn: '882',
    tisa: 'WS',
    endDate: '1997-10-08',
    name: 'SAMOA',
  },
  {
    tisn: '882',
    tisa: 'WS',
    endDate: '3999-12-31',
    name: 'SAMOA',
  },
  {
    tisn: '886',
    tisa: 'YE',
    endDate: '1990-05-21',
    name: 'YEMEN',
  },
  {
    tisn: '887',
    tisa: 'YE',
    endDate: '3999-12-31',
    name: 'YEMEN',
  },
  {
    tisn: '894',
    tisa: 'ZM',
    endDate: '3999-12-31',
    name: 'ZAMBIA',
  },
  {
    tisn: '890',
    tisa: 'YU',
    endDate: '1991-12-31',
    name: 'YUGOSLAVIA',
  },
  {
    tisn: '891',
    tisa: 'CS',
    endDate: '2006-06-02',
    name: 'SERBIA AND MONTENEGRO',
  },
];
