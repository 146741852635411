import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DataTableRow, SortInfo } from '@ice/components/data-table/data-table';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import { locale as englishTabs } from 'assets/i18n/en/config/tabs-data-builders';
import { DataTableBuilder } from 'config/data-table-builders/data-table-builder';
import { RootDatatable } from 'config/data-table-builders/root-datatable';

export class UserManagementOrganizationsDataTable extends RootDatatable implements DataTableBuilder {
  constructor(protected translate: TranslateService, protected fuseTranslationLoader: FuseTranslationLoaderService) {
    super(translate);
    this.fuseTranslationLoader.loadTranslations(english);
    this.fuseTranslationLoader.loadTranslations(englishTabs);
  }

  getDefaultSorting(): SortInfo[] {
    return [{ prop: 'name', dir: 'asc' }];
  }

  formatSort(sort: SortInfo) {
    return { prop: `attributes.${sort.prop}`, dir: sort.dir };
  }

  getDataTable(): DataTableRow[] {
    return [
      { name: this.translate.instant('ORGANIZATIONS.NAME'), prop: 'name', cellClass: 'ice-txt-size-14 organization-name', flexGrow: 1 },
      { name: this.translate.instant('ORGANIZATIONS.ID'), prop: 'id', cellClass: 'ice-txt-size-14 organization-id', flexGrow: 1 },
      { name: this.translate.instant('ORGANIZATIONS.TYPE'), prop: 'type', cellClass: 'ice-txt-size-14 organization-type', flexGrow: 1 },
    ];
  }

  getColumnMode(): ColumnMode {
    return ColumnMode.flex;
  }

  displayCheck(row: any, firstSelectedItem: any, targetItem: any, column?: any, value?: any): boolean {
    return true;
  }
}
