import { SharePictureTableSelectFunctions } from '@ice';
import { Observable } from 'rxjs';
import { CardWithSharesCore } from '../card-with-shares/card-with-shares';
import { SortInfo } from '../data-table/data-table';

export interface CardWithSharesRepertoire extends CardWithSharesCore {
  shareTypes: ShareTypeRepertoireOption[];
  table: SharesTableRepertoire;
  totals?: SharesTotalsRepertoire;
}

interface ShareTypeRepertoireOption {
  type: SharePictureRepertoireTypes;
  disabled?: Observable<boolean>;
  checked?: boolean;
  label: string;
}

export type SharePictureRepertoireType = 'repertoire';

export type SharesPictureRepertoireTypeMap<T> = {
  [key in SharePictureRepertoireType]?: T;
} & {
  default: T;
};

export enum SharePictureRepertoireTypes {
  REPERTOIRE = 'repertoire',
}

interface SharesTotalsRepertoire {
  totalParser: (rows: any) => any;
  publisherSubTotalLabel: string;
  writerSubTotalLabel: string;
  specialSubTotalLabel: string;
  totalClaimsLabel: string;
  class?: SharesPictureRepertoireTypeMap<Observable<string>>;
}

export interface SharesTableRepertoire {
  model: SharesPictureRepertoireTypeMap<Observable<any[]>>;
  visibleColumns: SharesPictureRepertoireTypeMap<Observable<string[]>>;
  schema: any;
  sorts?: SortInfo[];
  onSort?(event: any): void;
  sortReset?: boolean;
  disabledSort?: Observable<boolean>;
  shadowed?: boolean;
  isSelectable?: boolean;
  class?: SharesPictureRepertoireTypeMap<string>;
  selectableClass?: string;
  select: SharesPictureRepertoireTypeMap<SharePictureTableSelectFunctions>;
  selectionType?: Observable<string>;
  requestStatus?: Observable<string>;
}

export interface SharesRepertoireTree {
  model: SharesPictureRepertoireTypeMap<Observable<any[]>>;
  levels: SharesPictureRepertoireTypeMap<Observable<any>>;
  visibility: SharesPictureRepertoireTypeMap<Observable<boolean>>;
}
