import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import * as fromRoot from 'store/root';
import { SectionTabConfig } from '../../tabs-data';

export class TabOrganizationDetail implements SectionTabConfig {
  constructor(private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService, private store: Store<fromRoot.RootState>) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  getConf(): IceGroupComponent[] {
    const firstCardsHeight = '310px';
    return [
      {
        group: [
          {
            type: 'cardWithForm',
            config: {
              title: this.getTranslate('DETAILS_CARD.', 'TITLE'),
              class: 'organization-details',
              model: this.store.pipe(select(fromRoot.getUserManagementOrganizationAttributes)),
              resetAvailable: false,
              submitAvailable: false,
              formBuilder: this.getDetailsFormly(),
              formPercentWidth: 100,
              height: firstCardsHeight,
            },
          },
          {
            type: 'cardWithDataTable',
            config: {
              title: this.getTranslate('ROLES_CARD.', 'TITLE'),
              model: this.store.pipe(select(fromRoot.getUserManagementOrganizationRoles)),
              columnMode: 'flex',
              schema: [
                {
                  name: this.getTranslate('ROLES_CARD.', 'NAME'),
                  prop: 'name',
                  flexGrow: 1,
                },
              ],
              tableWidth: '100',
              height: firstCardsHeight,
            },
          },
        ],
      },
    ];
  }

  private getDetailsFormly() {
    const cardTranslatePath = 'DETAILS_CARD.';
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'id',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.getTranslate(cardTranslatePath, 'ID'), required: false, disabled: true },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'name',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.getTranslate(cardTranslatePath, 'NAME'), required: false, disabled: true },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'type',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.getTranslate(cardTranslatePath, 'TYPE'), required: false, disabled: true },
          },
          {
            className: 'flex-1',
            key: 'creationDate',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.getTranslate(cardTranslatePath, 'CREATION_DATE'), required: false, disabled: true },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'societyString',
            type: 'input',
            hideExpression: model => model.type !== 'society',
            templateOptions: { type: 'text', placeholder: this.getTranslate(cardTranslatePath, 'SOCIETY_STRING'), required: false, disabled: true },
          },
        ],
      },
    ];
  }

  private getTranslate(cardTranslatePath, fieldTranslatePath) {
    return this.translate.instant(`ORGANIZATIONS.DETAILS.${cardTranslatePath}${fieldTranslatePath}`);
  }
}
