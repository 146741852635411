import { DialogSharesUsesTypes } from 'config/dialog-builders/dialog-shares-uses-types';

export const InformedPartyBaseQuery = `{
  "and":[
    <<extra>>
    {"or":[<<pattern>>]},
    {"equals":{"counterclaim.participants.relation":"INFORMED"}},
    {"not":[{"equals":{"counterclaim.attributes.status":"RESOLVED"}}]}
  ]
}`;

export const DashboardTypeQuery = '{"equals":{"attributes.type":"<<type>>"}}';

export const CountersBaseQuery = `{
  "and":[
    <<extra>>
    {"or":[<<pattern>>]},
    {"equals":{"counterclaim.participants.relation":"RESOLVER"}},
    <<typeFilter>>,
    {"equals":{"attributes.status":"OPEN"}},
    {"not":[{"equals":{"counterclaim.attributes.status":"RESOLVED"}}]}
  ]
}`;

// For Publishers, the backend now handles the pattern logic.
// Passing whitespace to the `or` value makes the substatement evaluate true
export const CountersQueryPublishersPattern = ' ';

export enum CountersGroups {
  ACTION_ON_ME = 'ACTION_ON_ME',
  ACTION_ON_ICE = 'ACTION_ON_ICE',
  INFORMED_PARTY = 'INFORMED_PARTY',
}

export const DashboardCountersTypes = [
  { name: 'requestToSupportClaim', apiType: 'REQUEST_TO_SUPPORT_CLAIM', countersGroup: CountersGroups.ACTION_ON_ME },
  { name: 'membersToResolve', apiType: 'MEMBERS_TO_RESOLVE', countersGroup: CountersGroups.ACTION_ON_ME },
  { name: 'claimWithdrawn', apiType: 'WITHDRAW_CLAIM', countersGroup: CountersGroups.ACTION_ON_ICE },
  { name: 'claimNotSupported', apiType: 'CLAIM_NOT_SUPPORTED', countersGroup: CountersGroups.ACTION_ON_ICE },
  { name: 'claimReduced', apiType: 'REDUCE_CLAIM', countersGroup: CountersGroups.ACTION_ON_ICE },
  { name: 'informedParty', apiType: 'INFORMED_PARTY', countersGroup: CountersGroups.INFORMED_PARTY },
];

export const baseCounterClaimQuery = '{"wildcard":{"counterclaim.attributes.entityType":"work"}},';
const InformedPartyQuery = InformedPartyBaseQuery.replace('<<extra>>', baseCounterClaimQuery);
const CountersQuery = CountersBaseQuery.replace('<<extra>>', baseCounterClaimQuery);

export const counterClaimActionsForDashboardQuery = `{
  "and":[
    ${baseCounterClaimQuery}
    {"or":[<<dashboardFilterExpression>>]}
  ]
}`;

export const CounterClaimDashboardCountersConfig = [
  { storeCounterType: 'requestToSupportClaim', apiCounterType: 'REQUEST_TO_SUPPORT_CLAIM', baseQuery: CountersQuery },
  { storeCounterType: 'membersToResolve', apiCounterType: 'MEMBERS_TO_RESOLVE', baseQuery: CountersQuery },
  { storeCounterType: 'claimWithdrawn', apiCounterType: 'WITHDRAW_CLAIM', baseQuery: CountersQuery },
  { storeCounterType: 'claimNotSupported', apiCounterType: 'CLAIM_NOT_SUPPORTED', baseQuery: CountersQuery },
  { storeCounterType: 'claimReduced', apiCounterType: 'REDUCE_CLAIM', baseQuery: CountersQuery },
  { storeCounterType: 'informedParty', apiCounterType: '', baseQuery: InformedPartyQuery },
];

export const SEARCH_TYPE_DASHBOARD = 'dashboard';

export enum CounterClaimActionDialog {
  Withdraw = 'withdraw',
  Reduce = 'reduce',
  Resolve = 'resolve',
  View = 'view',
}

const usesTypes = DialogSharesUsesTypes.getUsesTypes({ instant: () => {} });

export const CounterClaimInitModel = {
  id: '',
  type: '',
  parties: '',
  workId: '',
  version: 1,
  usageTypes: { Performing: usesTypes && usesTypes.Performing.map(right => right.code), Mechanical: usesTypes && usesTypes.Mechanical.map(right => right.code) },
};

export type CounterClaimActionDialogType = CounterClaimActionDialog.Withdraw | CounterClaimActionDialog.Reduce | CounterClaimActionDialog.Resolve | CounterClaimActionDialog.View;

export const reduceVisibleRows = [
  'role',
  'name',
  'prMr',
  'agreementId',
  'territoriesTisa',
  'allRights',
  'pr',
  'reducedPr',
  'mr',
  'reducedMr',
  'reducemr',
  'startDate',
  'endDate',
  'postTermCollectionDate',
  'conflict',
];
