import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IceMaterialModule } from '@ice/material.module';
import { IcePipesModule } from '@ice/pipes/ice.pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { DataTableTotalsComponent } from './data-table-totals.component';

@NgModule({
  imports: [CommonModule, TranslateModule, IceMaterialModule, FlexLayoutModule, IcePipesModule],
  declarations: [DataTableTotalsComponent],
  exports: [DataTableTotalsComponent],
})
export class DataTableTotalsModule {}
