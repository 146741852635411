import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/material';

@Component({
  selector: 'ice-formly-field-button',
  templateUrl: './button-type.component.html',
})
export class FormlyFieldButtonComponent extends FieldType {
  onClick(event, isEnter = false) {
    if (!this.to?.disablePreventDefault) {
      event.preventDefault();
    }
    if (this.to.onClick) {
      this.to.onClick(event, this.field);
    }
  }

  onEnter(event) {
    if (!this.to?.disablePreventDefault) {
      event.preventDefault();
    }
    this.onClick(event, true);
  }
}
