import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { WORK_CLAIM_SHARE_TYPE } from 'config/constants/shares.constants';
import { SharesStepType } from 'config/stepper-builders/common-steps/shares-step-type';
import { StepType } from 'config/stepper-builders/stepper-config';
import { concat } from 'lodash';
import { Subject } from 'rxjs';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';

export class ClaimSharesStep {
  private shareStepType: SharesStepType;

  constructor(
    protected translate: TranslateService,
    protected dialog: MatDialog,
    protected fieldValidatorService: FieldValidatorService,
    protected store: Store<fromRoot.RootState>,
    protected claimsType?: string,
    protected claimShareType?: string,
    protected setLayout?: (layout) => void,
    protected rightsIncludeView$?: Subject<any>,
  ) {
    this.shareStepType = new SharesStepType(
      this.translate,
      this.dialog,
      this.fieldValidatorService,
      this.store,
      this.claimsType,
      this.claimShareType,
      this.setLayout,
      this.rightsIncludeView$,
    );
  }

  isWorkClaimScreen() {
    return this.claimsType === 'WORK_CLAIMS';
  }

  onInitForm(form, field) {}

  onDestroyForm() {}

  getStep(isEditScreen: boolean = false): StepType {
    const addOwnershipShares = isEditScreen ? false : !this.isWorkClaimScreen();
    return {
      label:
        (!this.isWorkClaimScreen() && this.translate.instant('AGREEMENTS.SHARES.TITLE')) ||
        (this.claimShareType === WORK_CLAIM_SHARE_TYPE.SHARES && this.translate.instant('AGREEMENTS.SHARES.TITLE_CLAIMED_SHARES')) ||
        (this.claimShareType === WORK_CLAIM_SHARE_TYPE.OWNERSHIP && this.translate.instant('AGREEMENTS.SHARES.TITLE_OWNER_SHARES')),
      formBuilder: concat(this.shareStepType.getMsgValidator(), this.shareStepType.getRepeatSectionFormConfig(true, false, addOwnershipShares)),
    };
  }
}
