import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { WORK_CLAIM_SHARE_TYPE } from 'config/constants/shares.constants';
import { Subject } from 'rxjs';
import { PermissionsService } from 'services/permissions/permissions.service';
import { SearchService } from 'services/search/search.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromNewSectionItem from 'store/new-section-item';
import * as fromRoot from 'store/root';
import type { StepType } from '../stepper-config';
import { ClaimDetailsStep } from './steps/claim-details.step';
import { ClaimSharesStep } from './steps/claim-shares.step';

export class DialogClaimSteps {
  static getSteps(
    translate: TranslateService,
    translationLoader: FuseTranslationLoaderService,
    store: Store<fromRoot.RootState>,
    storeNewItem: Store<fromNewSectionItem.NewSectionItemState>,
    fieldValidatorService: FieldValidatorService,
    searchService: SearchService,
    dialog: MatDialog,
    permissionsService: PermissionsService,
    setLayout: (layout, close?) => void,
    rightsIncludeView$: Subject<any>,
  ): StepType[] {
    return [
      new ClaimDetailsStep(translate, dialog, fieldValidatorService, store, storeNewItem, translationLoader, permissionsService, 'WORK_CLAIMS').getStep(),
      new ClaimSharesStep(translate, dialog, fieldValidatorService, store, 'WORK_CLAIMS', WORK_CLAIM_SHARE_TYPE.SHARES, setLayout, rightsIncludeView$).getStep(),
      new ClaimSharesStep(translate, dialog, fieldValidatorService, store, 'WORK_CLAIMS', WORK_CLAIM_SHARE_TYPE.OWNERSHIP, setLayout, rightsIncludeView$).getStep(),
    ];
  }
}
