import { AgreementUtils } from '../agreement/agreement.utils';

export class AgreementGroupUtils {
  static getAgreementsByType(agreements, type, translate) {
    const agreementMap = {};
    const list = AgreementUtils.cleanAgreements(
      agreements.filter(agreement => agreement.relation === type).map(agreement => agreement.agreement),
      translate,
    );
    return list.filter(agreement => {
      if (!agreementMap[agreement.key]) {
        agreementMap[agreement.key] = true;
        return true;
      }
      return false;
    });
  }
}
