import { DictionaryCodeItem } from 'models/dictionaryCodeItem';
import { ICE_ONLINE_SERVICES_VALUE } from './activity.constants';

export const categorySocietiesCode: DictionaryCodeItem[] = [
  { code: '023O ', name: 'BUMA_STEMRA_ONLINE' },
  { code: '023P ', name: 'BUMA_PERFORMING' },
  { code: '040 ', name: 'KODA_PERFORMING' },
  { code: '040L ', name: 'KODA_ON_LINE' },
  { code: '040M ', name: 'KODA_MECHANICAL' },
  { code: '040O ', name: 'STIM_KODA_ONLINE' },
  { code: '040P ', name: 'STIM_KODA_PERFORMING' },
  { code: '044M ', name: 'MCPS_MECHANICAL' },
  { code: '048M ', name: 'NCB_MECHANICAL' },
  { code: '048O ', name: 'NCB_ONLINE' },
  { code: '052C ', name: 'PRS_CUE_SHEET' },
  { code: '052J', name: 'PRS_MCPS_JOINT' },
  { code: '052O', name: 'PRS_MCPS_ONLINE' },
  { code: '052P', name: 'PRS_PERFORMING' },
  { code: '055J', name: 'SABAM_JOINT' },
  { code: '055M', name: 'SABAM_MECHANICAL' },
  { code: '055O', name: 'SABAM_ON_LINE' },
  { code: '055P', name: 'SABAM_PERFORMING' },
  { code: '077P', name: 'STEF_PERFORMING' },
  { code: '078M', name: 'STEMRA_MECHANICAL' },
  { code: '079K', name: 'STIM_PEL_KOBALT' },
  { code: '079O', name: 'STIM_ONLINE' },
  { code: '079P', name: 'STIM_PERFORMING' },
  { code: '079W', name: 'STIM_PEL_WARNER' },
  { code: '089', name: 'TEOSTO_PERFORMING' },
  { code: '089L', name: 'TEOSTO_ON_LINE' },
  { code: '089M', name: 'TEOSTO_MECHANICAL' },
  { code: '089O', name: 'STIM_TEOSTO_ONLINE' },
  { code: '089P', name: 'STIM_TEOSTO_PERFORMING' },
  { code: '090', name: 'TONO_PERFORMING' },
  { code: '090L', name: 'TONO_ON_LINE' },
  { code: '090M', name: 'TONO_MECHANICAL' },
  { code: '090O', name: 'STIM_TONO_ONLINE' },
  { code: '090P', name: 'STIM_TONO_PERFORMING' },
  { code: '110P', name: 'LATGA_A_PERFORMING' },
  { code: '116P', name: 'EAU_PERFORMING' },
  { code: '122P', name: 'AKKA_LAA_PERFORMING' },
  { code: '319I', name: 'ICE_INTERNAL' },
  { code: ICE_ONLINE_SERVICES_VALUE, name: 'ICE_ONLINE_SERVICES' },
  { code: 'M40', name: 'KODA_PERF_VIA_NCB' },
  { code: 'M40L', name: 'KODA_ON_LINE_VIA_NCB' },
  { code: 'M89', name: 'TEOSTO_PERF_VIA_NCB' },
  { code: '89L', name: 'TEOSTO_ON_LINE_VIA_NCB' },
  { code: 'M90', name: 'TONO_PERF_VIA_NCB' },
  { code: 'M90L', name: 'TONO_ON_LINE_VIA_NCB' },
];
