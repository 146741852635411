import { LinkedTabsName } from 'config/constants/ips.constants';
import { last } from 'lodash';
import { createLabelResolver } from '../api-call.factory';

export const getAttributesId = createLabelResolver({ id: `attributes.id` });

export const getLinkedIpsLabelResolver = createLabelResolver({
  linkedIps: (detail, user, tabName) => (tabName === LinkedTabsName.Publishers ? 'writer_publishers' : 'publisher_writers'),
  linkedRequestId: (detail, user, tabName) => (tabName === LinkedTabsName.Publishers ? `"writerId": "${detail.id}"` : `"publisherId": "${detail.id}"`),
});

export const getPartyNamesLabelResolver = createLabelResolver({
  id: (detail, user, tabName) => {
    return last(
      (detail?.baseInformation || []).map(baseInformation => {
        if (!baseInformation.deleted) return baseInformation.baseIdWithPrefix;
      }),
    );
  },
});

export const getIpsParentRelationshipPattern = createLabelResolver({
  pattern: detail => `{"and":[
        {"equals":{"relations[XREF].otherId":"<<id>>"}},
        {"phrase":{"relations.otherId":"ICE"}}]}`,
  id: detail => {
    const parent = (detail?.parentRelations || []).map(relation => {
      if (!relation.deleted) return relation.otherNameId;
    });
    return !!parent[0] ? parent : [''];
  },
});

export const getIpsChildrenRelationshipPattern = createLabelResolver({
  pattern: detail => `{"and":[
        {"equals":{"parties.relations.relation":"GR"}},
        {"equals":{"parties.relations.otherId":"<<id>>"}},
        {"wildcard":{"relations[XREF].otherId":"ICE:*"}},
        {"phrase":{"relations.otherId":"ICE"}}]}`,
});

export const mergeIpData = createLabelResolver({
  ns: detail => detail.ns,
  id: detail => detail.id,
});

export const bulkUpdatesLabelResolver = createLabelResolver({
  userName: (detail, user) => {
    return user?.userName;
  },
});
