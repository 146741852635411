<mat-card
  class="ice-p-0 ice-h-auto mat-card"
  fxflex="100"
  style="flex: 1 1 100%; box-sizing: border-box; max-width: 100%"
  fusePerfectScrollbar
  [fusePerfectScrollbarOptions]="{ scrollXMarginOffset: 10, scrollYMarginOffset: 10 }"
>
  <mat-card-header class="ice-light-grey-bg card-expanded-header">
    <mat-card-title class="card-header-title mat-card-title" *ngIf="config.title">
      {{ config.title }}
      <span *ngIf="config.errorMsg && config.errorMsg[selectedType] | async" class="ice-card-title-error"> ({{ config.errorMsg && config.errorMsg[selectedType] | async }}) </span>
    </mat-card-title>

    <div class="options" *ngIf="config.shareTypes?.length > 2">
      <mat-radio-group [(ngModel)]="selectedType" (ngModelChange)="onSelectType($event)" aria-labelledby="example-radio-group-label" class="example-radio-group">
        <mat-radio-button
          *ngFor="let op of config.shareTypes"
          [tabindex]="-1"
          [ngClass]="{ disabled: op.disabled || (config.loading | async), hidden: op.hidden | async }"
          [checked]="op.checked"
          [value]="op.type"
        >
          {{ op.label | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="options" *ngIf="config.headerSwitch; let hs">
      <mat-slide-toggle (change)="hs.switchChange($event)" class="headerSwitch" [checked]="hs.checked" id="headerSwitch">{{ hs.label }}</mat-slide-toggle>
    </div>

    <div *ngIf="config.shareTypes?.length === 2" class="display-flex-row-reverse flex-align-items-center flex-1">
      <div
        class="ice-toggle-right-option ice-toggle-inactive ice-txt-white"
        [ngClass]="{ 'ice-toggle-c-inactive': config.shareTypes[0].type !== selectedType, 'ice-toggle-c-active': config.shareTypes[0].type === selectedType }"
      >
        {{ config.shareTypes[0]?.label }}
      </div>

      <mat-slide-toggle
        class="mat-slide-toggle-either-or example-margin"
        [checked]="config.shareTypes[0]?.checked"
        [disabled]="config.shareTypes[1]?.disabled || config.shareTypes[0]?.disabled || (config.loading | async)"
        (change)="onToggleChange($event)"
      ></mat-slide-toggle>
      <div
        class="ice-toggle-left-option ice-txt-white"
        [ngClass]="{ 'ice-toggle-c-inactive': config.shareTypes[1].type !== selectedType, 'ice-toggle-c-active': config.shareTypes[1].type === selectedType }"
      >
        {{ config.shareTypes[1]?.label }}
      </div>
    </div>
  </mat-card-header>

  <mat-card-content class="shares-content">
    <ice-form
      fxFlex="100"
      *ngIf="config.filter"
      [resetAvailable]="config.filter.resetAvailable"
      [submitAvailable]="config.filter.submitAvailable"
      [formBuilder]="config.filter.formBuilder"
      [model]="config.filter.model | async"
      [submitLabel]="config.filter.submitLabel"
      [extraActionAvailable]="(config.filter.extraActionAvailable | async) && config.filter.extraActionShowOn[selectedType]"
      [extraActionLabel]="config.filter.extraActionLabel"
      [forceSubmitDisabled]="config.filter.forceSubmitDisabled && (config.filter.forceSubmitDisabled[selectedType] || config.filter.forceSubmitDisabled['default'])"
      [submitShortcutEnable]="config.filter.submitShortcutEnable"
      [formInline]="config.filter.formInline"
      [ngClass]="config.filter.formInline ? 'bg-filter-form' : ''"
      [ngClass]="config.filter.formClass"
      [className]="config.filter.className"
      [avoidResetModel]="config.filter.avoidResetModel"
      (submit)="config.filter.onSubmit($event)"
      (extraActionClick)="config.filter.onExtraActionClick($event)"
      (resetClick)="config.filter.onReset($event)"
    >
    </ice-form>

    <div class="overWidget ice-spinner-center" *ngIf="config.loading | async">
      <div class="spinner-container">
        <mat-spinner [diameter]="70"></mat-spinner>
      </div>
    </div>

    <div *ngIf="config && config.extraButtons; let extraButtons" class="extraButtons">
      <ng-container *ngFor="let button of extraButtons">
        <button
          *ngIf="!button?.templateOptions?.isHidden || (button?.templateOptions?.isHidden && !(button?.templateOptions?.isHidden | async))"
          mat-stroked-button
          class="btn btn-mat-button z-100 ice-mr-20 extraButton"
          [color]="button?.templateOptions?.btnType"
          [ngClass]="button?.templateOptions?.class"
          (click)="button?.templateOptions?.onClick && button?.templateOptions?.onClick($event)"
          [disabled]="button?.templateOptions?.disabled"
        >
          <mat-icon *ngIf="button?.templateOptions?.icon">{{ button?.templateOptions?.icon }}</mat-icon>
          <span class="ice-ml-5"></span>{{ button?.templateOptions?.text }}
        </button>
      </ng-container>

      <button
        mat-flat-button
        class="ice-accent ice-text-button sort-reset z-100 ice-ml-24"
        *ngIf="config.table.sortReset && !isFront"
        [disabled]="config.table.disabledSort | async"
        (click)="onResetSort()"
      >
        {{ 'RESET_SORT' | translate }}<mat-icon class="ice-ml-8">restart_alt</mat-icon>
      </button>

      <ice-widget-switch
        *ngIf="config?.widgetSwitch?.model; let widgetSwitchModel"
        [model]="widgetSwitchModel"
        (isFront)="setIsFront($event)"
        [fixedFace]="fixedFace | async"
        class="widget-no-scroll"
        #iceWidgetSwitch
      >
        <div class="pt-8 pb-32 ice-min-h-400 widget-switch-front" fxLayout="column" fxLayoutAlign="start start" fusePerfectScrollbar>
          <ng-container *ngTemplateOutlet="tree"></ng-container>
        </div>
        <div class="widget-switch-back">
          <ng-container *ngTemplateOutlet="headertotals"></ng-container>
          <ng-container *ngTemplateOutlet="datatable"></ng-container>
          <ng-container *ngTemplateOutlet="totals"></ng-container>
        </div>
      </ice-widget-switch>
    </div>

    <ng-container *ngIf="!config?.widgetSwitch">
      <div class="shares-card-body">
        <ng-container *ngTemplateOutlet="headertotals"></ng-container>
        <ng-container *ngTemplateOutlet="datatable"></ng-container>
        <ng-container *ngTemplateOutlet="totals"></ng-container>
      </div>
    </ng-container>
  </mat-card-content>
</mat-card>

<ng-template #tree>
  <ice-tree-chart
    fxFlex
    *ngIf="isFront && (treeVisibility | async) && !(config.loading | async) && config.tree && treeData && (treeData | async).length"
    [_data]="treeData | async"
    [verticalLevels]="(treeLevels | async)?.vLevel"
    [horizontalLevels]="(treeLevels | async)?.maxHLevel"
  ></ice-tree-chart>
</ng-template>

<ng-template #datatable>
  <ice-data-table
    *ngIf="canShow && !(config.loading | async) && config.table; let table"
    fxFlex="100"
    [ngClass]="table.class && (table.class[selectedType] || table.class['default'])"
    [data]="tableData | async"
    [requestStatus]="requestStatus | async"
    [visibleColumns]="tableVisibleColumns"
    [sorts]="table.sorts"
    (sortRowEmit)="table && table.onSort && table.onSort($event)"
    [schema]="table.schema"
    [columnMode]="'flex'"
    [hideEmpty]="true"
    [shadowed]="table.shadowed"
    [reorderable]="false"
    [selectionType]="(table.selectionType | async) || 'single'"
    [isSelectable]="table.select && (table.select[selectedType]?.onSelect || table.select['default']?.onSelect)"
    [headerTitle]="table.headerTitle"
    [headerClass]="table.headerClass"
    (selectedRowEmit)="table.select && (table.select[selectedType]?.onSelect($event) || table.select['default']?.onSelect($event))"
    (mouseWheelClickRowEmit)="table.select && (table.select[selectedType]?.onMouseSelect($event) || table.select['default']?.onMouseSelect($event))"
  >
  </ice-data-table>
  <div *ngIf="(requestStatus | async) === 203 && selectedType !== 'manuscript'" class="no-results">
    {{ config.noPermissionTitle }}
  </div>
</ng-template>

<ng-template #headertotals>
  <mat-grid-list *ngIf="canShow && !(config.loading | async) && claimTotals && config.totals; let totals" cols="4" rowHeight="40px" class="totals" [ngClass]="totalsClass | async">
    <mat-grid-tile *ngFor="let tile of claimTotals" [colspan]="tile.span" [rowspan]="1">
      {{ tile.text | addPercentToNumber }}
    </mat-grid-tile>
  </mat-grid-list>
</ng-template>

<ng-template #totals>
  <mat-grid-list *ngIf="canShow && !(config.loading | async) && config.totals; let totals" cols="4" rowHeight="40px" class="totals" [ngClass]="totalsClass | async">
    <mat-grid-tile *ngFor="let tile of totalsTiles | async" [colspan]="tile.span" [rowspan]="1">
      {{ tile.text | addPercentToNumber }}
    </mat-grid-tile>
  </mat-grid-list>
</ng-template>
