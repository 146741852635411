import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BulkUpdateSegment } from 'models/copyright/bulk-updates/jobs.model';
import { StepType } from 'config/stepper-builders/stepper-config';
import { map } from 'rxjs/operators';
import { RootState } from 'store/root';
const bulkUpdateTypeSelector = (state: any): BulkUpdateSegment => state.newSectionItem?.newItem.fields.bulkUpdateType;

export class BulkUpdateAdditionalInfoStep {
  private bulkUpdateType$ = this.store.pipe(select(bulkUpdateTypeSelector));

  constructor(private translate: TranslateService, private store: Store<RootState>) {}

  getStep(): StepType {
    return {
      label: this.translate.instant('BULK-UPDATES.ADDITIONAL_INFO.GROUP_LABEL'),
      formBuilder: [
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-2',
              key: 'comment',
              type: 'ice-input',
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                required: false,
                placeholder: this.translate.instant('BULK-UPDATES.ADDITIONAL_INFO.COMMENT'),
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-2',
              key: 'note',
              wrappers: ['form-field', 'wrapper-input-text'],
              type: 'textarea',
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                required: false,
                label: this.translate.instant('BULK-UPDATES.ADDITIONAL_INFO.NOTE'),
                rows: 8,
              },
            },
          ],
        },
      ],
      hide: this.bulkUpdateType$.pipe(map(bulkUpdateType => !bulkUpdateType)),
    };
  }
}
