import { animate, state, style, transition, trigger } from '@angular/animations';

export const iceAnimations = [
  trigger('fadeElement', [
    // route 'enter and leave (<=>)' transition
    transition('*<=>*', [
      // css styles at start of transition
      style({ opacity: 0 }),
      // animation and styles at end of transition
      animate('0.3s', style({ opacity: 1 })),
    ]),
  ]),
];

// -----------------------------------------------------------------------------------------------------
// @ Zoom in
// -----------------------------------------------------------------------------------------------------
export const zoomIncrease = trigger('zoomIncrease', [
  state(
    'void',
    style({
      opacity: 1,
      transform: 'scale(1)',
    }),
  ),

  state(
    'true',
    style({
      opacity: 1,
      transform: 'scale(1.3)',
    }),
  ),

  // Prevent the transition if the state is false
  transition('void => false', []),
]);

// -----------------------------------------------------------------------------------------------------
// @ rotateY in
// -----------------------------------------------------------------------------------------------------
export const rotateYIncrease = trigger('rotateYIncrease', [
  state(
    'void',
    style({
      opacity: 0,
      transform: 'rotateY(0deg)',
    }),
  ),

  state(
    'true',
    style({
      opacity: 1,
      transform: 'rotateY(360deg)',
    }),
  ),

  // Prevent the transition if the state is false
  transition('void => false', []),
]);
