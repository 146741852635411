<div fxLayout="column">
  <mat-form-field>
    <mat-select [id]="id" [formControl]="formControl" [formlyAttributes]="field" [placeholder]="to.placeholder" [multiple]="to.multiple" attr.data-testid="select-{{ field.key }}">
      <ng-container *ngFor="let item of to.options">
        <mat-optgroup *ngIf="item.group" label="{{ item.label }}">
          <mat-option *ngFor="let child of item.group" [value]="child.id" [disabled]="child.disabled">
            {{ child.label }}
          </mat-option>
        </mat-optgroup>
        <mat-option *ngIf="!item.group" [value]="item.value" [disabled]="item.disabled">{{ item.label }}</mat-option>
      </ng-container>
    </mat-select>
    <mat-error [id]="null">
      <formly-validation-message [field]="field"></formly-validation-message>
    </mat-error>
  </mat-form-field>
</div>
