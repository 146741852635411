import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { USER_STATE_INACTIVE } from 'config/constants/users.constants';
import { Observable, Subject } from 'rxjs';
import { catchError, debounceTime, flatMap, map, withLatestFrom } from 'rxjs/operators';
import * as fromRoot from 'store/root';
import { environment } from 'config/env';
import { SearchResult } from '../../../../models';
import { UserCleaned } from '../../../../models/users/users.model';
import { AuthService } from '../../../../services/auth/auth.service';
import { CommonApiService } from '../../../../services/common-api.service';
import { MaskedIdsService } from './../../../../services/masked-ids/masked-ids.service';

@Injectable()
export class SearchUsersService extends CommonApiService {
  public searchChanged$: Subject<string>;
  public searchTermListener$: Observable<string[]>;

  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    protected store: Store<fromRoot.RootState>,
    protected route: ActivatedRoute,
    protected maskedIdsService: MaskedIdsService,
    protected actions$: Actions,
  ) {
    super(http, authService, store, route, maskedIdsService, actions$);
    this.searchTermListener$ = this.searchTermListener();
  }

  public newSearch(term: string, userNs: string): Observable<SearchResult> {
    return this.postRequestCubeData<HttpResponse<SearchResult>>(
      `${environment.apiUrlCubeData}/users/${userNs}/search`,
      { include: 'attributes', size: '10' },
      {
        and: [
          { or: [{ wildcard: { 'attributes.firstName': `*${term}*` } }, { wildcard: { 'attributes.lastName': `*${term}*` } }] },
          { not: { equals: { 'attributes.status': USER_STATE_INACTIVE } } },
        ],
      },
    );
  }

  public userResendActivationEmail({ id, email, userNs }: { id: string; email: string; userNs: string }): Observable<SearchResult> {
    return this.postRequestCubeData<HttpResponse<any>>(`${environment.apiUrlCubeData}/users/${userNs}/${id}/email/resend`, {}, { email });
  }

  private searchTermListener(): Observable<string[]> {
    this.searchChanged$ = new Subject();
    return this.searchChanged$.pipe(
      debounceTime(500),
      withLatestFrom(this.store.pipe(select(fromRoot.getUserNS))),
      flatMap(([term, userNs]) => {
        return this.newSearch(term, userNs);
      }),
      map((response: any) => {
        const users: UserCleaned[] = response.items.map((item: any) => item.attributes as UserCleaned);
        return users.map(user => `${user.firstName} ${user.lastName} (${user.email}), ${user.id}`);
      }),
    );
  }
}
