import { Agreement, AgreementDetail, AgreementDetailCleaned, AgreementShares, AgreementUtils, DateTimeUtils, RelationsUtils } from '@ice';
import { createSelector } from '@ngrx/store';
import * as fromFeature from 'store/root/reducers';
import * as fromReducer from 'store/root/reducers/copyright/copyright.reducer';

export const getCopyrightAgreement = createSelector(fromFeature.getCopyrightFeatureState, fromReducer.getCopyrightAgreementState);

export const getAgreementTerms = createSelector(getCopyrightAgreement, (detail: AgreementDetailCleaned) =>
  detail && detail.attributes ? AgreementUtils.cleanAndLabelAgreementDetail(detail) : [],
);

export const getAgreementAttributes = createSelector(getCopyrightAgreement, (detail: AgreementDetailCleaned) => (detail && detail.attributes ? detail.attributes : null));

export const getAgreementConflicts = createSelector(getCopyrightAgreement, (detail: AgreementDetailCleaned) => (detail && detail.conflicts ? detail.conflicts : null));

export const getAgreementConflictIcon = createSelector(getCopyrightAgreement, (detail: AgreementDetailCleaned) => (detail && detail.conflictIcon ? detail.conflictIcon : null));

export const getAgreementKey = createSelector(getAgreementAttributes, (attributes: Agreement) => (attributes && attributes.key) || '');

export const getAgreementRowShares = createSelector(getCopyrightAgreement, (detail: AgreementDetailCleaned) =>
  detail && detail.attributes && detail.attributes.shares ? detail.attributes.shares : [],
);

export const getAgreementIsTerminated = createSelector(
  getAgreementRowShares,
  (shares: AgreementShares[]) => (shares && !shares.find(share => share.endDate && DateTimeUtils.isDateSameOrAfterNow(share.endDate))) || false,
);

export const getAgreementAssignor = createSelector(getCopyrightAgreement, (detail: AgreementDetailCleaned) => (detail && detail.assignor ? detail.assignor : {}));

export const getAgreementAssignee = createSelector(getCopyrightAgreement, (detail: AgreementDetailCleaned) => (detail && detail.assignee ? detail.assignee : {}));

export const getAgreementRelations = createSelector(getCopyrightAgreement, (detail: AgreementDetailCleaned) => (detail && detail.relations) || []);

export const getAgreementRelationsAndGroups = createSelector(
  getCopyrightAgreement,
  (detail: AgreementDetailCleaned) => (detail && AgreementUtils.joinRelationsAndGroups(detail)) || [],
);

export const getAgreementXREF = createSelector(getAgreementRelationsAndGroups, relations => relations && RelationsUtils.getAgreementXREF(relations));

export const getAgreementAuditHistory = createSelector(getCopyrightAgreement, (detail: AgreementDetailCleaned) => (detail && detail.auditHistory ? detail.auditHistory.items : []));

export const getWorkRetention = createSelector(getCopyrightAgreement, (detail: AgreementDetail): boolean => (detail && detail.attributes && detail.attributes.retention) || false);

export const getRetainedAgreement = createSelector(
  getCopyrightAgreement,
  (detail: AgreementDetail): string[] =>
    (detail && detail.relations && detail.relations.filter(rel => rel.relation === 'RETENTION' && rel.otherId !== detail.attributes.key).map(item => item.otherId)) || [],
);

export const getRetentions = createSelector(
  getCopyrightAgreement,
  (detail: AgreementDetail) => (detail && detail.retentions && detail.retentions.filter(ret => ret !== detail.attributes.key)) || [],
);

export const getRetentionsHtmlTable = createSelector(getCopyrightAgreement, (detail: AgreementDetail) => (detail && detail.retentions) || []);

export const getRetainedAgreementHtmlTable = createSelector(getRetainedAgreement, (retainedAgreement: []) => retainedAgreement || []);

export const getAgreementIncludedWorks = createSelector(getCopyrightAgreement, (detail: AgreementDetailCleaned) =>
  detail && detail.includedWorks ? detail.includedWorks.items : [],
);

export const getAgreementExcludedWorks = createSelector(getCopyrightAgreement, (detail: AgreementDetailCleaned) =>
  detail && detail.excludedWorks ? detail.excludedWorks.items : [],
);

export const getAgreementPublisherWorks = createSelector(getCopyrightAgreement, (detail: AgreementDetailCleaned) => detail?.publisherWorks?.items || []);

export const getAgreementSearchWorks = createSelector(getCopyrightAgreement, (detail: AgreementDetailCleaned) => (detail && detail.searchWorks ? detail.searchWorks.items : []));

export const getAgreementSubmitterPartyNameIds = createSelector(getAgreementAttributes, (attributes: Agreement) => attributes?.submitterPartyNameIds || []);

export const getAgreementLinkedWriters = createSelector(getCopyrightAgreement, (detail: AgreementDetailCleaned) => detail?.linkedWriters?.items || []);

export const getAgreementWriters = createSelector(getCopyrightAgreement, (detail: AgreementDetailCleaned) => detail?.writers?.items || []);

export const getAgreementSubmitterPartyRelations = createSelector(getCopyrightAgreement, (detail: AgreementDetailCleaned) => detail?.submitterPartyRelations || []);

export const getAgreementOriginalPublishersList = createSelector(getCopyrightAgreement, (detail: AgreementDetailCleaned) => detail?.originalPublishersList || []);

export const getAgreementIncludedWorksCounter = createSelector(getCopyrightAgreement, (detail: AgreementDetailCleaned) => detail?.includedWorksCount || 0);
