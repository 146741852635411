<mat-expansion-panel id="{{ listIdentifier ? 'expansion-list-data-table-' + listIdentifier : '' }}" [expanded]="expanded | async" (opened)="onOpened()" (closed)="onClosed()">
  <mat-expansion-panel-header class="mat-accent-bg" [ngClass]="className">
    <mat-panel-title>
      <div class="ice-txt-white header-text">{{ title }} {{ (showTotal && totalElemsCount) || '' }}</div>
      <div class="ice-txt-white header-text" *ngIf="showTotal && !(model.model | async)">
        <mat-spinner style="display: inline-block; filter: invert(1)" [diameter]="17" class="search-spinner"></mat-spinner>
      </div>
    </mat-panel-title>
    <mat-panel-description></mat-panel-description>
    <mat-icon
      *ngIf="(iconConflict | async) && totalElemsCount !== '(0)'"
      [matTooltip]="tooltipIconConflict | async"
      [matTooltipClass]="'ice-tooltip ice-tooltip-icon'"
      [matTooltipPosition]="'above'"
      class="nav-link-icon button-header ice-yellow button-title-dropdown"
      >{{ iconConflict | async }}</mat-icon
    >
    <mat-icon
      *ngIf="buttonTitle && totalElemsCount !== '(0)'"
      [matTooltip]="buttonTitleTooltip"
      [matTooltipClass]="'ice-tooltip ice-tooltip-icon'"
      [matTooltipPosition]="'above'"
      (click)="clickHeaderButton($event, extraData)"
      class="nav-link-icon button-header button-title-dropdown"
      >{{ buttonTitle }}</mat-icon
    >
  </mat-expansion-panel-header>

  <ice-data-table
    *ngIf="model && model.model"
    [data]="model.model | async"
    [sorts]="model.sorts"
    [schema]="model.schema"
    [shadowed]="model.shadowed"
    [columnMode]="model.columnMode ? model.columnMode : 'force'"
    [isSelectable]="!!selectable"
    [selected]="model.selected | async"
    [displayCheck]="model.displayCheck"
    [selectionType]="model.selectionType || 'single'"
    [singleSelection]="singleSelection"
    [fixedHeight]="maxHeight"
    [maxHeight]="maxHeight"
    [height]="height"
    [headerTitle]="model.headerTitle"
    [headerClass]="model.headerClass"
    [rowIdentity]="model.rowIdentity"
    (resized)="onTableResized($event)"
    (selectedRowEmit)="selectDataTableItem($event)"
    (mouseWheelClickRowEmit)="onMouseWheelClickDataTableSelect($event)"
    (selectedCheckboxEmit)="onSelectedCheckboxEmit($event)"
  >
  </ice-data-table>
</mat-expansion-panel>
