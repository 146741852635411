import { DateTimeUtils } from '@ice';
import { get } from 'lodash';
import moment from 'moment';
import { createLabelResolver } from '../api-call.factory';

export const getTerritoriesTisa = createLabelResolver({ territoriesTisa: 'territoriesList.tisa' });

export const getTerritoriesEndDate = createLabelResolver({
  endDate: detail => {
    const today = DateTimeUtils.formatDate(moment());
    const endDate = get(detail, 'territoriesList.endDate');
    return endDate > today ? today : moment(endDate).subtract(1, 'd').format('YYYY-MM-DD');
  },
});

export const getTerritoriesTisnPattern = createLabelResolver({
  pattern: () => {
    return '{"equals":{"attributes.tisn":"<<territoryTisa>>"}}';
  },
});

export const getTerritoriesSuccessors = createLabelResolver({ territoryTisa: 'territoriesList.successorsArray' });

export const getTerritoriesPredecessors = createLabelResolver({ territoryTisa: 'territoriesList.predecessorsArray' });

export const getTerritoriesTisaMembers = createLabelResolver({
  territoryTisa: detail => {
    return detail?.includedCountries[0]?.members?.map(member => member.tisn) || ['0'];
  },
});
