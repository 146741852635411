import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { fieldRepeaterValidator, IpUtils, StringUtils } from '@ice';
import { FormlyValidatorUtils } from '@ice/utils/formly/formly-validators.utils';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { RELATED_AGREEMENT_TYPES } from 'config/constants/agreements.constants';
import { AGREEMENT_GROUP_CONSTANTS } from 'config/constants/agrement-group.constants';
import { DialogAgreementGroupSearch } from 'config/dialog-builders/dialog-agreement-group-search';
import { DialogCreateAgreementGroup } from 'config/dialog-builders/dialog-create-agreement-group';
import { StepType } from 'config/stepper-builders/stepper-config';
import { get } from 'lodash';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromNewSectionItem from 'store/new-section-item';
import * as fromRoot from 'store/root';
import { SelectOptions } from '../stepper-select-options';
import * as fromActions from './../../../../store/root/actions';

export class AditionalFieldsStep {
  private identifiersField: FormlyFieldConfig;
  private relatedAgreementsField: FormlyFieldConfig;

  private set indicatorClaimsWrapper(field) {
    this.fieldValidatorService.agreementRegisterFields.gemaRemunerationClaimIndicator = field;
  }
  private get indicatorClaimsWrapper() {
    return this.fieldValidatorService.agreementRegisterFields.gemaRemunerationClaimIndicator;
  }

  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private storeNewItem: Store<fromNewSectionItem.NewSectionItemState>,
    private dialog: MatDialog,
    private fieldValidatorService: FieldValidatorService,
  ) {}

  getStep(store, translate, fieldValidatorService): StepType {
    const options = SelectOptions.getOptions(translate);
    return {
      label: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.TITLE'),
      formBuilder: [
        {
          /* Agreement Name */
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-2 ice-pr-1-5',
              key: 'agreement_name',
              wrappers: ['wrapper-info'],
              type: 'ice-input',
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                type: 'text',
                placeholder: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.AGREEMENT_NAME.NAME'),
                infoText: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.AGREEMENT_NAME.INFO_TEXT'),
                required: false,
                maxLength: 90,
              },
            },
          ],
        },
        {
          /* Identifiers Title */
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-1',
              template: `<h2>${translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.IDENTIFIERS.TITLE')}</h2>`,
            },
          ],
        },
        {
          /* Identifiers */
          key: 'identifiers',
          type: 'repeat',
          hooks: {
            onInit: field => (this.identifiersField = field),
          },
          fieldArray: {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [
              {
                wrappers: ['wrapper-info'],
                className: 'flex-2',
                templateOptions: {
                  infoText: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.IDENTIFIERS.SUBMITTER_AGREEMENT_NUMBER.INFO_TEXT'),
                },
                fieldGroup: [
                  {
                    key: 'submitter_agreement_number',
                    wrappers: ['form-field', 'wrapper-input-text'],
                    type: 'input',
                    modelOptions: {
                      updateOn: 'blur',
                    },
                    templateOptions: {
                      required: false,
                      pattern: /^[a-zA-Z0-9]+$/i,
                      label: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.IDENTIFIERS.SUBMITTER_AGREEMENT_NUMBER.NAME'),
                      change: (field, $event) => {
                        FormlyValidatorUtils.checkRepeaterDuplicates(field.formControl, 'identifiersErrorValidator', 'submitter_agreement_number');
                      },
                    },
                    validation: {
                      messages: {
                        pattern: translate.instant('ERROR.ALPHANUMERIC'),
                      },
                    },
                    validators: {
                      identifierToMask: {
                        expression: control => {
                          control.parent.get('maskedId').setValue(control.value);
                          return true;
                        },
                      },
                    },
                  },
                  { key: 'maskedId' },
                ],
              },
            ],
          },
        },
        fieldRepeaterValidator('identifiersErrorValidator', translate),
        {
          /* Related Agreements Title */
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-1',
              template: `<h2>${translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.RELATED_AGREEMENTS.TITLE')}</h2>`,
            },
          ],
        },
        {
          /* Related Agreements */
          key: 'related_agreements',
          type: 'repeat',
          hooks: {
            onInit: field => (this.relatedAgreementsField = field),
          },
          templateOptions: {
            addButtonText: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.RELATED_AGREEMENTS.ADD_BUTTON'),
            removeButtonText: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.RELATED_AGREEMENTS.REMOVE_BUTTON'),
          },
          fieldArray: {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [
              {
                /* Related Agreements - Type info */
                wrappers: ['wrapper-info'],
                className: 'flex-2',
                templateOptions: {
                  infoText: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.RELATED_AGREEMENTS.RELATED_AGREEMENT_TYPE.INFO_TEXT'),
                },
                fieldGroup: [
                  {
                    /* Related Agreements - Type */
                    key: 'related_agreement_type',
                    type: 'select',
                    modelOptions: {
                      updateOn: 'blur',
                    },
                    templateOptions: {
                      attributes: { 'data-testid': 'related_agreements' },
                      required: false,
                      label: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.RELATED_AGREEMENTS.RELATED_AGREEMENT_TYPE.NAME'),
                      options: options.additionalFields.related_agreement_type,
                      change: (field, $event) => {
                        const relatedAgreementNumberFormControl = get(field, 'parent.formControl.controls.related_agreement_number', null);
                        if (relatedAgreementNumberFormControl) {
                          relatedAgreementNumberFormControl.setValue(null);
                        }
                      },
                    },
                  },
                ],
              },
              {
                className: 'flex-2',
                fieldGroupClassName: 'display-flex',
                hideExpression: model => !model.related_agreement_type,
                fieldGroup: [
                  {
                    // Related Agreements - Number */
                    key: 'related_agreement_number',
                    className: 'flex-1',
                    wrappers: ['wrapper-info', 'form-field', 'wrapper-input-text'],
                    type: 'input',
                    modelOptions: {
                      updateOn: 'blur',
                    },
                    hideExpression: model => !model.related_agreement_type,
                    templateOptions: {
                      label: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.RELATED_AGREEMENTS.RELATED_AGREEMENT_NUMBER.NAME'),
                      needValidate: false,
                      infoText: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.RELATED_AGREEMENTS.RELATED_AGREEMENT_NUMBER.INFO_TEXT'),
                      focus: (field: FormlyFieldConfig) => (field.templateOptions.needValidate = true),
                      change: (field, $event) => (
                        store.dispatch(new fromActions.ShowDataLoadingVisibility(true)),
                        FormlyValidatorUtils.checkRepeaterDuplicates(field.formControl, 'relatedAgreementsErrorValidator', 'related_agreement_number')
                      ),
                    },
                    hooks: {
                      onDestroy: field =>
                        FormlyValidatorUtils.checkRepeaterDuplicatesOnDestroy(
                          this.relatedAgreementsField.formControl,
                          'relatedAgreementsErrorValidator',
                          'related_agreement_number',
                        ),
                    },
                    expressionProperties: {
                      'templateOptions.required': model => {
                        const requiredValue = model.related_agreement_type;
                        return requiredValue && requiredValue !== '';
                      },
                    },
                    validators: {
                      incorrect: {
                        expression: (control: FormControl) => (
                          store.dispatch(new fromActions.ShowDataLoadingVisibility(false)), control && control.parent && control.parent.value && StringUtils.isId(control.value)
                        ),
                        message: translate.instant('ERROR.INCORRECT'),
                      },
                    },
                    asyncValidators: {
                      agreementValid: {
                        expression: (control, field) =>
                          fieldValidatorService.useValidatorOnTouch(field) ||
                          (control.parent.value.related_agreement_type === RELATED_AGREEMENT_TYPES.COPUBLISHER
                            ? this.agreementValidation(control, field, fieldValidatorService)
                            : new Promise((resolve, reject) => resolve(true))),
                        message: translate.instant('REPERTOIRES.STEPS.EXCLUSIONS.FIELDS.AGREEMENTS.FIELDS.ERROR'),
                      },
                      agreementGroupValidator: {
                        expression: (control: FormControl, field) =>
                          fieldValidatorService.useValidatorOnTouch(field) ||
                          (control.parent.value.related_agreement_type === RELATED_AGREEMENT_TYPES.RECIPIENT
                            ? new Promise((resolve, reject) => {
                                if (field && field.templateOptions.needValidate && control.value) {
                                  store.dispatch(new fromActions.ShowDataLoadingVisibility(true));
                                  return fieldValidatorService
                                    .existAgreementGroupReference(control.value)
                                    .then((res: boolean) => resolve(res))
                                    .finally(() => store.dispatch(new fromActions.ShowDataLoadingVisibility(false)));
                                } else {
                                  resolve(true);
                                }
                              })
                            : new Promise((resolve, reject) => resolve(true))),
                        message: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.RELATED_AGREEMENTS.ERROR_AGREEMENTS_GROUP'),
                      },
                    },
                  },
                  { key: 'maskedId' },
                  {
                    key: 'btSearchRelatedAgreementRepeat',
                    className: 'ice-formly-icon-bt-info-search',
                    type: 'button',
                    hideExpression: model => model.related_agreement_type !== RELATED_AGREEMENT_TYPES.RECIPIENT,
                    templateOptions: {
                      materialType: 'mat-icon-button',
                      icon: 'search',
                      onClick: (event, field) => {
                        if (event.x !== 0 && event.y !== 0) {
                          const destinationField = field.parent.fieldGroup.find(fieldGroup => fieldGroup.key === 'related_agreement_number');
                          this.onClickAgreementGroupSearchDialog(destinationField, store);
                        }
                      },
                    },
                  },
                  {
                    key: 'btCreateAgreementGroup',
                    className: 'ice-formly-icon-bt-info-create',
                    type: 'button',
                    hideExpression: model => model.related_agreement_type !== RELATED_AGREEMENT_TYPES.RECIPIENT,
                    templateOptions: {
                      materialType: 'mat-icon-button',
                      icon: 'add',
                      tooltipText: this.translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.RELATED_AGREEMENTS.CREATE_NEW_AGREEMENT_GROUP'),
                      onClick: (event, field) => {
                        if (event.x !== 0 && event.y !== 0) {
                          const destinationField = field.parent.fieldGroup.find(fieldGroup => fieldGroup.key === 'related_agreement_number');
                          this.onClickAgreementGroupCreateDialog(destinationField);
                        }
                      },
                    },
                  },
                ],
              },
            ],
          },
        },
        fieldRepeaterValidator('relatedAgreementsErrorValidator', translate),
        {
          /* International Factors Title */
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-1',
              template: `<h2>${translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.INTERNATIONAL_FACTORS.TITLE')}</h2>`,
            },
          ],
        },
        {
          /* International Factors */
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              /* International Factors - Writer mechanical reason */
              className: 'flex-2',
              wrappers: ['wrapper-info'],
              templateOptions: {
                infoText: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.INTERNATIONAL_FACTORS.WRITER_MECHANICAL_REASON.INFO_TEXT'),
              },
              hideExpression: model => this.fieldValidatorService.writerMechanicalIsHidden,
              expressionProperties: {
                className: model => {
                  if (!this.fieldValidatorService.writerMechanicalIsHidden) {
                    return 'flex-2 ice-formly-flexible-hidden-children-first-child ice-pr-1-5';
                  }
                  return 'flex-2';
                },
              },
              fieldGroup: [
                {
                  key: 'writer_mechanical_reason',
                  type: 'select',
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  hooks: {
                    onInit: field => {
                      this.fieldValidatorService.writerMechanicalIsHidden = true;
                      field.formControl.markAsTouched();
                      this.fieldValidatorService.agreementRegisterFields.writerMechanicalReason = field;
                    },
                  },
                  templateOptions: {
                    required: true,
                    label: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.INTERNATIONAL_FACTORS.WRITER_MECHANICAL_REASON.NAME'),
                    options: options.additionalFields.writer_mechanical_reason,
                    change: (field, value) => {
                      field.formControl.setValue(value.value);
                      this.fieldValidatorService.generalSharesValidator();
                    },
                  },
                },
              ],
            },
            {
              /* International Factors - Usa licence indicator */
              className: 'flex-2',
              wrappers: ['wrapper-info'],
              templateOptions: {
                infoText: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.INTERNATIONAL_FACTORS.USA_LICENSE_INDICATOR.INFO_TEXT'),
              },
              expressionProperties: {
                className: model => {
                  if (this.fieldValidatorService.writerMechanicalIsHidden) {
                    return 'flex-2 ice-formly-flexible-hidden-children-first-child ice-pr-1-5';
                  }
                  return 'flex-2 ice-formly-flexible-hidden-children-second-child';
                },
              },
              fieldGroup: [
                {
                  key: 'usa_license_indicators',
                  type: 'select',
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  templateOptions: {
                    attributes: { 'data-testid': 'select-usa_license_indicators' },
                    required: false,
                    label: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.INTERNATIONAL_FACTORS.USA_LICENSE_INDICATOR.NAME'),
                    options: options.additionalFields.usa_license_indicators,
                  },
                },
              ],
            },
            {
              /* International Factors - Gema remuneration claim */
              className: 'flex-2',
              wrappers: ['wrapper-info'],
              templateOptions: {
                infoText: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.INTERNATIONAL_FACTORS.GEMA_REMUNERATION_CLAIM_INDICATOR.INFO_TEXT'),
              },
              fieldGroup: [
                {
                  className: 'flex-2',
                  key: 'gema_remuneration_claim_indicator',
                  type: 'checkbox',
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  defaultValue: false,
                  templateOptions: {
                    type: 'boolean',
                    placeholder: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.INTERNATIONAL_FACTORS.GEMA_REMUNERATION_CLAIM_INDICATOR.NAME'),
                    disabled: false,
                  },
                },
              ],
              hooks: {
                onInit: field => {
                  field.hide = true;
                  this.indicatorClaimsWrapper = field;
                },
              },
            },
          ],
        },
        {
          /* Library production title */
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-1',
              template: `<h2>${translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.LIBRARY_PRODUCTION.TITLE')}</h2>`,
            },
          ],
        },
        {
          /* Library production */
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              /* Library production -- Library music */
              className: 'flex-2 ice-pr-1-5',
              wrappers: ['wrapper-info'],
              templateOptions: {
                infoText: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.LIBRARY_PRODUCTION.LIBRARY_MUSIC.INFO_TEXT'),
              },
              fieldGroup: [
                {
                  key: 'library_music',
                  type: 'select',
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  templateOptions: {
                    attributes: { 'data-testid': 'select-library_music' },
                    required: false,
                    label: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.LIBRARY_PRODUCTION.LIBRARY_MUSIC.NAME'),
                    options: options.additionalFields.library_music,
                  },
                },
              ],
            },
            {
              /* Library production -- Recording prefix */
              className: 'flex-2',
              wrappers: ['wrapper-info'],
              templateOptions: {
                infoText: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.LIBRARY_PRODUCTION.RECORDING_PREFIX.INFO_TEXT'),
              },
              hideExpression: model => {
                return model.library_music === 'n';
              },
              fieldGroup: [
                {
                  key: 'recording_prefix',
                  wrappers: ['form-field', 'wrapper-input-text'],
                  type: 'input',
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  templateOptions: {
                    attributes: { 'data-testid': 'input-recording_prefix' },
                    pattern: /^[a-zA-Z0-9]+$/i,
                    label: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.LIBRARY_PRODUCTION.RECORDING_PREFIX.NAME'),
                  },
                  expressionProperties: {
                    'templateOptions.required': model => {
                      const requiredValue = model.library_music;
                      return requiredValue && requiredValue === 'y';
                    },
                  },
                  validation: {
                    messages: {
                      pattern: translate.instant('ERROR.ALPHANUMERIC'),
                    },
                  },
                },
              ],
            },
          ],
        },
        {
          /* Additional information title */
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-1',
              template: `<h2>${translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.ADDITIONAL_INFORMATION.TITLE')}</h2>`,
            },
          ],
        },
        {
          /* Additional information */
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-1',
              key: 'additional_information',
              wrappers: ['form-field', 'wrapper-input-text'],
              type: 'textarea',
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                required: false,
                label: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.ADDITIONAL_INFORMATION.HEADER_TEXT'),
                rows: 5,
              },
            },
          ],
        },
        {
          /* Additional information footer */
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-1',
              template: `<p>${translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.ADDITIONAL_INFORMATION.FOOTER_TEXT')}</p>`,
            },
          ],
        },
      ],
    };
  }

  onClickAgreementGroupSearchDialog(fieldToAssign, store) {
    DialogAgreementGroupSearch.openDialog(
      AGREEMENT_GROUP_CONSTANTS.TYPE_RELATED_AGREEMENT,
      this.dialog,
      this.storeNewItem,
      this.translate,
      this.fuseTranslationLoader,
      item => {
        fieldToAssign.templateOptions.needValidate = false;
        fieldToAssign.formControl.setValue(item.id);
        this.dialog.closeAll();
      },
      store,
    );
  }

  onClickAgreementGroupCreateDialog(fieldToAssign) {
    DialogCreateAgreementGroup.openDialog(this.dialog, this.storeNewItem, this.translate, this.fuseTranslationLoader, this.fieldValidatorService, response => {
      fieldToAssign.templateOptions.needValidate = false;
      fieldToAssign.formControl.setValue(response.id);
    });
  }

  agreementValidation(control, field, fieldValidatorService) {
    return new Promise((resolve, reject) =>
      fieldValidatorService.validAgreementKey(
        control,
        agreementDetail => {
          if (agreementDetail && agreementDetail.relations) {
            if (get(agreementDetail, 'relations', null)) {
              const key = IpUtils.selectIpsKey(agreementDetail.relations, null).replace('ICE:', '');
              control.parent.get('maskedId').setValue(key);
            }
            resolve(true);
          }
          resolve(false);
        },
        () => resolve(false),
      ),
    );
  }
}
