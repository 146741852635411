import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { FuseSharedModule } from '@fuse/shared.module';
import { IceMaterialModule } from '@ice/material.module';
import { TranslateModule } from '@ngx-translate/core';

import { DataTableModule } from '../data-table/data-table.module';
import { ExpansionListDataTableComponent } from './expansion-list-data-table.component';

@NgModule({
  imports: [CommonModule, TranslateModule, IceMaterialModule, FlexLayoutModule, FormsModule, FuseSharedModule, DataTableModule],
  declarations: [ExpansionListDataTableComponent],
  exports: [ExpansionListDataTableComponent],
})
export class ExpansionListDataTableModule {}
