import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import { IceFacade } from 'facades/ice.facade';
import { CommonApiService } from 'services/common-api.service';
import { DetailService } from 'services/detail/detail.service';
import { NamespaceService } from 'services/namespace/namespace.service';
import * as fromRoot from 'store/root';
import { ResendActivationEmail } from 'store/root';
import { SectionTabConfig } from '../../tabs-data';

export class TabUsersDetail implements SectionTabConfig {
  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private dialog: MatDialog,
    private commonApiService: CommonApiService,
    private detailService: DetailService,
    private nsService: NamespaceService,
    private iceFacade: IceFacade,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'cardWithForm',
            config: {
              title: this.translate.instant('USERS.DETAILS.FORM.TITLE'),
              model: this.iceFacade.users.getUsers(),
              resetAvailable: false,
              submitAvailable: false,
              formBuilder: this.getFormly(),
            },
          },
        ],
      },
    ];
  }

  private getFormly() {
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'cognitoUserName',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('USERS.DETAILS.FORM.FIELDS.USERNAME'), required: false, disabled: true },
          },
          {
            className: 'flex-1',
            key: 'firstName',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('USERS.DETAILS.FORM.FIELDS.FIRST_NAME'), required: false, disabled: true },
          },
          {
            className: 'flex-1',
            key: 'lastName',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('USERS.DETAILS.FORM.FIELDS.LAST_NAME'), required: false, disabled: true },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'email',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('USERS.DETAILS.FORM.FIELDS.EMAIL'), required: false, disabled: true },
          },
          {
            className: 'flex-1',
            key: 'organizationId',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('USERS.DETAILS.FORM.FIELDS.ASSOCIATED_ORGANIZATION'), required: false, disabled: true },
          },
          {
            className: 'flex-1',
            key: 'roles',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('USERS.DETAILS.FORM.FIELDS.ASSOCIATED_ROLES'), required: false, disabled: true },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'status',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('USERS.DETAILS.FORM.FIELDS.ACTIVITY_STATUS'), required: false, disabled: true },
          },
          {
            className: 'flex-1',
            key: 'lastAttempt',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('USERS.DETAILS.FORM.FIELDS.LAST_LOGIN_ATTEMPT'), required: false, disabled: true },
          },
          {
            className: 'flex-1',
            key: 'creationDate',
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('USERS.DETAILS.FORM.FIELDS.CREATION_DATE'), required: false, disabled: true },
          },
        ],
      },
      {
        fieldGroup: [
          {
            className: 'flex-1',
            type: 'button',
            hideExpression: 'model.status !== "NEW" && model.status !== "NOT ACTIVATED"',
            templateOptions: {
              materialType: 'mat-flat-button',
              color: 'accent',
              text: 'Resend Activation Email',
              onClick: ($event, field) => {
                $event.preventDefault();
                $event.stopPropagation();
                this.store.dispatch(new ResendActivationEmail());
              },
            },
          },
        ],
      },
    ];
  }
}
