import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { IceMaterialModule } from '@ice/material.module';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { TranslateModule } from '@ngx-translate/core';
import { WrapperCardComponent } from './wrapper-card.component';

@NgModule({
  declarations: [WrapperCardComponent],
  imports: [TranslateModule, FuseSharedModule, IceMaterialModule, FormlyMaterialModule, FormlyModule.forRoot()],
  exports: [WrapperCardComponent],
})
export class WrapperCardModule {}
