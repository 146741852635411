<div class="ice-extended-work-selector">
  <span class="text">
    <span class="counter">{{ options?.length }}</span>
    {{ extendedWorkSelectorTitle | async }}
  </span>
  <mat-form-field data-testid="extended-work-selector">
    <mat-label>{{ extendedWorkSelectorPlaceHolder | async }}:</mat-label>
    <mat-select attr.data-testid="work-select" [(value)]="selected" (selectionChange)="onSelectOption($event)">
      <mat-option *ngFor="let option of options" [value]="option.value">
        {{ option.viewValue }}<ng-container *ngIf="option.title"> - {{ option.title }}</ng-container>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
