<mat-form-field style="width: 100%">
  <ng-template #selectAll let-selectOptions="selectOptions">
    <mat-optgroup (click)="toggleSelectAll(selectOptions)" [ngClass]="getClass()">
      <mat-pseudo-checkbox class="mat-option-pseudo-checkbox" [state]="getSelectAllState(selectOptions)" data-testid="ice-select-all"> </mat-pseudo-checkbox>
      <span class="mat-option-text-group">{{ to.selectAllOption }} <ng-container [ngTemplateOutlet]="clearAll"></ng-container></span>
    </mat-optgroup>
  </ng-template>
  <mat-label *ngIf="to.label">{{ to.label }}</mat-label>

  <mat-select
    [id]="field.key"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [placeholder]="to.placeholder"
    [tabindex]="to.tabindex"
    [required]="to.required"
    [compareWith]="to.compareWith"
    [multiple]="to.multiple"
    (selectionChange)="change($event)"
    [errorStateMatcher]="errorStateMatcher"
    [aria-labelledby]="getAriaLabelledby()"
    [disableOptionCentering]="to.disableOptionCentering || true"
    (openedChange)="onOpen($event)"
    attr.data-testid="select-{{ field.key }}"
  >
    <div [ngClass]="'filter' + (to.componentVersion || '')" *ngIf="to.filterActive">
      <mat-form-field>
        <input
          matInput
          type="text"
          [placeholder]="to.filterPlaceHolder || 'Filter values'"
          (input)="filterOptions($event.target)"
          (focus)="$event.target.select()"
          autocomplete="off"
          #searchInput
        />
        <button
          [ngClass]="{ hide: !searchInput?.value }"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="searchInput && (searchInput.value = ''); filterOptions(searchInput)"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <mat-select-trigger attr.data-testid="select-trigger-{{ field.key }}">
      <ng-container *ngIf="validOptions as selectOptions">
        <ng-container *ngIf="!to.selectAllOption || !selectedAll(selectOptions); else elseBlock">
          <span [matTooltip]="to.tooltip" [matTooltipPosition]="'above'" [matTooltipClass]="'ice-tooltip ice-tooltip-select'">
            {{ getLabels(selectOptions) }}
          </span>
        </ng-container>
        <ng-template #elseBlock>
          {{ to.selectAllOption }}
        </ng-template>
      </ng-container>
    </mat-select-trigger>
    <ng-container *ngIf="validOptions as selectOptions">
      <ng-container *ngIf="to.multiple && to.selectAllOption && !searchInput?.value" [ngTemplateOutlet]="selectAll" [ngTemplateOutletContext]="{ selectOptions: selectOptions }">
      </ng-container>
      <ng-container *ngFor="let item of selectOptions">
        <mat-optgroup *ngIf="item.options" [ngClass]="getClass(item)">
          <mat-pseudo-checkbox (click)="toggleSelectGroup(item.options)" class="mat-option-pseudo-checkbox" [state]="getSelectGroupState(item.options)"> </mat-pseudo-checkbox>
          <span (click)="toggleSelectGroup(item.options)" class="mat-option-text-group">{{ item.header }}</span>
          <ng-container *ngFor="let child of item.options">
            <mat-option [ngClass]="{ hide: child.hide }" [value]="child.value" [disabled]="child.disabled">
              {{ child.label }}
            </mat-option>
          </ng-container>
        </mat-optgroup>
        <mat-option *ngIf="!item.options" [ngClass]="{ hide: item.hide }" [value]="item.value" [disabled]="item.disabled">{{ item.label }}</mat-option>
      </ng-container>
    </ng-container>
  </mat-select>
  <mat-error [id]="null">
    <formly-validation-message [field]="field"></formly-validation-message>
  </mat-error>
</mat-form-field>

<ng-template #clearAll>
  <span id="clear-all" *ngIf="to.componentVersion && to.clearAllLabel" class="clear-all primary-link" (click)="clearControl()">{{ to.clearAllLabel }}</span>
</ng-template>
