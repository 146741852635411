import { SectionsConfig } from 'config/sections-config';
import { RequestType } from 'config/sections-config/api-call';
import { environment } from 'config/env';
import { createApiCall, setBody, setInclude, setLabelResolver, setQueryParams, setResultCleaner, validateLabels } from '../api-call.factory';
import * as fromLabelResolvers from './organizations.label-resolvers';
import * as fromResultCleaners from './organizations.result-cleaners';

const organizationsSection = SectionsConfig.ORGANIZATIONS.name;

export const getOrganizationsAgreementApplications = createApiCall(
  `${environment.apiUrlCubeData}/users/<<extraParam>>/search`,
  RequestType.POST,
  setInclude(`id`),
  setBody(`{"and":[{"equals": {"roles.organizationId":"<<id>>"}}]}`),
  setLabelResolver(organizationsSection, fromLabelResolvers.getExtraParamId, fromLabelResolvers.getAttributesId),
  setResultCleaner(['usersCount', fromResultCleaners.getUsersCount]),
  validateLabels,
);

export const getOrganizationsPartyNames = createApiCall(
  `${environment.apiUrlCubeData}/party-names/<<ns>>/search`,
  RequestType.POST,
  setInclude(`attributes{typeOfName,name,firstName}, relations[XREF]{relation,otherId}`),
  setQueryParams({ followRedirects: false }),
  setBody(`{"or":[<<pattern>>]}`),
  setLabelResolver(organizationsSection, fromLabelResolvers.getOrganizationsRelationsMatchPatternLabelResolver, fromLabelResolvers.getPartyNames),
  setResultCleaner(['partyNames', fromResultCleaners.getPartyNamesResultCleaner]),
  validateLabels,
);

export const getOrganizationsUsers = createApiCall(
  `${environment.apiUrlCubeData}/${SectionsConfig.USERS.name}/<<extraParam>>/search`,
  RequestType.POST,
  setInclude(`attributes,roles,metrics`),
  setBody(`{"and":[{"equals": {"roles.organizationId":"<<id>>"}}]}`),
  setLabelResolver(organizationsSection, fromLabelResolvers.getExtraParamId, fromLabelResolvers.getAttributesId),
  setResultCleaner(['users', fromResultCleaners.getUsersResultCleaner]),
  validateLabels,
);
