import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ActivityDetail, AgreementUtils, FetchApiCallUtils, SearchResult } from '@ice';
import { NavigationUtils } from '@ice/utils/navigation/navigation.utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromRouter from '@ngrx/router-store';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/app/errors';
import * as fromApiCalls from 'config/api-calls';
import { DialogMatchConflict } from 'config/dialog-builders/dialog-match-conflict';
import { SectionsConfig } from 'config/sections-config';
import { sections } from 'config/sections-config/sections';
import { get, isEmpty } from 'lodash';
import { of } from 'rxjs';
import { catchError, filter, map, pairwise, startWith, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { PermissionsService } from 'services/permissions/permissions.service';
import { SearchService } from 'services/search/search.service';
import * as fromNewSectionItem from 'store/new-section-item';
import * as fromNewSectionItemActions from 'store/new-section-item/actions';
import * as fromRoot from 'store/root';
import { IceNavigationState } from 'store/root';
import { getRouterPaths } from 'store/root/selectors';
import { HttpStatusCode } from '@angular/common/http';
import { Severity, IconBySeverity } from 'models/action-payloads/ice-error-payload';
import * as fromActions from '../../actions';
import { StartApiCall, UnmarkPopupOk } from '../../actions';
import { hideLoadingActions, hideLoadingTypes } from './hide-loading';
import { errorMessageShowtime, showErrorActions, showErrorTypes } from './show-error';
import { isLoadingActions, showLoadingTypes } from './show-loading';

@Injectable()
export class UserInterfaceEffects {
  showLoadingVisibility$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<showLoadingTypes>(...isLoadingActions),
        withLatestFrom(this.store.pipe(select(fromRoot.getDataProgressBar))),
        map(([action, visible]) => {
          if (!visible) {
            this.store.dispatch(new fromActions.ShowDataLoadingVisibility(true));
          }
        }),
      ),
    { dispatch: false },
  );

  hideLoadingVisibility$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<hideLoadingTypes>(...hideLoadingActions),
        withLatestFrom(this.store.pipe(select(fromRoot.getDataProgressBar)), this.store.select(fromRoot.getCopyrightApiCallStatus)),
        map(([action, visible, apiCallStatus]) => {
          if (visible && FetchApiCallUtils.apiCallIsIdle(apiCallStatus)) {
            this.store.dispatch(new fromActions.ShowDataLoadingVisibility(false));
          }
        }),
      ),
    { dispatch: false },
  );

  provideErrorFeedback$ = createEffect(() =>
    this.actions$.pipe(
      ofType<showErrorTypes>(...showErrorActions),
      map(action => {
        const severity: Severity = get(action, 'payload.severity', 'warning');
        const icon = IconBySeverity[severity];
        let message = get(action, 'payload.message', '');
        const needsErrorLog = ['error', 'warning'].includes(severity);

        switch (action.type) {
          case fromActions.AUTH_ERROR:
            message = message || this.translate.instant('ERROR.SERVER.AUTH');
            break;
          case fromActions.FETCH_SEARCH_PAGE_FAIL:
            message = this.translate.instant('ERROR.SERVER.SEARCH');
            if (needsErrorLog) {
              console.error(message);
            }
            break;
          case fromActions.GET_ITEM_FAIL:
            message = message || this.translate.instant('ERROR.SERVER.COPYRIGHT');
            if (needsErrorLog) {
              console.error(message);
            }
            break;
          case fromActions.GET_ACCESS_FAIL:
            message = message || this.translate.instant('ERROR.SERVER.ACCESS_FAIL');
            if (needsErrorLog) {
              console.error(message);
            }
            break;
          case fromActions.GET_USER_DETAILS_FAIL:
            message = message || this.translate.instant('ERROR.SERVER.GET_USER_DETAILS');
            if (needsErrorLog) {
              console.error(message);
            }
            break;
          case fromActions.GET_ITEM_NOT_FOUND:
            message = message || this.translate.instant('ERROR.SERVER.ITEM_NOT_FOUND');
            if (needsErrorLog) {
              console.error(message);
            }
            break;
          case fromActions.GET_SHARE_PICTURE_FAIL:
            message = message || this.translate.instant('ERROR.SERVER.SHARE');
            if (needsErrorLog) {
              console.error(message);
            }
            break;
          case fromActions.SELECTED_SEARCH_ITEM_MISSING_KEY:
            message = message || this.translate.instant('ERROR.UI.SELECTED_ITEM_MISSING_KEY');
            if (needsErrorLog) {
              console.error(message);
            }
            break;
          case fromActions.API_CALL_ERROR:
            message = message || this.translate.instant('ERROR.SERVER.COPYRIGHT_EXTENDED');
            if (needsErrorLog) {
              console.error(message);
            }
            break;
          case fromNewSectionItemActions.SAVE_NEW_SECTION_ITEM_FAIL:
            message = message || this.translate.instant('ERROR.SERVER.NEW_SECTION_ITEM_FAIL');
            if (needsErrorLog) {
              console.error(message);
            }
            break;
          case fromActions.UPDATE_IPS_RELATIONS_FAIL:
            message = message || this.translate.instant('ERROR.SERVER.UPDATE_FAIL');
            if (needsErrorLog) {
              console.error(message);
            }
            break;
          case fromActions.SAVE_SECTION_ITEM_FAIL:
            message = message || this.translate.instant('ERROR.SERVER.SAVE_SECTION_ITEM_FAIL');
            if (needsErrorLog) {
              console.error(message);
            }
            break;
          case fromActions.ICE_ERROR:
            message = message || this.translate.instant('ERROR.SERVER.ICE_ERROR');
            if (needsErrorLog) {
              console.error(message);
            }
            break;
          case fromActions.AUDIT_FILTER_HISTORY_FAIL:
            message = message || this.translate.instant('ERROR.SERVER.SEARCH');
            if (needsErrorLog) {
              console.error(message);
            }
            break;
          case fromActions.DELETE_FAIL:
            message = message || this.translate.instant('ERROR.SERVER.DELETE_FAIL');
            if (needsErrorLog) {
              console.error(message);
            }
            break;
          case fromActions.GET_ALL_TERRITORIES_FAIL:
            message = message || this.translate.instant('ERROR.SERVER.ALL_TERRITORIES');
            if (needsErrorLog) {
              console.error(message);
            }
            break;
          default:
        }

        if (get(action, 'payload.status') === HttpStatusCode.TooManyRequests) {
          message = this.translate.instant('RESPONSE_CUSTOM_ERRORS.RETRIES_EXHAUSTED');
        }

        return new fromActions.ShowSnackBar({
          message,
          duration: errorMessageShowtime.normal,
          icon,
        });
      }),
    ),
  );

  getNavigationBadge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.GET_NAVIGATION_BADGES),
      map((action: fromActions.GetNavigationBadges) => action.payload),
      switchMap(userName => {
        const options = { entityType: 'agreement', from: 0, id: '***', ns: 'CUBE', size: 100, sort: '', xrefList: false, user: userName };
        return this.searchService.getSearchResults(SectionsConfig.AGREEMENTCONFLICT, options).pipe(
          map((data: SearchResult) => {
            let navigation = NavigationUtils.getNavigation(this.permissionsService);
            const length = get(data, 'items.length', 0);
            if (length) {
              const badge = AgreementUtils.getBadgeFromSearchAgreementConflicts(data, userName);
              if (badge) {
                navigation = NavigationUtils.setBadge(SectionsConfig.AGREEMENTCONFLICT.name, this.permissionsService, badge);
              }
            }
            return new fromRoot.GetNavigationBadgesSuccess(navigation);
          }),
          catchError(error => {
            return of(new fromRoot.FetchSearchPageFail(error));
          }),
        );
      }),
    ),
  );

  refreshNavigationOnChangeRoute$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromRouter.ROUTER_NAVIGATION, fromRoot.GET_NAVIGATION_BADGES_SUCCESS),
        withLatestFrom(
          this.store.pipe(select(fromRoot.getRouterUrl)),
          this.store.pipe(select(fromRoot.getRouterSection)),
          this.store.pipe(select(fromRoot.getGlobalNavigation)),
          this.store.pipe(select(fromRoot.getNavigation)),
        ),
        map(
          ([action, currentUrl, routerSection, globalNavigation, navigation]: [
            fromRouter.RouterNavigationAction | fromRoot.GetNavigationBadgesSuccess,
            string,
            string,
            IceNavigationState,
            any,
          ]) => {
            const navigationObject = action.type === fromRoot.GET_NAVIGATION_BADGES_SUCCESS ? action.payload : navigation;
            const sectionHomeConfigLocation = get(sections, `[${routerSection}].homeConfig.location`);
            if (action.type === fromRoot.GET_NAVIGATION_BADGES_SUCCESS && sectionHomeConfigLocation) {
              const navigationEdited = NavigationUtils.setNavigationVisibility(navigationObject, currentUrl, sectionHomeConfigLocation, routerSection);
              this.store.dispatch(new fromRoot.SetNavigation(navigationEdited));
            } else if (action.type === fromRoot.GET_NAVIGATION_BADGES_SUCCESS) {
              this.store.dispatch(new fromRoot.SetNavigation(navigationObject));
            }
          },
        ),
      ),
    { dispatch: false },
  );

  closeAdvancedSearchOnChangeRoute$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromRouter.ROUTER_NAVIGATION),
        withLatestFrom(this.store.pipe(select(fromRoot.getAdvancedSearchCollapsed))),
        withLatestFrom(this.store.pipe(select(fromRoot.getRouterSection))),
        withLatestFrom(this.store.pipe(select(fromRoot.getGlobalNavigationLastSection))),
        map(([[[_, collapsed], section], prevSection]) => {
          if (prevSection && section && prevSection !== section) {
            if (!collapsed) {
              this.store.dispatch(new fromActions.AdvancedSearchToggle(true));
            }
          }
        }),
      ),
    { dispatch: false },
  );

  unmarkPopup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromRouter.ROUTER_NAVIGATION),
        withLatestFrom(this.store.pipe(select(fromRoot.getRouterSection), startWith(<string>null), pairwise())),
        map(([_, [prevSection, section]]) => {
          if (prevSection && section && prevSection !== section) {
            this.store.dispatch(new fromActions.RestoreApiNamespace());
            this.store.dispatch(new UnmarkPopupOk()); // Reset edit mode on section change
          }
        }),
      ),
    { dispatch: false },
  );

  resetNewElementPagePopupOnLeavePage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromRouter.ROUTER_NAVIGATED),
        withLatestFrom(this.store.pipe(select(fromRoot.getRouterSection), startWith(<string>null), pairwise()), this.store.pipe(select(fromRoot.getCopyrightWorkEditClaim))),
        map(([_, [prevSection, section], editClaim]) => {
          if (prevSection !== section && !editClaim) {
            this.store.dispatch(new fromNewSectionItem.ResetNewSectionItem());
          }
        }),
      ),
    { dispatch: false },
  );

  routerNavigated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRouter.ROUTER_NAVIGATION),
      filter((action: any) => get(action, `payload.routerState.queryParams.forceNS`) || get(action, `event.url`)?.includes(`forceNS=true`)),
      map((action: any) => (get(action, `payload.routerState.url`)?.split('/')[2] || get(action, `event.url`)?.split('/')[3] || '').split(':')[0]),
      filter(id => !!id),
      map(id => new fromActions.ChangeApiNamespace(id)),
    ),
  );

  onAppFocus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.ON_APP_FOCUS),
        withLatestFrom(
          this.store.select(getRouterPaths),
          this.store.pipe(
            select(fromRoot.getConflictsActivity),
            map((activity: ActivityDetail) => ({ id: get(activity, `works[0].work.id`, ''), version: get(activity, `works[0].work.version`, '') })),
          ),
          this.store.pipe(
            select(fromRoot.getActivityWorkToCompare),
            map(workToCompare => ({ id: get(workToCompare, `id`, ''), version: get(workToCompare, `version`, '') })),
          ),
        ),
        tap(([action, path, masterWorkInfo, compareWorkInfo]) => {
          if (
            !isEmpty(path) &&
            path[0] === 'conflicts' &&
            path[1] === 'activity' &&
            (path[3] === 'analysis-merge' || path[3] === 'analysis') &&
            masterWorkInfo?.id &&
            isEmpty(this.dialog.openDialogs)
          ) {
            this.store.dispatch(
              new StartApiCall({
                apiCall: fromApiCalls.checkWorksVersion,
                apiCallData: { labels: { id: masterWorkInfo?.id } },
                callBack: masterWork => {
                  this.store.dispatch(
                    new StartApiCall({
                      apiCall: fromApiCalls.checkWorksVersion,
                      apiCallData: { labels: { id: compareWorkInfo?.id } },
                      callBack: compareWork => {
                        if (masterWork.version !== masterWorkInfo.version || compareWork.version !== compareWorkInfo.version) {
                          this.store.dispatch(
                            new StartApiCall({
                              apiCall: fromApiCalls.getWorkAnalysis,
                              callBack: workAnalysis => {
                                this.store.dispatch(
                                  new StartApiCall({
                                    apiCall: fromApiCalls.getWorkToCompare,
                                    callBack: workToCompare => {
                                      this.store
                                        .pipe(select(fromRoot.getRouterSection))
                                        .subscribe((section: any) => DialogMatchConflict.onSuccess({ store: this.store, workAnalysis, actionValue: 'CONFIRMED' }));
                                    },
                                  }),
                                );
                              },
                            }),
                          );
                        }
                      },
                    }),
                  );
                },
              }),
            );
          } else if (!isEmpty(path) && path[0] === 'copyright' && path[1] === 'works' && path[3] === 'conflicts-cc') {
            this.store.dispatch(
              new StartApiCall({
                apiCall: fromApiCalls.getWorkConflicts,
              }),
            );
          }
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private router: Router,
    private searchService: SearchService,
    private permissionsService: PermissionsService,
    private dialog: MatDialog,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
  }
}
