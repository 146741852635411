export const TRANSLATION_PREFIX = 'translation:';
export const CUSTOM_ERROR_TYPES = [
  {
    textToFind: 'shares exceed 100 percent in work',
    translateKey: 'SHARES_EXCEED_100_PERCENT_IN_WORK',
  },
  {
    textToFind: 'duplicate key',
    translateKey: 'DUPLICATE_KEY',
  },
];
export const RESPONSE_STATUS = { NEW: 'new', UPDATE: 'update', ERROR: 'error', IN_PROGRESS: 'in_progress', IN_PROGRESS_NEW: 'in-progress' };
export const TIMEOUT_MAX = 30_000;
