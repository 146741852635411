import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DataTableRow, SortInfo } from '@ice/components/data-table/data-table';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import { locale as englishTabs } from 'assets/i18n/en/config/tabs-data-builders';
import { DataTableBuilder } from 'config/data-table-builders/data-table-builder';
import { RootDatatable } from 'config/data-table-builders/root-datatable';

export class CopyrightRepertoiresDataTable extends RootDatatable implements DataTableBuilder {
  constructor(protected translate: TranslateService, protected fuseTranslationLoader: FuseTranslationLoaderService) {
    super(translate);
    this.fuseTranslationLoader.loadTranslations(english);
    this.fuseTranslationLoader.loadTranslations(englishTabs);
  }

  getDefaultSorting(): SortInfo[] {
    return [{ prop: 'name', dir: 'asc' }];
  }

  formatSort(sort: SortInfo) {
    switch (sort.prop) {
      case 'keyWithoutPrefix':
      case 'territoriesText':
        return null;
    }
    return { prop: `attributes.${sort.prop}`, dir: sort.dir };
  }

  getDataTable(): DataTableRow[] {
    return [
      {
        name: this.translate.instant('REPERTOIRES.DETAILS.DETAILS_CARD.KEY'),
        prop: 'keyWithoutPrefix',
        icons: 'assignor.icons',
        cellClass: 'ice-txt-size-14 repertoires-key',
        flexGrow: 1,
      },
      {
        name: this.translate.instant('REPERTOIRES.DETAILS.DETAILS_CARD.DESCRIPTION'),
        prop: 'name',
        cellClass: 'ice-txt-size-14 repertoires-name',
        flexGrow: 3.5,
        comparator: () => this.serverSideSorting(),
      },
      {
        name: this.translate.instant('REPERTOIRES.DETAILS.DETAILS_CARD.TYPE'),
        prop: 'type',
        cellClass: 'ice-txt-size-14 repertoires-type',
        flexGrow: 1,
        comparator: () => this.serverSideSorting(),
      },
      {
        name: this.translate.instant('REPERTOIRES.DETAILS.TERMS_CARD.START_DATE'),
        prop: 'startDate',
        cellClass: 'ice-txt-size-14 repertoires-valid-from',
        flexGrow: 0.8,
        comparator: () => this.serverSideSorting(),
      },
      {
        name: this.translate.instant('REPERTOIRES.DETAILS.TERMS_CARD.END_DATE'),
        prop: 'endDate',
        cellClass: 'ice-txt-size-14 repertoires-valid-to',
        flexGrow: 0.8,
        comparator: () => this.serverSideSorting(),
      },
      {
        name: this.translate.instant('REPERTOIRES.DETAILS.TERMS_CARD.TERRITORY'),
        icons: 'territoriesIcon',
        prop: 'territoriesText',
        tooltip: 'territoriesTooltip',
        tooltipDuration: 250,
        cellClass: 'ice-txt-size-14 repertoires-territory',
        flexGrow: 1.5,
      },
    ];
  }

  getColumnMode(): ColumnMode {
    return ColumnMode.flex;
  }

  displayCheck(row: any, firstSelectedItem: any, targetItem: any, column?: any, value?: any): boolean {
    return true;
  }
}
