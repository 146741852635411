import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DataTableRow, SortInfo } from '@ice/components/data-table/data-table';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import { DataTableBuilder } from 'config/data-table-builders/data-table-builder';
import { RootDatatable } from 'config/data-table-builders/root-datatable';

export class CopyrightSocietiesDataTable extends RootDatatable implements DataTableBuilder {
  constructor(protected translate: TranslateService, protected fuseTranslationLoader: FuseTranslationLoaderService) {
    super(translate);
    this.fuseTranslationLoader.loadTranslations(english);
  }

  getDefaultSorting(): SortInfo[] {
    return [{ prop: 'socName', dir: 'asc' }];
  }

  formatSort(sort: SortInfo) {
    switch (sort.prop) {
      case 'socName':
      case 'socCode':
      case 'countryCodes':
      case 'isIce':
        return null;
    }
    return { prop: `attributes.${sort.prop}`, dir: sort.dir };
  }

  getDataTable(): DataTableRow[] {
    return [
      { name: this.translate.instant('SOCIETIES.SOCIETY_NAME'), prop: 'socName', cellClass: 'ice-txt-size-14 societies-soc-code', flexGrow: 1.2 },
      { name: this.translate.instant('SOCIETIES.SOCIETY_CODE'), prop: 'socCode', cellClass: 'ice-txt-size-14 societies-soc-name', flexGrow: 1.2 },
      { name: this.translate.instant('SOCIETIES.COUNTRY_CODE'), prop: 'countryCodes', cellClass: 'ice-txt-size-14 societies-country', flexGrow: 1.2 },
      { name: this.translate.instant('SOCIETIES.IS_ICE'), prop: 'isIce', icons: 'isIceIcon', cellClass: 'ice-txt-size-14 societies-is-ice', flexGrow: 0.4 },
    ];
  }

  getColumnMode(): ColumnMode {
    return ColumnMode.flex;
  }

  displayCheck(row: any, firstSelectedItem: any, targetItem: any, column?: any, value?: any): boolean {
    return true;
  }
}
