import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { REPORT_TYPES_TRANSLATIONS } from 'config/constants/reports.constants';
import { get, toLower } from 'lodash';
import moment from 'moment';

export class SearchReportsParser {
  static parse(items: any[], translate: TranslateService, extraParams?: Object, extraItems?: string[], store?: Store<any>) {
    return items
      .map(item => {
        const { attributes } = item;
        const reportType = get(attributes, 'reportType', '');
        return {
          name: this.getReportyTypeById(reportType, translate),
          id: get(attributes, 'id', ''),
          date: attributes && attributes.requestDate ? moment(attributes.requestDate).format('YYYY-MM-DD HH:mm:ss') : '',
          status: get(attributes, 'status', ''),
          username: this.formatUsername(get(attributes, 'requestedBy', '')),
          reportType,
        };
      })
      .filter(report => !!report.id);
  }

  static getReportyTypeById(id, translate) {
    return !id ? '' : translate.instant(REPORT_TYPES_TRANSLATIONS[id] || '');
  }

  static formatUsername(userReport: string) {
    const splited = userReport && userReport.length ? userReport.split(':') : '';
    return splited && splited.length ? `${toLower(splited[1])}-${toLower(splited[0])}` : userReport;
  }
}
