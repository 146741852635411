import { Organization, OrganizationRole } from '../detail/organizations';

export interface OrganizationSearchItem {
  id: string;
  version?: number;
  attributes?: Organization;
  roles?: OrganizationRole[];
}

export enum OrganizationCaseStatusType {
  OP = 'OPEN',
  CL = 'CLOSED',
  AR = 'AWAITING_REPLY',
  IP = 'IN_PROGRESS',
  IPNEW = 'IN-PROGRESS',
  SP = 'SUPPORTED',
  CF = 'CONFIRMED',
  RS = 'RESOLVED',
  RSCH = 'RESEARCHED',
  NEW = 'NEW',
}

export interface OrganizationCaseStatusTypes {
  open: string;
  closed: string;
  awaiting_replay: string;
  in_progress: string;
  supported: string;
  resolved: string;
  confirmed: string;
  researched: string;
}

export const organizationCaseStatusOptions: OrganizationCaseStatusTypes = {
  open: 'OPEN',
  closed: 'CLOSED',
  awaiting_replay: 'AWAITING REPLY',
  in_progress: 'IN PROGRESS',
  supported: 'SUPPORTED',
  resolved: 'RESOLVED',
  confirmed: 'CONFIRMED',
  researched: 'RESEARCHED',
};

export interface OrganizationSearchItemExtras {
  dataType: 'organization';
}

export type OrganizationSearchCleaned = Pick<Organization, 'id' | 'name' | 'type' | 'roles'> & OrganizationSearchItemExtras;
