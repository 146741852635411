import { createLabelResolver } from '../api-call.factory';

export const getExtraParamId = createLabelResolver({
  extraParam: detail => detail.id.split(':')[1],
});

export const getAttributesId = createLabelResolver({ id: `attributes.id` });

export const getOrganizationsRelationsMatchPatternLabelResolver = createLabelResolver({
  pattern: detail => `{"equals": {"relations[XREF].otherId": "<<placeholder>>"}}`,
});

export const getPartyNames = createLabelResolver({ placeholder: `attributes.access.partyNames` });
