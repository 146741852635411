import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BulkUpdateSegment } from 'models/copyright/bulk-updates/jobs.model';
import { StepType } from 'config/stepper-builders/stepper-config';
import { take } from 'rxjs/operators';
import * as fromRoot from 'store/root';

export class BulkUpdateTypeStep {
  constructor(private translate: TranslateService, private store: Store<fromRoot.RootState>) {}

  getStep(): StepType {
    return {
      label: this.translate.instant('BULK-UPDATES.TYPE.GROUP_LABEL'),
      formBuilder: [
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-2',
              key: 'bulkUpdateType',
              type: 'select',
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                required: true,
                label: this.translate.instant('BULK-UPDATES.TYPE.NAME'),
                options: [
                  {
                    label: this.translate.instant('BULK-UPDATES.TYPE.IP_SWAP.LABEL'),
                    value: BulkUpdateSegment.SWAP_IP,
                  },
                  {
                    label: this.translate.instant('BULK-UPDATES.TYPE.IP_TRANSFER.LABEL'),
                    value: BulkUpdateSegment.TRANSFER_IP,
                  },
                  {
                    label: this.translate.instant('BULK-UPDATES.TYPE.IP_TERMINATION.LABEL'),
                    value: BulkUpdateSegment.TERMINATE_IP,
                  },
                  {
                    label: this.translate.instant('BULK-UPDATES.TYPE.PURPOSE.LABEL'),
                    value: BulkUpdateSegment.PURPOSE,
                  },
                  {
                    label: this.translate.instant('BULK-UPDATES.TYPE.TITLES.LABEL'),
                    value: BulkUpdateSegment.TITLES,
                  },
                  {
                    label: this.translate.instant('BULK-UPDATES.TYPE.OTHER_PARTIES.LABEL'),
                    value: BulkUpdateSegment.OTHER_PARTIES,
                  },
                  {
                    label: this.translate.instant('BULK-UPDATES.TYPE.WORK_XREFS.LABEL'),
                    value: BulkUpdateSegment.WORK_XREFS,
                  },
                  {
                    label: this.translate.instant('BULK-UPDATES.TYPE.WORK_MATCHES.LABEL'),
                    value: BulkUpdateSegment.WORK_MATCHES,
                  },
                  {
                    label: this.translate.instant('BULK-UPDATES.TYPE.WORK_BROADCAST_INFO.LABEL'),
                    value: BulkUpdateSegment.WORK_BROADCAST_INFO,
                  },
                ],
              },
            },
            {
              key: `workIds`,
              defaultValue: [],
              hooks: {
                onInit: field => {
                  this.store
                    .select(fromRoot.getUpdateMode)
                    .pipe(take(1))
                    .subscribe(updateMode => {
                      if (updateMode?.items) {
                        field.formControl.setValue(updateMode.items.map(work => work.key));
                        field.options.updateInitialValue();
                        this.store.dispatch(new fromRoot.EndUpdateMode());
                      }
                    });
                },
              },
            },
          ],
        },
      ],
    };
  }
}
