import { Injectable } from '@angular/core';
import { ActionResponse, ActionStatus, ActionType, CounterClaimResolutionOwnerType } from 'config/constants/activity.constants';
import moment from 'moment';
import { ExpertSearchParseService } from './expert-search-parse.service';

@Injectable()
export class ExpertSearchParseCounterClaimsActionsService extends ExpertSearchParseService {
  constructor() {
    const AttributesMapCounterClaims = {
      counterClaimId: 'counterclaim.id',
      id: 'id',
      workRef: 'counterclaim.work.relations[XREF].otherId',
      actionResolver: 'counterclaimParticipant.claimant.relations[XREF].otherId',
      resolutionOwners: 'counterclaimParticipant.resolutionOwner',
      actionType: 'attributes.type',
      actionStatus: 'attributes.status',
      actionOwner: 'ownerName.relations[XREF].otherId',
      actionUser: 'attributes.assignor',
      actionDeadlineFrom: async (comparator: string, value: string, _deep = false) => ({
        and: [{ range: { 'attributes.deadline': { gte: moment(this.cleanDateValue(value)).format('YYYY-MM-DD') } } }],
      }),
      actionDeadlineTo: async (comparator: string, value: string, _deep = false) => ({
        and: [{ range: { 'attributes.deadline': { lte: moment(this.cleanDateValue(value)).format('YYYY-MM-DD') } } }],
      }),
      actionResponse: 'attributes.response',
      actionDeadline: async (comparator: string, value: string, _deep = false) => ({
        and: [{ equals: { 'attributes.deadline': moment(this.cleanDateValue(value)).format('YYYY-MM-DD') } }],
      }),
    };

    // Filtering criteria in the following this jira:
    // https://onstage.atlassian.net/browse/CUBE-11514
    const typesToRemove = [ActionType.COUNTERCLAIM_RESOLVED, ActionType.NOTIFICATION];

    const attributesEnumMapCounterClaims = {
      ['resolutionOwners']: Object.keys(CounterClaimResolutionOwnerType),
      ['actionType']: Object.keys(ActionType).filter(actionType => !typesToRemove.some(typeToRemove => typeToRemove === ActionType[actionType])),
      ['actionStatus']: Object.keys(ActionStatus),
      ['actionResponse']: Object.keys(ActionResponse),
    };

    super(attributesEnumMapCounterClaims, AttributesMapCounterClaims);
  }

  cleanDateValue(dateString: string): string {
    return dateString.replace(/\*/g, '');
  }
}
