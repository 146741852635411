import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { UploadFileModule } from '@ice/components/upload-file/upload-file.module';
import { IceMaterialModule } from '@ice/material.module';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { TranslateModule } from '@ngx-translate/core';
import { UploadFileTypeComponent } from './upload-file-type.component';

@NgModule({
  declarations: [UploadFileTypeComponent],
  imports: [TranslateModule, FuseSharedModule, IceMaterialModule, FormlyMaterialModule, FormlyModule.forRoot(), UploadFileModule],
  exports: [UploadFileTypeComponent],
})
export class UploadFileTypeModule {}
