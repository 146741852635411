import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomToolTipComponent } from './custom-tool-tip.component';

@NgModule({
  imports: [CommonModule],
  declarations: [CustomToolTipComponent],
  exports: [CustomToolTipComponent],
})
export class CustomTooltipModule {}
