import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ActivityDetail, CaseWorkDetail, ConflictUtils, ContributorsUtils, CopyrightUtils, DateTimeUtils, RelationsUtils, sortFieldGroups, WorkUtils } from '@ice';
import { AnalysisAndComparisons } from '@ice/dynamic-components/analysis-and-comparisons/analysis-and-comparisons';
import { ActivityUtils } from '@ice/utils/activity/activity.utils';
import { SocietiesUtils } from '@ice/utils/societies/societies.utils';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as fromApiCalls from 'config/api-calls';
import { ActivityType } from 'config/constants/activity.constants';
import { WORK_RELATION_TYPE } from 'config/constants/works.constants';
import { SharesFilterConfig } from 'config/filter-builders/shares-filter';
import { SectionsConfig } from 'config/sections-config';
import { ApiCallData } from 'config/sections-config/api-call';
import moment from 'moment';
import { of } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { NamespaceService } from 'services/namespace/namespace.service';
import * as fromRoot from 'store/root';
import { SectionTabConfig } from '../../tabs-data';
import { TabActivity } from './tab-activity';

export class TabActivityMatchAnalysis extends TabActivity implements SectionTabConfig {
  constructor(
    public translate: TranslateService,
    public fuseTranslationLoader: FuseTranslationLoaderService,
    public store: Store<fromRoot.RootState>,
    dialog: null,
    commonApiService: null,
    detailService: null,
    public nsService: NamespaceService,
  ) {
    super(translate, fuseTranslationLoader, store, dialog, commonApiService, detailService, nsService);
    this.getFormlyModel();
  }

  getConf(): AnalysisAndComparisons {
    const HEIGHT_TOP_CARDS = 170;
    const today = DateTimeUtils.formatDate(moment());
    const left$ = this.store.pipe(select(fromRoot.getActivityWorkToCompareSocietyMarkers)).pipe(map(societyMarkers => WorkUtils.getSocietyMarkers(societyMarkers, this.translate)));
    const right$ = this.store.pipe(select(fromRoot.getActivityWorkAnalysisSocietyMarkers)).pipe(map(societyMarkers => WorkUtils.getSocietyMarkers(societyMarkers, this.translate)));

    const maxHeight = 296;
    const xrefsLeft = this.store.pipe(select(fromRoot.getActivityWorkToCompareRelations));
    const xrefsRight = this.store.pipe(select(fromRoot.getActivityWorkAnalysisRelations));
    const instrumentsLeft = this.store.pipe(
      select(fromRoot.getActivityWorkToCompareInstruments),
      map(instruments => WorkUtils.getWorkInstruments(instruments, this.translate)),
    );
    const instrumentsRight = this.store.pipe(
      select(fromRoot.getActivityWorkAnalysisInstruments),
      map(instruments => WorkUtils.getWorkInstruments(instruments, this.translate)),
    );
    const titlesLeft = this.store.pipe(
      select(fromRoot.getActivityWorkToCompareTitles),
      map(titles => this.completeTitles(titles)),
    );
    const titlesRight = this.store.pipe(
      select(fromRoot.getActivityWorkAnalysisTitles),
      map(titles => this.completeTitles(titles)),
    );
    const otherPartiesRight = this.store.select(fromRoot.getActivityWorkToCompareOtherParties);
    const otherPartiesLeft = this.store.select(fromRoot.getActivityWorkAnalysisOtherParties);

    return {
      comparisonFilter: {
        isVisible: of(true),
        formBuilder: of(
          SharesFilterConfig.getActivityForm(this.store, this.translate, ActivityType.MATCH, claimsFilter =>
            this.store.dispatch(new fromRoot.SetClaimsFilterToCompare({ ...claimsFilter, usageDate: claimsFilter.usageDate || today })),
          ),
        ),
        model: {
          removeE: false,
          removeSE: true,
        },
        formClass: 'bg-filter-form ice-p-16',
        submitShortcutEnable: true,
      },
      cardWithFormWorkToCompare: {
        title: this.store.pipe(
          select(fromRoot.getActivityWorkToCompare),
          map(model => this.getTitle(model)),
        ),
        iswc: this.store.pipe(
          select(fromRoot.getActivityWorkToCompare),
          map(model => this.getIswcAndStatus(model)),
        ),
        wsm: this.store.pipe(
          select(fromRoot.getActivityWorkToCompare),
          map(model => this.getSocietyMarkers(model)),
        ),
        expanded: this.expanded8,
        model: this.store.pipe(
          select(fromRoot.getActivityWorkToCompare),
          map(model => this.cleanMetaDataModel(model)),
        ),
        resetAvailable: false,
        submitAvailable: false,
        button1Available: false,
        formBuilder: this.formlyModel,
        buttonTitle: 'launch',
        buttonTitleTooltip: this.translate.instant('ACTIVITY.ANALYSIS.OPEN_WORK'),
        extendedWorkSelector: true,
        openNewTab: (workId: string) => {
          if (workId.indexOf('CUBE:') !== -1) {
            this.store
              .pipe(select(fromRoot.getActivityWorkToCompare))
              .subscribe((workToCompare: CaseWorkDetail) => {
                const iceId =
                  RelationsUtils.getWorkIdFromRelations(workToCompare?.relations) ||
                  workToCompare?.relations?.find(rel => rel.relation === WORK_RELATION_TYPE.MATCH)?.otherId ||
                  workId;
                this.store.dispatch(new fromRoot.OpenNewTab({ path: [`copyright/${SectionsConfig.WORKS.name}/${iceId}`] }));
              })
              .unsubscribe();
          } else {
            this.store.dispatch(new fromRoot.OpenNewTab({ path: [`copyright/${SectionsConfig.WORKS.name}/${workId}`] }));
          }
        },
        onSelect: event => {
          if (event.value) {
            this.store.dispatch(new fromRoot.SetSelectedOptionToCompare({ key: event.value }));
            const apiCallData: ApiCallData = { labels: { id: event.value } };
            this.store.dispatch(new fromRoot.StartApiCall({ apiCall: fromApiCalls.getWorkToCompare, apiCallData }));
          }
        },
        options: this.options$,
        extendedWorkSelectorTitle: this.getExtendedWorkSelectorTitle(ActivityType.MATCH),
        extendedWorkSelectorPlaceHolder: this.getExtendedWorkSelectorPlaceHolder(ActivityType.MATCH),
        optionSelected: this.store.select(fromRoot.getActivityOptionsToCompare).pipe(
          withLatestFrom(this.store.pipe(select(fromRoot.getActivityWorkToCompare))),
          map(([options, workToCompare]) => {
            return options.find(option => option.viewValue === workToCompare.id)?.value;
          }),
        ),
        open: () => this.expanseRow(8),
        close: () => this.collapseRow(8),
        listIdentifier: 'compare-left',
      },
      cardWithFormMasteredWork: {
        className: 'analysis-right',
        openNewTab: (workId: string) => {
          this.store
            .pipe(select(fromRoot.getActivityWorkAnalysis))
            .subscribe((workToCompare: CaseWorkDetail) => {
              const iceId =
                RelationsUtils.getWorkIdFromRelations(workToCompare?.relations) ||
                workToCompare?.relations?.find(rel => rel.relation === WORK_RELATION_TYPE.MATCH)?.otherId ||
                workId;
              this.store.dispatch(new fromRoot.OpenNewTab({ path: [`copyright/${SectionsConfig.WORKS.name}/${iceId}`] }));
            })
            .unsubscribe();
        },
        buttonTitle: 'launch',
        buttonTitleTooltip: this.translate.instant('ACTIVITY.ANALYSIS.OPEN_WORK'),
        title: this.store.pipe(
          select(fromRoot.getActivityWorkAnalysis),
          map(model => this.getTitle(model)),
        ),
        iswc: this.store.pipe(
          select(fromRoot.getActivityWorkAnalysis),
          map(model => this.getIswcAndStatus(model)),
        ),
        wsm: this.store.pipe(
          select(fromRoot.getActivityWorkAnalysis),
          map(model => this.getSocietyMarkers(model)),
        ),
        expanded: this.expanded8,
        referenceId: this.store.select(fromRoot.getActivityWorkAnalysisId),
        model: this.store.pipe(
          select(fromRoot.getActivityWorkAnalysis),
          map(model => this.cleanMetaDataModel(model)),
        ),
        resetAvailable: false,
        submitAvailable: false,
        formBuilder: this.formlyModel,
        open: () => this.expanseRow(8),
        close: () => this.collapseRow(8),
        listIdentifier: 'compare-left',
      },
      ...this.getClaimsHeaderToggle(),
      ...this.claimsComparatorConfig(true, ActivityType.MATCH),
      expansionListDataTableClaimsLeft: {
        className: 'analysis-left',
        listIdentifier: 'analysis-left',
        title: this.translate.instant('ACTIVITY.ANALYSIS.REVISION.SUBMITTED_CLAIMS'),
        model: {
          model: this.sharesCompare.pipe(
            map(shareLines => shareLines.map(shareLine => this.cleanLabels(shareLine.compare || this.getDummyRow() || this.getDummyRow(), this.translate))),
          ),
          columnMode: 'flex',
          schema: this.getSchemaClaims(),
        },
        onResized: event => this.drawHighLights(this.toggleHighlightActive),
        onSelect: id => {},
        onMouseSelect: event => {},
        open: () => this.expanseRow(4),
        close: () => this.collapseRow(4),
        expanded: this.expanded4,
        height: this.ownershipCountObservable,
      },
      expansionListDataTableClaimsRight: {
        className: 'analysis-right',
        listIdentifier: 'analysis-right',
        title: this.translate.instant('ACTIVITY.ANALYSIS.REVISION.SUBMITTED_CLAIMS'),
        model: {
          model: this.sharesCompare.pipe(
            map(shareLines => shareLines.map(shareLine => this.cleanLabels(shareLine.mastered || this.getDummyRow() || this.getDummyRow(), this.translate))),
          ),
          columnMode: 'flex',
          schema: this.getSchemaClaims(),
        },
        onSelect: id => {},
        onMouseSelect: event => {},
        open: () => this.expanseRow(4),
        close: () => this.collapseRow(4),
        expanded: this.expanded4,
        height: this.ownershipCountObservable,
      },
      expansionListTitlesLeft: {
        className: 'analysis-left',
        title: this.translate.instant('ACTIVITY.ANALYSIS.REVISION.LIST_TITLES'),
        model: {
          model: ActivityUtils.sortTitles(this.compareListsAndHighlightDifference(titlesLeft, titlesRight, 'title')),
          columnMode: 'flex',
          schema: this.getTilesSchemaClaims(),
        },
        listIdentifier: 'all-titles-compare',
        open: () => this.expanseRow(1),
        close: () => this.collapseRow(1),
        expanded: this.expanded1,
        height: this.getTitlesHeight(),
        maxHeight,
      },
      expansionListTitlesRight: {
        className: 'analysis-right',
        title: this.translate.instant('ACTIVITY.ANALYSIS.REVISION.LIST_TITLES'),
        model: {
          model: ActivityUtils.sortTitles(titlesRight),
          columnMode: 'flex',
          schema: this.getTilesSchemaClaims(),
        },
        listIdentifier: 'all-titles-main',
        open: () => this.expanseRow(1),
        close: () => this.collapseRow(1),
        expanded: this.expanded1,
        height: this.getTitlesHeight(),
        maxHeight,
      },
      expansionListXrefsLeft: {
        className: 'analysis-left',
        title: this.translate.instant('WORKS.DETAILS.EXPANSION_LIST.XREFS'),
        model: ActivityUtils.sortXref(ActivityUtils.compareListsAndHighlightDifferenceXref(xrefsLeft, xrefsRight, 'value')),
        listIdentifier: 'xrefs-compare',
        open: () => this.expanseRow(0),
        close: () => this.collapseRow(0),
        expanded: this.expanded0,
        height: this.getXrefsHeight(this.activityCases$, maxHeight),
        maxHeight,
        cardClass: 'xref-card',
      },
      expansionListXrefsRight: {
        className: 'analysis-right',
        title: this.translate.instant('WORKS.DETAILS.EXPANSION_LIST.XREFS'),
        model: ActivityUtils.sortXref(xrefsRight),
        listIdentifier: 'xrefs-main',
        open: () => this.expanseRow(0),
        close: () => this.collapseRow(0),
        expanded: this.expanded0,
        height: this.getXrefsHeight(this.activityCases$, maxHeight),
        maxHeight,
        cardClass: 'xref-card',
      },
      expansionListDataTableInstrumentsLeft: {
        className: 'analysis-left',
        title: this.translate.instant('WORKS.DETAILS.EXPANSION_LIST.INSTRUMENTS'),
        extraData: {
          workId: this.store.pipe(select(fromRoot.getActivitySelectedOptionToCompare)),
        },
        model: {
          model: this.compareListsAndHighlightDifference(instrumentsLeft, instrumentsRight, 'instrumentCode'),
          sorts: [{ prop: 'instrumentCode', dir: 'asc' }],
          columnMode: 'flex',
          schema: this.getSchemaInstruments(),
        },
        listIdentifier: 'instruments-compare',
        open: () => this.expanseRow(5),
        close: () => this.collapseRow(5),
        expanded: this.expanded5,
        height: this.getOtherInstrumentsHeight(),
      },
      expansionListDataTableInstrumentsRight: {
        className: 'analysis-right',
        title: this.translate.instant('WORKS.DETAILS.EXPANSION_LIST.INSTRUMENTS'),
        extraData: {
          workId: this.store.pipe(
            select(fromRoot.getConflictsActivity),
            map((activity: ActivityDetail) => ConflictUtils.getWorkTitle(activity)),
          ),
        },
        model: {
          model: instrumentsRight,
          sorts: [{ prop: 'instrumentCode', dir: 'asc' }],
          columnMode: 'flex',
          schema: this.getSchemaInstruments(),
        },
        listIdentifier: 'instruments-main',
        open: () => this.expanseRow(5),
        close: () => this.collapseRow(5),
        expanded: this.expanded5,
        height: this.getOtherInstrumentsHeight(),
      },
      otherPartiesLeft: {
        className: 'analysis-left',
        title: this.translate.instant('ACTIVITY.ANALYSIS.REVISION.OTHER_PARTY'),
        model: {
          model: this.compareListsAndHighlightDifference(otherPartiesRight, otherPartiesLeft, 'fullName'),
          sorts: [{ prop: 'fullName', dir: 'asc' }],
          columnMode: 'flex',
          schema: WorkUtils.getOtherPartyTableCommonFields(this.translate),
        },
        listIdentifier: 'other-party-compare',
        open: () => this.expanseRow(10),
        close: () => this.collapseRow(10),
        expanded: this.expanded10,
        height: this.getOtherPartiesHeight(),
        maxHeight,
      },
      otherPartiesRight: {
        className: 'analysis-right',
        title: this.translate.instant('ACTIVITY.ANALYSIS.REVISION.OTHER_PARTY'),
        model: {
          model: otherPartiesLeft,
          sorts: [{ prop: 'fullName', dir: 'asc' }],
          columnMode: 'flex',
          schema: WorkUtils.getOtherPartyTableCommonFields(this.translate),
        },
        listIdentifier: 'other-party-main',
        open: () => this.expanseRow(10),
        close: () => this.collapseRow(10),
        expanded: this.expanded10,
        height: this.getOtherPartiesHeight(),
        maxHeight,
      },
      workSocietyMarkerLeft: {
        class: 'flex-1 work-society-list',
        type: 'select',
        title: this.translate.instant('WORKS.DETAILS.SOCIETY_MARKER_CARD.TITLE'),
        selectTitle: this.translate.instant('WORKS.DETAILS.SOCIETY_MARKER_CARD.SUBTITLE'),
        expanded: this.expanded9,
        model: this.getActivityWorkToCompareSocietyMarkersWithHighlight(left$, right$),
        minHeight: HEIGHT_TOP_CARDS,
        filterItemsOn: 10,
        onSelect: event => {},
        showCounter: true,
        onOpen: () => this.expanseRow(9),
        onClosed: () => this.collapseRow(9),
      },
      workSocietyMarkerRight: {
        class: 'flex-1 work-society-list',
        headerClass: 'analysis-right',
        type: 'select',
        title: this.translate.instant('WORKS.DETAILS.SOCIETY_MARKER_CARD.TITLE'),
        selectTitle: this.translate.instant('WORKS.DETAILS.SOCIETY_MARKER_CARD.SUBTITLE'),
        expanded: this.expanded9,
        model: right$,
        minHeight: HEIGHT_TOP_CARDS,
        filterItemsOn: 10,
        onSelect: event => {},
        showCounter: true,
        onOpen: () => this.expanseRow(9),
        onClosed: () => this.collapseRow(9),
      },
    };
  }

  private getFormlyModel() {
    this.formlyModel = this.activityType.pipe(
      map(_ => {
        return [
          {
            fieldGroupClassName: 'display-flex ice-limit-form',
            fieldGroup: this.sortForActivityTab([
              ...this.getWorkDetailProperties('main', 'flex-5'),
              ...this.getWorkDetailProperties('genre', 'flex-5'),
              ...this.getWorkDetailProperties('other', 'flex-5'),
            ]),
          },
          {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [...this.getWorkDetailProperties('purpose', 'flex-2')],
          },
        ];
      }),
    );
  }

  private sortForActivityTab(fieldGroup: any[]): any[] {
    const orderKeys: string[] = ['version', 'owner', 'origin', 'genre', 'durationFormatted', 'source'];
    return sortFieldGroups(fieldGroup, orderKeys);
  }

  private cleanLabels(row: any, translate: any): any {
    const regexNumber = new RegExp(/(\d+)/g);
    row.ipiName = row?.ipiName ? row?.ipiName.replace(/IPI:/g, '') : '';
    row.prSociety = row?.prSociety ? row?.prSociety.match(regexNumber)[0] : '';
    row.mrSociety = row?.mrSociety ? row?.mrSociety.match(regexNumber)[0] : '';
    row.roleTooltip = row?.roleRaw ? ContributorsUtils.getRoleLabelFromRoleValue(row.roleRaw) : '';
    row.prSocietyTooltip = (row?.prSociety && SocietiesUtils.searchSocietyNameById(row?.prSociety)) || '';
    row.mrSocietyTooltip = (row?.mrSociety && SocietiesUtils.searchSocietyNameById(row?.mrSociety)) || '';
    row.alertIcons = row?.isIncomeParticipant ? CopyrightUtils.getIncomeparticipantIconWithTooltip(translate) : undefined;
    return row;
  }
}
