import { Observable } from 'rxjs';

export interface HtmlTemplate {
  htmlTemplate?: Observable<string>;
  htmlClass?: string;
  buttons?: HtmlButton[];
}

export interface HtmlButton {
  label?: string;
  action?: any;
  class?: string;
  color?: string;
}

export const BUTTON_DEFAULT_COLOR = 'accent';
