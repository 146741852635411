export const locale = {
  lang: 'en',
  data: {
    INSTRUMENTATION: {
      AUD: {
        DESCRIPTION: 'Audience',
        DESCRIPTION_TEXT: '',
      },

      BBA: {
        DESCRIPTION: 'Brass Band',
        DESCRIPTION_TEXT: 'Large group of brasses (approximately 25 players) with optional percussion',
      },

      BCH: {
        DESCRIPTION: 'Chorus Boys',
        DESCRIPTION_TEXT: "Multiple boys' voices on a part",
      },

      BND: {
        DESCRIPTION: 'Band',
        DESCRIPTION_TEXT: 'Large group of woodwinds and brass with optional percussion keyboard and double bass',
      },

      BQN: {
        DESCRIPTION: 'Brass Quintet',
        DESCRIPTION_TEXT: '2 trumpets horn trombone tuba',
      },

      BQU: {
        DESCRIPTION: 'Brass Quartet',
        DESCRIPTION_TEXT: '2 trumpets horn trombone or tuba',
      },

      BRC: {
        DESCRIPTION: 'Brass Choir',
        DESCRIPTION_TEXT: 'Group of brasses with optional percussion (usually 10-20 players)',
      },

      BTR: {
        DESCRIPTION: 'Brass Trio',
        DESCRIPTION_TEXT: 'Trumpet horn trombone',
      },

      BXT: {
        DESCRIPTION: 'Brass Sextet',
        DESCRIPTION_TEXT: '2 trumpets 2 horns trombone tuba',
      },

      CAO: {
        DESCRIPTION: 'Chorus and Orchestra',
        DESCRIPTION_TEXT: 'Multiple voices with orchestra',
      },

      CBA: {
        DESCRIPTION: 'Concert Band',
        DESCRIPTION_TEXT: 'Large group of woodwinds and brass with optional percussion keyboard and double bass',
      },

      CCH: {
        DESCRIPTION: "Chorus Children's",
        DESCRIPTION_TEXT: 'Multiple treble voices on a part',
      },

      CEN: {
        DESCRIPTION: 'Chamber Ensemble',
        DESCRIPTION_TEXT: 'Mixed group of single players on a part\nCHO',
      },

      CLC: {
        DESCRIPTION: 'Clarinet Choir',
        DESCRIPTION_TEXT: 'Multiple clarinets (usually 10-20)',
      },

      COR: {
        DESCRIPTION: 'Orchestra Chamber',
        DESCRIPTION_TEXT: 'Orchestra with fewer winds and strings than full orchestra (usually 20-25 players)',
      },

      CQN: {
        DESCRIPTION: 'Clarinet Quintet',
        DESCRIPTION_TEXT: 'Clarinet and string quartet',
      },

      DCH: {
        DESCRIPTION: 'Chorus Double',
        DESCRIPTION_TEXT: 'Two choruses',
      },

      FLC: {
        DESCRIPTION: 'Flute Choir',
        DESCRIPTION_TEXT: 'Multiple flutes (usually 10-20)',
      },

      GML: {
        DESCRIPTION: 'Gamelan',
        DESCRIPTION_TEXT: 'Indonesian percussion ensemble usually including gongs chimes mallet percussion and drums',
      },

      GQT: {
        DESCRIPTION: 'Guitar Quartet',
        DESCRIPTION_TEXT: '4 guitars',
      },

      HNC: {
        DESCRIPTION: 'Horn Choir',
        DESCRIPTION_TEXT: 'Multiple horns (10-20 players)',
      },

      JZE: {
        DESCRIPTION: 'Jazz Ensemble',
        DESCRIPTION_TEXT: 'Multiple saxophones trumpets trombones with rhythm section of piano double bass drum set guitar',
      },

      MCH: {
        DESCRIPTION: "Chorus Men's",
        DESCRIPTION_TEXT: "Multiple men's voices on a part",
      },

      MXC: {
        DESCRIPTION: 'Chorus Mixed',
        DESCRIPTION_TEXT: 'Multiple voices on a part (usually SATB)',
      },

      OQU: {
        DESCRIPTION: 'Oboe Quartet',
        DESCRIPTION_TEXT: 'Oboe and string trio',
      },

      ORC: {
        DESCRIPTION: 'Orchestra Full',
        DESCRIPTION_TEXT: 'Large group of multiple strings with woodwinds brass and percussion',
      },

      PCE: {
        DESCRIPTION: 'Percussion Ensemble',
        DESCRIPTION_TEXT: 'Multiple players on various instruments',
      },

      PDU: {
        DESCRIPTION: 'Piano Duo',
        DESCRIPTION_TEXT: '2 pianists on two pianos',
      },

      PFH: {
        DESCRIPTION: 'Piano Four Hands',
        DESCRIPTION_TEXT: '2 pianists on one piano',
      },

      PQN: {
        DESCRIPTION: 'Piano Quintet',
        DESCRIPTION_TEXT: 'Piano and string quartet',
      },

      PQU: {
        DESCRIPTION: 'Piano Quartet',
        DESCRIPTION_TEXT: 'Piano and string trio',
      },

      PTR: {
        DESCRIPTION: 'Piano Trio',
        DESCRIPTION_TEXT: 'Violin cello piano',
      },

      SBA: {
        DESCRIPTION: 'Symphonic Band',
        DESCRIPTION_TEXT: 'Large group of woodwinds and brass with optional percussion keyboard and double bass',
      },

      SGT: {
        DESCRIPTION: 'String Trio',
        DESCRIPTION_TEXT: 'Violin viola cello',
      },

      SOC: {
        DESCRIPTION: 'String Octet',
        DESCRIPTION_TEXT: 'Double string quartet',
      },

      SOR: {
        DESCRIPTION: 'Orchestra String',
        DESCRIPTION_TEXT: 'Multiple strings on a part (usually 16 or more)',
      },

      SQN: {
        DESCRIPTION: 'String Quintet',
        DESCRIPTION_TEXT: '2 violins viola cello and one other stringed instrument',
      },

      SQT: {
        DESCRIPTION: 'Saxophone Quartet',
        DESCRIPTION_TEXT: '4 saxophones',
      },

      SQU: {
        DESCRIPTION: 'String Quartet',
        DESCRIPTION_TEXT: '2 violins viola cello',
      },

      TBC: {
        DESCRIPTION: 'Trombone  Choir',
        DESCRIPTION_TEXT: 'Multiple trombones (usually 10-20)',
      },

      TCH: {
        DESCRIPTION: 'Chorus Treble',
        DESCRIPTION_TEXT: "Multiple children's voices on a part",
      },

      TPC: {
        DESCRIPTION: 'Trumpet Choir',
        DESCRIPTION_TEXT: 'Multiple trumpets (usually 10-20)',
      },

      TUC: {
        DESCRIPTION: 'Tuba Choir',
        DESCRIPTION_TEXT: 'Multiple tubas (usually 10-20)',
      },

      UCH: {
        DESCRIPTION: 'Chorus Unison',
        DESCRIPTION_TEXT: 'Multiple voices on a single part',
      },

      WCH: {
        DESCRIPTION: "Chorus Women's",
        DESCRIPTION_TEXT: "Multiple women's voices on a part",
      },

      WEN: {
        DESCRIPTION: 'Wind Ensemble',
        DESCRIPTION_TEXT: 'Large group of woodwinds and brass with optional percussion keyboard and double bass',
      },

      WQN: {
        DESCRIPTION: 'Woodwind Quintet',
        DESCRIPTION_TEXT: 'Flute oboe clarinet bassoon horn',
      },

      WQR: {
        DESCRIPTION: 'Woodwind Quartet',
        DESCRIPTION_TEXT: 'Flute oboe clarinet bassoon',
      },

      YCH: {
        DESCRIPTION: 'Chorus Youth',
        DESCRIPTION_TEXT: 'Multiple young voices on a part',
      },
    },
  },
};
