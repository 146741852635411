import { Injectable } from '@angular/core';
import { ExpertSearchParseService } from './expert-search-parse.service';

@Injectable()
export class ExpertSearchParseReportsService extends ExpertSearchParseService {
  constructor() {
    const AttributesMapReports = {
      id: 'attributes.id',
      requestedBy: 'attributes.requestedBy',
      reportType: 'attributes.reportType',
    };
    const attributesEnumMapReports = {};

    super(attributesEnumMapReports, AttributesMapReports);
  }
}
