import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import type { DetectedToken, HighlightTag, TagMouseEvent } from './highlight.component';
import { TypeaheadDirective } from './typeahead.directive';
import type { TypeaheadState } from './typeahead.model';

export interface TokenTree<T> {
  children?: TokenTree<T>;
  tokens: DetectedToken<T>[];
}

export interface ParsedInput<T> {
  key: string;
  text: string;
  typeMask: T[];
  levelMask: number[];
  tags: HighlightTag[];
  tokens: DetectedToken<T>[];
}

export interface QueryLanguageParser<T> {
  calculateTypeaheadState(analyzedInput: ParsedInput<T>, selectionStart: number): TypeaheadState<T>;
  processNextCharacter(tokenized: any, char: string);
  tokenize(key, text, selection): ParsedInput<T>;
  findTokenType(editorState: TypeaheadState<T>, searchText: string);
  getChoiceLabel(choice: string): string;
  parseSearchQuery(text: string);
}

@Component({
  selector: 'ice-query-editor',
  templateUrl: './query-editor.component.html',
  styleUrls: ['./query-editor.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => QueryEditorComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class QueryEditorComponent<T> implements ControlValueAccessor, OnInit, OnChanges {
  searchRegexp = /^\w*$/;
  parsed: ParsedInput<T>;

  @Input() queryLanguageParser: QueryLanguageParser<T>;
  @Input() prevVal: string;

  @Input() addTags: (inputString: string) => Array<HighlightTag>;
  /**
   * Called when the area over a tag is moused over
   */
  @Output() tagMouseEnter = new EventEmitter<TagMouseEvent>();

  /**
   * Called when the area over the tag has the mouse is removed from it
   */
  @Output() tagMouseLeave = new EventEmitter<TagMouseEvent>();

  @Output() ngModelChange = new EventEmitter<any>();

  @ViewChild('queryInput', { read: ElementRef, static: true }) queryInputEl: ElementRef;
  @ViewChild('queryInput', { static: true }) queryInput;
  @ViewChild(TypeaheadDirective, { static: true }) typeaheadDirective;

  @HostListener('blur') onTouched() {}

  handleInput(value: any, selectionStart: number): void {
    this.parsed = this.queryLanguageParser.tokenize('', value, selectionStart);
    if (value === this.prevVal) {
      const model = this.injector.get<NgControl>(NgControl as any); // get NgModel
      model.control.setValue(model.value); // set previous value
      return;
    }
    this.onChange(value);
  }

  onChange = (_: any) => {};

  ngOnChanges() {
    this.hideMenu();
  }

  writeValue(value: any): void {
    const normalizedValue = value == null ? '' : value;
    this.renderer2.setProperty(this.queryInputEl.nativeElement, 'value', normalizedValue);
    this.parsed = this.queryLanguageParser.tokenize('', value, this.queryInputEl.nativeElement);
    this.changeDetectorRef.detectChanges();
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer2.setProperty(this.queryInputEl.nativeElement, 'disabled', isDisabled);
    this.changeDetectorRef.detectChanges();
  }

  private hideMenu() {
    if (this.typeaheadDirective.menu) {
      this.typeaheadDirective.hideMenu();
    }
  }

  tagClick(tag: HighlightTag) {
    this.typeaheadDirective.typeaheadState = this.queryLanguageParser.calculateTypeaheadState(this.parsed, tag.indices.start);
    this.queryInputEl.nativeElement.selectionStart = tag.indices.start;
    this.queryInputEl.nativeElement.selectionEnd = tag.indices.end;
    this.hideMenu();
    this.typeaheadDirective.showMenu();
  }

  ngOnInit() {
    this.renderer2.listen(this.queryInputEl.nativeElement, 'input', evt => {
      if (this.prevVal !== evt.target.value) {
        this.handleInput(evt.target.value, evt.target.selectionStart);
        this.prevVal = evt.target.value;
      }
    });
    this.renderer2.listen(this.queryInputEl.nativeElement, 'click', evt => {
      this.typeaheadDirective.hideMenu();
      if (this.prevVal !== evt.target.value) {
        this.handleInput(evt.target.value, evt.target.selectionStart);
        this.prevVal = evt.target.value;
      }
    });
  }

  constructor(private renderer2: Renderer2, private injector: Injector, public changeDetectorRef: ChangeDetectorRef) {}
}
