<div fxFlex fxLayout="row" class="wrapper-input-text" (click)="setInputSelect()">
  <div fxFlex="90">
    <ng-container #fieldComponent></ng-container>
  </div>
  <div class="btn-input-clear" fxFlex="10" fxFlexAlign=" center">
    <button matSuffix mat-icon-button aria-label="Clear" (click)="clear($event)" *ngIf="input?.value && isValidInput()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
