import { OptionsGroup } from 'models/options-group';

export const COMPARE_CASES_DEFAULT_FILTER_TERRITORY = 'ALL';

export const CASE_STATUS_OPEN = 'OPEN';
export const CONFLICT_SUBTYPE_POSS_OVERLAP = 'POSS_OVERLAP_DATA';
export const NO_CASE = 'NO_CASE';
export const CASE_STATUS_CONFLICT = 'OPEN';
export const CASE_STATUS_DISPUTE = 'CONFIRMED';
export const CASE_STATUS_RESOLVED = 'RESOLVED';

export const AGREEMENT_CONFLICT_STATUS = {
  [NO_CASE]: 'AGREEMENT-CONFLICT.STATUS_OVERLAP',
  [CASE_STATUS_CONFLICT]: 'AGREEMENT-CONFLICT.STATUS_CONFLICT',
  [CASE_STATUS_DISPUTE]: 'AGREEMENT-CONFLICT.STATUS_DISPUTE',
  [CASE_STATUS_RESOLVED]: 'AGREEMENT-CONFLICT.STATUS_RESOLVED',
};

export const GOTOWORK_CONFLICTSUBTYPES = ['UNIDENTIFIED_IP_DATA', 'OVERSHARE_DATA', 'UNDERSHARE_DATA', 'OVERCLAIM'];

export const EXPANSION_LIST_PAIRS_HAS_SEPARATORS = [
  ['analysis-right', 'analysis-left'],
  ['extended-main', 'extended-compare'],
];

export const EXPANSION_LIST_PAIRS_NOT_SEPARATORS = [['instrumentations-main', 'instrumentations-compare']];
export const EXPANSION_LIST_PAIRS = [...EXPANSION_LIST_PAIRS_HAS_SEPARATORS, ...EXPANSION_LIST_PAIRS_NOT_SEPARATORS];
export const EXPANSION_LIST_PAIRS_HEADERS = [
  { pairs: EXPANSION_LIST_PAIRS_HAS_SEPARATORS, useSeparator: true },
  { pairs: EXPANSION_LIST_PAIRS_NOT_SEPARATORS, useSeparator: false },
];

export enum ActionResponse {
  SUPPORTED = 'SUPPORTED',
  WITHDRAWN = 'WITHDRAWN',
  REDUCED = 'REDUCED',
  REDUCED_AND_SUPPORTED = 'REDUCED_AND_SUPPORTED',
  SUPPORTED_AND_DOCUMENT_REJECTED = 'SUPPORTED_AND_DOCUMENT_REJECTED',
  CLAIMS_REDUCED = 'CLAIMS_REDUCED',
  WITHDRAW_CLAIM = 'WITHDRAW_CLAIM',
  CLAIMS_WITHDRAWN = 'CLAIMS_WITHDRAWN',
  CLAIMS_REMOVED = 'CLAIMS_REMOVED',
  NOT_SUPPORTED = 'NOT_SUPPORTED',
  REQUESTED_TO_SUPPORT_CLAIM = 'REQUESTED_TO_SUPPORT_CLAIM',
  COUNTERCLAIM_RESOLVED = 'COUNTERCLAIM_RESOLVED',
  AWAITING_REPLY = 'AWAITING_REPLY',
  EARLY_WARNING = 'EARLY_WARNING',
  CLAIM_NOT_SUPPORTED = 'CLAIM_NOT_SUPPORTED',
  CLAIMS_SUPPORTED_AND_REDUCED = 'CLAIMS_SUPPORTED_AND_REDUCED',
  CLAIMS_SUPPORTED_AND_REJECTED = 'CLAIMS_SUPPORTED_AND_REJECTED',
}

export enum ActionStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  PAUSED = 'PAUSED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
}

export enum ActionType {
  MEMBERS_TO_RESOLVE = 'MEMBERS_TO_RESOLVE',
  REQUEST_TO_SUPPORT_CLAIM = 'REQUEST_TO_SUPPORT_CLAIM',
  REQUEST_TO_SUPPORT_AGREEMENT = 'REQUEST_TO_SUPPORT_AGREEMENT',
  COUNTERCLAIM_RESOLVED = 'COUNTERCLAIM_RESOLVED',
  EARLY_WARNING = 'EARLY_WARNING',
  WITHDRAW_CLAIM = 'WITHDRAW_CLAIM',
  REDUCE_CLAIM = 'REDUCE_CLAIM',
  CLAIM_NOT_SUPPORTED = 'CLAIM_NOT_SUPPORTED',
  NOTIFICATION = 'NOTIFICATION',
}

export enum CounterClaimType {
  AUTHOR_SHARE_CLAIM = 'AUTHOR_SHARE_CLAIM',
  AUTHORSHIP_CLAIM = 'AUTHORSHIP_CLAIM',
  OWNERSHIP_CLAIM = 'OWNERSHIP_CLAIM',
  ORIGINAL_CLAIM = 'ORIGINAL_CLAIM',
  SAMPLE_CLAIM = 'SAMPLE_CLAIM',
  INFRINGEMENT_CLAIM = 'INFRINGEMENT_CLAIM',
  NO_AGREEMENT_CLAIM = 'NO_AGREEMENT_CLAIM',
  BREACH_OF_CONTRACT_CLAIM = 'BREACH_OF_CONTRACT_CLAIM',
  AGREEMENT_OVERLAP = 'AGREEMENT_OVERLAP',
  AGREEMENT_MANUAL_CONFLICT = 'AGREEMENT_MANUAL_CONFLICT',
  OWNERSHIP_CONFIRMED = 'OWNERSHIP_CONFIRMED',
  OWNERSHIP_NOT_CONFIRMED = 'OWNERSHIP_NOT_CONFIRMED',
}

export enum CounterClaimResolutionOwnerType {
  PRS = '052', // 'PRS',
  STIM = '079', // 'STIM',
  BUMA = '023', // 'BUMA',
  SABAM = '055', // 'SABAM',
  KODA = '040', // 'KODA',
  TEOSTO = '089', // 'TEOSTO',
  TONO = '090', // 'TONO'
}

export const PROCESS_DESCRIPTION = [
  '',
  'ADISTRIBUTION',
  'AMAZONITUNE',
  'APGENERAL',
  'APLICENSING',
  'APUNIVERSALEMI',
  'AP2',
  'AUDIO',
  'AUDIOVISUAL',
  'BLANKET',
  'CONCERT',
  'COPCON',
  'FOREIGN',
  'FOREIGNCONCERT',
  'IPC',
  'LIVE',
  'TRANSACTIONAL',
  'APSUPP',
  'FOREIGNCUE',
  'SPOTIFY',
  'YOUTUBE',
];

export const PROCESS_DESCRIPTION_OPTIONS = PROCESS_DESCRIPTION.map(option => ({ label: option, value: option }));

export type CounterClaimTypes =
  | 'AUTHOR_SHARE_CLAIM'
  | 'AUTHORSHIP_CLAIM'
  | 'OWNERSHIP_CLAIM'
  | 'ORIGINAL_CLAIM'
  | 'SAMPLE_CLAIM'
  | 'INFRINGEMENT_CLAIM'
  | 'NO_AGREEMENT_CLAIM'
  | 'BREACH_OF_CONTRACT_CLAIM'
  | 'AGREEMENT_OVERLAP'
  | 'AGREEMENT_MANUAL_CONFLICT'
  | 'OWNERSHIP_CONFIRMED'
  | 'OWNERSHIP_NOT_CONFIRMED';

export const PublisherActionTypes = [ActionType.MEMBERS_TO_RESOLVE, ActionType.REQUEST_TO_SUPPORT_CLAIM, ActionType.EARLY_WARNING];

export const ReducedActionResponses = [ActionResponse.REDUCED, ActionResponse.REDUCED_AND_SUPPORTED];

export const WithDrawActionTypes = [ActionType.WITHDRAW_CLAIM];

export const XREF_GROUP_EXCEPTIONS = ['ISWC', 'ISWCA'];

export const XREF_GROUP_SORT = ['ISWC', 'PWREF', 'ALLIB'];

export const TITLES_GROUP_SORT = ['OT'];

export enum ActivityType {
  MERGE = 'MERGE',
  MATCH = 'MTCH',
}

export const UnassignedUserValue = 'unassigned';

export const ALL_CONFLICT_AREAS = 'ALL_CONFLICT_AREAS';
export const conflictAreas: OptionsGroup[] = [
  {
    header: ALL_CONFLICT_AREAS,
    options: [
      {
        value: '',
        label: 'STN',
      },
      {
        value: 'CUE',
        label: 'CUE',
      },
      {
        value: 'LIB',
        label: 'LIB',
      },
      {
        value: 'SER',
        label: 'SER',
      },
    ],
  },
];

export const domestic = 'DOMESTIC';
export const nordicDomesticHeader = `NORDIC_${domestic}`;
export const nordicDomestiCodes = ['040', '079', '089', '090', '048'];
export const bumaDomesticHeader = `BUMA_${domestic}`;
export const bumaDomesticCodes = ['023', '078'];
export const standarDomesticHeader = `STANDAR_${domestic}`;
export const standarDomesticCodes = ['044', '052', '055'];
export const nonSociety = 'NS';

export const allConflictTypes = 'ALL_CONFLICT_TYPES';
export const conflictTypes = ['DATA', ActivityType.MATCH, ActivityType.MERGE];
export const allConflictSubTypes = 'ALL_CONFLICT_SUB_TYPES';
export const conflictSubTypes = [
  'BLOCKED',
  'CREATOR_NO_MERGE',
  'INSTR_DATA',
  'MISSING_CREATOR_LINK_DATA',
  'OVERCLAIM',
  'POSS_MATCH',
  'PUB_NO_MERGE',
  'TOP_DATA',
  'UNDERSHARE_DATA',
  'UNIDENTIFIED_IP_DATA',
];

export enum ConflictSubType {
  BLOCKED = 'BLOCKED',
  CREATOR_NO_MERGE = 'CREATOR_NO_MERGE',
  INSTR_DATA = 'INSTR_DATA',
  MISSING_CREATOR_LINK_DATA = 'MISSING_CREATOR_LINK_DATA',
  OVERCLAIM = 'OVERCLAIM',
  POSS_MATCH = 'POSS_MATCH',
  PUB_NO_MERGE = 'PUB_NO_MERGE',
  TOP_DATA = 'TOP_DATA',
  UNDERSHARE_DATA = 'UNDERSHARE_DATA',
  UNIDENTIFIED_IP_DATA = 'UNIDENTIFIED_IP_DATA',
}

export const HAS_PRIORITY_KEY = 'hasPriority';
export const OVERCLAIM_WITH_COUNTERCLAIM_FLAG_KEY = 'overclaimWithCounterclaimFlag';
export const ICE_ONLINE_SERVICES_VALUE = '800O';
export const POSS_MATCH_CONFLICT_SUBTYPE = 'POSS_MATCH';
export const PUB_NO_MERGE_CONFLICT_SUBTYPE = 'PUB_NO_MERGE';
