import { DatatableUtils } from '@ice';
import { DataTableMessages } from '@ice/components/data-table/data-table';
import { TranslateService } from '@ngx-translate/core';

export abstract class RootDatatable {
  constructor(protected translate: TranslateService) {}

  getMessages(): DataTableMessages {
    return DatatableUtils.getDataTableDefaultMessages(this.translate);
  }

  protected generalComparator(a, b) {
    return a < b ? -1 : 1;
  }

  protected numericalComparator(a: string, b: string) {
    return parseFloat(a) < parseFloat(b) ? -1 : 1;
  }

  protected arrayComparator(a: Array<string>, b: Array<string>, baseComparator: (a: string, b: string) => number) {
    const aFiltered = (a || []).filter(element => !!element);
    const bFiltered = (b || []).filter(element => !!element);
    if (aFiltered.length === bFiltered.length) {
      return baseComparator(aFiltered[0], bFiltered[0]);
    } else {
      return aFiltered.length < bFiltered.length ? -1 : 1;
    }
  }

  protected serverSideSorting() {
    return 0; // return always the same to avoid client side comparission. This column is using serverside
  }
}
