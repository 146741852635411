<mat-card class="ice-p-0 ice-h-auto mat-card" fxFlex fxLayout="column" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{ scrollXMarginOffset: 10, scrollYMarginOffset: 10 }">
  <mat-card-header class="ice-light-grey-bg" fxFlex fxLayout="row">
    <mat-card-title *ngIf="config?.title" fxFlex fxLayout="row" fxLayoutAlign="start center">
      {{ config.title }}
    </mat-card-title>

    <div *ngIf="config?.optionsGroup | async; let optionsGroup" fxFlex fxLayout="row" fxLayoutAlign="end center" class="display-flex-row-reverse flex-1">
      <mat-radio-group (change)="optionsGroup.change($event)" fxLayoutGap="20px">
        <mat-radio-button
          class="option"
          *ngFor="let op of optionsGroup.options"
          [tabindex]="-1"
          [ngClass]="{ disabled: op.disabled || (config.loading | async) }"
          [checked]="op.checked"
          [value]="op.value"
        >
          {{ op.label }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </mat-card-header>

  <mat-card-content>
    <ng-content></ng-content>
  </mat-card-content>
</mat-card>
