import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { FuseSharedModule } from '@fuse/shared.module';
import { FormContainerModule } from '@ice/components/form-container/form-container.module';
import { IceMaterialModule } from '@ice/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { CardWithExpansionListComponent } from './card-with-expansion-list.component';

@NgModule({
  imports: [CommonModule, TranslateModule, IceMaterialModule, FlexLayoutModule, FormsModule, FuseSharedModule, FormContainerModule, ScrollingModule],
  declarations: [CardWithExpansionListComponent],
  exports: [CardWithExpansionListComponent],
})
export class CardWithExpansionListModule {}
