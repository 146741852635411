import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { END_DATE_NO_DISPLAY } from 'config/constants/global.constants';
import { get, isArray, isString, keys } from 'lodash';
import { DatepickerParams } from 'models/copyright/formly/datepicker-params';
import moment from 'moment';
import { FormlyValidatorUtils } from '../formly/formly-validators.utils';

export class DatepickerUtils {
  static getDatepickerField({
    name,
    label,
    controlFieldToAssign,
    datepickerOptions,
    extraValidators,
    required = false,
    translate,
    extraClass,
    expressionProperties,
    defaultValue = '',
    infoText,
    hooks,
    hideExpression,
    asyncValidators,
    dateChange,
    extraTemplateOptions,
  }: DatepickerParams): FormlyFieldConfig {
    const validators = {
      required: {
        expression: (control: FormControl) => (!required ? true : !!control.value),
        message: translate.instant('REQUIRED'),
      },
      dateExist: {
        expression: (control: FormControl) => !control.errors?.invalidDate,
        message: translate.instant('DATE_DOES_NOT_EXIST'),
      },
      incorrect: {
        expression: (control: FormControl) => !control.errors?.incorrect,
        message: translate.instant('DATE_DOES_NOT_EXIST'),
      },
      ...extraValidators,
    };
    return {
      className: `flex-1 ${infoText ? 'infoText' : 'datepicker-type-field'}  ${extraClass}`,
      fieldGroup: [
        {
          key: name,
          type: 'datepicker',
          defaultValue,
          modelOptions: {
            updateOn: 'blur',
          },
          templateOptions: {
            datepickerOptions,
            label,
            type: 'text',
            datePickerRequired: required,
            infoText,
            required,
            dateChange,
            ...extraTemplateOptions,
          },
          hooks: {
            onInit: hooks?.onInit
              ? hooks?.onInit
              : (control: FormControl) => {
                  controlFieldToAssign = control;
                },
            onDestroy: hooks?.onDestroy ? hooks?.onDestroy : null,
          },
          validators,
          expressionProperties,
          hideExpression,
          asyncValidators,
        },
      ],
    };
  }

  static changeDateValidationInProcessValue(currentValue: boolean, otherDateControl: FormControl): boolean {
    if (currentValue) {
      return false;
    } else {
      if (otherDateControl.value) {
        return true;
      } else {
        return false;
      }
    }
  }

  static validateOtherDate(otherDateControl: FormControl, dateValidationInProcessValue: boolean) {
    if (dateValidationInProcessValue) {
      otherDateControl.markAsTouched();
    } else {
      if (otherDateControl.value) {
        otherDateControl.markAsTouched();
        if (otherDateControl.value?._i !== END_DATE_NO_DISPLAY) {
          otherDateControl.setValue(otherDateControl.value);
        }
      }
    }
  }

  static isOtherDateValidated(otherDateControl: FormControl) {
    return !otherDateControl.value && keys(otherDateControl.errors).filter(error => error !== 'required').length === 0;
  }

  static getDatepickerSimpleField({
    name,
    label,
    controlFieldToAssign,
    datepickerOptions,
    extraValidators,
    required = false,
    translate,
    extraClass,
    expressionProperties,
    defaultValue = '',
    infoText,
    hooks,
    hideExpression,
    asyncValidators,
    dateChange,
    extraTemplateOptions,
  }: DatepickerParams): FormlyFieldConfig {
    return {
      ...get(
        this.getDatepickerField({
          name,
          label,
          controlFieldToAssign,
          datepickerOptions,
          extraValidators,
          required,
          translate,
          extraClass,
          expressionProperties,
          defaultValue,
          infoText,
          hooks,
          hideExpression,
          asyncValidators,
          dateChange,
          extraTemplateOptions,
        }),
        'fieldGroup[0]',
      ),
      className: extraClass,
    };
  }

  static createDatepickerRange({
    translate,
    sinceFieldConfig,
    toFieldConfig,
  }: {
    translate: TranslateService;
    sinceFieldConfig: FormlyFieldConfig;
    toFieldConfig: FormlyFieldConfig;
  }): void {
    sinceFieldConfig = get(sinceFieldConfig, 'fieldGroup[0]', sinceFieldConfig);
    toFieldConfig = get(toFieldConfig, 'fieldGroup[0]', toFieldConfig);
    let sinceDateRef: Date;
    let toDateRef: Date;
    const sinceDateModelKey: string = this.getModelKey(sinceFieldConfig);
    const toDateModelKey: string = this.getModelKey(toFieldConfig);
    const baseKey = 'templateOptions.datepickerOptions.';
    const sinceKey = `${baseKey}${sinceDateModelKey}`;
    const toKey = `${baseKey}${toDateModelKey}`;
    const expressionMethod = model => ((toDateRef = model?.[toDateModelKey]), (sinceDateRef = model?.[sinceDateModelKey]));
    const sinceExpresion = {};
    sinceExpresion[sinceKey] = expressionMethod;
    const toExpresion = {};
    toExpresion[toKey] = expressionMethod;

    sinceFieldConfig.expressionProperties = {
      ...(sinceFieldConfig.expressionProperties || {}),
      ...sinceExpresion,
    };
    toFieldConfig.expressionProperties = {
      ...(toFieldConfig.expressionProperties || {}),
      ...toExpresion,
    };
    sinceFieldConfig.templateOptions.datepickerOptions = {
      ...(sinceFieldConfig.templateOptions.datepickerOptions || {}),
      filter: (date: Date, formGroup: FormGroup) => {
        const value = formGroup.get(toDateModelKey)?.value;
        return !value ? true : moment(date).isSameOrBefore(value);
      },
    };

    toFieldConfig.templateOptions.datepickerOptions = {
      ...(toFieldConfig.templateOptions.datepickerOptions || {}),
      filter: (date: Date, formGroup: FormGroup) => {
        const value = formGroup.get(sinceDateModelKey)?.value;
        return !value ? true : moment(date).isSameOrAfter(value);
      },
    };
    this.addValidators(translate, sinceFieldConfig, sinceDateModelKey, toFieldConfig, toDateModelKey);
  }

  private static getModelKey(fieldConfig: FormlyFieldConfig): string {
    return isArray(fieldConfig.key) ? fieldConfig.key[0] : isString(fieldConfig.key) ? fieldConfig.key : '';
  }

  static addValidators(translate: TranslateService, sinceFieldConfig: FormlyFieldConfig, sinceDateModelKey: string, toFieldConfig: FormlyFieldConfig, toDateModelKey: string) {
    sinceFieldConfig.validators = {
      ...sinceFieldConfig.validators,
      ...this.getDatepickerRangeSinceDateShouldBeBeforeToDateValidators({ translate, toDateModelKey, toDateLabel: toFieldConfig.templateOptions.label }),
    };
    toFieldConfig.validators = {
      ...toFieldConfig.validators,
      ...this.getDatepickerRangeToDateShouldBeAfterSinceDateoValidators({ translate, sinceDateModelKey, sinceDateLabel: sinceFieldConfig.templateOptions.label }),
    };

    this.linkValidatiors(sinceFieldConfig, sinceDateModelKey, toFieldConfig, toDateModelKey);
  }

  static getDatepickerRangeSinceDateShouldBeBeforeToDateValidators({
    translate,
    toDateModelKey,
    toDateLabel,
  }: {
    translate: TranslateService;
    toDateModelKey: string;
    toDateLabel: string;
  }): any {
    return {
      dateFromShouldBeAfterDateTo: FormlyValidatorUtils.getDatepickerRangeSinceDateShouldBeBeforeToDateValidators({ translate, toDateModelKey, toDateLabel }),
    };
  }

  static getDatepickerRangeToDateShouldBeAfterSinceDateoValidators({
    translate,
    sinceDateModelKey,
    sinceDateLabel,
  }: {
    translate: TranslateService;
    sinceDateModelKey: string;
    sinceDateLabel: string;
  }): any {
    return {
      dateFromShouldBeAfterDateTo: FormlyValidatorUtils.getDatepickerRangeToDateShouldBeAfterSinceDateoValidators({ translate, sinceDateModelKey, sinceDateLabel }),
    };
  }

  static linkValidatiors(sinceFieldConfig: FormlyFieldConfig, sinceDateModelKey: string, toFieldConfig: FormlyFieldConfig, toDateModelKey: string) {
    sinceFieldConfig.templateOptions.blur = (field: FormlyFieldConfig) => this.updateValueAndValidity(field.form?.get(toDateModelKey));
    toFieldConfig.templateOptions.blur = (field: FormlyFieldConfig) => this.updateValueAndValidity(field.form?.get(sinceDateModelKey));
  }

  static updateValueAndValidity(control: AbstractControl) {
    setTimeout(() => {
      control?.updateValueAndValidity();
    }, 50);
  }
}
