export type Severity = 'error' | 'warning' | 'info' | 'success';
export enum IconBySeverity {
  error = 'error',
  warning = 'warning',
  info = 'info',
  success = 'check_circle',
}
export interface IceErrorPayload {
  message?: string;
  severity?: Severity;
}
