import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IceMaterialModule } from '@ice/material.module';
import { HtmlTemplateComponent } from './html-template.component';

@NgModule({
  declarations: [HtmlTemplateComponent],
  imports: [CommonModule, IceMaterialModule, FlexLayoutModule],
  exports: [HtmlTemplateComponent],
})
export class HtmlTemplateModule {}
