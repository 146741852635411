import * as fromApiCalls from 'config/api-calls';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { SortInfo } from '@ice/components/data-table/data-table';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/home-builders';
import { ConflictsActionsDataTable } from 'config/data-table-builders/conflicts.actions';
import { SectionHomeConfig } from 'config/sections-config';
import { escape, find } from 'lodash';
import { of } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import * as fromRoot from 'store/root';
import { CopyrightBulkUpdatesDataTable } from 'config/data-table-builders/copyright.bulk-updates';
import { SearchCopyrightBulkUpdatesForm } from 'config/search-form-builders/search-copyright-bulk-updates';
import { UsersUtils } from '@ice/utils/users/users.utils';
import { BulkUpdateResponseType, BulkUpdateResultStatus, JobUpdateQueryParams } from 'models/copyright/bulk-updates/jobs.model';
import { PermissionsService } from 'services/permissions/permissions.service';
import * as fromNewSectionItem from 'store/new-section-item';

export class DashboardBulkUpdates implements SectionHomeConfig {
  private schema: CopyrightBulkUpdatesDataTable;
  accessPartyNames: string[];
  searchForm: SearchCopyrightBulkUpdatesForm;
  casesActionsDataTable: ConflictsActionsDataTable;

  constructor(
    private translate: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private storeNewItem: Store<fromNewSectionItem.NewSectionItemState>,
    private permissionsService: PermissionsService,
  ) {
    this.translationLoader.loadTranslations(english);
    this.schema = new CopyrightBulkUpdatesDataTable(this.translate, this.translationLoader, this.store);
    this.searchForm = new SearchCopyrightBulkUpdatesForm(this.translate, this.translationLoader, this.store);
    this.casesActionsDataTable = new ConflictsActionsDataTable(this.translate, this.translationLoader, this.store, storeNewItem, permissionsService);
  }

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('BULK_UPDATES.LIST.TITLE'),
              class: 'ice-limit-form',
              model: this.store
                .select(state => state.copyright['bulk-updates']?.jobs)
                .pipe(
                  withLatestFrom(this.store.select(fromRoot.getAllUsers)),
                  map(([items, users]) => {
                    return items?.map(item => {
                      const userID = UsersUtils.convertUsernameToUserID(item.createdBy);
                      const user = find(users as { id: string; attributes: any }[], { id: userID });
                      const userFullName = UsersUtils.composeFullName(user);
                      return {
                        ...item,
                        comments: escape(item.comment),
                        notes: escape(item.note),
                        createdBy: userFullName,
                        copyId: this.translate.instant('ACTIONS.COPY_TO_CLIPBOARD', { message: `"${item.bulkUpdateId}"` }),
                      };
                    });
                  }),
                ),
              sorts: this.schema.getDefaultSorting(),
              schema: of(this.schema.getDataTable()),
              loadingIndicator: false,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: true,
              columnMode: this.schema.getColumnMode(),
              tableWidth: '100',
              toggleButtons: of(),
              filter: {
                formBuilder: this.searchForm.getForm(),
                model: of({}),
                submitAvailable: true,
                resetAvailable: true,
                submitLabel: this.translate.instant('BULK_UPDATES.SUBMIT'),
                onSubmit: model => {
                  const queryParams: JobUpdateQueryParams = {};

                  if (model.userId) {
                    queryParams.userId = model.userId.value;
                  }
                  if (model.dateFrom) {
                    queryParams.startDate = model.dateFrom.toISOString();
                  }
                  if (model.dateTo) {
                    queryParams.endDate = model.dateTo.toISOString();
                  }
                  if (model.type !== BulkUpdateResponseType.ALL) {
                    queryParams.bulkUpdateType = model.type;
                  }
                  if (model.status !== BulkUpdateResultStatus.ALL) {
                    queryParams.status = model.status;
                  }
                  this.store.dispatch(new fromRoot.StartApiCall({ apiCall: { ...fromApiCalls.getBulkUpdateJobs, queryParams } }));
                },
              },
              submitShortcutEnable: true,
            },
          },
        ],
      },
    ];
  }

  formatSort(sort: SortInfo) {
    return sort;
  }
}
