import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FuseSharedModule } from '@fuse/shared.module';
import { AutocompleteTypeComponent } from '@ice';
import { IceMaterialModule } from '@ice/material.module';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [AutocompleteTypeComponent],
  imports: [TranslateModule, FuseSharedModule, IceMaterialModule, FormlyMaterialModule, MatAutocompleteModule, FormlyModule.forRoot()],
  exports: [AutocompleteTypeComponent],
})
export class AutocompleteTypeModule {}
