import { Component, OnDestroy, OnInit } from '@angular/core';
import { TreeNodeData } from '@ice/components/work-tree-chart/tree-chart-models';
import { TreeChartUtils } from '@ice/utils/tree-chart/tree-chart.utils';
import { FieldType } from '@ngx-formly/core';
import { WorkClaimsInitModel } from 'config/stepper-builders/works/new-work-init';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ice-formly-field-tree-chart',
  templateUrl: './tree-chart-type.component.html',
})
export class TreeChartTypeComponent extends FieldType implements OnInit, OnDestroy {
  valueSubscription: Subscription;
  treeData = WorkClaimsInitModel;
  levels = { maxHLevel: 7, vLevel: 2 };

  ngOnInit() {
    if (this.formControl) {
      this.updateValue(this.formControl.value);
      this.valueSubscription = this.formControl.valueChanges.subscribe((value: TreeNodeData[]) => this.updateValue(value));
    }
    if (this.to.customInitialRootNode) {
      this.treeData = [this.to.customInitialRootNode];
      this.updateValue(this.treeData);
    }
  }

  ngOnDestroy() {
    if (this.valueSubscription) {
      this.valueSubscription.unsubscribe();
    }
  }

  updateValue(value) {
    if (value) {
      this.treeData = value;
      this.levels = TreeChartUtils.calculateLevels(value);
    }
  }
}
