import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { RepertoireUtils } from '@ice';
import { DataTableRow } from '@ice/components/data-table/data-table';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import { ipsSocietiesCodes } from 'assets/ts/ips-societies';
import { DialogAddSociety } from 'config/dialog-builders/dialog-add-society';
import { DialogInfo } from 'config/dialog-builders/dialog-info';
import { SectionsConfig } from 'config/sections-config';
import { isEmpty } from 'lodash';
import { of, Subject } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { PermissionsService } from 'services/permissions/permissions.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';
import { SectionTabConfig } from '../../tabs-data';
import { RepertoireEditInline } from './shared/edit';

export class TabRepertoireCreatorAffiliations implements SectionTabConfig {
  private dialogAddSociety: DialogAddSociety;
  private currentSocieties: { societyCode: string; rightType: string }[] = [];
  private unsubscribeAll = new Subject();
  private dialogRef: MatDialogRef<any, any>;
  private repertoireRequiredFields: any;

  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private dialog: MatDialog,
    private commonApiService: null,
    private detailService: null,
    private nsService: null,
    private iceFacade: null,
    private fieldValidatorService: FieldValidatorService,
    protected permissionsService: PermissionsService,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.dialogAddSociety = new DialogAddSociety(this.translate, this.dialog, this.store);
    this.store
      .select(fromRoot.getRepertoireDetail)
      .pipe(
        filter(detail => !isEmpty(detail)),
        take(1),
      )
      .subscribe((detail: any) => {
        const { id, version, attributes } = detail;
        this.repertoireRequiredFields = { id, version, attributes };
      });
  }

  onDestroy = () => {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  };

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('REPERTOIRES.CREATOR_AFFILIATIONS.TAB_TITLE'),
              model: this.store.pipe(
                select(fromRoot.getRepertoireCreatorAffiliations),
                map(affiliations => RepertoireUtils.formatCreatorAffiliations(affiliations, this.translate)),
                tap(
                  affiliations =>
                    (this.currentSocieties = (affiliations || []).map(affiliation => {
                      return {
                        societyCode: affiliation.societyCode,
                        rightType: affiliation.rightType,
                      };
                    })),
                ),
              ),
              sorts: [],
              schema: this.getSchema(),
              loadingIndicator: false,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: true,
              shadowed: false,
              columnMode: 'flex',
              onSelect: event => {
                this.store.dispatch(
                  new fromRoot.Go({
                    path: [`copyright/${SectionsConfig.SOCIETIES.name}/${event[0].societyId}`],
                  }),
                );
              },
              onMouseSelect: event => {
                this.store.dispatch(
                  new fromRoot.OpenNewTab({
                    path: [`copyright/${SectionsConfig.SOCIETIES.name}/${event.societyId}`],
                  }),
                );
              },
              getRowClass: (row: any): any => ({ 'ice-search-results-table-row': true }),
              actionButtons: of(
                this.permissionsService.can(`repertoires_edit`)
                  ? [
                      {
                        icon: 'add',
                        tooltip: `${this.translate.instant('REPERTOIRES.COMMON.ADD')} ${this.translate.instant('REPERTOIRES.CREATOR_AFFILIATIONS.TAB_TITLE')}`,
                        class: 'mat-white-icon',
                        onClick: () => {
                          this.dialogRef = this.dialogAddSociety.openDialog(
                            society => {
                              const existingRightType = this.currentSocieties.find(s => s.societyCode === society.societyCode && s.rightType !== society.rights)?.rightType;
                              const body = JSON.stringify(
                                RepertoireUtils.getRepertoireSocietyUpdateBody(this.repertoireRequiredFields, {
                                  ...society,
                                  rights: [existingRightType, society.rights],
                                }),
                              );
                              RepertoireEditInline.updateReportoireWithMode(this.store, this.dialogRef, this.translate, this.repertoireRequiredFields.id, body, 'MERGE');
                            },
                            ipsSocietiesCodes.map(society => ({ label: society.name, value: society.code })),
                          );
                        },
                      },
                    ]
                  : [],
              ),
            },
          },
        ],
      },
    ];
  }

  private getSchema(): DataTableRow[] {
    return [
      { name: this.getTranslate('SOCIETY_CODE'), prop: 'societyCode', flexGrow: 1, cellClass: 'society-code' },
      { name: this.getTranslate('SOCIETY_NAME'), prop: 'societyName', flexGrow: 3 },
      { name: this.getTranslate('RIGHT_TYPE'), prop: 'rightType', flexGrow: 3, tooltip: 'rightTypeTooltip' },
      {
        name: '',
        prop: 'delete',
        actionButtonIcon: 'delete',
        flexGrow: 0.001,
        maxWidth: 50,
        minWidth: 50,
        resizeable: false,
        hideActionButton: row => of(!this.permissionsService.can(`repertoires_edit`)),
        action: row => {
          const dialogInfo = new DialogInfo(this.translate, this.dialog, this.store);
          this.dialogRef = dialogInfo.openDialog(
            `${this.translate.instant('REPERTOIRES.COMMON.DELETE')} ${this.translate.instant('REPERTOIRES.CREATOR_AFFILIATIONS.TAB_TITLE')}`,
            `${this.translate.instant('REPERTOIRES.CREATOR_AFFILIATIONS.DELETE_MESSAGE')}`,
            () => {
              {
                const societyWithRemainingRights = this.currentSocieties.find(s => s.societyCode === row.societyCode && s.rightType !== row.rightType);
                if (societyWithRemainingRights) {
                  const body = JSON.stringify(
                    RepertoireUtils.getRepertoireSocietyUpdateBody(this.repertoireRequiredFields, {
                      ...societyWithRemainingRights,
                      rights: [societyWithRemainingRights.rightType],
                    }),
                  );
                  RepertoireEditInline.updateReportoireWithMode(
                    this.store,
                    this.dialogRef,
                    this.translate,
                    this.repertoireRequiredFields.id,
                    body,
                    'MERGE',
                    undefined,
                    undefined,
                    true,
                  );
                } else {
                  const body = JSON.stringify(RepertoireUtils.getRepertoireSocietyUpdateBody(this.repertoireRequiredFields, row));
                  RepertoireEditInline.updateReportoireWithMode(this.store, this.dialogRef, this.translate, this.repertoireRequiredFields.id, body, 'DELETE');
                }
              }
            },
            () => this.dialogRef.close(),
          );
        },
        actionButtonTooltip: `${this.translate.instant('REPERTOIRES.COMMON.DELETE')} ${this.translate.instant('REPERTOIRES.CREATOR_AFFILIATIONS.TAB_TITLE')}`,
      },
    ];
  }

  private getTranslate(fieldTranslatePath) {
    return this.translate.instant(`REPERTOIRES.CREATOR_AFFILIATIONS.${fieldTranslatePath}`);
  }
}
