import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { IpCleaned } from '@ice';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { IceLayout } from '@ice/dynamic-components/group-component/group-component';
import { select } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CopyrightAgreementGroupDataTable } from 'config/data-table-builders/copyright.agreement-group';
import { CopyrightIpsDataTable } from 'config/data-table-builders/copyright.ips';
import { SearchCopyrightIpsForm } from 'config/search-form-builders/search-copyright-ips';
import { SectionsConfig } from 'config/sections-config';
import { clone } from 'lodash';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromNewSectionItem from 'store/new-section-item';
import { IpSearchDialogsUtils } from './common/ip-search-dialogs-utils';

export class DialogAgreementGroupSearch {
  static dialogRef: MatDialogRef<DialogMultiLayoutComponent, any>;
  static ipSearchField: FormControl;

  static openDialog(type, dialog, storeNewItem, translate, translationLoader: FuseTranslationLoaderService, onSelectSearchItem, store) {
    this.dialogRef = dialog.open(DialogMultiLayoutComponent, {
      data: {
        className: 'dialog-wrapper-width-900',
        layouts: [
          this.getAgreementGroupLayout(type, storeNewItem, translate, translationLoader, onSelectSearchItem),
          this.getIPSearchLayout(`${type}_IPSearch`, storeNewItem, translate, translationLoader, store),
        ],
      },
    });
  }

  static getAgreementGroupLayout(type, storeNewItem, translate, translationLoader, onSelectSearchItem): IceLayout {
    const section = SectionsConfig.AGREEMENT_GROUP.name;
    const copyrightAgreementGroupDatatable = new CopyrightAgreementGroupDataTable(translate, translationLoader);

    return {
      title: of(null),
      actions: [],
      layout: [
        {
          group: [
            {
              type: 'formly',
              config: {
                formBuilder: of(this.getAgreementGroupSearchForm(translate)),
                model: storeNewItem.pipe(
                  select(fromNewSectionItem.getNewSectionSearch),
                  map(search => clone(search[type] || {})),
                ),
                submitAvailable: true,
                submitLabel: translate.instant('SEARCH_POPUP.BT_SEARCH'),
                submit: $event => storeNewItem.dispatch(new fromNewSectionItem.NewItemDoSearch({ search: { ...$event }, section, type, reset: true })),
              },
            },
          ],
        },
        {
          group: [
            {
              type: 'dataTable',
              config: {
                data: IpSearchDialogsUtils.getSearchResults(storeNewItem, type),
                schema: copyrightAgreementGroupDatatable.getDataTable(),
                sorts: copyrightAgreementGroupDatatable.getDefaultSorting(),
                showLoader: true,
                columnMode: copyrightAgreementGroupDatatable.getColumnMode(),
                messages: copyrightAgreementGroupDatatable.getMessages(),
                selectionType: 'multi',
                onSelect: (selected: any[]) => onSelectSearchItem(selected[0]),
              },
            },
          ],
        },
      ],
    };
  }

  static getAgreementGroupSearchForm(translate): FormlyFieldConfig[] {
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'description',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: translate.instant('AGREEMENT_GROUP.NAME'),
              required: false,
            },
          },
          {
            className: 'flex-1',
            fieldGroupClassName: 'display-flex',
            fieldGroup: [
              {
                className: 'flex-1',
                key: 'ipNameKey',
                wrappers: ['form-field', 'wrapper-input-text'],
                type: 'input',
                templateOptions: {
                  type: 'text',
                  placeholder: translate.instant('AGREEMENT_GROUP.IP_NAME_KEY'),
                  required: false,
                },
              },
              {
                key: 'ipNameBtSearch',
                className: 'bt-search-ip-in-search-field',
                type: 'label',
                templateOptions: {
                  materialType: 'mat-icon-button',
                  icons: {
                    icon: 'search',
                  },
                  tooltipText: translate.instant('AGREEMENT_GROUP.SEARCH_IP'),
                  onClick: (event, field) => {
                    this.ipSearchField = field.parent.get('ipNameKey');
                    this.goToLayoutAndResizeDatatable(1);
                  },
                },
              },
            ],
          },
        ],
      },
    ];
  }

  static goToLayoutAndResizeDatatable(layoutId: number) {
    this.dialogRef.componentInstance.setLayout(layoutId);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
  }

  static getIPSearchLayout(type, storeNewItem, translate, translationLoader, store): IceLayout {
    const copyrightIpsDatatable = new CopyrightIpsDataTable(translate, translationLoader, store);
    const searchIpsForm = new SearchCopyrightIpsForm(translate, translationLoader);
    const section = SectionsConfig.IPS.name;
    const defaultXrefPrefix = null;

    return {
      title: of(null),
      actions: [{ tooltip: translate.instant('POPUP_COMMON.BACK'), nextLayout: 0, icon: 'arrow_back' }],
      layout: [
        {
          group: [
            {
              type: 'formly',
              config: {
                formBuilder: of(searchIpsForm.getForm(type, defaultXrefPrefix)),
                model: IpSearchDialogsUtils.getSearchIpsFormModel(type, storeNewItem),
                submitAvailable: true,
                submitLabel: translate.instant('AGREEMENTS.AGREEMENT_PARTIES.BT_SEARCH'),
                submit: $event => storeNewItem.dispatch(new fromNewSectionItem.NewItemDoSearch({ search: $event, section, type, reset: true })),
              },
            },
          ],
        },
        {
          group: [
            {
              type: 'dataTable',
              config: {
                data: IpSearchDialogsUtils.getSearchResults(storeNewItem, type),
                schema: copyrightIpsDatatable.getDataTable(),
                sorts: copyrightIpsDatatable.getDefaultSorting(),
                showLoader: true,
                columnMode: copyrightIpsDatatable.getColumnMode(),
                messages: copyrightIpsDatatable.getMessages(),
                selectionType: 'multi',
                onSelect: (selected: any[]) => this.onSelectIp(selected[0]),
              },
            },
          ],
        },
      ],
    };
  }

  static onSelectIp(item: IpCleaned) {
    if (item) {
      this.ipSearchField.setValue(item.nameKey);
      this.ipSearchField.markAsTouched();
      this.goToLayoutAndResizeDatatable(0);
    }
  }
}
