import { FormControl } from '@angular/forms';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CurrentOrganization, selectControlValue, User } from '@ice';
import { select, Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/search-form-builders';
import { SEARCH_TYPE_ORGANIZATION } from 'config/constants/organizations.constants';
import { SectionsConfig } from 'config/sections-config';
import { UserRolesOrganizationStep } from 'config/stepper-builders/users/steps/user-roles-organization.step';
import { cloneDeep } from 'lodash';
import { of, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import type { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromNewSectionItem from 'store/new-section-item';
import * as fromRoot from 'store/root';
import { FormConfig, SearchExpressions } from './form-config';

export const DEFAULT_SOCIETY = 'ICE:ICE';

export class UsersSearchExpressions extends SearchExpressions {
  getSearchExpressionConfig(key: string, value: string, prefix: string, allUsers: any) {
    switch (key) {
      case 'id':
        return `{"equals":{"${'attributes.' + key}":"${value}"}}`;
      case 'organizationId':
        return '';
      case 'roles':
        return `{"wildcard":{"roles.name":"*${value}*"}}`;
      case 'showInactive':
        return !value || value === 'false' ? `{"not": {"equals": {"attributes.status": "INACTIVE"}}}` : null;
      default:
        return `{"wildcard":{"${'attributes.' + key}":"*${value}*"}}`;
    }
  }

  getExtraSearchExpression() {
    return '';
  }
}

export class SearchUserManagementUsersForm implements FormConfig {
  private defaultOrganizationSubscription: Subscription;
  private field: FormlyFieldConfig;
  private user: User;
  private defaultOrganizationId = '';

  constructor(
    private store: Store<fromRoot.RootState>,
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private fieldValidatorService: FieldValidatorService,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.store
      .pipe(
        select(fromRoot.getUserCurrentOrganization),
        filter(currentOrganization => !!currentOrganization),
        take(1),
      )
      .subscribe(currentOrganization => {
        if (currentOrganization?.id !== 'ICE:ICE') {
          this.defaultOrganizationId = currentOrganization?.id;
        }
      });
  }

  getForm(): FormlyFieldConfig[] {
    const userRolesOrganizationStep = new UserRolesOrganizationStep(this.translate, this.fuseTranslationLoader, this.store, this.fieldValidatorService);
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'id',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('USERS.ID'), required: false },
          },
          {
            className: 'flex-1',
            key: 'firstName',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('USERS.FIRST_NAME'), required: false },
          },
          {
            className: 'flex-1',
            key: 'lastName',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('USERS.LAST_NAME'), required: false },
          },
          {
            className: 'flex-1',
            key: 'showInactive',
            type: 'checkbox',
            defaultValue: false,
            templateOptions: {
              label: this.translate.instant('USERS.SHOW_INACTIVE'),
              required: false,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'email',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('USERS.EMAIL'), required: false },
          },
          {
            className: 'flex-1',
            key: 'roles',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: { type: 'text', placeholder: this.translate.instant('USERS.ROLE'), required: false },
          },
          {
            className: 'flex-1',
            key: 'organizationId',
            type: 'ice-autocomplete-grouped',
            modelOptions: {
              updateOn: 'submit',
            },
            templateOptions: {
              placeholder: this.translate.instant('USERS.ASSOCIATED_ORGANIZATION'),
              asyncOptions: userRolesOrganizationStep.getOrganizationsAutocomplete(this.translate.instant('USERS.ORGANIZATION_ID')),
              inputReadonly: this.store.pipe(
                select(fromRoot.getUserCurrentOrganization),
                map((currentOrganization: CurrentOrganization) => currentOrganization?.id !== 'ICE:ICE'),
              ),
              onSearchEvent: itemSearch => {
                if (itemSearch && itemSearch.length > 1) {
                  this.store.dispatch(
                    new fromNewSectionItem.NewItemDoSearch({
                      search: cloneDeep({ idOrganization: itemSearch }),
                      section: SectionsConfig.ORGANIZATIONS.name,
                      type: SEARCH_TYPE_ORGANIZATION,
                      reset: true,
                    }),
                  );
                }
              },
            },
            expressionProperties: {
              defaultValue: () => this.defaultOrganizationId,
            },
            asyncValidators: {
              organizationIdExists: {
                expression: (control: FormControl, field: FormlyFieldConfig) =>
                  control?.dirty ? this.fieldValidatorService.organizationIdExists(selectControlValue(control)) : of(true).toPromise(),
                message: this.translate.instant('USERS.ORGANIZATION_DOESNT_EXIST'),
              },
            },
            hooks: {
              onInit: field => {
                this.field = field;
              },
              onDestroy: () => {
                if (this.defaultOrganizationSubscription) {
                  this.defaultOrganizationSubscription.unsubscribe();
                }
              },
            },
          },
        ],
      },
    ];
  }
}
