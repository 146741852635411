import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import { CopyrightAgreementsWritersDataTable } from 'config/data-table-builders/copyright.agreements-writers';
import { SelectionDatatableBuilder } from 'config/data-table-builders/selection-datatable-builder';
import { SectionTabConfig } from 'config/tabs-data-builders/tabs-data';
import { NamespaceService } from 'services/namespace/namespace.service';
import { SearchService } from 'services/search/search.service';
import * as fromNewSectionItem from 'store/new-section-item';
import * as fromRoot from 'store/root';
import * as fromApiCalls from 'config/api-calls';

export class TabAgreementWriters implements SectionTabConfig {
  private schema: CopyrightAgreementsWritersDataTable;
  private selectionDatatable: SelectionDatatableBuilder;

  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private dialog: null,
    private commonApiService: null,
    private detailService: null,
    protected nsService: NamespaceService,
    private iceFacade: null,
    private fieldValidatorService: null,
    private permissionsService: null,
    private searchService: SearchService,
    private newStore: Store<fromNewSectionItem.NewSectionItemState>,
    tabName,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.schema = new CopyrightAgreementsWritersDataTable(this.translate, this.fuseTranslationLoader, store);
    this.selectionDatatable = new SelectionDatatableBuilder(store, this.schema.getDataTable());
  }

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('AGREEMENTS.WRITERS.TAB_TITLE'),
              model: this.store.pipe(select(fromRoot.getAgreementWriters)),
              ...this.selectionDatatable.getSelectionDatatableConfig(),
              onSelect: null,
              loadingIndicator: false,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: true,
              shadowed: false,
              columnMode: 'flex',
              onMouseSelect: event => this.schema.onMouseSelect(event),
              sortConfig: { format: sort => this.schema.formatSort(sort) },
              sorts: this.schema.getDefaultSorting(),
              apiCall: fromApiCalls.getAgreementWritersSummary,
            },
          },
        ],
      },
    ];
  }
}
