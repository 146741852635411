import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import * as fromRoot from 'store/root';
import { ActivityFacade } from './entities/activity.facade';
import { AgreementsFacade } from './entities/agreements.facade';
import { CounterClaimsFacade } from './entities/counter-claims.facade';
import { IpsFacade } from './entities/ips.facade';
import { NotesFacade } from './entities/notes.facade';
import { OrganizationsFacade } from './entities/organizations.facade';
import { SocietiesFacade } from './entities/societies.facade';
import { TerritoriesFacade } from './entities/territories.facade';
import { UsersFacade } from './entities/users.facade';
import { WorksFacade } from './entities/works.facade';

@Injectable()
export class IceFacade {
  territories = new TerritoriesFacade(this.store);
  users = new UsersFacade(this.store);
  agreements = new AgreementsFacade(this.store);
  works = new WorksFacade(this.store);
  societies = new SocietiesFacade(this.store);
  organizations = new OrganizationsFacade(this.store);
  notes = new NotesFacade(this.store);
  ips = new IpsFacade(this.store);
  counterClaims = new CounterClaimsFacade(this.store);
  activity = new ActivityFacade(this.store);

  constructor(private store: Store<fromRoot.RootState>) {}

  dispatch(action: Action): void {
    this.store.dispatch(action);
  }
}
