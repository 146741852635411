import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, OnChanges, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import * as Chart from '@ice';
import { ClaimantUtils } from '@ice';
import * as d3 from 'd3';
import { get, groupBy, isEmpty, map, round, sum, trim } from 'lodash';
import { TreeNodeContextMenuItem, TreeNodeData } from './tree-chart-models';

@Component({
  selector: 'ice-tree-chart',
  styleUrls: ['./tree-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: '<div class="box"><div class="d3-chart" #chart></div></div>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeChartComponent implements OnChanges {
  @ViewChild('chart', { static: true }) private chartContainer: ElementRef;
  @Input() verticalLevels = 3;
  @Input() horizontalLevels = 3;
  @Input() addAdvancedInfoLabels = false;
  @Input() expanseCollapseIcons = false;
  @Input() expanseDisabledRootContextMenu = false;
  @Input() rootNodeDefaultName = '';
  @Input() disableExpanseCollapseMode = false;
  data: Array<Chart.TreeData>;
  private margin: Readonly<any> = { top: 0, bottom: 20, left: 80, right: 20 };
  private duration: Readonly<number> = 750;
  private svg: d3.Selection<SVGElement, any, any, any>;
  private tree: d3.TreeLayout<{}>;
  private i = 0;
  private nodeColors: string[];
  private isFirefox: boolean;

  @HostListener('click', ['$event'])
  onClick(event) {
    if (event.target.tagName === 'svg') {
      this.removeContextmenu();
    }
  }

  constructor() {
    this.nodeColors = ['#7ACAF9', '#36839D', '#05A88C', '#2ab59d', '#50c2ae', '#76cfc0', '#9bdcd1', '#c0e9e2', '#daf2ee'];
    this.isFirefox = navigator.userAgent.indexOf('Firefox') !== -1;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['_data']) {
      this.data = changes['_data'].currentValue;
      this.plotChart();
    }
    if (changes['verticalLevels']) {
      this.verticalLevels = changes['verticalLevels'].currentValue;
    }
    if (changes['horizontalLevels']) {
      this.horizontalLevels = changes['horizontalLevels'].currentValue;
    }
  }

  @Input()
  set _data(data: Array<Chart.TreeData>) {
    if (this.data == null || this.data[0].label.match(data[0]?.label) == null) {
      this.data = data;
      this.plotChart();
    }
  }

  private plotChart(): void {
    setTimeout(() => {
      const element = this.chartContainer.nativeElement;
      const svgHeight = element.offsetHeight - this.margin.top - this.margin.bottom;
      this.clear(element);
      this.svg = this.drawCanvas(element);
      this.tree = this.createTree(this.verticalLevels * 100, 500);
      const root = this.transformData(this.data);
      root.x0 = 0;
      root.y0 = svgHeight / 2;
      this.updateChart(root);
    }, 200);
  }

  private updateChart(source: Chart.TreeNodes): void {
    const tree = this.tree(source);
    const nodes = tree.descendants();
    const links = tree.descendants().slice(1);
    const collapse = (event, d) => {
      event.preventDefault();
      event.stopPropagation();
      d._children = d.children;
      d.children = null;
      if (d._children) {
        d3.select(`.expanse-${d.id}`).style('visibility', 'visible');
      }
      d3.select(`.collapse-${d.id}`).style('visibility', 'hidden');
      this.updateChart(source);
    };

    const expanse = (event, d) => {
      event.preventDefault();
      event.stopPropagation();
      d.children = d._children;
      d._children = null;
      d3.select(`.expanse-${d.id}`).style('visibility', 'hidden');
      if (d.children) {
        d3.select(`.collapse-${d.id}`).style('visibility', 'visible');
      }
      this.updateChart(source);
    };

    const click = (event, d) => {
      if (!this.disableExpanseCollapseMode) {
        if (d.children) {
          collapse(event, d);
        } else {
          expanse(event, d);
        }
      }
    };

    const showInitialDisabledMenu =
      this.expanseDisabledRootContextMenu && (get(nodes, '[0].data.data.name') === this.rootNodeDefaultName || get(nodes, '[0].data.data.name') === '');

    const contextMenu = (event, currentNode) => {
      event.preventDefault();
      if (!showInitialDisabledMenu) {
        this.removeContextmenu();
        const nodeData = get(currentNode, 'data.data');
        if (nodeData?.contextMenu) {
          this.addContextMenu(currentNode?.id, nodeData);
        }
      }
    };

    nodes.forEach(function (d) {
      d.y = d.depth * 200;
    });

    const renderTextBox = function (d: Chart.TreeNodes) {
      d3.select(this).text((e: Chart.TreeNodes) => e.data.data.name);
    };

    const node = this.svg.selectAll('g.node').data(nodes, (d: any) => d.id || (d.id = ++this.i));

    const nodeEnter = node
      .enter()
      .append('g')
      .attr('class', d => `node node-${d['id']}`)
      .attr('transform', (d: Chart.TreeNodes) => 'translate(' + source.y0 + ',' + source.x0 + ')');

    let circleExtraClasses = '';
    let circleTextExtraClasses = '';
    if (showInitialDisabledMenu) {
      circleExtraClasses = 'node-circle-disabled';
      circleTextExtraClasses = 'node-circle-disabled-text';
    }

    // Add Circle for the nodes
    if (this.disableExpanseCollapseMode) {
      nodeEnter
        .append('circle')
        .attr('class', `node ${' ' + circleExtraClasses}`)
        .attr('r', 17)
        .on('click', contextMenu);

      nodeEnter
        .append('text')
        .attr('y', '9px')
        .attr('class', d => `label node-circle-no-expandable-text ${circleTextExtraClasses}`)
        .attr('text-anchor', 'middle')
        .text((d: Chart.TreeNodes) => '+')
        .on('click', contextMenu);
    } else {
      nodeEnter
        .append('circle')
        .attr('class', `node ${' ' + circleExtraClasses}`)
        .attr('r', 17)
        .on('contextmenu', contextMenu)
        .on('click', click);
    }

    // Add Initial Context Menu if needed
    if (showInitialDisabledMenu) {
      this.addContextMenu(nodes[0].id, nodes[0].data['data'], true);
    } else if (this.disableExpanseCollapseMode && nodes.length === 1) {
      this.addContextMenu(nodes[0].id, nodes[0].data['data']);
    }

    if (!this.disableExpanseCollapseMode && this.expanseCollapseIcons) {
      // Expanse
      nodeEnter
        .append('text')
        .attr('y', '4px')
        .attr('class', d => `label expanse-${d['id']}`)
        .attr('text-anchor', 'middle')
        .style('visibility', (d: Chart.TreeNodes) => (d._children ? 'visible' : 'hidden'))
        .text((d: Chart.TreeNodes) => '+')
        .on('click', expanse)
        .on('contextmenu', contextMenu);

      // Collapse
      nodeEnter
        .append('text')
        .attr('y', '4px')
        .attr('class', d => `label collapse-${d['id']}`)
        .attr('text-anchor', 'middle')
        .style('visibility', (d: Chart.TreeNodes) => (d.children ? 'visible' : 'hidden'))
        .text((d: Chart.TreeNodes) => '-')
        .on('click', collapse)
        .on('contextmenu', contextMenu);
    }

    // Add labels for the nodes
    if (this.addAdvancedInfoLabels) {
      const label = nodeEnter
        .append('g')
        .style('position', 'absolute')
        .attr('text-anchor', 'middle')
        .classed('node--internal', (d: Chart.TreeNodes) => !!(d.children || d._children));

      this.addToolTipLabel(label, 'name', (d: Chart.TreeNodes) => (d.depth === 0 ? '33' : '-64'), false, 'name');

      this.addToolTipLabel(label, 'territories', (d: Chart.TreeNodes) => '-44', true, 'territories');

      this.addToolTipLabel(label, 'dates', (d: Chart.TreeNodes) => '-24', true, 'dates');

      label
        .append('text')
        .attr('y', '-44')
        .text((d: Chart.TreeNodes) => d.data.data.message1)
        .style('visibility', (d: Chart.TreeNodes) => (d.depth === 0 || !d.data.data.message1 ? 'hidden' : 'visible'));

      label
        .append('text')
        .attr('y', '-24')
        .text((d: Chart.TreeNodes) => d.data.data.message2)
        .style('visibility', (d: Chart.TreeNodes) => (d.depth === 0 || !d.data.data.message2 ? 'hidden' : 'visible'));
    } else {
      nodeEnter
        .append('text')
        .attr('dy', (d: Chart.TreeNodes) => (d.depth === 0 ? '33' : '-24'))
        .attr('text-anchor', 'middle')
        .classed('node--internal', (d: Chart.TreeNodes) => !!(d.children || d._children))
        .each(renderTextBox);
    }

    nodeEnter
      .append('text')
      .attr('dy', '4px')
      .attr('class', 'label')
      .attr('text-anchor', 'middle')
      .text((d: Chart.TreeNodes) => d.data.data.role);

    this.addMRorPRLabel(nodeEnter, 'pr', 'PR', 'prRights', -35);

    this.addMRorPRLabel(nodeEnter, 'mr', 'MR', 'mrRights', 25);

    // UPDATE
    const nodeUpdate = nodeEnter.merge(node as any);

    // Transition to the proper position for the node
    nodeUpdate
      .transition()
      .duration(this.duration)
      .attr('transform', (d: Chart.TreeNodes) => 'translate(' + d.y + ',' + d.x + ')');

    // Update the node attributes and style
    nodeUpdate
      .select('circle.node')
      .attr('r', 15)
      .style('stroke-width', 5)
      .style('stroke', (d: Chart.TreeNodes) => d?.data?.data?.borderColor || this.nodeColors[d.data.depth])
      .style('fill', (d: Chart.TreeNodes) => this.nodeColors[d.data.depth])
      .attr('cursor', 'pointer');

    // Remove any exiting nodes
    const nodeExit = node
      .exit()
      .transition()
      .duration(this.duration)
      .attr('transform', d => 'translate(' + source.y + ',' + source.x + ')')
      .remove();

    // On exit reduce the node circles size to 0
    nodeExit.select('circle').attr('r', 1e-6);

    // On exit reduce the opacity of text labels
    nodeExit.select('text').style('fill-opacity', 1e-6);

    // Update the links
    const link = this.svg.selectAll('path.link').data(links, (d: Chart.TreeNodes) => d.id);

    // Creates a curved (diagonal) path from parent to the child nodes
    const diagonal = (s, d) => `M ${s.y} ${s.x}
        C ${(s.y + d.y) / 2} ${s.x},
          ${(s.y + d.y) / 2} ${d.x},
          ${d.y} ${d.x}`;

    // Enter any new links at the parent's previous position.
    const linkEnter = link
      .enter()
      .insert('path', 'g')
      .attr('class', 'link')
      .attr('d', function (d) {
        const o = { x: source.x0, y: source.y0 };
        return diagonal(o, o);
      });

    // UPDATE
    const linkUpdate = linkEnter.merge(link as any);

    // Transition back to the parent element position
    linkUpdate
      .transition()
      .duration(this.duration)
      .attr('d', d => diagonal(d, d.parent));

    // Remove any exiting links
    const linkExit = link
      .exit()
      .transition()
      .duration(this.duration)
      .attr('d', function (d) {
        const o = { x: source.x, y: source.y };
        return diagonal(o, o);
      })
      .remove();

    // Store the old positions for transition.
    nodes.forEach(function (d: Chart.TreeNodes) {
      d.x0 = d.x;
      d.y0 = d.y;
    });
  }

  private transformData(data: Chart.TreeData[]): Chart.TreeNodes {
    const flatData = d3
      .stratify()
      .id((d: Chart.TreeData) => d.label)
      .parentId((d: Chart.TreeData) => d.parent)(data);
    return d3.hierarchy(flatData, d => d.children);
  }

  private drawCanvas(element): d3.Selection<any, any, any, any> {
    const totalVNodes = sum(map(groupBy(this.data, 'level'), group => group.length));
    const extraSpaceForMenus = totalVNodes * 20;
    return d3
      .select(element)
      .append<SVGElement>('svg')
      .attr('width', this.horizontalLevels * 200)
      .attr('height', this.verticalLevels * 100 + extraSpaceForMenus)
      .attr('padding-top', '50px')
      .append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + extraSpaceForMenus + ')')
      .attr('class', 'the_G')
      .attr('id', 'the_G');
  }

  private createTree(w: number, h: number): d3.TreeLayout<{}> {
    return d3
      .tree()
      .size([w, h])
      .separation((a: Chart.TreeNodes, b: Chart.TreeNodes) => (a.parent === b.parent ? 1 : 1.1));
  }

  private clear(element): boolean {
    d3.select(element).select('svg').remove();
    return true;
  }

  private addContextMenu(id: string, nodeData: TreeNodeData, disabledMode = false) {
    const items = nodeData?.contextMenu?.items;
    if (items) {
      const parentNode = d3.select(`.node-${id}`);
      const maxLabelCharacters = items.map(item => item.label.length || 0).sort((a, b) => (a < b ? 0 : -1))[0];
      const contextMenuItemWidth = maxLabelCharacters * (this.isFirefox ? 16 : 8);
      const contextMenuItemsHeight = items.length * 37;
      parentNode
        .append('rect')
        .attr('class', 'context-menu')
        .attr('rx', 3)
        .attr('ry', 3)
        .attr('x', `-${(20 + contextMenuItemWidth) / 2}px`)
        .attr('y', `-${20 + contextMenuItemsHeight + 20}px`)
        .attr('width', `${20 + contextMenuItemWidth}`)
        .attr('height', `${20 + contextMenuItemsHeight}`)
        .attr('fill', '#FFF');
      nodeData.contextMenu.items.forEach((menuItem, index) =>
        this.addMenuItem(parentNode, nodeData, menuItem, index, contextMenuItemWidth, contextMenuItemsHeight, maxLabelCharacters, disabledMode),
      );
      parentNode.raise();
    }
  }

  private addMenuItem(
    parentNode: any,
    nodeData: any,
    menuItem: TreeNodeContextMenuItem,
    index: number,
    contextMenuItemWidth: number,
    contextMenuItemsHeight: number,
    maxLabelCharacters: number,
    disabledMode = false,
  ) {
    const initialBoxYValue = -(10 + contextMenuItemsHeight + 14);
    const initialLabelYValue = initialBoxYValue + 20;
    const currentBoxYValue = initialBoxYValue + index * 35;
    const currentLabelYValue = initialLabelYValue + index * 35;
    const onItemClick = disabledMode
      ? event => (event.preventDefault(), event.stopPropagation())
      : event => (event.preventDefault(), event.stopPropagation(), menuItem.action(nodeData, this.removeContextmenu));

    const menuItemLeftXposition = (20 + contextMenuItemWidth) / 2 - 10;

    parentNode
      .append('rect')
      .attr('class', disabledMode ? 'menu-item menu-item-disabled' : 'menu-item')
      .attr('rx', 3)
      .attr('ry', 3)
      .attr('x', `-${menuItemLeftXposition}px`)
      .attr('y', `${currentBoxYValue}px`)
      .attr('width', contextMenuItemWidth)
      .attr('height', 30)
      .attr('stroke', '#cccccc')
      .attr('border-radius', '3px')
      .attr('fill', disabledMode ? '#C4C4C4' : menuItem.fillColor || '#2F9AE3')
      .on('click', onItemClick);

    parentNode
      .append('text')
      .attr('class', disabledMode ? 'menu-item-text-disabled' : 'menu-item-text')
      .attr('x', `${round(maxLabelCharacters * (this.isFirefox ? 8.1 : 4.1)) - menuItemLeftXposition}px`)
      .attr('y', `${currentLabelYValue}px`)
      .attr('fill', '#fff')
      .attr('text-anchor', 'middle')
      .attr('width', contextMenuItemWidth)
      .text((d: Chart.TreeNodes) => menuItem.label)
      .on('click', onItemClick);
  }

  public removeContextmenu() {
    d3.selectAll(`text.menu-item-text`).remove();
    d3.selectAll('rect.menu-item').remove();
    d3.selectAll('rect.context-menu').remove();
    d3.selectAll(`text.menu-item-text-disabled`).remove();
    d3.selectAll('rect.menu-item-disabled').remove();
  }

  private addMRorPRLabel(
    nodeEnter: d3.Selection<SVGGElement, d3.HierarchyPointNode<{}>, SVGElement, any>,
    keyValue: string,
    labelText: string,
    prOrMrRightsKeyValue: string,
    x: number,
  ) {
    let tooltip;
    const labelHeight = 20;
    const valueY = 45;
    nodeEnter
      .append('text')
      .attr('dy', '30px')
      .attr('x', x)
      .style('visibility', (d: Chart.TreeNodes) => (d.data.data.territories || d.data.data.dates ? 'visible' : 'hidden'))
      .text(function (d: Chart.TreeNodes) {
        if (d.data.data.parent != null) {
          return labelText;
        } else {
          return '';
        }
      });

    nodeEnter
      .append('text')
      .attr('dy', `${valueY}px`)
      .attr('x', x)
      .attr('height', `${labelHeight}px`)
      .style('visibility', (d: Chart.TreeNodes) => (d.data.data.territories || d.data.data.dates ? 'visible' : 'hidden'))
      .text(function (d: Chart.TreeNodes) {
        if (d.data.data.parent != null) {
          return ClaimantUtils.roundShare(d.data.data[keyValue]);
        } else {
          return '';
        }
      })
      .on('mouseover', (event, currentNode: Chart.TreeNodes) => {
        if (!isEmpty(trim(currentNode.data.data[prOrMrRightsKeyValue]))) {
          tooltip = this.createTooltip(currentNode, prOrMrRightsKeyValue, labelHeight, () => valueY);
        }
      })
      .on('mouseout', (event, currentNode: Chart.TreeNodes) => !isEmpty(trim(currentNode.data.data[prOrMrRightsKeyValue])) && tooltip.remove());
  }

  private addToolTipLabel(nodeLabel: d3.Selection<SVGGElement, d3.HierarchyPointNode<{}>, SVGElement, any>, keyValue: string, dy: any, hideOnRoot: boolean, cssTooltipTag: string) {
    let tooltip;
    const labelHeight = 20;
    nodeLabel
      .append('text')
      .attr('class', d => `text-label-container-${cssTooltipTag}`)
      .attr('dy', dy)
      .attr('height', `${labelHeight}px`)
      .text((d: Chart.TreeNodes) => (d.data.data[keyValue] && d.data.data[keyValue].length > 25 ? `${d.data.data[keyValue].slice(0, 20)}...` : d.data.data[keyValue]))
      .style('visibility', (d: Chart.TreeNodes) => (hideOnRoot && d.depth === 0 ? 'hidden' : 'visible'))
      .on('mouseover', (event, currentNode: Chart.TreeNodes) => {
        if (currentNode.data.data[keyValue].length > 25 && ((hideOnRoot && currentNode.data.data.level > 0) || !hideOnRoot)) {
          tooltip = this.createTooltip(currentNode, cssTooltipTag, labelHeight, dy);
        }
      })
      .on('mouseout', (event, currentNode: Chart.TreeNodes) => {
        if (currentNode.data.data[keyValue].length > 25 && ((hideOnRoot && currentNode.data.data.level > 0) || !hideOnRoot)) {
          tooltip.remove();
        }
      });
  }

  private createTooltip(currentNode: Chart.TreeNodes, cssTooltipTag: string, labelHeight: number, dy: any) {
    const tooltip = d3
      .select(`.node-${currentNode.id}`)
      .append('g')
      .attr('class', d => `tooltip tooltip-${cssTooltipTag}`);
    const y = dy(currentNode);
    const text = tooltip
      .append('text')
      .attr('y', `${y - labelHeight - 4}px`)
      .attr('fill', '#FFFFFF')
      .attr('font-family', 'Roboto')
      .attr('font-size', '12pt')
      .attr('text-anchor', 'middle')
      .classed('node-tooltip', true)
      .text((d: Chart.TreeNodes) => d.data.data[`${cssTooltipTag}`]);

    const SVGRect = text.node().getBBox();
    tooltip
      .append('rect')
      .attr('x', SVGRect.x - 8)
      .attr('y', SVGRect.y - 4)
      .attr('width', SVGRect.width + 16)
      .attr('height', SVGRect.height + 8)
      .attr('fill', '#707070')
      .attr('rx', '3')
      .attr('ry', '3')
      .style('padding', '10px');

    d3.select(`.node-${currentNode.id}`).raise();
    text.raise();
    return tooltip;
  }
}
