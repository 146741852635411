import { IpUtils, SocietyObject, StringUtils, WorkSociety } from '@ice';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { ipsSocietiesCodes } from 'assets/ts/ips-societies';
import { MECHANICAL_RIGHTS, mrRights, prRights, societiesICE } from 'config/constants/ips.constants';
import { RIGHT_MR, RIGHT_PR } from 'config/constants/shares.constants';
import { TerritoryDataType } from 'config/constants/territories.constants';
import { ALL_MR, ALL_PR } from 'config/constants/works.constants';
import { difference, filter, find, flatten, get, has, intersection, join, map, padStart, sortBy, toNumber } from 'lodash';
import { IpSocietyRelation } from 'models';
import { ClaimClaimantParty } from 'models/copyright/detail/claims';
import { IconInterface } from 'models/copyright/detail/icon';
import { SocietyDetail } from 'models/copyright/detail/society';
import { TerritoryUtils } from '@ice';

export class SocietiesUtils {
  static cleanSocietiesRelations(societiesRelations: IpSocietyRelation[], translate: TranslateService) {
    return societiesRelations && societiesRelations.length > 0 && societiesRelations.map(societyRel => this.cleanSocietyRelation(societyRel, translate));
  }

  static cleanSocietyRelation(societyRel: IpSocietyRelation, translate: TranslateService): IpSocietyRelation {
    const { territoriesIcon, territoriesText } = TerritoryUtils.getTerritoriesDisplayListData(societyRel.territories.split(','), TerritoryDataType.NAME);
    const territoriesTooltip = TerritoryUtils.getTerritoriesNamesTooltipText(
      TerritoryUtils.convertTerritoryArrayElements(societyRel.territories.split(','), TerritoryDataType.NAME),
    );
    const { creationClass } = societyRel;
    const creationClassTooltip = this.getCreationClass(creationClass, translate);
    const roleCodesTooltip = this.getRolesFullName(societyRel.roleCodesRaw, translate);
    const rightsCodesTooltip = this.getRightsCodes(societyRel.rightsCodes, translate);

    return {
      ...societyRel,
      territoriesText,
      territoriesIcon,
      territoriesTooltip,
      creationClassTooltip,
      roleCodesTooltip,
      rightsCodesTooltip,
    };
  }

  static cleanRights(rights: Object[], translate: TranslateService) {
    return rights && rights.length > 0 && rights.map(societyRel => this.cleanRight(societyRel, translate));
  }

  static cleanRight(right: Object, translate: TranslateService): Object {
    const { territoriesIcon, territoriesText } = has(right, 'territories') && TerritoryUtils.getTerritoriesDisplayListData(right['territories'], TerritoryDataType.NAME);
    const roleCodesTooltip = this.getRolesFullName(right['roleCodes'], translate);
    return {
      ...right,
      territoriesText,
      territoriesIcon,
      territoriesTooltip: TerritoryUtils.getTerritoriesNamesTooltipText(TerritoryUtils.convertTerritoryArrayElements(right['territories'], TerritoryDataType.NAME)),
      roleCodesTooltip,
    };
  }

  static cleanSociety(society: SocietyDetail): SocietyDetail {
    const territories = get(society, 'territories') && TerritoryUtils.convertTerritoryArrayElements(society.territories, TerritoryDataType.NAME);
    return {
      ...society,
      territories,
    };
  }

  static getFormattedSocietiesList(): {}[] {
    return ipsSocietiesCodes
      .map(society => {
        return { label: society.name, value: society.code };
      })
      .sort((a, b) => (a.label < b.label ? -1 : 1));
  }

  static getFormattedSocietiesListCISAC(): {}[] {
    return ipsSocietiesCodes
      .map(society => {
        return { label: society.name, value: `CISAC:${parseInt(society.code, 10)}` };
      })
      .sort((a, b) => (a.label < b.label ? -1 : 1));
  }

  static getSocietyName(societyCode: string): string {
    const selectedSociety = ipsSocietiesCodes.find(society => society.code === padStart(societyCode, 3, '0'));
    return (selectedSociety && selectedSociety.name) || '';
  }

  static getSocietyCodeByName(societyName: string): string {
    const selectedSociety = ipsSocietiesCodes.find(society => society.name === societyName);
    return (selectedSociety && selectedSociety.code) || '';
  }

  static getSocietyExtraPermissions(societyCode: string): String[] {
    const selectedSociety = ipsSocietiesCodes.find(society => society.code === padStart(societyCode, 3, '0'));
    return (selectedSociety && selectedSociety.extraSocPermissions) || [];
  }

  static searchSocietyNameById(id: string): string {
    const codes = ipsSocietiesCodes.find(item => item.code === id);
    return (codes && codes.name) || '';
  }

  static getSocietyNamesAndIdsByIds(ids: string): string {
    let output = '';

    if (ids) {
      ids = ids.replace(/[() ]/g, '');
      const idList = [...ids.split(',')];
      const codeNames = [];

      idList.forEach((id: string) => {
        const code = this.searchSocietyNameById(id);
        codeNames.push(id + ':' + code);
      });

      output = codeNames.join(', ');
    }

    return output; // 035:GEMA, 005:AKM, 080:SUISA ...
  }

  static getPRorMRSocietyFromContributor(societies, type: string): SocietyObject {
    const rightsToSelect = type === MECHANICAL_RIGHTS ? mrRights : prRights;
    const filteredTypeSocieties = filter(societies, s => {
      const rights =
        s.memberships &&
        this.getFilterMembershipsValidDate(s.memberships) &&
        find(s.memberships, m => {
          const correctRights = intersection(m.rights, rightsToSelect);
          return correctRights.length > 0;
        });
      return rights;
    });

    return this.getDisplaySocietyObject(filteredTypeSocieties);
  }

  static getPRorMRSociety(societies, type: string): SocietyObject {
    const rightsToSelect = type === MECHANICAL_RIGHTS ? mrRights : prRights;

    const filteredTypeSocieties = filter(societies, s => {
      const memberships = this.getFilterMembershipsValidDate(s.memberships);
      const rights =
        memberships &&
        find(memberships, m => {
          const correctRights = intersection(m.rights, rightsToSelect);
          return correctRights.length > 0;
        });
      return rights;
    });

    return SocietiesUtils.getDisplaySocietyObject(filteredTypeSocieties);
  }

  static getFilterMembershipsValidDate(memberships: any[]): any[] {
    return (memberships || []).filter(membership => Date.now() > new Date(membership.startDate).getTime() && Date.now() < new Date(membership.endDate).getTime());
  }

  static getDisplaySocietyObject(filteredTypeSocieties: string[]): any {
    const allSocietiesCodes: string[] = this.getAllSocietiesCodes(filteredTypeSocieties);
    const societyCode = (allSocietiesCodes && allSocietiesCodes.length > 0 && allSocietiesCodes[0]) || '';
    const iceSocietiesCodes: string[] = this.getICESocieties(filteredTypeSocieties) || [];
    const iceSocietiesCodesFormatted: string = (iceSocietiesCodes.length > 0 && `(${join(iceSocietiesCodes, ', ')})`) || '';
    const notIceSocietiesCodesFormatted = join(this.getNotICESocieties(filteredTypeSocieties), ', ');
    const societyCodes =
      iceSocietiesCodesFormatted.length > 0
        ? iceSocietiesCodesFormatted.concat(notIceSocietiesCodesFormatted.length > 0 ? `, ${notIceSocietiesCodesFormatted}` : '')
        : notIceSocietiesCodesFormatted;
    const multipleSocieties = filteredTypeSocieties && filteredTypeSocieties.length > 1 ? true : false;
    const hasICESociety = iceSocietiesCodes.length > 0;

    return { societyCode, multipleSocieties, hasICESociety, societyCodes };
  }

  static hasICESociety(societies) {
    if (societies) {
      const societiesIds = societies.map(s => s.societyId && this.formatSocietyCode(s.societyId));
      return intersection(societiesICE, societiesIds).length > 0;
    }
    return false;
  }

  static getICESocieties(societies): string[] {
    if (societies) {
      const societiesIds = societies.map(s => s.societyId && this.formatSocietyCode(s.societyId)) || [];
      return intersection(societiesICE, societiesIds);
    }
    return [];
  }

  static getAllSocietiesCodes(societies): string[] {
    return (societies && societies.map(s => s.societyId && this.formatSocietyCode(s.societyId))) || [];
  }

  static getNotICESocieties(societies): string[] {
    if (societies) {
      const societiesIds = societies.map(s => s.societyId && this.formatSocietyCode(s.societyId)) || [];
      return difference(societiesIds, societiesICE);
    }
    return [];
  }

  static formatSocietyCode(societyId): string {
    return padStart(societyId.split(':')[1], 3, '0');
  }

  static formatSocietyCodeNameFirst(societyId: string): string {
    const id = societyId?.trim();
    return (id && `${SocietiesUtils.searchSocietyNameById(id)}:${id}`) || '';
  }

  static generateIsICESocietyIcons({ isICE, societies, excludeMargin }: { isICE: boolean; societies?: string; excludeMargin?: boolean }): IconInterface[] {
    const marginClass = excludeMargin ? '' : 'ice-ml-10';
    const blueIconClass = 'blue-icon';

    return isICE
      ? [{ icon: 'more', text: societies, class: [marginClass, blueIconClass].join(' ') }]
      : [{ icon: 'more_horiz', text: societies, class: marginClass }];
  }

  static formatSociety(society) {
    return (society && `${society}:${this.searchSocietyNameById(society.trim())}`) || '';
  }

  static getSocietyCode(societyId) {
    return societyId && societyId.split(':')[1] ? padStart(societyId.split(':')[1], 3, '0') || '' : '';
  }

  static getSocietyCodeAndName(societyId: string): { societyCode: string; societyName: string } {
    const societyIdSplitted = societyId && societyId.split(':');
    const societyCode = (societyIdSplitted && societyIdSplitted.length > 0 && padStart(societyId.split(':')[1], 3, '0')) || '';
    const societyName = (societyCode && SocietiesUtils.searchSocietyNameById(societyCode)) || '';
    return { societyCode, societyName };
  }

  static getFormattedSocietyName(societyName) {
    if (societyName === 'BUMA') {
      return 'BUMA/STEMRA';
    }
    return societyName;
  }

  static getOriginalSocietyName(societyName) {
    if (societyName === 'BUMA/STEMRA') {
      return 'BUMA';
    }
    return societyName;
  }

  static getFormattedSocietyCode(societyCode) {
    if (societyCode === '023') {
      return '023/078';
    }
    return societyCode;
  }

  static getOriginalSocietyCode(societyCode) {
    if (societyCode === '023/078') {
      return '023';
    }
    return societyCode;
  }

  static getSocietiesAttributes(societies: WorkSociety[]) {
    if (societies) {
      return flatten(
        societies.map(society => {
          const attributes: Object = society.attributes || {};
          const { societyCode, societyName } = this.getSocietyCodeAndName(society.societyId);
          const formattedSocietyName = this.getFormattedSocietyName(societyName);
          const formattedSocietyCode = this.getFormattedSocietyCode(societyCode);

          return Object.entries(attributes)
            .filter(attribute => {
              return StringUtils.removeExtraSpaces(attribute[1]) !== '';
            })
            .map(attribute => {
              const attributeName = attribute[0];
              const attributeValue = attribute[1];
              return {
                societyName: formattedSocietyName,
                societyCode: formattedSocietyCode,
                attributeName,
                attributeValue,
              };
            });
        }),
      );
    }
  }

  static getSocietiesAttributeGradings(societies: WorkSociety[]) {
    const gradings = [];
    societies.map(society => {
      const societySplitted: { societyCode: string; societyName: string } = this.getSocietyCodeAndName(society.societyId);
      const societyName = societySplitted.societyName;
      let i = -1;
      if (society.grading) {
        society.grading.map(grading => {
          i++;
          const index = i.toString();
          gradings.push({ ...grading, societyName, index });
        });
      }
    });
    return gradings;
  }

  static getSocietyAttributesLastValue(societies: WorkSociety[], societyCode: string) {
    const selectedSociety = societies.find(society => society.societyId === `CISAC:${toNumber(societyCode)}`);
    const attributesToEdit = selectedSociety && selectedSociety.attributes ? { ...selectedSociety.attributes } : {};
    Object.keys(attributesToEdit).forEach(key => {
      const lastIndex = (attributesToEdit[key] && attributesToEdit[key].length - 1) || 0;
      attributesToEdit[key] = (attributesToEdit[key] && attributesToEdit[key][lastIndex]) || '';
    });
    return attributesToEdit;
  }

  static getSocietyGradingLastValue(societies: WorkSociety[], societyCode: string, grading) {
    const selectedSociety = societies.find(society => society.societyId === `CISAC:${toNumber(societyCode)}`);
    if (!grading.index) {
      return {};
    }
    const { gradingDate, gradingDecider, gradingStatus, gradingValue } = grading;
    return { gradingDate, gradingDecider, gradingStatus, gradingValue };
  }

  static getRelationSocietiesName(code) {
    return ipsSocietiesCodes.find(society => society.code === code) || ({} as any);
  }

  static getPrMrShares(claimShare: any): { prShares: number; mrShares: number } {
    let prShares = 0;
    let mrShares = 0;

    if (this.checkClaimHasRights(claimShare.rightTypes, prRights)) {
      prShares = ((!isNaN(prShares) && prShares) || 0) + claimShare.share;
    }
    if (this.checkClaimHasRights(claimShare.rightTypes, mrRights)) {
      mrShares = ((!isNaN(mrShares) && mrShares) || 0) + claimShare.share;
    }
    return { prShares, mrShares };
  }

  static checkClaimHasRights(claimRights: any, rightsToCompare: string[]): boolean {
    return intersection(claimRights, rightsToCompare).length > 0;
  }

  static getCreationClass(creationClass, translate: TranslateService) {
    return translate.instant(`IPS.DETAILS.CARD_WITH_DATA_TABLE_TWO.TABLE_SCHEMA.CREATION_CLASS_ITEM.${creationClass}`);
  }

  static getRolesFullName(roles, translate: TranslateService) {
    const groupRolesName = roles.map(role => {
      return { key: `${role}`, label: `${translate.instant('ROLES.' + role)}` };
    });
    return join(
      sortBy(groupRolesName).map(item => item.label),
      '<br>',
    );
  }

  static getRightsCodes(rights, translate: TranslateService) {
    const rightCodesTemp = rights.split(',') || [];
    const groupRightCodes = rightCodesTemp.map(right => {
      return { key: `${right}`, label: `${translate.instant('RIGHTS.' + right)}` };
    });
    return join(
      sortBy(groupRightCodes).map(item => item.label),
      '<br>',
    );
  }

  static getSocietyTooltip(societies) {
    if (societies) {
      if (societies.indexOf(',') !== -1) {
        return this.getSocietyNamesAndIdsByIds(societies);
      } else if (societies.indexOf(':') !== -1) {
        return this.searchSocietyNameById(societies.split(':')[0]);
      }
    }
  }

  static getPrRightSelect(translate: TranslateService, customClassName?: string, defaultValue = ''): FormlyFieldConfig {
    return {
      className: 'flex-1 ice-min-width-170 pr-right-select',
      key: 'prRightType',
      type: 'ice-select',
      defaultValue,
      templateOptions: {
        className: `flex-1 ${customClassName}`,
        label: `${translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.FIELD_PR_RIGHTS')} *`,
        options: IpUtils.getPrOptions([{ label: ALL_PR, value: 'ALL' }]),
      },
    };
  }

  static getMrRightSelect(translate: TranslateService, customClassName?: string, defaultValue = ''): FormlyFieldConfig {
    return {
      className: 'flex-1 ice-min-width-170 mr-right-select',
      key: 'mrRightType',
      type: 'ice-select',
      defaultValue,
      templateOptions: {
        className: `flex-1 ${customClassName}`,
        label: `${translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.FIELD_MR_RIGHTS')} *`,
        options: IpUtils.getMrOptions([{ label: ALL_MR, value: 'ALL' }]),
      },
    };
  }

  static checkSocietyHasSocietyCode(societies: any[] = [], societyCode: string = ''): boolean {
    return societies.includes(`CISAC:${societyCode.toString().replace('SO', '')}`);
  }

  static getClaimantWithSocietyCode(claimants: any[] = [], societyCode: string = ''): any[] {
    return claimants.filter(claimant => this.checkSocietyHasSocietyCode(claimant.societies, societyCode));
  }

  static containsClaimantTheSocietyCode(claimants: any[] = [], societyCode: string = ''): boolean {
    return !!this.getClaimantWithSocietyCode(claimants, societyCode).length;
  }

  static getClaimantWithSocietyCodeFromClaimClaimantParty(claimants: ClaimClaimantParty[] = [], societyCode: string = ''): any[] {
    return claimants.filter(claimant =>
      this.checkSocietyHasSocietyCode(
        filter(map(claimant.societies, 'societyId'), society => !!society),
        societyCode,
      ),
    );
  }
}
