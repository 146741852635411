import { Injectable, OnDestroy } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { Store, select } from '@ngrx/store';
import { environment } from 'config/env';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CheckVersionService } from 'services/check-version/check-version.service';
import { RootState, getUserDetail } from 'store/root';

@Injectable({
  providedIn: 'root',
})
export class DataDogRUMService implements OnDestroy {
  user$: Observable<string>;

  constructor(protected versionService: CheckVersionService, protected store: Store<RootState>) {}

  initialize(): void {
    this.versionService.currentVersion$.subscribe(version => {
      datadogRum.init({
        clientToken: get(environment, 'dataDogConf.clientId', ''),
        applicationId: get(environment, 'dataDogConf.applicationId', ''),
        allowedTracingUrls: [url => url.startsWith(get(environment, 'apiUrl', ''))],
        site: 'datadoghq.eu',
        env: get(environment, 'dataDogConf.environmentName', ''),
        service: get(environment, 'dataDogConf.service', ''),
        version,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        traceSampleRate: 100,
        trackUserInteractions: true,
        trackInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      });

      datadogRum.startSessionReplayRecording();
    });

    this.store
      .pipe(
        select(getUserDetail),
        map(user => user && user.id),
      )
      .subscribe((value: string) => {
        datadogRum.setUser({
          id: value,
        });
      });
  }

  async ngOnDestroy() {
    datadogRum.clearUser();
    datadogRum.stopSessionReplayRecording();
  }
}
