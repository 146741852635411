import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { ALL_RIGHTS } from 'config/constants/shares.constants';
import { includes } from 'lodash';
import { isObservable } from 'rxjs';

export const SHARES_TYPE_CLAIM = 'CLAIM';
export const TYPE_PERFORMING = 'performing';
export const TYPE_MECHANICAL = 'mechanical';
export const TYPE_SYNCHRONISATION = 'synchronisation';
export const TYPE_PRINT = 'print';
const DEFAULT_TERRITORY = '2WL';
const DEFAULT_INCLUSION = ALL_RIGHTS;
const USAGE_TYPE_FOR_LABEL = 'TITLE';
const INITIAL_MODEL_PERFORMING = { type: 'Performing', creator: 0, publisher: 0, assignee: 0, territory: DEFAULT_TERRITORY, inclusion: DEFAULT_INCLUSION };
const INITIAL_MODEL_MECHANICAL = { type: 'Mechanical', creator: 0, publisher: 0, assignee: 0, territory: DEFAULT_TERRITORY, inclusion: DEFAULT_INCLUSION };
const INITIAL_MODEL_SYNCHRONISATION = { type: 'Synchronisation', creator: 0, publisher: 0, assignee: 0, territory: DEFAULT_TERRITORY, inclusion: DEFAULT_INCLUSION };
const INITIAL_MODEL_PRINT = { type: 'Print', creator: 0, publisher: 0, assignee: 0, territory: DEFAULT_TERRITORY, inclusion: DEFAULT_INCLUSION };
const INITIAL_MODEL_PERFORMING_CLAIM = { type: 'Performing', share: 0, territory: DEFAULT_TERRITORY, inclusion: DEFAULT_INCLUSION };
const INITIAL_MODEL_MECHANICAL_CLAIM = { type: 'Mechanical', share: 0, territory: DEFAULT_TERRITORY, inclusion: DEFAULT_INCLUSION };

const INIT_MODEL_AGREEMENT = {
  performing: INITIAL_MODEL_PERFORMING,
  mechanical: INITIAL_MODEL_MECHANICAL,
  synchronisation: INITIAL_MODEL_SYNCHRONISATION,
  print: INITIAL_MODEL_PRINT,
};
const INIT_MODEL_CLAIM = {
  performing: INITIAL_MODEL_PERFORMING_CLAIM,
  mechanical: INITIAL_MODEL_MECHANICAL_CLAIM,
};
@Component({
  selector: 'ice-formly-shares-repeat-section',
  templateUrl: './shares-repeat-section-type.component.html',
  styleUrls: ['./shares-repeat-section-type.component.scss'],
})
export class SharesRepeatSectionTypeComponent extends FieldArrayType {
  isObservable = isObservable;
  constructor(private translate: TranslateService) {
    super();
  }

  remove(i) {
    super.remove(i);
    if (this.to['onRemove']) {
      this.to['onRemove'](i);
    }
  }

  addMechanical() {
    super.add(this.model ? this.model.length : 0, this.getInitialModel(TYPE_MECHANICAL));
  }

  addPerforming() {
    super.add(this.model ? this.model.length : 0, this.getInitialModel(TYPE_PERFORMING));
  }

  addSynchronisation() {
    super.add(this.model ? this.model.length : 0, this.getInitialModel(TYPE_SYNCHRONISATION));
  }

  addPrint() {
    super.add(this.model ? this.model.length : 0, this.getInitialModel(TYPE_PRINT));
  }

  getInitialModel(type) {
    const { sharesType } = this.to;
    if (sharesType && sharesType === SHARES_TYPE_CLAIM) {
      return INIT_MODEL_CLAIM[type];
    }
    return INIT_MODEL_AGREEMENT[type];
  }

  showInclusionPopup(i, event) {
    event.preventDefault();
    const field = this.field.fieldGroup[i].formControl.get('inclusion');
    const rightType = this.field.fieldGroup[i].formControl.get('type').value;
    const currentIncluded = this.getCurrentIncludedUsageInField(field.value);
    this.to.clickInclusionButton(rightType, currentIncluded, ($event, afterUpdate) => this.updateUsageTypes(field, $event, afterUpdate));
  }

  updateUsageTypes(field, includedUsages, afterUpdate) {
    const includedUsagesCodes = [];
    let allSelected = true;
    for (const key in includedUsages) {
      if (!includes(key, USAGE_TYPE_FOR_LABEL)) {
        allSelected = allSelected && includedUsages[key];
        if (includedUsages[key]) {
          includedUsagesCodes.push(key);
        }
      }
    }
    field.markAsTouched();
    field.setValue(allSelected ? DEFAULT_INCLUSION : includedUsagesCodes.join(', '));
    afterUpdate();
  }

  getCurrentIncludedUsageInField(fieldValue: string): Array<string> {
    return fieldValue.split(',').map(usageType => usageType.trim());
  }

  getShareTableLabelClass(i, rowLabel) {
    let rowClass = i > 0 ? 'ice-share-table-label' : '';
    if (rowLabel.classes) {
      rowClass += ` ${rowLabel.classes}`;
    }
    return rowClass;
  }
}
