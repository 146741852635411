import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CC_STATUS_RESOLVED, ClaimantUtils, TerritoryUtils, fieldConfig, fieldInput } from '@ice';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { URL_CONFLICTS } from 'app/sections/wrapper/detail/detail';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import { CounterClaimActionDialog } from 'config/constants/counter-claims.constants';
import { TerritoryDataType } from 'config/constants/territories.constants';
import { CopyrightClaimsDataTable } from 'config/data-table-builders/copyright.claims';
import { DialogCounterClaimActions } from 'config/dialog-builders/dialog-counter-claim-actions';
import { SectionsConfig } from 'config/sections-config';
import { IceFacade } from 'facades/ice.facade';
import { cloneDeep, get, isEmpty } from 'lodash';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, map, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { CommonApiService } from 'services/common-api.service';
import { FeatureFlagService } from 'services/feature-flags/feature-flags-flagsmith.service';
import { NamespaceService } from 'services/namespace/namespace.service';
import { PermissionsService } from 'services/permissions/permissions.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';
import { SearchReset } from 'store/root';
import { SectionTabConfig } from '../../tabs-data';

export class TabCounterClaimsDetail implements SectionTabConfig {
  private schema: CopyrightClaimsDataTable;
  private editMode: any;
  private unsubscribeAll = new Subject();
  private dialogCounterClaimActionsBuilder: DialogCounterClaimActions;

  // Permissions
  private canSupport: boolean;
  private canReduce: boolean;
  private canWithdraw: boolean;
  private canResolve: boolean;
  private canViewActionButtons: boolean;
  private canOpenIps: boolean;
  flagShowMergedTable: Observable<boolean>;
  expandTableSbj = new BehaviorSubject<boolean>(false);

  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private dialog: MatDialog,
    private commonApiService: CommonApiService,
    private detailService: null,
    private nsService: NamespaceService,
    private iceFacade: IceFacade,
    private fieldValidatorService: FieldValidatorService,
    private permissionsService: PermissionsService,
    private searchService,
    storeNewItem: null,
    params: null,
    private datatableService: null,
    private featureFlagService: FeatureFlagService,
  ) {
    this.flagShowMergedTable = this.featureFlagService.showNewMergedTableSbj.asObservable().pipe(takeUntil(this.unsubscribeAll));

    this.fuseTranslationLoader.loadTranslations(english);
    this.schema = new CopyrightClaimsDataTable(this.translate, this.fuseTranslationLoader, this.store, this.permissionsService);
    this.canSupport = this.permissionsService.can('counter-claims_details_support');
    this.canReduce = this.permissionsService.can('counter-claims_details_reduce');
    this.canWithdraw = this.permissionsService.can('counter-claims_details_withdraw');
    this.canResolve = this.permissionsService.can('counter-claims_details_resolve');
    this.canViewActionButtons = this.permissionsService.can('view-counter-claims_detail_actions');
    this.dialogCounterClaimActionsBuilder = new DialogCounterClaimActions(translate, store, dialog, this.schema, commonApiService, this.featureFlagService);
    this.canOpenIps = this.permissionsService.can('ips_agreements');
  }

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'buttonsGroup',
            config: {
              emitEvent: (action: any) => {
                this.store.dispatch(new fromRoot.CleanLoading());
                switch (action) {
                  case 'SUPPORT_CLAIMS':
                    {
                      this.store.pipe(select(fromRoot.getCounterClaimWork), take(1)).subscribe(counterClaim =>
                        this.store.dispatch(
                          new fromRoot.Go({
                            path: [URL_CONFLICTS.support],
                            query: { counterclaimId: counterClaim.id },
                          }),
                        ),
                      );
                    }
                    break;
                  case 'REDUCE_CLAIMS':
                    this.dialogCounterClaimActionsBuilder.openDialog(CounterClaimActionDialog.Reduce);
                    break;
                  case 'WITHDRAW_CLAIMS':
                    this.dialogCounterClaimActionsBuilder.openDialog(CounterClaimActionDialog.Withdraw);
                    break;
                  case 'RESOLVE_COUNTERCLAIM':
                    this.dialogCounterClaimActionsBuilder.openDialog(CounterClaimActionDialog.Resolve);
                }
              },
              config: {
                type: 'matBtn',
                layout: 'row',
                customClass: 'setup-counterclaim-button-wrapper',
                buttons: [
                  {
                    color: 'accent',
                    label: `BT_SUPPORT_CLAIMS`,
                    action: 'SUPPORT_CLAIMS',
                    disabled: this.isThisButtonDisabled(this.canSupport, false, false),
                    isHidden: of(!this.canViewActionButtons),
                  },
                  {
                    color: 'accent',
                    label: `BT_REDUCE_CLAIMS`,
                    action: 'REDUCE_CLAIMS',
                    disabled: this.isThisButtonDisabled(this.canReduce, true, false),
                    isHidden: of(!this.canViewActionButtons),
                  },
                  {
                    color: 'accent',
                    label: `BT_WITHDRAW_CLAIMS`,
                    action: 'WITHDRAW_CLAIMS',
                    disabled: this.isThisButtonDisabled(this.canWithdraw, true, true),
                    isHidden: of(!this.canViewActionButtons),
                  },
                  {
                    color: 'accent',
                    label: `BT_RESOLVE_COUNTERCLAIM`,
                    action: 'RESOLVE_COUNTERCLAIM',
                    disabled: this.isThisButtonDisabled(this.canResolve, true, true),
                    isHidden: of(!this.canViewActionButtons),
                  },
                ],
              },
            },
          },
        ],
      },
      {
        group: [
          {
            type: 'formly',
            config: {
              model: this.store.pipe(
                select(fromRoot.getCounterClaimWork),
                filter(counterClaimWork => !!counterClaimWork),
                distinctUntilChanged((prevCounter, currCounter) => prevCounter.territoriesLabel === currCounter.territoriesLabel && currCounter.status === prevCounter.status),
                map(counterclaimWork => {
                  return cloneDeep(counterclaimWork);
                }),
              ),
              resetAvailable: false,
              submitAvailable: false,
              formBuilder: this.getFormly(),
            },
          },
        ],
      },
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('COUNTER.COUNTERCLAIM_PARTIES'),
              model: this.store.select(fromRoot.getCounterClaimWork).pipe(
                filter(counterClaimWork => !!counterClaimWork && !!counterClaimWork.workClaims),
                map(counterClaimWork =>
                  ClaimantUtils.getCounterClaimsRelatedParties(
                    ClaimantUtils.getCounterClaimParties(counterClaimWork.workClaims, counterClaimWork.participants, counterClaimWork.actions, this.translate),
                    counterClaimWork.participants,
                    this.translate,
                  ),
                ),
              ),
              dontStrip: true,
              customClass: 'claim-graph',
              sorts: [],
              schema: this.schema.getDataTable(
                null,
                null,
                null,
                null,
                null,
                row => this.goToIp(row),
                null,
                row => this.dialogCounterClaimActionsBuilder.openDialog(CounterClaimActionDialog.View, row),
              ),
              onMouseSelect: event => this.dialogCounterClaimActionsBuilder.openIpOnNewTab(event),
              visibleColumns: of(['relation', 'role', 'name', 'refLabel', 'resolutionOwnerLabel', 'actionType', 'actionDeadline', 'actionResponse', 'actionStatus', 'viewInfo']),
              loadingIndicator: false,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: true,
              shadowed: false,
              columnMode: 'flex',
              hide: this.flagShowMergedTable,
            },
          },
        ],
      },
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('COUNTER.ASSOCIATED_CLAIMS'),
              model: this.store.select(fromRoot.getCounterClaimWork).pipe(
                filter(counterClaimWork => !!counterClaimWork && !!counterClaimWork.workClaims),
                map(counterClaimWork => ClaimantUtils.getRelatedParties(counterClaimWork.workClaims, counterClaimWork.participants, counterClaimWork.actions, this.translate)),
              ),
              dontStrip: true,
              customClass: 'claim-graph',
              sorts: [],
              schema: this.schema.getDataTable(null, null, null, null, null, row => this.goToIp(row)),
              onMouseSelect: event => this.dialogCounterClaimActionsBuilder.openIpOnNewTab(event),
              visibleColumns: of(['relation', 'role', 'name', 'prMr', 'startDate', 'endDate', 'postTermCollectionDate', 'territoriesTisa', 'allRights', 'status', 'pr', 'mr']),
              loadingIndicator: false,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: true,
              shadowed: false,
              columnMode: 'flex',
              hide: this.flagShowMergedTable,
            },
          },
        ],
      },
      {
        group: [
          {
            type: 'buttonsGroup',
            config: {
              emitEvent: (action: any) => {
                switch (action) {
                  case 'EXPAND_ROWS':
                    {
                      this.expandTableSbj.next(true);
                    }
                    break;
                  case 'COLLAPSE_ROWS':
                    {
                      this.expandTableSbj.next(false);
                    }
                    break;
                }
              },
              config: {
                type: 'matStrBtn',
                layout: 'row',
                customClass: 'counterclaim-table-button-wrapper',
                buttons: [
                  {
                    color: '',
                    label: `COUNTER.EXPAND_ROWS`,
                    action: 'EXPAND_ROWS',
                    className: 'extraButtonAbove',
                    icon: 'expand_more',
                    iconClassName: 'cc_expand_icon',
                    isHidden: combineLatest([this.flagShowMergedTable, this.expandTableSbj.asObservable()]).pipe(map(([flag, expanded]) => !flag || expanded)),
                  },
                  {
                    color: '',
                    label: `COUNTER.COLLAPSE_ROWS`,
                    action: 'COLLAPSE_ROWS',
                    className: 'extraButtonAbove',
                    icon: 'expand_less',
                    iconClassName: 'cc_expand_icon',
                    isHidden: combineLatest([this.flagShowMergedTable, this.expandTableSbj.asObservable()]).pipe(map(([flag, expanded]) => !flag || !expanded)),
                  },
                ],
              },
            },
          },
        ],
      },
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              expandAllRows: this.expandTableSbj.asObservable(),
              title: this.translate.instant('COUNTER.COUNTERCLAIM_PARTIES_AND_CLAIMS'),
              customClass: 'merged-table',
              expandRowsEnabled: true,
              expandableProperty: of('associatedClaims'),
              detailVisibleColumns: of([
                'relation',
                'role',
                'name',
                'prMr',
                'startDate',
                'endDate',
                'postTermCollectionDate',
                'territoriesTisa',
                'allRights',
                'status',
                'pr',
                'mr',
              ]),
              rowIdentity: row => row.sortProperty,
              expandableSchema: {
                associatedClaims: this.schema.getCounterClaimDetailsDataTable({
                  clickIPINumber: row => this.goToIp(row),
                }),
              },
              model: this.store.select(fromRoot.getCounterClaimWork).pipe(
                filter(counterClaimWork => !!counterClaimWork && !!counterClaimWork.workClaims),
                map(counterClaimWork =>
                  ClaimantUtils.getCounterClaimsRelatedParties(
                    ClaimantUtils.getCounterClaimParties(counterClaimWork.workClaims, counterClaimWork.participants, counterClaimWork.actions, this.translate),
                    counterClaimWork.participants,
                    this.translate,
                    true,
                    ClaimantUtils.translateActionsList(get(counterClaimWork, 'actionsList', []), this.translate),
                  ),
                ),
              ),
              dontStrip: true,
              schema: this.schema.getCounterClaimDetailsDataTable({
                clickIPINumber: row => this.goToIp(row),
                viewClaims: row => this.dialogCounterClaimActionsBuilder.openDialog(CounterClaimActionDialog.View, row),
              }),
              onMouseSelect: event => this.dialogCounterClaimActionsBuilder.openIpOnNewTab(event),
              visibleColumns: of(['relation', 'role', 'name', 'refLabel', 'resolutionOwnerLabel', 'actionType', 'actionResponse', 'actionDeadline', 'actionStatus', 'viewInfo']),
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              columnMode: 'flex',
              hide: this.flagShowMergedTable.pipe(map(value => !value)),
              shouldAddParentRowClass: true,
            },
          },
        ],
      },
    ];
  }

  isThisButtonDisabled(canDoThisAction, checkActions, onlyResolvers) {
    return canDoThisAction
      ? this.store.select(fromRoot.getCounterClaimWork).pipe(
          withLatestFrom(this.store.select(fromRoot.getUserCurrentOrganization)),
          map(
            ([counterClaimWork, currentOrganization]) =>
              !counterClaimWork ||
              !counterClaimWork.workClaims ||
              counterClaimWork.status === CC_STATUS_RESOLVED ||
              (checkActions &&
                isEmpty(ClaimantUtils.getUserAllowedCounterClaimActions(counterClaimWork.actions, counterClaimWork.participants, currentOrganization, onlyResolvers))),
          ),
        )
      : of(true);
  }

  goToIp(row) {
    if (!this.canOpenIps) {
      return;
    }
    this.store.dispatch(new SearchReset('counter-claims'));
    this.store.dispatch(
      new fromRoot.Go({
        path: [`${SectionsConfig.IPS.domainName}/${SectionsConfig.IPS.name}/${row.ref || row.key}`],
      }),
    );
  }

  private getFormly() {
    return of([
      fieldConfig([
        fieldInput('typeLabel', this.translate.instant('COUNTER.FIELD_TYPE')),
        fieldInput('pointOfConflict', this.translate.instant('COUNTER.FIELD_POINT')),
        fieldInput('status', this.translate.instant('COUNTER.FIELD_STATUS')),
      ]),
      fieldConfig([
        fieldInput('createdDate', this.translate.instant('COUNTER.FIELD_CREATION_DATE')),
        {
          className: 'flex-1',
          key: 'resolutionOwners',
          type: 'ice-input',
          templateOptions: {
            type: 'text',
            label: this.translate.instant('COUNTER.FIELD_OWNER'),
            placeholder: this.translate.instant('COUNTER.FIELD_OWNER'),
            required: false,
            disabled: true,
            tooltipDuration: 250,
            tooltipText: 'resolutionOwnersTooltip',
            tooltipField: 'resolutionOwnersTooltip',
          },
          hideExpression: model => false,
        },
        {
          className: 'flex-1',
          key: 'territoriesLabel',
          type: 'ice-input',
          templateOptions: {
            type: 'text',
            label: this.translate.instant('COUNTER.FIELD_TERRITORIES'),
            placeholder: this.translate.instant('COUNTER.FIELD_TERRITORIES'),
            required: false,
            disabled: true,
            tooltipField: 'territoriesTooltipWithoutHtml',
            tooltipDuration: 250,
            tooltipText: model =>
              TerritoryUtils.getTerritoriesNamesTooltipTextWithoutHtml(TerritoryUtils.convertTerritoryArrayElements(get(model, 'territories', []), TerritoryDataType.NAME)),
          },
          hideExpression: model => !!this.editMode,
        },
        {
          className: 'flex-2',
          key: 'territories',
          type: 'ice-select',
          defaultValue: '',
          hideExpression: model => !this.editMode,
          modelOptions: {
            updateOn: 'blur',
          },
          templateOptions: {
            placeholder: this.translate.instant('COUNTER.FIELD_TERRITORIES'),
            label: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.FIELD_TERRITORY'),
            required: true,
            multiple: true,
            selectAllLabel: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.ALL_TERRITORIES'),
            selectAllOption: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.SELECT_ALL'),
            options: TerritoryUtils.getIceCountryGroups(),
            useCountryShortcuts: true,
            filterActive: true,
            filterPlaceHolder: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.FILTER_PLACEHOLDER'),
            clearAllLabel: this.translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.FILTER.CLEAR_ALL'),
            change: (field, $event) => {
              TerritoryUtils.cleanTerritorySelectorValue(field, $event.value, $event.lastValue);
              this.store.dispatch(new fromRoot.UpdateField({ object: 'territories', newValue: field.formControl.value, type: 'edit' }));
            },
            componentVersion: 1,
          },
          hooks: {
            onInit: field => {
              this.store.pipe(select(fromRoot.getEditModeState), takeUntil(this.unsubscribeAll)).subscribe(editMode => {
                this.editMode = editMode && editMode.editing;
                this.fieldValidatorService.checkFormlyExpressions();
              });
            },
            onDestroy: field => {
              this.unsubscribeAll.next();
              this.unsubscribeAll.complete();
              this.expandTableSbj.complete();
            },
          },
        },
      ]),
    ]);
  }
}
