import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IceMaterialModule } from '@ice/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { CookiesComponent } from './cookies.component';

@NgModule({
  declarations: [CookiesComponent],
  imports: [CommonModule, IceMaterialModule, TranslateModule],
  exports: [CommonModule, CookiesComponent],
})
export class CookiesModule {}
