import { createSelector } from '@ngrx/store';
import * as fromFeature from 'store/root/reducers';

import { cloneDeep } from 'lodash';
import { OrganizationDetail } from 'models/copyright/detail/organizations';
import * as fromReducer from 'store/root/reducers/copyright/copyright.reducer';

export const getUserManagementOrganization = createSelector(fromFeature.getCopyrightFeatureState, fromReducer.getUserManagementOrganizationsState);

export const getUserManagementOrganizationAttributes = createSelector(getUserManagementOrganization, (detail: OrganizationDetail) =>
  detail && detail.attributes ? cloneDeep(detail.attributes) : {},
);

export const getUserManagementOrganizationRoles = createSelector(getUserManagementOrganization, (detail: OrganizationDetail) => (detail && detail.roles ? detail.roles : []));

export const getUserManagementOrganizationPartyNames = createSelector(getUserManagementOrganization, (detail: OrganizationDetail) =>
  detail && detail.partyNames ? detail.partyNames : [],
);

export const getUserManagementOrganizationUsers = createSelector(getUserManagementOrganization, (detail: OrganizationDetail) => (detail && detail.users ? detail.users : []));
