import { BehaviorSubject, of, Subscription } from 'rxjs';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';

import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IpUtils } from '@ice';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { FormlyValidatorUtils } from '@ice/utils/formly/formly-validators.utils';
import { fieldRepeaterValidator } from '@ice/utils/formly/formly.utils';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

import { DialogInfo } from './dialog-info';

export class DialogAddByAgreementId {
  private dialogRef: MatDialogRef<DialogMultiLayoutComponent>;
  private fieldsToAgreementIds: Map<string, string>;
  private manualAddDisabled$: BehaviorSubject<Boolean> = new BehaviorSubject(false);
  private agreementsField: FormlyFieldConfig;

  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private store: Store<fromRoot.RootState>,
    private fieldValidatorService: FieldValidatorService,
    private labels: Map<string, string>,
  ) {}

  openDialog(onSubmit: (id) => void, initialValue = new BehaviorSubject({ agreements: [] })) {
    this.fieldsToAgreementIds = new Map<string, string>();
    this.manualAddDisabled$.next(false);
    this.dialogRef = this.dialog.open(DialogMultiLayoutComponent, {
      panelClass: 'overflow-hidden',
      data: {
        loading: this.store.select(fromRoot.getCopyrightLoading),
        layouts: [this.getAddByIdLayout(onSubmit, initialValue)],
      },
    });
    return this.dialogRef;
  }

  getDialogConfigByModel() {
    return {
      title: of(this.translate.instant('ADD_BY_ID_DIALOG.AGREEMENT.TITLE')),
      placeholder: `${this.translate.instant('ADD_BY_ID_DIALOG.COMMON.ADD')} ${this.translate.instant('ADD_BY_ID_DIALOG.AGREEMENT.TITLE')}`,
      validator: this.getAgreementValidator(),
    };
  }

  getAddByIdLayout(onSubmit: (id) => void, initialValue) {
    const isFormValid = new BehaviorSubject(false);
    const dialogConfigBySection = this.getDialogConfigByModel();

    const manualAddDisabled$ = this.manualAddDisabled$.asObservable();
    let subscriptions: Subscription[] = [];

    return {
      title: dialogConfigBySection.title,
      className: 'dialog-wrapper-width-50vw',
      forceSubmitDisabled: true,
      layout: [
        {
          group: [
            {
              type: 'formly',
              config: {
                setValidForm: valid => isFormValid.next(!valid),
                formBuilder: of([
                  {
                    className: 'flex-1 ice-no-underline',
                    type: 'label',
                    defaultValue: this.translate.instant(this.labels.get('addManuallyLabel')),
                    key: 'type',
                    templateOptions: { class: 'ice-bold' },
                    hooks: {
                      onInit: field => {
                        const sub = manualAddDisabled$.subscribe(disabled => {
                          field.hide = disabled;
                        });
                        subscriptions.push(sub);
                      },
                      onDestroy: () => {
                        subscriptions.forEach(sub => sub.unsubscribe());
                        subscriptions = [];
                      },
                    },
                  },
                  {
                    key: 'agreements',
                    type: 'repeat',
                    modelOptions: {
                      updateOn: 'blur',
                    },
                    hooks: {
                      onInit: field => {
                        this.agreementsField = field;
                        const sub = manualAddDisabled$.subscribe(disabled => {
                          field.templateOptions.disabled = disabled;
                        });
                        subscriptions.push(sub);
                      },
                      onDestroy: () => {
                        subscriptions.forEach(sub => sub.unsubscribe());
                        subscriptions = [];
                      },
                    },
                    templateOptions: {
                      noScroll: false,
                      onRemove: value => {
                        this.fieldsToAgreementIds.delete(value.fieldGroup[0].id);
                      },
                    },
                    fieldArray: {
                      fieldGroupClassName: 'display-flex',
                      fieldGroup: [
                        {
                          className: 'ice-w-50 ice-mr-10',
                          key: 'agreementId',
                          type: 'input',
                          wrappers: ['form-field', 'wrapper-input-text'],
                          modelOptions: {
                            updateOn: 'blur',
                          },
                          templateOptions: {
                            type: 'text',
                            attributes: {
                              'data-testid': 'agreement-id',
                            },
                            placeholder: dialogConfigBySection.placeholder,
                            required: true,
                            change: (field, $event) => {
                              FormlyValidatorUtils.checkRepeaterDuplicates(field.formControl, 'agreementsErrorValidator', 'agreementId');
                              FormlyValidatorUtils.checkRepeaterMinimum1(field.formControl, 'agreementsErrorValidator');
                            },
                          },
                          hooks: {
                            onDestroy: () => FormlyValidatorUtils.checkRepeaterDuplicatesOnDestroy(this.agreementsField.formControl, 'agreementsErrorValidator', 'agreementId'),
                          },
                          asyncValidators: this.getAgreementValidator(),
                        },
                      ],
                    },
                  },
                  fieldRepeaterValidator('agreementsErrorValidator', this.translate),
                ]),
                model: initialValue,
                submitEnabled: true,
                submitAvailable: 'true',
                submitLabel: this.translate.instant('POPUP.CONFIRM'),
                button1Enabled: true,
                button1Available: 'true',
                button1Label: this.translate.instant('POPUP.CANCEL'),
                submit: () => {
                  onSubmit([...this.fieldsToAgreementIds.values()]);
                  this.dialogRef.close();
                },
                onButton1: () => this.showDialogCancel(),
              },
            },
          ],
        },
      ],
    };
  }

  showDialogCancel() {
    const popupTitle = this.translate.instant(this.labels.get('cancelDialogTitle'));
    const popupMsg = this.translate.instant(this.labels.get('cancelDialogMessage'));

    const dialogInfo = new DialogInfo(this.translate, this.dialog, this.store);
    const dialogRefInfo = dialogInfo.openDialog(
      popupTitle,
      popupMsg,
      () => {
        dialogRefInfo.close();
        this.dialogRef.close();
      },
      () => {
        dialogRefInfo.close();
      },
    );
  }

  openInfoDialog(message: string) {
    this.dialogRef = this.dialog.open(DialogMultiLayoutComponent, {
      disableClose: true,
      data: {
        className: 'dialogLoading',
        layouts: [this.getLayoutResults(message)],
        loading: this.store.select(fromRoot.getCopyrightLoading),
      },
    });
  }

  getLayoutResults(message: string) {
    return {
      title: of(this.translate.instant('POPUP.RESULTS')),
      layout: [
        {
          group: [
            {
              type: 'response-error',
              config: {
                response: of({ text: message }),
                responseButtons: of([
                  {
                    text: of(this.translate.instant('POPUP.CLOSE')),
                    action: () => {
                      this.store.dispatch(new fromRoot.RefreshDetail());
                      this.dialogRef.close();
                    },
                  },
                ]),
                errors: of(null),
                errorButtons: of([
                  {
                    text: of(this.translate.instant('POPUP.CLOSE')),
                    action: () => {
                      this.store.dispatch(new fromRoot.RefreshDetail());
                      this.dialogRef.close();
                    },
                  },
                ]),
              },
            },
          ],
        },
      ],
    };
  }

  getAgreementValidator() {
    return {
      agreement_validation: {
        expression: async (control: FormControl, field) => {
          return new Promise(resolve => {
            this.fieldValidatorService.validAgreementKey(
              control,
              (agreement: any) => {
                if (agreement) {
                  const agreementIceId = IpUtils.selectIpsKey(agreement?.relations, agreement.id);
                  this.fieldsToAgreementIds.set(field.id, agreementIceId);
                  resolve(true);
                } else {
                  resolve(false);
                }
              },
              () => {
                resolve(false);
              },
            );
          });
        },
        message: this.translate.instant('ADD_BY_ID_DIALOG.AGREEMENT.ERROR'),
      },
    };
  }
}
