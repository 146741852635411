import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '@ice/i18n/en/button-labels';
import { locale as englishUiMessages } from '@ice/i18n/en/ui-messages';
import { FieldArrayType } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { findIndex, last } from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ice-formly-repeat',
  templateUrl: './repeat-type.component.html',
  styleUrls: ['./repeat-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RepeatTypeComponent extends FieldArrayType implements OnInit, OnDestroy {
  addButtonText: string;
  removeButtonText: string;
  customError: string;
  findValues$ = new Subject();
  unsubscribeAll$ = new Subject();
  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;

  constructor(private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService, private chDet: ChangeDetectorRef) {
    super();
    this.fuseTranslationLoader.loadTranslations(english);
    this.fuseTranslationLoader.loadTranslations(englishUiMessages);
    this.addButtonText = this.translate.instant('ADD_BUTTON');
    this.removeButtonText = this.translate.instant('REMOVE_BUTTON');
  }

  ngOnInit() {
    this.field.formControl.statusChanges.pipe(takeUntil(this.unsubscribeAll$)).subscribe(values => {
      if (!this.field.templateOptions.noScroll) {
        this.chDet.detectChanges();
      }
    });
    this.findValues$
      .pipe(
        filter((res: any) => res.length > 1),
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.unsubscribeAll$),
      )
      .subscribe(values => {
        const findValueIndex = findIndex(this.field.fieldGroup || [], field => {
          return JSON.stringify(field.formControl.value).includes(values);
        });
        if (!this.field.templateOptions.noScroll && findValueIndex !== -1) {
          requestAnimationFrame(() => {
            this.viewport.scrollToIndex(findValueIndex, 'smooth');
          });
        }
      });
    if (this.field.templateOptions['addButtonText']) {
      this.addButtonText = this.field.templateOptions['addButtonText'];
    }
    if (this.field.templateOptions['removeButtonText']) {
      this.removeButtonText = this.field.templateOptions['removeButtonText'];
    }

    this.to.removeAll = () => this.removeAllItems();
  }

  findValues(event) {
    this.findValues$.next(event.target.value);
  }

  removeItem(i, field?) {
    this.remove(i);
    if (this.to.onRemove) {
      if (field) {
        this.to.onRemove(field);
      } else {
        this.to.onRemove();
      }
    }
    setTimeout(() => {
      this.field.fieldGroup = [...this.field.fieldGroup];
      this.field.formControl.markAsTouched();
      this.field.formControl.updateValueAndValidity();
      if (!this.chDet['destroyed']) {
        this.chDet.detectChanges();
      }
    }, 100);
  }

  ngOnDestroy() {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }

  showRepeaterError() {
    const errorIndex = findIndex(this.field.fieldGroup || [], field => {
      return field.formControl.invalid;
    });
    if (!this.field.templateOptions.noScroll && errorIndex !== -1) {
      requestAnimationFrame(() => {
        this.viewport.scrollToIndex(errorIndex, 'smooth');
      });
    }
  }

  addItem() {
    this.customError = '';
    if ((['VALID', 'PENDING'].includes(this.field.formControl.status) && this.isRepeaterNotEmpty()) || this.field.fieldGroup.length === 0) {
      this.add();
    } else {
      this.customError = this.translate.instant('ERROR.DATATABLE.NO_EMPTY_ROWS');
    }
    if (!this.field.templateOptions.noScroll) {
      this.field.fieldGroup = [...this.field.fieldGroup];
      if (!this.chDet['destroyed']) {
        this.chDet.detectChanges();
      }
      requestAnimationFrame(() => {
        this.viewport.scrollToIndex(this.field.fieldGroup.length + 1, 'smooth');
      });
    }
    if (this.to.onAdd) {
      this.to.onAdd();
    }
  }

  isRepeaterNotEmpty() {
    let isNotEmpty = true;
    const model = this.formControl.value;
    model.forEach(item => {
      if (!Object.values(item).some(value => value && value !== '')) {
        isNotEmpty = false;
      }
    });
    return isNotEmpty;
  }

  isLastRowEmpty() {
    const currentValue = this.formControl.value;
    const lastRow = last(currentValue);
    return !Object.values(lastRow).some(value => value && value !== '');
  }

  getVirtualScrollHeight() {
    const elements = this.field && this.field.model && this.field.model.length;
    if (elements) {
      switch (elements) {
        case 0:
        case 1:
          return '80';
        case 2:
          return '150';
      }
    }
    return '210';
  }

  removeAllItems() {
    while (this.field.fieldGroup.length > 0) {
      this.removeItem(0);
    }
  }
}
