import { ApiNamespace, CopyrightDetail } from '@ice';
import { PageableData } from 'models/response/cube-register.response';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { IconInterface } from './icon';

export interface Ip {
  id: string;
  baseId?: string;
  baseIpiNumber?: string;
  fullName?: string;
  ipiNameNumber?: string;
  key?: string;
  baseKey?: string;
  nameKey?: string;
  typeOfName?: string;
  tooltipTypeOfName?: string;
  typeOf?: string;
  typeOfIcon?: IconInterface[];
  status?: string;
  statusLiteral?: string;
  mrSociety?: string;
  mrSocietyCodeAndName?: string;
  mrSocietyIsIce?: boolean;
  mrSocietyIcons?: IconInterface[];
  prSociety?: string;
  prSocietyCodeAndName?: string;
  prSocietyIsIce?: boolean;
  prSocietyIcons?: IconInterface[];
  societyCodes?: string;
  works?: string;
  dataType?: string;
  ns: ApiNamespace | string;
  version?: number;
  name?: string;
  firstName?: string;
  clauses?: string[];
  names?: IpAttributesName[];
  role?: string;
  partyId?: string;
  partyNameId?: string;
  baseIpiNumbersFormatted?: string;
  baseIpiNumbersIcons?: any;
  rawItem?: any;
  agreementCount?: number;
  workCount?: number;
  baseParty?: any;
}

export interface IpAttributesName {
  name: string;
  firstName: string;
  language: string;
  nameId: number;
}

export interface IpClause {
  type: string;
  name?: string;
  workRef?: string;
  workTitle?: string;
  clauseType: string;
}

export interface TransferIpWorksInfo {
  sourceIp?: any;
  targetIp?: any;
  worksList?: any[];
  sourceIpWorks?: any[];
  sourceIpWorksFilter?: any;
}

export interface IpDetail extends CopyrightDetail {
  agg?: IpAgg;
  attributes?: Ip;
  admin?: any;
  parties?: any;
  baseInformation?: IpBaseInformation[];
  partyNames?: IpsNames[];
  relations?: IpRelation[];
  societies?: IpSocietyRelation[];
  partyRelationsSocieties?: IpSocietyRelation[];
  partyRelationsPublishers?: IpSocietyRelation[];
  alternativeNames?: IpAlternativeName[];
  auditHistory?: PageableData<any>;
  auditHistoryFilter?: any;
  selectedAuditItem?: string;
  relationsSearch?: any;
  relationsSearchResult?: any;
  parentRelations?: any[];
  parentRelationshipsData?: PageableData<any>;
  childrenRelationshipsData?: PageableData<any>;
  workClauses?: PageableData<IpClause>;
  linkedPublishers?: any[];
  linkedWriters?: any[];
  agreementCount?: number;
  workCount?: number;
  linkedWritersCount?: number;
  linkedPublishersCount?: number;
  workClausesCount?: number;
  mergingIP?: boolean;
  transferIpWorksInfo?: TransferIpWorksInfo;
  baseParty?: any;
}

export const agreementChainsAdapter = createEntityAdapter<any>();
export interface AgreementItem {
  id: string;
  version: number;
  attributes: any;
  assignor: any;
  assignee: any;
  relations: {
    otherId: string;
    ns: string;
    agreementId: string;
    version: number;
    relation: string;
    createdAtMs: number;
  }[];
  aggregations: any;
  depth?: number;
  total?: number;
  parent?: string;
  parentAgreementIds?: string[];
}
export type TableMap = Record<string, number>;

export interface AgreementChains extends EntityState<any> {
  inbox: { items: AgreementItem[] };
  tableMap: TableMap;
  tableData: AgreementItem['id'][];
}
export const initialAgreementChainsState: AgreementChains = agreementChainsAdapter.getInitialState({
  inbox: { items: [] },
  tableMap: {},
  tableData: [],
});

export type IpDetailCleaned = Pick<
  IpDetail,
  | 'id'
  | 'version'
  | 'attributes'
  | 'baseInformation'
  | 'societies'
  | 'partyNames'
  | 'parties'
  | 'partyRelationsSocieties'
  | 'partyRelationsPublishers'
  | 'relationsSearch'
  | 'relationsSearchResult'
  | 'parentRelationshipsData'
  | 'childrenRelationshipsData'
  | 'admin'
  | 'linkedWritersCount'
  | 'linkedPublishersCount'
  | 'auditHistory'
  | 'workClausesCount'
  | 'mergingIP'
  | 'baseParty'
> & {
  works?: PageableData<any>;
  workClauses?: PageableData<any[]>;
  agreements?: PageableData<any>;
  agreementChains?: AgreementChains;
};

export interface IpsNames {
  ns?: ApiNamespace;
  id: string;
  version?: number;
  name?: string;
  nameKey?: string;
  typeOf: string;
  typeOfName: string;
  typeOfNameTooltip?: string;
  tags?: object;
  key?: string;
  fullName?: string;
  dataType?: string;
  ipiNameNumber?: string;
  rowClass?: string;
  works?: number;
  agreements?: number;
  typeOfIcon?: IconInterface[];
  worksTooltipText?: string;
  agreementsTooltipText?: string;
}

export interface IpSociety {
  societyNumber: string;
  societyName: string;
  validFrom: string;
  validTo: string;
}

export interface IpAlternativeName {
  name: string;
  firstName: string;
  language: string;
}

export interface IpBaseInformation {
  baseId: string;
  baseIPINumber: string;
  name: string;
  typeOf: string;
  status: string;
  sex: string;
  dateOfBirth: string;
  dateOfFoundation?: string;
  countryOfBirth?: string;
  placeOfBirth?: string;
  dateOfDeath?: string;
  dateOfDissolution?: string;
  baseIdWithPrefix?: string;
}

export interface IpSocietyRelation {
  otherId?: string;
  societyNumber?: string;
  societyName?: string;
  societyIPReference?: string;
  creationClass?: string;
  creationClassTooltip?: string;
  share?: string;
  territories?: string;
  territoriesText?: string;
  territoriesTooltip?: string;
  territoriesIcon?: IconInterface[];
  validFrom?: string;
  validTo?: string;
  roleCodes?: string;
  roleCodesTooltip?: string;
  rightsCodes?: string;
  rightsCodesTooltip?: string;
  roleCodesRaw?: any;
}

export interface IpRelation {
  ns?: string;
  partyId?: string;
  partyNameId?: string;
  version?: number;
  otherId: string;
  otherNameId?: string;
  relation: string;
}

// Model to create new IPs in Agreement NEW/UPDATE
export interface PutNewIpPayload {
  id?: string;
  firstName?: string;
  name?: string;
  dateOfBirth?: string;
  rawItem?: any;
  dateOfDeath?: string;
  creationClass: string;
  status: string;
  typeOf?: string;
  // Legal entity or Natural Personal
  baseType?: string;
  submitterIPI?: string;
  getIpDetail?: boolean;
}

export interface IpAgg {
  id: string;
  version: number;
  attributes?: IpAggAttributes;
}

export interface IpAggAttributes {
  id: string;
  version: number;
  agreementCount: number;
  workCount: number;
  linkedWritersCount: number;
  linkedPublishersCount: number;
}
