import { environment } from 'config/env';
import { SectionRouterViews } from './section-router-views';
import type { SectionConfig } from './sections-config';

export const SectionConflictsCounterclaimNotifications: SectionConfig = {
  name: 'counterclaim-notifications',
  url: '/conflicts/counterclaim-notifications/new',
  domainName: 'conflicts',
  baseUrl: environment.apiUrl,
  baseUrlCubeData: environment.apiUrlCubeData,
  apiSegment: 'counterclaim-notifications',
  minCharsToSearch: 5,
  apiIncludes: {
    search: '',
    detail: '',
  },
  homeRedirect: 'new',
  searchServerSideSortDefault: '',
  allowedActions: {
    newItem: false,
    editItem: null,
    newNote: false,
    removeItem: false,
  },
  newItemBtn: [SectionRouterViews.new],
};
