import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '@ice/i18n/en/expansion-list';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CardWithDataTable } from '../card-with-data-table/card-with-data-table';

@Component({
  selector: 'ice-expansion-list-data-table',
  templateUrl: './expansion-list-data-table.component.html',
  styleUrls: ['./expansion-list-data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpansionListDataTableComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() className: string;
  @Input() model: CardWithDataTable;
  @Input() extraData: any;
  @Input() buttonTitle: string;
  @Input() buttonTitleTooltip: string;
  @Input() iconConflict: Observable<string>;
  @Input() tooltipIconConflict: Observable<string>;
  @Input() expanded: Observable<Boolean>;
  @Input() selectable = false;
  @Input() filterItemsOn = 10; // Number of items to show the filter On.
  @Input() maxHeight = 300;
  @Input() listIdentifier: string;
  @Input() height;
  @Input() showTotal = true;
  @Input() singleSelection = false;
  @Output() selectedCheckboxEmit = new EventEmitter<any>();
  @Output() newTab: EventEmitter<any> = new EventEmitter<any>();
  @Output() tableResized = new EventEmitter<any>();
  @Output() selectedItemEmit = new EventEmitter<any>();
  @Output() mouseWheelClickRowEmit = new EventEmitter<any>();
  @Output() open: EventEmitter<any> = new EventEmitter<any>();
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedRowEmit = new EventEmitter<any>();
  @Output() mouseWheelClickDataTableRowEmit = new EventEmitter<any>();

  filterValue = '';
  public totalElemsCount: string;
  private modelSubscriptor: Subscription;

  constructor(private fuseTranslationLoader: FuseTranslationLoaderService) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  ngOnInit() {
    this.modelSubscriptor = this.model.model.subscribe(res => {
      if (res) {
        this.totalElemsCount = `(${res.length})`;
      }
    });
  }

  ngOnDestroy() {
    this.modelSubscriptor.unsubscribe();
  }

  clickHeaderButton(ev: any, data: any): void {
    ev.stopPropagation();
    ev.preventDefault();
    if (data && data.workId) {
      data.workId.pipe(take(1)).subscribe((workId: string) => this.newTab.emit(workId));
    }
  }

  clear(event) {
    event.stopPropagation();
    event.preventDefault();
    this.filterValue = '';
  }

  onTableResized(event: any) {
    this.tableResized.emit(true);
  }

  onSelect(item) {
    if (this.selectable) {
      this.selectedItemEmit.emit(item.key);
    }
  }

  onSelectedCheckboxEmit(event) {
    if (event) {
      this.selectedCheckboxEmit.emit(event);
    }
  }

  onMouseWheelClickSelect(event, item) {
    if (event && event.which === 2 && item) {
      this.mouseWheelClickRowEmit.emit(item);
    }
  }

  onOpened() {
    if (this.open) {
      this.open.emit();
    }
  }

  onClosed() {
    if (this.close) {
      this.close.emit();
    }
  }

  selectDataTableItem(event) {
    if (event) {
      this.selectedRowEmit.emit(event);
    }
  }

  onMouseWheelClickDataTableSelect(event) {
    if (event) {
      this.mouseWheelClickDataTableRowEmit.emit(event);
    }
  }
}
