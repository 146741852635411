import { FormlyFieldConfig } from '@ngx-formly/core';
import { difference, filter, isEmpty, keys, mapKeys, pick } from 'lodash';
import { Observable, of } from 'rxjs';
import type { SearchService } from 'services/search/search.service';

export interface FormConfig {
  getForm(...params): FormlyFieldConfig[];
}

export interface FieldsToCombine {
  fields: string[];
  combinedField: string;
}

export interface SearchExpressionsInterface {
  getFieldsToCombine(): FieldsToCombine[];
  getSearchExpressionConfig(key: string, value: string, prefix: string, allUsers: any): string;
  getExtraSearchExpression(searchParameters?: any): string;
  getExtraCall(searchService: SearchService, data: any): Observable<any>;
  extraCallDataCleaner(response: any, data: any, searchObject: any): any;
}

export class SearchExpressions implements SearchExpressionsInterface {
  getFieldsToCombine(): FieldsToCombine[] {
    return [];
  }

  getExtraCall(searchService: SearchService, data: any): Observable<any> {
    return of({});
  }

  extraCallDataCleaner(response: any, data: any, searchObject: any) {
    return searchObject;
  }

  getSearchExpressionConfig(key: string, value: string, prefix: string, allUsers: any) {
    return `{"wildcard":{"${'attributes.' + key}":"*${value}*"}}`;
  }

  getExtraSearchExpression(searchParameters?: any) {
    return '{"wildcard":{"relations[XREF].otherId":"ICE:*"}}';
  }

  combineFields(searchParameters: Object) {
    const combinedParameters = searchParameters;
    const fieldsToCombine = this.getFieldsToCombine();
    if (!isEmpty(fieldsToCombine)) {
      fieldsToCombine.forEach(combination => {
        const hasAllRequieredFields =
          difference(
            combination.fields,
            filter(
              keys(mapKeys(combinedParameters, (value, key) => (value ? key : value))).filter(value => value !== 'null'),
              value => value !== 'null',
            ),
          )?.length === 0;
        if (hasAllRequieredFields) {
          combinedParameters[combination.combinedField] = pick(combinedParameters, combination.fields);
          combination.fields.forEach(fieldToCombine => delete combinedParameters[fieldToCombine]);
        }
      });
    }
    return combinedParameters;
  }

  formatExactIDSearch(idValue: string) {
    const valueArray = idValue.split(':');
    let saytPrefix = 'ICE:';
    let saytID = idValue;
    if (valueArray.length > 1) {
      saytPrefix = `${valueArray[0]}:`;
      saytID = valueArray[1];
    }
    return saytPrefix + saytID;
  }

  formatReferenceSearch(pattern: string, value: string, prefix: string, addPrefix = true) {
    const splittedValue = value && value.split(':');
    if (splittedValue && splittedValue.length > 1) {
      return pattern.replace(/<<type>>/g, 'equals').replace(/<<value>>/g, value);
    } else {
      const idSearchText = addPrefix ? `${prefix}:${value}` : `*:${value}`;
      return pattern.replace(/<<type>>/g, 'wildcard').replace(/<<value>>/g, `${idSearchText}`);
    }
  }
}
