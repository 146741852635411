import { replace } from 'lodash';
import { SearchService } from './search.service';

export class ExpertSearchCommonFunctions {
  static async agreementRefFunction(searchService: SearchService, comparator: string, value: string, deep = false, prefix = null) {
    if (deep) {
      const baseFieldToSearch = 'relations[XREF].otherId';
      const fieldToSearch = prefix ? `${prefix}.${baseFieldToSearch}` : baseFieldToSearch;
      const realValue = replace(value, '*', '').replace('*', '');
      const existAgreement = await searchService.existAgreementReference(realValue);
      const search = {};
      if (existAgreement) {
        search[fieldToSearch] = value;
      } else {
        const legacyAgreement = await searchService.existLegacyAgreementReference(realValue);
        if (legacyAgreement) {
          search[fieldToSearch] = legacyAgreement.mainId;
        } else {
          search[fieldToSearch] = value;
        }
      }
      return { [comparator]: search };
    }
  }
}
