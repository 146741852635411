import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'ice-formly-wrapper-info',
  templateUrl: './wrapper-info.component.html',
  styleUrls: ['./wrapper-info.component.scss'],
})
export class WrapperInfoComponent extends FieldWrapper {
  @ViewChild('fieldComponent', { read: ViewContainerRef, static: true }) fieldComponent: ViewContainerRef;
}
