import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DatepickerUtils } from '@ice/utils/datepicker/datepicker.utils';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/search-form-builders';
import { PUBLISHER_ROLES, REPERTOIRE_TYPES } from 'config/constants/repertoires.constants';
import { toNumber } from 'lodash';
import { FormConfig, SearchExpressions } from './form-config';

export class RepertoiresSearchExpressions extends SearchExpressions {
  getSearchExpressionConfig(key: string, value: string, prefix: string, allUsers: any) {
    switch (key) {
      case 'creatorMR':
        return `{"equals":{"societies[*:${toNumber(value)}*].rights":"MR"}}`;
      case 'creatorPR':
        return `{"equals":{"societies[*:${toNumber(value)}*].rights":"PR"}}`;
      case 'endDate':
        return `{"range":{"attributes.endDate":{"lte": "${value}"}}}`;
      case 'publisherIPIBaseNumber':
        if (prefix === PUBLISHER_ROLES.BOTH) {
          return `{"or":[{"equals":{"parties[${PUBLISHER_ROLES.CONTRACTOR}].party.relations[XREF].otherId":"IPI:${value}"}},
                         {"equals":{"parties[${PUBLISHER_ROLES.COLLECTOR}].party.relations[XREF].otherId":"IPI:${value}"}}]}`;
        }
        return `{"equals":{"parties[${prefix}].party.relations[XREF].otherId":"IPI:${value}"}}`;
      case 'publisherIPINameNumber':
        if (prefix === PUBLISHER_ROLES.BOTH) {
          return `{"or":[{"equals":{"parties[${PUBLISHER_ROLES.CONTRACTOR}].party.partyNames.partyName.relations[XREF].otherId":"IPI:${value}"}},
                         {"equals":{"parties[${PUBLISHER_ROLES.COLLECTOR}].party.partyNames.partyName.relations[XREF].otherId":"IPI:${value}"}}]}`;
        }
        return `{"equals":{"parties[${prefix}].party.partyNames.partyName.relations[XREF].otherId":"IPI:${value}"}}`;
      case 'publisherName':
        if (prefix === PUBLISHER_ROLES.BOTH) {
          return `{"or":[{"wildcard":{"parties[${PUBLISHER_ROLES.CONTRACTOR}].party.partyNames.partyName.attributes.name":"*${value}*"}},
                         {"wildcard":{"parties[${PUBLISHER_ROLES.COLLECTOR}].party.partyNames.partyName.attributes.name":"*${value}*"}}]}`;
        }
        return `{"wildcard":{"parties[${prefix}].party.partyNames.partyName.attributes.name":"*${value}*"}}`;
      case 'publisherRole':
        if (value !== PUBLISHER_ROLES.BOTH) {
          return `{"equals":{"parties.relation":"${value}"}}`;
        }
        return `{"or":[{"equals":{"parties.relation":"${PUBLISHER_ROLES.COLLECTOR}"}},{"equals":{"parties.relation":"${PUBLISHER_ROLES.CONTRACTOR}"}}]}`;
      case 'startDate':
        return `{"range":{"attributes.startDate":{"gte": "${value}"}}}`;
      case 'workKey':
        return `{"wildcard":{"works.work.relations[XREF].otherId":"*${value}*"}}`;
      case 'xref':
        return `{"or":[{"equals":{"relations[XREF].otherId":"${this.formatExactIDSearch(value)}"}}, {"equals":{"relations[DISP].otherId":"${this.formatExactIDSearch(value)}"}}]}`;
      case 'active':
        return `{"equals":{"${'attributes.' + key}":"${value}"}}`;
      case 'unpublishedWriterShares':
        return `{"equals":{"${'attributes.' + key}":"${value}"}}`;
    }
    return `{"wildcard":{"${'attributes.' + key}":"*${value}*"}}`;
  }

  getExtraSearchExpression() {
    return '';
  }
}

export class SearchCopyrightRepertoiresForm implements FormConfig {
  constructor(private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  getForm(): FormlyFieldConfig[] {
    const translate = this.translate;
    const sinceFieldConfig = DatepickerUtils.getDatepickerField({
      name: 'startDate',
      label: this.getTranslate('VALID_FROM'),
      translate,
    });
    const toFieldConfig = DatepickerUtils.getDatepickerField({
      name: 'endDate',
      label: this.getTranslate('VALID_TO'),
      translate,
    });
    DatepickerUtils.createDatepickerRange({ translate, sinceFieldConfig, toFieldConfig });
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'name',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.getTranslate('NAME'),
              required: false,
            },
          },
          {
            className: 'flex-1',
            key: 'xref',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.getTranslate('XREF'),
              required: false,
            },
          },
          sinceFieldConfig,
          toFieldConfig,
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'type',
            type: 'select',
            templateOptions: {
              label: this.getTranslate('TYPE.NAME'),
              options: [
                { label: this.getTranslate('TYPE.PUBLISHER'), value: REPERTOIRE_TYPES.PUBLISHER },
                { label: this.getTranslate('TYPE.SOCIETY'), value: REPERTOIRE_TYPES.SOCIETY },
                { label: this.getTranslate('TYPE.WORK_LIST'), value: REPERTOIRE_TYPES.WORK },
              ],
              required: false,
            },
          },
          {
            className: 'flex-1',
            key: 'creatorMR',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.getTranslate('CREATOR_MR_SOCIETY'),
              required: false,
            },
          },
          {
            className: 'flex-1',
            key: 'creatorPR',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.getTranslate('CREATOR_PR_SOCIETY'),
              required: false,
            },
          },
          {
            className: 'flex-1',
            key: 'workKey',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.getTranslate('WORK_KEY'),
              required: false,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'publisherName',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.getTranslate('PUBLISHER.NAME'),
              required: false,
            },
          },
          {
            className: 'flex-1',
            key: 'publisherIPINameNumber',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.getTranslate('PUBLISHER.IPI_NAME_NUMBER'),
              required: false,
            },
          },
          {
            className: 'flex-1',
            key: 'publisherIPIBaseNumber',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.getTranslate('PUBLISHER.IPI_BASE_NUMBER'),
              required: false,
            },
          },
          {
            className: 'flex-1',
            key: 'publisherRole',
            type: 'select',
            templateOptions: {
              label: this.getTranslate('PUBLISHER_ROLE.NAME'),
              options: [
                { label: this.getTranslate('PUBLISHER_ROLE.BOTH'), value: PUBLISHER_ROLES.BOTH },
                { label: this.getTranslate('PUBLISHER_ROLE.CONTRACTOR'), value: PUBLISHER_ROLES.CONTRACTOR },
                { label: this.getTranslate('PUBLISHER_ROLE.COLLECTOR'), value: PUBLISHER_ROLES.COLLECTOR },
              ],
              required: false,
            },
          },
          {
            className: 'flex-1',
            key: 'active',
            type: 'checkbox',
            defaultValue: false,
            templateOptions: {
              label: this.getTranslate('ACTIVE'),
              required: false,
            },
          },
          {
            className: 'flex-1',
            key: 'unpublishedWriterShares',
            type: 'checkbox',
            defaultValue: false,
            templateOptions: {
              label: this.getTranslate('UNPUBLISHED_WRITER_SHARES'),
              required: false,
            },
          },
        ],
      },
    ];
  }

  getTranslate(translationPath) {
    return this.translate.instant(`REPERTOIRES.${translationPath}`);
  }
}
