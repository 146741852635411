export const locale = {
  lang: 'en',
  data: {
    SAVE_NEW: 'Submit',
    CANCEL_NEW: 'Cancel',
    EDIT_ITEM: 'Edit',
    EXPORT: 'Export',
    EXPORT_CSV: 'Export to csv',
    EXPORT_XLSX: 'Export to xlsx',
    CLOSE_EXPORT_MODE: 'Close export mode',
    CLOSE_UPDATE_MODE: 'Close bulk update',
    BULK_UPDATE: 'Bulk Update',
    UPDATE: 'Send works to bulk update',
    ADD_BUTTON: 'Add',
    REMOVE_BUTTON: 'Remove',
    CANCEL_SELECT_MODE: 'Cancel selection',
    ASSIGN_ME: 'Assign to me',
    ASSIGN_USER: 'Assign to user',
    CHANGE_ASSIGNEE: 'Change Assignee',
    CLEAR_SELECTION: 'Clear selection',
    CONFIRM_MATCH: 'Confirm match',
    FORCE_MERGE: 'Force merge',
    DISCARD_MATCH: 'Discard match',
    EXCERPT_MATCH: 'Excerpt match',
    MODIFY_MATCH: 'Modify match',
    NO_MATCH: 'Add non-match work relation',
    SAMPLE_MATCH: 'Sample match',
    ALTERNATIVE_UPDATE: 'Alternative Update', // Default Label, change it in every section config
    TERMINATE: 'Terminate',
    CLOSE: 'Close',
    CLONE: 'Clone',
    REPORTS_NEW_BTN_TITLE: 'Create a new report',
    MORE_ACTIONS: 'More Actions',
    APP_NEEDS_UPDATE: 'Click here to update',
    MORE_OPTIONS: 'More options',
    FEWER_OPTIONS: 'Fewer options',
  },
};
