import { Component, Input } from '@angular/core';
import { DataTableTotals } from './data-table-totals';

@Component({
  selector: 'ice-data-table-totals',
  templateUrl: './data-table-totals.component.html',
})
export class DataTableTotalsComponent {
  @Input() config: DataTableTotals;
  constructor() {}
}
