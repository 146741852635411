import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { StepType } from 'config/stepper-builders/stepper-config';

export class RepertoireAdditionalFieldsStep {
  constructor(private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService, private store: Store<any>) {}

  getStep(translate: TranslateService): StepType {
    return {
      label: translate.instant('REPERTOIRES.STEPS.ADDITIONAL_FIELDS.TITLE'),
      formBuilder: [
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-1',
              key: 'notes',
              wrappers: ['form-field', 'wrapper-input-text'],
              type: 'textarea',
              modelOptions: {
                updateOn: 'blur',
              },
              templateOptions: {
                required: false,
                label: translate.instant('REPERTOIRES.STEPS.ADDITIONAL_FIELDS.FIELDS.NOTES'),
                rows: 5,
              },
            },
          ],
        },
      ],
    };
  }
}
