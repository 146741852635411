import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';

import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { DialogAddByAgreementId } from './dialog-add-by-agreement-id';

export class DialogAddMultipleAgreements extends DialogAddByAgreementId {
  constructor(translate: TranslateService, dialog: MatDialog, store: Store<fromRoot.RootState>, fieldValidatorService: FieldValidatorService) {
    super(
      translate,
      dialog,
      store,
      fieldValidatorService,
      new Map<string, string>([
        ['dialogTitle', 'AGREEMENT_GROUP.DIALOG.TITLE'],
        ['confirmTitle', 'AGREEMENT_GROUP.DIALOG.CONFIRM_TITLE'],
        ['confirmMessage', 'AGREEMENT_GROUP.DIALOG.CONFIRM_MESSAGE'],
        ['cancelDialogTitle', 'AGREEMENT_GROUP.DIALOG.CANCEL_TITLE'],
        ['cancelDialogMessage', 'AGREEMENT_GROUP.DIALOG.CANCEL_MESSAGE'],
        ['addManuallyLabel', 'AGREEMENT_GROUP.DIALOG.ADD_MANUALLY'],
      ]),
    );
  }
}
