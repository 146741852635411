import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '@ice/i18n/en/ui-messages';
import { FieldWrapper } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ice-formly-wrapper-character-counter',
  templateUrl: './wrapper-character-counter.component.html',
  styleUrls: ['./wrapper-character-counter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WrapperCharacterCounterComponent extends FieldWrapper implements OnInit, OnDestroy {
  valueSubscription: Subscription;
  textCounter: number;
  textCounterText: string;

  constructor(private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService) {
    super();
    this.fuseTranslationLoader.loadTranslations(english);
  }

  ngOnInit() {
    this.textCounterText = this.translate.instant('TEXT_COUNTER_TEXT', { maxCharacters: this.to.maxLength || 500 });
    this.textCounter = this.formControl.value?.length || 0;
    this.valueSubscription = this.formControl.valueChanges.subscribe(value => {
      this.textCounter = value?.length || 0;
    });
  }

  ngOnDestroy() {
    if (this.valueSubscription) {
      this.valueSubscription.unsubscribe();
    }
  }
}
