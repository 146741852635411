import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { REPERTOIRE_TYPES } from 'config/constants/repertoires.constants';
import { StepType } from 'config/stepper-builders/stepper-config';
import { FieldValidatorService } from 'services/validators/field.validator.service';

export class RepertoireTypeStep {
  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<any>,
    private fieldValidatorService: FieldValidatorService,
  ) {}

  getStep(translate: TranslateService): StepType {
    return {
      label: translate.instant('REPERTOIRES.STEPS.TYPE.TITLE'),
      formBuilder: [
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-1',
              fieldGroup: [
                {
                  key: 'type',
                  type: 'select',
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  templateOptions: {
                    label: translate.instant('REPERTOIRES.STEPS.TYPE.FIELDS.TYPE.TITLE'),
                    required: true,
                    options: [
                      { label: translate.instant('REPERTOIRES.STEPS.TYPE.FIELDS.TYPE.OPTIONS.PUBLISHER'), value: REPERTOIRE_TYPES.PUBLISHER },
                      { label: translate.instant('REPERTOIRES.STEPS.TYPE.FIELDS.TYPE.OPTIONS.SOCIETY'), value: REPERTOIRE_TYPES.SOCIETY },
                      { label: translate.instant('REPERTOIRES.STEPS.TYPE.FIELDS.TYPE.OPTIONS.WORK'), value: REPERTOIRE_TYPES.WORK },
                    ],
                    change: (field, $event) => {
                      if (this.fieldValidatorService.repertoireDetailsFields) {
                        const model = field.model;
                        const { repertoireDetailsFields, repertoireExcludedFields } = this.fieldValidatorService;
                        const { additionalCollectors, contractingCompanies, creatorAffiliations, workList } = repertoireDetailsFields;
                        const { worksExcludedList, agreementsExcludedList } = repertoireExcludedFields;

                        if (model.type !== REPERTOIRE_TYPES.PUBLISHER) {
                          if (additionalCollectors) {
                            additionalCollectors.templateOptions.removeAll();
                          }
                          if (contractingCompanies) {
                            contractingCompanies.templateOptions.removeAll();
                          }
                          if (agreementsExcludedList) {
                            agreementsExcludedList.templateOptions.removeAll();
                          }
                        }
                        if (model.type === REPERTOIRE_TYPES.WORK) {
                          if (creatorAffiliations) {
                            creatorAffiliations.templateOptions.removeAll();
                          }
                          if (worksExcludedList) {
                            worksExcludedList.templateOptions.removeAll();
                          }
                        } else {
                          if (workList) {
                            workList.templateOptions.removeAll();
                          }
                        }
                      }
                    },
                  },
                },
              ],
            },
            {
              className: 'flex-1',
              fieldGroup: [
                {
                  key: 'name',
                  wrappers: ['form-field', 'wrapper-input-text'],
                  type: 'input',
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  templateOptions: {
                    maxLength: 100,
                    label: translate.instant('REPERTOIRES.STEPS.TYPE.FIELDS.NAME'),
                    required: true,
                  },
                },
              ],
            },
          ],
        },
      ],
    };
  }
}
