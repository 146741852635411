import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from './../services/config.service';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class UrlInterceptor implements HttpInterceptor {
  constructor(private configService: ConfigService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const request = req.clone({
      url: req.url.replace('baseurlvalue', this.configService.url),
    });
    return next.handle(request);
  }
}
