import { ApiNamespace } from 'models';

export const cognitoConf = {
  region: 'eu-north-1',
  identityPoolId: '',
  userPoolId: '',
  clientId: '',
  cognito_idp_endpoint: '',
  sts_endpoint: '',
  cognito_identity_endpoint: '',
  userPoolAuthDomain: '',
  baseUrl: 'baseurlvalue',
};

export const AuthConf = {
  mandatorySignIn: true,
  identityPoolId: cognitoConf.identityPoolId,
  region: cognitoConf.region,
  userPoolId: cognitoConf.userPoolId,
  userPoolWebClientId: cognitoConf.clientId,
  oauth: {
    domain: cognitoConf.userPoolAuthDomain,
    scope: ['openid', 'profile', 'email', 'aws.cognito.signin.user.admin'],
    redirectSignIn: 'http://localhost:4200/auth.html',
    redirectSignOut: 'http://localhost:4200/login',
    responseType: 'token',
    clientID: cognitoConf.clientId,
  },
};

export const baseUrl = cognitoConf.baseUrl;
export const apiUrl = baseUrl;
export const apiUrlCubeData = `${baseUrl}`;
export const apiUrlCubeRegister = `${baseUrl}`;
export const territoriesApiUrl = `${baseUrl}/territories/countries`;
export const territoriesTisnApiUrl = `${baseUrl}/territories/TISN/search`;
export const urlSubmitSharePicture = `${baseUrl}/share-pictures/submit`;
export const urlGetStatusSharePicture = `${baseUrl}/share-pictures`;
export const urlGetSharePicture = `${baseUrl}/share-pictures`;
export const urlSharePicDebug = `${urlSubmitSharePicture}/debug`;
export const defaultApiNamespace: ApiNamespace = 'CUBE';
export const imbIceBaseUrl = 'https://integration.iceaccess.com/';
export const urlImbIceEnquiry = `${imbIceBaseUrl}/enquiry/requests/add`;
export const urlImbIceEnvirontmentPath = 'W';
export const flagsmithEnvironmentId = '';
export const userSnapEnable = true;
export const userSnapAPIKey = 'f6d143b9-6a8f-4d59-8ef6-2dc2db09ecf2';
export const ldId = '';
export const dataDogConf = {
  environmentName: '',
  clientId: '',
  applicationId: '',
};

export enum Hostnames {
  DEV = 'cognito.dev.cube.iceaccess.com',
  DEVELOPMENT = 'cognito.development.cube.iceaccess.com',
  LOCAL = 'localhost',
  NFT = 'cognito.nft.cube.iceaccess.com',
  PROD = 'cognito.prod.cube.iceaccess.com',
  SANDBOX = 'cognito.sandbox.cube.iceaccess.com',
  SIT = 'cognito.sit.cube.iceaccess.com',
}

export const environment = {
  ...cognitoConf,
  AuthConf,
  apiNamespace: defaultApiNamespace,
  apiUrl,
  apiUrlCubeData,
  apiUrlCubeRegister,
  baseUrl,
  territoriesApiUrl,
  territoriesTisnApiUrl,
  urlSubmitSharePicture,
  urlGetStatusSharePicture,
  urlGetSharePicture,
  urlSharePicDebug,
  urlGetMaskedId: `${baseUrl}/ids/mask`,
  imbIceBaseUrl,
  urlImbIceEnquiry,
  urlImbIceEnvirontmentPath,
  flagsmithEnvironmentId,
  userSnapEnable,
  userSnapAPIKey,
  ldId,
  dataDogConf,
};
