import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { UploadFileComponent } from '@ice/components';
import { DragDropDirective } from '@ice/directives/drag-drop/drag-drop.directive';
import { IceMaterialModule } from '@ice/material.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [DragDropDirective, UploadFileComponent],
  imports: [TranslateModule, CommonModule, ReactiveFormsModule, IceMaterialModule, FlexLayoutModule],
  exports: [UploadFileComponent],
})
export class UploadFileModule {}
