import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AgreementDetailCleaned, AgreementUtils, DateTimeUtils, SimpleDatatableValueType } from '@ice';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { ClaimsUtils } from '@ice/utils/claim/claims.utils';
import { DatatableUtils } from '@ice/utils/datatable/datatable.utils';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as englishShared } from 'assets/i18n/en/app/common/shared';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import { AGREEMENTS_CONSTANTS } from 'config/constants/agreements.constants';
import { DialogEditAgreementCrossReferences } from 'config/dialog-builders/dialog-edit-agreement-cross-references';
import { SectionsConfig } from 'config/sections-config';
import { SectionTabConfig } from 'config/tabs-data-builders/tabs-data';
import { IceFacade } from 'facades/ice.facade';
import { get, keys } from 'lodash';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { CommonApiService } from 'services/common-api.service';
import { DetailService } from 'services/detail/detail.service';
import { NamespaceService } from 'services/namespace/namespace.service';
import { PermissionsService } from 'services/permissions/permissions.service';
import { SearchService } from 'services/search/search.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromNewSectionItem from 'store/new-section-item';
import * as fromRoot from 'store/root';

const DEFAULT_CARDS_HEIGHT = 356;

export class TabAgreementDetail implements SectionTabConfig {
  expandTopCardsSubject: Subject<Boolean> = new Subject<Boolean>();
  expandTopCards$: Observable<Boolean> = this.expandTopCardsSubject.asObservable();
  private agreementId;

  //permissions
  canEditAgreement = false;
  private canDeleteXref: boolean;

  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private dialog: MatDialog,
    private commonApiService: CommonApiService,
    private detailService: DetailService,
    private nsService: NamespaceService,
    private iceFacade: IceFacade,
    private fieldValidatorService: FieldValidatorService,
    private permissionsService: PermissionsService,
    private searchService: SearchService,
    private storeNewItem: Store<fromNewSectionItem.NewSectionItemState>,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.fuseTranslationLoader.loadTranslations(englishShared);
    this.canEditAgreement = this.permissionsService.can('agreements_edit');
    this.canDeleteXref = this.permissionsService.can('agreements_delete_xrefs');
  }

  getConf(): IceGroupComponent[] {
    const cardWithFormHeight = '180px';
    const cardWithDataTableHeight = '330px';
    return [
      {
        group: [
          {
            type: 'cardWithDataTable',
            flex: 34,
            config: {
              title: this.translate.instant('AGREEMENTS.DETAILS.CARD_WITH_DATA_TABLE.TERMS'),
              model: this.store.pipe(
                select(fromRoot.getAgreementTerms),
                withLatestFrom(this.store.pipe(select(fromRoot.getAgreementRowShares))),
                map(([terms, shares]) => {
                  const allTheSameDates = ClaimsUtils.hasAgreementSharesSameEndDateAndPostTermCollectionDate(shares);
                  const cleanTerms = DateTimeUtils.cleanIndefiniteDateToAlias(terms, {
                    indefinite: this.translate.instant('AGREEMENTS.DETAILS.CARD_WITH_DATA_TABLE.INDEFINITE'),
                  });
                  const termsCleaned = cleanTerms.map(term => ({
                    label: this.translate.instant(`AGREEMENTS.DETAILS.CARD_WITH_DATA_TABLE.${term.label}`),
                    value: term.value,
                    valueIcons:
                      ['CONFIRMED_TERMINATION_DATE', 'POST_TERM_COLLECTION_END_DATE'].includes(term.label) && !allTheSameDates
                        ? [{ icon: 'sms_failed', text: this.translate.instant(`AGREEMENTS.DETAILS.CARD_WITH_DATA_TABLE.PARTIALLY_TERMINATED`) }]
                        : [],
                  }));
                  return AgreementUtils.orderUIByLabel(termsCleaned);
                }),
              ),
              columnMode: 'flex',
              schema: [
                { name: this.translate.instant('AGREEMENTS.DETAILS.CARD_WITH_DATA_TABLE.TERM'), prop: 'label', flexGrow: 1.5 },
                {
                  name: this.translate.instant('AGREEMENTS.DETAILS.CARD_WITH_DATA_TABLE.DATE'),
                  prop: 'value',
                  flexGrow: 0.7,
                  cellClass: 'bold-table-cell',
                  icons: 'valueIcons',
                },
              ],
              headerHeight: 0,
              tableWidth: '49',
              customClass: 'simple-list',
              dontStrip: true,
              height: cardWithFormHeight,
            },
          },
          {
            type: 'cardWithDataTable',
            flex: 66,
            config: {
              title: this.translate.instant('AGREEMENTS.DETAILS.CARD_WITH_DATA_TABLE.PARTIES'),
              model: combineLatest([this.store.pipe(select(fromRoot.getAgreementAssignor)), this.store.pipe(select(fromRoot.getAgreementAssignee))]).pipe(
                map(([assignor, assignee]: [any, any]) => [
                  { ...assignor, type: 'Assignor' },
                  { ...assignee, type: 'Assignee' },
                ]),
                map(ips => {
                  ips.forEach(ip => {
                    let ipiNameNumberFormatted: string = null;
                    if (ip && ip.ipiNameNumber) {
                      const splittedIpi = ip.ipiNameNumber.split(':');
                      ipiNameNumberFormatted = splittedIpi.length > 1 ? splittedIpi[1] : null;
                    }
                    ip['ipiNameNumberFormatted'] = ipiNameNumberFormatted;
                  });
                  return ips;
                }),
              ),
              columnMode: 'flex',
              schema: [
                {
                  name: this.translate.instant('AGREEMENTS.DETAILS.CARD_WITH_DATA_TABLE.SHARE_TYPE'),
                  prop: 'type',
                  flexGrow: 1,
                },
                {
                  name: this.translate.instant('AGREEMENTS.IPI_NAME'),
                  prop: 'name',
                  flexGrow: 2.4,
                },
                {
                  name: this.translate.instant('AGREEMENTS.IPI_NAME_NUMBER'),
                  prop: 'ipiNameNumberFormatted',
                  flexGrow: 1.2,
                },
                {
                  name: this.translate.instant('AGREEMENTS.IP_NAME_KEY'),
                  prop: 'ipNameKey',
                  flexGrow: 1.2,
                },
                {
                  name: this.translate.instant('AGREEMENTS.PR'),
                  prop: 'prSocietyName',
                  icons: 'prSocietyIcons',
                  flexGrow: 1,
                  headerTooltip: this.translate.instant('AGREEMENTS.PR_HEADER_TOOLTIP'),
                },
                {
                  name: this.translate.instant('AGREEMENTS.MR'),
                  prop: 'mrSocietyName',
                  icons: 'mrSocietyIcons',
                  flexGrow: 1,
                  headerTooltip: this.translate.instant('AGREEMENTS.MR_HEADER_TOOLTIP'),
                },
                {
                  actionButtonIcon: 'launch',
                  actionButtonTooltip: this.translate.instant('AGREEMENTS.OPEN_IP_DETAILS'),
                  flexGrow: 0.5,
                  resizeable: false,
                  cellClass: 'datatable-justify-center-cell',
                  action: row => {
                    this.store.dispatch(
                      new fromRoot.OpenNewTab({
                        path: [`${SectionsConfig.IPS.domainName}/${SectionsConfig.IPS.name}/ICE:${row.ipNameKey}`],
                      }),
                    );
                  },
                },
              ],
              height: cardWithFormHeight,
              onSelect: (event: any[]) => {
                this.store.dispatch(
                  new fromRoot.Go({
                    path: [`${SectionsConfig.IPS.domainName}/${SectionsConfig.IPS.name}/ICE:${event[0].ipNameKey}`],
                  }),
                );
              },
              onMouseSelect: event => {
                this.store.dispatch(
                  new fromRoot.OpenNewTab({
                    path: [`${SectionsConfig.IPS.domainName}/${SectionsConfig.IPS.name}/ICE:${event.ipNameKey}`],
                  }),
                );
              },
            },
          },
        ],
      },
      {
        group: [
          {
            type: 'cardWithDataTable',
            flex: 34,
            config: {
              title: this.translate.instant('AGREEMENTS.DETAILS.CARD_WITH_FORM.CARD_TITLE'),
              model: this.store.pipe(
                select(fromRoot.getCopyrightAgreement),
                tap((detail: AgreementDetailCleaned) => (this.agreementId = get(detail, 'attributes.key'))),
                map((detail: AgreementDetailCleaned) => {
                  const agreementAttributes = { ...(detail?.attributes || []), workCount: detail?.totalWorksCount || 0 };
                  return DatatableUtils.getDatatableSimpleListFromAttributes(agreementAttributes, {
                    name: { label: this.translate.instant('AGREEMENTS.AGREEMENT_NAME') },
                    agreementType: {
                      label: this.translate.instant('AGREEMENTS.AGREEMENT_TYPE.NAME'),
                      customValueParser: type =>
                        type === AGREEMENTS_CONSTANTS.GENERAL
                          ? this.translate.instant('AGREEMENTS.CORE_AGREEMENT.TYPE_OF_AGREEMENT.VALUES.GENERAL')
                          : this.translate.instant('AGREEMENTS.CORE_AGREEMENT.TYPE_OF_AGREEMENT.VALUES.SPECIFIC'),
                    },
                    sourceOfDoc: {
                      label: this.translate.instant('AGREEMENTS.SOURCE'),
                      tooltipParser: value => {
                        const translates = this.translate.instant('AGREEMENTS.CORE_AGREEMENT.SOURCE');
                        if (translates && value && keys(translates).find(translation => translation === value)) {
                          return translates[value];
                        }
                        return null;
                      },
                    },
                    workCount: {
                      label: this.translate.instant('AGREEMENTS.WORKCOUNT'),
                      valueType: SimpleDatatableValueType.NUMBER,
                      rowClass: agreementAttributes && agreementAttributes.workCount ? 'work-count-row' : 'work-count-row-disabled',
                      icons: {
                        launchWorkIcon: [
                          {
                            icon: 'launch',
                            text: this.translate.instant('AGREEMENTS.OPEN_WORKS'),
                            class: agreementAttributes?.workCount ? 'agreement-open-works-icon' : 'agreement-open-works-icon-disabled',
                            action: row => {
                              if (row.value) {
                                this.store.dispatch(
                                  new fromRoot.OpenNewTab({
                                    path: [`${SectionsConfig.AGREEMENTS.domainName}/${SectionsConfig.AGREEMENTS.apiSegment}/${this.agreementId}/included-works`],
                                  }),
                                );
                              }
                            },
                          },
                        ],
                      },
                    },
                    salesOrManufacture: {
                      label: this.translate.instant('AGREEMENTS.SALES_MANUFACTURE.NAME'),
                      customValueParser: type => type,
                      tooltipParser: type =>
                        type === AGREEMENTS_CONSTANTS.SALES
                          ? this.translate.instant('AGREEMENTS.CORE_AGREEMENT.SALES_MANUFACTURE.VALUES.SALES')
                          : type === AGREEMENTS_CONSTANTS.MANUFACTURE
                          ? this.translate.instant('AGREEMENTS.CORE_AGREEMENT.SALES_MANUFACTURE.VALUES.MANUFACTURE')
                          : null,
                    },
                    administrator: { label: this.translate.instant('AGREEMENTS.DETAILS.CARD_WITH_DATA_TABLE.ADMIN'), valueType: SimpleDatatableValueType.BOOLEAN },
                    usaLicenseIndicator: { label: this.translate.instant('AGREEMENTS.USA_LICENSE_INDICATOR'), valueType: SimpleDatatableValueType.STRING },
                    shareInOpAssignorOnly: { label: this.translate.instant('AGREEMENTS.SHARE_IN_OVERRIDE'), valueType: SimpleDatatableValueType.BOOLEAN },
                  });
                }),
              ),
              columnMode: 'flex',
              schema: [
                { name: this.translate.instant('AGREEMENTS.DETAILS.CARD_WITH_DATA_TABLE.TERM'), prop: 'label', flexGrow: 1 },
                {
                  name: this.translate.instant('AGREEMENTS.DETAILS.CARD_WITH_DATA_TABLE.DATE'),
                  tooltip: 'tooltip',
                  canHideTooltip: true,
                  prop: 'value',
                  flexGrow: 1,
                  cellClass: 'bold-table-cell',
                  icons: 'launchWorkIcon',
                  onClickAction: row => {
                    if (row.label === this.translate.instant('AGREEMENTS.WORKCOUNT') && row.value) {
                      this.store.dispatch(
                        new fromRoot.Go({
                          path: [`${SectionsConfig.AGREEMENTS.domainName}/${SectionsConfig.AGREEMENTS.apiSegment}/${this.agreementId}/included-works`],
                        }),
                      );
                    }
                  },
                },
              ],
              onMouseSelect: row => {
                if (row.label === this.translate.instant('AGREEMENTS.WORKCOUNT') && row.value) {
                  this.store.dispatch(
                    new fromRoot.OpenNewTab({
                      path: [`${SectionsConfig.AGREEMENTS.domainName}/${SectionsConfig.AGREEMENTS.apiSegment}/${this.agreementId}/included-works`],
                    }),
                  );
                }
              },
              headerHeight: 0,
              tableWidth: '49',
              customClass: 'simple-list',
              dontStrip: true,
              height: cardWithDataTableHeight,
            },
          },
          {
            type: 'cardWithDataTable',
            flex: 66,
            config: {
              title: this.translate.instant('AGREEMENTS.DETAILS.CARD_WITH_DATA_TABLE.SHARES_PER_TYPE_OF_USE'),
              model: this.store.pipe(
                select(fromRoot.getAgreementRowShares),
                map(shares => AgreementUtils.formatAgreementShares(shares, this.translate)),
              ),
              columnMode: 'flex',
              visibleColumns: this.store.pipe(
                select(fromRoot.getAgreementRowShares),
                withLatestFrom(this.store.select(fromRoot.getCopyrightDetailBySection)),
                map(([shares, detail]) => {
                  const isAssignorNaturalPerson = get(detail, 'assignor.nameType') === 'N';
                  const fixedVisibleColumns = ['shareType', 'rightsCodes', 'territoriesText', 'shareIn', ...(!isAssignorNaturalPerson ? ['shareOut'] : [])];
                  const allTheSameDates = ClaimsUtils.hasAgreementSharesSameEndDateAndPostTermCollectionDate(shares);
                  const terminateColumns = allTheSameDates ? [] : ['endDate', 'postTermCollectionDate'];
                  return [...fixedVisibleColumns, ...terminateColumns];
                }),
              ),
              schema: [
                {
                  name: this.translate.instant('AGREEMENTS.DETAILS.CARD_WITH_DATA_TABLE.SHARE_TYPE'),
                  prop: 'shareType',
                  tooltip: 'shareTypeTooltip',
                  flexGrow: 1,
                },
                {
                  name: this.translate.instant('AGREEMENTS.DETAILS.CARD_WITH_DATA_TABLE.IPI_RIGHT'),
                  prop: 'rightsCodes',
                  tooltip: 'rightsCodesTooltip',
                  flexGrow: 3,
                },
                {
                  name: this.translate.instant('AGREEMENTS.DETAILS.CARD_WITH_DATA_TABLE.TERRITORY'),
                  prop: 'territoriesText',
                  icons: 'territoriesIcon',
                  tooltip: 'territoriesTooltip',
                  tooltipDuration: 250,
                  cellClass: 'ice-txt-size-14 agreements-territories',
                  flexGrow: 2,
                },
                {
                  name: this.translate.instant('AGREEMENTS.DETAILS.CARD_WITH_DATA_TABLE.SHARE_IN'),
                  prop: 'shareIn',
                  flexGrow: 1,
                },
                {
                  name: this.translate.instant('AGREEMENTS.DETAILS.CARD_WITH_DATA_TABLE.SHARE_OUT'),
                  prop: 'shareOut',
                  flexGrow: 1,
                },
                {
                  name: this.translate.instant('AGREEMENTS.DETAILS.CARD_WITH_DATA_TABLE.TERM_DATE'),
                  prop: 'endDate',
                  flexGrow: 1,
                },
                {
                  name: this.translate.instant('AGREEMENTS.DETAILS.CARD_WITH_DATA_TABLE.PTC_END'),
                  prop: 'postTermCollectionDate',
                  flexGrow: 1,
                },
              ],
              height: cardWithDataTableHeight,
            },
          },
        ],
      },
      {
        group: [
          {
            type: 'card',
            flex: 34,
            config: {
              onOpen: () => this.expandTopCardsSubject.next(true),
              onClosed: () => this.expandTopCardsSubject.next(false),
              expanded: this.expandTopCards$,
              showCounter: this.store.pipe(
                select(fromRoot.getAgreementXREF),
                map(xref => xref.length),
              ),
              actionButton: this.canEditAgreement
                ? of({
                    icon: 'add',
                    tooltip: this.translate.instant('AGREEMENTS.CROSS_REFERENCES.ADD_CROSS_REFERENCES'),
                    class: 'mat-white-icon add-xref-button',
                    onClick: item => DialogEditAgreementCrossReferences.openDialog(this.dialog, this.store, this.translate, this.fuseTranslationLoader, this.fieldValidatorService),
                  })
                : of(null),
              title: this.translate.instant('AGREEMENTS.CROSS_REFERENCES.TITLE'),
              minHeight: DEFAULT_CARDS_HEIGHT,
              maxHeight: DEFAULT_CARDS_HEIGHT,
              hideToggle: false,
              content: [
                {
                  group: [
                    {
                      type: 'dataTable',
                      config: {
                        columnMode: 'flex',
                        customClass: 'simple-list ice-fixed-header',
                        fixedHeight: DEFAULT_CARDS_HEIGHT - 40 + 'px',
                        data: this.store.pipe(
                          select(fromRoot.getAgreementXREF),
                          map(rows =>
                            DatatableUtils.getDatatableSimpleListFromRows(
                              rows,
                              this.translate,
                              {
                                group: { label: this.translate.instant('AGREEMENTS.CROSS_REFERENCES.REFERENCE_LIST_LABELS.AGREEMENT_GROUP_REFERENCE') },
                                society: { label: this.translate.instant('AGREEMENTS.CROSS_REFERENCES.REFERENCE_LIST_LABELS.SOCIETY_AGREEMENT_REFERENCE') },
                                publisher: { label: this.translate.instant('AGREEMENTS.CROSS_REFERENCES.REFERENCE_LIST_LABELS.PUBLISHER_AGREEMENT_REFERENCE') },
                              },
                              {
                                included: { label: this.translate.instant('AGREEMENTS.CROSS_REFERENCES.AGREEMENT_GROUP_RELATION.INCLUDED') },
                                related: { label: this.translate.instant('AGREEMENTS.CROSS_REFERENCES.AGREEMENT_GROUP_RELATION.RELATED') },
                              },
                            ),
                          ),
                        ),
                        schema: [
                          { name: this.translate.instant('AGREEMENTS.DETAILS.CARD_WITH_DATA_TABLE.TERM'), prop: 'label', flexGrow: 1 },
                          { name: this.translate.instant('AGREEMENTS.DETAILS.CARD_WITH_DATA_TABLE.DATE'), prop: 'value', flexGrow: 1, cellClass: 'bold-table-cell' },
                          { prop: 'groupRelation', flexGrow: 1 },
                          {
                            actionButtonIcon: 'delete',
                            flexGrow: 0.001,
                            maxWidth: 50,
                            minWidth: 50,
                            resizeable: false,
                            hideActionButton: row => (row.label === 'ICE' || !this.canDeleteXref ? of(true) : of(false)),
                            action: row => DialogEditAgreementCrossReferences.openDeleteDialog(this.dialog, this.store, this.translate, this.fuseTranslationLoader, row),
                          },
                        ],
                        headerHeight: 0,
                        dontStrip: true,
                        shadowed: true,
                        showLoader: true,
                        sorts: [],
                      },
                    },
                  ],
                },
              ],
            },
          },
          {
            type: 'card',
            flex: 34,
            config: {
              onOpen: () => this.expandTopCardsSubject.next(true),
              onClosed: () => this.expandTopCardsSubject.next(false),
              expanded: this.expandTopCards$,
              showCounter: this.store.pipe(
                select(fromRoot.getAgreementSubmitterPartyNameIds),
                map(submitters => submitters.length),
              ),
              title: this.translate.instant('AGREEMENTS.SUBMITTER.TITLE'),
              minHeight: DEFAULT_CARDS_HEIGHT,
              maxHeight: DEFAULT_CARDS_HEIGHT,
              hideToggle: false,
              content: [
                {
                  group: [
                    {
                      type: 'dataTable',
                      config: {
                        columnMode: 'flex',
                        customClass: 'simple-list ice-fixed-header',
                        fixedHeight: DEFAULT_CARDS_HEIGHT - 40 + 'px',
                        data: combineLatest([
                          this.store.pipe(select(fromRoot.getAgreementSubmitterPartyNameIds)),
                          this.store.pipe(select(fromRoot.getAgreementSubmitterPartyRelations)),
                        ]).pipe(
                          map(([Ids, parties]: [string[], any[]]) => {
                            return AgreementUtils.formatAgreementSubmitter(Ids, parties);
                          }),
                        ),
                        schema: [
                          { prop: 'label', flexGrow: 1 },
                          { prop: 'value', flexGrow: 3, cellClass: 'bold-table-cell' },
                          { prop: 'name', flexGrow: 8 },
                        ],
                        headerHeight: 0,
                        dontStrip: true,
                        shadowed: true,
                        showLoader: true,
                        sorts: [],
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    ];
  }
}
