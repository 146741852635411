import { Search, SearchUtils } from '@ice';
import { createSelector } from '@ngrx/store';
import { FIELD_ORGANIZATION_ID } from 'config/constants/search.constants';
import { SectionsConfig } from 'config/sections-config';
import * as fromFeature from 'store/root/reducers';
import * as fromSearch from 'store/root/reducers/search/search.reducer';
import * as fromAuthSelectors from '../auth/auth.selectors';
import * as fromRouterSelectors from '../router/router.selectors';

export const getSearchResultsInput = createSelector(fromFeature.getSearchFeatureState, fromSearch.getSearchInputState);

export const getSearchXrefList = createSelector(fromFeature.getSearchFeatureState, fromSearch.getSearchXrefList);

export const getSearchResultsInputTerm = createSelector(getSearchResultsInput, (input: Search) => input && input.term);

export const getSearchResultsInputQuery = createSelector(getSearchResultsInput, (input: Search) => input && input.query);

export const getSearchResultsInputParams = createSelector(
  fromRouterSelectors.getRouterSection,
  getSearchResultsInput,
  fromAuthSelectors.getUserCurrentOrganization,
  (section: string, input: Search, currentOrganization) => {
    if (section === SectionsConfig.USERS.name) {
      const newParams = { ...input?.params };
      if (currentOrganization?.id !== 'ICE:ICE') {
        newParams[FIELD_ORGANIZATION_ID] = currentOrganization?.id;
      }
      return newParams;
    }
    return input && input.params;
  },
);

export const getSearchResultsForceCancel = createSelector(fromFeature.getSearchFeatureState, fromSearch.getSearchForceCancelState);

export const getSearchResultsInputTermAndRouterData = createSelector(getSearchResultsInputTerm, fromFeature.getRouterFeatureState, (term, router) => ({ term, router }));

export const getSearchResultsIds = createSelector(fromFeature.getSearchFeatureState, fromSearch.selectIds);

export const getSearchResultsEntities = createSelector(fromFeature.getSearchFeatureState, fromSearch.selectEntities);

export const getSearchResultsAll = createSelector(fromFeature.getSearchFeatureState, fromSearch.selectAll);

export const getSearchResultsAllFormatted = createSelector(
  getSearchResultsAll,
  fromRouterSelectors.getRouterSection,
  getSearchResultsInputTerm,
  (list: any[], section: string, term: string) => SearchUtils.customReOrder(list, section, term),
);

export const getSearchResultsTotal = createSelector(fromFeature.getSearchFeatureState, fromSearch.selectTotal);

export const getSearchResultsExtra = createSelector(fromFeature.getSearchFeatureState, fromSearch.getSearchExtra);

export const getSearchResultsExtraTotal = createSelector(getSearchResultsExtra, extras => extras && extras.total);

export const getSearchResultsSelectedItem = createSelector(fromFeature.getSearchFeatureState, fromSearch.getSearchSelectedItem);

export const getSearchHistory = createSelector(fromFeature.getSearchFeatureState, fromSearch.getSearchHistoryState);

export const getSearchSelectedRows = createSelector(fromFeature.getSearchFeatureState, state => state.selectedRowsSearchResults);

export const isSearchReseted = createSelector(fromFeature.getSearchFeatureState, state => state.searchReseted);

export const getConflictCaseType = createSelector(fromFeature.getSearchFeatureState, state => state.conflictStatus);
