import { FormlyFieldConfig } from '@ngx-formly/core';
import { AGREEMENTS_CONSTANTS } from 'config/constants/agreements.constants';
import { SelectOptions } from '../stepper-select-options';
import { AdditionalFieldsValidator } from '../steps/validators';

export class SummaryAdditionalFields {
  static getFormField(translate): FormlyFieldConfig[] {
    const options = SelectOptions.getOptions(translate);
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [{ className: 'flex-1', template: `<h2 class="ice-text-color-blue">${translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.TITLE')}</h2>` }],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-2',
            key: 'agreement_name',
            type: 'label',
            templateOptions: { text: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.AGREEMENT_NAME.NAME'), required: false, disabled: true, isSummary: true },
          },
        ],
      },
      { fieldGroupClassName: 'display-flex', fieldGroup: [{ className: 'flex-1', template: `<h3>${translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.IDENTIFIERS.TITLE')}</h3>` }] },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1 ice-disabled-text-color',
            key: 'identifiers',
            type: 'datatable',
            templateOptions: {
              scrollbarH: false,
              footerHeight: 0,
              reorderable: false,
              columns: [{ name: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.IDENTIFIERS.SUBMITTER_AGREEMENT_NUMBER.NAME'), prop: 'submitter_agreement_number' }],
              disabled: true,
            },
            fieldArray: {
              fieldGroup: [
                { className: 'flex-1', type: 'label', key: 'submitter_agreement_number', templateOptions: { class: 'ice-enabled-text-color', disabled: true, isSummary: true } },
              ],
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [{ className: 'flex-1', template: `<h3>${translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.RELATED_AGREEMENTS.TITLE')}</h3>` }],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1 ice-disabled-text-color',
            key: 'related_agreements',
            type: 'datatable',
            templateOptions: {
              scrollbarH: false,
              footerHeight: 0,
              columns: [
                { name: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.RELATED_AGREEMENTS.RELATED_AGREEMENT_TYPE.NAME'), prop: 'related_agreement_type' },
                { name: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.RELATED_AGREEMENTS.RELATED_AGREEMENT_NUMBER.NAME'), prop: 'related_agreement_number' },
              ],
            },
            fieldArray: {
              fieldGroup: [
                {
                  className: 'flex-1',
                  type: 'select',
                  key: 'related_agreement_type',
                  templateOptions: {
                    class: 'ice-enabled-text-color',
                    disabled: true,
                    options: options.additionalFields.related_agreement_type,
                    isSummary: true,
                  },
                },
                { className: 'flex-1', type: 'label', key: 'related_agreement_number', templateOptions: { class: 'ice-enabled-text-color', disabled: true, isSummary: true } },
              ],
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [{ className: 'flex-1', template: `<h3>${translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.INTERNATIONAL_FACTORS.TITLE')}</h3>` }],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-2',
            key: 'writer_mechanical_reason',
            type: 'select',
            templateOptions: {
              label: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.INTERNATIONAL_FACTORS.WRITER_MECHANICAL_REASON.NAME'),
              options: options.additionalFields.writer_mechanical_reason,
              disabled: true,
              isSummary: true,
            },
            hideExpression: model => {
              return AdditionalFieldsValidator.writerMechanicalReasonHideExpression(model);
            },
          },
          {
            key: 'usa_license_indicators',
            type: 'select',
            templateOptions: {
              label: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.INTERNATIONAL_FACTORS.USA_LICENSE_INDICATOR.NAME'),
              options: options.additionalFields.usa_license_indicators,
              disabled: true,
              isSummary: true,
            },
            expressionProperties: {
              className: model => {
                if (AdditionalFieldsValidator.writerMechanicalReasonHideExpression(model)) {
                  return 'flex-2 first-child';
                }
                return 'flex-2 second-child';
              },
            },
          },
          {
            className: 'flex-1',
            key: 'gema_remuneration_claim_indicator',
            type: 'checkbox',
            templateOptions: {
              type: 'boolean',
              placeholder: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.INTERNATIONAL_FACTORS.GEMA_REMUNERATION_CLAIM_INDICATOR.NAME'),
              disabled: true,
              isSummary: true,
            },
            hideExpression: model => {
              if (model.assignee_society_code) {
                return !model.assignee_society_code.includes(AGREEMENTS_CONSTANTS.GEMA_SOCIETY);
              } else {
                return true;
              }
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [{ className: 'flex-1', template: `<h3>${translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.LIBRARY_PRODUCTION.TITLE')}</h3>` }],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-2 ice-value-mt-25 ice-txt-size-18',
            key: 'library_music',
            type: 'select',
            templateOptions: {
              label: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.LIBRARY_PRODUCTION.LIBRARY_MUSIC.NAME'),
              disabled: true,
              options: options.additionalFields.library_music,
              isSummary: true,
            },
          },
          {
            className: 'flex-2',
            key: 'recording_prefix',
            type: 'label',
            templateOptions: { text: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.LIBRARY_PRODUCTION.RECORDING_PREFIX.NAME'), disabled: true },
            hideExpression: model => {
              return model.library_music === 'n';
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [{ className: 'flex-1', template: `<h3>${translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.ADDITIONAL_INFORMATION.TITLE')}</h3>` }],
      },
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'flex-1',
            key: 'additional_information',
            type: 'label',
            templateOptions: { text: translate.instant('AGREEMENTS.ADDITIONAL_FIELDS.ADDITIONAL_INFORMATION.TITLE'), disabled: true, isSummary: true },
          },
        ],
      },
    ];
  }
}
