<div fxLayout="row">
  <mat-form-field fxLayout="column" fxFlex="{{ flex }}">
    <input
      [id]="id"
      type="text"
      matInput
      [placeholder]="getPlaceholder()"
      [formControl]="formControl"
      [matAutocomplete]="autoGroup"
      #inputAutoComplete
      [readonly]="to.inputReadonly | async"
      (blur)="onBlurMethod()"
      (keyup)="searchEvent($event)"
      (click)="doHighlight($event)"
      [matTooltip]="tempTooltip"
      (mouseover)="enterField($event, formControl?.value?.label)"
      (mouseout)="leaveField()"
    />
    <mat-autocomplete autoActiveFirstOption #autoGroup="matAutocomplete" [displayWith]="displayFn" [panelWidth]="to?.panelWidth">
      <mat-optgroup *ngFor="let group of filteredOptions | async" [label]="group.header">
        <mat-option
          *ngFor="let option of group.options"
          [value]="option"
          (mousedown)="click($event)"
          [matTooltip]="tempTooltip"
          (mouseover)="enterField($event, option.label)"
          (mouseout)="leaveField()"
        >
          {{ option.label }}
        </mat-option>
      </mat-optgroup>
    </mat-autocomplete>
    <mat-icon *ngIf="formControl.value && !formControl.disabled && !(to.inputReadonly | async)" matSuffix class="cursor-pointer" (click)="clearValue()">clear</mat-icon>
    <mat-error [id]="null">
      <formly-validation-message [field]="field"></formly-validation-message>
    </mat-error>
  </mat-form-field>
</div>
