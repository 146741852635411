<div class="ice-search-bar-container">
  <div class="ice-search-bar" [ngClass]="{ expanded: true }">
    <div fxLayout="row">
      <div>
        <button
          class="ice-search-bt-advanced-search"
          matTooltip="Ctrl + Shift + A"
          matTooltipPosition="right"
          mat-button
          (click)="collapseAdvanced()"
          [matTooltipClass]="'ice-txt-size-12'"
          aria-label="Collapse Search Bar"
          [attr.collapsed]="collapsedAdvanced"
        >
          <mat-icon class="s-24 ice-expand-icon" [ngClass]="{ 'ice-expanded-icon': collapsedAdvanced }">expand_more </mat-icon>
          {{ 'SEARCH_ADVANCED' | translate }}
        </button>
      </div>
      <!-- open search input btn -->
      <div
        [ngClass]="{
          'disable-normal-search': disableNormalSearch
        }"
      >
        <label for="ice-search-bar-input">
          <button
            [disabled]="true"
            mat-icon-button
            class="ice-search-bar-expander ice-search-bar-bt-search"
            aria-label="search"
            matTooltip="Ctrl + Shift + F"
            [matTooltipClass]="'ice-txt-size-12'"
          >
            <mat-icon class="s-24">search</mat-icon>
          </button>
        </label>
      </div>
      <div
        [ngClass]="{
          'ice-search-bar-input-containe': true,
          'disable-normal-search': disableNormalSearch
        }"
      >
        <input
          #term
          [value]="searchTerm ? searchTerm : ''"
          iceAutofocus
          iceDebounceKey
          [debounceTime]="600"
          id="ice-search-bar-input"
          class="ice-search-bar-input"
          type="text"
          placeholder="{{ (section | uppercase) + '_SEARCH_PLACEHOLDER' | translate }}"
          (debounceKey)="search(term.value)"
          fxFlex
        />
      </div>
      <div
        [ngClass]="{
          'ice-bt-clear': true,
          'disable-normal-search': disableNormalSearch
        }"
      >
        <button class="search-simple-clear-button" mat-button *ngIf="searchTerm && searchTerm.length > 0" matSuffix mat-icon-button aria-label="Clear" (click)="resetSearch()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
