import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IceMaterialModule } from '@ice/material.module';
import { ResponseErrorComponent } from './response-error.component';

@NgModule({
  declarations: [ResponseErrorComponent],
  imports: [CommonModule, IceMaterialModule, FlexLayoutModule],
  exports: [ResponseErrorComponent],
})
export class ResponseErrorComponentModule {}
