import { TerritoryUtils } from '@ice';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import * as fromRoot from 'store/root';
import * as fromRouterSelectors from 'store/root/selectors/router/router.selectors';

export class TerritoriesFacade {
  constructor(private store: Store<fromRoot.RootState>) {}

  private getTerritoryDetailTerritories(): Observable<any> {
    return this.store.select(fromRoot.getTerritoryDetailTerritories);
  }

  getTerritoryLanguageNames(): Observable<any> {
    return this.getTerritoryDetailTerritories().pipe(map(territories => TerritoryUtils.getLanguageNames(territories)));
  }

  getCopyrightDetailTerritoriesList(): Observable<any> {
    return this.store.select(fromRoot.getCopyrightDetailTerritoriesList);
  }

  getTerritoryIncludedCountriesFilteredByDate(): Observable<any> {
    return this.getTerritoryIncludedCountriesMembers().pipe(
      withLatestFrom(this.getTerritoryDateFilter()),
      map(([members, filterDate]) => TerritoryUtils.getFilteredTerritoriesByDate(filterDate, members)),
    );
  }

  getTerritoryIncludedCountries(): Observable<any> {
    return this.store.select(fromRoot.getTerritoryIncludedCountries);
  }

  getTerritoryIncludedCountriesMembers(): Observable<any> {
    return this.store.select(fromRoot.getTerritoryIncludedCountriesMembers);
  }

  getTerritorySuccessors(): Observable<any> {
    return this.store.select(fromRoot.getTerritorySuccessors);
  }

  getTerritoryPredecessors(): Observable<any> {
    return this.store.select(fromRoot.getTerritoryPredecessors);
  }

  getSelectedTerritoryDetail(): Observable<any> {
    return this.getTerritoryDetailTerritories().pipe(
      withLatestFrom(this.store.pipe(select(fromRouterSelectors.getRouterQueryParams))),
      map(([territories, params]) => TerritoryUtils.selectTerritoryDetail(territories, params)),
    );
  }

  getTerritoryDateFilter(): Observable<any> {
    return this.store.select(fromRoot.getTerritoryDateFilter);
  }
}
