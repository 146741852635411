import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DataTableRow, SortInfo } from '@ice/components/data-table/data-table';
import { TranslateService } from '@ngx-translate/core';
import { DataTableBuilder } from 'config/data-table-builders/data-table-builder';
import { RootDatatable } from 'config/data-table-builders/root-datatable';

import { ColumnMode } from '@swimlane/ngx-datatable';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';

export class CopyrightTerritoriesDataTable extends RootDatatable implements DataTableBuilder {
  constructor(protected translate: TranslateService, protected fuseTranslationLoader: FuseTranslationLoaderService) {
    super(translate);
    this.fuseTranslationLoader.loadTranslations(english);
  }

  getDefaultSorting(): SortInfo[] {
    return [{ prop: 'name', dir: 'asc' }];
  }

  formatSort(sort: SortInfo) {
    switch (sort.prop) {
      case 'name':
      case 'tisA':
      case 'tisN':
      case 'tisAExt':
        return null;
    }
    return { prop: `attributes.${sort.prop}`, dir: sort.dir };
  }

  getDataTable(): DataTableRow[] {
    return [
      { name: this.translate.instant('TERRITORIES.NAME'), prop: 'name', flexGrow: 3, cellClass: 'territory-name' },
      { name: this.translate.instant('TERRITORIES.TISA'), prop: 'tisA', flexGrow: 1, cellClass: 'territory-tisa' },
      { name: this.translate.instant('TERRITORIES.TISN'), prop: 'tisN', flexGrow: 1, cellClass: 'territory-tisn' },
      { name: this.translate.instant('TERRITORIES.STATUS'), prop: 'tisAExt', flexGrow: 1, cellClass: 'territory-status' },
    ];
  }

  getColumnMode(): ColumnMode {
    return ColumnMode.flex;
  }

  displayCheck(row: any, firstSelectedItem: any, targetItem: any, column?: any, value?: any): boolean {
    return true;
  }
}
