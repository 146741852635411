import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { RepeatTypeComponent } from '@ice';
import { IceMaterialModule } from '@ice/material.module';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [RepeatTypeComponent],
  imports: [TranslateModule, FuseSharedModule, IceMaterialModule, FormlyMaterialModule, FormlyModule.forRoot(), ScrollingModule],
  exports: [RepeatTypeComponent],
})
export class RepeatTypeModule {}
