import { AgreementItem, CopyrightDetailRequest, IpCleaned, PayloadSharePicture, PutNewIpPayload, TableMap } from '@ice';
import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { ApiCall, ApiCallConfig, ApiCallPayload, CustomApiCall } from 'config/sections-config/api-call';
import { PayloadAuditHistoryFilter } from 'models/action-payloads/audit-history-filter.payload';
import { PayloadGetNotesSuccess } from 'models/action-payloads/notes.payload';
import { PayloadTerritoriesFilter } from 'models/action-payloads/territories-filter.payload';
import { AuditHistory } from 'models/copyright/detail/audit-history';
import { EditAction } from 'models/copyright/detail/edit';
import { EditMode, EditModeHistory } from 'models/copyright/detail/edit-mode';
import { ExportMode, ExportModeType } from 'models/copyright/search/export-mode';
import { SelectModeEnum } from 'models/copyright/search/select-mode';
import { UpdateMode, UpdateModeType } from 'models/copyright/search/update-mode';
import { Share, SharePicture } from 'models/copyright/shares/shares';
import { FileType } from 'services/report/report.service';
import { CopyrightItemView } from 'store/root/state';
import { IceErrorPayload } from 'models/action-payloads/ice-error-payload';
import { RowNote } from 'models/notes/notes';
import { DEFAULT_FILTER_MODEL } from 'config/constants/works.constants';

export const GET_ITEM = '[Copyright] Get Item Detail';
export const GET_ITEM_TO_EDIT = '[Copyright] Get Item Detail And Edit';
export const GET_ITEM_SUCCESS = '[Copyright] Get Item Detail Success';
export const GET_ITEM_FAIL = '[Copyright] Get Item Detail Fail';
export const GET_ACCESS_FAIL = '[Copyright] Get Access Fail';
export const GET_ITEM_NOT_FOUND = '[Copyright] Get Item Detail Not Found';
export const GET_DETAIL_PAGE_SUCCESS = '[Copyright] Get Detail Page Success';
export const GET_ITEM_FULL_DATA_END = '[Copyright] Get Item Full Data End';
export const GET_SHARE_PICTURE = '[Copyright] Get Share Picture';
export const GET_SHARE_PICTURE_SUCCESS = '[Copyright] Get Share Picture Success';
export const CLEAR_SHARE_PICTURE = '[Copyright] Clear Share Picture';
export const GET_SHARE_PICTURE_FAIL = '[Copyright] Get Share Picture Fail';
export const SWITCH_OWNERSHIP_TABLE_DATA = '[Copyright] Switch Ownership Table Data';
export const ASSIGN_OWNERSHIP_TO_ITEMS = '[Copyright] Assign Ownership To Items';
export const GET_WORK_TO_COMPARE = '[Copyright] Get Work To Compare';
export const SELECT_WORKS_AUDIT_HISTORY_EVENT = '[Copyright] Select Works Audit History Event';
export const SELECT_IPS_AUDIT_HISTORY_EVENT = '[Copyright] Select Ips Audit History Event';
export const SELECT_AGREEMENT_AUDIT_HISTORY_EVENT = '[Copyright] Select Agreement Audit History Event';
export const SELECT_COUNTER_CLAIM_AUDIT_HISTORY_EVENT = '[Copyright] Select Counter Claim Audit History Event';
export const CANCEL_REQUEST = '[Copyright] Cancel Request';
export const EDIT_ITEM = '[Copyright] Edit Item';
export const START_UPDATE_MODE = '[Copyright] Start Update Mode';
export const SWITCH_UPDATE_MODE = '[Copyright] Switch Update Mode';
export const END_UPDATE_MODE = '[Copyright] End Update Mode';
export const SET_UPDATE_MODE_BULK = '[Copyright] Set Update Mode Bulk';
export const START_EDIT_MODE = '[Copyright] Start Edit Mode';
export const FORCE_EDIT_MODE = '[Copyright] Force Edit Mode';
export const SAVE_EDIT_MODE_HISTORY = '[Copyright] Save Edit Mode History';
export const START_SELECT_MODE = '[Copyright] Start Select Mode';
export const CLEAR_SELECT_MODE = '[Copyright] Clear Select Mode';
export const END_SELECT_MODE = '[Copyright] End Select Mode';
export const SET_SELECT_MODE = '[Copyright] Set Select Mode';
export const SUBMIT_SELECT_MODE = '[Copyright] Submit Select Mode';
export const START_EXPORT_MODE = '[Copyright] Start Export Mode';
export const SET_EXPORT_BATCH = '[Copyright] Set Export Batch';
export const SET_EXPORT_TYPE = '[Copyright] Set Export Type';
export const RESET_EXPORT_MODE = '[Copyright] Reset Export Mode';
export const PAUSE_EXPORT_MODE = '[Copyright] Pause Export Mode';
export const CANCEL_DOWNLOAD = '[Copyright] Cancel Download';
export const GET_DATA_FROM_QUEUE = '[Copyright] Get Data from Queue';
export const EXPORT_DATA = '[Copyright] Export Data Start';
export const DATA_EXPORTER = '[Copyright] Data Exporter';
export const EXPORT_DATA_SUCCESS = '[Copyright] Export Data Success';
export const END_EXPORT_MODE = '[Copyright] End Export Mode';
export const UPDATE_FIELD = '[Copyright] Update Field';
export const UPDATE_FIELD_SUCCESS = '[Copyright] Update Field Success';
export const UPDATE_FIELD_FAIL = '[Copyright] Update Field Fail';
export const END_EDIT_MODE = '[Copyright] End Edit Mode';
export const END_EDIT_MODE_NEW_ITEM = '[Copyright] End Edit Mode New Item';
export const CREATE_ITEM = '[Copyright] Create Item';
export const CLONE_ITEM = '[Copyright] Clone Item';
export const CLONE_ITEM_IN_PLACE = '[Copyright] Clone Item in place';
export const SET_EDIT_MODE_FROM_CREATE_MODE = '[Copyright] Set Edit Mode From Create Mode';
export const FINISH_CLONE_ITEM = '[Copyright] Finish Clone Item';
export const RESET_CREATE_MODE = '[Copyright] Reset Create Mode';
export const SET_ITEM_VIEW = '[Copyright] Set Item View';
export const GET_NOTES = '[Copyright] Get Notes';
export const GET_NOTES_SUCCESS = '[Copyright] Get Notes Success';
export const GET_NOTES_FAIL = '[Copyright] Get Notes';
export const CLEAR_NOTES = '[Copyright] Clear Notes';
export const UPDATE_NOTES = '[Copyright] Update Notes';
export const UPDATE_NOTES_SUCCESS = '[Copyright] Update Notes Success';
export const UPDATE_NOTES_FAIL = '[Copyright Update Notes Fail';
export const NEW_NOTE = '[Copyright] New Note';
export const UPDATE_NOTE = '[Copyright] Update Note';
export const DELETE_NOTE = '[Copyright] Delete Note';
export const IPS_RELATIONS_DO_SEARCH = '[Copyright] Do a Search for IPS Relations ';
export const IPS_RELATIONS_SEARCH_SUCCESS = '[SectionSearch] IPS Relations Search Success';
export const SECTION_DO_SEARCH_FAIL = '[SectionSearch] Section Search Fail';
export const UPDATE_IPS_RELATIONS_SELECT = '[Copyright] Update Ips Relations - select relations to save';
export const UPDATE_IPS_RELATIONS_FAIL = '[Copyright] Update Ips Relations Fail';
export const CLEAN_LOADING = '[Copyright] Clean loading';
export const SAVE_SECTION_ITEM = '[Copyright] Save';
export const SAVE_SECTION_ITEM_FAIL = '[Copyright] Save Fail';
export const SAVE_SECTION_ITEM_SUCCESS = '[Copyright] Save Success';
export const REGISTER_IPS_ON_ICE_PARTIES = '[Copyright] Register IPS on Ice parties';
export const CLEAN_RESPONSE = '[Copyright] Clean Response';
export const MARK_POPUP_OK = '[Copyright] Mark popup Ok button submit';
export const UNMARK_POPUP_OK = '[Copyright] Unmark popup Ok button submit';
export const ICE_ERROR = '[Copyright] Ice Error';
export const AUTH_ERROR = '[Copyright] Auth Error';
export const SET_WORK_ANALYSIS = '[Copyright] Set Work Analysis';
export const RESET_STORE_PARAM = '[Copyright] Reset Store Param';
export const RESET_WORK_TO_COMPARE = '[Copyright] Reset Work To Compare';
export const SAVE_ATTRIBUTES_TO_SOCIETIES = '[Copyright] Save Attributes To Societies';
export const SAVE_ATTRIBUTES_TO_SOCIETIES_SUCCESS = '[Copyright] Save Attributes To Societies Success';
export const DELETE_ATTRIBUTE_FROM_SOCIETY = '[Copyright] Delete Attribute From Society';
export const RESET_DELETE_ITEM = '[Copyright] Reset Delete Item';
export const DELETE_ITEM = '[Copyright] Delete Item';
export const DELETE_ITEM_SUCCESS = '[Copyright] Delete Item Success';
export const DELETE_ITEM_FAIL = '[Copyright] Delete Item Fail';
export const SAVE_SHARES_DATA = '[Copyright] Save Shares Data';
export const RETRIEVE_SHARE_PICTURE_FROM_CACHE = '[Copyright] Retrieve Share Picture From Cache';
export const UPDATE_COUNTERCLAIM_CCPARTIESONLY_FILTER = '[Copyright] Update CounterClaim CC Parties Only Filter';
export const SET_COUNTERCLAIM_DASHBOARD_SEARCH_FILTER = '[Copyright] Set Counterclaim Dashboard Search Filter';
export const SUBMIT_AUDIT_HISTORY_FILTER = '[Copyright] Audit History Filter';
export const AUDIT_FILTER_HISTORY_SUCCESS = '[Copyright] Audit History Filter Success';
export const AUDIT_FILTER_HISTORY_FAIL = '[Copyright] Audit History Filter Fail';
export const CLEAN_DETAIL = '[Copyright] Clean Item Detail';
export const SUBMIT_TERRITORIES_FILTER = '[Copyright] Territories Filter';
export const TERRITORY_FILTERED_SUCCESSORS_SUCCESS = '[Copyright] Territories Filtered Successors Success';
export const TERRITORY_FILTERED_PREDECESSORS_SUCCESS = '[Copyright] Territories Filtered Predecessors Success';
export const WORK_RESOLUTION = '[Copyright] Work Resolution';
export const WORK_RESOLUTION_SUCCESS = '[Copyright] Work Resolution Success';
export const WORK_RESOLUTION_FAIL = '[Copyright] Work Resolution Fail';
export const GET_SEARCH_SCROLL_PAGINATION = '[Copyright] Get Search Scroll Pagination';
export const RESEND_ACTIVATION_EMAIL = '[Copyright] Resend Invite';
export const RESEND_ACTIVATION_EMAIL_SUCCESS = '[Copyright] Resend Activation Email Success';
export const RESEND_ACTIVATION_EMAIL_FAIL = '[Copyright] Resend Activation Email Fail';
export const SET_CLAIMS_FILTER_TO_COMPARE = '[Copyright] Set Claims Filter To Compare';
export const SET_UPDATE_TYPE = '[Copyright] Set Update Mode Type';
export const SET_RETENTION_WORKS = '[Copyright] Set Retention Works';
export const SAVE_WORK_BATCH_SUCCESS = '[Copyright] Save Work Batch Success';
export const SAVE_WORK_BATCH_ERROR = '[Copyright] Save Work Batch Error';
export const GET_RELATION_RELATED_WORK = '[Copyright] Get RelationRelatedWork';
export const ADD_WORK_RELATION = '[Copyright] Add Work Relation';
export const ACTIVATE_USER = '[Copyright] Activate User';
export const DISABLE_USER = '[Copyright] Disable User';

export const START_API_CALL = '[Copyright] Start Api Call';
export const PAGINATE_API_CALL = '[Copyright] Fetch Api Call Pagination';
export const FETCH_API_CALL = '[Copyright] Fetch Api Call';
export const CANCEL_API_CALL = '[Copyright] Cancel Api Call';
export const API_CALL_SUCCESS = '[Copyright] Api Call Success';
export const API_CALL_ERROR = '[Copyright] Api Call Error';
export const API_CALL_RETRY = '[Copyright] Api Call Retry';
export const API_CALL_CHECK_STATUS_PROCESS = '[Copyright] Api Call Check Status Process';
export const REFRESH_DETAIL = '[Copyright] Refresh detail';

export const SET_DUMMY_IP = '[Copyright] Create Dummy Ip';
export const SET_DUMMY_IP_EMPTY = '[Copyright] Create Dummy Ip Empty';
export const SET_DUMMY_IP_SUCCESS = '[Copyright] Create Dummy Ip Success';
export const SET_DUMMY_IP_ERROR = '[Copyright] Create Dummy Ip Error';
export const EDIT_CLAIM_SUCCESS = '[Copyright] Edit Claim Success';
export const EDIT_CLAIM_FAIL = '[Copyright] Edit Claim Fail';
export const BEGIN_EDIT_CLAIM = '[Copyright] Begin Edit Claim';
export const SET_EDIT_CLAIM = '[Copyright] Set Edit Claim';
export const END_EDIT_CLAIM = '[Copyright] End Edit Claim';
export const SAVE_SCROLL_BY_SECTION = '[Copyright] Save Scroll By Section';
export const RESET_WORK = '[Copyright] Reset Work';
export const SET_ALL_CLAIMS_FILTER = '[Copyright] Set All Claims Filter';
export const SET_COPYRIGHT_LOADING = '[Copyright] Set Copyright Loading';
export const UPLOAD_USING_PRE_SIGNED_URL = '[Copyright] Upload Using Pre-Signed URL';
export const UPLOAD_RESPONSE = '[Copyright] Upload Response';
export const ENABLE_DOWNLOAD_DOCUMENT = '[Copyright] Enable Download Document';
export const DISABLE_DOWNLOAD_DOCUMENT = '[Copyright] Disable Download Document';
export const SAVE_DOWNLOAD_DOCUMENTS = '[Copyright] Save Download Documents';
export const DOWNLOAD_DOCUMENTS = '[Copyright] Download Documents';
export const GET_REPORT_TYPES = '[Copyright] Get Report Types';
export const GET_REPORT_TYPES_SUCCESS = '[Copyright] Get Report Types Success';
export const GET_REPORT_TYPES_FAIL = '[Copyright] Get Report Types Fail';
export const GET_REPORT = '[Copyright] Get Report';
export const GET_REPORT_SUCCESS = '[Copyright] Get Report Success';
export const GET_REPORT_FAIL = '[Copyright] Get Report Fail';
export const DOWNLOAD_REPORT = '[Copyright] Download Report';
export const CLONE_REPORT = '[Copyright] Clone Report';
export const CLONE_REPORT_SUCCESS = '[Copyright] Clone Report Success';
export const CLONE_REPORT_FAIL = '[Copyright] Clone Report Fail';
export const ENABLE_REJECT_DOCUMENT = '[Copyright] Enable Reject Document';
export const DISABLE_REJECT_DOCUMENT = '[Copyright] Disable Reject Document';
export const SAVE_REJECT_DOCUMENTS = '[Copyright] Save Reject Documents';
export const SEND_REJECT_DOCUMENTS = '[Copyright] Send Reject Documents';
export const SEND_REJECT_DOCUMENTS_FAIL = '[Copyright] Send Reject Documents Fail';
export const SEND_REJECT_DOCUMENTS_SUCCESS = '[Copyright] Send Reject Documents Success';
export const SET_SELECT_SHARE_TARGET = '[Copyright] Set Select Share Target';
export const DELETE_FAIL = '[Copyright] Delete Error';
export const HYDRATE_WORK_CLAIMS = '[Persist] Hydrate Work Claims';
export const HYDRATE_ALL_CLAIMS = '[Persist] Hydrate All Claims';
export const SET_WORK_FILTER = '[Copyright] Set Work Filter';
export const RESET_DIALOG_SEARCH_DATA = '[Copyright] Reset Dialog Search Data';
export const SET_SELECTED_OPTION_TO_COMPARE = '[Copyright] Set Selected Option To Compare';
export const START_MERGE_IP_MODE = '[Copyright] Start Merge Ip Mode';
export const SET_MERGE_IP_ITEMS = '[Copyright] Set Merge Ip Items';
export const END_MERGE_IP_MODE = '[Copyright] End Merge Ip Mode';
export const START_MERGING_IP = '[Copyright] Start Merging IP';
export const END_MERGING_IP = '[Copyright] End Merging IP';
export const SAVE_ALL_USERS = '[Copyright] Save all users';
export const UPSERT_USERS = '[Copyright] Upsert users';
export const SAVE_ALL_API_DATA = '[Copyright] Save all API data';
export const TOGGLE_TRANSFER_IP_WORKS = '[Copyright] Toggle transfer ip works';
export const SET_TRANSFER_IP_WORKS_INFO = '[Copyright] Set tranfer ip works info';
export const GET_ALL_TERRITORIES = '[Copyright] Get all territories';
export const SAVE_ALL_TERRITORIES = '[Copyright] Save all territories';
export const GET_ALL_TERRITORIES_SUCCESS = '[Copyright] Get all territories success';
export const GET_ALL_TERRITORIES_FAIL = '[Copyright] Get all territories failed';
export const GET_OPTION_TO_COMPARE_DETAILS = '[Copyright] Get option to compare details';
export const SET_OPTIONS_TO_COMPARE = '[Copyright] Set options to compare';

export const UPDATE_AGREEMENT_CHAIN = '[Agreement] Update Agreement Chain';
export const UPSERT_AGREEMENT_CHAINS = '[Agreement] Upsert Agreement Chains';
export const SORTED_AGREEMENT_CHAINS = '[Agreement] Sorted Agreement Chains';
export const IGNORE_MERGE_CLAIMS = '[Copyright] Ignore Merge Claims';

export class CloneItemInPlace implements Action {
  readonly type = CLONE_ITEM_IN_PLACE;
  constructor(public payload: any) {}
}
export class SetEditModeFromCreateMode implements Action {
  readonly type = SET_EDIT_MODE_FROM_CREATE_MODE;
}
export class HydrateWorkClaims implements Action {
  readonly type = HYDRATE_WORK_CLAIMS;
  constructor(public payload: any) {}
}
export class HydrateAllClaims implements Action {
  readonly type = HYDRATE_ALL_CLAIMS;
  constructor(public payload: any) {}
}
export class ResetWork implements Action {
  readonly type = RESET_WORK;
  constructor() {}
}
export class SaveScrollBySection implements Action {
  readonly type = SAVE_SCROLL_BY_SECTION;
  constructor(public payload: any) {}
}

export class BeginEditClaim implements Action {
  readonly type = BEGIN_EDIT_CLAIM;
  constructor(public payload: any) {}
}
export class SetEditClaim implements Action {
  readonly type = SET_EDIT_CLAIM;
  constructor(public payload: any) {}
}
export class EndEditClaim implements Action {
  readonly type = END_EDIT_CLAIM;
  constructor() {}
}

export class EditClaimSuccess implements Action {
  readonly type = EDIT_CLAIM_SUCCESS;

  constructor() {}
}

export class EditClaimFail implements Action {
  readonly type = EDIT_CLAIM_FAIL;
  constructor(public payload: { error: any }) {}
}

export class SetDummyIp implements Action {
  readonly type = SET_DUMMY_IP;
  constructor(public payload: { dummyIpPayload: PutNewIpPayload; mode: 'add' | 'edit' | 'delete' }) {}
}
export class SetDummyIpEmpty implements Action {
  readonly type = SET_DUMMY_IP_EMPTY;
}

export class SetDummyIpSuccess implements Action {
  readonly type = SET_DUMMY_IP_SUCCESS;
  constructor(public payload: any) {}
}

export class SetDummyIpError implements Action {
  readonly type = SET_DUMMY_IP_ERROR;
  constructor(public payload: any) {}
}

export class ResendActivationEmail implements Action {
  readonly type = RESEND_ACTIVATION_EMAIL;
}

export class ResendActivationEmailSuccess implements Action {
  readonly type = RESEND_ACTIVATION_EMAIL_SUCCESS;
  constructor(public payload: string) {}
}

export class ResendActivationEmailFail implements Action {
  readonly type = RESEND_ACTIVATION_EMAIL_FAIL;
  constructor(public payload: string) {}
}
export class GetSearchScrollPagination implements Action {
  readonly type = GET_SEARCH_SCROLL_PAGINATION;
}

export class GetItem implements Action {
  readonly type = GET_ITEM;

  constructor(public payload: CopyrightDetailRequest) {}
}

export class GetItemToEdit implements Action {
  readonly type = GET_ITEM_TO_EDIT;

  constructor(public payload: CopyrightDetailRequest) {}
}

export class GetItemSuccess implements Action {
  readonly type = GET_ITEM_SUCCESS;

  constructor(public payload: { [section: string]: any }) {}
}

export class GetItemFail implements Action {
  readonly type = GET_ITEM_FAIL;

  constructor(public payload?: IceErrorPayload) {}
}

export class GetAccessFail implements Action {
  readonly type = GET_ACCESS_FAIL;

  constructor(public payload?: IceErrorPayload) {}
}

export class AuthError implements Action {
  readonly type = AUTH_ERROR;

  constructor(public payload?: IceErrorPayload) {}
}

export class GetItemNotFound implements Action {
  readonly type = GET_ITEM_NOT_FOUND;
}

export class SaveWorkBatchSuccess implements Action {
  readonly type = SAVE_WORK_BATCH_SUCCESS;

  constructor(public payload: string) {}
}

export class SaveWorkBatchError implements Action {
  readonly type = SAVE_WORK_BATCH_ERROR;

  constructor(public payload: string) {}
}

export class GetDetailPageSuccess implements Action {
  readonly type = GET_DETAIL_PAGE_SUCCESS;

  constructor(public payload: any) {}
}
export class GetItemFullDataEnd implements Action {
  readonly type = GET_ITEM_FULL_DATA_END;
}

export class SwitchOwnershipTableData implements Action {
  readonly type = SWITCH_OWNERSHIP_TABLE_DATA;

  constructor(public payload: boolean) {}
}

export class AssignOwnerhipToItems implements Action {
  readonly type = ASSIGN_OWNERSHIP_TO_ITEMS;

  constructor(public payload: any) {}
}

export class SetWorkAnalysis implements Action {
  readonly type = SET_WORK_ANALYSIS;

  constructor(public payload: any) {}
}

export class GetWorkToCompare implements Action {
  readonly type = GET_WORK_TO_COMPARE;

  constructor(public payload: CopyrightDetailRequest) {}
}

export class SetClaimsFilterToCompare implements Action {
  readonly type = SET_CLAIMS_FILTER_TO_COMPARE;

  constructor(public payload: any) {}
}

export class ResetWorkToCompare implements Action {
  readonly type = RESET_WORK_TO_COMPARE;
}

export class CancelRequest implements Action {
  readonly type = CANCEL_REQUEST;
}

export class EditItem implements Action {
  readonly type = EDIT_ITEM;
}

export class StartUpdateMode implements Action {
  readonly type = START_UPDATE_MODE;
  constructor(public payload: UpdateMode) {}
}

export class SetUpdateModeBulk implements Action {
  readonly type = SET_UPDATE_MODE_BULK;
  constructor(public payload: { items: any[] }) {}
}

export class SwitchUpdateMode implements Action {
  readonly type = SWITCH_UPDATE_MODE;
  constructor(public payload: UpdateMode) {}
}

export class EndUpdateMode implements Action {
  readonly type = END_UPDATE_MODE;
}

export class StartEditMode implements Action {
  readonly type = START_EDIT_MODE;
  constructor(public payload: EditMode) {}
}

export class ForceEditMode implements Action {
  readonly type = FORCE_EDIT_MODE;
  constructor(public payload: EditMode) {}
}

export class SaveEditModeHistory implements Action {
  readonly type = SAVE_EDIT_MODE_HISTORY;
  constructor(public payload: EditModeHistory) {}
}

export class StartSelectMode implements Action {
  readonly type = START_SELECT_MODE;
  constructor(public payload?: SelectModeEnum) {}
}

export class EndSelectMode implements Action {
  readonly type = END_SELECT_MODE;
  constructor() {}
}

export class ClearSelectMode implements Action {
  readonly type = CLEAR_SELECT_MODE;
  constructor() {}
}

export class SetSelectMode implements Action {
  readonly type = SET_SELECT_MODE;
  constructor(public payload: any[]) {}
}

export class SubmitSelectMode implements Action {
  readonly type = SUBMIT_SELECT_MODE;
  constructor() {}
}

export class StartExportMode implements Action {
  readonly type = START_EXPORT_MODE;
  constructor(public payload: ExportMode) {}
}

export class SetExportModeBatch implements Action {
  readonly type = SET_EXPORT_BATCH;
  constructor(
    public payload: { batch?: any; add?: boolean; visibleColumns?: string[]; queueChunckSize?: number; columnLabelDictionary?: { [id: string]: string }; apiCall?: ApiCall },
  ) {}
}

export class PauseExportMode implements Action {
  readonly type = PAUSE_EXPORT_MODE;
}

export class SetExportModeType implements Action {
  readonly type = SET_EXPORT_TYPE;
  constructor(public payload: ExportModeType) {}
}

export class SetUpdateModeType implements Action {
  readonly type = SET_UPDATE_TYPE;
  constructor(public payload: UpdateModeType) {}
}

export class ResetExportMode implements Action {
  readonly type = RESET_EXPORT_MODE;
}

export class CancelDownload implements Action {
  readonly type = CANCEL_DOWNLOAD;
}

export class GetDataFromQueue implements Action {
  readonly type = GET_DATA_FROM_QUEUE;
}

export class ExportData implements Action {
  readonly type = EXPORT_DATA;
  constructor(public payload?: FileType | UpdateModeType) {}
}

/**
 * Calls action of type `DATA_EXPORTER` to export data
 * and let the user download a file.
 *
 * @param {Object} payload - The payload object.
 * @param {string} payload.name - The name to be used as filename.
 * @param {ExportConfig} payload.config - The configuration for export mode.
 */
export class DataExporter implements Action {
  readonly type = DATA_EXPORTER;
  constructor(public payload: { name: string; config: ExportMode }) {}
}

export class ExportDataSuccess implements Action {
  readonly type = EXPORT_DATA_SUCCESS;
  constructor(public payload: { name: string }) {}
}

export class EndExportMode implements Action {
  readonly type = END_EXPORT_MODE;
}

export class UpdateField implements Action {
  readonly type = UPDATE_FIELD;
  constructor(public payload: EditAction) {}
}

export class UpdateFieldSuccess implements Action {
  readonly type = UPDATE_FIELD_SUCCESS;
  constructor(public section: string, public value: any) {}
}

export class UpdateFieldFail implements Action {
  readonly type = UPDATE_FIELD_FAIL;
}

export class EndEditMode implements Action {
  readonly type = END_EDIT_MODE;
  constructor(public payload?: { pathOnEnd: string }) {}
}

export class EndEditModeNewItem implements Action {
  readonly type = END_EDIT_MODE_NEW_ITEM;
  constructor(public payload?: { pathOnEnd: string }) {}
}

export class CreateItem implements Action {
  readonly type = CREATE_ITEM;

  constructor(public payload?: EditMode) {}
}

export class CloneItem implements Action {
  readonly type = CLONE_ITEM;

  constructor(public payload: EditMode) {}
}

export class FinishCloneItem implements Action {
  readonly type = FINISH_CLONE_ITEM;

  constructor(public payload: string) {}
}

export class ResetCreateMode implements Action {
  readonly type = RESET_CREATE_MODE;

  constructor() {}
}

export class SetItemView implements Action {
  readonly type = SET_ITEM_VIEW;

  constructor(public payload: CopyrightItemView) {}
}
export class GetSharePicture implements Action {
  readonly type = GET_SHARE_PICTURE;

  constructor(public payload?: PayloadSharePicture, public saveSettings = false, public saveDefaultSettings = false) {}
}

export class RetrieveSharePictureFromCache implements Action {
  readonly type = RETRIEVE_SHARE_PICTURE_FROM_CACHE;

  constructor(public payload: string) {}
}
export class GetSharePictureSuccess implements Action {
  readonly type = GET_SHARE_PICTURE_SUCCESS;

  constructor(public payload: SharePicture, public saveSettings = false, public saveDefaultSettings = false) {}
}

export class SaveSharesData implements Action {
  readonly type = SAVE_SHARES_DATA;

  constructor(public payload: { share: Share }) {}
}

export class GetSharePictureFail implements Action {
  readonly type = GET_SHARE_PICTURE_FAIL;

  constructor(public payload?: IceErrorPayload) {}
}

export class ClearSharePicture implements Action {
  readonly type = CLEAR_SHARE_PICTURE;
  constructor() {}
}

export class GetNotesSuccess implements Action {
  readonly type = GET_NOTES_SUCCESS;
  constructor(public payload: PayloadGetNotesSuccess) {}
}

export class GetNotesFail implements Action {
  readonly type = GET_NOTES_FAIL;
  constructor(error?) {}
}

export class ClearNotes implements Action {
  readonly type = CLEAR_NOTES;
  constructor(public payload: { section: string }) {}
}

export class UpdateNotes implements Action {
  readonly type = UPDATE_NOTES;

  constructor(public payload: { attributes: any; notSaveResponse?: boolean; customAPISection?: string; customId?: string; avoidSnackbar?: boolean }) {}
}

export class UpdateNotesSuccess implements Action {
  readonly type = UPDATE_NOTES_SUCCESS;
  constructor() {}
}

export class UpdateNotesFail implements Action {
  readonly type = UPDATE_NOTES_FAIL;
  constructor(error?) {}
}

export class NewNote implements Action {
  readonly type = NEW_NOTE;
  constructor(public payload: { currentNotes: any }) {}
}

export class UpdateNote implements Action {
  readonly type = UPDATE_NOTE;
  constructor(public payload: { rowNote: RowNote; currentNotes: any }) {}
}

export class DeleteNote implements Action {
  readonly type = DELETE_NOTE;
  constructor(public payload: { deleteRowNote: RowNote; currentNotes: any }) {}
}

export class ResetDialogSearchData implements Action {
  readonly type = RESET_DIALOG_SEARCH_DATA;
}

export class UpdateIpsRelationsSelect implements Action {
  readonly type = UPDATE_IPS_RELATIONS_SELECT;
  constructor(public payload: { ipSelected: IpCleaned; ipiType: string; baseKey: string; nameKey: string; addByBaseValue: boolean }) {}
}

export class UpdateIpsRelationsFail implements Action {
  readonly type = UPDATE_IPS_RELATIONS_FAIL;
  constructor(public payload?: IceErrorPayload) {}
}

export class CleanLoading implements Action {
  readonly type = CLEAN_LOADING;
}

export class CleanResponse implements Action {
  readonly type = CLEAN_RESPONSE;
}

export class SaveSectionItem implements Action {
  readonly type = SAVE_SECTION_ITEM;
  constructor(public payload?: { onSuccess?: Function }) {}
}

export class RegisterIPsOnICEparties implements Action {
  readonly type = REGISTER_IPS_ON_ICE_PARTIES;
  constructor(public payload?: any) {}
}

export class SaveSectionItemFail implements Action {
  readonly type = SAVE_SECTION_ITEM_FAIL;

  constructor(public payload?: IceErrorPayload) {}
}

export class SaveSectionItemSuccess implements Action {
  readonly type = SAVE_SECTION_ITEM_SUCCESS;
  constructor(public payload: any) {}
}

export class MarkPopupOk implements Action {
  readonly type = MARK_POPUP_OK;
}

export class UnmarkPopupOk implements Action {
  readonly type = UNMARK_POPUP_OK;
}

export class IceError implements Action {
  readonly type = ICE_ERROR;
  constructor(public payload: IceErrorPayload) {}
}

export class ResetStoreParam implements Action {
  readonly type = RESET_STORE_PARAM;
  constructor(public payload: { section: string; value: any }) {}
}

export class ResetDeleteItem implements Action {
  readonly type = RESET_DELETE_ITEM;
}

export class DeleteItem implements Action {
  readonly type = DELETE_ITEM;
}

export class DeleteItemSuccess implements Action {
  readonly type = DELETE_ITEM_SUCCESS;
  constructor(public payload: { text: string }) {}
}

export class DeleteItemFail implements Action {
  readonly type = DELETE_ITEM_FAIL;
  constructor(public payload: string) {}
}

export class UpdateCounterClaimCCPartiesOnlyFilter implements Action {
  readonly type = UPDATE_COUNTERCLAIM_CCPARTIESONLY_FILTER;

  constructor(public payload: boolean) {}
}

export class SetCounterclaimDashboardSearchFilter implements Action {
  readonly type = SET_COUNTERCLAIM_DASHBOARD_SEARCH_FILTER;

  constructor(public payload?: any) {}
}

export class SubmitAuditHistoryFilter implements Action {
  readonly type = SUBMIT_AUDIT_HISTORY_FILTER;

  constructor(public payload: PayloadAuditHistoryFilter) {}
}

export class AuditHistoryFilterSuccess implements Action {
  readonly type = AUDIT_FILTER_HISTORY_SUCCESS;

  constructor(public payload: { auditHistory: AuditHistory[]; section: string; filterQuery: string; filterValues: Object }) {}
}

export class AuditHistoryFilterFail implements Action {
  readonly type = AUDIT_FILTER_HISTORY_FAIL;

  constructor(public payload?: IceErrorPayload) {}
}

export class CleanDetail implements Action {
  readonly type = CLEAN_DETAIL;

  constructor(public payload: any) {}
}
export class SubmitTerritoriesFilter implements Action {
  readonly type = SUBMIT_TERRITORIES_FILTER;

  constructor(public payload: PayloadTerritoriesFilter) {}
}

export class GetTerritoryFilteredSuccessorsSuccess implements Action {
  readonly type = TERRITORY_FILTERED_SUCCESSORS_SUCCESS;

  constructor(public payload: PayloadTerritoriesFilter) {}
}

export class GetTerritoryFilteredPredecessorsSuccess implements Action {
  readonly type = TERRITORY_FILTERED_PREDECESSORS_SUCCESS;

  constructor(public payload: PayloadTerritoriesFilter) {}
}

export class WorkResolution implements Action {
  readonly type = WORK_RESOLUTION;
  constructor(public payload: any) {}
}

export class WorkResolutionSuccess implements Action {
  readonly type = WORK_RESOLUTION_SUCCESS;
  constructor(public payload: any) {}
}

export class WorkResolutionFail implements Action {
  readonly type = WORK_RESOLUTION_FAIL;
  constructor(public payload: string) {}
}

export class SetRetentionWorks implements Action {
  readonly type = SET_RETENTION_WORKS;
  constructor(public payload: any) {}
}

export class GetRelationRelatedWork implements Action {
  readonly type = GET_RELATION_RELATED_WORK;
  constructor(public payload: { id; intExt; relationType }) {}
}
export class AddWorkRelation implements Action {
  readonly type = ADD_WORK_RELATION;
  constructor(public payload: { workRelationData: any; relationType: string; intExt: string; relationTypeDisplay: string }) {}
}

export class ActivateUser implements Action {
  readonly type = ACTIVATE_USER;
}

export class DisableUser implements Action {
  readonly type = DISABLE_USER;
}

export class StartApiCall implements Action {
  readonly type = START_API_CALL;

  constructor(public payload: ApiCallPayload, public prevResponse?: any) {}
}

export class FetchApiCall implements Action {
  readonly type = FETCH_API_CALL;

  constructor(public payload: CustomApiCall) {}
}

export class PaginateApiCall implements Action {
  readonly type = PAGINATE_API_CALL;
  constructor(public apiCallConfig?: ApiCallConfig) {}
}

export class ApiCallError implements Action {
  readonly type = API_CALL_ERROR;
  // TODO: what to do with this payload? should we not use it to display feedback to user?
  // looks like the purpose is to re-try the api call instead.
  constructor(public payload: { apiCallConfig: CustomApiCall; result?: any; error: any }) {}
}

export class ApiCallRetry implements Action {
  readonly type = API_CALL_RETRY;
  constructor(public payload: { apiCallConfig: CustomApiCall; error: any }) {}
}

export class ApiCallSuccess implements Action {
  readonly type = API_CALL_SUCCESS;
  constructor(public payload: { apiCallConfig: CustomApiCall; result: any; newItems: any; section: string; error?: any }) {}
}

export class SetAllClaimsFilter implements Action {
  readonly type = SET_ALL_CLAIMS_FILTER;

  constructor(public payload: typeof DEFAULT_FILTER_MODEL) {}
}

export class SetCopyrightLoading implements Action {
  readonly type = SET_COPYRIGHT_LOADING;

  constructor(public payload: boolean) {}
}

export class UploadResponse implements Action {
  readonly type = UPLOAD_RESPONSE;

  constructor(public payload: any) {}
}

export class UploadUsingPreSignedURL implements Action {
  readonly type = UPLOAD_USING_PRE_SIGNED_URL;

  constructor(public payload: { url: string; file: any; sourceId: string }) {}
}
export class EnableDownloadDocument implements Action {
  readonly type = ENABLE_DOWNLOAD_DOCUMENT;
}
export class DisableDownloadDocument implements Action {
  readonly type = DISABLE_DOWNLOAD_DOCUMENT;
}
export class SaveDownloadDocuments implements Action {
  readonly type = SAVE_DOWNLOAD_DOCUMENTS;

  constructor(public payload: any) {}
}
export class DownloadDocuments implements Action {
  readonly type = DOWNLOAD_DOCUMENTS;
}
export class EnableRejectDocument implements Action {
  readonly type = ENABLE_REJECT_DOCUMENT;
}
export class DisableRejectDocument implements Action {
  readonly type = DISABLE_REJECT_DOCUMENT;
}
export class SaveRejectDocuments implements Action {
  readonly type = SAVE_REJECT_DOCUMENTS;

  constructor(public payload: any) {}
}
export class SendRejectDocuments implements Action {
  readonly type = SEND_REJECT_DOCUMENTS;

  constructor(public payload: any) {}
}
export class SendRejectDocumentsFail implements Action {
  readonly type = SEND_REJECT_DOCUMENTS_FAIL;

  constructor(public payload: any) {}
}
export class SendRejectDocumentsSuccess implements Action {
  readonly type = SEND_REJECT_DOCUMENTS_SUCCESS;

  constructor(public payload: any) {}
}

export class GetReportTypes implements Action {
  readonly type = GET_REPORT_TYPES;
}

export class GetReportTypesSuccess implements Action {
  readonly type = GET_REPORT_TYPES_SUCCESS;
  constructor(public payload: any[]) {}
}

export class GetReportTypesFail implements Action {
  readonly type = GET_REPORT_TYPES_FAIL;
  constructor(public payload) {}
}

export class GetReport implements Action {
  readonly type = GET_REPORT;
  constructor(public payload) {}
}

export class GetReportSuccess implements Action {
  readonly type = GET_REPORT_SUCCESS;
  constructor() {}
}

export class GetReportFail implements Action {
  readonly type = GET_REPORT_FAIL;
  constructor(public payload) {}
}

export class DownloadReport implements Action {
  readonly type = DOWNLOAD_REPORT;
  constructor(public payload) {}
}

export class CloneReport implements Action {
  readonly type = CLONE_REPORT;
  constructor(public payload) {}
}

export class CloneReportSuccess implements Action {
  readonly type = CLONE_REPORT_SUCCESS;
  constructor(public payload) {}
}

export class CloneReportFail implements Action {
  readonly type = CLONE_REPORT_FAIL;
  constructor(public payload) {}
}

export class CancelApiCall implements Action {
  readonly type = CANCEL_API_CALL;
  constructor(public payload?: { apiCallConfig: CustomApiCall }) {}
}

export class ApiCallCheckStatusProcess implements Action {
  readonly type = API_CALL_CHECK_STATUS_PROCESS;
  constructor(public payload: any) {}
}

export class RefreshDetail implements Action {
  readonly type = REFRESH_DETAIL;
}
export class SetSelectShareTarget implements Action {
  readonly type = SET_SELECT_SHARE_TARGET;
  constructor(public payload) {}
}
export class DeleteFail implements Action {
  readonly type = DELETE_FAIL;
  constructor(public payload?: IceErrorPayload) {}
}

export class SetWorkFilter implements Action {
  readonly type = SET_WORK_FILTER;
  constructor(public payload: object) {}
}

export class SetSelectedOptionToCompare implements Action {
  readonly type = SET_SELECTED_OPTION_TO_COMPARE;

  constructor(public payload: CopyrightDetailRequest) {}
}

export class StartMergeIpMode implements Action {
  readonly type = START_MERGE_IP_MODE;
  constructor() {}
}

export class SetMergeIpItems implements Action {
  readonly type = SET_MERGE_IP_ITEMS;
  constructor(public payload: any) {}
}

export class EndMergeIpMode implements Action {
  readonly type = END_MERGE_IP_MODE;
  constructor() {}
}

export class StartMergingIP implements Action {
  readonly type = START_MERGING_IP;
  constructor() {}
}

export class EndMergingIP implements Action {
  readonly type = END_MERGING_IP;
  constructor() {}
}

export class SaveAllUsers implements Action {
  readonly type = SAVE_ALL_USERS;
  constructor(public payload: any) {}
}
export class UpsertUsers implements Action {
  readonly type = UPSERT_USERS;
  constructor(public payload: any) {}
}

export class SaveAllApiData implements Action {
  readonly type = SAVE_ALL_API_DATA;
  constructor(public payload: any) {}
}

export class ToggleTransferIpWorks implements Action {
  readonly type = TOGGLE_TRANSFER_IP_WORKS;
  constructor(public payload: { transferIpWorksMode: boolean }) {}
}

export class SetTransferIpWorksInfo implements Action {
  readonly type = SET_TRANSFER_IP_WORKS_INFO;
  constructor(public payload: any) {}
}

export class GetAllTerritories implements Action {
  readonly type = GET_ALL_TERRITORIES;
  constructor() {}
}

export class GetOptionsToCompareDetails implements Action {
  readonly type = GET_OPTION_TO_COMPARE_DETAILS;

  constructor(public payload: any[]) {}
}
export class SetOptionsToCompare implements Action {
  readonly type = SET_OPTIONS_TO_COMPARE;

  constructor(public payload: any[]) {}
}

export class GetAllTerritoriesSuccess implements Action {
  readonly type = GET_ALL_TERRITORIES_SUCCESS;
  constructor() {}
}

export class GetAllTerritoriesFail implements Action {
  readonly type = GET_ALL_TERRITORIES_FAIL;
  constructor(public payload?: IceErrorPayload) {}
}

export class SaveAllTerritories implements Action {
  readonly type = SAVE_ALL_TERRITORIES;
  constructor(public payload: any) {}
}

export class UpsertAgreementChains implements Action {
  readonly type = UPSERT_AGREEMENT_CHAINS;
  constructor(public payload: AgreementItem[]) {}
}

export class SortedAgreementChains implements Action {
  readonly type = SORTED_AGREEMENT_CHAINS;
  constructor(public payload: { tableData: AgreementItem['id'][]; tableMap: TableMap }) {}
}

export class UpdateAgreementChain implements Action {
  readonly type = UPDATE_AGREEMENT_CHAIN;
  constructor(public payload: Update<AgreementItem>) {}
}

export class IgnoreMergeClaims implements Action {
  readonly type = IGNORE_MERGE_CLAIMS;
  constructor(public payload: Array<{ claimId: string; ipiNameKey: string; name: string }>) {}
}

export type CopyrightActions =
  | GetItem
  | GetItemToEdit
  | GetItemSuccess
  | GetItemFail
  | GetItemNotFound
  | GetItemFullDataEnd
  | GetSharePicture
  | GetSharePictureSuccess
  | GetSharePictureFail
  | ClearSharePicture
  | SwitchOwnershipTableData
  | GetWorkToCompare
  | CancelRequest
  | EditItem
  | StartEditMode
  | ForceEditMode
  | SaveEditModeHistory
  | UpdateField
  | UpdateFieldSuccess
  | UpdateFieldFail
  | EndEditMode
  | EndEditModeNewItem
  | CreateItem
  | CloneItem
  | FinishCloneItem
  | ResetCreateMode
  | SetItemView
  | GetNotesSuccess
  | GetNotesFail
  | ClearNotes
  | UpdateNotes
  | UpdateNotesSuccess
  | UpdateNotesFail
  | NewNote
  | UpdateNote
  | DeleteNote
  | UpdateIpsRelationsSelect
  | UpdateIpsRelationsFail
  | CleanLoading
  | CleanResponse
  | SaveSectionItem
  | SaveSectionItemFail
  | SaveSectionItemSuccess
  | MarkPopupOk
  | UnmarkPopupOk
  | IceError
  | AuthError
  | AssignOwnerhipToItems
  | ResetStoreParam
  | SetWorkAnalysis
  | ResetWorkToCompare
  | DeleteItem
  | DeleteItemSuccess
  | DeleteItemFail
  | ResetDeleteItem
  | SaveSharesData
  | RetrieveSharePictureFromCache
  | UpdateCounterClaimCCPartiesOnlyFilter
  | SetCounterclaimDashboardSearchFilter
  | SubmitAuditHistoryFilter
  | AuditHistoryFilterSuccess
  | AuditHistoryFilterFail
  | CleanDetail
  | SubmitTerritoriesFilter
  | GetTerritoryFilteredSuccessorsSuccess
  | GetTerritoryFilteredPredecessorsSuccess
  | WorkResolution
  | WorkResolutionSuccess
  | WorkResolutionFail
  | StartExportMode
  | EndExportMode
  | SetExportModeBatch
  | SetExportModeType
  | ResetExportMode
  | CancelDownload
  | GetDataFromQueue
  | ExportData
  | ExportDataSuccess
  | DataExporter
  | GetSearchScrollPagination
  | GetDetailPageSuccess
  | SetClaimsFilterToCompare
  | StartUpdateMode
  | SetUpdateModeBulk
  | SwitchUpdateMode
  | EndUpdateMode
  | SetUpdateModeType
  | SetRetentionWorks
  | SetUpdateModeType
  | GetRelationRelatedWork
  | AddWorkRelation
  | SaveWorkBatchError
  | SaveWorkBatchSuccess
  | ActivateUser
  | DisableUser
  | ResendActivationEmailSuccess
  | SetDummyIp
  | SetDummyIpEmpty
  | SetDummyIpError
  | SetDummyIpSuccess
  | EditClaimSuccess
  | EditClaimFail
  | SaveScrollBySection
  | BeginEditClaim
  | SetEditClaim
  | EndEditClaim
  | ResetWork
  | SetAllClaimsFilter
  | StartSelectMode
  | EndSelectMode
  | SetSelectMode
  | ClearSelectMode
  | SetCopyrightLoading
  | UploadUsingPreSignedURL
  | UploadResponse
  | EnableDownloadDocument
  | DisableDownloadDocument
  | SaveDownloadDocuments
  | DownloadDocuments
  | GetReportTypes
  | GetReportTypesSuccess
  | GetReportTypesFail
  | GetReport
  | GetReportSuccess
  | GetReportFail
  | CloneReport
  | DownloadReport
  | CloneReportSuccess
  | CloneReportFail
  | EnableRejectDocument
  | DisableRejectDocument
  | SaveRejectDocuments
  | SendRejectDocuments
  | SendRejectDocumentsFail
  | SendRejectDocumentsSuccess
  | StartApiCall
  | ApiCallError
  | ApiCallSuccess
  | CancelApiCall
  | ApiCallCheckStatusProcess
  | RefreshDetail
  | SetSelectShareTarget
  | FetchApiCall
  | PaginateApiCall
  | DeleteFail
  | HydrateWorkClaims
  | HydrateAllClaims
  | ApiCallRetry
  | PauseExportMode
  | SetWorkFilter
  | ResetDialogSearchData
  | CloneItemInPlace
  | SetEditModeFromCreateMode
  | SetSelectedOptionToCompare
  | StartMergeIpMode
  | SetMergeIpItems
  | EndMergeIpMode
  | StartMergingIP
  | EndMergingIP
  | SaveAllUsers
  | UpsertUsers
  | SaveAllApiData
  | ToggleTransferIpWorks
  | SetTransferIpWorksInfo
  | GetAllTerritories
  | SaveAllTerritories
  | GetAllTerritoriesSuccess
  | GetAllTerritoriesFail
  | RegisterIPsOnICEparties
  | GetOptionsToCompareDetails
  | SetOptionsToCompare
  | UpsertAgreementChains
  | SortedAgreementChains
  | UpdateAgreementChain
  | IgnoreMergeClaims;
