import { OrganizationType, ORGANIZATION_POLICIES } from 'config/constants/organizations.constants';
import { get } from 'lodash';
import { OrganizationDetail } from 'models/copyright/detail/organizations';
import { SocietiesUtils } from '../societies/societies.utils';
export class OrganizationUtils {
  static isTypeSociety(item: OrganizationDetail): boolean {
    return item && item.attributes && item.attributes.type === OrganizationType.society;
  }

  static containsSociety(item: OrganizationDetail): boolean {
    return this.isTypeSociety(item) && item.attributes.access && item.attributes.access.societies !== undefined;
  }

  static getOrganizationSocietyString(item: OrganizationDetail): string {
    return this.containsSociety(item) && SocietiesUtils.searchSocietyNameById(get(item.attributes.access, 'societies[0]'));
  }

  static getOrganizationSociety(item: OrganizationDetail): string {
    return this.containsSociety(item) && get(item.attributes.access, 'societies[0]');
  }

  static getOrganizationRoles(type, id) {
    return Object.keys(ORGANIZATION_POLICIES[type]).map(role => ({
      organizationId: id,
      name: role,
      version: 1,
      policies: ORGANIZATION_POLICIES[type][role].map(policy => ({
        name: role,
        organizationId: id,
        policyId: policy,
        version: 1,
      })),
    }));
  }
}
