import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HighlightModule } from './highlight.module';
import { QueryEditorComponent } from './query-editor.component';
import { TypeaheadModule } from './typeahead.module';

@NgModule({
  declarations: [QueryEditorComponent],
  imports: [CommonModule, HighlightModule, TypeaheadModule, FormsModule],
  exports: [QueryEditorComponent],
})
export class QueryEditorModule {}
