import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'ice-formly-search-info',
  templateUrl: './wrapper-search.component.html',
  styleUrls: ['./wrapper-search.component.scss'],
})
export class WrapperSearchComponent extends FieldWrapper {
  @ViewChild('fieldComponent', { read: ViewContainerRef, static: true }) fieldComponent: ViewContainerRef;

  search(event) {
    event.preventDefault();
    event.stopPropagation();
    this.to.onSearch();
  }
}
