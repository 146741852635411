import { createSelector } from '@ngrx/store';
import * as fromFeature from 'store/root/reducers';
import * as fromReducer from 'store/root/reducers/copyright/copyright.reducer';

export const getCopyrightAgreementGroup = createSelector(fromFeature.getCopyrightFeatureState, fromReducer.getCopyrightAgreementGroupState);

export const getRelatedAgreements = createSelector(getCopyrightAgreementGroup, (detail: any) => (detail && detail.relatedAgreements) || []);

export const getIncludedAgreements = createSelector(getCopyrightAgreementGroup, (detail: any) => (detail && detail.includedAgreements) || []);

export const getAgreementGroupAuditHistory = createSelector(getCopyrightAgreementGroup, (detail: any) => (detail && detail.auditHistory ? detail.auditHistory.items : []));
