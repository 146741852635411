import { Injectable } from '@angular/core';
import { CounterClaimClaimantRelation, CounterClaimClaimantStatus, PointOfConflict } from '@ice';
import { CounterClaimResolutionOwnerType, CounterClaimType } from 'config/constants/activity.constants';
import moment from 'moment';
import { ExpertSearchCommonFunctions } from './expert-search-common-functions';
import { ExpertSearchParseService } from './expert-search-parse.service';
import { SearchService } from './search.service';

@Injectable()
export class ExpertSearchParseCounterClaimsService extends ExpertSearchParseService {
  constructor(private searchService: SearchService) {
    super(
      {
        ['counterClaimStatus']: Object.keys(CounterClaimClaimantStatus),
        ['counterClaimType']: Object.keys(CounterClaimType),
        ['pointOfConflict']: Object.keys(PointOfConflict),
        ['resolutionOwners']: Object.keys(CounterClaimResolutionOwnerType),
        ['counterClaimRelationType']: Object.keys(CounterClaimClaimantRelation),
      },
      {
        workTitle: 'work.attributes.titles.*.titleValue',
        workRef: 'attributes.workId',
        agreementRef: async (comparator: string, value: string, deep = false) => ExpertSearchCommonFunctions.agreementRefFunction(searchService, comparator, value, deep),
        counterClaimId: 'id',
        counterClaimType: 'attributes.type',
        counterClaimStatus: 'attributes.status',
        pointOfConflict: 'attributes.pointOfConflict',
        resolutionOwners: 'attributes.resolutionOwners',
        counterClaimCreationFrom: async (comparator: string, value: string, _deep = false) => ({
          and: [{ range: { 'attributes.createdDate': { gte: moment(value).format('YYYY-MM-DD') } } }],
        }),
        counterClaimCreationTo: async (comparator: string, value: string, _deep = false) => ({
          and: [{ range: { 'attributes.createdDate': { lte: moment(value).format('YYYY-MM-DD') } } }],
        }),
        counterClaimResolutionFrom: async (comparator: string, value: string, _deep = false) => ({
          and: [{ range: { 'attributes.resolvedDate': { gte: moment(value).format('YYYY-MM-DD') } } }],
        }),
        counterClaimResolutionTo: async (comparator: string, value: string, _deep = false) => ({
          and: [{ range: { 'attributes.resolvedDate': { lte: moment(value).format('YYYY-MM-DD') } } }],
        }),
        counterClaimParty: 'participants.claimant.relations[XREF].otherId',
        counterClaimRelationType: 'participants.relation',
        actionType: 'actions.attributes.type',
        actionStatus: 'actions.attributes.status',
        actionParticipant: async (comparator: string, value: string, _deep = false) => ({
          and: [
            {
              and: [
                { [comparator]: { 'participants.claimant.relations[XREF].otherId': `${value}` } },
                { equals: { 'participants.relation': `${CounterClaimClaimantRelation.RESOLVER}` } },
              ],
            },
          ],
        }),
        actionOwner: 'actions.attributes.owner',
        actionUser: 'actions.attributes.assignor',
        actionDeadlineFrom: async (comparator: string, value: string, _deep = false) => ({
          and: [{ range: { 'actions.attributes.deadline': { gte: moment(value).format('YYYY-MM-DD') } } }],
        }),
        actionDeadlineTo: async (comparator: string, value: string, _deep = false) => ({
          and: [{ range: { 'actions.attributes.deadline': { lte: moment(value).format('YYYY-MM-DD') } } }],
        }),
        actionResponse: 'actions.attributes.response',
      },
    );
  }
}
