import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IceMaterialModule } from '@ice/material.module';
import { DatatableSelectComponent } from './datatable-select.component';

@NgModule({
  declarations: [DatatableSelectComponent],
  imports: [CommonModule, ReactiveFormsModule, IceMaterialModule],
  exports: [DatatableSelectComponent],
})
export class DatatableSelectModule {}
