import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DataTableRow } from '@ice/components/data-table/data-table';
import { CounterClaimInActive } from '@ice/components/expert-search-form/model/enums';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { ConflictUtils, CopyrightUtils, TabsUtils } from '@ice';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import { CopyrightAgreementsDataTable } from 'config/data-table-builders/copyright.agreements';
import type { SectionTabConfig } from 'config/tabs-data-builders/tabs-data';
import { IceFacade } from 'facades/ice.facade';
import { cloneDeep, isArray, last, pull } from 'lodash';
import { User } from 'models';
import { BehaviorSubject, of, pipe } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CommonApiService } from 'services/common-api.service';
import { NamespaceService } from 'services/namespace/namespace.service';
import { PermissionsService } from 'services/permissions/permissions.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';
import * as fromActions from 'store/root/actions';

export class TabConflicts implements SectionTabConfig {
  private schema: CopyrightAgreementsDataTable;
  private canViewConflictsValue: boolean;

  private conflictsExpandableProperty$ = new BehaviorSubject('detail');
  constructor(
    protected translate: TranslateService,
    protected fuseTranslationLoader: FuseTranslationLoaderService,
    protected store: Store<fromRoot.RootState>,
    private dialog: MatDialog,
    private commonApiService: CommonApiService,
    private detailService: null,
    private nsService: NamespaceService,
    private iceFacade: IceFacade,
    private fieldValidatorService: FieldValidatorService,
    private permissionsService: PermissionsService,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.canViewConflictsValue = this.permissionsService.can('works_conflicts_list_value_read');
  }

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('WORKS.CONFLICTS_CC.TABLE_TITLE'),
              model: this.store.pipe(
                select(fromRoot.getConflicts),
                map((conflicts: any[]) => {
                  if (isArray(conflicts)) {
                    return conflicts.map(conflict => {
                      const conflictSubTypeTooltip = ConflictUtils.getConflictSubtypesTooltip({ conflict, translate: this.translate });

                      return {
                        ...conflict,
                        scope: conflict.scope.map(scope => {
                          return {
                            ...scope,
                            rights: CopyrightUtils.getRightsLabels(scope.rights ?? [], 'mechanical_performing'),
                            claimShareType: scope.claimShareType && this.translate.instant(`WORKS.DETAILS.CONFLICTS.TABLE.CLAIM_TYPE_${scope.claimShareType.toUpperCase()}`),
                          };
                        }),
                        conflictSubTypeTooltip,
                      };
                    });
                  }
                  return conflicts;
                }),
              ),
              sorts: [],
              schema: this.getConflictsDataTable(),
              loadingIndicator: false,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: true,
              shadowed: false,
              columnMode: 'flex',
              onSelect: event => this.store.dispatch(new fromRoot.GoToConflict({ conflict: event[0] })),
              onMouseSelect: event => this.store.dispatch(new fromRoot.GoToConflict({ conflict: event, openInNewTab: true })),
              getRowClass: (row: any): any => ({ 'ice-search-results-table-row': true }),
              expandRowsEnabled: true,
              expandableProperty: this.conflictsExpandableProperty$,
              expandableSchema: { scope: this.getConflictScopeDataTable() },
              toggleButtons: of({
                defaultValue: 'detail',
                buttons: [
                  {
                    value: 'detail',
                    displayValue: this.translate.instant('WORKS.COUNTERCLAIM.TOGGLE_BUTTONS.SHOW_TRIGGERS'),
                    buttonClass: 'conflict-toggle-button',
                  },
                  {
                    value: 'scope',
                    displayValue: this.translate.instant('WORKS.COUNTERCLAIM.TOGGLE_BUTTONS.SHOW_SCOPE'),
                    buttonClass: 'conflict-toggle-button',
                  },
                ],
                change: event => {
                  this.conflictsExpandableProperty$.next(event.value);
                },
              }),
              visibleColumns: of([...this.getConflictsVisibleColumns(this.getConflictsDataTable()), ...TabsUtils.getSChemaPropsToArray(this.getConflictScopeDataTable())]),
            },
          },
        ],
      },
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('WORKS.COUNTERCLAIM.TABLE_TITLE'),
              model: this.store.pipe(
                select(fromRoot.getCounterClaimList),
                map((counterClaims: any[]) =>
                  counterClaims?.filter(cc => cc?.attributes?.status !== CounterClaimInActive.RESOLVED).map(cc => this.cleanLabels(ConflictUtils.cleanCounterClaimDetail(cc))),
                ),
              ),
              sorts: [{ prop: 'createdDate', dir: 'desc' }],
              schema: this.getCounterClaimsDataTable(),
              loadingIndicator: false,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: true,
              shadowed: false,
              columnMode: 'flex',
              onSelect: event => this.store.dispatch(new fromRoot.GoToCounterclaim({ counterclaim: event[0] })),
              onMouseSelect: event => this.store.dispatch(new fromRoot.GoToCounterclaim({ counterclaim: event, openInNewTab: true })),
              getRowClass: (row: any): any => ({ 'ice-search-results-table-row': true }),
            },
          },
        ],
      },
    ];
  }

  getConflictsDataTable(): DataTableRow[] {
    return [
      {
        name: this.translate.instant('WORKS.CONFLICTS_CC.DATATABLE_FIELDS.CONFLICT_ID'),
        prop: 'conflictId',
        cellClass: 'ice-txt-size-14',
        flexGrow: 1,
      },
      {
        name: this.translate.instant('WORKS.CONFLICTS_CC.DATATABLE_FIELDS.DEADLINE_DATE'),
        prop: 'deadline',
        cellClass: 'ice-txt-size-14',
        flexGrow: 1,
      },
      {
        name: this.translate.instant('WORKS.CONFLICTS_CC.DATATABLE_FIELDS.VALUE'),
        prop: 'value',
        cellClass: 'ice-txt-size-14',
        flexGrow: 1,
      },
      {
        name: this.translate.instant('WORKS.CONFLICTS_CC.DATATABLE_FIELDS.UPLOAD_CAT'),
        prop: 'uploadCat',
        cellClass: 'ice-txt-size-14',
        flexGrow: 1,
      },
      {
        name: this.translate.instant('WORKS.CONFLICTS_CC.DATATABLE_FIELDS.CASE_STATUS'),
        prop: 'conflictStatus',
        cellClass: 'ice-txt-size-14',
        selectable: true,
        callbackSelectOptions: (row: any, event: any) => {
          const newState = event.target.value;
          this.store.dispatch(new fromRoot.SetUpdateConflictType(newState));
          this.store.dispatch(new fromRoot.StartSelectMode());
          this.store.dispatch(new fromRoot.SetSelectMode([row]));
          this.store.pipe(select(fromRoot.getLoginUser), pipe(take(1))).subscribe((user: User) => {
            const userId = user.detail.id;
            this.store.dispatch(new fromRoot.SetAssigneeConflictsSelection({ userId }));
            this.store.dispatch(new fromActions.AssignSelectedConflictsToUser());
          });
        },
        flexGrow: 1.5,
      },
      {
        name: this.translate.instant('WORKS.CONFLICTS_CC.DATATABLE_FIELDS.CONFLICT_SUB_TYPE'),
        prop: 'conflictSubType',
        cellClass: 'ice-txt-size-14',
        flexGrow: 1,
        tooltip: 'conflictSubTypeTooltip',
      },
      {
        name: this.translate.instant('WORKS.CONFLICTS_CC.DATATABLE_FIELDS.ASSIGNEE'),
        prop: 'assignee',
        cellClass: 'ice-txt-size-14',
        flexGrow: 1,
      },
      {
        actionButtonIcon: 'launch',
        flexGrow: 0.4,
        resizeable: false,
        action: field => this.store.dispatch(new fromRoot.GoToConflict({ conflict: field, openInNewTab: true })),
        hideActionButton: row => of(!row.conflictId),
      },
    ];
  }

  getConflictScopeDataTable(): DataTableRow[] {
    return [
      {
        name: this.translate.instant('WORKS.CONFLICTS_CC.DATATABLE_FIELDS.SCOPE_DETAIL.TERRITORIES'),
        prop: 'territoriesTisa',
        tooltip: 'territoriesTooltip',
        tooltipDuration: 250,
        cellClass: 'ice-txt-size-14',
        flexGrow: 1,
      },
      {
        name: this.translate.instant('WORKS.CONFLICTS_CC.DATATABLE_FIELDS.SCOPE_DETAIL.RIGHTS'),
        prop: 'rights',
        cellClass: 'ice-txt-size-14',
        flexGrow: 1,
      },
      {
        name: this.translate.instant('WORKS.CONFLICTS_CC.DATATABLE_FIELDS.SCOPE_DETAIL.CONFLICT_START'),
        prop: 'usageStartDate',
        cellClass: 'ice-txt-size-14',
        flexGrow: 1,
      },
      {
        name: this.translate.instant('WORKS.CONFLICTS_CC.DATATABLE_FIELDS.SCOPE_DETAIL.CONFLICT_END'),
        prop: 'usageEndDate',
        cellClass: 'ice-txt-size-14',
        flexGrow: 1,
      },
      {
        name: this.translate.instant('WORKS.DETAILS.CONFLICTS.TABLE.CLAIM_TYPE'),
        prop: 'claimShareType',
        cellClass: 'ice-txt-size-14',
        flexGrow: 1,
      },
    ];
  }

  protected serverSideSorting() {
    return 0; // return always the same to avoid client side comparission. This column is using serverside
  }

  getCounterClaimsDataTable(): DataTableRow[] {
    return [
      {
        name: this.translate.instant('WORKS.COUNTERCLAIM.FIELDS.COUNTERCLAIM_ID'),
        prop: 'formattedId',
        cellClass: 'ice-txt-size-14',
        flexGrow: 1,
      },
      {
        name: this.translate.instant('WORKS.COUNTERCLAIM.FIELDS.CREATION_DATE'),
        prop: 'createdDate',
        cellClass: 'ice-txt-size-14',
        flexGrow: 1,
      },
      {
        name: this.translate.instant('WORKS.COUNTERCLAIM.FIELDS.OWNER'),
        prop: 'resolutionOwners',
        cellClass: 'ice-txt-size-14',
        flexGrow: 1,
      },
      {
        name: this.translate.instant('WORKS.COUNTERCLAIM.FIELDS.TYPE'),
        prop: 'type',
        cellClass: 'ice-txt-size-14',
        flexGrow: 1,
      },
      {
        name: this.translate.instant('WORKS.COUNTERCLAIM.FIELDS.POINT_CONFLICT'),
        prop: 'pointOfConflict',
        cellClass: 'ice-txt-size-14',
        flexGrow: 1,
      },
      {
        name: this.translate.instant('WORKS.COUNTERCLAIM.FIELDS.STATUS'),
        prop: 'status',
        cellClass: 'ice-txt-size-14',
        flexGrow: 1,
      },
      {
        name: this.translate.instant('WORKS.COUNTERCLAIM.FIELDS.TERRITORY'),
        prop: 'territoriesLabel',
        tooltip: 'territoriesTooltip',
        cellClass: 'ice-txt-size-14',
        flexGrow: 1,
      },
    ];
  }

  private cleanLabels(cc: any): any {
    const clone = cloneDeep(cc);
    return { ...clone, formattedId: last(clone.id.split(':')) };
  }

  private getConflictsVisibleColumns(schema: any): String[] {
    const schemaDatatable = TabsUtils.getSChemaPropsToArray(schema);
    return this.canViewConflictsValue ? schemaDatatable : pull(schemaDatatable, 'value');
  }
}
