import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as englishCommon } from 'assets/i18n/en/app/common/shared';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import { mrRightsNonOnline, mrRightsOnline, prRightsNonOnLine, prRightsOnLine, rpRights, syRights } from 'config/constants/ips.constants';
import { concat, forEach, map as lodashMap } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromRoot from 'store/root';
import { SectionTabConfig } from '../../tabs-data';

export class TabRepertoireRightTypes implements SectionTabConfig {
  constructor(private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService, private store: Store<fromRoot.RootState>) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.fuseTranslationLoader.loadTranslations(englishCommon);
  }

  getConf(): IceGroupComponent[] {
    const cardWithFormHeight = '940px';
    return [
      {
        group: [
          {
            type: 'cardWithForm',
            config: {
              title: this.getTranslate(`PERFORMING_RIGHT_TYPES`),
              model: this.getModel(),
              resetAvailable: false,
              submitAvailable: false,
              formBuilder: this.getPRorMRFieldsConfig(prRightsOnLine, prRightsNonOnLine),
              height: cardWithFormHeight,
            },
          },
          {
            type: 'cardWithForm',
            config: {
              title: this.getTranslate(`MECHANICAL_RIGHT_TYPES`),
              model: this.getModel(),
              resetAvailable: false,
              submitAvailable: false,
              formBuilder: this.getPRorMRFieldsConfig(mrRightsOnline, mrRightsNonOnline),
              height: cardWithFormHeight,
            },
          },
        ],
      },
      {
        group: [
          {
            type: 'cardWithForm',
            config: {
              title: this.getTranslate(`SYNCHRONISATION_RIGHT_TYPES`),
              model: this.getModel(),
              resetAvailable: false,
              submitAvailable: false,
              formBuilder: this.getFieldsConfig(syRights),
            },
          },
          {
            type: 'cardWithForm',
            config: {
              title: this.getTranslate(`PRINT_RIGHT_TYPES`),
              model: this.getModel(),
              resetAvailable: false,
              submitAvailable: false,
              formBuilder: this.getFieldsConfig(rpRights),
            },
          },
        ],
      },
    ];
  }

  getModel(): Observable<any> {
    return this.store.pipe(
      select(fromRoot.getRepertoireRights),
      map(rights => {
        const newRights = {};
        forEach(rights, right => {
          newRights[right] = true;
        });
        return newRights;
      }),
    );
  }

  getPRorMRFieldsConfig(online, nonOnline) {
    let fields = [];
    fields.push(this.getTitleFieldConfig(`ONLINE_IPI_RIGHTS`));
    fields = concat(fields, this.getFieldsConfig(online));
    fields.push(this.getTitleFieldConfig(`NON_ONLINE_IPI_RIGHTS`));
    fields = concat(fields, this.getFieldsConfig(nonOnline));
    return fields;
  }

  getFieldsConfig(rights) {
    return lodashMap(rights, right => {
      return this.getFieldConfig(right);
    });
  }

  getFieldConfig(right: string) {
    const field = {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          className: 'flex-1 ice-checkbox-infix',
          type: 'checkbox',
          key: right,
          defaultValue: false,
          templateOptions: {
            label: `${right} - ${this.translate.instant(`RIGHTS.${right}`)}`,
            disabled: true,
          },
        },
      ],
    };
    return field;
  }

  getTitleFieldConfig(titleTranslateField) {
    return {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          className: 'flex-1',
          template: `<h2>${this.getTranslate(titleTranslateField)}</h2>`,
        },
      ],
    };
  }

  private getTranslate(fieldTranslatePath) {
    return this.translate.instant(`REPERTOIRES.RIGHT_TYPES.${fieldTranslatePath}`);
  }
}
