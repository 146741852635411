import { environment } from 'config/env';
import { ApiNamespace } from 'models';
import { IceUrlItem } from '../actions';

export enum NavigationStates {
  routerRequest = 'ROUTER_REQUEST',
  routerNavigation = 'ROUTER_NAVIGATION',
  routerCancel = 'ROUTER_CANCEL',
  routerError = 'ROUTER_ERROR',
  routerNavigated = 'ROUTER_NAVIGATED',
}

export interface IceNavigationState {
  state: NavigationStates;
  stateStack: NavigationStates[];
  urlStack: IceUrlItem[];
  lastSection: string;
}

export interface GlobalState {
  navigation: IceNavigationState;
  apiNamespace: ApiNamespace;
  forcedNS: boolean;
  forcedNsBackLink: string;
}

export const initialGlobalState: GlobalState = {
  navigation: {
    state: null,
    stateStack: [],
    urlStack: [],
    lastSection: '',
  },
  apiNamespace: environment.apiNamespace,
  forcedNS: false,
  forcedNsBackLink: '',
};
