<ice-typeahead-container iceHighlightContainer>
  <textarea
    #queryInput
    id="advanced-search-input"
    spellcheck="false"
    class="query-editor"
    rows="5"
    iceTypeahead
    [parsed]="parsed"
    iceHighlightElement
    (ngModelChange)="ngModelChange.emit($event)"
    [queryLanguageParser]="queryLanguageParser"
  ></textarea>
  <ice-highlight
    #highlight
    [tags]="parsed?.tags || []"
    [textInputElement]="queryInput"
    (tagClick)="tagClick($event.tag)"
    (tagMouseEnter)="tagMouseEnter.emit($event)"
    (tagMouseLeave)="tagMouseLeave.emit($event)"
  >
  </ice-highlight>
</ice-typeahead-container>
