<div class="editable-datatable-container">
  <div *ngIf="to.config?.rowHeight" class="search-form">
    <mat-icon> search </mat-icon>
    <mat-form-field class="search-field">
      <mat-select [(value)]="searchProperty" attr.data-testid="select-{{ to.key }}">
        <mat-option *ngFor="let option of searchOptions" [value]="option.prop">
          {{ option.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="search-field">
      <input matInput [(ngModel)]="searchValue" />
    </mat-form-field>
    <button [disabled]="!searchValue" mat-raised-button (click)="search()" color="primary">{{ 'FILTER_SEARCH' | translate }}</button>
  </div>
  <div class="datatable-container" #datatable_container [style.minHeight]="currentHeight">
    <ice-data-table
      fxFlex="1"
      *ngIf="to.config; let table"
      [data]="table.data | async"
      [hideEmpty]="table.hideEmpty"
      class="editable-datatable"
      [customClass]="table.customClass"
      [dontStrip]="table.dontStrip"
      [selectionType]="table.selectionType || 'single'"
      [sorts]="table.sorts"
      [schema]="table.schema"
      [fixedHeight]="table.fixedHeight"
      [virtualization]="table.virtualization !== undefined ? table.virtualization : true"
      [rowHeight]="table.rowHeight || 0"
      [columnMode]="table.columnMode ? table.columnMode : 'force'"
      [visibleColumns]="table.visibleColumns"
      [isSelectable]="table.isSelectable"
      [displayCheck]="table.displayCheck"
      [displayRadio]="table.displayRadio"
      [loadingIndicator]="table.loadingIndicator | async"
      [expandRowsEnabled]="table.expandRowsEnabled"
      [messages]="table.messages"
      [isLoadingData]="table.isLoadingData | async"
      [shadowed]="table.shadowed"
      [headerTitle]="table.headerTitle"
      [headerClass]="table.headerClass"
      [addDetailsOnMainDataTable]="table?.addDetailsOnMainDataTable"
      [expandableProperty]="table?.expandableProperty"
      [expandableSchema]="table?.expandableSchema"
      [expandableNoHeader]="table?.expandableNoHeader"
      (selectedRowEmit)="table.onSelect && table.onSelect($event)"
      (mouseWheelClickRowEmit)="table.onMouseSelect && table.onMouseSelect($event)"
      [detailVisibleColumns]="table?.detailVisibleColumns"
      [shouldAddParentRowClass]="table?.shouldAddParentRowClass"
      [expandAllRows$]="table?.expandAllRows"
      [rowIdentity]="table?.rowIdentity"
    ></ice-data-table>
  </div>
  <div class="validation-message-container" *ngIf="field?.formControl?.dirty">
    <mat-error [id]="null">
      <formly-validation-message [field]="field"></formly-validation-message>
    </mat-error>
  </div>
</div>
