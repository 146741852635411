import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { get } from 'lodash';

@Component({
  selector: 'ice-wrapper-input-text',
  templateUrl: './wrapper-input-text.component.html',
  styleUrls: ['./wrapper-input-text.component.scss'],
})
export class WrapperInputTextComponent extends FieldWrapper implements AfterViewInit {
  @ViewChild('fieldComponent', { read: ViewContainerRef, static: true }) fieldComponent: ViewContainerRef;
  input: HTMLInputElement;
  display = false;

  ngAfterViewInit() {
    this.selectInput();
  }

  private selectInput() {
    const target = get(this.fieldComponent, '_hostLView')?.find(element => element && element.localName === 'div');
    this.input = target?.querySelector('input') || target?.querySelector('textarea');
  }

  setInputSelect() {
    if (this.isValidInput()) {
      this.input.select();
    }
  }

  clear(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.isValidInput() && this.formControl) {
      this.formControl.markAsTouched();
      this.formControl.markAsDirty();
      this.formControl.setValue('');
    }
  }

  isValidInput(): boolean {
    return this.input && !this.input.disabled && !this.input.readOnly;
  }
}
