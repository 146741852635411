import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '@ice/i18n/en/expansion-list';
import { toNumber } from 'lodash';
import { isObservable } from 'rxjs';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CardWithExpansionList } from './card-with-expansion-list';

@Component({
  selector: 'ice-card-with-expansion-list',
  templateUrl: './card-with-expansion-list.component.html',
  styleUrls: ['./card-with-expansion-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardWithExpansionListComponent {
  @Input() config: CardWithExpansionList;
  filterValue = '';
  tempTooltip: string;
  isEdit = false;
  counterValue: number;

  constructor(private fuseTranslationLoader: FuseTranslationLoaderService) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  clear(event) {
    event.stopPropagation();
    event.preventDefault();
    this.filterValue = '';
  }

  enterField(event, tooltipValue) {
    // If it's ellipsed
    if (event.target.offsetWidth < event.target.scrollWidth) {
      this.tempTooltip = tooltipValue;
    }
  }

  leaveField() {
    this.tempTooltip = '';
  }

  headerTitleOnClick(event) {
    if (this.config.blockExpansion) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (this.config && this.config.onHeaderTitleClick) {
      this.config.onHeaderTitleClick(this.counterValue);
    }
  }

  headerActionButtonClick(event, actionButton) {
    event.stopPropagation();
    actionButton.onClick();
  }

  getCounter(): Observable<string> {
    if (this.config && this.config.showCounter && this.config.model) {
      const model$: Observable<any> = (isObservable(this.config.model) && this.config.model) || of(this.config.model);
      this.counterValue = 0;
      return model$.pipe(
        map(model => {
          if (this.config.counterSumValues) {
            (model || []).forEach(row => {
              this.counterValue = this.counterValue + toNumber(row.value);
            });
          } else {
            this.counterValue = (model || []).filter(row => row.value).length;
          }
          return `(${this.counterValue})`;
        }),
      );
    }
    return of('');
  }
}
