import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ClaimantUtils, ConflictUtils } from '@ice';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { StepType } from 'config/stepper-builders/stepper-config';
import { isEmpty } from 'lodash';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, share, tap, withLatestFrom } from 'rxjs/operators';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromNewSectionItem from 'store/new-section-item';
import * as fromRoot from 'store/root';

export class CounterClaimRegistrationConflictStep {
  pointOfConflictField: FormlyFieldConfig;
  conflictTypeSubject = new BehaviorSubject(null);
  selectedClaims$ = addResolvers =>
    combineLatest([this.store.select(fromRoot.getAllClaims), this.store.select(fromNewSectionItem.getClaimantGroups)]).pipe(
      distinctUntilChanged(([claimsPrev, prev], [claimsCurr, curr]) => ClaimantUtils.hasChangedGroups(prev, curr)),
      map(([claims, claimGroups]) => ClaimantUtils.getClaimStatus(claims, claimGroups, addResolvers)),
      map(claimants => claimants && claimants.filter(claimant => !!claimant.claimantStatus)),
    );
  constructor(private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService, private store: Store<any>) {}

  resetPointOfConflict(markAsTouched?: boolean) {
    this.pointOfConflictField.formControl.reset();
    this.pointOfConflictField.formControl.setValue(null);
    if (markAsTouched) {
      this.pointOfConflictField.formControl.markAsTouched();
    }
  }

  getStep(translate: TranslateService, fieldValidatorService: FieldValidatorService): StepType {
    return {
      label: translate.instant('COUNTERCLAIMS.STEPS.CONFLICT'),
      formBuilder: [
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-3',
              fieldGroup: [
                {
                  key: 'type',
                  type: 'select',
                  defaultValue: '',
                  templateOptions: {
                    label: translate.instant('COUNTERCLAIMS.FORM.FIELDS.TYPE'),
                    required: true,
                    attributes: { 'data-testid': 'counterclaims-type-dropdown' },
                    options: this.selectedClaims$(false).pipe(map(selectedClaims => ConflictUtils.getCounterClaimsTypesOptions(selectedClaims, this.translate))),
                    change: (field, value) => {
                      this.conflictTypeSubject.next(value.value);
                    },
                  },
                  modelOptions: {
                    updateOn: 'blur',
                  },
                },
              ],
            },
            {
              className: 'flex-2',
              fieldGroup: [
                {
                  key: 'pointOfConflict',
                  type: 'select',
                  defaultValue: '',
                  templateOptions: {
                    label: translate.instant('COUNTERCLAIMS.FORM.FIELDS.CONFLICT'),
                    attributes: { 'data-testid': 'conflict-point-dropdown' },
                    options: combineLatest([
                      this.conflictTypeSubject.pipe(
                        filter(type => !!type),
                        distinctUntilChanged(),
                        tap(_ => this.resetPointOfConflict(true)),
                      ),
                      this.selectedClaims$(false).pipe(
                        map(claimants => claimants && ClaimantUtils.getCounterClaimConflictConditionList(claimants)),
                        tap(_ => this.resetPointOfConflict(true)),
                      ),
                    ]).pipe(
                      withLatestFrom(
                        this.store.select(fromNewSectionItem.getNewSectionItemFields).pipe(
                          filter(fields => fields && !isEmpty(fields['parties'])),
                          map(fields => fields['parties']),
                        ),
                      ),
                      map(([[type, counterClaimConditionList], selectedIps]) => {
                        const pointOfConflictOptions = ConflictUtils.getPointOfConflictOptions(translate, type);
                        const autoOption = ConflictUtils.getPointOfConflictAutoOption(type, counterClaimConditionList, pointOfConflictOptions);
                        if (autoOption && !!ClaimantUtils.claimantsAreValid(type, selectedIps)) {
                          this.pointOfConflictField.formControl.reset();
                          this.pointOfConflictField.formControl.setValue(autoOption);
                        }

                        return pointOfConflictOptions;
                      }),
                      share(),
                    ),
                  },
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  hooks: {
                    onInit: field => {
                      this.pointOfConflictField = field;
                    },
                  },
                  expressionProperties: {
                    className: model => (!model.type || model.type === '' || !ClaimantUtils.claimantsAreValid(model.type, model.parties) ? 'ice-form-field-disabled' : ''),
                    'templateOptions.required': model => !!model.type && model.type !== '' && ClaimantUtils.claimantsAreValid(model.type, model.parties),
                  },
                  validators: {
                    missing_type: {
                      expression: (c, control) => !!control.model.type && control.model.type !== '' && ClaimantUtils.claimantsAreValid(control.model.type, control.model.parties),
                      message: (error, field: FormlyFieldConfig) => translate.instant('COUNTERCLAIMS.FORM.ERRORS.ADD_TYPE'),
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    };
  }
}
