import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as englishButtons } from '@ice/i18n/en/button-labels';
import { SelectionType } from '@swimlane/ngx-datatable';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import { filter, get, uniq } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DatatableFilter, DialogContent } from './dialog-content.model';

@Component({
  selector: 'ice-dialog-content',
  templateUrl: 'dialog-content.component.html',
  styleUrls: ['./dialog-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogContentComponent implements AfterViewInit, OnInit, OnDestroy, AfterViewInit {
  @ViewChild('popupHeader', { static: true }) headerElement: ElementRef;
  @ViewChild('scrollSpy', { static: true }) scrollSpy: ElementRef;
  formBuilder: any;
  form = new FormGroup({});
  allDatatableModel = [];
  filteredDatatableModel = [];
  selected: string;
  dataModel: any;
  selectType = this.data.selectionType ? this.data.selectionType : SelectionType.single;
  showFilter: boolean;
  selectOptions: DatatableFilter[];
  private SCROLL_SPY_OFFSET_FACTOR = 4;
  private LAZY_LOAD_PAGE_SIZE = 60;
  private scrollSpySet = false;
  private unsubscribeAll = new Subject();
  isArray = Array.isArray;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogContent,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private elementRef: ElementRef,
    public dialogRef: MatDialogRef<DialogContentComponent>,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.fuseTranslationLoader.loadTranslations(englishButtons);
  }

  ngOnInit() {
    if (this.data) {
      if (this.data.onInit) {
        this.data.onInit();
      }
      if (this.data.model) {
        this.data.model.pipe(takeUntil(this.unsubscribeAll)).subscribe(dataModel => {
          this.dataModel = dataModel;
        });
      }
      if (this.data.formBuilder) {
        this.data.formBuilder.pipe(takeUntil(this.unsubscribeAll)).subscribe(formBuilder => {
          if (formBuilder) {
            this.formBuilder = formBuilder;
            this.headerElement.nativeElement.focus();
          }
        });
      }

      if (this.data.datatable && this.data.datatable.data) {
        this.data.datatable.data.pipe(takeUntil(this.unsubscribeAll)).subscribe(datatable => {
          this.filteredDatatableModel = this.allDatatableModel = datatable;

          if (this.data.datatableFilter) {
            const defaultOption = this.data.datatableFilter.find(model => model.isDefault);
            this.selected = defaultOption.value;
            this.selectOptions = this.data.datatableFilter;
            this.onSelectOption(defaultOption);
          }
        });
      }
    }
  }
  ngAfterViewInit() {
    if (this.data.datatable && this.data.datatable.sliceLimit && !this.scrollSpySet) {
      let scrollSpyTop;
      this.scrollSpySet = true;
      const scrollSpy = () => {
        const parentHeight = this.elementRef.nativeElement.parentElement.getBoundingClientRect().bottom - this.elementRef.nativeElement.parentElement.getBoundingClientRect().top;
        const isHittingScrollSpy =
          this.scrollSpy.nativeElement.getBoundingClientRect().top -
            this.elementRef.nativeElement.parentElement.getBoundingClientRect().bottom -
            parentHeight * this.SCROLL_SPY_OFFSET_FACTOR <
          0;
        if (isHittingScrollSpy) {
          if (scrollSpyTop !== this.scrollSpy.nativeElement.offsetTop) {
            scrollSpyTop = this.scrollSpy.nativeElement.offsetTop;
            this.data.datatable.sliceLimit = Math.min(this.filteredDatatableModel.length - 1, this.data.datatable.sliceLimit + this.LAZY_LOAD_PAGE_SIZE);
          }
        }
        if (this.data.datatable.sliceLimit >= this.filteredDatatableModel.length - 1) {
          this.elementRef.nativeElement.parentElement.removeEventListener('scroll', scrollSpy);
        }
      };
      this.elementRef.nativeElement.parentElement.addEventListener('scroll', scrollSpy);
    }
  }

  ngOnDestroy() {
    if (this.data.onDestroy) {
      this.data.onDestroy();
    }
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  onSubmit(event, isNoIconOkButton = false) {
    event.preventDefault();
    if (this.data.onSubmit && !this.form.invalid) {
      this.data.onSubmit(this.dataModel);
      if (isNoIconOkButton && !this.data.preventOkButtonClosePopup) {
        this.dialogRef.close();
      }
    }
  }

  onButtonClick(action) {
    if (action) {
      action();
    }
  }

  onResponseLinkClick() {
    if (this.data && this.data.responseMessage) {
      this.data.responseMessage.subscribe(response => (response && response.linkAction ? response.linkAction() : () => {})).unsubscribe();
    }
  }

  onSelectOption(event) {
    const value = event && event.value;
    this.showFilter = this.selectOptions.find(option => option.value === value && option.filter) && true;
    if (this.allDatatableModel && value) {
      this.filteredDatatableModel = filter(this.allDatatableModel, row => row.type === value);
    }
  }

  onUpdateFilter(event) {
    const value = get(event, 'target.value').toLowerCase();
    if (this.allDatatableModel) {
      const itemsList = filter(this.allDatatableModel, row => row.type === this.selected);
      this.filteredDatatableModel = value !== '' ? filter(itemsList, row => (row.value && row.value.toLowerCase().indexOf(value) !== -1) || !value) : itemsList;
    }
  }

  onSort(sorts) {
    const sort = sorts && sorts[0];
    let groupedAndSorted = [];
    const types = uniq(this.allDatatableModel.map(row => row.type)).sort();
    types.forEach(type => {
      const filteredByType = this.allDatatableModel.filter(row => row.type === type);
      const isGrouped = filteredByType.find(row => !!row.groupBy);
      if (isGrouped) {
        const groupedBy = uniq(filteredByType.map(row => row.groupBy)).sort();
        groupedBy.forEach(group => {
          const filteredByGroup = filteredByType.filter(row => row.groupBy === group);
          groupedAndSorted = [...groupedAndSorted, ...this.sort(filteredByGroup, sort)];
        });
      } else {
        groupedAndSorted = [...groupedAndSorted, ...this.sort(filteredByType, sort)];
      }
    });
    this.allDatatableModel = groupedAndSorted;
    this.onSelectOption({ value: this.selected });
  }

  sort(rowsToSort, sort) {
    return rowsToSort.sort((a, b) => {
      if (sort.dir === 'desc') {
        if (a[sort.prop] < b[sort.prop]) {
          return 1;
        } else if (a[sort.prop] > b[sort.prop]) {
          return -1;
        }
        return 0;
      } else {
        if (a[sort.prop] > b[sort.prop]) {
          return 1;
        } else if (a[sort.prop] < b[sort.prop]) {
          return -1;
        }
        return 0;
      }
    });
  }

  selectSearchItem(item?) {
    if (this.data.onSelectSearchItem) {
      if (this.selectType === SelectionType.checkbox) {
        this.data.onSelectSearchItem(item);
      } else if (Array.isArray(item)) {
        item = item[0];
        this.data.onSelectSearchItem(item);
      }
    }
  }

  closeOnSubmit(): boolean {
    return get(this.data, 'closeOnSubmit', true);
  }
}
