import { WorkOption } from '@ice';
import { CopyrightUtils } from '@ice/utils';
import { EXPANSION_LIST_PAIRS, EXPANSION_LIST_PAIRS_HEADERS } from 'config/constants/activity.constants';
import { get, sortBy, toNumber, toPairs } from 'lodash';
import { Observable } from 'rxjs';

export class AnalysisAndComparisonsUtils {
  public highLightExpansionListPairs = EXPANSION_LIST_PAIRS;

  static cleanOptions(options: WorkOption[]): WorkOption[] {
    return options?.map(option => {
      return { ...option, viewValue: this.cleanNamespaceId(option.viewValue) };
    });
  }

  static cleanNamespaceId(targetText: string): string {
    const val = CopyrightUtils.getKeySuffix(targetText);
    return isNaN(toNumber(val)) ? val.toString() : toNumber(val).toString();
  }

  public highlightExpansionLists(idPrefixes, toogleHighlightActive = true): void {
    idPrefixes.forEach(idPrefix => {
      EXPANSION_LIST_PAIRS_HEADERS.forEach(group => {
        group.pairs.forEach(pair => setHighlight(idPrefix, pair, group.useSeparator));
      });
    });

    function setHighlight(idPrefix: any, pair: string[], useSeparator: boolean) {
      const idListWork = idPrefix + pair[0];
      const idListCompare = idPrefix + pair[1];
      const selector = idPrefix === '#expansion-list-data-table-' ? '.ice-data-table-row' : '.expansion-list-elem span';
      const elementsWorkList = document.querySelectorAll(`${idListWork} ${selector}`);
      const elementsCompareList = document.querySelectorAll(`${idListCompare} ${selector}`);

      if (!toogleHighlightActive) {
        swapElementsList(elementsWorkList, elementsCompareList, useSeparator);
      } else {
        swapElementsList(elementsCompareList, elementsWorkList, useSeparator);
      }
    }

    function swapElementsList(list1: NodeListOf<Element>, list2: NodeListOf<Element>, useSeparator: boolean): void {
      for (let x = 0; x < list1.length; x++) {
        const compareElementValue = get(list1, `[${x}].innerHTML`, null);
        let elemMatch = false;

        list1[x]?.classList?.remove('conflict-analysis-highlight-elem');
        list2[x]?.classList?.remove('conflict-analysis-highlight-elem');

        for (let y = 0; y < list2.length; y++) {
          const workElementValue = get(list2, `[${y}].innerHTML`, null);
          if ((compareElementValue === workElementValue && x === y) || (useSeparator && [1, 2].includes(x % 7) && workElementValue && compareElementValue)) {
            elemMatch = true;
          }
        }

        if (!elemMatch) {
          list1[x].classList.add('conflict-analysis-highlight-elem');
        }
      }
    }
  }

  public formBuilderHighlight(formBuilder$: Observable<any[]>, toogleHighlightActive: boolean): void {
    formBuilder$.subscribe(formBuilder => {
      formBuilder.forEach((group: any) => {
        group.fieldGroup.forEach((field: any) => {
          const idPattern = field.type + '_' + field.key;
          const htmlElems = document.querySelectorAll('*[id*="' + idPattern + '"');
          const value1 = get(htmlElems, '[0].value', null);
          const value2 = get(htmlElems, '[1].value', null);
          const targetIndex = toogleHighlightActive ? 1 : 0;
          if (value1 !== value2 && htmlElems[targetIndex]) {
            htmlElems[targetIndex].classList.remove('conflict-analysis-highlight-elem');
            htmlElems[1 - targetIndex].className += ' conflict-analysis-highlight-elem';
          }
        });
      });
    });
  }

  public removeHighlightedElems(): void {
    const alreadyMarkedElems = document.querySelectorAll('*[class*="conflict-analysis-highlight-elem"');

    const roleRows = document.querySelectorAll('*[class*="conflict-analysis-first-level-elem"');
    roleRows.forEach(roleRow => (roleRow.className = roleRow.className.replace('conflict-analysis-first-level-elem', '')));

    const roleRows2 = document.querySelectorAll('*[class*="conflict-analysis-second-level-elem"');
    roleRows2.forEach(roleRow => (roleRow.className = roleRow.className.replace('conflict-analysis-second-level-elem', '')));

    const allRows = document.querySelectorAll('*[class*="striped"');
    allRows.forEach(row => (row.className = row.className.replace('striped', '')));

    alreadyMarkedElems.forEach(markedElemen => (markedElemen.className = markedElemen.className.replace('conflict-analysis-highlight-elem', '')));
  }

  public paintRowsByPath(elements: any, shares: any) {
    for (let x = 0; x < elements.length; x++) {
      if (shares[x]) {
        switch (shares[x].path.length) {
          case 1:
            {
              elements[x].className += ' conflict-analysis-first-level-elem';
            }
            break;
          case 2:
            {
              elements[x].className += ' conflict-analysis-second-level-elem';
            }
            break;
        }
      }
    }
  }

  public setClaimRowsStyle(sharesCompare: any, toogleHighlightActive: boolean) {
    const elemsToBeMarked: any[] = sharesCompare.map((sharesRow, index) => {
      const compareShare = sharesRow.compare;
      const masteredShare = sharesRow.mastered;
      return compareShare && compareShare.matchScore !== undefined ? masteredShare || compareShare : null;
    });

    const compareShareHtmlElems = document.querySelectorAll('#compare-expansion-list-datatable datatable-row-wrapper');
    this.paintRowsByPath(
      compareShareHtmlElems,
      sharesCompare.map(sharesRow => sharesRow.compare),
    );
    const masterShareHtmlElems = document.querySelectorAll('#mastered-expansion-list-datatable datatable-row-wrapper');
    this.paintRowsByPath(
      masterShareHtmlElems,
      sharesCompare.map(sharesRow => sharesRow.mastered),
    );
    for (let x = 0; x < compareShareHtmlElems.length; x++) {
      if (elemsToBeMarked[x] && elemsToBeMarked[x].sourceWork === 'compare' && toogleHighlightActive) {
        compareShareHtmlElems[x].className += ' conflict-analysis-highlight-elem';
      } else if (elemsToBeMarked[x]) {
        const rowElements = compareShareHtmlElems[x].querySelectorAll('.data-table-row');
        const rowPattern = ['role', 'name', 'ipiName', 'ipiNameKey', 'territories', 'prSociety', 'prRights', 'pr', 'mrSociety', 'mrRights', 'mr'];
        const masteredRow = sortBy(toPairs(elemsToBeMarked[x]), ([key, value]) => rowPattern.indexOf(key))
          .filter(([key, value]) => rowPattern.includes(key) && value)
          .map(([key, value]) => (Array.isArray(value) && value.join(',')) || value);
        (rowElements || []).forEach((element, index) => {
          if (element.innerHTML !== masteredRow[index] && toogleHighlightActive) {
            element.className += ' conflict-analysis-highlight-elem';
          }
        });
      }
    }
  }
}
