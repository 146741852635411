import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import * as fromApiCalls from 'config/api-calls';
import { CopyrightWorksDataTable } from 'config/data-table-builders/copyright.works';
import { SelectionDatatableBuilder } from 'config/data-table-builders/selection-datatable-builder';
import { DialogAddById } from 'config/dialog-builders/dialog-add-by-id';
import { SectionsConfig } from 'config/sections-config';
import { of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PermissionsService } from 'services/permissions/permissions.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';
import { SectionTabConfig } from '../../tabs-data';
import { RepertoireEditInline } from './shared/edit';

export class TabRepertoireExcludedWorks implements SectionTabConfig {
  private schema: CopyrightWorksDataTable;
  private selectionDatatable: SelectionDatatableBuilder;
  private dialogAddById: DialogAddById;
  private dialogRef: MatDialogRef<any, any>;
  private unsubscribeAll = new Subject();
  private currentIds = [];
  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private dialog: MatDialog,
    private commonApiService: null,
    private detailService: null,
    private nsService: null,
    private iceFacade: null,
    private fieldValidatorService: FieldValidatorService,
    protected permissionsService: PermissionsService,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.schema = new CopyrightWorksDataTable(this.translate, this.fuseTranslationLoader);
    const onSelect = (event: any[]) => {
      this.store.dispatch(
        new fromRoot.Go({
          path: [`copyright/${SectionsConfig.WORKS.name}/${event[0].key || event[0].id}`],
        }),
      );
    };
    this.selectionDatatable = new SelectionDatatableBuilder(
      store,
      [
        ...this.schema.getDataTable(),
        ...this.schema.getRepertoireWorksField(
          this.dialog,
          this.store,
          this.translate.instant('REPERTOIRES.EXCLUDED_WORKS.TAB_TITLE'),
          this.translate.instant('REPERTOIRES.EXCLUDED_WORKS.DELETE_MESSAGE'),
          (row, dialogRef) => RepertoireEditInline.updateRepertoire(this.store, dialogRef, this.translate, row.id, 'excludedWorks', this.unsubscribeAll, 'delete', this.currentIds),
          !this.permissionsService.can(`repertoires_edit`),
        ),
      ],
      onSelect,
      fromApiCalls.getExcludedWorks,
    );
    this.dialogAddById = new DialogAddById(this.translate, this.dialog, this.store, this.fieldValidatorService, SectionsConfig.WORKS.name);
  }

  onDestroy = () => {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  };

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('REPERTOIRES.EXCLUDED_WORKS.TAB_TITLE'),
              model: this.store.pipe(
                select(fromRoot.getRepertoireExcludedWorks),
                tap(works => (this.currentIds = works?.map(work => work.id))),
              ),
              sorts: [],
              ...this.selectionDatatable.getSelectionDatatableConfig(),
              loadingIndicator: false,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: true,
              shadowed: false,
              columnMode: 'flex',
              onMouseSelect: event => {
                this.store.dispatch(
                  new fromRoot.OpenNewTab({
                    path: [`copyright/${SectionsConfig.WORKS.name}/${event.key || event.id}`],
                  }),
                );
              },
              getRowClass: (): any => ({ 'ice-search-results-table-row': true }),
              actionButtons: of(
                this.permissionsService.can(`repertoires_edit`)
                  ? [
                      {
                        icon: 'add',
                        tooltip: `${this.translate.instant('REPERTOIRES.COMMON.ADD')} ${this.translate.instant('REPERTOIRES.EXCLUDED_WORKS.TAB_TITLE')}`,
                        class: 'mat-white-icon',
                        onClick: () => {
                          this.dialogRef = this.dialogAddById.openDialog(workId =>
                            RepertoireEditInline.updateRepertoire(this.store, this.dialogRef, this.translate, workId, 'excludedWorks', this.unsubscribeAll, 'add', this.currentIds),
                          );
                        },
                      },
                    ]
                  : [],
              ),
            },
          },
        ],
      },
    ];
  }
}
