import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { initialNavigation } from 'config/navigation/navigation';

export interface SnackBarConfiguration {
  message: string;
  action?: string;
  duration?: number;
  icon?: string;
  position?: {
    horizontalPosition: MatSnackBarHorizontalPosition;
    verticalPosition: MatSnackBarVerticalPosition;
  };
}

export interface UiState {
  navigation: any;
  searchBar: {
    advancedCollapsed: boolean;
    expertCollapsed: boolean;
  };
  snackBar: {
    visible: boolean;
    configuration: SnackBarConfiguration;
  };
  progressBar: {
    routerLoading: boolean;
    dataLoading: boolean;
  };
  dialogStatus: {
    isOpen: boolean;
  };
  sidebar: {
    isOpen: boolean;
  };
  forms: {
    resetTerritorySearchInput: boolean;
  };
  fabButtons: {
    mainCount: number;
    childCount: number;
  };
}

export const initialUiState: UiState = {
  searchBar: {
    advancedCollapsed: true,
    expertCollapsed: false,
  },
  snackBar: {
    visible: false,
    configuration: null,
  },
  progressBar: {
    routerLoading: false,
    dataLoading: false,
  },
  dialogStatus: {
    isOpen: false,
  },
  navigation: initialNavigation,
  sidebar: {
    isOpen: null,
  },
  forms: {
    resetTerritorySearchInput: true,
  },
  fabButtons: {
    mainCount: 0,
    childCount: 0,
  },
};
