import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[iceDebounceKey]',
})
export class DebounceKeyDirective implements OnInit, OnDestroy {
  @Input() debounceTime = 200;
  @Output() debounceKey = new EventEmitter();
  private key = new Subject();
  private subscription: Subscription;

  ngOnInit() {
    this.subscription = this.key.pipe(debounceTime(this.debounceTime)).subscribe(e => {
      this.debounceKey.emit(e);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('keyup', ['$event'])
  keyEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    if (event.code !== 'Tab') {
      this.key.next(event);
    }
  }
}
