import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { HtmlTemplateModule } from '@ice/components/html-template/html-template.module';
import { IceMaterialModule } from '@ice/material.module';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { TranslateModule } from '@ngx-translate/core';
import { HtmlTemplateTypeComponent } from './html-template-type.component';

@NgModule({
  declarations: [HtmlTemplateTypeComponent],
  imports: [TranslateModule, FuseSharedModule, IceMaterialModule, FormlyMaterialModule, FormlyModule.forRoot(), HtmlTemplateModule],
  exports: [HtmlTemplateTypeComponent],
})
export class HtmlTemplateTypeModule {}
