import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, isDevMode } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import * as fromRoot from '../../store/root';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  store;
  errorCount = 0;

  constructor(private injector: Injector, private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService) {
    this.fuseTranslationLoader.loadTranslations(english);

    setTimeout(() => {
      this.store = this.store || injector.get(Store);
    });
  }

  handleError(error: Error | HttpErrorResponse) {
    console.error(error);
    if (++this.errorCount > 10) {
      // force a reload if more than 10 unhandled errors occur
      if (!isDevMode()) {
        window.location.reload();
      } else {
        // we don't need that in non-production environments. So let's be specific about the error
        this.store.dispatch(
          new fromRoot.ShowSnackBar({
            duration: 8000,
            message: (error as { error: Error })?.error?.message || error?.message || error.toString(),
          }),
        );
      }
    } else {
      this.store.dispatch(
        new fromRoot.ShowSnackBar({
          duration: 8000,
          message: this.translate.instant('ERROR.SERVER.UNHANDLED'),
        }),
      );
    }
  }
}
