import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DataTableRow, SortInfo } from '@ice/components/data-table/data-table';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import { locale as englishTabs } from 'assets/i18n/en/config/tabs-data-builders';
import { DataTableBuilder } from 'config/data-table-builders/data-table-builder';
import { RootDatatable } from 'config/data-table-builders/root-datatable';

export class UserManagementUsersDataTable extends RootDatatable implements DataTableBuilder {
  constructor(protected translate: TranslateService, protected fuseTranslationLoader: FuseTranslationLoaderService) {
    super(translate);
    this.fuseTranslationLoader.loadTranslations(english);
    this.fuseTranslationLoader.loadTranslations(englishTabs);
  }

  getDefaultSorting(): SortInfo[] {
    return [{ prop: 'firstName', dir: 'asc' }];
  }

  formatSort(sort: SortInfo) {
    switch (sort.prop) {
      case 'cognitoUserName':
      case 'organizationId':
      case 'lastLogin':
        return null;
      case 'creationDate':
        return { prop: `tags.created`, dir: sort.dir };
    }
    return { prop: `attributes.${sort.prop}`, dir: sort.dir };
  }

  getDataTable(): DataTableRow[] {
    return [
      { name: this.translate.instant('USERS.ID'), prop: 'cognitoUserName', cellClass: 'ice-txt-size-14 user-username', flexGrow: 1 },
      { name: this.translate.instant('USERS.ORGANIZATION'), prop: 'organizationId', cellClass: 'ice-txt-size-14 user-organization', flexGrow: 1 },
      { name: this.translate.instant('USERS.FIRST_NAME'), prop: 'firstName', cellClass: 'ice-txt-size-14 user-firstname', flexGrow: 1 },
      { name: this.translate.instant('USERS.LAST_NAME'), prop: 'lastName', cellClass: 'ice-txt-size-14 user-lastname', flexGrow: 1 },
      { name: this.translate.instant('USERS.LAST_ATTEMPT'), prop: 'lastLogin', cellClass: 'ice-txt-size-14 user-lastlogin', flexGrow: 1 },
      { name: this.translate.instant('USERS.EMAIL'), prop: 'email', cellClass: 'ice-txt-size-14 user-email', flexGrow: 2 },
      { name: this.translate.instant('USERS.STATUS'), prop: 'status', cellClass: 'ice-txt-size-14 user-status', flexGrow: 0.8 },
      { name: this.translate.instant('USERS.CREATION_DATE'), prop: 'creationDate', cellClass: 'ice-txt-size-14 user-creation-date', flexGrow: 1 },
    ];
  }

  getColumnMode(): ColumnMode {
    return ColumnMode.flex;
  }

  displayCheck(row: any, firstSelectedItem: any, targetItem: any, column?: any, value?: any): boolean {
    return true;
  }
}
