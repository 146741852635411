import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FormlyValidatorUtils } from '@ice/utils/formly/formly-validators.utils';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/search-form-builders';
import { AGREEMENTS_CONSTANTS } from 'config/constants/agreements.constants';
import { FormConfig } from './form-config';

export class SearchCopyrightIpsTransferWorkTargetsForm implements FormConfig {
  constructor(private translate: TranslateService, private fuseTranslationLoader: FuseTranslationLoaderService) {
    this.fuseTranslationLoader.loadTranslations(english);
  }

  // Filter form
  getForm(typeOfEntity?: any, defaultXrefPrefix?: string, baseType: string = null, formButtons: any = null): FormlyFieldConfig[] {
    const needsBase = {
      className: 'input-wrapper',
      key: 'needsBase',
      type: 'input',
      defaultValue: 'Y',
      hideExpression: model => {
        return true;
      },
      templateOptions: {
        label: this.translate.instant('IPS.IPI_BASE_TYPE'),
        required: false,
        disabled: true,
      },
    };

    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          {
            className: 'input-wrapper flex-1 w-100-p',
            key: 'name',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('IPS.LAST_NAME'),
              required: false,
            },
          },
          {
            className: 'input-wrapper flex-1 w-100-p',
            key: 'firstName',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('IPS.FIRST_NAME'),
              required: false,
            },
          },
          {
            className: 'input-wrapper flex-1 w-100-p',
            key: 'xrefPrefix',
            type: 'select',
            templateOptions: {
              label: this.translate.instant('IPS.XREF_PREFIX.IP_NAME_REFERENCE_TYPE'),
              options: [
                { label: this.translate.instant('IPS.XREF_PREFIX.SOCIETY_REFERENCE'), value: 'SO*' },
                { label: this.translate.instant('IPS.XREF_PREFIX.IPI_NAME'), value: 'IPINAME' },
                { label: this.translate.instant('IPS.XREF_PREFIX.IP_NAME_KEY'), value: 'ICENAME' },
                { label: this.translate.instant('IPS.IPI_BASE_NUMBER_OR_KEY.IPI_BASE_NUMBER'), value: 'IPIBASE' },
                { label: this.translate.instant('IPS.IPI_BASE_NUMBER_OR_KEY.IPI_BASE_KEY'), value: 'ICEBASE' },
              ],
              required: false,
              disableOptionCentering: true,
            },
          },
          {
            className: 'input-wrapper flex-1 w-100-p',
            key: 'baseId',
            wrappers: ['form-field', 'wrapper-input-text'],
            type: 'input',
            templateOptions: {
              type: 'text',
              placeholder: this.translate.instant('IPS.XREF_PREFIX.IP_NAME_REREFENCE'),
              required: false,
            },
            asyncValidators: {
              workRefValidation: FormlyValidatorUtils.getReferenceValidator(this.translate, 'COMMON.ERRORS.INVALID_REFERENCE', true),
            },
          },

          {
            className: 'input-wrapper flex-1 w-100-p',
            key: 'typeOfName',
            type: 'select',
            defaultValue: '',
            templateOptions: {
              label: this.translate.instant('IPS.XREF_PREFIX.IPI_NAME_TYPE'),
              options: [
                { label: this.translate.instant('IPS.VALUES_ALL'), value: '' },
                { label: this.translate.instant('IPS.TYPE_OF_NAME_OPTIONS.DF'), value: 'DF' },
                { label: this.translate.instant('IPS.TYPE_OF_NAME_OPTIONS.HR'), value: 'HR' },
                { label: this.translate.instant('IPS.TYPE_OF_NAME_OPTIONS.MO'), value: 'MO' },
                { label: this.translate.instant('IPS.TYPE_OF_NAME_OPTIONS.OR'), value: 'OR' },
                { label: this.translate.instant('IPS.TYPE_OF_NAME_OPTIONS.PA'), value: 'PA' },
                ...(![AGREEMENTS_CONSTANTS.TYPE_ASSIGNEE, AGREEMENTS_CONSTANTS.TYPE_ASSIGNOR].includes(typeOfEntity)
                  ? [{ label: this.translate.instant('IPS.TYPE_OF_NAME_OPTIONS.PG'), value: 'PG' }]
                  : []),
                { label: this.translate.instant('IPS.TYPE_OF_NAME_OPTIONS.PP'), value: 'PP' },
                { label: this.translate.instant('IPS.TYPE_OF_NAME_OPTIONS.ST'), value: 'ST' },
              ],
              required: false,
              disableOptionCentering: true,
            },
          },
          {
            className: 'input-wrapper flex-2 w-100-p',
            key: 'typeOf',
            type: 'select',
            defaultValue: '',
            templateOptions: {
              label: this.translate.instant('IPS.TYPE_OF_IPI_BASE_TYPE'),
              options: [
                { label: this.translate.instant('IPS.SELECT_ALL'), value: '' },
                { label: this.translate.instant('IPS.LEGAL_ENTITY'), value: 'L' },
                { label: this.translate.instant('IPS.NATURAL_PERSON'), value: 'N' },
              ],
              required: false,
              disableOptionCentering: true,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'display-flex ice-justify-space-between ice-mt-12',
        fieldGroup: [
          {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [needsBase],
          },
          {
            className: 'transfer-ips-checkbox',
            fieldGroupClassName: 'display-flex flex-align-items-center ice-accent',
            fieldGroup: [
              {
                className: 'input-checkbox-wrapper w-100-p',
                key: 'includeInactive',
                type: 'checkbox',
                defaultValue: false,
                templateOptions: {
                  label: this.translate.instant('IPS.INCLUDE_INACTIVE'),
                  required: false,
                },
              },
              ...formButtons,
            ],
          },
        ],
      },
    ];
  }
}
