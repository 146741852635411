export const locale = {
  lang: 'en',
  data: {
    NAV: {
      APPLICATIONS: 'Applications',
      COPYRIGHT: {
        WORKS: 'Work',
        TITLE: 'Copyright',
        BADGE: '25',
        IPS: 'IP',
        AGREEMENTS: 'Agreements',
        AGREEMENT_GROUP: 'Agreement Group',
        REPERTOIRES: 'Repertoire',
        SOCIETIES: 'Society',
        TERRITORIES: 'Territory',
        REPORTS: 'Reports On Demand',
        BULK_UPDATES: 'Bulk updates',
      },
      WORK: {
        TITLE: 'Work',
        MESSAGE: 'anything',
      },
      DASHBOARD: 'COPYRIGHT ADMIN',
      NAS: {
        TITLE: 'NAS',
        ACCOUNTS: 'NAS Accounts',
        CONTROL: 'NAS Control',
        REFERENCE: 'Reference Data',
        SYSTEM: 'NAS System',
      },
      ROD: {
        TITLE: 'ROD',
        REPORT: 'Report',
        RESULT: 'Result',
        MYREPORTS: 'My Reports',
      },
      CONFLICTS: {
        TITLE: 'Conflicts',
        ACTIVITY: 'Activity',
        COUNTER_CLAIMS: 'CounterClaim',
        COUNTER_CLAIMS_ACTIONS: 'CounterClaim Actions',
        COUNTER_CLAIM_SUPPORT: 'Support claim',
        AGREEMENT_CONFLICT: 'Agreement conflicts',
        DASHBOARD: 'Dashboard',
        COUNTERCLAIM_NOTIFICATIONS: 'Counterclaim Notifications',
      },
      ENQUIRY: {
        TITLE: 'Enquiry',
        EMAILS: 'Enquiry Emails',
        ACCOUNTS: 'Enquiry Accounts',
        CONTACTS: 'Enquiry Contacts',
      },
      FICHES: {
        TITLE: 'Fiches',
      },
      ADMINISTRATION: 'Administration',
      SETTINGS: {
        TITLE: 'Personal Settings',
        GENERAL: 'General',
        PASSWORD: 'Change Password',
        WORK: 'Work Details',
        ROLES: 'Change Roles',
      },
      USER_MANAGEMENT: {
        TITLE: 'User Management',
        ORGANIZATIONS: 'Organizations',
        USERS: 'Users',
      },
      CUSTOM_FUNCTION: {
        TITLE: 'Custom Function',
        CUSTOMIZE: 'Customize',
      },
      LOGIN: {
        TITLE: 'Login',
      },
    },
  },
};
