import { ConflictUtils, CopyrightUtils } from '@ice';
import { createResultCleaner } from '../api-call.factory';

export const getAlternativeTitlesAndXrefsResultCleaner = createResultCleaner(
  (value, oldDetail, cleanerData, translate) => value && ConflictUtils.loadHeaderAlternativeTitlesAndXrefs(value, translate),
);

export const getScopeOfConflictsResultCleaner = createResultCleaner((value, oldDetail, cleanerData, translate) => CopyrightUtils.cleanConflicts(value?.conflicts, translate));

export const getComparesTitlesResultCleaner = createResultCleaner((value, oldDetail) => CopyrightUtils.extenseOptionsToCompare(value, oldDetail));
