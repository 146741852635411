import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CopyrightUtils } from '@ice/utils';
import { toNumber } from 'lodash';
import { CardWithFormComponent } from '../card-with-form/card-with-form.component';

@Component({
  selector: 'ice-card-with-form-dropdown',
  templateUrl: './card-with-form-dropdown.component.html',
  styleUrls: ['./card-with-form-dropdown.component.scss'],
})
export class CardWithFormDropdownComponent extends CardWithFormComponent {
  @Input() selected: string;
  @Input() options: any;
  @Input() className: string;
  @Input() buttonTitle: string;
  @Input() buttonTitleTooltip: string;
  @Input() extendedWorkSelector: boolean;
  @Input() showCounterclaimIndicator: boolean;
  @Output() select: EventEmitter<any> = new EventEmitter<any>();
  @Output() newTab: EventEmitter<string> = new EventEmitter<string>();
  @Output() open: EventEmitter<any> = new EventEmitter<any>();
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  onSelectOption(data: any) {
    this.select.emit({ value: data.value });
  }

  clickHeaderButton(ev: any, workId: string) {
    ev.stopPropagation();
    ev.preventDefault();
    this.newTab.emit(workId);
  }

  onOpened() {
    if (this.open) {
      this.open.emit();
    }
  }

  onClosed() {
    if (this.close) {
      this.close.emit();
    }
  }

  getReferenceIdSuffix(referenceId: string): string {
    return toNumber(CopyrightUtils.getKeySuffix(referenceId).match(/\d+/g)[0] || '').toString();
  }

  getSelectedViewValue(options: any[], selected: string): string {
    const selectedValue = options?.find(option => option.value === selected);
    return selectedValue?.viewValue;
  }
}
