import { FormGroup } from '@angular/forms';
import { SharesColumn, TerritoryRight } from '@ice/index';
import { TranslateService } from '@ngx-translate/core';
import {
  ALL_RIGHTS,
  ERROR_AT_LEAST_ONE_RIGHT_FILLED,
  ERROR_AT_LEAST_ONE_RIGHT_TERRITORY,
  ERROR_LIMIT_EXCEEDED,
  ERROR_NAN,
  ERROR_NO_ENOUGH_MR_RIGHTS,
  ERROR_NO_ENOUGH_PR_RIGHTS,
  ERROR_NO_NEGATIVE_VALUE,
  ERROR_NO_REPETITION_RIGHT_TERRITORY,
  ERROR_ONLY_2_DECIMALS,
  ERROR_TERRITORY_IS_EMPTY,
  ERROR_TERRITORY_NOT_EXISTS,
  ERROR_TOTAL_100,
  ERROR_TOTAL_MORE_THAN_100,
  ERROR_WRONG_END_DATE,
} from 'config/constants/shares.constants';
import Decimal from 'decimal.js';
import { difference, filter, indexOf, intersection, isNumber, orderBy, sumBy, toNumber } from 'lodash';

const SHARE_LIMIT_VALUE = 200;

export class SharesValidator {
  public static checkNegative(model: Array<SharesColumn>) {
    let isNegative = false;
    model.map(column => {
      isNegative = isNegative || parseFloat(column.creator) < 0 || parseFloat(column.publisher) < 0 || parseFloat(column.assignee) < 0;
    });
    return isNegative;
  }

  public static check2Decimals(value: number) {
    const val = value.toString();

    return val.split('.').length > 1 && val.split('.')[1].length > 2;
  }

  public static check2DecimalsInAll(model: Array<SharesColumn>) {
    let moreThanTwo = false;
    for (let i = 0; !moreThanTwo && i < model.length; i++) {
      const column = model[i];
      const creator = column.creator && column.creator.toString();
      const publisher = column.publisher && column.publisher.toString();
      const assignee = column.assignee && column.assignee.toString();
      const share = column.share && column.share.toString();
      moreThanTwo = this.checkTwoBySplit(share) || this.checkTwoBySplit(creator) || this.checkTwoBySplit(publisher) || this.checkTwoBySplit(assignee);
    }
    return moreThanTwo;
  }

  public static checkTotalSharesLimitExceeded(model: Array<SharesColumn>, totalSharesValue: number) {
    return totalSharesValue + sumBy(model, column => +column.share) > SHARE_LIMIT_VALUE;
  }

  public static checkTwoBySplit(val) {
    return val && val.split('.').length > 1 && val.split('.')[1].length > 2;
  }

  public static checkTotal100(model: Array<SharesColumn>) {
    let sum100 = true;
    model.map(column => {
      const creator = new Decimal(column.creator || 0);
      const publisher = new Decimal(column.publisher || 0);
      const assignee = new Decimal(column.assignee || 0);
      sum100 =
        sum100 &&
        (new Decimal(creator).plus(new Decimal(publisher)).plus(new Decimal(assignee)).equals(100) ||
          (column.noShareout && assignee.greaterThan(0) && assignee.lessThanOrEqualTo(100)));
    });
    return sum100;
  }

  public static checkAtLeastOneColumnFilled(model: Array<SharesColumn>) {
    let atLeastOneFilled = false;
    for (let i = 0; !atLeastOneFilled && i < model.length; i++) {
      const column = model[i];
      atLeastOneFilled =
        atLeastOneFilled ||
        ((column.creator.length > 0 || isNumber(column.creator)) &&
          (column.publisher.length > 0 || isNumber(column.publisher)) &&
          (column.assignee.length > 0 || isNumber(column.assignee)) &&
          column.territory.length > 0 &&
          column.inclusion.length > 0);
    }
    return atLeastOneFilled;
  }

  public static checkAtLeastOneColumnFilledForClaim(model: Array<SharesColumn>) {
    let atLeastOneFilled = false;
    model.map(column => {
      atLeastOneFilled = atLeastOneFilled || ((column.share.length > 0 || isNumber(column.share)) && column.territory.length > 0 && column.inclusion.length > 0);
    });
    return atLeastOneFilled;
  }

  public static checkFilledAllTerritories(model: Array<SharesColumn>) {
    let filled = true;
    model.map(col => (filled = filled && col.territory.length > 0));
    return filled;
  }

  public static checkNoRepeatRightForTerritory(model: Array<SharesColumn>) {
    let valid = true;
    const territoryRights = new Array<TerritoryRight>();

    model.map(column => {
      if (valid) {
        const inclusion = SharesValidator.sortInclusionList(column.inclusion);
        const sameRightColumn = filter(territoryRights, territoryRight => {
          return territoryRight.right === column.type;
        });
        for (let i = 0; i < sameRightColumn.length && valid; i++) {
          if (
            indexOf(sameRightColumn[i].inclusion, ALL_RIGHTS) >= 0 ||
            indexOf(inclusion, ALL_RIGHTS) >= 0 ||
            difference(sameRightColumn[i].inclusion, inclusion).length !== sameRightColumn[i].inclusion.length
          ) {
            if (intersection(sameRightColumn[i].codes, column['codes']).length > 0) {
              valid = false;
            }
          }
        }
        territoryRights.push({ codes: column['codes'], right: column.type, inclusion });
      }
    });

    return valid;
  }

  public static sortInclusionList(inclusion: string): Array<string> {
    if (inclusion && inclusion.length && inclusion.length > 0) {
      return orderBy(
        inclusion
          .split(',')
          .map(usage => usage.trim())
          .sort(),
      );
    }
    return [];
  }

  public static checkAtLeastOneRightForEachTerritory(model: Array<SharesColumn>, territoryCodes: Array<number>) {
    const includedCodes = [];
    model.map(column => {
      includedCodes.push.apply(includedCodes, difference(column['codes'], includedCodes));
    });

    return intersection(territoryCodes, includedCodes).length === territoryCodes.length;
  }

  public static checkInitialState(form: FormGroup): boolean {
    const shares = form && form.value.shares ? form.value.shares : null;

    const initialSharesStates: boolean[] =
      shares &&
      shares.map(
        (share, index) =>
          form.controls['shares']['controls'][index]['controls']['inclusion']['value'] === 'ALL' &&
          Number.parseInt(shares[index]['creator'], 10) === 0 &&
          Number.parseInt(shares[index]['publisher'], 10) === 0 &&
          Number.parseInt(shares[index]['assignee'], 10) === 0,
      );

    return initialSharesStates.every(value => value);
  }

  public static checkInitialStateForClaim(form: FormGroup): boolean {
    const shares = form && form.value.shares ? form.value.shares : null;

    const initialSharesStates: boolean[] =
      shares &&
      shares.map((share, index) => form.controls['shares']['controls'][index]['controls']['inclusion']['value'] === 'ALL' && Number.parseInt(shares[index]['share'], 10) === 0);

    return initialSharesStates.every(value => value);
  }

  public static getSharesValidationErrors(translate: TranslateService) {
    return [
      { type: ERROR_NAN, msg: translate.instant('AGREEMENTS.SHARES.VALIDATION.NAN') },
      { type: ERROR_ONLY_2_DECIMALS, msg: translate.instant('AGREEMENTS.SHARES.VALIDATION.ONLY_2_DECIMALS') },
      { type: ERROR_TOTAL_100, msg: translate.instant('AGREEMENTS.SHARES.VALIDATION.TOTAL_100') },
      { type: ERROR_AT_LEAST_ONE_RIGHT_FILLED, msg: translate.instant('AGREEMENTS.SHARES.VALIDATION.ONE_RIGHT_FILLED') },
      { type: ERROR_NO_REPETITION_RIGHT_TERRITORY, msg: translate.instant('AGREEMENTS.SHARES.VALIDATION.NO_REPETITION_RIGHT') },
      { type: ERROR_AT_LEAST_ONE_RIGHT_TERRITORY, msg: translate.instant('AGREEMENTS.SHARES.VALIDATION.AT_LEAST_ONE_BY_TERRITORY') },
      { type: ERROR_NO_NEGATIVE_VALUE, msg: translate.instant('AGREEMENTS.SHARES.VALIDATION.NO_NEGATIVE_VALUE') },
      { type: ERROR_TERRITORY_NOT_EXISTS, msg: translate.instant('AGREEMENTS.SHARES.VALIDATION.ERROR_TERRITORY_NOT_EXISTS') },
      { type: ERROR_TERRITORY_IS_EMPTY, msg: translate.instant('AGREEMENTS.SHARES.VALIDATION.ERROR_TERRITORY_IS_EMPTY') },
      { type: ERROR_LIMIT_EXCEEDED, msg: translate.instant('AGREEMENTS.SHARES.VALIDATION.LIMIT_EXCEEDED') },
      { type: ERROR_WRONG_END_DATE, msg: translate.instant('AGREEMENTS.SHARES.VALIDATION.ERROR_WRONG_END_DATE') },
      { type: ERROR_TOTAL_MORE_THAN_100, msg: translate.instant('AGREEMENTS.SHARES.VALIDATION.TOTAL_MORE_THAN_100') },
      { type: ERROR_NO_ENOUGH_MR_RIGHTS, msg: translate.instant('AGREEMENTS.SHARES.VALIDATION.ERROR_NO_ENOUGH_MR_RIGHTS') },
      { type: ERROR_NO_ENOUGH_PR_RIGHTS, msg: translate.instant('AGREEMENTS.SHARES.VALIDATION.ERROR_NO_ENOUGH_PR_RIGHTS') },
    ];
  }

  public static initTotals(model, totals) {
    (model || []).forEach(column => {
      ((column.inclusion && column.inclusion.split(',')) || []).forEach(inclusionRight => {
        totals[`${column.type}-${inclusionRight.trim()}`] =
          (totals[`${column.type}-${inclusionRight.trim()}`] || 0) + (isNumber(column.share) ? column.share : toNumber(column.share));
      });
    });
  }

  public static sumTypeALLValueToIndividualRights(model, totals) {
    (model || [])
      .filter(column => column.inclusion !== ALL_RIGHTS)
      .forEach(column => {
        ((column.inclusion && column.inclusion.split(',')) || []).forEach(inclusionRight => {
          totals[`${column.type}-${inclusionRight.trim()}`] = (totals[`${column.type}-${inclusionRight.trim()}`] || 0) + (totals[`${column.type}-${ALL_RIGHTS}`] || 0);
        });
      });
  }
}
