import { Injectable } from '@angular/core';
import { ExpertSearchParseService } from './expert-search-parse.service';

export enum UserStatusType {
  ACTIVE = 'ACTIVE',
}
@Injectable()
export class ExpertSearchParseUsersService extends ExpertSearchParseService {
  constructor() {
    const AttributesMapUsers = {
      username: async (comparator: string, value: string, _deep = false) => {
        let transformed = value;
        const split = value.split('-');
        if (split.length > 1) {
          const society = split.slice(-1)[0];
          const name = split.slice(0, -1).join('');
          transformed = `${society.toUpperCase()}:${name}`;
        }
        return { [comparator]: { 'attributes.id': transformed } };
      },
      firstName: 'attributes.firstName',
      lastName: 'attributes.lastName',
      email: 'attributes.email',
      status: 'attributes.status',
      roles: 'roles.name',
      organizationId: 'roles.organizationId',
      lastLoginDate: 'metrics.lastLogin',
      created: 'tags.created',
    };
    const attributesEnumMapUsers = {
      ['status']: Object.keys(UserStatusType),
    };
    super(attributesEnumMapUsers, AttributesMapUsers);
  }
}
