import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'ice-formly-field-button-menu',
  templateUrl: './button-menu.component.html',
  styleUrls: ['./button-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormlyFieldButtonMenuComponent extends FieldType implements OnInit, OnDestroy {
  public disabledButton: boolean;
  public optionsSubscription: Subscription;

  ngOnInit(): void {
    if (this.to && this.to.options) {
      this.optionsSubscription = (this.to.options as Observable<any>).subscribe(options => (this.disabledButton = true ? options && options.length === 0 : false));
    }
  }

  onClick(option: string): void {
    if (this.to.onClick) {
      this.to.onClick(option);
    }
  }

  ngOnDestroy(): void {
    if (this.optionsSubscription) {
      this.optionsSubscription.unsubscribe();
    }
  }
}
