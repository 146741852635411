import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CounterClaimUtils } from '@ice';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, of } from 'rxjs';
import * as fromRoot from 'store/root';

export class DialogAddSociety {
  private dialogRef: MatDialogRef<DialogMultiLayoutComponent, any>;
  private id = null;
  constructor(private translate: TranslateService, private dialog: MatDialog, private store: Store<fromRoot.RootState>) {}

  openDialog(onSubmit: (id) => void, autocompleteOptions) {
    this.id = null;
    this.dialogRef = this.dialog.open(DialogMultiLayoutComponent, {
      panelClass: 'overflow-hidden',
      data: {
        loading: this.store.pipe(select(fromRoot.getCopyrightLoading)),
        layouts: [this.getAddByIdLayout(onSubmit, autocompleteOptions)],
      },
    });
    return this.dialogRef;
  }

  getDialogConfig() {
    return {
      title: of(this.translate.instant('ADD_BY_ID_DIALOG.CREATOR_AFFILIATIONS.TITLE')),
      placeholder: `${this.translate.instant('ADD_BY_ID_DIALOG.COMMON.ADD')} ${this.translate.instant('ADD_BY_ID_DIALOG.CREATOR_AFFILIATIONS.TITLE')}`,
    };
  }

  getAddByIdLayout(onSubmit: (id) => void, autocompleteOptions) {
    const idSubject = new BehaviorSubject(true);
    const dialogConfigBySection = this.getDialogConfig();
    return {
      title: dialogConfigBySection.title,
      className: 'dialog-wrapper-auto',
      layout: [
        {
          group: [
            {
              type: 'formly',
              config: {
                setValidForm: valid => idSubject.next(!valid),
                formBuilder: of([
                  {
                    className: 'ice-field-w-80',
                    key: 'societyCode',
                    type: 'ice-select',
                    templateOptions: {
                      filterActive: true,
                      options: autocompleteOptions,
                      placeholder: dialogConfigBySection.placeholder,
                      required: true,
                    },
                    hooks: {
                      onInit: field => {
                        field.formControl.markAsTouched();
                        field.formControl.markAsDirty();
                      },
                    },
                  },
                  {
                    key: 'rights',
                    type: 'select',
                    modelOptions: {
                      updateOn: 'blur',
                    },
                    templateOptions: {
                      label: this.translate.instant('REPERTOIRES.STEPS.DETAILS.FIELDS.CREATOR_AFFILIATIONS.FIELDS.RIGHTS.TITLE'),
                      required: true,
                      multiple: false,
                      options: CounterClaimUtils.getRightsCreatorAffiliations(this.translate),
                    },
                  },
                ]),
                model: of({}),
                submitEnabled: true,
                submitAvailable: 'true',
                submitLabel: this.translate.instant('POPUP.CONFIRM'),
                button1Enabled: true,
                button1Available: 'true',
                button1Label: this.translate.instant('POPUP.CANCEL'),
                submit: value => onSubmit(value),
                onButton1: () => this.dialogRef.close(),
              },
            },
          ],
        },
      ],
    };
  }
}
