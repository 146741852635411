import { DatePipe } from '@angular/common';
import { NavigationExtras } from '@angular/router';
import { CurrentOrganization } from '@ice';
import { StringUtils } from '@ice/utils/string/string.utils';
import { UsersUtils } from '@ice/utils/users/users.utils';
import { ipsSocietiesCodes } from 'assets/ts/ips-societies';
import { conflictAreas, conflictSubTypes, conflictTypes } from 'config/constants/activity.constants';
import { TerritoryDataType } from 'config/constants/territories.constants';
import { ALL_REPORTS_NAMES } from 'config/search-form-builders/search-reports';
import { SectionsConfig } from 'config/sections-config';
import { isArray, isObject, isString, keysIn, mapKeys, mapValues, pickBy, startsWith } from 'lodash';
import { environment } from 'config/env';
import { SectionConflictsCounterClaimsActions } from 'config/sections-config/section-conflicts-counterclaims-actions';
import { SectionCopyrightAgreements } from 'config/sections-config/section-copyright-agreements';
import { SectionCopyrightWorks } from 'config/sections-config/section-copyright-works';
import { SearchUtils } from './search-utils';

export class SearchUtilsFactory implements SearchUtils {
  constructor(private section: string) {}

  static formatParamsAdvancedSearch(params, section?: string, formatKey = true) {
    if (params) {
      const formattedValues = mapValues(params, (value, key) => {
        const formatValue = this.formatParamValue(value, key, section);
        return isString(formatValue) ? formatValue.trim() : formatValue;
      });
      return pickBy(
        mapKeys(formattedValues, (value, key) => (formatKey && this.formatParam(value, key)) || key),
        value => value !== '',
      );
    }
    return params;
  }

  static formatParam(value, key) {
    switch (key) {
      case 'assignor':
        return keysIn(SearchUtilsFactory.formatParamsIsNumber(value, 'assignorName', 'assignorId', 'assignorName'))[0];
      case 'assignee':
        return keysIn(SearchUtilsFactory.formatParamsIsNumber(value, 'assigneeName', 'assigneeId', 'assigneeName'))[0];
    }
    return key;
  }

  static getXrefFromData(data, start?, count?) {
    const arrayStart = start !== undefined ? start : 1;
    return (
      (Array.isArray(data) &&
        data.length > arrayStart &&
        data
          .filter(row => Array.isArray(row) && row.length)
          .slice(arrayStart, (count && count + start) || Math.min(data.length, 1000))
          .map(row => row[0])
          .map(id => this.addWorkIdICEPrefix(id))) ||
      []
    );
  }

  static addWorkIdICEPrefix(id) {
    if (id && `${id}`.split(':').length > 1) {
      return id;
    } else {
      return `ICE:${id}`;
    }
  }

  static formatParamValue(value, key, section?, toUrl = false) {
    const formattedValue = this.formatValueForMultiSelectParameters(toUrl, value);

    switch (key) {
      case 'dueDateFrom':
      case 'fromDate':
      case 'deadline':
      case 'toDate':
      case 'dueDateTo':
      case 'endDate':
      case 'startDate':
      case 'counterClaimCreationFrom':
      case 'counterClaimCreationTo':
      case 'counterClaimResolutionFrom':
      case 'counterClaimResolutionTo':
      case 'actionDeadlineFrom':
      case 'actionDeadlineTo':
        const datePipe = new DatePipe('en-UK');
        const formattedDate = datePipe.transform(formattedValue, 'yyyy-MM-dd');
        return formattedDate;
      case 'country':
      case 'organizationId':
        return formattedValue && formattedValue.value !== undefined ? formattedValue.value : value; // to get the id value from the object in autocomplete component
      case 'socCode':
        return value;
      case 'tisn':
        return StringUtils.trimLeadingZerosFromString(formattedValue);
      case 'id':
        return section && section === 'users' ? UsersUtils.formatUserIdforSearch(formattedValue) : formattedValue;
    }
    if (toUrl) {
      switch (key) {
        case 'domesticSociety':
          return this.sendNullInAllItemsSelectFields(
            value.filter(val => val !== ''),
            formattedValue,
            ipsSocietiesCodes,
          );
        case 'conflictArea':
          return this.sendNullInAllItemsSelectFields(value, formattedValue, conflictAreas[0].options);
        case 'conflictType':
          return this.sendNullInAllItemsSelectFields(value, formattedValue, conflictTypes);
        case 'conflictSubType':
          return this.sendNullInAllItemsSelectFields(value, formattedValue, conflictSubTypes);
        case 'reportName':
          return value === ALL_REPORTS_NAMES ? null : formattedValue;
      }
    }
    if (isObject(formattedValue)) {
      return formattedValue?.value || formattedValue;
    }
    return formattedValue;
  }

  static sendNullInAllItemsSelectFields(value, formattedValue, options) {
    const arrayValue = isString(value) ? value.substring(1, value.length - 1).split(',') : value;
    return arrayValue.length === options.length ? null : formattedValue;
  }

  static formatValueForMultiSelectParameters(toUrl, value) {
    const valueToSendIsArray = toUrl && isArray(value);
    const valueFromURLIsArray = isString(value) && startsWith(value, '[');
    return valueToSendIsArray ? `[${value.join(',')}]` : valueFromURLIsArray ? value.substring(1, value.length - 1).split(',') : value;
  }

  static formatSearchExtras(extras: NavigationExtras) {
    let formattedExtras;
    if (extras && extras.queryParams) {
      const formattedValues = pickBy(
        mapValues(extras.queryParams, (value, key) => SearchUtilsFactory.formatParamValue(value, key, null, true)),
        value => value !== '',
      );
      formattedExtras = { ...extras, queryParams: formattedValues };
    }
    return formattedExtras || extras;
  }

  static formatQueryParams(queryParams: any, currentOrganization: CurrentOrganization): object {
    if (queryParams && queryParams['organizationId'] && currentOrganization?.id !== 'ICE:ICE') {
      return { ...queryParams, organizationId: currentOrganization?.id };
    }
    // When we changed the form input from being a single selection to a multiple selection,
    // the formly component expects an "arrayish" value in the shape of `[value]` or `[value1, value2]`
    ['actionStatus', 'caseStatus', 'conflictType'].forEach(key => {
      if (queryParams?.[key] && !/[\[\]]/.test(queryParams[key])) {
        queryParams[key] = `[${queryParams[key]}]`;
      }
    });

    return { ...queryParams };
  }

  private static formatParamsIsNumber(term, argAlphanumeric, argNumeric, argLetter) {
    const hasNumbers = StringUtils.isContainsNumbers(term);
    const hasLetters = StringUtils.isContainsLetters(term);
    if (hasLetters && hasNumbers) {
      return { [argLetter]: term };
    }
    if (!hasLetters && hasNumbers) {
      return { [argNumeric]: term };
    }
    return { [argAlphanumeric]: term };
  }

  private static formatParams(term: string, arg1: string, arg2?: string, arg3?: string, formatIdFunction?: Function): object {
    if (term[0] === '#') {
      return { [arg1]: formatIdFunction ? formatIdFunction(term.substr(1)) : term.substr(1) };
    } else {
      const splitTerm = term.split(':');
      if (splitTerm.length > 1 && arg3) {
        return { [arg2]: splitTerm[0], [arg3]: splitTerm[1] };
      } else {
        return { [arg2]: term };
      }
    }
  }

  public static formatTerritoryParams(term) {
    if (term[0] === '#') {
      return { tisa: term.substr(1) };
    } else if (StringUtils.isContainsNumbers(term)) {
      return { tisn: term };
    }
    return { name: term };
  }

  public static getAPIFormatFieldsValueFromParams(key: string): string {
    switch (key) {
      case TerritoryDataType.TISN:
      case TerritoryDataType.TISA:
      case TerritoryDataType.NAME:
        return key.toUpperCase();
      case TerritoryDataType.TISA_EXT:
        return 'TISA_EXT';
      case 'officialName':
        return 'OFFICIAL_NAME';
      case 'unofficialName':
        return 'UNOFFICIAL_NAME';
      default:
        return 'NAME';
    }
  }

  public static getSearchUrl(sectionConfig, namespace, data?) {
    const nsString = (!sectionConfig.removeUrlNSInSearch && `${namespace}/`) || '';
    let segment = sectionConfig.apiSegment;
    switch (sectionConfig.name) {
      case SectionConflictsCounterClaimsActions.name:
        segment = `v2/${sectionConfig.apiSegment}`;
        break;
      case SectionCopyrightWorks.name:
        if (data.agreementId && !data.contributorsAgreementId) {
          segment = SectionCopyrightAgreements.apiSegment;
        }
        break;
      default:
        break;
    }
    return `${environment.apiUrlCubeData}/${segment}/${nsString}search`;
  }

  getFormatted(term: string): object {
    switch (this.section) {
      case SectionsConfig.WORKS.name: {
        return SearchUtilsFactory.formatParams(term, 'xref', 'title', 'creator');
      }
      case SectionsConfig.IPS.name: {
        return SearchUtilsFactory.formatParams(term, 'xref', 'name', 'firstName');
      }
      case SectionsConfig.AGREEMENTS.name: {
        return SearchUtilsFactory.formatParams(term, 'xref', 'assignor', 'assignorId');
      }
      case SectionsConfig.AGREEMENT_GROUP.name: {
        return SearchUtilsFactory.formatParams(term, 'groupId', 'ipName', 'description');
      }
      case SectionsConfig.REPERTOIRES.name: {
        return SearchUtilsFactory.formatParams(term, 'xref', 'name');
      }
      case SectionsConfig.SOCIETIES.name: {
        return SearchUtilsFactory.formatParams(term, 'socCode', 'socName');
      }
      case SectionsConfig.TERRITORIES.name: {
        return SearchUtilsFactory.formatTerritoryParams(term);
      }
      case SectionsConfig.CONFLICTS.name: {
        return SearchUtilsFactory.formatParams(term, null, 'userId');
      }
      case SectionsConfig.AGREEMENTCONFLICT.name: {
        return SearchUtilsFactory.formatParams(term, 'id', 'id');
      }
      case SectionsConfig.ORGANIZATIONS.name: {
        return SearchUtilsFactory.formatParams(term, 'id', 'name');
      }
      case SectionsConfig.USERS.name: {
        return SearchUtilsFactory.formatParams(term, 'id', 'firstName', 'lastName', param => param);
      }
      case SectionsConfig.COUNTERCLAIMS_ACTIONS.name: {
        return SearchUtilsFactory.formatParams(term, 'actionDeadline', 'actionAssignedUser');
      }
      case SectionsConfig.COUNTERCLAIMS.name: {
        // Temporarily redirected to Counterclaim Actions following: https://onstage.atlassian.net/browse/CUBE-12178
        // return SearchUtilsFactory.formatParams(term, 'workRef', 'id');
        return SearchUtilsFactory.formatParams(term, 'actionDeadline', 'actionAssignedUser');
      }
      case SectionsConfig.REPORTS.name: {
        return SearchUtilsFactory.formatParams(term, 'id', 'reportName');
      }
    }
    return {};
  }
}
