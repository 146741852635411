import { Component, Input } from '@angular/core';
import { CardBasic } from './card-basic';

@Component({
  selector: 'ice-card-basic',
  templateUrl: './card-basic.component.html',
  styleUrls: ['./card-basic.component.scss'],
})
export class CardBasicComponent {
  @Input() config: CardBasic;

  constructor() {}
}
