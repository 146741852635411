<div class="page-layout simple tabbed">
  <div class="ice-p-12" fusePerfectScrollbar>
    <div
      *ngIf="
        components.cardWithForm || components.optionalCardWithForm1 || components.optionalCardWithForm2 || components.optionalCardWithForm3 || components.optionalCardWithForm4
      "
      [fxLayout]="components.formsAlign || 'row'"
      fxFlex="100"
      fxLayoutAlign="space-between start"
      class="ice-w-100 ice-pb-24"
    >
      <ice-card-with-form
        *ngIf="components.cardWithForm; let cwf"
        [cardHeight]="cwf.height"
        [ngStyle]="{ 'width.%': cwf.formPercentWidth || '100' }"
        [title]="cwf.title"
        [submitAvailable]="cwf.submitAvailable"
        [resetAvailable]="cwf.resetAvailable"
        [formBuilder]="cwf.formBuilder"
        [model]="cwf.model | async"
        class="{{ cwf.class }}"
        (submit)="onSubmit()"
      ></ice-card-with-form>
      <ice-card-with-form
        *ngIf="components.optionalCardWithForm1; let ocwf1"
        [cardHeight]="ocwf1.height"
        [ngStyle]="{ 'width.%': ocwf1.formPercentWidth || '0' }"
        [title]="ocwf1.title"
        [submitAvailable]="ocwf1.submitAvailable"
        [resetAvailable]="ocwf1.resetAvailable"
        [formBuilder]="ocwf1.formBuilder"
        [model]="ocwf1.model | async"
        class="{{ ocwf1.class }}"
        (submit)="onSubmit()"
      >
      </ice-card-with-form>
      <ice-card-with-form
        *ngIf="components.optionalCardWithForm2; let ocwf2"
        [cardHeight]="ocwf2.height"
        [ngStyle]="{ 'width.%': ocwf2.formPercentWidth || '0' }"
        [title]="ocwf2.title"
        [submitAvailable]="ocwf2.submitAvailable"
        [resetAvailable]="ocwf2.resetAvailable"
        [formBuilder]="ocwf2.formBuilder"
        [model]="ocwf2.model | async"
        class="{{ ocwf2.class }}"
        (submit)="onSubmit()"
      >
      </ice-card-with-form>
      <ice-card-with-form
        *ngIf="components.optionalCardWithForm3; let ocwf3"
        [cardHeight]="ocwf3.height"
        [ngStyle]="{ 'width.%': ocwf3.formPercentWidth || '0' }"
        [title]="ocwf3.title"
        [submitAvailable]="ocwf3.submitAvailable"
        [resetAvailable]="ocwf3.resetAvailable"
        [formBuilder]="ocwf3.formBuilder"
        [model]="ocwf3.model | async"
        class="{{ ocwf3.class }}"
        (submit)="onSubmit()"
      >
      </ice-card-with-form>
      <ice-card-with-form
        *ngIf="components.optionalCardWithForm4; let ocwf4"
        [cardHeight]="ocwf4.height"
        [ngStyle]="{ 'width.%': ocwf4.formPercentWidth || '0' }"
        [title]="ocwf4.title"
        [submitAvailable]="ocwf4.submitAvailable"
        [resetAvailable]="ocwf4.resetAvailable"
        [formBuilder]="ocwf4.formBuilder"
        [model]="ocwf4.model | async"
        class="{{ ocwf4.class }}"
        (submit)="onSubmit()"
      >
      </ice-card-with-form>
    </div>
    <div [fxLayout]="components.tablesAlign || 'row wrap'" fxFlex="100" fxLayoutAlign="space-between start" [fxLayoutGap]="components.tablesGap || '0'" class="ice-w-100 ice-pb-24">
      <ice-card-with-data-table
        *ngIf="components.cardWithDataTableOne; let ct1"
        [config]="ct1"
        [ngStyle]="{ 'width.%': ct1.tableWidth || '50' }"
        class="{{ ct1.class }} card-with-data-table-one"
      ></ice-card-with-data-table>
      <ice-card-with-data-table
        *ngIf="components.cardWithDataTableTwo; let ct2"
        [config]="ct2"
        [ngStyle]="{ 'width.%': ct2.tableWidth || '50' }"
        class="{{ ct2.class }} card-with-data-table-two"
      ></ice-card-with-data-table>
      <ice-card-with-data-table
        *ngIf="components.cardWithDataTableThree; let ct3"
        [config]="ct3"
        [ngStyle]="{ 'width.%': ct3.tableWidth || '50' }"
        class="{{ ct3.class }} card-with-data-table-three"
      ></ice-card-with-data-table>
      <ice-expansion-list-labeled
        fxFlex="{{ el1.flexWidth || '25' }}"
        *ngIf="components.col0ExpansionList; let el1"
        [expanded]="el1.expanded"
        [maxHeight]="el1.maxHeight"
        [minHeight]="el1.minHeight"
        [title]="el1.title"
        [selectable]="isObservable(el1.selectable) ? (el1.selectable | async) : el1.selectable"
        [model]="el1.model | async"
        [actionButton]="el1.actionButton | async"
        [rowButtons]="el2.rowButtons | async"
        (selectedItemEmit)="el1.onSelect($event)"
        (mouseWheelClickRowEmit)="el1.onMouseSelect($event)"
        (open)="el1.open()"
        (close)="el1.close()"
        [hideExpansionIcon]="el1.hideExpansionIcon"
        class="{{ el1.class }}"
      ></ice-expansion-list-labeled>

      <ice-expansion-list-labeled
        fxFlex="{{ el2.flexWidth || '25' }}"
        *ngIf="components.col1ExpansionList; let el2"
        [expanded]="el2.expanded"
        [maxHeight]="el2.maxHeight"
        [minHeight]="el2.minHeight"
        [title]="el2.title"
        [selectable]="isObservable(el2.selectable) ? (el2.selectable | async) : el2.selectable"
        [model]="el2.model | async"
        [actionButton]="el2.actionButton | async"
        [rowButtons]="el2.rowButtons | async"
        (selectedItemEmit)="el2.onSelect($event)"
        (mouseWheelClickRowEmit)="el2.onMouseSelect($event)"
        (open)="el2.open()"
        (close)="el2.close()"
        [hideExpansionIcon]="el2.hideExpansionIcon"
        class="{{ el2.class }}"
      ></ice-expansion-list-labeled>
    </div>
  </div>
</div>
