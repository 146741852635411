import { DataExporter, RootState, ShowSnackBar, StartApiCall } from 'store/root';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DataTableRow, SortInfo } from '@ice/components/data-table/data-table';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import { RootDatatable } from 'config/data-table-builders/root-datatable';

import { ColumnMode } from '@swimlane/ngx-datatable';
import { Store } from '@ngrx/store';
import { FileType } from 'services/report/report.service';
import { BulkUpdatesUtils } from '@ice/utils/bulk-updates/bulk-updates.utils';
import { getBulkUpdateDetails } from './../api-calls/ips/ips.api-calls';
import { DataTableBuilder } from './data-table-builder';

export class CopyrightBulkUpdatesDataTable extends RootDatatable implements DataTableBuilder {
  constructor(protected translate: TranslateService, protected fuseTranslationLoader: FuseTranslationLoaderService, protected store: Store<RootState>) {
    super(translate);
    this.fuseTranslationLoader.loadTranslations(english);
  }

  getDefaultSorting(): SortInfo[] {
    return [{ prop: 'createdAt', dir: 'desc' }];
  }

  getDataTable(): DataTableRow[] {
    return [
      {
        name: this.translate.instant('BULK_UPDATES.LIST.ID'),
        prop: 'bulkUpdateId',
        tooltip: 'copyId',
        cellClass: 'ice-txt-size-14 ',
        flexGrow: 1,
        action: row => {
          navigator.clipboard.writeText(row.bulkUpdateId);
          this.store.dispatch(
            new ShowSnackBar({ message: this.translate.instant('ACTIONS.COPIED_TO_CLIPBOARD', { message: `"${row.bulkUpdateId}"` }), duration: 2000, icon: 'file_copy' }),
          );
        },
      },
      {
        name: this.translate.instant('BULK_UPDATES.LIST.TYPE'),
        prop: 'bulkUpdateType',
        tooltip: 'bulkUpdateType',
        cellClass: 'ice-txt-size-14 ',
        flexGrow: 1,
      },
      {
        name: this.translate.instant('BULK_UPDATES.LIST.USER_ID'),
        prop: 'createdBy',
        tooltip: 'createdBy',
        cellClass: 'ice-txt-size-14 ',
        flexGrow: 1,
      },
      {
        name: this.translate.instant('BULK_UPDATES.LIST.STATUS'),
        prop: 'statusText',
        tooltip: 'statusText',
        cellClass: 'ice-txt-size-14 ',
        badge: { text: 'stagesCount', tooltip: 'tooltipCount', backgroundColor: '#0A9BE5', textColor: '#fff' },
        flexGrow: 1,
      },
      {
        name: this.translate.instant('BULK_UPDATES.LIST.DATE'),
        prop: 'createdAt',
        tooltip: 'createdAt',
        cellClass: 'ice-txt-size-14 ',
        flexGrow: 1,
      },
      {
        name: this.translate.instant('BULK_UPDATES.LIST.COMMENTS'),
        prop: 'comments',
        tooltip: 'comments',
        cellClass: 'ice-txt-size-14 ',
        flexGrow: 1,
      },
      {
        name: this.translate.instant('BULK_UPDATES.LIST.NOTES'),
        prop: 'notes',
        tooltip: 'notes',
        cellClass: 'ice-txt-size-14 ',
        flexGrow: 1,
      },
      {
        actionButtonIcon: 'download',
        prop: '.csv',
        cellClass: 'p-0 max-w-40px',
        // using `flexGrow` super small and setting the `minWidth` and `maxWidth` to 40px to make the button as small
        // as possible. The three are needed to trick on resize and work correctly in a responsive way
        flexGrow: 0.001,
        maxWidth: 40,
        minWidth: 40,
        resizeable: false,
        action: item => {
          this.store.dispatch(
            new StartApiCall({
              apiCall: { ...getBulkUpdateDetails, queryParams: { bulkUpdateId: item.bulkUpdateId } },
              callBack: (items, error) => {
                if (error) {
                  return;
                }
                this.store.dispatch(new ShowSnackBar({ message: this.translate.instant('ACTIONS.DOWNLOAD_COMPLETE'), duration: 2000, icon: 'thumb_up' }));
                this.store.dispatch(
                  new DataExporter({
                    name: `${item.bulkUpdateType}(${item.bulkUpdateId})`,
                    config: {
                      fileType: FileType.CSV,
                      downloadBatch: BulkUpdatesUtils.prepareBulkUpdateExport({ items, translate: this.translate }),
                      downloading: false,
                      visibleColumns: ['workId', 'status', 'details'],
                      columnLabelDictionary: {
                        workId: 'Work ID',
                        status: 'Status',
                        details: 'Details',
                      },
                    },
                  }),
                );
              },
            }),
          );
        },
        actionButtonTooltip: this.translate.instant('BULK_UPDATES.LIST.DOWNLOAD_CSV'),
        sortable: false,
      },
      {
        actionButtonIcon: 'download',
        prop: '.xlsx',
        cellClass: 'p-0 m-0',
        flexGrow: 0.001,
        maxWidth: 40,
        minWidth: 40,
        resizeable: false,
        action: item => {
          this.store.dispatch(
            new StartApiCall({
              apiCall: { ...getBulkUpdateDetails, queryParams: { bulkUpdateId: item.bulkUpdateId } },
              callBack: (items, error) => {
                if (error) {
                  return;
                }
                this.store.dispatch(new ShowSnackBar({ message: this.translate.instant('ACTIONS.DOWNLOAD_COMPLETE'), duration: 2000, icon: 'thumb_up' }));
                this.store.dispatch(
                  new DataExporter({
                    name: `${item.bulkUpdateType}(${item.bulkUpdateId})`,
                    config: {
                      fileType: FileType.XLSX,
                      downloadBatch: BulkUpdatesUtils.prepareBulkUpdateExport({ items, translate: this.translate }),
                      downloading: false,
                      visibleColumns: ['workId', 'status', 'details'],
                      columnLabelDictionary: {
                        workId: 'Work ID',
                        status: 'Status',
                        details: 'Details',
                      },
                    },
                  }),
                );
              },
            }),
          );
        },
        actionButtonTooltip: this.translate.instant('BULK_UPDATES.LIST.DOWNLOAD_XLSX'),
        sortable: false,
      },
    ];
  }

  getColumnMode(): ColumnMode {
    return ColumnMode.flex;
  }

  displayCheck(row: any, firstSelectedItem: any, targetItem: any, column?: any, value?: any): boolean {
    return true;
  }
}
