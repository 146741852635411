<div class="upload-file-container" iceDragDrop (fileDropped)="uploadFile($event)">
  <p class="drag-and-drop-text">{{ label || 'DRAG_AND_DROP_TEXT' | translate }}</p>
  <button mat-raised-button class="mat-accent" (click)="fileInput.click()" type="button">
    {{ 'SELECT_FILE' | translate }}
  </button>
  <input hidden type="file" #fileInput (change)="uploadFile($event.target.files, $event)" [accept]="fileType" attr.data-testid="file-input" />
</div>
<div class="files-list" *ngFor="let file of files; let i = index">
  <p>{{ file.name }}</p>
  <button class="delete-file ice-red" (click)="deleteAttachment(i)">
    <mat-icon>delete</mat-icon>
  </button>
</div>
