import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IceMaterialModule } from '@ice/material.module';
import { EditableTextComponent } from './editable-text.component';

@NgModule({
  declarations: [EditableTextComponent],
  imports: [CommonModule, ReactiveFormsModule, IceMaterialModule],
  exports: [EditableTextComponent],
})
export class EditableTextModule {}
