import { TableConfig } from '@ice/components/table/table.model';
import { TerritoryUtils } from '@ice/utils/territory/territory.utils';
import { ipsSocietiesCodes } from 'assets/ts/ips-societies';
import { toNumber } from 'lodash';
import { DictionaryCodeItem } from 'models/dictionaryCodeItem';
import moment from 'moment';
import { CLAIM_TYPES } from './repertoires.constants';
import { DATE_FORMAT } from './global.constants';

export const TAB_DETAIL = 'details';
export const TAB_WORK_SUB_CLAIMS = 'work-claims';
export const TAB_WORK_ALL_CLAIMS = 'all-claims';
export const TABS_TO_ADD_CLAIMS = [TAB_DETAIL, TAB_WORK_SUB_CLAIMS, TAB_WORK_ALL_CLAIMS];

export const WORK_SECTION = 'works';
export const workCategoriesCodes: DictionaryCodeItem[] = [
  {
    code: 'COS',
    name: 'Composite of Samples',
  },
  {
    code: 'EXC',
    name: 'Excerpt',
  },
  {
    code: 'MED',
    name: 'Medley',
  },
  {
    code: 'POT',
    name: 'Potpourri',
  },
  {
    code: 'UCO',
    name: 'Unspecified Composite',
  },
];

export const CREATOR = 'CREATOR';
export const PUBLISHER = 'ORIGINAL_PUBLISHER';
export const SUB_PUBLISHER = 'SUB_PUBLISHER';
export const ICE_TERRITORIES = 'ICE Territories';
export const DEFAULT_FILTER_MODEL = {
  territory: TerritoryUtils.getIceCountryGroups()
    .find(group => group.header === ICE_TERRITORIES)
    ?.options.map(option => option.value),
  mrRight: '',
  prRight: '',
  usageDate: moment().format(DATE_FORMAT),
  distributionDate: moment().format(DATE_FORMAT),
  status: Object.values(CLAIM_TYPES),
};

export const INCOME_PARTICIPANT_VALUE = 'Income Participant';
export const INCOME_PARTICIPANT_JSON = { incomeParticipant: ['true'] };
export const TITLE_TYPES = {
  alternativeAL: 'AL',
  alternative: 'AT',
  alternativeET: 'ET',
  alternativeFT: 'FT',
  alternativeIT: 'IT',
  alternativeOL: 'OL',
  original: 'OT',
  alternativePT: 'PT',
  alternativeRT: 'RT',
  alternativeTE: 'TE',
  alternativeTT: 'TT',
  alternativeDI: 'DI',
  alternativeDT: 'DT',
  alternativeLT: 'LT',
  alternativeOV: 'OV',
  alternativeFA: 'FA',
  alternativeNT: 'NT',
  alternativeCT: 'CT',
  alternativeVT: 'VT',
};
export const CUBE = 'CUBE';
export const ISWC = 'ISWC';
export const NOT_ICE = 'Not ICE';
export const SOCIETIES = ['BUMA', 'KODA', 'MCPS', 'SABAM', 'STIM', 'TEOSTO', 'TONO'];

export const TABLE_EDIT_MODE_HEADER = [
  { name: 'Name', prop: 'name', hideTooltip: true },
  { name: 'Code', prop: 'code', hideTooltip: true },
]; // Translate
export const SOCIETIES_ICE: any[] = ipsSocietiesCodes.filter(soc => soc.isIce).map(soc => ({ name: soc.name, code: toNumber(soc.code) }));
export const SOCIETIES_NOT_ICE: any[] = ipsSocietiesCodes.filter(soc => !soc.isIce).map(soc => ({ name: soc.name, code: toNumber(soc.code) }));
export const TABLE_SOCIETIES: TableConfig = {
  header: TABLE_EDIT_MODE_HEADER,
  groups: [
    { name: 'ICE', prop: 'ice' },
    { name: NOT_ICE, prop: 'notIce' },
  ],
  rows: { ice: SOCIETIES_ICE, notIce: SOCIETIES_NOT_ICE },
  selectable: true,
};
export const [TABLE_PUBLISHERS, TABLE_CREATORS]: TableConfig[] = Array(2).fill({
  header: TABLE_EDIT_MODE_HEADER,
  selectable: true,
  multiselect: true,
});

export const ICE_SOCIETIES = ipsSocietiesCodes
  .map(society => ({ value: society.name, key: toNumber(society.code), type: 'society', groupBy: society.isIce ? 'ICE' : NOT_ICE }))
  .sort((a, b) => (a.groupBy < b.groupBy ? -1 : 1));

export const societies = ipsSocietiesCodes
  .map(society => ({ value: society.name, key: toNumber(society.code), type: 'society', groupBy: society.isIce ? 'ICE' : NOT_ICE }))
  .sort((a, b) => (a.groupBy < b.groupBy ? -1 : 1));

export const WORK_SOURCE_OPTIONS = [
  { label: '', value: '' },
  { label: 'FICHE', value: 'FICHE' },
  { label: 'CUE', value: 'CUE' },
  { label: 'CIS', value: 'CIS' },
  { label: 'RDQI', value: 'RDQI' },
  { label: 'CFW', value: 'CFW' },
  { label: 'SOC', value: 'SOC' },
];

export const ALL_PR = 'ALL PR Rights';
export const ALL_MR = 'ALL MR Rights';
export const ALL_STATUS = 'ALL_STATUS';

export const DEFAULT_ALL_CLAIMS_FILTER_MODEL = {
  territory: '',
  mrRight: '',
  prRight: '',
  usageDate: '',
  distributionDate: '',
  status: '',
  addOutdated: true,
  removeE: true,
  removeSE: true,
};

export const WORK_MUSIC_RELATIONSHIP_OPTIONS = [
  { label: '', value: '' },
  { label: 'Music and Text not Co-authored (MTN)', value: 'MTN' },
  { label: 'Music and Text Co-authored (MTX)', value: 'MTX' },
];

export const WORK_CATEGORY_OPTIONS = [
  { label: '', value: '' },
  { label: 'Composite of Samples (COS)', value: 'COS' },
  { label: 'Excerpt (EXC)', value: 'EXC' },
  { label: 'Medley (MED)', value: 'MED' },
  { label: 'Potpurri (POT)', value: 'POT' },
  { label: 'Unspecified Composite (UCO)', value: 'UCO' },
];

export const TABS_WITH_TOOLTIPS = ['CONFLICTS_CC', 'SOCIETY_ATTRIBUTES', 'EXTENDED_TOP'];

export const ROLE_SPECIAL = 'Special';
export const UNAUTHORIZED = 'unauthorized';
export const INVALID_SPECIAL_ICONS = [UNAUTHORIZED];

export enum WorkAuditEvents {
  WORK_SOCIETY = 'Work Society',
  WORK_CLAIM = 'Work Claim',
}

export const EVENT_ID = 'eventID';
export const ROW_MATCH = 'row-match';

export const WORK_TAGS_SUBMITTED = { submittedFrom: ['UI'] };
export const WORK_TAGS = { tags: WORK_TAGS_SUBMITTED };
export const HISTORY_MULTIPLE_EVENTS_CLASS = 'has-changes-indicator';

export enum WORK_RELATION_TYPE {
  MATCH = 'MTCH',
}
