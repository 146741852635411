import { SectionsConfig } from 'config/sections-config';
import { WorkUtils } from '..';

export class CloneUtils {
  static getFormattedCloneItem(item: any, section: string) {
    switch (section) {
      case SectionsConfig.WORKS.name:
        return WorkUtils.cleanWorkForClone(item);
      default:
        return item;
    }
  }
}
