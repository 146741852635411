import { createSelector } from '@ngrx/store';
import { OrganizationType } from 'config/constants/organizations.constants';
import { get } from 'lodash';
import * as fromFeature from 'store/root/reducers';
import * as fromAuth from 'store/root/reducers/auth/auth.reducer';

export const getLoginLoading = createSelector(fromFeature.getAuthFeatureState, fromAuth.getAuthLoadingState);

export const getLoginUser = createSelector(fromFeature.getAuthFeatureState, fromAuth.getLoginUserState);

export const getLoginError = createSelector(fromFeature.getAuthFeatureState, fromAuth.getLoginErrorState);

export const getLoginNewPasswordInfo = createSelector(fromFeature.getAuthFeatureState, fromAuth.getLoginNewPasswordInfoState);

export const getUserName = createSelector(getLoginUser, user => {
  return user && user.userName;
});

export const getNewPasswordInfo = createSelector(getLoginNewPasswordInfo, newPasswordInfo => newPasswordInfo);

export const getUserDetail = createSelector(getLoginUser, user => {
  return user && user.detail;
});

export const getUserNS = createSelector(getLoginUser, user => {
  return user && user.ns;
});

export const getUser = createSelector(getLoginUser, user => {
  return user;
});

export const getUserOrganizationsForSelect = createSelector(getLoginUser, user => {
  const organizations = get(user, 'organizations') || [];
  if (organizations.length) {
    return organizations.map(item => {
      return { value: item.id, label: item.id };
    });
  } else if (user && user.detail) {
    return [{ value: user.detail.organizationId, label: user.detail.organizationId }];
  }

  return [];
});

export const getUserDisplayName = createSelector(getLoginUser, user => {
  if (user) {
    let userName = `${user.name || ''} ${user.surname || ''}`;
    if (!userName.trim()) {
      userName = user.userName;
    }
    if (!userName) {
      userName = user.email;
    }
    return userName;
  }
  return '';
});

export const getUserRole = createSelector(fromFeature.getAuthFeatureState, user => user && user.role);

export const getUserDetailRoles = createSelector(getUserDetail, detail => detail && detail.roles);

export const getUserRoles = createSelector(getUserDetail, detail => detail && detail.rolesList);

export const getUserOrganizationId = createSelector(getLoginUser, user => get(user, 'detail.organizationId', ''));

export const getUserOrganizations = createSelector(getLoginUser, user => (user && user.organizations ? user.organizations : []));

export const getUserCurrentOrganization = createSelector(getLoginUser, user => user && user.currentOrganization);
