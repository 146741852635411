import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { from, of, throwError, timer } from 'rxjs';
import { catchError, mapTo, switchMap, tap } from 'rxjs/operators';
import { ExpertSearchService } from 'services/search/expert-search.service';
import { StorageService } from 'services/storage/storage.service';
import { ExpertSearchParseService } from '../../../services/search/expert-search-parse.service';
import { QueryEditorExpertSearchComponent } from '../query-editor-expert-search/query-editor-expert-search.component';
import { QueryChip } from './model/query-chip';

export class ValidateAdvancedSearchSyntax {
  static createValidator(qeryLanguageParser: ExpertSearchParseService) {
    return (control: AbstractControl) => {
      return timer(100).pipe(
        switchMap(() => {
          try {
            return from(qeryLanguageParser.parseSearchQuery(control.value));
          } catch (e) {
            return throwError(`${e}`.replace('SyntaxError: ', ''));
          }
        }),
        mapTo(null),
        catchError(error => of({ syntaxError: error })),
      );
    };
  }
}

@Component({
  selector: 'ice-expert-search',
  templateUrl: './expert-search.component.html',
  styleUrls: ['./expert-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpertSearchComponent implements OnInit, OnChanges {
  queryLanguageParser: ExpertSearchParseService;
  advancedSearchForm: FormGroup;
  visible = true;
  selectable = true;
  showError = false;
  removable = true;
  addOnBlur = true;
  queryChipsDisabled: boolean;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  queryChips: QueryChip[] = [];
  AttributesMap: object;
  attributesEnumMap: object;

  @Input() searchInputQuery = '';
  @Input() section: string;
  @Input() showUploadXref = false;
  @Output() submitQuery = new EventEmitter<string>();
  @Output() reset = new EventEmitter();
  @Output() uploadXref = new EventEmitter();
  @ViewChild(QueryEditorExpertSearchComponent, { static: true }) queryEditorAdvancedSearch;

  get f() {
    return this.advancedSearchForm.controls['expertSearchFormControl'];
  }

  submitClick(e: any, query: string) {
    if (!this.f.errors) {
      this.submitQuery.emit(query);
      this.showError = false;
    } else {
      this.showError = true;
    }
  }

  resetClick(e: any) {
    this.queryEditorAdvancedSearch.setValue('');
    this.showError = false;
    this.updateQueryChips(this.searchInputQuery);
    this.reset.emit();
  }

  uploadXrefClick() {
    this.uploadXref.emit();
  }

  updateQueryChips(newValue: string) {
    this.queryChips = this.queryChips.map(queryChipIt => (queryChipIt.query === newValue ? { ...queryChipIt, selected: true } : { ...queryChipIt, selected: false }));
    this.queryChipsDisabled =
      !this.advancedSearchForm.controls['expertSearchFormControl'].value || this.queryChips.length >= 10 || this.queryChips.some(queryChip => queryChip.selected);
    if (this.queryChipsDisabled && !this.advancedSearchForm.controls['queryChipInput'].disabled) {
      this.advancedSearchForm.controls['queryChipInput'].disable();
    } else if (!this.queryChipsDisabled && this.advancedSearchForm.controls['queryChipInput'].disabled) {
      this.advancedSearchForm.controls['queryChipInput'].enable();
    }
  }

  ngModelChange(event) {
    this.updateQueryChips(this.advancedSearchForm.controls['expertSearchFormControl'].value);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.section) {
      this.queryLanguageParser = this.expertSearchService.forSection(changes.section.currentValue);
      this.queryChips = this.storageService.getEntity(changes.section.currentValue + '-expert-search') || [];
      setTimeout(() => {
        this.advancedSearchForm = this.formBuilder.group({
          expertSearchFormControl: new FormControl(this.searchInputQuery, [], ValidateAdvancedSearchSyntax.createValidator(this.queryEditorAdvancedSearch.queryLanguageParser)),
          queryChipInput: new FormControl(''),
        });
        setTimeout(() => {
          this.queryEditorAdvancedSearch.setValue(this.searchInputQuery);
          this.updateQueryChips(this.searchInputQuery);
        }, 0);
      });
    } else {
      if (this.advancedSearchForm && changes.searchInputQuery && changes.searchInputQuery.currentValue !== changes.searchInputQuery.previousValue) {
        this.queryEditorAdvancedSearch.setValue(this.searchInputQuery);
        this.updateQueryChips(this.searchInputQuery);
      }
    }
  }

  ngOnInit() {
    this.advancedSearchForm = this.formBuilder.group({
      expertSearchFormControl: new FormControl(this.searchInputQuery, [], ValidateAdvancedSearchSyntax.createValidator(this.queryEditorAdvancedSearch.queryLanguageParser)),
      queryChipInput: new FormControl(''),
    });
    setTimeout(() => this.updateQueryChips(this.searchInputQuery), 0);
  }

  add(event: MatChipInputEvent): void {
    if (!this.advancedSearchForm.controls['queryChipInput'].disabled) {
      const { input, value } = event;

      // Add our queryChip
      if ((value || '').trim()) {
        this.queryChips.push({ name: value.trim(), query: this.advancedSearchForm.get('expertSearchFormControl').value });
        this.persistQueryChips();
        this.updateQueryChips(this.advancedSearchForm.get('expertSearchFormControl').value);
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }
    }
  }

  persistQueryChips() {
    this.storageService.setEntity(
      this.section + '-expert-search',
      this.queryChips.map(({ query, name }) => ({
        query,
        name,
      })),
    );
  }

  remove(queryChip: QueryChip): void {
    const index = this.queryChips.indexOf(queryChip);

    if (index >= 0) {
      this.queryChips.splice(index, 1);
    }
    this.persistQueryChips();
    this.updateQueryChips(this.advancedSearchForm.get('expertSearchFormControl').value);
  }

  selectQuery(queryChip: QueryChip) {
    this.searchInputQuery = queryChip.query;
    this.updateQueryChips(queryChip.query);
    this.queryEditorAdvancedSearch.setValue(queryChip.query);
  }

  constructor(private formBuilder: FormBuilder, private expertSearchService: ExpertSearchService, private storageService: StorageService) {}
}
