import { SectionsConfig } from 'config/sections-config';

export const navApplications = {
  id: 'applications',
  translate: 'NAV.DASHBOARD',
  type: 'group',
  children: [],
};

export const navCopyright = {
  id: 'copyr',
  translate: 'NAV.COPYRIGHT.TITLE',
  classes: 'nav-copyr',
  type: 'collapsable',
  icon: 'copyright',
  children: [
    {
      id: 'works',
      translate: 'NAV.COPYRIGHT.WORKS',
      type: 'item',
      url: SectionsConfig.WORKS.url,
    },
    {
      id: 'ips',
      translate: 'NAV.COPYRIGHT.IPS',
      type: 'item',
      url: SectionsConfig.IPS.url,
    },
    {
      id: 'agreements',
      translate: 'NAV.COPYRIGHT.AGREEMENTS',
      type: 'item',
      url: SectionsConfig.AGREEMENTS.url,
    },
    {
      id: 'agreement_group',
      translate: 'NAV.COPYRIGHT.AGREEMENT_GROUP',
      type: 'item',
      url: SectionsConfig.AGREEMENT_GROUP.url,
    },
    {
      id: 'repertoires',
      translate: 'NAV.COPYRIGHT.REPERTOIRES',
      type: 'item',
      url: SectionsConfig.REPERTOIRES.url,
    },
    {
      id: 'societies',
      translate: 'NAV.COPYRIGHT.SOCIETIES',
      type: 'item',
      url: SectionsConfig.SOCIETIES.url,
    },
    {
      id: 'territories',
      translate: 'NAV.COPYRIGHT.TERRITORIES',
      type: 'item',
      url: SectionsConfig.TERRITORIES.url,
    },
    {
      id: 'reports',
      translate: 'NAV.COPYRIGHT.REPORTS',
      type: 'item',
      url: SectionsConfig.REPORTS.url,
    },
    {
      id: 'bulk-updates',
      translate: 'NAV.COPYRIGHT.BULK_UPDATES',
      type: 'item',
      url: SectionsConfig.BULK_UPDATES.url,
    },
  ],
};

export const navConflicts = {
  id: 'conflicts',
  translate: 'NAV.CONFLICTS.TITLE',
  classes: 'nav-conflicts',
  type: 'collapsable',
  icon: 'account_balance',
  children: [
    {
      id: 'activity',
      title: 'Activity',
      translate: 'NAV.CONFLICTS.ACTIVITY',
      type: 'item',
      url: SectionsConfig.CONFLICTS.url,
    },
    {
      id: 'agreement-conflict',
      title: 'Agreements',
      translate: 'NAV.CONFLICTS.AGREEMENT_CONFLICT',
      type: 'item',
      url: SectionsConfig.AGREEMENTCONFLICT.url,
    },
    {
      id: 'counter-claims-actions',
      title: 'CounterClaim Actions',
      translate: 'NAV.CONFLICTS.COUNTER_CLAIMS_ACTIONS',
      type: 'item',
      url: SectionsConfig.COUNTERCLAIMS_ACTIONS.url,
    },
    {
      id: 'dashboard',
      title: 'Dashboard',
      translate: 'NAV.CONFLICTS.DASHBOARD',
      type: 'item',
      url: '/conflicts/counter-claims/dashboard',
    },
    {
      id: SectionsConfig.COUNTERCLAIM_NOTIFICATIONS.name,
      title: 'Counterclaim Notificiations',
      translate: 'NAV.CONFLICTS.COUNTERCLAIM_NOTIFICATIONS',
      type: 'item',
      url: SectionsConfig.COUNTERCLAIM_NOTIFICATIONS.url,
    },
  ],
};

export const navFiches = {
  id: 'fiches',
  translate: 'NAV.FICHES.TITLE',
  type: 'item',
  icon: 'settings',
};

export const navAdministration = {
  id: 'administration',
  translate: 'NAV.ADMINISTRATION',
  type: 'group',
  children: [
    {
      id: 'user_management',
      translate: 'NAV.USER_MANAGEMENT.TITLE',
      classes: 'nav-user-management',
      type: 'collapsable',
      icon: 'person',
      children: [],
    },
  ],
};

export const navAdminOrganizations = {
  id: 'organizations',
  translate: 'NAV.USER_MANAGEMENT.ORGANIZATIONS',
  type: 'item',
  url: SectionsConfig.ORGANIZATIONS.url,
};
export const navAdminUsers = {
  id: 'users',
  translate: 'NAV.USER_MANAGEMENT.USERS',
  type: 'item',
  url: SectionsConfig.USERS.url,
};

export const initialNavigation = [{ ...navApplications, children: [navCopyright] }];

export const allNavigation: any = [
  { ...navApplications, children: [navCopyright, navConflicts, navFiches] },
  { ...navAdministration, children: [{ ...navAdministration.children, children: [navAdminOrganizations, navAdminUsers] }] },
];
