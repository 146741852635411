import { environment } from 'config/env';
import type { SectionConfig } from './sections-config';

export const SectionCopyrightIps: SectionConfig = {
  name: 'ips',
  url: '/copyright/ips',
  domainName: 'copyright',
  baseUrl: environment.apiUrl,
  baseUrlCubeData: environment.apiUrlCubeData,
  apiSegment: 'party-names',
  detailSegment: `party-names/<<ns>>/<<id>>`,
  minCharsToSearch: 3,
  apiIncludes: {
    search: `attributes{id,name,firstName, typeOfName, publisher}, relations{relation,otherId},parties.party.relations[XREF]{relation,otherId},
    parties.party.societies{societyId,society,memberships}, parties.party.attributes{typeOf, status},
    parties.party.partyNames.partyName.relations[XREF]{relation,otherId}, agg.attributes`,
    detail: `works,admin.attributes, attributes{id,name,firstName, typeOfName, names, clauses}.names, relations{relation,otherId},
    parties.party.relations{relation,otherId},
    parties.relations,parties.party.societies{society, memberships}.society{id,attributes}.attributes,parties.relations,parties.relations,parties.party.partyNames.partyName,
    parties.party.attributes{typeOf, status,sex,dateOfBirth,countryOfBirth,placeOfBirth,dateOfDeath},
    agg.attributes, counterclaims.counterclaim.attributes`,
  },
  searchServerSideSortDefault: 'attributes.name, attributes.firstName',
  allowedActions: {
    newItem: false,
    editItem: 'tab',
    newNote: true,
    removeItem: false,
  },
  auditTypes: ['party-name'],
};
