import { AnalysisAndComparisons } from '@ice/dynamic-components/analysis-and-comparisons/analysis-and-comparisons';
import { AnalysisAndComparisonsComponent } from '@ice/dynamic-components/analysis-and-comparisons/analysis-and-comparisons.component';
import { FormsAndTables } from '@ice/dynamic-components/forms-and-tables/forms-and-tables';
import { FormsAndTablesComponent } from '@ice/dynamic-components/forms-and-tables/forms-and-tables.component';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { GroupComponent } from '@ice/dynamic-components/group-component/group-component.component';
import { SectionTabConfig, TabConfig } from './tabs-data';

export class GroupComponentConfig implements TabConfig {
  main = GroupComponent;
  components: IceGroupComponent[];
  onDestroy: any;
  constructor(public sectionTabConfig: SectionTabConfig) {
    this.components = <IceGroupComponent[]>sectionTabConfig.getConf();
    this.onDestroy = sectionTabConfig.onDestroy;
  }
}
/**
 * structure with one card-with-form and two card-with-data-table
 * used in agreements-details (first tab)
 */
export class FormsAndTablesConfig implements TabConfig {
  main = FormsAndTablesComponent;
  components: FormsAndTables;
  constructor(public sectionTabConfig: SectionTabConfig) {
    this.components = <FormsAndTables>sectionTabConfig.getConf();
  }
}

export class AnalysisAndComparisonsConfig implements TabConfig {
  main = AnalysisAndComparisonsComponent;
  components: AnalysisAndComparisons;

  constructor(public sectionTabConfig: SectionTabConfig) {
    this.components = <AnalysisAndComparisons>sectionTabConfig.getConf();
  }
}
