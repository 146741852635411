<div fxLayout="row">
  <mat-form-field fxLayout="column" fxFlex="{{ flex }}">
    <mat-label *ngIf="field && field.templateOptions && field.templateOptions.label">{{ field?.templateOptions?.label }}</mat-label>
    <input
      [id]="id"
      type="text"
      matInput
      [placeholder]="to.placeholder"
      aria-label="Number"
      [required]="to.required"
      [formControl]="formControl"
      [matAutocomplete]="auto"
      #inputAutoComplete
      (blur)="onBlurMethod($event)"
      (keyup)="setFilter($event)"
      (click)="doHighlight($event)"
      [matTooltip]="to.addTooltip ? getTooltip(formControl?.value) : tempTooltip"
      [matTooltipClass]="'ice-tooltip'"
      [matTooltipPosition]="'above'"
      (mouseover)="enterField($event, formControl?.value)"
      (mouseout)="leaveField()"
    />
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn" [panelWidth]="to?.panelWidth">
      <mat-option
        *ngFor="let option of filteredOptions | async"
        [value]="option"
        (click)="onClick($event, option)"
        [matTooltip]="to.addTooltip ? getTooltip(formControl?.value) : tempTooltip"
        [matTooltipClass]="'ice-tooltip'"
        [matTooltipPosition]="'above'"
        (mouseover)="enterField($event, option.label)"
        (mouseout)="leaveField()"
      >
        {{ option.label }}
      </mat-option>
    </mat-autocomplete>
    <mat-icon *ngIf="formControl.value" matSuffix class="cursor-pointer" (click)="clearValue()">clear</mat-icon>
    <mat-error [id]="null">
      <formly-validation-message [field]="field"></formly-validation-message>
    </mat-error>
  </mat-form-field>
</div>
