import { environment } from 'config/env';
import { SectionRouterViews } from './section-router-views';
import type { SectionConfig } from './sections-config';

export const SectionConflictsAgreementConflict: SectionConfig = {
  name: 'agreement-conflict',
  url: '/conflicts/agreement-conflict',
  domainName: 'conflicts',
  baseUrl: environment.apiUrl,
  baseUrlCubeData: environment.apiUrlCubeData,
  apiSegment: 'counterclaims',
  detailSegment: `counterclaims/CUBE/<<id>>`,
  minCharsToSearch: 5,
  apiIncludes: {
    search: `attributes, tags, cases, agreements, agreements.agreement.attributes,
    agreements.agreement.conflicts, agreements.agreement.conflicts.conflict.attributes, actions, actions.attributes, agreements.agreement.counterclaims.counterclaim.attributes`,
    detail: `attributes, tags, actions, actions.attributes, agreements,
    agreements.agreement.attributes{id, administrator, agreementType, searchTerm, shares, agreementDispute, termTerritory, retention},
    agreements.agreement.relations{relation,otherId},
    agreements.agreement.assignor{party,partyName}.partyName.attributes{id, name, firstName},
    agreements.agreement.assignor.partyName.relations{relation,otherId}, agreements.agreement.assignor.party.attributes{typeOf},
    agreements.agreement.assignor.party.societies{societyId,society,memberships},
    agreements.agreement.assignee{party,partyName}.partyName.attributes{id, name, firstName},
    agreements.agreements.assignee.partyName.relations{relation,otherId},
    agreements.agreement.assignee.party.societies{societyId,society,memberships}, agreements.agreement.conflicts{conflictId},
    agreements.agreement.agg.attributes.workCount, agreements.agreement.conflicts,
    agreements.agreement.conflicts.conflict.attributes, agreements.agreement.conflicts.conflict.case, agreements.agreement.groups.group.relations,
    agreements.agreement.counterclaims.counterclaim.attributes`,
  },
  searchServerSideSortDefault: '',
  allowedActions: {
    newItem: false,
    editItem: 'stepper',
    newNote: false,
    removeItem: false,
  },
  newItemBtn: [SectionRouterViews.home, SectionRouterViews.search],
  homeRedirect: 'search',
};
