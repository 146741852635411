<ng-container *ngIf="config && !(config.hide | async)">
  <mat-card fxFlex="48" [ngClass]="config.class ? config.class : ''">
    <mat-card-header class="ice-light-grey-bg card-expanded-header">
      <mat-card-title style="margin-top: 10px" [ngClass]="config.titleClass ? config.titleClass : ''" data-testid="card-title"
        >{{ config.title || (config.titleWithCount | async) }}
      </mat-card-title>
      <div style="margin-top: 10px" *ngIf="config.infoIconTooltip">
        <mat-icon class="mat-icon material-icons" tabindex="-1" [matTooltip]="config.infoIconTooltip">info</mat-icon>
      </div>
      <div fxFlex="45" *ngIf="config.actionButtons | async; let actionButtons" class="display-flex-row-reverse flex-align-items-center flex-1">
        <ng-container *ngFor="let actionButton of actionButtons; let actionButtonIndex = index">
          <button
            mat-icon-button
            [matTooltip]="actionButton.tooltip"
            [matTooltipClass]="'ice-tooltip'"
            [matTooltipPosition]="'above'"
            [ngClass]="actionButton.buttonClass"
            (click)="actionButton.onClick()"
            [disabled]="actionButton.disabled && (actionButton.disabled | async)"
            attr.data-testid="action-button-{{ actionButtonIndex }}"
          >
            <mat-icon class="mat-icon material-icons" tabindex="-1" [ngClass]="actionButton.class">{{ actionButton.icon }} </mat-icon>
          </button>
        </ng-container>
      </div>
      <div fxFlex="45" *ngIf="config.toggleButtons | async; let toggleButtons" class="display-flex-row-reverse flex-align-items-center flex-1">
        <mat-button-toggle-group appearance="standard" [value]="toggleButtons.defaultValue" (change)="toggleButtons.change($event)">
          <ng-container *ngFor="let toggleButton of toggleButtons.buttons">
            <mat-button-toggle
              [class]="toggleButton.buttonClass"
              [value]="toggleButton.value"
              [matTooltip]="toggleButton.label"
              [matTooltipPosition]="'above'"
              [matTooltipClass]="'ice-tooltip'"
            >
              <mat-icon *ngIf="toggleButton.icon" class="ice-toggle-button-icon" tabindex="-1">{{ toggleButton.icon }}</mat-icon>
              <span *ngIf="!toggleButton.icon" class="ice-toggle-button-text">{{ toggleButton.displayValue || takeFirstLetter(toggleButton.label) }}</span>
            </mat-button-toggle>
          </ng-container>
        </mat-button-toggle-group>
      </div>
      <div fxFlex="45" *ngIf="config.optionsGroup | async; let optionsGroup" class="display-flex-row-reverse flex-align-items-center flex-1">
        <mat-radio-group (change)="optionsGroup.change($event)">
          <mat-radio-button
            *ngFor="let op of optionsGroup.options"
            [tabindex]="-1"
            [ngClass]="{ disabled: op.disabled || (config.loading | async) }"
            [checked]="op.checked"
            [value]="op.value"
            attr.data-testid="card-option-{{ op.label }}"
          >
            {{ op.label }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </mat-card-header>
    <mat-card-content
      class="ice-overflow-auto"
      [ngClass]="config.contentClass ? config.contentClass : ''"
      [ngStyle]="{
        'max-height.px': config.maxHeight ? config.maxHeight : 'auto',
        height: config.height ? (isObservable(config.height) ? (config.height | async) : config.height) : 'auto'
      }"
    >
      <ice-form
        fxFlex="100"
        *ngIf="config.filter"
        [resetAvailable]="config.filter.resetAvailable"
        [submitAvailable]="config.filter.submitAvailable"
        [formBuilder]="config.filter.formBuilder"
        [model]="config.filter.model | async"
        [submitLabel]="config.filter.submitLabel"
        [submitClass]="config.filter.submitClass"
        [forceSubmitDisabled]="config.filter.forceSubmitDisabled && (config.filter.forceSubmitDisabled[selectedType] || config.filter.forceSubmitDisabled['default'])"
        [submitShortcutEnable]="config.submitShortcutEnable"
        [formInline]="config.filter.formInline"
        [ngClass]="config.filter.formClass"
        [isFlatButton]="config.filter.isFlatButton"
        (submit)="config.filter.onSubmit($event)"
        (resetClick)="config.filter.onReset?.($event)"
      >
      </ice-form>

      <div class="overWidget" *ngIf="config.loading | async">
        <div class="spinner-container">
          <mat-spinner [diameter]="70"></mat-spinner>
        </div>
      </div>

      <ice-data-table
        *ngIf="config; let c"
        [data]="c.model | async"
        [sorts]="c.sorts"
        [schema]="c.schema"
        [shadowed]="c.shadowed"
        [apiCall]="c.apiCall"
        [sortConfig]="c.sortConfig"
        [isLoadingData]="c.isLoadingData | async"
        [messages]="c.messages"
        (sortRowEmit)="c.onSort && c.onSort($event)"
        [headerHeight]="c.headerHeight"
        [fixedHeight]="c.fixedHeight"
        [footerHeight]="c.footerHeight"
        [footerOptions]="c.footerOptions"
        [dontStrip]="c.dontStrip"
        [customClass]="c.customClass"
        [columnMode]="c.columnMode ? c.columnMode : 'force'"
        [selectionType]="(c.selectionType | async) || 'single'"
        [selected]="c.selected | async"
        [isSelectable]="c.isSelectable"
        [isSelectableMode]="c?.isSelectableMode"
        [visibleColumns]="c.visibleColumns"
        [displayCheck]="c.displayCheck"
        [displayRadio]="c.displayRadio"
        (selectedRowEmit)="selectItem($event)"
        (selectedExpandableRowEmit)="selectExpandableItem($event)"
        (mouseWheelClickRowEmit)="onMouseWheelClickSelect($event)"
        (allSelected)="c?.onAllSelected && c.onAllSelected($event)"
        (reorderColumn)="c?.onReorderColumn && c.onReorderColumn($event)"
        [expandRowsEnabled]="c?.expandRowsEnabled"
        [detailVisibleColumns]="c?.detailVisibleColumns"
        [detailSorts]="c?.detailSorts"
        [expandableSchema]="c?.expandableSchema"
        [expandableProperty]="c?.expandableProperty"
        [expandableNoHeader]="c?.expandableNoHeader"
        [rowHeight]="c?.rowHeight || 0"
        [sortReset]="c?.sortReset"
        [disabledSort]="c?.disabledSort"
        [totals]="c.totals"
        [headerTitle]="c.headerTitle"
        [headerClass]="c.headerClass"
        [addDetailsOnMainDataTable]="c?.addDetailsOnMainDataTable"
        [doPagination]="c?.doPagination"
        [rowFixedDetails]="c?.rowFixedDetails | async"
        [tableActionButton]="c?.tableActionButton"
        [expandAllRows$]="c?.expandAllRows"
        [shouldAddParentRowClass]="c?.shouldAddParentRowClass"
        [rowIdentity]="c?.rowIdentity"
      >
      </ice-data-table>
    </mat-card-content>
  </mat-card>
</ng-container>
