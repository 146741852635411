import { CREATOR_ROLES } from 'config/constants/global.constants';
import { has, join, sortBy, startCase, take } from 'lodash';
import { CopyrightUtils, IpUtils, WorkUtils } from '..';
import { hasItems } from '../hasItems';

export class ContributorsUtils {
  static getContributionPartyName(contribution) {
    return (contribution && contribution.contributor && contribution.contributor.partyName) || {};
  }

  static getContributionPartyNameRelations(contribution) {
    const partyName = this.getContributionPartyName(contribution);
    return (partyName && partyName.relations) || [];
  }

  static getContributionParty(contribution) {
    const partyName = this.getContributionPartyName(contribution);
    return (partyName && hasItems(partyName.parties) && partyName.parties[0].party) || {};
  }

  static getContributionPartyRelations(contribution) {
    const party = this.getContributionParty(contribution);
    return (party && party.relations) || [];
  }

  static selectContributionPartyNameFullName(contribution) {
    const partyName = this.getContributionPartyName(contribution);
    const attributes = (partyName && partyName.attributes) || {};
    const fistNameWithSeparator = has(attributes, 'firstName') ? `, ${startCase(attributes.firstName)}` : '';
    const fullName = (attributes && `${attributes.name}${fistNameWithSeparator}`) || '';
    return IpUtils.cleanNameLabel(fullName, 'N');
  }

  static concatContributionsPartyNames(contributions = [], max = 5): string {
    const contributionsFormatted = contributions.map(contribution => {
      return { key: `${this.selectContributorIPINumber(contribution)}`, fullName: this.selectContributionPartyNameFullName(contribution) };
    });
    return take(
      sortBy(contributionsFormatted, ['key']).map(item => item.fullName),
      max,
    ).join('<br>');
  }

  static selectContributorIPINumber(contribution) {
    const relations = this.getContributionPartyNameRelations(contribution);
    return (relations && WorkUtils.selectIPIKey(relations)) || '';
  }

  static concatContributionsPartyNamesIPINumbers(contributions = [], max = 5): string {
    const contributionsFormatted = contributions.map(contribution => {
      const keyWithoutPrefix = CopyrightUtils.getKeySuffix(this.selectContributorIPINumber(contribution));
      return { key: `${keyWithoutPrefix}` };
    });
    const returnValue = take(
      sortBy(contributionsFormatted, ['key']).map(item => item.key),
      max,
    ).join('<br>');
    return returnValue;
  }

  static concatContributionsRolesCodes(contributions = [], max = 5): string {
    const groupByContribution = contributions.map(contribution => {
      const keyWithoutPrefix = CopyrightUtils.getKeySuffix(this.selectContributorIPINumber(contribution));
      return { key: `${keyWithoutPrefix}`, role: contribution.role };
    });
    return join(
      take(
        sortBy(groupByContribution, ['key']).map(item => item.role),
        max,
      ),
      '<br>',
    );
  }

  static getRoleLabelFromRoleValue(valueToSearch) {
    if (valueToSearch) {
      return CREATOR_ROLES.find(rol => rol.value === valueToSearch)?.labelFull || '';
    } else {
      return '';
    }
  }

  static getRoleLabelFromRoleContributions(contributions, translate) {
    const groupByContribution = contributions.map(contribution => {
      const keyWithoutPrefix = CopyrightUtils.getKeySuffix(this.selectContributorIPINumber(contribution));
      const roleFound = CREATOR_ROLES.find(rol => rol.value === contribution.role);
      return { key: `${keyWithoutPrefix}`, labelFull: roleFound.labelFull };
    });
    return join(
      sortBy(groupByContribution, ['key']).map(item => item.labelFull),
      '<br>',
    );
  }
}
