import { Search, TerritoryUtils } from '@ice';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { uniqWith } from 'lodash';

export class SearchTerritoryParser {
  static parse(items: any[], translate: TranslateService, extraParams?: { search?: Search }, extraItems?: string[], store?: Store<any>) {
    const { search } = extraParams;
    return uniqWith(TerritoryUtils.territorySearchCleaner(items, search.params), (arrTer, othTer) => {
      return arrTer.id === othTer.id && arrTer.tisAExt === othTer.tisAExt && arrTer.tisN === othTer.tisN && arrTer.name === othTer.name;
    });
  }
}
