import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FuseWidgetComponent } from '@fuse/components/widget/widget.component';
import { WidgetSwitchModel } from './widget-switch-model';

@Component({
  selector: 'ice-widget-switch',
  templateUrl: './widget-switch.component.html',
  styleUrls: ['./widget-switch.component.scss'],
})
export class WidgetSwitchComponent implements OnInit, AfterViewInit {
  @Input() model: WidgetSwitchModel;
  @Output() isFront = new EventEmitter<boolean>();
  @Input() set fixedFace(face: string) {
    this._fixedFace = face;
  }
  get fixedFace() {
    return this._fixedFace;
  }
  @ViewChild('frontButton', { static: true }) frontButton: ElementRef;
  @ViewChild('backButton', { static: true }) backButton: ElementRef;
  @ViewChild('fuseWidget', { static: true }) fuseWidged: FuseWidgetComponent;
  public front = false;
  public slideCaption: string;
  private _fixedFace: string;

  constructor(public chDet: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.slideCaption = this.model.titleFront;
  }

  ngAfterViewInit() {
    this.fuseWidged.flipped = true;
    this.chDet.detectChanges();
  }

  switchFace(toFront: boolean) {
    if (this.front !== toFront) {
      if (toFront) {
        this.frontButton.nativeElement.click();
        this.slideCaption = this.model.titleBack;
      } else {
        this.backButton.nativeElement.click();
        this.slideCaption = this.model.titleFront;
      }
      this.front = toFront;
    }
    this.isFront.emit(this.front);
  }
}
