import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { IpCleaned, selectControlValue } from '@ice';
import { select, Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { ipsSocietiesCodes } from 'assets/ts/ips-societies';
import { ICE_PREFIX } from 'config/constants/global.constants';
import { OrganizationType } from 'config/constants/organizations.constants';
import { DialogSearchParty } from 'config/dialog-builders/dialog-search-party';
import { SearchCopyrightIpsForm } from 'config/search-form-builders/search-copyright-ips';
import { AgreementPartiesValidator } from 'config/stepper-builders/agreements/steps/validators';
import { StepType } from 'config/stepper-builders/stepper-config';
import { toNumber } from 'lodash';
import { of, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SearchService } from 'services/search/search.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromNewSectionItem from 'store/new-section-item';
import * as fromRoot from 'store/root';

// TODO: Add Angular decorator.
export class OrganizationStep {
  private fieldParentIpi: FormlyFieldConfig;
  private searchCopyrightIpsForm: SearchCopyrightIpsForm;
  private subscriptionEditMode: Subscription;
  private subscriptionGetIPs: Subscription;
  private isEditMode: boolean;
  private fieldParentIpNameKey: any;
  private fieldId: any;
  private fieldSociety: any;
  private fieldType: any;
  private unsubscribeAll = new Subject();

  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private storeNewItem: Store<fromNewSectionItem.NewSectionItemState>,
    private dialog: MatDialog,
    private fieldValidatorService: FieldValidatorService,
    private searchService: SearchService,
  ) {
    this.searchCopyrightIpsForm = new SearchCopyrightIpsForm(this.translate, this.fuseTranslationLoader);
    this.subscriptionEditMode = this.store.pipe(select(fromRoot.getRouterView)).subscribe(view => {
      this.isEditMode = view === 'EDIT' ? true : false;
    });
  }

  getStep(): StepType {
    return {
      label: this.getTranslation('TITLE', this.translate),
      formBuilder: [
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-3',
              wrappers: ['wrapper-info'],
              templateOptions: {
                infoText: this.getTranslation('ID.INFO_TEXT', this.translate),
              },
              fieldGroup: [
                {
                  key: 'id',
                  wrappers: ['form-field', 'wrapper-input-text'],
                  type: 'input',
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  templateOptions: {
                    label: this.getTranslation('ID.NAME', this.translate),
                    disabled: this.isEditMode,
                    readonly: true,
                    tabindex: -1,
                    focus: (field: FormlyFieldConfig, event?: any) => event.target.blur(),
                  },
                  asyncValidators: {
                    valid_id_validation: {
                      expression: (control: FormControl) =>
                        (this.isEditMode && of(this.isEditMode).toPromise()) || this.fieldValidatorService.organizationIdExists(selectControlValue(control), true),
                      message: (error, field: FormlyFieldConfig) => this.translate.instant('ORGANIZATIONS.ORGANIZATION.ID.INVALID', { type: field.model.type }),
                    },
                  },
                  hooks: {
                    onInit: field => (this.fieldId = field),
                  },
                },
              ],
            },
            {
              className: 'flex-3',
              wrappers: ['wrapper-info'],
              templateOptions: {
                infoText: this.getTranslation('NAME.INFO_TEXT', this.translate),
              },
              fieldGroup: [
                {
                  key: 'name',
                  wrappers: ['form-field', 'wrapper-input-text'],
                  type: 'input',
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  templateOptions: {
                    required: true,
                    label: this.getTranslation('NAME.NAME', this.translate),
                  },
                  asyncValidators: {
                    InitEdit_validation: {
                      // Force deactivate Save on Edit
                      expression: (control: FormControl) => {
                        return new Promise((resolve, reject) => resolve(true));
                      },
                      message: '',
                    },
                  },
                  hooks: {
                    onDestroy: () => {
                      this.unsubscribeAll.next();
                      this.unsubscribeAll.complete();
                    },
                  },
                },
              ],
            },
            {
              className: 'flex-3',
              wrappers: ['wrapper-info'],
              templateOptions: {
                infoText: this.getTranslation('TYPE.INFO_TEXT', this.translate),
              },
              fieldGroup: [
                {
                  key: 'type',
                  type: 'select',
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  templateOptions: {
                    required: true,
                    label: this.getTranslation('TYPE.NAME', this.translate),
                    options: [
                      { label: this.getTranslation('TYPE.PUBLISHER', this.translate), value: OrganizationType.publisher },
                      { label: this.getTranslation('TYPE.SOCIETY', this.translate), value: OrganizationType.society },
                    ],
                    change: (field, $event) => {
                      if (field.formControl.value !== OrganizationType.publisher) {
                        this.fieldParentIpi.formControl.setValue('');
                      }
                      field.formControl.setValue($event.value);
                      const id = this.getIdFormatType($event.value, this.fieldSociety?.formControl?.value, this.fieldParentIpi?.formControl?.value);
                      this.fieldId.formControl.markAsTouched();
                      this.fieldId.formControl.setValue(id);
                    },
                  },
                  hooks: {
                    onInit: field => {
                      if (field.form.controls.id.value != null) {
                        field.formControl.disable();
                      }
                      this.fieldType = field;
                    },
                    onDestroy: () => {
                      if (this.subscriptionEditMode) {
                        this.subscriptionEditMode.unsubscribe();
                      }
                      if (this.subscriptionGetIPs) {
                        this.subscriptionGetIPs.unsubscribe();
                      }
                    },
                  },
                },
              ],
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-1',
              template: `<h2>${this.getTranslation('SOCIETY.NAME', this.translate)}</h2>`,
              hideExpression: model => {
                return model.type !== 'society';
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-3',
              wrappers: ['wrapper-info'],
              templateOptions: {
                infoText: this.getTranslation('SOCIETY.INFO_TEXT', this.translate),
              },
              fieldGroup: [
                {
                  key: 'society',
                  type: 'select',
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  templateOptions: {
                    required: true,
                    label: this.getTranslation('SOCIETY.NAME', this.translate),
                    options: ipsSocietiesCodes.sort((a, b) => (a.name < b.name ? -1 : 1)).map(society => ({ label: society.name, value: society.code })),
                    change: (field, $event) => {
                      field.formControl.setValue($event.value);
                      const id = this.getIdFormatType(this.fieldType?.formControl?.value, $event.value, this.fieldParentIpi?.formControl?.value);
                      this.fieldId.formControl.setValue(id);
                    },
                    disabled: this.isEditMode,
                  },
                  expressionProperties: {
                    'model.society': model => {
                      if (model.type !== 'society') {
                        return null;
                      }
                      return model.society;
                    },
                  },
                  hooks: {
                    onInit: field => (this.fieldSociety = field),
                  },
                },
              ],
              hideExpression: model => {
                return model.type !== 'society';
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-1',
              template: `<h2>${this.getTranslation('PARENT_IPI.NAME', this.translate)}</h2>`,
              hideExpression: model => {
                return model.type !== OrganizationType.publisher;
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              className: 'flex-3',
              wrappers: ['wrapper-info'],
              templateOptions: {
                infoText: this.getTranslation('PARENT_IPI.INFO_TEXT', this.translate),
              },
              fieldGroup: [
                {
                  key: 'parentIpNameKey',
                  type: 'input',
                  className: 'ice-display-none',
                  hooks: {
                    onInit: field => (this.fieldParentIpNameKey = field),
                  },
                },
                {
                  key: 'parentIpi',
                  wrappers: ['form-field', 'wrapper-input-text'],
                  type: 'input',
                  modelOptions: {
                    updateOn: 'blur',
                  },
                  templateOptions: {
                    required: true,
                    disabled: this.isEditMode,
                    label: this.getTranslation('PARENT_IPI.NAME', this.translate),
                    needValidate: false,
                    focus: (field: FormlyFieldConfig) => {
                      field.templateOptions.needValidate = true;
                    },
                    keypress: (field, event) => {
                      if (event.which === 13) {
                        this.onClickIPSearchDialog(field, true, true);
                      }
                    },
                  },
                  hooks: {
                    onInit: field => {
                      this.fieldParentIpi = field;
                      field.formControl.valueChanges.pipe(takeUntil(this.unsubscribeAll)).subscribe(value => {
                        const id = this.getIdFormatType(this.fieldType?.formControl?.value, this.fieldSociety?.formControl?.value, value);
                        this.fieldId.formControl.setValue(id);
                      });
                    },
                  },
                  asyncValidators: {
                    parentIpi_validation: {
                      expression: (control: FormControl) => {
                        return new Promise((resolve, reject) =>
                          this.fieldParentIpi && this.fieldParentIpi.templateOptions.needValidate
                            ? AgreementPartiesValidator.validIPKey(
                                this.fieldValidatorService,
                                control,
                                '',
                                (name: string, prSociety: string, typ: string, ipKey: string, baseIpiNumber: string, nameKey: string, baseKey: string) => {
                                  this.fieldParentIpNameKey.formControl.setValue(ipKey);
                                  resolve(true);
                                },
                                () => {
                                  resolve(false);
                                },
                              )
                            : resolve(true),
                        );
                      },
                      message: this.translate.instant('ERROR.NOT_EXISTS').replace(/<<\w+>>/g, this.translate.instant('ORGANIZATIONS.ORGANIZATION.PARENT_IPI.NAME')),
                    },
                  },
                },
              ],
            },
            {
              key: 'btSearchParentIpi',
              className: `ice-formly-icon-bt-info-search ${this.isEditMode && 'ice-display-none'}`,
              type: 'button',
              templateOptions: {
                materialType: 'mat-icon-button',
                icon: 'search',
                onClick: event => {
                  if (event.x !== 0 && event.y !== 0) {
                    this.onClickIPSearchDialog(this.fieldParentIpi, true, true);
                  }
                },
              },
            },
          ],
          hideExpression: model => {
            return model.type !== OrganizationType.publisher;
          },
        },
      ],
    };
  }

  onClickIPSearchDialog(fieldToAssign, filterByIPI = false, isParentIpi = false, assignIpNameKey = false) {
    const extraFiltersInSearch = filterByIPI ? { searchPartyNameFilterByRelationTypeExist: 'IPI' } : {};
    DialogSearchParty.openDialog(
      fieldToAssign.key,
      this.dialog,
      this.store,
      this.storeNewItem,
      this.translate,
      this.fuseTranslationLoader,
      this.searchService,
      (item: IpCleaned) => {
        fieldToAssign.templateOptions.needValidate = false;
        if (assignIpNameKey) {
          fieldToAssign.formControl.setValue(item.key && item.key.split(':').length ? item.key.split(':')[1] : item.key);
        } else {
          fieldToAssign.formControl.setValue(item.ipiNameNumber);
        }
        if (isParentIpi) {
          this.fieldParentIpNameKey.formControl.setValue(item.key);
        }
        fieldToAssign.parent.form.markAsDirty();
        this.dialog.closeAll();
      },
      extraFiltersInSearch,
      ICE_PREFIX,
    );
  }

  getTranslation(translateString, translate) {
    return translate.instant(`ORGANIZATIONS.ORGANIZATION.${translateString}`);
  }

  getIdFormatType(type, society, parentIpi) {
    switch (type) {
      case OrganizationType.society:
        return society && `ICE:SO${toNumber(society)}`;
      case OrganizationType.publisher:
        return parentIpi && `ICE:PB${parentIpi}`;
    }
  }
}
