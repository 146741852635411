import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, HostBinding, Inject, OnDestroy, Renderer2, ViewEncapsulation } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as fromRoot from 'store/root';

@Component({
  selector: 'ice-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FuseMainComponent implements OnDestroy {
  onConfigChanged: Subscription;
  fuseSettings: any;
  @HostBinding('attr.fuse-layout-mode') layoutMode;
  isOpenSidebar$: Observable<boolean>;
  forcedNSVisible$: Observable<boolean>;

  constructor(
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    private fuseConfig: FuseConfigService,
    private platform: Platform,
    @Inject(DOCUMENT) private document: any,
    private store: Store<fromRoot.RootState>,
  ) {
    this.onConfigChanged = this.fuseConfig.getConfig().subscribe(newSettings => {
      this.fuseSettings = newSettings;
      this.layoutMode = this.fuseSettings.layout.mode;
    });
    this.document.body.classList.add('theme-default');
    if (this.platform.ANDROID || this.platform.IOS) {
      this.document.body.className += ' is-mobile';
    }
    this.isOpenSidebar$ = this.store.pipe(select(fromRoot.getIsOpenSidebar));
    this.forcedNSVisible$ = this.store.pipe(select(fromRoot.getForcedNS));
  }

  ngOnDestroy() {
    this.onConfigChanged.unsubscribe();
  }
}
