import { environment } from 'config/env';
import { SectionRouterViews } from './section-router-views';
import type { SectionConfig } from './sections-config';

export const SectionCopyrightRepertoires: SectionConfig = {
  name: 'repertoires',
  url: '/copyright/repertoires',
  domainName: 'copyright',
  baseUrl: environment.apiUrl,
  baseUrlCubeData: environment.apiUrlCubeData,
  apiSegment: 'repertoires',
  detailSegment: `repertoires/<<ns>>/<<id>>`,
  minCharsToSearch: 3,
  apiIncludes: {
    search: 'attributes{id, name, type, territories, startDate, endDate}, relations{relation, otherId}',
    detail: `admin.attributes, attributes{id, name, type, territories, startDate, endDate, exclude, claimTypes, territories,
    rights, opRepertoireAllowed, publisherRollup, publisherRollupToNonSoc, publisherRollupExcludeTerritories, ruleSetId,
    active, unpublishedWriterShares},
    parties.party.partyNames[type='PA'].partyName.attributes,
    parties.party.partyNames[type='PA'].partyName.relations,
    relations{relation, otherId}, works,
    societies`,
  },
  searchServerSideSortDefault: 'attributes.name',
  newItemBtn: [SectionRouterViews.home, SectionRouterViews.search],
  allowedActions: {
    newItem: true,
    editItem: 'stepper',
    newNote: true,
    removeItem: false,
  },
  hideHomeEditButton: true,
  auditTypes: ['repertoire'],
};
