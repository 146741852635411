import { DateTimeUtils, NS_ICE, OrganizationUtils, RelationsUtils, AgreementUtils, ClaimsUtils, TerritoryUtils, UsersUtils, WorkUtils, BulkUpdatesUtils } from '@ice';
import { GENERIC_IP_NAME_KEY, GENERIC_IP_NAME_KEY_WITHOUT_ICE, RELATED_AGREEMENT_TYPES } from 'config/constants/agreements.constants';
import { ICE, IPI, PartyRelationType } from 'config/constants/ips.constants';
import { OrganizationType } from 'config/constants/organizations.constants';
import { RELATIONTYPES } from 'config/constants/relation.constants';
import { TerritoryDataType } from 'config/constants/territories.constants';
import { INCOME_PARTICIPANT_VALUE } from 'config/constants/works.constants';
import { UnKnowPublisherIPIKey, UnknownAuthorIPIKey } from 'config/stepper-builders/claim/new-claim-init';
import { cloneDeep, concat, filter, find, get, groupBy, isEmpty, keyBy, map, remove, startsWith, toPairs } from 'lodash';
import { AgreementAttributes, AgreementRelation, ApiNamespace, EditNewAgreement, EditNewCounterClaim, IpDetail, SendAgreement, WorkDetail } from 'models';
import { SendCounterClaim } from 'models/copyright/send-update/counter-claim';
import moment from 'moment';

export const SectionSendItemCleaner = {
  works: (item: any, ns: string, isEdit = true) => {
    if (isEdit) {
      return WorkUtils.createWorkItemToEdit(item, ns);
    } else {
      return WorkUtils.createWorkItemToRegister(item, ns);
    }
  },
  claim: (item: WorkDetail, ns: string, isEdit: boolean, newFields: any) => {
    const { id, attributes, claims, relations } = item;
    const { role, startDate, endDate, shares, linkCreator, agreementId, ClaimantIPI } = newFields;
    const clonedClaims = cloneDeep(claims);

    // Change Namespace if needed
    let newNS = ns;
    if (newFields.organizationToFilter) {
      const prefix = ['E', 'SE'].find(pubRole => pubRole === role) ? 'PUB' : 'MANU';
      newNS = `${ns}:${prefix}:${ClaimantIPI}`;
    }

    const claimNS = attributes?.ns;
    const claimantPartyId = newFields['ClaimantPartyId'];
    const claimantPartyNameId = newFields['ClaimantPartyNameId'];
    const groupedClaims = ClaimsUtils.getGroupedClaims(clonedClaims);
    const baseClaimId = RelationsUtils.getWorkIdFromRelations(relations);
    const unauthorized = ClaimantIPI === UnKnowPublisherIPIKey || ClaimantIPI === UnknownAuthorIPIKey;
    const incomeParticipant = (role === 'E' || role === 'SE') && linkCreator.length > 0 && linkCreator.includes(INCOME_PARTICIPANT_VALUE);

    // Following https://ice-cube.atlassian.net/browse/CUBE-14536 when no ownership shares in the screen we must not add them
    const addOwnershipShares = role !== 'SE' ? true : false;
    const claimantInfo = get(newFields, 'ClaimantInfo');

    if (role !== 'E' && role !== 'SE') {
      // CREATOR
      ClaimsUtils.addCreatorToClaims(
        baseClaimId,
        clonedClaims,
        groupedClaims,
        claimNS,
        id,
        role,
        claimantPartyId,
        claimantPartyNameId,
        startDate,
        endDate,
        shares,
        unauthorized,
        claimantInfo,
        addOwnershipShares,
      );
    } else if (role === 'E') {
      // ORIGINAL_PUBLISHER
      ClaimsUtils.addPublisherToClaims(
        clonedClaims,
        groupedClaims,
        linkCreator,
        agreementId,
        claimNS,
        id,
        role,
        claimantPartyId,
        claimantPartyNameId,
        startDate,
        endDate,
        shares,
        unauthorized,
        incomeParticipant,
        baseClaimId,
        claimantInfo,
        addOwnershipShares,
      );
    } else if (role === 'SE') {
      // SUB_PUBLISHER
      const { isPublisherFromWork, PublisherPartyId, PublisherPartyNameId } = newFields;
      const publisherIpi = newFields['PublisherIPI'];
      const publisherIpiName = newFields['PublisherIPNameNumber'];
      const PublisherParentId = newFields['PublisherParentId'] || newFields['parentId'];
      const PublisherInfo = newFields['PublisherInfo'];
      ClaimsUtils.addSubpublisherToClaims(
        clonedClaims,
        groupedClaims,
        publisherIpi,
        isPublisherFromWork,
        linkCreator,
        claimNS,
        id,
        role,
        claimantPartyId,
        claimantPartyNameId,
        startDate,
        endDate,
        shares,
        agreementId,
        unauthorized,
        incomeParticipant,
        baseClaimId,
        publisherIpiName,
        PublisherParentId,
        claimantInfo,
        null,
        null,
        addOwnershipShares,
        PublisherPartyId,
        PublisherPartyNameId,
        PublisherInfo,
      );
    }

    const formattedClaims =
      item &&
      item.claims &&
      clonedClaims.map(claim => ({
        ...claim,
        claimShares: claim.claimShares && ClaimsUtils.formatClaimShareToSend(claim.claimShares),
        ownershipShares: claim.ownershipShares && ClaimsUtils.formatClaimShareToSend(claim.ownershipShares),
      }));
    return SectionSendItemCleaner.works({ ...item, claims: formattedClaims }, newNS);
  },
  ips: (item: IpDetail, ns: ApiNamespace) => {
    const { attributes, parties, version, partyNames, relations, parentRelationshipsData, childrenRelationshipsData } = cloneDeep(item);
    if (parties && parties.length) {
      const newParentRelations = cloneDeep(item['parentRelations']) || [];
      parentRelationshipsData?.items
        ?.filter(ipRelated => ipRelated.rawItem)
        .map(ipRelated => {
          if (ipRelated.deleted && newParentRelations.find(relation => relation.otherNameId === ipRelated.key)) {
            remove(
              newParentRelations,
              newParentRelations.find(relation => relation.otherNameId === ipRelated.key),
            );
          }
          const rel = get(ipRelated, 'rawItem.parties[0].relations', []).find(relation => relation.otherNameId === ipRelated.key);
          if (rel) {
            newParentRelations.push(rel);
          }
        });

      parties[0].relations = newParentRelations;
    }
    attributes.ns = ns;

    // Get the matching partyName from the list to add the IPI if it was added in the table
    const partyName = find(partyNames, { id: item.id });
    if (partyName && partyName.ipiNameNumber) {
      const ipi = `IPI:${partyName.ipiNameNumber}`;
      const ipiRelation = find(relations, { otherId: ipi, relation: PartyRelationType.CROSS_REFERENCE });
      if (!ipiRelation) {
        relations.push({ otherId: ipi, relation: PartyRelationType.CROSS_REFERENCE });
      }
    }

    const { key, ...cleanAttributes } = attributes;

    const iceParties = relations?.filter(items => items?.otherId?.includes('ICE') && items?.relation?.includes(PartyRelationType.CROSS_REFERENCE));
    let icePartyNameId = iceParties?.[0]?.otherId;
    if (iceParties.length > 1) {
      icePartyNameId = iceParties.find(items => items.relation?.includes(PartyRelationType.DISPLAY_REFERENCE))?.otherId;
    }

    return {
      id: attributes.key,
      attributes: cleanAttributes,
      childrenRelationshipsData,
      parties,
      relations: relations || [],
      icePartyNameId,
    };
  },
  agreements: (item: EditNewAgreement, ns: ApiNamespace, isEdit = false) => {
    if (item) {
      const {
        submitter_prefix,
        submitter_masked_id,
        assignor,
        gema_remuneration_claim_indicator,
        agreement_dispute,
        identifiers,
        key,
        related_agreements,
        retention,
        agreement_relations,
        administrator,
        submitterPartyNameIds,
        updatedShares,
        termination,
        no_recipient_groups,
        agreement_id,
      } = item;
      let { ipi_name_number } = item;
      const tags = cloneDeep(get(item, 'attributes.tags', {}));
      const referenceNumbers =
        identifiers &&
        identifiers
          .filter(identifier => identifier.submitter_agreement_number && identifier.submitter_agreement_number !== undefined)
          .map(identifier => identifier.submitter_agreement_number);
      tags.referenceNumbers = referenceNumbers || [];

      const version = 0;

      if (ipi_name_number && ipi_name_number.includes('IPI')) {
        ipi_name_number = ipi_name_number.split(':')[1];
      }
      const newIPINameNumber = `IPI:${ipi_name_number}`;
      const submitterPartyNameIdsArray = cloneDeep(submitterPartyNameIds) || [];
      if (!submitterPartyNameIdsArray.includes(newIPINameNumber)) {
        submitterPartyNameIdsArray.push(newIPINameNumber);
      }

      const sharesToUpdate = (termination && updatedShares) || AgreementUtils.formatShares(item);

      const assigneeIPNameKey = item.assignee_ip_name_key;
      const assigneeIpi = item.assignee_ipi_name_number;
      const isDummyAssignee = assigneeIPNameKey && assigneeIPNameKey.length && assigneeIPNameKey.length > 0 && (!assigneeIpi || (assigneeIpi.length && assigneeIpi.length === 0));
      let assigneePartyId: string;
      let assigneePartyNameId: string;
      if (isDummyAssignee) {
        assigneePartyId = item.assignee_dummy_party_id;
        assigneePartyNameId = item.assignee_dummy_party_name_id;
      } else {
        const assigneeIcePartyId = item.assignee_base_key;
        const assigneeIcePartyNameId = item.assignee_ip_name_key;
        assigneePartyId = (assigneeIcePartyId && (!startsWith(assigneeIcePartyId, ICE) ? `${ICE}:${assigneeIcePartyId}` : assigneeIcePartyId)) || '';
        assigneePartyNameId = (assigneeIcePartyNameId && (!startsWith(assigneeIcePartyNameId, ICE) ? `${ICE}:${assigneeIcePartyNameId}` : assigneeIcePartyNameId)) || '';
      }

      let assignorPartyId: string;
      let assignorPartyNameId: string;
      if (assignor) {
        const assignorIPNameKey = assignor.assignor_ip_name_key;
        const assignorIpi = assignor.assignor_ipi_name_number;
        const isDummyAssignor = assignorIPNameKey && assignorIPNameKey.length && assignorIPNameKey.length > 0 && (!assignorIpi || (assignorIpi.length && assignorIpi.length === 0));
        const isGenericCreator = assignorIPNameKey && assignorIPNameKey.length && assignorIPNameKey.length > 0 && assignorIPNameKey === GENERIC_IP_NAME_KEY_WITHOUT_ICE;
        if (isGenericCreator) {
          assignorPartyId = GENERIC_IP_NAME_KEY;
          assignorPartyNameId = GENERIC_IP_NAME_KEY;
        } else if (isDummyAssignor) {
          assignorPartyId = item.assignor_dummy_party_id;
          assignorPartyNameId = item.assignor_dummy_party_name_id;
        } else {
          const assignorIcePartyId = assignor.assignor_base_key;
          const assignorIcePartyNameId = assignor.assignor_ip_name_key;
          assignorPartyId = (assignorIcePartyId && (!startsWith(assignorIcePartyId, ICE) ? `${ICE}:${assignorIcePartyId}` : assignorIcePartyId)) || '';
          assignorPartyNameId = (assignorIcePartyNameId && (!startsWith(assignorIcePartyNameId, ICE) ? `${ICE}:${assignorIcePartyNameId}` : assignorIcePartyNameId)) || '';
        }
      }
      const attributes: AgreementAttributes = {
        agreementType: item.type_of_agreement,
        agreementContext: 'PP', // Hardcoded because this field is required but not implemented in stepper yet
        assigneePartyId,
        assigneePartyNameId,
        assignorPartyId,
        assignorPartyNameId,
        ns: NS_ICE,
        salesOrManufacture: item.sales_manufacture,
        transferWorks: item.transfer_works,
        shareInOpAssignorOnly: item.share_in_op_assignor_only,
        searchTerm: item.agreement_name,
        shares: sharesToUpdate,
        agreementDispute: agreement_dispute || false,
        gemaRemuneration: gema_remuneration_claim_indicator,
        retention: retention || false,
        libraryMusic: item.library_music === 'y',
        recordingPrefix: item.recording_prefix,
        writerMechanicalReason: item.writer_mechanical_reason,
        usaLicenseIndicator: item.usa_license_indicators,
        version,
        tags: { ...tags },
        submitterPartyNameIds: submitterPartyNameIdsArray,
        administrator,
        expectedTerminationDate: item.expected_termination,
      };

      if (item.additional_information) {
        attributes.tags['additional'] = [item.additional_information];
      }

      if (item.inter_company !== undefined) {
        attributes.interCompany = item.inter_company;
      }

      const filteredIdentifiers = filter(identifiers, identifier => identifier.submitter_agreement_number && identifier.submitter_agreement_number !== '');

      const parefPrefix = `PAREF:${submitter_prefix || submitter_masked_id}`;
      const identifiersRelations: AgreementRelation[] = map(filteredIdentifiers, (identifier: any) => {
        const { maskedId } = identifier;
        const otherId = `${parefPrefix}:${maskedId}`;
        return {
          ns: NS_ICE,
          otherId,
          relation: RELATIONTYPES.XREF,
          version,
        };
      });

      const filteredRelatedAgreements = filter(related_agreements, agreement => agreement.related_agreement_number && agreement.related_agreement_number !== '');

      const relatedAgreementsCoPublishingRelations = [];
      const relatedAgreementsRecipientGroups = [];

      filteredRelatedAgreements.forEach((agreement: any) => {
        const { related_agreement_number, maskedId } = agreement;
        let otherId: string;
        if (agreement.related_agreement_type) {
          switch (agreement.related_agreement_type) {
            case RELATED_AGREEMENT_TYPES.RECIPIENT:
              otherId = related_agreement_number.split(':').length > 1 ? related_agreement_number : `CUBE:${related_agreement_number}`;
              relatedAgreementsRecipientGroups.push({
                ns: 'CUBE',
                groupId: otherId,
                agreementId: agreement_id,
                relation: RELATIONTYPES.RECIPIENT,
                version,
              });
              break;
            case RELATED_AGREEMENT_TYPES.COPUBLISHER:
              otherId = `${parefPrefix}:${maskedId}`;
              relatedAgreementsCoPublishingRelations.push({
                ns: NS_ICE,
                otherId,
                relation: RELATIONTYPES.COPUB,
                version,
              });
          }
        }
        return;
      });
      const relations: AgreementRelation[] = concat(agreement_relations || [], identifiersRelations, relatedAgreementsCoPublishingRelations);
      const sendItem: SendAgreement = {
        version,
        attributes,
        groups: [...relatedAgreementsRecipientGroups, ...(no_recipient_groups || [])],
        relations,
      };
      return key ? { ...sendItem, id: key } : sendItem;
    }
    return item;
  },
  repertoires: (item, ns: ApiNamespace, isEdit = false, inLine = false) => {
    const {
      id,
      version,
      additionalCollectors,
      claimTypes,
      societies,
      contractingCompanies,
      creatorAffiliations,
      exclusionsAgreements,
      exclusionsWorks,
      name,
      works,
      attributes,
      notes, // Waiting to know where to put this field in the Object to Send
      opDetermine,
      publisherRollup,
      publisherRollupToNonSoc,
      rollupExcludeTerritoriesText,
      territory,
      type,
      usageTypes,
      relations,
      startDate,
      endDate,
      ruleSetId,
      workList,
      active,
      unpublishedWriterShares,
    } = item;
    const newVersion = !version || version === 0 ? 0 : version + 1;
    return {
      id,
      version: newVersion,
      relations,
      attributes: {
        ...(inLine && attributes),
        ...(!inLine && {
          ns,
          publisherRollup,
          endDate,
          publisherRollupToNonSoc,
          type,
          ruleSetId,
          territories: territory && TerritoryUtils.convertTerritoryStringElements(territory, TerritoryDataType.TISN),
          rights: usageTypes && usageTypes.map(right => right.split(' ')[0]),
          claimTypes,
          publisherRollupExcludeTerritories:
            (rollupExcludeTerritoriesText && TerritoryUtils.convertTerritoryStringElements(rollupExcludeTerritoriesText.replace(/  +/g, ' '), TerritoryDataType.TISN)) || [],
          startDate,
          opRepertoireAllowed: opDetermine,
          name,
          active,
          unpublishedWriterShares,
        }),
      },
      ...(inLine && {
        parties:
          contractingCompanies || additionalCollectors
            ? concat(
                (contractingCompanies &&
                  contractingCompanies
                    .filter(party => party.partyId && party.partyId !== '')
                    .map(party => ({ relation: 'CONTRACTOR', partyId: party.partyId, version: newVersion }))) ||
                  null,
                (additionalCollectors &&
                  additionalCollectors
                    .filter(party => party.partyId && party.partyId !== '')
                    .map(party => ({ relation: 'COLLECTOR', partyId: party.partyId, version: newVersion }))) ||
                  null,
              )
            : null,
      }),
      societies,
      works,
    };
  },
  territories: (item, ns: ApiNamespace) => {
    return item;
  },
  'agreement-conflict': (item, ns, isEdit, newFields, userName) => {
    return { ...item, attributes: { ns }, userName };
  },
  'agreement-group': (item, ns: ApiNamespace) => {
    const { ipNameKey, ipName, ...relevantFields } = item;
    return {
      attributes: {
        ns,
        ...relevantFields,
      },
    };
  },
  'counter-claims': (item: EditNewCounterClaim, ns: ApiNamespace): SendCounterClaim => {
    const { country, pointOfConflict, type, version, rawItem, workCubeId, claimantGroups, claimantShares, status } = item;
    const iceNs = 'ICE';
    if (rawItem) {
      return { ...rawItem };
    }
    const today = DateTimeUtils.formatDate(moment());
    return {
      attributes: {
        ns: iceNs,
        claimStatuses: status.slice(1, -1).split(','),
        version: 1,
        pointOfConflict,
        type,
        counterClaimTerritories:
          (country && [{ tisDate: today, inExTisns: TerritoryUtils.convertTerritoryStringElements(country, TerritoryDataType.TISN).map(value => `${value}`) }]) || [],
        onlyIncludeConflicts: true,
        workId: workCubeId,
        workVersion: version,
      },
      participants: claimantGroups,
      scope: {
        ns: iceNs,
        shares: claimantShares,
      },
    };
  },
  organizations: (item, ns: ApiNamespace, isEdit = false) => {
    if (item) {
      const alternativeNamespace = ns;
      const version = 0;
      const partyNames: string[] = [];
      let namespaces: string[] = [];
      let tags: any;
      const { id, name, type, parentIpi, society, parentIpNameKey } = item;
      let access: any = {
        namespaces: ['CUBE'],
      };
      switch (type) {
        case OrganizationType.society:
          namespaces = [id];
          tags = { societyId: [society] };
          access = {
            namespaces: ['*'],
            societies: [society],
          };
          break;
        case OrganizationType.publisher:
          partyNames.push(parentIpNameKey);
          namespaces = [`PB${parentIpi}`];
          access = {
            namespaces: ['CUBE'],
            partyNames,
          };
          break;
        case OrganizationType.ice:
          namespaces = [id];
          break;
      }
      return {
        attributes: {
          access,
          id,
          name,
          ns: alternativeNamespace,
          owner: {
            namespaces,
          },
          type,
          version,
          tags,
        },
        ...(!isEdit && { roles: OrganizationUtils.getOrganizationRoles(type, id) }),
        id,
        version,
      };
    }
  },
  users: item => UsersUtils.getUserPutRequestFromUser(item),
  reports: (item, nsRequest, isEdit, fields, userName, paths, sections) => {
    const subsection = paths[paths.length - 1];
    return sections.reports.subSections[subsection].stepper.formatter({ ...item, reportType: subsection });
  },
  'counter-claim-support': item => {
    const actions = (get(item, 'documents') && groupBy(get(item, 'documents', []), 'actionId')) || keyBy(get(item, 'selectedParties', []), 'actionId');
    return {
      id: get(item, 'counterclaimId', ''),
      response: 'SUPPORTED',
      actions: toPairs(actions).map(([id, documents]) => ({
        id,
        ...(!isEmpty(((Array.isArray(documents) && documents) || []).filter(doc => !!doc.fileName)) && {
          documents: documents.map(doc => ({
            path: doc.location,
            filename: doc.fileName,
          })),
        }),
      })),
    };
  },
  'counterclaim-notifications': item => item,
  'bulk-updates': (item, ns) => BulkUpdatesUtils.formatBulkUpdateRequestData(item, ns),
};
