export interface UpdateMode {
  updating: boolean;
  items?: any[];
  updateType?: UpdateModeType;
}

export enum UpdateModeType {
  BULK = 'Bulk',
  BULK_ALL = 'BulkAll',
}
