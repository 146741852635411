import { environment } from 'config/env';
import { SectionRouterViews } from './section-router-views';
import type { SectionConfig } from './sections-config';

export const SectionCopyrightAgreements: SectionConfig = {
  name: 'agreements',
  url: '/copyright/agreements',
  domainName: 'copyright',
  baseUrl: environment.apiUrl,
  baseUrlCubeData: environment.apiUrlCubeData,
  apiSegment: 'agreements',
  detailSegment: `agreements/<<ns>>/<<id>>`,
  minCharsToSearch: 3,
  apiIncludes: {
    search: `attributes{id, retention, administrator, agreementType, searchTerm, shares, agreementDispute, sourceOfDoc, termTerritory},counterclaims.counterclaim.attributes,
    relations[XREF]{relation,otherId},
    assignor{party,partyName}.partyName.attributes{id, name, firstName},
    assignor.partyName.relations[XREF]{relation,otherId}, assignor.party.attributes{typeOf},
    assignor.party.societies{societyId,society,memberships}, assignee{party,partyName}.partyName.attributes{id, name, firstName},
    assignee.partyName.relations[XREF]{relation,otherId}, assignee.party.societies{societyId,society,memberships},
    agg.attributes.workCount, counterclaims.counterclaim.attributes`,
    detail: `admin.attributes, attributes.shares{startDate,endDate,postTermCollectionDate,priorRoyaltiesDate},
    relations{relation,otherId},otherRelations{agreementId,relation},
    assignor{party,partyName,partyNameId,assigneeId,assignorId,assigneePartyId,assignorPartyId}.partyName{attributes,relations}.attributes{name,firstName,typeOf},
    assignor.party.relations{relation,otherId},
    assignor.party.attributes{id,typeOf},
    assignor.partyName.relations{relation,otherId},
    assignor.party.societies{societyId,society,memberships},
    assignee{relations,otherRelations,party,partyName}.partyName{attributes,relations}.attributes{name,firstName,typeOf},
    assignee.party.relations,
    assignee.party.attributes,
    assignee.partyName.relations{relation,otherId},
    assignee.party.societies{societyId,society,memberships},
    agg.attributes.workCount, conflicts, conflicts.conflict.attributes, conflicts.conflict.case,conflicts.conflict.agreements,groups`,
  },
  searchServerSideSortDefault: 'assignor.partyName.attributes.name',
  newItemBtn: [SectionRouterViews.home, SectionRouterViews.search],
  allowedActions: {
    newItem: true,
    editItem: 'stepper',
    newNote: true,
    removeItem: false,
    alternativeUpdateLabel: 'TERMINATE',
    newCounterClaim: true,
  },
  auditTypes: ['agreement'],
  enableFabToggle: true,
  fabActionsOrder: ['EDIT', 'OPEN_ALTERNATIVE_UPDATE', 'NEW_AGREEMENT_CONFLICT', 'GO_TO_NEW', 'SAVE_AGREEMENT_AS_NEW', 'SELECT_EDIT_ITEM', 'GO_TO_NEW_NOTE', 'START_EXPORT_MODE'],
};
