import { NgModule } from '@angular/core';

import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseIfOnDomDirective } from './fuse-if-on-dom/fuse-if-on-dom.directive';

@NgModule({
  declarations: [FusePerfectScrollbarDirective, FuseIfOnDomDirective],
  imports: [],
  exports: [FusePerfectScrollbarDirective, FuseIfOnDomDirective],
})
export class FuseDirectivesModule {}
