import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ClaimantUtils, DateTimeUtils } from '@ice';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { ClaimsUtils } from '@ice/utils/claim/claims.utils';
import { select, Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { NodeRole } from 'config/constants/claim-node.constants';
import { IP_CONSTANTS } from 'config/constants/ips.constants';
import { CREATOR_ROLES, PUBLISHER_ROLES, RightTypes, SUBPUBLISHER_ROLES, TYPE_CLAIMANT, WORK_CREATION_SUBPUBLISHER_ROLES } from 'config/constants/shares.constants';
import { DialogClaimNode } from 'config/dialog-builders/dialog-claim-node';
import { DialogInfo } from 'config/dialog-builders/dialog-info';
import { SharesValidator } from 'config/stepper-builders/agreements/steps/validators';
import { ClaimInitModel, ClaimInitModelUnknownAuthor, ClaimInitModelUnknownPublisher } from 'config/stepper-builders/claim/new-claim-init';
import { ClaimantStepType } from 'config/stepper-builders/common-steps/claimant-step-type';
import { SharesStepType } from 'config/stepper-builders/common-steps/shares-step-type';
import { StepType } from 'config/stepper-builders/stepper-config';
import { cloneDeep, concat, drop, dropRight, first, isEmpty, isEqual, remove as lodashRemove, startsWith, uniq } from 'lodash';
import { Subject } from 'rxjs';
import { PermissionsService } from 'services/permissions/permissions.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromNewSectionItem from 'store/new-section-item';
import * as fromRoot from 'store/root';

export class WorkAddAdditionalClaims extends ClaimantStepType {
  public shareStepType: SharesStepType;
  private dialogClaimRef: MatDialogRef<DialogMultiLayoutComponent, any>;
  private set additionalClaimsField(field) {
    this.fieldValidatorService.workRegisterFields.additionalClaims = field;
  }
  private get additionalClaimsField() {
    return this.fieldValidatorService.workRegisterFields.additionalClaims;
  }
  private msgClaimsValidation: FormlyFieldConfig;
  private errors: string[];
  private afterFirstValidation = false;

  constructor(
    protected translate: TranslateService,
    protected dialog: MatDialog,
    protected fieldValidatorService: FieldValidatorService,
    protected store: Store<fromRoot.RootState>,
    protected storeNewItem: Store<fromNewSectionItem.NewSectionItemState>,
    protected translationLoader: FuseTranslationLoaderService,
    protected permissionsService: PermissionsService,
  ) {
    super(translate, dialog, fieldValidatorService, store, storeNewItem, translationLoader, permissionsService);
    this.shareStepType = new SharesStepType(this.translate, this.dialog, this.fieldValidatorService, this.store, this.claimsType, null, null, new Subject());
  }

  getStep(): StepType {
    return {
      label: this.translate.instant('WORKS.STEP_ADDITIONAL_CLAIMS.TITLE'),
      formBuilder: [
        {
          fieldGroupClassName: 'display-flex',
          fieldGroup: [
            {
              type: 'label',
              key: 'msgClaimsValidation',
              modelOptions: { updateOn: 'submit' },
              templateOptions: { class: 'ice-error-msg' },
              hideExpression: model => !this.afterFirstValidation,
              validators: {
                hasError: {
                  expression: (control: FormControl, field: FormlyFieldConfig) => !control.value,
                  message: '',
                },
              },
              hooks: {
                onInit: field => {
                  this.msgClaimsValidation = field;
                },
              },
            },
          ],
        },
        {
          key: 'additionalClaims',
          type: 'tree-chart',
          templateOptions: {
            originalTitleChanged: originalTitleValue => {
              const rootElement = this.getInitialRootElement(this.translate);
              rootElement.name = originalTitleValue;
              if (this.additionalClaimsField.formControl?.value?.length > 1) {
                const currentValue = this.additionalClaimsField.formControl.value;
                this.additionalClaimsField.formControl.setValue(currentValue.map(node => (node.label === 'root' ? rootElement : node)));
              } else {
                this.additionalClaimsField.formControl.setValue([rootElement]);
              }
            },
            customInitialRootNode: this.getInitialRootElement(this.translate),
            expanseDisabledRootContextMenu: true,
            rootNodeDefaultName: this.translate.instant('WORKS.STEP_WORK_DETAIL.ORIGINAL_TITLE'),
            disableExpanseCollapseMode: true,
          },
          validators: {
            hasError: {
              expression: (control: FormControl, field: FormlyFieldConfig) => control.value?.length > 1,
              message: '',
            },
          },
          hooks: {
            onInit: field => {
              this.additionalClaimsField = field;
              this.validateClaims();
              this.storeNewItem
                .pipe(select(fromNewSectionItem.getNewSectionItemFields))
                .subscribe(fields => {
                  // Add cloned Claims
                  const rootElement = this.getInitialRootElement(this.translate);
                  if (fields['originalTitle']) {
                    rootElement.name = fields['originalTitle'];
                  }
                  if (fields['cloneClaims']) {
                    const claimNodes = fields['cloneClaims'].map(claim => {
                      const label = `root-${drop(claim.claimId.split('_')).join('-')}`;
                      const level = claim.claimId.split('_').length - 1;
                      const parentLabel = dropRight(label.split('-')).join('-');
                      const linkCreator = fields['cloneClaims'].find(clonedClaim => clonedClaim.claimId === dropRight(label.split('-')).join('_')) || null;
                      const role = this.getNodeRoleFromAPIRole(claim.extensions, claim.role);
                      const formattedClaim = this.joinShares(ClaimantUtils.getFormattedClaimantInfoFromClaim(claim, true));
                      return this.createNewNodeItem(formattedClaim, parentLabel, role, label, level, linkCreator);
                    });
                    field.formControl.setValue([rootElement, ...claimNodes]);
                  }
                })
                .unsubscribe();
            },
          },
        },
      ],
    };
  }

  private joinShares(claim) {
    const { ownership, shares } = claim;
    const tempOwnershipShares = cloneDeep(ownership);
    const newShares = (shares || []).map(share => {
      const compareShareText = this.getShareCompareText(share);
      const shareMatch = (ownership || []).find(os => this.getShareCompareText(os) === compareShareText);
      const ownershipShare = (shareMatch && shareMatch.share) || 0;
      if (shareMatch) {
        lodashRemove(tempOwnershipShares, os => this.getShareCompareText(os) === compareShareText);
      }
      return { ...share, ownershipShare };
    });
    if (!isEmpty(tempOwnershipShares)) {
      tempOwnershipShares.forEach(ownershipShare => {
        newShares.push({ ...ownershipShare, share: 0, ownershipShare: ownershipShare.share });
      });
    }
    return { ...claim, shares: newShares, ownership: null };
  }

  private getShareCompareText(share) {
    const { inclusion, territory, startDate, endDate, priorRoyaltiesDate, postTermCollectionDate } = share;
    return `${inclusion}-${territory}-${startDate}-${endDate}-${priorRoyaltiesDate}-${postTermCollectionDate}`;
  }

  private getNodeRoleFromAPIRole(claimExtensions, role) {
    if (CREATOR_ROLES.includes(role)) {
      return NodeRole.CREATOR;
    } else if (PUBLISHER_ROLES.includes(role)) {
      if (isEqual(claimExtensions?.incomeParticipant, ['true'])) {
        return NodeRole.INCOME_PARTICIPANT;
      }
      return NodeRole.E_PUBLISHER;
    } else if (SUBPUBLISHER_ROLES.includes(role)) {
      return NodeRole.SE_PUBLISHER;
    } else {
      return null;
    }
  }

  private openClaimDialog(parent: any, model = null, role = null, removeContextMenu: Function): void {
    const dialogForm = new DialogClaimNode(this.translate, this.translationLoader, this.store, this.storeNewItem, this.dialog, true);
    this.dialogClaimRef = dialogForm.openDialog(
      this.translate.instant('WORKS.STEP_ADDITIONAL_CLAIMS.CLAIM_DIALOG.TITLE'),
      cloneDeep(model || this.getInitialModel(role)),
      this.getDialogBuilder(role, model),
      event => this.onPopupSubmit(event, parent, model, role),
      () => (this.dialogClaimRef.close(), removeContextMenu && removeContextMenu()),
      ClaimsUtils.onSearchPartySelected(this, TYPE_CLAIMANT),
      this.getSearchBaseType(role),
    );
    this.shareStepType.dialogClaimRef = dialogForm;
  }

  private getSearchBaseType(role: string) {
    switch (role) {
      case NodeRole.CREATOR:
      case NodeRole.UNKNOWN_CREATOR:
        return IP_CONSTANTS.NATURAL_PERSON;
      case NodeRole.INCOME_PARTICIPANT:
      case NodeRole.E_PUBLISHER:
      case NodeRole.UNKNOWN_E_PUBLISHER:
      case NodeRole.SE_PUBLISHER:
        return IP_CONSTANTS.LEGAL_ENTITY;
    }
  }

  private onPopupSubmit(event, parent, model, role) {
    this.dialogClaimRef.close();
    const value = cloneDeep(this.additionalClaimsField.formControl.value || []);
    const newLevel = parent.level + 1;
    const label = `${parent.label}-${newLevel === 1 && role !== NodeRole.INCOME_PARTICIPANT ? 'W' : newLevel === 2 || role === NodeRole.INCOME_PARTICIPANT ? 'P' : ''}${
      value.filter(nodeItem => nodeItem.parent === parent.label && nodeItem.level === newLevel).length + 1
    }`;
    const linkCreator = (parent?.model?.ClaimantIPNameNumber && [parent.model.ClaimantIPNameNumber]) || null;
    const newItem = this.createNewNodeItem(event, parent.label, role, label, newLevel, linkCreator);
    if (role === NodeRole.INCOME_PARTICIPANT) {
      newItem.borderColor = '#717171';
    }
    if (!model) {
      this.additionalClaimsField.formControl.setValue([...value, newItem]);
    } else {
      this.additionalClaimsField.formControl.setValue([
        ...value.map(node => {
          if (node.label === parent.label) {
            const updatedNode = cloneDeep(node);
            updatedNode.dates = newItem.dates;
            updatedNode.territories = newItem.territories;
            updatedNode.name = newItem.name;
            updatedNode.model = newItem.model;
            updatedNode.pr = newItem.pr;
            updatedNode.mr = newItem.mr;
            updatedNode.prRights = newItem.prRights;
            updatedNode.mrRights = newItem.mrRights;
            updatedNode.message1 = newItem.message1;
            updatedNode.message2 = newItem.message2;
            updatedNode.societies = newItem.societies;
            return updatedNode;
          } else {
            return node;
          }
        }),
      ]);
    }
    this.additionalClaimsField.formControl.markAsTouched();
    this.validateClaims();
    this.afterFirstValidation = true;
    this.fieldValidatorService.checkFormlyExpressions();
  }

  private validateClaims() {
    const errors = [];
    const hasFirstCreator = this.additionalClaimsField.formControl.value?.some(claimNode => claimNode.parent === 'root' && ['C', 'A', 'CA'].includes(claimNode.model?.role));
    if (!hasFirstCreator) {
      errors.push(this.translate.instant('WORKS.STEP_ADDITIONAL_CLAIMS.VALIDATION_ERRORS.NO_VALID_FIRST_CREATOR'));
    }
    this.errors = errors;
    const firstError = this.errors.length > 0 ? this.errors[0] : null;
    this.msgClaimsValidation.formControl.setValue(firstError);
  }

  createNewNodeItem(event, parentLabel, role, label, level, linkCreator) {
    const { ClaimantName, shares, societies } = event;
    const { mr, pr, mrRights, prRights } = this.getPrMrRights(shares);
    let dates = '';
    let territories = '';
    let message1 = '';
    let message2 = '';
    if (!isEmpty(event.shares) && uniq(event.shares.map(share => `${share.type}-${share.territory}-${share.startDate}-${share.endDate}-${share.inclusion}`)).length === 2) {
      // If only 1 Performing and 1 Mechanical = 1 scope
      const data: any = first(event.shares);
      dates = (data.startDate || data.endDate) && `${DateTimeUtils.formatDate(data.startDate)}/${DateTimeUtils.formatDate(data.endDate)}`;
      territories = data.territory;
    } else {
      message1 = this.translate.instant('WORKS.STEP_ADDITIONAL_CLAIMS.MULTIPLE_SCOPES_LINE_1');
      message2 = this.translate.instant('WORKS.STEP_ADDITIONAL_CLAIMS.MULTIPLE_SCOPES_LINE_2');
    }
    return {
      label,
      dates,
      territories,
      name: ClaimantName,
      nodeRole: role,
      model: event,
      parent: parentLabel,
      linkCreator,
      color: '#9B0CFF',
      pr: `${pr} %`,
      mr: `${mr} %`,
      mrRights,
      prRights,
      message1,
      message2,
      level,
      borderColor: null,
      contextMenu: {
        items: this.getContextMenuOptions(role),
      },
      societies,
    };
  }

  private getInitialModel(role) {
    const initialModel = ClaimInitModel;
    switch (role) {
      case NodeRole.CREATOR:
        return { ...initialModel, role: 'CA' };
      case NodeRole.UNKNOWN_CREATOR:
        return ClaimInitModelUnknownAuthor;
      case NodeRole.INCOME_PARTICIPANT:
      case NodeRole.E_PUBLISHER:
        return { ...initialModel, role: 'E' };
      case NodeRole.UNKNOWN_E_PUBLISHER:
        return ClaimInitModelUnknownPublisher;
      case NodeRole.SE_PUBLISHER:
        return { ...initialModel, role: 'SE' };
      default:
        return initialModel;
    }
  }

  private getDialogBuilder(role: string, model: any) {
    return concat(
      this.getpartyAndRoleFields(false, true, this.getDefaultRoleValues(role, model), this.getSearchBaseType(role)),
      this.shareStepType.getMsgValidator(),
      this.shareStepType.getRepeatSectionFormConfig(false, true, role !== NodeRole.SE_PUBLISHER, true),
    );
  }

  private getpartyAndRoleFields(isClaim = true, onlyCreators = false, availableRoles: string[] = null, ipBaseType: string): any {
    return [
      {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
          ...this.getBasicSearchPartyFields(false, true, ipBaseType),
          ...this.getHiddenSearchPartyFields(false),
          this.getClaimantRoleField(isClaim, onlyCreators, availableRoles),
        ],
      },
    ];
  }

  private getDefaultRoleValues(role, model) {
    switch (role) {
      case NodeRole.CREATOR:
      case NodeRole.UNKNOWN_CREATOR:
        return CREATOR_ROLES;
      case NodeRole.INCOME_PARTICIPANT:
      case NodeRole.E_PUBLISHER:
      case NodeRole.UNKNOWN_E_PUBLISHER:
        return PUBLISHER_ROLES;
      case NodeRole.SE_PUBLISHER:
        if (model && SUBPUBLISHER_ROLES.includes(model.role)) {
          // When cloning
          return [model.role];
        }
        return WORK_CREATION_SUBPUBLISHER_ROLES;
      default:
        return null;
    }
  }

  private getPrMrRights(shares) {
    const totals = {};
    SharesValidator.initTotals(shares, totals);
    SharesValidator.sumTypeALLValueToIndividualRights(shares, totals);
    let mr = 0;
    let pr = 0;
    let mrRights = '';
    let prRights = '';
    Object.keys(totals).forEach(key => {
      const value = totals[key];
      if (key.includes(RightTypes.MR)) {
        if (value > mr) {
          mr = value;
        }
        const inclusionRight = key.replace(`${RightTypes.MR}-`, '');
        if (inclusionRight === 'ALL') {
          mrRights = 'All MR';
        } else {
          mrRights = `${mrRights}, ${inclusionRight}`;
        }
      }
      if (key.includes(RightTypes.PR)) {
        if (value > pr) {
          pr = value;
        }
        const inclusionRight = key.replace(`${RightTypes.PR}-`, '');
        if (inclusionRight === 'ALL') {
          prRights = 'All PR';
        } else {
          prRights = `${prRights}, ${inclusionRight}`;
        }
      }
    });
    return { mr, pr, mrRights, prRights };
  }

  private getInitialRootElement(translate) {
    return {
      label: 'root',
      name: this.translate.instant('WORKS.STEP_WORK_DETAIL.ORIGINAL_TITLE'),
      parent: null,
      color: '#17becf',
      level: 0,
      contextMenu: {
        items: [
          {
            label: translate.instant('WORKS.STEP_ADDITIONAL_CLAIMS.ROOT_NODE_OPTIONS.ADD_CREATOR'),
            action: (nodeData, removeContextMenu) => this.openClaimDialog(nodeData, null, NodeRole.CREATOR, removeContextMenu),
          },
          {
            label: translate.instant('WORKS.STEP_ADDITIONAL_CLAIMS.ROOT_NODE_OPTIONS.ADD_UNKNOWN_CREATOR'),
            action: (nodeData, removeContextMenu) => this.openClaimDialog(nodeData, null, NodeRole.UNKNOWN_CREATOR, removeContextMenu),
          },
          {
            label: translate.instant('WORKS.STEP_ADDITIONAL_CLAIMS.ROOT_NODE_OPTIONS.ADD_INC_PART_PUBLISHER'),
            action: (nodeData, removeContextMenu) => this.openClaimDialog(nodeData, null, NodeRole.INCOME_PARTICIPANT, removeContextMenu),
          },
        ],
      },
    };
  }

  private getContextMenuOptions(role: string) {
    let roleOptions = [];
    const childNodeOptions = [
      {
        label: this.translate.instant('WORKS.STEP_ADDITIONAL_CLAIMS.MODIFY_CLAIM'),
        action: (nodeData, removeContextMenu) => this.openClaimDialog(nodeData, nodeData?.model, nodeData?.nodeRole, removeContextMenu),
      },
      {
        label: this.translate.instant('WORKS.STEP_ADDITIONAL_CLAIMS.DELETE_CLAIM'),
        action: (nodeData, removeContextMenu) => this.deleteClaimDialog(role, nodeData),
        fillColor: '#EE5855',
      },
    ];
    switch (role) {
      case NodeRole.FIRST_CREATOR:
      case NodeRole.CREATOR:
      case NodeRole.UNKNOWN_CREATOR:
        roleOptions = [
          {
            label: this.translate.instant('WORKS.STEP_ADDITIONAL_CLAIMS.CREATOR_NODE_OPTIONS.ADD_E_PUBLISHER'),
            action: (nodeData, removeContextMenu) => this.openClaimDialog(nodeData, null, NodeRole.E_PUBLISHER, removeContextMenu),
          },
          {
            label: this.translate.instant('WORKS.STEP_ADDITIONAL_CLAIMS.CREATOR_NODE_OPTIONS.ADD_UNKNOWN_E_PUB'),
            action: (nodeData, removeContextMenu) => this.openClaimDialog(nodeData, null, NodeRole.UNKNOWN_E_PUBLISHER, removeContextMenu),
          },
        ];
        break;
      case NodeRole.E_PUBLISHER:
      case NodeRole.UNKNOWN_E_PUBLISHER:
      case NodeRole.SE_PUBLISHER:
        roleOptions = [
          {
            label: this.translate.instant('WORKS.STEP_ADDITIONAL_CLAIMS.PUB_NODE_OPTIONS.ADD_SE_PUBLISHER'),
            action: (nodeData, removeContextMenu) => this.openClaimDialog(nodeData, null, NodeRole.SE_PUBLISHER, removeContextMenu),
          },
        ];
        break;
      case NodeRole.INCOME_PARTICIPANT:
      // No extra options
    }
    return [...roleOptions, ...(role !== 'FIRST_CREATOR' ? childNodeOptions : [])];
  }

  private deleteClaimDialog(role, nodeData) {
    const dialogInfo = new DialogInfo(this.translate, this.dialog, this.store);
    let deleteMessage: string;
    switch (role) {
      case NodeRole.FIRST_CREATOR:
      case NodeRole.CREATOR:
      case NodeRole.UNKNOWN_CREATOR:
        deleteMessage = this.translate.instant('WORKS.STEP_ADDITIONAL_CLAIMS.DELETE_CREATOR');
        break;
      case NodeRole.E_PUBLISHER:
      case NodeRole.UNKNOWN_E_PUBLISHER:
      case NodeRole.INCOME_PARTICIPANT:
        deleteMessage = this.translate.instant('WORKS.STEP_ADDITIONAL_CLAIMS.DELETE_PUBLISHER');
        break;
      case NodeRole.SE_PUBLISHER:
        deleteMessage = this.translate.instant('WORKS.STEP_ADDITIONAL_CLAIMS.DELETE_SUBPUBLISHER');
    }
    const dialogRefInfo = dialogInfo.openDialog(
      this.translate.instant('WORKS.STEP_ADDITIONAL_CLAIMS.DELETE_CLAIM'),
      deleteMessage,
      () => {
        const value = cloneDeep(this.additionalClaimsField.formControl.value);
        this.additionalClaimsField.formControl.setValue(value.filter(node => !startsWith(node.label, nodeData.label)));
        dialogRefInfo.close();
        this.validateClaims();
      },
      () => dialogRefInfo.close(),
      this.translate.instant('WORKS.STEP_ADDITIONAL_CLAIMS.DELETE_YES'),
      this.translate.instant('WORKS.STEP_ADDITIONAL_CLAIMS.DELETE_NO'),
    );
  }
}
