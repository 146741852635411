import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CopyrightUtils, RouterUtils, WorkCleaned, WorkUtils, fieldConfig, TabsUtils } from '@ice';
import { DataTableRow, SortInfo } from '@ice/components/data-table/data-table';
import { DialogContentComponent } from '@ice/components/dialog-content/dialog-content.component';
import { DialogMultiLayoutComponent } from '@ice/components/dialog-multi-layout/dialog-multi-layout.component';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { Store, select } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectionType } from '@swimlane/ngx-datatable';
import { locale as englishSearch } from 'assets/i18n/en/config/data-table-builders';
import { locale as english } from 'assets/i18n/en/config/tabs-data-builders';
import * as fromApiCalls from 'config/api-calls';
import { RELATION_ORIGIN } from 'config/constants/relation.constants';
import { CopyrightWorksDataTable } from 'config/data-table-builders/copyright.works';
import { DIALOG_NORMAL_BT, DialogForm } from 'config/dialog-builders/dialog-form';
import { DialogInfo } from 'config/dialog-builders/dialog-info';
import { SectionsConfig } from 'config/sections-config';
import { first, get, groupBy, isEmpty, sortBy, toPairs } from 'lodash';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { filter, map, skipWhile, take, withLatestFrom } from 'rxjs/operators';
import { DetailService } from 'services/detail/detail.service';
import { PermissionsService } from 'services/permissions/permissions.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';
import type { SectionTabConfig } from '../../tabs-data';

const ERROR_MESSAGE_DURATION = 10000;

export class TabSourceWorks implements SectionTabConfig {
  private schema: CopyrightWorksDataTable;
  private workId: string;
  private canAddSourceWorks = false;
  private canEditWorkRelations = false;
  private workDetailUrl: string = undefined;

  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private dialog: MatDialog,
    commonApiService: null,
    private detailService: DetailService,
    nsService: null,
    iceFacade: null,
    private fieldValidatorService: FieldValidatorService,
    private permissionsService: PermissionsService,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.fuseTranslationLoader.loadTranslations(englishSearch);
    this.schema = new CopyrightWorksDataTable(this.translate, this.fuseTranslationLoader);
    this.getWorkId();
    this.canAddSourceWorks = this.permissionsService.can('work_source_works_add_works_of_all_statuses') || this.permissionsService.can('work_source_works_add_works_of_status_4');
    this.canEditWorkRelations = this.permissionsService.can('work_source_works_edit_work_relations');
    this.store.pipe(select(fromRoot.getRouterUrl), take(1)).subscribe(url => {
      this.workDetailUrl = url;
    });
  }

  getNameSpaceSchema() {
    return [
      {
        name: this.translate.instant('WORKS.SOURCE_WORKS.TABLE.SOC'),
        prop: 'ns',
        flexGrow: 1,
      },
    ];
  }

  getAdditionalFieldsAtTheEndSchema() {
    return [
      {
        name: this.translate.instant('WORKS.SOURCE_WORKS.TABLE.MIGRATED_FROM'),
        prop: 'migratedFrom',
        flexGrow: 1.5,
      },
      {
        name: this.translate.instant('WORKS.SOURCE_WORKS.TABLE.CWR'),
        prop: 'cwrSourceLabel',
        tooltip: 'cwrSourceTooltip',
        tooltipDuration: 250,
        flexGrow: 1,
      },
      {
        name: this.translate.instant('WORKS.SOURCE_WORKS.CREATION_DATE'),
        prop: 'creationDate',
        tooltip: 'creationDate',
        tooltipDuration: 250,
        flexGrow: 1.2,
        cellClass: 'ice-justify-left',
      },
    ];
  }

  getDeleteActionSchema() {
    return [
      {
        name: '',
        prop: 'deleteBtn',
        actionButtonIcon: 'delete',
        flexGrow: 0.001,
        maxWidth: 50,
        minWidth: 50,
        resizeable: false,
        action: row => this.deletePopup(row),
        hideActionButton: row =>
          this.store.pipe(
            select(fromRoot.getWorkKey),
            map(workKey => row.key === workKey),
          ),
        actionButtonTooltip: this.translate.instant('WORKS.SOURCE_WORKS.REMOVE'),
      },
    ];
  }

  deletePopup(row) {
    this.store
      .select(fromRoot.getCopyrightWork)
      .pipe(
        map(work => work),
        withLatestFrom(this.store.select(fromRoot.getSourceWorks).pipe(map(works => works.length))),
        take(1),
      )
      .subscribe(([work, sourceWorksCount]) => {
        const workId = work.attributes.key;
        const isMasterWork =
          CopyrightUtils.getKeySuffixNumerical(row.id) === CopyrightUtils.getKeySuffixNumerical(workId) &&
          CopyrightUtils.getKeyPrefix(row.id) === CopyrightUtils.getKeyPrefix(workId);
        const deleteMasterAloneMessage = isMasterWork && sourceWorksCount === 1 ? this.translate.instant('WORKS.SOURCE_WORKS.CHECK_DELETE_MASTER_ALONE') : '';
        const deleteMasterSourceMessage = isMasterWork && sourceWorksCount > 1 ? this.translate.instant('WORKS.SOURCE_WORKS.CHECK_DELETE_MASTER_WITH_SOURCE') : '';
        const deleteSourceMessage = !isMasterWork ? this.translate.instant('WORKS.SOURCE_WORKS.CHECK_DELETE_SOURCE') : '';
        const title$ = new BehaviorSubject<string>(this.translate.instant('WORKS.SOURCE_WORKS.REMOVE'));
        const message$ = new BehaviorSubject<string>(deleteMasterAloneMessage || deleteMasterSourceMessage || deleteSourceMessage);
        let isRowDeleted = false;
        let isDeleting = false;

        const onRowDeleted = () => {
          this.store.dispatch(new fromRoot.RefreshDetail());
          this.dialog.closeAll();
        };

        const dialogInfo = new DialogInfo(this.translate, this.dialog, this.store);
        const dialogRefInfo = dialogInfo.openDialog(
          title$,
          message$,
          () => {
            if (isRowDeleted) {
              return onRowDeleted();
            }
            if (isDeleting) {
              return;
            }
            isDeleting = true;
            this.detailService.deleteWork(row.id, row.ns).subscribe(result => {
              title$.next(this.translate.instant('WORKS.SOURCE_WORKS.REMOVED'));
              message$.next(this.translate.instant('WORKS.SOURCE_WORKS.DELETE_WARNING'));
              if (deleteMasterAloneMessage) {
                this.store.dispatch(new fromRoot.EndEditMode());
                this.store.dispatch(new fromRoot.Go({ path: RouterUtils.getSectionPath('works') }));
              } else {
                this.store.dispatch(new fromRoot.UpdateField({ object: 'source-works', newValue: row.id, type: 'delete' }));
                isRowDeleted = true;
              }
              isDeleting = false;
            });
          },
          () => (isRowDeleted ? onRowDeleted() : dialogRefInfo.close()),
        );
      });
  }

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('WORKS.SOURCE_WORKS.TAB_TITLE'),
              actionButtons: this.store.pipe(
                select(fromRoot.getEditMode),
                withLatestFrom(this.store.pipe(select(fromRoot.getForcedNS))),
                map(([editMode, forcedNS]) => {
                  if (!editMode && this.canAddSourceWorks && !forcedNS) {
                    return [
                      {
                        icon: 'add',
                        tooltip: this.translate.instant('WORKS.SOURCE_WORKS.ADD'),
                        class: 'mat-white-icon',
                        onClick: () => this.openDialogNewSourceWork(),
                        disabled: this.store.pipe(
                          select(fromRoot.getLoadingVisibility),
                          map(loading => !!loading),
                        ),
                      },
                    ];
                  }
                  return null;
                }),
              ),
              model: this.store.pipe(
                select(fromRoot.getSourceWorks),
                withLatestFrom(this.store.select(fromRoot.getWorkKey)),
                map(([sourceWorks, workKey]) => {
                  const cleanedSourceWorkItems = sourceWorks.map(sourceWork => {
                    const sourceWorkId = get(sourceWork, 'id');
                    const ownIceId = sourceWorkId && sourceWorkId.startsWith('ICE:') ? sourceWorkId : null;
                    const iceWorkRef = sourceWork['relations'] && sourceWork['relations'].filter(rel => rel.otherId.startsWith('ICE'));
                    return {
                      ...sourceWork,
                      keyWithoutPrefix: ownIceId ? CopyrightUtils.getKeySuffix(ownIceId) : (get(iceWorkRef, '[0].otherId') && get(iceWorkRef, '[0].otherId').split(':')[1]) || '',
                    };
                  });
                  const orderedList = sortBy(
                    cleanedSourceWorkItems,
                    (
                      item, // The original work goes first
                    ) => (item.key === workKey ? 0 : 1),
                  );

                  return orderedList;
                }),
              ),
              sorts: [],
              schema: this.getDataTableSourceWork(),
              loadingIndicator: false,
              selectionType: of(SelectionType.single),
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: true,
              shadowed: false,
              columnMode: 'flex',
              tableWidth: '100',
              onSelect: (event: any[]) => {
                const url = window.location.pathname;
                this.store.dispatch(new fromRoot.SaveForcedNsBackLink(url));
                const defaultTabName = TabsUtils.getDefaultTabName(SectionsConfig.WORKS.name);
                this.store.dispatch(
                  new fromRoot.Go({
                    path: [`copyright/${SectionsConfig.WORKS.name}/${event[0].id}/details`],
                    extras: { queryParams: { forceNS: true } },
                  }),
                );
              },
              onMouseSelect: event => {
                const defaultTabName = TabsUtils.getDefaultTabName(SectionsConfig.WORKS.name);
                this.store.dispatch(
                  new fromRoot.OpenNewTab({
                    path: [`copyright/${SectionsConfig.WORKS.name}/${event.id}/details?forceNS=true`],
                  }),
                );
                this.store.dispatch(new fromRoot.SaveForcedNsBackLink(this.workDetailUrl));
              },
              getRowClass: (): any => ({ 'ice-search-results-table-row': true }),
              onSort: sorts => {
                this.store
                  .select(fromRoot.getWorkMatchRelations)
                  .pipe(
                    filter(relations => !isEmpty(relations)),
                    take(1),
                  )
                  .subscribe(relations => {
                    const firstSort: SortInfo = sorts && sorts[0];
                    if (firstSort && firstSort.prop !== '') {
                      const sortInfo: SortInfo = firstSort && this.schema.formatSort(firstSort);
                      const sort: string = sortInfo ? `${sortInfo.prop}:${sortInfo.dir}` : '';
                      if (sort) {
                        toPairs(
                          groupBy(
                            relations.map(relation => relation.otherId),
                            id => id.split(':')[0],
                          ),
                        ).map(([ns, nsRelations]) => {
                          const body = JSON.stringify(JSON.stringify(WorkUtils.getSourceWorksQuery(nsRelations)));
                          this.store.dispatch(
                            new fromRoot.StartApiCall({
                              apiCall: fromApiCalls.getSourceWorks,
                              apiCallData: { body, labels: { sort, ns } },
                            }),
                          );
                        });
                      }
                    }
                  });
              },
              visibleColumns: this.getVisibleColumnsSourceWorks(this.getDataTableSourceWork()),
            },
          },
        ],
      },
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('WORKS.WORKS_RELATIONS.TABLE_TITLE'),
              model: this.store.pipe(select(fromRoot.getWorkRelations)),
              columnMode: 'flex',
              actionButtons: this.store.pipe(select(fromRoot.getEditMode)).pipe(
                map(editMode => {
                  if (editMode && this.canEditWorkRelations) {
                    return [
                      {
                        icon: 'add',
                        tooltip: this.translate.instant('WORKS.WORKS_RELATIONS.ADD'),
                        class: 'mat-white-icon',
                        onClick: () => this.openDialogCreateRelations(),
                        disabled: this.store.pipe(
                          select(fromRoot.getLoadingVisibility),
                          map(loading => !!loading),
                        ),
                      },
                    ];
                  }
                  return null;
                }),
              ),
              schema: this.getDataTableRelations(),
              tableWidth: '100',
              sorts: [{ prop: 'relationTypeDisplay', dir: 'asc' }],
              visibleColumns: this.getVisibleColumnsWorkRelations(this.getDataTableRelations()),
            },
          },
        ],
      },
    ];
  }

  getDataTableSourceWork(): DataTableRow[] {
    return [
      ...this.getNameSpaceSchema(),
      ...this.schema.getDataTableShareFields(true, true),
      ...this.getAdditionalFieldsAtTheEndSchema(),
      ...this.getUndoMatchActionSchema(),
      ...this.getDeleteActionSchema(),
    ];
  }

  getDataTableRelations(): DataTableRow[] {
    return [
      { name: this.translate.instant('WORKS.WORKS_RELATIONS.RELATION_TYPE'), prop: 'relationTypeDisplay', flexGrow: 1.5 },
      { name: this.translate.instant('WORKS.WORK_KEY'), prop: 'keyWithoutPrefix', flexGrow: 1 },
      { name: this.translate.instant('WORKS.TITLE'), prop: 'title', flexGrow: 1.7 },
      { name: this.translate.instant('WORKS.PARTY_NAMES'), prop: 'namesToString', flexGrow: 2 },
      { name: this.translate.instant('WORKS.ROLE_CODES'), prop: 'roleCodesToString', flexGrow: 1 },
      { name: this.translate.instant('WORKS.IPI_NUMBER'), prop: 'IPINumbersToString', flexGrow: 2 },
      { name: this.translate.instant('WORKS.SOURCE'), prop: 'source', flexGrow: 1 },
      { name: this.translate.instant('WORKS.OWNER'), prop: 'owner', flexGrow: 1 },
      { name: this.translate.instant('WORKS.STATUS'), prop: 'status', flexGrow: 1 },
      { name: this.translate.instant('WORKS.DURATION'), prop: 'duration', flexGrow: 1 },
      {
        name: this.translate.instant('CC'),
        prop: 'counterclaims',
        flexGrow: 0.5,
        cellClass: 'ice-search-results-table-div-names-field',
        iconCustomTooltipHtmlText: 'htmlText',
        iconColor: 'iconColor',
      },
      {
        name: '',
        prop: 'deleteBtn',
        actionButtonIcon: 'delete',
        flexGrow: 0.001,
        maxWidth: 50,
        minWidth: 50,
        resizeable: false,
        action: row => this.deleteRelationPopup(row),
        hideActionButton: row =>
          this.store.pipe(
            select(fromRoot.getEditMode),
            map(editMode => !editMode),
          ),
        actionButtonTooltip: this.translate.instant('WORKS.WORKS_RELATIONS.REMOVE'),
      },
    ];
  }

  openDialogCreateRelations(newItemType?) {
    const dialogForm = new DialogForm(this.translate, this.dialog, DIALOG_NORMAL_BT);
    const dialogRef = dialogForm.openDialog(
      this.translate.instant('WORKS.WORKS_RELATIONS.ADD'),
      {},
      this.getRelationsForm(),
      event => {
        const { relationDisplayType, workId } = event;
        let relationType: string;
        let intExt: string;

        if (relationDisplayType.startsWith('EXT')) {
          intExt = RELATION_ORIGIN.EXTERNAL;
          relationType = relationDisplayType.substring(3);
        } else {
          intExt = RELATION_ORIGIN.INTERNAL;
          relationType = relationDisplayType;
        }
        this.store.dispatch(new fromRoot.GetRelationRelatedWork({ id: workId.trim(), intExt, relationType }));
        if (intExt === RELATION_ORIGIN.INTERNAL) {
          this.store.dispatch(
            new fromRoot.UpdateField({
              object: 'relations',
              newValue: { otherId: workId.trim(), relation: relationType },
              type: 'new',
            }),
          );
        }
        dialogRef.close();
      },
      () => dialogRef.close(),
      this.translate.instant('POPUP_COMMON.CONFIRM'),
      this.translate.instant('POPUP_COMMON.CANCEL'),
      'dialog-wrapper-width-420-h',
    );
  }

  getWorkId() {
    this.store.pipe(select(fromRoot.getRouterParams), take(1)).subscribe(params => {
      this.workId = params.key;
    });
  }

  private getRelationsForm(): FormlyFieldConfig[] {
    return [
      fieldConfig([
        {
          className: 'flex-1',
          key: 'relationDisplayType',
          type: 'select',
          templateOptions: {
            label: `${this.translate.instant('WORKS.WORKS_RELATIONS.TYPE')}`,
            required: true,
            options: this.getRelationOptions(),
          },
        },
        {
          className: 'flex-1',
          key: 'workId',
          wrappers: ['form-field', 'wrapper-input-text'],
          type: 'input',
          templateOptions: {
            placeholder: `${this.translate.instant('WORKS.WORKS_RELATIONS.WORK_ID')}`,
            required: true,
          },
          modelOptions: {
            updateOn: 'blur',
          },
          expressionProperties: {
            'model.relationDisplayType': model => (model.relationDisplayType ? model.relationDisplayType.trim() : model.relationDisplayType),
          },
          asyncValidators: {
            works_validation: {
              expression: (control: FormControl, field) => {
                if (!control.touched) {
                  of(true).toPromise();
                }
                return new Promise((resolve, reject) =>
                  this.fieldValidatorService.validWorkKey(
                    control,
                    workId => {
                      field.model.workId = workId;
                      resolve(true);
                    },
                    () => resolve(false),
                  ),
                );
              },
              message: this.translate.instant('REPERTOIRES.STEPS.EXCLUSIONS.FIELDS.WORKS.FIELDS.ERROR'),
            },
          },
        },
      ]),
    ];
  }

  private getRelationOptions() {
    // Folowing this logic: https://ice-cube.atlassian.net/browse/CUBE-10977?focusedCommentId=61801
    return [
      { label: this.translate.instant('WORKS.WORKS_RELATIONS.RELATION_TYPES.SAMPLES'), value: 'SMPL' },
      { label: this.translate.instant('WORKS.WORKS_RELATIONS.RELATION_TYPES.MODIFIES'), value: 'EXTMOD' },
      { label: this.translate.instant('WORKS.WORKS_RELATIONS.RELATION_TYPES.EXCERPTS'), value: 'EXTEXCP' },
      { label: this.translate.instant('WORKS.WORKS_RELATIONS.RELATION_TYPES.IS_SAMPLED_BY'), value: 'EXTSMPL' },
      { label: this.translate.instant('WORKS.WORKS_RELATIONS.RELATION_TYPES.IS_MODIFIED_BY'), value: 'MOD' },
      { label: this.translate.instant('WORKS.WORKS_RELATIONS.RELATION_TYPES.IS_EXCERPTED_BY'), value: 'EXCP' },
    ];
  }

  deleteRelationPopup(row: WorkCleaned) {
    this.dialog.open(DialogMultiLayoutComponent, {
      data: {
        className: 'dialogInfo',
        layouts: [
          {
            title: of(this.translate.instant('WORKS.WORKS_RELATIONS.REMOVE')),
            layout: [
              {
                group: [
                  {
                    type: 'response-error',
                    className: 'dialog-wrapper-auto',
                    config: {
                      response: of({ text: this.translate.instant('WORKS.WORKS_RELATIONS.CHECK_DELETE') }),
                      centerMessage: false,
                      messageIcon: of('info'),
                      responseButtons: of([
                        {
                          text: of(this.translate.instant('NOTES.BUTTONS.REMOVE')),
                          class: 'ok-button',
                          action: () => {
                            const intExt = row.intExt;
                            if (intExt === RELATION_ORIGIN.EXTERNAL) {
                              this.store.dispatch(
                                new fromRoot.UpdateField({
                                  object: 'workExternalRelations',
                                  newValue: { otherId: `ICE:${row.keyWithoutPrefix}`, relation: row.relationType },
                                  type: 'delete',
                                }),
                              );
                            } else {
                              this.store.dispatch(
                                new fromRoot.UpdateField({ object: 'relations', newValue: { otherId: `ICE:${row.keyWithoutPrefix}`, relation: row.relationType }, type: 'delete' }),
                              );
                              this.store.dispatch(
                                new fromRoot.UpdateField({
                                  object: 'workInternalRelations',
                                  newValue: { otherId: `ICE:${row.keyWithoutPrefix}`, relation: row.relationType },
                                  type: 'delete',
                                }),
                              );
                            }

                            this.dialog.closeAll();
                          },
                        },
                        {
                          text: of(this.translate.instant('NOTES.BUTTONS.CANCEL')),
                          action: () => this.dialog.closeAll(),
                        },
                      ]),
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    });
  }

  getUndoMatchActionSchema() {
    return [
      {
        name: '',
        prop: 'undoMatchBtn',
        actionButtonIcon: 'sync_alt',
        flexGrow: 1,
        resizeable: false,
        action: row => this.undoMatchPopup(row),
        hideActionButton: row =>
          this.store.pipe(
            withLatestFrom(fromRoot.getWorkKey),
            map(workKey => row.key === workKey),
          ),
        actionButtonTooltip: this.translate.instant('WORKS.SOURCE_WORKS.UNMATCH'),
      },
    ];
  }

  undoMatchPopup(row) {
    this.store
      .select(fromRoot.getCopyrightWork)
      .pipe(
        map(work => work),
        withLatestFrom(this.store.select(fromRoot.getSourceWorks).pipe(map(works => works.length))),
        take(1),
      )
      .subscribe(([work, sourceWorksCount]) => {
        const workId = work.attributes.key;
        const unmatchSourceMessage = this.translate.instant('WORKS.SOURCE_WORKS.CHECK_UNMATCH_SOURCE');
        this.store.dispatch(new fromRoot.CleanResponse());
        this.dialog.open(DialogContentComponent, {
          data: {
            loading: this.store.pipe(select(fromRoot.getLoadingVisibility)),
            title: of(this.translate.instant('WORKS.SOURCE_WORKS.UNMATCH')),
            formBuilder: of([
              {
                fieldGroupClassName: 'display-flex-col',
                fieldGroup: [
                  {
                    template: `<h3>${unmatchSourceMessage}</h3>`,
                  },
                ],
              },
            ]),
            model: of({}),
            submitEnabled: true,
            submitAvailable: 'true',
            submitLabel: this.translate.instant('POPUP_COMMON.YES'),
            button1Enabled: true,
            button1Available: 'true',
            button1Label: this.translate.instant('POPUP_COMMON.NO'),
            onSubmit: _ => {
              this.store.dispatch(
                new fromRoot.WorkResolution({
                  id1: work.id || '',
                  id2: row.id || '',
                  ns: first(work.id.split(':')) || '',
                  ns2: row.ns || '',
                  cause: 'not specified',
                  outcome: 'NO_MATCH',
                }),
              );

              this.store
                .select(fromRoot.getCopyrightResponse)
                .pipe(
                  filter(response => !!response),
                  take(1),
                )
                .subscribe((response: any) => {
                  const status = response.status;
                  switch (status) {
                    case 'success':
                      setTimeout(() => {
                        this.dialog.closeAll();
                        this.store.dispatch(new fromRoot.ShowDataLoadingVisibility(false));
                        this.store.dispatch(new fromRoot.EndEditMode());
                        this.store.dispatch(new fromRoot.GetItem({ key: workId }));
                        this.store.dispatch(new fromRoot.Go({ path: [`/${SectionsConfig.WORKS.domainName}/${SectionsConfig.WORKS.name}/${workId}/source-works`] }));
                      }, 4000);
                      break;
                    case 'error':
                      this.store.dispatch(
                        new fromRoot.ShowSnackBar({
                          message: get(response, 'error.error.message') || (response && response.statusMessage),
                          duration: ERROR_MESSAGE_DURATION,
                        }),
                      );
                      setTimeout(() => {
                        this.dialog.closeAll();
                        this.store.dispatch(new fromRoot.ShowDataLoadingVisibility(false));
                        this.store.dispatch(new fromRoot.EndEditMode());
                        this.store.dispatch(new fromRoot.GetItem({ key: workId }));
                        this.store.dispatch(new fromRoot.Go({ path: [`/${SectionsConfig.WORKS.domainName}/${SectionsConfig.WORKS.name}/${workId}/source-works`] }));
                      }, 3000);
                      break;

                    case 'in_progress':
                    case 'in-progress':
                    default:
                  }
                });
            },
            onButton1: () => {
              this.dialog.closeAll();
            },
          },
        });
      });
  }

  openDialogNewSourceWork() {
    const dialogForm = new DialogForm(this.translate, this.dialog, DIALOG_NORMAL_BT);
    const dialogRef = dialogForm.openDialog(
      this.translate.instant('WORKS.SOURCE_WORKS.ADD'),
      {},
      this.getNewSourceWorkForm(),
      event => this.doSourceWorkResolution(event.workId, dialogRef),
      () => dialogRef.close(),
      this.translate.instant('POPUP_COMMON.CONFIRM'),
      this.translate.instant('POPUP_COMMON.CANCEL'),
      'dialog-wrapper-width-420-h',
      this.store.pipe(select(fromRoot.getLoadingVisibility)),
      this.getSourceWorkResolutionStatus(),
      () => {
        dialogRef.close();
        this.updateSourceWorks();
      },
      this.getSourceWorkResolutionMessageIcon(),
    );
    dialogRef.disableClose = true;
  }

  private getNewSourceWorkForm(): FormlyFieldConfig[] {
    const workExistsValidator = {
      expression: (control: FormControl, field) => {
        return new Promise((resolve, reject) =>
          this.fieldValidatorService.validWorkKey(
            control,
            workId => {
              field.model.workId = workId;
              resolve(true);
            },
            () => resolve(false),
          ),
        );
      },
      message: this.translate.instant('REPERTOIRES.STEPS.EXCLUSIONS.FIELDS.WORKS.FIELDS.ERROR'),
    };
    const workStatus4Validator = {
      expression: (control: FormControl, field) => this.fieldValidatorService.isWorkKeyStatus4(control),
      message: this.translate.instant('REPERTOIRES.STEPS.EXCLUSIONS.FIELDS.WORKS.FIELDS.NOT_STATUS_4_ERROR'),
    };
    const canOnlyAddSourceWorksOfStatus4 =
      !this.permissionsService.can('work_source_works_add_works_of_all_statuses') && this.permissionsService.can('work_source_works_add_works_of_status_4');
    return [
      fieldConfig([
        {
          className: 'flex-1',
          key: 'workId',
          type: 'input',
          templateOptions: {
            placeholder: `${this.translate.instant('WORKS.WORKS_RELATIONS.WORK_ID')}`,
            required: true,
          },
          modelOptions: {
            updateOn: 'blur',
          },
          asyncValidators: {
            workExistsValidator,
            ...(canOnlyAddSourceWorksOfStatus4 ? { workStatus4Validator } : {}),
          },
        },
      ]),
    ];
  }

  private doSourceWorkResolution(workIdToMatch: String, dialogRef: MatDialogRef<DialogMultiLayoutComponent, any>): void {
    this.store
      .select(fromRoot.getCopyrightWork)
      .pipe(take(1))
      .subscribe(work => {
        dialogRef.componentInstance.setLayout(1);
        this.store.dispatch(
          new fromRoot.WorkResolution({
            id1: work?.id || '',
            id2: workIdToMatch || '',
            ns: first(work?.id?.split(':')) || '',
            ns2: first(workIdToMatch?.split(':')) || '',
            cause: 'not specified',
            outcome: 'CONFIRMED',
          }),
        );
      });
  }

  private updateSourceWorks(): void {
    this.store
      .pipe(
        select(fromRoot.getCopyrightResponse),
        skipWhile(response => !response),
        take(1),
      )
      .subscribe(() => {
        this.store.pipe(select(fromRoot.getRouterParams), take(1)).subscribe(params => {
          this.store.dispatch(new fromRoot.GetItem({ key: params.key }));
        });
      });
  }

  private getSourceWorkResolutionStatus(): Observable<Object> {
    return combineLatest([this.store.pipe(select(fromRoot.getCopyrightResponse)), this.store.pipe(select(fromRoot.getLoadingVisibility))]).pipe(
      map(([response, isLoading]) => {
        if (isLoading || response === null) {
          return null;
        }
        const hasConflict = get(response, `masterEvent.targetTags.conflicts`, []).includes('no_merge');
        return {
          text:
            response?.status === 'success'
              ? hasConflict
                ? this.translate.instant('WORKS.SOURCE_WORKS.ADD_CONFLICT')
                : this.translate.instant('WORKS.SOURCE_WORKS.ADD_SUCCESS')
              : this.translate.instant('WORKS.SOURCE_WORKS.ADD_FAILURE'),
        };
      }),
    );
  }

  private getSourceWorkResolutionMessageIcon(): Observable<string> {
    return combineLatest([this.store.pipe(select(fromRoot.getCopyrightResponse)), this.store.pipe(select(fromRoot.getLoadingVisibility))]).pipe(
      map(([response, isLoading]) => {
        if (isLoading || response === null) {
          return '';
        }
        const hasConflict = get(response, `masterEvent.targetTags.conflicts`, []).includes('no_merge');
        return response?.status === 'success' ? (hasConflict ? 'warning' : 'info') : 'alert';
      }),
    );
  }

  private getVisibleColumnsSourceWorks(schema: any): Observable<string[]> {
    const schemaDatatable = schema ? TabsUtils.getSChemaPropsToArray(schema) : [];

    return this.store.pipe(
      select(fromRoot.getEditModeState),
      map(editMode => {
        if (editMode && editMode?.editName === 'ICE' && this.permissionsService.can('works_delete_source_works')) {
          return schemaDatatable;
        } else {
          return TabsUtils.getSchemaFiltered(schemaDatatable, ['migratedFrom', 'undoMatchBtn', 'deleteBtn']);
        }
      }),
    );
  }

  private getVisibleColumnsWorkRelations(schema: any): Observable<string[]> {
    const schemaDatatable = schema ? TabsUtils.getSChemaPropsToArray(schema) : [];

    return this.store.pipe(
      select(fromRoot.getEditMode),
      map(editMode => {
        if (editMode) {
          return schemaDatatable;
        } else {
          return TabsUtils.getSchemaFiltered(schemaDatatable, ['deleteBtn']);
        }
      }),
    );
  }
}
