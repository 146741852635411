import { environment } from 'config/env';
import type { SectionConfig } from './sections-config';

export const SectionCopyrightReports: SectionConfig = {
  name: 'reports',
  url: '/copyright/reports',
  domainName: 'copyright',
  baseUrl: environment.apiUrl,
  baseUrlCubeData: environment.apiUrlCubeData,
  apiSegment: 'reports',
  detailSegment: `reports/ICE/<<id>>`,
  minCharsToSearch: 5,
  apiIncludes: {
    search: `attributes`,
    detail: `attributes`,
  },
  searchServerSideSortDefault: 'attributes.requestDate:desc',
  allowedActions: {
    newItem: true,
    editItem: 'tab',
    newNote: false,
    removeItem: false,
  },
  homeRedirect: 'search',
  avoidSearchRedirect: true,
};
