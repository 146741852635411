import { Search, SearchActionInterface, SearchResultCleaned } from '@ice';
import { Action } from '@ngrx/store';
import { IceErrorPayload } from 'models/action-payloads/ice-error-payload';
import { MarkSearchItemPayload } from 'models/action-payloads/mark-search-item-payload';

export const SEARCH_SIMPLE = '[Search] Search Simple';
export const SEARCH_ADVANCED = '[Search] Search Advanced';
export const SEARCH_EXPERT = '[Search] Search Expert';
export const SEARCH_RESET = '[Search] Search Reset';
export const FETCH_SEARCH_PAGE = '[Search] Fetch Search Page';
export const FETCH_SEARCH_PAGE_FAIL = '[Search] Fetch Search Page Fail';
export const FETCH_SEARCH_PAGE_SUCCESS = '[Search] Fetch Search Page Success';
export const CANCEL_SEARCH = '[Search] Cancel Search';
export const EMPTY_RESULTS_ON_SEARCH_CHANGE = '[Search] Empty results on change search when term <min.length';
export const SELECT_SEARCH_ITEM = '[Search] Select Search Item';
export const MARK_SEARCH_ITEM = '[Search] Select Search Results Row';
export const SELECTED_SEARCH_ITEM_MISSING_KEY = '[Search] Selected Search Item Has Missing key';
export const RESET_SEARCH_RESULTS = '[Search] Delete Search Criteria and Results';
export const RESET_SEARCH = '[Search] Reset Search';
export const SAVE_SEARCH_TO_HISTORY = '[Search] Save Search Input to History';
export const ASSIGN_SELECTED_CONFLICTS_TO_USER = '[Search] Assign Selected Conflicts to User';
export const ASSIGN_SELECTED_ACTIONS_TO_USER = '[Search] Assign Selected Actions to User';
export const SET_ASSIGNEE_CONFLICTS_SELECTION = '[Search] Set Assignee Conflicts Selection';
export const UPDATE_CONFLICTS_SUCCESS = '[Search] Update Conflicts Success';
export const UPDATE_CONFLICTS_FAIL = '[Search] Update Conflicts Fail';
export const OPEN_UPLOAD_XREF_DIALOG = '[Search] Open Upload Xref Dialog';
export const SET_SEARCH_XREF_LIST = '[Search] Set Search Xref List';
export const SET_USERS_EXPERT_SEARCH_DEFAULT = '[Ui] Set Users Expert Search Default';
export const DO_SEARCH = '[Search] Do Search';
export const SORT_SEARCH = '[Search] Sort Search';
export const SET_UPDATE_CONFLICT_TYPE = '[Search] Set Update Conflict Type';

export class DoSearch implements Action {
  readonly type = DO_SEARCH;

  constructor(public payload: Search) {}
}
export class SearchSimple implements Action {
  readonly type = SEARCH_SIMPLE;

  constructor(public payload: string, public searchSection: string) {}
}

export class SearchAdvanced implements Action {
  readonly type = SEARCH_ADVANCED;

  constructor(public payload: Search, public searchSection: string) {}
}

export class SetSearchXrefList implements Action {
  readonly type = SET_SEARCH_XREF_LIST;

  constructor(public payload: string[]) {}
}

export class SearchExpert implements Action {
  readonly type = SEARCH_EXPERT;

  constructor(public payload: string, public searchSection: string) {}
}

export class OpenUploadXrefDialog implements Action {
  readonly type = OPEN_UPLOAD_XREF_DIALOG;

  constructor() {}
}

export class SearchReset implements Action {
  readonly type = SEARCH_RESET;

  constructor(public searchSection: string) {}
}

export class FetchSearchPage implements Action {
  readonly type = FETCH_SEARCH_PAGE;

  constructor(public payload: SearchActionInterface) {}
}

export class FetchSearchPageFail implements Action {
  readonly type = FETCH_SEARCH_PAGE_FAIL;

  constructor(public payload?: IceErrorPayload) {}
}

export class FetchSearchPageSuccess implements Action {
  readonly type = FETCH_SEARCH_PAGE_SUCCESS;

  constructor(public payload: SearchResultCleaned) {}
}

export class CancelSearch implements Action {
  readonly type = CANCEL_SEARCH;
}

export class EmptyResultOnSearchChange implements Action {
  readonly type = EMPTY_RESULTS_ON_SEARCH_CHANGE;

  constructor(public payload: { search: Search; section: string }) {}
}

export class SelectSearchItem implements Action {
  readonly type = SELECT_SEARCH_ITEM;

  constructor(public payload: any, public openInNewTab = false) {}
}

export class SelectedSearchItemMissingKey implements Action {
  readonly type = SELECTED_SEARCH_ITEM_MISSING_KEY;
}

export class ResetSearchResults implements Action {
  readonly type = RESET_SEARCH_RESULTS;

  constructor(public payload?: { section: string; advancedSearchDefaults: Object }) {}
}

export class ResetSearch implements Action {
  readonly type = RESET_SEARCH;
}

export class SaveSearchToHistory implements Action {
  readonly type = SAVE_SEARCH_TO_HISTORY;

  constructor(public payload: string) {}
}

export class MarkSearchItem implements Action {
  readonly type = MARK_SEARCH_ITEM;

  constructor(public payload: MarkSearchItemPayload) {}
}

export class AssignSelectedConflictsToUser implements Action {
  readonly type = ASSIGN_SELECTED_CONFLICTS_TO_USER;
}

export class AssignSelectedActionsToUser implements Action {
  readonly type = ASSIGN_SELECTED_ACTIONS_TO_USER;
}

export class SetAssigneeConflictsSelection implements Action {
  readonly type = SET_ASSIGNEE_CONFLICTS_SELECTION;

  constructor(public payload: { userId: string }) {}
}

export class UpdateConflictsSuccess implements Action {
  readonly type = UPDATE_CONFLICTS_SUCCESS;

  constructor(public payload: any[]) {}
}

export class UpdateConflictsFail implements Action {
  readonly type = UPDATE_CONFLICTS_FAIL;

  constructor(public payload: any) {}
}

export class SetUsersExpertSearchDefault implements Action {
  readonly type = SET_USERS_EXPERT_SEARCH_DEFAULT;
}

export class SetUpdateConflictType implements Action {
  readonly type = SET_UPDATE_CONFLICT_TYPE;

  constructor(public payload: any) {}
}

export type SearchActions =
  | FetchSearchPage
  | FetchSearchPageFail
  | FetchSearchPageSuccess
  | CancelSearch
  | EmptyResultOnSearchChange
  | SelectSearchItem
  | SelectedSearchItemMissingKey
  | ResetSearchResults
  | SaveSearchToHistory
  | MarkSearchItem
  | AssignSelectedConflictsToUser
  | SetAssigneeConflictsSelection
  | UpdateConflictsSuccess
  | UpdateConflictsFail
  | ResetSearch
  | SearchReset
  | OpenUploadXrefDialog
  | SetSearchXrefList
  | SetUsersExpertSearchDefault
  | SetUpdateConflictType;
