import { AGREEMENT_CONFLICT_STATUS, CC_STATUS_CLOSED, CC_STATUS_RESOLVED, CopyrightUtils } from '@ice';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import moment from 'moment';

export class SearchAgreementConflictParser {
  static parse(items: any[], translate: TranslateService, extraParams?: Object, extraItems?: string[], store?: Store<any>) {
    return items.map(item => {
      const { id, attributes, agreements, actions } = item;
      const ccStatus = (attributes && attributes.status) || '';
      const status = translate.instant(AGREEMENT_CONFLICT_STATUS[ccStatus] || ccStatus);
      const createdDate = (attributes && attributes.createdDate) || '';
      let agreementRefs = '';
      if (agreements && agreements.length && agreements.length > 0) {
        agreements.map((agreement, i) => {
          agreementRefs = i === 0 ? `${agreement.agreementId.replace('CUBE:', '')}` : `${agreementRefs}<br>${agreement.agreementId.replace('CUBE:', '')}`;
        });
      }
      let assignedUser = '';
      let deadline = '';
      if (actions && actions.length && actions.length > 0) {
        const lastAction = actions[actions.length - 1];
        assignedUser = get(lastAction, 'attributes.assignor');
        deadline = get(lastAction, 'attributes.deadline', '');
      }
      const deadlineReached =
        ![CC_STATUS_RESOLVED, CC_STATUS_CLOSED].includes((status || '').toUpperCase()) && moment(deadline).isBefore()
          ? CopyrightUtils.generateIconWithTooltip(translate.instant('ACTIVITY.DEADLINE_REACHED'), 'error_outline')
          : null;
      return {
        id,
        status,
        agreementRefs,
        assignedUser,
        createdDate,
        deadline,
        deadlineReached,
      };
    });
  }
}
