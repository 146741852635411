import { Injectable } from '@angular/core';
import { OrganizationType } from 'config/constants/organizations.constants';
import { ExpertSearchParseService } from './expert-search-parse.service';

@Injectable()
export class ExpertSearchParseOrganizationsService extends ExpertSearchParseService {
  constructor() {
    const AttributesMapOrganizations = {
      organizationName: 'attributes.name',
      organizationId: 'attributes.id',
      organizationType: 'attributes.type',
    };
    const attributesEnumMapOrganizations = {
      ['organizationType']: Object.keys(OrganizationType),
    };
    super(attributesEnumMapOrganizations, AttributesMapOrganizations);
  }
}
