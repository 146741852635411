import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CardWithFormly } from './card-with-formly';

@Component({
  selector: 'ice-card-with-formly',
  templateUrl: './card-with-formly.component.html',
  styleUrls: ['./card-with-formly.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardWithFormlyComponent {
  @Input() config: CardWithFormly;
}
