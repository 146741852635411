import { User } from '@ice';

export interface AuthState {
  user: User;
  loading: boolean;
  error: any;
  role: string;
  newPasswordInfo?: any;
}

export const initialAuthState: AuthState = {
  user: null,
  loading: false,
  error: null,
  role: '',
  newPasswordInfo: '',
};
