import { AbstractControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { column, fieldConfig, TabsUtils } from '@ice';
import { DataTable, DataTableRow } from '@ice/components/data-table/data-table';
import { DialogContentComponent } from '@ice/components/dialog-content/dialog-content.component';
import { IceGroupComponent } from '@ice/dynamic-components/group-component/group-component';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { locale as sharedEnglish } from 'assets/i18n/en/app/common/shared';
import { locale as english } from 'assets/i18n/en/config/data-table-builders';
import { IP_CLAUSES } from 'config/constants/ips.constants';
import type { SectionTabConfig } from 'config/tabs-data-builders/tabs-data';
import { IceFacade } from 'facades/ice.facade';
import { intersection, intersectionBy, pull, trim, union, uniqBy } from 'lodash';
import { Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CommonApiService } from 'services/common-api.service';
import { DetailService } from 'services/detail/detail.service';
import { NamespaceService } from 'services/namespace/namespace.service';
import { PermissionsService } from 'services/permissions/permissions.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import * as fromRoot from 'store/root';

// TODO: Add Angular decorator.
export class TabWorkIpClauses implements SectionTabConfig {
  dialogRef: MatDialogRef<DialogContentComponent, any>;
  hideWorksList = true;
  hideWorksSubject = new Subject();
  ipiNameNumberSubject = new Subject();
  unsubscribeAll = new Subject();
  ipiNameNumberControl: AbstractControl;
  ipiList = [];
  ipiTotalList = [];

  // permissions
  private canEditIpClauses = false;

  constructor(
    private translate: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    private store: Store<fromRoot.RootState>,
    private dialog: MatDialog,
    private commonApiService: CommonApiService,
    private detailService: DetailService,
    private nsService: NamespaceService,
    private iceFacade: IceFacade,
    private fieldValidatorService: FieldValidatorService,
    private permissionsService: PermissionsService,
  ) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.fuseTranslationLoader.loadTranslations(sharedEnglish);
    this.store.pipe(select(fromRoot.getEditMode), takeUntil(this.unsubscribeAll)).subscribe((editMode: boolean) => {
      if (editMode) {
        this.canEditIpClauses = this.permissionsService.can('works_ip_clauses_edit');
      }
    });
  }

  getConf(): IceGroupComponent[] {
    return [
      {
        group: [
          {
            type: 'cardWithDataTable',
            config: {
              title: this.translate.instant('WORKS.WORK_CLAUSES.TAB_TITLE'),
              model: this.store.pipe(
                select(fromRoot.getWorkIpClauses),
                map(clauses => uniqBy(clauses, clause => clause.type + clause.ipiNumber + clause.ipiName)),
              ),
              sorts: [],
              schema: this.getDataTable(),
              loadingIndicator: false,
              isLoadingData: this.store.pipe(select(fromRoot.getDataProgressBar)),
              reorderable: true,
              shadowed: false,
              columnMode: 'flex',
              actionButtons: this.store.pipe(select(fromRoot.getEditMode)).pipe(
                map(editMode => {
                  return editMode && this.canEditIpClauses
                    ? [
                        {
                          icon: 'add',
                          tooltip: this.translate.instant('WORKS.WORK_CLAUSES.ADD'),
                          class: 'mat-white-icon ice-add',
                          onClick: () => {
                            this.openDialog();
                          },
                        },
                      ]
                    : null;
                }),
              ),
              visibleColumns: this.getVisibleColumnsIpClauses(this.getDataTable()),
            },
          },
        ],
      },
    ];
  }

  openDialog() {
    this.dialogRef = this.dialog.open(DialogContentComponent, {
      data: {
        title: of(this.translate.instant('WORKS.WORK_CLAUSES.ADD')),
        ok: this.translate.instant('POPUP_COMMON.CONFIRM'),
        ko: this.translate.instant('POPUP_COMMON.CANCEL'),
        model: of({}),
        formBuilder: of([
          fieldConfig([
            {
              className: 'flex-1',
              key: 'typeSelected',
              type: 'select',
              templateOptions: {
                placeholder: `${this.translate.instant('WORKS.WORK_CLAUSES.SELECT_TYPE')}`,
                required: true,
                options: IP_CLAUSES.map(ipClause => ({ value: ipClause, label: this.translate.instant(`CLAUSES.${ipClause}`) })),
              },
            },
          ]),
          fieldConfig([
            {
              className: 'flex-1',
              key: 'filteredName',
              wrappers: ['wrapper-search', 'wrapper-input-text'],
              type: 'ice-input',
              templateOptions: {
                placeholder: `${this.translate.instant('WORKS.WORK_CLAUSES.SELECT_IPI_NAME')}`,
                type: 'text',
                required: true,
                onSearch: () => {
                  if (!this.hideWorksList) {
                    this.ipiList = [];
                    this.ipiNameNumberControl.setValue('');
                  }
                  this.hideWorksSubject.next(this.hideWorksList);
                  this.hideWorksList = !this.hideWorksList;
                },
              },
              hooks: {
                onInit: fieldGroup => {
                  this.ipiNameNumberControl = fieldGroup.formControl;
                  fieldGroup.formControl.valueChanges.pipe(takeUntil(this.unsubscribeAll)).subscribe(changes => {
                    this.ipiNameNumberSubject.next(changes);
                  });
                  this.store
                    .select(fromRoot.getManuscriptData)
                    .pipe(takeUntil(this.unsubscribeAll))
                    .subscribe(data => {
                      this.ipiTotalList = data;
                    });
                },
                onDestroy: () => {
                  this.unsubscribeAll.next();
                  this.unsubscribeAll.complete();
                  this.hideWorksList = true;
                  this.ipiList = [];
                },
              },
              validators: {
                wrongNameNumber: {
                  expression: (formControl: AbstractControl) => {
                    return (
                      !this.ipiTotalList ||
                      !formControl.value ||
                      formControl.value.replace(/\s/g, '').split(',').length ===
                        intersection(
                          this.ipiTotalList.map((item: any) => item.refLabel),
                          formControl.value.replace(/\s/g, '').split(','),
                        ).length
                    );
                  },
                  message: `${this.translate.instant('WORKS.WORK_CLAUSES.WRONG_NAME_NUMBER')}`,
                },
              },
            },
          ]),
        ]),
        datatable: {
          data: this.store.pipe(
            select(fromRoot.getManuscriptData),
            map(data =>
              uniqBy(
                data.filter(ip => ip.ipiNameNumber),
                ip => ip.ipiNameNumber,
              ),
            ),
          ),
          sorts: [],
          hidden: this.hideWorksSubject,
          class: '',
          columnMode: 'flex',
          schema: this.getDialogSelectIpDataTableSchema(this.translate),
          isSelectable: false,
          hideEmpty: true,
          rowIdentity: row => row.refLabel,
        } as DataTable,
        selectType: 'checkbox',
        selectedItemsInDialog: this.ipiNameNumberSubject.pipe(
          map((changes: string) => {
            const itemsWithRefLabel = changes
              .replace(/\s/g, '')
              .split(',')
              .map(refLabel => ({ refLabel }));
            const selectedItems = intersectionBy(this.ipiTotalList, itemsWithRefLabel, 'refLabel');
            return selectedItems;
          }),
        ),
        selectedKey: 'refLabel',
        onSelectSearchItem: ipSelected => {
          if (this.ipiNameNumberControl) {
            this.ipiList = this.ipiList.includes(ipSelected.refLabel) ? pull(this.ipiList, ipSelected.refLabel) : union([ipSelected.refLabel], this.ipiList);
            this.ipiNameNumberControl.setValue(this.ipiList.join(', '));
          }
        },
        onSubmit: (event: any) => {
          if (this.ipiList.length > 0) {
            event.ipiList = this.ipiTotalList.filter(item => this.ipiList.includes(item.refLabel));
          } else {
            const ipiListFromField: string[] = this.ipiNameNumberControl.value && this.ipiNameNumberControl.value.split(',');
            event.ipiList = ipiListFromField.length > 0 && this.ipiTotalList.filter(item => ipiListFromField.map(ipNameNumber => trim(ipNameNumber)).includes(item.refLabel));
          }

          this.store.dispatch(
            new fromRoot.UpdateField({
              object: 'clauses',
              newValue: { ...event, name: this.translate.instant(`CLAUSES.${event && event.typeSelected}`), clauseType: 'work' },
              type: 'new',
            }),
          );
          this.dialog.closeAll();
        },
        onButton1: () => {
          this.dialog.closeAll();
        },
      },
    });
  }

  getDialogSelectIpDataTableSchema(translate) {
    return [
      {
        name: '',
        prop: 'rowSelected',
        flexGrow: 0.001,
        maxWidth: 50,
        minWidth: 50,
        resizeable: false,
        headerCheckboxable: false,
        checkboxable: true,
      },
      column(translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_NAME'), 'name', 2.2),
      column(translate.instant('WORKS.DETAILS.CARD_FILTER_DATATABLE.TABLE_SCHEMA.COL_IP'), 'refLabel', 1.5),
    ];
  }

  getDataTable(): DataTableRow[] {
    return [
      { name: this.translate.instant('WORKS.WORK_CLAUSES.TABLE.CLAUSE_TYPE'), prop: 'type', flexGrow: 1, filter: true },
      { name: this.translate.instant('WORKS.WORK_CLAUSES.TABLE.CLAUSE_NAME'), prop: 'name', flexGrow: 1, filter: true },
      { name: this.translate.instant('WORKS.WORK_CLAUSES.TABLE.IP_NUMBER'), prop: 'ipiNumber', flexGrow: 1, filter: true },
      { name: this.translate.instant('WORKS.WORK_CLAUSES.TABLE.IP_NAME'), prop: 'ipiName', flexGrow: 1, filter: true },
      {
        name: '',
        prop: 'deleteIpClausesBtn',
        actionButtonIcon: 'delete',
        flexGrow: 0.001,
        maxWidth: 50,
        minWidth: 50,
        resizeable: false,
        action: row => this.deletePopup(row),
        hideActionButton: _row =>
          this.store.pipe(
            select(fromRoot.getEditMode),
            map(editMode => editMode && !this.permissionsService.can('works_ip_clauses_edit')),
          ),
        actionButtonTooltip: this.translate.instant('WORKS.WORK_CLAUSES.REMOVE'),
      },
    ];
  }

  deletePopup(row) {
    this.dialog.open(DialogContentComponent, {
      data: {
        title: of(this.translate.instant('WORKS.WORK_CLAUSES.REMOVE')),
        formBuilder: of([
          {
            fieldGroupClassName: 'display-flex-col',
            fieldGroup: [
              {
                template: `<h3>${this.translate.instant('WORKS.WORK_CLAUSES.CHECK_DELETE')}</h3>`,
              },
            ],
          },
        ]),
        model: of({}),
        submitEnabled: true,
        submitAvailable: 'true',
        submitLabel: this.translate.instant('NOTES.BUTTONS.REMOVE'),
        button1Enabled: true,
        button1Available: 'true',
        button1Label: this.translate.instant('NOTES.BUTTONS.CANCEL'),
        onSubmit: event => {
          this.store.dispatch(new fromRoot.UpdateField({ object: 'clauses', newValue: row, type: 'delete' }));
          this.dialog.closeAll();
        },
        onButton1: () => {
          this.dialog.closeAll();
        },
      },
    });
  }

  private getVisibleColumnsIpClauses(schema: any): Observable<string[]> {
    const schemaDatatable = TabsUtils.getSChemaPropsToArray(schema);

    return this.store.pipe(
      select(fromRoot.getEditMode),
      map(editMode => {
        if (editMode) {
          return schemaDatatable;
        } else {
          return TabsUtils.getSchemaFiltered(schemaDatatable, ['deleteIpClausesBtn']);
        }
      }),
    );
  }
}
