import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Host, NgModule, isDevMode } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FuseModule } from '@fuse/fuse.module';
import { GroupComponentModule } from '@ice/dynamic-components/group-component/group-component.module';
import { EffectsModule } from '@ngrx/effects';
import { NavigationActionTiming, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
import { HotkeyModule } from 'angular2-hotkeys';
import { fuseConfig } from 'config/fuse-config';
import { Hostnames, environment } from 'config/env';
import { IceFacade } from 'facades/ice.facade';
import { AuthService } from 'services/auth/auth.service';
import { CheckVersionService } from 'services/check-version/check-version.service';
import { DetailService } from 'services/detail/detail.service';
import { FormlyService } from 'services/formly/formly.service';
import { GlobalErrorHandler } from 'services/global-error-handler/global-error-handler.service';
import { NamespaceService } from 'services/namespace/namespace.service';
import { PermissionsService } from 'services/permissions/permissions.service';
import { ExportService } from 'services/report/report.service';
import { SaveItemService } from 'services/save-item/save-item.service';
import { ExpertSearchModule } from 'services/search/expert-search.module';
import { SearchService } from 'services/search/search.service';
import { StorageService } from 'services/storage/storage.service';
import { ToolbarService } from 'services/toolbar.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import { effects as effectsNewItem } from 'store/new-section-item/effects';
import { reducers as reducersNewItem } from 'store/new-section-item/reducers';
import { CustomRouterSerializer, effects, reducers } from 'store/root';
import { metaReducerPersist } from 'store/root/reducers/persist/persist.metareducer';
import { CommonApiService } from '../../services/common-api.service';
import { DatatableService } from '../../services/datatable/datatable.service';
import { CookiesModule } from '../auth/cookies/cookies.module';
import { MaskedIdsService } from './../../services/masked-ids/masked-ids.service';
import { AppRoutingModule } from './app-routing.module';
import { SearchUsersService } from './components/search-users/search-users.service';
import { SnackbarTemplateModule } from './components/snackbar-template/snackbar-template.module';
import { AppComponent } from './containers';
import { FuseMainModule } from './containers/main/main.module';
import { AuthGuard, LoggedGuard } from './guards';
import { PermissionsGuard } from './guards/permissions/permissions.guard';
import { httpInterceptorProviders } from './http-interceptors';
import { ConfigService } from './services/config.service';

declare global {
  interface Window {
    /**
     * Sets the debug UI value and reloads the window.
     * It adds UI elements to the page to help with debugging.
     *
     * @param value - The value to set for the debug UI.
     * @returns
     */
    setDebugUi: (value: boolean) => void;
  }
}
const appInitializerFn = (appConfig: ConfigService) => {
  return async () => {
    const dynamicConfig = await appConfig.getConfig();
    if ([Hostnames.DEV, Hostnames.LOCAL, Hostnames.SIT].includes(window.location.hostname as Hostnames)) {
      window.setDebugUi = (value: boolean) => {
        localStorage.setItem('debugUi', value ? 'enabled' : 'disabled');
        window.location.reload();
      };
    }
    // rewrite environment dynamically so it can be imported and used in the app as a regular import
    Object.assign(environment, dynamicConfig);
    // freeze it to prevent accidental changes or attacks
    Object.freeze(environment);
    return environment;
  };
};
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ExpertSearchModule,
    HotkeyModule.forRoot(),
    TranslateModule.forRoot(),
    FuseModule.forRoot(fuseConfig),
    FuseMainModule,
    MatSnackBarModule,
    SnackbarTemplateModule,
    GroupComponentModule,
    StoreModule.forRoot(reducers, { metaReducers: [metaReducerPersist] }),
    EffectsModule.forRoot(effects),
    StoreModule.forFeature('newSectionItem', reducersNewItem),
    EffectsModule.forFeature(effectsNewItem),
    CookiesModule,
    StoreDevtoolsModule.instrument({
      name: 'ICE Store DevTools',
      logOnly: window.location.hostname === Hostnames.PROD,
    }),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      serializer: CustomRouterSerializer,
      navigationActionTiming: NavigationActionTiming.PreActivation,
    }),
  ],
  bootstrap: [AppComponent],
  providers: [
    httpInterceptorProviders,
    NamespaceService,
    IceFacade,
    FieldValidatorService,
    AuthService,
    PermissionsService,
    SearchService,
    SearchUsersService,
    SaveItemService,
    ExportService,
    StorageService,
    DetailService,
    AuthGuard,
    PermissionsGuard,
    LoggedGuard,
    MaskedIdsService,
    DatatableService,
    CheckVersionService,
    FormlyService,
    CommonApiService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    Title,
    ToolbarService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      deps: [ConfigService],
      multi: true,
    },
  ],
})
export class AppModule {}
