import { DialogMatchConflict } from 'config/dialog-builders/dialog-match-conflict';

export enum ButtonsGroupActions {
  edit = 'EDIT',
  startTabEdit = 'START_EDIT',
  update = 'UPDATE',
  endUpdateMode = 'END_UPDATE_MODE',
  startUpdateMode = 'START_UPDATE_MODE',
  save = 'SAVE',
  saveInLocal = 'SAVE_IN_LOCAL',
  remove = 'REMOVE',
  saveWithoutSummary = 'SAVE_WHITOUT_SUMMARY',
  cancel = 'CANCEL',
  cancelSelectMode = 'CANCEL_SELECT_MODE',
  cancelEditMode = 'CANCEL_EDIT_MODE',
  goToNew = 'GO_TO_NEW',
  goToNewNote = 'GO_TO_NEW_NOTE',
  selectEditItem = 'SELECT_EDIT_ITEM',
  clearSelection = 'CLEAR_SELECTION',
  assignToMe = 'ASSIGN_TO_ME',
  assignToUser = 'ASSIGN_TO_USER',
  changeAssignee = 'CHANGE_ASSIGNEE',
  confirmMatch = 'CONFIRM_MATCH',
  forceMerge = 'FORCE_MERGE',
  discardMatch = 'DISCARD_MATCH',
  excerptMatch = 'EXCERPT_MATCH',
  modifyMatch = 'MODIFY_MATCH',
  sampleMatch = 'SAMPLE_MATCH',
  newAgreementConflict = 'NEW_AGREEMENT_CONFLICT',
  saveAgreementAsNew = 'SAVE_AGREEMENT_AS_NEW',
  newClaim = 'NEW_CLAIM',
  newClaimEdition = 'NEW_CLAIM_EDITION',
  export = 'EXPORT',
  exportXLSX = 'EXPORT_XLSX',
  exportCSV = 'EXPORT_CSV',
  startExportMode = 'START_EXPORT_MODE',
  endExportMode = 'CANCEL_EXPORT_MODE',
  openAlternativeUpdate = 'OPEN_ALTERNATIVE_UPDATE',
  addRetention = 'ADD_RETENTION',
  enableUser = 'ENABLE_USER',
  disableUser = 'DISABLE_USER',
  startSelectMode = 'START_SELECT_MODE',
  endSelectMode = 'END_SELECT_MODE',
  clone = 'CLONE',
  enableDownloadDocument = 'ENABLE_DOWNLOAD_DOCUMENT',
  downloadDocument = 'DOWNLOAD_DOCUMENT',
  cancelDownloadDocument = 'CANCEL_DOWNLOAD_DOCUMENT',
  enableRejectDocument = 'ENABLE_REJECT_DOCUMENT',
  cancelRejectDocument = 'CANCEL_REJECT_DOCUMENT',
  saveRejectDocument = 'SAVE_REJECT_DOCUMENT',
  submitSelectMode = 'SUBMIT_SELECT_MODE',
  startDeleteMode = 'START_DELETE_MODE',
  startShareMode = 'START_SHARE_MODE',
  startMergeIpMode = 'START_MERGE_IP_MODE',
  mergeIp = 'MERGE_IP',
  endMergeIpMode = 'END_MERGE_IP_MODE',
  mergeIPDetail = 'DETAIL_MERGE_IP',
  mergeIPDetailCancel = 'DETAIL_MERGE_IP_CANCEL',
  startTransferIpWorks = 'START_TRANSFER_IP_WORKS',
  endTransferIpWorks = 'END_TRANSFER_IP_WORKS',
  fabButtonsToggle = 'FAB_BUTTONS_TOGGLE',
  generateFiche = 'GENERATE_FICHE',
}

export class SharedActions {
  static doSharedAction(action: string, dialog, translate, store): void {
    switch (action) {
      case ButtonsGroupActions.forceMerge:
        DialogMatchConflict.openDialogMerge(dialog, translate, store, 'CONFIRMED');
        break;
      case ButtonsGroupActions.discardMatch:
        DialogMatchConflict.openDialog(dialog, translate, store, 'NO_MATCH');
        break;
      case ButtonsGroupActions.modifyMatch:
        DialogMatchConflict.openDialogPossMatch(dialog, translate, store, 'NO_MATCH');
        break;
    }
  }
}
