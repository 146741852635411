<div class="dialog-mat-dialog-actions-container">
  <ng-container *ngTemplateOutlet="verticalScrollBarVisible || data.layouts[layout]?.verticalFloatingButtons ? floatButtons : inlineButtons"></ng-container>
</div>
<div class="dialog-wrapper {{ data.className }} {{ data.layouts[layout]?.className }}" #popupHeader>
  <div class="dialog-mat-dialog-spinner-container" *ngIf="isLoading() | async">
    <div class="dialog-loader-info-container" [style.padding-top]="(data?.layouts)[layout]?.title ? '50px' : '0px'">
      <div class="spinner-container">
        <mat-spinner [diameter]="70"></mat-spinner>
      </div>
      <div [innerHTML]="getLoadingText() | async" class="loading-text" *ngIf="getLoadingText() | async"></div>
      <div class="loading-buttons-container" *ngIf="getLoadingButtons() | async; let buttons">
        <div class="loading-button" *ngFor="let button of buttons">
          <button mat-button (click)="button.action()">
            {{ button.text | async }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-mat-dialog-content-container">
    <mat-dialog-content id="ice-dialog-content" [ngClass]="{ 'ice-dialog-content-buttons-margin': true, 'dialog-content-wrapper': true }" #contentContainer>
      <mat-tab-group #matTabGroup style="height: 100%" [selectedIndex]="layout">
        <ng-container *ngFor="let layout of data.layouts">
          <mat-tab label="{{ layout.title | async }}" class="ice-overflow-hidden" style="height: auto">
            <div *ngIf="layout.title | async" [ngClass]="dialogTitleClass(layout)">
              <h2 mat-dialog-title [innerHTML]="layout.title | async" [ngClass]="layout?.titleClass"></h2>
              <button
                *ngIf="!dialogRef.disableClose"
                mat-icon-button
                mat-dialog-close
                class="close-button"
                matTooltip="{{ 'CLOSE' | translate }}"
                matTooltipPosition="above"
                data-testid="dialog-close"
              >
                <mat-icon>close</mat-icon>
              </button>
            </div>
            <button
              *ngIf="layout.contentClose"
              mat-icon-button
              mat-dialog-close
              class="close-button dialog-content-close"
              matTooltip="{{ 'CLOSE' | translate }}"
              matTooltipPosition="above"
              attr.data-testid="dialog-close-button"
            >
              <mat-icon>close</mat-icon>
            </button>
            <div
              (scroll)="onScroll($event)"
              class="ice-dialog-multi-layout-content-wrapper"
              [style.height]="(layout.title | async) ? 'calc(100% - 50px)' : '100%'"
              [style.max-height]="(layout.title | async) ? 'calc(100vh - 50px)' : '100%'"
            >
              <ice-group-component class="dialog-multi-layout-group-component" *ngIf="!(isLoading() | async)" [components]="layout.layout"></ice-group-component>
            </div>
          </mat-tab>
        </ng-container>
      </mat-tab-group>
    </mat-dialog-content>
  </div>
</div>
<div #scrollSpy></div>

<ng-template #floatButtons>
  <mat-dialog-actions
    *ngIf="data.layouts && data.layouts.length > 0 && data.layouts[layout].actions && data.layouts[layout].actions.length > 0"
    class="float-buttons {{ data.floatingAndInlineButtons && data.inlineButtons ? 'ice-mb-40-i' : '' }}"
    layout="row"
    layout-align="end center"
  >
    <div class="start-buttons">
      <div fxLayout="column" fxLayoutGap="10px">
        <ng-container *ngFor="let action of data.layouts[layout].actions">
          <button
            mat-fab
            [matTooltip]="action.tooltip"
            [matTooltipPosition]="'above'"
            [matTooltipClass]="'ice-tooltip'"
            [style.visibility]="action.hidden && (action.hidden | async) ? 'hidden' : 'visible'"
            [color]="action.color ? action.color : 'accent'"
            [disabled]="action.disabled || isLoading() | async"
            (click)="action.onClick ? onClick(action) : tabAction(action.nextLayout)"
            attr.data-testid="action-{{ action.icon }}"
          >
            <mat-icon class="white-icon {{ action.className }}">{{ action.icon }}</mat-icon>
          </button>
        </ng-container>
      </div>
    </div>
  </mat-dialog-actions>
  <div *ngIf="data.floatingAndInlineButtons">
    <div class="inline-extra-buttons-container" *ngIf="data.inlineButtons; let buttons">
      <div *ngFor="let button of buttons">
        <button mat-raised-button [color]="button.color || 'accent'" (click)="button.onClick && button.onClick()" attr.data-testid="action-{{ button.label }}">
          {{ button.label | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #inlineButtons>
  <mat-dialog-actions
    *ngIf="
      data.floatingAndInlineButtons
        ? data.inlineButtons?.length > 0
        : data.layouts && data.layouts.length > 0 && data.layouts[layout].actions && data.layouts[layout].actions.length > 0
    "
    layout="row"
    layout-align="end"
  >
    <div class="start-buttons">
      <div fxLayout="row" fxLayoutGap="10px">
        <ng-container *ngFor="let action of data.floatingAndInlineButtons ? data.inlineButtons : data.layouts[layout].actions">
          <button
            mat-fab
            [matTooltip]="action.tooltip"
            [matTooltipPosition]="'above'"
            [matTooltipClass]="'ice-tooltip ice-tooltip-popup-inline-button'"
            [style.visibility]="action.hidden && (action.hidden | async) ? 'hidden' : 'visible'"
            [color]="action.color ? action.color : 'accent'"
            [disabled]="action.disabled || isLoading() | async"
            (click)="action.onClick ? onClick(action) : tabAction(action.nextLayout)"
            attr.data-testid="action-{{ action.icon }}"
          >
            <mat-icon class="white-icon {{ action.className }}">{{ action.icon }}</mat-icon>
          </button>
        </ng-container>
      </div>
    </div>
  </mat-dialog-actions>
</ng-template>
