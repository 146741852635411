<mat-toolbar class="ice-p-0 ice-h-auto ice-toolbar ice-shadow">
  <mat-progress-bar *ngIf="isLoading$ | async" class="loading-bar" color="accent" mode="indeterminate"></mat-progress-bar>

  <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
    <button mat-button class="toggle-button-navbar mat-icon-button" (click)="toggleSidebarOpened('navbar')" fxHide.gt-md>
      <mat-icon>menu</mat-icon>
    </button>
  </div>

  <div fxFlex="100" style="height: 100%">
    <ice-search-bar
      fxFlex="80"
      *ngIf="showSearchBar$ | async"
      (searchEmitter)="searchSimple($event)"
      (resetEmitter)="searchReset()"
      (advancedCollapsedEmitter)="advancedCollapse()"
      (expertCollapsedEmitter)="toggleExpert()"
      [collapsedAdvanced]="advancedSearchCollapsed$ | async"
      [collapsedExpert]="expertSearchCollapsed$ | async"
      [searchTerm]="searchTerm$ | async"
      [section]="section$ | async"
      [disableNormalSearch]="disableNormalSearch$ | async"
    ></ice-search-bar>
    <div *ngIf="modeLabelVisible" fxFlex fxLayout="row" fxLayoutAlign="end none">
      <mat-chip-list class="edit-mode-chip-margin" *ngIf="(editModeVisible$ | async) || (mergeIPModeVisible$ | async)">
        <mat-chip [matTooltip]="editOrganizationName$ | async" class="edit-mode-chip"
          >{{ 'EDIT_MODE' | translate }}: {{ (mergeIPModeVisible$ | async) ? ICEtext : (editOrganizationName$ | async) }}</mat-chip
        >
      </mat-chip-list>
      <mat-chip-list class="edit-mode-chip-margin" *ngIf="forcedNSVisible$ | async">
        <mat-chip class="red-600-bg">{{ 'SOURCE_WORK_VIEW_ONLY' | translate }}: {{ globalApiNameSpace$ | async }}</mat-chip>
      </mat-chip-list>
    </div>
    <div *ngIf="['works', 'bulk-updates'].includes(section$ | async) && (creationModeVisible$ | async)" fxFlex fxLayout="row" fxLayoutAlign="end none">
      <mat-chip-list class="edit-mode-chip-margin">
        <mat-chip [matTooltip]="creationName$ | async" class="edit-mode-chip">{{ 'EDIT_MODE' | translate }}: {{ creationName$ | async }}</mat-chip>
      </mat-chip-list>
    </div>
  </div>

  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center" aria-label="user-menu">
    <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
      <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
        <div fxLayout="row" fxLayoutAlign="center center">
          <img class="avatar" src="assets/images/avatars/profile.jpg" aria-label="avatar" />
          <span class="username mr-12" fxHide fxShow.gt-sm>{{ displayName$ | async }}</span>
          <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
        </div>
      </button>

      <div class="toolbar-separator"></div>
      <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
        <button *ngIf="userId$ | async; let userId" mat-menu-item (click)="gotoUserDetails(userId)">
          <mat-icon>account_circle</mat-icon>
          <span>My Profile</span>
        </button>
        <button mat-menu-item class="" (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>
<div class="ice-form-advanced-search ice-transition ice-shadow" [ngClass]="{ 'ice-form-advanced-search-collapsed': advancedSearchCollapsed$ | async }">
  <div class="ice-expert-search-toggle-slide" *ngIf="showExpertSearch$ | async" fxLayout="row">
    <mat-slide-toggle (change)="toggleExpert()" [checked]="expertSearchCollapsed$ | async">
      {{ 'EXPERT_MODE' | translate }}
    </mat-slide-toggle>
    <mat-hint class="inline-flex flex-1 flex-align-items-center ice-txt-size-11" *ngIf="(expertSearchCollapsed$ | async) && (expertSearchExperimentalWarning$ | async)">
      <span class="material-icons"> science </span><span>{{ 'EXPERT_WARNING' | translate }}</span>
    </mat-hint>
  </div>
  <ice-form
    *ngIf="!(expertSearchCollapsed$ | async) || !(showExpertSearch$ | async)"
    [model]="model"
    [formBuilder]="formBuilder$ | async"
    [submitLabel]="'SEARCH_ADVANCED'"
    [extraActionAvailable]="uploadXref$ | async"
    [extraActionLabel]="'UPLOAD_XREF'"
    (extraActionClick)="uploadXref($event)"
    [changeInitModel]="section$"
    [submitEnabled]="true"
    [resetAvailable]="true"
    (submit)="searchAdvanced($event)"
  ></ice-form>
  <ice-expert-search
    *ngIf="(expertSearchCollapsed$ | async) && (showExpertSearch$ | async)"
    (submitQuery)="searchExpert($event)"
    [showUploadXref]="uploadXref$ | async"
    (uploadXref)="uploadXref($event)"
    (reset)="searchReset()"
    [searchInputQuery]="searchInputQuery$ | async"
    [section]="section$ | async"
  ></ice-expert-search>
</div>
