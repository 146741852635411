import { environment } from 'config/env';
import type { SectionConfig } from './sections-config';

export const SectionConflictsActivity: SectionConfig = {
  name: 'activity',
  url: '/conflicts/activity',
  domainName: 'conflicts',
  baseUrl: environment.apiUrl,
  baseUrlCubeData: environment.apiUrlCubeData,
  apiSegment: 'cases',
  removeUrlNSInSearch: true,
  detailSegment: `conflicts/CUBE/<<id>>`,
  minCharsToSearch: 5,
  apiIncludes: {
    search: ``,
    detail: `societies{conflict,conflictId,version},attributes,case{conflictId,data,status,user},works.work.attributes,works,works.work.relations,partyNames{partyName,conflict}`,
  },
  searchServerSideSortDefault: '',
  allowedActions: {
    newItem: false,
    editItem: null,
    newNote: false,
    removeItem: false,
  },
  homeRedirect: 'search',
  customSearchSize: 100,
  avoidSearchRedirect: true,
  auditTypes: ['conflict'],
  searchResultsUniqByParameter: 'id',
};
