import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as fromRoot from 'store/root';
import { FieldValidatorService } from '../../services/validators/field.validator.service';
import { DialogAddUploadIp } from './dialog-add-upload-ip';

export class DialogAddContractingCompany extends DialogAddUploadIp {
  constructor(translate: TranslateService, dialog: MatDialog, store: Store<fromRoot.RootState>, fieldValidatorService: FieldValidatorService) {
    super(
      translate,
      dialog,
      store,
      fieldValidatorService,
      new Map<string, string>([
        ['maxIpMessage', 'REPERTOIRES.STEPS.DETAILS.FIELDS.CONTRACTING_COMPANIES.CONTRACTING_COMPANIES_DETAILS.MAX_CONTRACTING_COMPANIES_REACHED'],
        ['dialogTitle', 'REPERTOIRES.STEPS.DETAILS.FIELDS.CONTRACTING_COMPANIES.DIALOG.TITLE'],
        ['uploadedIpsMessage', 'REPERTOIRES.STEPS.DETAILS.FIELDS.CONTRACTING_COMPANIES.DIALOG.INFO'],
        ['confirmUploadingTitle', 'REPERTOIRES.STEPS.DETAILS.FIELDS.CONTRACTING_COMPANIES.DIALOG.CONFIRM_TITLE'],
        ['confirmUploadingMessage', 'REPERTOIRES.STEPS.DETAILS.FIELDS.CONTRACTING_COMPANIES.DIALOG.CONFIRM_MESSAGE'],
        ['cancelUploadingTitle', 'REPERTOIRES.STEPS.DETAILS.FIELDS.CONTRACTING_COMPANIES.DIALOG.CANCEL_TITLE'],
        ['cancelUploadingMessage', 'REPERTOIRES.STEPS.DETAILS.FIELDS.CONTRACTING_COMPANIES.DIALOG.CANCEL_MESSAGE'],
        ['invalidIpUploadingMessage', 'REPERTOIRES.STEPS.DETAILS.FIELDS.CONTRACTING_COMPANIES.DIALOG.INVALID_MESSAGE'],
        ['dragAndDropLabel', 'REPERTOIRES.STEPS.DETAILS.FIELDS.CONTRACTING_COMPANIES.DIALOG.DRAG_AND_DROP_TEXT'],
        ['addManuallyLabel', 'REPERTOIRES.STEPS.DETAILS.FIELDS.CONTRACTING_COMPANIES.DIALOG.ADD_MANUALLY'],
        ['invalidFileMessage', 'REPERTOIRES.STEPS.DETAILS.FIELDS.CONTRACTING_COMPANIES.DIALOG.INVALID_FILE'],
        ['bulkUploadToMuchEntriesMessage', 'REPERTOIRES.STEPS.DETAILS.FIELDS.CONTRACTING_COMPANIES.DIALOG.BULK_UPLOAD_TO_MUCH_ENTRIES'],
        ['bulkUploadSuccessMessage', 'REPERTOIRES.STEPS.DETAILS.FIELDS.CONTRACTING_COMPANIES.DIALOG.BULK_UPLOAD_SUCCESS_MESSAGE'],
        ['bulkUploadAllValid', 'REPERTOIRES.STEPS.DETAILS.FIELDS.CONTRACTING_COMPANIES.DIALOG.BULK_UPLOAD_SUCCESS_MESSAGE_ALL_VALID'],
        ['bulkUploadSomeValid', 'REPERTOIRES.STEPS.DETAILS.FIELDS.CONTRACTING_COMPANIES.DIALOG.BULK_UPLOAD_SUCCESS_MESSAGE_SOME_VALID'],
        ['bulkUploadSomeInvalid', 'REPERTOIRES.STEPS.DETAILS.FIELDS.CONTRACTING_COMPANIES.DIALOG.BULK_UPLOAD_SUCCESS_MESSAGE_SOME_INVALID'],
      ]),
    );
  }
}
