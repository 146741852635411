import { DatePipe } from '@angular/common';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { StringUtils } from '@ice';
import { IceForm } from '@ice/dynamic-components/group-component/group-component';
import * as fromApiCalls from 'config/api-calls';
import { CopyrightAgreementsDataTable } from 'config/data-table-builders/copyright.agreements';
import { FormConfig } from 'config/search-form-builders/form-config';
import { AgreementsSearchExpressions, SearchCopyrightAgreementsForm } from 'config/search-form-builders/search-copyright.agreements';
import { cloneDeep } from 'lodash';
import { of } from 'rxjs';
import { SearchService } from 'services/search/search.service';
import { FieldValidatorService } from 'services/validators/field.validator.service';
import { DialogSearchDatatable } from './dialog-search-datatable';

export class DialogSearchAgreement {
  static openDialog(
    dialog,
    store,
    translate,
    translationLoader: FuseTranslationLoaderService,
    searchService: SearchService,
    fieldValidatorService: FieldValidatorService,
    onSelectSearchItem,
  ) {
    const datatable = new CopyrightAgreementsDataTable(translate, translationLoader, store);
    const searchForm = new SearchCopyrightAgreementsForm(translate, translationLoader, searchService, fieldValidatorService);
    const dialogSearchAgreement = new DialogSearchDatatable(dialog, store, searchService, translate);
    dialogSearchAgreement.openDialog(
      null,
      datatable,
      fromApiCalls.searchDialogAgreements,
      this.getAgreementSearchForm(searchForm, translate),
      new AgreementsSearchExpressions(),
      onSelectSearchItem,
      model => this.getAgreementSearchFormModel(model),
    );
  }

  static getAgreementSearchForm(searchForm: FormConfig, translate): IceForm {
    return {
      formBuilder: of(searchForm.getForm()),
      model: of({}),
      submitEnabled: true,
      submitAvailable: true,
      submitLabel: translate.instant('AGREEMENTS.AGREEMENT_PARTIES.BT_SEARCH'),
    };
  }

  static getAgreementSearchFormModel(model) {
    const search = cloneDeep(model);
    const { assignor, assignee, startDate } = model;
    if (assignor) {
      if (StringUtils.isNumberLike(assignor)) {
        search['assignorId'] = assignor;
      } else {
        search['assignorName'] = assignor;
      }
      delete search['assignor'];
    }
    if (assignee) {
      if (StringUtils.isNumberLike(assignee)) {
        search['assigneeId'] = assignee;
      } else {
        search['assigneeName'] = assignee;
      }
      delete search['assignee'];
    }
    if (startDate) {
      const datePipe = new DatePipe('en-UK');
      search['startDate'] = datePipe.transform(startDate, 'yyyy-MM-dd');
    }
    return search;
  }
}
