import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DirectivesModule } from '@ice/directives/directives.module';
import { IceMaterialModule } from '@ice/material.module';
import { IcePipesModule } from '@ice/pipes/ice.pipes.module';
import { CustomToolTipComponent } from '../custom-tool-tip/custom-tool-tip.component';
import { CustomIconWithHtmlToolTipComponent } from './custom-icon-with-html-tooltip.component';

@NgModule({
  imports: [CommonModule, DirectivesModule, IceMaterialModule, IcePipesModule],
  declarations: [CustomIconWithHtmlToolTipComponent],
  exports: [CustomIconWithHtmlToolTipComponent],
})
export class CustomIconWithHtmlToolTipModule {}
