import { EVENT_ID } from 'config/constants/works.constants';
import { pick } from 'lodash';
import { PayloadAuditHistoryFilter } from 'models/action-payloads/audit-history-filter.payload';
import * as fromRoot from 'store/root';

export class AuditHistoryFilterConfig {
  static doSubmit(event, store, itemId: string, auditTypes: string[], sort: string) {
    const requestSubmit: PayloadAuditHistoryFilter = {
      itemId,
      auditTypes,
      timeout: 25,
      ...pick(event, ['emittedStartDate', 'emittedEndDate', 'userId', 'eventDescription', 'version', EVENT_ID, 'eventsWithNotes', 'workSourceId']),
      sort,
    };
    store.dispatch(new fromRoot.SubmitAuditHistoryFilter(requestSubmit));
  }
}
